import { IonContent, IonPage } from '@ionic/react';
import { IInternalErrorScreenProps } from './InternalErrorScreen.def';
import './InternalErrorScreen.scss';
import { OrdinaryButton, PrimaryButton } from 'views/forms/buttons/Button';
//import { history } from 'scripts/router/history'
import { isProduction } from 'scripts/application/settings';

export const InternalErrorScreen = (props: IInternalErrorScreenProps): JSX.Element =>
{
    const { debugMode = !isProduction } = props;

    return (
        <IonPage>
            <IonContent>
                <div className="container pt-md-5 mt-md-5 pt-5 bg_color_snow_white">
                    <div className="cards p-0 ml-auto mr-auto max-w-1000px">
                        <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pl-0 pr-md-5 pr-0">
                            <h2 className='mb-0'>Something went wrong.</h2>
                        </div>
                        <div className="p-md-5 p-0 ml-auto mr-auto text-center">
                            <img className="mb-5 mt-4" src="https://content.harstatic.com/media/artwork/img_404.svg" alt="404 page" />
                            <p className='font_size--large_extra font_weight--bold'>Support has been notified</p>
                            {props.error && debugMode && <div className='input-description input-description__error mt-4 mb-4'>
                                {props.error?.toString() ?? 'Internal Server Error'}
                            </div>}

                            <PrimaryButton size='large' onClick={() => props.resetErrorBoundary()} className="mr-2">Try Again</PrimaryButton>
                            <OrdinaryButton size='large' onClick={() => window.location.replace("/home")}>Go to Dashboard</OrdinaryButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
