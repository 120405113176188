import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"
import { isPlatform } from '@ionic/react';


export namespace DownloadAttachmentApi {
    export const url = "/emails/:message_uid/attachments/:attachment_id/download";
    export const method = "GET";
    export const contentType = isPlatform('desktop') ? 'blob' : 'json';
    export const defaultError = "failed to download attachment";
    export const operations = apiOperations(DownloadAttachmentApi);
    export const scope = new class DownloadAttachmentApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {

            return {
                message_uid: input.message_uid,
                attachment_id: input.attachment_id,
                content_type: input.content_type,
                version: input.version

            }
        }

        response(input: Response): Response {
            return input;
        }
    }();

    export interface Request {
        message_uid: string;
        attachment_id: string;
        content_type: string;
        version?: string
    }

    export type Response = {
        data?: TFile
    };

    type TFile = {
        url: string
    }
}

export const fetchDownloadAttachmentService = forwardOperation(DownloadAttachmentApi.operations, 'fetch');
export const useFetchDownloadAttachmentService = forwardOperation(DownloadAttachmentApi.operations, 'useFetch');
export const useDownloadAttachmentService = forwardOperation(DownloadAttachmentApi.operations, 'useService');