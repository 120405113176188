import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { strToBoolean, booleanToStr } from "scripts/helpers/texts";
import { formatDateTimeShort, formatSystemDateShort, formatTwoDateTimeShort, } from "scripts/helpers/dates";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"
import moment from 'moment';

export namespace GetCalendarTasksApi {
    export const url = "/tasks";
    export const method = "GET";
    export const defaultError = "Failed to load task list.";
    export const operations = apiOperations(GetCalendarTasksApi);
    export const scope = new class GetCalendarTasksApiScope implements IApiScope<Request, Response>
    {

        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input,
                start_dt: formatSystemDateShort(input.start_dt),
                end_dt: formatSystemDateShort(input.end_dt),
                date: formatSystemDateShort(input.date),
                renew: input.renew?.toString(),
                id: input.id,
                page: input.page?.toString(),
                page_size: input.page_size?.toString(),
                completed: booleanToStr(input.completed),
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                total: parseInt(data.total),
                page: parseInt(data.page),
                page_size: parseInt(data.page_size),
                total_pages: parseInt(data.total_pages),
                list: data.list.map((x) => ({
                    ...x,
                    completed: strToBoolean(x.completed),
                    id: (x.id),
                    start_dt: moment(x.start_dt).toDate(),
                    end_dt: moment(x.end_dt).toDate(),
                    created_dt: moment(x.created_dt).toDate(),
                    reminder_minutes_prior: x.reminder_minutes_prior,
                    dateTime:x.category=='e'? formatTwoDateTimeShort(moment(x.start_dt), moment(x.end_dt)):formatDateTimeShort(moment(x.start_dt)),
                    contacts: x.contacts.map((y) => ({
                        ...y,
                        id: parseInt(y.id)
                    }))

                }))
            }
        }
    }();

    export interface Request {
        id?: string,
        page?: number,
        page_size?: number,
        sort_by?: string,
        sort_dir?: string,
        title?: string,
        keyword?: string,
        date?: Date,
        start_dt?: Date,
        end_dt?: Date,
        completed?: boolean,
        types?: string,
        categories?: string,
        priorities?: string,
        status_filter?: string,
        renew?: number,
        contact_id?: string

    }

    export interface Response {
        total: number,
        page: number,
        page_size: number,
        total_pages: number,
        next_page_url: string,
        prev_page_url: string,
        list: Array<IResponseList>
    }


    export interface IResponseList {
        id: string,
        title?: string,
        property_full_street_address: string,
        type: string,
        type_text: string,
        start_dt: Date,
        end_dt: Date,
        dateTime: string,
        created_dt: Date,
        completed_dt: string,
        completed: boolean,
        status: string,
        status_text: string,
        category: string,
        category_text: string,
        priority: string,
        priority_text: string,
        reminder_minutes_prior: string,
        reminder_minutes_prior_text: string,
        repeat: string,
        repeat_text: string,
        repeat_every_value: string,
        repeat_every_text: string,
        repeat_on_day: string,
        repeat_end_on: string,
        repeat_end_occurrences: string,
        repeat_end_date: string,
        contacts: Array<IContact>
        harid: string,
        listingid: string,
        for: string,
        location: string
    }

    export interface IContact {
        id: number,
        first_name: string,
        middle_name: string,
        last_name: string,
        photo_url: string,
        deleted:string
    }
}

export const fetchGetCalendarTasksService = forwardOperation(GetCalendarTasksApi.operations, 'fetch');
export const useFetchGetCalendarTasksService = forwardOperation(GetCalendarTasksApi.operations, 'useFetch');
export const useGetCalendarTasksService = forwardOperation(GetCalendarTasksApi.operations, 'useService');