import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateRoutineEmailApi {
    export const url = "/emails/routine_email";
    export const method = "POST";
    export const defaultError = "Bad Request";
    export const operations = apiOperations(UpdateRoutineEmailApi);
    export const scope = new class UpdateRoutineEmailApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        routine_email_alias: string
    }

    export interface Response {

    }
}

export const fetchUpdateRoutineEmailService = forwardOperation(UpdateRoutineEmailApi.operations, 'fetch');
export const useFetchUpdateRoutineEmailService = forwardOperation(UpdateRoutineEmailApi.operations, 'useFetch');
export const useUpdateRoutineEmailService = forwardOperation(UpdateRoutineEmailApi.operations, 'useService');