import { IContactDetailScreenProps } from './ContactDetailScreen.def';
import { BasicInformationSection } from 'views/contacts/BasicInformationSection';
import { AddressesSection } from 'views/contacts/AddressesSection';
import { RelationshipSection } from 'views/contacts/RelationshipSection';
import { ContactInsightSection } from 'views/contacts/ContactInsightSection';
import { ContactSection } from 'views/contacts/ContactSection';
import { LinksSection } from 'views/contacts/LinksSection';
import './ContactDetailScreen.scss';
import { useParams } from 'react-router';
import { GetContactApi, useFetchGetContactService } from 'services/contacts/contactDetails/getContact';
import { IBasicInfoData } from 'views/contacts/BasicInformationSection/BasicInformationSection.def';
import { useState } from 'react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { ILinkItem } from 'views/contacts/LinksSection/LinksSection.def';
import { IRelationItem } from 'views/contacts/RelationshipSection/RelationshipSection.def';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { OrdinaryButton } from 'views/forms/buttons/Button';
import { ContactsConfirmDelete } from 'views/contacts/popups/ContactsConfirmDelete';



export const ContactDetailScreenDesktop = (props: IContactDetailScreenProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [contactDetails] = useFetchGetContactService({ contact_id: parseInt(id), renew: '1' });
    const [basicInformation, setBasicInforamtion] = useState<IBasicInfoData>({});
    const [contactInsight, getContactInsight] = useState<IContactInsightData>();
    const [email, setEmail] = useState<GetContactApi.IEmailItemResponse[]>([]);
    const [phone, setPhone] = useState<GetContactApi.IPhoneItemResponse[]>([]);
    const [link, getLink] = useState<ILinkItem[]>([]);
    const [addresses, getAddresses] = useState<IAddressItem[]>([]);
    const [relationships, getRelationships] = useState<IRelationItem[]>([]);
    const [showPopUp, setShowPopUp] = useState(false)

   // let history = useHistory()
    useServiceDidSucceeded(contactDetails, (response) => {
        setBasicInforamtion({
            firstName: response?.first_name,
            lastName: response?.last_name,
            middleName: response?.middle_name,
            title: response?.title,
            suffix: response?.name_suffix,
            company: response?.company,
            jobTitle: response?.job_title,
            knownSince: response?.known_since,
            homeAniversary: response?.home_anniversary_date,
            dateOfBirth: response?.birthday,
            gender: response?.gender,
            weddingAniversary: response?.anniversary_date,
            image: response?.photo_url,
            id: parseInt(response?.id),
            income: response?.income,
            reminder_days_after_text : response?.reminder_days_after_text

        })

        getContactInsight({
            scoreType: response.score,
            pipelineType: response?.pipeline_status,
            sourceType: response?.sources,
            leadType: response?.lead_type,
            tags: response?.tags
        })

        setEmail(response?.emails);
        setPhone(response?.phones);
        getLink(response?.links?.map?.(val => ({ label: val.name, url: val.url, id: val.id })));
        getAddresses(response?.addresses.map?.(val => ({
            address1: val.address1,
            address2: val.address2,
            address_type: val.address_type,
            city: val.city,
            zipcode: val.zipcode,
            id: parseInt(val.id),
            state:val.state,
            country:val.country
        })));
        getRelationships(response?.relationships?.map?.(val => ({
            //name: val.name,
            contact: val.contact,
            type: val.type,
            id: val.id
        })))
    })

   

    return (
        <div className='container container__large pt-4'>
               {contactDetails.response?.id&&<ContactsConfirmDelete
                show={showPopUp ? true : false}
                toggleShow={() => setShowPopUp(false)}
                contacts={[contactDetails.response]}
                onDelete={()=>window.location.href = "/contacts"}
            />}
          {/*} <div className='d-flex justify-content-end mb-3'> <ProminentButton onClick={() => history.push(`/contacts/${id}/detail/edit`)}>Edit Contact</ProminentButton></div>*/}
            <div className="row">
                <div className="col-md-6 col-12 font_size--medium">
                    <div className=" mb-5 p-0"> <BasicInformationSection loading={contactDetails.loading} data={basicInformation} notifyImg={props.onChange} /></div>
                    <div className=" mb-5 p-0"> <AddressesSection loading={contactDetails.loading} initialItems={addresses} /></div>
                    <div className=" mb-5 p-0"><RelationshipSection loading={contactDetails.loading} initialItems={relationships} /></div>
                    <div className="pb-5">
                        <OrdinaryButton className="btn btn--simple btn--red btn--small" aria-label="Search" onClick={() => setShowPopUp(true)} >Delete this contact</OrdinaryButton>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div className=" mb-5 p-0"><ContactInsightSection loading={contactDetails.loading} initialValues={contactInsight} notifyScore={props.onChange} /></div>
                    <div className=" mb-5 p-0"> <ContactSection loading={contactDetails.loading} initialEmails={email} initialPhones={phone} notifyContact={props.onChange} /></div>
                    <div className=" mb-5 p-0"> <LinksSection loading={contactDetails.loading} initialLinks={link} /></div>
                </div>
            </div>
        </div>
    );
}

