import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IContactFormFields, IContactFormFieldsErrors, ISimpleContactFormPopUpProps } from './SimpleContactFormPopUp.def';
import './SimpleContactFormPopUp.scss';
import { forwardRef, useState } from 'react';
import { useMultiRef } from 'scripts/hooks/general';
import { OrdinaryButton, PrimaryButton } from 'views/forms/buttons/Button';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { useIonAlert } from '@ionic/react';
import { IonModalCustomEvent } from '@ionic/core';
import { CreateContactApi, useCreateContactService } from 'services/contacts/createContact';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { validateContactEmail, validateContactPhone, validateFirstName, validateLastName, validateRequiredFields } from 'scripts/validations/contacts';
import { requiredMessage, requiredContactMessage, contactCreatedMessage } from 'scripts/validations/messages';
import { displayMessage } from 'scripts/hooks/feedbacks';

export const SimpleContactFormPopUp = forwardRef<HTMLIonModalElement, ISimpleContactFormPopUpProps>((props, ref): JSX.Element =>
{
    const { firstName, lastName, email, onSave, ...popUpProps } = props;
    const [form, setForm] = useState<IContactFormFields>({});
    const [errors, setErrors] = useState<IContactFormFieldsErrors>({});
    const [modalRef, assignRef] = useMultiRef(ref);
    const [presentError] = useIonAlert();
    const [createContact, createActions] = useCreateContactService();

    const onIonModalWillPresent = (e:IonModalCustomEvent<void>) =>
    {
        setForm({ firstName, lastName, email });
        props.onIonModalWillPresent?.(e);
    }

    useServiceDidSucceeded(createContact, response => {
        const { firstName = '', lastName = '', email = '', phone = '' } = form;
        props.onSave?.({ id:response.id, firstName, lastName, email, phone });
        displayMessage( contactCreatedMessage() );
        modalRef.current?.dismiss();
    });

    useServiceDidFailed(createContact, (error) => {
        if(!error.data?.is_duplicated) { return; }

        error.preventDefault();
        const header = 'Duplicated Contact';
        const { message } = error;
        presentError({ header, message, buttons:['OK'], cssClass: 'p-5' });
    });

    const saveContact = async () =>
    {  
        // Validating fields
        if(!validate()) { return; }
        
        // Request Data
        const { firstName, lastName, email, phone } = form;
        const formData:CreateContactApi.Request = {
            first_name: firstName,
            last_name: lastName,
            emails: email? [{ email:email, preferred:true }]: [],
            phones: phone? [{ phone:phone, phone_type:'N', preferred:true }]: []
        }

        return createActions.fetch(formData);
    };

    const validate = (): boolean =>
    {
        // Validating each field
        errors.firstName = validateFirstName(form.firstName, false);
        errors.lastName = validateLastName(form.lastName, false);
        errors.email = validateContactEmail(form.email, false);
        errors.phone = validateContactPhone(form.phone, false);
        setErrors({ ...errors });
        const hasErrors = Object.values(errors).some(x => !!x);
        if(hasErrors) { return false; }

        // Validating Required Fields
        const { firstName, lastName, email, phone } = form;
        const { status, name, contact } = validateRequiredFields(firstName, lastName, email, phone);
        if(!status)
        {
            const message = requiredMessage();
            if(!name) { errors.firstName = errors.lastName = message; }
            if(!contact) { errors.email = errors.phone = message; }
            setErrors({ ...errors });
            showRequiredFieldsError();
            return false;
        }

        // All validations passed
        return true;
    }

    const showRequiredFieldsError = () =>
    {
        // Showing in the popup
        presentError({
            header: 'Required Fields',
            message: requiredContactMessage(),
            buttons:['OK'],
            cssClass: 'p-5'
        });
    }

    const onChange = (key:keyof IContactFormFields, value?:string | null) =>
    {
        setForm({ ...form, [key]:value });
        setErrors({ ...errors, [key]:undefined });
    }

    return (
        <DefaultPopUp {...popUpProps} title="Add Contact" modalRef={assignRef} onIonModalWillPresent={onIonModalWillPresent}>
            <DefaultPopUp.Body>
                <div className="px-5 py-4">

                    <Textbox
                        id="simpleContactFirstName"
                        label='First Name'
                        className="form-control"
                        containerClassName='mb-4'
                        value={form.firstName}
                        error={errors.firstName}
                        maxlength={50}
                        placeholder="First Name"
                        disabled={createContact.loading}
                        aria-describedby="First Name"
                        onIonChange={(e) => onChange('firstName', e.detail.value)}
                    />

                    <Textbox
                        id="simpleContactLastName"
                        label='Last Name'
                        className="form-control"
                        containerClassName='mb-4'
                        value={form.lastName}
                        error={errors.lastName}
                        maxlength={50}
                        placeholder="Last Name"
                        disabled={createContact.loading}
                        aria-describedby="Last Name"
                        onIonChange={(e) => onChange('lastName', e.detail.value)}
                    />

                    <Textbox
                        id="simpleContactEmail"
                        label='Email'
                        className="form-control"
                        containerClassName='mb-4'
                        value={form.email}
                        error={errors.email}
                        maxlength={50}
                        placeholder="Email"
                        disabled={createContact.loading}
                        aria-describedby="Email"
                        onIonChange={(e) => onChange('email', e.detail.value)}
                    />

                    <Textbox
                        id="simpleContactPhone"
                        label='Phone number'
                        className="form-control"
                        containerClassName='mb-4'
                        value={form.phone}
                        error={errors.phone}
                        maxlength={25}
                        placeholder="Phone number"
                        disabled={createContact.loading}
                        aria-describedby="Phone number"
                        onIonChange={(e) => onChange('phone', e.detail.value)}
                    />

                </div>
                
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="text-center p-2">

                    <OrdinaryButton
                        onClick={() => modalRef.current?.dismiss()}
                        className="btn--simple btn--simple__auxiliary"
                        disabled={createContact.loading}>
                            Cancel
                    </OrdinaryButton>

                    <PrimaryButton
                        onClick={() => saveContact()}
                        className="btn--primary btn w-100px"
                        loading={createContact.loading}>
                            Save
                    </PrimaryButton>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp>
    );
});