//import { useComponent } from "scripts/context";
import { IImageObj, IPhotoUploadSectionProps } from "./PhotoUploadSection.def";
import { PhotoUploadSectionDesktop } from "./PhotoUploadSection.desktop";
import { PhotoUploadSectionApp } from "./PhotoUploadSection.app";
import { useState } from "react";
import * as filestack from 'filestack-js';
import { clientId } from "scripts/vendors/filestack";
import { useResponsive } from "scripts/hooks/general";

export const PhotoUploadSection = (props:IPhotoUploadSectionProps): JSX.Element =>
{
    const { imageUrl, onItemsChange } = props;
    const [imageObj, setImageObj] = useState<IImageObj>( { imageUrl: imageUrl??"", imageType: '' });
    const fileStack = filestack.init(clientId);
    let time = new Date().getTime()
    const filestackOpener = () => {
        const options: filestack.PickerOptions = {
            fromSources: ['local_file_system', 'googledrive', 'unsplash', 'facebook', 'instagram'],
            maxFiles: 1,
            accept: ['text/plain','image/heic', 'image/jpeg', 'image/png', 'image/gif'],
            uploadInBackground: false,
            //imageDim: [400, 400],
           // imageMin: [400, 400],
            imageMax: [400, 400],
            maxSize: 5242880,
            storeTo: {
                location: 's3',
                path: 'crmapp_test/temp/' + time,
                container: 'content.har.com',
                access: 'public',
                region: 'us-east-1'
            },
            
            onOpen: () => console.log('opened!'),
            onFileSelected: (file: any) => {
                if (file.size > 5 * 1024 * 1024) {
                    alert('max file size')
                }
            },
            onUploadDone: (res: any) => {
                if (res?.filesUploaded.length > 0) {
                    const image = res?.filesUploaded
                    save({
                        imageUrl: 'https://content.harstatic.com/' + image[0].key,
                        imageType: image[0].mimetype
                    })
                }
            },
        };
        fileStack.picker(options).open();
    }

    const save = (obj: IImageObj) => {
        setImageObj(obj)
        onItemsChange?.(obj);
    };

    const PhotoUploadSectionPlatform = useResponsive(PhotoUploadSectionDesktop, PhotoUploadSectionApp);
    return <PhotoUploadSectionPlatform imageUrl={imageObj.imageUrl} onOpen={filestackOpener} />;
}
