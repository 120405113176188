import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeleteEmailsApi {
    export const url = "/emails/:type/bulk_delete";
    export const method = "DELETE";
    export const defaultError = "Emails has not been deleted";
    export const operations = apiOperations(DeleteEmailsApi);
    export const scope = new class DeleteEmailsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string | string[]> {
            return {
                ...input
            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        email_ids?: Array<string>,
        draft_ids?: Array<string>,
        type: string
    }

    export interface Response {
        status: string;
        message: string;
    }
}

export const fetchDeleteEmailsService = forwardOperation(DeleteEmailsApi.operations, 'fetch');
export const useFetchDeleteEmailsService = forwardOperation(DeleteEmailsApi.operations, 'useFetch');
export const useDeleteEmailsService = forwardOperation(DeleteEmailsApi.operations, 'useService');