import { IonButton, IonModal } from '@ionic/react';
import { IContactsConfirmDeleteProps } from './ContactsConfirmDelete.def';
import './ContactsConfirmDelete.scss';

export const ContactsConfirmDeleteApp = (props: IContactsConfirmDeleteProps): JSX.Element => {
    const { show, contacts, toggleShow, unSelectAll } = props
   // const [provider, setProvider] = useState("")


    const removeContact = () => {
        if (props.removeContact) {
            props.removeContact("0")

            toggleShow?.()
            unSelectAll?.()
        }
    }
   // const areTheyProviderContacts = (contacts: any) => contacts.provider_mailbox_id === mailerBoxId?.toString()
    return (
        <IonModal isOpen={show} trigger="open-modal" className="modal__autoheight" initialBreakpoint={1} breakpoints={[0, 1]} onIonModalDidPresent={() =>{}} onIonModalDidDismiss={() => toggleShow?.()} backdropDismiss={false}>
            <div className="ion-padding">
                <h2 tabIndex={0} className="h2--simple mb-4 mt-3">Delete Contacts?</h2>
                <div className="font_size--medium">

                    Once you delete  {contacts.length === 1 && (contacts[0].first_name !== "" || contacts[0].last_name !== "") ?
                        <strong>{contacts[0].first_name} {contacts[0].last_name}</strong>
                        : `these ${contacts.length} records`} from your account, there is no getting it back. Make sure you want to delete these contacts.
                    <br /><br />
                    <strong>If you delete these contacts, you will:</strong>
                    <div className="font_size--medium color_sold pt-3">
                        <div className="row mb-3 small-gutters">
                            <div className="col-auto"><img className=' w-16px opacity-86pr' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col">All Deals records will be deleted.</div>
                        </div>

                        <div className="row mb-3 small-gutters">
                            <div className="col-auto"><img className=' w-16px opacity-86pr' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col">All Calendar and Documents will be deleted.</div>
                        </div>

                        <div className="row mb-3 small-gutters d-none">
                            <div className="col-auto"><img className=' w-16px opacity-86pr' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col">All collaborators with this contact will also lose their records.</div>
                        </div>
                        {/**
                            <div className="border-bottom pt-3 pb-3 mb-3 font_size--medium border-color--cloudy-sky-light">
                                <h3>Remove contact</h3>
                                <div className="row no-gutters">
                                    <div className="col-auto pr-3">
                                        <div className='d-flex justify-content-center  cursor--hand' onClick={() => setProvider(provider === "0" ? "" : "0")}>
                                            <IonCheckbox id="prevent-checkbox" slot="start" checked={provider === "0"}  ></IonCheckbox>
                                            <div className='font_size--medium font_weight--semi_bold color_auxiliary cursor--hand ml-2'>Remove from the CRM</div>
                                        </div>
                                    </div>
                                    <div className="col-auto mt-3">
                                        <div className='d-flex justify-content-center cursor--hand' onClick={() => setProvider(provider === "1" ? "" : "1")}>
                                            <IonCheckbox id="prevent-checkbox" checked={provider === "1"} slot="start"  ></IonCheckbox>
                                            <div className='font_size--medium font_weight--semi_bold  color_auxiliary cursor--hand ml-2' >Remove from the CRM and Email Provider</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                         */}

                        <div className="row mb-3 small-gutters">
                            <div className="col">
                                <IonButton className="btn btn--textonly btn--large" onClick={() => toggleShow?.()}>Cancel</IonButton>
                            </div>
                            <div className="col text-right">
                                <IonButton className="btn btn--textonly btn--large btn--textonly__red" onClick={() => removeContact()}>{contacts.length > 1 ? `Yes, Delete ${contacts.length} Contacts` : `Delete Permanently`}</IonButton>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </IonModal>
    );
}
