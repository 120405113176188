import { IOnBoardingTemplateProps, IOnboardingTemplateContainerProps } from './OnBoardingTemplate.def';
import './OnBoardingTemplate.scss';
//import { IonContent, IonPage } from '@ionic/react';
//import { Sidebar } from './Sidebar';
import { DefaultTemplate } from '../DefaultTemplate';
import { IonContent, IonPage, IonRouterLink } from '@ionic/react';

export const OnBoardingTemplateDesktop = (props:IOnBoardingTemplateProps): JSX.Element =>
{
    const simple = props.simple ?? false;
    //const header = simple? <Title className="mb-5" />: <GeneralHeader />;
    //const cardContent = simple? props.children: <CardContent>{props.children}</CardContent>;
    return simple?
        <Simple {...props} />:
        <General {...props} />;
}

const General = (props:IOnBoardingTemplateProps): JSX.Element =>
{
    return (
        <Container onboarding={props.onboarding} contentRef={props.contentRef}>
            
            <div className="container pt-md-0 mt-md-5 pt-5 bg_color_snow_white">
                <div className="ml-auto mr-auto max-w-1000px">
                    <div className="row">
                        {props.onboarding && <div className="col-12 col-md-auto">
                            <div className=' min-w-140px'>
                                <div className="text-center">
                                    <div className="mb-4 tagline_text text-center">Welcome to</div>
                                    <img src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" className='w-92px' />
                                    {/* <img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='rounded w-92px' /> */}
                                    {/* <img src="https://content.harstatic.com/media/common/har_logo.svg" alt="HAR Logo"/>
                                    <h1 tabIndex={0} className="color_carbon pt-3 mb-3">CRM</h1> */}
                                </div>
                            </div>	                    
                        </div>}

                        <div className="col-12 col-md">
                            <div className="cards p-0">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
};

const Simple = (props:IOnBoardingTemplateProps): JSX.Element =>
{
    return (
        <IonPage ref={props.contentRef}>
            <IonContent>
                <div className="onboarding_container">
                    <div className="container">
                        <div className="row">
                                    <div className="col-auto align-self-center">
                                        {props.previousLink && <div className=' w-100px'>
                                            <IonRouterLink className="font_weight--semi_bold font_size--large color_har_blue" routerLink={props.previousLink}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Previous</IonRouterLink>
                                        </div>}
                                    </div>
                                    <div className="col text-center">
                                        <div className="d-inline-flex">                        
                                            <div className="align-self-center mr-3">
                                                <img src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" className='rounded onb_logo' />
                                                {/* <img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='rounded onb_logo' /> */}
                                                {/* <a href="#"><IonImg className='onb_logo' alt="HAR" src="https://content.harstatic.com/resources/images/common/logo-blue.svg" /></a> */}
                                            </div>
                                            <div className="align-self-center d-flex">
                                                <div className="onboarding__title onboarding__title_left ">CRM</div>
                                                <div className="onboarding__title onboarding__title_right">Onboarding</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className='min-w-100px'></div>
                                    </div>
                                </div>

                        <div className="mt-5 card content">
                            {props.children} 
                        </div>
                    </div>
                </div>
            </IonContent>
        
        </IonPage>
    )
};

// const Title = (_props:ITitleProps): JSX.Element =>
// {
//     return  <h2 tabIndex={(0)} className="h2--simple h2--simple__black mb-0">Settings</h2>          
// };

// const GeneralHeader = (): JSX.Element =>
// (
//     <div className="row pt-4 pb-4">
//         <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-3"><Title /></div>
//     </div>
// );

// const CardContent = (props:PropsWithChildren<{}>): JSX.Element =>
// (
//    <div className="col-12 col-lg-9 col-md-8">
//             {props.children}
//         </div>
// );

const Container = (props:IOnboardingTemplateContainerProps): JSX.Element =>
{
    if(!props.onboarding) { return <DefaultTemplate  contentRef={props.contentRef} title="Settings">{props.children}</DefaultTemplate>; }
    return (
        <IonPage ref={props.contentRef}>
            <IonContent>{props.children}</IonContent>
        </IonPage>
    );
}

