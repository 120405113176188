import moment from "moment";
import { eOnboardingStage } from "scripts/enums";
import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities";

export namespace GetBasicInfoApi {
    export const url = "/user/basicinfo";
    export const method = "GET";
    export const defaultError = "Failed to load your information.";
    export const operations = apiOperations(GetBasicInfoApi);
    export const scope = new class GetBasicInfoApiScope implements IApiScope<Request, Response> {
        request(data: Request) { return data; }
        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            return {
                ...input.data,
                user_id: parseInt(input.data.user_id),
                role_id: input.data.role_id ? parseInt(input.data.role_id) : undefined,
                photo: input.data.photo ?? 'https://content.harstatic.com/media/icons/agent_placeholder.svg',
                provider_emails_all: (input.data.provider_emails_all ?? []).map(provider => ({
                    ...provider,
                    mailbox_id: parseInt(provider.mailbox_id),
                    active: provider.active === '1',
                    sync_mode: provider.sync_mode === '1',
                    created_dt: moment(provider.created_dt).toDate(),
                    updated_dt: provider.updated_dt ? moment(provider.updated_dt).toDate() : undefined
                })),
                provider_mailbox_id: input.data.provider_mailbox_id !== undefined ? parseInt(input.data.provider_mailbox_id) : undefined,
                provider_sync_mode: input.data.provider_sync_mode !== undefined ? input.data.provider_sync_mode === '1' : undefined,
                settings: this._mapSettings(input.data.settings),
                emails: this._mapEmailData(input.data.emails),
                matrix: this._mapMatrixData(input.data.matrix),
                csv: this._mapCSVData(input.data.csv),
                onboarding_stage: input.data.onboarding_stage ? input.data.onboarding_stage as eOnboardingStage : eOnboardingStage.Welcome,
                accountSynced: input.data ? !!input.data.provider_email && input.data.provider_status?.trim().toLowerCase() === 'ok' : false,
                basicSynced: input.data ? true : false,
                sync_provider_to_nylas: input.data.sync_provider_to_nylas === '1'
            }
        }

        _mapSettings(settings?: PropertiesType<ISettings, string>): ISettings | undefined {
            if (!settings) { return undefined; }

            return {
                ...settings,
                daily_recommend_yn: settings.daily_recommend_yn ? settings.daily_recommend_yn === '1' : null
            }
        }

        _mapEmailData(emailData?: PropertiesType<IEmailData, string>): IEmailData | undefined {
            if (!emailData) { return undefined; }

            return {
                ...emailData,
                total_unread: emailData.total_unread
            }
        }
        _mapMatrixData(matrixData?: PropertiesType<IMatrix, string>): IMatrix | undefined {
            if (!matrixData) { return undefined; }
            return {
                ...matrixData,
            }
        }
        _mapCSVData(CSVData?: PropertiesType<ICSVImport, string>): ICSVImport | undefined {
            if (!CSVData) { return undefined; }
            return {
                ...CSVData,
            }
        }
    }();

    export interface Request {
        renew?: '1';
    }

    export interface Response {
        user_id: number;
        user_name: string;
        first_name?: string;
        last_name?: string;
        photo?: string;
        role_id?: number;
        role_name?: string;
        provider_mailbox_id?: number;
        provider_id?: string;
        provider_status?: string;
        provider_email?: string;
        provider_sync_mode?: boolean;
        provider_emails_all: Array<IAgentProvider>;
        agent_data: IAgentData;
        settings?: ISettings;
        emails?: IEmailData;
        matrix?: IMatrix;
        csv?: ICSVImport;
        onboarding_stage: eOnboardingStage;
        accountSynced: boolean;
        basicSynced: boolean;
        sync_provider_to_nylas: boolean;
    }

    export interface IAgentData {
        member_number: string;
        email: string;
        phone?: string;
        office_key?: string;
        office_name?: string;
        mls_member_type?: string;
        har_member_tupe?: string;
        mls_premium?: string;
        pwd_length?: string;
    }

    export interface IAgentProvider {
        mailbox_id: number;
        email: string;
        active: boolean;
        created_dt: Date;
        updated_dt?: Date;
        provider_status?: string;
        sync_mode: boolean;
    }

    export interface IRoutineEmailSettings {
        routine_email: string;
        routine_email_alias: string;
        routine_email_domain: string;
    }

    export interface ISettings extends IRoutineEmailSettings {
        daily_recommend_yn: boolean | null;
        notification_method: string;
    }

    export interface IEmailData {
        total_unread?: string
        total_unread_lead?: string
    }

    export interface IMatrix {
        status?: string
        progress?: string
        contacts_count: string,
        status_id?: string,
        created_dt?: string
        modefied_dt?: string
    }
    export interface ICSVImport {
        import_id: string,
        in_queue: string,
        created_dt: string,
        modified_dt: string
    }
}

export const fetchGetBasicInfoService = forwardOperation(GetBasicInfoApi.operations, 'fetch');
export const useFetchGetBasicInfoService = forwardOperation(GetBasicInfoApi.operations, 'useFetch');
export const useGetBasicInfoService = forwardOperation(GetBasicInfoApi.operations, 'useService');