import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar, IonNote, useIonAlert, IonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { IDealsAddProps } from './DealsAdd.def';
import './DealsAdd.scss';
import { ListingsApi } from 'services/typeaheads/listings';
import { PropertyTypeahead } from 'views/forms/typeaheads/PropertyTypeahead';
import { ContactsTypeahead } from 'views/forms/typeaheads/ContactsTypeahead';
import { useRemoveDealService } from 'services/deals/removeDeal';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { dateFormat, currencyFormat } from 'scripts/helpers/number';
import { useHistory } from 'react-router';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { TextArea } from 'views/forms/textarea/TextArea';
import { NativeDateTimePicker } from 'views/general/NativeDateTimePicker';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';
import { useMultiRef } from 'scripts/hooks/general';
import { format } from 'date-fns';

export const DealsAddApp = (props: IDealsAddProps): JSX.Element => {
    const { visible, deals, dealsId, listing, contact, type, lookup, loading, getError, setDeal, updateDeal, setContact, toggleShow, saveDeal, setListing, reload } = props
    const [confirmClose, setConfirmClose] = useState(false)
    const stages = lookup?.response?.deal_stage.items.filter((s) => s.short_value !== 'N');
    const dealTypes = lookup?.response?.deal_type.items;
    const dealPriorties = lookup?.response?.deal_priority.items;
    const [remove, removeAction] = useRemoveDealService()
    const [ref, assignRef] = useMultiRef<HTMLIonModalElement>();

    const [presentAlert] = useIonAlert();
    let history = useHistory()

    function updateContact() {
        setContact?.([])
        updateDeal?.('contact_id', "")
    }

    function removeListing() {
        setListing?.([])
        if (deals)
            setDeal?.({
                ...deals,
                ["property_harid"]: "",
                ["property_listingid"]: "",
                ["property_for"]: "",
                ["property_full_street_address"]: "",
                ["deal_amount"]: ''
            })
    }

    function updateListing(listing: ListingsApi.IListingsList[]) {
        if (listing && listing[0]) {
            setListing?.(listing)
        }
    }

    function formattedField(value: string) {
        let number = value.replace(/[^0-9]/g, '')
        updateDeal?.("deal_amount", number)

    }



    function getClassName($type?: string): string {
        if ($type === 'A') {
            return 'btn--active'
        } else if ($type === 'I') {
            return 'btn--contract'
        } else if ($type === 'C') {
            return 'btn--close'
        } else if ($type === 'F') {
            return 'btn--fellthrough'
        }
        return '';
    }

    const close = () => {
        let dealsObj: any = deals
        delete dealsObj.deal_stage
        delete dealsObj.deal_stage_text
        if (props.contactId) {
            delete dealsObj.contact_id
        }
        let isEmpty = Object.values(dealsObj).every(x => x === null || x === '');
        if (!isEmpty) {
            setConfirmClose(true)
        } else {
            toggleShow?.()
        }
    }

    useServiceDidSucceeded(remove, () => {
        let url = typeof type === 'undefined' ? '' : type === 'expired' ? '-expired' : type === 'upcoming' ? '-upcoming' : '';
        displayMessage("The deal successfully Deleted")
        history.push('/deals' + url);

    })

    useEffect(() => {
        if (listing && listing[0] && deals) {
            setDeal?.({
                ...deals,
                ["property_harid"]: listing[0]?.harid,
                ["property_listingid"]: listing[0]?.listingid,
                ["property_city"]: listing[0]?.city,
                ["property_state"]: listing[0]?.state,
                ["property_zip"]: listing[0]?.zip,
                ["property_address2"]: listing[0]?.address_2 ? listing[0]?.address_2 : '',
                ["property_for"]: listing[0]?.for,
                ["property_full_street_address"]: listing[0]?.street_address,
                ["property_country"]: listing[0]?.country,
                ["deal_amount"]: listing[0]?.list_price
            })
        }
    }, [listing])

    useEffect(() => {
        if (contact && contact[0]) {
            updateDeal?.('contact_id', contact[0].id.toString())
        }
    }, [contact])
    useEffect(() => {
        if (props.stage && deals) {
            let lookUpStage = stages?.filter((s) => s.short_value === props.stage)[0];
            if (lookUpStage) {
                setDeal?.({
                    ...deals,
                    deal_stage: lookUpStage.short_value,
                    deal_stage_text: lookUpStage.long_value
                })
            }
        } else if (deals) {
            setDeal?.({
                ...deals,
                deal_stage: "",
                deal_stage_text: ""
            })
        }
    }, [props.stage])
    usePopUpAutoFocus(ref);

    return (
        <div>
            <ConfirmClose show={confirmClose}
                affirmative={() => {
                    setConfirmClose(false);
                    toggleShow?.()
                    props.clean?.()

                }}
                negative={() => {
                    setConfirmClose(false);
                }}

            />
            <IonModal ref={assignRef} className="gray_bg" trigger="open-modal" isOpen={visible} onDidDismiss={toggleShow} backdropDismiss={false}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton className='font_weight--semi_bold' color={"blue"} onClick={() => dealsId ? toggleShow?.() : close()}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>{dealsId ? 'Edit Deal' : 'New Deal'}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton color={"blue"} strong={true} onClick={() => {
                                if (dealsId) {
                                    saveDeal?.(dealsId)
                                } else {
                                    saveDeal?.()
                                }
                            }}>
                                Save
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {loading && <Loading />}
                    {!loading &&
                        <>
                            <div className="form_sec mb-5 mt-3">
                                <IonItem>
                                    <IonInput autofocus={true} type="text" placeholder="Deal Title" value={deals?.title} maxlength={99} onIonChange={(e: any) => {
                                        updateDeal?.("title", e.target.value)
                                    }} />
                                </IonItem>
                                {getError?.("title") &&
                                    <IonNote slot="helper" className='input-description input-description__error ml-4'>{getError?.("title")}</IonNote>
                                }
                            </div>
                            <div className="form_sec mb-5 mt-4">
                                <ContactsTypeahead
                                    initialValues={contact ? contact : []}
                                    change={setContact}
                                    remove={updateContact}
                                    nonRemoveable={props.preSelected}
                                    deleted={contact && contact[0] && contact[0]?.deleted === "1" ? true : false}
                                />
                                {getError?.("contact_id") &&
                                    <IonNote slot="helper" className='input-description input-description__error ml-4'> {getError?.("contact_id")}</IonNote>
                                }
                            </div>
                            <div className="form_sec mb-5">
                                <div className="ion-padding">
                                    <div className="pb-3 color_auxiliary font_size--large">Stages</div>
                                    <ToggleButtonGroup className="btn-group btn-group-toggle btn-group--dealstage" type="radio" name="options" defaultValue={deals?.deal_stage ? deals?.deal_stage : props.stage ? props.stage : ''}>
                                        {stages?.map((stage, key) =>
                                            <ToggleButton key={key} className={`btn ${getClassName(stage.short_value)}`} id={stage.short_value} value={stage.short_value} onClick={() => {
                                                if (deals) {
                                                    setDeal?.({
                                                        ...deals,
                                                        estimated_deadline: stage.short_value === 'C' ? format(new Date(), 'yyyy-MM-dd') : deals.estimated_deadline,
                                                        deal_stage: stage.short_value,
                                                        deal_stage_text: stage.long_value
                                                    })
                                                }
                                            }}>
                                                {stage.long_value}
                                            </ToggleButton>
                                        )}
                                    </ToggleButtonGroup>
                                    {getError?.("deal_stage") &&
                                        <IonNote slot="helper" className='input-description input-description__error ml-4'> {getError?.("deal_stage")}</IonNote>
                                    }
                                </div>
                            </div>


                            <div className="form_sec mb-5">
                                <IonItem className="tab__nextpage cursor--hand">
                                    <div className="tab__nextpage_label">
                                        Deal Type
                                    </div>
                                    <div className="tab__nextpage_value">
                                        <IonSelect defaultValue={deals?.deal_type} value={deals?.deal_type} onIonChange={(e: any) => updateDeal?.('deal_type', e.target.value)}
                                        >
                                            {dealTypes?.map((type, index: number) =>
                                                <IonSelectOption key={index} value={type.short_value}>{type.long_value}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </div>

                                </IonItem>
                                {getError?.("deal_type") &&
                                    <IonNote slot="helper" className='input-description input-description__error ml-4'> {getError?.("deal_type")}</IonNote>
                                }

                                <IonItem className="tab__nextpage cursor--hand">
                                    <div className="tab__nextpage_label">
                                        Priority
                                    </div>
                                    <div className="tab__nextpage_value">
                                        <IonSelect defaultValue={deals?.deal_priority} value={deals?.deal_priority} onIonChange={(e: any) => updateDeal?.('deal_priority', e.target.value)}>
                                            {dealPriorties?.map((type, index: number) =>
                                                <IonSelectOption key={index} value={type.short_value}>{type.long_value}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </div>

                                </IonItem>
                            </div>

                            <div className="form_sec mb-5">
                                <PropertyTypeahead
                                    initialValues={listing}
                                    change={setListing}
                                    remove={removeListing}
                                    update={updateListing}
                                    showingCountry={true}
                                    minLength={1}
                                />
                                {getError?.("property_full_street_address") &&
                                    <IonNote slot="helper" className='input-description input-description__error ml-4'> {getError?.("property_full_street_address")}</IonNote>
                                }
                                <IonItem>
                                    <div className='date__input__label pr-3'>Deal Value</div>
                                    <IonInput type="text" placeholder="Deal Amount" value={deals?.deal_amount ? currencyFormat(deals.deal_amount) : ''} maxlength={12} onIonChange={(e: any) => formattedField?.(e.target.value)} />

                                </IonItem>
                                <NativeDateTimePicker
                                    name="Estimated Deadline"
                                    disabled={deals?.deal_stage === 'C'}
                                    label="Deadline"
                                    value={deals?.estimated_deadline}
                                    onChange={(e: any) => {
                                        updateDeal?.('estimated_deadline', dateFormat(e))
                                    }}
                                />

                            </div>

                            <div className="form_sec mb-5 d-none">
                                <IonItem className="tab__nextpage cursor--hand">
                                    <div className="tab__nextpage_label">
                                        Add Collaborators
                                    </div>
                                    <div className="tab__nextpage_value">
                                        None
                                    </div>
                                    <img className="tab__nextpage_arrow" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
                                </IonItem>
                            </div>

                            <div className="form_sec mb-0 pt-3 pb-2 ion-padding">
                                <TextArea showCounter={true} className="form-control border-0" placeholder="Type some description, if you want…" rows={5} value={deals?.notes} onChange={(e: any) => updateDeal?.("notes", e.target.value)} maxLength={3000}> </TextArea>

                                {dealsId ?
                                    <IonItem className="tab__nextpage pb-5 mb-5">
                                        <IonButton className='btn btn--simple btn--red btn--medium w-100' onClick={() =>
                                            presentAlert({
                                                header: 'Are you sure?',
                                                cssClass: 'custom-alert',
                                                buttons: [
                                                    {
                                                        text: 'No',
                                                        cssClass: '',
                                                    },
                                                    {
                                                        text: 'Yes',
                                                        cssClass: '',
                                                        handler: () => {
                                                            removeAction.fetch({ deal_id: dealsId.toString() })
                                                            toggleShow?.()
                                                            reload?.()
                                                        }
                                                    },
                                                ],
                                            })
                                        } >Delete this Deal?</IonButton>
                                    </IonItem>
                                    :
                                    <></>
                                }

                            </div>

                        </>
                    }
                </IonContent>
            </IonModal>
        </div >
    );
}

export const Loading = () => {
    return (
        <div>


            <div className="form_sec mb-5 mt-3">
                <IonItem className="tab__nextpage" id="contact-modal">
                    <div className="tab__nextpage_label">
                        Deal Title
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>
            </div>

            <div className="form_sec mb-5 mt-4">
                <IonItem className="tab__nextpage" id="contact-modal">
                    <div className="tab__nextpage_label">
                        Choose a Contact
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>
            </div>

            <div className="form_sec mb-5">
                <div className="ion-padding">
                    <div className="pb-3 color_auxiliary font_size--large">Priority</div>
                    <div className="row small-gutters">
                        <div className="col-4"><div className=" loading__text_small h-15px"></div></div>
                        <div className="col-4"><div className="loading__text_small h-15px"></div></div>
                        <div className="col-4"><div className="loading__text_small h-15px"></div></div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-center font_weight--semi_bold color_auxiliary">High</div>
                        <div className="col-4 text-center font_weight--semi_bold color_auxiliary">Middle</div>
                        <div className="col-4 text-center font_weight--semi_bold color_auxiliary">Low</div>
                    </div>
                </div>
            </div>


            <div className="form_sec mb-5">
                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Task Type
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>

                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Property Type
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>

                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Deal Amount
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>

                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Estimated Deadline
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>

                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Priority
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>
            </div>

            <div className="form_sec mb-5">
                <IonItem className="tab__nextpage">
                    <div className="tab__nextpage_label">
                        Add Collaborators
                    </div>
                    <div className="tab__nextpage_value">
                        <div className="loading__textbox pt-2 w-150px h-30px"></div>
                    </div>
                </IonItem>

            </div>

            <div className="form_sec mb-0 pt-3 pb-2 ion-padding">
                <div className="loading__textbox pt-2 mb-2 h-80px"></div>
            </div>

        </div >
    );
}

export const ConfirmClose = (props: IDealsAddProps.IDealsConfirmation) => {
    const { show, affirmative, negative } = props
    return (
        <IonAlert
            isOpen={show}
            header="All filled information will be lost, Are you sure you want to cancel?"
            buttons={[
                {
                    text: 'No',
                    cssClass: 'alert-button-cancel',
                    handler: () => negative?.()
                },
                {
                    text: 'Yes',
                    cssClass: 'alert-button-confirm',
                    handler: () => affirmative?.()
                },
            ]}
        ></IonAlert >

    )
}



