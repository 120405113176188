import {  IAddressesSectionProps, IAddressItem, IAddressItemErrors } from './AddressesSection.def';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox';
import './AddressesSection.scss';
import { IonText} from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { SetStateAction, useEffect, useState } from 'react';
import { AddressesTypeahead } from 'views/forms/typeaheads/AddressesTypeahead';
//import { isString } from 'lodash-es';
import { useFetchLookupsService } from 'services/general/lookups';
import { DropDown } from 'views/forms/dropdowns/DropDown';
import { Button} from 'views/forms/buttons/Button';
import { allEmptyOrNone,allEmpty } from 'scripts/helpers/texts';

//import { useParams } from 'react-router';
const emptyAddress = () => ({ 
  address1:'',
  address2:'',
  address_type:'',
  city:'',
  zipcode:"",
  state:"",
  country:""
})

export const AddressesSectionDesktop = (props:IAddressesSectionProps): JSX.Element =>
{
    const { initialItems, onItemsChange } = props;
    const [items, setAddresses] = useState<Array<IAddressItem>>([]);
    const [ ,notifyChange] = useValidateConsumer(props.validate, validate);
    const [errors, updateErrors] = useState<IAddressItemErrors[]>([]);
    const maxItems = props.maxItems ?? 10;
    const [lookupData] = useFetchLookupsService('address_type')

    useEffect(() => {
      if(initialItems === items) { return; }
      
      const addresses = initialItems ?? [];
      setAddresses(addresses.length > maxItems ? addresses.slice(0, maxItems): addresses);
      notifyChange()
      
    }, [initialItems]);

    const fieldCheck = (key: any, required: boolean = true): boolean => {
        let property = key;
        const value = property?.toString()?.replaceAll("  ","")
        if (!value || value === '') {
          return !required;
        }

        if (value.length < 3) {
          return false;
        }
        return true;
  };



  function validate() {
    if(items.length===0){return true;}
    const fieldEmptyMsg='This field is required';

    const form = items.map((item)=>{
      const errors:IAddressItemErrors = {};
     // const addressType = (item.address_type || 'None') !== 'None'? item.address_type: '';

      if(allEmpty(item.address1, item.city, item.zipcode)) {  //No error if any of these fields empty
        return errors; }
      
      if(allEmpty(item.city, item.zipcode)) {errors.address1 = !fieldCheck(item.address1) ? fieldEmptyMsg :'';}
      if(allEmpty(item.address1, item.zipcode)) {errors.city = !fieldCheck(item.city) ? fieldEmptyMsg : '';}
      if(allEmpty(item.address1, item.city)){ errors.zipcode = !fieldCheck(item.zipcode) ? fieldEmptyMsg : '';}
      
      return errors;
    });
    
  /*const form:Array<IAddressItem>=[]; 
    items.map((item )=>{
    form.push({
      ...item,
      address1: {
        value: item.address1?.value,
        error: !fieldCheck(item.address1) ? fieldEmptyMsg : '',
      },
      city: {
        value: item.city.value,
        error: !fieldCheck(item.city) ? fieldEmptyMsg : '',
      },
      zipcode: {
        value: item.zipcode?.value,
        error: !fieldCheck(item.zipcode) ? fieldEmptyMsg : '',
      }
    });
})*/
    updateErrors(form);

    const isValid = form.every((item)=> Object.entries(item).every(([, value]) => !value));
    return isValid;
    
  }

  const handleAddNew = () => {
    updateErrors([...errors,{}]);
    updateAddresses([ ...items, emptyAddress() ]);
    }

  const handleRemoveAddress = (index: number) => {
    items.splice(index, 1);
    errors.splice(index, 1);
    updateErrors([...errors]);
    updateAddresses([...items]);
  };

  const handleChangeField = (key:number, name:string, value:string) =>
  {
    updateAddresses((current:any) => {
      if(!current[key]) { return current; }
      current[key][name] = value;
      notifyChange();
      //validate();
      return current;
    });

    updateErrors((current:any) => {
      if(!current[key]) { current[key] = {}; }
      current[key][name] = '';
      return [ ...current ];
    });
  }

  const updateAddresses = (value:SetStateAction<IAddressItem[]>) =>
  {
    if('call' in value) {
      return setAddresses((current:IAddressItem[]) => {
        const result = value(current);
        onItemsChange?.(result);
        return [ ...result ];
      });
    }
    
    setAddresses?.(value);
    onItemsChange?.(items);
    //validate()
  };

    const isAddressList = !!items?.length;
    return (<>
       <div className="card p-0 mb-5">
             <IonText className="sec_title font-weight_semi-bold  ml-4 mt-4 pt-2">Address</IonText>
           
            <div className="secpadding_left secpadding_right">
            {isAddressList && items.map((item,key)=>
              <FormView
                key={key}
                addressList={items}
                address={item}
                errors={errors[key]!}
                onRemove={() => handleRemoveAddress(key)}
                onUpdate={(name, value) => handleChangeField(key, name, value)}
                lookupData={lookupData.response?.address_type?.items}
              />
            )}
            {items.length<10&&<div className="addable_item pb-3 pt-3 border-top" onClick={handleAddNew}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Address</div>
            </div>} 
         </div> </div>
     </>)
}

    const FormView = (props: IAddressesSectionProps.AppFormViewProps): JSX.Element => {
    const { address, errors, onRemove, onUpdate,lookupData ,addressList} = props;
    //const [address1, setAddress1] = useState({ value: '', error: '' });
  console.log("addressList",addressList)
   // const index = address? addressList.indexOf(address): addressList.length;
    const onChange=(value:any)=>{
      onUpdate("address1",String(value?.address1?.replace(/\s{2,}/g, ' ') ?? ''));
      onUpdate("zipcode",String(value?.zipcode)); 
      onUpdate("city",String(value?.city?.replace(/\s{2,}/g, ' ') ?? ''))
      onUpdate("state",String(value?.state?.replace(/\s{2,}/g, ' ') ?? ''))
      onUpdate("country",String(value?.country?.replace(/\s{2,}/g, ' ') ?? ''))
    }
 
    return (
      <div className="mb-2">
        <div className="row">
            {/** <div className="col-auto align-self-center">
          <div className="delete_item d-flex">
              <img onClick={()=>onRemove()}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
              <div className="delete_item_value">Addr. {index+1} </div>
              <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
            </div>
          </div>*/}
          <div className="col">
      {/**   <IonItem style={{marginTop:6}}>                                                
              <IonSelect value={address?.address_type} name='address_type'  placeholder="Address Type" onIonChange={(e) => onUpdate("address_type",String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}>
                <IonSelectOption value="None">None</IonSelectOption>
                <IonSelectOption value="Home">Home address</IonSelectOption>
                <IonSelectOption value="Business">Business address</IonSelectOption>
                <IonSelectOption value="Billing">Billing address</IonSelectOption>
                <IonSelectOption value="Shipping">Shipping address</IonSelectOption>
                <IonSelectOption value="Contract">Contract address</IonSelectOption>
                <IonSelectOption value="POBox">P.O. Box.</IonSelectOption>
              </IonSelect>
            </IonItem> */}
            {/**  <label className='mt-4' htmlFor="AddressType">Address Type</label>
                <DropDown id="AddressType" value={address?.address_type} className="custom-select custom-select_large mt-2" containerClassName="mb-4" error={errors?.address_type} onChange={(e) => onUpdate("address_type",String(e.target.value?.replace(/\s{2,}/g, ' ') ?? ''))}> 
                        <option value="None">None</option>
                        <option value="Home">Home address</option>
                        <option value="Business">Business address</option>
                        <option value="Billing">Billing address</option>
                        <option value="Shipping">Shipping address</option>
                        <option value="Contract">Contract address</option>
                        <option value="POBox">P.O. Box.</option>   
                </DropDown> */}
            <label className='mt-4' htmlFor="address_type">Address Type</label>
                <DropDown id="address_type" error={errors?.address_type} value={address?.address_type} className="custom-select custom-select_large" containerClassName="mb-4" onChange={(e)=>onUpdate("address_type",String(e.target.value?.replace(/\s{2,}/g, ' ') ?? ''))}>
                    {lookupData?.map((item, index) => (
                        <option key={index} value={item.short_value}>{item.long_value}</option>
                    ))}
                </DropDown>
              <label htmlFor="AddressType">Address 1</label>            
              <AddressesTypeahead  initialValue={address} className='form-control' onTextChange={(value:string)=>onUpdate("address1",String(value?.replace(/\s{2,}/g, ' ') ?? ''))}  error={errors?.address1} change={onChange}/>
               
                <Textbox value={address?.address2} name="address2" type='text' placeholder='Address 2' maxlength={50} id="Address 2" label='Address 2' containerClassName='mt-4' error={errors?.address2} onIonChange={(e) => onUpdate(e.target.name, e.detail.value?.replace(/\s{2,}/g, ' ') ?? '') } ></Textbox>
                <div className="row">
                    <div className="col-6">
                        <Textbox value={address?.city} name="city" type='text' placeholder='City' maxlength={50}  id="City" aria-describedby="City" containerClassName='mt-4' label='City' error={errors?.city} onIonChange={(e) =>  onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? '') )}></Textbox>
                    </div>
                    <div className="col-6">
                        <Textbox value={address?.zipcode || ''} name='zipcode' placeholder='Zip Code' maxlength={10} type='text' id="ZipCode" containerClassName='mt-4' aria-describedby="ZipCode" label='Zip Code' error={errors?.zipcode} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}></Textbox>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Textbox value={address?.state} name="state" type='text' placeholder='State' maxlength={50}  id="State" aria-describedby="State" containerClassName='mt-4' label='State' error={errors?.state} onIonChange={(e) =>  onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? '') )}></Textbox>
                    </div>
                    <div className="col-6">
                        <Textbox value={address?.country || ''} name='country' placeholder='Country' maxlength={15} type='text' id="country" containerClassName='mt-4' aria-describedby="Country" label='Country' error={errors?.country} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}></Textbox>
                    </div>
                </div>
           {/**  <AddressesTypeahead initialValue={address} className="form-control" onTextChange={(value:string)=>onUpdate("address1",String(value?.replace(/\s{2,}/g, ' ') ?? ''))}  error={errors?.address1} change={onChange} />
            <Textbox  value={address?.address2} placeholder='Address 2' name='address2' maxlength={50} className="form-control" containerClassName="mb-4" id="Address" aria-describedby="Address" onIonChange={(e) => onUpdate(e.target.name, e.detail.value?.replace(/\s{2,}/g, ' ') ?? '')} ></Textbox> */}         
          {/**   <Textbox value={address?.city} name='city' placeholder='State' maxlength={50} className="form-control" id="City" aria-describedby="City" error={errors?.city} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
            <Textbox value={address?.zipcode || ''} name='zipcode' placeholder='Zip Code' maxlength={15} type='number' className="form-control" id="ZipCode" aria-describedby="ZipCode" error={errors?.zipcode} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>*/}
          </div>
        </div>
        <div className="address__row__links text-right" style={{textAlign:"end"}} >
          <Button kind='shapeless_red' size='medium' className='mr-2' onClick={()=>onRemove()} clickConfirmation={!allEmptyOrNone(address.address1, address.city,address.address2??"",address.zipcode,address.address_type)?'Are you sure you want to delete this address?':undefined}>Delete</Button>
          </div>
      </div>        
    )
}