import { Redirect, RouteProps as ReactRouteProps, match } from "react-router";
import { OnboardingRouteProps, RouteProps } from "types/react";
import { MakeOptional } from "types/utilities";
import { PublicRoute, PrivateRoute, OnboardingRoute, MemberRoute } from "./routes";

type BuilderRouteProps<T extends RouteProps = RouteProps> = MakeOptional<T, 'title'>;

interface IRouteGroupProps<Path extends string = string>
{
    path:Path;
    title?:string;
    render?:ReactRouteProps<Path>['render']
    children?:ReactRouteProps<Path>['children']
}

interface IRedirectProps extends Omit<BuilderRouteProps, 'render' | 'children' | 'component'>
{
    to:string;
}

export interface IRouteRoot extends Omit<RouteBuilder, 'Group' | 'EndGroup'>
{
    Group(props:IRouteGroupProps): IRouteGroup<IRouteRoot>;
}

export type IRouteGroup<TParent> = Omit<RouteBuilder<TParent>, 'Build'>;

export class RouteBuilder<TParent = undefined>
{
    private result:Array<RouteBuilder<this> | JSX.Element>;
    public static New():IRouteRoot { return new RouteBuilder(undefined, ['']); }

    private constructor(
        private readonly previous:TParent,
        private readonly paths:Array<string | readonly string[]>,
        private readonly titles:string[] = ['HAR CRM'],
    )
    {
        this.result = [];
    }

    public Group(props:IRouteGroupProps)
    {
        const { paths, titles } = this.increment(props.path, props.title);
        const group = new RouteBuilder(this, paths, titles);
        this.result.push(group);
        return group;
    }

    public Public(props:BuilderRouteProps)
    {
        this.createRoute(PublicRoute, props);
        return this;
    }

    public Private(props:BuilderRouteProps)
    {
        this.createRoute(PrivateRoute, props);
        return this;
    }

    public Member(props:BuilderRouteProps)
    {
        this.createRoute(MemberRoute, props);
        return this;
    }

    public Onboarding(props:BuilderRouteProps<OnboardingRouteProps>)
    {
        this.createRoute(OnboardingRoute, props);
        return this;
    }

    public Redirect(props:IRedirectProps)
    {
        const { to, ...redirectProps } = props;
        const children = to.startsWith('/')? <Redirect to={to} />: undefined;
        const render = !to.startsWith('/')? ({ match }:{ match:match }) => <Redirect to={`${match.url}/${to}`} />: undefined;
        const routeProps:BuilderRouteProps = { ...redirectProps, children, render };
        this.createRoute(PublicRoute, routeProps);
        return this;
    }

    public EndGroup(): TParent
    {
        return this.previous;
    }

    public Build(): JSX.Element[]
    {
        return this.result.flatMap((item) => ('Build' in item? item.Build(): [item]));
    }

    private createRoute<T extends RouteProps>(Route:(props:T) => JSX.Element, props:BuilderRouteProps<T>)
    {
        const { paths, titles } = this.increment(props.path ?? '/', props.title);
        const path = this.buildPath(paths);
        const title = titles.reverse().join(' - ');
        this.result.push(<Route {...props as T} path={path} title={title} key='route' />);
        return this;
    }

    private increment(path:string | readonly string[], title?:string)
    {
        const paths = [...this.paths];
        const titles = [...this.titles];
        paths.push(path);
        if(title) { titles.push(title); }
        return { paths, titles };
    }

    private buildPath(paths:Array<string | readonly string[]>)
    {
        const finalPaths = paths.reduce<string[]>((paths, item) => {

            const result = new Array<string>();
            const segments = Array.isArray(item)? item: [item];
            for(let segment of segments)
            {
                for(let path of paths) { result.push(path + segment); }
            }

            return result;

        }, [''] as string[]);

        return finalPaths.length === 1? finalPaths[0]!: finalPaths;
    };
}