import { IEmailDetailProps } from './EmailDetail.def';
import './EmailDetail.scss';
import { useEffect, useState } from 'react';
import { useEmailDetailsService } from 'services/emails/emailDetails';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { EmailDetailsApi } from 'services/emails/emailDetails';
import { Avatar } from 'views/contacts/Avatar';
import { useMarkEmailReadService } from 'services/emails/markEmailRead';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { parseISO, format, getMonth } from 'date-fns'
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { getMonthName, strToDate } from 'scripts/helpers/number';
import { getDayNumber } from 'scripts/helpers/dates';
import { useHistory } from 'react-router';
import { MultipleRecipientsPopover } from './EmailDetail.common';
import { joinWithSpace } from 'scripts/helpers/texts';
import { SendEmailsApi } from 'services/emails/sendEmails';
import { fetchDownloadAttachmentService } from 'services/emails/downloadAttachment';
import { checkInlineAttachments, replaceInlineImages } from '../hooks/emailHook';
import { documentAttachOutline } from 'ionicons/icons';
import { ContactTitle } from 'views/general/ContactTitle';
import { ContactDetailPopover } from 'views/contacts/popovers/ContactDetailPopover';


export const EmailDetailDesktop = (props: IEmailDetailProps): JSX.Element => {
    const { id, type, reload, connectionStatus, remove, openMessage, createDraft, setReload, setCounter } = props
    const [emailService, emailServiceAction] = useEmailDetailsService();
    const [loadingAttachments, setLoadingAttachment] = useState<string[]>()
    const [showContactDetailPopup, setShowContactDetailPopup] = useState(false)
    //const [attachment, startDownloading] = useDownloadAttachmentService()
    const [, markedReadServiceAction] = useMarkEmailReadService()
    const [email, setEmail] = useState<EmailDetailsApi.Response>();
    const isContactCreated = !!email?.contact_id;
    const history = useHistory()

    const contactPreview: number[] = [];
    for (let i = 0; i < 2; i++) {
        contactPreview[i] = i
    }

    const getEmailDetail = (id: string) => {
        if (type) {
            emailServiceAction.fetch({ id: id, type: type })
        }
    }

    function markedEmail(id: string, isLead: string) {
        if (type === 'inbox' || type === 'lead') {
            markedReadServiceAction.fetch({ email_ids: [id] })
            setCounter?.(id, type, isLead === '1' ? true : undefined)
        }
    }



    const requestDownloadAttachment = async (message_id: string, attachment_id: string, content_type: string) => {
        const promise = fetchDownloadAttachmentService({ message_uid: message_id, attachment_id: attachment_id, content_type: content_type })
        setLoadingAttachment([...loadingAttachments ? loadingAttachments : [], attachment_id])
        const file = await promise
        if (file) {
            downloadAttachment(attachment_id, file)
        }
    }


    function downloadAttachment(attachment_id: string, blob: any) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute("download", "")

        a.target = "_blank"
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setLoadingAttachment(loadingAttachments?.filter((file: any) => file.id !== attachment_id) && [])
    }

    useServiceDidSucceeded(emailService, (response) => {
        const checkInlineAttachment = checkInlineAttachments(response)
        let message = response.message.replace(/<a/g, '<a target="_blank"')
        setEmail({ ...response, message: checkInlineAttachment.length > 0 ? replaceInlineImages(message, checkInlineAttachment) : message })
        if (response.read === "0") {
            markedEmail(response.id, response.is_lead)
        }
    })

    useEffect(() => {
        if (type && id) {
            getEmailDetail(id)
        }
    }, [type])

    useEffect(() => {
        if (type && id) {
            getEmailDetail(id)
        }
    }, [id])

    useEffect(() => {
        if (reload && id) {
            getEmailDetail(id)
            setReload?.(false)
        }
    }, [reload])
    return (
        <>
            <ContactDetailPopover
                show={showContactDetailPopup}
                viewProfile={() => {
                    setShowContactDetailPopup(showContactDetailPopup ? false : true);
                    setTimeout(() => {
                        history.push('/contacts/' + email?.contact_id + '/overview', { referrer: '/emails/inbox' })
                    }, 400)
                }}
                id={!!email?.contact_id ? parseInt(email?.contact_id) : 0}
                toggleShow={() => setShowContactDetailPopup(false)}
            // reload={reload}
            />
            {emailService.loading ?
                <Loading />
                :
                <>
                    {!id &&
                        <Empty {...props} />
                    }

                    {id &&

                        <div className="details detials_preview border-bottom ">
                            {(type === 'inbox' || type === 'lead') && !isContactCreated &&
                                <div className={joinWithSpace('p-3 border-color--cloudy-sky-light border-bottom', !isContactCreated && 'bg_color_har_blue')}>
                                    <div className="row">
                                        <div className="col align-self-center">
                                        </div>

                                        <div className="col-auto">
                                            <IonButton className="btn btn--border_invers btn--medium btn--inlineicon" onClick={() => {
                                                history.push(`/contacts/create`, {
                                                    first_name: email?.first_name,
                                                    last_name: email?.last_name,
                                                    primary_email: email?.from_address
                                                })
                                            }}><img src="https://content.harstatic.com/media/icons/user_white.svg" /><span>Add to Contact</span></IonButton>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="email_detail_inner">
                                <div className={`email_body__title position-sticky border-bottom pb-2`}>
                                    <div className="row pb-2">
                                        <div className="col font_weight font_weight--semi_bold font_size--large "> {email?.subject}</div>
                                        {(type === 'inbox' || type === 'lead') && isContactCreated &&
                                            <div className="col-auto d-flex">
                                                <div className="mr-2 align-self-center ">
                                                    <img className="w-24px" src="https://content.harstatic.com/media/icons/greencheck_boldborder.svg" />
                                                </div>
                                                <a onClick={() => history.push('/contacts/' + email?.contact_id + '/overview', { referrer: '/emails/' + type })} className="font_weight--bold font_size--medium align-self-center color_available cursor--hand">Existing Contact</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="row pb-2">
                                        {(type === 'inbox' || type === 'lead') &&
                                            <div className="col">
                                                <a className={`agent_signature agent_signature__large ${isContactCreated && `cursor--hand`} mb-2`} onClick={() => isContactCreated && setShowContactDetailPopup(true)}>
                                                    {email?.contact_id &&
                                                        <Avatar
                                                            id={email?.contact_id}
                                                            firstName={email?.first_name}
                                                            lastName={email?.last_name}
                                                            photo={email.photo_url}
                                                            referrer={'/emails/inbox'}
                                                        //  link={isContactCreated ? '/contacts/' + email?.contact_id + '/overview' : undefined}

                                                        />
                                                    }
                                                    <div className="agent_signature__text" onClick={() => isContactCreated && setShowContactDetailPopup(true)}>
                                                        <div className='d-flex'>
                                                            <div className="agent_signature__text__title">
                                                                {email?.first_name || email?.last_name ? email.first_name + " " + email.last_name : email?.from_address}
                                                            </div>
                                                        </div>
                                                        <div className="agent_signature__text__address">{email?.from_address}{/*<br />To Me */}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        }

                                        {type === 'sent' && email?.recipients[0] &&

                                            <div className="col">
                                                <a className={`agent_signature agent_signature__large mb-2 ${email?.recipients[0]?.id && `cursor--hand`}`}>
                                                    <Avatar
                                                        id={email.recipients[0].id}
                                                        firstName={email.recipients[0].first_name}
                                                        lastName={email.recipients[0].last_name}
                                                        photo={email.recipients[0].photo_url}
                                                        referrer={'/emails/sent'}
                                                        link={isContactCreated ? '/contacts/' + email.recipients[0].id + '/overview' : undefined}
                                                    />

                                                    <div className="agent_signature__text" >
                                                        <div className='d-flex'>
                                                            <div className="agent_signature__text__title" onClick={() => email?.recipients[0]?.id ? history.push('/contacts/' + email.recipients[0].id + '/overview', { referrer: '/emails/sent' }) : ''}>
                                                                <ContactTitle contact={email.recipients[0]} />
                                                            </div>
                                                            {email.recipients.length > 1 && <MultipleRecipientsPopover recipients={email.recipients} />}
                                                        </div>
                                                        <div className="agent_signature__text__address" onClick={() => email?.recipients[0]?.id ? history.push('/contacts/' + email.recipients[0].id + '/overview', { referrer: '/emails/sent' }) : ''}>{email.recipients[0].email}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        }
                                        {type === 'draft' &&
                                            <div className="col">
                                                {email?.recipients[0] &&
                                                    <a className={`agent_signature agent_signature__large mb-2 ${email?.recipients[0]?.id && `cursor--hand`}`}>
                                                        <Avatar
                                                            id={email.recipients[0].id}
                                                            firstName={email.recipients[0].first_name}
                                                            lastName={email.recipients[0].last_name}
                                                            photo={email.recipients[0].photo_url}
                                                            referrer={'/emails/sent'}
                                                            link={email.recipients[0].id ? '/contacts/' + email.recipients[0].id + '/overview' : undefined}
                                                        />

                                                        <div className="agent_signature__text" >
                                                            <div className='d-flex'>
                                                                <div className="agent_signature__text__title" onClick={() => email?.recipients[0]?.id ? history.push('/contacts/' + email.recipients[0].id + '/overview', { referrer: '/emails/draft' }) : ''}>
                                                                    <ContactTitle contact={email.recipients[0]} />
                                                                </div>
                                                                {email.recipients.length > 1 && <MultipleRecipientsPopover recipients={email.recipients} />}
                                                            </div>
                                                            <div className="agent_signature__text__address" onClick={() => email?.recipients[0]?.id ? history.push('/contacts/' + email.recipients[0].id + '/overview', { referrer: '/emails/draft' }) : ''}>{email.recipients[0].email}</div>
                                                        </div>
                                                    </a>
                                                }
                                            </div>
                                        }
                                        {connectionStatus &&
                                            <div className="col-auto">
                                                {type !== 'draft' &&
                                                    <>
                                                        <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-top'}>Reply</Tooltip>} >
                                                            <a className="email_icon" onClick={() => email ? createDraft?.(email, true) : ''}><img className=' w-32px' src="https://content.harstatic.com/media/icons/crm/icon-reply_.svg" /></a>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-top'}>Forward</Tooltip>} >
                                                            <a className="email_icon" onClick={() => email ? createDraft?.(email, false) : ''}><img className=' w-32px' src="https://content.harstatic.com/media/icons/crm/icon-forward_.svg" /></a>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-top'}>Delete</Tooltip>} >
                                                            <a className="email_icon" onClick={() => remove?.([email?.id ? email.id : ''])}><img className=' w-32px' src="https://content.harstatic.com/media/icons/crm/icon-trash_.svg" /></a>
                                                        </OverlayTrigger>
                                                    </>
                                                }


                                                {type === 'draft' &&
                                                    <>
                                                        <a className="email_icon" onClick={() => email?.id ? openMessage?.(email.id) : ''} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0738cd" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                        </svg></a>
                                                        <a className="email_icon" onClick={() => remove?.([email?.message_uid ? email.message_uid : ''])}><img className=' w-32px' src="https://content.harstatic.com/media/icons/crm/icon-trash_.svg" /></a>
                                                    </>
                                                }


                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className={`font_size--small_extra pb-2 color_custom_2 text-uppercase ${type === 'draft' ? `pt-3` : `pt-1px`}`}>{email?.date_tag?.toUpperCase() === 'OLDER' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : email?.date_tag?.toUpperCase()} - {email?.created_dt && format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>
                                        </div>
                                        {email?.folder === 'Forwarded' &&
                                            <div className='col pl-0'>
                                                <div className="label_email label_email--forwarded">Forwarded Message</div>
                                            </div>
                                        }
                                        {email?.is_lead === '1' &&
                                            <div className='col pl-0'>
                                                <div className="label_email label_email--lead">Lead</div>
                                            </div>
                                        }


                                    </div>
                                </div>

                                <div className="email_body pt-2">
                                    {connectionStatus && email?.attachments && email?.attachments?.length > 0 &&
                                        <div className='pb-3 pl-1 d-flex flex-wrap'>
                                            {
                                                email.attachments.map((file: SendEmailsApi.IEmailAttachment, key: number) =>

                                                    !loadingAttachments?.some(x => x === file.id) ?
                                                        <div key={key} className='row pl-3 pb-2 cursor--hand mr-4' onClick={() => file.id ? requestDownloadAttachment(email.message_uid, file.id, file.content_type ? file.content_type : '') : ""}>
                                                            <IonIcon icon={documentAttachOutline} size='small' ></IonIcon>
                                                            <div className='ml-1'>{file.file_name}</div>
                                                        </div>
                                                        :
                                                        <div key={key} className='row pl-3 pb-2'>
                                                            <IonText >Please wait your attachments is downloading.</IonText>
                                                        </div>
                                                )

                                            }
                                        </div>
                                    }

                                    {type === 'forwarded' &&
                                        <div className={`font_size--small_extra text-uppercase color_custom_2`}>{email?.date_tag?.toUpperCase() === 'OLDER' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : email?.date_tag?.toUpperCase()} - {email?.created_dt && format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>
                                    }
                                    <h3 className='d-none'>{email?.subject}</h3>
                                    {email?.message &&
                                        <iframe className="email_body__content w-100 h-700px border-0" srcDoc={email?.message}></iframe>
                                    }

                                    <div className="email_body__attachment border-0" >
                                        {/* hide for presentation
                    <div className="font_size--large font_weight--bold pb-2">5 attachments</div>
                    
                    <div className="attach_items row">
                        
                        <div className="col-auto">
                            <div className="attach_item">
                                <div className="attach_item__img"></div>
                                <div className="attach_item__title">This can be your next house.jpg</div>
                                <div className="attach_item__size">42KB</div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="attach_item">
                                <div className="attach_item__img"></div>
                                <div className="attach_item__title">beautiful_home.jpg</div>
                                <div className="attach_item__size">42KB</div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="attach_item">
                                <div className="attach_item__img"></div>
                                <div className="attach_item__title">modern-home-2.png</div>
                                <div className="attach_item__size">42KB</div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="attach_item">
                                <div className="attach_item__img"></div>
                                <div className="attach_item__title">This can be your next house.jpg</div>
                                <div className="attach_item__size">42KB</div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="attach_item">
                                <div className="attach_item__img"></div>
                                <div className="attach_item__title">This can be your next house.jpg</div>
                                <div className="attach_item__size">42KB</div>
                            </div>
                        </div>
                    </div>
                    */}
                                    </div>
                                </div>
                            </div >

                        </div >
                    }
                </>
            }
        </>
    );
}

const Empty = (props: IEmailDetailProps) => {
    const { mails, type } = props
    return (
        <div className="details details_empty pt-md-5 h-100">
            <div className="d-flex h-100">
                <div className="w-100 align-self-center">
                    <div className="text-center font_size--medium pt-md-5 opacity-64pr">
                        {type === 'inbox' &&
                            <div>
                                Currently you have {mails?.total_unread} unread emails<br />
                                among {mails?.total} emails you've received
                            </div>
                        }
                        {type === 'sent' &&
                            <div>
                                Currently, you have {mails?.total} sent emails
                            </div>
                        }
                        {type === 'draft' &&
                            <div>
                                Currently, you have {mails?.total_draft} draft emails
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const Loading = () => {
    return (
        <div className="details detials_preview">

            <div className="row mb-3">
                <div className="col">
                    <div className="card--email cursor--hand ">
                        <div className="card--email__agent"><div className="loading__photo mr-3 w-40px h-40px"></div></div>
                        <div className="card--emaill__content overflow-hidden w-100">
                            <div className="card--email__content_heading d-flex">
                                <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                            </div>
                            <div className="card--email__text text-truncate">
                                <div className="loading__text_small w-100"></div>
                            </div>
                            <div className="loading__text_smaller w-85px"></div>
                        </div>
                    </div>
                </div>
                <div className="col-auto d-flex align-self-lg-center">
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                </div>
            </div>
            <div className="email_detail_inner">
                <div className="email_body pt-3">
                    <div className="loading__text_small mb-3 w-120px"></div>
                    <div className="loading__text mb-4 w-200px"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-4 w-70pr"></div>

                    <div className="border-top mt-3 pt-4">
                        <div className="loading__text_small mb-3 w-70pr"></div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
