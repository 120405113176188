import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IEasyWayCSVResultProps } from './EasyWayCSVResult.def';
import './EasyWayCSVResult.scss';
import { useEffect, useRef } from 'react';
import { isProduction } from 'scripts/application/settings';
//import { IonSpinner, IonText } from '@ionic/react';
import { useGetCSVContentService } from 'services/contacts/csvmapping/getCSVContent';
import { IonSpinner, IonText } from '@ionic/react';

export const EasyWayCSVResultDesktop = (props: IEasyWayCSVResultProps): JSX.Element => {
    const { show, import_id, onClose } = props
    const [importSer, getImportSer] = useGetCSVContentService()
    const modalRef = useRef<HTMLIonModalElement>(null)

    const fetchImportResult = (id: string) => {

        getImportSer.fetch({ id: id, queue: !isProduction ? '1' : '0' })
    }
    const LoadingState = () => {
        return (
            <div className='pt-3 pb-3' >
                <div className='mx-auto d-flex w-290px min-h-260px'>
                    <IonSpinner className='col-auto' name="bubbles"></IonSpinner>
                    <IonText className='col pt-1'>Please wait a moment as we compile the results.</IonText>
                </div>
            </div>
        )
    }

    const dismiss = () => {

        modalRef.current?.dismiss()
    }


    useEffect(() => {
        if (show && import_id) {
            fetchImportResult(import_id)
        }
    }, [show])

    return (
        <DefaultPopUp modalRef={modalRef} isOpen={show} title="Easy Way Result" className="custom--modal" closeButton onIonModalWillDismiss={() => onClose?.()}>
            <DefaultPopUp.Body>

                <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
                    {importSer.loading && <LoadingState />}
                    {!importSer.loading && importSer.response &&
                        <>
                            <div className="text-center pb-5 pt-2">
                                {importSer.response.added_count !== "0" &&
                                    <img className="mb-3 w-60px" src="https://content.harstatic.com/media/icons/checkmark_green.svg" />
                                }
                                <div className="font_size--large_extra font_weight--semi_bold mb-4">{`${importSer.response.added_count ?? '0'} Contacts from ${importSer.response.rows_count} uploaded`}<br />into the HAR CRM</div>
                            </div>

                            {Number(importSer.response.errors_count) > 0 &&
                                <div className='mb-4 border-bottom  card p-4'>
                                    <div className="d-block mb-3 border_radius--pudgy">
                                        <div className="d-flex">
                                            <div className="align_self_center mr-2"><img className="w-16px" src="https://content.harstatic.com/media/icons/icon-warning.svg" /></div>
                                            <div className="color_sold align-self-center font_weight--semi_bold font_size--medium">{importSer.response.errors_count} contacts could not be imported</div>
                                        </div>
                                    </div>
                                    {importSer.response.errors_file &&
                                        <div>
                                            <a className="d-block mt-1 cursor--hand" onClick={() => {
                                                var downloadFileLink = document.createElement("a");
                                                downloadFileLink.href = importSer.response.errors_file
                                                document.body.appendChild(downloadFileLink);
                                                downloadFileLink.click();
                                                downloadFileLink.remove()
                                            }}>Download CSV file with a list of not uploaded contacts, to fix them</a>
                                        </div>
                                    }

                                </div>
                            }
                            {Number(importSer.response.total_warnings) > 0 &&
                                <div className='mb-4 border-bottom  card p-4'>
                                    <div className=" d-block mb-3 border_radius--pudgy">
                                        <div className="d-flex">
                                            <div className="align_self_center mr-2"><img className="w-16px" src="https://content.harstatic.com/media/icons/icon_danger.svg" /></div>
                                            <div className="color_pending align-self-center font_weight--semi_bold font_size--medium">Some contact has warnings But Contacts<br />are uploaded</div>
                                        </div>
                                    </div>
                                    {importSer.response.warnings_file &&
                                        <div>
                                            <a className="d-block mt-1 cursor--hand" onClick={() => {
                                                var downloadFileWarn = document.createElement("a");
                                                downloadFileWarn.href = importSer.response.warnings_file
                                                document.body.appendChild(downloadFileWarn);
                                                downloadFileWarn.click();
                                                downloadFileWarn.remove()
                                            }}>Download CSV file with a list of uploaded contacts with some validation warnings, to check them</a>
                                        </div>
                                    }
                                </div>
                            }


                            <div className='text-center pt-4'><a onClick={() => dismiss()} className='btn btn--ordinary'>Close</a></div>

                        </>


                    }


                </div>

            </DefaultPopUp.Body>

        </DefaultPopUp>
    );
}
