import { isEqual } from "lodash";
import { MutableRefObject, ReactElement, RefObject, useEffect, useRef, useState } from "react";
import { useDevice } from "scripts/context";
import { INullable } from "types/general";

export function useEquality<T>(input:T)
{
    const currentInput = useRef(input);
    const { current } = currentInput;
    if(current === input) { return input; }

    const equals = isEqual(input, current);
    if(equals) { return current; }
    return currentInput.current = input;
}

export function useEqualityList<T>(list:ReadonlyArray<T>)
{
    const currentDeps = useRef(list);
    const { current } = currentDeps;
    if(current === list) { return list; }

    const equals = current.length === list.length && list.every((dep, index) => isEqual(dep, current[index]));
    if(equals) { return current; }
    return currentDeps.current = list;
}

/**
 * Stores previous hook value
 * @param value Hook value to be stored
 * @returns Previous Hook value
 */
export function usePrevious<T>(value:T, nullable:true): INullable<T>
export function usePrevious<T>(value:T, nullable?:false): T | undefined
export function usePrevious<T>(value:T, nullable?:boolean)
{
    const ref = useRef<{ value:T }>();
    useEffect(() => { ref.current = { value }; }, [value]); //this code will run when the value of 'value' changes
    const currentValue = ref.current?.value;

    //in the end, return the current ref value.
    if(!nullable) { return currentValue; }
    return {
        hasValue:!!ref.current,
        value:currentValue
    };
}

/**
 * Creates ref and merges with list of other refs
 * @param refs Refs to be merged with created ref
 * @returns Array where first item is created ref, and second item a function for refs assignment (use on your component 'ref' attribute)
 */
export function useMultiRef<T>(...refs:Array<React.Ref<T> | undefined>)
{
    const ref = useRef<T>(null);
    const assignRef = useMergeRef(ref, ...refs);
    return [ref, assignRef] as const;
}

/**
 * Merges a list of refs into a single assigment function
 * @param refs Refs to be merged
 * @returns Function for refs assignment (use on your component 'ref' attribute)
 */
export function useMergeRef<T>(...refs:Array<React.Ref<T> | undefined>)
{
    return (node:T) => refs.forEach(ref => {
        if(!ref) { return; }
        if('current' in ref) { (ref as MutableRefObject<T>).current = node; }
        else { ref(node); }
    });
}


export function useClickOutside<T extends HTMLElement>(initialState:boolean)
{
    const ref = useRef<T>(null);
    const [state, setState] = useClickOutsideMultiple(initialState, [ref]);
    return [ref, state, setState] as const;
}

export function useClickOutsideMultiple(initialState:boolean, refs:RefObject<HTMLElement>[])
{
    const [state, setState] = useState(initialState);

    const handleClickOutside = (event:Event) =>
    {
        for(let ref of refs)
        {
            if(!ref.current) { continue; }
            if (ref.current.contains(event.target as Node)) { return; }
        }

        setState(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return [state, setState] as const;
}


export function useMediaQuery(mediaQuery:string)
{
    const media = useRef(window.matchMedia(mediaQuery));
    const [_, setMatches] = useState(media.current.matches);

    useEffect(() => {
        const update = (x:MediaQueryListEvent) => setMatches(x.matches);
        media.current = window.matchMedia(mediaQuery);
        media.current.addEventListener('change', update);
        return () => media.current.removeEventListener('change', update);
    }, [mediaQuery]);
    
    return media.current.matches;
}

export function useResponsive(): boolean;
export function useResponsive(mediaQuery:string | undefined): boolean;
export function useResponsive<TDesktop, TMobile>(desktopValue:TDesktop, mobileValue:TMobile): TDesktop | TMobile;
export function useResponsive<TDesktop, TMobile>(mediaQuery:string | undefined, desktopValue:TDesktop, mobileValue:TMobile): TDesktop | TMobile;
export function useResponsive(...args:[unknown?, unknown?, unknown?]): unknown | boolean
{
    const mediaQuery = args.length === 1 || arguments.length === 3? args.shift() as string: undefined;
    const isResponsive = useMediaQuery(mediaQuery ?? '(max-width:767px)');
    if(arguments.length < 2) { return isResponsive; }
    return isResponsive? args[1]: args[0];
}


export function useDeviceResponsive<TDesktop, TMobile, TApp>(webDesktop:TDesktop, webMobile:TMobile, app:TApp): TDesktop | TMobile | TApp;
export function useDeviceResponsive<TDesktop, TMobile, TApp>(mediaQuery:string | undefined, webDesktop:TDesktop, webMobile:TMobile, app:TApp): TDesktop | TMobile | TApp;
export function useDeviceResponsive(...args:[unknown?, unknown?, unknown?, unknown?]): unknown | boolean
{
    const mediaQuery = args.length === 4? args.shift() as string: undefined;
    const responsive = useResponsive(mediaQuery, args[0], args[1]);
    const result = useDevice(responsive, args[2]);
    return result;
}


export function useResponsiveChoose<TDesktop extends JSX.Element | ((...args:any[]) => ReactElement), TApp extends JSX.Element | ((...args:any[]) => ReactElement)>
    (Desktop:TDesktop, App:TApp, mediaQuery?:string): TDesktop | TApp
{
    const isResponsive = useResponsive(mediaQuery);
    return isResponsive? App: Desktop;
}

export function useResponsiveRender(Desktop:JSX.Element | (() => ReactElement), App:JSX.Element | (() => ReactElement), mediaQuery?:string)
{
    const Element = useResponsiveChoose(Desktop, App, mediaQuery);
    return 'call' in Element? <Element />: Element;
}

class ElementClassManager
{
    private _map:Map<string, number>;

    public constructor(private readonly element:HTMLElement)
    {
        this._map = new Map();
        /*this._map = new Proxy(new Map(), {
            get(response: any, key) {
                console.warn(key);
                return (...args: any[]) => response[key](...args);
            }
        });*/
    }

    public add(className:string)
    {
        /*window.console.log('add', className, this._map.get(className) ?? 0,
            Array.from(this._map.entries()).reduce((current, [key, value]) => ({ ...current, [key]:value }), {} as Record<string, number>)
        );*/

        if(!this._map.has(className)) { this._map.set(className, 1); }
        else { this._map.set(className, this._map.get(className)! +1); }
        this.element.classList.add(className);

        /*window.console.log('added', className, this._map.get(className)!,
            Array.from(this._map.entries()).reduce((current, [key, value]) => ({ ...current, [key]:value }), {} as Record<string, number>)
        );*/
    };

    public remove(className:string)
    {
        /*window.console.log('remove', className, this._map.get(className) ?? 0,
            Array.from(this._map.entries()).reduce((current, [key, value]) => ({ ...current, [key]:value }), {} as Record<string, number>)
        );*/

        if(!this._map.has(className)) { return; }
        const total = this._map.get(className)!;
        if(total > 1) { this._map.set(className, total -1); }
        else {
            this._map.delete(className);
            this.element.classList.remove(className);
        }

        /*window.console.log('removed', className, this._map.get(className) ?? 0,
            Array.from(this._map.entries()).reduce((current, [key, value]) => ({ ...current, [key]:value }), {} as Record<string, number>)
        );*/
    };
}

namespace ElementClassManager
{
    export const entries = new Map<HTMLElement, ElementClassManager>();
    export const get = (element:HTMLElement) =>
    {
        if(!entries.has(element)) { entries.set(element, new ElementClassManager(element)); }
        return entries.get(element)!;
    }
}

export function useElementClass(element:HTMLElement, ...classNames:Array<string>)
{
    const manager = ElementClassManager.get(element);

    useEffect(
        () => {
            classNames.forEach(manager.add, manager);

            // Clean up
            return () => { classNames.forEach(manager.remove, manager); };
        },
        [classNames]
    );
}

export function useBodyClass(...classNames:Array<string>)
{
    useElementClass(document.body, ...classNames);
}

export function useStickyToggle(ref:RefObject<HTMLElement>)
{
    const [value, setValue] = useState(false);

    useEffect(() => {

        const { current } = ref;
        if(!current) {
            setValue(false);
            return;
        }

        current.style.top = '-1px'; // hack

        const observer = new IntersectionObserver( 
            ([e]) => { setValue(e!.intersectionRatio < 1) },
            {threshold: [1]}
        );
          
        observer.observe(current);

        return () => { observer.unobserve(current); }

    }, [ref])

    return value;
}


type UseClassOnStickyOptions =
    { pinned:string, sticky?:string, target?:HTMLElement | RefObject<HTMLElement> } |
    { pinned?:string, sticky:string, target?:HTMLElement | RefObject<HTMLElement> };

export function useClassOnSticky(ref:RefObject<HTMLElement>, options:UseClassOnStickyOptions)
{
    const classRef = useRef<boolean>();
    const isSticky = useStickyToggle(ref);
    const { pinned, sticky, target = ref } = options;

    const apply = (className:string | undefined, method:'add' | 'remove') => {
        if(!className) { return; }
        //console.log(className, method, classRef.current);
        if(classRef.current === undefined && method === 'remove') { return; }

        const classList = 'current' in target? target.current?.classList: ElementClassManager.get(target);
        if(!classList) { return; }

        className
            .split(' ')
            .filter(x => !!x)
            .forEach(className => classList[method](className));
    }

    useEffect(() => () => {
        if(classRef.current === true) { apply(sticky, 'remove'); }
        if(classRef.current === false) { apply(pinned, 'remove'); }
        classRef.current = undefined;
    }, [ref, pinned, sticky, target]);

    if(classRef.current === isSticky) { return; }

    apply(sticky, isSticky? 'add': 'remove');
    apply(pinned, isSticky? 'remove': 'add');
    classRef.current = isSticky;
}