import { PropsWithChildren } from 'react';
import { useMultiRef } from 'scripts/hooks/general';
import { extractChildren } from 'scripts/utilities/react';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { DefaultPopUp } from '../DefaultPopUp';
import { IConfirmPopUpButtonProps, IConfirmPopUpProps } from './ConfirmPopUp.def';
import './ConfirmPopUp.scss';

export function ConfirmPopUp(props: IConfirmPopUpProps): JSX.Element {
    const { children, modalRef, ...modalProps } = props;
    const [ref, assignRef] = useMultiRef(modalRef);

    const { body, confirmButton, cancelButton } = extractChildren(children, {
        body: ConfirmPopUp.Body,
        confirmButton: ConfirmPopUp.ConfirmButton,
        cancelButton: ConfirmPopUp.CancelButton
    });

    const { omit: omitConfirmButton, ...confirmButtonProps } = confirmButton?.props as IConfirmPopUpButtonProps ?? {};
    const { omit: omitCancelButton, onClick, ...cancelButtonProps } = cancelButton?.props as IConfirmPopUpButtonProps ?? {};
    /*const cancelText = cancelButtonProps.children ?? 'Cancel';
    const confirmText = userConfirmText.children ?? 'Confirm';*/

    const onCancelClick = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        if (props.toggleShow) {
            props.toggleShow()
        } else {
            onClick?.(e);
            if (!e.isDefaultPrevented()) { ref.current?.dismiss(); }
        }
    };

    return (
        <DefaultPopUp modalRef={assignRef} toggleShow={props.toggleShow} {...modalProps} >
            <DefaultPopUp.Body>{body}</DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className='p-2'>
                    <div className="row no-gutters">
                        <div className='col-auto min-w-120px'></div>
                        <div className='col text-center'>
                            {!omitConfirmButton && <div className=" text-center">
                                <PrimaryButton size='medium' children='Confirm' {...confirmButtonProps} />
                            </div>}
                        </div>
                        <div className='col-auto min-w-120px text-right'>
                            {!omitCancelButton && <div className="">
                                <ShapelessButton size='medium' children='Cancel' onClick={onCancelClick} {...cancelButtonProps} />
                            </div>}
                        </div>                    
                    </div>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp>
    );
}

export namespace ConfirmPopUp {
    export function Body(props: PropsWithChildren<{}>) { return <>{props.children}</>; }
    export function CancelButton(_: IConfirmPopUpButtonProps) { return <></>; }
    export function ConfirmButton(_: IConfirmPopUpButtonProps) { return <></>; }
}