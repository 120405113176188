import { isLocal } from "scripts/application/settings";

declare global
{
    interface Window
    {
        _mtm:Record<string, string | number>[];
    }
}

const enabled = !isLocal;
if(enabled) { install(); }

function install()
{
    const _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    (function() {
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]!;
        g.async=true; g.src='https://matomo.har.com/js/container_7YDE7PvL.js'; s.parentNode!.insertBefore(g,s);
    })();
}


export {};