import { ContactTitle } from 'views/general/ContactTitle';
import { ISelectedContactsProps } from './SelectedContacts.def';
import './SelectedContacts.scss';
import { ellipsisHorizontalOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react';

export const SelectedContacts = (props: ISelectedContactsProps): JSX.Element => {
    const { contacts, selectToggle } = props
    return (
        <div>
            <div className="font_size--large font_weight--semi_bold mb-4" tabIndex={0}>{contacts.length} Contacts Selected</div>
            <div className="pb-3 mb-3 border-bottom border-color--cloudy-sky-light max-h-100px overflow-hidden">
                {contacts.map((contact: any, ind: number) =>
                    <>
                        {ind < 10 &&
                            <div key={ind} className="filterpill filterpill--closeable2">
                                <div><ContactTitle contact={contact} /></div>
                                <a onClick={() => {
                                    if (selectToggle) {
                                        selectToggle(contact.id, 0)
                                    }
                                }}></a>
                            </div>
                        }
                    </>
                )}
                {contacts.length >= 10 &&
                    <div className="filterpill--closeable3"><a><IonIcon icon={ellipsisHorizontalOutline}></IonIcon></a></div>

                }
            </div></div>
    );
}
