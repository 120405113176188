import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace AddEmailApi {
    export const url = "/contacts/:contact_id/emails";
    export const method = "POST";
    export const defaultError = "Failed to save email.";
    export const operations = apiOperations(AddEmailApi);
    export const scope = new class AddEmailApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input,
                preferred: input.preferred !== undefined ? (input.preferred ? '1' : '0') : '0'
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                id: parseInt(data.id),
                preferred: data.preferred === '1'
            }
        }
    }();

    export interface Request {
        contact_id: string,
        email: string,
        preferred?: boolean,
    }

    export interface Response {
        id: number;
        preferred: boolean;
    }
}

export const fetchAddEmailService = forwardOperation(AddEmailApi.operations, 'fetch');
export const useFetchAddEmailService = forwardOperation(AddEmailApi.operations, 'useFetch');
export const useAddEmailService = forwardOperation(AddEmailApi.operations, 'useService');