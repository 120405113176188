import { Textbox } from 'views/forms/textboxes/Textbox';
import { ILoginFormState, ILoginScreenFormProps, ILoginScreenProps, initialLoginFormState } from './LoginScreen.def';
import './LoginScreen.scss';
import { ProminentButton } from 'views/forms/buttons/Button';
import { ChangeEvent, EffectCallback, FormEvent, useEffect, useRef, useState } from 'react';
import { IonInputCustomEvent } from '@ionic/core';
import { validateField } from 'scripts/hooks/validateForm';
import { InputChangeEventDetail, IonPage } from '@ionic/react';
import { Badge } from '@awesome-cordova-plugins/badge';
import { LoginApi, useLoginService } from 'services/auth/login';
import { useLogoutService } from 'services/auth/logout';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { handleFailedLogin, handleLogin, handleLogout } from 'scripts/security/LoginService';
import { useIsAuthenticatedService } from 'services/auth/isAuthenticated';
import { Auth } from 'scripts/security/AuthService';
import { LoginFooter } from 'views/auth/LoginFooter';
import { LoginFeedback } from 'views/auth/LoginFeedback';
import { version } from 'scripts/application/settings';
import { RouteComponentProps, useLocation } from 'react-router';
import { loadApiUrl, loadHeaders } from 'scripts/http/serviceFetch';
import { history } from 'scripts/router/history';
import { mailto } from 'scripts/helpers/urls';
import { joinWithSpace } from 'scripts/helpers/texts';
import { Tag } from 'scripts/ui/tag';

export const LoginScreen = (_props:ILoginScreenProps): JSX.Element =>
{
    const [showForm, setShowForm] = useState(false);
    const location = useLocation();
    const [authentication, authenticationService] = useIsAuthenticatedService();
    const [headers, setHeaders] = useState<Record<string, string | number>>();
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const nextUrl = params.get('nexturl');
    Badge.set(0);
    useEffect(() => {
        let formLoad:EffectCallback;
        const authentication = Auth.loginProvider.isAuthenticated().then(isAuthenticated => {
            if(isAuthenticated && Auth.loginProvider.useCookies) { handleLogin(undefined, nextUrl); }
            else if(isAuthenticated) { history.replace(nextUrl || '/onboarding'); }
            else if(Auth.loginProvider.useCookies && action !== 'signout') { authenticationService.fetch(); }
            else { formLoad = loadForm(); }
        });

        return () => {
            authentication.abort();
            formLoad?.();
        }
    }, []);

    useServiceDidSucceeded(authentication, () => { handleLogin(undefined, nextUrl); });
    useServiceDidFailed(authentication, (e) => {
        e.preventDefault?.();
        return loadForm();
    });

    function loadForm()
    {
        let aborted = false;
        setShowForm(true);

        if(!headers) loadHeaders(LoginApi.url).then(headers => {
            if(!aborted) { setHeaders(headers); }
        });
        
        return () => { aborted = true; }
    }

    const versionText = ` v${version}`;
    const showVersion = false;

    return (
        <IonPage>
            <div className="row no-gutters row-eq-height">
                <Tag.Div className="col-12 col-md-6 order-md-0 order-1 min-h-100vh bg-size-cover bg--image login_sreen_bg" cssVars={{ loginScreenBg:'url(https://content.harstatic.com/media/bgs/crm/login_bg_image_2.jpg)' }}></Tag.Div>
                <div className="col-12 col-md-6 order-md-1 order-0 align-self-center pt-5 pt-md-0">
                    <div className="p-3 m-3">
                        <div className="text-center pt-md-0 pt-5">
                            <img className={joinWithSpace(`w-92px h-92px mb-2 d-md-inline-block d-none`, !showVersion && 'mb-4')} src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" alt="HAR Logo" />
                            <img className={joinWithSpace(`w-70px h-70px mb-2  d-md-none d-inline-block`, !showVersion && 'mb-4')} src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" alt="HAR Logo" />
                            {showVersion && <div className="color_carbon font_size--medium mb-3">{versionText}</div>}
                            <div className="font_size--large_extra text-center ml-auto w-310px mr-auto mb-md-5 pb-4">Simple way to organize your leads, contacts, emails and more.</div>
                        </div>
                        {showForm && headers &&
                            <LoginFeedback />
                        }
                        {showForm && headers? <Form nextUrl={nextUrl} headers={headers} />: 'Loading...'}
                    </div>
                    <LoginFooter />
                </div>
            </div>
          
        </IonPage>
    );
}

export const LogoutScreen = () =>
{
    const [request, actions] = useLogoutService();
    const params = new URLSearchParams(window.location.search);
    const nextUrl = params.get('nexturl');

    const handle = () => {
        const process = handleLogout(nextUrl);
        return () => process.abort();
    };

    useEffect(() => {
        if(!Auth.loginProvider.useCookies) { return handle(); }
        actions.fetch();
        return () => actions.abort();
    }, []);

    useServiceDidSucceeded(request, handle);
    useServiceDidFailed(request, handle);

    return (
        <IonPage className='items-center'>
            <div className="login-card flex items-center">
                <div className="container flex items-center  h-20 font-20">
                    <p>{request.error? 'Sign out failed, please try again': 'Signing out...'}</p>
                </div>
            </div>
        </IonPage>
    );
};

export const LoginCallbackScreen = (props:RouteComponentProps) =>
{
    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const token = params.get('har_key');
        const nextUrl = params.get('nexturl');
        if(!token) { return handleFailedLogin(params.get('error'), nextUrl); }

        const sub = handleLogin(token, nextUrl);
        return () => sub.abort();
    }, []);

    return (
        <IonPage className='items-center'>
            <div className="login-card flex items-center justify-center">
                <div className="container flex items-center justify-center h-20 font-20">
                    <p>Signing in...</p>
                </div>
            </div>
        </IonPage>
    )
};

const Form = (props:ILoginScreenFormProps) =>
{
    const [form, setForm] = useState<ILoginFormState>(initialLoginFormState);
    const [passEye, setPassEye] = useState(false);
    const [login, loginService] = useLoginService();
    const usernameRef = useRef<HTMLIonInputElement>(null);
    const passwordRef = useRef<HTMLIonInputElement>(null);
    const [redirectUrl] = useState(() => {
        return Auth.loginProvider.getRedirectLoginUrl(props.nextUrl ?? undefined);
    })

    const handleNativeChanges = (node:HTMLIonInputElement | null, field:keyof ILoginFormState) => {
        const input = node?.children[0] as HTMLInputElement;
        if(!input) { return; }
        
        const listener = (e:Event) => {
            const value = (e.target as any).value;
            setForm(form => ({ ...form, [field]:{ value } }));
        }

        input.addEventListener('change', listener);
        return () => input.removeEventListener('change', listener);
    }

    useEffect(() => handleNativeChanges(usernameRef.current, 'username'), [usernameRef.current]);
    useEffect(() => handleNativeChanges(passwordRef.current, 'password'), [passwordRef.current]);

    useServiceDidSucceeded(login, response => {
        const token = response.har_key;
        const process = handleLogin(token)
        return () => process.abort();
    });

    const hasErrors = !!form.username.error || !!form.password.error;

    const onFocus = (key:'username' | 'password', input:HTMLIonInputElement) => setForm(form => {
        input.getInputElement().then(x => x.select());
        const field = { ...form[key], error:'' };
        return { ...form, [key]:field };
    });

    const onFieldBlur = (key:'username' | 'password', validations?:string[], label?:string) => setForm(form => {
        const value = form[key].value;
        const error = validations? validateField(key, value, validations, label): '';
        if(value === form[key].value && error === form[key].error) { return form; }
        const field = { value, error };
        return { ...form, [key]:field };
    });

    const onFieldChange = (key:'username' | 'password', value?:string | number | null) => setForm(form => {
        value = value?.toString()?.trim() ?? '';
        const field = { value, error:'' };
        return { ...form, [key]:field };
    });

    const onLoginChange = (e:IonInputCustomEvent<InputChangeEventDetail>) => onFieldChange('username', e.detail.value);
    const onLoginFocus = (e:IonInputCustomEvent<FocusEvent>) => onFocus('username', e.target);
    const onLoginBlur = () => onFieldBlur('username', ['req'], 'Username');
    const onPasswordChange = (e:IonInputCustomEvent<InputChangeEventDetail>) => onFieldChange('password', e.detail.value);
    const onPasswordFocus = (e:IonInputCustomEvent<FocusEvent>) => onFocus('password', e.target);
    const onPasswordBlur = () => onFieldBlur('password', ['req'], 'Password');
    const onRememberMeChange = (e:ChangeEvent<HTMLInputElement>) =>
    {
        const value = e.currentTarget.checked;
        const rememberMe = { value, error:'' };
        setForm(form => ({ ...form, rememberMe }));
    }

    const signIn = () =>
    {
        const username = form.username.value;
        const password = form.password.value;
        const remember_me = form.rememberMe.value;
        loginService.fetch({ username, password, remember_me });
    }

    const handleSubmit = (e:FormEvent<HTMLFormElement>) =>
    {
        if(Auth.loginProvider.useCookies) { return; }

        e.preventDefault();
        signIn();
    }

    //const url = 'https://har-crm-api.har.com' + LoginApi.url + '?redirect_url=' + encodeURIComponent(Auth.loginProvider.options.redirectLoginUrl);
    //const url = 'https://har-crm-api.har.com' + LoginApi.url;
    const url = loadApiUrl(LoginApi.url);

    return (
        <form method="post" action={url} className="ml-auto mr-auto mt-md-5 mt-4 mb-4 max-w-405" onSubmit={handleSubmit}>
            <Textbox
                containerClassName="mb-4"
                name="username"
                label="Username"
                placeholder="username"
                type="text"
                innerRef={usernameRef}
                value={form.username.value}
                error={form.username.error}
                disabled={login.loading}
                onIonChange={onLoginChange}
                onIonFocus={onLoginFocus}
                onIonBlur={onLoginBlur}
            />
            <div className="input_with_icon">
                <Textbox
                    containerClassName="mb-2"
                    name="password"
                    label="Password"
                    placeholder="password"
                    type={passEye? 'text': 'password'}
                    innerRef={passwordRef}
                    value={form.password.value}
                    error={form.password.error}
                    disabled={login.loading}
                    onIonChange={onPasswordChange}
                    onIonFocus={onPasswordFocus}
                    onIonBlur={onPasswordBlur}
                />
                <img
                    className="input_icon"
                    src={`https://content.harstatic.com/media/icons/${passEye? 'not-':''}visible.svg`}
                    alt="Enable or disable password visibility"
                    onClick={() => setPassEye(!passEye)}
                />
            </div>

            <p className='mb-4'>If you forgot your password, please <a href={mailto({ to:'support@har.com', subject:'CRM Support' })}>contact support team</a>.</p>

            <input type="hidden" name="redirect_url" value={redirectUrl} />
            {Object.keys(props.headers).map(header => 
                <input key={header} type="hidden" name={header} value={props.headers[header]} />
            )}

            {Auth.loginProvider.showRememberMe?
                <label>
                    <input type="checkbox" name="remember_me" className='align-middle' value="1" checked={form.rememberMe.value} onChange={onRememberMeChange} /> 
                    <span className="align-middle ml-2">Remember Me</span>
                </label>:
                <input type="hidden" name="remember_me" checked={form.rememberMe.value} value="1" /> 
            }
            <div className="pt-2 pb-md-5 mb-md-4">
                <div className="row small-gutters align-items-center">
                    <div className="col-auto"><ProminentButton type="submit" disabled={hasErrors} loading={login.loading}>Sign In</ProminentButton></div>
                    <input type="submit" hidden />
                    {/* <div className="col-auto"><OrdinaryButton>Cancel</OrdinaryButton></div> */}
                </div>
            </div>
            {/* <div className="text-center font_size--medium color_auxiliary"></div> */}
        </form>
    );
}