import { IContactDetailsTemplateProps,IContactDetailsLocationProps, menuItems, IMenuProps, FORCE_FULL_HEIGHT_CLASS, ICustomErrorProps } from './ContactDetailsTemplate.def';
import { useHistory, useParams, useLocation} from 'react-router';
import { useFetchGetContactSummaryService } from 'services/contacts/getContactSummary';
import { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import {  IonContent,IonHeader,IonPage,RefresherEventDetail ,IonRefresher,IonRefresherContent } from '@ionic/react';
import { useResponsiveChoose } from 'scripts/hooks/general';
import "./ContactDetailsTemplate.scss";
import { DefaultTemplate } from '../DefaultTemplate';
import { IUpdateState } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
//import { CollaboratorButton } from 'views/contacts/popups/CollaboratorPopup/component/CollaboratorButton/CollaboratorButton';
import { useFetchLookupsService } from 'services/general/lookups'
import { PhotoUploadPopup } from 'views/contacts/PhotoUploadPopup';
import { Link } from 'react-router-dom';
import { Avatar } from 'views/contacts/Avatar';

//import { CollaboratorPopup } from 'views/contacts/popups/CollaboratorPopup';
import { ContactsConfirmDelete } from 'views/contacts/popups/ContactsConfirmDelete';
import { useTimeout } from 'scripts/hooks/timers';
import { OrdinaryButton } from 'views/forms/buttons/Button';
import { redirectToUrlParam } from 'scripts/helpers/urls';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { ContactTitle } from 'views/general/ContactTitle';
import { QuickEditPopup } from 'views/contacts/component/QuickEditPopup';
import { formatHarDate } from 'scripts/helpers/dates';
import { allEmptyOrNone, transformPhoneNumber } from 'scripts/helpers/texts';
import { Page404 } from 'views/screens/Page404';


export const ContactDetailsTemplate = (props: IContactDetailsTemplateProps): JSX.Element => {
    const location = useLocation<IContactDetailsLocationProps>()
    const [referrer,setReferrer] = useState("")
    const Component = useResponsiveChoose(ContactDetailsTemplateDesktop, ContactDetailsTemplateApp);

   

    useEffect(() => {
        if(location?.state && location?.state?.referrer) {
             setReferrer(location?.state?.referrer)
        }
    },[location.state]);
    return <Component referrer={referrer} {...props} />;
}

export const ContactDetailsTemplateApp = (props: IContactDetailsTemplateProps): JSX.Element => {
    const history=useHistory();
 
    const [showAvatarModal,toggleAvatarModal]=useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const isLoaded = useRef(false);
   // const isDevice = useDevice();
   // const [showCollaboratorsModal,setShowCollaboratorsModal]=useState(false)
    const { id } = useParams<{ id: string }>();
    const contactId = parseInt(id);
    const [contacts,getSummary] = useFetchGetContactSummaryService({ contact_id: contactId,renew: '1' });
    const { lastChange, forceFullHeight } = props;
 //const isOwner=contacts.response?.data.is_object_owner=='1'
    const refreshSummary = useTimeout(() => getSummary.fetch(), 1000);
    useEffect(() => {
        if(lastChange === undefined) { return; }
        refreshSummary.schedule();
    }, [lastChange]);

    //if (contacts.error) { return <></>; }
    if(!isLoaded.current && !contacts.loading) { isLoaded.current = true; }
    if (contacts.error) { return <CustomError error={contacts.error} />; }
    //if (contacts.loading) { return <LoadingApp id={id} {...props} />; }
  
  
    const update=()=>{
        getSummary.fetch({contact_id: contactId, renew: '1' })
    }
 
   const handleCall=()=>{
    window.open('tel:'+transformPhoneNumber(contacts.response?.data.primary_phone_country_code,contacts.response?.data.primary_phone??"",contacts.response?.data.primary_phone_extension),'_system');
    }
    const handleEmail=()=>{
       /* if(isDevice) {*/ window.open('mailto:'+contacts.response?.data.primary_email,'_system'); //}
        }
    
    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
            setTimeout(() => {
               props.onSwipeRefresh?.()
              event.detail.complete();
              getSummary.fetch({contact_id: contactId,renew: '1'})
            }, 1000);
          }

       //const isContactDetailTab= window.location.pathname==`/contacts/${id}/detail`||window.location.pathname==`/contacts/${id}/detail/edit`;
       const isContactDetailTab = props.selectedMenu === 'ContactDetails';
    const goBack = () => {
        if(redirectToUrlParam('redirect_url')) { return; }

        // Original code
        props.referrer ? history.push(props.referrer) : history.length > 1 ? history.goBack() : history.push('/contacts');
    };
      
    const getPipelineClass=(value:string)=>value.toLowerCase()=='active listing / under contract'?'undercontract':value.toLowerCase();
    const getPipelineText=(value:string)=>value.toLowerCase()=='active listing / under contract'?'Active Listing':value;
          return (
        <IonPage className={forceFullHeight? FORCE_FULL_HEIGHT_CLASS: ''}>
           
                <div className="hb_app">
                    <IonHeader>
                    {contacts.response? <div className="header_blue ion-padding-bottom">
                        <div className="ion-padding-start ion-padding-end hb_section">
                            <div className="row no-gutters">
                                <div className="col-auto">
                                    <div className="hb_top">
                                <div className="hb_back animate__animated animate__fadeIn animate__slow animate__delay-1s cursor--hand" onClick={goBack}><img src="https://content.harstatic.com/media/icons/back-ios.svg" /></div>
                                    </div>
                                </div>
                                {!isContactDetailTab&&<div className="col text-right pt-0  ml-4">
                                <ShapelessButton onClick={()=>history.push(`detail/edit`)} color={'light'} size='medium' className='p-0 ml-3'>Edit</ShapelessButton></div>}
                                </div>
                            <div className="agent_signature agent_signature__large__extra agent_signature__nagative mb-0 animate__animated  animate__fast">
                                <div onClick={() => toggleAvatarModal(true)} >
                                {contacts?.loading ?
                                    <div className="photophoto__image nophoto"><div className="loading__photo"></div></div>
                                    :
                                    <Avatar 
                                    className={"position-relative"}
                                    firstName={contacts.response?.data?.first_name} 
                                    lastName={contacts.response?.data?.last_name} 
                                    id={id} 
                                    photo={ contacts?.response?.data.photo_url}>
                                            <div className="position-absolute bottom--10px right--2px"><img src="https://content.harstatic.com/media/icons/change-photo-mobile.svg" /></div>
                                    </Avatar>
                                        
                                }</div>

                                <div className="agent_signature__text">
                                <div className="agent_signature__text__title"><ContactTitle contact={contacts.response?.data} /></div>
                                    <div className="agent_signature__text__tags d-flex">
                
                                        {contacts.response?.data?.primary_phone?<div><button onClick={handleCall}  role="button" className="btn btn--rounded btn--inlineicon mr-2"> <img className=' w-14px opacity-64pr' src="https://content.harstatic.com/media/icons/phone_white_outline.svg" /><span>Phone</span></button></div>   : null}                                   
                                        {contacts.response?.data?.primary_email?
                                            <div><button onClick={handleEmail} role="button" className="btn btn--rounded btn--inlineicon mr-2">
                                                <img className='w-14px opacity-64pr' src="https://content.harstatic.com/media/icons/email_white_outline.svg" />
                                                <span>Email</span>
                                                {/*isDevice?
                                                    <span>Email</span>:
                                                    <CopyToClipboard  text={contacts.response?.data?.primary_email} onCopy={()=>displayMessage("The Email was successfully copied")}><span className='color_auxiliary'>Email</span></CopyToClipboard>
                                        */}
                                            </button></div>
                                            : null
                                        } 
                                    <div className='d-none'>{!isContactDetailTab&&<div><button onClick={()=>history.push(`detail/edit`)}  role="button" className="btn btn--rounded btn--rounded--padding btn--inlineicon mr-2 "><span>Details</span></button></div>}</div>

                                    {!!contacts.response?.data?.score &&
                                    <div className='ml-auto d-none'>
                                        <div className={`score_label mr-0 score_label--${contacts.response?.data?.score.toLowerCase()}`} >
                                            <span className="score_label__alpha">{contacts.response?.data?.score}</span>
                                            <span className="score_label__text">Score</span>
                                    </div>
                                </div>
                                    }
                            </div> 
                        </div>
                            </div> 
                        </div>
                         <div className="ion-padding-start animate__animated animate__fadeIn">
                            <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm">
                                    {!allEmptyOrNone(contacts.response?.data?.score??"") &&
                                    <div className='align-self-center color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 border-right_custom_1'>
                                        <div className={`score_label mr-0 score_label--${contacts.response?.data?.score?.toLowerCase()}`} >
                                            <span className="score_label__alpha">{contacts.response?.data?.score}</span>
                                            <span className="score_label__text">Score</span>
                                        </div>
                                    </div>
                                    }
                                {!allEmptyOrNone(contacts?.response?.data.pipeline_status_text??"") &&
                                    <div className="align-self-center color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 border-right_custom_1 mt-0">
                                        <div className="font_weight--bold mb-1">Stage</div>
                                        
                                        <div className={'stage stage__'+getPipelineClass(contacts?.response?.data?.pipeline_status_text??"")}>{getPipelineText(contacts?.response?.data?.pipeline_status_text??"")}</div>
                                    </div>
                                }
                              {/**   {!!contacts.response?.data?.lead_type&& <div className="align-self-top color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 border-right_custom_1 mt-0">
                                    <div className="font_weight--bold">Lead</div>
                                    <div>{contacts.response?.data?.lead_type}</div>
                                </div>}
                                {!!contacts.response?.data?.sources&&
                                    <div className="align-self-top color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 border-right_custom_1 mt-0">
                                        <div className="font_weight--bold">Source</div>
                                        <div>{contacts.response?.data?.sources}</div>
                                    </div>
                                }*/}
                                <div className="align-self-top color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 ">
                                        <div className="font_weight--bold">Updated Date</div>
                                        <div>{formatHarDate(contacts.response?.data?.last_contact_date)}</div>
                            </div>
                            </div>
                            </div>
                        {/**<CollaboratorPopup contact_id={id} showModal={showCollaboratorsModal} setShowModal={setShowCollaboratorsModal}/>*/}
                    </div>:
                    <LoadingApp />}
                    <Menu isApp={true} id={id} selectedMenu={props.selectedMenu} />
                 
                    </IonHeader>
                    <IonContent fullscreen ref={props.contentRef} scrollY={!forceFullHeight}>
                        {props.onSwipeRefresh && 
                            <IonRefresher className="p-5"  slot="fixed" onIonRefresh={handleRefresh}>
                            <IonRefresherContent
                         on-refresh="doRefresh()"></IonRefresherContent>
                            </IonRefresher>
                        }
                     <div className="template-inner-wrapper">
                        {isLoaded.current && props.children}
                        <ContactsConfirmDelete 
                            show={isOpen}
                            contacts={contacts?.response?.data ? [contacts?.response?.data] : []}
                            toggleShow={() => setIsOpen(false) }
                            onDelete={() => history.push(props.referrer ?  props.referrer : '/contacts')}
                        
                        />
                    </div>
                    </IonContent>
                </div>
                {showAvatarModal && contacts.response && <PhotoUploadPopup refresh={update} id={contacts.response.data.id} firstName={contacts.response.data.first_name??""} lastName={contacts.response.data.last_name??""} imageUrl={contacts?.response?.data.photo_url} toggleShow={toggleAvatarModal} show={showAvatarModal} />}
        </IonPage>
    )
}

export const ContactDetailsTemplateDesktop = (props: IContactDetailsTemplateProps): JSX.Element => {
    const { valueChanged, forceFullHeight } = props;
    const history=useHistory();
   
    // const [updatedState, setUpdatedState] = useState<Array<IUpdateState>>();
    const [newState, setNewState] = useState({ firstname: '', lastname: '', img: '', sEmail: '', sPhone: '', sPhoneType: '', sScore: '', knownsince: '', sSource: '',pipeline_status_text:'' })
    const [isOpen, setIsOpen] = useState(false);
    const isLoaded = useRef(false);
    const [quickEditPopup, setQuickEditPopup] = useState({show:false,contact:{},tab:"Stages"});
    const { id } = useParams<{ id: string }>();
    const contactId = parseInt(id);
    const [contacts,getSummary] = useFetchGetContactSummaryService({ contact_id: contactId });
    const [lookupData] = useFetchLookupsService(['phone_type']);
    const [showAvatarModal,toggleAvatarModal]=useState(false);
   // const isOwner=contacts.response?.data.is_object_owner=='1'
    let firstName = '';
    let lastName = '';
    let image = '';
    let email = '';
    let phone = '';
    let phoneType = '';
    let source = '';
    let knownSince = '';
    let score = '';
    let pipeline_status_text='';
 

    const UpdateState = (valueChanged: IUpdateState[]) => {
        for (let i = 0; i < valueChanged.length; i++) {
            if (valueChanged[i]?.action === 'first_name') { firstName = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'last_name') { lastName = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'image') { image = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'known_since') { knownSince = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'phone') { phone = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'phoneType') { phoneType = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'email') { email = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'score') { score = valueChanged[i]!.payload }
            if (valueChanged[i]?.action === 'sources') { source = valueChanged[i]!.payload }
        }
        if (firstName === '') { firstName = newState.firstname }
        if (lastName === '') { lastName = newState.lastname }
        if (image === '') { image = newState.img }
        if (knownSince === '') { knownSince = newState.knownsince }
        if (phone === '') { phone = newState.sPhone }
        if (phoneType === '') { phoneType = newState.sPhoneType }
        if (email === '') { email = newState.sEmail }
        if (score === '') { score = newState.sScore }
        if (source === '') { source = newState.sSource }
        return { firstName, lastName, image, knownSince, phone, email, score, source };
    }
   useEffect(() => {
        if (valueChanged && valueChanged !== undefined) {
            UpdateState(valueChanged);
            setNewState({
                firstname: firstName,
                lastname: lastName,
                img: image,
                knownsince: knownSince,
                sScore: score,
                sSource: source,
                sPhone: phone,
                sPhoneType: phoneType,
                sEmail: email,
                pipeline_status_text:pipeline_status_text
            })

        }
        // ActionFetchContacts.fetch({ contact_id: contactId })
        // return null
    }, [valueChanged]);

    const scoreClass = newState.sScore !== null && newState.sScore !== '' ? `score_label--${newState.sScore.toLowerCase()}` : `score_label--${contacts.response?.data?.score?.toLowerCase()}`;
    const primaryPhone = newState.sPhone !== '' ? newState.sPhone : contacts.response?.data?.primary_phone;
    const primaryPhoneType = newState.sPhoneType !== '' ? newState.sPhoneType : contacts.response?.data?.primary_phone_type;
    const pTypeLongValue = lookupData.response?.phone_type?.items.find(x => x.short_value === primaryPhoneType);
    const update=()=>{
        getSummary.fetch({contact_id: contactId, renew: '1' })
    }
    // const phoneType = lookupData.response?.phone_type?.items.find(x => x.short_value === item.phone_type)
    
    if(!isLoaded.current && !contacts.loading) { isLoaded.current = true; }
    if (contacts.error) { return <CustomError error={contacts.error} />; }


    const handleCall=()=>{
        window.open('tel:'+transformPhoneNumber(contacts.response?.data.primary_phone_country_code,contacts.response?.data.primary_phone??"",contacts.response?.data.primary_phone_extension),'_system');
        }

        const handleEmail=()=>{
           /* if(isDevice) {*/ window.open('mailto:'+contacts.response?.data.primary_email,'_system'); //}
            }
    
    const goBack = () => {
        if(redirectToUrlParam('redirect_url')) { return; }

        // Original code
        props.referrer ? history.push(props.referrer) : history.length > 1 ? history.goBack() : history.push('/contacts');
    };
    const getPipelineClass=(value:string)=>{
        return value.toLowerCase()=='active listing / under contract'?'undercontract':value.toLowerCase();
    }

    return (
        <DefaultTemplate className={forceFullHeight? FORCE_FULL_HEIGHT_CLASS: ''} title="Contacts" contentRef={props.contentRef}>
            {forceFullHeight && <DefaultTemplate.IonContent scrollY={false} />}
            
            {contacts.loading?<LoadingDesktop id={id} selectedMenu={props.selectedMenu} />:
            <div className="header_blue">
               <PhotoUploadPopup refresh={update} id={contacts.response.data.id} firstName={contacts.response.data.first_name??""} lastName={contacts.response.data.last_name??""} imageUrl={contacts?.response?.data.photo_url} toggleShow={toggleAvatarModal} show={showAvatarModal} onItemsChange={(image)=>setNewState((prevState)=>({...prevState,img:image.imageUrl}))}/>
{}
                <div className="container container__large mt-4 pb-4 header_main_container">  
                 <div className="row no-padding">
                        <div className="col-xs-1 mt-5 pl-2 arrow_container">
                          <div className='cursor--hand' onClick={goBack}><img src="https://content.harstatic.com/media/icons/back-ios.svg" /></div>
                        </div>
                        
                            
                                <Fragment key={1}>
                                <div className="col-md-6 col-12">
                                        <div className="ap_change cursor--hand agent_signature agent_signature__large__extra agent_signature__nagative mb-0 mr-0 p-0">
                                            <div onClick={() => toggleAvatarModal(true)}><Avatar firstName={contacts.response?.data?.first_name??""} lastName={contacts.response?.data?.last_name??""} id={id} photo={newState.img !== '' ? newState.img : contacts?.response?.data.photo_url}>
                                                <div className="ap_overlay zindex-1">
                                                <img src="https://content.harstatic.com/media/icons/icon-change-picture.svg"/>
                                                <div>Change</div>
                                                 </div>
                                                 </Avatar>
                                            </div>
                                            <div className="agent_signature__text">
                                                <div className="agent_signature__text__title"><ContactTitle contact={contacts.response?.data} /></div>
                                                <div className="agent_signature__text__tags">
                                                    {(primaryPhone !== undefined && primaryPhone !== '') ?
                                                        <a href={'tel:'+transformPhoneNumber(contacts.response?.data.primary_phone_country_code,contacts.response?.data.primary_phone??"",contacts.response?.data.primary_phone_extension)} className="agent_signature__text__tags_tag" onClick={handleCall}>
                                                            <img className='mr-2 w-14px' alt='img' src="https://content.harstatic.com/media/icons/phone_white_outline.svg" /><span>{transformPhoneNumber(contacts.response?.data.primary_phone_country_code,contacts.response?.data.primary_phone??"",contacts.response?.data.primary_phone_extension)}  {pTypeLongValue?.long_value}</span>
                                                        </a> :
                                                        null
                                                    }
                                                    {contacts?.response?.data?.primary_email || newState.sEmail !== ''
                                                        ? <a href={'mailto:'+contacts.response?.data.primary_email} className="agent_signature__text__tags_tag" onClick={handleEmail}><img className='mr-2 w-14px' alt='img' src="https://content.harstatic.com/media/icons/email_white_outline.svg" onClick={handleEmail}/><span onClick={handleEmail}>{newState.sEmail !== '' ? newState.sEmail : contacts?.response?.data?.primary_email}</span></a> : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="col-md-5 pt-4 col-12 align-self-center text-md-right animate__animated  animate__faster header_info">
                                        <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm">
                                            {/**  {isOwner&&<CollaboratorButton contact_id={contactId.toString()}/>}*/}
                                            <div className=" align-self-start">
                                                {!allEmptyOrNone(contacts.response?.data?.score??"",newState.sScore) ?
                                                <div className="align-self-top color_snow_white   border-right_custom_1 ">
                                                    <div className={`score_label ${scoreClass} `} onClick={()=> setQuickEditPopup({show:true,contact:contacts?.response?.data,tab:"Score"})}>
                                                        <span className="score_label__alpha">{newState.sScore !== '' ? newState.sScore : contacts.response?.data?.score}</span>
                                                        <span className="score_label__text">Score</span>
                                                    </div></div>
                                                    : null
                                                }
                                            </div>

                                            {!allEmptyOrNone(contacts?.response?.data?.pipeline_status_text??"",newState.pipeline_status_text)?
                                                <div className="align-self-top color_snow_white pl-md-4 pl-3 pr-md-4 pr-3 border-right_custom_1"  onClick={()=> setQuickEditPopup({show:true,contact:contacts?.response?.data,tab:"Stages"})}>
                                                    <div className="font_weight--bold text-left">Stage</div>
                                                    <div className={'stage mb-0 stage__'+getPipelineClass(contacts?.response?.data?.pipeline_status_text??"")}>{newState.pipeline_status_text !== '' ? newState.pipeline_status_text : contacts?.response?.data?.pipeline_status_text}</div>
                                                </div>
                                                : null
                                            }

                                           {/**  {contacts.response?.data?.sources !== null || (newState.sSource !== '')
                                                ?
                                                <div className="align-self-top color_snow_white pl-md-4 pl-3 pr-md-4 pr-3 border-right_custom_1" onClick={()=> setQuickEditPopup({show:true,contact:contacts?.response?.data,tab:"Source"})}>
                                                    <div className="font_weight--bold">Source</div>
                                                    <div>{newState.sSource !== '' ? newState.sSource : contacts.response?.data?.sources}</div>
                                                </div>
                                                : null
                                            }
                                            {!!contacts.response?.data?.lead_type&&<div className="align-self-top color_snow_white pl-md-4 pl-3  pr-md-4 pr-3 border-right_custom_1">
                                                <div className="font_weight--bold">Lead</div>
                                                <div>{contacts.response?.data?.lead_type}</div>
                                            </div>}*/}
                                            <div className="align-self-top text-left color_snow_white pl-md-4 pl-4 ">
                                                <div className="font_weight--bold">Updated Date</div>
                                                <div>{formatHarDate(contacts.response?.data?.last_contact_date
)}</div>
                                            </div>
                                            <OrdinaryButton className="ml-4" onClick={()=>history.push(`/contacts/${id}/detail/edit`)}>Edit Contact</OrdinaryButton>
                                            {/*<div className="align-self-center color_snow_white pl-md-4 pl-3">
                                                <Dropdown className=''>
                                                    <Dropdown.Toggle className="btn btn--whiteoutline" variant="success" id="dropdown-basic">
                                                        Edit
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu align={'right'}>
                                                        <Dropdown.Item onClick={()=>history.push(`/contacts/${id}/detail/edit`)}>Edit contact details</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setIsOpen(true)}>Delete contact</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                        </div>*/}
                                            </div>
                                        </div>
                                </Fragment>
                            
                        
                    </div>
                </div>
            </div >}
            <Menu id={id} selectedMenu={props.selectedMenu} />
            <div className="template-inner-wrapper">
                {isLoaded.current && props.children}
                <ContactsConfirmDelete 
                    show={isOpen}
                    contacts={contacts?.response?.data ? [contacts?.response?.data] : []}
                    toggleShow={() => setIsOpen(false) }
                    onDelete={() => history.push(props.referrer ?  props.referrer : '/contacts')}
                   
                />
                <QuickEditPopup 
                    open={quickEditPopup.show}
                    contact={quickEditPopup.contact}
                    selectedTab={quickEditPopup.tab}
                    close={() => setQuickEditPopup({show:false,contact:{},tab:""})}
                    onAssignment={()=>window.location.reload()}
                />
            </div>
            
        </DefaultTemplate >
    );
}

const Menu = (props: IMenuProps) => {
    const { id, selectedMenu } = props;
    const menuEntries = Object.entries(menuItems);
    
    useEffect(()=>{
        if(selectedMenu== "ContactDetails"||selectedMenu== "Documents"){ setTimeout(()=>document.getElementById("inpagenav")?.scrollBy({left:250,top:250}),1000) }
    },[])
    return (
        <div  className={props.isApp?"withblueheader header-unsticky":"withblueheader"}>
            <div className={props.isApp?"inpagenav position-static":"inpagenav"} id="inpagenav">
                <div className="container container__large">
                    <ul className="w-100">
                        {menuEntries.map(([key, value], index) => {
                            if(value === undefined) { return undefined; }
                    return (
                            <li key={key} className={`${selectedMenu && selectedMenu === key ? 'active' : menuEntries.length - 1 === index ? 'pr-4 pr-md-0' : ''}`}><Link to={`/contacts/${id}/${value.url}`} replace className='custom_font'>{value.text}</Link></li>
                        )})}
                    </ul>
                </div>
            </div>
        </div>
    );
}



const LoadingDesktop = (_props: PropsWithChildren<IMenuProps>) =>
(
        <div className="header_blue">
            <div className="container container__large pt-4 pb-4">
                <div className="row opacity-30pr">
                    <div className="col-xs-1 mt-4 pl-2">
                        <div className='cursor--hand'><img src="https://content.harstatic.com/media/icons/back-ios.svg" /></div>
                    </div>
                    <div className="col-md-7 col-12">
                        <a href="#" className="agent_signature agent_signature__large__extra agent_signature__nagative mb-0 ">
                            <div className="agent_signature__photo bg--image_none"><div className="loading__photo w-48px h-48px"></div></div>
                            <div className="agent_signature__text">
                                <div className="agent_signature__text__title"><div className="loading__text w-50"></div></div>
                                <div className="agent_signature__text__tags">
                                    <div className="agent_signature__text__tags_tag"><div className="loading__text_small w-100px"></div></div>
                                    <div className="agent_signature__text__tags_tag"><div className="loading__text_small w-90px"></div></div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-4 pt-4 col-12 align-self-center text-md-right animate__animated animate__fadeIn animate__faster">
                        <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm">
                            <div className="pr-md-4 pr-3">
                                <div className="loading__square border_radius--default w-48px h-48px"></div>
                            </div>
                            <div className="align-self-center color_snow_white pr-md-4 pr-3border-right_custom_1">
                                <div className="loading__text_small ml-auto mb-2 w-30px"></div>
                                <div className="loading__text_small ml-auto w-70px"></div>
                            </div>
                            <div className="align-self-center color_snow_white pr-md-4 pr-3 pl-md-4 pl-3border-right_custom_1">
                                <div className="loading__text_small ml-auto mb-2 w-40px"></div>
                                <div className="loading__text_small ml-auto w-60px"></div>
                            </div>
                            <div className="align-self-center color_snow_white pl-md-4 pl-3">
                                <div className="loading__text_small ml-auto mb-2 w-40px"></div>
                                <div className="loading__text_small ml-auto w-80px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
);

const LoadingApp = () =>
(
    <div className="header_blue  ion-padding-bottom">
        <div className="ion-padding-start ion-padding-end hb_section">
            <div className="row no-gutters">
                <div className="col-auto">
                    <div className="hb_top">
                        <div className="hb_back animate__animated animate__fadeIn animate__slow animate__delay-1s cursor--hand"><img src="https://content.harstatic.com/media/icons/back-ios.svg" /></div>
                    </div>
                </div>
                <div className="col text-right pt-2"></div>
            </div>
            <div className="agent_signature agent_signature__large__extra agent_signature__nagative mb-0 animate__animated  animate__fast">
                <div><div className="loading__photo mr-4"></div></div>

                <div className="agent_signature__text">
                    <div className="agent_signature__text__title"><div className="loading__text w-50"></div></div>
                    <div className="agent_signature__text__tags">
                        <div className="agent_signature__text__tags_tag"><div className="loading__text_small w-100px"></div></div>
                        <div className="agent_signature__text__tags_tag"><div className="loading__text_small w-90px"></div></div>
                    </div>
                </div>
                    
                <div className="align-self-center color_snow_white pr-md-4 pr-3 pl-md-4 pl-3border-right_custom_1">
                    <div className="loading__text_small ml-auto mb-2 w-40px"></div>
                    <div className="loading__text_small ml-auto w-60px"></div>
                </div>
                <div className="align-self-center color_snow_white pl-md-4 pl-3">
                    <div className="loading__text_small ml-auto mb-2 w-40px"></div>
                    <div className="loading__text_small ml-auto w-80px"></div>
                </div>
            </div> 
        </div>
        <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm"></div>
    </div>
);

function CustomError(props:ICustomErrorProps)
{
    const { error } = props;
    error.preventDefault();
    if(error.response?.status !== 404) { throw error; }
    return (
        <Page404 />
        // <Page404
        //     title="Contact not found"
        //     message={<>
        //         This contact may be private or it's temporarily unavailable.
        //         <br className="d-md-block d-none" />
        //         If someone gave you this link, you may not have permission to access it.
        //     </>}
        // />
    );
}