import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { eOnboardingStage } from "scripts/enums";
import { GetBasicInfoApi, useFetchGetBasicInfoService, useGetBasicInfoService } from "services/user/getBasicInfo";
import { useUpdateOnboardingStageService } from "services/user/updateOnboardingStage";
import { IHookFetch } from "./useService";
import { usePrevious } from './general';
import { useIsAuthenticated } from './auth';
import { onboardingMap } from 'scripts/application/onboarding';
import { ifElse } from 'scripts/helpers/general';
import { joinWithSeparator } from 'scripts/helpers/texts';

type RefreshFunction = IHookFetch<GetBasicInfoApi.Request, {}>;

export namespace IOnboardingStageHook
{
    interface ILabels
    {
        title:string;
        skipLabel:string;
        nextLabel:string;
        customLabel:boolean;
    }
    interface Basic
    {
        labels:ILabels;
        nextUrl?:string;
    }

    interface NotSuccess extends Basic
    {
        user:undefined;
        stage:undefined;
        next:undefined
    }

    interface Loaded extends Basic
    {
        loading:false;
        refresh:RefreshFunction,
    }

    export type LoadingState = Readonly<NotSuccess & { error:undefined; loading:true }>;

    export type ErrorState = Readonly<NotSuccess & Loaded & { error:Error }>;

    export type SuccessState = Readonly<Loaded & {
        user:GetBasicInfoApi.Response;
        error:undefined;
        stage:eOnboardingStage,
        next: { (skip?:boolean, redirect?:boolean | string): Promise<void>, loading:boolean, error?:Error }
    }>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IOnboardingStageHook = 
    IOnboardingStageHook.LoadingState
    | IOnboardingStageHook.ErrorState
    | IOnboardingStageHook.SuccessState;


export const useOnboardingStage = (nextStage:eOnboardingStage): IOnboardingStageHook =>
{
    const history = useHistory();
    const [basicInfo, basicInfoActions] = useFetchGetBasicInfoService({ renew:'1' });
    const [updateStage, updateStageActions] = useUpdateOnboardingStageService();
    const [nextUrl] = useState(() => mapNextUrl(history.location.search));
    const stage = basicInfo.response?.onboarding_stage;
    const isNextStageLast = nextStage === eOnboardingStage.Complete;
    //const nextUrl = new URLSearchParams(history.location.search).get('nexturl');

    const refresh = (...params:any[]) => basicInfoActions.fetch(params);

    const next:IOnboardingStageHook['next'] = async (skip:boolean = false, redirect:boolean | string = true) =>
    {
        if(basicInfo.loading || basicInfo.error) { return; }
        if(updateStage.loading) { return; }

        // In case of skip
        const stage = basicInfo.response.onboarding_stage;
        const hasProvider = basicInfo.response.provider_sync_mode === true;
        const nextStageItem = skip? ifElse(hasProvider, onboardingMap.CIM, onboardingMap.COM): onboardingMap[nextStage];
        if(skip && stage !== eOnboardingStage.Complete) { await updateStageActions.fetch({ stage:eOnboardingStage.Complete }); }

        // In case of next stage
        if(!skip && nextStageItem.index === onboardingMap[stage!].index +1) {
            await updateStageActions.fetch({ stage:nextStage });
            //if(!redirect) { await basicInfoActions.fetch(); }
        }

        const isComplete = nextStageItem === onboardingMap.COM;
        let url:string;
        if(redirect === true && isComplete && !!nextUrl.url?.trim()) { url = nextUrl.url; }
        else if(redirect === true) { url = nextStageItem.path; }
        else if(redirect) { url = redirect; }
        else { return; }

        if(!isComplete && nextUrl.url?.trim()) { url += '?nexturl=' + encodeURIComponent(nextUrl.url); }
        history.push(url);
    }

    next.loading = updateStage.loading;
    next.error = updateStage.error;
    //const skipNextUrlLabel = nextUrl.title? `Skip to ${nextUrl.title}`: undefined;
    const customLabel = nextUrl.title !== undefined;
    const finalLabel = customLabel? `Continue to ${nextUrl.title}`: 'Go to Dashboard';
    const skipLabel = customLabel? finalLabel: 'Skip';
    const nextLabel = isNextStageLast? finalLabel: 'Next';
    const labels = { skipLabel, nextLabel, customLabel, title:nextUrl.title };

    const { loading, error, response:user } = basicInfo;
    return { loading, error, user, stage, refresh, next, labels, nextUrl:nextUrl.url } as IOnboardingStageHook;
}


export const useOnboardingProperStage = (stage:eOnboardingStage):
    { status?:boolean, loading:boolean, redirectTo?:string, isAuthenticated?:boolean, user?:GetBasicInfoApi.Response } =>
{
    const isAuthenticated = useIsAuthenticated();
    const [basicInfo, basicInfoActions] = useGetBasicInfoService({ renew:'1' });
    const history = useHistory();
    const [nextUrl] = useState(() => loadNextUrl(history.location.search));
    const previousStage = usePrevious(stage);
    useEffect(() => {
        if(isAuthenticated === true) { basicInfoActions.fetch(); }
    }, [stage,isAuthenticated]);

    if(isAuthenticated !== true) {
        return { loading:isAuthenticated === undefined, isAuthenticated };
    }

    if(basicInfo.error) { throw basicInfo.error; }
    if(previousStage !== stage || basicInfo.loading || basicInfo.response === undefined) { return { loading:true }; }

    const { response:user } = basicInfo;
    const currentStage = onboardingMap[stage];
    const onboarding_stage = onboardingMap[user.onboarding_stage];

    // Checking ordering of stage
    let properStage = currentStage;
    if(onboarding_stage.index < properStage.index) { properStage = onboarding_stage; }

    // Deciding destination
    if(properStage === currentStage) { return { status:true, loading:false, isAuthenticated, user }; }
    const redirectTo = joinWithSeparator('?', properStage.path, nextUrl && `nexturl=${nextUrl}`);
    return { status:false, loading:false, redirectTo, isAuthenticated, user };
};

function loadNextUrl(queryString:string)
{
    if(!queryString.trim()) { return undefined; }
    return new URLSearchParams(queryString).get('nexturl')?.trim() ?? undefined;
}

function mapNextUrl(queryString:string)
{
    // Retrieving next url
    const response:Partial<Record<'url' | 'title', string>> = {};
    const param = response.url = loadNextUrl(queryString);
    if(!param) { return response; }

    // Normalizing next url
    const url = new URL(param, window.location.origin);
    
    // Mapping next url title
    const selector = selectTitle();
    let path = selector.next();
    while(!path.done)
    {
        const matches = url.pathname.startsWith(path.value);
        path = selector.next(matches);
    }
    
    response.title = path.value;
    return response;
}

// Creating a function generator to map title from url
function* selectTitle(): Generator<string, string | undefined, boolean>
{
    if(yield '/services/emailmarketing') { return 'EmailMarketing'; }
    if(yield '/services/marketinsight') { return 'MarketInsight'; }
    return undefined;
}