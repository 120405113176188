import { IContactsTableProps, ILoadingGrid, IEmptyGrid, IContactsTableLocationProps } from './ContactsTable.def';
import "./ContactsTable.scss";
import { IonInfiniteScroll, IonSegment, IonInfiniteScrollContent, IonRouterLink, IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { ContactsFilterPopUp } from '../popups/ContactsFilterPopUp';
import { IContactsFilters, ILocationFilter, stagesOptions } from '../popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { ContactsConfirmDelete } from '../popups/ContactsConfirmDelete';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NewTagPopUp } from '../popups/NewTagPopUp';
import { ContactDetailPopover } from '../popovers/ContactDetailPopover';
import { useHistory, useLocation } from 'react-router';
import { useResponsiveRender } from 'scripts/hooks/general';
import { ContactsTableApp } from './ContactsTable.app';
import { Avatar } from '../Avatar';
import { ContactImportStatus } from '../component/ContactImportStatus';
import { SortPopover } from './popover/SortPopover';
import { AssignScore } from '../component/AssignScore';
import { AssignStage } from '../component/AssignStage';
import { AssignSource } from '../component/AssignSource';
import { ContactsEditTagsPopUp } from '../popups/ContactsEditTagsPopUp';
import { QuickEditPopup } from '../component/QuickEditPopup';
import { displayMessage } from 'scripts/hooks/feedbacks';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContactTitle } from 'views/general/ContactTitle';
import { transformPhoneNumber } from 'scripts/helpers/texts';


const ContactsTableLarge = (props: IContactsTableProps): JSX.Element => {
    const { result, contacts, file, page, manageTagPopUp, showImport, reload } = props;
    const [quickEditPopup, setQuickEditPopup] = useState({ show: false, contact: {}, tab: "Stages" });
    const [showPopUp, setShowPopUp] = useState(false)
    const [reloadTags, setReloadTags] = useState(false)
    const [showEditRow, setEditRow] = useState("")
    const [selected, setSelected] = useState<any>([])
    const [sort, setSortState] = useState({ sort_by: props.sort?.sort, sort_dir: props.sort?.dir });
    const [serachField, setSearchField] = useState('')
    const [filtersCountNo, setFilterCount] = useState<number>(0)
    const [copied, setCopied] = useState(false)
    const [timer, setTimer] = useState<number | string>()
    const [pop, setPop] = useState(false)
    const [sortGrid, setSortGrid] = useState(1)
    const [contactDetail, setContactDetail] = useState<any>()
    const [newTagPop, setNewTagPop] = useState(false)
    const [filters, setFilters] = useState<IContactsFilters>({
        cities: [],
        scores: [],
        stages: [],
        owners: [],
        zip_codes: [],
        deal_stages: [],
        companies: [],
        sources: [],
        tags: [],
        last_contact_date: "",
        birthday_start: "",
        birthday_end: "",
        home_anniversary_start: "",
        home_anniversary_end: "",
        lead_type: ""
    });
    const location = useLocation<IContactsTableLocationProps>()
    const locationPipeline = useLocation<ILocationFilter>()
    const [showDuplicate, setShowDuplicate] = useState(false)
    let history = useHistory();
    const maxSelection = 50

    const doSort = (param: any, grid?: boolean) => {
        var num
        if (grid) {
            if (param.sort_by === sort.sort_by) {
                num = sortGrid + 1
            } else {
                num = 1
            }
        } else {
            num = 1
        }
        setSortGrid(num)

        if (num === 3) {
            props.changeFilter?.({ sort_by: 'last_contact_date', sort_dir: 'desc', page: 1 }, 1)
            setSortState({ sort_by: 'last_contact_date', sort_dir: 'desc' })
        } else {
            props.changeFilter?.({ ...param, page: 1 }, 1)
            setSortState(param)
        }

    }



    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore()
        }
    }

    const selectAll = () => {
        let sel: any = []
        contacts.map((contact: any, index: number) => {
            if (index < maxSelection) {
                sel.push(contact)

            }
        })
        setSelected(sel)
    }


    const isSelectAll = (): boolean => {
        if (contacts.length <= maxSelection) {
            return contacts.length === selected.length
        } else {
            return selected.length === maxSelection
        }
    }

    const unSelectAll = () => {
        setSelected([])
    }

    const selectToggle = (id: number, pushF: number) => {

        if (pushF === 1) {
            let sel: any = contacts.filter((contact: any) =>
                contact.id === id
            )
            setSelected(sel)
        } else if (isSelected(Number(id))) {
            let sel = selected.filter((contact: any) =>
                contact.id !== id
            )
            setSelected(sel)
        } else {
            if (selected.length === maxSelection) {
                displayMessage('You can select up to 50 contacts at a time.')
                return
            }
            let sel: any = contacts.filter((contact: any) =>
                contact.id === id
            )
            setSelected([
                ...selected,
                ...sel
            ])
        }
    }


    const doSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setSearchField(e.target.value)
        if (e.target.value === '') {
            clear()
        }
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            if (props.changeFilter)
                props.changeFilter({ keyword: e.target.value, sort_by: sort.sort_by ? sort.sort_by : '', sort_dir: sort.sort_by !== "" && sort.sort_dir ? sort.sort_dir : '', page: 1 }, 1)
        }, 500))
    }

    const clear = () => {
        setSearchField("")
    }

    function filterhandler<K extends keyof IContactsFilters>(key: K, value: IContactsFilters[K]) {
        setFilters({ ...filters, [key]: value })
    }

    const clearFilters = () => {
        let filter = {
            cities: [],
            scores: [],
            stages: [],
            zip_codes: [],
            owners: [],
            deal_stages: [],
            companies: [],
            sources: [],
            tags: [],
            last_contact_date: "",
            birthday_start: "",
            birthday_end: "",
            home_anniversary_start: "",
            home_anniversary_end: "",
            wedding_anniversary_start: "",
            wedding_anniversary_end: "",
            known_since_start: "",
            known_since_end: "",
            lead_type: ""
        }
        setFilters(filter)
        applyFilter(filter)
    }


    const applyFilter = (param: any) => {
        if (props.changeFilter) {
            props.changeFilter({ ...param, sort_by: sort ? sort.sort_by : "created_dt", sort_dir: sort.sort_dir ? sort.sort_dir : "DESC", page: 1 }, 1)
        }
    }

    const filterCount = () => {
        var i = 0
        if (filters.cities && filters.cities.length > 0) {
            i = i + filters.cities.length
        }
        if (filters.scores && filters.scores.length > 0) {
            i = i + filters.scores.length
        }
        if (filters.stages && filters.stages.length > 0) {
            i = i + filters.stages.length
        }
        if (filters.tags && filters.tags.length > 0) {
            i = i + filters.tags.length
        }
        if (filters.deal_stages && filters.deal_stages.length > 0) {
            i = i + filters.deal_stages.length
        }
        if (filters.owners && filters.owners.length > 0) {
            i = i + filters.owners.length
        }
        if (filters.zip_codes && filters.zip_codes.length > 0) {
            i = i + filters.zip_codes.length
        }
        if (filters.companies && filters.companies.length > 0) {
            i = i + filters.companies.length
        }
        if (filters.sources && filters.sources.length > 0) {
            i = i + filters.sources.length
        }

        if (filters.birthday_start || filters.birthday_end) {
            i = i + 1
        }

        if (filters.home_anniversary_start || filters.home_anniversary_end) {
            i = i + 1
        }

        if (filters.wedding_anniversary_start || filters.wedding_anniversary_end) {
            i = i + 1
        }

        if (filters.known_since_start || filters.known_since_end) {
            i = i + 1
        }

        if (filters.lead_type) {
            i = i + 1
        }

        if (filters.last_contact_date) {
            i = i + 1
        }
        setFilterCount(i)
    }


    if (file) {
        var link = document.createElement("a");
        link.href = file;
        document.body.appendChild(link);
        link.click();
    }


    const downloadContact = (): any => {
        if (props.downloadContact) {
            props.downloadContact(selected)
        }
    }

    const isSelected = (id: number): boolean => {
        let sel = selected.filter((contact: any) =>
            contact.id === id
        )
        return sel && sel.length > 0 ? true : false;
    }
    /*
    const redirect = (e: any, id: number) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(`/contacts/${id}/overview`, "_blank")
        } else {
            history.push(`/contacts/${id}/overview`, { referrer: '/contacts' })
        }
    }*/

    function getStageOption(status: string) {
        let stage = stagesOptions.filter((s) => Number(status) === s.short_value)
        return stage[0]
    }



    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 5000);
        }
    }, [copied]);


    useEffect(() => {
        if (location && location.state) {
            if (location.state.reload) {
                reload?.()
            }
        }
    }, [location])


    useEffect(() => {
        if (props.filters) {
            setFilters(props.filters)

        }
    }, [props.filters])

    useEffect(() => {
        if (locationPipeline && locationPipeline.state) {
            if (locationPipeline.state.filters) {
                setFilters(locationPipeline.state.filters)
                props.changeFilter?.(locationPipeline.state.filters, 1)
            }
        }
    }, [locationPipeline])


    useEffect(() => {
        if (reloadTags) {
            setReloadTags(false)
        }

    }, [reloadTags])


    useEffect(() => {
        if (props.reloadTags) {
            setReloadTags(true)
        }
    }, [props.reloadTags])









    return (
        <>
            <ContactsConfirmDelete
                show={showPopUp ? true : false}
                toggleShow={() => setShowPopUp(false)}
                contacts={selected ? selected : []}
                onDelete={reload}
                unSelectAll={unSelectAll}
            />
            <ContactDetailPopover
                show={pop}
                viewProfile={() => {
                    setPop(pop ? false : true);
                    setTimeout(() => {
                        history.push(`/contacts/${contactDetail.id}/overview`, { referrer: '/contacts' })
                    }, 400)
                }}
                id={contactDetail ? contactDetail.id : 0}
                toggleShow={() => setPop(false)}
                reload={reload}
            />
            <QuickEditPopup
                open={quickEditPopup.show}
                contact={quickEditPopup.contact}
                selectedTab={quickEditPopup.tab}
                close={() => setQuickEditPopup({ show: false, contact: {}, tab: "" })}
                onAssignment={reload}
            />
            <NewTagPopUp show={newTagPop} toggleShow={() => setNewTagPop(false)} onNewTagAdded={() => setReloadTags(true)} />
            {copied ?
                <SimpleDialog message='Phone Number is copied.' /> : ''
            }
            <ContactImportStatus className='mb-3' />

            <div className="table--wrapper" onMouseLeave={() => setEditRow("")}>

                {selected?.length > 0 &&
                    <div className={`table p-3 ion-no-padding contact_table_searchbar make-sticky ${selected?.length > 0 ? `managed` : ``}`}>
                        <div className={`row`}>
                            <div className='col-auto border-0'>
                                <div role="checkbox" className="custom-control custom-checkbox  custom-control-inline custom-checkbox__ContactsSelection mt-2 ml-12px">
                                    <input type="checkbox" id="CheckAll" name="CheckAll" className="custom-control-input" checked={isSelectAll()} onChange={() => isSelectAll() ? unSelectAll() : selectAll()} readOnly />
                                    <label className="custom-control-label mr-0" htmlFor="CheckAll"></label>
                                </div>
                            </div>
                            <div className="border-0 col">
                                <AssignStage contacts={selected} selectToggle={(id: number, push: number) => selectToggle(id, push)} onAssignment={reload} />
                                <AssignScore contacts={selected} selectToggle={(id: number, push: number) => selectToggle(id, push)} onAssignment={reload} />
                                <AssignSource contacts={selected} selectToggle={(id: number, push: number) => selectToggle(id, push)} onAssignment={reload} />
                                <ContactsEditTagsPopUp
                                    selectedContacts={selected}
                                    reloadTags={reloadTags}
                                    selectToggle={(id: number, push: number) => selectToggle(id, push)}
                                    setNewTagPop={setNewTagPop}
                                    manageTagPopUp={() => { manageTagPopUp?.(true) }}
                                    reload={reload}
                                />
                                <button role="button" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" disabled={selected.length > 0 ? false : true} aria-label="Download" onClick={() => downloadContact()}>Export {selected.length} Contact{selected.length > 1 ? 's' : ''}</button>
                                <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" disabled={selected.length > 0 ? false : true} aria-label="Delete" onClick={() => setShowPopUp(true)}>Delete</button>
                            </div>
                            <div className='col-auto ml-auto'>
                                <button role="button" className="btn btn--shapeless" onClick={() => unSelectAll()}><img className="mr-2 w-14px" src="https://content.harstatic.com/media/icons/close-white.svg" /></button>
                            </div>
                        </div>
                    </div>
                }

                {selected?.length === 0 &&
                    <div className='row p-3 small-gutters'>
                        <div className='col-auto noBorderRight'><input type="text" value={serachField} className=" min-w-340 form-control input-search_form" onChange={(e) => doSearch(e)} placeholder={result.response ? `Search  ${result.response.total} contacts` : ``} /></div>
                        <div className='text-left col' >
                            <ContactsFilterPopUp
                                filters={filters}
                                onChange={filterhandler}
                                onClear={() => {
                                    clearFilters()
                                    setFilterCount(0)
                                }}
                                disabled={showDuplicate}
                                numberOfFilter={filterCount}
                                applyFilter={() => {
                                    applyFilter(filters)
                                    filterCount()
                                }}
                                filtersCount={filtersCountNo} />
                            <SortPopover doSort={doSort} duplicate={showDuplicate} sort={sort} />
                            <button className={`actionsbtn ${showDuplicate && `active`} px-4 ml-2`} aria-label="Filter by price" onClick={() => {
                                if (result.loading) {
                                    return
                                }
                                setShowDuplicate(!showDuplicate)
                                setSearchField('')
                                clearFilters()
                                props.duplicate?.(!showDuplicate)
                            }}>Duplicates</button>

                        </div>
                        <div className="col-auto">
                            <WatchNLearn />

                        </div>
                    </div>
                }
                {!(result.loading && contacts.length === 0) && serachField &&
                    <div className='table__th ion-nowrap ionCol py-2 px-3 custom_border'>
                        {result.response ? `${result.response.total} contacts found` : ``}
                    </div>
                }
                <IonSegment scrollable className='min-h-550px'>
                    <div className=' flex-grow-1'>
                        <table className="table ion-no-padding contact_list" >
                            <thead>
                                <tr className="table__th ion-nowrap" onMouseEnter={() => setEditRow("")}>
                                    <th className={`cnt_col-1 first_address_col ${sort.sort_by === 'first_name' && 'active-col-head'}  ${sort.sort_by === 'first_name' ? `${sort.sort_dir === 'asc' ? `arrow-option__up` : `arrow-option__down`}` : `arrow-option__down`}`} onClick={() => {
                                        doSort({ sort_by: 'first_name', sort_dir: (sort.sort_dir === 'asc') ? 'desc' : 'asc' }, true)
                                    }}><a>Identification</a></th>
                                    <th className={`cnt_col-2 ${sort.sort_by === 'score' && 'active-col-head'}  ${sort.sort_by === 'score' ? `${sort.sort_dir === 'asc' ? `arrow-option__up` : `arrow-option__down`}` : `arrow-option__down`}`} onClick={() => {
                                        doSort({ sort_by: 'score', sort_dir: (sort.sort_dir === 'asc') ? 'desc' : 'asc' }, true)
                                    }}><a>Score</a></th>
                                    <th className={`cnt_col-3 ${sort.sort_by === 'pipeline_status' && 'active-col-head'} ${sort.sort_by === 'pipeline_status' ? `${sort.sort_dir === 'asc' ? `arrow-option__up` : `arrow-option__down`}` : `arrow-option__down`}`} onClick={() => {
                                        doSort({ sort_by: 'pipeline_status', sort_dir: (sort.sort_dir === 'asc') ? 'desc' : 'asc' }, true)
                                    }}><a>Stage</a></th>
                                    <th className="cnt_col-4">Tags</th>
                                    <th className={`cnt_col-5 ${sort.sort_by === 'sources' && 'active-col-head'}  ${sort.sort_by === 'sources' ? `${sort.sort_dir === 'asc' ? `arrow-option__up` : `arrow-option__down`}` : `arrow-option__down`}`} onClick={() => {
                                        doSort({ sort_by: 'sources', sort_dir: (sort.sort_dir === 'asc') ? 'desc' : 'asc' }, true)
                                    }}>
                                        <a>Source</a></th>
                                    <th className="cnt_col-6">Deals</th>
                                    <th className={`cnt_col-7 ${sort.sort_by === 'last_contact_date' && 'active-col-head'}  ${sort.sort_by === 'last_contact_date' ? `${sort.sort_dir === 'asc' ? `arrow-option__up` : `arrow-option__down`}` : `arrow-option__down`}`} onClick={() => {
                                        doSort({ sort_by: 'last_contact_date', sort_dir: (sort.sort_dir === 'asc') ? 'desc' : 'asc' }, true)
                                    }}><a>Last Updated</a></th>
                                    <th className="cnt_col-8">Phone</th>
                                </tr>
                            </thead>
                            <tbody>

                                {result.loading && contacts.length === 0 ?
                                    <LoadingState fields={20} /> : ''
                                }
                                {!result.loading &&
                                    <EmptyState
                                        show={result.response?.total === 0 && contacts.length === 0 ? 'block' : 'none'}
                                        clear={clear}
                                        duplicate={showDuplicate}
                                        showImport={showImport}
                                        clearFilter={clearFilters}
                                        noContacts={filtersCountNo === 0 && serachField === "" ? true : false}
                                        search={applyFilter}
                                    />
                                }
                                {contacts.map((contact: any, index: number) =>
                                    <tr key={contact.id} className={` cursor--hand ${isSelected(contact.id) ? `row-selected` : ``}`} onMouseEnter={() => setEditRow(contact.id)}  >

                                        <td className="cnt_col-1 first_address_col cursor--hand">
                                            <a href={`/contacts/` + contact.id + `/overview`} >

                                                <div className="agent_signature agent_signature__large">
                                                    {(showEditRow === contact.id || isSelected(contact.id)) &&
                                                        <div className="agent_signature__photo  align-items-center justify-content-center background-img-none position-absolute opacity-86pr pt-1" onClick={(e: any) => e.stopPropagation()}>
                                                            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                <input type="checkbox" id="checkbox_button_medium_1" name="checkbox_button_medium" checked={isSelected(contact.id)} className="custom-control-input" onChange={() => selectToggle(contact.id, 0)} readOnly />
                                                                <label className="custom-control-label mr-0 p-0" htmlFor="checkbox_button_medium_1"></label>
                                                            </div>
                                                        </div>
                                                    }

                                                    <Avatar
                                                        id={contact.id}
                                                        photo={contact.photo_url}
                                                        firstName={contact.first_name}
                                                        middleName={contact.middle_name}
                                                        lastName={contact.last_name}
                                                    />

                                                    <div className="agent_signature__text  text-truncate">
                                                        <ContactTitle contact={contact} />
                                                        {contact.primary_email &&
                                                            <div className="agent_signature__text__desc  text-truncate">{contact?.primary_email}</div>
                                                        }
                                                    </div>
                                                    {showEditRow === contact.id &&
                                                        <a className='ml-3 mr-3 cursor--hand' onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            if (e.metaKey || e.ctrlKey) {
                                                                window.open('/contacts/' + contact.id + '/detail?edit=1', "_blank")
                                                            } else {
                                                                history.push('/contacts/' + contact.id + '/detail/edit')
                                                            }
                                                        }}>
                                                            <img className='w-24px' src='https://content.harstatic.com/media/icons/agent_header_edit.svg'></img>
                                                        </a>
                                                    }
                                                    <a className="btn btn--white btn--onlyicon  btn btn-none color_000" onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setContactDetail(contact);
                                                        setPop(true)
                                                    }}><IonIcon className='viewContactDetailPopover font_size--large mt-1px' name="eye-outline"></IonIcon>
                                                    </a>
                                                </div>
                                            </a>
                                        </td>
                                        <td className="cnt_col-2 cursor--hand text-center" onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setQuickEditPopup({ show: true, contact: contact, tab: "Score" })
                                        }}>
                                            {contact.score ?
                                                <div className={`scor_lone m-auto scor_lone__${contact?.score.toLocaleLowerCase()} scor_lone__medium mr-2`} onClick={(e: any) => e.stopPropagation()}>
                                                    <a className=' cursor_pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setQuickEditPopup({ show: true, contact: contact, tab: "Score" })
                                                    }}>
                                                        {contact.score}
                                                    </a>
                                                </div>
                                                :
                                                <div className='m-auto text-center'>
                                                    <img src="https://content.harstatic.com/media/common/svgs/pluse_only_transperant.svg" />
                                                </div>
                                            }
                                        </td>

                                        <td className="cnt_col-3 text-center" onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setQuickEditPopup({ show: true, contact: contact, tab: "Stages" })
                                        }}>
                                            {contact.pipeline_status && contact.pipeline_status !== '0' ?
                                                <div className={`m-auto ${getStageOption(contact.pipeline_status)?.class} `} onClick={(e: any) => e.stopPropagation()}>
                                                    <a className=' cursor_pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setQuickEditPopup({ show: true, contact: contact, tab: "Stages" })
                                                    }}>
                                                        {getStageOption(contact.pipeline_status)?.long_value}
                                                    </a>
                                                </div>
                                                :
                                                <div className='m-auto text-center'>
                                                    <img src="https://content.harstatic.com/media/common/svgs/pluse_only_transperant.svg" />
                                                </div>

                                            }
                                        </td>
                                        <td className="cnt_col-4 text-wrap" onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setQuickEditPopup({ show: true, contact: contact, tab: "Tags" })
                                        }}>
                                            {contact.tags?.length > 0 ?
                                                <>
                                                    {contact.tags?.map((tag: any, ind: number) =>
                                                        <div key={ind} className={`stage stage__tags ${ind == 0 ? `mt-1 mr-1` : ``}`} onClick={(e: any) => e.stopPropagation()}>
                                                            <a className=' cursor_pointer' onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setQuickEditPopup({ show: true, contact: contact, tab: "Tags" })
                                                            }}>{tag.name}</a></div>

                                                    )}
                                                </>
                                                :
                                                <div className='m-auto text-center' >
                                                    <img src="https://content.harstatic.com/media/common/svgs/pluse_only_transperant.svg" />
                                                </div>

                                            }
                                        </td>
                                        <td className="cnt_col-5" onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setQuickEditPopup({ show: true, contact: contact, tab: "Source" })
                                        }}>
                                            <a className='cursor--hand cursor_pointer' onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setQuickEditPopup({ show: true, contact: contact, tab: "Source" })
                                            }}>{(contact.sources_text && contact.sources_text.toLowerCase() !== 'none') ? contact.sources_text :

                                                <div className='m-auto text-center'>
                                                    <img src="https://content.harstatic.com/media/common/svgs/pluse_only_transperant.svg" />
                                                </div>


                                                }</a>
                                        </td>
                                        <td className="cnt_col-6 text-wrap">
                                            <a className="anchorCell" onClick={() => history.push(`/contacts/` + contact.id + `/deals`, { referror: '/contacts' })} >
                                                {contact.deal_stages.length > 0 ?
                                                    <>
                                                        {contact.deal_stages?.map((deal: any, index: number) =>
                                                            <div key={index} className=" cursor_pointer message--success message--small p-2 mb-1 mx-2 d-inline-block" onClick={(e: any) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                history.push(`contacts/` + contact.id + `/deals`, { referrer: '/contacts' })
                                                            }}>{deal.name + ' (' + deal.total_deals + ')'}
                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <div className='m-auto text-center'>
                                                        <img src="https://content.harstatic.com/media/common/svgs/pluse_only_transperant.svg" />
                                                    </div>
                                                }
                                            </a>
                                        </td>
                                        <td className="cnt_col-7" >
                                            <a className="anchorCell" href={`/contacts/` + contact.id + `/overview`} >

                                                <div className="warning warning__small" onClick={(e: any) => e.stopPropagation()}><div className="warning__small__text font_weight--bold">{contact.last_contact_date_text}</div></div>
                                            </a>
                                        </td>
                                        <td className={`cnt_col-8 ${contacts.length - 1 === index ? `border-bottom` : ``}`}>
                                            <a className="anchorCell" href={`/contacts/` + contact.id + `/overview`} >
                                                {contact.primary_phone &&
                                                    <>
                                                        <div className="font_weight--semi_bold mb-1">{transformPhoneNumber(contact.primary_phone_country_code, contact.primary_phone, contact.primary_phone_extension)}</div>
                                                        <a onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            window.location.href = 'tel://' + contact.primary_phone;

                                                        }} className="mr-2">Call</a>
                                                        <CopyToClipboard text={contact.primary_phone}
                                                            onCopy={() => setCopied(true)}>
                                                            <a onClick={(e: any) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                            }} className='cursor_pointer color_0738CD'>Copy</a>
                                                        </CopyToClipboard>
                                                    </>
                                                }
                                            </a>
                                        </td>

                                    </tr>

                                )}
                                <tr className="ion-nowrap" onMouseEnter={() => setEditRow("")} >
                                    <td className="cnt_col-1 first_address_col" ></td>
                                    <td className="cnt_col-2"></td>
                                    <td className="cnt_col-3"> </td>
                                    <td className="cnt_col-4"></td>
                                    <td className="cnt_col-5"></td>
                                    <td className="cnt_col-6"></td>
                                    <td className="cnt_col-7"></td>
                                </tr>
                            </tbody>




                            {result?.response?.total_pages && result?.response?.total_pages > Number(page) ?
                                <IonInfiniteScroll threshold='10%'
                                    onIonInfinite={(ev) => {
                                        loadMore();
                                        setTimeout(() => ev.target.complete(), 500);
                                    }}><IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                                :
                                <></>
                            }
                        </table>
                    </div>
                </IonSegment>
            </div >
        </>

    );

}

const LoadingState = (props: ILoadingGrid): JSX.Element => {
    const { fields } = props
    const LoadingField = (): JSX.Element => {
        return (
            <tr className=" ion-nowrap" >
                <td className="cnt_col-1  first_address_col">
                    <a href="#" className="agent_signature agent_signature__large agent_signature__loading">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-50"></div>
                            <div className="agent_signature__loading__textline max-w-90"></div>
                        </div>
                    </a>
                </td>
                <td className="cnt_col-2"><div className="loading_text max-w-32"></div></td>
                <td className="cnt_col-3"><div className="loading_text max-w-32"></div></td>
                <td className="cnt_col-4">
                    <div className="loading_text max-w-100"></div>
                </td>
                <td className="cnt_col-5"><div className="loading_text max-w-88"></div></td>
                <td className="cnt_col-6"><div className="loading_text max-w-56"></div></td>
                <td className="cnt_col-7">
                    <div className="loading_text max-w-56"></div>
                </td>
                <td className="cnt_col-8">
                    <div className="loading_text max-w-88"></div>
                    <div className="loading_text max-w-88"></div>
                </td>
            </tr >
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < fields; i++) {
        rows[i] = i
    }
    return (
        <>
            {
                rows.map((index: number) =>
                    <LoadingField key={index} />
                )
            }
        </>
    );
}

const EmptyState = (props: IEmptyGrid): JSX.Element => {
    const { noContacts, show, duplicate, showImport } = props
    const clear = () => {
        if (props.clearFilter) {
            props.clearFilter()
        }
        if (props.clear) {
            props.clear()
        }
        if (props.search) {
            props.search({
                cities: [],
                scores: [],
                stages: [],
                owners: [],
                zip_codes: [],
                deals: [],
                companies: [],
                sources: [],
                tags: [],
                last_contact_date: "",
                name: "",
                birthday_start: "",
                birthday_end: "",
                home_anniversary_start: "",
                home_anniversary_end: "",
                lead_type: "",
                keyword: ""
            })
        }

    }
    return (
        <>
            <div className={`d-${show}`}>
                <div className="p-5">
                    {!noContacts &&
                        <>
                            <div className="font_weight--semi_bold font_size--large pb-3">No contact matches</div>
                            <div className="pb-4">Try changing or removing some of your search filters.</div>
                            <button role="button" className="btn btn--ordinary btn--medium" onClick={() => clear()}>Clear Filters</button>
                        </>
                    }
                    {noContacts &&
                        <>
                            <div className="font_weight--semi_bold font_size--large pb-3">
                                {duplicate ? "You don’t have any duplicated contacts" : "You don't have any contacts"}
                            </div>
                            <div className="pb-4"></div>
                            {!duplicate &&
                                <div >
                                    <button role="button" onClick={() => showImport?.()} className="btn btn--white btn--white__slate btn--inlineicon"><img src="https://content.harstatic.com/media/icons/down_arrow_auxialary.svg" /><span>Import</span></button>
                                    <IonRouterLink routerLink="/contacts/create">
                                        <button role="button" className="btn btn--prominent__v2 btn--small btn--inlineicon ml-2"><img className="align-self-center w-12px mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" /><span>New Contact</span></button>
                                    </IonRouterLink>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export const WatchNLearn = () => {
    const [addContactVisiblity, setAddContactVisiblity] = useState(false)
    const [contactManagementVisiblity, setContactManagementVisiblity] = useState(false)

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" variant="none" className="btn w-100 btn--white btn--white__slate align-items-center d-flex btn--small pr-2 mangement-watch-learn">Watch & Learn</Dropdown.Toggle>
                <Dropdown.Menu alignRight style={{ minWidth: '250px' }}>
                    <Dropdown.Item onClick={() => setAddContactVisiblity(true)} className="d-flex align-items-center"><img className=" w-22px mr-1" src="https://content.harstatic.com/media/icons/listing_details/icon_play_for_button.svg" /><span>Contacts</span></Dropdown.Item>
                    <Dropdown.Item onClick={() => setContactManagementVisiblity(true)} className="d-flex align-items-center"><img className=" w-22px mr-1" src="https://content.harstatic.com/media/icons/listing_details/icon_play_for_button.svg" /><span>Contact Management</span></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={addContactVisiblity} onHide={() => setAddContactVisiblity(false)} centered size="xl">
                <Modal.Body className="p-0 position-relative">
                    <div className=" cursor--hand position-absolute" style={{ top: '-18px', right: '-28px' }} onClick={() => setAddContactVisiblity(false)}><img className=" w-22px" src="https://content.harstatic.com/media/icons/cross_icon_white_circle.svg" /></div>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/_hJxWWIinFM?si=O7M5c-aHfY1aW59L" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={contactManagementVisiblity} oonHide={() => setContactManagementVisiblity(false)} centered size="xl">
                <Modal.Body className="p-0 position-relative">
                    <div className=" cursor--hand position-absolute" style={{ top: '-18px', right: '-28px' }} onClick={() => setContactManagementVisiblity(false)}><img className=" w-22px" src="https://content.harstatic.com/media/icons/cross_icon_white_circle.svg" /></div>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/YAWFPMfi6nY?si=EQCizD_-rFIFFNVj" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}



export const ContactsTableDesktop = (props: IContactsTableProps): JSX.Element => {
    return useResponsiveRender(ContactsTableLarge(props), ContactsTableApp(props))
} 
