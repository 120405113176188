import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace ImportResultApi {
    export const url = "/provider/contacts/bulk_import_results/:import_id";
    export const method = "GET";
    export const defaultError = "Failed to load response result.";
    export const operations = apiOperations(ImportResultApi);
    export const scope = new class ImportResultApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        import_id: string

    }

    export interface Response {
        total: string,
        total_added: string,
        total_updated: string,
        total_failed: string,
        import_id: string,
        list: IImportContactResult[]
    }

    export interface IImportContactResult {
        order_number: string,
        integration_id: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        primary_email: string,
        primary_phone: string,
        status: string,
        contact_id: string,
        errors: IImportContactFailed[]
    }
    export interface IImportContactFailed {
        reason: string
        message: string
    }
}

export const fetchImportResultService = forwardOperation(ImportResultApi.operations, 'fetch');
export const useFetchImportResultService = forwardOperation(ImportResultApi.operations, 'useFetch');
export const useImportResultService = forwardOperation(ImportResultApi.operations, 'useService');