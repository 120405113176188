import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace SendEmailsApi {
    export const url = "/emails/drafts/:id";
    export const method = "PUT";
    export const defaultError = "Problem in saving Email,please try again later.";
    export const operations = apiOperations(SendEmailsApi);
    export const scope = new class SendEmailsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string | string[] | IEmailAttachment[]> {
            return {
                ...input,

            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        id: string
        send?: string,
        subject?: string,
        message?: string,
        recipients?: string[],
        recipients_cc?: string[],
        recipients_bcc?: string[],
        message_uid?: string,
        reply_to_message_id?: string,
        attachments?: IEmailAttachment[]

    }

    export interface IEmailAttachment {
        container?:string,
        file_name?:string,
        content_id?:string,
        key?:string,
        file_preview?:string,
        id?:string,
        content_type?:string,
        hasPreview?:boolean,
        file_size?:string,
    }


    export interface Response {

    }
}

export const fetchSendEmailsService = forwardOperation(SendEmailsApi.operations, 'fetch');
export const useFetchSendEmailsService = forwardOperation(SendEmailsApi.operations, 'useFetch');
export const useSendEmailsService = forwardOperation(SendEmailsApi.operations, 'useService');