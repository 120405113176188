import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace LogoutApi
{
    export const url = "/user/logout";
    export const method = "POST";
    export const defaultError = "Sign out failed";
    export const operations = apiOperations(LogoutApi);
    export const scope = new class LogoutApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        
    }
}

export const fetchLogoutService = forwardOperation(LogoutApi.operations, 'fetch');
export const useFetchLogoutService = forwardOperation(LogoutApi.operations, 'useFetch');
export const useLogoutService = forwardOperation(LogoutApi.operations, 'useService');