import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { AddTaskPopUp } from 'views/calendar/popups/AddTaskPopUp';
import { ICalendarTemplateProps } from './CalendarTemplate.def';
import './CalendarTemplate.scss';
import moment from 'moment';
export const CalendarTemplateDesktop = (props:ICalendarTemplateProps): JSX.Element =>
{   
    const [showAddTask,toggleShowAddTask] = useState(false);
      const addMonth=async ()=>{
        props.setMonth(moment(props.month).add(1, 'month').toDate());
      }
      const subMonth=async()=>{
        props.setMonth(moment(props.month).subtract(1, 'month').toDate());
      }

    return (
        <> <div className="container">
             <div className="page_title_container">
                <div className="ml-auto mr-auto">
                    <div className="row">
                        <div className="col-lg-auto col-6 text-lg-left text-right order-lg-0 order-1">
                            <ToggleButtonGroup className="btn-group--custom mr-3" type="radio" name="options" defaultValue={1}>
                                <ToggleButton onClick={()=> props.onFilterChange?.({categories:"T,E"})} id="tbg-radio-1" value={1}>Any</ToggleButton>
                                <ToggleButton onClick={()=> props.onFilterChange?.({categories:"E"})} id="tbg-radio-2" value={2}>Events</ToggleButton>
                                <ToggleButton onClick={()=> props.onFilterChange?.({categories:"T"})} id="tbg-radio-3" value={3}>Tasks</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className="col-lg col-12 order-lg-1 order-0 text-center pb-3 pb-lg-0">
                            <div className="ml-auto mr-auto d-inline-block">
                                <div className="row ml-auto mr-auto small-gutters">
                                <div className="col-auto align-self-center pr-2 cursor--hand">
                                    <div onClick={subMonth} className="d-inline-flex color_carbon pt-1 "><img className="d-inline-flex" src="https://content.harstatic.com/media/icons/slider_arrow_darkblue_left.svg" /> <span className="align-self-center">Prev Month</span></div>
                                </div>
                                <div className="col-auto align-self-center font_size--large_extra_extra text-center cursor--hand">
                                    <span className="font_weight--bold">{moment(props.month).format("MMMM")},</span> {moment(props.month).format("YYYY")}
                                </div>
                                <div className="col-auto align-self-center cursor--hand">
                                <div onClick={addMonth} className="d-inline-flex color_carbon"><span className="align-self-center">Next Month</span> <img src="https://content.harstatic.com/media/icons/slider_arrow_darkblue_right.svg" /> </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-auto col-6 text-lg-right text-left order-2">
                            <ToggleButtonGroup className="btn-group--custom" type="radio" value={props.section} name="options" defaultValue={"timeline"}>
                                <ToggleButton id="tbg-radio-1" onClick={()=> props.onSectionChange?.("timeline")}  value={"timeline"}>Timeline</ToggleButton>
                                <ToggleButton id="tbg-radio-2" onClick={()=> props.onSectionChange?.("overview")}  value={"overview"}>Activity View</ToggleButton>
                            </ToggleButtonGroup> 
                            <a className="btn btn btn--prominent__v2 btn--small ml-4 d-inline-flex" onClick={()=>toggleShowAddTask(true)}>
                                <img className="mr-1 w-22px" src="https://content.harstatic.com/media/icons/icon_plus_white.svg" /> Create 
                            </a> 
                        </div>  
                    </div>
                </div>
            </div>
                {props.children}
            </div>
            <AddTaskPopUp refreshData={props.refreshData} show={showAddTask} toggle={toggleShowAddTask} />
         </>
    );
}
