import { IonModal } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Subject } from "rxjs";
import { JSXAttributes } from "types/react";

const source = new Array<JSX.Element>();
const entries = new Subject<JSX.Element[]>();

export function usePopUpSpace()
{
    const [ popups, setPopUps ] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const subscription = entries.subscribe(popups => setPopUps(popups))
        return () => subscription.unsubscribe();
    }, []);

    return popups;
}

export function usePopUp<P extends JSXAttributes.Exotic<typeof IonModal>>(PopUp:(props:P) => JSX.Element | null, userProps:P)
{
    const [show, setShow] = useState(false);
    const element = useRef<JSX.Element>();
    const props:P = {
        ...userProps,
        isOpen:true,
        onWillDismiss(e) {
            userProps.onWillDismiss?.(e);
            setShow(false);
            //e.preventDefault();
        }
    };

    useEffect(() => {
        if(show && !element.current) {
            element.current = <PopUp {...props} key={source.length} />;
            add(element.current);
        }

        else if(!show && element.current) {
            remove(element.current);
            element.current = undefined;
        }
        
    });

    const present = () =>
    {
        setShow(true);
    }

    const dismiss = () =>
    {
        if(element.current) {
            const index = source.indexOf(element.current);
            update(index, element.current = <PopUp {...props} isOpen={false} key={index} />);
        }

        setShow(false);
        
    }

    return [present, dismiss] as const;
}
export function usePopUpAutoFocus(ref:React.RefObject<HTMLIonModalElement>,timeout:number=1000){
    useEffect(() => {
        const timer = setTimeout(() => {
            const { current } = ref;
            if(!current) { return; }
            const node = current.querySelector<HTMLIonInputElement>('[autofocus]');
            if(node && 'setFocus' in node) {
                node.setFocus();
            }
        }, timeout);

        return () => clearTimeout(timer);

    }, []);
}

function add(element:JSX.Element)
{
    source.push(element);
    entries.next([...source]);
}

function update(index:number, replacement:JSX.Element)
{
    if(index >= source.length) { return; }

    source[index] = replacement;
    entries.next([...source]);
}

function remove(element:JSX.Element)
{
    const index = source.indexOf(element);
    if(index === -1) { return; }

    source.splice(index, 1);
    entries.next([...source]);
}