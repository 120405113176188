import {  IAddressesSectionProps, IAddressItem, IAddressItemErrors } from './AddressesSection.def';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import './AddressesSection.scss';
import {  IonItem, IonText,IonSelect ,IonSelectOption} from '@ionic/react';
//import { useValidateConsumer } from 'scripts/hooks/validations';
import { SetStateAction, useEffect, useState } from 'react';
import { AddressesTypeahead } from 'views/forms/typeaheads/AddressesTypeahead';
import { isString } from 'lodash-es';
import { useFetchLookupsService } from 'services/general/lookups';
import { useRemoveAddressService } from 'services/contacts/contactDetails/removeAddress';
import { useParams } from 'react-router';

//import { useParams } from 'react-router';
const emptyAddress = () => ({ 
  address1:'',
  address2:'',
  address_type:'',
  city:'',
  zipcode:""
})

export const AddressesSectionApp = (props:IAddressesSectionProps): JSX.Element =>
{
    const { initialItems, onItemsChange } = props;
    const [items, setAddresses] = useState<Array<IAddressItem>>([]);
   // const [ ,notifyChange] = useValidateConsumer(props.validate, validate);
    const [errors, updateErrors] = useState<IAddressItemErrors[]>([]);
    const [lookupData] = useFetchLookupsService('address_type')
    const [, ActionDeleteAddress] = useRemoveAddressService();
    const { id } = useParams<{ id: string }>();

    const maxItems = props.maxItems ?? 10;

    useEffect(() => {
      if(initialItems === items) { return; }
      
      const addresses = initialItems ?? [];
      setAddresses(addresses.length > maxItems ? addresses.slice(0, maxItems): addresses);
   //   notifyChange()
      
    }, [initialItems]);

   /* const fieldCheck = (data: any, required: boolean = true): boolean => {
        let property = data;
        const value = property?.toString()?.replaceAll("  ","")
        if (!value || value === '') {
          return !required;
        }

        if (value.length < 3) {
          return false;
        }
        return true;
  };*/

  /*function validate() {
    if(items.length===0){return true;}
    const fieldEmptyMsg='This field is required';
    

    const form = items.map((item)=>{
      const errors:Partial<IAddressItemErrors> = {};
          errors.address1 = !fieldCheck(item.address1) ? fieldEmptyMsg :'';
          errors.address_type = !fieldCheck(item.address_type) ? fieldEmptyMsg :'';
          errors.city = !fieldCheck(item.city) ? fieldEmptyMsg : '';
          errors.zipcode = !fieldCheck(item.zipcode) ? fieldEmptyMsg : '';
      return errors;
    });

    updateErrors(form);

    const isValid = form.map((item)=> Object.entries(item).every(([, value]) => !value)).includes(true);
    return isValid;
    
  }*/

  const handleAddNew = () => {
    updateErrors([...errors,{}]);
    updateAddresses([ ...items, emptyAddress() ]);
  }
  
  const handleRemoveState=(index:number)=>{
    items.splice(index, 1)
    errors.splice(index, 1);
    updateErrors([...errors]);
    updateAddresses([...items]);
  } 
  const handleRemoveAddress = async(index: number) => {
    if(items[index]?.id !== undefined){ 
      await ActionDeleteAddress.fetch({
            contact_id: id!,
            address_id: items[index]?.id?.toString()??"",
        });
       // if(deleteAddress.response?.status=='ok'){
          handleRemoveState(index);
        //}
    }else{
      handleRemoveState(index);
    }

  };

  const handleChangeField = (key:number, name:string, value:string) =>
  {
    updateAddresses((current:any) => {
    if(!current[key]) { return current; }
    current[key][name] = value;
   // notifyChange();
      //validate();
     handleSave(key, current);
    return current;
  });

    updateErrors((current:any) => {
      if(!current[key]) { current[key] = {}; }
      current[key][name] = '';
      return [ ...current ];
    });
  }

  const updateAddresses = (value:SetStateAction<IAddressItem[]>) =>
  {
    if('call' in value) {
      return setAddresses((current:IAddressItem[]) => {
        const result = value(current);
        onItemsChange?.(result);
        return [...result];
      });
    }
    
    setAddresses?.(value);
    onItemsChange?.(items);
    //validate()
  };
  const handleSave = (index:number, current?:IAddressItem[]) => {

    if(!current) { current = items; }
    if(current) {
       const item = current[index];
      if(!item) { return; }

     //const errorMessage = validate()
    //  if(!errorMessage ) {
        props.handleSave?.(index);
      //}
    }}
    const isAddressList = !!items?.length;
    return (<>
        <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Address</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">
            {!!isAddressList && items.map((item,key)=>
              <FormView
                key={key}
                addressList={items}
                address={item}
                errors={errors[key]!}
                onRemove={() => handleRemoveAddress(key)}
                onUpdate={(name, value) => handleChangeField(key, name, value)}
                lookupData={lookupData.response?.address_type?.items}
                //handleSave={()=>handleSave(key)}
              
              />
            )}
            {items.length<10&&<div className="addable_item pb-3 pt-3" onClick={handleAddNew}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Address</div>
            </div>} 
         </div>
     </>)
}

const FormView = (props: IAddressesSectionProps.AppFormViewProps): JSX.Element => {
    const { addressList, address, errors, onRemove, onUpdate,lookupData } = props;
    //const [address1, setAddress1] = useState({ value: '', error: '' });
    const index = address? addressList.indexOf(address): addressList.length;

    const onChange=(value:any)=>{
      onUpdate("address1",String(value?.address1?.replace(/\s{2,}/g, ' ') ?? ''));
      onUpdate("zipcode",String(value?.zipcode)); 
      onUpdate("city",String(value?.city?.replace(/\s{2,}/g, ' ') ?? ''))
      onUpdate("state",String(value?.state?.replace(/\s{2,}/g, ' ') ?? ''))
      onUpdate("country",String(value?.country?.replace(/\s{2,}/g, ' ') ?? ''))
      //handleSave?.()
    }

    return (
      <div className="border-bottom">
        <div className="row no-gutters">
          <div className="col-auto align-self-center">
            <div className="delete_item d-flex">
              {<img onClick={()=>onRemove()}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />}
              <div className="delete_item_value">Addr. {index+1} </div>
              <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
            </div>
          </div>
          <div className="col">
          <IonItem style={{marginTop:6}}>                                                
              <IonSelect value={address?.address_type} name='address_type' id='address_type' placeholder="Address Type" onIonChange={(e) => onUpdate("address_type",String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}>
                {lookupData?.map((item, index) => (
                        <IonSelectOption key={index} value={item.short_value}>{item.long_value}</IonSelectOption>
                    ))}
              </IonSelect>
            </IonItem>
            {isString(address?.address_type) && address?.address_type?.trim() == ''?
            <div  style={{color:'red',marginLeft:15}}>{errors?.address_type}</div>:null}
            <AddressesTypeahead key={index} initialValue={address} onTextChange={(value:string)=>onUpdate("address1",String(value?.replace(/\s{2,}/g, ' ') ?? ''))}  error={errors?.address1} change={onChange} />
            <Textbox  value={address?.address2} placeholder='Address 2' name='address2' maxlength={50} containerClassName="mb-4" id="Address" aria-describedby="Address" onIonChange={(e) => onUpdate(e.target.name, e.detail.value?.replace(/\s{2,}/g, ' ') ?? '')} ></Textbox>          
            <Textbox value={address?.city} name='city' placeholder='City' maxlength={50} id="City" aria-describedby="City" error={errors?.city} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
            <Textbox value={address?.zipcode || ''} name='zipcode' placeholder='Zip Code' maxlength={10} type='text' id="ZipCode" aria-describedby="ZipCode" error={errors?.zipcode} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
            <Textbox value={address?.state} name='state' placeholder='State' maxlength={15} className="form-control" id="State" aria-describedby="State" error={errors?.state} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
            <Textbox value={address?.country || ''} name='country' placeholder='Country' maxlength={50} type='text' className="form-control mb-3" id="country" aria-describedby="country" error={errors?.country} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
        
          </div>
        </div>
      </div>        
    )
}