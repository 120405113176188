import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace GetRecommendedItemsApi
{
    export const url = "/user/recommended_items";
    export const method = "GET";
    export const defaultError = "Failed to load recommended items";
    export const operations = apiOperations(GetRecommendedItemsApi);
    
    export const scope = new class GetRecommendedItemsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input,
                page: input.page?.toString(),
                page_size: input.page_size?.toString(),
                version:"1"
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data,
                total: parseInt(data.total),
                page: parseInt(data.page),
                page_size: parseInt(data.page_size),
                total_pages: parseInt(data.total_pages)
			}
        }
    }();
    
    export interface Request
    {
        page?: number,
        page_size?: number,
        version?:number
    }

    export interface Response
    {
        "total": number,
        page: number,
        page_size: number,
        total_pages: number,
        "list": Array<IResponseList>
    }
    export interface IResponseList  {
                "id": string,
                "title": string,
                "link": string,
                "description": string,
                "created_dt": string,
                "completed_dt":string,
                "completed":string,
                "type": string,
                "sub_type":string,
                "sub_type_text":string,
                "stay_in_touch":string,
                "type_text": string,
                "status": string,
                "status_text": string,
                "full_name":string,
                "first_name":string,
                "last_name":string,
                "deleted":string,
                "photo":string,
                "userid":string,
                "type_object_id":string,
                "payload": {
                    "id": string,
                    "first_name":string,
                    "middle_name": string,
                    "last_name": string,
                    "photo_url": string,
                    "score": string,
                    "contact_id":Array<string>
                }
            }
}

export const fetchGetRecommendedItemsService = forwardOperation(GetRecommendedItemsApi.operations, 'fetch');
export const useFetchGetRecommendedItemsService = forwardOperation(GetRecommendedItemsApi.operations, 'useFetch');
export const useGetRecommendedItemsService = forwardOperation(GetRecommendedItemsApi.operations, 'useService');