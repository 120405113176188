import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { Draggable } from "react-beautiful-dnd";
import { IStageAddContactsProps, IStageColumnEmptyProps, IStageColumnLoadingProps, IStageColumnProps } from './StageColumn.def';
import './StageColumn.scss';
import { ContactCard } from './ContactCard';
import { AddPopover } from './AddPopover';
import { LazyScroll } from 'views/general/LazyScroll';

export const StageColumnDesktop = (props: IStageColumnProps): JSX.Element => {
    const { pipeline, openPopover, className, title, total, page, totalPages, contacts, loading, addContact, removeContact, loadMore, setOpenPopover } = props



    const handleScroll = (page: number) => {
        loadMore?.({ stages: [pipeline], sort_by: 'pipeline_status_order', sort_dir: 'desc', renew: 1, page: page })
    }


    return (
        <div className={`pipeline_col min-h-400px overflow-hidden ${className}`} >
            <div className="pipeline_col_title position-sticky top-0 zindex-1 bg-color-f3f4f7" onClick={(e: any) => {
                e.stopPropagation()
            }}>
                <div className="pipeline_col_title_heading"><strong>{title} </strong> <span className="font_size--small">({total})</span></div>
                <a className="ml-auto add_pipeline_item__addbtn" href="#" onClick={(e: any) => { e.preventDefault(); setOpenPopover?.(pipeline ? pipeline : 0) }}><img src="https://content.harstatic.com/media/icons/add_bold_blue.svg" /></a>
                <AddPopover visible={pipeline === openPopover ? true : false} setVisiblity={() => setOpenPopover?.(0)} pipeline={pipeline} addContact={(contact: any, param: any) => {
                    addContact?.(contact, param);
                    setOpenPopover?.(0)

                }} />
            </div>
            {loading ? <LoadingState fields={4} /> : ''}
            {total === 0 && contacts && contacts.list.length === 0 && !loading && <EmptyState pipeline={pipeline} />}
            <LazyScroll
                hasScroll={page && totalPages && page < totalPages ? true : false}
                page={page ? page : 0}
                className={'pipeline_items fancy_scroll'}
                onload={handleScroll}
            >
                {contacts && contacts.list.map((contact: any, index: number) =>
                    <Draggable key={contact.id} draggableId={`${contact.id}`} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`contact-card-jr card card_hover_edit text-left mb-3 p-3 ${snapshot.isDragging ? "draggingRotate" : ""}`}
                            >
                                <ContactCard
                                    contact={contact}
                                    pipeline={pipeline}
                                    removeContact={removeContact}
                                    referrer={'/contacts/pipeline'}
                                    redirectToContact={true}

                                />
                            </div>
                        )}
                    </Draggable>
                )}
            </LazyScroll>

        </div>


    );
}

const LoadingState = (props: IStageColumnLoadingProps): JSX.Element => {
    const { fields } = props
    const LoadingField = (): JSX.Element => {
        return (
            <div className="pipeline_items">
                <div className="card p-3 text-left mb-3">
                    <div className="agent_signature agent_signature__large agent_signature__loading">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-50"></div>
                            <div className="agent_signature__loading__textline max-w-90"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < fields; i++) {
        rows[i] = i
    }
    return (
        <>
            {
                rows.map((index: number) =>
                    <LoadingField key={index} />
                )
            }
        </>
    );

}


const EmptyState = (props: IStageColumnEmptyProps): JSX.Element => {
    const { pipeline } = props
    let history = useHistory<IStageAddContactsProps>()
    const newContact = () => {
        history.push(`/contacts/create`, { pipeline_status: pipeline ? pipeline : 0 })
    }
    return (
        <div className="pipeline_items">
            <IonButton className="btn btn--flate d-block mb-0" onClick={newContact}>Add Contact</IonButton>
        </div>
    );
}

