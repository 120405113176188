import { Tag } from 'scripts/ui/tag';
import { IPhotoUploadSectionViewProps } from './PhotoUploadSection.def';
import './PhotoUploadSection.scss';
import { OrdinaryButton } from 'views/forms/buttons/Button';

export const PhotoUploadSectionDesktop = (props: IPhotoUploadSectionViewProps): JSX.Element =>
{
    const {  imageUrl, onOpen } = props;

    return (
        <div className="photophoto mb-4 pb-3">
            <div className="agent_signature agent_signature__large__extra agent_signature__nagative mb-0 animate__animated  animate__fast">
            <Tag.Div className={`position-relative ${imageUrl?``:`photophoto__image`} nophoto agent_signature__photo bg-size-inherit
             `
            } 
             onClick={onOpen}
              cssVars={{ avatarAgentPicture: `url( ${imageUrl ? imageUrl : `https://content.harstatic.com/media/icons/icon-listing-provided-by-agent.svg`})` }}
              >
                <div className="photophoto__image__icon"></div>
            </Tag.Div></div>
            <div className="photophoto__image__btn pt-2">
                <div className="input-group">
                    <div className="custom-file">
                        <OrdinaryButton size='small' className='btn--small-extra w-92px' onClick={onOpen}>Upload Photo</OrdinaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
