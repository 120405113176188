import { IManageTagsPopUpProps, IListManageTagsPopupProps, IdeleteWarningProps, IemptyProps } from './ManageTagsPopUp.def';
import './ManageTagsPopUp.scss';
import { useEffect, useState } from 'react';
import { fetchGetTagsService } from 'services/contacts/tags/getTags';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { OrdinaryButton, PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useDeleteTagService } from 'services/contacts/tags/deleteTag';
import { useResponsiveRender } from 'scripts/hooks/general';
import { IonModal } from '@ionic/react';
import { NewTagPopUp } from '../NewTagPopUp';
import { useUpdateTagService } from 'services/contacts/tags/updateTag';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';


//import { ManageTagsPopUpApp } from './ManageTagsPopUp.app';

export const ManageTagsPopUpDesktop = (props: IManageTagsPopUpProps): JSX.Element => {
  const { show, toggleShow,onTagsUpdated,setFilter } = props
  const [warning, setWarning] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [openNewTag, setOpenNewTag] = useState(false);
  const [tagPopupList, setTagPopupList] = useState<Array<IListManageTagsPopupProps>>();
  const [editTag, setEditTag] = useState("");
  const [editTagError, setEditTagError] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<IListManageTagsPopupProps>({ id: 0, name: "", contacts_count: 0, error: "" });
  const [deletedTag, setDelectedTag] = useState<IListManageTagsPopupProps>({ id: 0, name: "", contacts_count: 0 });
  const [updateData, updateActions] = useUpdateTagService();
  const [deleteTagInfo, deleteTagAction] = useDeleteTagService();
  const fetchData = async (): Promise<void> => {
    const response = await fetchGetTagsService();
    setTagPopupList(response);
    onTagsUpdated?.()
    return;
  };

  useEffect(() => {
    if (tagPopupList?.length === 0 && show) {
      setEmpty(true)
    }
  }, [show]);

  const handleSaveEditPopup = async () => {
    try {
      await updateActions.fetch({ name: editTag, id: selectedTag.id });
    } catch (error) { }
  }

  useEffect(() => {
    if (updateData.error) {
      setEditTagError(updateData.error.message)
    }
    if (updateData.response) {
      fetchData()
      onTagsUpdated?.()
    }
  }, [updateData]);



  const handleDelete = async () => {
    try {
      await deleteTagAction.fetch({ id: deletedTag.id });
    } catch (error) { }
  }
  useEffect(() => {
    if (deleteTagInfo.response) {
      setWarning(false)
      
      fetchData()
      onTagsUpdated?.()
    }
  }, [deleteTagInfo])





  useEffect(() => {
    if (show) {
      fetchData();
      
    }

  }, [show]);




  const Launcher = () => {
    return useResponsiveRender(<button role="button" className="btn btn--white btn--white__slate btn--inlineicon mr-2 d-none d-md-block"
      onClick={() => toggleShow?.(true)}><img src="https://content.harstatic.com/media/icons/a_all/icon_tags_blue.svg" /><span>Manage Tags</span></button>
      , <></>)
  }

  return (
    <>
      <Launcher />
      <EmptyState show={empty} backToContact={() => {
        setOpenNewTag(openNewTag ? false : true);
        setEmpty(empty ? false : true)
      }} toggleShow={() => {
        if (toggleShow)
          toggleShow()

        setEmpty(empty ? false : true)
      }} />
      <NewTagPopUp show={openNewTag} toggleShow={() => {
         setOpenNewTag(false);
         fetchData()
         
      }} />
      <DeleteWarning tag={deletedTag} handleDelete={() => handleDelete()} show={warning} toggleShow={() => setWarning(warning ? false : true)} />
      <DefaultPopUp isOpen={show} toggleShow={() => toggleShow?.()} title="Manage Tags" className="custom--modal" closeButton>
        <DefaultPopUp.Body>
          <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
          <div className="info_box info_box_withicon mb-3 mt-0">
            <div className="info_box__content color_carbon">Tags are labels that are assigned to organize and segment your contacts based on their real estate interests and activities, enabling more targeted and effective contact pipeline management.</div>
          </div>
            {tagPopupList?.map((item: any, key) =>
              item === selectedTag ?
                <div key={key} className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
                  <div className=" border-bottom pb-3 pt-3">
                    <div className="row">
                      <div className="col">
                        <Textbox
                          placeholder="Edit Tag"
                          required={true}
                          onIonInput={(e: any) => setEditTag(e.target.value)}
                          value={editTag}
                          error={editTagError}
                        />
                      </div>
                      <div className="col-auto align-self-start p-0">
                        <OrdinaryButton size="small" onClick={handleSaveEditPopup}>
                          Save
                        </OrdinaryButton>
                      </div>
                      <div className="col-auto align-self-start p-0">
                        <ShapelessButton size="small" onClick={() => setSelectedTag({ id: 0, name: "", contacts_count: 0 })}>
                          Cancel
                        </ShapelessButton>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div key={'view_' + key} className=" border-bottom pb-3 pt-3">
                  <div className="row">
                    <div className="col">
                      <div className="card_tags cursor--hand" onClick={() => {setFilter?.(item.id,item.name)}}>
                        {item?.name} <span className="card_tags_numbers">({item?.contacts_count})</span>
                      </div>
                    </div>
                    <div className="col-auto align-self-center">
                      <ShapelessButton
                        onClick={() => { setDelectedTag(item); setWarning(true) }}
                        size="small"
                      >
                        Delete
                      </ShapelessButton>
                    </div>
                    <div className="col-auto align-self-center">
                      <a className="btn btn--shapless" onClick={() => { setSelectedTag(item); setEditTag(item.name); setEditTagError('') }}>
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
            )}
          </div>

        </DefaultPopUp.Body>
        <DefaultPopUp.ActionBar>
          <div className="text-center h-100 d-flex justify-content-center">
            <div className={`align-self-center color_cement_dark`}>
              <PrimaryButton className="btn--large w-100" onClick={() => setOpenNewTag(true)}>Add Tag</PrimaryButton>
            </div>
          </div>
        </DefaultPopUp.ActionBar>

      </DefaultPopUp>
      {deleteTagInfo?.response && <SimpleDialog message={'Tag ' + deletedTag.name + ' deleted.'} />}
      {updateData?.response && updateData.response.status === 'ok' && <SimpleDialog message="Successfully saved." />}
    </>
  );
};
const DeleteWarning = (props: IdeleteWarningProps) => {
  const { tag, show, handleDelete, toggleShow } = props
  return (
    <>
      <IonModal isOpen={show} className=" custom--modal md modal-default hydrated" id="example-modal" trigger="open-custom-dialog" backdropDismiss={false}>
        <div className="p-4 mt-25pr">
          <h2 className="mb-3" tabIndex={0}>Delete Tag?</h2>
          <div className="color_sold font_size--medium font_weight--semi_bold mb-2">ATTENTION:</div>
          <ul className="list list--inside">
            <li className="mb-2">There are {tag.contacts_count} contacts with the tag {tag.name}.</li>
            <li className="mb-2">Deleting this tag is permanent and can’t be undone.</li>
            <li className="mb-2">All contacts allocated to it will be untagged.</li>
          </ul>
        </div>
        <div className="row">
          <div className="col">
            <ShapelessButton className="btn--large" onClick={() => toggleShow ? toggleShow() : ''}>Cancel</ShapelessButton>
          </div>
          <div className="col-auto text-right">
            <ShapelessButton className="btn--large btn--shapeless_dark btn--redtext" onClick={() => handleDelete ? handleDelete() : ''}>Yes, Delete Tag</ShapelessButton>
          </div>
        </div>
      </IonModal>
    </>
  )
}

const EmptyState = (props: IemptyProps) => {
  const { show, backToContact, toggleShow } = props
  const backTo = () => {
    if (backToContact) {
      backToContact()
    }
  }
  return (
    <>
      <DefaultPopUp title="Manage Tags" className="custom--modal" isOpen={show} toggleShow={() => toggleShow ? toggleShow() : ''}>
        <DefaultPopUp.Body>
          <div className="text-center h-100 d-flex justify-content-center">
            <div className="align-self-center color_cement_dark">
              <div className="font_weight--bold mb-3">No tag created</div>
              <div>Create tags to better organize and easily find your contacts</div>
              <PrimaryButton className="btn--large w-100" onClick={() => backTo()}>New Tag</PrimaryButton>
            </div>
          </div>
        </DefaultPopUp.Body>


      </DefaultPopUp>
    </>
  );
}

