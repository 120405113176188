import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateEmailApi {
    export const url = "/contacts/:contact_id/emails/:email_id";
    export const method = "PUT";
    export const defaultError = "Failed to update email.";
    export const operations = apiOperations(UpdateEmailApi);
    export const scope = new class UpdateEmailApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                id: parseInt(data.id),
                preferred: data.preferred === '1'
            }
        }
    }();

    export interface Request {
        contact_id: string,
        email_id: string,
        email: string,
        preferred: string
    }

    export interface Response {
        email:string;
        id: number;
        preferred: boolean;
    }
}

export const fetchUpdateEmailService = forwardOperation(UpdateEmailApi.operations, 'fetch');
export const useFetchUpdateEmailService = forwardOperation(UpdateEmailApi.operations, 'useFetch');
export const useUpdateEmailService = forwardOperation(UpdateEmailApi.operations, 'useService');