import { IMiniCalendarProps } from './MiniCalendar.def';
import './MiniCalendar.scss';
import {  useEffect, useState } from 'react';
import { Loading } from './states/loading';
import moment,{Moment} from 'moment';
import { formatSystemDate } from 'scripts/helpers/dates';

export const MiniCalendar = (props: IMiniCalendarProps): JSX.Element =>
{
  const month=moment(props.month);

  const [monthCalendar,setMonthCalender]=useState<Array<Array<{className:string,date:Date| Moment}>>>();

  useEffect(() => {
      calender()
    }, [props.show,props.month,props.upcoming,props.due,props.completed]); 

      const handleSelectValue=async(date:Date)=>{
        props.handleNavigateToDate(date);
      }

      const getClass=(isSelected:boolean,isUpcoming:boolean,isDue:boolean,isCompleted:Boolean)=>{
            var className='calendar_custom__row_col'
              if(isSelected && isUpcoming){className='calendar_custom__row_col selected_date selected_date__next current_date'}
               else if(isSelected){className='calendar_custom__row_col selected_date current_date selected_date__next'}
                else if(isDue){className='calendar_custom__row_col selected_date selected_date__overdue'}
                 else if(isUpcoming){className='calendar_custom__row_col selected_date selected_date__next'}
                 else if (isCompleted){className='calendar_custom__row_col selected_date selected_date__colplete'}
          return className
      }

      const calender=(selectedValue=moment().format("YYYY-MM-DD"))=>{
          var startWeek = month.startOf('month').week();
          var endWeek = month.endOf('month').week();

         if(month.month()==11&&endWeek==1){
          startWeek=48;
            endWeek=53;
          }
          let calendar = [];
          for(let week = startWeek; week<=endWeek;week++){
            
            calendar.push(Array(7).fill(0).map((n, i) => {
              
              var date=null
              if(month.month()===11){ 
                 date=moment(moment(props.month).format("YYYY-MM-DD")).week(week).startOf('week').clone().add(n + i, 'day');
                // date=moment(moment(props.month).format("YYYY-MM-")+day.format("DD"));
              }else{
                 date=month.week(week).startOf('week').clone().add(n + i, 'day');
              }
              const isUpcoming=props.upcoming.includes(formatSystemDate(moment(date)));
              const isDue=props.due.includes(formatSystemDate(moment(date)));
              const isSelected=moment(date).isSame(selectedValue);
              const isCompleted=props.completed.includes(moment(date).format('YYYY-MM-DD') );
            return ({className:getClass(isSelected,isUpcoming,isDue,isCompleted),date:date})}))
          }
          setMonthCalender(calendar);
      }
      
    const weekDays=["SUN","MON","TUE","WED","THU","FRI","SAT"];
    return (
    <div >
      {!props.show?<Loading/>:
       <div className="calendar_custom text">
            <div className="calendar_custom__header">
              <div className="calendar_custom__header__title">{month.format("MMMM")}</div>
              </div>
            <div className="calendar_custom__body">
                <div className="calendar_custom__row calendar_custom__row_title">
                {weekDays.map((dayName,ind)=><div key={ind} className="calendar_custom__row_col">{dayName}</div>)}
                </div>
               {monthCalendar?.map((item,ind)=> 
               <div key={ind} className="calendar_custom__row">
                 {item.map((item:{className:string,date:Date| Moment},index:number)=>
                   moment(item.date).month()===month.month() ? 
                    <div onClick={()=>handleSelectValue(moment(item.date).toDate())} key={index} className={item.className}>
                      <span>{moment(item.date).format("DD")}</span>
                    </div>
                    :<div key={index}  className="calendar_custom__row_col other_month mt-7px">{moment(item.date).format("DD")}</div>)}
                     </div>)}
             </div>
        </div>}
    </div>
    );
}
