import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"
import { AddDealApi } from "./addDeal";

export namespace UpdateDealApi {
    export const url = "/deals/:deal_id";
    export const method = "PUT";
    export const defaultError = "Failed to save Deal";
    export const operations = apiOperations(UpdateDealApi);
    export const scope = new class UpdateDealApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            const { deal_id, title, contact_id, deal_type, deal_stage, deal_priority,
                property_full_street_address, property_mlsnum, property_harid, property_for, property_listingid, property_city, property_address2, property_zip, property_state, property_country, deal_amount, estimated_deadline, notes } = input
            return {
                deal_id: deal_id,
                title: title,
                contact_id: contact_id,
                deal_type: deal_type,
                deal_stage: deal_stage,
                deal_priority: deal_priority,
                property_full_street_address: property_full_street_address,
                property_address2: property_address2,
                property_harid: property_harid,
                property_listingid: property_listingid,
                property_for: property_for,
                property_city: property_city,
                property_zip: property_zip,
                property_state: property_state,
                property_country: property_country,
                property_mlsnum: property_mlsnum,
                deal_amount: deal_amount,
                estimated_deadline: estimated_deadline,
                notes: notes

            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request extends AddDealApi.Request {
        deal_id: string
    }

    export interface Response {

    }
}

export const fetchUpdateDealService = forwardOperation(UpdateDealApi.operations, 'fetch');
export const useFetchUpdateDealService = forwardOperation(UpdateDealApi.operations, 'useFetch');
export const useUpdateDealService = forwardOperation(UpdateDealApi.operations, 'useService');