import { IonRouterLink } from '@ionic/react';
import { ContactsTable } from 'views/contacts/ContactsTable';
import { IContactsLandingScreenProps } from './ContactsLandingScreen.def';
import './ContactsLandingScreen.scss';
import { ManageTagsPopUp } from 'views/contacts/popups/ManageTagsPopUp';
import { ImportContactsPopUp } from 'views/contacts/popups/ImportContactsPopUp';
import { useEffect, useState } from 'react';
import { ContactManageTemplate } from 'views/templates/ContactManageTemplate';
import { IContactsFilters } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { useHistory } from 'react-router';
import { ModuleInfo } from 'views/general/ModuleInfo';
import { useMatrix } from '../hooks/useMatrix';
import { useCSVImportStatus } from '../hooks/useCSVImportStatus';



export const ContactsLandingScreenDesktop = (props: IContactsLandingScreenProps): JSX.Element => {
    const [showManageTag, setShowManageTag] = useState(false);
    const [showImportPopUp, setShowImportPopUp] = useState(false);
    const [reloadTags, setReloadTags] = useState(false);
    const { matrixContactStatus, matrixContactImportStatusId, MatrixImportMessage, IntiateMatrix } = useMatrix()
    const { csvImporting, CSVStatusBlock } = useCSVImportStatus()
    const [filters, setFilters] = useState<IContactsFilters>({})
    const [duplicate, setDuplicate] = useState(false);
    const setFilter = (param: any, reload: number) => {
        props.changeFilter?.(param, reload)
        setFilters({ ...filters, ...param })

    }


    const downloadContact = (param: any) => {
        if (props.downloadContact) {
            const result = props.downloadContact(param)
            return result;
        }
    }

    const history = useHistory()

    const Header = (): JSX.Element => {
        return (
            <div className="page_title_container">
                <div className="row">
                    <div className="col align-self-center">
                        <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Contacts</h2>
                    </div>
                    <div className="col-auto align-self-center d-none d-md-flex">
                        <ManageTagsPopUp show={showManageTag}
                            toggleShow={(show?: boolean) => setShowManageTag(show ? show : false)}
                            onTagsUpdated={() => setReloadTags(true)}
                            setFilter={(id: string, name: string) => {
                                if (duplicate) {
                                    return
                                }
                                if (!filters.tags?.find((tag) => tag.id === Number(id))) {
                                    props.changeFilter?.({ 'tags': [{ ...filters, id: id, name: name }], sort_by: '', sort_dir: '' }, 1)
                                    setFilters({ ...filters, tags: [...filters.tags ? filters.tags : [], { 'id': Number(id), 'name': name }] })
                                }
                                setShowManageTag(false)
                            }} />
                        <button role="button" className="btn btn--white btn--white__slate btn--inlineicon mr-2" onClick={() => downloadContact([])}><img src="https://content.harstatic.com/media/icons/up_arrow_auxialary.svg" /><span>Export All</span></button>
                        <ImportContactsPopUp show={showImportPopUp} toggleShow={() => setShowImportPopUp(showImportPopUp ? false : true)} reload={props.reload} matrixStatusId={matrixContactImportStatusId} onMatrixInit={IntiateMatrix} />
                        <IonRouterLink routerLink="/contacts/create">
                            <button role="button" className="btn btn--prominent__v2 btn--small btn--inlineicon ml-2"><img className="align-self-center w-12px mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" /><span>New Contact</span></button>
                        </IonRouterLink>
                    </div>
                </div>
            </div>
        );
    }
    useEffect(() => {
        setReloadTags(false)
    }, [reloadTags])

    useEffect(() => {
        if (matrixContactStatus?.toLocaleLowerCase() === "done") {
            props.reload?.()
        }
    }, [matrixContactStatus]);



    return (
        <ContactManageTemplate header={Header()} >
            <>
                <nav id="ContactTabs" className="nav nav-tabs nav-tabs--simple">
                    <a title="Contact Management" onClick={() => history.push('/contacts', { 'filters': filters })} className="nav-item nav-link active  cursor_pointer" >
                        Contact Management
                    </a>
                    <a title="Contact Management" onClick={() => {
                        history.push('/contacts/pipeline', { 'filters': filters })
                    }} className="nav-item  nav-link  cursor_pointer" >
                        Contact Pipeline
                    </a>
                </nav>

                <div className='mt-3'>
                    <ModuleInfo type="contacts" />
                </div>

                {matrixContactStatus &&
                    <MatrixImportMessage />
                }

                {csvImporting &&
                    <CSVStatusBlock />
                }

                <div className="mt-3">
                    <ContactsTable
                        result={props.result}
                        contacts={props.contacts}
                        file={props.file}
                        page={props.page}
                        reloadTags={reloadTags}
                        loadMore={props.loadMore}
                        reload={props.reload}
                        filters={filters}
                        sort={{ sort: props.filters.sort_by, dir: props.filters.sort_dir }}
                        duplicate={(b: boolean) => {
                            setDuplicate(b)
                            props.duplicate?.(b)
                        }}
                        showImport={() => setShowImportPopUp(true)}
                        changeFilter={setFilter}
                        downloadContact={downloadContact}
                        manageTagPopUp={() => setShowManageTag(true)}
                        setShowManageTag={setShowManageTag}
                    />
                </div>
            </>
        </ContactManageTemplate >
    );
}