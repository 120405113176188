import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { ListingsApi, useListingsService } from 'services/typeaheads/listings';
import { IPropertyTypeaheadProps } from './PropertyTypeahead.def';
import './PropertyTypeahead.scss';
import { validateField } from 'scripts/hooks/validateForm';
import { TextboxCommonApp } from 'views/forms/textboxes/Textbox/TextboxCommon.app';
import { Tag } from 'scripts/ui/tag';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';
import { useMultiRef } from 'scripts/hooks/general';
import { useSetTimeout } from 'scripts/hooks/timers';

export const PropertyTypeaheadApp = (props: IPropertyTypeaheadProps): JSX.Element => {
    const [modal, showModal] = useState(false)
    const [showingCustom, setShowingCustom] = useState(false)
    return (
        <>
            <Form {...props} openAppModal={modal} showAppModal={(value: boolean) => showModal(value)} setShowingCustom={setShowingCustom} showingCustom={showingCustom} />
            <Selection {...props} showAppModal={(value: boolean) => showModal(value)} setShowingCustom={setShowingCustom} showingCustom={showingCustom} />
        </>
    );
}



const Selection = (props: IPropertyTypeaheadProps): JSX.Element => {
    const { initialValues, remove, showAppModal, setShowingCustom } = props;
    return (
        <>
            {initialValues && initialValues.length === 1 &&
                <div>
                    <IonItem className="tab__nextpage cursor--hand" onClick={() => {
                        showAppModal?.(true)
                    }}>
                        <div className="tab__nextpage_label">
                            <div className="row no-gutters pt-3 pb-3">
                                <div className="col-auto pr-3">
                                    <Tag.Div className=" bg--image border_radius--default w-110px h-80px listing_signature_photo" cssVars={{ listingSignaturePhoto: `url(${initialValues[0]?.photo ? initialValues[0]?.photo : 'https://content.harstatic.com/img/common/no_image_bg.jpg'})` }}></Tag.Div>
                                </div>
                                <div className="col align-self-center color_carbon font_size--large">
                                    <span className='font_weight--semi_bold'> {initialValues[0]?.street_address ? initialValues[0]?.street_address : initialValues[0]?.address_2 ? initialValues[0]?.address_2 : ''} </span><br />
                                    {initialValues[0]?.city ?
                                        <>
                                            {initialValues[0]?.city}{initialValues[0]?.state === "" && initialValues[0]?.zip === "" ? `` : `,`} {initialValues[0]?.state} {initialValues[0]?.zip} {initialValues[0]?.country}
                                        </>
                                        :
                                        <>
                                            {initialValues[0]?.state} {initialValues[0]?.country}  {initialValues[0]?.zip}
                                        </>

                                    }
                                    {initialValues[0]?.country && <><br />{initialValues[0]?.country}</>}
                                </div>
                            </div>
                            {!initialValues[0]?.harid &&
                                <a className="ml-auto mr-4 text-righ" href="#" onClick={(e: any) => {
                                    e.preventDefault()
                                    setShowingCustom?.(true)

                                }}>Edit</a>
                            }
                        </div>

                        <img className="tab__nextpage_arrow ml-auto" onClick={(e: any) => {
                            e.preventDefault()
                            remove?.()
                        }} src="https://content.harstatic.com/media/icons/arrow-right.svg" />

                    </IonItem>
                </div>
            }
        </>

    );
}


const Form = (props: IPropertyTypeaheadProps): JSX.Element => {
    const { initialValues, openAppModal, showingCustom, minLength, setShowingCustom, showAppModal } = props;
    const propertyModal = useRef<HTMLIonModalElement>(null);
    const [listingService, listingServiceAction] = useListingsService()
    const [] = useState(false)
    const [searchTxt, setSearchTxt] = useState("")
    const [timer, setTimer] = useState<number | undefined>()
    const { setTimeout, clearTimeout } = useSetTimeout();
    const searchField = useRef<HTMLIonSearchbarElement>(null)
    const search = (value: string) => {
        setSearchTxt(searchField.current?.value??"")
        if (minLength && minLength >= value.length) {
            if (listingService?.response && listingService?.response?.list?.length > 0) {
                searchListing("")
            }
            setSearchTxt("")
            return
        }
        searchListing(value)
    }
    const searchListing = (seachTxt: string) => {
        if (timer) { clearTimeout(timer); }
        if (!seachTxt) { return; }

        setTimer(setTimeout(() => {
            listingServiceAction.fetch({ input: seachTxt });
        }, 800))
    }

    function updateListing(listing: ListingsApi.IListingsList) {

        let listingArray: ListingsApi.IListingsList[] = []
        listingArray.push(listing)
        props.update?.(listingArray)
        close()
    }
    function close() {
        if (null !== searchField?.current) {
            setSearchTxt("")
        }
        showAppModal?.(false)
        propertyModal?.current?.dismiss()
      
        // searchListing("")

    }
    usePopUpAutoFocus(propertyModal);
    const focusFirstField = () => {
        setTimeout(() => { searchField.current?.setFocus() }, 500)
    }

    useEffect(() => {
            if (null !== searchField?.current) {
                setSearchTxt("")
        }
    }, [openAppModal])


    const ImageWithPlaceholder = (photo: string) => {
        return <div className='w-70px' >
            <Tag.Div className="listing_signature__photo listing_signature_photo position-absolute" cssVars={{ listingSignaturePhoto: `url(https://www.har.com/resources/images/listing_details/place_holder_listing.png)` }} ></Tag.Div>
            <Tag.Div className="listing_signature__photo listing_signature_photo position-absolute" cssVars={{ listingSignaturePhoto: `url(${photo})` }} ></Tag.Div>
        </div>
    }

    return (
        <div className={`${initialValues && initialValues?.length > 0 ? `d-none` : `d-block`}`} >
            <IonItem className="tab__nextpage" id="property-modal" onClick={focusFirstField}>
                <div className="tab__nextpage_label">
                    Search Property
                </div>
                <div className="tab__nextpage_value">
                    {!showingCustom &&
                        <div className='position-relative'>
                            <div className='font_size--small'>
                                <a href="#" onClick={(e: any) => {
                                    e.preventDefault();
                                    setShowingCustom?.(true)
                                }}>Add Manually</a>
                            </div>
                        </div>
                    }
                </div>
                <img className="tab__nextpage_arrow" src="https://content.harstatic.com/media/icons/arrow-right.svg" />


            </IonItem>
            <IonModal id="property-modal" ref={propertyModal} trigger="property-modal" backdropDismiss={false}>
                <IonContent>
                    <IonHeader>
                        <div className='pb-4'>
                            <IonToolbar>
                                <IonButtons className="w-15pr" slot="start">
                                    <IonButton color={"blue"} onClick={() => close()}>Cancel</IonButton>
                                </IonButtons>
                                <IonTitle className=' text-center'>Choose a Property</IonTitle>

                            </IonToolbar>
                            <div className="ion-padding-start ion-padding-end mt-3 pl-2">
                                {/**<input ref={searchField.current?.value} autoFocus={true} style={{ width:400 }} defaultValue={searchField.current?.value ? searchField.current?.value : searchTxt} onChange={(e: any) => search(e.target.value)} type="text" className="custom_search" placeholder="Search here" ></input>*/}
                                <IonSearchbar ref={searchField} className="custom ion-no-padding" placeholder="Search here" onIonChange={(e: any) => search(e.detail.value)}></IonSearchbar>
                            </div>
                        </div></IonHeader>
                    {listingService?.loading &&
                        <LoadingState />
                    }

                    <div className="mt-2 pb-2">
                        {searchTxt.length>0&&(listingService?.response && listingService.response.list?.length === 0 ?
                            <EmptyState showCustomListing={(value: boolean) => setShowingCustom?.(value)} />
                            :
                            <IonRadioGroup value={initialValues?.[0]?.listingid} >
                                {listingService?.response?.list?.map((listing, index) =>
                                    <IonItem className='py-2' key={index} onClick={() => updateListing(listing)}>
                                        <IonLabel className="font_size--medium">
                                            <div className="listing_signature listing_signature__small mb-2">
                                                {ImageWithPlaceholder(listing?.photo)}
                                                <div className="listing_signature__details">
                                                    <div className="listing_signature__details__title w-70px">{listing?.street_address}</div>
                                                    <div className="listing_signature__details__address">{listing?.city}{listing?.state === "" && listing?.zip === "" ? `` : `,`} {listing?.state} {listing?.zip} {listing?.country}</div>
                                                </div>
                                            </div>
                                        </IonLabel>
                                        <IonRadio slot="end" value={listing.listingid}></IonRadio>
                                    </IonItem>
                                )}
                            </IonRadioGroup>
                        )}
                    </div>

                </IonContent>
            </IonModal>
            {showingCustom && <CustomListing show={showingCustom} initialValues={initialValues} updateListing={updateListing} showingCountry={props.showingCountry} onRemove={() => {
                setShowingCustom?.(false)
                close()
            }} />}

        </div>
    );
}

const CustomListing = (props: IPropertyTypeaheadProps.CustomListingInterface) => {
    const { show, showingCountry, updateListing, onRemove } = props
    const [listing, setListing] = useState<ListingsApi.IListingsList>(props.initialValues?.[0] ?? ListingsApi.getInitialListingList())
    const [error, setError] = useState<any>()
    const [ref, assignRef] = useMultiRef<HTMLIonModalElement>();
    const addressRef = useRef<HTMLIonInputElement>(null)

    function update<K extends keyof ListingsApi.IListingsList>(key: K, value: ListingsApi.IListingsList[K]) {
        setListing({ ...listing, [key]: value })
    }
    useEffect(() => {
        if (show) {
            const timeout = setTimeout(() => {
                if (addressRef.current) {
                    addressRef.current.setFocus()
                }
            }, 500)
            return () => clearTimeout(timeout)
        }
        return () => { }
    }, [show])
    async function isValidated(): Promise<boolean> {
        let checkField = {
            "street_address": validateField("street_address", listing.street_address.trim(), ['req', 'min:1', 'max:100'], 'Street Address'),
            "address_2":validateField("address_2",listing.address_2?.trim(), ['req', 'min:1', 'max:100'], 'Address 2'),
            "zip": validateField("zip", listing.zip.trim(), ['req', 'min:1', 'max:15'], 'Zip Code'),
            "city": validateField("city", listing.city.trim(), ['req', 'min:1', 'max:50'], 'City'),
            "state": validateField("state", listing.state.trim(), ['req', 'min:1', 'max:50'], 'State'),
            "country": validateField("country", listing.country?.trim(), ['req', 'min:1', 'max:50'], 'Country'),

        }
        let anyoneIsValid = Object.values(checkField).every(x => x === null || x !== '');
        setError(anyoneIsValid ? "Please fill in at least one field before proceeding." : undefined)
        return  !anyoneIsValid;
    }
    usePopUpAutoFocus(ref);

    return (
        <IonModal className="gray_bg" trigger="open-modal" ref={assignRef} isOpen={show} onDidDismiss={() => onRemove?.()} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color={"blue"} onClick={() => onRemove?.()}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Add Property</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color={"blue"} strong={true} onClick={async () => {
                            let validated = await isValidated()
                            if (validated) {
                                updateListing?.(listing)
                                onRemove?.()
                            }
                        }}>
                            Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                    {error && 
                    <div className='p-3 mt-1 font_size--medium color_cc0000'>{error}</div>
                }

                <div className="form_sec mb-5 mt-3">
                    <TextboxCommonApp innerRef={addressRef} type="text" placeholder="Address 1" maxlength={100} regex='[^a-zA-Z0-9,\s.///-]'  value={listing.street_address} onIonChange={(e: any) => update("street_address", e.target.value)} />

                    <TextboxCommonApp type="text" placeholder="Address 2" maxlength={100} regex='[^a-zA-Z0-9,\s.///-]' value={listing.address_2} onIonChange={(e: any) => update("address_2", e.target.value)} />

                </div>

                <div className="form_sec mb-5 mt-3">
                    <TextboxCommonApp type="text" placeholder="City" regex='[^a-zA-Z.-\s]' maxlength={50} value={listing.city} onIonChange={(e: any) => update("city", e.target.value)} />
                    <TextboxCommonApp type="text" placeholder="State" regex='[^a-zA-Z.-\s]' maxlength={50} value={listing.state} error={error?.state} onIonChange={(e: any) => update("state", e.target.value)} />

                    <TextboxCommonApp type="text" placeholder="Zip Code" regex='[\s]' maxlength={15} value={listing?.zip}  onIonChange={(e: any) => update("zip", e.target.value)} />
                    {showingCountry &&
                        <TextboxCommonApp type="text" placeholder="Country" regex='[^a-zA-Z.-\s]' maxlength={25} value={listing?.country}  onIonChange={(e: any) => update("country", e.target.value)} />
                    }
                </div>

            </IonContent>
        </IonModal>)
}

const LoadingState = () => {
    const LoadingField = (): JSX.Element => {
        return (
            <div className="cards cards__deals_wt cursor--hand ui-sortable-handle d-block" >
                <div className="listing_signature listing_signature__small mb-3">
                    <div className="loading__photo mr-3 w-40px h-40px"></div>
                    <div className="listing_signature__details">
                        <div className="loading__text_small mb-2 w-120px"></div>
                        <div className="loading__text_small w-150px"></div>
                    </div>
                </div>
            </div >
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < 8; i++) {
        rows[i] = i
    }
    return (
        <IonItem className='py-3'>
            <div className="typeahead w-100 border-0">
                {rows.map((index: number) =>
                    <LoadingField key={index} />
                )}
            </div>
        </IonItem>

    );
}

const EmptyState = (props: IPropertyTypeaheadProps.EmptySearchInterface) => {
    const { showCustomListing } = props
    return (
        <div className="typeahead w-100 border-0">
            <IonItem className='py-2'>
                <div className='row'>
                    <div className='col font_size--medium'>No Records found</div>
                    <div className='col-auto font_size--medium'><a className=' d-block m-auto w-230px text-right' href='#' onClick={(e: any) => {
                        e.preventDefault()
                        showCustomListing?.(true)
                    }} >+ Add Property Manually</a></div>

                </div>
            </IonItem >
        </div >


    );
}

