import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import { IGlobalSearchScreenProps } from './GlobalSearchScreen.def';
import './GlobalSearchScreen.scss';
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { Avatar } from 'views/contacts/Avatar';
import { useFetchGlobalSearchService,GlobalSearchApi} from 'services/typeaheads/globalsearch';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { ContactPhoto } from 'views/forms/images/ContactPhoto/ContactPhoto';
import { DropDownSecondary } from 'views/forms/dropdowns/DropDownSecondary';
import { Tag } from 'scripts/ui/tag';

export const GlobalSearchScreenDesktop = (_props:IGlobalSearchScreenProps): JSX.Element =>
{
    const { input } = useParams<{ input: string }>();
    const [{response,loading},Action]=useFetchGlobalSearchService({input:input,tab:"all"})
    const [data,setData]=useState<GlobalSearchApi.Response>({contacts:{list:[],total:"0"},deals:{list:[],total:"0"},emails:{list:[],total:"0"},tasks:{list:[],total:"0"},documents:{list:[],total:"0"},services:{list:[],total:"0"}});
    const total=response?.total

    const seeMore=(tab:string,excludedIds:string)=>{
        Action.fetch({input:input,tab:"all",paging_for_tab:tab,excluded_ids:excludedIds});
    }
    useEffect(()=>{
        const updatedData={contacts:{list:[...data.contacts.list,...response?.contacts.list||[]],
          total:(parseInt(data.contacts.total)+parseInt(response?.contacts.total||"0")).toString()},
          deals:{list:[...data.deals.list,...response?.deals.list||[]],total:(parseInt(data.deals.total)+parseInt(response?.deals.total||"0")).toString()},
          emails:{list:[...data.emails.list,...response?.emails.list||[]],total:(parseInt(data.emails.total)+parseInt(response?.emails.total||"0")).toString()},
          tasks:{list:[...data.tasks.list,...response?.tasks.list||[]],total:(parseInt(data.tasks.total)+parseInt(response?.tasks.total||"0")).toString()},
          documents:{list:[...data.documents.list,...response?.documents.list||[]],total:(parseInt(data.documents.total)+parseInt(response?.documents.total||"0")).toString()},
          services:{list:[...data.services.list,...response?.services.list||[]],total:(parseInt(data.services.total)+parseInt(response?.services.total||"0")).toString()}
                    }
     response&&setData(updatedData)},[response]);

    return (
        <div> 
          <div className="col align-self-center">
            <DefaultTemplate title="Global Search" >
         <div className="container pb-5">
             <h2 tabIndex={0} className=''>{total} results for "{input}"</h2>
            <Tabs defaultActiveKey="ALL" transition={false} id="MainTabs" className="nav nav-tabs nav-tabs--simple nav-tabs--fullwidth">
               <Tab eventKey="ALL" title="ALL" className="animate animate__slideInUp">
                {<Card loading={loading} showMore={data?.contacts.list.length !==parseInt(data?.contacts.total??'0')} onSeeMore={()=>seeMore("contacts",data?.contacts.list.map((item)=>item.id).toString())} title="Contacts"><>{data.contacts.list.length==0?<Empty  title='Contacts'/>:data?.contacts.list.map((item,index)=><ContactsCard key={index} {...item}/>)}</></Card> }
                { <Card loading={loading} showMore={data?.deals.list.length !==parseInt(data?.deals.total??'0')} onSeeMore={()=>seeMore("deals",data?.deals.list.map((item)=>item.id).toString())} title="Deals"><>{data.deals.list.length==0?<Empty  title='Deals'/>:data?.deals.list.map((item,index)=> <DealsCard key={index} {...item}/>)}  </></Card>}
                { <Card loading={loading} showMore={data?.emails.list.length !==parseInt(data?.emails.total??'0')} onSeeMore={()=>seeMore("emails",data.emails.list.map((item)=>item.id).toString())} title="Emails"><>{data.emails.list.length==0?<Empty  title='Emails'/>: data?.emails.list.map((item,index)=> <EmailsCard key={index} {...item}/>)} </></Card> }
                { <Card loading={loading} showMore={data?.tasks.list.length !==parseInt(data?.tasks.total??'0')} onSeeMore={()=>seeMore("tasks",data.tasks.list.map((item)=>item.id).toString())} title="Tasks"><>{ data.tasks.list.length==0?<Empty  title='Tasks'/>:data?.tasks.list.map((item,index)=> <TasksCard key={index} {...item}/>)} </></Card>}
                { <Card loading={loading} showMore={data?.documents.list.length !==parseInt(data?.documents.total??'0')} onSeeMore={()=>seeMore("documents",data.documents.list.map((item)=>item.id).toString())} title="Documents"><>{ data.documents.list.length==0?<Empty  title='Documents'/>:data?.documents.list.map((item,index)=> <DocumentsCard key={index} {...item}/>)} </></Card>}
                </Tab>

                <Tab eventKey="CONTACTS" title="CONTACTS" className="animate animate__slideInUp">
                    {<Card loading={loading} showMore={data?.contacts.list.length !==parseInt(data?.contacts.total??'0')} onSeeMore={()=>seeMore("contacts",data.contacts.list.map((item)=>item.id).toString())} title="Contacts"><>{ data.contacts.list.length==0?<Empty  title='Contacts'/>:data?.contacts.list.map((item,index)=><ContactsCard key={index} {...item}/>)}</></Card>}
                </Tab>

                <Tab eventKey="DEALS" title="DEALS" className="animate animate__slideInUp">
               { <Card loading={loading} showMore={data?.deals.list.length !==parseInt(data?.deals.total??'0')} onSeeMore={()=>seeMore("deals",data.deals.list.map((item)=>item.id).toString())} title="Deals"><>{data.deals.list.length==0?<Empty  title='Deals'/>:data?.deals.list.map((item,index)=> <DealsCard key={index} {...item}/>)}</></Card>}
                </Tab>

                <Tab eventKey="EMAILS" title="EMAILS" className="animate animate__slideInUp">
                { <Card loading={loading} showMore={data?.emails.list.length !==parseInt(data?.emails.total??'0')} onSeeMore={()=>seeMore("emails",data.emails.list.map((item)=>item.id).toString())} title="Emails"><>{ data.emails.list.length==0?<Empty  title='Emails'/>:data?.emails.list.map((item,index)=> <EmailsCard key={index} {...item}/>)}</></Card>}
                </Tab> 

                <Tab eventKey="TASKS" title="TASKS" className="animate animate__slideInUp">
                { <Card loading={loading} showMore={data?.tasks.list.length !==parseInt(data?.tasks.total??'0')} onSeeMore={()=>seeMore("tasks",data.tasks.list.map((item)=>item.id).toString())} title="Tasks"><>{ data.tasks.list.length==0?<Empty  title='Tasks'/>:data?.tasks.list.map((item,index)=> <TasksCard key={index} {...item}/>)} </></Card>}
                </Tab>

                <Tab eventKey="DOCUMENTS" title="DOCUMENTS" className="animate animate__slideInUp">
                { <Card loading={loading} showMore={data?.documents.list.length !==parseInt(data?.documents.total??'0')} onSeeMore={()=>seeMore("documents",data.documents.list.map((item)=>item.id).toString())} title="Documents"><>{ data.documents.list.length==0?<Empty  title='Documents'/>:data?.documents.list.map((item,index)=> <DocumentsCard key={index} {...item}/>)} </></Card>}
                </Tab>
            </Tabs>
         </div>
        </DefaultTemplate></div></div>
    );
}
 

const Card=(props:{children:JSX.Element | JSX.Element[],title:string,onSeeMore:()=>void,showMore?:boolean,loading?:boolean})=>{
    return  <div className="container pb-5 pt-5">
            <div className="col-md-12 col-12">
                <div className="card mb-5 mb-md-0">  
                    <h2 tabIndex={0} className='p-4 pb-0 mb-0'>{props.title}</h2>
                    <div className="ion-padding">
                        {props.children}
                    </div>
       {props.showMore&&<div className="notification_card_footer p-1 text-right">
                <ShapelessButton color={"blue"} onClick={()=>props.onSeeMore()} size='small' loading={props.loading}>  <a onClick={()=>props.onSeeMore()}>View more results</a></ShapelessButton>
             </div>}
             </div>
            </div>
        </div>
}

export const Empty = (props:{title:string}) =>
(
        <div className="card_empty">
                <div className="card_empty__content max-w-320px">
                    <div className="mb-3"><img className=' w-85px' src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
                    <div className="pt-3 pb-2">
                        You currently don’t have {props.title}.
                    </div>
                </div>
            </div>
);


export const ContactsCard= (props:GlobalSearchApi.IContactData)=> {
    return(
            
             <div className="typeahead__item pb-3 mb-3 pt-4 pl-4 pr-4 ">	
             <a href={props.link} className="agent_signature" >
                <div className=' agent_signature__large'>
                 <Avatar id={props.id} firstName={props.first_name} photo={props.photo_url}/></div>
                 <div className="agent_signature__text">
                    <div className="agent_signature__text__title"><span className="status_circle status_circle__pending"></span>{props.first_name}</div>
                    <div className="agent_signature__text__address">{props.address}</div>
                     <div className="agent_signature__text__tags">
                        {props.tags?.slice(0,2).map((item:any,index:any)=> 
                  <div className="agent_signature__text__tags_tag" key={index}><img src="https://content.harstatic.com/media/icons/tag_small.svg" className='pt-2'/> {item.name}</div>
                        )}
                     </div>
                   </div>
                         
          </a>
        </div>
       )
}
export const DealsCard=(props:GlobalSearchApi.IDealData)=>{
    return(<div className="pt-2">
    <a href={props.link} className="agent_signature" >
      <div className="row no-gutters mb-3">
          <div className="col-auto">
              <Tag.Div className="listing_signature__photo global_search_bg w-48px max-h-40px" cssVars={{backgroundImage: `url(${props.property_photo ? props.property_photo : `https://content.harstatic.com/img/common/no_image_bg.jpg`})`}}></Tag.Div>
          </div>
          <div className="col">
              <div className="font_size--medium font_weight--semi_bold">{props.property_full_street_address},{props.property_city} {props.property_state} {props.property_zip}</div>
              <div className="color_auxiliary font_size--small">
              {props.contacts.map((item,ind)=> <ul className="mb-0" key={ind}>
                      <li>• {props.title}</li>
                      <li>• For {item.first_name} {item.last_name}</li>
                      <li>• Approx. close date ({props.estimated_deadline})</li>
                  </ul>)}
                  <div className="pt-1 pb-2"><span className=" font_weight--semi_bold">Deal Stage:</span> {props.deal_stage_text}</div>
              </div>
  
              <div className="btn-group btn-group-toggle btn-group--dealstage btn-group--dealstage__small" data-toggle="buttons">
                  <label className={`stage stage--active ${props.deal_stage === 'A' ? `active` : ``}`}></label>
                  <label className={`stage stage--contract  ${props.deal_stage === 'I' ? `active` : ``}`}></label>
                  <label className={`stage stage--close  ${props.deal_stage === 'C' ? `active` : ``}`}></label>
                  <label className={`stage stage--fellthrough ${props.deal_stage === 'F' ? `active` : ``}`}></label>
              </div>
          </div>
          <div className="col-auto text-right ml-4">
              <div className="message message--success font_size--small d-block mb-2">${props.deal_amount_formatted}</div>
              <div className="font_size--small_extra color_available">{props.deal_type_text}</div>
          </div>
      </div></a>
      {/* search deals card ended here */}
  
  </div>
    )
}

export const EmailsCard=(props:GlobalSearchApi.IEmailData)=>{

    return(<div className="card--email cursor--hand pb-3 mb-3 pl-4 pr-4">
                     <a href={props.link} className="agent_signature agent_signature__large mb-0">

             <div className="card--email__agent custom_bg_1"></div>
               <div className="card--emaill__content overflow-hidden">
                     <div className="card--email__content_heading">{props.is_lead=='1'&&<div className="label_email label_email--lead">Lead</div>}
                                      {props.first_name} {props.last_name}                        
                                </div>
                                <div className="card--email__text text-truncate">
                              {props.short_message}
                                </div>
                                <div className="card--email__date">
                        {props?.date_tag?.toUpperCase()} - {props.formatted_date}</div>
                            </div>    </a>  
                     </div>
    )
}

export const TasksCard=(props:GlobalSearchApi.ITaskData)=>{
    const history = useHistory();
    const redirect = (id?: string) => {
      id && history.push(`/contacts/${id}/overview`)
  }
      return(
          <div className="card--email cursor--hand p-0  pb-3 mb-3">
          <a href={props.link} className="agent_signature mb-0">
  
          <div className="card--email__agent custom_bg_2"></div>
          <div className="card--emaill__content overflow-hidden">
              <div className="card--email__content_heading">                                            
                {props.title}                       
              </div>
              <div className="card--email__text text-truncate">
              <div className="d-flex">
              {props.contacts.length > 0 &&
                  <div className="agent_signature agent_signature__mini mb-0 mr-2" >
                    <ContactPhoto contact={props.contacts[0]} index={0} />            
                      <div className="agent_signature__text font-9">{props.contacts[0]?.first_name}{props.contacts[0]?.last_name} </div>
                  </div>}
                  {props.contacts.length > 1 &&
                  <div className="agent_signature agent_signature__mini mb-0 mr-2" >
                    <ContactPhoto contact={props.contacts[1]} index={0} />            
                      <div className="agent_signature__text font-9">{props.contacts[1]?.first_name}{props.contacts[1]?.last_name} </div>
                  </div>}
               {props.contacts.length>2&&
                  <DropDownSecondary label={`+${props.contacts.length - 2}`}>
                  {props.contacts.map((item,ind)=>
                   <Dropdown.Item key={ind} className="dropdown-item animate__animated animate__fadeInUp animate__faster pb-2 cursor--hand" onClick={() => redirect(item.id)}>
                   <div className="agent_signature agent_signature__mini mb-0 mr-2">
                    <ContactPhoto contact={item} index={0}/>            
                      <div className="agent_signature__text font-9">{item.first_name}{item.last_name} </div>
                  </div> 
                  </Dropdown.Item>)}
                   </DropDownSecondary>}
                  </div>
              </div>
              <div className="card--email__date">
          {props.formatted_date?.toUpperCase()}
              </div>
          </div>  </a>                                
      </div>
      )
  }

  export const DocumentsCard=(props:GlobalSearchApi.IDocumentData)=>{
    return( <div className="typeahead__item pb-3 mb-3 pl-4 pr-4">			
    <a href={`/contacts/${props.id}/documents`} className="agent_signature mb-0">
   <div> <img className="mr-2 ml-md-2" src={"https://content.harstatic.com/media/icons/"+props.icon} alt='img' /></div>
      <div className="agent_signature__text">
         <div className="agent_signature__text__title">{`${props.name} (${props.extension})`}</div>
        
      <div className="card--email__text text-truncate">
       <div className="card--email__date">
                {props.created_dt.split(' ')[0]}</div></div>
   </div>
 </a>
</div>
        )
    }