import { IonButton, IonButtons, IonHeader, IonItem, IonContent, IonLabel, IonModal, IonSearchbar, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { SearchContactsApi, useSearchContactsService } from 'services/contacts/searchContacts';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IContactsTypeaheadProps } from './ContactsTypeahead.def';
import { Pagination, Navigation } from "swiper";
import './ContactsTypeahead.scss';
//import { GetDealsPipelineApi } from 'services/deals/getDealsPipeline';
import { SimpleCheckbox } from 'views/forms/checkboxes/SimpleCheckbox/SimpleCheckbox';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { Avatar } from 'views/contacts/Avatar';
import { ContactTitle } from 'views/general/ContactTitle';
//import { usePopUpAutoFocus } from 'scripts/hooks/popups';

export const ContactsTypeaheadMultiApp = (props: IContactsTypeaheadProps): JSX.Element => {
    return (
        <>
            <Form {...props} />
        </>
    );
}

/*const Selection = (props: IContactsTypeaheadProps): JSX.Element => {
    const { initialValues, nonRemoveable } = props;
    const [selectedContacts, saveSelectedContacts] = useState<SearchContactsApi.IContactSearchItem[] | any[]>()
    const [searchContact] = useSearchContactsService()

    const onSelect = (id: number) => {
        if (selectedContacts && selectedContacts?.find((selected: SearchContactsApi.IContactSearchItem) => id === selected.id)) {
            let oldContacts: SearchContactsApi.IContactSearchItem[] = selectedContacts.filter((selected: SearchContactsApi.IContactSearchItem) => selected.id !== id)
            saveSelectedContacts([...oldContacts])
        } else {
            let contact = searchContact?.response?.list.filter((person) => person.id !== id)[0]
            saveSelectedContacts([...selectedContacts ? selectedContacts : [], contact])
        }
    }
  //  const isSelected = (contact: SearchContactsApi.IContactSearchItem | GetDealsPipelineApi.IDealContact) => initialValues.includes(contact);

    return (
        <>
            {initialValues.length > 0 && <div className="ml-3 mr-3">
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={12}
                    slidesPerGroup={1}
                    loop={false}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="owners_desktop"
                >
                    {initialValues.map((item, ind) => <SwiperSlide key={ind}>
                        <div className="agent_signature agent_signature__inline pr-5" >
                            {!nonRemoveable && <div onClick={() => onSelect(item.id)} className="agent_signature__photo_cross">
                                 <img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" />
                            </div>}
                            <Avatar id={item?.id.toString()} firstName={item?.first_name} lastName={item?.last_name} photo={item?.photo_url}></Avatar>
                              <div className="agent_signature__text">{item?.first_name  || item?.last_name ? item?.first_name +' '+ item?.last_name : item.primary_email}</div>
                        </div>
                    </SwiperSlide>)}
                </Swiper></div>}
        </>

    );
}*/

const Form = (props: IContactsTypeaheadProps): JSX.Element => {
    const [searchContact, searchContactAction] = useSearchContactsService()
    const { initialValues, nonRemoveable, maxSelection, persistentContact, showCustomEmailOption, save } = props;
    const [searchTxt, setSearchTxt] = useState("")
    const searchField = useRef<HTMLIonSearchbarElement>(null)
    const [selectedContacts, saveSelectedContacts] = useState<SearchContactsApi.IContactSearchItem[] | any[]>()
    const [options, setOptions] = useState<SearchContactsApi.IContactSearchItem[] | any[]>()
    const [show, setShow] = useState(false)
    const [isEmpty, setIsEmpty] = useState(true)
    const [presentAlert] = useIonAlert();
    const [timer, setTimer] = useState<number | undefined>()
    const search = (value: string) => {
        if (value.length === 0) {
            setSearchTxt('')
            setIsEmpty(true)
            return
        }

        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            if (showCustomEmailOption) {
                if (isEmail(value)) {
                    isEmpty && setIsEmpty(false)
                    if (selectedContacts?.find((contact: SearchContactsApi.IContactSearchItem) => contact.primary_email.toLocaleLowerCase() === value.toLocaleLowerCase())) {
                        setOptions(selectedContacts?.filter((contact: SearchContactsApi.IContactSearchItem) => contact.primary_email.toLocaleLowerCase() === value.toLocaleLowerCase())[0])
                    } else {
                        setOptions([{ id: getMinId() > 0 ? 0 : getMinId() - 1, first_name: '', last_name: '', primary_email: value }])
                    }
                    return
                }
            }
            searchContactAction.fetch({ keyword: value });
        }, 800))
    }

    const getMinId = (): number => {
        const totals = selectedContacts
            ?.map(x => x.id);
        return Math.min(...totals ?? []);
    }
    const isEmail = (value: string) => {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (value && regex.test(value.trim())) {
            return true
        }
        return false
    }

    useServiceDidSucceeded(searchContact, (response) => {
        if (response.list.length === 0) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        setOptions(response.list)
    })


    const onSelect = (id: number) => {
        if (selectedContacts && selectedContacts?.find((selected: SearchContactsApi.IContactSearchItem) => id === selected.id)) {
            let oldContacts: SearchContactsApi.IContactSearchItem[] = selectedContacts.filter((selected: SearchContactsApi.IContactSearchItem) => selected.id !== id)
            saveSelectedContacts([...oldContacts])
        } else {
            if (id <= 0) {
                let contact = options?.filter((person) => person.id === id)[0]
                saveSelectedContacts([...selectedContacts ? selectedContacts : [], contact])
            } else {
                let contact = searchContact?.response?.list.filter((person) => person.id === id)[0]
                saveSelectedContacts([...selectedContacts ? selectedContacts : [], contact])
            }

        }
    }
    const isSelected = (person: SearchContactsApi.IContactSearchItem) => {
        if (person.id <= 0) {
            return selectedContacts?.find((contact) => person.primary_email.toLocaleLowerCase() === contact.primary_email.toLocaleLowerCase())
        } else {
            return selectedContacts?.find((contact) => person.id === contact.id)
        }

    }

    function close() {
        setShow(false)
        if (null !== searchField?.current) {
            setSearchTxt(searchField?.current?.value ? searchField?.current?.value : "")
        }
    }
    useEffect(() => {
        saveSelectedContacts(initialValues)
    }, [initialValues])

    useEffect(() => {
        console.log('selectedContacts', selectedContacts)
    }, [selectedContacts])

    useEffect(() => {
        console.log('options', options)
    }, [options])

    useEffect(() => {
        focusFirstField();
    }, [show]);

    //usePopUpAutoFocus(modalRef);
    const focusFirstField = () => {
        setTimeout(() => { searchField.current?.setFocus() }, 500)
    }

    return (
        <div>
            {props.secondary ?

                <IonItem className="tab__nextpage cursor--hand" button detail={false} onClick={() => setShow(true)}>
                    <div className="tab__nextpage_label color_custom_3">
                        To
                    </div>
                    <div className="tab__nextpage_value w-100 mr-auto ml-2 d-flex overflow-x-scroll">
                        {initialValues && initialValues.map((contact, index: number) =>
                            <div key={index} className="addresspill display-block_ruby w-auto" aria-label="Filter by price"  ><ContactTitle contact={contact} /></div>
                        )}
                    </div>
                    <img className="tab__nextpage_arrow w-36px" src="https://content.harstatic.com/media/icons/plus_har_blue.svg" />
                </IonItem>
                : <IonItem className="tab__nextpage cursor--hand" button detail={false} onClick={() => setShow(true)}>
                    {initialValues &&
                        <div className="tab__nextpage_label color_custom_3" >
                            Choose a contact
                        </div>
                    }
                    <div className="tab__nextpage_value mr-auto ml-2 d-flex overflow-x-scroll"></div>
                    <img className="tab__nextpage_arrow w-36px" src="https://content.harstatic.com/media/icons/plus_har_blue.svg" />
                </IonItem>}
            <IonModal id="contact-modal" isOpen={show} onDidDismiss={() => setShow(false)} backdropDismiss={false} onIonModalDidPresent={() => { searchField.current?.setFocus() }} onIonModalWillDismiss={() => {
                if (!!searchField.current) {
                    searchField.current.value = ''
                }
                setSearchTxt('')
                setOptions(undefined)
                setIsEmpty(true)
            }}>
                <IonContent scrollY={true}>
                    <div ><IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton className='color_0738CD' onClick={() => close()}>Cancel</IonButton>
                            </IonButtons>
                            <IonTitle className=' text-center'>Choose Contact</IonTitle>
                            <IonButtons slot="end">
                                <IonButton className='color_0738CD' onClick={() => {
                                    if (maxSelection && selectedContacts && selectedContacts?.length > 10) {
                                        presentAlert({
                                            header: '',
                                            subHeader: 'Warning',
                                            message: 'Maximum ' + maxSelection + ' contacts selection is allow.',
                                            buttons: ['OK'],
                                        })
                                    } else {
                                        save?.(selectedContacts ? selectedContacts : []);
                                        close()
                                    }


                                }}>Ok</IonButton>
                            </IonButtons>
                        </IonToolbar></IonHeader>
                        <div className="ion-padding-end border-bottom mt-3 pl-2">
                            <IonSearchbar value={searchField.current?.value ? searchField.current?.value : searchTxt} ref={searchField} className="custom ion-no-padding mb-3" placeholder="Search by name, address" onIonInput={(e: any) => search(e.target.value)}></IonSearchbar>
                        </div>
                        <div className={`mt-3 ml-3 mr-3 ${selectedContacts && selectedContacts.length > 0 && `border-bottom`} pt-3 pb-3 ${show ? `d-block` : `d-none}`}`}>
                            {selectedContacts && selectedContacts.length > 0 && <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={12}
                                slidesPerGroup={1}
                                slidesOffsetAfter={100}
                                loop={false}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={false}
                                modules={[Pagination, Navigation]}
                                className="owners_desktop s1"
                            >

                                {selectedContacts.map((item: SearchContactsApi.IContactSearchItem, ind: number) => <SwiperSlide key={ind}>
                                    <div className="agent_signature__inline pr-5 w-auto">
                                        {(!nonRemoveable && Number(persistentContact) !== item.id) && <div className="agent_signature__photo_cross">
                                            <img className='cursor--hand position-absolute zindex-100 right-6px' onClick={() => onSelect(item.id)} src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" />
                                        </div>}

                                        {/**  <ContactPhoto contact={item} index={item.id} />*/}
                                        <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                                        <div className={`agent_signature__text__title text-truncate text-center ${selectedContacts.length > 1 ? `w-85px` : ``}`}><ContactTitle contact={item} /></div>
                                    </div>
                                </SwiperSlide>)}
                            </Swiper>}</div>
                    </div>
                    {searchContact?.loading &&
                        <LoadingState />
                    }
                    <div className="h-100px">
                        {!isEmpty && options?.map((contact, ind) =>
                            <IonItem key={ind} onClick={() => onSelect(contact.id)} className='' >
                                <IonLabel className="font_size--medium">
                                    <div className="agent_signature agent_signature__large ">
                                        {/**  <ContactPhoto contact={contact} index={contact.id} />*/}
                                        <div className='min-w-72px'>
                                            <Avatar id={contact.id.toString()} firstName={contact.first_name} lastName={contact.last_name} photo={contact.photo_url} />
                                        </div>
                                        <div className="agent_signature__text text-truncate">
                                            <div className="agent_signature__text__title ">
                                                <ContactTitle contact={contact} />
                                                <div className=' font_size--small color_cement'> {contact.primary_email} </div></div>
                                        </div>
                                    </div>
                                </IonLabel>
                                <SimpleCheckbox checked={isSelected(contact)}  ></SimpleCheckbox>
                            </IonItem>
                        )}
                    </div></IonContent>
            </IonModal>
        </div >

    );

}
const LoadingState = () => {
    const LoadingField = (): JSX.Element => {
        return (
            <div className="cards cards__deals_wt cursor--hand ui-sortable-handle d-block" >
                <div className="listing_signature listing_signature__small mb-3">
                    <div className="loading__photo mr-3 w-40px h-40px"></div>
                    <div className="listing_signature__details">
                        <div className="loading__text_small mb-2 w-120px"></div>
                        <div className="loading__text_small w-150px"></div>
                    </div>
                </div>
            </div >
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < 8; i++) {
        rows[i] = i
    }
    return (
        <IonItem className='py-3'>
            <div className="typeahead w-100 border-0">
                {rows.map((index: number) =>
                    <LoadingField key={index} />
                )}
            </div>
        </IonItem>

    );
}