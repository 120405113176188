
import moment, { Moment } from 'moment';

type DateOrMoment = Date | Moment;

//2020-10-10 23:10:10
export  function formatSystemDate(date:DateOrMoment):string;
export  function formatSystemDate(date?:DateOrMoment):string|undefined;
export  function formatSystemDate(date?:DateOrMoment)
{ return date?moment(date).format('YYYY-MM-DD'):undefined }


//2020-10-10 23:10:10
export  function formatSystemDateTime(date:DateOrMoment):string;
export  function formatSystemDateTime(date?:DateOrMoment):string|undefined;
export  function formatSystemDateTime(date?:DateOrMoment)
{ return date?moment(date).format('YYYY-MM-DD HH:mm:ss'):undefined }



//01/31/2023
export  function formatSystemDateShort(date:DateOrMoment):string;
export  function formatSystemDateShort(date?:DateOrMoment):string|undefined;
export  function formatSystemDateShort(date?:DateOrMoment)
{ return date?moment(date).format('MM/DD/yyyy'):undefined }

//01/31/2023
export  function formatSystemDateTimeShort(date:DateOrMoment):string;
export  function formatSystemDateTimeShort(date:DateOrMoment | undefined): string | undefined;
export  function formatSystemDateTimeShort(date:string, inputFormat?:string): string;
export  function formatSystemDateTimeShort(date:string | undefined, inputFormat?:string): string | undefined;
export  function formatSystemDateTimeShort(date?:DateOrMoment | string, inputFormat?:string)
{ return date?moment(date, inputFormat).format('MM/DD/yyyy hh:mm A'):undefined }
//Sat
export  function getDay(date:DateOrMoment):string;
export  function getDay(date?:DateOrMoment):string|undefined;
export  function getDay(date?:DateOrMoment)
{ return date?moment(date).format('dddd').substring(0,3):undefined }

//Saturday
export  function getDayLong(date:DateOrMoment):string;
export  function getDayLong(date?:DateOrMoment):string|undefined;
export  function getDayLong(date?:DateOrMoment)
{ return date?moment(date).format('dddd'):undefined }

//Jun
export  function getMonth(date:DateOrMoment):string;
export  function getMonth(date?:DateOrMoment):string|undefined;
export  function getMonth(date?:DateOrMoment)
{ return date?moment(date).format('MMM'):undefined }

//2021
export  function getYear(date:DateOrMoment):string;
export  function getYear(date?:DateOrMoment):string|undefined;
export  function getYear(date?:DateOrMoment)
{ return date?moment(date).format('YYYY'):undefined }

export  function getDayNumber(date:DateOrMoment):string;
export  function getDayNumber(date?:DateOrMoment):string|undefined;
export  function getDayNumber(date?:DateOrMoment)
{ return date?moment(date).format('DD'):undefined }

//6 Jun ⋅ 11:00 AM to 7 jun ⋅ 12:30 PM
export  function formatTwoDateTimeShort(stDate:DateOrMoment,edDate:DateOrMoment):string;
export  function formatTwoDateTimeShort(stDate?:DateOrMoment,edDate?:DateOrMoment):string|undefined;
export  function formatTwoDateTimeShort(stDate?:DateOrMoment,edDate?:DateOrMoment)
{ return stDate && edDate ?
    moment(stDate).format('D MMM ⋅ hh:mm A to ')+moment(edDate).format('D MMM ⋅ hh:mm A')
    :undefined }

    //Thu,6 Jun ⋅ 11 AM to 12:30 PM
export  function formatTwoDateTimeShortWithDay(stDate:DateOrMoment,edDate:DateOrMoment):string;
export  function formatTwoDateTimeShortWithDay(stDate?:DateOrMoment,edDate?:DateOrMoment):string|undefined;
export  function formatTwoDateTimeShortWithDay(stDate?:DateOrMoment,edDate?:DateOrMoment)
{ return stDate && edDate ?
    moment(stDate).format('ddd, MMM D ⋅ hh:mm A to ')+moment(edDate).format('hh:mm A')
    :undefined }

    //6 Jun ⋅ 11 AM
export  function formatDateTimeShort(stDate:DateOrMoment):string;
export  function formatDateTimeShort(stDate?:DateOrMoment):string|undefined;
export  function formatDateTimeShort(stDate?:DateOrMoment)
{ return stDate ?
    moment(stDate).format('D MMM ⋅ hh:mm A ')
    :undefined }

//Jun 6, 2020 – 03:00 PM
export  function formatHarDateTime(date:DateOrMoment):string;
export  function formatHarDateTime(date?:DateOrMoment):string|undefined;
export  function formatHarDateTime(date?:DateOrMoment)
{ return date?moment(date).format('MMM D, YYYY - hh:mm A'):undefined }

//Fri Jun 6, 2020 – 15:40:30
export  function formatHarDayDateTime(date:DateOrMoment):string;
export  function formatHarDayDateTime(date?:DateOrMoment):string|undefined;
export  function formatHarDayDateTime(date?:DateOrMoment)
{ return date?moment(date).format('ddd MMM D, YYYY - HH:mm:ss'):undefined }

//Jun 6, 2020
export  function formatHarDate(date:DateOrMoment):string;
export  function formatHarDate(date:DateOrMoment | undefined):string|undefined;
export  function formatHarDate(date:string, inputFormat?:string):string;
export  function formatHarDate(date:string | undefined, inputFormat?:string):string|undefined;
export  function formatHarDate(date?:DateOrMoment | string, inputFormat?:string)
{ return date?moment(date, inputFormat).format('MMM D, YYYY'):undefined }
//03:00 PM
export  function formatHarTime(time:DateOrMoment):string;
export  function formatHarTime(time:DateOrMoment | undefined): string | undefined;
export  function formatHarTime(time:string, inputFormat?:string):string;
export  function formatHarTime(time:string | undefined, inputFormat?:string): string | undefined;
export  function formatHarTime(time?:DateOrMoment | string, inputFormat?:string)
{ return time?moment(time, inputFormat).format('hh:mm A'):undefined }

//15:40:30
export  function formatStringToSystemTime(time:string):string;
export  function formatStringToSystemTime(time?:string):string|undefined;
export  function formatStringToSystemTime(time?:string)
{ return time?moment(time,'hh:mm A').format('HH:mm:ss'):undefined }