import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IImportCsvMappedDetail, IImportCsvMappedScreenProps } from "../ImportCsvMappedScreen.def";
import { PostCSVValidateApi } from "services/contacts/csvmapping/postCSVValidate";
//import { useResponsive } from "scripts/hooks/general";
import { ValueMappingApi } from "services/contacts/csvmapping/valueMapping";
import { ProminentButton } from "views/forms/buttons/Button";
import { useResponsive } from "scripts/hooks/general";



export const DropdownMapping = forwardRef((props: IImportCsvMappedScreenProps, ref) => {
    const { mapping, selectedMapping, setSelectedMapping, onNext } = props
    const [tmpMapping, setTmpMapping] = useState<PostCSVValidateApi.ISelectedMappings>()
    type mapingKeys = keyof ValueMappingApi.IMappings
    const [keys, setKeysSelected] = useState<mapingKeys[]>()
    const isResponsive = useResponsive()
    const defaultMappingValue: IImportCsvMappedDetail[] = [{
        key: "name_suffix",
        title: "Name Suffix",
        default_short: "Jr.",
        default_long: "Jr.",

    }, {
        key: "gender",
        title: "Gender",
        default_short: "Other",
        default_long: "Other",

    }, {
        key: "title",
        title: "Title",
        default_short: "Dr.",
        default_long: "Dr.",

    }, {
        key: "income",
        title: "Income",
        default_short: "100k-200k",
        default_long: "$100,000 to $200,000",

    }, {
        key: "score",
        title: "Score",
        default_short: "A",
        default_long: "A",

    }, {
        key: "pipeline_status",
        title: "Pipeline Status",
        default_short: "10",
        default_long: "New",
    }, {
        key: "sources",
        title: "Sources",
        default_short: "other",
        default_long: "Other",

    }, {
        key: "lead_type",
        title: "Lead Type",
        default_short: "Buyer",
        default_long: "Buyer",

    }, {
        key: "primary_phone_type",
        title: "Phone Type",
        default_short: "H",
        default_long: "Home Phone",

    }, {
        key: "phone_type_1",
        title: "Phone Type 1",
        default_short: "H",
        default_long: "Home Phone",

    }, {
        key: "phone_type_2",
        title: "Phone Type 2",
        default_short: "H",
        default_long: "Home Phone",

    }, {
        key: "phone_type_3",
        title: "Phone Type 3",
        default_short: "H",
        default_long: "Home Phone",

    }]
    //const isResponsive = useResponsive();


    useImperativeHandle(ref, () => ({
        submit() {
            onSubmit()
        }
    }));
    const intializeMapping = () => {
        setKeysSelected(mapping && Object.keys(mapping) as mapingKeys[])
    }


    const onCrmDropDownChange = (csv: string, crm: string, ind: string) => {
        let tmpArray = selectedMapping?.[ind]?.filter((s) => s.csv !== csv && !s.crm.includes('*'))
        let mapping = {
            ...selectedMapping,
            [ind]:
                [
                    ...tmpArray ?? [],
                    {
                        "csv": csv,
                        "crm": crm
                    }
                ]
        }
        setTmpMapping(mapping)
        setSelectedMapping?.(mapping)

    }



    const CrmDropDown = (props: { value: string, index: number, skey: string, options?: ValueMappingApi.ICRMValueMapping[] }) => {
        let defaultSelection = selectedMapping?.[props.skey]?.filter((s) => s.crm.includes('*'))
        let defaultValue = defaultSelection?.[0] ? defaultSelection?.[0]?.crm.split("*")[1] : undefined
        return (
            <>
                <select className='form-control form-control--small' defaultValue={defaultValue ?? selectedMapping?.[props.skey]?.filter((s) => s.csv === props.value)[0]?.crm} onChange={(e) => onCrmDropDownChange(props.value, e.target.value, props.skey)}>
                    {props.options?.map((crm: any, index: number) => {
                        return <option key={index} value={crm.short_value}>{crm.long_value}</option>
                    })}
                </select >
            </>
        )
    };
    const doDefaultSelection = (event: any, mapKey: keyof ValueMappingApi.IMappings) => {
        if (event?.target.checked) {
            setTmpMapping(selectedMapping)
            setSelectedMapping?.(({
                ...selectedMapping, [mapKey]: [{
                    "csv": mapKey,
                    "crm": '*' + mappingFieldDetail(mapKey)?.default_short
                }]
            }))

        } else {
            setTmpMapping(selectedMapping)
            setSelectedMapping?.(tmpMapping)
        }

    }

    const mappingFieldDetail = (mapingKeys: mapingKeys) => {
        const field = defaultMappingValue.filter((item) => item.key === mapingKeys)?.[0]
        return field
    }

    const onSubmit = () => {
        onNext?.(4, undefined, selectedMapping)
    }

    const isDefaultChecked = (mapKey: keyof ValueMappingApi.IMappings) => {
        let selectedLength = selectedMapping?.[mapKey]?.filter((sel) => sel.crm === '*' + mappingFieldDetail(mapKey)?.default_short).length
        return selectedLength === 1
    }

    useEffect(() => {
        intializeMapping()
    }, [])

    useEffect(() => {
        console.log("tmpMapping", tmpMapping)
    }, [tmpMapping])





    return (
        <div className="container container__large">
            {!isResponsive &&
                <div className='row mb-2  mx-3'>

                    <div className='col-auto ml-auto pr-2 '>
                        <ProminentButton className='mt-2 mb-3 mr-3' size='medium' onClick={onSubmit}>Next</ProminentButton>
                    </div>
                </div>
            }            {keys?.map((key, index) => {
                return (<div key={index} >
                    <div className='font_size--large font_weight--bold mb-3'>{mappingFieldDetail(key)?.title}</div>
                    <div className="row pb-2">
                        <div className="col-12 d-flex">
                            {key && mapping?.[key] && mapping?.[key]?.csv?.length ?
                                <>
                                    <input type="checkbox" className="" defaultChecked={false} checked={isDefaultChecked(key)} onChange={(e: any) => { doDefaultSelection(e, key) }} id={`checkbox-other-${key}`} />
                                    <label className="font_size--medium font_weight--semi-bold  pt-md-2 ml-2" htmlFor={`checkbox-other-${key}`}>Choose "{mappingFieldDetail(key)?.default_long}" for every field in the {key}.</label>
                                </>
                                :
                                <div className="font_size--medium mb-3">
                                    No values found to Map.
                                </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        {mapping?.[key] && mapping?.[key]?.csv.map((label: any, cIndex: number) => {
                            return (
                                <div key={cIndex} className='col-lg-4 col-md-6 col-12'>
                                    <div className="bg_color_cement_light border_radius--pudgy p-3 max-w-500 mb-4">
                                        <div className='row align-items-center'>
                                            <div className='col-sm-6 col-12'>
                                                <div className='font_size--large color_auxiliary'>{label.long_value}</div>
                                            </div>
                                            <div className='col-sm-6 col-12'>
                                                <CrmDropDown value={label.short_value} skey={key.toString()} index={index} options={mapping?.[key]?.crm} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>)
            })}
            {!isResponsive &&
                <div className='row'>
                    <div className='col-12'>
                        <ProminentButton className='mt-2 mb-3 mr-3' size='medium' onClick={onSubmit}>Next</ProminentButton>
                    </div>
                </div>
            }



        </div >
    )
})