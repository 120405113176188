import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { ICustomMobileFooterProps, ICustomMobileHeaderProps, IEmailLandingFiltersProps, IEmailLandingScreenProps } from './EmailLandingScreen.def';
import './EmailLandingScreen.scss';
import { IonSearchbar } from '@ionic/react';
import { EmailList } from 'views/emails/EmailList';
import { useEffect, useState } from 'react';
import { AlertDialog } from 'views/dialogs/AlertDialog';
import { EmailDetail } from 'views/emails/EmailDetail';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { Tab, Tabs, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { NewEmail } from 'views/emails/popups/NewEmail';
import { useHistory } from 'react-router';
import { EmailDetailsApi } from 'services/emails/emailDetails';
import { ChangeAliasPopUp } from 'views/emails/popups/ChangeAliasPopUp';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';


export const EmailLandingScreenApp = (props: IEmailLandingScreenProps): JSX.Element => {
    const { list, id, type, loading, mails, page, member, readList, total_unread, currentSort, loadMore, search, remove, mark, sort, update, reload, setCounter, init } = props
    const [isManage, setManage] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [selectionLength, setSelectionLength] = useState(0)
    const [newEmailShow, setNewEmailShow] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>()
    const [showAliasPop, setShowAliasPop] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [messageId, setMessageId] = useState("")
    const [openPreview, setPreview] = useState(false)
    const [emailItem, setEmailItem] = useState<{ item: any, isNew: boolean } | undefined>()
    const [action, setAction] = useState<'reply' | 'forward' | 'draft' | "">("")
    const [timer, setTimer] = useState<number | string>()
    const [element, setElement] = useState<JSX.Element | undefined>()
    let history = useHistory()


    const startWriting = (mid?: string, action?: 'reply' | 'forward' | "draft" | "") => {

        if (mid) {
            setMessageId(mid)
        } else {
            setMessageId("")
        }
        setAction(action ? action : '')
        setNewEmailShow(true)
    }

    const doSearch = (key: string) => {
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            search?.(key)
        }, 512))
    }

    const forwardDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "forward")
    }


    const replyDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "reply")
    }

    useEffect(() => {
        if (member && typeof member?.provider_status !== 'undefined') {
            setConnectionStatus(member.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [member])

    return (
        <>
            {type === 'forwarded' && member?.settings &&
                <ChangeAliasPopUp show={showAliasPop} setting={member.settings} onClose={() => setShowAliasPop(false)} onChange={() => window.location.reload()} />
            }
            <Filter show={showFilter} onClose={() => setShowFilter(false)} type={type ? type : "inbox"} sort={sort} currentSort={currentSort} />
            <NewEmail
                show={newEmailShow}
                onClose={() => {
                    setNewEmailShow(false);
                    setMessageId("")
                    setAction("")
                }}
                changePreview={(item?: any, isNew?: boolean) => {
                    if (item) {
                        setEmailItem({ 'item': item, 'isNew': isNew ? isNew : false })
                    }
                }}
                connectionStatus={connectionStatus}
                provider_email={member?.provider_email}
                messageId={messageId}
                action={action}
                reload={reload}
                type={type}
                title={type === 'draft' && messageId !== '0' ? 'Draft' : 'New Email'}

            />
            <DefaultTemplate
                selectedMenu='Emails'
                total_unread={total_unread} title="Emails"
                onSwipeRefresh={() => type ? init?.(type, undefined, 1,) : ''}
                bottomFixElement={element ? <CustomFooter >{element}</CustomFooter> : undefined}
                CustomMobileHeader={() =>
                    <CustomMobileHeader
                        connectionStatus={connectionStatus}
                        isManage={isManage}
                        doManage={() => setManage(isManage ? false : true)}
                        selection={selectionLength}
                        openCompose={() => startWriting("")} />}
            >

                <>

                    {type === 'forwarded' && connectionStatus &&
                        <div className="warning color_auxiliary p-0">
                            <div className="warning__text  ion-padding-start ion-padding-end pt-2 pb-3">
                                <span className="font_weight--semi_bold">To import new lead as contact,</span> forward email to your CRM email: {member?.settings?.routine_email} <span className=" font_weight--semi_bold color_har_blue" onClick={() => setShowAliasPop(true)}>change email</span>
                            </div>
                        </div>
                    }

                    <IonSearchbar className="custom ion-no-padding ion-padding-start ion-padding-end mt-0 mb-0 search_email" placeholder="Search emails" onIonInput={(e: any) => doSearch(e.target.value.trim())}></IonSearchbar>
                    <div className='px-3 pb-3 max-w-500'>
                        <Tabs
                            id="controlled-tab-example"
                            className="nav nav-tabs nav-tabs--filters"
                        >
                            <Tab disabled={loading} eventKey="inbox" title="Inbox" onEnter={() => history.push(`/emails/inbox`)}>
                            </Tab>
                            <Tab disabled={loading} eventKey="leads" title="Leads" onEnter={() => history.push(`/emails/lead`)}>
                            </Tab>
                            <Tab disabled={loading} eventKey="draft" title="Draft" onEnter={() => history.push(`/emails/draft`)}>
                            </Tab>
                            <Tab disabled={loading} eventKey="sent" title="Sent" onEnter={() => history.push(`/emails/sent`)}>
                            </Tab>

                        </Tabs>
                    </div>
                    <div className="action_bar">
                        {type === 'inbox' &&
                            <>
                                <div>{mails?.total} email{mails?.total && Number(mails.total) > 1 ? 's' : ''}</div>
                                <div className="action_bar__link ml-auto">
                                    <a className="action_bar__link ml-auto mr-4 cursor--hand" onClick={() => setShowFilter(true)}>Sort </a>
                                </div>
                            </>
                        }
                        {type !== 'inbox' &&
                            <>
                                <div className="align-self-center flex-grow-1">{mails?.total} {type} mail{mails?.total && Number(mails.total) > 1 ? 's' : ''}</div>

                                <div className="action_bar__link pl-2 cursor--hand ml-auto flex-grow-0 align-self-center" onClick={() => setShowFilter(true)}>Sort</div>
                            </>
                        }
                    </div>

                    {member && typeof connectionStatus !== 'undefined' &&
                        <div>
                            <EmailList
                                list={list}
                                type={type}
                                id={id}
                                loading={loading}
                                editMode={isManage}
                                page={page}
                                totalPages={mails?.total_pages}
                                updateList={emailItem}
                                readList={readList}
                                connectionStatus={connectionStatus}
                                loadMore={loadMore}
                                selectionCount={(id) => setSelectionLength(id)}
                                remove={remove}
                                mark={mark}
                                setPreview={setPreview}
                                openMessage={(Id: string) => {
                                    startWriting(Id, "draft")
                                }}
                                update={update}
                                setCustomFooter={(elem: JSX.Element | undefined) => setElement(elem)}
                            />
                            <EmailDetail
                                type={type}
                                id={id}
                                mails={mails}
                                preview={openPreview}
                                connectionStatus={connectionStatus}
                                setPreview={setPreview}
                                createDraft={(mail: EmailDetailsApi.Response, isReply: boolean) => {
                                    if (isReply) {
                                        replyDraft(mail)
                                    } else {
                                        forwardDraft(mail)
                                    }

                                }}
                                openMessage={(Id: string) => {
                                    startWriting(Id, "reply")
                                }}
                                setCounter={setCounter}
                            />
                            <AlertDialog message={alertMessage} setSuccessMsg={setAlertMessage} />
                        </div>
                    }

                    {member && typeof connectionStatus === "undefined" &&
                        <NoConnectionBox noBorder={true} />
                    }
                </>
            </DefaultTemplate >
        </>
    );
}

const Filter = (props: IEmailLandingFiltersProps) => {
    const { show, currentSort, sort, onClose } = props
    const [sortText, setSortText] = useState<"recent" | "older">("recent");

    function doApply() {
        if (currentSort !== sortText) {
            sort?.(sortText)
        }

        onClose?.()
    }




    return (
        <IonModal className="gray_bg" trigger="open-modal" isOpen={show} onDidDismiss={() => onClose?.()} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons className="w-20pr" slot="start">
                        <IonButton color={"blue"} strong={true} onClick={() => onClose?.()}>Close</IonButton>
                    </IonButtons>
                    <IonTitle>Sort</IonTitle>
                    <IonButtons className="w-20pr" slot="end">
                        <IonButton  color={"blue"} strong={true} onClick={doApply}>Apply</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>




                <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                    <div className=" form_title__large">Sort By</div>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__grid" type="radio" name="filter" defaultValue={sortText} onChange={(e: "recent" | "older") => {
                        setSortText(e)
                    }}>
                        <ToggleButton className="btn--medium" id="sort-recent" value={"recent"} >
                            Recent
                        </ToggleButton>
                        <ToggleButton className="btn--medium" id="sort-older" value={"older"} >
                            Older
                        </ToggleButton>

                    </ToggleButtonGroup>
                </div>

            </IonContent>
        </IonModal >
    );
}


const CustomMobileHeader = (props: ICustomMobileHeaderProps): JSX.Element => {
    const { isManage, doManage, selection, connectionStatus, openCompose } = props
    return (

        <div className="ml-auto align-self-center">

            <div>
                {isManage &&
                    <>
                        <a className="action_bar__link ml-auto mr-4 cursor--hand  color_har_blue font_weight--semi_bold" onClick={() => doManage?.()} ><span className="font_size--large">Selected {selection}</span></a>
                        <a className="ml-auto mr-2 cursor--hand  color_har_blue font_weight--semi_bold" onClick={() => doManage?.()}><span className="font_size--large">Cancel</span></a>
                    </>
                }
                <div>
                    {connectionStatus &&
                        <a className="action_bar__link ml-auto mr-4 cursor--hand color_har_blue font_weight--semi_bold" onClick={() => openCompose?.()} ><span className="font_size--large">Compose</span></a>
                    }
                    {!isManage &&
                        <a className="action_bar__link ml-auto mr-4 cursor--hand color_har_blue font_weight--semi_bold" onClick={() => doManage?.()}><span className="font_size--large">Edit</span></a>
                    }
                </div>

            </div >
        </div>

    );
}

const CustomFooter = (props: ICustomMobileFooterProps) => {
    const { children } = props
    return children;
}