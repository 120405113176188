import { ICalendarTimelineScreenProps, ICalendarTimelineScreenState } from './CalendarTimelineScreen.def';
import './CalendarTimelineScreen.scss';
import { IonSearchbar, IonSpinner } from "@ionic/react";
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from "@ionic/react";
import { useEffect, useState } from 'react';
import { getDayNumber } from 'scripts/helpers/dates';
import { fetchGetCalendarTasksService, GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { TaskTimeline } from 'views/calendar/TaskTimeline';
import { MiniCalendar } from "views/calendar/MiniCalendar";
import { FilterModalApp } from '../../../calendar/TaskTimeline/components/FiltersModalApp/FilterModal.app';
import { fetchGetCalendarTaskStatsService, GetCalendarTaskStatsApi } from 'services/calendar/getCalendarTaskStats';
import { useHistory } from 'react-router';
import { AddTaskPopUpApp } from 'views/calendar/popups/AddTaskPopUp/AddTaskPopUp.app';
import { ShapelessButton } from 'views/forms/buttons/Button';
import moment from 'moment';
var timeout: any;
const typesFilter = "CALL,APPT,EMAIL,FOLLW,OFFER,MEETG,INSPT,CLOSE,PREVW,OH,VOH,BOH,SHOW,VSHOW,EVENT,VOLUN,TRAIN"

export const CalendarTimelineScreenApp = (props: ICalendarTimelineScreenProps): JSX.Element => {
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [showCalenderModal, setShowCalenderModal] = useState(false);
    const [showAddTask, toggleShowAddTask] = useState(false)
    useEffect(() => {
     //   props.month !== filter.start_dt && handleFilterData({ start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate() });
        refreshData();
        try {
            state?.filter && history.replace(history.location.pathname, { 'filter': JSON.stringify({ ...JSON.parse(state.filter), 'start_dt': moment(props.month).startOf('month'), 'end_dt': moment(props.month).endOf('month') }) });
        } catch (error) { }
    }, [props.refreshData, props.month, props.filter]);

    {/**useEffect(() => {
        handleFilterData({ start_dt: moment(props.month).startOf('month'), end_dt: moment(props.month).endOf('month') })
    }, [props.month]);
    useEffect(() => {
        handleFilterData(props.filter);
    }, [props.filter])*/}

    useEffect(() => {
        if (props.filter?.date) {
            setTimeout(() => handleNavigateToDate(props.filter?.date || new Date()), 1000);
        }
    }, [props.filter])


    const history = useHistory<ICalendarTimelineScreenState>()
    const { state = {} } = history.location;
    const getInitialFilters = () => {
        try {
            if (state?.filter) {
                return JSON.parse(state.filter);
            }
        } catch (error) { }
        return initialFilter
    }
    const initialFilter = { completed: undefined, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), priorities: "H,M,L", types: typesFilter, status_filter: "", contact_id: props.contactId, sort_dir: "ASC", categories: "T,E" }
    const [filter, setFilter] = useState<GetCalendarTasksApi.Request>(getInitialFilters);
    const [tasks, setTasks] = useState<GetCalendarTasksApi.Response>();
    const [taskStats, setTaskStats] = useState<GetCalendarTaskStatsApi.Response>();
    const [calendarData, setCalendarData] = useState<{ upcoming: Array<string>, due: Array<string>, completed: Array<string>, show: boolean }>({ upcoming: [], due: [], completed: [], show: false });
    const [showLoading, setShowLoading] = useState(false);

    const onChangeSearchText = async (text: string) => {
        clearTimeout(timeout);
        if (text.length > 0) {
            timeout = setTimeout(() => getTaskData({ ...filter, keyword: text }), 500);
            setFilter({ ...filter, keyword: text });
        } else if (text.length === 0) {
            getTaskData({ ...filter, keyword: "" })
        }
    };

    const getMiniCalendarData = async (tasksData: GetCalendarTasksApi.Response) => {
        const data: { upcoming: Array<string>, due: Array<string>, completed: Array<string> } = { upcoming: [], due: [], completed: [] };

        try {
            tasksData.list.map((item) => {
                moment(item.start_dt).diff(moment()) > 0 &&
                    !item.completed && data.upcoming.push(moment(item.start_dt).format("YYYY-MM-DD")) ||
                    !item.completed && data.due.push(moment(item.start_dt).format("YYYY-MM-DD")) ||
                    item.completed && data.completed.push(moment(item.start_dt).format("YYYY-MM-DD"))

            });
            setCalendarData({ ...data, show: true });
        } catch (error) { }
    }

    const getTaskData = async (filter: GetCalendarTasksApi.Request) => {
        try {
            setShowLoading(true);
            setTaskStats(undefined);
            if(filter.priorities=='none'){filter.priorities=undefined};
            if(filter.types=='none'){filter.types=undefined};
            const responses = await fetchGetCalendarTasksService({ ...filter })
            setTasks(responses);
            getMiniCalendarData(responses);
            setShowLoading(false);
            getStatsData({...filter})
        } catch (error) { }
    }
    const getStatsData = async (filtered: GetCalendarTasksApi.Request) => {
        try {
            const responses = await fetchGetCalendarTaskStatsService({ contact_id: props.contactId, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(),types:filtered.types,priorities:filtered.priorities,categories:filtered.categories })
            setTaskStats(responses);
        } catch (error) { }
    }

    const refreshData = () => {
        setTasks(undefined)
        getTaskData({ ...filter, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), ...props.filter, date: undefined });
    }

    const handleFilterData = async (newFilter: GetCalendarTasksApi.Request) => {
        if (newFilter.date) {
            setTimeout(() => handleNavigateToDate(newFilter?.date || new Date()), 1000);
        }
        setTasks(undefined);
        getTaskData({ ...filter, ...newFilter })
        setFilter((prev) => ({ ...prev, ...newFilter }))
        history.replace(history.location.pathname, { 'filter': JSON.stringify({ ...filter, ...newFilter }) });
    }

    const handleNavigateToDate = (date: Date) => {
        var myElement = document.getElementById(getDayNumber(date))?.offsetTop;
        props.contentRef?.current?.scrollToPoint(0, myElement, 500);
    }
    const loadMore = async () => {
        handleFilterData({ page: (tasks?.page ?? 0) + 1 })
    }
    return (<>
        <IonSearchbar onIonChange={(e) => onChangeSearchText(e.detail.value ?? "")} className="custom ion-no-padding ion-padding-start ion-padding-end" placeholder="Search tasks and events.."></IonSearchbar>
        <div className="action_bar  mt-3">
            {/**  <div>{tasks?.list.length !== undefined && tasks?.list?.length + ` Tasks`}</div>*/}

            <div className="action_bar__link mr-4 ml-auto cursor--hand" onClick={() => setShowCalenderModal(true)}><img src="https://content.harstatic.com/media/icons/calender_small.svg" /></div>
            <div className="action_bar__link pl-2 cursor--hand" onClick={() => setShowFiltersModal(true)}><img className=' w-24px' src="https://content.harstatic.com/media/icons/filters.svg" /></div>
            {props.contactId && <div className="action_bar__link pl-4 cursor--hand justify-content-end">
                <a onClick={() => toggleShowAddTask(true)}><img className=' w-22px' src="https://content.harstatic.com/media/icons/plus_auxilliary.svg"></img></a>
                <AddTaskPopUpApp show={showAddTask} toggle={toggleShowAddTask} /></div>}
            {/* this model is for Calender */}
            <IonModal className="gray_bg" trigger="open-modal" isOpen={showCalenderModal} onDidDismiss={() => setShowCalenderModal(false)} initialBreakpoint={0.43} breakpoints={[0, 0.25, 0.5, 0.75]} backdropDismiss={false}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton color={"blue"} strong={true} onClick={() => handleNavigateToDate(moment().toDate())}>Today</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <MiniCalendar
                        show={calendarData.show}
                        completed={calendarData.completed}
                        upcoming={calendarData.upcoming}
                        due={calendarData.due}
                        handleNavigateToDate={handleNavigateToDate}
                        filter={filter}
                        month={props.month} />
                </IonContent>
            </IonModal>
        </div>

        <div className="ion-padding page_gray_bg">
            {showLoading && <div className=' ml-170px'>  <IonSpinner name="lines"></IonSpinner></div>}
            <TaskTimeline tasks={tasks} refreshTasksList={refreshData} taskId={props.taskId} filter={filter}
                returnUrl={props.contactId ? `/contacts/${props.contactId}/calendar` : `/calendar`}
                redirect={
                    (id: string) => { props.contactId ? history.push(`/contacts/${props.contactId}/calendar/task/${id}`, { referrer: `/contacts/${props.contactId}/overview` }) : history.push('/calendar/task/' + id) }
                }

            />
        </div>
        {tasks?.page !== tasks?.total_pages && <div className=' text-right'>
            <ShapelessButton color={"blue"} onClick={loadMore} > View more results</ShapelessButton></div>}
        <FilterModalApp
            showFiltersModal={showFiltersModal}
            setShowFiltersModal={setShowFiltersModal}
            handleSetFilter={handleFilterData}
            filter={filter}
            initialFilter={initialFilter}
            taskStats={taskStats!}
        />
    </>);
}