import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { DefaultPopUpApp } from 'views/popups/DefaultPopUp/DefaultPopUp.app';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper";
import { IAddTaskProps } from '../AddTaskPopUp.def';
import { useEffect, useState } from 'react';
import { IFormDataProps,defaultFormData,transformEditData} from '../AddTaskPopUp.def'
import { InputChangeEventDetail, IonInputCustomEvent, } from '@ionic/core';
import { getLongvalue, useFetchLookupsService } from 'services/general/lookups';
import { PropertyTypeahead } from 'views/forms/typeaheads/PropertyTypeahead';
import {CreateCalendarTaskApi, useCreateCalendarTaskService } from 'services/calendar/createCalendarTask';
import { cloneDeep } from 'lodash-es';
import { useHistory, useParams } from 'react-router';
import { fetchGetContactService } from 'services/contacts/contactDetails/getContact';
import { SearchContactsApi } from 'services/contacts/searchContacts';
import { RepeatCustomRecurrence } from './RepeatCustomRecurrence';
import { DropDownModal } from 'views/forms/dropdowns/DropDownModal/DropDownModal.app';
import { IonToggle,IonHeader,IonToolbar,IonButtons,IonButton,IonTitle, useIonActionSheet } from '@ionic/react';
import { formatHarTime } from 'scripts/helpers/dates';
import { ListingsApi } from 'services/typeaheads/listings';
import { useUpdateCalendarTaskService } from 'services/calendar/updateCalendarTask';
import { Avatar } from 'views/contacts/Avatar';
import { ContactsTypeaheadMultiApp } from 'views/forms/typeaheads/ContactsTypeahead/ContactsTypeaheadMulti.app';
import moment from 'moment';
import { compareAsc, parseISO } from 'date-fns';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { NativeDateTimePicker } from 'views/general/NativeDateTimePicker';
import { AlertDialog } from 'views/dialogs/AlertDialog';
import { useMultiRef } from 'scripts/hooks/general';
import { ContactTitle } from 'views/general/ContactTitle';
import { useEventConfirmation } from 'scripts/hooks/forms';
import { isEmpty } from 'scripts/helpers/texts';

export const TaskFormApp = (props:IAddTaskProps) =>{
    const { id } = useParams<{ id: string }>();
    const history=useHistory();
    const {show,toggle,category,refreshData,taskDetail}=props
    const isEditTask=!!taskDetail;
    const [present]=useIonActionSheet()
    const [characterCount, setCharacterCount] = useState(0);
    const [showAddress,setShowAddress]=useState<string>('0')
    const [ref,textAreaRef]=useMultiRef<HTMLDivElement>()
    const addConfirm = useEventConfirmation();

    const maxCharacterLimit = 3000;
    const handleInitialData=()=> { 
        return isEditTask?transformEditData(taskDetail,true):cloneDeep(defaultFormData);
    }
    const [formData,setFormData]=useState<IFormDataProps>(handleInitialData);
    const [alertMessage,setAlertMessage]=useState("");
    const [createData, createActions] = useCreateCalendarTaskService();
    const [updateData, updateActions] = useUpdateCalendarTaskService();
    const [showRepeatCustomRecurrence,setShowRepeatCustomRecurrence]=useState(false);
    const [taskType,setTaskType]=useState(formData.task_type.value??"");
    const [repeat,setRepeat]=useState("");
    const [disableSave,toggleDisableSave]=useState(false) 
    const isEvent=category==="e";

     
    useEffect(()=>{
        if(id && !taskDetail){handleGetContactByID(parseInt(id))}
        setShowAddress(isEmpty(taskDetail?.location)?"0":"1")
    },[id])

    useEffect(()=>{setFormData(handleInitialData);  setCharacterCount(handleInitialData().description.length);
        if(taskDetail?.property_full_street_address) {
            setShowAddress(!(Object.values(formData.property_full_street_address.value).every(x => !x || !x.toString().trim()|| x.toString()=='0'))?"1":"0")
        }

    },[taskDetail]);
    const handleShowRecurrentOptionsPopup=(notify_contacts:boolean)=>{
        present({
            buttons: [
              {
                text: 'Edit this task',
                handler:()=>createTask("0",notify_contacts),
              },
              {
                text: 'Edit all tasks',
                handler:()=>createTask("2",notify_contacts)
              },
              {
                text: 'Cancel',
                role: 'cancel',
                data: {
                  action: 'cancel'
                },
              },
            ],
            //onDidDismiss: ({ detail }) => setResult(detail),
          })
    }

    const handleGetContactByID = async (id: number) => {
       const response=await fetchGetContactService({contact_id:id})
      setFormData({...formData,contacts:{list:[...formData.contacts.list,response],error:false}})
    }
    const handleDescriptionChange = (e:any) => {
        const descriptionValue = e.target.value;

        // Update the description in formData
        setFormData({ ...formData, description: descriptionValue });
    
        // Update the character count
        setCharacterCount(descriptionValue.length);
      };

    const lookups=useFetchLookupsService(["task_type","reminder_minutes_prior","task_priority","task_repeat"]);
   
    const handleCreateTask=formData.contacts.list.length==0?(isRecurring:string|boolean|false)=>createTask(isRecurring,false): addConfirm(`Do you want to notify ${formData.contacts.list.length??"0"} contacts?`,(isRecurring:string|boolean|false)=>{createTask(isRecurring,true);setFormData({ ...formData, notify_contacts:true});},(isRecurring:string|boolean|false)=>{createTask(isRecurring,false);setFormData({ ...formData, notify_contacts:false});})
    const createTask=async(recurringOption:string|boolean|false,isNotify:boolean)=>{
        toggleDisableSave(true)
            var formDataValue:CreateCalendarTaskApi.Request={
                    category:category,
                    title:formData.title.value,
                    property_harid:formData.property_full_street_address.value.harid,
                    property_for:formData.property_full_street_address.value.for,
                    property_listingid:formData.property_full_street_address.value.listingid,
                    location:formData.property_full_street_address.value.full_address,
                    start_dt:parseISO(formData.start_dt.value),
                    start_time:formatHarTime(formData.start_dt.value),
                    task_type:formData.task_type.value,
                    contact_ids:formData.contacts.list.map((item)=>item.id.toString()),
                    priority:formData.priority,
                    repeat:formData.repeat,
                    notes:formData.description,
                    completed:formData.completed,
                    notify_contacts:isNotify,
                    reminder_minutes_prior: formData.reminder_minutes_prior.toString(),
                    repeat_every_value: formData.repeat_every_value,
                    repeat_every_text: formData.repeat_every_text,
                    repeat_on_day: formData.repeat_on_day,
                    repeat_end_date: parseISO(formData.repeat_end_date),
                    repeat_end_on:formData.repeat_end_on,
                }
                if(formData.repeat==="Custom"){
                    formDataValue = {
                        ...formDataValue,
                        repeat_every_value:formData.repeat_every_value!==''? formData.repeat_every_value:"1",
                        repeat_every_text: formData.repeat_every_text!==''?formData.repeat_every_text:"Daily",
                        repeat_on_day: formData.repeat_on_day,
                        repeat_end_on: formData.repeat_end_on,
                        repeat_end_date:formData.repeat_end_date!==''? parseISO(formData.repeat_end_date):undefined,
                        repeat_month_option: formData.repeat_month_option
                        }
                }
                if(category==="e"){
                    formDataValue = {
                        ...formDataValue,
                        end_dt:parseISO(formData.end_dt.value),
                        end_time: formatHarTime(formData.end_dt.value)
                        }
        }
        const property = formData.property_full_street_address.value;

                if (formData.property_full_street_address.isManually) {
                    formDataValue = {
                        ...formDataValue,
                        property_full_street_address: property.street_address,
                        property_address2: property.address_2,
                        property_city: property.city,
                        property_state: property.state,
                        property_zip: property.zip,
                        property_country: property.country
                    }
                }

        

                if(validate()){
                    if(isEditTask) {
                        if(taskDetail?.is_recurrent== '1'&&!recurringOption){  handleShowRecurrentOptionsPopup(isNotify);}
                        else{ 
                            await updateActions.fetch({...formDataValue,task_id:taskDetail.id.toString(),option_id:taskDetail?.is_recurrent=='0'?'0':(typeof recurringOption=="boolean"?'0':recurringOption)});
                }
            } else {
                        await createActions.fetch(formDataValue);
            }
                }
                toggleDisableSave(false);
               
    }
    useServiceDidSucceeded(createData.response,()=> {
        displayMessage(isEvent?'Event'+' created successfully.':'Task'+' created successfully.')
        onSuccess()
    });
    useServiceDidSucceeded(updateData.response,()=>{
        displayMessage(isEvent?'Event'+' updated successfully.':'Task'+' updated successfully.')
        onSuccess()
    });


    const validate=()=>{
        var specialCharacterRegex =/^[a-zA-Z0-9\s!@#$%^&*()-_=+{}\[\]:;"'<>,.?/\\|]*$/;
        var isValidate=true
                if(formData.title.value.length<1){ 
                    formData.title.error="This field should contain min 1 character. max - infinity. Only Latin letters, numbers, symbols, and space can be added.";
                    isValidate= false;
                }
                else if(!specialCharacterRegex.test(formData.title.value)){
                    formData.title.error="This field should contain (Latin letters, numbers, symbols and space can be added)."
                    isValidate=false;
                }
                if(!moment(formData.start_dt.value).isValid()){ 
                    formData.start_dt.error="This field is required.";
                    isValidate=false;
                }
                if(!moment(formData.start_dt.value,"hh:mm A").isValid()){ 
                formData.start_dt.error="This field is required.";
                isValidate=false;
                }
            
                if(formData.task_type.value.length<2){ 
                    formData.task_type.error="This field is required.";
                    isValidate=false;
                }
                if(category==="e"){ 

                    if(!moment(formData.end_dt.value).isValid()){ 
                        formData.end_dt.error="This field is required.";
                        isValidate=false;
                    }
                    if(!moment(formData.end_dt.value,"hh:mm A").isValid()){ 
                    formData.end_dt.error="This field is required.";
                    isValidate=false;
                    }
            if (compareAsc(parseISO(formData.start_dt.value), parseISO(formData.end_dt.value)) > 0) {
                formData.end_dt.error = "Ending time should not be earlier then starting time.";
                isValidate = false;
                }

        }
              {/** if(formData.contacts.list.length===0){
                    formData.contacts.error="This field is required."   
                    isValidate=false; 
                } */}

                if(showAddress==='1'){
                    if(formData.property_full_street_address.isManually){
                        if(Object.values(formData.property_full_street_address.value).every(x => !x || !x.toString().trim()|| x.toString()=='0')){
                            formData.property_full_street_address.error="This field is required."   
                            isValidate=false; 
                        }

                    }else{
                if(formData.property_full_street_address.value.full_address.length===0){
                    formData.property_full_street_address.error="This field is required."   
                    isValidate=false; 
            }
            }
        }
                setFormData({...formData})
             return isValidate;
            }

    const onChangeHandler = (e: IonInputCustomEvent<InputChangeEventDetail>|React.ChangeEvent<any>): void => {
        setFormData({...formData,[e.target.name]:{value:e.target.value,error:""}}) ;
    }
    /*
    const onDateTimeChangeHandler = (e: IDateTimeTextboxProps.ValueChangeArgs): void => {
        setFormData({...formData,[e.name!]:{value:e.isoValue,error:""}}) ;
       }*/

     const onChangeHandlerCustom = (field:string,value:string|Array<{}>): void => {
        setFormData({...formData,[field]:{value:value,error:""}}) ;
    }
        const typeFilter=lookups[0].response?.task_type||{items:[],caption:""};
        const typeFilterItems=lookups[0].response?.task_type.items||[];
        const reminder_minutes_prior=lookups[0].response?.reminder_minutes_prior||{items:[],caption:""};
        const priorityFilter=lookups[0].response?.task_priority.items||[];
        const taskRepeat=lookups[0].response?.task_repeat.items||[];


        const removeContact = (value: SearchContactsApi.IContactSearchItem) => {
        if(parseInt(id)!=value.id){
            formData.contacts.list.splice(formData.contacts.list.indexOf(value), 1);
            const newValues = [...formData.contacts.list];
            setFormData({...formData,contacts:{list:newValues,error:false}});
        }
    }

    const title=category==="t"?isEditTask?"Edit Task / Activity":"New Task / Activity":isEditTask?"Edit Event":"New Event"
    const handleNoAssociatedAddress=()=>{
        if(showAddress ==='0'){  setShowAddress('1')}else{
        setShowAddress('0')
        setFormData({...formData,property_full_street_address:{value:ListingsApi.getInitialListingList(),isManually:false}});
        }
    }
    const onSuccess=()=>{
      
        refreshData(true);
        setFormData(defaultFormData);
        toggle(false);       
        isEditTask?history.goBack():window.location.reload();
        onClose();
        }

        const onClose=()=>{
           
            setShowRepeatCustomRecurrence(false)
            toggle(false);
         }
    //const getMaxTime=formData.end_dt.value!="" && category==='e'?moment(formData.end_dt.value).format("YYYY-MM-DD"):"2099-10-10T00:00:00"
        
         const isSelected=(value:string)=>{return formData.reminder_minutes_prior.includes(value)}
         const removeReminder=(value:string)=>{ 
            setFormData((prevState)=>({...prevState,reminder_minutes_prior:prevState.reminder_minutes_prior?.filter((a)=> !!value&&a!==value&&value!=="NaN") }))
        }
        const addReminder=(value:string)=>{ 
            setFormData((prevState)=>({...prevState,reminder_minutes_prior:[...prevState.reminder_minutes_prior,value] }))
        }
         
         const reminderValue=()=>formData.reminder_minutes_prior.length>0?(getLongvalue(reminder_minutes_prior,formData.reminder_minutes_prior[0])||"")+(formData.reminder_minutes_prior.length>1?" +"+(formData.reminder_minutes_prior.length-1):""):""
         return (
        <DefaultPopUpApp 
            isOpen={show}  hideHeader class="gray_bg" onDidDismiss={onClose}
            toggleShow={onClose} 
            customHeader={<IonHeader>
                <IonToolbar>
                    <IonButtons slot="start" className="w-15pr" >
                        <IonButton color={"blue"} onClick={()=>toggle(false)}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle >{title}</IonTitle>
                    <IonButtons slot="end" className="w-15pr">
                        <IonButton color={"blue"} disabled={disableSave} strong={true} onClick={()=>validate()&&handleCreateTask(false)}>Save</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>}>     
            <DefaultPopUp.Body>
    <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
      <div className="form_sec mb-5 mt-3">
        <Textbox autofocus name="title" onIonChange={onChangeHandler} error={formData.title.error}  value={formData.title.value}  placeholder={`Give your ${category==="e"?"event":"task"} a title`} id="Title"></Textbox>
        <DropDownModal 
            name="task_type" 
            label={`${category=="e"?"Event":"Task"} Type`}
            value={getLongvalue(typeFilter,formData.task_type.value)||""} 
            handleSave={()=>onChangeHandlerCustom("task_type",taskType)} 
            resetSelectedValue={()=>setTaskType("")}
            isSingleSelection
           >
            {typeFilterItems.map((item,index)=>
                <div key={index} className="choosable__stage" >
                    <input type="radio" className="custom-control-input"  onChange={(e)=>{setTaskType(e.target.value);onChangeHandlerCustom("task_type",e.target.value)}} checked={taskType==item.short_value} value={item.short_value} name="task_type" id={item.short_value} />
                    <label className="custom-control-label" htmlFor={item.short_value}>
                        <div>{item.long_value}</div>
                        <div className="check"></div>
                    </label>
                </div>)}
    </DropDownModal>

     {formData.task_type.error&&<div className='ml-3 color_sold'>{formData.task_type.error}</div>}
            </div> 
            <div className="form_sec">
                        <NativeDateTimePicker
                            name="start_dt"
                            label={category === "e" ? "Start Date" : "Date"}
                            showTime={true}
                            onChange={(e) => setFormData({ ...formData, ["start_dt"]: { value: e, error: "" } })}
                    value={formData.start_dt.value}
                            error={formData.start_dt.error ? formData.start_dt.error.toString() : ''}
                    disabled={false}  
                    />
            {category==="e"&&
                            <NativeDateTimePicker
                                name="end_dt"
                                label={category === "e" ? "End Date" : "Date"}
                                showTime={true}
                                onChange={(e) => setFormData({ ...formData, ["end_dt"]: { value: e, error: "" } })}
                    value={formData.end_dt.value}
                                error={formData.end_dt.error ? formData.end_dt.error.toString() : ''}
                                minDate={formData.start_dt.value ? moment(formData.start_dt.value).format("YYYY-MM-DDTHH:mm:ss") : undefined}
                                disabled={formData.start_dt.value !== "" ? false : true}
                                onError={(str: string) => setFormData({ ...formData, ["end_dt"]: { value: formData.end_dt.value, error: str } })}
                    />
                        }
                </div>
                         <div className="form_sec mb-0 pt-1 pb-1 ion-padding border-bottom-0">
                            <div className="row">
                                <div className="col align-self-center font_size--large">It is associated with a property</div>
                                <div className="col-auto">
                                    <IonToggle checked={showAddress==='1'} onIonChange={handleNoAssociatedAddress} slot="end"></IonToggle>
                                </div>
                            </div>
                        </div>
                      {/**  {showAddress==='1'&&<div className="pb-4">
                            <div className="section_divider">
                                <AddressTextbox listing={formData.property_full_street_address.value} onAddressChange={(address)=>setFormData({...formData,property_full_street_address:{value:address,error:false,isManually:false}})}  value={formData.property_full_street_address.value.full_address} placeholder="Type here" id="Location" />
                            </div>
            </div>}*/ } 
                         {showAddress==='1'&&<div className="border-top">
                          <PropertyTypeahead className={`${formData.property_full_street_address.error ? `is-invalid` : ``}`}
                                    initialValues={!Object.values(formData.property_full_street_address.value).every(x => !x || !x.toString().trim()|| x.toString()=='0' ) ? [formData.property_full_street_address.value] : []}
                                    change={(item)=>setFormData({...formData,property_full_street_address:{value:item[0]||ListingsApi.getInitialListingList(),error:false,isManually:false}})}
                                    remove={()=>setFormData({...formData,property_full_street_address:{value:ListingsApi.getInitialListingList(),error:false,isManually:false}})}
                                    update={(item)=>setFormData({...formData,property_full_street_address:{value:item[0]||ListingsApi.getInitialListingList(),error:false,isManually:item[0]?true:false}})}
                                    showingCountry={true}
                                    minLength={1}
                                />
                                {formData.property_full_street_address.error&&
                                    <div className='color_sold bg_color_snow_white ml-3'>{formData.property_full_street_address.error}</div>
                                }
                        </div>}
         <div className="form_sec mb-5">
         <ContactsTypeaheadMultiApp minLength={1} multi maxSelection={10} initialValues={formData.contacts.list} save={(item) =>setFormData({...formData,contacts:{list:item,error:false}})} persistentContact={id} />
                   
         {/** <DropDownModal 
                name="task_type" 
                label='Choose a Contact'
                value={""} >
                   <>
                <ContactsTextbox minLength={1} initialContacts={formData.contacts.list} placeholder="Search" onContactsChange={(item)=>setFormData({...formData,contacts:{list:item,error:false}})} maxContacts={20}/>
                    <div className="mb-4"></div>
                    {formData.contacts.list.length>0&&
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={12}
                                    slidesPerGroup={1}
                                    loop={false}
                                    loopFillGroupWithBlank={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    className="owners_desktop"
                                >
                                   {formData.contacts.list.map((item,ind)=> <SwiperSlide key={ind}>
                                        <div className="agent_signature agent_signature__inline">
                                            <div className="agent_signature__photo">
                                            <Avatar id={item?.id} firstName={item?.first_name} lastName={item?.last_name} photo={item?.photo_url} link={`/contacts/${formData.contacts.list}/overview`}></Avatar>
                                                <div onClick={()=>removeContact(item)} className="agent_signature__photo_cross"><img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" /></div>
                                            </div>
                                            <div className="agent_signature__text">{`${item?.first_name} ${item.last_name}`}</div>
                                        </div>
                                    </SwiperSlide>)}
                                </Swiper>}    
                </> 
           </DropDownModal>*/}
            {/** {!!formData.contacts.error&&<div className='ml-3 color_sold bg_color_snow_white'>{formData.contacts.error}</div> } */}
           {formData.contacts.list.length>0&&<div className="form_sec pt-3 ion-padding mb-0">
                <Swiper
                    slidesPerView={4}
                    spaceBetween={12}
                    slidesOffsetAfter={60}
                    slidesPerGroup={1}
                    loop={false}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={formData.contacts.list?.length > 4 ? true : false}
                    modules={[Pagination, Navigation]}
                    className="owners_desktop"
                >
                    {formData.contacts.list.map((item,ind)=> <SwiperSlide key={ind}>
                        <div className="agent_signature agent_signature__inline">
                            <div className="agent_signature__photo">
                            <Avatar id={item?.id} firstName={item?.first_name} lastName={item?.last_name} photo={item?.photo_url} link={item.deleted=="1"?undefined:`/contacts/${item?.id}/overview`}></Avatar>
                            {item.id.toString()!==id  &&  <div onClick={()=>removeContact(item)} className="agent_signature__photo_cross"><img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" /></div>}
                            </div>
                            <div className="agent_signature__text"><ContactTitle contact={item} /> {`${item.deleted=="1"?`(deleted)`:""}`}</div>
                        </div>
                    </SwiperSlide>)}
                </Swiper></div>}
               
           </div>
        <div className="form_sec mb-5">
               <div className="ion-padding">
                    <label className="pb-3 color_auxiliary font_size--large">Priority</label>                        
                    <ToggleButtonGroup className="d-flex btn-group btn-group-toggle btn-group--priority" type="radio" name="options" value={formData.priority}>
                    {priorityFilter.map((item,ind)=><ToggleButton  key={ind} onClick={()=>setFormData({...formData,priority:item.short_value})}
                     className={`btn--medium btn btn--${item.long_value.toLowerCase()}`}  
                      id={item.long_value.toLowerCase()} value={item.short_value}>
                       {item.long_value}
                                    </ToggleButton>
                                   )}
                            </ToggleButtonGroup>
                        </div>
                    </div>
        <div className="form_sec mb-5 mt-3">
            <DropDownModal 
                name="reminder" 
                label='Reminder'
                value={reminderValue()}
               // handleSave={()=>setFormData((prevState)=>({...prevState,reminder_minutes_prior:reminder}))} 
                resetSelectedValue={()=>setFormData((prevState)=>({...prevState,reminder_minutes_prior:[]}))}>
                {reminder_minutes_prior?.items.map((item,ind)=>
                    <div key={ind} className="choosable__stage">
                        <input type="checkbox" className="custom-control-input" checked={isSelected(item.short_value)} name="stageoption" id={"A"+item.short_value} value={item.short_value} 
                        onChange={()=>isSelected(item.short_value)?removeReminder(item.short_value):addReminder(item.short_value) }/>
                        <label className="custom-control-label" htmlFor={"A"+item.short_value}>
                            <div>{item.long_value}</div>
                            <div className="check"></div>
                        </label>
		            </div>)}
        </DropDownModal>
    </div>
    <div className="form_sec mb-5 mt-3">
           <DropDownModal 
                name="repeat" 
                label='Repeat'
                value={formData.repeat||""} 
                handleSave={()=>onChangeHandlerCustom("Repeat",repeat)} 
                resetSelectedValue={()=>setRepeat("")}
                isSingleSelection>
<>
                    <div className='choosable__stage' onClick={()=>{setFormData({...formData,repeat:""}) }}>
                        <input type="radio" value={formData.repeat} checked={(""===formData.repeat)} className="custom-control-input" name="stageoption" id={""} />
                        <label className="custom-control-label" htmlFor={""}>
                            <div>Do not repeat</div>
                        <div className="check"></div>
                        </label>
                    </div>
                {taskRepeat.map((item,ind)=>
               <div  key={ind} className="choosable__stage" 
                    onClick={()=>{ if(item.short_value==="Custom"){
                            if(formData.start_dt.value===""){           
                                 setAlertMessage('Please choose task date.', ); 
                        }else{
                    setShowRepeatCustomRecurrence(true);
                    setFormData({...formData,repeat:"Custom"})}}
                else{
                    setFormData({...formData,repeat:item.short_value});}}}>
                    <input type="radio" value={formData.repeat} checked={(item.short_value===formData.repeat)} className="custom-control-input" name="stageoption" id={item.short_value} />
                    <label className="custom-control-label" htmlFor={item.short_value}>
                        <div>{item.long_value!=''?item.long_value:"Do not repeat"}</div>
                    <div className="check"></div>
                    </label>
		        </div>)}
                </>
            </DropDownModal>
            </div>
            </div>
            <div className="form_sec pt-3 pb-2 ion-padding">
                <div role="checkbox" className="custom-control custom-checkbox">
                    <input type="checkbox" checked={formData.completed} onClick={()=>setFormData({...formData,completed:!formData.completed})}  id="Completed" name="Completed" className="custom-control-input pt-2" />
                    <label className="custom-control-label" htmlFor="Completed"><span className="font_size--large">Mark as complete</span></label>
                </div>
            </div>
            <div ref={textAreaRef} className="form_sec mb-5 pt-3 pb-2 ion-padding">
                <textarea  onChange={handleDescriptionChange}  onFocus={()=>{ref.current?.style.setProperty("height","400px");ref.current?.scrollIntoView({inline:"end"})}} onBlur={()=>{ref.current?.style.setProperty("height","100px");}} className="form-control border-0" maxLength={3000} value={formData.description} placeholder="Type some description, if you want…"></textarea>
                <p className='font_weight--bold'> Character Count: {characterCount} / {maxCharacterLimit} </p>

            </div>
            <RepeatCustomRecurrence formData={formData} setFormData={setFormData} show={showRepeatCustomRecurrence} toggle={setShowRepeatCustomRecurrence}/>
            <AlertDialog setSuccessMsg={setAlertMessage} message={alertMessage} />
        </DefaultPopUp.Body>
        </DefaultPopUpApp>
        );
    }