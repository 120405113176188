import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { joinWithSpace } from 'scripts/helpers/texts';
import { useMultiRef } from 'scripts/hooks/general';
import { extractChildren } from 'scripts/utilities/react';
import { DefaultPopUp } from '.';
import { IDefaultPopUpProps } from './DefaultPopUp.def';
import './DefaultPopUp.scss';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';
import { close } from 'ionicons/icons';

export const DefaultPopUpApp = (props: IDefaultPopUpProps): JSX.Element => {
    const { title, className, closeButton, children, modalRef,hideHeader,customHeader, backdropDismiss = false, ...modalProps } = props;
    const showCloseButton = backdropDismiss? (closeButton ?? true): true; // if backdropDismiss is disabled, then closeButton must be true
    const [ref, assignRef] = useMultiRef(modalRef);

    const { body, actionBar } = extractChildren(children, {
        body: DefaultPopUp.Body,
        actionBar: DefaultPopUp.ActionBar
    });

    const onClose = () => {
        if (props.toggleShow) {
            props.toggleShow()
        } else {
            ref.current?.dismiss();
        }
    };

    usePopUpAutoFocus(ref);
    return (
        <IonModal {...modalProps} onDidDismiss={onClose} ref={assignRef} className={joinWithSpace(className, "custom--modal custom--modal__blackoverlay")} backdropDismiss={backdropDismiss}>
            {!hideHeader&&<IonHeader className="ion-padding-md ion-no-border border-bottom">
                <IonToolbar>
                    <div className="custom--modal_header">
                        <IonTitle className="modal-title">{title}</IonTitle>
                        {showCloseButton && <IonButton color={"blue"} className="modal-closebtn" slot="end" fill="clear" onClick={onClose}>
                            <IonIcon slot="icon-only" icon={close} color="dark"></IonIcon>
                        </IonButton>}
                    </div>
                </IonToolbar>
            </IonHeader>}
            {customHeader&&customHeader}
            <IonContent class="md content-ltr hydrated">{body}</IonContent>
            {actionBar && <IonFooter className="ion-no-border border-top">{actionBar}</IonFooter>}
        </IonModal>
    );
}