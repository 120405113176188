import { isString } from "lodash-es";
import React, { ClassAttributes, MutableRefObject } from "react";
import { DetailedHTMLProps, LegacyRef } from "react";

type CSSVars = Record<string, string | number | null | undefined>;

interface ITagExtension<T>
{
    cssVars?:CSSVars;
    refs?:LegacyRef<T>[];
}

type ITagProps<K extends keyof JSX.IntrinsicElements, T> = JSX.IntrinsicElements[K] & ITagExtension<T>;

type ITagListProps = {
    [K in keyof JSX.IntrinsicElements as Capitalize<K>]: JSX.IntrinsicElements[K] extends DetailedHTMLProps<any, infer T>?
        (props:ITagProps<K, T>) => JSX.Element:
        (props:JSX.IntrinsicElements[K]) => JSX.Element;
}


export const Tag:ITagListProps = new Proxy({}, {
    get: (response: any, key): any => {
        if(response[key]) { return response[key]; }
        if(!isString(key)) { return undefined; }
        return response[key] = (props:ITagExtension<any>) => {

            const tagProps = handleProps(props);
            return React.createElement(key.toLowerCase(), tagProps);
        }
    }
});

function handleProps(inputProps:(ITagExtension<any> & ClassAttributes<any>) | null | undefined): object | null | undefined
{
    const props = inputProps? { ...inputProps }: inputProps;

    if(props && props.cssVars)
    {
        const ref = cssVarsRef(props.cssVars);
        if('refs' in props && props.refs && Array.isArray(props.refs)) { props.refs = [...props.refs, ref]; }
        else { props.ref = props.ref? mergeRefs(props.ref, ref): ref; }
        delete props.cssVars;
    }

    // Handling refs
    if(props && props.refs)
    {
        const refs:any[] = [];
        if('ref' in props && props.ref) { refs.push(props.ref); }
        else if('refs' in props && props.refs && Array.isArray(props.refs)) {
            props.refs.forEach(ref => refs.push(ref));
        }

        props.ref = mergeRefs(...refs);
        delete props.refs;
    }

    return props;
}

function cssVarsRef(cssVars:CSSVars)
{
    return (tag:HTMLElement) => {
        if(!tag || !('style' in tag)) { return; }
        Object.keys(cssVars).forEach(name => {
            const value = cssVars[name]?.toString();
            if(value) { tag.style.setProperty('--' + name, value); }
        });
    };
}

function mergeRefs<T>(...refs:Array<React.Ref<T> | string | undefined>)
{
    return (tag:T) => refs.forEach(ref => {
        if(!ref || isString(ref)) { return; }
        if('current' in ref) { (ref as MutableRefObject<T>).current = tag; }
        else { ref(tag); }
    });
}

