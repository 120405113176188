import { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { IDealsFilterPopoverProps } from './DealsFilterPopover.def';
import './DealsFilterPopover.scss';

export const DealsFilterPopoverDesktop = (props: IDealsFilterPopoverProps): JSX.Element => {
    const { lookupData, filters, onSave, onClear } = props
    const dealRef = useRef<HTMLSelectElement>(null);
    const propertyRef = useRef<HTMLSelectElement>(null);

    function search() {
        if (dealRef.current !== null || propertyRef.current !== null) {
            let param = {
                'property_types': propertyRef.current?.value,
                'deal_types': dealRef.current?.value
            }

            onSave?.(param)
        }
    }

    function clear() {
        if (null !== dealRef.current) {
            dealRef.current.value = ""
        }

        if (null !== propertyRef.current) {
            propertyRef.current.value = ""
        }
        onClear?.()
    }

    function filterCount(): number {
        var i = 0;
        if (filters?.property_types) {
            i = i + 1
        }
        if (filters?.deal_types) {
            i = i + 1
        }
        return i
    }

    return (
        <Dropdown className='dropdown dropdown--custom dropdown--custom__inverse dropdown__alltprop d-inline-block'>
            <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" variant="none" href="#">
                <button className={`filterpill ${filterCount() ? `filterpill--all` : ``} mr-3`} aria-label="Filter by price">Filters {filterCount() && filterCount() > 0 ? <span>{filterCount()}</span> : ''}</button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu agent_dropdown animate slideIn p-4 max-h-auto">
                <div className='min-w-280px'>
                    <label>Property Type </label>
                    <select ref={propertyRef} defaultValue={filters?.property_types ? filters?.property_types : ''} className="custom-select custom-select_large mb-4">
                        <option value="">All Property Type</option>
                        {lookupData?.response?.property_type?.items.map((property, index) =>
                            <option key={index} value={property.short_value}>{property.long_value}</option>
                        )}
                    </select>

                    <label>Deal Type</label>
                    <select ref={dealRef} defaultValue={filters?.deal_types} className="custom-select custom-select_large">
                        <option value="">All Deal Types</option>
                        {lookupData?.response?.deal_type?.items.map((type, index) =>
                            <option key={index} value={type.short_value}>{type.long_value}</option>
                        )}
                    </select>
                    <div className="row mt-3">
                        <div className="col"><ShapelessButton className="pl-0 mb-0" onClick={() => {
                            clear();
                            onSave?.({})
                        }}>Clear</ShapelessButton></div>
                        <div className="col-auto"><PrimaryButton className="mb-0" onClick={() => search()}>Apply</PrimaryButton></div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
