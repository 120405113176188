import { IInstructionsSectionProps } from './InstructionsSection.def';
import './InstructionsSection.scss';

export const InstructionsSection = (props: IInstructionsSectionProps): JSX.Element => {
    const { showHarContacts, showProviderContacts, className } = props
    return (
        <div className={`row justify-content-center ${className ? className : `pt-md-5 pt-3  pb-2`}}`}>
            {showProviderContacts && <div className={`${showHarContacts && `col-md-6`} col-12`}>
                <div className="p-2 text-center ml-auto mr-auto max-w-450">
                    <img src="https://content.harstatic.com/media/icons/crm/connecting_agents.svg" className=" w-90px d-md-none d-inline-block" alt="Contact Import" />
                    <img src="https://content.harstatic.com/media/icons/crm/connecting_agents.svg" className="w-120px d-md-inline-block d-none" alt="Contact Import" />
                    <div className="mb-1 pt-md-4 pt-2 font_size--large_extra font_weight--bold">
                        Contact Import in Progress
                    </div>
                    <div className="font_size--medium pb-md-5 pb-4">
                        We are currently importing contacts, and this process may take a few minutes to a few hours. You may continue to use the CRM while the import is in progress.
                        Thank you for your patience.
                    </div>
                </div>
            </div>
            }
            {showHarContacts &&
                <div className={`${showProviderContacts && `col-md-6`} col-12`}>
                    <div className="p-2 text-center ml-auto mr-auto max-w-450">
                    <img src="https://content.harstatic.com/media/common/har_logo.svg" className=" w-90px d-md-none d-inline-block" alt="HAR Logo" />
                        <img src="https://content.harstatic.com/media/common/har_logo.svg" className="w-120px d-md-inline-block d-none" alt="HAR Logo" />
                        <div className="mb-1 pt-md-4 pt-2 font_size--large_extra font_weight--bold">
                            HAR Contacts
                        </div>
                        <div className="font_size--medium pb-md-4">
                            Your contacts from the HAR Members Only Area are accessible through your CRM. These include the contacts you've cultivated over the years through various applications such as the LeadsMagnet tools, the Booking App, LeadPages, LeadConnect, and more.
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
