import { IGetStartedScreenContentProps, IGetStartedScreenLoadedProps } from "./GetStartedScreen.def";
import { Avatar } from 'views/contacts/Avatar';
import { mopHost } from 'scripts/application/settings';
import { OnBoardingTemplate } from 'views/templates/OnBoardingTemplate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OrdinaryButton, PrimaryButton } from "views/forms/buttons/Button";

export const Content = (props:IGetStartedScreenContentProps): JSX.Element =>
{
    const { onboarding } = props;
    return (
        <OnBoardingTemplate onboarding>
            <div className="border-bottom pb-md-4 pb-2 pt-md-4 pt-0 pl-md-5 pr-5">
                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Get Started...</h2>
            </div>
            <div className="p-md-5 mt-3 mt-md-0">            
                {onboarding.loading || onboarding.error? <Loading />: <Loaded onboarding={onboarding} />}
            </div>
        </OnBoardingTemplate>
    );
}

const Loading = (): JSX.Element =>
{
    // TODO: Loading State Pending
    return <>Loading...</>;
}

const Loaded = (props:IGetStartedScreenLoadedProps): JSX.Element =>
{
    const { onboarding } = props;
    const photo = onboarding.user.photo ?? 'https://content.harstatic.com/resources/images/listing_details/place_holder_agent.png';
    const tooltipText = 'Edit your account in the Members Only Area';
    const { next, labels } = onboarding;

    return (<>
        <div className="ap_change cursor--hand onboarding_screen agent_signature agent_signature__large__extra agent_signature__nagative mb-md-5 mb-4">                
            <div className="d-flex align-items-center overflow-ellipsis">
                <Avatar className={"position-relative w-96px h-96px"} id={onboarding.user.user_id.toString()} photo={photo} />
                <div className="overflow-ellipsis">
                    <div className="font_size--large_extra_extra">{onboarding.user.first_name} {onboarding.user.last_name}</div>
                    <div className="font_size--large d-flex align-items-center">
                        <span className="mr-2 overflow-ellipsis ">{onboarding.user.agent_data.email}</span>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={props => <Tooltip id="mop-tooltip" {...props}>{tooltipText}</Tooltip>}>
                                <span className="d-block bg_color_cloudy_sky_light cursor_pointer w-14px h-14px bottom-0 right-0px line-h-1 border_radius--rounded">
                                    <img className=" w-14px h-14px" src="https://content.harstatic.com/media/icons/styleguide/icon-info-yellow.svg" />
                                </span>
                        </OverlayTrigger>
                    </div>
                    <div className="font_size--large pb-2">{onboarding.user.agent_data.phone}</div>
                    <a href={`${mopHost}/moa_myaccount_personalinfo/my_info?pid=1`} className="font_weight--bold" target='_blank'>{tooltipText}</a>
                </div>
            </div>
        </div>

        <div className="onb_stps_ctr border_radius border_radius--default pt-3 pb-4 mb-md-5 mb-4">
            <div className="onb_stps">

                <div className="onb_stp onb_stp_done">
                    <div className="onb_stp_no"><span><img src="https://content.harstatic.com/media/icons/checkmark_filled.svg" /></span></div>
                    <div className="onb_stp_txt">Welcome</div>
                </div>

                <div className="onb_stp  active">
                    <div className="onb_stp_no"><span>2</span></div>
                    <div className="onb_stp_txt">Getting Started</div>
                </div>
                
                <div className="onb_stp">
                    <div className="onb_stp_no"><span>3</span></div>
                    <div className="onb_stp_txt">Connect</div>
                </div>
                
                <div className="onb_stp">
                    <div className="onb_stp_no"><span>4</span></div>
                    <div className="onb_stp_txt">Import</div>
                </div>

                <div className="onb_stp">
                    <div className="onb_stp_no"><span>5</span></div>
                    <div className="onb_stp_txt">Done</div>
                </div>
            </div>
        </div>
        
        <PrimaryButton size='large' className="mr-4 d-inline-block white_space_break" loading={next.loading} onClick={() => next()}>Let us connect with your email provider </PrimaryButton>
        <OrdinaryButton size="large" loading={next.loading} onClick={() => next(true)}>{labels.skipLabel}</OrdinaryButton>
    </>)
}