import { useRef, useState } from 'react';
import { ISelectWithSearchOptions, ISelectWithSearchProps } from './SelectWithSearch.def';
import './SelectWithSearch.scss';
import { checkmarkCircleOutline } from 'ionicons/icons'
import { IonButton, IonButtons, IonContent, IonIcon, IonModal, IonToolbar } from '@ionic/react';

export const SelectWithSearchApp = (props: ISelectWithSearchProps): JSX.Element => {
    const { title, options, selectedValue, multiple, autoOpen, placeholder, emptyOption, label, search, onSelect, onSearch, onRemove, onClose } = props
    const isMultiple = multiple;
    const dropDownElem = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLInputElement>(null)
    const [internalOptions, setInternalOptions] = useState<ISelectWithSearchOptions[] | undefined>(emptyOption ? [{ label: emptyOption, value: '' }, ...options ?? []] : options)
    const [timer, setTimer] = useState(0)
    const [loading, setLoading] = useState(false)
    const clickHandler = (obj: ISelectWithSearchOptions) => {
        if (isMultiple) {
            if (selectedValue?.includes(obj.value)) {
                onRemove?.(obj.value)
            } else {
                onSelect?.(obj);
            }
        } else {
            onSelect?.(obj);
            dropDownElem.current?.click()
        }
    }

    const searchHandler = (e: any) => {
        let query = e.target.value.toLowerCase()
        if (search) {
            e.preventDefault()
            if (query.length < 3) {
                return
            }
            if (timer) {
                clearTimeout(timer)
            }
            setLoading(true)
            setTimer(setTimeout(() => {
                search(query).then((res) => setInternalOptions(res))
                setLoading(false)
            }, 800))

            return () => {
                clearTimeout(timer);
            };
        } else {
            var searchResult = options?.filter((obj: any) => obj.label.toLowerCase().indexOf(query) >= 0 ? true : false)
            setInternalOptions(searchResult)
            onSearch?.()
            return
        }
    }

    const isSeletected = (val: ISelectWithSearchOptions) => {

        if (isMultiple && selectedValue && typeof selectedValue === 'object') {
            return selectedValue?.find((option) => option === val.value) ? true : false;
        } else {
            return options?.find((option) => option.value === selectedValue && option.value === val.value) ? true : false;
        }
    }
    /*
    const getLabel = (val: string) => {
        return options?.filter((option) => option.value === val)[0]?.label;
    }*/

    const moveCursorToSpot = () => {
        const timeOut = setTimeout(() => {
            textRef?.current?.focus()
        }, 500)
        return () => {
            clearTimeout(timeOut);
        };
    }
    return (
        <IonModal id="example-modal" isOpen={autoOpen} trigger="type-modal"
            initialBreakpoint={0.90}
            handleBehavior="cycle"
            backdropDismiss={true}
            onIonModalDidDismiss={() => {
                if (search) {
                    setInternalOptions([])
                } else {
                    setInternalOptions(options)
                }
                if (!!textRef.current?.value) {
                    textRef.current.value = ''
                }



                onClose?.()
            }}
            onIonModalWillPresent={() => {
                moveCursorToSpot()
            }}


        >
            <IonToolbar className='border-top'>
                <div slot='start' className='font_size--medium font_weight--bold ml-4' >{title}</div>
                <div className='font_size--large font_weight--semi_bold py-3 pl-2 ml-3'></div>
                <IonButtons slot="end">
                    <IonButton color="dark" onClick={() => {
                        props.onClose?.()
                    }}><img src="https://content.harstatic.com/media/icons/close_black.svg" /></IonButton>
                </IonButtons>
            </IonToolbar>
            <div className='toolbar-container border-bottom px-4'>
                {!isMultiple &&
                    <div className='font_size--medium pt-3 pl-3 '>
                        {selectedValue ? `This field has been assigned to "` + label + `"` : ' This field has no assigned value.'}
                    </div>
                }
                <div className="add_pipeline_item__content ">
                    <label htmlFor="SearchKeyWord" className="sr-only"></label>
                    <input ref={textRef} type="text" className="form-control" id="SearchKeyWord" aria-describedby="SearchKeyWord" placeholder={placeholder ? placeholder : `Search Fields`} title={title} onKeyUp={searchHandler} />
                </div>
            </div>

            <IonContent className="ioncontentPB50" scrollY={true}>
                <div className="ion-padding bg-white p-3">
                    <div className="add_pipeline_item__conten  ">
                        <div className="results jprScroll">
                            {loading ?
                                <LoadingState />
                                :
                                <>
                                    {internalOptions && internalOptions.length > 0 ? internalOptions?.map((option, index) =>
                                        <a onClick={() => clickHandler(option)}>
                                            <div key={index} className={`row py-3 border-bottom ${internalOptions.length === index && `mb-3`}`}>
                                                <div className='col font_size--large pr-2'>{option.label}</div>
                                                {isSeletected(option) &&
                                                    <div className="selected ml-auto pr-4"><IonIcon className='w-24px h-24px' icon={checkmarkCircleOutline}></IonIcon></div>
                                                }
                                            </div>
                                        </a>
                                    )
                                        :
                                        <>
                                            {!props.noErrorMessage &&
                                                <div className=' font_size--medium'>No fields founds</div>
                                            }
                                        </>
                                    }

                                </>
                            }

                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal >
    );
}

const LoadingState = () => {
    return (
        <>
            <div className={`p-3 border-bottom loadingState`}>
                <div className='row'>
                    <div className=" col agent_signature__loading__textline min-w-72px h-20px mr-3 mt-2"></div>
                    <div className=" col ml-auto agent_signature__photo loading"></div>
                </div>
            </div>
            <div className={`p-3 border-bottom loadingState`}>
                <div className='row'>
                    <div className=" col agent_signature__loading__textline min-w-72px h-20px mr-3 mt-2"></div>
                    <div className=" col ml-auto agent_signature__photo loading"></div>
                </div>
            </div>
            <div className={`p-3 border-bottom loadingState`}>
                <div className='row'>
                    <div className=" col agent_signature__loading__textline min-w-72px h-20px mr-3 mt-2"></div>
                    <div className=" col ml-auto agent_signature__photo loading"></div>
                </div>
            </div>

            <div className={`p-3 border-bottom loadingState`}>
                <div className='row'>
                    <div className=" col agent_signature__loading__textline min-w-72px h-20px mr-3 mt-2"></div>
                    <div className=" col ml-auto agent_signature__photo loading"></div>
                </div>
            </div>
        </>
    )
}
