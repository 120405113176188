export const Empty = (_props:{}) =>
(
            <div className="card_empty">
                <div className="card_empty__content pt-sm-5 pb-sm-5 mb-sm-5 mt-sm-5 max-w-320px">
                    <div className="mb-3 d-sm-block d-none"><img className="w-85px" src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
                    <div className="mb-3 d-sm-none d-block"><img className="w-50px" src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
                    <div className="pt-3 pb-2">
                        You currently don’t have Recommended Action items today
                    </div>
             </div>
            </div>
);