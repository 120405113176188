import { IScoreOptionsMultiProps, IScoreOptionsSingleProps } from "./ScoreOptions.def";
import { ScoreOptionsDesktop } from "./ScoreOptions.desktop";
import { ScoreOptionsApp } from "./ScoreOptions.app";
import { useResponsiveChoose } from "scripts/hooks/general";

export function ScoreOptions(props:IScoreOptionsSingleProps)
{
  const ScoreOptionsPlatform = useResponsiveChoose(ScoreOptionsDesktop, ScoreOptionsApp);
  return (
      <ScoreOptionsPlatform {...props} />
  );
}

export namespace ScoreOptions
{
    export function Multi(props:IScoreOptionsMultiProps)
    {
        const ScoreOptionsPlatform = useResponsiveChoose(ScoreOptionsDesktop.Multi, ScoreOptionsApp.Multi);
        return (
            <ScoreOptionsPlatform {...props} />
        );
    }
}
