import { useComponent } from "scripts/context";
import { IAddressesSectionProps } from "./AddressesSection.def";
import { AddressesSectionDesktop } from "./AddressesSection.desktop";
import { AddressesSectionApp } from "./AddressesSection.app";

export const AddressesSection = (props: IAddressesSectionProps): JSX.Element =>
{
  const AddressesSectionPlatform = useComponent(AddressesSectionDesktop, AddressesSectionApp);
  return (
      <AddressesSectionPlatform {...props} />
  );
}
