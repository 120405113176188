import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace UpdateNotesApi {
    export const url = "/contacts/:contact_id/notes/:notes_id";
    export const method = "PUT";
    export const defaultError = "Contact note is successfully updated";
    export const operations = apiOperations(UpdateNotesApi);
    export const scope = new class UpdateNotesApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        contact_id: string,
        notes_id: string,
        notes?:string,
    }

    export interface Response {

    }
}

export const fetchUpdateNotesService = forwardOperation(UpdateNotesApi.operations, 'fetch');
export const useFetchUpdateNotesService = forwardOperation(UpdateNotesApi.operations, 'useFetch');
export const useUpdateNotesService = forwardOperation(UpdateNotesApi.operations, 'useService');