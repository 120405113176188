import { IContactInsightData, IContactInsightSectionProps } from './ContactInsightSection.def';
import './ContactInsightSection.scss';
import { DropDown } from 'views/forms/dropdowns/DropDown';
import { useEffect, useState } from 'react';
import { FormLabel } from "react-bootstrap";
import {  OverlayTrigger  } from 'react-bootstrap';
import { popover_score,popover_tags } from './components/InfoOverlayTrigger';
import { TagsTextbox } from 'views/forms/textboxes/TagsTextbox';
import { ScoreOptions } from '../ScoreOptions';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { ITag } from 'views/forms/textboxes/TagsTextbox/TagsTextbox.def';
import { useParams } from 'react-router';
import { getLongvalue, useFetchLookupsService } from 'services/general/lookups';
import {Loader} from './components/Loader';
import { QuickEditPopup } from '../component/QuickEditPopup';

export const ContactInsightSectionDesktop = (props: IContactInsightSectionProps): JSX.Element => {

    const { initialValues, onChange,isEdit } = props;
    const [quickEditPopup, setQuickEditPopup] = useState({show:false,contact:{},tab:"Stages"});
    const [, setScoreType] = useState({ score: initialValues?.scoreType, error: '' });
    const [pipelineType, setPipelineType] = useState({ pipeline: initialValues?.pipelineType, error: '' });
    const [sourceType, setSourceType] = useState({ source: initialValues?.sourceType ?? '', error: '' });
    const [leadType, setLeadType] = useState({ leadType: initialValues?.leadType ?? '', error: '' });
    const [, setTags] = useState({ tags: initialValues?.tags ?? [], error: '' });
    const [form, setContactInsightForm] = useState<IContactInsightData>(initialValues ?? {scoreType:""});
    const [, notifyChange] = useValidateConsumer(props.validate, validate);
    const [prevInformation, setPrevInformation] = useState<IContactInsightData>({scoreType:""});
    const [contactId, getId] = useState(false);
    const { id } = useParams<{ id: string }>();

    const [lookupData] = useFetchLookupsService(['contact_source', 'pipeline_stage','contact_lead_type']);

    useEffect(() => {
        if (id) { getId(Object.keys(id!).length > 0 ? true : false) };
        setScoreType({ score: initialValues?.scoreType, error: '' });
        setPipelineType({ pipeline: initialValues?.pipelineType ?? '', error: '' });
        setSourceType({ source: initialValues?.sourceType ?? '', error: '' });
        setLeadType({ leadType: initialValues?.leadType ?? '', error: '' });
        setTags({ tags: initialValues?.tags ?? [], error: '' });
        setContactInsightForm(initialValues ?? {scoreType:""});
        setPrevInformation(initialValues ?? {scoreType:""})

    }, [initialValues, id]);

    const stageClassName = (value?: string) => {
        if (value === '10') { return 'stage__new' }
        if (value === '20') { return 'stage__qualified' }
       // if (value === '60') { return 'stage__undercontract' }
        if (value === '30') { return 'stage__cold' }
        if (value === '40') { return 'stage__warm' }
        if (value === '50') { return 'stage__hot' }
        if (value === '60') { return 'stage__undercontract' }
        if (value === '100') { return 'stage__pastclient' }

        return 'title__label__common';
    }

    const scoreClass = prevInformation?.scoreType ? `score_label--${prevInformation?.scoreType?.toLowerCase()}` : 'score_label--n';
    const stagesClass = `stage ${stageClassName(prevInformation?.pipelineType)}`
    // prevInformation?.pipelineType === '60' ? 'stage stage__undercontract' : prevInformation?.pipelineType === '' || prevInformation?.pipelineType === 'None' ? 'stage title__label__common' : prevInformation?.pipelineType === '100' ? 'stage stage__pastclient' : 



    function validate() {
        // No validation so far
        return true;
    }

    function selectPipeline(pipeline: string) {
        setPipelineType({ pipeline, error: '' });
        updateForm({ ...form, pipelineType: pipeline });
    }

    const selectScore = (val?: string) => {

        setScoreType({ score: val, error: '' });
        updateForm({ ...form, scoreType: val??"" });
    }

    function selectSource(source: string) {
        setSourceType({ source, error: '' })
        updateForm({ ...form, sourceType: source });
    }

    function selectLeadType(leadType:string) {
        setLeadType({ leadType, error: '' });
        updateForm({ ...form, leadType });
    }

    function selectTags(tags: ITag[]) {
        setTags({ tags, error: '' });
        updateForm({ ...form, tags });
    }

    function updateForm(data: IContactInsightData) {
        setContactInsightForm(data);
        onChange?.(data);
        notifyChange();
    }
    const contactObj={id,score:prevInformation.scoreType,sources:prevInformation.sourceType,pipeline_status:prevInformation.pipelineType,tags:prevInformation.tags};
    return (
        <>
            {
                props.loading || lookupData.loading || lookupData.error ?<Loader/>
                    :
                    <div className="mb-5 p-0 card">
                        {!isEdit&&contactId&& initialValues ?
                            <div className="p-3 show_over">
                                <div className='p-3'>
                                    <div className="row pb-3" >
                                        <div className="col align-self-center">
                                            <h3 tabIndex={0}>Contact Insight</h3>
                                        </div>
                                       {/** <div className="col-auto text-right"><button className="btn btn--white show_over_item animate animate__fadeIn" type="button" onClick={editContactInsight} >Edit</button></div>*/} 
                                    </div >
                                    <div className="row small-gutters">

                                        <div className="col-auto"   onClick={()=> setQuickEditPopup({show:true,contact:contactObj,tab:"Score"})}>
                                            <div className={`score_label score_label__large ${scoreClass}`}>
                                                <span className="score_label__large__alpha">{prevInformation?.scoreType && prevInformation?.scoreType !== '' ? prevInformation.scoreType : 'N/A'}</span>
                                                <span className="score_label__large__text">Score </span>
                                            </div>
                                        </div>

                                        <div className="col">
                                            {/* <div className="border-bottom pb-3 mb-3 font_size--medium">
                                                <div className="input_label__bold">Contact Type</div>
                                                {prevInformation?.sourceType !== '' ? prevInformation?.sourceType : 'N/A'}

                                            </div> */}

                                            <div className="border-bottom pb-3 mb-3 pt-3"   onClick={()=> setQuickEditPopup({show:true,contact:contactObj,tab:"Stages"})}>
                                                <div className="input_label__bold">Stages on Pipeline</div>
                                                <div>
                                                    <div className={stagesClass} >
                                                        {getLongvalue(lookupData.response.pipeline_stage, prevInformation?.pipelineType, 'None')}
                                                        {/* {prevInformation?.pipelineType !== '' ? prevInformation.pipelineType : 'N/A'} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-bottom pb-3 mb-3 pt-3"   onClick={()=> setQuickEditPopup({show:true,contact:contactObj,tab:"Source"})}>
                                                <div className="input_label__bold">Sources</div>
                                                <div className="title__label title__label__common">
                                                    {getLongvalue(lookupData.response.contact_source, prevInformation?.sourceType, 'None')}
                                                </div>
                                            </div>

                                            <div className="border-bottom pb-3 mb-3 pt-3">
                                                <div className="input_label__bold">Lead Type</div>
                                                <div className="title__label title__label__common">
                                                    {getLongvalue(lookupData.response.contact_lead_type, prevInformation?.leadType, 'None')}
                                                </div>
                                            </div>

                                            <div className="pb-3 mb-3 pt-3"onClick={()=> setQuickEditPopup({show:true,contact:contactObj,tab:"Tags"})}>
                                                <div className="input_label__bold">Tags <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                                                {prevInformation?.tags?.map((item) => {
                                                    return (
                                                        <div key={item.id} className="title__label title__label__common">{item.name}</div>
                                                    )
                                                })}

                                            </div>

                                            {/* <div className="warning">
                                                <div className="warning__icon"><img src="https://content.harstatic.com/media/icons/event/clock.svg" /></div>
                                                <div className="warning__text">
                                                    <span className="font_weight--bold">Last contact two months ago</span>
                                                </div>
                                            </div> */}

                                        </div>

                                    </div>
                                </div>

                            </div>

                            :

                            <div className='p-3'>
                                <div className="p-3">
                                    <div className="row pb-3">
                                        <div className="col">
                                            <h3 tabIndex={0}>Contact Insight</h3>
                                        </div>
                                       {/**  {id &&
                                            <div className="col-auto text-right">
                                                <button className="btn btn--shapeless btn--small mr-2" type="button" onClick={discardContact}>Discard</button>
                                                <button className="btn btn--primary btn--small" type="button" onClick={saveContact}>Save</button>
                                            </div>}*/}
                                    </div>
                                    {id ?
                             <div className='row small-gutters'>                                     
                 
                               <div className='col-12'>
                               <FormLabel>Score <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></FormLabel>
                                    <ScoreOptions label="Score" initialSelected={form?.scoreType} onScoreChange={selectScore}  />
                                           </div>  {/**  <ScoreOptions dropDown={!contactId && initialValues ? true : false} label="Score" initialSelected={form?.scoreType} onScoreChange={selectScore} />*/}
                                                    
                                            <div className='col'>
                                                <div className="mb-4">
                                                    <FormLabel>Stage on Pipeline </FormLabel>
                                                    <DropDown className="custom-select_large" value={form?.pipelineType} error={pipelineType.error ?? false} onChange={(e) => selectPipeline(e.target.value)}>
                                                        {lookupData.response.pipeline_stage.items.map((item, index) =>
                                                            <option key={index} value={item.short_value}>{item.long_value}</option>
                                                        )}

                                                    </DropDown>
                                                </div>

                                                <div className="mb-4">
                                                    <FormLabel>Sources</FormLabel>
                                                    <DropDown className="custom-select_large" value={form?.sourceType} error={sourceType.error ?? false} onChange={(e) => selectSource(e.target.value)} >

                                                        {lookupData.response.contact_source.items.map((item, index) =>
                                                            <option key={index} value={item.short_value}>{item.long_value}</option>
                                                        )}

                                                    </DropDown>
                                                </div>

                                                <div className='mb-4'>
                                                    <FormLabel>Lead Type</FormLabel>
                                                    <DropDown className="custom-select_large" value={form.leadType} error={leadType.error ?? false} onChange={(e) => selectLeadType(e.target.value)}>
                                                        {lookupData.response.contact_lead_type.items.map((item, index) =>
                                                            <option key={index} value={item.short_value}>{item.long_value}</option>
                                                        )}
                                                    </DropDown>
                                                </div>
                                                
                                                <FormLabel>Tags <OverlayTrigger trigger="hover" placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></FormLabel>
                                                <TagsTextbox initialTags={form?.tags} onTagsChange={selectTags} maxlength={15}  />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <FormLabel>Score <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></FormLabel>
                                            <ScoreOptions dropDown={contactId && initialValues ? true : false} label="Score" initialSelected={form?.scoreType} onScoreChange={selectScore} />
                                            <div className="mb-4">
                                                <FormLabel>Stage on Pipeline</FormLabel>
                                                <DropDown className="custom-select_large" value={form?.pipelineType} error={pipelineType.error ?? false} onChange={(e) => selectPipeline(e.target.value)}>
                                                    {lookupData.response.pipeline_stage.items.map((item, index) =>
                                                        <option key={index} value={item.short_value}>{item.long_value}</option>
                                                    )}
                                                </DropDown>
                                            </div>
                                            
                                            <div className="mb-4">
                                                <FormLabel>Sources</FormLabel>
                                                <DropDown className="custom-select_large" value={form?.sourceType} error={sourceType.error ?? false} onChange={(e) => selectSource(e.target.value)} >
                                                    {lookupData.response.contact_source.items.map((item, index) =>
                                                        <option key={index} value={item.short_value}>{item.long_value}</option>
                                                    )}
                                                </DropDown>
                                            </div>
                                            
                                            <div className='mb-4'>
                                                <FormLabel>Lead Type</FormLabel>
                                                <DropDown className="custom-select_large" value={form.leadType} error={leadType.error ?? false} onChange={(e) => selectLeadType(e.target.value)}>
                                                    {lookupData.response.contact_lead_type.items.map((item, index) =>
                                                        <option key={index} value={item.short_value}>{item.long_value}</option>
                                                    )}
                                                </DropDown>
                                            </div>

                                            
                                            <FormLabel>Tags <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></FormLabel>
                                            <TagsTextbox initialTags={form?.tags} onTagsChange={selectTags} maxlength={15}/>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </div>
            }
             <QuickEditPopup 
                    open={quickEditPopup.show}
                    contact={quickEditPopup.contact}
                    selectedTab={quickEditPopup.tab}
                    close={() => setQuickEditPopup({show:false,contact:{},tab:""})}
                    onAssignment={()=>window.location.reload()}
                />
        </>
    );
}
