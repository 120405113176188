import { useEffect, useRef, useState } from 'react';
import { IImportPhoneContactsList, IImportPhoneContactsPopUpProps } from './ImportPhoneContactsPopUp.def';
import './ImportPhoneContactsPopUp.scss';
import { Contacts, ContactPayload, PostalAddressType } from '@capacitor-community/contacts';
import { IonAlert, IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { Avatar } from 'views/contacts/Avatar';
import { OrdinaryButton, PrimaryButton, ProminentButton } from 'views/forms/buttons/Button';
import { BulkImportApi, useBulkImportService } from 'services/contacts/bulkImport';
import { IHookData, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { ContactTitle } from 'views/general/ContactTitle';
import { displayMessage } from 'scripts/hooks/feedbacks';

export const ImportPhoneContactsPopUp = (props: IImportPhoneContactsPopUpProps): JSX.Element => {
    const [showList, setShowList] = useState(false)
    const [isImporting, startImporting] = useState(false)
    const [showAlert, toogleAlert] = useState(false)
    const [totalContacts, setTotalContacts] = useState<number>(0)
    const [display, setDisplay] = useState<number>(0)
    const [contacts, setContacts] = useState<ContactPayload[]>()
    const [allcontacts, setAllContacts] = useState<ContactPayload[]>()
    const DeniedMessage = 'To Allow HAR CRM read your Contacts. Please go to Settings  >  Privacy & Security  >  Contacts > Enable HAR CRM.'
    const retrieveList = async () => {
        startImporting(true)
        const projection = {
            // Specify which fields should be retrieved.
            name: true,
            emails: true,
            phones: true,
            birthday: true,
            postalAddresses: true,
            image: true,
            organization: true,
            urls: true,

        };
        const result = await Contacts.getContacts({
            projection,
        });

        result.contacts.sort((a: any, b: any) => a.name?.display?.localeCompare(b.name?.display ?? ''))
        setAllContacts(result.contacts)
        setTotalContacts(result.contacts.length)
        setDisplay(50)
        setContacts(result.contacts.slice(0, 50))

    };

    const getPermission = async () => {
        const permission = await Contacts.requestPermissions()
        if (permission.contacts === 'granted') {
            retrieveList()
        } else {
            props.onPermissionDenied?.(DeniedMessage)
            toogleAlert(true)

        }
    }




    const loadMore = () => {
        if (allcontacts && contacts) {
            setContacts([...contacts, ...allcontacts.slice(display, display + 50)])
            setDisplay(display + 50)
        }
    }

    useEffect(() => {
        if (contacts) {
            setShowList(true)
            startImporting(false)
        }

    }, [contacts])




    return (
        <>
            <IonAlert
                isOpen={showAlert}
                header="Permission Denied"
                message={DeniedMessage}
                buttons={['Ok']}
                onDidDismiss={() => toogleAlert(false)}
            ></IonAlert >
            <ShowResult show={showList} contacts={contacts} importing={isImporting} onClose={() => setShowList(false)} display={display} total={totalContacts} setContact={(list, failedIds) => {
                let alltempContact: ContactPayload[] = []
                if (failedIds) {
                    let failedContacts = allcontacts?.filter((c) => {
                        if (failedIds?.find((f) => f === c.contactId)) {
                            return c
                        } else {
                            return
                        }
                    })
                    if (failedContacts) {
                        let contacts = allcontacts?.filter((c) => !failedIds.includes(c.contactId))
                        alltempContact = [...contacts ?? [], ...failedContacts]
                        setAllContacts(alltempContact)
                    }
                }
                if (allcontacts) {
                    if (list && list.length > 0) {
                        setContacts([...list, ...allcontacts?.slice(display, display + 50)])
                    } else {
                        setContacts([...allcontacts?.slice(display, display + 50)])
                    }
                    setDisplay(display + 50)
                }
            }} loadMore={loadMore} />
            <div>
                <div className=" text-center py-2">
                    <PrimaryButton loading={isImporting || props.loading} disabled={isImporting || props.disabled} className="btn btn--primary btn--medium" onClick={() => getPermission()} >Import</PrimaryButton>
                </div>
            </div>
        </>
    );
}


const ShowResult = (props: IImportPhoneContactsList) => {
    const { show, contacts, display, total, onClose, loadMore, setContact } = props
    const [selected, setSelected] = useState<ContactPayload[]>()
    const [importService, importServiceAction] = useBulkImportService()
    const [isPrevent, setIsPrevent] = useState(true);
    const [showImporting, setImporting] = useState(false);
    const [seeReport, viewReport] = useState(false);
    const maxSelection = 50

    const toggleCheckBoxes = (contact: ContactPayload) => {
        if (selected?.find((c) => c.contactId == contact.contactId)) {
            setSelected(selected?.filter((c) => c.contactId !== contact.contactId))
        } else {
            if (selected && selected.length === maxSelection) {
                displayMessage('You can select up to 50 contacts at a time.')
                return
            }
            setSelected([...selected ?? [], contact])
        }
    }

    const addressMap = (address: PostalAddressType) => {
        if (address === 'home') {
            return 'H'
        }
        if (address === 'work') {
            return 'W'
        }
        if (address === 'other') {
            return 'O'
        }
        if (address === 'custom') {
            return 'None'
        }
        return 'O'

    }

    const doImport = () => {
        const contacts = selected?.map((c) => {
            return {
                first_name: c.name?.given ?? '',
                last_name: c.name?.family ?? '',
                ref_id: c.contactId ?? '',
                emails: c.emails?.map((e, i) => {
                    return {
                        email: e.address ?? '',
                        preferred: i === 0 ? '1' : '0'
                    }
                }),
                birthday: c.birthday ? c.birthday.year + '-' + c.birthday.month + '-' + c.birthday.day : '',
                company: c.organization?.company ?? '',
                addresses: c.postalAddresses?.map((a) => {
                    return {
                        address1: a.street ?? '',
                        address2: a.neighborhood ?? '',
                        address_type: a.type ? addressMap(a.type) : 'None',
                        city: a.city ?? '',
                        zipcode: a.postcode ?? '',
                        state: a.region ?? '',
                        country: a.country ?? ''
                    }
                }),
                links: c.urls?.map((l) => {
                    return {
                        name: '',
                        url: l ?? ''
                    }
                }),
                phones: c.phones?.map((p, index) => {
                    return {
                        phone: p.number ?? '',
                        type: p.type.substring(0, 1).toUpperCase(),
                        preferred: index === 0 ? '1' : '0'
                    }
                })
            }
        })

        importServiceAction.fetch({ prevent_update: isPrevent ? "1" : "0", contacts: contacts })
    }

    const selectAll = (selAll: boolean) => {
        if (contacts && contacts.length === 0) {
            return
        }
        if (selAll) {
            let sel: any = []
            contacts?.map((contact, index: number) => {
                if (index < maxSelection) {
                    sel.push(contact)

                }
            })
            setSelected(sel)
        } else {
            setSelected([])
        }
    }

    const isSelectAll = (): boolean => {
        if (selected) {
            if (contacts && contacts.length <= maxSelection) {
                return contacts.length === selected.length
            } else {
                return selected.length === maxSelection
            }
        } else {
            return false
        }
    }



    const cleanUp = (errorlist: BulkImportApi.IBulkContactResponseError[]) => {
        let remainingContacts = contacts?.filter((c) => {
            if (selected?.find((s) => s.contactId === c.contactId)) {
                return false
            } else {
                return c
            }
        })
        let failedIds = errorlist.map((e) => e.ref_id)
        if (remainingContacts) {
        }
        setContact(remainingContacts ?? [], failedIds)

        setSelected(undefined)
    }

    useServiceDidSucceeded(importService, (response) => {
        viewReport(true)
        setImporting(false)
        cleanUp(response.errors_list)
    })

    return (
        <>
            <Report result={importService} show={seeReport} onClose={() => {
                viewReport(false)
            }} />
            <IonModal isOpen={showImporting} trigger="open-modal" className="modal__autoheight" initialBreakpoint={1} breakpoints={[0, 1]} onIonModalDidDismiss={() => setImporting(false)} backdropDismiss={false}>
                <div className="ion-padding">
                    <h2 tabIndex={0} className="h2--simple mb-4 mt-3 text-center">Import</h2>
                    <div className="mb-5 p-2 bg_color_snow_white">
                        <div className="mb-4 pt-4 font_size--large_extra font_weight--bold text-center algin-self-center ">
                            You have selected <span className="font_weight--semi_bold">{selected?.length}</span> to import.</div>
                        <div className="font_size--medium font_weight--semi_bold m-auto text-center algin-self-center color_auxiliary mb-4">
                            <div className='d-flex cursor--hand justify-content-center  ImpContacts-checkbox' onClick={() => setIsPrevent(!isPrevent)}>
                                <IonCheckbox id="prevent-checkbox" slot="start" checked={isPrevent}  ></IonCheckbox>
                                <div className='ml-2'  >Prevent updates on existing contacts</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 small-gutters">
                        <div className="col">
                            <OrdinaryButton onClick={() => setImporting(false)}>Cancel</OrdinaryButton>
                        </div>
                        <div className="col text-right">
                            <PrimaryButton className="btn btn--large" disabled={importService.loading} loading={importService.loading} onClick={doImport}>Confirm</PrimaryButton>
                        </div>
                    </div>
                </div>

            </IonModal >
            <IonModal className={`bg-white`} isOpen={show} canDismiss={true} backdropDismiss={false}>
                <IonHeader className="ion-padding-md ion-no-border border-bottom">

                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color={"blue"} onClick={() => onClose?.()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
                        </IonButtons>
                        <IonTitle className="modal-title">Import</IonTitle>
                        {contacts && contacts.length > 0 &&
                            <IonButtons slot="end">
                                <IonButton color={"blue"} onClick={() => selectAll(isSelectAll() ? false : true)}> {isSelectAll() ? `Unselect All` : contacts && contacts.length >= 50 ? `Select 50` : `Select All`}</IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>
                <IonContent className="bg-white phoneContactsList">
                    {props.importing && <LoadingState />}
                    {!props.importing && contacts?.length === 0 && <EmptyState />}
                    {!props.importing && contacts && contacts?.length > 0 &&
                        <>
                            <IonList>
                                {contacts?.map((contact, key) =>
                                    <IonItem key={key} >
                                        <input type="checkbox" className="select-contacts-all" onChange={() => toggleCheckBoxes(contact)} checked={selected?.find((c) => c.contactId === contact.contactId) ? true : false} readOnly slot="start" />
                                        <IonLabel onClick={() => toggleCheckBoxes(contact)}>
                                            <div className="card--contact pt-3 pb-2">
                                                <div className="card--contact__image">
                                                    <Avatar
                                                        id={'0'}
                                                        firstName={contact?.name?.given ? contact?.name?.given : ''}
                                                        photo=''
                                                        lastName={contact?.name?.family ? contact?.name?.family : ''}
                                                        responsive={true}
                                                    />
                                                </div>
                                                <div className="card--contact__cont">
                                                    <div className="card--contact__cont__header flex-column">
                                                        <div className="card--contact__cont__header_name" >{contact.name?.display}</div>
                                                        {contact.emails?.[0]?.address &&
                                                            <div className=" font_size--small color_auxiliary text-truncate pb-1 font-weight-normal">{contact.emails?.[0]?.address}</div>
                                                        }
                                                        {contact.phones?.[0]?.number &&
                                                            <div className=" font_size--small color_auxiliary text-truncate pb-1 font-weight-normal">{contact.phones?.[0]?.number}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </IonLabel>
                                    </IonItem>


                                )}
                            </IonList>
                            <div className="p-3 position-fixed bg_color_snow_white  w-100 border-color--cloudy-sky-light top-auto bottom--10px lef-0px right-0px zindex-111 opacity-95pr">
                                <ProminentButton size='large' className="btn btn--large  w-100" disabled={selected?.length === 0} onClick={() => setImporting(true)}>Import Selected Contacts</ProminentButton>
                            </div>
                            {contacts && display < total &&
                                <IonInfiniteScroll

                                    onIonInfinite={(ev) => {
                                        loadMore();
                                        setTimeout(() => ev.target.complete(), 500);
                                    }}
                                >
                                    <IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            }
                        </>
                    }
                </IonContent>

            </IonModal >
        </>
    )
}
const LoadingState = () => {
    return (
        <div>
            <IonList>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-24px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text h-22px w-52px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text h-22px w-22px"></div></div>
                                <div className="col-auto"><div className="loading_text h-22px w-52px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
}


const EmptyState = () => {
    return (
        <div>
            <IonList>
                <IonItem>
                    <div className="card--contact pt-4">
                        <div className="card--contact__cont">
                            <div className="card--contact__cont__header flex-column">
                                <div className="card--contact__cont__header_name" >There are no contacts to import</div>

                            </div>
                        </div>
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
}

const Report = (props: { result: IHookData<BulkImportApi.Response, unknown>, show: boolean, onClose: () => void }) => {
    const { result, show, onClose } = props
    const [viewError, setShowError] = useState(false)
    const [error, setError] = useState<BulkImportApi.IBulkContactResponseError>()
    const ref = useRef<HTMLIonModalElement>(null)

    return (
        <>
            <ErrorDetailPopup error={error ?? undefined} show={viewError} onClose={() => setShowError(false)} />
            <IonModal ref={ref} className={`bg-white`} isOpen={show} backdropDismiss={false} onIonModalWillDismiss={() => onClose()}>
                <IonHeader className="ion-padding-md ion-no-border border-bottom">

                    <IonToolbar>
                        <IonTitle className="modal-title">Import Contacts</IonTitle>

                        <IonButtons slot="start">
                            <IonButton color={"blue"} onClick={() => ref?.current?.dismiss()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <div></div>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="bg-white">
                    <div className="pt-5 ml-auto mr-auto">
                        {!result.loading && result.response?.total_success !== "0" &&

                            <div className="d-flex px-2  pb-3">
                                <img className='w-16px' src="https://content.harstatic.com/media/icons/check_green_2.svg" />
                                <div className='color_available align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_success} contact{Number(result.response?.total_success) > 1 ? `s` : ``} successfully added.
                                </div>
                            </div>
                        }

                        {!result.loading && result.response?.total_errors !== "0" &&

                            <div className="d-flex px-2 pb-3">
                                <img className=' w-16px' src="https://content.harstatic.com/media/icons/icon-warning.svg" />
                                <div className='color_sold align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_errors} contact{Number(result.response?.total_errors) > 1 ? `s` : ``} failed to import.
                                </div>
                            </div>
                        }
                        <div className='border-top mx-auto p-3'>
                            {result?.response?.total_errors !== "0" &&
                                <div className="pb-4 mb-4 pt-2">
                                    <div className="pt-3 ">
                                        {result?.response?.errors_list.map((contact, key: number) =>
                                            <div key={key} className={`message  bg_color_sold_light mb-2 w-100`}><ContactTitle contact={contact} />
                                                <span className='font_weight--bold'> Failed
                                                    {contact.errors.length > 0 && <a className='cursor--hand ml-2 font_size--small' onClick={() => {
                                                        setError(contact)
                                                        setShowError(true)
                                                    }}> view detail</a>}

                                                </span>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            }
                        </div>
                    </div >
                </IonContent>
            </IonModal >
        </>
    )

}


const ErrorDetailPopup = (props: { error?: BulkImportApi.IBulkContactResponseError, show: boolean, onClose: () => void }) => {
    const { error, show, onClose } = props
    return (
        <IonModal isOpen={show} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Error Detail</IonTitle>
                    <IonButtons slot="start">
                        <IonButton color={"blue"} onClick={onClose}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />  Back</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div className="contact_detail min-h-250px p-4">
                    <div className='d-flex border-bottom mb-3'>
                        <div className='font_weight--semi_bold font_size--large pb-3 text-truncate'>{<ContactTitle contact={error} />} </div>
                    </div>
                    {error?.errors?.map((e, key: number) =>
                        <ul className='row' key={key}>
                            <li className='ml-3 color_sold text-break'>
                                <div className='font_size--medium font_weight--semi_bold'>{e.reason}</div>
                                <div className='font_size--small'>{e.message}</div>


                            </li>
                        </ul>
                    )}
                </div>
            </IonContent>
        </IonModal >
    )

}
