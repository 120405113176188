import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities";
import { IEmailRecipient, IServerEmailRecipient } from './searchEmails';
import { joinWithSpace } from "scripts/helpers/texts";
import { SendEmailsApi } from "./sendEmails";

export namespace EmailDetailsApi {
    export const url = "/emails/:type/:id";
    export const method = "GET";
    export const defaultError = "Bad Request";
    export const operations = apiOperations(EmailDetailsApi);
    export const scope = new class EmailDetailsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<ServerResponse, string>>): Response {

            return {
                ...input.data,
                first_last_name: joinWithSpace(input.data.first_name, input.data.last_name),
                isLead: (input.data.isLead === '1'),
                attachments: input.data.attachments.map(x => ({
                    id: x.id,
                    container: x.container,
                    content_type: x.content_type,
                    file_name: x.file_name,
                    file_preview: x.file_preview,
                    file_size: x.file_size,
                    key: x.key
                })),
                recipients: input.data.recipients.map(x => ({
                    id: x.id,
                    first_name: x.first_name,
                    last_name: x.last_name,
                    first_last_name: joinWithSpace(x.first_name, x.last_name),
                    middle_name: x.middle_name,
                    email: x.email,
                    photo_url: x.photo_url,
                    score: x.score,
                }))
            }
        }
    }();

    export interface Request {
        type: string,
        id: string,
        renew?: string,
    }

    export interface ServerResponse {
        message: string,
        message_uid: string,
        attachments: SendEmailsApi.IEmailAttachment[],
        id: string,
        subject: string,
        read: string,
        short_message: string,
        date_tag: string,
        created_dt: string,
        from_address: string,
        reply_to_address: string,
        to_address: string,
        cc_address: string,
        folder?: string,
        bcc_address: string,
        contact_id: string,
        first_name: string,
        middle_name: string,
        last_name: string,
        display_name: string,
        photo_url: string,
        isLead: boolean,
        is_lead: string,
        recipients: IServerEmailRecipient[],
        recipients_cc?: string[],
        recipients_bcc?: string[],

    }

    export interface Response extends Omit<ServerResponse, 'recipients'> {
        first_last_name: string;
        recipients: IEmailRecipient[],
    }
}
export const fetchEmailDetailsService = forwardOperation(EmailDetailsApi.operations, 'fetch');
export const useFetchEmailDetailsService = forwardOperation(EmailDetailsApi.operations, 'useFetch');
export const useEmailDetailsService = forwardOperation(EmailDetailsApi.operations, 'useService');