import { isPlatform } from "@ionic/react";
import { AuthActions, AuthService } from "ionic-appauth";
import { CapacitorRequestor } from "./CapacitorService";
import { AxiosRequestor } from "./AxiosService";
import { CapacitorBrowser, CapacitorSecureStorage } from "ionic-appauth/lib/capacitor";
import { isProduction } from "scripts/application/settings";
import { lazy, lock } from "scripts/utilities/decorators";
import { Subscription, filter, switchMap, take } from "rxjs";
import { PromiseController } from "scripts/helpers/async";
import { history } from "scripts/router/history";
import { Storage } from "@ionic/storage";
import { platform } from "scripts/context";
import { wait } from "scripts/helpers/intervals";


export enum eLoginProvider
{
    Keycloak = 1,
    Cookie
}

export interface ILoginProviderOptions
{
    redirectLoginUrl:string;
    redirectLogoutUrl:string;
}

export interface IAuthentication
{
    token:string;
    authorization:string;
}

export abstract class LoginProvider
{ 
    private errorsListeners:Array<(error:unknown) => any>;
    public readonly options:ILoginProviderOptions;
    public get isExternal(): boolean { return true; }
    public get useCookies(): boolean { return false; }
    public get showRememberMe(): boolean { return false; }
    public get credentials(): RequestCredentials | undefined
    {
        return this.useCookies? 'include': undefined;
    }
    
    public constructor(options:ILoginProviderOptions)
    {
        this.errorsListeners = [];
        this.options = options;
    }

    public abstract signIn(nextUrl?:string): Promise<void>;
    public abstract signOut(nextUrl?:string): Promise<void>;
    public abstract isAuthenticated(): PromiseController<boolean>;
    public abstract getToken(): PromiseController<string | undefined>;
    public abstract handleLoginCallback(token?:string): PromiseController<void>;
    public abstract handleLogoutCallback(): PromiseController<void>;
    protected abstract getAuthorizationHeader(token:string): string;

    public getAuthentication(): PromiseController<IAuthentication | undefined>
    {
        return this.getToken().then(token => {
            if(!token) { return undefined; }
            const authorization = this.getAuthorizationHeader(token);
            return { token, authorization };
        });
    }

    public getRedirectLoginUrl(nextUrl?:string): string
    {
        return this.getRedirectUrl(this.options.redirectLoginUrl, nextUrl);
    }

    public getRedirectLogoutUrl(nextUrl?:string): string
    {
        return this.getRedirectUrl(this.options.redirectLogoutUrl, nextUrl);
    }

    protected handleError(error:unknown): void
    {
        this.errorsListeners.forEach(x => x(error));
    }

    private getRedirectUrl(baseUrl:string, nextUrl?:string): string
    {
        if(!nextUrl || !nextUrl.trim()) { return baseUrl; }
        
        const url = new URL(baseUrl);
        if(nextUrl) { url.searchParams.append('nexturl', nextUrl); }
        return url.toString();
    } 
}

export class KeycloakProvider extends LoginProvider
{
    @lazy()
    private get authService(): AuthService
    {
        return this.buildAuthInstance();
    }

    public signIn(nextUrl?:string)
    {
        this.authService.authConfig.redirect_url = this.getRedirectLoginUrl(nextUrl);
        return this.authService.signIn();
    }

    public signOut(nextUrl?:string)
    {
        this.authService.authConfig.end_session_redirect_url = this.getRedirectLogoutUrl(nextUrl);
        return this.authService.signOut();
    }

    @lock()
    public isAuthenticated(): PromiseController<boolean>
    {
        let subscription:Subscription | undefined = undefined;
        return PromiseController.create<boolean>(resolve => subscription = this.subscribeIsAuthenticated(resolve))
            .finally(() => subscription?.unsubscribe());
    }

    @lock()
    public getToken(): PromiseController<string | undefined>
    {
        const authentication = this.isAuthenticated();
        const promise = new Promise<string | undefined>(async (resolve, reject) => {
            try {
                const isAuthenticated = await authentication;
                if(!isAuthenticated) { return resolve(undefined); }

                const token = await this.authService.getValidToken(-30);
                resolve(token.accessToken);
            }
            catch(e) { reject(e); }
        });

        return PromiseController.from(promise, authentication);
    }

    public handleLoginCallback(): PromiseController<void>
    {
        const response = this.subscribeToPromise(AuthActions.SignInSuccess, AuthActions.SignInFailed);
        //let url = window.location.origin +  props.location.pathname +  props.location.search;
        const url = window.location.origin +  window.location.pathname +  window.location.search;
        this.authService.authorizationCallback(url);
        return response;
    }

    public handleLogoutCallback(): PromiseController<void>
    {  
        const response = this.subscribeToPromise(AuthActions.SignOutSuccess, AuthActions.SignOutFailed);
        this.authService.endSessionCallback();
        return response;
    }

    protected getAuthorizationHeader(token:string)
    {
        return `Bearer ${token}`;
    }

    private subscribeIsAuthenticated(callback:(isAuthenticated:boolean) => void)
    {
        return this.authService.initComplete$
        .pipe(filter(complete => complete), switchMap(() => this.authService.token$), take(1))
        .subscribe({
            next(token) { callback(!!token); },
            error:this.handleError
        })
        //.subscribe(token => callback(!!token))
    }

    private subscribeToPromise(resolveAction:AuthActions, rejectAction:AuthActions): PromiseController<void>
    {
        return PromiseController.create<void>((resolve, reject) => {
            const subscription = this.authService.events$.subscribe(async(action) => {
                if(action.action === resolveAction) { resolve(); }
                else if(action.action === rejectAction) { reject(action.error); }
            });

            return () => subscription.unsubscribe();
        });
    }

    private buildAuthInstance()
    {
        const requestor = isPlatform("ios")? new CapacitorRequestor(): new AxiosRequestor();
        const authService = new AuthService(new CapacitorBrowser(), new CapacitorSecureStorage(), requestor);

        authService.authConfig = {
            //client_id: 'harreactapp',
            client_id: isProduction? 'harreactapp': 'harreactapp-test',
            //server_host: "https://id.hartech.io/auth/realms/harmembers",
            server_host: "https://id.hartech.io/auth/realms/harcrm",
            redirect_url: this.options.redirectLoginUrl,
            end_session_redirect_url: this.options.redirectLogoutUrl,
            scopes:'openid email profile',
            pkce: true,
        }

        authService.initComplete$.subscribe({ error:this.handleError });

        authService.init();
        return authService;
    }
}

export class CookieProvider extends LoginProvider
{
    private readonly STORAGE_HAR_KEY = 'com.har.crm.auth';

    public override get isExternal(): boolean { return false; }
    public override get useCookies(): boolean
    {
        if(process.env.REACT_APP_USE_COOKIE === 'true') { return true; }
        else if(process.env.REACT_APP_USE_COOKIE === 'false') { return false; }
        return !platform.isApp;
    }

    //public override get useCookies(): boolean { return !platform.isApp && false; }

    @lazy()
    private get storage()
    {
        return PromiseController.from(new Storage().create());
    }

    @lock()
    public async signIn(nextUrl?:string)
    {
        this.redirect('/login', nextUrl);
        await wait(500);
    }

    @lock()
    public async signOut(nextUrl?:string)
    {
        this.redirect('/logout', nextUrl);
        await wait(500);
    }

    public isAuthenticated(): PromiseController<boolean>
    {
        return this.loadHarKeyFromStorage().then(key => !!key);
    }

    public getToken(): PromiseController<string | undefined>
    {
        if(this.useCookies) { return PromiseController.resolve(undefined); }
        //if(this.useCookies && !force) { return PromiseController.resolve(undefined); }
        return this.loadHarKeyFromStorage();
    }

    public handleLoginCallback(token?:string): PromiseController<void>
    {
        if(this.useCookies) { token = '1'; }
        //if(this.useCookies && !token?.trim()) { token = '1'; } // temporary
        if(!token?.trim()) { return PromiseController.reject('Invalid Token.'); }
        return this.storage.then(x => x.set(this.STORAGE_HAR_KEY, token));
    }

    public handleLogoutCallback(): PromiseController<void>
    {  
        return this.storage.then(x => x.remove(this.STORAGE_HAR_KEY));
    }

    protected getAuthorizationHeader(token:string)
    {
        return `Cookie ${token}`;
    }

    private redirect(baseUrl:string, nextUrl?:string)
    {
        const params = nextUrl? '?nexturl=' + encodeURIComponent(nextUrl): '';
        history.push(baseUrl + params);
    }

    private loadHarKeyFromStorage(): PromiseController<string | undefined>
    {
        return this.storage
            .then(x => x.get(this.STORAGE_HAR_KEY))
            .then((x:string | undefined) => x?.trim());
    }
}