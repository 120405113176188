import { Fragment, useEffect, useState } from 'react';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { ILinkItem, ILinksSectionProps } from './LinksSection.def';
import './LinksSection.scss';
import { OrdinaryButton } from 'views/forms/buttons/Button';

export const LinksSectionApp = (props: ILinksSectionProps): JSX.Element => {
    const { initialLinks, maxLink, onLinkChange } = props;
    const maximumLink = maxLink ?? 10;
    // maxLink = maxLink ?? maximumLink;
    const [linkData, setLinkData] = useState<Array<ILinkItem>>(initialLinks && maximumLink && initialLinks.length > maximumLink ? initialLinks.splice(0, maximumLink) : initialLinks ? initialLinks : []);
    /**
   * To edit an link, set the link index
   * To add a new link, set the next index available (for example, for an array with 2 items, the next available index is "2")
   */
    const [selectedLink, setSelectedLink] = useState<number | undefined>(linkData.length === 0 ? 0 : undefined);

    const edit = (index: number) => setSelectedLink(index);
    const add = () => setSelectedLink(linkData.length);
    const remove = (index: number) => {
        linkData.splice(index, 1);
        setSelectedLink(linkData.length === 0 ? 0 : undefined);
        setLinkData([...linkData]);
    };
    const save = (address: ILinkItem) => {
        if (selectedLink === linkData.length && linkData.length >= maximumLink) { return; }

        if (selectedLink !== undefined) { linkData[selectedLink] = address; } // Updating address
        else { linkData.push(address); } // Adding new address

        setLinkData([...linkData]);
        setSelectedLink(undefined);
        onLinkChange?.(linkData);
    };
    const isLinkList = selectedLink === undefined;
    return isLinkList ?
        <ListView list={linkData} onRemove={remove} onEdit={(index) => edit(index)} onAdd={add} maximumLink={maximumLink} {...props} />
        :
        <FormView selectedLink={linkData[selectedLink]} onRemove={() => remove(selectedLink)} onCancel={() => setSelectedLink(undefined)} onSave={save} {...props} />
        ;
}

const FormView = (props: ILinksSectionProps.FormViewProps): JSX.Element => {
    const [linkField, setLinkField] = useState({ value: '', error: '' });

    const [urlField, setUrlField] = useState({ value: '', error: '' });

    useEffect(() => {
        const item = props.selectedLink;
        setLinkField({ value: item?.label ?? '', error: '' });
        setUrlField({ value: item?.url ?? '', error: '' });
    }, [props.selectedLink]);

    const deleteAddress = () => props.onRemove();

    const addLink = () => {
        if (linkField.value.trim().length > 1 && urlField.value.trim().length > 1) {
            if (urlField.value.match(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
            )) {
                props.onSave({
                    label: linkField.value,
                    url: urlField.value
                },"0")
            } else {
                setUrlField({ value: urlField.value.trim(), error: 'URLs can be with https://, and could be without it (HAR.com).' });
            }

        } else {
            setLinkField({ value: linkField.value ?? '', error: linkField.value.length === 1 ? 'This field should contain min 2 max 15 characters' : linkField.value.length === 0 ? 'This field is required' : '' })
            setUrlField({ value: urlField.value ?? '', error: urlField.value.length === 1 ? 'This field should contain min 2 max 100 symbols' : urlField.value.length === 0 ? 'This field is required' : '' })
        }
    }
    return (
        <div >
            <div >
                

                <Textbox label={`What's the Link?`} containerClassName="mb-4" placeholder='e.g Facebook profile' value={linkField.value} maxlength={50} error={linkField.error !== '' ? linkField.error : false} onIonChange={(e) => setLinkField({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' })} />
                <Textbox label='URL' value={urlField.value} containerClassName=" mb-4" placeholder='http://www...' maxlength={100} error={urlField.error !== '' ? urlField.error : false} onIonChange={(e) => setUrlField({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' })} />

                {!props.selectedLink ?
                    <div className="text-right">
                        <button className="btn btn--shapeless mr-4">Cancel</button>
                        <button className="btn btn--primary" onClick={addLink}>Add</button>
                    </div>
                    :
                    <div className="address__row__links text-right">
                        <OrdinaryButton size='small' className='mr-3' onClick={deleteAddress} clickConfirmation='Are you sure to delete the link?'>Remove</OrdinaryButton>
                        <button type="button" className="btn btn--ordinary btn--small mr-2" onClick={addLink}>Save</button>
                        <button type="button" className="btn btn--shapeless mr-4" onClick={props.onCancel}>Cancel</button>
                    </div>
                }
            </div>
        </div>
    )
}

const ListView = (props: ILinksSectionProps.ListViewProps): JSX.Element => {

    const add = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onAdd();
    };

    return (
        <div className="card mb-5">
            <div className="p-4">
                <h3 tabIndex={0}>Links</h3>
                {props.list.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <div className="address__row">
                                <div className="address__row__desc overflow-hidden font_size--medium">
                                    <div className="">{item.label}</div>
                                    <div className=" overflow-ellipsis">
                                        <a href={item.url} target='_blank' rel="noreferrer">{item.url}</a>
                                    </div>
                                </div>
                                <div className="address__row__links pl-3">
                                    <div className="d-flex">
                                        <button type="button" className="btn btn--ordinary btn--small mr-3" onClick={() => props.onEdit(index)}>Edit</button>
                                        <OrdinaryButton size='small' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete the link?'>Remove</OrdinaryButton>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
            </div>
            {props.list.length < props.maximumLink &&
                <div className="p-4 border-top">
                    <a href="#" className="font_size--medium font_weight--semi_bold text-decoration-none" onClick={add}>+ Add Links</a>
                </div>}
        </div>
    )
}