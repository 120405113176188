import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace AddDealApi {
    export const url = "/deals";
    export const method = "POST";
    export const defaultError = "Something went wrong while adding the deal.";
    export const operations = apiOperations(AddDealApi);
    export const scope = new class AddDealApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            const { title, contact_id, deal_type, deal_type_text, deal_stage, deal_stage_text, deal_priority,
                property_full_street_address, property_mlsnum, property_harid, property_for, property_address2, property_listingid, property_city,property_country, property_price, property_zip, property_state, deal_amount, estimated_deadline, notes } = input
            return {
                title: title,
                contact_id: contact_id,
                deal_type: deal_type,
                deal_type_text: deal_type_text,
                deal_stage: deal_stage,
                deal_stage_text: deal_stage_text,
                deal_priority: deal_priority,
                property_full_street_address: property_full_street_address,
                property_address2: property_address2,
                property_harid: property_harid,
                property_listingid: property_listingid,
                property_for: property_for,
                property_city: property_city,
                property_price: property_price,
                property_zip: property_zip,
                property_state: property_state,
                property_country: property_country,
                property_mlsnum: property_mlsnum,
                deal_amount: deal_amount,
                estimated_deadline: estimated_deadline,
                notes: notes
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        title: string,
        contact_id: string,
        deal_type: string,
        deal_type_text?: string,
        deal_stage: string,
        deal_stage_text?: string
        deal_priority: string,
        property_full_street_address: string,
        property_address2: string,
        property_mlsnum?: string,
        property_listingid?: string,
        property_for?: string,
        property_harid?: string,
        property_url?: string,
        property_photo?: string,
        property_price?: string,
        property_city?: string,
        property_state?: string,
        property_country?: string,
        property_zip?: string,
        deal_amount: string,
        estimated_deadline: string,
        notes: string

    }

    export interface Response {

    }
}

export const fetchAddDealService = forwardOperation(AddDealApi.operations, 'fetch');
export const useFetchAddDealService = forwardOperation(AddDealApi.operations, 'useFetch');
export const useAddDealService = forwardOperation(AddDealApi.operations, 'useService');