import { INativeDateTimePickerProps } from './NativeDateTimePicker.def';
import './NativeDateTimePicker.css';
import { IonInput, IonItem, IonModal, IonDatetime, IonIcon } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { parseISO, format, addYears, subYears } from 'date-fns'
import { PrimaryButton } from 'views/forms/buttons/Button';
import { closeCircleOutline } from 'ionicons/icons'
import { joinWithSpace } from 'scripts/helpers/texts';


export const NativeDateTimePicker = (props: INativeDateTimePickerProps): JSX.Element => {
    const modalDate = useRef<HTMLIonModalElement>(null);
    const ionTimeRef = useRef<HTMLIonDatetimeElement>(null);
    const ionDateRef = useRef<HTMLIonDatetimeElement>(null);
    const [date, setDate] = useState<Date | undefined>(props.value ? parseISO(props.value) : undefined)
    const [showTimer, setShowTimer] = useState(false)
    const today = new Date()
    const [day, setDay] = useState<number>(today.getDate())
    const maxDefaultDate = addYears(new Date(), 10)
    const minDefaultDate = subYears(new Date(), 2)
    const showFormat = props.format ? props.format : props.showTime ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy'
    const initPicker = () => {
        if(props.disabled){
            return

        } 
        modalDate.current?.present()
    }

    const save = () => {

        let time = ionTimeRef?.current?.value && typeof ionTimeRef?.current?.value === 'string' && date ?
            parseISO(format(date, "yyyy-MM-dd") + 'T' + ionTimeRef.current.value + ":00")
            :
            new Date();

        setDate(time)
        props.onChange?.(format(time, "yyyy-MM-dd'T'HH:mm:ss"))
        modalDate.current?.dismiss()
    }

    useEffect(() => {
        if (props.value) {
            setDate(parseISO(props.value))

        }
    }, [props.value])

    const className = joinWithSpace('date__input__input', props.customClass ?? "min-w-255px");
    return (
        <>
            <div className="datewraper">
                <IonItem className="date__input">
                    {props.label &&
                        <div className="date__input__label">{props.label}</div>
                    }
                    <div className={className}>
                        <IonInput
                            placeholder={props.showTime ? `Choose Date and Time` : `Choose Date`}
                            value={date ? format(date, showFormat) : ''}
                            onIonFocus={initPicker}
                            disabled={props.disabled}
                            className='p-2'
                        />

                    </div>

                </IonItem>
                {props.error &&
                    <div className='ml-3 color_sold'>{props.error}</div>
                }

                <IonModal ref={modalDate} initialBreakpoint={0.55} breakpoints={[0.55]} onIonModalWillDismiss={() => setShowTimer(false)} backdropDismiss={false}>
                    <div className='pt-4 position-relative' >
                        <div className='position-absolute right-6px top-10px zindex-100'>
                            <a onClick={() => modalDate.current?.dismiss()}>
                                <IonIcon icon={closeCircleOutline} slot="icon-only" color="dark" className='closeDp__icon right-6px top-10px position-absolute' ></IonIcon>
                            </a>
                        </div>
                        {!showTimer &&
                            <div className='mx-auto'>
                                <h2 className=' text-center mb-0'>{date ? format(date, props.format ? props.format : 'MM/dd/yyyy') : `Select Date`}</h2>
                                {props.showTime && date && !showTimer &&
                                    <a className='d-block text-center cursor-pointer' onClick={() => setShowTimer(true)}>Set time</a>
                                }
                                <div className='d-flex justify-content-center timePickerNativeOnly'>
                                    <IonDatetime className='mt-4 pb-3' id="datetime"
                                        ref={ionDateRef}
                                        locale="en-US-u-hc-h12"
                                        value={props.value ? format(parseISO(props.value), "yyyy-MM-dd'T'HH:mm:ss") : undefined}
                                        max={props.maxDate ? format(parseISO(props.maxDate), "yyyy-MM-dd'T'HH:mm:ss") : format(maxDefaultDate, "yyyy-MM-dd'T'HH:mm:ss")}
                                        min={props.minDate ? format(parseISO(props.minDate), "yyyy-MM-dd'T'HH:mm:ss") : format(minDefaultDate, "yyyy-MM-dd'T'HH:mm:ss")}
                                        presentation="date" onIonChange={(e: any) => {
                                            let dateString = e.target.value
                                            if (dateString) {
                                                let dateStringSplit = dateString.split("T")
                                                if (dateStringSplit[0]) {
                                                    let tmpDate = parseISO(dateStringSplit[0] + 'T00:00:00')
                                                    setDate(tmpDate)
                                                    let saveTime = props.showTime ? 'T00:00:00' : ''
                                                    props.onChange?.(dateStringSplit[0] + saveTime)

                                                    let daySelected = tmpDate.getDate()
                                                    if (daySelected !== day) {
                                                        setDay(daySelected)
                                                    }
                                                    if (!props.showTime && daySelected !== day) {
                                                        modalDate.current?.dismiss()
                                                    }
                                                }
                                            }

                                        }}
                                    ></IonDatetime>
                                </div>
                            </div>
                        }
                        {showTimer &&
                            <div className='mx-auto w-90pr timePickerNativeOnly' >
                                {date &&
                                    <div className='min-h-250px '>
                                        <h2 className=' text-center mb-0'>{format(date, 'MM/dd/yyyy')}</h2>
                                        <a className='d-block text-center cursor-pointer' onClick={() => setShowTimer(false)}>Set date</a>
                                        <IonDatetime ref={ionTimeRef}
                                            className='mx-3'
                                            presentation="time"
                                            hourCycle="h12"
                                            value={format(date, "HH:mm")}
                                        ></IonDatetime>
                                    </div>
                                }

                                <div className=' d-flex'>
                                    <PrimaryButton size='small' className='text-center m-auto d-inline-block w-25' onClick={save} >Ok</PrimaryButton>
                                </div>
                            </div>
                        }


                    </div>

                </IonModal >
            </div >
        </>
    );

}
