import { ITaskTimelineProps } from './TaskTimeline.def';
import './TaskTimeline.scss';
import { TaskCard } from "views/calendar/TaskCard";
import { getDay, getDayNumber, getMonth } from 'scripts/helpers/dates';
import { GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { useEffect, useState } from 'react';
import { Empty } from './components/empty';
import { Loading } from './components/loading';
import { TaskDetailsPopUp } from '../popups/TaskDetailsPopUp';
import { fetchEditCalendarTaskCompletedService } from 'services/calendar/editCalendarTaskCompleted';
import { IonAlert } from '@ionic/react';
import { AddTaskPopUp } from '../popups/AddTaskPopUp';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { ContactDetailPopover } from 'views/contacts/popovers/ContactDetailPopover';

export const TaskTimeline = (props: ITaskTimelineProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const contact_id = id;
    const [taskId, setTaskId] = useState("")
    const { tasks, returnUrl, redirect, filter } = props
    const [showAddTask, toggleShowAddTask] = useState(false);
    const [showTaskDetailModal, toggleShowTaskDetailModal] = useState<boolean>(false);
    const [successMsg, setSuccessMsg] = useState("");
    const history = useHistory()
    const [showContactDetailPopup,setShowContactDetailPopup]=useState(false)
    const [contactId,setContactId]=useState("")

    const handleSetTaskDetail = () => {
        toggleShowTaskDetailModal(true);
    }

    const getTasksBreakupListByDate = (list: Array<GetCalendarTasksApi.IResponseList>) => {
        const groups = list.reduce(
            (groups, item: GetCalendarTasksApi.IResponseList) => {
                const key = moment(item.start_dt).format('YYYY-MM-DD');
                if (!groups[key]) { groups[key] = { date: moment(key).toDate(), list: [] }; }
                groups[key]!.list.push(item);
                return groups;

            },
            {} as Record<string, { date: Date, list: GetCalendarTasksApi.IResponseList[] }>
        );
        return Object.values(groups)
    };

    const handleTaskCompleted = async (data: GetCalendarTasksApi.IResponseList) => {
        const response = await fetchEditCalendarTaskCompletedService({ id: data.id.toString(), status_id: !data.completed })
        if (response) {
            handleSuccessMsg(!data.completed ? "Marked as completed" : "Unmarked");
            return true;
        }
        return false;
    }
    const handleSuccessMsg = (msg: string) => {
        setSuccessMsg(msg)
        setTimeout(() => {
            props.refreshTasksList?.();
            setSuccessMsg("");
            toggleShowTaskDetailModal(false);
        }, 3000);

    }


    function taskDetailModal() {
        toggleShowTaskDetailModal(false)
         if (showTaskDetailModal) {
            props.onClose?.();
        }

    }

    useEffect(() => {
        setTaskId(props.taskId ? props.taskId : '')
    }, [props.taskId])


    useEffect(() => {
        if (taskId) {
            toggleShowTaskDetailModal(true)
        }

    }, [taskId]);

    return (
        <div>
            {tasks?.list! && tasks?.list?.length === 0 && <Empty handleShowAddTaskPopup={() => toggleShowAddTask(true)} />}
            {!tasks?.list! && <Loading />}
            {tasks?.list! && tasks?.list?.length > 0 &&
                getTasksBreakupListByDate(tasks.list)?.map((data, ind) =>
                    <div key={ind} id={getDayNumber(data.date)} >
                        <div className="row no-gutters">
                            {!props.hideDateCol && <div className="col-auto pr-md-4 pr-2">
                                <div className="date date__card min-w-72px">
                                    <div className="date__card__month" >{getMonth(data.date)}</div>
                                    <div className="date__card__date">{getDayNumber(data.date)}</div>
                                    <div className="date__card__day">{getDay(data.date)}</div>
                                </div>
                            </div>}
                            <div className="col">
                                <div className="pb-md-4 border-md-bottom mb-md-5">
                                    <div className="row"  >
                                        {data.list.map((dataa: GetCalendarTasksApi.IResponseList) =>
                                            <TaskCard
                                                key={dataa.id}
                                                data={dataa}
                                                compact={props.compact}
                                                handleTaskCompleted={handleTaskCompleted}
                                                returnUrl={returnUrl}
                                                setTaskDetail={handleSetTaskDetail}
                                                openTask={(id: string) => {
                                                    if (filter && !contact_id) {
                                                        history.push('/calendar/task/' + id, { 'filter': JSON.stringify(filter) })
                                                    } else {
                                                     
                                                        redirect?.(id)
                                                    }
                                                }}
                                                openContactQuickOverviewPopup={(contactid:string)=>{setContactId(contactid);setShowContactDetailPopup(true);}}
                                            />)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
            <IonAlert
                isOpen={successMsg !== ''}
                onDidDismiss={() => setSuccessMsg("")}
                subHeader={successMsg}
            />
            <TaskDetailsPopUp
                toggle={taskDetailModal}
                show={showTaskDetailModal}
                returnUrl={returnUrl}
                taskId={taskId ? taskId : props.taskId ?? ""}
                handleTaskCompleted={handleTaskCompleted}
                refreshData={() => { props.refreshTasksList?.(); taskDetailModal() }}
                openContactQuickOverviewPopup={(contactid:string)=>{setContactId(contactid);setShowContactDetailPopup(true);}}
            />
            <AddTaskPopUp refreshData={props.refreshTasksList} show={showAddTask} toggle={toggleShowAddTask} />
            <ContactDetailPopover
                show={showContactDetailPopup}
                viewProfile={() => {
                    setShowContactDetailPopup(showContactDetailPopup ? false : true);
                    setTimeout(() => {
                        history.push(`/contacts/${contactId}/overview`, { referrer: '/contacts' })
                    }, 400)
                }}
                id={!!contactId ? parseInt(contactId) : 0}
                toggleShow={() => setShowContactDetailPopup(false)}
               // reload={reload}
            />
        </div>
    );
}
