import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText } from '@ionic/react';
import { IImportCsvMappedScreenProps } from '../ImportCsvMappedScreen.def';
import { useGetCSVContentService } from 'services/contacts/csvmapping/getCSVContent';
import { ValueMappingApi, useValueMappingService } from 'services/contacts/csvmapping/valueMapping';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { ProminentButton } from 'views/forms/buttons/Button';
import { useResponsive } from 'scripts/hooks/general';
import { ISelectWithSearchOptions } from 'views/forms/select/SelectWithSearch/SelectWithSearch.def';
import { SelectWithSearch } from 'views/forms/select/SelectWithSearch';
import { createOutline, arrowForwardOutline } from 'ionicons/icons'
import { isProduction } from 'scripts/application/settings';
export const FieldMapping = forwardRef((props: IImportCsvMappedScreenProps, ref) => {
    //tmp
    const { field_mappings, setFieldMappings } = props
    const [csvInfo, doGetCSVContentService] = useGetCSVContentService();
    const [valueMappingService, valueMappingServiceAction] = useValueMappingService()
    const [error, setError] = useState<string>('');

    const [crmOptions, setCrmOption] = useState<ISelectWithSearchOptions[]>()
    const isResponsive = useResponsive();
    //App
    const [showModal, setShowModal] = useState<boolean>(false)
    const [index, setIndex] = useState<number>(0)

    useImperativeHandle(ref, () => ({
        submit() {
            onSubmit()
        }

    }));
    const loadCSVContent = (id: string) => {
        doGetCSVContentService.fetch({ id: id, queue: !isProduction ? '1' : '0' });
    }


    useServiceDidSucceeded(csvInfo, (response: any) => {
        setCrmOption(response.crm_fields.map((field: any) => {
            return {
                label: field.long_value,
                value: field.short_value
            }
        }))
    });


    useEffect(() => {
        if (props.import_id) {
            loadCSVContent(props.import_id)
        }

    }, [props.import_id]);



    const onFieldChange = (index: number, value: string) => {
        let limit = csvInfo?.response?.crm_fields?.filter((field) => field.short_value === value)?.[0]?.limit

        if (limit && Number(limit) === 1) {
            let swapping = field_mappings?.filter((field) => (field.index !== index.toString() && field.value !== value))
            let tmpArray = [...swapping ? swapping : [], { index: index.toString(), value: value }];
            setFieldMappings?.(tmpArray);
        } else {
            let records = field_mappings?.filter((field) => field && field.value === value)?.length ?? 0
            if (Number(records) < Number(limit) || value === "") {
                let swapping = field_mappings?.filter((field) => (field.index !== index.toString()))
                let tmpArray = [...swapping ? swapping : [], { index: index.toString(), value: value }];
                setFieldMappings?.(tmpArray);
            } else {
                alert(`You can only map ${limit} fields of this type`)
                return
            }

        }
    }

    const getValue = (index: number) => {
        return field_mappings?.filter((field) => field.index === index.toString())?.[0]?.value
    }

    const FieldsDropDown = (param: { index: number }) => {
        return (
            <SelectWithSearch
                key={param.index}
                title="Select Field"
                onSelect={(obj: any) => onFieldChange(param.index, obj.value)}
                options={crmOptions}
                selectedValue={getValue(param.index)}
                emptyOption='Select Field'
            />
        )
    }
    const LoadingState = () => {
        return (
            <div className='pt-3 pb-3' >
                <div className='mx-auto d-flex w-290px min-h-260px'>
                    <IonSpinner className='col-auto' name="bubbles"></IonSpinner>
                    <IonText className='col pt-1'>Please wait while preparing the contacts grid.</IonText>
                </div>
            </div>
        )
    }



    const trimFieldMappings = (field_mappings: ValueMappingApi.IFieldMappings[]) => {
        let tmpArray = field_mappings?.filter((field) => field.value !== "")
        return tmpArray
    }

    const FieldMappingApp = (props: { show: boolean, setVisiblity?: () => void, index: number }) => {
        const { show, setVisiblity } = props
        const label = crmOptions?.filter((option) => { return option.value === getValue(Number(props.index)) })?.[0]?.label ?? undefined
        return (
            <SelectWithSearch
                title={csvInfo?.response?.columns?.[props.index]?.column ?? ''}
                autoOpen={show}
                onSelect={(obj: any) => {
                    onFieldChange(props.index, obj.value)
                    setVisiblity?.()
                }}
                label={label}
                onClose={setVisiblity}
                options={crmOptions}
                selectedValue={getValue(props.index)}
                emptyOption='Select Field'
            />
        )
    }



    const onSubmit = () => {
        if (!field_mappings?.find((field) => field.value === "first_name" || field.value === "last_name" || field.value === "preferred_email" || field.value === "preferred_phone")) {
            setError('Please map at least one of the following fields: First Name, Last Name, Preferred Email or Preferred Phone')
            return
        }
        props.setSelectedMapping?.({})


        if (props.import_id && valueMappingService.loading === false && field_mappings) {
            valueMappingServiceAction.fetch({
                import_id: props.import_id,
                field_mappings: trimFieldMappings(field_mappings),
                queue: !isProduction ? '1' : '0'
            })
        }
    }

    useServiceDidSucceeded(valueMappingService, (response: any) => {
        props.onNext?.(response.has_value_mapping === '0' ? 4 : 3, response.mappings)
    })


    return (
        <div className='ml-auto mr-auto pl-4 pr-4' style={{ maxWidth: '2000px' }}>
            {isResponsive &&
                <FieldMappingApp show={showModal} setVisiblity={() => setShowModal(false)} index={index} />
            }
            {!csvInfo.loading && error && <div color="danger" className='error_cell p-3 mb-2'>{error}.</div>}

            {csvInfo.loading && <LoadingState />}
            {!csvInfo.loading && csvInfo.response &&
                <div>
                    <div className="">
                        <IonGrid className="table ion-no-padding contact_table_searchbar make-sticky" fixed={true}>
                            <IonRow>
                                <IonCol className='align-self-center'>
                                    <span className='font_size--large font_weight--bold'> {`Map column to field `}</span>
                                </IonCol>
                                {!isResponsive &&
                                    <IonCol size="auto" className='text-right ml-auto'>
                                        <ProminentButton onClick={onSubmit} loading={valueMappingService.loading} disabled={valueMappingService.loading}>Submit</ProminentButton>
                                    </IonCol>
                                }
                            </IonRow>
                        </IonGrid>

                    </div>
                    <div className="p-3 mapping-grid"  >
                        <div className={`row py-3 px-1 pb-4 border-bottom`}>
                            <div className="col d-flex pb-2">
                                <span className='font_size--large font_weight--bold'> {`CSV Field`}</span>
                            </div>

                            <div className="col font_size--large font_weight--bold pb-2 ">
                                {`CRM Field`}
                            </div>
                        </div>
                        {csvInfo?.response.columns?.map((c, index) =>
                            <div key={index} className={`row py-3 px-1 border-bottom ${getValue(Number(c.index)) ? `mapped_cell` : ``}`}>
                                <div className="col d-flex pt-2">
                                    <div className='font_size--medium'> {c.column}
                                        {c.column_value && csvInfo?.response.has_header === '1' &&
                                            <>
                                                <br />
                                                {isResponsive ?
                                                    <div className='font_weight--light  color_custom_4 max-w-100 pt-2 text-truncate overflow-ellipsis '>e.g. <br />{c.column_value}</div>
                                                    :
                                                    <div className='font_weight--light  color_custom_4 pt-2 ml-2'> e.g. {c.column_value}</div>
                                                }

                                            </>
                                        }
                                    </div>

                                </div>
                                <div className='ml-auto pt-2 '>
                                    <IonIcon className='font_size--large' icon={arrowForwardOutline}  ></IonIcon>
                                </div>
                                {isResponsive ?
                                    <div className='col'>
                                        <div className='d-flex pt-2'>
                                            <div className='font_size--medium pr-2'> {getValue(Number(c.index)) ? crmOptions?.filter((option) => { return option.value === getValue(Number(c.index)) })?.[0]?.label : 'Select Field'} </div>
                                            <div className=' ml-auto '><a className='font_size--medium' onClick={() => {
                                                setIndex(Number(c.index))
                                                setShowModal(true)
                                            }}> <IonIcon className='font_size--large' icon={createOutline}  ></IonIcon></a></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col ">
                                        <FieldsDropDown index={Number(c.index)} />
                                    </div>
                                }
                            </div>
                        )}


                    </div>
                    <div className="d-flex">
                        <div className='mr-auto'>
                            <ProminentButton onClick={onSubmit} loading={valueMappingService.loading} disabled={valueMappingService.loading}>Submit</ProminentButton>

                        </div>
                    </div>

                </div>
            }
        </div >
    );
})






