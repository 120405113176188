import { RecordValueError } from "types/form";

export function buildRecordValueError<T extends object>(form:T): RecordValueError<T>
{
    const result:Partial<RecordValueError<T>> = {};
    for(const key of Object.getOwnPropertyNames(form))
    {
        result[key as keyof T] = { value:form[key as keyof T], error:'' };
    }

    return result as RecordValueError<T>;
}