import { useCallback, useEffect, useRef, useState } from "react";
import { IGeneratorContext, PromiseController } from "scripts/helpers/async";

export interface IUseTimeoutOptions
{
    renew?:boolean;
}


type ScheduleAction<K extends string | number, P extends any[], R = any> = (key:K, ...params:P) => R;

interface IScheduleMapEntry<K extends string | number, P extends any[]>
{
    ongoing?:IScheduleMapValue<K,P>;
    upcoming?:IScheduleMapValue<K,P>;
    next?:IUpcomingSchedule<K,P>;
}

interface IUpcomingSchedule<K extends string | number, P extends any[]>
{
    action:ScheduleAction<K,P>;
    params:P;
}

interface IScheduleMapValue<K extends string | number, P extends any[]>
{
    timeout?:number;
    promise?:Promise<unknown>;
    running?:boolean;
    abort?(): void;
    action:ScheduleAction<K,P>;
}

interface IGenericScheduleReturn<K extends string | number>
{
    clear(key:K): void;
    clearSchedule(key:K): void;
    clearAll(): void;
    isScheduled(key:K): boolean;
}

interface IScheduleReturn<K extends string | number, P extends any[]> extends IGenericScheduleReturn<K>
{
    run<R>(action:ScheduleAction<K,P,R>, key:K, ...params:P): void;
    schedule<R>(action:ScheduleAction<K,P,R>, key:K, ...params:P): void;
}

interface ITimeoutReturn<K extends string | number, P extends any[]> extends IGenericScheduleReturn<K>
{
    run(key:K, ...params:P): void;
    schedule(key:K, ...params:P): void;
}

export function useSetTimeout()
{
    const entries = useRef<Set<number>>(new Set());

    useEffect(() => () => {
        entries.current.forEach(id => clearTimeout(id));
        entries.current.clear();
    }, []);

    const execute = useCallback((id:number, action:() => any) => {
        entries.current.delete(id);
        action();
    }, []);

    const add = useCallback((action:() => any, timeout:number) => {
        const id = window.setTimeout(() => execute(id, action), timeout);
        entries.current.add(id);
        return id;
    }, []);

    const clear = useCallback((id:number) => {
        if(entries.current.has(id)) { clearTimeout(id); }
        entries.current.delete(id);
    }, []);

    return { setTimeout:add, clearTimeout:clear };
}


export function useTimeout<P extends any[]>(action:(...args:P) => void, timeout:number, options?:IUseTimeoutOptions)
{
    const [params, setParams] = useState<P>();
    const { schedule:scheduleAction, run:runAction, clear, isScheduled } = useSchedule<P>(timeout, options);

    useEffect(() => {
        if(!params) { return; }
        setParams(undefined);
        runAction(action, ...params);
    }, [params, runAction, action]);

    const updateParams = (...params:P) => setParams(params);
    const run = useCallback((...params:P) => { runAction(updateParams, ...params); }, [runAction]);
    const schedule = useCallback((...params:P) => { scheduleAction(updateParams, ...params); }, [scheduleAction]);

    return { run, schedule, clear, isScheduled };
}

export function useTimeoutMap<K extends string | number, P extends any[]>(action:(this:IGeneratorContext, key:K, ...args:P) => AsyncGenerator<void, void>, timeout:number, options?:IUseTimeoutOptions): ITimeoutReturn<K,P>;
export function useTimeoutMap<K extends string | number, P extends any[]>(action:(key:K, ...args:P) => any, timeout:number, options?:IUseTimeoutOptions): ITimeoutReturn<K,P>;
export function useTimeoutMap<K extends string | number, P extends any[]>(action:(key:K, ...args:P) => any, timeout:number, options?:IUseTimeoutOptions): ITimeoutReturn<K,P>
{
    const { schedule:scheduleQueue, run:runQueue, clear, clearSchedule, clearAll, isScheduled } = useScheduleMap<K, P>(timeout, options);
    const [paramsList, setParamsList] = useState<Record<K, P>>({} as Record<K, P>);

    useEffect(() => {
        const entries = Object.entries(paramsList);
        if(!entries.length) { return; }

        setParamsList({} as Record<K, P>);
        entries.forEach(([key, params]: [any, any]) => runQueue(action, key, ...params));
    }, [paramsList, action]);

    const addParams = (key:K, ...params:P) => setParamsList(current => ({ ...current, [key]:params }));
    const run = useCallback((key:K, ...params:P) => runQueue(addParams, key, ...params), [runQueue]);
    const schedule = useCallback((key:K, ...params:P) => scheduleQueue(addParams, key, ...params), [scheduleQueue]);

    return { run, schedule, clear, clearSchedule, clearAll, isScheduled } as const;
}

export function useSchedule<P extends any[]>(timeout:number, options?:IUseTimeoutOptions)
{
    const KEY = 'default';
    const { schedule:scheduleQueue, run:runQueue, clear:clearQueue, isScheduled:isQueueScheduled } = useScheduleMap<typeof KEY, P>(timeout, options);

    /** Clean up */
    //useEffect(() => clear, []);

    const run = useCallback((action:(...params:P) => void, ...params:P) =>
        runQueue((_key, ...params:P) => action(...params), KEY, ...params),
    [runQueue]);

    const schedule = useCallback((action:(...params:P) => void, ...params:P) =>
        scheduleQueue((_key, ...params:P) => action(...params), KEY, ...params),
    [scheduleQueue]);

    const clear = useCallback(() => clearQueue(KEY), []);
    const isScheduled = isQueueScheduled(KEY);

    return { run, schedule, clear, isScheduled };
}

/*export function useScheduleOld<P extends any[]>(timeout:number, options?:IUseTimeoutOptions)
{
    const renew = options?.renew ?? true;
    const actionRef = useRef<(...params:P) => void>();
    const timeoutRef = useRef<number>();

    /** Clean up *
    useEffect(() => clear, []);

    const schedule = useCallback((action:(...params:P) => void, ...params:P) =>
    {
        actionRef.current = action;
        if(timeoutRef.current && !renew) { return; }

        if(timeoutRef.current) { window.clearTimeout(timeoutRef.current); }
        timeoutRef.current = window.setTimeout(() => {
            const { current } = actionRef;
            timeoutRef.current = actionRef.current = undefined;
            current?.(...params);
        }, timeout);
    },
    [timeout, renew]);

    const clear = useCallback(() =>
    {
        if(timeoutRef.current) { clearTimeout(timeoutRef.current); }
        timeoutRef.current = actionRef.current = undefined;
    },
    []);
    
    const isScheduled = !!timeoutRef.current;
    return { schedule, clear, isScheduled };
}*/

export function useScheduleMap<K extends string | number, P extends any[]>(timeout:number, options?:IUseTimeoutOptions): IScheduleReturn<K,P>
{
    const renew = options?.renew ?? true;
    const queueRef = useRef<Record<K, IScheduleMapEntry<K,P> | undefined>>({} as any);
    const cleanedUp = useRef(false);

    /** Clean up */
    useEffect(() => cleanUp, []);

    const run = useCallback((action:(key:K, ...params:P) => any, key:K, ...params:P) =>
        register(false, action, key, ...params),
        [timeout, renew]
    );

    const schedule = useCallback((action:(key:K, ...params:P) => any, key:K, ...params:P) =>
        register(true, action, key, ...params),
        [timeout, renew]
    );

    const register = useCallback((schedule:boolean, action:(key:K, ...params:P) => any, key:K, ...params:P) =>
    {
        // Don't do anything for cleaned up state
        if(cleanedUp.current) { return; }

        // Getting entry
        if(!queueRef.current[key]) { queueRef.current[key] = {} as IScheduleMapEntry<K,P>; }
        const entry = queueRef.current[key]!;
        delete entry.next;

        // Registering action... If not renew, don't reschedule
        const spotKey = entry.ongoing && isRunning(key)? 'upcoming': 'ongoing';
        const spot = entry[spotKey] ?? (entry[spotKey] = { action });
        spot.action = action;
        if(schedule && spot.timeout && !renew) { return; }

        // Clearing Schedule
        clearSpotSchedule(spot);

        // Running or Scheduling action
        if(schedule) { spot.timeout = window.setTimeout(() => execute(key, ...params), timeout); }
        else { execute(key, ...params); }
    },
    [timeout, renew]);

    const execute = useCallback((key:K, ...params:P) =>
    {
        // Don't do anything for cleaned up state
        if(cleanedUp.current) { return; }

        // No ongoing
        const current = queueRef.current[key];
        const ongoing = current?.ongoing;
        if(!current || !ongoing) { return; }

        // Upcoming: In case ongoing is running
        const running = isRunning(key);
        if(running && !!current.upcoming) { current.next = { action:current.upcoming.action, params }; }
        if(running) { return; }

        // Start ongoing run
        ongoing.timeout = undefined;
        const { action } = ongoing;

        const context = PromiseController.createGeneratorContext();
        ongoing.running = true;
        const response = action.call(context, key, ...params);
        const promise:Promise<unknown> | PromiseController<unknown> = response && response.next? PromiseController.from(response): response;
        if(!promise || !promise.then) { return handleExecuted(key); }

        let aborted = false;
        ongoing.promise = promise;
        ongoing.abort = () => {
            aborted = true;
            if('abort' in promise) { promise.abort(); }
        };

        ongoing.promise
            .catch((e:any) => {
                if(aborted) { return console.error(e); }
                throw e;
            })
            .finally(() => {
                if(!aborted) { handleExecuted(key); }
            });
    },
    []);

    const handleExecuted = useCallback((key:K) => {
        const { ongoing, next, upcoming } = queueRef.current[key] ?? {};
        if(ongoing) { ongoing.running = undefined; }
        clear(key);
        if(next) { run(next.action, key, ...next.params); }
        else if(upcoming) {
            queueRef.current[key] = { ongoing:upcoming };
        }
    },
    []);

    const clear = useCallback((key:K) =>
    {
        const current = queueRef.current[key];
        const ongoing = current?.ongoing;
        if(!current) { return; }

        delete current.upcoming;
        ongoing?.abort?.();
        if(ongoing?.timeout) { clearTimeout(ongoing.timeout); }
        delete queueRef.current[key];
    },
    []);

    const clearSchedule = useCallback((key:K) => {
        const item = queueRef.current[key]?.ongoing;
        if(!item || isRunning(key)) { return; }

        clearSpotSchedule(item);
        delete queueRef.current[key];
    },
    []);

    const clearSpotSchedule = useCallback((spot:IScheduleMapValue<K,P>) => {
        if(spot.timeout) { clearTimeout(spot.timeout); }
        delete spot.timeout;
    },
    []);

    const isRunning = useCallback((key:K) => {
        const ongoing = queueRef.current[key]?.ongoing;
        return !!ongoing?.running;
    },
    []);

    const clearAll = useCallback(() => {
        Object.keys(queueRef.current).forEach(key => clear(key as K));
    },
    []);

    const cleanUp = useCallback(() => {
        cleanedUp.current = true;
        clearAll();
    },
    []);
    
    const isScheduled = useCallback((key:K) => !!queueRef.current[key], []);

    return { run, schedule, clear, clearSchedule, clearAll, isScheduled };
}