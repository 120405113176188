import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateAddressApi
{
    export const url = "/contacts/:contact_id/addresses/:address_id";
    export const method = "PUT";
    export const defaultError = "Failed to update address.";
    export const operations = apiOperations(UpdateAddressApi);
    export const scope = new class UpdateAddressApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        address_id:string,
        address1:string,
        address2?:string,
        address_type:string,
        city:string,
        zipcode:string,
        state?:string,
        country?:string
    }

    export interface Response
    {
        
    }
}

export const fetchUpdateAddressService = forwardOperation(UpdateAddressApi.operations, 'fetch');
export const useFetchUpdateAddressService = forwardOperation(UpdateAddressApi.operations, 'useFetch');
export const useUpdateAddressService = forwardOperation(UpdateAddressApi.operations, 'useService');