import { IonContent } from '@ionic/react';
import { IInitialScreenProps } from './InitialScreen.def';
import './InitialScreen.scss';
import { Auth } from 'scripts/security/AuthService';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
//import { joinWithSpace } from 'scripts/helpers/texts';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';

export const InitialScreen = (_props: IInitialScreenProps): JSX.Element =>
{
    const [loading, setLoading] = useState(true);
//    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    useEffect(() => { Auth.isAuthenticated.then(handleAuthentication, handleAuthentication); }, []);

    const handleAuthentication = (state?:boolean) =>
    {
        if(state === true) { return history.replace('/home'); }
        setLoading(false);
    }
/* 
    async function redirect()
    {
        const isAuthenticated = await Auth.isAuthenticated;
        if(isAuthenticated) { history.push('/onboarding'); }
        else { return Auth.signIn(); }
    };

    async function scheduleRedirect()
    {
        setDisabled(true);
        try { await redirect(); }
        catch(e) { setDisabled(false); }
    };

   async function handleGetStartedClick(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>)
    {
        e.preventDefault();
        scheduleRedirect();
    }
*/
    // Loading
    if(loading) { return <></>; }

    return (    
        <IonContent fullscreen scrollY={false}>
              <ExternalEmbedContent
                url='/har-crm?crm=1'
                source={eExternalEmbedContentSource.CMS}
                iframeTitle='HAR CRM'
            />
           {/**  <div className="container">
                <div className="hero hero--default hero--default__cols no-gutters">
                    <div className="hero--default__cols__left">
                        <img src='https://content.harstatic.com/media/crm/crm_app_logo_3.svg' className='mb-md-4 d-md-block d-none' />
                        <img src='https://content.harstatic.com/media/crm/crm_app_logo_3.svg' className='mb-md-4 mb-3 d-md-none d-inline-block max-w-65'/>

                        <div className="hero--default__content--tagline">Simple way to organize your leads,<br className="d-md-inline-block d-none" /> contacts, emails and more.</div>
                        <div className="hero--default__content--pera">
                            CRM is more than initiating contact with potential leads. It involves nurturing contacts and building loyalty while maintaining a dynamic repository of contact information and client history. Making this information accessible for collaborative teams and piping it out to other business software solutions via smart integrations is the logical next step.
                        </div>
                        <img className="d-md-none d-inline-block mb-4 max-w-250" src="https://content.harstatic.com/media/bgs/crm/cra_screen.png" />
                        <a href="/onboarding" className={joinWithSpace('btn btn--prominent btn--large', disabled && 'disabled')} onClick={handleGetStartedClick}>Get started</a>
                        
                    </div>		
                    <div className="hero--default__cols__right pb-md-5 pb-4">
                        
                        <img className="d-md-block d-none w-100 max-w-585" src="https://content.harstatic.com/media/bgs/crm/cra_screen.png" />
                    </div>
                </div>
            </div>

            <div className="container pt-5 mt-5 pb-5 page_bottom_border">
                <div className="sec-mrg--large">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <a href="#" className="card card--hover border_radius--rounded text-decoration-none color_carbon p-md-5 p-4 h-100 mb-0">
                                <div className="text-center d-flex justify-content-center min-h-260px"><img className="align-self-center max-w-170px" src="https://content.harstatic.com/media/bgs/crm/crm_screen_1.png" /></div>
                                <div className="font_size--large_extra_extra font_weight--semi_bold mb-2">Keep track and follow up with your contacts</div>
                                <div className="font_size--medium">Create tasks for yourself, manage your appointments, manage emails and activities faster. Do more for your leads.</div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <a href="#" className="card card--hover text-decoration-none color_carbon p-md-5 p-4 h-100 mb-0">
                                <div className="text-center d-flex justify-content-center min-h-260px"><img className="align-self-center max-w-170px" src="https://content.harstatic.com/media/bgs/crm/crm_screen_2.png"/></div>
                                <div className="font_size--large_extra_extra font_weight--semi_bold mb-2">Give your customers powerful services</div>
                                <div className="font_size--medium">With an incredible range of available services, provide your customers all the tools they need.</div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <a href="#" className="card card--hover text-decoration-none color_carbon p-md-5 p-4 h-100 mb-0">
                                <div className="text-center d-flex justify-content-center min-h-260px"><img className="align-self-center max-w-170px" src="https://content.harstatic.com/media/bgs/crm/crm_screen_3.png" /></div>
                                <div className="font_size--large_extra_extra font_weight--semi_bold mb-2">Boost your deals, potentialize your sales</div>
                                <div className="font_size--medium">Manage your leads in an easy and effective way, following up your leads through a visual pipeline.</div>
                            </a>
                        </div>
                    </div>    
                </div>
                
                <div className="text-center sec-mrg">
                    
                    <div className="pb-md-2 pt-md-5 pt-3">
                        <div className='pb-4 text-center'><img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='border_radius--12px w-90px'></img></div>
                        <a className="d-inline-block pr-2 pl-4 pb-3" target="_blank" href="https://apps.apple.com/us/app/har-crm/id6449685819"><img className=' h-65px' src="https://content.harstatic.com/media/icons/download-on-the-app-store-black.svg" alt="Download on the App Store" /></a>
                        <a className="d-inline-block pl-2 pb-3" target="_blank" href="https://play.google.com/store/apps/details?id=com.har.crm"><img className=' h-65px' src="https://content.harstatic.com/media/icons/google-play-badge_black.svg" alt="Get it on Google Play" /></a>
                    </div>

                    {/*
                    <h2 tabIndex={0} className="text-center h2--simple h2--simple__black">Sign up to HAR CRM to take your<br className="d-md-inline-block d-none" />Real Estate business to the next level</h2>
                    <a href="/onboarding" className={joinWithSpace('btn btn--prominent btn--large__extra', disabled && 'disabled')} onClick={handleGetStartedClick}>Get started now</a>
                    */}   
                  {/**   <div className="text-center text-center font_size--large color_cloudy_sky mt-5 pt-4">
                        <a className="color_carbon text-decoration-none" href="https://cms.har.com/privacy-policy-2/"  target="_blank">Privacy Policy</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp; 
                        <a className="color_carbon text-decoration-none" href="https://cms.har.com/termsofuse/" target="_blank">Terms of Use</a>
                    </div>
                </div>
                 
            </div>*/}
        </IonContent>
    );
}
