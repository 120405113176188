import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IErrorDetailProps, IImportProviderResultScreenProps } from './ImportProviderResultScreen.def';
import './ImportProviderResultScreen.scss';
import { OrdinaryButton } from 'views/forms/buttons/Button';
import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow, IonSegment } from '@ionic/react';
import { ImportResultApi } from 'services/contacts/provider/importResult';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { refreshCircleOutline } from 'ionicons/icons';

export const ImportProviderResultScreenDesktop = (props: IImportProviderResultScreenProps): JSX.Element => {
    const { result } = props;
    const [errorList, setList] = useState<ImportResultApi.IImportContactFailed[]>()
    const [error, showError] = useState(false)
    const [name, setName] = useState("")


    const history = useHistory()
    return (
        <>
            <ErrorDetailPopup show={error} errors={errorList} onClose={() => showError(false)} name={name} />
            <DefaultTemplate title="Import Contact">
                <div className="container">
                    <div className="row pt-4 pb-4">
                        <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-3">
                            <h2 tabIndex={(0)} className="h2--simple h2--simple__black mb-0">Import Result</h2>
                        </div>
                        <div className="col-sm-6 col-12 align-self-center text-left text-sm-right">
                            <OrdinaryButton onClick={() => history.push(`/contacts/import`)}>Back to Import Contact</OrdinaryButton>
                        </div>
                    </div>
                    <div >
                        {!result.loading && result.response?.total_added !== "0" &&

                            <div className="d-flex p-2">
                                <img className=' w-16px' src="https://content.harstatic.com/media/icons/check_green_2.svg" />
                                <div className='color_available align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_added} contact{Number(result.response?.total_added) > 1 ? `s` : ``} successfully added.
                                </div>
                            </div>
                        }
                        {!result.loading && result.response?.total_updated !== "0" &&
                            <div className="d-flex p-2">
                                <IonIcon icon={refreshCircleOutline} className='updatedIcon color_custom_5' ></IonIcon>
                                <div className='align-self-center font_weight--semi_bold font_size--medium pl-2 color_custom_5'>
                                    {result.response?.total_updated} contact{Number(result.response?.total_updated) > 1 ? `s` : ``} updated.
                                </div>
                            </div>
                        }

                        {!result.loading && result.response?.total_failed !== "0" &&

                            <div className="d-flex p-2">
                                <img className=' w-16px' src="https://content.harstatic.com/media/icons/icon-warning.svg" />
                                <div className='color_sold align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_failed} contact{Number(result.response?.total_failed) > 1 ? `s` : ``} failed to import.
                                </div>
                            </div>
                        }
                    </div>

                    <div className="cards p-0 overflow-inherit">


                        <IonSegment>
                            <IonGrid className="table ion-no-padding border_radius--default" fixed={false}>
                                <IonRow className="table__th ion-nowrap">
                                    <IonCol className=' max-w-40'></IonCol>
                                    <IonCol className=' max-w-200'><span className="font_weight--bold">Order number</span></IonCol>
                                    <IonCol className='max-w-200'><span className="font_weight--bold">Name</span></IonCol>
                                    <IonCol className='max-w-200'><span className="font_weight--bold">Email</span></IonCol>
                                    <IonCol><span className="font_weight--bold">Phone</span></IonCol>
                                    <IonCol><span className="font_weight--bold">Status</span></IonCol>
                                    <IonCol></IonCol>

                                </IonRow>
                                {result.loading &&
                                    <LoadingState />
                                }


                                {!result.loading && result.response?.list?.length === 0 &&
                                    <EmptyState />
                                }

                                {!result.loading && result.response?.list?.map((contact: ImportResultApi.IImportContactResult, index: number) =>

                                    <IonRow key={index} className={`ion-import-result-row ion-nowrap ${contact.status === 'added' && `bg_color_available_light`} 
                                ${contact.status !== 'updated' && contact.status !== 'added' && `bg_color_sold_light`}  
                                align-items-center`}>
                                        <IonCol className="p-0 max-w-40"><div className=' min-h-19px'></div></IonCol>
                                        <IonCol className=' max-w-200'>05523</IonCol>
                                        <IonCol className="font_weight--bold max-w-200">{contact.first_name + ' ' + contact.last_name}</IonCol>
                                        <IonCol className=' max-w-200'>{contact.primary_email}</IonCol>
                                        <IonCol>{contact.primary_phone}</IonCol>
                                        <IonCol className="font_weight--bold color_available">{contact.status}</IonCol>
                                        <IonCol>{contact.errors.length > 0 && <a id="error-modal" className='cursor--hand' onClick={() => {
                                            setList(contact.errors);
                                            setName(contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.primary_email ? contact.primary_email : contact.primary_phone)
                                            showError(true)


                                        }} >view detail</a>}</IonCol>

                                    </IonRow>
                                )}


                            </IonGrid>
                        </IonSegment>



                    </div>


                </div>
            </DefaultTemplate >
        </>
    );

}
const LoadingState = () => {
    return (
        <>

            <IonRow className="ion-nowrap h-50px">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-nowrap h-50px ">
                <IonCol className=' max-w-45'>

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text h-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
            <IonRow className="ion-now h-50px ">
                <IonCol className=' max-w-45' >

                </IonCol>
                <IonCol className="font_weight--bold max-w-200"><div className="loading_text w-90pr"></div></IonCol>
                <IonCol className=' max-w-200'><div className="loading_text w-90pr"></div></IonCol>
                <IonCol><div className="loading_text w-60pr"></div></IonCol>
                <IonCol>
                    <div className="loading_text h-60pr"></div>
                </IonCol>

            </IonRow>
        </>
    );
}


const EmptyState = () => {
    return (
        <IonRow className=" ion-nowrap py-2">
            <IonCol className=' max-w-40'></IonCol>
            <IonCol className="font_weight--bold">Sorry no contacts have been imported.</IonCol>
        </IonRow>
    );
}

const ErrorDetailPopup = (props: IErrorDetailProps) => {

    return (
        <IonModal isOpen={props.show} className='Popover' onIonModalDidDismiss={() => props.onClose?.()} backdropDismiss={false}>
            <div className="contact_detail position-relative min-h-250px p-4">
                <div className='position-absolute top--4px  right-0px'>

                    <IonButton className="modal-closebtn" slot="start" fill="clear" onClick={() => {
                        props.onClose?.()
                    }}>

                        <IonIcon slot="icon-only" name="close" color="dark"></IonIcon>
                    </IonButton>
                </div>
                <div className='d-flex border-bottom pb-3 pt-3'>
                    <div className='font_weight--semi_bold font_size--large text-truncate'>{props.name} </div>
                </div>
                <div className='pt-3'>
                    {props.errors?.map((error: ImportResultApi.IImportContactFailed) =>
                        <ul className='row '>
                            <li className='ml-3 color_sold text-break'>{error.message}</li>
                        </ul>
                    )}
                </div>
            </div>
        </IonModal >
    )

}
