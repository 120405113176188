
import './GlobalSearchTypeahead.scss';

import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { Typeahead } from '../Typeahead';
import { IGlobalSearchTypeaheadProps } from './GlobalSearchTypeahead.def';
import './GlobalSearchTypeahead.scss';
import { GlobalSearchApi, fetchGlobalSearchService } from 'services/typeaheads/globalsearch';
//import { Avatar } from 'views/contacts/Avatar';
import { useState } from 'react';

export const GlobalSearchTypeaheadApp = (props: IGlobalSearchTypeaheadProps): JSX.Element => {

    return (
        <>
            <Form {...props} />
        </>
    );
}
const transformResponse=(response:GlobalSearchApi.Response)=>{
   return [...response?.contacts?.list,...response?.tasks?.list,...response?.emails?.list,...response?.deals?.list,...response?.services?.list]
}

const Form = (props: IGlobalSearchTypeaheadProps): JSX.Element => {

    const { change ,initialValue} = props;
    const [input,setInput]=useState("")
    const search = (async (value: string) => {
        setInput(value)
        const response = await fetchGlobalSearchService({ input:value});
        return transformResponse(response)
    })
    const mapResult = (item: any): IDropDownOption => {
       return ({ id:item.id.toString(), text:item.text,title:item.title ,photo_url: item.photo_url,listItems:item.listItems,link:item.link,date:item.formatted_date,date_tag:item.date_tag });}
  
   return (
        <div>
            <Typeahead 
                placeholder='Search contacts, leads, events…'
                search={search}
                className={"header_search ml-auto"}
                optionsClassName='position-absolute right-160px zindex-9999 w-500px'
                mapResult={mapResult}
                initialValue={initialValue}
                minLength={2}
                onChange={change}
             //   width={100}
              /** itemTemplate={(item:any)=>()=>
                 <div className="typeahead__item pl-3 pt-3 mb-md-0">
                 <a href={item.link} className="agent_signature" >
                    <div className=' agent_signature__large'>
                     <Avatar id={item.id} firstName={item.title?.split(" ")?.[0]} lastName={item.title?.split(" ")?.[1]}  photo={item.photo_url}/></div>
                     <div className="agent_signature__text">
                        <div className="agent_signature__text__title"><span className="status_circle status_circle__pending"></span>{item.title}</div>
                        <div className="agent_signature__text__address">{item.text}</div>
                         <div className="agent_signature__text__tags">
                            {item.listItems?.slice(0,2).map((listItems:any,index:any)=> 
                          listItems.first_name? <div className="card--email__text text-truncate" key={index} >
                          <div className="agent_signature agent_signature__mini ">
                          <Avatar id={listItems.id} firstName={listItems.first_name} lastName={listItems.last_name}  photo={listItems.photo_url}/>
                              <div className="agent_signature__text">{listItems.first_name} {listItems.last_name}</div>
                          </div>
                         </div> : <>
                                <div className="agent_signature__text__tags_tag"><img src="https://content.harstatic.com/media/icons/tag_small.svg" className='pt-2'/> {listItems.name}</div>
                            </>)}
                         </div>
        
                         <div className="card--email__date">
                        {item?.date_tag?.toUpperCase()}{item?.date_tag&&" - "}{item.date}</div></div>
                             
              </a>
            </div>
            }  */ 
            footer={ <div className="notification_card_footer p-2 text-center">
              <a href={`/search/${input}`}>View all results for "{input}"</a>
             </div>}
           />
        </div>
    );
}
