
import { Draggable } from 'react-beautiful-dnd';
import { DealsCard } from '../DealsCard';
import { IDealsColumnProps, IDealsColumnsProps } from './DealsColumn.def';
import './DealsColumn.scss';
import { LazyScroll } from 'views/general/LazyScroll';
import { Tag } from 'scripts/ui/tag';


export const DealsColumn = (props: IDealsColumnProps): JSX.Element => {
    const { column, filterLoading, openAddPopUp, loadMore, openEditPopUp } = props
    return (
        <div className="deals_cols pipeline_col">
            <div className="pipeline_col_title position-sticky top-0">
                <Tag.Div className="deals_cals__title deals_column_bg" cssVars={{ dealColumnBG: `${column?.bgImage ? `url(` + column?.bgImage + `)` : ``}` }}>
                    <div className='row'>
                        <div className="col">{column?.title}</div>
                        <div className='col-auto'>
                            <a href='#' onClick={(e: any) => {
                                e.preventDefault();
                                openAddPopUp?.()

                            }}>
                                <img src={`https://content.harstatic.com/resource_2019/imgs/icons/pluse_wite_circle.svg`} className={column?.type === 'I' || column?.type === 'A' ? `mr-3` : ``} />
                            </a>
                        </div>
                    </div>
                </Tag.Div>
                <div className="deals_title_counter color_auxiliary font_size--medium"><span className="font_weight--semi_bold pr-2">{column?.totalAmountFormat}</span> <span className='color_custom_2'>{column?.count == 0 || column?.count == 1 ? column?.count + ' Deal' : column?.count + ' Deals'}  </span></div>
            </div>

            {column?.loading || filterLoading ? <Loading field={3} /> : ''
            }

            {!column?.loading && !filterLoading && column?.list?.length === 0 &&
                <Empty />
            }

            {!column?.loading && !filterLoading && column?.list && column?.list?.length > 0 &&


                <LazyScroll
                    hasScroll={column.page && column.totalPages && column.page < column.totalPages ? true : false}
                    page={column.page ? column.page : 0}
                    className={'deals_items pipeline_items fancy_scroll pb-3'}
                    onload={(_page) => {
                        loadMore?.(column.type)
                    }}

                >
                    <>
                        {column?.list?.map((deal, index) =>
                            <Draggable key={deal.id + "-" + index} draggableId={`${deal.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${snapshot.isDragging ? "dragginRotate" : ""}`}
                                    >
                                        <DealsCard
                                            deals={deal}
                                            openEditPopUp={openEditPopUp}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        )}
                    </>
                </LazyScroll>

            }
        </div>
    );


}

const Loading = (props: IDealsColumnsProps.loading) => {

    const { field } = props
    const LoadingField = (): JSX.Element => {
        return (
            <div className="cards cards__deals_wt cursor--hand ui-sortable-handle">
                <div className="listing_signature listing_signature__small mb-3">
                    <div className="loading__photo mr-3 h-40px w-40px"></div>
                    <div className="listing_signature__details">
                        <div className="loading__text_small mb-2 w-120px"></div>
                        <div className="loading__text_small w-150px"></div>
                    </div>
                </div>

                <div className="loading__text mb-2"></div>
                <div className="loading__text_small w-150px"></div>
                <div className="d-flex pt-3">
                    <div className="loading__square mr-2 w-18px h-18px"></div>
                    <div className="align-self-center"><div className="loading__text_small w-90px"></div></div>
                </div>
            </div>
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < field; i++) {
        rows[i] = i
    }
    return (
        <>
            <div className="deals_cals__body bg_color_cement_light">
                <div className="color_auxiliary font_size--medium loading__text_small  pb-3 pl-2"><span className="font_weight--semi_bold pr-2 loading__text_small w-70px h-18px"></span> <span className="loading__text_small color_custom_2 w-70px h-18px"></span></div>
                {
                    rows.map((index: number) =>
                        <LoadingField key={index} />
                    )
                }
            </div>
        </>
    );
}

const Empty = (_props: IDealsColumnsProps.empty) => {
    return (
        <div className="deals_cals__body bg_color_cement_light min-h-110px"></div>
    );
}
