import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace SortStagesApi
{
    export const url = "/contacts/sort_pipeline";
    export const method = "POST";
    export const defaultError = "Failed to update stage.";
    export const operations = apiOperations(SortStagesApi);
    export const scope = new class SortStagesApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string | number>
        {
            return {
				pipeline_status:Number(input.pipeline_status),
                positions:input.positions
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        pipeline_status:number,
        positions:any
    }

    export interface Response
    {
        status:string,
        message:string
    }

    
}

export const fetchSortStagesService = forwardOperation(SortStagesApi.operations, 'fetch');
export const useFetchSortStagesService = forwardOperation(SortStagesApi.operations, 'useFetch');
export const useSortStagesService = forwardOperation(SortStagesApi.operations, 'useService');