import { IQuickEditPopupProps } from './QuickEditPopup.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { QuickEditPopupApp } from './QuickEditPopup.app';
import { QuickEditPopupDesktop } from './QuickEditPopup.desktop';

export const QuickEditPopup = (props: IQuickEditPopupProps): JSX.Element =>
{
    const QuickEditPopupPlatform = useResponsiveChoose(QuickEditPopupDesktop, QuickEditPopupApp);
    return (
        <QuickEditPopupPlatform {...props} />
    );
}
