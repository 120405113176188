import { IChangeAliasPopUpProps } from './ChangeAliasPopUp.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ChangeAliasPopUpApp } from './ChangeAliasPopUp.app';
import { ChangeAliasPopUpDesktop } from './ChangeAliasPopUp.desktop';

export const ChangeAliasPopUp = (props: IChangeAliasPopUpProps): JSX.Element =>
{
    const ChangeAliasPopUpPlatform = useResponsiveChoose(ChangeAliasPopUpDesktop, ChangeAliasPopUpApp);
    return (
        <ChangeAliasPopUpPlatform {...props} />
    );
}
