import { useState } from 'react';
import { IAddTaskPopUpProps } from './AddTaskPopUp.def';
import './AddTaskPopUp.scss';

import { TaskFormApp } from './components/TaskFormApp';
import { ChooseTaskModal } from './components/ChooseTaskModal/ChooseTaskModal';

export const AddTaskPopUpApp= (props:IAddTaskPopUpProps): JSX.Element =>
{     const [showTask,toggleTask]=useState(false);
    const [category,setCategory]=useState("t");
    const handleRefresh=()=>{}
    return (
         <div>
        <ChooseTaskModal {...props} setCategory={setCategory} toggleTask={toggleTask}/>
        {showTask&&<TaskFormApp refreshData={props.refreshData||handleRefresh} show={showTask} toggle={toggleTask} category={category} />}
    </div>
    );
}