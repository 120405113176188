export const requiredMessage = () => 'The field is required';

export const requiredContactMessage = () =>
    `Please provide at least one of the following:<br />first name, last name or company, along with either a primary phone number or primary email address`;

export const invalidEmailMessage = () =>
    `${limitMessage(50, 8)}, qw@qw.co`;

export const invalidPhoneMessage = () =>
    limitMessage(15, 3, { chars: 'digits' });

export const contactCreatedMessage = () =>
    "The contact has been successfully added";

export const contactUpdatedMessage = () =>
    "The contact has been successfully updated";

export const limitMessage = (max:number, min?:number, { chars = 'characters' }:{ chars?:string } = {}) =>
    `This field should contain${cond` min ${min}`} max ${max} ${chars}`;

export const duplicatedContactMessage = () =>
    `<p>The contact you are attempting to add is not a unique contact, and therefore, can not be added.</p>
    <p>To be considered unique, a contact must not have:</p>
    <p class="mb-0">• the same First Name OR Last Name OR Company Name</p>
    <p class="mb-0"><strong>AND</strong></p>
    <p>• the same Email OR Phone Number.</p>
    <p class="mt-4 mb-0">Email and phone number can be not unique in the list of all contacts but should be unique inside each contact</p>`;


function cond(input:TemplateStringsArray, ...values:unknown[])
{
    const messages = Array.from(input);
    if(!values.length || values.some(v => v === undefined || v === null || v === '')) { return ''; }
    const result = new Array<string>();
    while(messages.length || values.length)
    {
        result.push(`${messages.shift() ?? ''}${values.shift() ?? ''}`);
    }

    return result.join('');
}