
import { ContactsTable } from 'views/contacts/ContactsTable';
import { IContactsLandingScreenProps } from './ContactsLandingScreen.def';
import './ContactsLandingScreen.scss';
import { ContactManageTemplate } from 'views/templates/ContactManageTemplate';
import { useHistory, useLocation } from 'react-router';
import { IContactsFilters, ILocationFilter } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { useEffect, useState } from 'react';
import { useMatrix } from '../hooks/useMatrix';
import { useCSVImportStatus } from '../hooks/useCSVImportStatus';




export const ContactsLandingScreenApp = (props: IContactsLandingScreenProps): JSX.Element => {
    const [filters, saveFilters] = useState<IContactsFilters>({})
    const { matrixContactStatus, matrixContactImportStatusId, MatrixImportMessage, IntiateMatrix } = useMatrix()
    const { csvImporting, CSVStatusBlock } = useCSVImportStatus()
    const setFilter = (param: any, reload: number) => {
        if (props.changeFilter) {
            props.changeFilter(param, reload)
            saveFilters(param)
        }
    }


    const downloadContact = (param: any) => {
        if (props.downloadContact) {
            const result = props.downloadContact(param)
            return result;
        }
    }

    let history = useHistory();
    let location = useLocation<ILocationFilter>();
    useEffect(() => {
        if (location?.state && location.state.filters) {
            saveFilters(location.state.filters)
        }
    }, [location.state])
    useEffect(() => {
        if (matrixContactStatus?.toLocaleLowerCase() === "done") {
            props.reload?.()
        }
    }, [matrixContactStatus]);
    return (
        <ContactManageTemplate downloadContact={() => downloadContact([])} onSwipeRefresh={props.reload} showImportPopUp={props.showImportPopUp}
            reload={props.reload}
            setFilter={(id: string, name: string) => {
                if (!filters.tags?.find((tag) => tag.id === Number(id))) {
                    props.changeFilter?.({ 'tags': [{ ...filters, id: id, name: name }], sort_by: '', sort_dir: '' }, 1)
                    saveFilters({ ...filters, tags: [...filters.tags ? filters.tags : [], { 'id': Number(id), 'name': name }] })
                }
            }} matrixStatusId={matrixContactImportStatusId} matrixInit={IntiateMatrix}>
            <div>
                <nav id="ContactTabs" className="nav nav-tabs nav-tabs--simple  nav-tabs--fullwidth">
                    <a title="Contact Management" className="mt-2 mt-md-5 nav-item nav-link active" onClick={() => history.push('/contacts', { 'filters': filters })} >
                        Contact Management
                    </a>
                    <a title="Contact Management" className="mt-2 mt-md-5 nav-item  nav-link" onClick={() => history.push('/contacts/pipeline', { 'filters': filters })} >
                        Contact Pipeline
                    </a>
                </nav>

                {matrixContactStatus &&
                    <MatrixImportMessage />
                }

                {csvImporting &&
                    <CSVStatusBlock />
                }

                <div>
                    <ContactsTable
                        result={props.result}
                        contacts={props.contacts}
                        file={props.file}
                        page={props.page}
                        filters={filters}
                        isDownloading={props.isDownloading}
                        duplicate={props.duplicate}
                        reload={props.reload}
                        loadMore={props.loadMore}
                        downloadContact={downloadContact}
                        changeFilter={setFilter}
                    />
                </div>
            </div>
        </ContactManageTemplate>
    );
}


