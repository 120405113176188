import { useResponsiveChoose } from "scripts/hooks/general";
import { ChooseTaskModalApp } from './ChooseTaskModal.app';
import { ChooseTaskModalDesktop } from './ChooseTaskModal.desktop';
import { IChooseTaskModalProps } from "../../AddTaskPopUp.def";

export const ChooseTaskModal = (props: IChooseTaskModalProps): JSX.Element =>
{
    const AddTaskPopUpPlatform = useResponsiveChoose(ChooseTaskModalDesktop, ChooseTaskModalApp);
    return (
        <AddTaskPopUpPlatform {...props} />
    );
}
