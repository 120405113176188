import { IContactsLandingScreenProps } from './ContactsLandingScreen.def';
import { ContactsLandingScreenApp } from './ContactsLandingScreen.app';
import { ContactsLandingScreenDesktop } from './ContactsLandingScreen.desktop';
import { useSearchContactsService } from 'services/contacts/searchContacts';
import { useEffect, useState } from 'react';
import { useDownloadContactsService } from 'services/contacts/downloadContacts';
import { useResponsiveChoose } from 'scripts/hooks/general';
import { useLocation } from 'react-router';
import { ILocationFilter } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';

export const ContactsLandingScreen = (props: IContactsLandingScreenProps): JSX.Element => {
    const location = useLocation<ILocationFilter>()
    const [filters, setFilterState] = useState({
        contact_ids: [],
        page: 1,
        page_size: 50,
        sort_by: "last_contact_date",
        sort_dir: "",
        name: "",
        keyword: "",
        cities: [""],
        zip_codes: [""],
        tag_ids: [""],
        stages: [],
        object_owners: [""],
        scores: [""],
        sources: [""],
        companies: [""],
        deal_stages: [""],
        last_contact_date: "",
        renew: 1,
        show_duplicates: 0,
        is_filter: '1',
        birthday_start: "",
        birthday_end: "",
        home_anniversary_start: "",
        home_anniversary_end: "",
        wedding_anniversary_start: "",
        wedding_anniversary_end: "",
        known_since_start: "",
        known_since_end: "",
        lead_type: "",

    });
    const [sorted, setSorted] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)
    const [contacts, setContact] = useState<any[]>([]);
    const [downloading, setDownloading] = useState(false)
    const [downloadFile, setDownloadFile] = useState('')
    const [pages, setPages] = useState<number>()
    const [showImportPopup, setShowImportPopup] = useState(false)

    const changeFilter = (param: any) => {
        if (param.hasOwnProperty('sort_by')) {
            setSorted(true)
        }
        let newScore: any[] = [];
        if (param.hasOwnProperty('scores')) {
            param.scores.map((score: any) =>
                newScore.push(score)
            )
        }
        let newTags: string[] = [];
        if (param.hasOwnProperty('tags')) {
            if (param.tags.length > 0) {
                param.tags.map((tag: any) =>
                    newTags.push(tag.id.toString())
                )
            } else {
                newTags = []
            }
        }
        let newOwners: any[] = [];
        if (param.owners?.length) {
            param.owners.map((owner: any) =>
                newOwners.push(owner.agent_key)
            )
        }
        setFilterState({
            ...filters,
            page: param.hasOwnProperty('page') ? param.page : filters.page,
            page_size: param.hasOwnProperty('page_size') ? param.page_size : filters.page_size,
            sort_by: param.hasOwnProperty('sort_by') ? param.sort_by : filters.sort_by,
            sort_dir: param.hasOwnProperty('sort_dir') ? param.sort_dir : filters.sort_dir,
            name: param.hasOwnProperty('name') ? param.name : filters.name,
            keyword: param.hasOwnProperty('keyword') ? param.keyword : filters.keyword,
            cities: param.hasOwnProperty('cities') ? param.cities : filters.cities,
            zip_codes: param.hasOwnProperty('zip_codes') ? param.zip_codes : filters.zip_codes,
            tag_ids: param.hasOwnProperty('tags') ? newTags : filters.tag_ids,
            stages: param.hasOwnProperty('stages') ? param.stages : filters.stages,
            object_owners: param.hasOwnProperty('owners') ? newOwners : filters.object_owners,
            scores: param.hasOwnProperty('scores') ? newScore : filters.scores,
            sources: param.hasOwnProperty('sources') ? param.sources : filters.sources,
            companies: param.hasOwnProperty('companies') ? param.companies : filters.companies,
            deal_stages: param.hasOwnProperty('deal_stages') ? param.deal_stages : filters.deal_stages,
            last_contact_date: param.hasOwnProperty('last_contact_date') ? param.last_contact_date : filters.last_contact_date,
            renew: 1,
            show_duplicates: param.hasOwnProperty('show_duplicates') ? param.show_duplicates : filters.show_duplicates,
            is_filter: '1',
            birthday_start: param.hasOwnProperty('birthday_start') ? param.birthday_start : filters.birthday_start,
            birthday_end: param.hasOwnProperty('birthday_end') ? param.birthday_end : filters.birthday_end,
            home_anniversary_start: param.hasOwnProperty('home_anniversary_start') ? param.home_anniversary_start : filters.home_anniversary_start,
            home_anniversary_end: param.hasOwnProperty('home_anniversary_end') ? param.home_anniversary_end : filters.home_anniversary_end,
            wedding_anniversary_start: param.hasOwnProperty('wedding_anniversary_start') ? param.wedding_anniversary_start : filters.wedding_anniversary_start,
            wedding_anniversary_end: param.hasOwnProperty('wedding_anniversary_end') ? param.wedding_anniversary_end : filters.wedding_anniversary_end,
            known_since_start: param.hasOwnProperty('known_since_start') ? param.known_since_start : filters.known_since_start,
            known_since_end: param.hasOwnProperty('known_since_end') ? param.known_since_end : filters.known_since_end,
            lead_type: param.hasOwnProperty('lead_type') ? param.lead_type : filters.lead_type,


        })
    }

    //getting contacts
    const [contactService, contactServiceAction] = useSearchContactsService(filters)
    const loadContactService = (param?: any) => {
        contactServiceAction.fetch(param ?? filters);
    }

    const checkDuplicate = (contact: any): boolean => {
        let searchContact = contacts.filter((cont: any) => {
            return contact.id === cont.id
        });
        return (searchContact.length > 0) ? false : true
    }
    //loading Contacts
    const loadWithContacts = () => {
        const newContacts: any = [];
        if (contactService.response) {
            contactService.response?.list.map((contact) => {
                if (checkDuplicate(contact)) {
                    newContacts.push(contact);
                }
            })
            setContact([...contacts, ...newContacts]);
        }
    }


    //Paging
    const loadMore = () => {
        if (contactService.response?.page) {
            let param = {
                page: contactService.response.page + 1,
            }
            changeFilter(param)
        }
    }

    const toogleDuplicate = (show: boolean) => {
        setSorted(true)
        setFilterState({
            contact_ids: [],
            page: 1,
            page_size: 20,
            sort_by: show ? "first_name" : "last_contact_date",
            sort_dir: show ? "asc" : '',
            name: "",
            keyword: "",
            cities: [""],
            zip_codes: [""],
            tag_ids: [""],
            stages: [],
            object_owners: [""],
            scores: [""],
            sources: [""],
            companies: [""],
            deal_stages: [""],
            last_contact_date: "",
            renew: 1,
            show_duplicates: show ? 1 : 0,
            is_filter: '1',
            birthday_start: "",
            birthday_end: "",
            home_anniversary_start: "",
            home_anniversary_end: "",
            wedding_anniversary_start: "",
            wedding_anniversary_end: "",
            known_since_start: "",
            known_since_end: "",
            lead_type: "",

        })

    }


    //Downloading Stuff
    const [downloadContactsService, downloadContactsServiceAction] = useDownloadContactsService();
    const downloadContacts = (param: any) => {
        let arr: any = [];
        param.map((contact: any) =>
            arr.push(contact.id)
        )
        let param2: any = { contact_ids: arr, format: "csv" }
        downloadContactsServiceAction.fetch(param2)
        setDownloading(true)

    }
    if (downloadContactsService.response?.file && downloading) {
        setDownloadFile(downloadContactsService.response?.file)
        setDownloading(false)
    }


    useEffect(() => {
        if (location.state && location.state.filters) {
            const filters = { ...location.state.filters, ['keyword']: '' };
            changeFilter(filters)
        } else {
            loadContactService()
        }

        if (location.state && location.state.showImportPopup) {
            setShowImportPopup(true)
        }

    }, [])

    useEffect(() => {
        if (initialLoad) {
            loadContactService();
        }
        setInitialLoad(true)
    }, [filters])



    useEffect(() => {
        if (sorted) {
            setContact([])
        }
        setSorted(false)
        setPages(contactService.response?.page)
        loadWithContacts()
    }, [contactService])

    useEffect(() => {
        if (!downloading) {
            setDownloadFile('')
        }
    }, [downloading])

    const ContactsLandingScreenPlatform = useResponsiveChoose(ContactsLandingScreenDesktop, ContactsLandingScreenApp);
    return (
        <ContactsLandingScreenPlatform {...props}
            contacts={contacts}
            result={contactService}
            file={downloadFile}
            page={pages}
            filters={filters}
            showImportPopUp={showImportPopup}
            isDownloading={downloadContactsService.loading}
            reload={() => {
                setSorted(true)
                loadContactService({ ...filters, page: 1 })
            }}
            duplicate={toogleDuplicate}
            changeFilter={changeFilter}
            loadMore={loadMore}
            downloadContact={downloadContacts}

        />
    );
}
