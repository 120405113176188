import { INewEmailProps } from './NewEmail.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { NewEmailApp } from './NewEmail.app';
import { NewEmailDesktop } from './NewEmail.desktop';

export const NewEmail = (props: INewEmailProps): JSX.Element => {
    const NewEmailPlatform = useResponsiveChoose(NewEmailDesktop, NewEmailApp);
    return (
        <NewEmailPlatform {...props} />
    );
}
