import { IContactDetailScreenProps, ITemplateProps } from './ContactDetailScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ContactDetailScreenApp } from './ContactDetailScreen.app';
import { ContactDetailScreenDesktop } from './ContactDetailScreen.desktop';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { useState } from 'react';
import { IUpdateState } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { EditContactScreen } from '../EditContactScreen';

export const ContactDetailScreen = (props: IContactDetailScreenProps): JSX.Element =>
{
    const ContactDetailScreenPlatform = useResponsiveChoose(ContactDetailScreenDesktop, ContactDetailScreenApp);
    return <Template {...props} Component={ContactDetailScreenPlatform} props={{}} />;
}

export const EditContactDetailScreen = (props: IContactDetailScreenProps): JSX.Element =>
{
  return <Template {...props} Component={EditContactScreen} props={{}} />;
}

function Template<P extends IContactDetailScreenProps>(props:ITemplateProps<P>): JSX.Element
{
    const [reload, doReload] = useState(1);
    const [updatedState, setUpdatedState] = useState<Array<IUpdateState>>();
    const [lastSummaryChange, setLastSummaryChange] = useState<Date>();
    const { Component, props:componentProps } = props;
    
    const getChangedValue = (value: Array<IUpdateState>) =>
    {
        setUpdatedState(value);
    }

    const onSummaryChange = () =>
    {
        props.onSummaryChange?.();
        setLastSummaryChange(new Date());
    }

    return (
        <ContactDetailsTemplate selectedMenu='ContactDetails' lastChange={lastSummaryChange} valueChanged={updatedState} onSwipeRefresh={() => doReload(reload + 1)}>
            <Component {...componentProps} onSummaryChange={onSummaryChange} onChange={getChangedValue} />
        </ContactDetailsTemplate>
    );
}
