import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace RemoveDealApi {
    export const url = "/deals/:deal_id";
    export const method = "DELETE";
    export const defaultError = "Something went wrong in removing this deal.";
    export const operations = apiOperations(RemoveDealApi);
    export const scope = new class RemoveDealApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        deal_id: string
    }

    export interface Response {

    }
}

export const fetchRemoveDealService = forwardOperation(RemoveDealApi.operations, 'fetch');
export const useFetchRemoveDealService = forwardOperation(RemoveDealApi.operations, 'useFetch');
export const useRemoveDealService = forwardOperation(RemoveDealApi.operations, 'useService');