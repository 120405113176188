
export interface IUseTimeoutOptions
{
    renew?:boolean;
}

 
type minNumber = "min:"[number];
type email = "email"
type req = "req"
type maxNumber = "max:"[number];
 
export type validationFormType = minNumber | email | req | maxNumber


export type validationFormMessage<T> = {
    key?:T,
    message?:string
}


export function validateField<T>(key:T,value:string|undefined,validate:validationFormType[],name?:string):string{
    var message =""
    let field = name ? name :key
    for (var i = 0; i < validate.length; i++) {    
        if(validate[i] && validate[i] === 'req'){
            if(!value || value === "") {
                return field + " is required field"
            }
        }
        if(validate[i] && validate[i]?.includes("min:")){
            let sep = validate[i]?.split(":")
            if(sep && sep[1]){
                 let limit = Number(sep[1])
                if(value && value.length <= limit){
                    return field + " length must be greater than "+limit
                }
            }
        }
        if(validate[i] && validate[i]?.includes("max:")){
            let sep = validate[i]?.split(":")
            if(sep && sep[1]){
                 let limit = Number(sep[1])
                if(value && value.length > limit){
                    return field + " length must be less than "+limit
                }
            }
        }
        if(validate[i] && validate[i] === 'email'){
            const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if(value && !regex.test(value)){
                return field + " should be in valid format."
            }
        }       
    }
    return message
}