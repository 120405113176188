import { IAssignStageProps } from './AssignStage.def';
import './AssignStage.scss';

import { stagesOptions } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';

export const AssignStageDesktop = (props: IAssignStageProps): JSX.Element => {
    const { stage, setStage } = props

    return (
        <>
            <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Select Stages</div>
            <div className="row">
                {stagesOptions.filter((s) => s.short_value !== 0).map((item, index) => {
                    const checked = item.short_value.toString() == stage;
                    return (
                        <div className="col-6 pb-2" key={index}>
                            <div role="radio" className="custom-control custom-radio">
                                <input type="radio" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} className="custom-control-input" onChange={() => setStage(`${item.short_value}`)} />
                                <label className='custom-control-label stage pt-1' htmlFor={`${item.short_value}`}><div className={`stage ${item.class}`}>{item.long_value}</div></label>
                            </div>
                        </div>
                    )
                })}
            </div>

        </>
    );
}
