import { isPlatform } from '@ionic/react';
import { ReactElement, createContext, useContext } from 'react';
import { useResponsive, useResponsiveChoose } from 'scripts/hooks/general';

export const platform = {
    get isApp() { return isPlatform('capacitor'); },
    get isAndroid() { return isPlatform('android'); },
    get isIos() { return isPlatform('ios'); },
    get isDesktop() { return isPlatform('desktop'); },
    get isMobileWeb() { return isPlatform('mobileweb'); },
    get isWeb() { return this.isDesktop || this.isMobileWeb; },
};

export const origin = isPlatform('capacitor')? 'com.har.crm:/': window.location.origin + (process.env.PUBLIC_URL ?? '');

const PlatformContext = createContext<'app' | 'desktop'>(platform.isApp? 'app': 'desktop');
//export const PlatformProvider = PlatformContext.Provider;

export function useNativeDevice(): boolean;
export function useNativeDevice<TDesktop, TApp>(desktopValue:TDesktop, appValue:TApp): TDesktop | TApp;
export function useNativeDevice(...args:[unknown?, unknown?, unknown?]): unknown | boolean
{
    const value = useContext(PlatformContext);
    const isApp = value === 'app';
    if(arguments.length === 0) { return isApp; }
    return isApp? args[1]: args[0];
}

function useNativeComponent<TDesktop extends JSX.Element | ((...args:any[]) => ReactElement), TApp extends JSX.Element | ((...args:any[]) => ReactElement)>
    (Desktop:TDesktop, App:TApp): TDesktop | TApp
{
    const value = useNativeDevice();
    return value? App: Desktop;
}



const nativeAsResponsiveEnv = process.env.REACT_APP_AS_RESPONSIVE?.trim();
const nativeAsResponsive = nativeAsResponsiveEnv === 'true' || nativeAsResponsiveEnv === '1';

export const useDevice:typeof useNativeDevice = nativeAsResponsive? useResponsive: useNativeDevice;
export const useComponent = nativeAsResponsive? useResponsiveChoose: useNativeComponent;