import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdatePhotoApi
{
    export const url = "/contacts/:contact_id/updatephoto";
    export const method = "POST";
    export const defaultError = "Failed to update contact photo";
    export const operations = apiOperations(UpdatePhotoApi);
    export const scope = new class UpdatePhotoApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        photo_key:string ,
        photo_ext:string,
        contact_id:string
    }

    export interface Response
    {
        
    }
}

export const fetchUpdatePhotoService = forwardOperation(UpdatePhotoApi.operations, 'fetch');
export const useFetchUpdatePhotoService = forwardOperation(UpdatePhotoApi.operations, 'useFetch');
export const useUpdatePhotoService = forwardOperation(UpdatePhotoApi.operations, 'useService');