import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DownloadDocumentApi
{
    export const url = `/contacts/:contact_id/documents/:document_id`;
    export const method = "GET";
    export const defaultError = "The File is not downloaded.";
    export const contentType = 'blob';

    export const operations = apiOperations(DownloadDocumentApi);
    export const scope = new class DownloadDocumentApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
                ...input

            }
        }

        response(input: Response): Response {
            return input;
        }
    }();
    
    export interface Request
    {
        document_id:string;
        contact_id:string;
        no_redirect:string;
        content_type:string
        
    }

    export type Response = string|{
      data:{ url:string}
    };

}

export const fetchDownloadDocumentService = forwardOperation(DownloadDocumentApi.operations, 'fetch');
export const useFetchDownloadDocumentService = forwardOperation(DownloadDocumentApi.operations, 'useFetch');
export const useDownloadDocumentService = forwardOperation(DownloadDocumentApi.operations, 'useService');
