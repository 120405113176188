import { ISortPopoverProps } from './SortPopover.def';
import { Dropdown } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import './SortPopover.scss';
import { useEffect, useState } from 'react';

export const SortPopover = (props: ISortPopoverProps): JSX.Element => {
    const [sort, setSort] = useState(props.sort?.sort_by ? props.sort?.sort_by : 'last_contact_date')
    const [dir, setDir] = useState<'asc' | 'desc'>(props.sort?.sort_dir ? props.sort?.sort_dir : 'desc')

    const doSort = (str: string) => {
        setSort(str)
        props.doSort?.({ sort_by: str, sort_dir: dir, page: 1 })
    }

    const setDirection = (di: 'asc' | 'desc') => {
        setDir(di)
        props.doSort?.({ sort_by: sort, sort_dir: di, page: 1 })
    }

    useEffect(() => {
        if (props.duplicate) {
            setSort('first_name')
            setDir('asc')
        } else {
            setSort('last_contact_date')
            setDir('desc')
        }
    }, [props.duplicate])

    useEffect(() => {
        if (props.sort?.sort_by) {
            setSort(props.sort.sort_by)

        }

        if (props.sort?.sort_dir) {
            setDir(props.sort.sort_dir)

        }

    }, [props.sort])



    return (
        <Dropdown className={`dropdown dropdown--custom dropdown--custom__inverse dropdown__alltprop d-inline-block ${props.duplicate && `opacity-64pr`}`}>
            <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" disabled={props.duplicate} variant="none" href="#">
                <button className={`actionsbtn px-4 ml-2`} aria-label="Filter by price">Sort</button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu agent_dropdown animate slideIn p-4 max-h-auto zindex-100">
                <div className='min-w-350px zindex-100'>
                    <div className='row border-bottom'>
                        <div className='col-6'><h3 className="form_sec_title">Sort by: {dir.toUpperCase()}</h3></div>
                        <div className='col-auto ml-auto'>
                            <a className={`font-15 text-decoration-none ${dir === 'desc' && `cursor--hand`}`} onClick={() => dir === 'desc' && setDirection("asc")}>
                                {dir === 'asc' ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-arrow-down-fill" viewBox="0 0 16 16">
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-arrow-down" viewBox="0 0 16 16">
                                        <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                    </svg>
                                }
                            </a>

                            <a className={`font-15 text-decoration-none ${dir === 'asc' && `cursor--hand`}`} onClick={() => dir === 'asc' && setDirection("desc")}>
                                {dir === 'desc' ?

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-arrow-up-fill" viewBox="0 0 16 16">
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M7.5 6.707 6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0z" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-arrow-up" viewBox="0 0 16 16">
                                        <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                    </svg>
                                }
                            </a>
                        </div>

                    </div>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__fullwidth" type="radio" name="options" defaultValue={'first name'}>
                        <div className='row pt-3 pb-1'>
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `last_contact_date` && `active`}`} onClick={() => doSort("last_contact_date")}>Last Updated</label>
                            </div>
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `first_name` && `active`}`} onClick={() => doSort("first_name")}>First Name</label>
                            </div>
                        </div>
                        <div className='row pb-1'>
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `last_name` && `active`}`} onClick={() => doSort("last_name")}>Last Name</label>

                            </div> 
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `score` && `active`}`} onClick={() => doSort("score")}>Score</label>
                            </div>
                        </div>
                        <div className='row pb-1'>
                            
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `pipeline_status` && `active`}`} onClick={() => doSort("pipeline_status")}>Stage</label>

                            </div>
                            <div className='col-6'>
                                <label className={`btn--medium font-weight_bold btn btn-primary ${sort === `sources` && `active`}`} onClick={() => doSort("sources")}>Source</label>
                            </div>

                        </div>


                    </ToggleButtonGroup>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
