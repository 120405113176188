import { useComponent } from 'scripts/context';
import { ContactsFilterPopUpApp } from './ContactsFilterPopUp.app';
import { IContactsFilterPopUpProps } from './ContactsFilterPopUp.def';
import { ContactsFilterPopUpDesktop } from './ContactsFilterPopUp.desktop';
import { useState } from 'react';
import { useTimeout } from 'scripts/hooks/timers';
import { useSearchContactsCountService } from 'services/contacts/searchContactsCount';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';

export const ContactsFilterPopUp = (props: IContactsFilterPopUpProps): JSX.Element => {
    const Component = useComponent(ContactsFilterPopUpDesktop, ContactsFilterPopUpApp);
    const { filters, filtersCount, onChange, ...filtersProps } = props;
    const [count, setCount] = useState<number>();
    const { cities, companies, stages, deal_stages, scores, owners, zip_codes, tags, sources, last_contact_date, birthday_start, birthday_end, home_anniversary_start, home_anniversary_end,
        wedding_anniversary_start, wedding_anniversary_end, known_since_start, known_since_end, lead_type } = filters ? filters : filtersProps;
    const { schedule: scheduleCounter } = useTimeout(loadCount, 1000);
    const [contactsCount, contactsCountActions] = useSearchContactsCountService();

    function loadCount() {
        if (filterCount() === 0) {
            setCount(undefined)
            return
        }
        let newScore: any[] = [];
        if (scores?.length) {
            scores.map((score) =>
                newScore.push(score)
            )
        }

        let newTags: any[] = [];
        if (tags?.length) {
            tags.map((tag) =>
                newTags.push(tag.id)
            )
        }

        let newOwners: any[] = [];
        if (owners?.length) {
            owners.map((owner) =>
                newOwners.push(owner.agent_key)
            )
        }
        contactsCountActions.fetch({
            cities: cities ? cities?.join(',').toString() : '',
            zip_codes: zip_codes ? zip_codes?.join(',').toString() : '',
            deal_stages: deal_stages ? deal_stages.join(',').toString() : '',
            scores: newScore ? newScore.join(',').toString() : '',
            object_owners: newOwners ? newOwners.join(',').toString() : '',
            stages: stages ? stages.join(',').toString() : '',
            companies: companies ? companies.join(',').toString() : '',
            tag_ids: newTags ? newTags.join(',').toString() : '',
            sources: sources ? sources.join(',').toString() : '',
            last_contact_date: last_contact_date ?? '',
            birthday_start: birthday_start ? birthday_start : '',
            birthday_end: birthday_end ? birthday_end : '',
            home_anniversary_start: home_anniversary_start ? home_anniversary_start : '',
            home_anniversary_end: home_anniversary_end ? home_anniversary_end : '',
            wedding_anniversary_start: wedding_anniversary_start ? wedding_anniversary_start : '',
            wedding_anniversary_end: wedding_anniversary_end ? wedding_anniversary_end : '',
            known_since_start: known_since_start ? known_since_start : '',
            known_since_end: known_since_end ? known_since_end : '',
            lead_type: lead_type,
            is_filter: '1'
        })
    }


    const checkCount = (total: number) => {
        if (total === 0) {
            setCount(undefined)
        } else {
            setCount(total)
        }
    }

    const filterCount = () => {
        var i = 0
        if (filters) {
            if (filters.cities && filters.cities.length > 0) {
                i = i + filters.cities.length
            }
            if (filters.scores && filters.scores.length > 0) {
                i = i + filters.scores.length
            }
            if (filters.stages && filters.stages.length > 0) {
                i = i + filters.stages.length
            }
            if (filters.tags && filters.tags.length > 0) {
                i = i + filters.tags.length
            }
            if (filters.deal_stages && filters.deal_stages.length > 0) {
                i = i + filters.deal_stages.length
            }
            if (filters.owners && filters.owners.length > 0) {
                i = i + filters.owners.length
            }
            if (filters.zip_codes && filters.zip_codes.length > 0) {
                i = i + filters.zip_codes.length
            }
            if (filters.companies && filters.companies.length > 0) {
                i = i + filters.companies.length
            }
            if (filters.sources && filters.sources.length > 0) {
                i = i + filters.sources.length
            }

            if (filters.birthday_start || filters.birthday_end) {
                i = i + 1
            }

            if (filters.home_anniversary_start || filters.home_anniversary_end) {
                i = i + 1
            }

            if (filters.wedding_anniversary_start || filters.wedding_anniversary_end) {
                i = i + 1
            }

            if (filters.known_since_start || filters.known_since_end) {
                i = i + 1
            }

            if (filters.lead_type) {
                i = i + 1
            }

            if (filters.last_contact_date) {
                i = i + 1
            }
        }

        return i;

    }

    const overideClear = () => {
        setCount(undefined)
        props.onClear?.()
    }


    useServiceDidSucceeded(contactsCount, (resp) => {
        checkCount(resp.total)
    });

    return <Component count={count} loadCount={scheduleCounter} contactsApiLoading={contactsCount.loading} {...props} onClear={overideClear} />;
}
