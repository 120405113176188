import { IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { fetchListingsService, ListingsApi } from 'services/typeaheads/listings';
import { OrdinaryButton, PrimaryButton } from 'views/forms/buttons/Button';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { Typeahead } from '../Typeahead';
import { IPropertyTypeaheadProps } from './PropertyTypeahead.def';
import './PropertyTypeahead.scss';
import { Tag } from 'scripts/ui/tag';
import { allEmpty } from 'scripts/helpers/texts';
export const PropertyTypeaheadDesktop = (props: IPropertyTypeaheadProps): JSX.Element => {
    const [showingCustom, setShowingCustom] = useState(false)
    const [signal, setSignal] = useState(false)
    useEffect(() => {
        if (signal) {
            setSignal(false)
        }
    }, [signal])
    return (
        <>
            <Form {...props} setShowingCustom={setShowingCustom} showingCustom={showingCustom} signal={signal} />
            <Selection {...props} setShowingCustom={setShowingCustom} showingCustom={showingCustom} setSignal={setSignal} />
        </>
    );
}

function numberFormat(amount: string) {
    var options = { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    var formatter = new Intl.NumberFormat('en-US', options);
    if (formatter.format(Number(amount))) {
        return formatter.format(Number(amount))
    } else {
        return amount
    }
}


const Selection = (props: IPropertyTypeaheadProps): JSX.Element => {
    const { initialValues, remove, setShowingCustom, setSignal } = props;

    return (
        <>
            {(initialValues && initialValues.length === 1) &&
                <div>
                    <IonLabel>Property</IonLabel>
                    <div className="contact_closable">

                        <a className="contact_closable__close" href="#" onClick={(e: any) => {
                            e.preventDefault()
                            if (initialValues[0]?.harid) {
                                setSignal?.(true)
                            }
                            remove?.()
                        }}><img className=' w-10px' src="https://content.harstatic.com/media/icons/close_medium_gra.svg" /></a>
                        <div className="d-flex max-w-440">
                            <div className="listing_signature listing_signature__small mb-2">
                                <Tag.Div className="listing_signature__photo listing_signature_photo" cssVars={{ listingSignaturePhoto: `url(${initialValues[0]?.photo ? initialValues[0]?.photo : 'https://content.harstatic.com/img/common/no_image_bg.jpg'})` }} ></Tag.Div>
                                <div className="listing_signature__details">
                                    <div className="listing_signature__details__title">{initialValues[0]?.street_address ? initialValues[0]?.street_address:""}{initialValues[0]?.street_address&&initialValues[0].address_2?",  ":''}{ initialValues[0]?.address_2 ? initialValues[0]?.address_2 : ''}</div>
                                    <div className="listing_signature__details__address">
                                        {initialValues[0]?.city ?
                                            <>
                                                {initialValues[0]?.city}{initialValues[0]?.state === "" && initialValues[0]?.zip === "" ? `` : `,`} {initialValues[0]?.state} {initialValues[0]?.zip} 
                                            </>
                                            :
                                            <>
                                                {initialValues[0]?.state} {initialValues[0]?.zip}
                                            </>

                                        }
                                        {initialValues[0]?.country && <><br />{initialValues[0]?.country}</>}
                                    </div>
                                </div>
                            </div>

                            <div className="ml-auto mr-4 text-right">
                                {initialValues[0]?.list_type && initialValues[0]?.list_type === 'a' &&
                                    <div className="message message--success font_size--small pt-1 pb-1">for sale</div>
                                }
                                {initialValues[0]?.list_price && initialValues[0]?.list_price !== "0" &&
                                    <div className="font_weight--semi_bold font_size--medium">{numberFormat(initialValues[0]?.list_price)}</div>
                                }
                            </div>

                        </div>
                        {!initialValues[0]?.harid &&
                            <a className="ml-auto mr-4 text-righ" href="#" onClick={(e: any) => {
                                e.preventDefault()
                                remove?.()
                                setShowingCustom?.(true)

                            }}>Edit</a>
                        }
                    </div>
                </div>
            }
        </>

    );
}


const Form = (props: IPropertyTypeaheadProps): JSX.Element => {
    const { initialValues, showingCustom, signal, className, change, setShowingCustom, remove, minLength } = props;


    const search = (async (value: string) => {
        const response = await fetchListingsService({ input: value });
        return response.list
    })
    const mapResult = (item: ListingsApi.IListingsList): IDropDownOption => ({
        id: `${item.street_address}`, text: `${item.city}, ${item.state} ${item.zip} ${item.country}`, photo_url: item.photo, rightContent: <div><div className="message--success font_weight--semi_bold font_size--small p-1 mr-1">
            For Sale
        </div>
            <div className='font_weight--semi_bold pr-2'>{numberFormat(item.list_price)}</div>
        </div>
    });
    function updateListing(listing: ListingsApi.IListingsList) {
        let listingArray: ListingsApi.IListingsList[] = []
        listingArray.push(listing)
        props.update?.(listingArray)
    }

    return (
        <div className={`${initialValues && initialValues?.length > 0 ? `d-none` : `d-block`}`}>
            <Typeahead.Multi label='Property'
                placeholder='Search an Address'
                search={search}
                fullWidth={true}
                className={className}
                mapResult={mapResult}
                minLength={minLength}
                initialValue={initialValues}
                onChange={change}
                hideSelectedItem
                emptyTemplate={
                    <div className="row">
                        <div className="col mb-0 pb-0">
                            No Result found
                        </div>
                        <div className="col-auto text-right">
                            <a href="#" onClick={(e: any) => {
                                e.preventDefault();
                                setShowingCustom?.(true)
                            }}>Add Manually</a>
                        </div>
                    </div>
                }
            />
            {!showingCustom &&
                <div className='position-relative'>
                    <div className='position-absolute right-0px'>
                        <a href="#" onClick={(e: any) => {
                            e.preventDefault();
                            setShowingCustom?.(true)
                        }}>Add Manually</a>
                    </div>
                </div>
            }
            <CustomListing show={showingCustom ?? false} clearSignal={signal} initialValues={initialValues} showingCountry={props.showingCountry} updateListing={updateListing} onRemove={() => {
                remove?.();
                setShowingCustom?.(false)
            }} />
        </div>
    );
}


const CustomListing = (props: IPropertyTypeaheadProps.CustomListingInterface) => {
    const { show, showingCountry, clearSignal, updateListing, onRemove } = props
    const [listing, setListing] = useState<ListingsApi.IListingsList>(props.initialValues?.[0] ?? ListingsApi.getInitialListingList())
    const [error, setError] = useState<string|undefined>()
    //const addressRegex = /[^a-zA-Z0-9,\s.///-]/


    function update<K extends keyof ListingsApi.IListingsList>(key: K, value: ListingsApi.IListingsList[K]) {
        setListing({ ...listing, [key]: value })
    }

    async function isValidated(): Promise<boolean> {
        let isAllEmpty = allEmpty(
        listing.street_address?.trim()??"",
        listing.address_2?.trim()??"",
          listing.zip.trim(),
        listing.city.trim(),
         listing.state.trim(),
        listing.country?.trim()??""
        )
        
        //  let isEmpty = allEmpty(...checkField)//Object.values(checkField).every(x => x === null || x === '');
          setError(isAllEmpty ? "Please fill in at least one field before proceeding." : undefined)//setError(checkField)
    return !isAllEmpty;
        //let anyoneIsValid = Object.values(checkField).every(x => x === null || x !== '');
       // setError(anyoneIsValid ? "Please fill in at least one field before proceeding." : undefined)
        //return  !anyoneIsValid;
    }
  
    useEffect(() => {
        if (clearSignal) {
            setListing(ListingsApi.getInitialListingList())
        }
    }, [clearSignal])



    return (<div className={`p-4 mb-5 mt-3 border_radius--default bg_color_cement_light ${show ? `d-block` : `d-none`}`}>
        <div className="font_size--large font_weight--semi_bold row mb-4">
            <div className='col'>
                Add property manually
            </div>
            <div className='col-auto'>
                <a className="" href="#" onClick={(e: any) => {
                    e.preventDefault()
                    onRemove?.()
                }}><img className=' w-10px' src="https://content.harstatic.com/media/icons/close_medium_gra.svg" /></a>
            </div>
        </div>
        {error && 
            <div className='p-3  color_cc0000'>{error}</div>
        }
        <Textbox class="mb-2 mt-2" label="Street Address" maxlength={100}  regex='[^a-zA-Z0-9,\s.///-]' value={listing.street_address} placeholder="Address 1" id="Address1" onIonChange={(e: any) => {
            update("street_address", e.target.value)
        }}></Textbox>
        <Textbox class="mb-2 mt-2" label="Address 2" placeholder="Address 2" maxlength={100}  regex='[^a-zA-Z0-9,\s.///-]' value={listing.address_2} id="Address2" onIonChange={(e: any) => {
            update("address_2", e.target.value)
        }}></Textbox>

        <Textbox class="mb-2 mt-2" label="City" placeholder="City" id="City" maxlength={50} regex='[^a-zA-Z.-\s]' value={listing.city} onIonChange={(e: any) => update("city", e.target.value)}></Textbox>
        <Textbox class="mb-2 mt-2" label="State" placeholder="State" id="State" maxlength={50} regex='[^a-zA-Z.-\s]' value={listing.state} onIonChange={(e: any) => update("state", e.target.value)}></Textbox>
        <Textbox class="mb-2 mt-2" label="Zip Code" placeholder="Zip Code" maxlength={15} regex='[\s]' value={listing.zip} id="ZipCode" onIonChange={(e: any) => update("zip", e.target.value)}></Textbox>
        {showingCountry &&
            <Textbox class="mb-2 mt-2" label="Country" placeholder="Country" maxlength={20} regex='[\s]'     value={listing.country} id="Country" onIonChange={(e: any) => update("country", e.target.value)}></Textbox>
        }
           {!!error && 
            <div className='pb-3 color_cc0000'>{error}</div>
        }
        <PrimaryButton onClick={async () => {
            let validated = await isValidated()
            if (validated) {
                updateListing?.(listing)
            }
        }}>Save</PrimaryButton>
        <OrdinaryButton onClick={() => onRemove?.()}>Cancel</OrdinaryButton>

    </div >)
}


