
import { useResponsiveChoose } from 'scripts/hooks/general';
import { ManageTagsPopUpApp } from './ManageTagsPopUp.app';
import { IManageTagsPopUpProps } from './ManageTagsPopUp.def';
import { ManageTagsPopUpDesktop } from './ManageTagsPopUp.desktop';
import './ManageTagsPopUp.scss';

export const ManageTagsPopUp = (props: IManageTagsPopUpProps): JSX.Element => {
  const ManageTagsPopUpPlatform = useResponsiveChoose(ManageTagsPopUpDesktop, ManageTagsPopUpApp);
  return (
    <ManageTagsPopUpPlatform {...props} />
  );
}
