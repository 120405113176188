import { IAddressesTypeaheadProps } from './AddressesTypeahead.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AddressesTypeaheadApp } from './AddressesTypeahead.app';
import { AddressesTypeaheadDesktop } from './AddressesTypeahead.desktop';

export const AddressesTypeahead = (props: IAddressesTypeaheadProps): JSX.Element =>
{
    const AddressesTypeaheadPlatform = useResponsiveChoose(AddressesTypeaheadDesktop, AddressesTypeaheadApp);
    return (
        <AddressesTypeaheadPlatform {...props} />
    );
}
