import { IDealsLandingScreenProps } from './DealsLandingScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { DealsLandingScreenApp } from './DealsLandingScreen.app';
import { DealsLandingScreenDesktop } from './DealsLandingScreen.desktop';
import { useGetDealsPipelineService, fetchGetDealsPipelineService } from 'services/deals/getDealsPipeline';
import { useEffect, useState } from 'react';
import { GetDealsPipelineApi } from "services/deals/getDealsPipeline"
import { useUpdatePipelineService } from 'services/deals/updatePipeline';
import { useHistory, useLocation, useParams } from 'react-router';


export const DealsLandingScreen = (props: IDealsLandingScreenProps): JSX.Element => {
    const { type } = useParams<{ type: string }>();
    const [getDealsPipeline, getDealsPipelineAction] = useGetDealsPipelineService()
    const [filterLoading, setFilterLoading] = useState(false)
    const [locationFilter, setLocationFilter] = useState<IDealsLandingScreenProps.FilterType>()
    const [dealType, setDealType] = useState("")
    const [allFilters, setAllFilters] = useState<IDealsLandingScreenProps.FilterType>()
    const [id, setId] = useState("");
    const [paging, setPaging] = useState(false);

    const [, updateAction] = useUpdatePipelineService()
    const location = useLocation<IDealsLandingScreenProps.LocationFilters>()
    const history = useHistory()
    const [columns, setColumn] = useState<IDealsLandingScreenProps.IDealsColumns[] | any[]>([
        {
            type: 'A',
            loading: true,
            bgImage: 'https://content.harstatic.com/media/icons/crm/titlebar_a.svg',
            title: "Active/Offers",
            totalAmount: 0,
            totalAmountFormat: "",
            count: 0,
            page: 0,
            totalPages: 0,
            list: []
        },
        {
            type: 'I',
            loading: true,
            bgImage: 'https://content.harstatic.com/media/icons/crm/titlebar_b.svg',
            title: "In Contract",
            totalAmount: 0,
            totalAmountFormat: "",
            count: 0,
            page: 0,
            totalPages: 0,
            list: []
        },
        {
            type: 'C',
            loading: true,
            bgImage: 'https://content.harstatic.com/media/icons/crm/titlebar_c.svg',
            title: "Closed",
            totalAmount: 0,
            totalAmountFormat: "",
            count: 0,
            page: 0,
            totalPages: 0,
            list: []
        },
        {
            type: 'F',
            loading: true,
            bgImage: 'https://content.harstatic.com/media/icons/crm/titlebar_d.svg',
            title: "Fell Through",
            totalAmount: 0,
            totalAmountFormat: "",
            count: 0,
            page: 0,
            totalPages: 0,
            list: []
        }
    ])
    const init = () => {
        setFilterLoading(true)
        getDealsPipelineAction.fetch({ renew: "1" })
    }
    function fillColumns() {
        getDealsPipeline.response?.stages.map((stages: any) => {
            let key = Object.keys(stages)[1]
            if (key) {
                let dealStage: GetDealsPipelineApi.IDealsApiResponse = stages[key]
                let thisColumn = columns.filter((col) => col.type === key)[0]
                mapColumn(thisColumn, dealStage)
            }
        })

    }

    function mapColumn(column: IDealsLandingScreenProps.IDealsColumns | undefined, dealStage: GetDealsPipelineApi.IDealsApiResponse) {
        if (column) {
            column.totalAmount = dealStage.total_amount
            column.totalAmountFormat = numberFormat(dealStage.total_amount.toString())
            column.count = dealStage.total
            column.page = dealStage.page
            column.totalPages = dealStage.total_pages
            column.list = dealStage.list
            column.loading = false
        }
    }


    function filter(param: IDealsLandingScreenProps.FilterType) {
        setFilterLoading(true)
        getDealsPipelineAction.fetch(param)

        setAllFilters(param)
    }

    function removeDealIndex(type: string, index: number) {
        let thisColumn = columns.filter((col) => col.type === type)[0]
        if (thisColumn?.list) {
            thisColumn?.list.splice(index, 1)
        }

        if (thisColumn) {
            reCalculate(thisColumn, false)
        }
    }
    function numberFormat(amount: string) {
        var options = { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 0 };
        var formatter = new Intl.NumberFormat('en-US', options);
        if (formatter.format(Number(amount))) {
            return formatter.format(Number(amount))
        } else {
            return amount
        }
    }
    function reCalculate(column: IDealsLandingScreenProps.IDealsColumns, increment: boolean) {
        if (increment) {
            column.count = Number((column.count ? column.count : 0)) + 1
        } else {
            column.count = (column.count ? column.count - 1 : 0)
        }
        let newTotal = column?.list?.map(item => Number(item.deal_amount)).reduce((prev, next) => prev + next, 0);
        if (newTotal) {
            column.totalAmountFormat = numberFormat(newTotal.toString())
        } else {
            column.totalAmountFormat = "0"
        }
    }

    function onDragHandler(response: any) {
        if (response.destination.droppableId === response.source.droppableId) {
            return
        }
        let thisColumn = columns.filter((col) => col.type === response.destination.droppableId)[0]
        let sourceDeal = columns.filter((col) => col.type === response.source.droppableId)[0]?.list?.filter((d: any) => d.id === response.draggableId)[0]
        let DealList = thisColumn?.list
        if (DealList) {
            removeDealIndex(response.source.droppableId, Number(response.source.index))
            if (sourceDeal) {
                DealList.push(sourceDeal)
            }
            DealList.sort(function (a: any, b: any) {
                return Date.parse(a.estimated_deadline) - Date.parse(b.estimated_deadline)
            })
            if (thisColumn) {
                reCalculate(thisColumn, true)
            }
            updateAction.fetch({ stages: [{ 'deal_id': response.draggableId, 'deal_stage': response.destination.droppableId }] })
        }
    }

    const reload = (type?: string) => {
        if (type) {
            if (type === 'expired') {
                filter({ 'expired': "1", ...allFilters })
            } else if (type === 'upcoming') {
                filter({ 'expired_in_90_days': "1", ...allFilters })
            }
        } else {
            if (allFilters) {
                filter({ ...allFilters })
            } else {
                init()
            }
        }
    }

    const loadMore = async (type?: string) => {
        if (paging) {
            return
        }
        setPaging(true)
        let column: IDealsLandingScreenProps.IDealsColumns | undefined = columns?.filter((col) => col.type === type)[0]
        let page = column?.page ? Number(column.page) + 1 : 0
        if (page > 0) {
            let result: any = await fetchGetDealsPipelineService({ ...allFilters, 'deal_stages': type, 'page': page.toString() })
            let stage: any = result.stages[0]
            let key = Object.keys(stage)[1]

            if (key && column?.list && stage[key] && columns) {
                column.list = [...column.list, ...stage[key].list]
                column.page = stage[key].page
                let updColumn: any[] = []
                columns.map((col) => {
                    if (col.type === key) {
                        updColumn.push(column)
                    } else {
                        updColumn.push(col)
                    }
                })
                setColumn(updColumn)
                setPaging(false)
            }
        }

    }

    const redirect = (id?: string) => {
        let url = typeof type === 'undefined' ? '' : type === 'expired' ? '-expired' : type === 'upcoming' ? '-upcoming' : '';
        let did = id ? '/' + id : '';
        history.push('/deals' + url + did, {});
        setId(id ? id : "")
    }

    useEffect(() => {
        setDealType(type ? type : '')
        if (!type) {
            if (locationFilter) {
                filter(locationFilter)
            } else {
                init()
            }
        } else {
            if (type === 'expired') {
                filter({ 'expired': "1", ...locationFilter })
            } else if (type === 'upcoming') {
                filter({ 'expired_in_90_days': "1", ...locationFilter })
            }
        }

    }, [type])

    useEffect(() => {
        if (getDealsPipeline.response) {
            fillColumns()
            setFilterLoading(false)
        }
    }, [getDealsPipeline])





    useEffect(() => {
        if (location.pathname) {
            var url = location.pathname.split('/')
            if (url[2]) {
                setId(url[2])
            }
        }
    }, [])






    const DealsLandingScreenPlatform = useResponsiveChoose(DealsLandingScreenDesktop, DealsLandingScreenApp);
    return (
        <DealsLandingScreenPlatform {...props}
            did={id}
            type={dealType}
            columns={columns}
            filter={filter}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            filterLoading={filterLoading}
            loadMore={loadMore}
            reload={reload}
            redirect={redirect}
            onDragComplete={onDragHandler}
        />
    );
}
