import { isString } from 'lodash-es';
import { ISimpleDialogComponentProps, ISimpleDialogProps } from './SimpleDialog.def';
import { eSimpleDialogIcon } from './SimpleDialog.def';
import { useEffect } from 'react';
import './SimpleDialog.scss';
import { displayMessage } from 'scripts/hooks/feedbacks';

export function SimpleDialog(props: ISimpleDialogProps): JSX.Element
{
    useEffect(() => {
        const { close } = displayMessage(props.message, props);
        return () => close();
    }, []);

    return <></>;
}

export namespace SimpleDialog
{
    export function Component(props: ISimpleDialogComponentProps): JSX.Element
    {
        /*let timer: number | undefined;
        useEffect(() => {
            clearAutoClose();
            if (!props.autoClose || !props.onClose) {
                return
            }
            const timeOut = isNumber(props.autoClose) ? props.autoClose : 3000;
            timer = window.setTimeout(closeDialog, timeOut);
            return clearAutoClose;
        }, [props.autoClose, props.onClose])*/
        const closeDialog = () => {
            //clearAutoClose();
            if (props.onClose) {
                props.onClose()
            }
        }
        /*const clearAutoClose = () => {
            if (timer) {
                clearTimeout(timer);
                timer = undefined;
            }
        }*/

        const enumToSrc = (input: eSimpleDialogIcon) => {
            switch (input) {
                case eSimpleDialogIcon.Success: return 'https://content.harstatic.com/media/icons/check_mark_green.svg'; // the icon for success
                default: throw new Error('icon not found');
            }
        }
        const loadIcon = (input: eSimpleDialogIcon | string) => {
            if (isString(input)) {
                return input
            }
            return enumToSrc(input)
        }

        return (
            <>
                <div className='d-block'>
                    <div className="alert alert--warnings left-24px bottom-24px zindex-2">{/*fadded_from_bottom*/}
                        {props.icon?
                            <div className="pr-3">
                                <img className=' w-12px' src={loadIcon(props.icon)} />
                            </div>
                            : null
                        }
                        <div className="pr-3">{props.message} </div>
                        <a className="alert--warnings__close">
                            <img src="https://content.harstatic.com/media/icons/icons-close_faded.svg" onClick={closeDialog} />
                        </a>
                    </div>
                </div>
            </>

        );
    }
}
