import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { ITag } from "views/forms/textboxes/TagsTextbox/TagsTextbox.def";
import { DeepPropertiesType, PropertiesType } from "types/utilities";
import { strToBoolean } from "scripts/helpers/texts";
// import { values } from "lodash-es";

export namespace GetContactApi {
    export const url = "/contacts/:contact_id";
    export const method = "GET";
    export const defaultError = "Failed to load contact information.";
    export const operations = apiOperations(GetContactApi);
    export const scope = new class GetContactApiScope implements IApiScope<Request, Response>
    {
        request(data: Request): PropertiesType<Request, string> {
            return {
                contact_id: data.contact_id.toString(),
            }
        }
        response(input: DeepPropertiesType<Record<'data', ServerResponse>, string>): Response {
            return {
                ...input.data,
                tags: input.data.tags.map((item) => ({
                    id: item.id ? parseInt(item.id) : undefined,
                    name: item.name,
                    contacts_count: parseInt(item.contacts_count)
                })),
                emails: input.data.emails.map((val) => ({
                    email: val.email,
                    preferred: (val.preferred === '1'),
                    id: parseInt(val.id)
                })),
                phones: input.data.phones.map((val) => ({
                    phone: val.phone,
                    preferred: (val.preferred === '1'),
                    id: parseInt(val.id),
                    phone_type:val.phone_type,
                    country_code:val.country_code,
                    phone_extension:val.phone_extension? parseInt(val.phone_extension): undefined
                })),
                relationships:input.data.relationships.map(val => this._mapRelationship(val))
            }
        }

        _mapRelationship(input: DeepPropertiesType<IServerRelationshipItemResponse, string>): IRelationshipItemResponse {
            const { id, type } = input;
            const response:IRelationshipItemResponse = { id:parseInt(id), type, contact:undefined };
            if(!input.contact_id) { return response; }

            response.contact = this._mapRelationshipContact(input)

            return response;
        }

        _mapRelationshipContact(input: DeepPropertiesType<IServerRelationshipContact, string>): IRelationshipContact
        {
            const { contact_id, primary_phone, primary_phone_id, primary_phone_country_code, primary_phone_extension, ...other } = input;
            return {
                ...other,
                contact_id: parseInt(contact_id),
                //first_last_name: joinWithSpace(other.first_name, other.last_name),
                primary_phone: this._mapRelationshipContactPhone(input),
                deleted: strToBoolean(input.deleted, false)
            };
        }

        _mapRelationshipContactPhone(input: DeepPropertiesType<IServerRelationshipContactPhone, string>): IRelationshipContactPhone | undefined
        {
            if(!input.primary_phone_id) { return undefined; }
            return {
                id: parseInt(input.primary_phone_id),
                number: parseInt(input.primary_phone),
                extension: input.primary_phone_extension? parseInt(input.primary_phone_extension): undefined,
                country_code: input.primary_phone_country_code? parseInt(input.primary_phone_country_code): undefined,
            }
        }

    }();

    export interface Request {
        contact_id: number;
        renew?: string;
    }

    interface BaseResponse
    {
        id: string,
        photo_url: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        title: string,
        name_suffix: string,
        gender: string,
        birthday: string,
        known_since: string,
        income: string,
        job_title: string,
        reminder_days_after:string,
        reminder_days_after_text:string,
        primary_email?:string,
        city?:string
        company: string,
        score: string,
        pipeline_status: string,
        anniversary_date: string,
        home_anniversary_date: string,
        contact_reminder_days:string,
        contact_type: string,
        sources: string,
        lead_type: string,
        deleted:string,
        emails:
        IEmailItemResponse[]
        ,
        phones:
        IPhoneItemResponse[]
        ,
        addresses:
        {
            address1: string,
            address2: string,
            address_type: string,
            city: string,
            zipcode: string,
            id: string
            state:string,
            country:string
        }[],
        links:
        {
            name: string,
            url: string,
            id: string
        }[]
        ,
        tags: Array<ITagResponse>
    }

    export interface ServerResponse extends BaseResponse
    {
        relationships:IServerRelationshipItemResponse[]
    }

    export interface Response extends BaseResponse
    {
        relationships:IRelationshipItemResponse[]
    }

    export interface ITagResponse extends ITag {
        contacts_count: number;
    }
    export interface IEmailItemResponse {
        email: string,
        preferred: boolean,
        id: number,
    }
    export interface IPhoneItemResponse {
        phone: string,
        preferred: boolean,
        id: number,
        phone_type:string,
        country_code?:string
        phone_extension?:number
    }

    interface IBaseRelationshipItemResponse
    {
        id:number,
        type:string,
        //name:string,
}

    interface IServerRelationshipItemResponse extends IBaseRelationshipItemResponse, IServerRelationshipContact
    {

    }

    export interface IRelationshipItemResponse extends IBaseRelationshipItemResponse
    {
        contact?:IRelationshipContact
    }

    export interface IBaseRelationshipContact
    {
        contact_id:number,
        photo_url:string,
        first_name:string,
        middle_name:string,
        last_name:string,
        email:string,
        deleted?:boolean,
    }

    export interface IServerRelationshipContact extends IBaseRelationshipContact, IServerRelationshipContactPhone {

    }

    export interface IRelationshipContact extends IBaseRelationshipContact {
        //first_last_name: string;
        primary_phone?: IRelationshipContactPhone;
    }

    export interface IServerRelationshipContactPhone {
        primary_phone_id: number
        primary_phone: number,
        primary_phone_extension?:number,
        primary_phone_country_code?: number,
    }

    export interface IRelationshipContactPhone {
        id: number
        number: number,
        extension?:number,
        country_code?: number,
    }
}


export const fetchGetContactService = forwardOperation(GetContactApi.operations, 'fetch');
export const useFetchGetContactService = forwardOperation(GetContactApi.operations, 'useFetch');
export const useGetContactService = forwardOperation(GetContactApi.operations, 'useService');