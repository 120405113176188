import { IAccountAdvancedSettingsProps, IAccountConnectioContentnProps, IAccountConnectionChildProps, IAccountConnectionPopup, IAccountConnectionProps, eAccountConnectionAction, eProviderStatus } from './AccountConnection.def';
import './AccountConnection.scss';
import { OnBoardingTemplate } from 'views/templates/OnBoardingTemplate';
import { OrdinaryButton, PrimaryButton, ProminentButton, ShapelessButton } from 'views/forms/buttons/Button';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { displayError } from 'scripts/hooks/feedbacks';
import { formatHarDate } from "scripts/helpers/dates";
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { useResponsive } from 'scripts/hooks/general';
import { useEffect, useRef, useState } from 'react';
import { useOnboardingStage } from 'scripts/hooks/onboarding';
import { eOnboardingStage } from 'scripts/enums';
import { fetchRevokeAccountTokenService } from 'services/user/revokeAccountToken';
import { useConnectedParameter, useProviderAuthorization, useProviderSync } from './modules/hooks';
import { useIonAlert } from '@ionic/react';
import { Form, useBasicInfoOnboarding } from '../BasicInfoScreen/BasicInfoScreen.common';
import { platform } from 'scripts/context';
import { ChangeAliasPopUp } from 'views/emails/popups/ChangeAliasPopUp';
import { GetBasicInfoApi } from 'services/user/getBasicInfo';
import { InstructionsSection } from 'views/onboarding/InstructionsSection';
import { fetchSaveResetSystemService } from 'services/user/resetSystem';
import { Auth } from 'scripts/security/AuthService';
import CopyToClipboard from 'react-copy-to-clipboard';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { ContactImportStatus } from 'views/contacts/component/ContactImportStatus';

//import { warning } from 'ionicons/icons';
//import { GetBasicInfoApi } from 'services/user/getBasicInfo';

export const SettingsAccountConnection = (_props:IAccountConnectionProps): JSX.Element =>
{
    return <AccountConnection settings={true} />;
}

export const AccountConnection = (props:IAccountConnectionProps): JSX.Element =>
{  const onboarding = useBasicInfoOnboarding();
   const { isConnected:justConnected, authorized } = useConnectedParameter();
   const [showInstruction,showModal] = useState(false);
   const contentRef = useRef<HTMLIonContentElement>(null);

   useEffect(() => {
    if(authorized && props.settings){
        showModal(true)
    }
   },[authorized])
   
    return (
        <>
        {props.settings &&
           <ShowInstructionModal show={showInstruction} onClose={() => showModal(false)}/>
        }
        <OnBoardingTemplate onboarding={!props.settings} contentRef={contentRef}>
            <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pl-0 pr-md-5 pr-0">
                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">{props.settings? 'Settings': 'Connect'}</h2>
            </div>
            {props.settings&&<div className="p-md-4 p-0 ">
                <Form onboarding={onboarding} getBasicInfo={true}/>
            </div>}
           {!platform.isDesktop&&<div className='pb-4'/>}
            <div className="p-md-4 p-0" >
                <Content {...props} justConnected={justConnected} contentRef={contentRef} />
            </div>
        </OnBoardingTemplate>
        </>
    );
}

export const Content = (props:IAccountConnectioContentnProps): JSX.Element =>
{
    const [loading, setLoading] = useState<boolean | string>(false);
    const action = useRef<eAccountConnectionAction>();

    // In case it has just returned from provider, renew agent data
    const onboarding = useOnboardingStage(eOnboardingStage.ContactImport);
    const providerSync = useProviderSync(onboarding);
    const justConnected = props.justConnected;


    // Handling just connected waiting
    if(justConnected)
    {
        const activated = providerSync.activate(eProviderStatus.Connection, showNotConnectedError);
        if(activated) { return <Loading />; }
    }

    // Error or Loading in progress
    if(onboarding.error) { return <></>; }
    if(onboarding.loading || loading || providerSync.loading) { return <Loading />; }

    const refresh = async (thisAction:eAccountConnectionAction, schedule?:Promise<unknown>) =>
    {
        if(schedule)
        {
            setLoading(true);
            try { await schedule } catch(e) { }
            setLoading(false);
        }

        action.current = thisAction;
        onboarding.refresh();
    };

    // Handle this use case in the future for users that are not agents
    if(!onboarding.user.agent_data?.member_number) { return <></>; }

    // Decide based on user state
    //const provider = onboarding.user?.provider_email?.trim();
    const hasProvider = onboarding.user.provider_emails_all.length > 0;
    return hasProvider?
        <Connected {...props}
            onboarding={onboarding}
            action={action}
            onRefresh={refresh}
            providerSync={providerSync}
            settings={!!props.settings}
        />
        :
        <NotConnected
            onboarding={onboarding}
            action={action}
            providerSync={providerSync}
            settings={!!props.settings}
            contentRef={props.contentRef}
        />;
}

const Connected = (props:IAccountConnectionChildProps): JSX.Element =>
{
    // Loading authorization urls
    const { action, providerSync } = props;
    const { user, next, labels, nextUrl } = props.onboarding;
    //const isOnboarding = props.onboarding.stage !== eOnboardingStage.Complete;
    const isOnboarding = !props.settings;
    const memberNumber = user.agent_data!.member_number!;
    const { authorize, reauthenticate } = useProviderAuthorization(memberNumber, isOnboarding, nextUrl);
    const [presentDisconnectDialog, dismissDisconnectDialog] = useIonAlert();

    const showDisconnectDialog = (action:'switch' | 'disconnect') => {
        const word = action === 'switch'? 'Switching': 'Disconnecting';
        const handler = action === 'switch'? () => authorize.redirect(): disconnect;
        presentDisconnectDialog({
            header: `${word} Account`,
            message: `${word} your email account will halt the synchronization of data with your current email provider.<br />Are you sure you want to continue?`,
            buttons: [
                { text:'Yes', cssClass:'btn btn--medium btn--primary', handler },
                { text:'No', handler:dismissDisconnectDialog  }
            ]
        });
    }

    // Provider Time
    //const getTime = (provider:GetBasicInfoApi.IAgentProvider) => (provider.updated_dt ?? provider.created_dt).getTime();

    const findProvider = () =>
    {
        return user.provider_emails_all.find(x => x.mailbox_id === user.provider_mailbox_id)!;
        // const all = user.provider_emails_all;

        // let list = all.filter(x => x.sync_mode);
        // if(list.length === 1) { return list[0]!; }
        // if(list.length === 0) { list = all; }
        // return list.sort((a,b) => getTime(b) - getTime(a))[0]!;
    }
    
    // Loading provider information
    const provider = findProvider();
    const providerEmail = provider.email.trim();
    const connectedDate = provider?.updated_dt ?? provider?.created_dt;
    const connectedAt = connectedDate? formatHarDate(connectedDate): 'Unknown';
    const providerStatus = provider.provider_status?.trim()?.toLowerCase();
    const isProviderOk = providerStatus === 'ok';
    //const isTokenRevoked = providerStatus === 'token revoked';
    const style = getProviderStyle(provider.email);
    // const showReadyButton = isProviderOk && user.onboarding_stage === eOnboardingStage.AccountConnection;
    const title = isProviderOk? <>The CRM is successfully connected to <strong>{providerEmail}</strong></>:
        provider.sync_mode? <>The CRM is not synchronizing with <strong>{providerEmail}</strong></>:
        <>The CRM is disconnected from <strong>{providerEmail}</strong></>;

    const statusText = isProviderOk? 'Connected:':
        provider.sync_mode? 'Out of Sync:':
        'Disconnected:';

    // Saving step if provider is connected
    // useEffect(() => {
    //     if(isProviderOk) { props.onboarding.next(false); }
    // }, [isProviderOk]);

    // Show Disconnection Progress
    if(action.current === eAccountConnectionAction.Disconnect && provider.sync_mode)
    {
        action.current = undefined;
        const activated = providerSync.activate(eProviderStatus.Disconnection, showNotDisconnectedError);
        if(activated) { return <Loading />; }
    }

    // Handlers
    const disconnect = () =>
    {
        // Showing loading and scheduling refresh
        const promise = fetchRevokeAccountTokenService();
        props.onRefresh?.(eAccountConnectionAction.Disconnect, promise);
    };

    const redirectToImportContacts = () =>
    {
        next(false, '/onboarding/contacts');
    };

    const handleDisconnect = () => showDisconnectDialog('disconnect');
    const handleSwitch = () => showDisconnectDialog('switch');

    return (<>
        {/*<div className="mb-1 font_size--large_extra font_weight--bold color_sold">Provider Connection</div>*/}
        <div className="mb-4 mt-4 mt-md-0">
            <div className={`font_size--large mb-3 ${isProviderOk? '': 'color_sold'}` }>{title}</div>
            <div className="font_size--large">
                {/* {isProviderOk? 'indicating that your email account is successfully connected.':  */}
                {!isProviderOk &&
                    <>{provider.sync_mode? 'Your email connection is temporarily out of sync': 'It seems that you have disconnected your email account'}.
                    You can easily re-establish the {provider.sync_mode? 'synchronization': 'connection'} or choose to switch to a different email account below.</>
                }
            </div>
        </div>

        {!isProviderOk &&
            (provider.sync_mode?
                <ProminentButton className='mb-3 mb-sm-0' size='large' aria-label="Synchronize" onClick={() => reauthenticate.redirect()}>Synchronize Email Account</ProminentButton>:
                <ProminentButton className='mb-3 mb-sm-0' size='large' aria-label="Reconnect" onClick={() => authorize.redirect({ login_hint:providerEmail })}>Reconnect Email Account</ProminentButton>
            )
        }
            
        <div className=' max-w-470'>
        <ContactImportStatus className='mb-3' />
            {isProviderOk && <div className="cards border-radius__ mb-2 mt-0">
                <div className="font_size--medium mb-4">
                    <div className="d-flex align-items-center mb-2">

                        {/* Re-enable it when we have provider information*/}
                        <div className="mr-3"><IonImg className='provIcon' src={style.icon}></IonImg></div>
                        <div>
                            <strong className="font_size--large_extra">{style.title}</strong>
                            <div className=""><span className="font_weight--bold">{statusText}</span> {connectedAt}</div>
                        </div>
                    </div>

                    {/* Enable this when system is available without provider access */}
                    {/* <button role="button" className="btn btn--simple btn--red btn--small" aria-label="Search">Unlink Gmail</button> */}

                </div>

                <div className="font_weight--bold font_size--large mb-3">Connected services</div>
                <div className="row small-gutters">
                    <div className="col-auto pb-2 d-flex align-content-center">
                        <div className="mr-2"><IonImg className=' w-24px' src="https://content.harstatic.com/media/icons/greencheck_boldborder.svg"></IonImg></div>
                        <div className="font_weight--bold font_size--medium align-self-center color_available">Email</div>
                    </div>

                    <div className="col-auto pb-2 d-flex align-content-center">
                        <div className="mr-2"><IonImg className=' w-24px' src="https://content.harstatic.com/media/icons/greencheck_boldborder.svg"></IonImg></div>
                        <div className="font_weight--bold font_size--medium align-self-center color_available">Contacts</div>
                    </div>

                    <div className="col-auto pb-2 d-flex align-content-center">
                        <div className="mr-2"><IonImg className=' w-24px' src="https://content.harstatic.com/media/icons/greencheck_boldborder.svg"></IonImg></div>
                        <div className="font_weight--bold font_size--medium align-self-center color_available">Calendar</div>
                    </div>
                </div>

                <button role="button" className="btn btn--simple btn--redtext btn--small btn-disconnect mt-2 mr-1 mb-3 mb-sm-0" aria-label="Replace" onClick={() => handleDisconnect()}>Disconnect Email Account</button>
            </div>}

        </div>
        

        <div className="mt-3 pb-0" id="advance-settings">
            <div className="pt-4">
                <AdvancedSettings user={user} contentRef={props.contentRef} changeEmailAccount={handleSwitch} isConnected>               
                    <div className="pb-3">
                        {/*<div className="font_size--large mb-2">Do you want to switch to a different account for synchronization with the HAR CRM account?</div>*/}
                        {/*<div className="font_size--large mb-2">Not the account you want to sync with HAR CRM account?</div>*/}
                        {/*<button role="button" className="btn btn--simple btn--small mr-1 mb-3 mb-sm-0" aria-label="Replace" onClick={() => handleSwitch()}>Change Email Account</button>
                        {/* {isProviderOk && <button role="button" className="btn btn--simple btn--redtext btn--small btn-disconnect mr-1  mb-3 mb-sm-0" aria-label="Replace" onClick={() => disconnect()}>Disconnect Email Account</button>} */}
                        {/*<GoogleWarning />*/}
                    </div>
                </AdvancedSettings>
                    </div>

            {isOnboarding && (<div className='mt-2'>
                {isProviderOk && <PrimaryButton size='large' loading={next.loading} onClick={redirectToImportContacts}>Let's go to import Contacts</PrimaryButton>}
                <OrdinaryButton size="large" loading={next.loading} onClick={() => next(true)}>{labels.skipLabel}</OrdinaryButton>
            </div>)}
        </div>

        <Disclaimer />
    </>);
}

const NotConnected = (props:IAccountConnectionChildProps): JSX.Element =>
{
    const isResponsive = useResponsive();

    const { user, next, labels, nextUrl } = props.onboarding;
    const memberNumber = user.agent_data.member_number;

    // Loading authorization url
    const { authorize } = useProviderAuthorization(memberNumber, !props.settings, nextUrl);

    // Handle this use case in the future for users that are not agents
    if(!memberNumber) { return <>Not an agent</>; }

    return (
        <div className={isResponsive? 'd-flex flex-column h-100 pt-4 pt-md-0': 'accounts_bg'}>
            <div className="font_size--large_extra_extra mb-4">Connect with your email provider</div>
            <div className={`pb-5 ${isResponsive ? `max-w-320` : `max-w-500`}`}>                
                <div className="font_size--medium mb-5">
                    <span className="font_weight--bold">Sync your contacts in HAR CRM with any major provider.</span> From Google and Outlook to iCloud, we’ve got your covered. Send email directly from HAR CRM and link emails to your deals and contacts.
                </div>                    
                
                <PrimaryButton className="w-100 max-w-200" loading={next.loading} onClick={() => authorize.redirect()}>Connect Email</PrimaryButton>
                {!props.settings && <OrdinaryButton className="w-100 max-w-200" loading={next.loading} onClick={() => next(true)}>{ labels.customLabel ? labels.skipLabel: 'I will do it later' }</OrdinaryButton>}
                {props.settings && <div className="pt-4"><AdvancedSettings user={user} contentRef={props.contentRef} changeEmailAccount={() => authorize.redirect()} /></div>}

                {/* <GoogleWarning /> */}

                <Disclaimer />
                {/*<OrdinaryButton className="w-100">Ready to use the CRM</OrdinaryButton>*/}

                <DefaultPopUp title="Connect your account">
                    <DefaultPopUp.Body>
                        <div className="p-5 text-center">
                            3party service (Key Clock) <br/><br/>
                            <a href="#">
                                <PrimaryButton>go to selected email screen</PrimaryButton>
                            </a>
                        </div> 
                    </DefaultPopUp.Body>
                </DefaultPopUp>
            </div>
            {isResponsive && <div className=" accounts_bg flex-grow-1 mr--10 bg-size-contain"></div>}
        </div>
    );
};

const AdvancedSettings = (props:IAccountAdvancedSettingsProps) =>
{
    const { contentRef,changeEmailAccount,isConnected } = props;
    const [advancedSettings, setAdvancedSettings] = useState(false);
    const [present, dismiss] = useIonAlert();
    const [showAliasPopUp, setShowAliasPopUp] = useState(false);
    const [settings, setSettings] = useState<GetBasicInfoApi.IRoutineEmailSettings>(props.user.settings!);
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        var timeout:any=null
        if (copied) {
             timeout= setTimeout(() => {
                setCopied(false);

            }, 5000);
            
        }
        return () => {clearTimeout(timeout);}
    }, [copied]);

    const handleReset =async()=>{
        await fetchSaveResetSystemService();
        Auth.signOut();  
    }

    const showResetDialog = () => {
        present({
            header: `Reset System`,
            message: `Are you sure you want to reset the system? This action will clear all data from the CRM, reverting it to its initial state. This action cannot be undone. Proceed with reset?`,
            buttons: [
                { text:'Yes', cssClass:'btn btn--medium btn--primary', handler:handleReset },
                { text:'No', handler:dismiss  }
                
            ]
        });
    }

  

    const toggleAdvancedSettings = (value:boolean) => {
        setAdvancedSettings(value);
        if(!value || !contentRef.current) { return; }

        contentRef.current.scrollToPoint(0, document.getElementById("advance-settings")?.offsetTop, 1000)
    }

    return (<>
        <a  className="mb-4 font_size--medium font_weight--semi_bold d-inline-block cursor--hand text-decoration-none" onClick={() => toggleAdvancedSettings(!advancedSettings)}>
            Advanced Settings <img className=' w-12px' src={`https://content.harstatic.com/media/icons/arrow-gray-${advancedSettings? 'up':'down'}.svg`} />
        </a>

        <div className= {advancedSettings? 'd-block animate animate__fadeIn pb-4 border-bottom': 'd-none'}>
            <div className="mb-1 font_size--large_extra font_weight--bold">Sync</div>
            <div className="font_size--large mb-4 user_select-text">HAR CRM features bidirectional sync. This means changes you make to contacts in your connected account are automatically reflected in your HAR CRM. The opposite is true too. Update a contact in the CRM and you'll see the changes next time you log into your connected account.</div>

            <div className="mb-1 font_size--large_extra font_weight--bold">Deleting contacts</div>
            <div className="font_size--large mb-4 user_select-text">
                <div className="pb-3  user_select-text">Deleting a Contact from your email provider will delete the Contact in the HAR CRM.</div>
                <div className="pb-3  user_select-text">If there is a Task, Event or Deal assigned to the deleted Contact, the Task, Event or Deal will remain in the CRM. The respective Contact will not be a clickable link but will remain assigned to the Task, Event or Deal as a placeholder.</div>
                Deleting a Contact from the CRM will only delete the Contact in the CRM (will not affect your email).
                {/* In HAR CRM, deleting a contact depends on your choice; you can delete it in CRM only or both in CRM and your email providers. */}
            </div>

            <div className="mb-1 font_size--large_extra font_weight--bold user_select-text">Import Contact and Email</div>
            <div className="font_size--large mb-4 user_select-text">To import contacts and emails, follow this straightforward process: Forward the email conversation to our designated Routing Email (<CopyToClipboard text={settings.routine_email} onCopy={() => setCopied(true)}><a onClick={(e: any) => {

e.stopPropagation();
e.preventDefault();

}} className='cursor_pointer color_0738CD'>{settings.routine_email}</a></CopyToClipboard>)), and it will capture the entire conversation. This ensures that emails sent by your contacts are automatically accessible within your CRM.</div>                   

            <div className="mb-1 font_size--medium font_weight--bold">Connect Your Email Account to the CRM</div>
            <div className="font_size--large mb-1">You can import your emails and contacts into the CRM by connecting your email account.</div>
            {<button role="button" className="btn btn--simple btn--small mr-1 mb-3 mb-sm-0"  aria-label="Change Routing Email" onClick={changeEmailAccount}>{isConnected? "Change Email Provider":"Connect Email"}</button>}

            <div className="mb-1 font_size--medium font_weight--bold mt-4">CRM Routing Email</div>
            <div className="font_size--large mb-1">  CRM Routing Email
                If you do not connect your email account to the CRM, you can
                import contacts and emails into the CRM. To import, forward
                each email to your CRM Routing email that is auto-created for
                you (<CopyToClipboard text={settings.routine_email} onCopy={() => setCopied(true)}><a onClick={(e: any) => {

                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                        }} className='cursor_pointer color_0738CD'>{settings.routine_email}</a></CopyToClipboard>).
                To change the Routing Email account, click on the button
                below.</div>
            <button role="button" className="btn btn--simple btn--small mr-1 mb-4 mb-sm-0" aria-label="Change Email Provider" onClick={()=>setShowAliasPopUp(true)}>Change Routing Email</button>
            {copied ?
                <SimpleDialog message='Email is copied.' /> : ''
            }


            {props.children}
            <div className="pb-3 pt-1 mt-2">
                <div className="mb-1 font_size--large_extra font_weight--bold">Reset the HAR CRM</div>
                <div className="font_size--large mb-2 user_select-text">By clicking the Reset System button below, all Contacts, Deals, Tasks and Events will be deleted from the CRM.<br /> Any Services, such as MarketInsight, EmailMarketing Campaigns and CMAs, will not be deleted.<br /> Once the HAR CRM is Reset, you will be redirected to the Login page to begin the Onboarding process again.</div>
                <ShapelessButton  className="btn btn--simple btn--redtext btn--small btn-disconnect mt-2 mr-1 mb-3 mb-sm-0 align-self-center" aria-label="Reset System" onClick={() => showResetDialog()}>Reset System</ShapelessButton>
            </div>
        </div>

        {showAliasPopUp && <ChangeAliasPopUp show setting={settings} onChange={setSettings} onClose={() => setShowAliasPopUp(false)} />}
    </>);
}

const Loading = () =>
(
    <div className="p-4 mt-4 max-w-470">
        <div className="loading__text p-4 mb-1"></div>
        <div className="loading__text p-2 mb-4"></div>
        <div className="row mb-3">
            <div className="col-2">
                <div className="loading__photo w-50px h-50px"></div>
            </div>
            <div className="col-6">
                <div className="loading__text pb-4 mb-2"></div>
                <div className="loading__text"></div>
            </div>
        </div>
        <div className="cards border-radius__">
            <div className="loading__text mb-2"></div>
            <div className="row">
                <div className="col-3"><div className="loading__text"></div></div>
                <div className="col-3"><div className="loading__text"></div></div>
                <div className="col-3"><div className="loading__text"></div></div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <div className="loading__text p-4"></div>
            </div>
        </div>

        <div className="loading__text mb-2"></div>
        <div className="row">
            <div className="col-4"><div className="loading__text"></div></div>
            <div className="col-4"><div className="loading__text"></div></div>
        </div>
    </div>
)

// const GoogleWarning = () =>
// {
//     return (
//         <div className="border_radius--default d-inline-block mb-2 bg_color_pending_light p-3 font_size--medium mt-4">
//             {/* <IonIcon icon={warning} size='large' className='d-block ml-auto mr-auto'></IonIcon> */}
//             <div className="row">
//                 <div className="col-auto my-auto">
//                     <IonIcon icon={warning} color='red' size='large' className='d-inline-block ml-auto mr-auto'></IonIcon>
//                 </div>
//                 <div className="col pl-0">
//                     At this time, please do not utilize a Google (Gmail or Google Workspace) email provider to set up your CRM account. We expect this issue to be rectified within a month.
//                 </div>
//             </div>
//         </div>
//     )
// }

const Disclaimer = () =>
(
    <div className="font_weight--normal pt-5">
        HAR CRM's use and transfer to any other app of information received from Google APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy" target='_blank' rel='noreferrer' className=' text-decoration-underline'>Google API Services User Data Policy</a>, including the Limited Use requirements.
    </div>
);

const showNotConnectedError = () => displayError('Provider not connected', 'Something went wrong connecting to your provider.');
const showNotDisconnectedError = () => displayError('Provider not disconnected', 'Something went wrong when disconnecting your provider.');

//This function is temporary and for giant email service providers.
const getProviderStyle = (email:string): Record<'title' | 'icon', string>  =>
{
    const baseIcons = 'https://content.harstatic.com/media/icons';
    const baseImages = 'https://content.harstatic.com/resources/images';
    const domain = email.substring(email.lastIndexOf("@") +1).toLowerCase();
    switch (domain)
    {
        case "gmail.com": return { title:'Gmail', icon:`${baseIcons}/crm/gmail-icon-2020.svg` };
        case "yahoo.com": return { title:'Yahoo', icon:`${baseIcons}/crm/logo-yahoo-mail.svg` };
        case "outlook.com": return { title:'Outlook', icon:`${baseIcons}/crm/logo-outlook.svg` };
        case "live.com": return { title:'Live.com', icon:`${baseIcons}/crm/logo-outlook.svg` };
        case "hotmail.com": return { title:'Hotmail', icon:`${baseIcons}/crm/logo-outlook.svg` };
        case "icloud.com": return { title:'iCloud', icon:`${baseIcons}/crm/logo-icloud.svg` };
        case "aol.com": return { title:'AOL', icon:`${baseIcons}/crm/logo-aol.svg` };
        case "har.com": return { title:'HAR', icon:`${baseImages}/common/logo-blue.svg` };
        default: return { title:domain, icon:`${baseIcons}/email_gray_circle.svg` };
    }
}


const ShowInstructionModal = (props:IAccountConnectionPopup) => {
    const isResponsive = useResponsive()
    const modalRef = useRef<HTMLIonModalElement>(null);
    return (
    <IonModal isOpen={props.show} ref={modalRef} className={`custom--modal__blackoverlay ${!isResponsive && `custom--modal custom--modal__smallheight`}`} title="Small Height Pop Up" onIonModalDidDismiss={() => props.onClose?.()} backdropDismiss={false}>
     <IonHeader className="ion-padding-md ion-no-border border-bottom">
        <IonToolbar>
            <div className="custom--modal_header">
                <IonTitle className="modal-title">{'Import Status'}</IonTitle>
                {!isResponsive ?
                <IonButton color={"blue"} className="modal-closebtn" slot="start" fill="clear" onClick={() => modalRef.current?.dismiss()}>
                    <IonIcon slot="icon-only" icon="close" color="dark"></IonIcon>
                </IonButton>
                :
                <a onClick={() => modalRef.current?.dismiss()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /></a>
                }
            </div>
        </IonToolbar>                    
    </IonHeader>
    <IonContent><InstructionsSection showProviderContacts={true} className={'pt-3 pl-5 pr-5 pb-2'} /></IonContent>
    {!isResponsive &&
        <IonFooter className="ion-no-border border-top">
            <PrimaryButton className='mx-auto d-flex justify-content-center max-w-80' onClick={() => modalRef.current?.dismiss()}>Ok</PrimaryButton>
        </IonFooter>
    }
</IonModal>
    );
}


