import { ICalendarOverviewScreenProps,ICalendarOverviewScreenState } from './CalendarOverviewScreen.def';
import {  useEffect, useState } from 'react';
import './CalendarOverviewScreen.scss';
import { fetchGetCalendarTasksService,GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { TaskDetailsPopUp } from 'views/calendar/popups/TaskDetailsPopUp';
import { fetchEditCalendarTaskCompletedService } from 'services/calendar/editCalendarTaskCompleted';

import moment,{Moment} from 'moment';
import { formatSystemDate } from 'scripts/helpers/dates';
import { TaskStats } from 'views/calendar/TaskStats';
import { useHistory } from 'react-router';
import { GetCalendarTaskStatsApi, fetchGetCalendarTaskStatsService } from 'services/calendar/getCalendarTaskStats';
import { useFetchLookupsService } from 'services/general/lookups';
import { FilterPopUp } from 'views/calendar/popups/FilterPopUp';
const typesFilter = "CALL,APPT,EMAIL,FOLLW,OFFER,MEETG,INSPT,CLOSE,PREVW,OH,VOH,BOH,SHOW,VSHOW,EVENT,VOLUN,TRAIN"

export const CalendarOverviewScreen = (props: ICalendarOverviewScreenProps): JSX.Element =>
{   
    const [calendarData,setCalendarData]=useState<Array<GetCalendarTasksApi.IResponseList>>([]);
    const [showTaskDetailModal,toggleShowTaskDetailModal]=useState<boolean>(false);
    const [taskDetail,setTaskDetail]=useState<GetCalendarTasksApi.IResponseList>();
    const [taskStats, setTaskStats] = useState<GetCalendarTaskStatsApi.Response>();
    const lookups = useFetchLookupsService(["task_type", "task_priority"]);

    const history = useHistory<ICalendarOverviewScreenState>();
    const { state = {} } = history.location;
    const getInitialFilters=()=>{ 
        try {
            if(state?.filter){
               return JSON.parse(state.filter);
            }
          } catch (error) {}
          return initialFilter
        }

    const initialFilter = { completed: undefined, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), priorities: "H,M,L", types: typesFilter, status_filter: "", contact_id: props.contactId, sort_dir: "ASC",categories:"T,E" }
    const [filter, setFilter] = useState<GetCalendarTasksApi.Request>(getInitialFilters());   
    const prioritiesFilterData = lookups[0].response?.task_priority.items || []
    const typesFilterData = lookups[0].response?.task_type.items || []

    const [monthCalendar,setMonthCalender]=useState<Array<Array<{children:JSX.Element | JSX.Element[],date:Date|Moment}>>>();
    const handleSetTaskDetail=(data:GetCalendarTasksApi.IResponseList)=>{
        setTaskDetail(data);
        toggleShowTaskDetailModal(true);
    }
    useEffect(() => {
      refreshData()
        }, [props.month,props.filter]); 

    useEffect(()=>calender(),[calendarData])

    const getStatsData = async () => {
        const responses = await fetchGetCalendarTaskStatsService({ contact_id: props.contactId, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate() })
        setTaskStats(responses);
  }
    const getTaskData = async (filter: GetCalendarTasksApi.Request) => {
        const responses = await fetchGetCalendarTasksService({ ...filter })
        setCalendarData(responses.list);
  }

      const taskItem=(item:GetCalendarTasksApi.IResponseList,ind:number)=>{
        
          if(item.completed){
                  return <div onClick={()=>handleSetTaskDetail(item)} key={ind} className="text-truncate cursor--hand">{moment(item.start_dt).format("hh:mm A")} <del>{item.title}</del></div>;
              }else{
                  return <div onClick={()=>handleSetTaskDetail(item)} key={ind} className="text-truncate cursor--hand">{moment(item.start_dt).format("hh:mm A")} {item.title}</div>;
          }
      }
      const getChildNode=(items:Array<GetCalendarTasksApi.IResponseList>)=>{
          return <div  className="date_desc">{items.map((item,ind)=>ind<3&& taskItem(item,ind))}</div>
      }

      const calender=()=>{
          const startWeek = moment(props.month).startOf('month').week();
          var endWeek = moment(props.month).endOf('month').week();
          if(startWeek===48){
            endWeek=53
          }
          let calendar = []
          for(let week = startWeek; week<=endWeek;week++){
            
            calendar.push(Array(7).fill(0).map((n, i) => {
              const date=moment(props.month).week(week).startOf('week').clone().add(n + i, 'day')
              const filteredTasks=calendarData.filter((item)=>formatSystemDate(moment(item.start_dt))===formatSystemDate(moment(date)))
         
          return ({children:getChildNode(filteredTasks),date:date})}));
          }
          setMonthCalender(calendar);
      }
      
       const handleTaskCompleted=async(data:GetCalendarTasksApi.IResponseList)=>{
          const response=await fetchEditCalendarTaskCompletedService({id:data.id.toString(),status_id:!data.completed})
          if(response.status==='ok'){
            getTaskData(filter);
              return true
          }
          return false
      }
      const handleFilterData = async (newFilter: GetCalendarTasksApi.Request) => {
      
        getTaskData({ ...filter, ...newFilter })
        setFilter((prev) => ({ ...prev, ...newFilter }))
        history.replace(history.location.pathname, {'filter':JSON.stringify({ ...filter, ...newFilter })});

    }
    const refreshData = () => {
      getTaskData({ ...filter,start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(),...props.filter,date:undefined });
      getStatsData();
  }
  const weekDays=["SUN","MON","TUE","WED","THU","FRI","SAT"];
  const getClass=(date:Date|Moment)=>{
    var className="calendar_large__row_col"
    if(formatSystemDate(moment().toDate())==formatSystemDate(moment(date)) ){ className+=" selected_date current_date"}
  return className
  }
    return (
       <>
            <div className="calendar_wrapper">
                <div className="calendar_large">
                    <div className="calendar_large__body">
                        <div className="calendar_large__row_title">
                           {weekDays.map((dayName,ind)=> <div key={ind} className="calendar_large__row_title_col">{dayName}</div>)}
                        </div>
             {monthCalendar?.map((item,ind)=> 
                <div key={ind} className="calendar_large__row d-flex h-100">
                 {item.map((item:{children:JSX.Element | JSX.Element[],date:Date|Moment},index:number)=>
                    <div  key={index} className={getClass(moment(item.date))}>
                      <div onClick={()=>props.onClickDay(moment(item.date).toDate())} className="date_text">{moment(item.date).format("DD")}</div>
                      {item.children}
                    </div>)}
                </div>)}
             </div>
                </div>
                <div className="row">

                <div className="col-auto">
                <div className="pt-5">
                                    <FilterPopUp
                                        selectedValue={filter.priorities || ""}
                                        handleFilter={handleFilterData}
                                        filterName="priorities"
                                        items={prioritiesFilterData}
                                        label="Priorities"
                                    /></div>
                                    <div className="pt-5">
                                    <FilterPopUp
                                        selectedValue={filter.types || ""}
                                        handleFilter={handleFilterData}
                                        filterName="types"
                                        items={typesFilterData}
                                        label="Types"
                                    />
                </div>
                        </div>
                <div className="col-md-7 col-12" >
                <TaskStats
                  data={taskStats}
                  handleSetFilter={handleFilterData}
                  filter={filter}
                  initialFilter={initialFilter}
                  column={4}
                />
                </div>
                </div>
                <div>{/* removed the height:700px from this div as it was wrong */}</div>
                 {(taskDetail?.id&&showTaskDetailModal)&& <TaskDetailsPopUp
                    toggle={toggleShowTaskDetailModal} 
                    show={showTaskDetailModal} 
                    taskId={taskDetail.id}
                    handleTaskCompleted={handleTaskCompleted} 
                   refreshData={refreshData} 
                   />}
            </div></>
    );
}
