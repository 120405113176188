import { useHistory } from "react-router";
import { useResponsiveChoose } from "scripts/hooks/general";

interface INoConnection {
    noBorder?: boolean
    className?: string
}

export const NoConnectionBox = (props: INoConnection) => {
    const NoConnectionBoxPlatform = useResponsiveChoose(NoConnectionBoxDesktop, NoConnectionBoxApp);
    return <NoConnectionBoxPlatform {...props} />
}

const NoConnectionBoxDesktop = (props: INoConnection) => {
    let history = useHistory()
    return (
        <div className={`${!props.noBorder && `email_container`} d-flex ${props.className}`}>
            <div className="pl-3 pr-3 pt-4 pb-3">
                <div className="font_size--large mb-3">Connect with your email provider</div>
                <button role="button" className="btn btn--ordinary mt-2 mr-1 mb-2" aria-label="Replace" onClick={() => history.push('/settings/account')} >Go to Settings</button>

            </div>
        </div>
    );
}


const NoConnectionBoxApp = (props: INoConnection) => {
    let history = useHistory()
    return (
        <div className={`${!props.noBorder && `email_container`} d-flex height_calc_1`}>
            <div className="w-100 align-self-center ">
                <div className="text-center pt-md-5 opacity-64pr">
                    <div className="font_size--large font-weight-bold mb-3">Connect with your email provider.</div>
                    <button role="button" className="btn btn--ordinary mt-2 mr-1 mb-2" aria-label="Replace" onClick={() => history.push('/settings/account')} >Go to Settings</button>
                </div>
            </div>
        </div>
    );
}