import { ITaskDetailsPopUpProps } from './TaskDetailsPopUp.def';
import './TaskDetailsPopUp.scss';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { formatHarDateTime, getDayLong } from 'scripts/helpers/dates';
import { useState, useEffect } from 'react';
import { GetCalendarTaskDetailsApi, useGetCalendarTaskDetailsService } from 'services/calendar/getCalendarTaskDetails';
import { Button, PrimaryButton, ProminentButton, ShapelessRedButton } from 'views/forms/buttons/Button';
import { fetchDeleteCalendarTaskService } from 'services/calendar/deleteCalendarTask';
import { IonButton, IonIcon } from '@ionic/react';
import { TaskForm } from '../AddTaskPopUp/components/TaskForm';
import { useHistory, useParams } from 'react-router';
import { Avatar } from 'views/contacts/Avatar';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import {close} from 'ionicons/icons'
import { isEmpty } from 'scripts/helpers/texts';
import { ContactDetailPopover } from 'views/contacts/popovers/ContactDetailPopover';

export const TaskDetailsPopUpDesktop = (props: ITaskDetailsPopUpProps): JSX.Element => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { taskId, returnUrl, handleTaskCompleted,openContactQuickOverviewPopup } = props;
    const [showEditTask, toggleEditTask] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedDeleteOption, setSelectedDeleteOption] = useState("0")
    const [details, getDetails] = useGetCalendarTaskDetailsService()
    const [detailsData, setDetail] = useState<GetCalendarTaskDetailsApi.Response>()
    useEffect(() => {
        if (taskId) {
            handleFetchDetailsData(taskId);
        }
    }, [taskId])

    const [showContactDetailPopup,setShowContactDetailPopup]=useState(false)
    const [contactId,setContactId]=useState("")

    const handleFetchDetailsData = async (id?: string) => {
        await getDetails.fetch({ id: id ? id : props.taskId });
    }
    const handleTaskRemove = async () => {
        const response = await fetchDeleteCalendarTaskService({ id: taskId, option_id: details.response?.is_recurrent == '0' ? undefined : selectedDeleteOption })
        if (response) { props.toggle?.(false); props.refreshData?.(); displayMessage("The " + detailsData?.category_text.toLowerCase() + " successfully deleted") }
    }
    const closeModal = () => {
        props.toggle?.(false);
        if (returnUrl) {
            return history.replace(returnUrl)
        }
    }

  {/**   const redirect = (id?: number) => {
        id && history.push(`/contacts/${id}/overview`);
        // closeModal();
    }*/}
    const toggleTaskStatus = async () => {
        if (await handleTaskCompleted(detailsData!)) { handleFetchDetailsData(); }
    }
    const getContactSubtitle=(email:string,phone:string|undefined,fname:string,lname:string):string=>{
        if(!isEmpty(email)){return email;}
        else if(isEmpty(fname)&&isEmpty(lname)&& !isEmpty(phone)){
            return '';
        }else if(!isEmpty(phone)){
            return phone??'';
        }
        return ''; 
    }

    useServiceDidSucceeded((details), () => {
        setDetail(details.response)
    })

    return (
        <DefaultPopUp title='' isOpen={props.show} toggleShow={closeModal} onDidDismiss={closeModal} hideHeader>
            <DefaultPopUp.Body>
                <div className="d-flex position-relative">
                    <IonButton className="btn btn--shapeless ml-auto position-fixed right-6px top-5 zindex-11000" onClick={closeModal}>
                        <IonIcon slot="icon-only" icon={close} color="dark"></IonIcon>
                    </IonButton>
                </div>

                <div className="pl-5 pr-5 pb-5 pt-5 ion-padding ion-padding-end d-none">
                    <div className=' loading__text h-20px mb-3 w-100px'></div>
                    <div className=' loading__text w-90px mb-4'></div>
                    <div className='card depth--emboss p-4'>
                        <div className="row">
                            <div className="col">
                                <div className=" loading__text_small w-100 mb-2"></div>
                                <div className=" loading__text_small w-80pr mb-3"></div>
                                <div className=" loading__text w-110px mb-3"></div>
                                <div className=" loading__text_small w-80px mb-2"></div>
                            </div>
                            <div className="col-auto align-self-center"><img src="https://content.harstatic.com/media/icons/arrow_faded.svg" /></div>
                            <div className="col">
                                <div className=" loading__text_small w-100 mb-2"></div>
                                <div className=" loading__text_small w-80pr mb-3"></div>
                                <div className=" loading__text w-110px mb-3"></div>
                                <div className=" loading__text_small w-80px mb-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-4'>
                        <div className='col-6'>
                            <div className='d-flex align-items-center pt-3 mb-3'>
                                <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
                                <div><div className=" loading__text w-136px"></div></div>
                            </div>

                            <div className='d-flex align-items-center pt-3 mb-3'>
                                <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
                                <div><div className=" loading__text w-136px"></div></div>
                            </div>

                            <div className='d-flex align-items-center pt-3 mb-3 pb-4'>
                                <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
                                <div><div className=" loading__text w-136px"></div></div>
                            </div>

                            <div className=" loading__text w-100 h-40px mb-4"></div>
                        </div>

                        <div className='col-6'>
                            <div className='d-flex align-items-center pt-3 mb-2'>
                                <div className='w-20px mr-2'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light"></div></div>
                                <div><div className=" loading__text_small w-120px"></div></div>
                            </div>
                            <div className='d-flex align-items-center pt-2 mb-2'>
                                <div className='w-20px mr-2'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light"></div></div>
                                <div><div className=" loading__text_small w-120px"></div></div>
                            </div>
                            <div className='d-flex align-items-center pt-2 mb-2'>
                                <div className='w-20px mr-2'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light"></div></div>
                                <div><div className=" loading__text_small w-120px"></div></div>
                            </div>
                            <div className='d-flex align-items-center pt-2 mb-2'>
                                <div className='w-20px mr-2'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light"></div></div>
                                <div><div className=" loading__text_small w-120px"></div></div>
                            </div>
                            <div className='d-flex align-items-center pt-2 mb-2'>
                                <div className='w-20px mr-2'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light"></div></div>
                                <div><div className=" loading__text_small w-120px"></div></div>
                            </div>
                        </div>
                    </div>

                </div>




                <div className="d-flex pt-5" />
                <div className="pl-5 pr-5 pb-5 mt-2">
                    {details.loading &&
                        <Loading />
                    }

                    {!details.loading && details.response &&
                        <>
                            <div className={`label label--${detailsData?.type_text.toLowerCase().replaceAll(" ", "")}`}>{detailsData?.type_text}</div>
                            <h2 className="h2--simple h2--simple__black mb-4">{detailsData?.title}</h2>
                            <div className="cards">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="auxiliary__fadded font_size--medium font_weight--semi_bold text-uppercase pb-2">Starts</div>
                                        <div className="auxiliary__fadded font_size--small">{getDayLong(detailsData?.start_dt)?.toUpperCase()}</div>
                                        <div className="font_size--medium font_weight--bold">{formatHarDateTime(detailsData?.start_dt)}</div>
                                    </div>
                                    {detailsData?.category === "e" && <><div className="col-auto align-self-center"><img src="https://content.harstatic.com/media/icons/arrow_faded.svg" /></div>
                                        <div className="col-auto">
                                            <div className="auxiliary__fadded font_size--medium font_weight--semi_bold text-uppercase pb-2">Ends</div>
                                            <div className="auxiliary__fadded font_size--small">{getDayLong(detailsData?.end_dt)?.toUpperCase()}</div>
                                            <div className="font_size--medium font_weight--bold">{formatHarDateTime(detailsData?.end_dt)}</div>
                                        </div></>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7  font_size--medium">
                                    {!!detailsData?.reminder_minutes_prior_text && <div className="row no-gutters pb-4">
                                        <div className="col-auto min-w-35px"><img className='w-18px' src="https://content.harstatic.com/media/icons/notification_bell_black.svg" /></div>
                                <div className="col align-self-center">

                                    {detailsData?.reminder_minutes_prior_text}
                                </div>
                            </div>

                            }

                                    {(!!detailsData?.location ||!!detailsData?.property_full_street_address) && <div className="row no-gutters pb-4">
                                        <div className="col-auto min-w-35px"><img className='w-20px' src="https://content.harstatic.com/media/icons/map_filled_black.svg" /></div>
                                        <div className="col align-self-center">{detailsData?.location || detailsData?.property_full_street_address}</div>
                                    </div>}
                                    {/**<div className="row no-gutters pb-4">
                        <div className="col-auto"><img src="https://content.harstatic.com/media/icons/mdi-calendar-month_black.svg" /></div>
                        <div className="col align-self-center">{getDayLong(detailsData?.created_dt||taskData?.created_dt)} {formatHarDateTime(taskData?.created_dt)}</div>
                     </div>*/}
                                    <div className="pb-4">
                                        {detailsData?.notes}
                                    </div>
                                </div>
                                <div className="col-5">
                                    {!!detailsData?.contacts.length && <h3>Contacts</h3>}
                                    <div className=" overflow-auto min-h-150px">
                                        {detailsData?.contacts.map((item, ind) =>
                                            <a key={ind} onClick={() => {(item.deleted!=="1"&& item.id!=0)&&openContactQuickOverviewPopup?.(item.id.toString())}} className="agent_signature agent_signature__medium mb-2 cursor--hand">
                                                <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                                                <div className="agent_signature__text">{item.first_name} {item.last_name} {item.deleted=="1"?`(deleted)`:""} <br/><div className='auxiliary__fadded font_size--small'>{getContactSubtitle(item.email,item.phone,item.first_name,item.last_name)}</div></div>
                                            </a>)}
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                </div>
                {detailsData?.id &&
                 <TaskForm refreshData={() => props.refreshData?.()} show={showEditTask} toggle={toggleEditTask} category={detailsData?.category || "e"} taskDetail={detailsData}
                  onClose={() => {
                    props.toggle?.(false);

                }}
                openContactQuickOverviewPopup={(contactid:string)=>{setContactId(contactid);setShowContactDetailPopup(true);}}
                />}
              <ContactDetailPopover
                show={showContactDetailPopup}
                viewProfile={() => {
                    setShowContactDetailPopup(showContactDetailPopup ? false : true);
                    setTimeout(() => {
                        history.push(`/contacts/${id}/overview`, { referrer: '/contacts' })
                    }, 400)
                }}
                id={!!contactId ? parseInt(contactId) : 0}
                toggleShow={() => setShowContactDetailPopup(false)}
               // reload={reload}
            />
                {!!showDeletePopup && detailsData?.is_recurrent == '1' &&
                    <DefaultPopUp title="Delete Recurring Task" className="custom--modal initmodal" isOpen={showDeletePopup} toggleShow={() => setShowDeletePopup(false)} onDidDismiss={() => setShowDeletePopup(false)}>
                        <DefaultPopUp.Body>
                            <div className="bg-white d-flex justify-content-center">
                                <div className="align-self-center pt-4">
                                    <div role="radio" className="custom-control custom-radio custom-control-inline d-flex">
                                        <input type="radio" id="radio_button_medium_1" checked={selectedDeleteOption == "0"} value={"0"} onChange={() => setSelectedDeleteOption("0")} name="radio_button_medium" className="custom-control-input"></input>
                                        <label className="custom-control-label" htmlFor="radio_button_medium_1">Delete this task</label>
                                    </div>
                                    <div role="radio1" className="custom-control custom-radio custom-control-inline d-flex">
                                        <input type="radio" id="radio_button_medium_2" checked={selectedDeleteOption == "1"} value={"1"} onChange={() => setSelectedDeleteOption("1")} name="radio_button_medium" className="custom-control-input"></input>
                                        <label className="custom-control-label" htmlFor="radio_button_medium_2">Delete this and following task</label>
                                    </div>
                                    <div role="radio2" className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="radio_button_medium_3" checked={selectedDeleteOption == "2"} value={"2"} onChange={() => setSelectedDeleteOption("2")} name="radio_button_medium" className="custom-control-input"></input>
                                        <label className="custom-control-label" htmlFor="radio_button_medium_3">Delete all tasks</label>
                                    </div>
                                </div>
                            </div>
                        </DefaultPopUp.Body>
                        <DefaultPopUp.ActionBar>
                            <div className="ml-4 mr-4">
                                <div className="row pt-2 mb-2">
                                    <div className="col-auto algin-self-center">
                                        <Button kind="shapeless" className="btn--medium" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
                                    </div>
                                    <div className="col-auto ml-auto">
                                        <ProminentButton clickConfirmation="Are you sure? you want to delete this task/event?" onClick={() => { handleTaskRemove() }}>Delete</ProminentButton>
                                    </div>
                                </div>
                            </div>
                        </DefaultPopUp.ActionBar>
                    </DefaultPopUp>}

            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="pl-5 pr-5 pt-2 pb-2">
                    <div className="row">
                        <div className="col align-self-center">
                            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" disabled={details.loading} id="checkbox_button_medium_1" onChange={toggleTaskStatus} name="completed" checked={detailsData?.completed} className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="checkbox_button_medium_1">Mark as Complete</label>
                            </div>
                        </div>
                        <div className="col-auto">
                            {detailsData?.is_recurrent == '0' ? <ShapelessRedButton className='font-weight-normal' clickConfirmation="Are you sure? you want to delete this task/event?" onClick={() => { handleTaskRemove() }}>Delete</ShapelessRedButton> :
                                <ShapelessRedButton className='font-weight-normal' onClick={() => !details.loading && setShowDeletePopup(true)}>Delete</ShapelessRedButton>}

                        </div>
                        <div className="col-auto">
                            <PrimaryButton disabled={details.loading} onClick={() => !details.loading && toggleEditTask(true)}>Edit {detailsData?.category_text}</PrimaryButton>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp >
    );
}

const Loading = () => {
    return (
        <div>
            <div className=' loading__text h-20px mb-3 w-60px'></div>
            <div className=' loading__text w-120px mb-4'></div>
            <div className='card_boxed'>
                <div className="row">
                    <div className="col-auto">
                        <div className=" loading__text_small w-80px mb-2"></div>
                        <div className=" loading__text_small w-100px mb-3"></div>
                        <div className=" loading__text w-110px mb-3"></div>
                        <div className=" loading__text_small w-80px mb-2"></div>
                    </div>
                    <div className="col-auto align-self-center"><img src="https://content.harstatic.com/media/icons/arrow_faded.svg" /></div>
                    <div className="col-auto">
                        <div className=" loading__text_small w-80px mb-2"></div>
                        <div className=" loading__text_small w-100px mb-3"></div>
                        <div className=" loading__text w-110px mb-3"></div>
                        <div className=" loading__text_small w-80px mb-2"></div>
                    </div>
                </div>
            </div>
            <div className='card_boxed d-none'>
                <div className=" loading__text w-110px mb-3"></div>
                <div className="d-flex align-items-center">
                    <div className=" loading__rounded w-40px h-40px bg_color_cloudy_sky_light mr-3"></div>
                    <div><div className=" loading__text_small w-80px mb-2"></div></div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <div className='d-flex align-items-center pt-3 mb-4'>
                        <div className='w-20px mr-3'><div className="loading__text_small w-10px h-10px bg_color_cloudy_sky_light mr-4"></div></div>
                        <div><div className=" loading__text w-150px"></div></div>
                    </div>

                    <div className='d-flex align-items-center pt-3 mb-4'>
                        <div className='w-20px mr-3'><div className="loading__text_small w-10px h-10px bg_color_cloudy_sky_light mr-4"></div></div>
                        <div><div className=" loading__text w-150px"></div></div>
                    </div>

                    <div className='d-flex align-items-center pt-3 mb-4'>
                        <div className='w-20px mr-3'><div className="loading__text_small w-10px h-10px bg_color_cloudy_sky_light mr-4"></div></div>
                        <div><div className=" loading__text w-150px"></div></div>
                    </div>

                    <div className='d-flex align-items-center pt-3 mb-4  border-color--cloudy-sky-light pb-4'>
                        <div className='w-20px mr-3'><div className="loading__text_small w-10px h-10px bg_color_cloudy_sky_light mr-4"></div></div>
                        <div><div className=" loading__text w-150px"></div></div>
                    </div>
                </div>
                <div className='col pt-3'>
                    <div className=" loading__text w-136px"></div>
                    <div className=" overflow-auto min-h-150px pt-3">
                        <a className="d-flex agent_signature agent_signature__medium mb-2 mt-2 cursor--hand">
                            <div className=" loading__rounded w-40px h-40px bg_color_cloudy_sky_light mr-3"></div>
                            <div className=" loading__text w-150px align-self-center"></div>
                        </a>

                        <a className="agent_signature agent_signature__medium mb-2 mt-2 cursor--hand">
                            <div className=" loading__rounded w-40px h-40px bg_color_cloudy_sky_light mr-3"></div>
                            <div className=" loading__text w-150px align-self-center"></div>
                        </a>
                        <a className="agent_signature agent_signature__medium mb-2 mt-2 cursor--hand">
                            <div className=" loading__rounded w-40px h-40px bg_color_cloudy_sky_light mr-3"></div>
                            <div className=" loading__text w-150px align-self-center"></div>
                        </a>

                    </div>
                </div>

            </div>

        </div>
    );
}