 import { IContactDocumentsScreenProps } from './ContactDocumentsScreen.def';
import './ContactDocumentsScreen.scss';
import {  Dropdown } from 'react-bootstrap';
import { FileStack } from 'views/forms/files/FileStack';
import { ConfirmPopUp } from 'views/popups/ConfirmPopUp/ConfirmPopUp';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { formatSystemDateShort } from 'scripts/helpers/dates';
import moment from 'moment';
import { useParams } from 'react-router';
import { handleDownloadFile, handleOpenFile } from './actions/actions';
import { useState } from 'react';
import { IonSpinner } from '@ionic/react';



   export const ContactDocumentsScreenDesktop = (props:IContactDocumentsScreenProps): JSX.Element => {
    const {data,loading,update,remove,openEdit,isEditPopupShow,setSelectedData,selectedData,uploadFile}=props;
    const { id } = useParams<{ id: string }>();
    const [downloadLoading,setDownloadLoading]=useState('0');

    const handleDownload = async(name:string,docID:string,ext:string)=>{
        setDownloadLoading(docID)
       await handleDownloadFile(name,docID,id,ext); 
       setDownloadLoading("0");
    }

    return (
            
            <div className="container animate__animated animate__fadeIn min-h-300px h-300px">
                <div className="row pt-5 pb-5">
                    <div className="col align-self-center">
                        <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Documents</h2>
                    </div>

                    {!!data.length&&  <FileStack.Document text='Add' onUploadDone={(data) => { uploadFile(data) }} />}
                </div>
                {/*<IonContent>  i am removing this IonContent bcz it creating extra scroll */}
                    {loading?<Loading/>:
                    !!data.length?data.map((item, index) => {
                        return (
                            <div className="border-bottom pb-2 pt-2 font_size--medium" key={index}>
                                <div className="row">
                                 <div className="col-sm-5 col-6 align-self-center cursor--hand" onClick={()=> handleOpenFile(item.name,item.id,id)}><img className="mr-2 ml-md-2 cursor--hand" src={"https://content.harstatic.com/media/icons/"+item.icon} alt='img' /> {`${item.name} (${item.extension})`}</div>
                                 <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">{formatSystemDateShort(moment(item.created_dt).toDate())}</div>
                                    <div className="col-sm-2 col-12 text-right align-self-center">
                                    {item.id==downloadLoading?<IonSpinner className=' w-20px h-20px ml-1' color='primary' />:

                                        <Dropdown  className='dropdown without__arrow openoverlay dropdown--custom d-inline-flex mr-3'>
                                            <Dropdown.Toggle className="btn btn--white btn--onlyicon" role="link" variant="none" href="#">
                                                <img src="https://content.harstatic.com/media/icons/dot_dot_dot.svg" alt='img'></img>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu  className="dropdown-menu animate slideIn dropdown-menu-right" align={'right'}>
                                                <Dropdown.Item href="#" onClick={()=>handleDownload(item.name,item.id,item.extension)}>Download</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=> openEdit(item)}>Edit File name</Dropdown.Item>
                                                <Dropdown.Item  onClick={()=>remove(item.id)}>Delete File</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    </div>
                                </div>
                            </div>
                        )
                    }): 
                    
                <div>
                    <div className="pb-5 mr-4">
                          <div className="font_weight--semi_bold font_size--large">You don't have any documents</div>
                          <div>Add relevant documents for this contact, such as tax slips, renovation, property documents .</div>
                     </div>
                   
                     <FileStack.Document text='Add a document' onUploadDone={(data) => { uploadFile(data) }} />

                </div>}
                    {isEditPopupShow &&
                        <ConfirmPopUp  title="Edit Document" className="custom--modal__smallheight" isOpen={isEditPopupShow} onDidDismiss={() => openEdit()} >
                            <ConfirmPopUp.Body>
                                <div className="bg-white d-flex justify-content-center min-h-100pr">
                                    <div className="align-self-center min-w-400px">
                                        <Textbox label='Rename Document' placeholder="Rename file name" value={selectedData?.name} onIonChange={(e)=>selectedData&&setSelectedData({...selectedData,name:e.detail.value??""})}  required={true} />
                                    </div>
                                </div>
                            </ConfirmPopUp.Body>
                            <ConfirmPopUp.ConfirmButton onClick={update} ></ConfirmPopUp.ConfirmButton>
                        </ConfirmPopUp>
                    }
                {/*!!docUploadData &&
                        <ConfirmPopUp  title="Upload Document" className="custom--modal__smallheight" isOpen={!!docUploadData} onDidDismiss={()=>openEdit()} >
                            <ConfirmPopUp.Body>
                                <div className="bg-white d-flex justify-content-center min-h-100pr">
                                    <div className="align-self-center min-w-400px">
                                        <Textbox label='Document Name' placeholder="Name" value={docUploadData?.name} onIonChange={(e)=>docUploadData&&props.setDocUploadData({...docUploadData,name:e.detail.value??""})}  required={true} />
                                    </div>
                                </div>
                            </ConfirmPopUp.Body>
                            <ConfirmPopUp.ConfirmButton onClick={saveUploadData} ></ConfirmPopUp.ConfirmButton>
                        </ConfirmPopUp>
                */}
                {/* </IonContent> */}
            </div>

    );
}
function Loading(_props: { }): JSX.Element {
    return (
             
                <div className="container animate__animated animate__fadeIn">
                       
                        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4 color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>  
                        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
        
                        <div className="border-bottom pb-2 pt-2 font_size--medium">
                            <div className="row">
                                <div className="col-sm-5 col-6 align-self-center d-flex align-items-center">
                                    <div className=" loading__square mr-3 w-425px h-36px"></div>
                                    <div className=" loading__text w-150px"></div>
                                </div>
                                <div className="col-sm-5 col-6 text-sm-left text-right align-self-center color_custom_4">
                                    <div className=" loading__text w-70px"></div>
                                </div>
                                <div className="col-sm-2 col-12 text-right align-self-center">
                                    <div className=" loading__square ml-auto w-425px h-36px"></div>
                                </div>
                            </div>
                        </div>
                        
                            
                        
        
        
                </div>)}
