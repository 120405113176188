import { FileStack } from 'views/forms/files/FileStack';
import { IAttachment } from '../NewEmail.def';
import { useEffect, useState } from 'react';
import { useResponsiveChoose } from 'scripts/hooks/general';
import { SendEmailsApi } from 'services/emails/sendEmails';
import { displayError } from 'scripts/hooks/feedbacks';


export const Attachment = (props: IAttachment): JSX.Element => {
    const { attachments, setAttachment, updateEmail } = props
    const maxSizeLimit = 10485760   //10 MB   
    const maxFileLimit = 5
    const allowTypes = ['application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/zip',
        'application/x-zip-compressed',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'text/csv',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-', 'application/vnd.apple.keynote',
        'image/jpeg',
        'image/png'
    ];

    const [maxSize, setMaxSize] = useState(maxSizeLimit)
    const [maxFile, setFileLimit] = useState(maxFileLimit)
    const multiFilesUpload = (files: any) => {
        let attachmentInfo: SendEmailsApi.IEmailAttachment[] = []
        files.map((file: any) => {
            attachmentInfo.push({
                'container': file.container.toString(),
                'key': file.key.toString(),
                'file_name': file.filename.toString(),
                "file_preview": file.url.toString(),
                "file_size": file.size,
                "content_type": file.mimetype,
            })

        })
        setAttachment?.([...attachments ? attachments : [], ...attachmentInfo])
        updateEmail?.("attachments", [...attachments ? attachments : [], ...attachmentInfo])
    }

    const calculateMeta = (files: any[] | undefined) => {
        if (files) {
            let filesSize = files.map((file: any) => Number(file.file_size)).reduce((prev: number, next: number) => prev + next, 0);
            setMaxSize(maxSizeLimit - filesSize)
        }
        setFileLimit(attachments?.length ? 5 - attachments.length : 5)
    }

    const showFileFormatWarning = () => {
        displayError("The File is not supported", "The File you are trying to attach is not supported.")
    }

    useEffect(() => {
        calculateMeta(attachments)
    }, [attachments])

    return useResponsiveChoose(<FileStack text={"Attach"}
        onMultiFilesUpload={multiFilesUpload}
        buttonStyles={{ kind: 'ordinary', size: "medium" }}
        maxFiles={maxFile}
        maxSize={maxSize}
        onInvalidFileType={(isValid: boolean) => isValid ? showFileFormatWarning() : ''}
        accept={allowTypes}
    />
        , <FileStack
            onMultiFilesUpload={multiFilesUpload}
            maxFiles={maxFile}
            maxSize={maxSize}
            onInvalidFileType={(isValid: boolean) => isValid ? showFileFormatWarning() : ''}
            uploadIcon={'https://content.harstatic.com/media/temporary/paperclip@3x.png'}
            accept={allowTypes}
        />
    );



}