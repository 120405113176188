import { IAddTaskPopUpProps } from './AddTaskPopUp.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AddTaskPopUpApp } from './AddTaskPopUp.app';
import { AddTaskPopUpDesktop } from './AddTaskPopUp.desktop';

export const AddTaskPopUp = (props: IAddTaskPopUpProps): JSX.Element =>
{
    const AddTaskPopUpPlatform = useResponsiveChoose(AddTaskPopUpDesktop, AddTaskPopUpApp);
    return (
        <AddTaskPopUpPlatform {...props} />
    );
}
