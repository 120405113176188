
import { IContactManageTemplateProps } from './ContactManageTemplate.def';
import './ContactManageTemplate.scss';
import { useState,useEffect} from "react";
import {  IonRouterLink, useIonActionSheet} from "@ionic/react";
import { ManageTagsPopUp } from 'views/contacts/popups/ManageTagsPopUp';
import { ImportContactsPopUp } from 'views/contacts/popups/ImportContactsPopUp';
import { DefaultTemplate } from '../DefaultTemplate';
//import { AddContactScreenApp } from 'views/screens/contacts/AddContactScreen/AddContactScreen.app';

export const ContactManageTemplateApp = (props:IContactManageTemplateProps): JSX.Element =>
{
    const { isDragging,headerButton,downloadContact, visibleManageTag, children,noContent,onSwipeRefresh,matrixStatusId,matrixInit} = props
    const [showManage,setShowManage] = useState(false);
    const [showImportPopUp, setShowImportPopUp] = useState(false)
    // const [showAddContactsModal, setShowAddContactsModal] = useState(false);

    if(visibleManageTag) {
        setShowManage(true)
    }

    useEffect(() => {
        if(props.showImportPopUp){
            setShowImportPopUp(true)
        }
    },[props.showImportPopUp])
    
    const Header = () => 
        <CustomMobileHeader
            showManage={showManage}
            headerButton={headerButton}
            showImportPopUp={showImportPopUp}
            downloadContact={downloadContact}
            setShowManage={setShowManage}
            setShowImportPopUp={setShowImportPopUp}
            // setShowAddContactsModal={setShowAddContactsModal}
        />

    return (
        <DefaultTemplate title="Contacts" selectedMenu='Contacts' CustomMobileHeader={Header} isDragging={isDragging} noContent={noContent} onSwipeRefresh={onSwipeRefresh}>
            <ManageTagsPopUp show={showManage} toggleShow={(show?:boolean) => setShowManage(show ? show : false)} setFilter={(id:string,name:string) =>  {
                props.setFilter?.(id,name);
                setShowManage(false)
            }
        } />
            <ImportContactsPopUp show={showImportPopUp} toggleShow={() => setShowImportPopUp(showImportPopUp ? false : true)} matrixStatusId={matrixStatusId} onMatrixInit={matrixInit} reload={props.reload}/>
            {/* <AddContactScreenApp show={showAddContactsModal} toggleShow={setShowAddContactsModal} /> */}
            {noContent ?
                children? children:
                <>
                    <img src="https://content.harstatic.com/media/temprary/app_homescreen.png" />
                </> 
                :
               <>
                    {
                      children? children:
                      <>
                          <img src="https://content.harstatic.com/media/temprary/app_homescreen.png" />
                      </>
                  }
                </>
           }
        </DefaultTemplate>
    );
} 

const CustomMobileHeader = (props:IContactManageTemplateProps.CustomMobileHeader): JSX.Element =>
{
    const { showManage, showImportPopUp,headerButton , downloadContact, setShowManage, setShowImportPopUp} = props
    const [present] = useIonActionSheet();

    return (
        <div className="ml-auto align-self-center">
            <a className="pr-4" 
                onClick={() =>
                    present({
                        buttons: [
                         ...headerButton ? headerButton : [],
                    {
                     
                        text: 'Manage Tags',
                        cssClass:'font_weight font_weight--semi_bold',
                        data: {
                        action: 'delete',
                        },
                        handler: () => {
                            setShowManage(showManage ? false : true)
                        } 
                    },
                    {
                        text: 'Import Contacts',
                        cssClass:'font_weight font_weight--semi_bold',
                        data: {
                        action: 'share',
                        },
                        handler: () => {
                            setShowImportPopUp(showImportPopUp ? false : true)
                        } 
                    },
                    {
                        text: 'Export Contacts',
                        cssClass:'font_weight font_weight--semi_bold',
                        data: {
                        action: 'share',
                        },
                        handler: () => {
                            if(downloadContact){
                                downloadContact()
                            }
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                        action: 'cancel',
                        },
                    }
                    ],
                
                    })
                }
                >
                <img className=' w-18px' src="https://content.harstatic.com/media/icons/icons-settings-auxilary.svg"/>
            </a>
         
            <IonRouterLink routerLink="/contacts/create"><img className=' w-22px' src="https://content.harstatic.com/media/icons/plus_auxilliary.svg" /></IonRouterLink>
         </div>
    );
}



    
