import { ContactsTypeaheadMultiApp } from 'views/forms/typeaheads/ContactsTypeahead/ContactsTypeaheadMulti.app';
import { IConfirmation, IConfirmationClose, INewEmailProps } from './NewEmail.def';
import './NewEmail.scss';
import { Editor } from '@tinymce/tinymce-react';
import { IonButton, IonContent, IonModal, IonToolbar, IonButtons, IonTitle, IonHeader, IonItem, IonInput, IonAlert, IonNote, IonFooter } from "@ionic/react";
import { SearchContactsApi, fetchSearchContactsService, useSearchContactsService } from 'services/contacts/searchContacts';
import { useEffect, useState } from 'react';
import { SendEmailsApi, useSendEmailsService } from 'services/emails/sendEmails';
import { useCreateDraftService } from 'services/emails/createDraft';
import { useDeleteMultiDraftsService } from 'services/emails/deleteMultiDrafts';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useEmailDetailsService } from 'services/emails/emailDetails';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { IEmailRecipient } from 'services/emails/searchEmails';
import { EmailTags } from 'views/general/EmailTags';
import { Attachment } from './components/Attachment';
import { AttachmentFiles } from './components/AttachmentFiles';
import { format, parseISO } from 'date-fns';

export const NewEmailApp = (props: INewEmailProps): JSX.Element => {
    const { show, title, messageId, type, action, contactId, provider_email, connectionStatus, onClose, changePreview, reload } = props

    const [email, setEmail] = useState<SendEmailsApi.Request>({
        id: ""
    })
    const [ToContacts, saveToContact] = useState<SearchContactsApi.IContactSearchItem[]>()
    type EmailApiKey = keyof SendEmailsApi.Request
    const [sending, setSending] = useState(false)
    const [confirmClosing, setConfirmClosing] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [updatingApi, setUpdatingApi] = useState(false)
    const [error, setError] = useState("")
    const [CClist, setCClist] = useState<string[]>()
    const [BCClist, setBCClist] = useState<string[]>()
    const [confirmMessage, setConfirmMessage] = useState("")
    const [oldMessage, setOldMessage] = useState("")
    const [draftTimer, setDraftTimer] = useState(0)
    const [counter, setCounter] = useState(0)

    //services   
    const [emailService, emailServiceAction] = useEmailDetailsService();
    const [newDraftSer, newDraftSerAction] = useCreateDraftService()
    const [contactSearch, contactSearchAction] = useSearchContactsService()
    const [saveEmailSer, saveEmailSerAction] = useSendEmailsService()
    const [, deleteDraftSerAction] = useDeleteMultiDraftsService()


    const sendEmail = () => {
        if (!connectionStatus) {
            return
        }
        if ((!ToContacts || ToContacts.length === 0)) {
            setError("select contacts to send")
            setSending(false)
        } else {
            setError("")
            setSending(true)
            saveEmailSerAction.fetch({
                send: "1",
                ...email
            })
        }
    }
    const discard = () => {
        if (email.id) {
            deleteDraftSerAction.fetch({ draft_ids: [email.id] })
        }
        clear()
        onClose?.()
    }

    const saveDraft = () => {
        if (!connectionStatus) {
            return
        }
        if (email.id === "") {
            if (newDraftSer.loading || saveEmailSer.loading) {
                return
            }
            newDraftSerAction.fetch({
                subject: email.subject,
                message: email.message,
                recipients: email.recipients,
                recipients_cc: email.recipients_cc,
                recipients_bcc: email.recipients_bcc,
                attachments: email.attachments
            })
        } else {
            saveEmailSerAction.fetch({
                id: email.id,
                subject: email.subject,
                message: email.message,
                recipients: email.recipients,
                recipients_cc: email.recipients_cc,
                recipients_bcc: email.recipients_bcc,
                attachments: email.attachments
            })
        }

    }

    const getContacts = (persons: IEmailRecipient[]) => {
        let contact_ids: any[] = []
        persons.map((contact: IEmailRecipient) => {
            if (contact.id) {
                contact_ids.push(contact.id)
            }
        })
        if (contact_ids.length > 0) {
            contactSearchAction.fetch({ 'contact_ids': contact_ids })
        } else {
            saveToContact([])
        }
    }

    //Create new Draft
    useServiceDidSucceeded((newDraftSer), (response) => {
        if (sending && connectionStatus) {
            saveEmailSerAction.fetch({ ...email, id: response.data.id, send: "1" })
        } else {
            setEmail({ ...email, id: response.data.id, attachments: response.data.attachments })
        }
        if (type === 'draft') {
            changePreview?.({
                id: response.data.message_id,
                message_uid: response.data.id,
                short_message: response.data.short_message,
                subject: email.subject,
                date_tag: 'Today',
                created_dt: response.data.created_dt
            }, true)
        }
    })

    //Update Draft or Email Sent
    useServiceDidSucceeded((saveEmailSer), (response: any) => {
        if (sending) {
            displayMessage("Email have been sent.")
            clear()
            onClose?.()
            setSending(false)
            if (type === 'draft') {
                reload?.()
            }
        } else {
            if (type === 'draft') {
                changePreview?.({
                    id: response?.data.message_id,
                    message_uid: response.data.id,
                    short_message: response.data.short_message,
                    subject: email.subject
                }, false)

            }
        }
        if (response.data.attachments) {
            setUpdatingApi(false)
            setEmail({ ...email, attachments: response.data.attachments })
        }
    })

    useServiceDidFailed((saveEmailSer), () => {
        setSending(false)

    })

    //get message for Draft
    useServiceDidSucceeded((emailService), (response) => {
        setUpdatingApi(false)
        if (type && response.message_uid && type === `draft`) {
            getContacts(response.recipients)
            setEmail({
                id: response.message_uid,
                subject: response.subject,
                message: response.message,
                recipients_cc: response.recipients_cc,
                recipients_bcc: response.recipients_bcc,
                attachments: response.attachments

            })
            setOldMessage(response.message)
            let nonContactEmails: string[] = []
            response.recipients.map((contact) => {
                if (!contact.id) {
                    nonContactEmails.push(contact.email)
                }
            })
            setCClist(response.recipients_cc)
            setBCClist(response.recipients_bcc)
        } else if (type !== 'draft' && action === 'reply') {
            let recipientIds: string[] = []
            if (response.recipients) {
                response.recipients.map((contact) => recipientIds.push(contact.id))
            }
            if (type === 'sent') {
                getContacts(response.recipients)
            } else {
                saveContact(Number(response.contact_id))
            }


            setEmail({
                id: "",
                subject: 'Re: ' + response.subject,
                message: '<br/><br/>' + response.message,
                recipients: type === 'sent' ? recipientIds : [response.contact_id],
                recipients_cc: response.recipients_cc,
                recipients_bcc: response.recipients_bcc,
                reply_to_message_id: response.message_uid,
                attachments: response.attachments

            })
            setOldMessage('<br/><br/>' + response.message)
            setCClist(response.recipients_cc)
            setBCClist(response.recipients_bcc)

        } else if (type !== 'draft' && action && action === 'forward') {

            // Mon, Jun 26, 2023 at 10:16 PM
            let mailDate = new Date(parseISO(response.created_dt))
            let dateFormat = format(mailDate, "EEE, MMM dd, yyyy' at 'HH:mm:ss a")
            let name = response.first_name || response.last_name ? response.first_name + ' ' + response.last_name : ''

            let fwdMessage = '<br/><br/><div dir="ltr">---------- Forwarded message ---------<br>From: <b dir="auto">' + name + '</b> <span dir="auto">&lt;<a href="mailto:' + response.from_address + '">' + response.from_address + '</a>&gt;</span><br>Date:' + dateFormat + '<br>Subject:' + response.subject + '</div><br/><br/>'

            setEmail({
                id: "",
                subject: 'Fwd: ' + response.subject,
                message: fwdMessage + response.message,
                attachments: response.attachments

            })
            setOldMessage(fwdMessage + response.message)
            setCClist(response.recipients_cc)
            setBCClist(response.recipients_bcc)
        }

    })


    //save Contacts
    useServiceDidSucceeded((contactSearch), (response) => {
        saveToContact(response.list)
        setUpdatingApi(false)
        prepareSender("recipients", response.list)

    })


    async function saveContact(contact_id: number) {
        const contactService = await fetchSearchContactsService({ contact_ids: [contact_id] })
        if (contactService.list) {
            saveToContact(contactService.list)
        }
    }



    function clear() {
        setUpdatingApi(false)
        saveToContact([])
        setCClist([])
        setBCClist([])
        setOldMessage("")
        setError("")
        setUpdatingApi(false)
        setEmail({
            id: ''
        })

    }
    function updateEmail(key: EmailApiKey, value: string | string[] | SendEmailsApi.IEmailAttachment[]) {
        if (!updatingApi) {
            setUpdatingApi(true)
        }
        setEmail({ ...email, [key]: value })
    }

    function checkInlineImages(html: string) {
        const pattern = /<img src="[blob:|data:].*?>/g
        var message = html
        html.match(pattern)?.map((_images: any) => {
            message = message.replace(pattern, "");

        })
        updateEmail("message", message)
    }




    function prepareSender(key: EmailApiKey, contact: SearchContactsApi.IContactSearchItem[] | undefined) {
        let emails: string[] = []
        contact?.map((contact) => {
            if (Number(contact.id) <= 0) {
                emails.push(contact.primary_email)
            } else {
                emails.push(contact.id.toString())

            }
        })
        setEmail({ ...email, [key]: emails })
    }


    const save = () => {
        if (updatingApi) {

            if (draftTimer) { clearTimeout(draftTimer); }

            setDraftTimer(setTimeout(() => {
                saveDraft()
            }, 900))
        }
        return () => {
            if (draftTimer) {
                clearTimeout(draftTimer)
            }
        }
    }


    const removeAttach = (key: string, type: "key" | "id") => {
        let attachmentInfo = email.attachments?.filter((attach) => (type === 'key' && attach.key !== key) || (type === 'id' && attach.id !== key))
        if (attachmentInfo) {
            updateEmail("attachments", attachmentInfo)
        }

    }


    const close = () => {
        clear()
        onClose?.()

    }


    useEffect(() => {
        if (contactId) {
            saveContact(Number(contactId))
            setUpdatingApi(false)
            setEmail({ ...email, recipients: [contactId] })
        }
    }, [contactId])


    useEffect(() => {
        return save()
    }, [email])

    useEffect(() => {
        if (messageId && type) {
            emailServiceAction.fetch({ id: messageId, type: type })
            setCounter(0)
        } else {
            clear()
            setCounter(1)
        }
    }, [messageId])




    return (
        <>
            <Confirmation
                show={showConfirmation}
                message={confirmMessage}
                onClose={() => setShowConfirmation(false)}
                affirmative={() => {
                    if (email.id === "") {
                        if (!email.recipients || email?.recipients?.length === 0) {
                            setError("Select contacts to send")
                        } else {
                            setSending(true)
                            saveDraft()

                        }
                    } else {
                        sendEmail()
                    }
                    setShowConfirmation(false)
                }}
                negative={() => setShowConfirmation(false)} />

            <ConfirmClose show={confirmClosing}
                affirmative={() => {
                    saveDraft();
                    setConfirmClosing(false)
                }}
                negative={() => {
                    discard();
                    setConfirmClosing(false)
                }}
            />
            <IonModal className="gray_bg" trigger="open-modal" isOpen={show} backdropDismiss={false}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color={"blue"} onClick={() => close()}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>{title}</IonTitle>
                        <IonButtons slot="end">
                            {connectionStatus &&
                                <>
                                    {!email.attachments || (email.attachments && email?.attachments.length < 5) ?
                                        <IonButton color={"blue"} strong={true}>
                                            <Attachment attachments={email.attachments} files_count={email.attachments?.length} updateEmail={updateEmail} />
                                        </IonButton>
                                        :
                                        ''
                                    }
                                    <IonButton color={"blue"} disabled={sending} strong={true} onClick={() => {
                                        if (action === "forward" || action === 'reply' || action === 'draft') {
                                            setSending(true)
                                            if (email.id === "") {
                                                saveDraft()
                                            } else {
                                                sendEmail()
                                            }
                                        } else if (typeof email?.subject !== 'undefined' && email?.subject?.trim() !== '' && typeof email?.message !== 'undefined' && email?.message?.trim() !== '') {
                                            sendEmail()
                                        } else {
                                            let message = 'Are you sure you want to send email without'
                                            if (typeof email.subject === 'undefined' || email.subject === "") {
                                                message += " Subject "
                                            }
                                            if (typeof email.message === 'undefined' || email.message === "") {
                                                let assume = (typeof email.subject === 'undefined' || email?.subject === "") ? 'and' : ''
                                                message += assume + " Message"
                                            }
                                            setConfirmMessage(message)
                                            setShowConfirmation(true)
                                        }

                                    }}>
                                        {sending ? `Sending` : `Send`}
                                    </IonButton>
                                </>
                            }
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="autoheight_container">
                        <div className="form_sec mb-5 autoheight_container--top">
                            <IonItem>
                                <IonInput type="text" placeholder={`From: ${provider_email} `} disabled={true} className='ml-2' />
                            </IonItem>
                            <ContactsTypeaheadMultiApp multi secondary maxSelection={10} initialValues={ToContacts ? ToContacts : []} showCustomEmailOption={true} save={(items) => {
                                setError('')
                                setUpdatingApi(true)

                                saveToContact(items);
                                prepareSender('recipients', items)
                            }} />

                            {error &&
                                <IonNote color="danger" className='ml-4'>{error}</IonNote>
                            }
                            <IonItem>
                                <EmailTags
                                    list={CClist}
                                    save={(list?: string[]) => {
                                        updateEmail("recipients_cc", list ? list : [])
                                        setCClist(list)
                                    }}
                                    placeholder='CC'
                                />
                            </IonItem>
                            <IonItem>
                                <EmailTags
                                    list={BCClist}
                                    save={(list?: string[]) => {
                                        updateEmail("recipients_bcc", list ? list : [])
                                        setBCClist(list)
                                    }} placeholder='BCC'
                                />
                            </IonItem>
                            <IonItem>
                                <IonInput type="text" placeholder="Subject:" value={email.subject ? email.subject : ''} onIonInput={(e: any) => updateEmail('subject', e.target.value)} />
                            </IonItem>
                        </div>


                        <div className="form_sec mb-0 pt-3 pb-2 ion-padding autoheight_container--bottom">
                            <Editor
                                apiKey={process.env.REACT_APP_AS_TINYMCE_KEY}
                                onEditorChange={(html) => {
                                    if (counter > 0) {
                                        checkInlineImages(html)
                                    } else {
                                        setCounter(1)
                                    }
                                }}
                                initialValue={oldMessage ? oldMessage : ''}
                                init={{
                                    height: 500,
                                    width: '100%',
                                    paste_block_drop: true,
                                    menubar: false,
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat ',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                </IonContent>
                <IonFooter>
                    <AttachmentFiles removeAttach={removeAttach} attachments={email.attachments} />
                </IonFooter>
            </IonModal>
        </>
    );
}

export const ConfirmClose = (props: IConfirmationClose) => {
    const { show, affirmative, negative } = props
    return (
        <IonAlert
            isOpen={show}
            header="do you want to save this in Draft?"
            buttons={[
                {
                    text: 'No',
                    cssClass: 'alert-button-cancel',
                    handler: () => negative?.()
                },
                {
                    text: 'Yes',
                    cssClass: 'alert-button-confirm  ',
                    handler: () => affirmative?.()
                },
            ]}
        ></IonAlert >

    )
}


const Confirmation = (props: IConfirmation) => {
    const { show, message, affirmative, negative } = props
    return (
        <IonAlert
            isOpen={show}
            header={message}
            buttons={[
                {
                    text: 'No',
                    cssClass: 'alert-button-cancel',
                    handler: () => negative?.()
                },
                {
                    text: 'Yes',
                    cssClass: 'alert-button-confirm',
                    handler: () => affirmative?.()
                },
            ]}
        ></IonAlert >
    )

}