import { IErrorDialogComponentProps, IErrorDialogProps } from './ErrorDialog.def';
import { PropsWithChildren, useEffect } from 'react';
import './ErrorDialog.scss';
import { displayError } from 'scripts/hooks/feedbacks';

export function ErrorDialog(props: PropsWithChildren<IErrorDialogProps>): JSX.Element
{
    useEffect(() => {
        const { title, children:content, blur, autoClose, onClose } = props;
        const { close } = displayError(title, { content, blur, autoClose, onClose });
        return () => close();
    }, []);

    return <></>;
}

export namespace ErrorDialog
{
    export function Component(props: PropsWithChildren<IErrorDialogComponentProps>)
    {
        /*let timer: number | undefined;
        useEffect(() => {
            clearAutoClose();
            if (!props.autoClose || !props.onClose) {
                return
            }
            const timeOut = isNumber(props.autoClose) ? props.autoClose : 3000;
            timer = window.setTimeout(closeDialog, timeOut);
            return clearAutoClose;
        }, [props.autoClose, props.onClose])*/
        const closeDialog = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            //clearAutoClose();
            if (props.onClose) {
                props.onClose()
            }
        }
        /*const clearAutoClose = () => {
            if (timer) {
                clearTimeout(timer);
                timer = undefined;
            }
        }*/
        return (
            <>
                <div className=' d-block'>
                    <div className="alert alert--error fadded_from_bottom alert--bottom">
                        <div className={`alert--error__header ${props.children? ``: `border_radius--pudgy`}`}>
                            <div className="align-self-center pr-3"><img src="https://content.harstatic.com/media/icons/alert_faded_i.svg" /></div>
                            <div className="align-self-center pr-3">{props.title ?? 'Internal Error'}</div>
                            <a className="alert--error__close" onClick={closeDialog} href="#">
                                <img src="https://content.harstatic.com/media/icons/icons-close_faded.svg" />
                            </a>
                        </div>
                        {props.children && <div className="alert--error__content">{props.children} </div>}
                    </div>
                </div>
            </>
        );
    }
}