
import {
    IonButton, IonHeader, IonModal, IonToolbar, IonTitle, IonContent, IonButtons, IonList,
    IonLabel, IonItem, IonItemSliding, IonItemOptions, IonItemOption, IonInfiniteScrollContent, IonInfiniteScroll, IonRouterLink, IonSearchbar,
    IonIcon
} from "@ionic/react";

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { useEffect, useRef, useState } from "react";
import { IContactsTableProps, IEmptyGrid, IManageState, ISortModal } from "./ContactsTable.def";
import { ContactsFilterPopUp } from "../popups/ContactsFilterPopUp";
import { IContactsFilters, ILocationFilter, stagesOptions } from "../popups/ContactsFilterPopUp/ContactsFilterPopUp.def";
import { ShapelessButton } from "views/forms/buttons/Button";
import { NewTagPopUp } from "../popups/NewTagPopUp";
import { ManageTagsPopUp } from "../popups/ManageTagsPopUp";
import { Avatar } from "../Avatar";
import './ContactsTable.scss'
import { ContactsConfirmDelete } from "../popups/ContactsConfirmDelete";
import { useHistory, useLocation } from "react-router";
import { ContactImportStatus } from "../component/ContactImportStatus";
import { peopleOutline } from 'ionicons/icons'
import { ContactsEditTagsPopUp } from "../popups/ContactsEditTagsPopUp";
import { isProduction } from "scripts/application/settings";
import { ContactTitle } from "views/general/ContactTitle";
import { displayMessage } from "scripts/hooks/feedbacks";
import { transformPhoneNumber } from 'scripts/helpers/texts';


export const ContactsTableApp = (props: IContactsTableProps): JSX.Element => {
    const { result, contacts, file, page, isDownloading, reload } = props
    const [newTagPop, setNewTagPop] = useState(false)
    const [, setShowPopUp] = useState(false)
    const [showSort, setShowSort] = useState(false)
    const [, setSelected] = useState<any>([])
    const [showManageTag, setShowManageTag] = useState(false)
    const [isManage, setManageState] = useState(false)
    const [timer, setTimer] = useState<number | undefined>()
    const location = useLocation<ILocationFilter>();
    const [filtersCountNo, setFilterCount] = useState<number>(0)
    const [filters, setFilters] = useState<IContactsFilters>({
        cities: [],
        scores: [],
        stages: [],
        owners: [],
        zip_codes: [],
        deal_stages: [],
        companies: [],
        sources: [],
        tags: [],
        last_contact_date: "",
        birthday_start: "",
        birthday_end: "",
        home_anniversary_start: "",
        home_anniversary_end: "",
        lead_type: ""


    });
    const [showDuplicate, setShowDuplicate] = useState(false)
    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore()
        }
    }
    const history = useHistory()

    const searchField = useRef<HTMLIonSearchbarElement>(null)


    const doSearch = (e: any) => {
        e.preventDefault()
        if (e.target.value.length === 0) {
            clear()
        }
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            if (props.changeFilter) {
                props.changeFilter({ keyword: e.target.value, sort_by: "", page: 1 }, 1)
            }
        }, 800))

    }

    const doSort = (param: any) => {
        if (props.changeFilter) {
            props.changeFilter({ ...param, sort_dir: 'asc' }, 1)

        }
    }



    const SortToggle = () => {
        setShowSort(showSort ? false : true)
    }


    const clear = () => {
        if (null !== searchField.current) {
            searchField.current.value = ""
        }
    }

    function filterhandler<K extends keyof IContactsFilters>(key: K, value: IContactsFilters[K]) {
        setFilters({ ...filters, [key]: value })
    }

    const filterCount = () => {
        var i = 0
        if (filters.cities && filters.cities.length > 0) {
            i = i + filters.cities.length
        }
        if (filters.scores && filters.scores.length > 0) {
            i = i + filters.scores.length
        }
        if (filters.stages && filters.stages.length > 0) {
            i = i + filters.stages.length
        }
        if (filters.tags && filters.tags.length > 0) {
            i = i + filters.tags.length
        }
        if (filters.deal_stages && filters.deal_stages.length > 0) {
            i = i + filters.deal_stages.length
        }
        if (filters.owners && filters.owners.length > 0) {
            i = i + filters.owners.length
        }
        if (filters.zip_codes && filters.zip_codes.length > 0) {
            i = i + filters.zip_codes.length
        }
        if (filters.companies && filters.companies.length > 0) {
            i = i + filters.companies.length
        }
        if (filters.sources && filters.sources.length > 0) {
            i = i + filters.sources.length
        }
        if (filters.last_contact_date !== "") {
            i = i + 1
        }

        if (filters.birthday_start || filters.birthday_end) {
            i = i + 1
        }

        if (filters.home_anniversary_start || filters.home_anniversary_end) {
            i = i + 1
        }

        if (filters.lead_type) {
            i = i + 1
        }
        setFilterCount(i)
    }


    const clearFilters = () => {
        let filter = {
            cities: [],
            scores: [],
            stages: [],
            zip_codes: [],
            owners: [],
            deal_stages: [],
            companies: [],
            sources: [],
            tags: [],
            last_contact_date: "",
            birthday_start: "",
            birthday_end: "",
            home_anniversary_start: "",
            home_anniversary_end: "",
            lead_type: ""

        }
        setFilters(filter)
        applyFilter(filter)

    }

    const applyFilter = (param: any) => {
        props.changeFilter?.({ ...param, sort_by: "created_dt", sort_dir: "asc", page: 1 }, 1)
    }



    const manageTagPopUp = (b: boolean) => {
        if (props.manageTagPopUp && b) {
            setShowManageTag(b)
            manageTagPopUp(showManageTag)
        }
    }
    function getStageOption(status: string) {
        let stage = stagesOptions.filter((s) => Number(status) === s.short_value)
        return stage[0]
    }

    const downloadContact = (selectedContact: any): any => {
        props.downloadContact?.(selectedContact)
    }

    if (file) {
        var link = document.createElement("a");
        link.href = file;
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        if (location.state && location?.state.filters) {
            setFilters(location?.state.filters)
        }
    }, [location?.state])


    useEffect(() => {
        console.log(filters)
    }, [filters])



    return (
        <div>
            <div>
                <IonSearchbar ref={searchField} onIonInput={(e) => doSearch(e)} className="custom ion-no-padding ion-padding-start ion-padding-end pt-3 pb-3" placeholder="Search Contacts"></IonSearchbar>
                <div className="action_bar">
                    <div> {result?.response ? result.response.total + " contacts" : ''}</div>
                    <a className="action_bar__link ml-auto mr-4 cursor_pointer" onClick={() => { setManageState(isManage ? false : true); setSelected([]) }}>Manage</a>
                    <a className={`action_bar__link mr-4 text-decoration-none  ${showDuplicate ? `opacity-64pr` : `cursor_pointer`}`} onClick={() => {
                        if (showDuplicate) {
                            return
                        }
                        SortToggle()
                    }}>Sort</a>
                    <ContactsFilterPopUp
                        filters={filters}
                        onChange={filterhandler}
                        onClear={clearFilters}
                        numberOfFilter={filterCount}
                        disabled={showDuplicate}
                        applyFilter={() => {
                            applyFilter(filters)
                        }}
                        filtersCount={filtersCountNo} />


                    <a className={`action_bar__link ml-2 pt-1 px-2 cursor_pointer ${showDuplicate && `filterAppActive`}`} onClick={() => {
                        if (result?.loading) {
                            return
                        }
                        setShowDuplicate(!showDuplicate)
                        props.duplicate?.(!showDuplicate)
                        if (null !== searchField.current) {
                            searchField.current.value = ""
                        }
                        setFilters({
                            cities: [],
                            scores: [],
                            stages: [],
                            owners: [],
                            zip_codes: [],
                            deal_stages: [],
                            companies: [],
                            sources: [],
                            tags: [],
                            last_contact_date: "",
                            birthday_start: "",
                            birthday_end: "",
                            home_anniversary_start: "",
                            home_anniversary_end: "",
                            lead_type: ""

                        })
                    }}><IonIcon className="w-20px h-20px" icon={peopleOutline} title="show duplicate"></IonIcon>
                    </a>
                </div>
                {(result?.loading && contacts.length === 0) &&
                    <LoadingState />
                }
                <ContactImportStatus className="w-100" />

                <EmptyState
                    show={result.response?.total === 0 && contacts.length === 0 ? 'flex' : 'none'}
                    clear={clear}
                    duplicate={showDuplicate}
                    clearFilter={clearFilters}
                    noContacts={filtersCountNo === 0 && searchField.current?.value === "" && result.response?.total === 0 ? true : false}
                    search={applyFilter}
                />
                {result?.response || contacts.length > 0 ?
                    <IonList className="contact_list">
                        {contacts.map((contact: any) =>
                            <IonItemSliding key={contact.id}>
                                <IonItem>
                                    <IonLabel>
                                        <div className="card--contact">
                                            <div className="card--contact__image">
                                                <Avatar
                                                    id={contact.id}
                                                    firstName={contact.first_name}
                                                    photo={contact.photo_url}
                                                    lastName={contact.last_name}
                                                    middleName={contact.middle_name}
                                                    referrer={'/contacts'}
                                                    responsive={true}
                                                    link={`/contacts/${contact.id}/overview`}
                                                />
                                            </div>
                                            <div className="card--contact__cont">
                                                <div className="card--contact__cont__header flex-column">
                                                    <a onClick={() => history.push(`/contacts/${contact.id}/overview`)}>
                                                        <div className="card--contact__cont__header_name font_size--small"><ContactTitle contact={contact} /></div>
                                                        {contact?.primary_email && <div className=" font_size--small color_auxiliary text-truncate pb-1 font_weight--normal">{contact?.primary_email}</div>}
                                                    </a>
                                                </div>
                                                <a onClick={() => history.push(`/contacts/${contact.id}/overview`)}>
                                                    <div className="card--contact__cont__labels">
                                                        {contact.score &&
                                                            <div className={`scor_lone scor_lone__${contact?.score.toLocaleLowerCase()} mr-2 mb-0`}>{contact.score}</div>
                                                        }
                                                        {contact.pipeline_status &&
                                                            <div className={`stage ${getStageOption(contact.pipeline_status)?.class} mb-0 mr-2 d-inline-flex align-items-center`}>{getStageOption(contact.pipeline_status)?.long_value}</div>
                                                        }
                                                        {contact.last_contact_date_text &&
                                                            <div className="warning warning__small">
                                                                <div className="warning__small__text">{contact.last_contact_date_text}</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                    </IonLabel>
                                </IonItem>
                                {contact.primary_phone &&
                                    <IonItemOptions>
                                        <IonItemOption onClick={() => {
                                            var anchor = document.createElement("a")
                                            anchor.href = 'tel:' + transformPhoneNumber(contact.primary_phone_country_code, contact.primary_phone, contact.primary_phone_extension)
                                            document.body.appendChild(anchor)
                                            anchor.click()
                                        }} color="blue"><div className="pl-4 pr-4 font_weight--bold">Call</div></IonItemOption>
                                    </IonItemOptions>
                                }
                            </IonItemSliding>
                        )}
                        {result?.response && result.response.total_pages > Number(page) ?
                            <IonInfiniteScroll threshold='10%'
                                onIonInfinite={(ev) => {
                                    loadMore();
                                    setTimeout(() => ev.target.complete(), 500);
                                }}
                            >
                                <IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                            : <></>
                        }


                    </IonList>
                    : ''
                }


                <SortModal show={showSort} toggleShow={SortToggle} doSort={doSort} onReset={() => props.changeFilter?.({ sort_by: '', sort_dir: '' }, 1)} duplicate={showDuplicate} />

                <NewTagPopUp show={newTagPop} toggleShow={() => setNewTagPop(newTagPop ? false : true)} />
                <ManageTagsPopUp show={showManageTag} toggleShow={() => setShowManageTag(showManageTag ? false : true)} />
                <ManageState
                    isManage={isManage}
                    contacts={contacts}
                    result={result}
                    page={page}
                    isDownloading={isDownloading}
                    reload={() => {
                        reload?.();
                    }}
                    close={() => setManageState(false)}
                    getStageOption={getStageOption}
                    downloadContact={downloadContact}
                    openDeleteWarning={() => setShowPopUp(true)}
                    loadMore={loadMore}
                />

            </div >
        </div >

    );
}

const SortModal = (props: ISortModal): JSX.Element => {
    const { show } = props
    const [sort, setSort] = useState('last_contact_date')

    const doSort = () => {
        if (props.doSort) {
            props.doSort({ sort_by: sort, page: 1 })
        }
        toggleShow()
    }
    const toggleShow = () => {
        if (props.toggleShow) {
            props.toggleShow()
        }
    }

    useEffect(() => {
        if (props.duplicate) {
            setSort('first_name')
        } else {
            setSort('last_contact_date')
        }
    }, [props.duplicate])

    return (
        <IonModal isOpen={show} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons className="w-15pr" slot="start">
                        <IonButton color={"blue"} onClick={() => { setSort(''); toggleShow(); props.onReset?.() }}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle></IonTitle>
                    <IonButtons className="w-15pr" slot="end">
                        <IonButton color={"blue"} onClick={() => doSort()} strong>Apply</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding-none">
                <div className="bg-white ion-padding">
                    <h2 className="form_sec_title">Sort by</h2>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__grid" type="radio" name="options" defaultValue={sort ? sort : `last_contact_date`}>
                        <ToggleButton className={`btn--medium ${sort === 'last_contact_date' ? `active` : ``}`} id="LastContacted" value={"last_contact_date"} onClick={() => setSort("last_contact_date")} >
                            Last Updated
                        </ToggleButton>
                       
                        <ToggleButton className={`btn--medium ${sort === 'first_name' ? `active` : ``}`} id="FirstName" value={"first_name"} onClick={() => setSort("first_name")}>
                            First Name
                        </ToggleButton>
                        <ToggleButton className={`btn--medium ${sort === 'last_name' ? `active` : ``}`} id="LastName" value={"last_name"} onClick={() => setSort("last_name")}>
                            Last Name
                        </ToggleButton>
                        <ToggleButton className={`btn--medium ${sort === 'score' ? `active` : ``}`} id="Score" value={"score"} onClick={() => setSort("score")}>
                            Score
                        </ToggleButton>
                        <ToggleButton className={`btn--medium ${sort === 'pipeline_status' ? `active` : ``}`} id="Stage" value={"pipeline_status"} onClick={() => setSort("pipeline_status")}>
                            Stage
                        </ToggleButton>
                        <ToggleButton className={`btn--medium ${sort === 'source' ? `active` : ``}`} id="source" value={"source"} onClick={() => setSort("source")}>
                            Source
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </IonContent>
        </IonModal>

    );
}


const LoadingState = () => {
    return (
        <div>
            <IonList>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-24px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text h-22px w-52px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text h-22px w-22px"></div></div>
                                <div className="col-auto"><div className="loading_text h-22px w-52px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>
                            <div className="row no-gutters">
                                <div className="col-auto mr-2"><div className="loading_text w-22px h-22px"></div></div>
                                <div className="col-auto"><div className="loading_text w-52px h-22px"></div></div>
                            </div>
                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
}


const EmptyState = (props: IEmptyGrid): JSX.Element => {
    const { noContacts, show, duplicate } = props
    const clear = () => {
        if (props.clearFilter) {
            props.clearFilter()
        }
        if (props.clear) {
            props.clear()
        }
        if (props.search) {
            props.search({
                cities: [],
                scores: [],
                stages: [],
                zip_codes: [],
                deal_stages: [],
                companies: [],
                sources: [],
                tags: [],
                last_contact_date: "",
                name: "",
                keyword: "",
                birthday_start: "",
                birthday_end: "",
                home_anniversary_start: "",
                home_anniversary_end: "",
                lead_type: ""

            })
        }

    }
    return (
        <div className={`d-${show} pt-5 mt-5`}>
            <div className="text-center color_cement_dark h-100 pt-5 mt-5   w-100">
                {!noContacts &&
                    <div className="align-self-center ml-auto mr-auto">
                        <div className="font_weight--semi_bold font_size--large_extra_extra pb-3">No contact matches</div>
                        <div className="pb-4 font_size--large_extra">Try changing or removing some<br />of your search filters.</div>
                        <button role="button" className="btn btn--ordinary btn--medium w-100" onClick={() => clear()}>Clear Filters</button>
                    </div>
                }

                {noContacts &&
                    <div className="align-self-center ml-auto mr-auto">
                        <div className="font_weight--semi_bold font_size--large_extra_extra pb-3">     {duplicate ? "You don’t have any duplicated contacts" : "You don't have any contacts"}</div>
                        {!duplicate &&
                            <IonRouterLink routerLink="/contacts/create">
                                <button role="button" className="btn btn--prominent__v2 btn--medium btn--inlineicon ml-2"><img className="align-self-center w-12px mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" /><span>New Contact</span></button>
                            </IonRouterLink>
                        }
                    </div>
                }
            </div>
        </div >
    );
}


const ManageState = (props: IManageState): JSX.Element => {
    const { isManage, result, page, isDownloading,
        close, reload, getStageOption, loadMore } = props
    const [showPopUp, setShowPopUp] = useState(false)
    const [contacts, setContacts] = useState<any>(props.contacts)
    const [selected, setSelected] = useState<any>([])
    const maxSelection = 50
    const selectToggle = (id: number, pushF: number) => {
        if (pushF === 1) {
            let sel: any = contacts.filter((contact: any) =>
                contact.id === id
            )
            setSelected(sel)
        } else if (isSelected(Number(id))) {
            let sel = selected.filter((contact: any) =>
                contact.id !== id
            )
            setSelected(sel)
        } else {
            if (selected.length === maxSelection) {
                displayMessage('You can select up to 50 contacts at a time.')
                return
            }
            let sel: any = contacts.filter((contact: any) =>
                contact.id === id
            )
            setSelected([
                ...selected,
                ...sel
            ])
        }
    }

    const selectAll = () => {
        let sel: any = []
        contacts.map((contact: any, index: number) => {
            if (index < maxSelection) {
                sel.push(contact)

            }
        })
        setSelected(sel)
    }

    const isSelectAll = (): boolean => {
        if (contacts.length <= maxSelection) {
            return contacts.length === selected.length
        } else {
            return selected.length === maxSelection
        }
    }

    const unSelectAll = () => {
        setSelected([])
    }

    const isSelected = (id: number): boolean => {
        let sel = selected.filter((contact: any) =>
            contact.id === id
        )
        return sel && sel.length > 0 ? true : false;
    }


    useEffect(() => {
        if (props.contacts.length <= 20) {
            setSelected([])
        }
        setContacts(props.contacts)
    }, [props.contacts])



    return (
        <IonModal isOpen={isManage} className="manage_contacts" backdropDismiss={false}>
            {isManage &&
                <div className="custom_header">
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton color={"blue"} onClick={() => close?.()}>Cancel</IonButton>
                            </IonButtons>
                            <IonTitle>   {selected && selected.length > 0 ? selected.length + ' Selected' : ''}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton color={"blue"} onClick={() => isSelectAll() ? unSelectAll() : selectAll()}> {isSelectAll() ? `Unselect All` : `Select All`}</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                </div>
            }
            <ContactsConfirmDelete
                show={showPopUp}
                toggleShow={() => setShowPopUp(false)}
                contacts={selected ? selected : []}
                onDelete={() => {
                    reload?.();
                    setSelected([])
                }}

            />
            <IonContent className="ion-padding">
                {contacts.map((contact: any, index: number) =>
                    <IonList key={index}>
                        <IonItemSliding>
                            <IonItem className=" remove--padding-start">
                                {isManage &&
                                    <input type="checkbox" className="select-contacts-all" onChange={() => selectToggle(contact.id, 0)} checked={isSelected?.(contact.id)} readOnly slot="start" />
                                }
                                <IonLabel onClick={() => selectToggle(contact.id, 0)}>
                                    <div className="card--contact">
                                        <div className="card--contact__image">
                                            <Avatar
                                                id={contact.id}
                                                firstName={contact.first_name}
                                                photo={contact.photo_url}
                                                lastName={contact.last_name}
                                                responsive={true}

                                            />

                                        </div>


                                        <div className="card--contact__cont">
                                            <div className="card--contact__cont__header flex-column">
                                                <div className="card--contact__cont__header_name font_size--small"><ContactTitle contact={contact} /></div>
                                                {contact?.primary_email && <div className=" font_size--small color_auxiliary text-truncate pb-1 font_weight--normal">{contact?.primary_email}</div>}
                                            </div>
                                            <div className="card--contact__cont__labels">
                                                {contact.score &&
                                                    <div className={`scor_lone scor_lone__${contact?.score.toLocaleLowerCase()} mr-2 mb-0`}>{contact.score}</div>
                                                }
                                                {contact.pipeline_status &&
                                                    <div className={`stage ${getStageOption?.(contact.pipeline_status)?.class} mb-0 mr-2 d-inline-flex align-items-center`}>{getStageOption?.(contact.pipeline_status)?.long_value}</div>
                                                }
                                                {contact.last_contact_date_text &&
                                                    <div className="warning warning__small">
                                                        <div className="warning__small__text">{contact.last_contact_date_text}</div>
                                                    </div>
                                                }
                                            </div>

                                        </div>


                                    </div>
                                </IonLabel>
                            </IonItem>

                        </IonItemSliding>

                    </IonList>

                )}
                {result?.response && result.response.total_pages > Number(page) ?
                    <IonInfiniteScroll

                        onIonInfinite={(ev) => {
                            loadMore?.();
                            setTimeout(() => ev.target.complete(), 500);
                        }}
                    >
                        <IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                    : <></>
                }
                {isManage &&
                    <div className="custom_footer custom_footer_app_content_management border-bottom bottom-0 zindex-11000 right-0px left-0px w-100">
                        <div className="row">
                            {!isProduction &&
                                <div className="col-4 text-left"><ContactsEditTagsPopUp selectedContacts={selected} selectToggle={isSelected} reload={props.reload} /></div>
                            }
                            <div className="col-4 text-center"><ShapelessButton loading={isDownloading} disabled={(selected.length > 0 ? false : true) || isDownloading} onClick={() => props.downloadContact?.(selected)}><span className="font_size--large">Export</span></ShapelessButton></div>
                            <div className="col-4 text-right"><ShapelessButton disabled={selected.length > 0 ? false : true} onClick={() => setShowPopUp(true)}><span className="font_size--large">Delete</span></ShapelessButton></div>

                        </div>
                    </div>
                }
            </IonContent>
        </IonModal>
    );
}





