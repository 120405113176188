import { ReactNode, RefObject } from "react";
import { isLocal, isTest } from "scripts/application/settings";
import { GetBasicInfoApi } from "services/user/getBasicInfo";
import { JSXAttributes } from "types/react";
import { IonContent as NativeIonContent } from '@ionic/react';

export interface IDefaultTemplateProps
{
    title:string | JSX.Element;
    className?:string;
    CustomMobileHeader?(): JSX.Element;
    showIcon?:boolean;
    selectedMenu?:MenuItemsKey;
    children:ReactNode;
    fluid?:boolean;
    isDragging?:boolean;
    total_unread?:number;
    noContent?:boolean;
    contentRef?:RefObject<HTMLIonContentElement>;
    bottomFixElement?:JSX.Element|undefined;
    onSwipeRefresh?:() => void    
}

export type IDefaultTemplateIonContentProps = Omit<JSXAttributes.Exotic<typeof NativeIonContent>, 'children' | 'fullscreen' | 'ref'>;

export interface IDefaultTemplateFinalProps extends IDefaultTemplateProps
{
    ionContentProps?:IDefaultTemplateIonContentProps;
}


export interface IDefaultTemplateWrapper
{
    children:JSX.Element | JSX.Element[];
    noContent?:boolean;
    className:string
}

export interface IDefaultTemplateMoreOptionsProps
{
    user?:GetBasicInfoApi.Response;
}

export const menuItems = {  
	Home: { text: 'Home', url: '/home', icon:'https://content.harstatic.com/media/icons/crm/menu/icon-home.svg' },
	Contacts: { text: 'Contacts', url: '/contacts', icon:'https://content.harstatic.com/media/icons/crm/icon_contacts.svg' },
	Deals: { text: 'Deals', url: '/deals', icon:'https://content.harstatic.com/media/icons/crm/menu/icon-deals.svg' },
	Emails: { text: 'Emails', url: '/emails/inbox', icon:'https://content.harstatic.com/media/icons/crm/menu/icon-emails.svg' },
	Calendar: { text: 'Calendar', url: '/calendar', icon:'https://content.harstatic.com/media/icons/crm/icon_calendar.svg' },
	Reports: { text: 'Reports', url: '/reports', icon:"https://content.harstatic.com/media/icons/crm/icon_reports.svg" },
	Services: { text: 'Services', url: '/services', icon:'https://content.harstatic.com/media/icons/crm/icon_services.svg' },
	Activities: { text: 'Activities', url: '/activities', icon:'https://content.harstatic.com/media/icons/crm/icon_activities.svg' },
    Support: { text: 'Support', url: '/support', icon:'https://content.harstatic.com/media/icons/crm/icon_supprts.svg' },
};

export const mobileMenuItems = {
    Home: menuItems.Home,
    Contacts: menuItems.Contacts,
    Deals: menuItems.Deals,
    Emails: menuItems.Emails
};
export const mobileMenuMoreItems = {
    Calendar: menuItems.Calendar,
    Reports: menuItems.Reports,
    Services: menuItems.Services,
};

export type MenuItemsKey = keyof typeof menuItems;

export type MobileMenuItemsKey = keyof typeof mobileMenuItems;
export type MobileMenuMoreItemsKey = keyof typeof mobileMenuMoreItems;

export const showVersion = true;
export const environment = () =>
{
    if(isTest) { return ' (Test)'; }
    else if(isLocal) { return ' (Local)'; }
    return '';
};