import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdatePipelineApi
{
    export const url = "/deals/update_pipeline";
    export const method = "POST";
    export const defaultError = "failed update Pipeline";
    export const operations = apiOperations(UpdatePipelineApi);
    export const scope = new class UpdatePipelineApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        status:string,
        message:string,
    }
}

export const fetchUpdatePipelineService = forwardOperation(UpdatePipelineApi.operations, 'fetch');
export const useFetchUpdatePipelineService = forwardOperation(UpdatePipelineApi.operations, 'useFetch');
export const useUpdatePipelineService = forwardOperation(UpdatePipelineApi.operations, 'useService');