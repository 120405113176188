import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace UpdateBasicInformationApi
{
    export const url = "/contacts/:contact_id/basic_info";
    export const method = "PUT";
    export const defaultError = "Failed to update contact information.";
    export const operations = apiOperations(UpdateBasicInformationApi);
    export const scope = new class UpdateBasicInformationApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {   
        contact_id:string;
        photo_url?:string;
        first_name:string;
        middle_name?:string;
        last_name:string;
        gender?:string;
        birthday?:string;
        known_since?:string;
        income?:string;
        job_title?:string;
        company?:string;
        anniversary_date?:string;
        home_anniversary_date?:string;
    }

    export interface Response
    {
        
    }
}

export const fetchUpdateBasicInformationService = forwardOperation(UpdateBasicInformationApi.operations, 'fetch');
export const useFetchUpdateBasicInformationService = forwardOperation(UpdateBasicInformationApi.operations, 'useFetch');
export const useUpdateBasicInformationService = forwardOperation(UpdateBasicInformationApi.operations, 'useService');