import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IMyTransactionsScreenProps } from './MyTransactionsScreen.def';
import './MyTransactionsScreen.scss';
import { useLocation, useParams } from 'react-router';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';

export const MyTransactionsScreen = (_props: IMyTransactionsScreenProps): JSX.Element =>
{
    const { path = 'active' } = useParams<{ path:string }>();
    const location = useLocation();

    return (
        <DefaultTemplate title='My Transactions' fluid>

            <ExternalEmbedContent
                url='/mopx_mls/listings'
                initialPath={path}
                queryString={location.search}
                source={eExternalEmbedContentSource.Website}
                iframeTitle='My Transactions'
                enabledInnerRoutes
            />

        </DefaultTemplate>
    );
}
