//import { useEffect, useState } from "react";
import { isPlainObject } from "lodash-es";
import { EffectCallback, useEffect, useState } from "react";
import { ServiceError } from "scripts/exceptions";
import { IServiceFetchResponse, serviceFetch } from "scripts/http/serviceFetch";
import { IApi } from "types/apis";

type ServiceEffectCallback<T> = (response:T) => ReturnType<EffectCallback>;


export type IFetchHookData<TResponse, TResponseError = unknown> = Readonly<
    { response:undefined; error:undefined; loading:true; } |
    { response:undefined; error:ServiceError<TResponseError>; loading:false; } |
    { response:TResponse; error:undefined; loading:false; }
>;
//{ response:TResponse | undefined; error:Error | undefined; loading:boolean; }


export type IHookData<TResponse, TResponseError = unknown> = IFetchHookData<TResponse, TResponseError> |
    Readonly<{ response:undefined; error:undefined; loading:false; }>;


export interface IHookActions<TRequest, TPartialRequest extends Partial<TRequest>>
{
    fetch:IHookFetch<TRequest, TPartialRequest>;
    abort(): void;
}


export type IServiceHookResponse<TRequest, TResponse, TResponseError = undefined, TPartialRequest extends Partial<TRequest> = TRequest> =
[
    IHookData<TResponse, TResponseError>,
    IHookActions<TRequest, TPartialRequest>
];


export type IHookFetch<TRequest, TPartialRequest extends Partial<TRequest>> =
    TPartialRequest extends TRequest?
        (parameters?:Complement<TRequest, TPartialRequest>) => Promise<void>:
        (parameters:Complement<TRequest, TPartialRequest>) => Promise<void>;


/*type Complement<T1, T2 extends Partial<T1>> =
{
    [K in keyof T2 as K extends keyof T1? T1[K] extends T2[K]? never: K: K]: T2[K]
}*/

type Complement<T1, T2 extends Partial<T1>> = Omit<T1, keyof T2>;


/*type A = {
    email:string;
    phone:string;
}

type B = {
    email:string;
    name:string;
}

type C = Omit<A, keyof B>;*/


export const useService = <TRequest, TServerResponse, TResponse, TResponseError = undefined, TPartialRequest extends Partial<TRequest> = {}>(api:IApi<any,TRequest,TServerResponse,TResponse, any, TResponseError>, parameters?:TPartialRequest)
    :IServiceHookResponse<TRequest, TResponse, TResponseError, TPartialRequest> =>
{
    const dataHook = useState<IHookData<TResponse, TResponseError>>({
        response:undefined,
        error:undefined,
        loading:false,
    });

    let currentRequest:IServiceFetchResponse<TResponse> | undefined;

    const [actions] = useState({
        fetch: fetch as any,
        abort() {
            currentRequest?.abort();
            currentRequest = undefined;
        }
    });
    
    useEffect(() => () => currentRequest?.abort(), []);

    const [data] = dataHook;
    return [data, actions];

    async function fetch(newParameters?:Partial<TRequest>)
    {
        if(!newParameters) { newParameters = parameters; }
        else if(parameters && newParameters && isPlainObject(parameters) && isPlainObject(newParameters)) {
            newParameters = { ...parameters, ...newParameters };
        }

        const [data, setData] = dataHook;
        setData({ ...data, response:undefined, error:undefined, loading: true });
        const thisCurrentRequest = currentRequest = serviceFetch(api, newParameters as TRequest);
        //console.log(currentRequest);

        try
        {
            const response = await currentRequest;
            if(!currentRequest) { return; }
            setData({ ...data, response, loading:false, error:undefined });
        }
        catch(error:any)
        {
            console.error(error);
            if(error && error instanceof DOMException && error.name === 'AbortError') { return; }
            setData({ ...data, response:undefined, loading:false, error })
        }
        finally
        {
            if(currentRequest === thisCurrentRequest) { currentRequest = undefined; }
        }
    };
};


export function useServiceDidSucceeded<T>(service:IHookData<T, unknown> | undefined, effect:ServiceEffectCallback<NonNullable<T>>): void
export function useServiceDidSucceeded<T>(service:Exclude<T, ServiceError> | undefined, effect:ServiceEffectCallback<NonNullable<T>>): void
export function useServiceDidSucceeded<T>(service:IHookData<T, unknown> | undefined, effect:ServiceEffectCallback<NonNullable<T>>)
{
    const response = isServiceHook<T>(service)? service?.response: service as T;
    useEffect(() => {
        if(response) { return effect(response); }
    }, [response])
}

export function useServiceDidFailed<T, TError>(service:IHookData<T, TError> | ServiceError | undefined, effect:ServiceEffectCallback<ServiceError<TError | undefined>>)
{
    const error = isServiceHook<T,TError>(service)? service?.error: service as ServiceError;
    useEffect(() => {
        if(error) { return effect(error); }
    }, [error])
}

function isServiceHook<T,TError = undefined>(input:unknown | undefined): input is IHookData<T,TError>
{
    if(!input || !isPlainObject(input)) { return false; }
    const obj = input as object;
    return 'response' in obj;
}