import { IScrollIntoViewProps } from './ScrollIntoView.def';
import './ScrollIntoView.scss';
import { createElement, JSX, PropsWithChildren } from "react";
import { useTimeout } from "scripts/hooks/timers";

export function ScrollIntoView<T extends keyof JSX.IntrinsicElements = 'div'>(props:PropsWithChildren<IScrollIntoViewProps<T>>)
{
    const { as = 'div', selector, behavior = 'smooth', block, inline, onClick:onUserClick, ...nodeProps } = props;
    const scrollOptions = { behavior, block, inline } as ScrollIntoViewOptions;
    
    const scrollTimer = useTimeout((attempts:number) =>
    {
        const target = document.querySelector(selector);
        if(target) { target.scrollIntoView(scrollOptions); }
        else if(attempts) { scrollTimer.schedule(attempts -1); }

    }, 150);

    const onClick = (e:React.MouseEvent<T>) =>
    {
        onUserClick?.(e);
        scrollTimer.schedule(10);
    }

    return createElement(as, { ...nodeProps, onClick }, props.children);
}