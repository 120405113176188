import { IToFieldProps } from './ToField.def';
import { useComponent } from "scripts/context";
import { ToFieldApp } from './ToField.app';
import { ToFieldDesktop } from './ToField.desktop';

export const ToField = (props: IToFieldProps): JSX.Element =>
{
    const ToFieldPlatform = useComponent(ToFieldDesktop, ToFieldApp);
    return (
        <ToFieldPlatform {...props} />
    );
}
