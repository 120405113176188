import { useResponsiveChoose } from "scripts/hooks/general";
import { CalendarLandingScreenApp } from "./CalendarLandingScreen.app";
import { ICalendarLandingScreenProps } from "./CalendarLandingScreen.def";
import { CalendarLandingScreenDesktop } from "./CalendarLandingScreen.desktop";
import { useParams } from "react-router";

export const CalendarLandingScreen = (props: ICalendarLandingScreenProps): JSX.Element => {
    const { taskid } = useParams<{ taskid: string }>();
    const CalendarLandingScreenPlatform = useResponsiveChoose(CalendarLandingScreenDesktop, CalendarLandingScreenApp);
    return <CalendarLandingScreenPlatform
        taskId={taskid}
        {...props} />;
}
