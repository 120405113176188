import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace ImportContactApi {
    export const url = "/provider/contacts/bulk_import";
    export const method = "POST";
    export const defaultError = "Something went wrong while importing contact please try again later.";
    export const operations = apiOperations(ImportContactApi);
    export const scope = new class ImportContactApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string | string[]> {
            return {
                prevent_update: input.prevent_update,
                ids: input.ids
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        prevent_update: string,
        ids: string[]
    }

    export interface Response {
        total: string,
        total_added: string,
        total_updated: string,
        total_failed: string,
        import_id: string,
        list: IImportContactResult[]
    }

    export interface IImportContactResult {
        order_number: string,
        integration_id: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        primary_email: string,
        primary_phone: string,
        status: string,
        contact_id: string,
        errors: IImportContactFailed[]
    }

    export interface IImportContactFailed {
        reason: string
        message: string
    }
}

export const fetchImportContactService = forwardOperation(ImportContactApi.operations, 'fetch');
export const useFetchImportContactService = forwardOperation(ImportContactApi.operations, 'useFetch');
export const useImportContactService = forwardOperation(ImportContactApi.operations, 'useService');