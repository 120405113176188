import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeletePhotoApi
{
    export const url = "/contacts/:contact_id/photo";
    export const method = "DELETE";
    export const defaultError = "Failed to delete contact photo.";
    export const operations = apiOperations(DeletePhotoApi);
    export const scope = new class DeletePhotoApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string
    }

    export interface Response
    {
        
    }
}

export const fetchDeletePhotoService = forwardOperation(DeletePhotoApi.operations, 'fetch');
export const useFetchDeletePhotoService = forwardOperation(DeletePhotoApi.operations, 'useFetch');
export const useDeletePhotoService = forwardOperation(DeletePhotoApi.operations, 'useService');