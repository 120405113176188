import { useRef, useState } from 'react';
import { IToFieldProps } from './ToField.def';
import './ToField.scss';
import { SearchContactsApi, useSearchContactsService } from 'services/contacts/searchContacts';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { IEmailRecipient } from 'services/emails/searchEmails';
import { IonInput } from '@ionic/react';
import { Avatar } from 'views/contacts/Avatar';

export const ToFieldDesktop = (props: IToFieldProps): JSX.Element => {
    const [contacts, setContacts] = useState<SearchContactsApi.IContactSearchItem[]>([])
    const [contactSearch, ContactSearchAction] = useSearchContactsService()
    const [timer, setTimer] = useState<number | string>()
    const searchField = useRef<HTMLIonInputElement | null>(null)

    useServiceDidSucceeded(contactSearch, (response) => {
        const newContacts: any = [];
        if (response) {
            response?.list.map((contact: any) => {
                newContacts.push(contact);
            })
            setContacts(newContacts);
        }
    })
    const search = ((value: string) => {
        if (value.length === 0) {
            return
        }

        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            ContactSearchAction.fetch({ keyword: value })
        }, 500))
    })

    function onSelect(contact: IEmailRecipient) {
        setContacts([])
        props.selected(contact)
        if (null !== searchField.current) {
            searchField.current.value = ""
        }
    }



    const LoadingState = (): JSX.Element => {
        const LoadingField = (): JSX.Element => {
            return (
                <div className="card text-left mb-3 border-0 card__mini">
                    <div className="agent_signature agent_signature__medium agent_signature__loading">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-50"></div>
                            <div className="agent_signature__loading__textline max-w-90"></div>
                        </div>
                    </div>
                </div>
            );
        }
        const rows: number[] = [];
        for (let i = 0; i < 3; i++) {
            rows[i] = i
        }
        return (
            <div className=' position-absolute zindex-200 bg_color_snow_white p-2 min-w-350'>
                {
                    rows.map((index: number) =>
                        <LoadingField key={index} />
                    )
                }
            </div>
        );

    }
    const doBreak = (str: string) => {
        let token = str.split(/\s+/, 1)
        if (token[0]) {
            doSave(token[0])
            if (searchField.current !== null) {
                searchField.current.value = ""
            }

        }
    }

    const doSave = (token: string) => {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (token && regex.test(token.trim())) {
            props.selected({
                id: "",
                first_name: "",
                last_name: "",
                email: token.trim(),
                middle_name: "",
                photo_url: "",
                score: "",
                first_last_name: ""

            })
        }
    }

    return (
        <>
            <div className="inputauto__title">To:</div>
            <div className="inputauto__item w-82pr">
                <div className='row no-gutters'>
                    <Selection {...props} />
                    {(props.contacts && props.contacts?.length <= 10 || typeof props.contacts === 'undefined') &&
                        <div className='col'>
                            <div >
                                <IonInput ref={searchField} className="form-control border-0" placeholder='Search contacts'
                                    onKeyUp={(e: any) => {
                                        if (e.keyCode === 32 || e.keyCode === 188) {
                                            doBreak(e.target.value + " ")
                                        }
                                    }}
                                    onBlur={(e: any) => doBreak(e.target.value + ' ')}
                                    onIonInput={(e: any) => search(e.target.value)} />
                                {
                                    contactSearch.loading &&
                                    <LoadingState />
                                }
                                {
                                    contactSearch.response &&
                                    <div className={`border_radius--pudgy  depth--standed_out position-absolute  min-w-350px max-h-240px h-auto zindex-200 overflow-hidden ${searchField.current?.value ? `d-block` : `d-none`}`}>
                                        {contacts.map((contact, index: number) =>
                                            <div key={index} className={`agent_signature agent_signature__medium border-bottom p-3 bg_color_snow_white`} onClick={() => onSelect({
                                                id: contact.id.toString(),
                                                first_name: contact.first_name,
                                                middle_name: contact.middle_name,
                                                first_last_name: contact.first_last_name,
                                                photo_url: contact.photo_url,
                                                last_name: contact.last_name,
                                                score: contact.score,
                                                email: contact.primary_email,
                                            })}>
                                                <Avatar
                                                    firstName={contact.first_name}
                                                    lastName={contact.last_name}
                                                    photo={contact.photo_url}
                                                    className={'cursor--hand'}
                                                    id={contact.id.toString()}
                                                />

                                                <div className="agent_signature__text text-truncate cursor--hand">
                                                    <div className="row no-gutters">
                                                        <div className="col  text-truncate">
                                                            <div className="agent_signature__text__title text-truncate">{contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.primary_email}</div>
                                                            <div className="agent_signature__text__desc">{contact.primary_email}</div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                    </div>
                                }


                            </div >
                        </div>
                    }
                </div>
            </div>
            <div className="inputauto__input h-40px"></div>

        </>

    );

}

const Selection = (props: IToFieldProps) => {
    const { contacts } = props
    function onRemove(contact: IEmailRecipient) {
        props.removed(contact)

    }
    return (
        <div className='col-auto d-flex'>
            {contacts?.map((contact, index: number) =>
                <div className="emailpill" key={index}>
                    <div className="agent_signature agent_signature__mini2">
                        {contact.id &&
                            <Avatar
                                firstName={contact.first_name}
                                lastName={contact.last_name}
                                photo={contact.photo_url}
                                id={contact.id.toString()}

                            />
                        }
                        <div className="agent_signature__text">{contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.email}</div>
                    </div>
                    <a className="emailpill--clossbtn" onClick={() => onRemove(contact)}><img src="https://content.harstatic.com/media/icons/close_black.svg" /></a>
                </div>
            )}
        </div>
    );
}