
import { IImportCsvMappedScreenProps, IImportCsvRef } from './ImportCsvMappedScreen.def';
import './ImportCsvMappedScreen.scss';
import { ImportSetup } from './components/ImportSetup';
import { ProgressBar } from './components/ProgressBar';
import { FieldMapping } from './components/FieldMapping';
import { DropdownMapping } from './components/DropdownMapping';
import { ValidationProcess } from './components/ValidationProcess';
import { ImportingCsv } from './components/ImportingCsv';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { useRef } from 'react';
import { useHistory } from 'react-router';


export const ImportCsvMappedScreenApp = (props: IImportCsvMappedScreenProps): JSX.Element => {
    const { step_id, import_id, mapping, selectedMapping, importSetup, field_mappings, setSelectedMapping, setFieldMappings, setImportSetup, setImportId, onNext, setStepId } = props
    const ref = useRef<IImportCsvRef>(null)
    const history = useHistory()
    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    const onBack = () => {
        if (step_id === 4) {
            setStepId?.((typeof selectedMapping === 'undefined' || isEmpty(selectedMapping)) ? 2 : 3)
        } else {
            setStepId?.(step_id ? step_id - 1 : 1)
        }
    }

    return (
        <IonModal className={`bg-white`} isOpen={true} >
            <IonHeader className="ion-padding-md ion-no-border border-bottom">
                <IonToolbar>
                    <IonButtons slot="start">
                        {step_id && step_id > 1 && step_id < 5 ?
                            <IonButton onClick={onBack}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />  Back</IonButton>
                            :
                            <IonButton onClick={() => history.push('/contacts')} >Close</IonButton>
                        }
                    </IonButtons>
                    <IonTitle className="modal-title">Import Contacts</IonTitle>
                    {step_id && step_id < 5 &&
                        <IonButtons slot="end">
                            <IonButton strong={true} onClick={() => {
                                ref.current?.submit()

                            }}>
                                Next
                            </IonButton>
                        </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent className="bg-white">
                <div className="ion-padding bg-white" style={{ minHeight: '100%' }}>
                    <ProgressBar active={step_id} />
                    {step_id === 1 && <ImportSetup ref={ref} setImportId={setImportId} onNext={onNext} importSetup={importSetup} setImportSetup={setImportSetup} />}
                    {step_id === 2 && <FieldMapping ref={ref} import_id={import_id} onNext={onNext} field_mappings={field_mappings} setFieldMappings={setFieldMappings} setSelectedMapping={setSelectedMapping} />}
                    {step_id === 3 && <DropdownMapping ref={ref} import_id={import_id} onNext={onNext} mapping={mapping} selectedMapping={selectedMapping} setSelectedMapping={setSelectedMapping} />}
                    {step_id === 4 && <ValidationProcess ref={ref} import_id={import_id} onNext={onNext} selectedMapping={selectedMapping} />}
                    {step_id === 5 && <ImportingCsv import_id={import_id} />}
                </div>
            </IonContent>
        </IonModal >

    );

}

/*export const ImportCsvMappedScreenApp = (_props: IImportCsvMappedScreenProps): JSX.Element =>
{
    const [showModal, setShowModal] = useState(false);

    return (
        <DefaultTemplate title="Import CSV Mapped">
        <div>Component ImportCsvMappedScreen not implemented ap.</div>
        </DefaultTemplate>
        <div className='ion-padding'>
            <div className="text center pb-3 mb-3 border-bottom">
                    <IonButton
                        role="button"
                        id="open-modal"
                        fill="clear"
                        className="btn btn--primary btn--small"
                        expand="block"
                        onClick={() => setShowModal(true)}
                        style={{ width: '210px' }}
                    >
                        Import Popup
                    </IonButton>
                
            </div>
                <p></p>
                <IonModal className="custom--modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)} backdropDismiss={false}>
                    <IonHeader className="ion-padding-md ion-no-border border-bottom">
                    <IonToolbar>
                        <div className="custom--modal_header">
                        <IonTitle className="modal-title">Import Contacts</IonTitle>
                        <IonButton className="modal-closebtn h-24px w-25" slot="end" fill="clear" onClick={() => setShowModal(false)}>
                            <IonIcon slot="icon-only" icon={close} color="dark"></IonIcon>
                        </IonButton>
                        </div>
                    </IonToolbar>
                    </IonHeader>
                    <IonContent className="bg-white">
                    <div className="ion-padding bg-white" style={{ minHeight: '100%' }}>
                                        <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-0">
                                        <div className='card p-4 mb-4'>
                                            <div className='d-flex'>
                                                <div className='font_size--large font_weight--semi_bold pb-3'>Import Matching (Easy-way)</div>                                            
                                            </div>
                                            <div>
                                                <button role='button' type='button' className='btn btn--primary mt-2 mb-3'>Import</button>                                            
                                            </div>
                                        </div>

                                        <div className='card p-4 mb-4'>
                                            <div className='d-flex'>
                                                <div className='font_size--large font_weight--semi_bold pb-3'>Import CSV Template</div>
                                                <a className="cursor--hand ml-auto mr-0">
                                                    <div className='d-flex justify-content-center'>
                                                        <div className="pr-2 pt-2">
                                                        <IonIcon name="cloud-download-outline" color="blue" size="medium" className='font_size--large'></IonIcon>
                                                        </div>
                                                        <div className="align-self-center">Download Example file</div>
                                                    </div >
                                                </a >
                                            </div>
                                            <div>
                                                <button type='button' className='btn btn--primary mt-2 mb-3'>Import</button>
                                                <div role="checkbox" className="custom-control custom-checkbox">
                                                    <input type="checkbox" id="checkbox_button_medium_1" name="checkbox_button_medium" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="checkbox_button_medium_1">Prevent updates on existing contacts</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                            <div className='card p-4'>
                                                <div className='d-flex'>
                                                    <div className='font_size--large font_weight--semi_bold pb-3'>Import</div>
                                                </div>
                                                <div className='d-flex'>
                                                    <button role='button' type='button' className='btn btn--primary mt-2 mb-3 mr-3 pl-4 pr-4'>Import from Provider</button>
                                                    <button role='button' type='button' className='btn btn--ordinary mt-2 mb-3 pl-4 pr-4'>Import from Phone</button>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                    </IonContent>
                </IonModal>
            
            <div className='mb-4 border-bottom'>

                <div className="onb_stps_ctr border_radius border_radius--default mb-4">
                        <div className="onb_stps">

                            {/*
                            <div className="onb_stp onb_stp_done">
                                <div className="onb_stp_no"><span><img src="https://content.harstatic.com/media/icons/checkmark_filled.svg" /></span></div>
                                <div className="onb_stp_txt">Import Matching</div>
                            </div>
                            }

                            <div className="onb_stp">
                                <div className="onb_stp_no"><span><img src="https://content.harstatic.com/media/icons/checkmark_filled.svg" /></span></div>                                
                                <div className="onb_stp_txt">Import Setup</div>
                            </div>

                            <div className="onb_stp active">
                                <div className="onb_stp_no"><span>2</span></div>
                                <div className="onb_stp_txt">Fields Mapping</div>
                            </div>
                            
                            <div className="onb_stp">
                                <div className="onb_stp_no"><span>3</span></div>
                                <div className="onb_stp_txt">Dropdown Mapping</div>
                            </div>
                            
                            <div className="onb_stp">
                                <div className="onb_stp_no"><span>4</span></div>
                                <div className="onb_stp_txt">Validation Process</div>
                            </div>

                            <div className="onb_stp">
                                <div className="onb_stp_no"><span></span></div>
                                <div className="onb_stp_txt">Done</div>
                            </div>
                        </div>
                    </div>
            </div>
                
        </div>
    );
  
}*/