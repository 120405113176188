import { IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { handleSignIn } from './ExternalLoginScreen.common';
import { IExternalLoginScreenProps } from './ExternalLoginScreen.def';
import './ExternalLoginScreen.scss';
import { version } from 'scripts/application/settings';
import { LoginFooter } from 'views/auth/LoginFooter';
import { LoginFeedback } from 'views/auth/LoginFeedback';
import { Tag } from 'scripts/ui/tag';

export const ExternalLoginScreenDesktop = (_props: IExternalLoginScreenProps): JSX.Element =>
{
    //const versionText = isProduction? '': ` ${version}`;
    const versionText = ` v${version}`;

    return ( 
        <div className="row no-gutters row-eq-height">
            <Tag.Div className="col-12 col-md-6 order-md-0 order-1 min-h-100vh" cssVars={{  loginScreenBg:'url(https://content.harstatic.com/media/bgs/crm/login_bg_image_2.jpg) no-repeat center center', backgroundSize:'cover' }}></Tag.Div>
            <div className="col-12 col-md-6 order-md-1 order-0">
                <div className="text-center align-self-center d-flex flex-column h-100">
                    <div className="p-3 m-3 text-center pt-md-5 mt-md-5">
                        <img className=' w-92px h-92px' src="https://content.harstatic.com/media/common/har_logo.svg" alt="HAR Logo" />
                        <h1 tabIndex={0} className="color_carbon pt-3 mb-3">CRM<span className="font_size--medium">{versionText}</span></h1>
                        <div className="font_size--large_extra text-center ml-auto mr-auto mb-md-5 pb-md-5 pb-4 w-310px">Simple way to organize your leads, contacts, emails and more.</div>
                        
                        <div className="ml-auto mr-auto mt-md-5 mt-2 max-w-300">
                            <button onClick={handleSignIn} className="btn btn--prominent btn--large ml-auto mr-auto mb-4 w-100">Sign in with HAR account</button>
                        </div>
                    </div>
                    <LoginFooter />
                    <LoginFeedback />
                </div>
            </div>
        </div>
    );
}

export const ExternalLoginCallbackScreenDesktop = (_props: RouteComponentProps) => {
    return (
        <IonPage className='items-center'>
            <div className="login-card flex items-center justify-center">
                <div className="container flex items-center justify-center h-20 font-20">
                    <p>Signing in ....</p>
                </div>
            </div>
        </IonPage>

    );
};

export const ExternalLogoutScreenDesktop = (_props: RouteComponentProps) => {
    return (
        <IonPage className='items-center'>
            <div className="login-card flex items-center ">
                <div className="container flex items-center  h-20 font-20">
                    <p>Signing out....</p>
                </div>
            </div>
        </IonPage>
    );
};