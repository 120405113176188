import { IContactManageTemplateProps } from './ContactManageTemplate.def';

import { useResponsiveChoose } from "scripts/hooks/general";
import { ContactManageTemplateApp } from './ContactManageTemplate.app';
import { ContactManageTemplateDesktop } from './ContactManageTemplate.desktop';

export const ContactManageTemplate = (props: IContactManageTemplateProps): JSX.Element =>
{
    const ContactManageTemplatePlatform = useResponsiveChoose(ContactManageTemplateDesktop, ContactManageTemplateApp);
    return (
        <ContactManageTemplatePlatform {...props} />
    );
}
