
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IImportCsvMappedProgress, IImportCsvMappedScreenProps } from './ImportCsvMappedScreen.def';
import './ImportCsvMappedScreen.scss';
import { ProgressBar } from './components/ProgressBar';
import { ImportSetup } from './components/ImportSetup';
import { FieldMapping } from './components/FieldMapping';
import { DropdownMapping } from './components/DropdownMapping';
import { ValidationProcess } from './components/ValidationProcess';
import { ImportingCsv } from './components/ImportingCsv';
import { useHistory } from 'react-router';


export const ImportCsvMappedScreenDesktop = (props: IImportCsvMappedScreenProps): JSX.Element => {
    const { step_id, import_id, mapping, selectedMapping, importSetup, field_mappings, setSelectedMapping, setFieldMappings, setImportSetup, setImportId, onNext, setStepId } = props


    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0;
    }
    const onBack = () => {
        if (step_id === 4) {
            setStepId?.((typeof selectedMapping === 'undefined' || isEmpty(selectedMapping)) ? 2 : 3)
        } else {
            setStepId?.(step_id ? step_id - 1 : 1)
        }
    }
    return (
        <DefaultTemplate title="Import CSV Mapped">

            <div className='border-color--carbon pb-5 mb-5'>

                {/* content for first page starts from here */}
                <div className="container container__large">
                    <Header active={step_id} setStepId={setStepId} onBack={onBack} />
                    <ProgressBar active={step_id} />
                    {step_id === 1 && <ImportSetup setImportId={setImportId} onNext={onNext} importSetup={importSetup} setImportSetup={setImportSetup} />}
                    {step_id === 2 && <FieldMapping import_id={import_id} onNext={onNext} field_mappings={field_mappings} setFieldMappings={setFieldMappings} setSelectedMapping={setSelectedMapping} />}
                    {step_id === 3 && <DropdownMapping import_id={import_id} onNext={onNext} mapping={mapping} selectedMapping={selectedMapping} setSelectedMapping={setSelectedMapping} />}
                    {step_id === 4 && <ValidationProcess import_id={import_id} onNext={onNext} selectedMapping={selectedMapping} />}
                    {step_id === 5 && <ImportingCsv import_id={import_id} />}
                </div>
            </div>
        </DefaultTemplate>
    );
}
const Header = (props: IImportCsvMappedProgress) => {
    const history = useHistory()

    return (
        <div className="page_title_container">
            <div className="row">
                <div className="col align-self-center">
                    <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Import Contacts</h2>
                </div>
                <div className="col-auto align-self-center d-flex">
                    {props.active && props.active > 1 && props.active < 5 &&
                        <button role="button" className="btn btn--white btn--white__slate mr-3 d-inline-flex align-items-center" onClick={props.onBack}><img className='w-14px' src="https://content.harstatic.com/media/icons/back_black.svg" /><span>Back</span></button>
                    }
                    <button role="button" className="btn btn--white btn--white__slate" onClick={() => history.push('/contacts')} ><span>Close</span></button>
                </div>
            </div>
        </div >
    )
}