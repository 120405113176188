import { isString } from 'lodash-es';
import { ISimpleDialogProps } from './SimpleDialog.def';
import { eSimpleDialogIcon } from './SimpleDialog.def';
import { useEffect } from 'react';
import { isNumber } from 'lodash-es';
import './SimpleDialog.scss';

export const SimpleDialog = (props: ISimpleDialogProps): JSX.Element => {
    let timer: number | undefined;
    useEffect(() => {
        clearAutoClose();
        if (!props.autoClose || !props.onClose) {
            return
        }
        const timeOut = isNumber(props.autoClose) ? props.autoClose : 3000;
        timer = window.setTimeout(closeDialog, timeOut);
        return clearAutoClose;
    }, [props.autoClose, props.onClose])
    const closeDialog = () => {
        clearAutoClose();
        if (props.onClose) {
            props.onClose()
        }
    }
    const clearAutoClose = () => {
        if (timer) {
            clearTimeout(timer);
            timer = undefined;
        }
    }

    const enumToSrc = (input: eSimpleDialogIcon) => {
        switch (input) {
            case eSimpleDialogIcon.Success: return 'https://content.harstatic.com/media/icons/check_mark_green.svg'; // the icon for success
            default: throw new Error('icon not found');
        }
    }
    const loadIcon = (input: eSimpleDialogIcon | string) => {
        if (isString(input)) {
            return input
        }
        return enumToSrc(input)
    }

    return (
        <>
            <div className={`d-block ${props.blur ? 'blureoverlay' : ''}`}>
                <div className="alert alert--warnings position-fixed fadded_from_bottom  left-2px right-2px bottom-80px">
                    <div className="pr-3">
                        {props.icon
                            ?
                            <img className=' w-12px' src={loadIcon(props.icon)}/>
                            :
                            null
                        }

                    </div>
                    <div className="pr-5 text-truncate">{props.message} </div>
                    <a className="alert--warnings__close">
                        <img src="https://content.harstatic.com/media/icons/icons-close_faded.svg" onClick={closeDialog} />
                    </a>
                </div>
            </div>
        </>

    );
}
