import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSearchDealService } from 'services/deals/searchDeal';
import { ProminentButton } from 'views/forms/buttons/Button';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { IContactDealScreenProps } from './ContactDealScreen.def';
import './ContactDealScreen.scss';
import { parseISO, format } from 'date-fns'
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { Tag } from 'scripts/ui/tag';

export const ContactDealScreenDesktop = (_props: IContactDealScreenProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { dealid } = useParams<{ dealid: string }>();
    const [dealPop, setDealPop] = useState(false)
    const [dealId, setDealId] = useState(Number(dealid))
    const [searchDeal, searchDealAction] = useSearchDealService()
    const history = useHistory();
    useServiceDidSucceeded(searchDeal, () => {
        setDealId(0)
    });




    function formModeOpen(deal_id?: number) {
        if (deal_id) {
            setDealId(deal_id)
            history.push(`/contacts/${id}/deals/${deal_id}`)
        }
        setDealPop(true)
    }

    function init() {
        searchDealAction.fetch({ contact_id: id })
    }



    useEffect(() => {
        init()
        if (dealid) {
            formModeOpen(Number(dealid))

        } else {
            setDealId(0)
        }
    }, [])
    return (
        <>
            <DealsAdd visible={dealPop} toggleShow={() => {
                setDealPop(false);
                setDealId(0);
            }} reload={() => init()} dealsId={dealId} contactId={id} doRedirect={() => history.push(`/contacts/` + id + `/deals`, { referrer: '/contacts' })} />
            <ContactDetailsTemplate selectedMenu='Deals'>
                <div className="container">
                    <div className="page_title_container">
                        <div className="row">
                            <div className="col align-self-center">
                                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0 s1">Deals</h2>
                            </div>

                            <div className="col-auto align-self-center">
                                <ProminentButton onClick={() => formModeOpen()}><img className="mr-1 w-12px" src="https://content.harstatic.com/media/icons/add_white_2.svg" /> Add</ProminentButton>
                            </div>
                        </div>
                    </div>
                    {searchDeal.loading && 'loading'}
                    {searchDeal.response?.list.length === 0 &&
                        <div className="row">
                            <div className="col font_size--large">
                                There is no deal for this contact.
                            </div>
                        </div>

                    }
                    {searchDeal.response?.list.map((deal, index) =>
                        <div key={index} className="card depth--emboss p-4 border_radius--pudgy mb-lg-5 mb-4">
                            <div className="row">
                                <div className="col-lg-3 col-md-5 col-12 pb-4 pb-lg-0">
                                    <div className="listingcard listingcard--portrait mb-0 w-100">
                                        <a onClick={(e: any) => {
                                            e.preventDefault()
                                            formModeOpen(deal.id)
                                        }}>
                                            <Tag.Div className="listingcard--portrait__img listing_signature_photo" cssVars={{ listingSignaturePhoto: `url(${deal.property_photo ? deal.property_photo.replace('lr', 'hr') : `https://content.harstatic.com/img/common/no_image_bg.jpg`})` }}></Tag.Div>
                                        </a>
                                        <a >
                                            <div className="listingcard--portrait__body">
                                                <div className="listingcard--portrait__body_flexrow">
                                                    <div className="listingcard--portrait__body_price">${deal.deal_amount_formatted}</div>

                                                </div>
                                                <div className="listingcard--portrait__body_flexrow">
                                                    <div className="listingcard--portrait__body_address">
                                                        {deal.property_full_street_address}<br />
                                                        {deal.property_city}, {deal.property_state} {deal.property_zip}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-7 col-12 pb-4 pb-lg-0">
                                    <div className="mr-4 pr-5 border-lg-right border-0 h-100">
                                        <h3 tabIndex={0}>{deal.title}</h3>

                                        <div className="btn-group btn-group-toggle btn-group--dealstage mb-4 max-w-340" data-toggle="buttons">
                                            <label className={`stage stage--active ${deal.deal_stage === 'A' ? `active` : ``}`}>
                                                Active/Offers
                                            </label>
                                            <label className={`stage stage--contract  ${deal.deal_stage === 'I' ? `active` : ``}`}>
                                                In Contract
                                            </label>
                                            <label className={`stage stage--close  ${deal.deal_stage === 'C' ? `active` : ``}`}>
                                                Closed
                                            </label>
                                            <label className={`stage stage--fellthrough ${deal.deal_stage === 'F' ? `active` : ``}`}>
                                                Fell Through
                                            </label>
                                        </div>

                                        <div className=" font_size--medium">
                                            <div className="font_weight--bold">Deal Type</div>
                                            <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal.deal_type_text}</div>

                                            <div className=" font_weight--bold">Create date</div>
                                            <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal?.created_dt && format(parseISO(deal.created_dt), 'MMM d, yyyy')}</div>

                                            <div className=" font_weight--bold">Last Updated</div>
                                            <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal?.modified_dt && format(parseISO(deal.modified_dt), 'MMM d, yyyy')}</div>
                                            {deal.estimated_deadline
                                                &&
                                                <>
                                                    <div className=" font_weight--bold">Estimated Deadline</div>
                                                    <div className="mb-0">{format(parseISO(deal.estimated_deadline), 'MMM d, yyyy')}</div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h2 className="mb-3" tabIndex={0}>Notes</h2>
                                    <div className=" font_size--large">{deal.notes}</div>
                                </div>
                            </div>
                            <div className="text-right pt-3 pt-lg-0">
                                <a href="#" onClick={(e: any) => {
                                    e.preventDefault()
                                    formModeOpen(deal.id)
                                }} className=" color_auxiliary d-inline-flex align-items-center"><img className="mr-2" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" /> Edit Deal</a>
                            </div>
                        </div>
                    )}
                </div>
            </ContactDetailsTemplate>
        </>
    );
}
