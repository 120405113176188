import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import {  PropertiesType } from "types/utilities"
import { SendEmailsApi } from "./sendEmails";

export namespace CreateDraftApi
{
    export const url = "/emails/drafts";
    export const method = "POST";
    export const defaultError = "failed to create a draft.";
    export const operations = apiOperations(CreateDraftApi);
    export const scope = new class CreateDraftApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string | string[]|SendEmailsApi.IEmailAttachment[]>
        {
            return {
                ...input,
                subject: input.subject,
                message: input.message,
                recipients: input.recipients,
                recipients_cc: input.recipients_cc,
                recipients_bcc: input.recipients_bcc,
                reply_to_message_id:input.reply_to_message_id
                

            }
        }

        response(input:PropertiesType<Response, string |any>): Response
        {
            return {
				...input

			}
        }

        
    }();
    
    export interface Request 
    {
        subject?: string,
        message?: string,
        recipients?: string[],
        recipients_cc?: string[],
        recipients_bcc?: string[],
        attachments?:SendEmailsApi.IEmailAttachment[],
        reply_to_message_id?: string,
    }

    export interface Response
    {
        status:string,
        message:string,
        data:any
    }
    
}

export const fetchCreateDraftService = forwardOperation(CreateDraftApi.operations, 'fetch');
export const useFetchCreateDraftService = forwardOperation(CreateDraftApi.operations, 'useFetch');
export const useCreateDraftService = forwardOperation(CreateDraftApi.operations, 'useService');