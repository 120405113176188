import { forwardRef } from 'react';
import { ILazyScrollProps } from './LazyScroll.def';
import './LazyScroll.scss';

export const LazyScroll = forwardRef<LazyScroll.RefElement, ILazyScrollProps>((props, ref): JSX.Element => {
    const { page, children, hasScroll, onload, ...otherProps } = props
    const handleScroll = (e: any) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) - 3 <= e.target.clientHeight;
        if (bottom) {
            if (hasScroll) {
                onload?.(page + 1)
            }
        }
    }
    return (
        <div {...otherProps} ref={ref} onScroll={(e: any) => handleScroll(e)} >
            {children}
        </div>

    );
});

export namespace LazyScroll {
    export type RefElement = HTMLDivElement;
}