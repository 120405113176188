import { RefObject } from 'react';
import { ServiceError } from 'scripts/exceptions';
import { IUpdateState } from "views/contacts/ContactInsightSection/ContactInsightSection.def";

export interface IContactDetailsTemplateProps {
	children: JSX.Element | JSX.Element[];
	selectedMenu?: MenuItemsKey;
	loadMore?: () => void;
	referrer?: string;
	valueChanged?: Array<IUpdateState>;
	lastChange?:Date; // used to force update on contact data
	contentRef?:RefObject<HTMLIonContentElement>;
	forceFullHeight?:boolean;
	onSwipeRefresh?:() => void;
}

export interface IContactDetailsLocationProps {
	referrer?:string
}




export interface IMenuProps {
	id: string;
	selectedMenu?: MenuItemsKey;
	isApp?:boolean
}

export const menuItems = {
	Overview: { text: 'Overview', url: 'overview' },
	Notes: { text: 'Notes', url: 'notes' },
	Deals: { text: 'Deals', url: 'deals' },
	Calendar: { text: 'Calendar', url: 'calendar' },
	Emails: { text: 'Emails', url: 'emails' },
	Documents: { text: 'Documents', url: 'documents' },
//	Activities: { text: 'Activities', url: 'activities' },
	Services: { text: 'Services', url: 'services' },
	//Transactions: !isProduction? { text:'Transactions', url:'transactions' }: undefined,
	ContactDetails: { text: 'Contact Details', url: 'detail' },
};

export type MenuItemsKey = keyof typeof menuItems;

// interface IDeleteC {
// 	firstname:string;
// 	lastname:string
// }


export interface IDeleteContactPopUpProps {
	modalRef?: React.Ref<HTMLIonModalElement>;
	onClose?: () => void;
	isResponse?: () => void;
	firstname?: string;
	lastname?: string;
}

export interface IDeleteMessagePopUpProps {
	firstname?: string;
	lastname?: string;
}

export interface ICustomErrorProps
{
	error:ServiceError<unknown>;
}

// export interface IResponse {
// 	response?: undefined | {}
// }

// Constants
export const FORCE_FULL_HEIGHT_CLASS = 'inner-full-height';