import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"
import { GetDealsPipelineApi } from "./getDealsPipeline";

export namespace GetDealApi {
    export const url = "/deals/:deal_id";
    export const method = "GET";
    export const defaultError = "Failed to load Deal";
    export const operations = apiOperations(GetDealApi);
    export const scope = new class GetDealApiScope implements IApiScope<Request, Response> {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                deal_id: input.deal_id
            }
        }
        response(input: DeepPropertiesType<Response, string>): Response {
            const { status, data, ...other } = input;
            return {
                status: status,
                data: {
                    id: Number(data.id),
                    title: data.title,
                    deal_type: data.deal_type,
                    deal_type_text: data.deal_type_text,
                    deal_stage: data.deal_stage,
                    deal_stage_text: data.deal_stage_text,
                    deal_amount: Number(data.deal_amount),
                    deal_amount_formatted: data.deal_amount_formatted,
                    deal_priority: data.deal_priority,
                    property_photo: data.property_photo,
                    property_price: data.property_price,
                    property_full_street_address: data.property_full_street_address,
                    property_address2: data.property_address2,
                    property_city: data.property_city,
                    property_state: data.property_state,
                    property_harid: data.property_harid,
                    property_mlsnum: data.property_mlsnum,
                    property_listingid: data.property_listingid,
                    property_listtype: data.property_listtype,
                    property_for: data.property_for,
                    property_zip: Number(data.property_zip),
                    property_country: data.property_country,
                    property_type: data.property_type,
                    property_type_text: data.property_type_text,
                    estimated_deadline: data.estimated_deadline,
                    notes: data.notes,
                    contacts: data.contacts.map(this._mapDealContacts, this),
                    ...other
                },
                ...other
            }
        }

        _mapDealContacts(input: DeepPropertiesType<GetDealsPipelineApi.IDealContact, string>): GetDealsPipelineApi.IDealContact {
            const { id, first_name, last_name, city, middle_name, score, photo_url, ...other } = input;
            return {
                id: Number(id),
                first_name: first_name,
                last_name: last_name,
                middle_name: middle_name,
                city: city,
                score: score,
                photo_url: photo_url,
                ...other
            }
        }
    }();

    export interface Request {
        deal_id: string
    }

    export interface Response {
        status: string,
        data: GetDealsPipelineApi.IDealList
    }
}

export const fetchGetDealService = forwardOperation(GetDealApi.operations, 'fetch');
export const useFetchGetDealService = forwardOperation(GetDealApi.operations, 'useFetch');
export const useGetDealService = forwardOperation(GetDealApi.operations, 'useService');