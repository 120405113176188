import { isPlatform } from "@ionic/react";

type Environment = 'production' | 'test' | 'local';

declare global
{
  const harcrm: {
    APP_VERSION:number;
    ENV:Environment;
  }
}

export const version = harcrm?.APP_VERSION;

/// TODO: Temporary... Get from Docker instance when available
export const _environment:Environment =
  window.location.host === 'crm.har.com'? 'production':
  window.location.host === 'crm-test.har.com'? 'test':
  window.location.host === 'localhost' && !isPlatform('capacitor')? 'local':
  harcrm.ENV;

// Replace this variable to simulate a specific enviornment from local
export const environment = _environment;
//export const environment = 'production' as Environment;

//export const environment = process.env.ENVIRONMENT ?? 'local';
export const isLocal = environment === 'local';
export const isTest = environment === 'test';
export const isProduction = environment === 'production';

export const websiteHost = process.env.REACT_APP_WEBSITE_URL ?? (isProduction? 'https://www.har.com': 'https://www-v2-test.har.com');
export const mopHost = process.env.REACT_APP_MOP_URL ??  (isProduction? websiteHost: 'https://www-test.har.com');
export const cmsHost = 'https://cms.har.com';

export const forceHttps = _environment !== 'local' && window.location.protocol === 'http:' && (isPlatform('desktop') || isPlatform('mobileweb'));


/**
 * Defines the default cluster timeout for services
 */
export const SERVICE_CLUSTER_TIMEOUT = 150;