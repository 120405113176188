import { isProduction, mopHost } from "scripts/application/settings";

export interface IServicesLandingScreenProps
{

}

export interface IServiceSection
{
    title: string;
    description: string;
    icon: string;
    link?: string;
    learnMoreLink?: string;
    disabled?: boolean;
    actionLabel?:string;
}

export const services:IServiceSection[] = [
    {
        title: 'MarketInsight',
        description: 'Keep your clients up to date on real estate activities surrounding their property.',
        icon: 'https://content.harstatic.com/media/icons/crm/group-27.svg',
        link: '/services/marketinsight',
        learnMoreLink: 'https://cms.har.com/marketinsight/'
    },
    {
        title: 'EmailMarketing',
        description: 'Share listing & message-based email campaigns with your clients.',
        icon: 'https://content.harstatic.com/media/icons/crm/email_marketing.svg',
        link: '/services/emailmarketing',
        learnMoreLink: 'https://cms.har.com/emailmarketing/'
    },
    {
        title: 'My Transactions',
        description: 'Access your listing dashboard (Active, Sold, Incomplete, My Open Houses, etc.).',
        icon: 'https://content.harstatic.com/media/icons/crm/group-11.svg',
        link: '/services/mytransactions',
        disabled: isProduction
    },
    {
        title: 'CMA Report',
        description: 'Create an Instant CMA for your clients to assist in determining the best price for a property.',
        icon: 'https://content.harstatic.com/media/icons/crm/MyListings.svg',
        link: `${mopHost}/moa_cma/instant_cma`,
        learnMoreLink: 'https://cms.har.com/instantcma'
    },
    {
        title: 'Client Portal',
        description: 'Create a client portal with customized property searches,  market trend reports and a client interface.',
        icon: 'https://content.harstatic.com/media/icons/icon__search.svg',
        link: '/services/client-portal',
        actionLabel: isProduction? 'Coming Soon': 'Access Dashboard',
        disabled: isProduction
    }
    /*{
        title: 'ShowingSmart',
        description: 'HAR’s platform for scheduling and managing showings for listings.',
        icon: ?,
        disabled: true
    }*/
];