import { useFeedbackIncluded, useFeedbackRemoved } from 'scripts/hooks/feedbacks';
import { IFeedbackBlurProps, IFeedbackItemProps, IFeedbackSpaceProps } from './FeedbackSpace.def';
import './FeedbackSpace.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

export const FeedbackSpace = (_props: IFeedbackSpaceProps): JSX.Element =>
{
    const feedbacks = useRef(new Map<string, IFeedbackItemProps>())
    const newFeedback = useFeedbackIncluded();
    const removedFeedback = useFeedbackRemoved();
    // console.log('new feedback', newFeedback);
    // console.log('removed feedback', removedFeedback);

    if(newFeedback) { feedbacks.current.set(newFeedback.key, { feedback:newFeedback, visible:true }); }
    if(removedFeedback) { feedbacks.current.set(removedFeedback.key, { feedback:removedFeedback, visible:false }); }

    const onExit = (key:string) => feedbacks.current.delete(key);

    const feedbackList = Array.from(feedbacks.current.values());
    const items = feedbackList.map(props => (
        <FeedbackItem
            key={props.feedback.key}
            feedback={props.feedback}
            visible={props.visible}
            onExit={onExit}
        />
    ));

    const feedbacksBlur = feedbackList.filter(x => x.feedback.options.blur);

    return (<>
        <TransitionGroup className='feedback-list zindex-21000'>
            {!!feedbacksBlur.length && <FeedbackBlur visible={feedbacksBlur.some(x => x.visible)} />}
            {items}
        </TransitionGroup>
    </>);
}

const FeedbackItem = (props:IFeedbackItemProps) =>
{
    const className = 'feedback-item';
    const { key, element } = props.feedback;
    const nodeRef = useRef<HTMLDivElement>(null)
    return (
        <CSSTransition
            in={props.visible}
            key={key}
            nodeRef={nodeRef}
            classNames={className}
            timeout={{ enter:500, exit:700 }}
            unmountOnExit={true}
            onExit={() => props.onExit?.(key)}
        >
            <div className={className} ref={nodeRef}>{element}</div>
        </CSSTransition>
    );
}

const FeedbackBlur = (props:IFeedbackBlurProps) =>
{
    const className = 'feedback-blur';
    const nodeRef = useRef<HTMLDivElement>(null);
    return (
        <CSSTransition
            in={props.visible}
            key={className}
            nodeRef={nodeRef}
            classNames={className}
            timeout={{ enter:500, exit:300 }}
            unmountOnExit={true}
        >
            <div className={className} ref={nodeRef}></div>
        </CSSTransition>
    );
}