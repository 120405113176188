import { IContentProps, IImportContactsProps } from './ImportContacts.def';
import { OnBoardingTemplate } from 'views/templates/OnBoardingTemplate';
import { LoaderScreen } from 'views/screens/LoaderScreen';
import { InstructionsSection } from 'views/onboarding/InstructionsSection';
import { eOnboardingStage } from 'scripts/enums';
import { useOnboardingStage } from 'scripts/hooks/onboarding';
import { useEffect } from 'react';
import { PrimaryButton } from 'views/forms/buttons/Button';

export const ImportContacts = (_props: IImportContactsProps): JSX.Element =>
{
    const onboarding = useOnboardingStage(eOnboardingStage.Complete);
    //const [agent] = useFetchGetBasicInfoService();
    //const { sync_provider_to_nylas } = agent.response ?? {};
    const { provider_sync_mode } = onboarding.user ?? {};

    // useEffect(() => {
    //     if(sync_provider_to_nylas) { toDefaultScreen(); }
    // }, [sync_provider_to_nylas])

    return (
        <OnBoardingTemplate onboarding>
            <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pr-5">
                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Import</h2>
            </div>
            {
                onboarding.user === undefined || provider_sync_mode === undefined?
                <LoaderScreen />:
                <Content onboarding={onboarding} displayNylasImport={provider_sync_mode} />
            }
        </OnBoardingTemplate>
    )
}

const Content = (props:IContentProps): JSX.Element =>
{
    const { next, labels } = props.onboarding;
    
    const redirect = () => next?.();

    useEffect(() => {
        if(!props.displayNylasImport) { redirect(); }
    }, [props.displayNylasImport])

    return (<>
        <InstructionsSection showProviderContacts={props.displayNylasImport} />
        <div className="mb-md-5 mb-4 text-center ml-auto mr-auto">
            <PrimaryButton size='large' onClick={redirect} loading={!next}>{labels.nextLabel}</PrimaryButton>
        </div>
    </>)
}