import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeleteCalendarTaskApi
{
    export const url = "/tasks/:id";
    export const method = "DELETE";
    export const defaultError = "Failed to delete task.";
    export const operations = apiOperations(DeleteCalendarTaskApi);
    export const scope = new class DeleteCalendarTaskApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        id:string,
        option_id?:string
    }

    export interface Response
    {
        status:string,
        message:string
    }
}

export const fetchDeleteCalendarTaskService = forwardOperation(DeleteCalendarTaskApi.operations, 'fetch');
export const useFetchDeleteCalendarTaskService = forwardOperation(DeleteCalendarTaskApi.operations, 'useFetch');
export const useDeleteCalendarTaskService = forwardOperation(DeleteCalendarTaskApi.operations, 'useService');