import { lazy, lock } from 'scripts/utilities/decorators';
import { history } from 'scripts/router/history';
import { Badge } from '@awesome-cordova-plugins/badge';
import { CookieProvider, LoginProvider } from './loginProviders';
import { PromiseController } from 'scripts/helpers/async';
import { wait } from 'scripts/helpers/intervals';
import { IMessageOptions, captureMessage } from 'scripts/logging/error-report';

export class Auth
{
  @lazy()
  public static get loginProvider(): LoginProvider
  {
    return new CookieProvider({
      redirectLoginUrl: `${origin}/login/callback`,
      redirectLogoutUrl: `${origin}/logout/callback`,
    });
  }

  public static get isAuthenticated()
  {
    return this.loginProvider.isAuthenticated();
  }

  public static get isLoginExternal()
  {
    return this.loginProvider.isExternal;
  }

  public static get userToken(): PromiseController<string | undefined>
  {
    return this.loginProvider.getToken();
  }

  public static get userValidToken(): Promise<string>
  {
    return new Promise<string>(async resolve => {
      try {
        const token = await this.userToken;
        if(token) { return resolve(token); }
        return this.handleError();
      }
      catch(e) { return this.handleError(e); }
    });
  }

  public static signIn(nextUrl?:string | true)
  {
    if(nextUrl === true) { nextUrl = this.getCurrentRouteUrl(); }
    return this.loginProvider.signIn(nextUrl);
  }

  public static signOut(nextUrl?:string | true)
  {
    //return Auth.Instance.signOut(undefined, true);
    if(nextUrl === true) { nextUrl = this.getCurrentRouteUrl(); }
    Badge.set(0);
    return this.loginProvider.signOut(nextUrl);
  }

  /**
   * Temporary
   */
  @lock()
  public static async captureLogoutMessage(message:string, options?:IMessageOptions): Promise<void>
  {
    //const token = await this.loginProvider.getToken();
    if(!options) { options = { tags: {} }; }

    // This is a temporary solution to avoid the error:
    // "The value of the 'HAR_KEY' tag must not exceed 200 characters."
    // The error is thrown by Sentry when the token is too long.
    //const chunks = token?.match(/.{1,200}/g) || [];
    //for(let index = 0; index < chunks.length; index++) { options.tags[`HAR_KEY_${index}`] = chunks[index]; }
    
    captureMessage('User logged out: ' + message, options);
    await wait(1000);
  }

  private static async handleError(e?:unknown)
  {
    // TODO: Log into sentry
    if(e) { console.error(e); }
    try { await this.signOut(); }
    catch(_e) { history.push('/login'); }
  }

  private static getCurrentRouteUrl()
  {
    return window.location.href.substring(window.location.origin.length);
  }
}