import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { formatHarTime, formatTwoDateTimeShortWithDay,formatHarDate} from "scripts/helpers/dates";
import { IApiScope } from "types/apis";
import { PropertiesType ,DeepPropertiesType} from "types/utilities"
import moment from 'moment';

export namespace GlobalSearchApi
{
    export const url = "/globalsearch";
    export const method = "GET";
    export const defaultError = "Failed to load data";
    export const operations = apiOperations(GlobalSearchApi);
    export const scope = new class GlobalSearchApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
                ...input
               
			}
        }
        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            const calendarIconUrl='https://content.harstatic.com/media/icons/calander_gray_cicle.svg'
            const emailIconUrl="https://content.harstatic.com/media/icons/email_gray_circle.svg"
            return {
				...data,
                contacts:{...data.contacts,list:data.contacts.list.map((x)=>({...x,title:`${x.first_name} ${x.last_name}`,listItems:x.tags,link:`/contacts/${x.id}/overview`,text:x.address}))},
                emails:{...data.emails,list:data.emails.list.map((x)=>({...x,formatted_date:formatHarTime(x.created_dt),title:`${x.first_name} ${x.last_name}`,listItems:[],link:`/emails/inbox/${x.id}`,text:x.short_message,photo_url:emailIconUrl}))},
                tasks:{...data.tasks,list:data.tasks.list.map((x)=>({...x,formatted_date:formatTwoDateTimeShortWithDay(moment(x.start_dt),moment(x.end_dt)),listItems:x.contacts,link:`/calendar/task/${x.id}`,text:x.property_full_street_address,photo_url:calendarIconUrl}))},
                deals:{...data.deals,list:data.deals.list.map((x)=>({...x,created_dt:formatHarTime(x.created_dt),estimated_deadline:formatHarDate(x.estimated_deadline),listItems:x.contacts,link:`/deals/${x.id}`,text:x.property_full_street_address,photo_url:x.property_photo}))},


                /* list: data.list.map((x) => ({
                    ...x,
                    completed: strToBoolean(x.completed)
                }))*/
            }
        }
    }();
    
    export interface Request
    {
        input?:string,
        tab?:string,
        page?:string,
        page_size?:string,
        paging_for_tab?:string,
        excluded_ids?:string

    }

    export interface Response
    {
       
        page?: string,
        page_size?: string,
        excluded_ids?: string,
        total?:string,
        contacts:{list:Array<IContactData>,total:string},
        deals:{list:Array<IDealData>,total:string },
        documents:{list:Array<IDocumentData>,total:string},
        emails:{list:Array<IEmailData>,total:string},
        services:{list:Array<IContactData>,total:string},
        tasks:{list:Array<ITaskData>,total:string}
    }

    export interface IContactData{
        first_name:string,
        id:string,
        last_name:string,
        last_contact_date:string,
        middle_name:string,
        object_owner_name:string,
        photo_url:string,
        pipeline_status:string,
        pipeline_status_order:string,
        pipeline_status_text:string,
        primary_email:string,
        city:string,
        zip_code:string,
        primary_phone:string,
        primary_phone_id:string,
        company:string,
        created_dt:string,
        score:string,
        sources:string,
        link:string,
        address:string,
        formatted_date:string,
        tags:Array<ITag>
    }
    export interface IEmailData{

        id:string,
        subject:string,
        message_uid: string,
        read: string,
        thread_id:string,
        short_message: string,
        created_dt: string,
        date_tag: string,
        from_address: string,
        first_name: string,
        middle_name: string,
        last_name: string,
        photo_url: string,
        contact_id: string,
        display_name: string,
        linked_draft_id: string,
        recipients: string[],
        recipients_cc:string[],
        recipients_bcc:string[],
        is_lead:string,
        link:string,
        formatted_date:string
    }
  
    export interface ITaskData{
        id: string,
        title: string,
        location: string,
        type: string,
        type_text: string,
        formatted_date:string
        start_dt: string,
        end_dt: string,
        start_dt_format: string,
        end_dt_format: string,
        created_dt:string,
        completed_dt: string,
        completed: string,
        status:string,
        status_text:string,
        category: string,
        category_text: string,
        property_full_street_address: string,
        priority:string,
        property_mlsnum: string,
        property_harid: string,
        property_url: string,
        priority_text: string,
        reminder_minutes_prior:string,
        reminder_minutes_prior_text: string,
        repeat: string,
        repeat_text: string,
        repeat_every_value:string,
        repeat_every_text: string,
        repeat_on_day:string,
        repeat_end_on: string,
        repeat_end_occurrences:string,
        repeat_end_date: string,
        link:string,
        repeat_month_option: string,
        contacts: Array<IContact>
    }
    export interface IDealData{

        id: string,
        title: string,
        deal_type: string,
        deal_type_text: string,
        deal_priority: string,
        deal_priority_text:string,
        deal_stage: string,
        deal_stage_text:string,
        property_url: string,
        property_photo: string,
        property_harid: string,
        property_for_sale: string,
        property_mlsnum: string,
        property_price: string,
        property_full_street_address: string,
        property_address2: string,
        property_city: string,
        property_state:string,
        property_zip: string,
        property_type: string,
        property_for: string,
        property_listingid:string,
        property_type_text: string,
        property_category:string,
        deal_amount: string,
        deal_amount_formatted: string,
        estimated_deadline: string,
        created_dt: string,
        modified_dt: string,
        link:string,
        contacts:Array<IDealContact>
    }
    export interface IDocumentData{
        
        id: string,
        name:string,
        path: string,
        extension: string,
        icon: string,
        created_dt: string,
        modified_dt: string
    
}
    export interface IDealContact{
        
            id: string,
            first_name: string,
            middle_name: string,
            last_name: string,
            photo_url: string,
            score: string,
            city: string,
            zip_code: string
            link:string
        
    }
 
    export interface IContact{ 
        id: string,
        first_name: string,
        middle_name: string,
        last_name: string,
        photo_url: string,
        email: string
    }
    export interface ITag{
    id:string,
    name:string
   }
 
}

export const fetchGlobalSearchService = forwardOperation(GlobalSearchApi.operations, 'fetch');
export const useFetchGlobalSearchService = forwardOperation(GlobalSearchApi.operations, 'useFetch');
export const useGlobalSearchService = forwardOperation(GlobalSearchApi.operations, 'useService');