import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IEmailMarketingScreenProps } from './EmailMarketingScreen.def';
import './EmailMarketingScreen.scss';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';
import { useLocation, useParams } from 'react-router';

export const EmailMarketingScreen = (_props: IEmailMarketingScreenProps): JSX.Element =>
{
    const { path } = useParams<{ path:string }>();
    const location = useLocation();

    return (
        <DefaultTemplate title='EmailMarketing' fluid>

            <ExternalEmbedContent
                url='/moa_outboundcrm'
                initialPath={path}
                queryString={location.search}
                source={eExternalEmbedContentSource.MemberOnlyArea}
                titleSeparator=' '
                iframeTitle='EmailMarketing'
                enabledInnerRoutes
            />

        </DefaultTemplate>
    );
}
