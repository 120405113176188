import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"

export namespace PostCSVApi {
    export const url = "/contacts/csv/:import_id/submit";
    export const method = "POST";
    export const defaultError = "Failed to load result of csv import.";
    export const operations = apiOperations(PostCSVApi);
    export const scope = new class PostCSVApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: DeepPropertiesType<Response, string>): Response {

            return {
                ...input
            }
        }
    }();

    export interface Request {
        import_id: string,
        queue?: string
    }

    export interface Response {
        status: string,

    }
}

export const fetchPostCSVService = forwardOperation(PostCSVApi.operations, 'fetch');
export const useFetchPostCSVService = forwardOperation(PostCSVApi.operations, 'useFetch');
export const usePostCSVService = forwardOperation(PostCSVApi.operations, 'useService');