import { IDefaultTemplateFinalProps, environment, showVersion } from './DefaultTemplate.def';
import './DefaultTemplate.scss';
import { IonContent, IonRouterLink } from '@ionic/react';
import { Dropdown} from 'react-bootstrap';
import { useFetchGetBasicInfoService } from 'services/user/getBasicInfo';
import React, { PropsWithChildren } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isLocal, isProduction, mopHost, version } from 'scripts/application/settings';
//import { GlobalSearchPopup } from 'views/screens/GlobalSearchScreen/components/GlobalSearchPopup';
import { Auth } from 'scripts/security/AuthService';
import { GlobalSearchPopup } from 'views/screens/GlobalSearchScreen/components/GlobalSearchPopup';
import { useFetchNotificationSummaryService } from 'services/notifications/notificationSummary';
//import { eOnboardingStageIndex } from 'scripts/enums';
import { eOnboardingStage } from 'scripts/enums';
import { fetchUpdateCounterService } from 'services/notifications/updateCounter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { joinWithSpace } from 'scripts/helpers/texts';

export const DefaultTemplateDesktop = (props: IDefaultTemplateFinalProps): JSX.Element => {
  const { ionContentProps } = props;
  const [{ response }] = useFetchGetBasicInfoService();
  const [data]=useFetchNotificationSummaryService()
  const history = useHistory();

  const signOutHandler = (e:React.MouseEvent) =>
  {
    e.preventDefault();
    Auth.signOut();
  };
  
  const fullname=`${response?.first_name!?response?.first_name:""} ${response?.last_name?response?.last_name:""}`;
  const agentOfficeAddress=response?.agent_data?.office_name;
  //const versionText = isProduction? '': ` v${version}`;
  const versionText = ` v${version}`;
  //const enableMenu = response? eOnboardingStageIndex[response.onboarding_stage] > eOnboardingStageIndex.AccountConnection: false;
  const isOnboardingComplete = response?.onboarding_stage === eOnboardingStage.Complete;
  const showSearch = isOnboardingComplete
  const showNotifications = isOnboardingComplete;
  const enableMenu = true;
     const handleNavigate=async()=>{
      await fetchUpdateCounterService();
       history.push(`/notifications`);
     }
  return (
      <IonContent {...ionContentProps} className={joinWithSpace(props.className, ionContentProps?.className)} fullscreen ref={props.contentRef} >
        <Container fluid={props.fluid} >
             <Header fluid={props.fluid}>
                 <nav className="navbar crm-nav position-sticky blured_bg top-0">
                  <Logo asLink={enableMenu} className="navbar-brand">
                    <img src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" className='rounded w-48px h-48px' />
                    {/* <img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='rounded w-48px h-48px' /> */}
                    {/* <img alt="HAR" src="https://content.harstatic.com/resources/images/common/logo-blue.svg" /> */}
                  </Logo>
                  {enableMenu&&<div className="bavbar_dropdown">
                    <Dropdown className="dropdown dropdown--custom dropdown--custom__home dropdown__stats">
                      <Dropdown.Toggle                        
                        className="btn btn--shapeless_dark btn--inlineicon"
                        role="link"
                        variant="none"
                        href="#"
                      >
                        <img className=' mr-12px'                          
                          src="https://content.harstatic.com/media/icons/crm/cra_menu_icon.svg"
                        />
                        <span>Menu</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu__icon animate slideIn">
                        <Dropdown.Item className="dropdown-item" as={Link} to="/home">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_home.svg" />
                          </span>
                          <span className="dropdown-item__text">Home</span>
                        </Dropdown.Item>

                        <Dropdown.Item className="dropdown-item" as={Link} to="/contacts">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_contacts.svg" />
                          </span>
                          <span className="dropdown-item__text">Contacts</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/deals">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_deals.svg" />
                          </span>
                          <span className="dropdown-item__text">Deals</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/emails/inbox">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_emails.svg" />
                          </span>
                          <span className="dropdown-item__text">Emails</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/calendar">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_calendar.svg" />
                          </span>
                          <span className="dropdown-item__text">Calendar</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item className="dropdown-item" as={Link} to="/services/marketinsight">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_reports.svg" />
                          </span>
                          <span className="dropdown-item__text">MarketInsight</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/services/emailmarketing">
                          <span className="dropdown-item__icon">
                            <img width="24" src="https://content.harstatic.com/media/icons/common/email_circle_black.svg" />
                          </span>
                          <span className="dropdown-item__text">EmailMarketing</span>
                        </Dropdown.Item>
                        {!isProduction && <Dropdown.Item className="dropdown-item" as={Link} to="/services/mylistings">
                          <span className="dropdown-item__icon">
                            <img width="24" src="https://content.harstatic.com/media/icons/mls/listing_icon_formenu.svg" />
                          </span>
                          <span className="dropdown-item__text">My Listings</span>
                        </Dropdown.Item>} */}
                        <Dropdown.Item className="dropdown-item" as={Link} to="/services">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_services.svg" />
                          </span>
                          <span className="dropdown-item__text">Services</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/support/training">
                          <span className="dropdown-item__icon">
                            <img width="24" src="https://content.harstatic.com/media/icons/crm/menu/email_circle_black_1.svg" />
                          </span>
                          <span className="dropdown-item__text">Video Tutorials</span>
                        </Dropdown.Item>
                        
                        <Dropdown.Item className="dropdown-item d-none" as={Link} to="/support">
                          <span className="dropdown-item__icon">
                            <img width="24" src="https://content.harstatic.com/media/icons/social/email_circle_settings.svg" />
                          </span>
                          <span className="dropdown-item__text">Services</span>
                        </Dropdown.Item>

                        <Dropdown.Item className="dropdown-item" as={Link} to="/support">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_supprts.svg" />
                          </span>
                          <span className="dropdown-item__text">Support</span>
                        </Dropdown.Item>
                        
                        <Dropdown.Item className="dropdown-item border-top border-color--cloudy-sky-light" as={Link} to="/settings/account">
                          <span className="dropdown-item__icon">
                            <img width="24" src="https://content.harstatic.com/media/icons/social/email_circle_settings.svg" />
                          </span>
                          <span className="dropdown-item__text">Settings</span>
                        </Dropdown.Item>
                        
                        
                        
                        {/* 
                        <Dropdown.Item className="dropdown-item" as={Link} to="/reports">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_reports.svg" />
                          </span>
                          <span className="dropdown-item__text">Reports</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/tasks">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_services.svg" />
                          </span>
                          <span className="dropdown-item__text">Services</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" as={Link} to="/activities">
                          <span className="dropdown-item__icon">
                            <img src="https://content.harstatic.com/media/icons/crm/icon_activities.svg" />
                          </span>
                          <span className="dropdown-item__text">Activities</span>
                        </Dropdown.Item>
                        */}
                        
                        
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>}
                  <div className="user_menu">
                    <a id="HamIcon" href="#">
                      <img className=' w-24px' src="https://content.harstatic.com/media/icons/menulines_black.svg" />
                    </a>
                  </div>  
                  <div className="navbar_usermenu">
                     
                  {isLocal && showSearch && <div className="navbar_search">
                    {/* <input className="header_search ml-auto" type="text" placeholder="Search contacts, leads, events…" /> */}
                    <GlobalSearchPopup/>
                  </div>}

                  {showNotifications && <div className=" align-self-center" >  
                    <Dropdown className='dropdown dropdown--custom dropdown--custom__withoutarrow' alignRight>
                    <Dropdown.Toggle className="" role="link" variant="none" href="#">
                      <div onClick={handleNavigate}className="position-relative pl-2 pr-1 mr-2">
                      <img className="rounded-circle bg--image w-24px" src="https://content.harstatic.com/media/icons/icon-notifications.svg" />
                      {(data.response?.total_unread??0) >0&&<div  className="counterBubble">{data.response?.total_unread}</div>}
                    </div>
                  </Dropdown.Toggle></Dropdown>
                  </div>}
                    
                   <div className="user_menu">
                    <Dropdown className='dropdown dropdown--custom' >
                        <Dropdown.Toggle
                          
                          className="dropdown-toggle btn color_snow_white btn--shapeless logged-in"
                          role="link"
                          variant="none"
                          href="#"
                          
                        >
                          <img
                            className="rounded-circle bg--image h-40px w-40px"
                            src={response?.photo}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu  className="dropdown-menu animate slideIn profile-menu w-330px">
                                    <div className="border-bottom border-color--cloudy-sky-light p-4">
                                        <div className="animate__animated animate__fadeInUp animate__fast dropdown-item dropdown-item--title">{fullname}</div>
                        <Dropdown.Item  target="_blank" href={`${mopHost}/memberonlyarea#member`} className="animate__animated animate__fadeInUp animate__fast">
                              <div className="row">
                                  <div className="col color_har_blue white_space_break">
                                     {agentOfficeAddress}
                                  </div>
                                  <div className="col-auto align-self-center"><img className=' h-24px' src="https://content.harstatic.com/media/icons/arrow_faded.svg"></img></div>
                              </div>
                          </Dropdown.Item>
                          </div>
                          <div className="border-bottom border-color--cloudy-sky-light p-4">                                        
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="/settings/account">Settings</Dropdown.Item>
                         {/**  <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="#">Switchh Team</Dropdown.Item>*/}
                      </div>
                      <div className="border-bottom border-color--cloudy-sky-light p-4">
                          <div className="animate__animated animate__fadeInUp animate__fast dropdown-item dropdown-item--title">HAR CRM{showVersion && (versionText + environment())}</div>
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" target="_blank" href={`${mopHost}/memberonlyarea#member`}>Members Only Area</Dropdown.Item>
                          {!isProduction && <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="#">About the App</Dropdown.Item>}
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast d-none" href="#">Support</Dropdown.Item>
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="https://cms.har.com/help-center/">Help & Feedback</Dropdown.Item>
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="https://cms.har.com/privacy-policy/">Privacy Policy</Dropdown.Item>
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="https://cms.har.com/accessibility/">Accessibility</Dropdown.Item>
                          <Dropdown.Item className="animate__animated animate__fadeInUp animate__fast" href="https://cms.har.com/termsofuse/">Terms of Use</Dropdown.Item>
                      </div>
                      <div className="p-4">
                          <Dropdown.Item href="#" className="animate__animated animate__fadeInUp animate__fast" onClick={signOutHandler}>Sign out</Dropdown.Item>
                      </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </nav>
                <div className="menu_overlay animate slideIn"></div>
          </Header>
          <LocalizationProvider dateAdapter={AdapterDateFns} >
          {props.children}
          </LocalizationProvider>
         </Container>
      </IonContent>
  );
};

function Header(props:PropsWithChildren<{ fluid?:boolean }>)
{
    if(!props.fluid) { return <>{props.children}</>; }
    return (
        <div className="scrollable_layout__header zindex-999999999">
            {props.children}
        </div>
    );
}

function Container(props:PropsWithChildren<{ fluid?:boolean }>)
{
    if(!props.fluid) { return <>{props.children}</>; }
    return (
        <div className="scrollable_layout">
         
            {props.children}

        </div>
    );
}

function Logo(props:{ asLink:boolean } & React.HTMLAttributes<any>)
{
  const { asLink, ...htmlProps } = props;

  return asLink?
    <IonRouterLink {...htmlProps} routerLink="/home">{props.children}</IonRouterLink>:
    <span {...htmlProps}>{props.children}</span>;
}
