import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateCounterApi
{
    export const url = "/notifications/sync_count";
    export const method = "POST";
    export const defaultError = "Failed to sync counter.";
    export const operations = apiOperations(UpdateCounterApi);
    export const scope = new class UpdateCounterApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        
    }
}

export const fetchUpdateCounterService = forwardOperation(UpdateCounterApi.operations, 'fetch');
export const useFetchUpdateCounterService = forwardOperation(UpdateCounterApi.operations, 'useFetch');
export const useUpdateCounterService = forwardOperation(UpdateCounterApi.operations, 'useService');