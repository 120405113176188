import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"


export namespace GetStagesApi {
    export const url = "/contacts/pipeline";
    export const method = "GET";
    export const defaultError = "Failed to load stage list.";
    export const operations = apiOperations(GetStagesApi);
    export const scope = new class GetStagesApiScope implements IApiScope<Request, Response>
    {
        request(data: Request): PropertiesType<Request, string | number> {
            return {
                stages: data.stages?.join(','),
                cities: data.cities?.join(','),
                zip_codes: data.zip_codes?.join(','),
                tag_ids: data.tag_ids?.join(','),
                scores: data.scores?.join(','),
                object_owners: data.object_owners?.join(','),
                sources: data.sources?.join(','),
                companies: data.companies?.join(','),
                deal_stages: data.deal_stages?.join(','),
                last_contact_date: data.last_contact_date
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                stages: data.stages,
            }
        }



        _mapContactStages(data: DeepPropertiesType<IContactStages, string>): IContactStages {
            const { total, total_pages, page, page_size, list, ...other } = data;
            return {
                total: parseInt(total),
                total_pages: parseInt(total_pages),
                page: parseInt(page),
                page_size: parseInt(page_size),
                list: list.map(this._mapContactItem, this),
                ...other
            }
        }


        _mapContactItem(input: DeepPropertiesType<IContactSearchItem, string>): IContactSearchItem {
            const { id, deal_stages, tags, ...other } = input;
            return {
                id: parseInt(id),
                deal_stages: deal_stages.map(this._mapDealStages, this),
                tags: tags.map(this._mapTag, this),
                ...other
            };
        }

        _mapDealStages(input: PropertiesType<IDealStage, string>): IDealStage {
            const { total_deals, ...other } = input;
            return { total_deals: parseInt(total_deals), ...other };
        }

        _mapTag(input: PropertiesType<ITag, string>): ITag {
            const { id, contacts_count, ...other } = input;
            return { id: parseInt(id), contacts_count: parseInt(contacts_count), ...other };
        }

    }();

    export interface Request {
        stages?: number[],
        cities?: string[],
        zip_codes?: string[];
        tag_ids?: string[],
        scores?: string[],
        object_owners?: string[],
        sources?: string[],
        companies?: string[],
        deal_stages?: string[],
        last_contact_date?: string
    }

    export interface Response {
        // stages: Array<{[index:string]:Array<IContactStages>}>;
        stages: any;
    }

    export interface IContactPipelineArray {
        index: any,
        stage: Array<IContactStages>
    }
    export interface IContactPipeline {
        pipeline: 10 | 20 | 30 | 40 | 50 | 60 | 70
    }


    export interface IContactStages {
        total: number,
        page: number,
        page_size: number,
        total_pages: number,
        next_page_url: string,
        prev_page_url: string,
        list: Array<IContactSearchItem>;
    }

    export interface IContactSearchItem {
        id: number,
        photo_url: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        company: string,
        score: string,
        pipeline_status: string,
        sources: string,
        city: string,
        deal_stages: Array<IDealStage>,
        tags: Array<ITag>,



    }

    export interface IDealStage {
        id: string;
        name: string;
        total_deals: number;
    }

    export interface ITag {
        id: number;
        name: string;
        contacts_count: number;
    }
}

export const fetchGetStagesService = forwardOperation(GetStagesApi.operations, 'fetch');
export const useFetchGetStagesService = forwardOperation(GetStagesApi.operations, 'useFetch');
export const useGetStagesService = forwardOperation(GetStagesApi.operations, 'useService');