import { useState } from "react";
import { useFetchGetNotesService } from "services/contacts/contactDetails/getNotes";
//import {INotesScreenProps} from "./NotesScreen.def"
import { useServiceDidSucceeded } from "scripts/hooks/useService";
import { useAddNotesService } from "services/contacts/contactDetails/addNotes";
import { ProminentButton, ShapelessButton } from "views/forms/buttons/Button";
import {  INoteList  } from '../../home/HomeScreen/HomeScreen.def';
import {  useParams } from 'react-router';
//import { formatHarDayDateTime } from 'scripts/helpers/dates';
import { usePopUp } from 'scripts/hooks/popups';
import { AddNotesPopUp } from 'views/contacts/popups/AddNotesPopUp';
import { useDeleteNotesService } from 'services/contacts/contactDetails/deleteNotes';
import { useUpdateNotesService } from 'services/contacts/contactDetails/updateNotes';
import { displayMessage } from 'scripts/hooks/feedbacks';

export const NotesScreenApp = (): JSX.Element => {

    const { id } = useParams<{ id: string }>();
    const [notes, ActionFetchNotes] = useFetchGetNotesService({ contact_id: id });
    const notesList=notes.response?.list;
    const [selectedNote, setSelectedNote] = useState<INoteList>();
    const [, ActionDeleteNote] = useDeleteNotesService();
    const [addNote, ActionSaveNote] = useAddNotesService();
    const [updateNote, ActionUpdateNote] = useUpdateNotesService();
    const [present, dismiss] = usePopUp(AddNotesPopUp, {
        selectedNote,
        onRemove: (index) => deleteNote(index),
        onSave: (note) => saveNote(note),
        onWillDismiss() { setSelectedNote(undefined); },
        isLoading:addNote.loading||updateNote.loading

    })

    const deleteNote = (value: INoteList) => {
        ActionDeleteNote.fetch({
            contact_id: id,
            notes_id: value.id
        }).then(()=>{
        ActionFetchNotes.fetch({contact_id: id})
        setSelectedNote(undefined);
        dismiss();
    })}
    useServiceDidSucceeded(updateNote, () => { displayMessage('Note updated successfully.', { onClose:onSuccess });onSuccess()})
    useServiceDidSucceeded(addNote, () => { displayMessage('Note added successfully.', { onClose:onSuccess });onSuccess()})

    const saveNote = async (value: string) => {
        if (!!!value) {
            return
        }
        if (selectedNote) {
          await ActionUpdateNote.fetch({
                contact_id: id,
                notes_id: selectedNote!.id,
                notes: value,
            })
        } 
      
        else if (!selectedNote) {
            await ActionSaveNote.fetch({
                contact_id: id,
                notes: value,
            })
        }
       
        dismiss();
        return true;
    }
    const editNote = (index: number) => {
        // setNote(true);
        present();
        setSelectedNote(notesList?.[index]!);
    }
    const onSuccess=()=>{
        setSelectedNote(undefined);
        ActionFetchNotes.fetch({contact_id: id})
    }
    const datePattern = (value: string) => {
        const newDate = value.split(' ')[0]!;
        const newTime = value.split(' ')[1]!;
        let date = new Date(Date.parse(newDate)).toDateString();
        const PatternedDate = `${date}   ${newTime}`;
        return PatternedDate;
    }
    const truncatedText=(text:string)=>{
        //   const lines=text.split(/\r\n|\r|\n/).length;
           var transformText='';
           text=text.trim()
           for(var a=1;a<=250;a++){
              const lines= text.substring(0,a).split(/\r\n|\r|\n/).length
               if(lines>3){transformText=text.substring(0,a);break;}else if(a==250){transformText=text.substring(0,250)}
           }
          return  text.length <250?text:<>{transformText}...<a className="color_0738CD cursor--hand">show more</a></>;
       }
    return (
       <div className="container">
         <div className="color_har_blue w-120px mb-2 ml-auto mt-2 mr-0 text-right">
                <ProminentButton onClick={present} className='btn--prominent__v4'><img className="align-self-center mr-2 w-20px" src="https://content.harstatic.com/media/icons/plus_blue_light.svg" alt='' />write notes</ProminentButton>
            </div>
            <div className="row" >
            {notesList?.map((note,index) => (
                <div className={'col-12 col-md-4 pt-4'} onClick={()=>{present();setSelectedNote(note)}} key={note.id}>
                     <div className="note mr-4"  >
                                <div className="note__date">
                                    {datePattern(note.created_dt)}
                                </div>
                                <div className="note__text h-130px">
                                    {truncatedText(note.notes)}
                                </div>
                                <div className="note__edit" >
                                    {/* <ShapelessRedButton className='note_delete' size='small' onClick={() => deleteNote(note)} clickConfirmation='Are you sure to delete this note?'>Delete</ShapelessRedButton> */}
                                    <a data-toggle="modal" data-target="#EditNotesModal" href='javascript:void(0)' onClick={() => editNote(index)}>Edit</a>
                                </div>
                            </div>
                </div>
                ))}
            
                <div className="col-12 pt-4">
                    <div className="color_har_blue note mr-4 cursor_pointer" onClick={present} >
                    <div className="note h-130px align-self-center justify-content-center"><ShapelessButton color={"color_har_blue"} className='mt-40px'>Write notes..</ShapelessButton></div>
                        <div className="note__edit h-30px" >
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )
    }