//import { useFetchGetRecommendedItemsService } from 'services/user/getRecommendedItems';
import { useFetchGetCalendarTasksService } from 'services/calendar/getCalendarTasks';
//import { AddTaskPopUp } from 'views/calendar/popups/AddTaskPopUp';
//import { RecommendedItemsCard } from '../RecommendedItemsCard'
import { TaskTimeline } from 'views/calendar/TaskTimeline';
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { IHomeScreenProps ,IHomeScreenState} from './HomeScreen.def';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import { Loading } from 'views/calendar/TaskTimeline/components/loading';
import { Empty } from '../RecommendedItemsCard/components/empty';
//import { useHistory } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './HomeScreen.scss';
import { NotesSection } from 'views/contacts/NotesSection';
//import { Empty } from '../RecommendedItemsCard/components/empty';
//import { Loading } from '../RecommendedItemsCard/states/loading';
import { EmailList } from 'views/emails/EmailList';
import { useFetchGetDashboardService } from 'services/user/getDashboard';
import { convertToInternationalCurrencySystem } from 'scripts/helpers/texts';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';
import { NewEmail } from 'views/emails/popups/NewEmail';
import { IonIcon } from '@ionic/react';
import { GetRecommendedItemsApi, useFetchGetRecommendedItemsService } from 'services/recommendedActionItems/getRecommendedItems';
import { RecommendedItemsCard } from '../RecommendedItemsCard';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { isLocal } from 'scripts/application/settings';
import { TaskFormApp } from 'views/calendar/popups/AddTaskPopUp/components/TaskFormApp';

export const HomeScreenApp = (props: IHomeScreenProps): JSX.Element => {
    // const history=useHistory();
    const { mailService, memberService, taskId } = props
    let history = useHistory<IHomeScreenState>()
    const { state = {} } = history.location;

    const [tasks, actionTasks] = useFetchGetCalendarTasksService({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })
    //const [recommendedItems] = useFetchGetRecommendedItemsService();
    const [showAddTask, toggleShowAddTask] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>();
    const [dashboard] = useFetchGetDashboardService()
    const [writeEmail, setWriteEmail] = useState(false);
    const [dealPop, setDealPop] = useState(false);
    const [category,setCategory]=useState("t");
    const [recommendedItems,getRecommendedItems] = useFetchGetRecommendedItemsService();
    const [recommendedItemsList,setRecommendedItemsList] = useState<Array<GetRecommendedItemsApi.IResponseList>>([])
    const [compact, _updateCompact] = useState(state.compact ?? 0);

    const [showMore,setShowMore]=useState(false)
    const [aboutText,setAboutText]=useState(true);

    const [cid, setCid] = useState("")
    //  const recommendedItemsList = recommendedItems.response?.list || []
    //let history = useHistory()
   
    const loadRecommendedItems=async(showMore:boolean=false)=>{
        await getRecommendedItems.fetch({page_size:showMore?(recommendedItems.response?.page_size??20)+20 : recommendedItems.response?.page_size??20});
     }
     useEffect(()=>{
        !recommendedItems.loading&&  setRecommendedItemsList(recommendedItems.response?.list??[]);
    },[recommendedItems.response?.list])
    useEffect(() => {
        if (memberService?.response && typeof memberService?.response?.provider_status !== 'undefined') {
            setConnectionStatus(memberService?.response?.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [memberService?.response]);
    const redirectNewTab=(link:string)=>{
        window.open(link);
    } 
    return (
        <div className="container">
            <div className="page_title_container">
            {(!props.contactId &&aboutText)&&<div>
                        <div className=' border_radius--default bg_color_pending_light p-4 mb-4 position-relative'>
                            <div><h3 tabIndex={0}>   About the App</h3></div>
                            <div onClick={()=>setAboutText(false)} className=' position-absolute top-10px right-10px zindex-1 cursor--hand'><img src="https://content.harstatic.com/media/icons/close_black.svg"></img></div>

                            <div className=' font_size--medium'>
                            Manage your contacts, tasks, and deals all in HAR’s CRM platform. This program helps ensure that prospecting, referral generation, and marketing campaigns happen when planned. Features, such as the Contact Pipeline and the Deals Dashboard 
                                
                                {showMore&&<span>, allow agents to easily view the current state of their business to plan their next steps.<br />
                                    <a className='font_weight--bold' onClick={()=>redirectNewTab("https://cms.har.com/har-crm")}>Learn more here</a><br /><br />
                                    Stay connected on the go with the <a onClick={()=>redirectNewTab("https://www.har.com/")}>HAR</a>  CRM mobile app. To download the app, <a className='font_weight--bold' onClick={()=>redirectNewTab("https://www.har.com/mobile/#CRMApp")}>click here</a>
                                    <br/>
                                </span>}
                                <br/>
                                <a onClick={()=>setShowMore(showMore?false:true)} className='font_weight--bold cursor--hand'>view {showMore?"less":"more"}</a>
                            </div>
                        </div>
                    
                    </div>}
                    {!props.contactId && memberService?.response?.emails?.total_unread_lead && memberService?.response?.emails?.total_unread_lead !== "0" &&
                    <div className={`bg_color_cement_light p-3 d-flex align-items-center border_radius--default mb-4 py-4 cursor--hand`} onClick={() => history.push('/emails/lead')}>
                        <div className="pr-2"><IonIcon name="people-circle-outline" className='lead--Icon'></IonIcon></div>
                        <div className="font_size--medium font_weight--bold color_auxiliary">You have  {memberService?.response?.emails?.total_unread_lead} unread email lead{Number(memberService?.response?.emails?.total_unread_lead) > 1 ? `s`:``}.</div>
                    </div>
                    }
                <div className="row">
                    <div className="col align-self-center">

                        {/* <button role="button" type="button" className="btn btn--prominent__v2 btn--large mb-0">
                                <img className="mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" /> Add
                            </button> */}

                    </div>
                   {props.contactId && <div className="w-100 align-items-baseline"> <NotesSection /></div>}
                </div>

                {isLocal&&!props.contactId && (dashboard.loading ? <LoadingState /> : <div className="row mb-2">
                    <div className="col-6 pb-md-0">
                        <div className="card--kpi h-100" onClick={() => history.push(`/emails/forwarded`)}>
                            <div className="card--kpi__left">
                                <div className="card--kpi__left_number">{dashboard.response?.leads.total}</div>
                                <div className="card--kpi__left_title">Leads</div>
                            </div>
                            <div className="card--kpi__right">
                                Stay up to date on your opportunities and leads (Active + in Contract):
                                <br />
                                <br />
                                Your have<strong> {dashboard.response?.leads.total_today}</strong> new leads today <br />
                                <strong> {dashboard.response?.leads.total_today}</strong> the number of new Leads, as up today
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div onClick={() => history.push(`/deals`)} className="card--kpi card--kpi__light h-100">
                            <div className="card--kpi__left">
                                <div className="card--kpi__left_number">{dashboard.response?.deals.total}</div>
                                <div className="card--kpi__left_title">Deals</div>
                            </div>
                            <div className="card--kpi__right">
                                <div className="pb-2">
                                    View opportunities / leads
                                    in your pipeline.
                                </div>
                                Total transactions amount:<br />
                                <strong>${convertToInternationalCurrencySystem(dashboard.response?.deals.amount)}</strong>
                            </div>
                        </div>
                    </div>
                </div>)}
                {!props.contactId && <Dropdown>
                    <Dropdown.Toggle className="btn--large w-100 mb-2 align-items-center" variant="-prominent__v2" >
                        <div className='w-100'><img className="align-self-center mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" />
                            <span>Add</span></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu dropdown-menu__icon" align={'right'}>
                        <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => {setCategory('t');toggleShowAddTask(true)}}>
                            <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></span>
                            <span className="dropdown-item__text">Task</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => {setCategory('e');toggleShowAddTask(true)}}>
                            <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></span>
                            <span className="dropdown-item__text">Event</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => setDealPop(true)}>
                            <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-deal.svg" /></span>
                            <span className="dropdown-item__text">Deal</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" as={Link} to="/contacts/create">
                            <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-contact.svg" /></span>
                            <span className="dropdown-item__text">Contact</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#">
                                        <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-service.svg" /></span>
                                        <span className="dropdown-item__text">Service</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#">
                                        <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-conversation-log.svg" /></span>
                                        <span className="dropdown-item__text">Conversation Log</span>
                                    </Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>}
            </div>

            <div className="row">
            
                {/* Start of First columns */}
                <div className="col-12 col-md-8">
                    <Tabs defaultActiveKey="Tasks" transition={false} id="MainTabs" className="nav nav-tabs nav-tabs--simple nav-tabs--fullwidth">
                        <Tab eventKey="Tasks" title="Tasks" className="animate animate__slideInUp">
                            <div className="pt-4">
                                <TaskTimeline 
                                    tasks={tasks.response || undefined}
                                    hideDateCol 
                                    refreshTasksList={() => actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })}
                                    taskId={taskId}
                                    returnUrl={props.contactId && props.isContactOverview ? `/contacts/${props.contactId}/overview` : '/home'}
                                    redirect={
                                        (id: string) => {
                                            history.push(props.contactId ? props.isContactOverview ? `/contacts/${props.contactId}/overview/task/${id}` : `/contacts/${props.contactId}/calendar/task/${id}` : '/home/task/' + id)
                                        }
                                    }
                                />
                            </div>
                            {!tasks.loading &&tasks.response?.page !== tasks.response?.total && <div className="notification_card_footer p-1 text-right custom_font">
                                <a href={"/calendar"} >{tasks.response?.list.length==0?"Go to Calendar":"View all tasks"} →</a></div>
                            }

                        </Tab>
                          <Tab eventKey="ActionItems" title="Action Items" className="animate animate__slideInUp">
                          {!recommendedItems.loading &&recommendedItemsList && recommendedItemsList?.length === 0 && <Empty />}
                                {recommendedItemsList.length > 0 &&
                                        <div className="pt-4 pb-4">
                                            <div className="pb-0">
                                                <div className="row">
                                                    {recommendedItemsList.map((item, key) =>
                                                        <RecommendedItemsCard data={item} compact={compact === 1} loadRecommendedItems={()=>loadRecommendedItems(false)}  showAddTask={toggleShowAddTask} setCategory={setCategory} showAddDeal={setDealPop} showWriteEmail={setWriteEmail} key={key} />
                                                    )}

                                                </div>
                                            </div>  
                                          {!recommendedItems.loading&&(recommendedItemsList.length!==recommendedItems.response?.total)&&<div className=' text-right'>
                                            <ShapelessButton color={"blue"} onClick={()=>loadRecommendedItems(true)} > View more results</ShapelessButton></div>}
                                          {recommendedItems.loading && <Loading />}
                                        </div>}
                        </Tab>
                        <Tab eventKey="Emails" title="Emails" className="animate animate__slideInUp">
                            {memberService?.response && typeof connectionStatus !== 'undefined' &&
                                <>
                                    <div className="row">
                                        <div className="action_bar__link border-bottom-none ml-auto pt-4 pr-4">
                                            <a className='cursor--hand font_size--small' onClick={() => {
                                                if (props.contactId) {
                                                    setCid(props.contactId);
                                                }
                                                setWriteEmail(true)
                                            }}>+New Email</a>
                                        </div>
                                    </div>

                                    <div className="pb-0 emails_list">
                                    <EmailList
                                        type='inbox'
                                        list={mailService?.response?.list}
                                        hideHeader={true}
                                        connectionStatus={connectionStatus}
                                        loading={mailService?.loading}
                                        redirect={(id: string) => {
                                            if (props.contactId) {
                                                history.push('/contacts/' + props.contactId + '/emails/' + id)
                                            } else {
                                                history.push('/emails/inbox/' + id)
                                            }
                                        }} />
                                   {!!mailService?.response&& <div className="email_card_footer mt-1 border-top-0">
                                        <a href={"/emails/inbox"} >{mailService?.response?.list.length==0?"Go to Emails":"View all emails"}</a>
                                    </div>}
                                        </div>
                                </>

                            }
                            {memberService?.response && typeof connectionStatus === 'undefined' &&
                                <div>
                                    <NoConnectionBox noBorder={true} />
                                </div>
                            }

                        </Tab>
                    </Tabs>
                </div>

            </div>
            <NewEmail
                show={writeEmail}
                provider_email={memberService?.response?.provider_email}
                onClose={() => {
                    setWriteEmail(false);
                    setCid("")
                }}
                contactId={cid}
                connectionStatus={connectionStatus}
            />
            {<TaskFormApp refreshData={() => actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })} show={showAddTask} toggle={toggleShowAddTask} category={category} />}

           {/**  <AddTaskPopUp show={showAddTask} refreshData={() => actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })} toggle={toggleShowAddTask} />*/}
            {dealPop&&<DealsAdd visible={dealPop} toggleShow={() => setDealPop(false)} dealsId={0} />}

        </div>
    );
}


const LoadingState = (): JSX.Element => {

    return (
        <div className="row mb-5">
            <div className="col-6 col-md-6 pb-4 pb-md-0">
                <div className="cards cards--tasks">
                    <div className="cards--tasks__content">
                        <div className="card--email__content_heading d-flex">
                            {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                            <div className="mr-2 loading__labels mr-2 w-50px"></div>
                            <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                        </div>
                        <div className="card--email__text text-truncate">
                            <div className="loading__text_small w-100"></div>
                        </div>
                        <div className="loading__text_smaller w-80px"></div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 pb-4 pb-md-0">
                <div className="cards cards--tasks">
                    <div className="cards--tasks__content">
                        <div className="card--email__content_heading d-flex">
                            {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                            <div className="mr-2 loading__labels mr-2 w-50px"></div>
                            <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                        </div>
                        <div className="card--email__text text-truncate">
                            <div className="loading__text_small w-100"></div>
                        </div>
                        <div className="loading__text_smaller w-80px"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
