import { fetchZipCodesService } from 'services/typeaheads/zipCodes';
import { Typeahead } from '../Typeahead';
import { IZipCodeTypeaheadProps } from './ZipCodeTypeahead.def';
import './ZipCodeTypeahead.scss';

export const ZipCodeTypeahead = (props: IZipCodeTypeaheadProps): JSX.Element => {
    const search = (value: string) => {
        return fetchZipCodesService({ input: value})
    }

    const mapResult = (item: number) => {
        return item.toString()
    }

    const getSelectedZCode = (value: number[]) => {
        props.onChange?.(value)
    }
    return (
        <div>
            <Typeahead.Multi label='Zip Code' placeholder='Zip Code' search={search} mapResult={mapResult} initialValue={props.initialValues} onChange={getSelectedZCode} />
        </div>
    );
}
