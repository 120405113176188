import { Slider } from '../Slider';
import { IWelcomeScreenProps } from './WelcomeScreen.def';
import './WelcomeScreen.scss';
import { GetStartedButton, PasswordPopUp, SkipButton } from './WelcomeScreen.common';
import { useOnboardingStage } from 'scripts/hooks/onboarding';
import { eOnboardingStage } from 'scripts/enums';

export const WelcomeScreenApp = (_props:IWelcomeScreenProps): JSX.Element =>
{
    const onboardingState = useOnboardingStage(eOnboardingStage.BasicInfo);
    if(onboardingState.error) { return <></>; }
    
    return (
        <div className=" h-100 d-flex slider_app">
            <div className="ml-auto mr-auto align-self-center max-w-100pr">        
                <Slider/>
                <div className="p-4 text-center mt-4 pt-4">
                    <GetStartedButton onboarding={onboardingState} className="btn--large w-100" />
                    <SkipButton onboarding={onboardingState} className='w-100'>Skip</SkipButton>
                </div>
            </div>
            <PasswordPopUp user={onboardingState.user} />
        </div>
    );
}
