import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace DeleteRecommendedAction
{
    export const url = "/user/recommended_items/:id";
    export const method = "DELETE";
    export const defaultError =  "Something went wrong in removing this recommended item.";
    export const operations = apiOperations(DeleteRecommendedAction);
    
    export const scope = new class GetRecommendedItemsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        id: string
    }

    export interface Response
    {
    }
    
}

export const fetchDeleteRecommendedItemsService = forwardOperation(DeleteRecommendedAction.operations, 'fetch');
export const useFetchDeleteRecommendedItemsService = forwardOperation(DeleteRecommendedAction.operations, 'useFetch');
export const useDeleteRecommendedItemsService = forwardOperation(DeleteRecommendedAction.operations, 'useService');