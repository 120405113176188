import { SERVICE_CLUSTER_TIMEOUT } from "scripts/application/settings";
import { callRequest } from "./serviceFetch";
import { IApi } from "types/apis";
import { lazy } from "scripts/utilities/decorators";

export interface IApiClusterProvider<TRequest, TResponse, TToken = TRequest, TValue = Partial<TResponse>>
{
    readonly timeout?:number;

    mapRequest(request:TRequest): readonly TToken[];
    buildRequest(tokens:TToken[]): TRequest;

    mapResponse(response:TResponse): Map<TToken, TValue>;
    buildResponse(list:[TToken, TValue][]): TResponse;
}

export type ApiClusterRequestHandler = () => void;
export type ApiClusterResponseHandler<TResponse> = (response:TResponse) => void;

export class ApiCluster<TRequest, TResponse, TToken = unknown, TValue = unknown>
{
    @lazy()
    private static get clusters(): Map<IApi<any, any, any, any, any, any>, ApiCluster<any, any>>
    {
        return new Map();
    }

    public static getCluster<TRequest, TResponse>(api:IApi<any, TRequest, any, TResponse, any, any>): ApiCluster<TRequest, TResponse>
    {
        let cluster = this.clusters.get(api);
        if(cluster) { return cluster; }
        if(!api.cluster) { throw new Error('API does not have cluster provider.'); }

        this.clusters.set(api, cluster = new ApiCluster(api, api.cluster));
        return cluster;
    }

    private _session:ApiClusterSession<TRequest, TResponse, TToken, TValue>;
    public readonly timeout:number;

    public get session() { return this._session; }

    public constructor(public readonly api:IApi<any, any, any, any, any, any>, public readonly service:IApiClusterProvider<TRequest, TResponse, TToken, TValue>)
    {
        this._session = this.createSession();
        this.timeout = this.service.timeout ?? SERVICE_CLUSTER_TIMEOUT;
    }

    private createSession()
    {
        this._session = new ApiClusterSession(this);
        const detach = this._session.onRequest(() => {
            detach();
            this.createSession();
        });

        return this._session;
    }
}

export class ApiClusterSession<TRequest, TResponse, TToken = unknown, TValue = unknown>
{
    private requests:Map<TRequest, ApiClusterResponseHandler<TResponse>>;
    private requestListeners:Set<ApiClusterRequestHandler>;
    private schedule?:number;
    private aborter?:AbortController;

    public constructor(public readonly cluster:ApiCluster<TRequest, TResponse, TToken, TValue>)
    {
        this.requests = new Map();
        this.requestListeners = new Set();
    }

    public add(request:TRequest, handler:ApiClusterResponseHandler<TResponse>): void
    {
        this.requests.set(request, handler);
        if(this.schedule) { clearTimeout(this.schedule); }
        this.schedule = setTimeout(() => this.request(), this.cluster.timeout);
    }

    public remove(request:TRequest): void
    {
        this.requests.delete(request);
        if(!this.requests.size) { this.clearSchedule(); }
    }

    public onRequest(handler:ApiClusterRequestHandler): () => void
    {
        this.requestListeners.add(handler);
        return () => this.requestListeners.delete(handler);
    }

    private async request()
    {
        // Freeze new requests
        this.aborter = new AbortController();
        this.requestListeners.forEach(x => x());
        Object.defineProperty(this, 'add', { value() { throw new Error('Session is not active'); } });

        // Starting request
        const requests = new Map(this.requests);
        const tokens = new Map<TRequest, readonly TToken[]>();
        const flatTokens = new Set<TToken>();
        for(let [request] of requests)
        {
            const values = this.cluster.service.mapRequest(request);
            tokens.set(request, values);
            values.forEach(token => flatTokens.add(token));
        }

        const list = Array.from(flatTokens);
        const request = this.cluster.service.buildRequest(list);

        // Requesting and distributing data
        const serverRequest = this.cluster.api.scope.request(request);
        const response = await callRequest<TRequest, TResponse>(this.cluster.api, serverRequest, this.aborter.signal);
        this.distribute(response, tokens);

        // Freeing from memory
        this.unload();
        Object.defineProperty(this, 'remove', { value() {} });
    }

    public distribute(response:TResponse, tokensMap:Map<TRequest, readonly TToken[]>): void
    {
        const values = this.cluster.service.mapResponse(response);
        for(let [request, tokens] of tokensMap)
        {
            const map = tokens.map(token => [token, values.get(token)!] as [TToken, TValue]);
            const response = this.cluster.service.buildResponse(map);
            const listener = this.requests.get(request);
            if(listener) { listener(response); }
        }
    }

    private unload()
    {
        this.requests.clear();
        this.requestListeners.clear();
        this.aborter = undefined;
        this.clearSchedule();
    }

    private clearSchedule()
    {
        if(!this.schedule) { return; }
        clearTimeout(this.schedule);
        this.schedule = undefined;
        if(this.aborter) { this.aborter.abort(); }
    }
}