import { IGlobalSearchScreenProps } from './GlobalSearchScreen.def';
import { useComponent } from "scripts/context";
import { GlobalSearchScreenApp } from './GlobalSearchScreen.app';
import { GlobalSearchScreenDesktop } from './GlobalSearchScreen.desktop';

export const GlobalSearchScreen = (props: IGlobalSearchScreenProps): JSX.Element =>
{
    const GlobalSearchScreenPlatform = useComponent(GlobalSearchScreenDesktop, GlobalSearchScreenApp);
    return (
        <GlobalSearchScreenPlatform {...props} />
    );
}
