import { isFunction } from "lodash-es";

type PromiseExecutor<T> = ConstructorParameters<typeof Promise<T>>[0];

type QueueGenerator<T> = AsyncGenerator<QueueGeneratorValue, T, any>;

type QueueGeneratorValue = AbortController | (() => any);

type PromiseLikeOrGenerator<T> = PromiseController.Like<T> | AsyncGenerator<QueueGeneratorValue, T>;

export interface IGeneratorContext
{
    step: PromiseControllerConstructor['step']
}

export namespace PromiseController
{
    export type Like<T> = PromiseController<T> | PromiseLike<T>;
}

export interface PromiseController<T> extends Promise<T>
{
    /**
     * Attaches callbacks for the resolution and/or rejection of the Promise.
     * @param onfulfilled The callback to execute when the Promise is resolved.
     * @param onrejected The callback to execute when the Promise is rejected.
     * @returns A Promise for the completion of which ever callback is executed.
     */
    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): PromiseController<TResult1 | TResult2>;

    /**
     * Attaches a callback for only the rejection of the Promise.
     * @param onrejected The callback to execute when the Promise is rejected.
     * @returns A Promise for the completion of the callback.
     */
    catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): PromiseController<T | TResult>;

    /**
     * Attaches a callback that is invoked when the Promise is settled (fulfilled or rejected). The
     * resolved value cannot be modified from the callback.
     * @param onfinally The callback to execute when the Promise is settled (fulfilled or rejected).
     * @returns A Promise for the completion of the callback.
     */
    finally(onfinally?: (() => void) | undefined | null): PromiseController<T>;

    /**
     * Aborts Promise
     */
    abort(): void;
}


export class PromiseControllerConstructor implements Pick<PromiseConstructor, Exclude<keyof PromiseConstructor, 'prototype' | Symbol>>
{
    public from<T>(generator:(this:IGeneratorContext) => QueueGenerator<T>): PromiseController<T>;
    public from<T>(generator:QueueGenerator<T>): PromiseController<T>;
    public from<T>(promise:PromiseController<T>): PromiseController<T>;
    public from<T>(promise:PromiseLike<T>, abort?:(() => void) | PromiseController<any> | AbortController): PromiseController<T>;
    public from<T>(promise:PromiseLike<T> | QueueGenerator<T> | (() => QueueGenerator<T>), abort?:(() => void) | PromiseController<any> | AbortController): PromiseController<T>
    {
        if('then' in promise) { return this.fromPromise(promise, abort); }
        return this.fromGenerator(promise);
    }

    /*public create<T>(executor:PromiseExecutorCleanUp<T>): PromiseController<T>;
    public create<T>(executor:PromiseExecutor<T>, abort?:() => void): PromiseController<T>;*/
    public create<T>(executor:PromiseExecutor<T>, abort?:() => void): PromiseController<T>
    {
        const promiseExecutor:PromiseExecutor<T> = (...args) => {
            const result = executor(...args);
            if(!abort && isFunction(result)) { abort = result; }
        };

        const promise = new Promise(promiseExecutor);
        return this.from(promise, abort);
    }

    public all<T>(values: Iterable<T | PromiseLike<T>>): PromiseController<Awaited<T>[]>;
    public all<T extends readonly unknown[] | []>(values: T): PromiseController<{ -readonly [P in keyof T]: Awaited<T[P]>; }>;
    public all<T>(values: T[])
    {
        return this.from(Promise.all(values));
    }
    
    public race<T>(values: Iterable<T | PromiseLike<T>>): PromiseController<Awaited<T>>;
    public race<T extends readonly unknown[] | []>(values: T): PromiseController<Awaited<T[number]>>;
    public race<T>(values:T[])
    {
        return this.from(Promise.race(values));
    }

    public reject<T = never>(reason?:T)
    {
        return this.from(Promise.reject(reason));
    }

    public resolve(): PromiseController<void>;
    public resolve<T>(value: T): PromiseController<Awaited<T>>;
    public resolve<T>(value: T | PromiseLike<T>): PromiseController<Awaited<T>>;
    public resolve<T>(value?:T)
    {
        return this.from(Promise.resolve(value));
    }

    public allSettled<T extends readonly unknown[] | []>(values: T): PromiseController<{ -readonly [P in keyof T]: PromiseSettledResult<Awaited<T[P]>>; }>;
    public allSettled<T>(values: Iterable<T | PromiseLike<T>>): PromiseController<PromiseSettledResult<Awaited<T>>[]>;
    public allSettled<T>(values:T[])
    {
        return this.from(Promise.allSettled(values));
    }

    public any<T extends readonly unknown[] | []>(values: T): PromiseController<Awaited<T[number]>>;
    public any<T>(values: Iterable<T | PromiseLike<T>>): PromiseController<Awaited<T>>;
    public any<T>(values:T[])
    {
        return this.from(Promise.any(values));
    }

    public step<T, P extends any[]>(generator:(...args:P) => AsyncGenerator<QueueGeneratorValue, T>, ...args:P): AsyncGenerator<QueueGeneratorValue, T, unknown>;
    public step<T>(iterable:AsyncGenerator<QueueGeneratorValue, T>): AsyncGenerator<QueueGeneratorValue, T, void>;
    public step<T, P extends any[]>(action:(...args:P) => PromiseController.Like<T>, ...args:P): AsyncGenerator<QueueGeneratorValue, T, unknown>;
    public step<T>(promise:PromiseController.Like<T>): AsyncGenerator<QueueGeneratorValue, T, void>;
    public step(action:() => any): AsyncGenerator<QueueGeneratorValue, void, void>;
    public async* step<T>(input:PromiseLikeOrGenerator<T> | ((...args:any[]) => PromiseLikeOrGenerator<T>) | (() => unknown), ...args:any[]): AsyncGenerator<QueueGeneratorValue, T | void, void>
    {
        // Prevent step to start if process is aborted
        yield () => { };

        // Handle functions
        if('call' in input)
        {
            //if(!args.length) { return yield input(); }
            input = input(...args) as PromiseLikeOrGenerator<T>;
        }

        // Generator
        if('next' in input) { return yield* input; }

        // Promises
        const promise = 'then' in input? input: undefined;
        const controller = 'abort' in input? input: undefined;
        yield () => controller?.abort();

        if(promise) { return await promise; }
    }

    public fromGenerator<T>(generator:QueueGenerator<T>): PromiseController<T>;
    public fromGenerator<T>(generator:(this:IGeneratorContext) => QueueGenerator<T>): PromiseController<T>;
    public fromGenerator<T>(generator:QueueGenerator<T> | ((this:IGeneratorContext) => QueueGenerator<T>)): PromiseController<T>;
    public fromGenerator<T>(generator:QueueGenerator<T> | ((this:IGeneratorContext) => QueueGenerator<T>)): PromiseController<T>
    {   
        let aborted = false;
        let _abort:(() => void) | undefined;
        const abort = () => {
            aborted = true;
            _abort?.();
        };
        
        return PromiseController.create<T>(async (resolve, reject) =>
        {
            const context = this.createGeneratorContext();
            try {
                const it = 'call' in generator? generator.call(context): generator;
                let step = await it.next();
                while (!aborted)
                {
                    if(step.done) { return resolve(step.value); }
                    const { value } = step;
                    if('call' in value) { _abort = value; }
                    else if('abort' in value) { _abort = () => value.abort(); }
                    step = await it.next();
                }

                throw new DOMException('AbortError');
            }
            catch(e) { return reject(e); }

        }, abort);
    }

    public createGeneratorContext(): IGeneratorContext
    {
        return { step:PromiseController.step };
    }

    private fromPromise<T>(promise:PromiseController.Like<T>, abort?:(() => void) | PromiseController<any> | AbortController): PromiseController<T>
    {
        if('abort' in promise) { return promise; }

        abort = this.extractAbort(abort);
        return new Proxy(promise, {
            get: (response: any, key): any => {
                if (key === 'abort') { return abort; }
                const property = response[key];
                if(!property || !property.call) { return response[key]; }
                return (...args: any[]) => this.from(response[key](...args), abort);
            },
            has: (response:any, key) => {
                if(key === 'abort') { return true; }
                return key in response;
            }
        });
    }

    private extractAbort(abort?:(() => void) | PromiseController<any> | AbortController): () => void
    {
        if(abort && 'call' in abort) { return abort; }
        if(abort && 'abort' in abort) { return () => abort.abort(); }
        return () => { };
    }
}

export const PromiseController = new PromiseControllerConstructor();