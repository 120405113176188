import { createElement, useEffect } from "react";
import { Route, Redirect, matchPath } from "react-router";
import { OnboardingRouteProps, RouteProps } from "types/react";
import { useOnboardingProperStage } from "scripts/hooks/onboarding";
import { LoaderScreen } from "views/screens/LoaderScreen";
import { useIsAuthenticated } from "scripts/hooks/auth";
import { Auth } from "scripts/security/AuthService";
import { useGetBasicInfoService } from "services/user/getBasicInfo";
import { eOnboardingStage } from "scripts/enums";
//import { onboardingMap } from "scripts/application/onboarding";
//import { Location } from 'history';
import { IncompleteOnboardingScreen } from "views/screens/errors/IncompleteOnboardingScreen";
//import { eOnboardingStage } from "scripts/enums";

function useInternalRoute(routeProps: RouteProps, actions?:Record<'onboarding', (stage:eOnboardingStage) => JSX.Element>) 
{
    const isAuthenticated = useIsAuthenticated();
    const [basicInfo, basicInfoActions] = useGetBasicInfoService({ renew:'1' });

    useEffect(() => {
        if(isAuthenticated === true) { basicInfoActions.fetch(); }
    }, [isAuthenticated]);

    function renderFn(props: any)
    {
        if(isAuthenticated === false) { return toLogin(); }

        const { onboarding } = actions ?? {};
        const isOnboarding = basicInfo.response && basicInfo.response.onboarding_stage !== eOnboardingStage.Complete;
        if(isOnboarding && onboarding) { return onboarding(basicInfo.response.onboarding_stage); }
        return renderComponent(routeProps, props, isAuthenticated === undefined || basicInfo.response === undefined);
    }

    //console.log(context.path);
    const { title, component, children, render, ...rest } = routeProps;
    return <Route {...rest} render={renderFn} />;
}


export function PrivateRoute(routeProps: RouteProps)
{
    //const location = useLocation();
    return useInternalRoute(routeProps,
    {
        onboarding()
        {
            // const { path } = onboardingMap[stage];
            // const url = path + '?nexturl=' + toUrl(location);
            // return <Redirect to={url} />;
            return <IncompleteOnboardingScreen {...routeProps} />;
        }
    });
}

export function MemberRoute(routeProps: RouteProps)
{
    return useInternalRoute(routeProps);
}


export function OnboardingRoute(routeProps: OnboardingRouteProps)
{
    const onboarding = useOnboardingProperStage(routeProps.step);

    function renderFn(props: any)
    {
        if(onboarding.isAuthenticated === false) { return toLogin(); }
        if(onboarding.redirectTo) { return <Redirect to={onboarding.redirectTo} />; }

        // Checking Account Connection
        // const { user } = onboarding;
        // const complete = eOnboardingStage.Complete;
        // if(user && user.onboarding_stage === complete && routeProps.step === complete && !!user.provider_email)
        // {
        //     const status = user.provider_status?.trim()?.toLowerCase();
        //     const notSyncronizing = status !== 'ok' && !!user.provider_sync_mode;
        //     if(notSyncronizing) { return <Redirect to="/settings/account" />; }
        // }

        return renderComponent(routeProps, props, onboarding.loading);
    }

    //console.log(context.path);
    const { title, component, children, render, ...rest } = routeProps;
    return <Route {...rest} render={renderFn} />;
}



export function PublicRoute(routeProps: RouteProps)
{
    function renderFn(props: any)
    {
        return renderComponent(routeProps, props, false);
    }

    //console.log(context.path);
    const { title, component, children, render, ...rest } = routeProps;
    return <Route {...rest} render={renderFn} />;
}


function renderComponent({ title, component, children, render }: RouteProps, props:any, loading:boolean)
{
    const matches = matchPath(props.location.pathname, props.match);
    if(matches && matches.isExact) { document.title = title; }

    if(loading === true) { return <LoaderScreen/>; }
    if(component) { return createElement(component, props); }
    else if(render) { return render(props); }
    else if(children) { return children; }
    return <></>;
}

function toLogin()
{
    Auth.captureLogoutMessage('No storage value (from routes)');
    const nextUrl = encodeURIComponent(window.location.href.substring(window.location.origin.length));
    return <Redirect to={`/login?nexturl=${nextUrl}`} />;
}

/*function toUrl(location:Location): string
{
    const { pathname, search } = location;
    if(!location.search) { return encodeURIComponent(pathname); }
    return encodeURIComponent(pathname + search);
}*/