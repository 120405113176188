
import { Directory } from '@capacitor/filesystem';
import { fetchDownloadDocumentService } from 'services/contacts/documents/downloadDocuments';
import { fetchOpenDocumentService } from 'services/contacts/documents/openDocument';
import { isPlatform } from '@ionic/react';
import { Browser } from '@capacitor/browser';
import { Http } from '@capacitor-community/http';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { captureException } from 'scripts/logging/error-report';

export async function downloadAttachment( file: any,attachmentName:string,extension:string,presignedUrl?:string) {

  var url = presignedUrl?presignedUrl:window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href =url;
  link.setAttribute("download", attachmentName.replaceAll("."+extension,"")+"."+extension)
  document.body.appendChild(link);
  link.click();
  link.remove();
  
}

  export const handleDownloadFile= async(name:string,docID:string,contactID:string,extension:string)=>{
    if(isPlatform("capacitor")){ 
     return handleDownloadFileCapacitor(name,docID,contactID,extension)}
    else{ 
      const getFile= await fetchDownloadDocumentService({document_id:docID,contact_id:contactID,no_redirect:"2",content_type:"blob"})
      downloadAttachment(getFile,name,extension);
      }
  };

   const handleDownloadFileCapacitor= async(name:string,docID:string,contactID:string,extension:string,retry:number=1,url?:{url:string})=>{
      const getUrl=url?? await fetchOpenDocumentService({document_id:docID,contact_id:contactID,no_redirect:"1"})
     //if(isPlatform("android")){await getAndroidPermission();}

       const options = { url: getUrl?.url, filePath: `${name}-${retry}.${extension}`, fileDirectory: Directory.ExternalStorage, method: 'GET', };
       
       try{
        const result=await Http.downloadFile(options);

        result.path? displayMessage('Document Download Successfully'):downloadAttachment(null,name,extension,getUrl.url);


     

        } catch (error) {
          if(retry<5){
            const retryNumber=retry+1;
            handleDownloadFileCapacitor(name,docID,contactID,extension,retryNumber,getUrl);
          }else{
          
            isPlatform("android")?downloadAttachment(null,name,extension,getUrl.url):displayMessage('Error downloading the file.');
          }
          captureException(error);

      }
    
    };

  export const handleOpenFile= async(_name:string,docID:string,contactID:string)=>{
    const getUrl= await fetchOpenDocumentService({document_id:docID,contact_id:contactID,no_redirect:"1"})
    isPlatform("capacitor")? openCapacitorSite(getUrl?.url): window.open(getUrl?.url,"_system");
     };

    

  const openCapacitorSite = async (link:string) => {
     
    isPlatform("capacitor")&&  await Browser.open({ url: link });
  };

  /*const getAndroidPermission=async()=>{
   try{
      await AndroidPermissions.checkPermission("WRITE_EXTERNAL_STORAGE").then((result)=>{
        !result.hasPermission&&AndroidPermissions.requestPermissions(['WRITE_EXTERNAL_STORAGE','MANAGE_EXTERNAL_STORAGE ']);
      }).catch(()=>{AndroidPermissions.requestPermissions(['WRITE_EXTERNAL_STORAGE','MANAGE_EXTERNAL_STORAGE']);
    });
    }catch(e){console.log("error permission",e)}
  }*/