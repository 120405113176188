import { IFileStackChildProps, IFileStackProps } from './FileStack.def';
import * as filestack from 'filestack-js';
import './FileStack.scss';
import { Button } from './../../buttons/Button';
import { clientId } from 'scripts/vendors/filestack';

export function FileStack(props: IFileStackProps): JSX.Element {
  const { text, onUploadDone, uploadIcon,customTypes, onMultiFilesUpload, onInvalidFileType, buttonStyles, ...fileStackProps } = props;
  const fileStack = filestack.init(clientId);

  var totalSize = 0
  //let member_number = '3314';

  const filestackOpener = () => {
    const options: filestack.PickerOptions = {
      ...fileStackProps,
      fromSources: ['local_file_system', 'googledrive', 'unsplash', 'facebook', 'instagram'],
      maxFiles: props.maxFiles ? props.maxFiles : 1,
      uploadInBackground: false,
      maxSize: props.maxSize ? props.maxSize : 5242880,
      
      storeTo: {
        location: 's3',
        path: 'crmapp_test/temp/',
        container: 'content.har.com',
        access: 'public',
        region: 'us-east-1',

      },
      acceptFn: (file, options) => {
        return options.mimeFromMagicBytes(file.originalFile).then((res:any) => { 
           if((customTypes && !customTypes.includes(res)) ||  (props.accept && !props.accept .includes(res)) ){
            onInvalidFileType?.(false)
            return Promise.reject('Cannot accept that file')
          } else {
            return Promise.resolve();
          }  
         
        });
       }
       ,
      onFileSelected: async (file) => {

        //console.log((Number(file?.size) + totalSize),props.maxSize)
        if (props.maxSize && (Number(file?.size) + totalSize) > props.maxSize) {
          throw new Error('Size are bigger than limit');
        }
        totalSize = Number(file?.size) + totalSize
        const time = new Date().getTime();
        file.filename = time + '_' + decodeURIComponent(file.filename);
        /*var toks = file.filename.match(/(\.\w*$)/);
                if (toks[0].toLowerCase() === '.jpeg') toks[0] = '.jpg'
                file.filename = member_number + String(toks[0]).toLowerCase(); //*/
      },
      onUploadDone: (res) => {
        const [file] = res.filesUploaded;

        totalSize = 0;
        if (!file || !file.key) {
          console.log(res)
          return console.error('upload not found.');
        }

        onMultiFilesUpload?.(res.filesUploaded)

        onUploadDone?.({
          file: file,
          filename: file.key,
          url: file.key,
          mimeType: file.mimetype,
        });
      }

    };

    fileStack.picker(options).open();
  
  };


  return (
    uploadIcon ? <img src={uploadIcon} className="cursor--hand w-24px" onClick={filestackOpener} onDrop={filestackOpener} /> :
      <Button kind={buttonStyles?.kind || 'ordinary'} size={buttonStyles?.size ?? "small"} onClick={filestackOpener} onDrop={filestackOpener}>
        {buttonStyles?.icon && <img className="mr-1 w-12px" src={buttonStyles.icon} />}{text}
      </Button>
  );
}

export namespace FileStack {
  export function Image(props: IFileStackChildProps) {
    return (
      <FileStack
      maxSize={11534336}
      customText={{"Select Files to Upload":"or Drag and Drop, Copy and Paste. Ensure photo is a PNG or JPG file, not exceeding 5MB, and originally stored at 320 x 320 pixels (displayed at 110 x 100 pixels)."}}
        {...props}
        accept={['image/heic', 'image/jpeg', 'image/png', 'image/gif']}
       // imageDim={[400, 400]}
        //imageMin={[400, 400]}
        imageMax={[400, 400]}
      />
    );
  }
  export function Csv(props: IFileStackChildProps) {
    return <FileStack maxSize={11534336} {...props} accept={['.csv']} customText={{"Select Files to Upload":" Max file size is 10MB. Accepted Format: CSV."}}
    /> }
    
  export function Document(props: IFileStackChildProps) {
    return <FileStack 
    customText={{"Select Files to Upload":"Select Files to Upload. Max file size is 10MB. Accepted formats: PNG, JPG, PDF, PPT, Doc, Xlsx, Keynote, TXT."}} buttonStyles={{kind:'prominent',size:"large",icon:"https://content.harstatic.com/media/icons/add_white_2.svg"}} {...props} maxSize={11534336} accept={['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.ms-powerpoint','application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-','application/vnd.apple.keynote','application/vnd.openxmlformats-officedocument.presentationml.presentation', 'image/jpeg', 'image/png','text/plain'
  ]} />;
  }
}
