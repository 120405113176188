import { IImportContactsPopUpProps, IImportContactsSuccessProps, IResponseData } from './ImportContactsPopUp.def';
import './ImportContactsPopUp.scss';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useResponsive, useResponsiveRender } from 'scripts/hooks/general';
import { useGetSampleImportService } from 'services/contacts/getSampleImport';
import { IFetchHookData, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { FileStack } from 'views/forms/files/FileStack';
import { displayError } from 'scripts/hooks/feedbacks';
import { GetBasicInfoApi, useFetchGetBasicInfoService } from 'services/user/getBasicInfo';
import { useHistory } from 'react-router';
import { close, cloudDownloadOutline } from 'ionicons/icons'
import { PrimaryButton } from 'views/forms/buttons/Button';
import { useIntiateImportService } from 'services/contacts/matrix/intiateImport';
import { ImportPhoneContactsPopUp } from '../ImportPhoneContactsPopUp';

export const ImportContactsPopUp = (props: IImportContactsPopUpProps): JSX.Element => {
  const { show, matrixStatusId, toggleShow, reload, onMatrixInit } = props
  const isResponsive = useResponsive()
  const [uploadedData, setUploadedData] = useState<Array<IResponseData>>();
  const [downloadSample, getDownloadSample] = useGetSampleImportService()
  const [basicInfo, intiateBasicInfoApi] = useFetchGetBasicInfoService();
  const [disabled, setDisabled] = useState(false)
  const [waiting, setWaiting] = useState(true)

  const download = () => {
    getDownloadSample.fetch()
  }

  useServiceDidSucceeded((downloadSample), (response) => {
    if (response.status === 'ok') {
      var link = document.createElement("a");
      link.href = response.data.file;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  })

  useServiceDidSucceeded(basicInfo, (result) => {
    if (result.matrix?.status?.toLocaleLowerCase() === 'queued' || result.matrix?.status?.toLocaleLowerCase() === 'in progress') {
      setDisabled(true)
    }
    if (result.csv?.in_queue === "1") {
      setDisabled(true)
    }
    if (basicInfo.response?.provider_status && basicInfo.response?.provider_status.toLowerCase() === 'ok' && !basicInfo.response.sync_provider_to_nylas) {
      setDisabled(true)
    }
    setWaiting(false)
  })

  const handleDismissModal = () => {
    setUploadedData(undefined);
    if (toggleShow) {
      toggleShow()
    }
    reload?.()
  };

  useEffect(() => {
    if (show) {
      intiateBasicInfoApi.fetch()
      setWaiting(true)
    }
  }, [show])

  return (
    <>
      {useResponsiveRender(<button role="button" onClick={() => toggleShow ? toggleShow() : ''} className="btn btn--white btn--white__slate btn--inlineicon"><img src="https://content.harstatic.com/media/icons/down_arrow_auxialary.svg" /><span>Import</span></button>
        , <></>)}
      <IonModal className={`${!isResponsive ? `custom--modal custom--modal__importcsv custom--modal__fullheight` : `bg-white`}`} isOpen={show} backdropDismiss={false} onIonModalDidDismiss={() => setDisabled(false)}>
        <IonHeader className="ion-padding-md ion-no-border border-bottom">
          <IonToolbar>
            <IonTitle className="modal-title">Import Contacts</IonTitle>
            {!isResponsive ?
              <IonButton color={"blue"} className="modal-closebtn" slot="end" fill="clear" onClick={handleDismissModal}>
                <IonIcon slot="icon-only" icon={close} color="dark"></IonIcon>
              </IonButton>
              :
              <IonButtons className="w-15pr" slot="start">
                <IonButton color={"blue"} className='d-flex align-content-center' onClick={handleDismissModal}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> <span className='pt-1px'>Back</span></IonButton>
              </IonButtons>
            }
            <IonButtons className="w-15pr" slot="end">
              <div className=' min-w-70px'></div>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="bg-white">
          <div className="ion-padding bg-white" style={{ minHeight: '100%' }}>
            {disabled &&
              <div className='alert-warning font_size--small p-3'>There is an import process currently in progress. Please wait for it to complete before initiating a new import. Thank you for your patience.</div>
            }
            <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-0">
              {!uploadedData ?
                <>
                  <IPhoneImport disabled={disabled} waiting={waiting} />
                  <CSVMappedOption disabled={disabled} waiting={waiting} />
                  <MatrixImport onDone={(id: string) => {
                    onMatrixInit?.(id)
                    handleDismissModal()
                  }} disabled={disabled} matrixStatusId={matrixStatusId} waiting={waiting} />

                  <ProviderImport basicInfo={basicInfo} disabled={disabled} waiting={waiting} />
                </>
                : (
                  <ResultforUpload data={uploadedData} dismissModal={handleDismissModal} reload={reload} download={download} isDownloading={downloadSample.loading} />
                )}
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};



const ResultforUpload = (props: IImportContactsSuccessProps) => {
  const { data, dismissModal, isDownloading, reload, download } = props;
  const [showAllErrors, setShowAllErrors] = useState(false)
  const [showAllSuccess, setShowAllSuccess] = useState(false)


  const back = () => {
    if (dismissModal) {
      dismissModal()
    }
    if (reload) {
      reload();
    }
  }
  return (
    <div className="pt-5 ml-auto mr-auto">
      <div className="text-center border-bottom pb-4 mb-4 pt-2 ml-auto mr-auto max-w-374">
        {data?.data.total_success != "0" &&
          <>
            <img className="mb-3 w-40px" src="https://content.harstatic.com/media/icons/checkmark_filled.svg" />
            <div className="font_size--large_extra font_weight--semi_bold mb-4">{data?.data.total_success} contacts imported successfully</div>
          </>
        }
        <IonButton className="btn btn--ordinary btn--large" onClick={back}>Back to Contacts
        </IonButton>
      </div>
      <div className='mx-auto p-4'>
        {data?.data.total_errors != "0" &&
          <div className="border-bottom pb-4 mb-4 pt-2">
            <div className="d-flex">
              <div className="align_self_center mr-2"><img className='w-16px' src="https://content.harstatic.com/media/icons/icon-warning.svg" /></div>
              <div className="color_sold align-self-center font_weight--semi_bold font_size--medium">{data?.data.total_errors} contacts couldn’t not be imported</div>
            </div>

            <div className="pt-3">
              {data?.data?.errors_list?.map((item: any, key: number) =>
                <div key={key} className={`message message--error ${key > 2 && !showAllErrors ? `d-none` : `d-block`} mb-1`}>{item?.row_number}.{item.email} failed {item.errors[0].message ? `(` + item.errors[0].message + `)` : ``}</div>
              )}
              {data?.data?.errors_list.length > 3 &&
                <a className="color_red font_weight--semi_bold d-block mt-3 cursor--hand" onClick={() => setShowAllErrors(!showAllErrors)} >{!showAllErrors ? `View all ${data?.data.total_errors} failed imports ↓` : `Show less`}</a>}
              {data.data.error_file &&
                <a className="color_red font_weight--semi_bold d-block mt-1 cursor--hand" onClick={() => {
                  var downloadFileLink = document.createElement("a");
                  downloadFileLink.href = data.data.error_file
                  document.body.appendChild(downloadFileLink);
                  downloadFileLink.click();
                  downloadFileLink.remove()
                }} >Click here to download all failed records</a>
              }
            </div>

          </div>
        }
        {data?.data.total_success != "0" &&
          <>
            <div className="d-flex">
              <div className="align_self_center mr-2"><img className=' w-16px' src="https://content.harstatic.com/media/icons/check_green_2.svg" /></div>
              <div className="color_available align-self-center font_weight--semi_bold font_size--medium">{data?.data.total_success} contacts imported successfully</div>
            </div>
            <div className="pt-3">
              {data?.data?.success_list?.map((item: any, key: number) =>
                <div key={key} className={`message message--success ${key > 2 && !showAllSuccess ? `d-none` : `d-block`} mb-1`}>{item?.row_number}.{item.email}  imports ↓</div>
              )}
              {data?.data?.success_list.length > 3 &&
                <a className="color_red font_weight--semi_bold d-block mt-3 cursor--hand" onClick={() => setShowAllSuccess(!showAllSuccess)}>{!showAllSuccess ? `View all ${data?.data.total_success} imports ↓` : `Show less`}</a>
              }
            </div>
          </>
        }
        <div className="pt-2">
          {!isDownloading &&
            <a className="cursor--hand" onClick={download} >
              <div className='d-flex justify-content-center'>
                <div className="pr-2 pt-2">
                  <IonIcon icon={cloudDownloadOutline} color="blue" size="medium" className='font_size--large'></IonIcon>
                </div>
                <div className="align-self-center">Download Example file</div>
              </div >
            </a >
          }
        </div >
      </div>
    </div >
  )
}

const MatrixImport = (props: { status?: boolean, onDone?: (id: string) => void, matrixStatusId?: string, disabled?: boolean, waiting?: boolean }) => {
  const [matrixImport, initiateMatrixImport] = useIntiateImportService();
  const doIntiateMatrixImport = () => {
    initiateMatrixImport.fetch({})
  }
  useServiceDidSucceeded(matrixImport, (result) => {
    if (result.success) {
      props.onDone?.(result.status_id ? result.status_id : '')
    }
  })

  return (
    <div className='card p-4 mb-4'>
      <div className='d-flex'>
        <div className='font_size--large font_weight--semi_bold pb-3'>Matrix Contacts</div>
      </div>

      <div>

        <PrimaryButton loading={matrixImport.loading || props.waiting} disabled={matrixImport.loading || props.disabled} onClick={doIntiateMatrixImport}>Import from Matrix</PrimaryButton>
      </div>

    </div>
  )
}

const CSVMappedOption = (props: { disabled?: boolean, waiting?: boolean }) => {
  const history = useHistory();
  const onUploadDone = (key: string) => {

    history.push(`/contacts/import/csv-mapping`, { file_key: key })

  }

  return (
    <div className='card p-4 mb-4'>
      <div className='d-flex'>
        <div className='font_size--large font_weight--semi_bold pb-3'>Direct CSV Upload</div>
      </div>
      <div>{props.disabled || props.waiting ?
        <PrimaryButton disabled={true} loading={props.waiting} size='medium'>Upload CSV</PrimaryButton>
        :

        <FileStack text="Upload CSV" buttonStyles={{ 'size': 'medium', 'kind': 'primary' }}
          onUploadDone={(file) => {
            onUploadDone(file.file.key)
          }}
          accept={!isPlatform('android') ? ["text/csv"] : undefined}
          customTypes={["text/csv"]}
          maxSize={10485760}

          onInvalidFileType={(isValid: boolean) => !isValid ? displayError(" Invalid File Type", "Please select the CSV format file in order to import contacts") : ''}
        />
      }
      </div>
    </div>
  )


}



const ProviderImport = (props: { basicInfo: IFetchHookData<GetBasicInfoApi.Response>, disabled?: boolean, waiting?: boolean }) => {
  const { basicInfo, disabled, waiting } = props;
  // Temporary hack to hide section when no action is visible
  const isProviderOk = basicInfo.response?.provider_status && basicInfo.response?.provider_status.toLowerCase() === 'ok';
  const providerDetail: any = {
    yahoo: {
      icon: 'https://content.harstatic.com/media/icons/yahoo_icon.svg',
      name: 'Yahoo'
    },
    gmail: {
      icon: 'https://content.harstatic.com/media/icons/crm/gmail-icon-2020.svg',
      name: 'Google'
    },
    eas: {
      icon: 'https://content.harstatic.com/media/icons/crm/logo-microsoft.svg',
      name: 'Microsoft'
    },
    icloud: {
      icon: 'https://content.harstatic.com/media/icons/social/icon-icloud.svg',
      name: 'ICloud'
    },
    custom: {
      icon: 'https://content.harstatic.com/media/icons/map_popup/mail-copy-3.svg',
      name: 'Custom'
    }

  }


  return (
    <div className='card p-4 mb-4'>
      <div className='row'>
        <div className='col'>
          <div className='d-flex '>
            <div className='font_size--large font_weight--semi_bold pb-3'>Email Provider Contacts</div>
          </div>
          <div className='d-md-flex row pl-4'>
            {(isProviderOk || waiting) ?
              <PrimaryButton disabled={disabled} loading={waiting} className="btn btn--primary btn--medium" onClick={() => window.location.href = '/contacts/import'}>Import</PrimaryButton>
              :
              <div className='font_size--small py-2'>
                Please connect or reconnect your email provider to bring contacts into the HAR CRM.
                <a href='/settings/account'> Connect now</a>
              </div>
            }

          </div>
        </div>
        {isProviderOk && typeof basicInfo.response?.provider_id === 'string' && providerDetail?.[basicInfo.response.provider_id.toLocaleLowerCase()] &&
          <div className='col-auto align-self-start'>
            <div className='text-center min-w-100px border_radius--default bg_color_cement_light pt-3 mt-4 pt-md-4 mt-md-3 pb-1'>
              <img src={providerDetail?.[basicInfo.response.provider_id.toLocaleLowerCase()].icon}></img>
              <div className='font_size--small color_auxiliary'>{providerDetail?.[basicInfo.response.provider_id.toLocaleLowerCase()].name}</div>
            </div>
          </div>
        }
      </div>
    </div >
  )
}



const IPhoneImport = (props: { disabled?: boolean, waiting?: boolean }) => {
  const isIos = isPlatform('ios') || isPlatform('ipad');

  const [warning, setWarning] = useState("")
  if (!isIos) { return <></>; }

  return (
    <div className='card p-4 mb-4'>
      <div className='row'>
        <div className='col-12 col-md'>
          <div className='d-flex '>
            <div className='font_size--large font_weight--semi_bold pb-3'>Import From Phone</div>
          </div>
          <div className='d-md-flex row px-4 '>
            {isIos &&
              <>
                <ImportPhoneContactsPopUp disabled={props.disabled} loading={props.waiting} onPermissionDenied={(message: string) => setWarning(message)} />
              </>
            }
          </div>
        </div>
        <div className='col-12 col-md-auto align-self-start'>

          {warning && <div ><span className="font_weight--bold">Permission Denied <br /></span>  {warning}</div>}

          <div className='text-center min-w-100px border_radius--default bg_color_cement_light p-4 d-none'>
            <img src='https://content.harstatic.com/media/icons/yahoo_icon.svg'></img>
            <div className='font_size--small color_auxiliary'>Yahoo</div>
          </div>
        </div>
      </div>
    </div>
  )
}

