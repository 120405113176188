// import { IonInputCustomEvent } from '@ionic/core';
import { IonLabel } from '@ionic/react';
import { isEqual, isNumber, isString } from 'lodash-es';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { joinWithSpace } from 'scripts/helpers/texts';
import { useEquality } from 'scripts/hooks/general';
import { IServiceFetchResponse } from 'scripts/http/serviceFetch';
import { DropDownOptions } from 'views/forms/dropdowns/DropDownOptions';
// import { DropDownOptions } from 'views/forms/dropdowns/DropDownOptions';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { IMultiTypeaheadProps, ISingleTypeaheadProps, ITypeaheadFooter, ITypeaheadProps } from './Typeahead.def';
import './Typeahead.scss';

interface IDataItem<T, TID extends string | number> { data: T; item: IDropDownOption<TID>; }

export function Typeahead<T, TID extends string | number>(props: ISingleTypeaheadProps<T, TID>): JSX.Element {
    const { initialValue, onChange, search: _1, mapResult, emptyTemplate, loadingTemplate, itemTemplate,
        className, disabled: userDisabled,footer, label,optionsStyle,optionsClassName, ...ionicProps } = props;
    const [value, setValue] = useState(props.initialValue);
    const [searchResults, search] = useSearch(props);
    const [hasFocus, handleFocus, handleBlur] = useFocusTimer();
    const showTypeahead = hasFocus && !value && (searchResults.loading || !!searchResults.results);
    const textboxRef = useRef<HTMLInputElement>(null);
    const items = searchResults.results?.map(x => x.item);

    const currentValue = isEqual(initialValue, value)? value: initialValue;
    useEffect(() => setValue(currentValue), [currentValue]);

    /** Normalizing footer */
    const footerProps = !!footer && 'props' in footer? { template:footer, showResults:true } as ITypeaheadFooter: footer;

    const loadResultValue = (item: T): string => {
        const result = mapResult(item);
        return normalizeResult(result).text;
    };

    function addValue(input: IDropDownOption<TID>) {
        const value = findData(searchResults.results, input);
        if (!value) { return; }

        setValue(value);
        if (textboxRef.current) { textboxRef.current.value = loadResultValue(value); }
        onChange?.(value);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const { value: inputValue } = e.currentTarget;
        if (!inputValue) { return; }
        if (value && inputValue === loadResultValue(value)) { return; }
        setValue(undefined);
        if (inputValue.trim()) { search(inputValue, []); }
    }

    return (
        <>
            {label && <IonLabel>{label}</IonLabel>}
            <input
                {...ionicProps}
                ref={textboxRef}
                className={joinWithSpace('form-control', className)}
                type="text"
                onChange={e => handleChange(e)}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()} 
                />
            {showTypeahead && (
                <DropDownOptions footer={footer} styles={optionsStyle} className={optionsClassName} source={items} onSelect={(item) => addValue(item)} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} loadingTemplate={loadingTemplate} noAvatar={props.noAvatar} />
                //searchResults.results ? <Results {...props} results={searchResults.results!} onSelect={(item) => addValue(item)} />: <Loading />
            )}

            {!showTypeahead && footerProps?.showNoSearch && footerProps.template && hasFocus &&
                <div className={joinWithSpace('typeahead', optionsClassName)}>{footerProps.template}</div>
            }
        </>
    );
}

export namespace Typeahead {
    export function Multi<T, TID extends string | number>(props: IMultiTypeaheadProps<T, TID>): JSX.Element {
        const { initialValue: userInitialValue, onChange, search: _1, mapResult, mapResultsList, maxValues,
            emptyTemplate, loadingTemplate, itemTemplate,
            className, disabled: userDisabled, label, fullWidth,hideId, hideSelectedItem, ...ionicProps } = props;

        const [values, setValues] = useState<T[]>([]);
        const [hasFocus, handleFocus, handleBlur] = useFocusTimer();
        const [searchResults, search] = useSearch(props);
        const [isEmptyBackspaceLast, setEmptyBackspaceLast] = useState(false);
        const textboxRef = useRef<HTMLInputElement>(null);
        const initialValue = useEquality(userInitialValue);


        // const disabled = userDisabled || (maxValues ? values.length >= maxValues : false);
        const disabled = userDisabled;
        const showTypeahead = hasFocus && (searchResults.loading || !!searchResults.results);
        const dataItems = zipResult(values, mapResult);
        const source = searchResults.results?.map(x => x.item);
        useEffect(
            () => {!hideSelectedItem&&setValues(initialValue?.slice(0, maxValues) ?? [])},
            [initialValue, maxValues]
        );

       //useEffect(()=>{initialValue&&setValues(initialValue)},[initialValue])
        
        function addValue(input: IDropDownOption<TID>) {
            if (maxValues && values.length >= maxValues) { return; }

            const value = findData(searchResults.results, input);
            if (!value) { return; }  
            
            if(hideSelectedItem) {onChange?.([...values,value])}
            else{
                values.push(value);
              setValues([...values]);
              onChange?.(values);
            }
            search('', dataItems); // hide typeahead
            if (textboxRef.current) { textboxRef.current.value = ''; }
            textboxRef.current?.focus();
        }

        function removeLastValue() {
            values.pop();
            setValues([...values]);
            onChange?.(values);
        }

        const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            if (!value && e.code === 'Backspace') {
                if (isEmptyBackspaceLast) { removeLastValue(); }
                setEmptyBackspaceLast(!isEmptyBackspaceLast);
            }
        }

        const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;

            if (!isString(value)) { return; }
            if (value.trim()) { search(value, dataItems); }
        }

        const removeValue = (value: T) => {
            values.splice(values.indexOf(value), 1);
            const newValues = [...values];
            setValues(newValues);
            props.onChange?.(values);
        }

        return (
            <>
                {label && <IonLabel>{label}</IonLabel>}
                <div className={`tagsinput mb-2 ${fullWidth && `d-block`}`} id='container'>
                    <div className="tagsinput__item">
                        {!hideSelectedItem && dataItems.map(({ data, item }) => {
                            return (
                                <div className="filterpill filterpill--closeable2" id={`${item.id}`} key={item.id}>
                                    <div className="text">{item.text.replaceAll("(Add New Tag)","")}</div>
                                    <a className="" onClick={() => removeValue(data)}></a>
                                </div>
                            )
                        })}
                        <div className='tagsinput__input' >
                            <input
                                {...ionicProps}
                                type="text"
                                disabled={disabled}
                                className={joinWithSpace('form-control', className)}
                                ref={textboxRef}
                                //value={textboxRef.current?.value ?? ''}
                                autoFocus
                                required={true}
                                onKeyDown={keyDownHandler}
                                onKeyUp={keyUpHandler}
                                onFocus={() => handleFocus()}
                                onBlur={() => handleBlur()}
                            />
                        </div>

                    </div>
                </div>
                {showTypeahead && (
                    <DropDownOptions 
                    source={source} hideId={hideId} onSelect={(item) => addValue(item)} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} noAvatar={props.noAvatar} loadingTemplate={loadingTemplate} />
                    //searchResults.results ? <Results {...props} results={searchResults.results!} onSelect={(item) => addValue(item)} />: <Loading />
                )}
            </>
        );
    }
}

export function useFocusTimer(): [boolean, () => void, () => void] {
    const [hasFocus, setHasFocus] = useState(false);
    const blurTimer = useRef<number>();

    const handleFocus = (state: boolean) => {
        if (blurTimer.current) { clearTimeout(blurTimer.current); }
        blurTimer.current = undefined;

        if (state) { setHasFocus(true); }
        else {
            blurTimer.current = window.setTimeout(() => setHasFocus(false), 1000);
        }
    }

    return [hasFocus, () => handleFocus(true), () => handleFocus(false)];
}

function useSearch<T, TID extends string | number>(props: ITypeaheadProps<T, TID>): [typeof data, typeof search] {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<{ data: T, item: IDropDownOption<TID> }[] | undefined>(undefined);
    const [currentValue, setValue] = useState<string>();
    const [currentTimer, setTimer] = useState<number>();
    const currentRequestRef = useRef<IServiceFetchResponse<T[]> | Promise<T[]> | undefined>(undefined);
    const currentRequest = currentRequestRef.current;
    

    async function scheduleSearch(value: string, current: IDataItem<T, TID>[]) {
        if (currentValue && currentValue === value) { return; }
        const minLength = (props.minLength || props.minLength === 0) ? props.minLength : 2
        const isValid = value.length > minLength;

        if (currentRequest && 'abort' in currentRequest) { currentRequest.abort(); }
        if (currentTimer) { window.clearTimeout(currentTimer) };

        currentRequestRef.current = undefined;
        setTimer(undefined);
        setResults(undefined);
        setValue(isValid ? value : undefined);
        setLoading(isValid);
        if (!isValid) { return; }

        const timer = window.setTimeout(() => search(value, current), 1000);
        setTimer(timer);
    }

    async function search(value: string, current: IDataItem<T, TID>[]) {
        setTimer(undefined);

        const process = props.search(value);
        currentRequestRef.current = process && 'then' in process ? process : undefined;
        const initialResponse = await process;
        const response = props.mapResultsList?.(value, initialResponse) ?? initialResponse;
        if (process !== currentRequestRef.current) { return; }

        const currentIds = current.map(x => x.item.id);
        const dataItems = zipResult(response, props.mapResult).filter(x => !currentIds.includes(x.item.id)|| x.item.id=='');

        setResults(dataItems);
        setLoading(false);
        currentRequestRef.current = undefined;
    }

    const data = { loading, results } as const;
    return [data, scheduleSearch];
}

function findData<T, TID extends string | number>(dataItems: IDataItem<T, TID>[] | undefined, item: IDropDownOption<TID>): T | undefined {
    if (!dataItems) { return undefined; }
    const dataItem = dataItems.find(x => x.item === item);
    return dataItem?.data;
}

function zipResult<T, TID extends string | number>(result: T[], mapResult: (item: T) => IDropDownOption<TID> | string): IDataItem<T, TID>[] {
    return result.map(data => {
        const item = mapResult(data);
        return { data, item: normalizeResult(item) }
    });
}

function normalizeResult<TID extends string | number>(item: IDropDownOption<TID> | string): IDropDownOption<TID> {
    if (isString(item) || isNumber(item)) { return { id: item as TID, text: item.toString() }; }
    return item;
}