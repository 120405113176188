import { Fragment, useEffect, useState } from 'react';
//import { Textbox } from 'views/forms/textboxes/Textbox';
import { ILinkItem, ILinksSectionProps } from './LinksSection.def';
import './LinksSection.scss';
import { useParams } from 'react-router';
import { useAddLinkService } from 'services/contacts/contactDetails/addLink';
//import { useUpdateLinkService } from 'services/contacts/contactDetails/updateLink';
import { useDeleteLinkService } from 'services/contacts/contactDetails/deleteLink';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';

export const LinksSectionDesktop = (props: ILinksSectionProps): JSX.Element => {

    const { id } = useParams<{ id: string }>();
    const { initialLinks, maxLink, onLinkChange } = props;
    const maximumLink = maxLink ?? 10;
    // maxLink = maxLink ?? maximumLink;
    const [linkData, setLinkData] = useState<Array<ILinkItem>>([]);
    /**
   * To edit an link, set the link index
   * To add a new link, set the next index available (for example, for an array with 2 items, the next available index is "2")
   */
    const [selectedLink, setSelectedLink] = useState<number | undefined>(linkData.length === 0 ? 0 : undefined);
    const [, ActionDeleteLink] = useDeleteLinkService();
    const [addLinkData, _ActionAddLink] = useAddLinkService();

    useServiceDidSucceeded(addLinkData, (response) => {
        linkData[selectedLink!]!.id = response.id.toString();
        setLinkData([...linkData]);
        setSelectedLink(undefined);
        onLinkChange?.(linkData);
    })

    useEffect(() => {
        setLinkData(initialLinks && maximumLink && initialLinks.length > maximumLink ? initialLinks.splice(0, maximumLink) : initialLinks ? initialLinks : []);
        if (initialLinks?.length! > 0 || id!) { setSelectedLink(undefined) }
    }, [initialLinks, id, maximumLink])

    const edit = (index: number) => setSelectedLink(index);
    const add = () => setSelectedLink(linkData?.length);
    const remove = (index: number) => {
        const linkId = linkData[index]?.id?.toString();
        if (id !== undefined && Object.keys(id).length > 1) {
            ActionDeleteLink.fetch({
                contact_id: id!,
                link_id: linkId!
            })
        }
        linkData.splice(index, 1);
        setSelectedLink(linkData.length === 0 ? 0 : undefined);
        setLinkData([...linkData]);
    };

   /* const save = (address: ILinkItem,buttonEvent:string) => {
      
        if (selectedLink === linkData.length && linkData.length >= maximumLink) { return; }

        if (selectedLink !== undefined) { linkData[selectedLink] = address;} // Updating address
        else { linkData.push(address); 
        } // Adding new address

        if ((!!id&&Object.keys(id).length > 0) && buttonEvent === 'Add') {
            ActionAddLink.fetch({
                contact_id: id,
                name: address.label,
                url: address.url,
            })
        }

        if (!!!id || buttonEvent === 'Save') {
            setLinkData([...linkData]);
            setSelectedLink(undefined);
            onLinkChange?.(linkData);
       }
     
    };*/
    const isLinkList = selectedLink === undefined;
    return <ListView isOpen={isLinkList} list={linkData} onRemove={remove} onEdit={(index) => edit(index)} onAdd={add} maximumLink={maximumLink} {...props} />

  /*  return isLinkList ?
        :
        <FormView selectedLink={linkData[selectedLink]} linkData={linkData} onRemove={() => remove(selectedLink)} onCancel={() => setSelectedLink(undefined)} onSave={save} {...props} />
*/}

/*const FormView = (props: ILinksSectionProps.FormViewProps): JSX.Element => {
    const { initialLinks, selectedLink } = props;
    const [linkField, setLinkField] = useState({ value: '', error: '' });
    const [urlField, setUrlField] = useState({ value: '', error: '' });
    const { id } = useParams<{ id: string }>();
    const [, ActionUpdateLink] = useUpdateLinkService();
    const [_, setSelectedLink] = useState<number | undefined>(initialLinks?.length === 0 ? 0 : undefined);
    let addressId: string;

    useEffect(() => {
        const item = props.selectedLink;
        setLinkField({ value: item?.label ?? '', error: '' });
        setUrlField({ value: item?.url ?? '', error: '' });
    }, [props.selectedLink]);

    addressId = props.selectedLink?.id!

    const deleteAddress = () => props.onRemove();

    const addLink = (e: any) => {
        const buttonEvent = e.target.value;

        if (linkField.value.trim().length > 1 && urlField.value.trim().length > 1) {
            if (urlField.value.match(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
            )) {
                props.onSave({
                    label: linkField.value,
                    url: urlField.value,
                   //  id:props.selectedLink?.id
                }, buttonEvent)
            } else {
                setUrlField({ value: urlField.value.trim(), error: 'URLs can be with https://, and could be without it (HAR.com).' });
            }

        } else {
            setLinkField({ value: linkField.value ?? '', error: linkField.value.length === 1 ? 'This field should contain min 2 max 15 characters' : linkField.value.length === 0 ? 'This field is required' : '' })
            setUrlField({ value: urlField.value ?? '', error: urlField.value.length === 1 ? 'This field should contain min 2 max 100 symbols' : urlField.value.length === 0 ? 'This field is required' : '' })

        }

        if (id === undefined || id === null) { return };

        if (Object.keys(id).length > 0 && buttonEvent === 'Save') {
            if ((selectedLink?.url !== urlField.value) || (selectedLink?.label !== linkField.value)) {
                ActionUpdateLink.fetch({
                    contact_id: id,
                    name: linkField.value,
                    url: urlField.value,
                    link_id: addressId
                })
            }
        }

    }
    return (
        <>

            <div className="card p-3 mb-5" >
                <div className="p-3">
                    <h3 tabIndex={0}>Links</h3>
                    {
                         props?.linkData!?.length > 0 ?
                            <ListView selectedLink={props.selectedLink} list={props?.linkData!} onRemove={deleteAddress} onEdit={(index) => setSelectedLink(index)} onAdd={() => addLink} maximumLink={props?.maxLink!} />
                            : null
                    }
                    <Textbox label={`What's the Link?`} containerClassName="mb-4" placeholder='e.g Facebook profile' value={linkField.value} maxlength={40} error={linkField.error !== '' ? linkField.error : false} onIonChange={(e) => setLinkField({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' })} />
                    <Textbox label='URL' value={urlField.value} containerClassName=" mb-4" placeholder='https://www...' maxlength={100} error={urlField.error !== '' ? urlField.error : false} onIonChange={(e) => setUrlField({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' })} />

                    {!props.selectedLink ?
                        <div className="text-right">
                            <button type="button" className="btn btn--shapeless mr-4" onClick={props.onCancel}>Cancel</button>
                            <button value='Add' className="btn btn--primary" onClick={addLink}>Save</button>
                        </div>
                        :
                        <div className="address__row__links text-right">

                            <Button kind='shapeless_red' size='medium' className='shapeless_red mr-3' onClick={deleteAddress} clickConfirmation='Are you sure to delete link?'>Delete this Link</Button>
                            <Button kind='shapeless' size='medium' className="btn btn--shapeless mr-4" onClick={props.onCancel}>Cancel</Button>
                            <button value='Save' className="btn btn--primary btn--medium" onClick={addLink}>Save</button>

                        </div>
                    }
                </div>
            </div>
        </>
    )
}*/

const ListView = (props: ILinksSectionProps.ListViewProps): JSX.Element => {

   /* const add = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onAdd();
    };
*/
    return (
        <>
            {
                props.loading ?
                    <div className="card mb-5 p-3">
                        <div className="p-3">
                            <h3 tabIndex={0}>Links</h3>

                            <label htmlFor="WhatLink">What’s the Link?</label>
                            <div className="loading__textbox mb-4"></div>

                            <label htmlFor="URL">URL</label>
                            <div className="loading__textbox mb-3"></div>

                            <div className="text-right">
                                <button className="btn btn--shapeless mr-4" disabled>Cancel</button>
                                <button className="btn btn--primary" disabled>Add</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={ "card p-0 mb-5"}>
                        <div className={ "p-3"}>
                                <div className="pl-3 pr-3 pt-3">
                                    <h3 tabIndex={0}>Links</h3>
                                </div>
                            {props.list.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        {props.selectedLink !== item &&
                                            <div className={!props.isOpen ? "pl-0 address__row" : "address__row"}>
                                                <div className="address__row__desc overflow-hidden font_size--medium">
                                                    <div className="">{item.label}</div>
                                                    <div className=" overflow-ellipsis">
                                                        <a href={item.url} target='_blank' rel="noreferrer">{item.url}</a>
                                                    </div>
                                                </div>
                                                {/* {props.isOpen &&
                                                    <div className="address__row__links pl-3">
                                                        <div className="d-flex">
                                                           {<button type="button" className="btn btn--ordinary btn--small mr-3" value='Save' onClick={() => props.onEdit(index)}>Edit</button>}
                                                            <OrdinaryButton className='m-0' size='small' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete link?'>Remove</OrdinaryButton>
                                                        </div>
                                                    </div>} */}
                                            </div>
                                        }

                                    </Fragment>
                                )
                            })}
                        </div>
                        {/*props.list.length < props.maximumLink &&
                            <div className="p-3 border-top">
                                <a href="#" className="font_size--medium font_weight--semi_bold text-decoration-none pl-3" onClick={add}>+ Add Links</a>
                        </div>*/}

                    </div>
            }
        </>

    )
}