
import { DealApiKey, IDealsAddProps, validateMessage, validateType } from './DealsAdd.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { DealsAddApp } from './DealsAdd.app';
import { DealsAddDesktop } from './DealsAdd.desktop';
import { AddDealApi, useAddDealService } from 'services/deals/addDeal';
import { useEffect, useState } from 'react';
import { GetDealApi, useGetDealService } from 'services/deals/getDeal';
import { useFetchLookupsService } from 'services/general/lookups';
import { ListingsApi } from 'services/typeaheads/listings';
import { GetDealsPipelineApi } from 'services/deals/getDealsPipeline';
import { useUpdateDealService } from 'services/deals/updateDeal';
import { parseISO, format } from 'date-fns';
import { useGetContactService } from 'services/contacts/contactDetails/getContact';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { validateField } from 'scripts/hooks/validateForm';
import { displayMessage } from 'scripts/hooks/feedbacks';


export const DealsAdd = (props: IDealsAddProps): JSX.Element => {
    const { visible, dealsId, type, toggleShow, reload, doRedirect } = props
    const [getDeal, getDealAction] = useGetDealService()
    const [, setStage] = useState("")
    const [loading, setLoading] = useState(false)
    const [addDeal, addDealAction] = useAddDealService()
    const [editDeal, editDealAction] = useUpdateDealService()
    const [contactSelected, setContactSelected] = useState(false)
    const [contactSearch, contactSearchAction] = useGetContactService()
    const [contact, setContact] = useState<GetDealsPipelineApi.IDealContact[]>([])
    const [listing, setListing] = useState<ListingsApi.IListingsList[]>([])
    const [lookupData] = useFetchLookupsService(['deal_stage', 'deal_type', 'deal_priority']);
    const [validation, setValidation] = useState<validateMessage[]>()
    const [requiredFields,] = useState<validateType[]>([
        { "key": "title", "validate": "min:2".split("|"), "name": "Title" },
        { "key": "deal_type", "validate": "req".split("|"), "name": "Deal Type" },
        { "key": "deal_stage", "validate": "req".split("|"), "name": "Deal Stage" },
        { "key": "property_full_street_address", "validate": "req".split("|"), "name": "Property Address" }
    ])
    const [deals, setDeal] = useState<AddDealApi.Request>({
        title: "",
        contact_id: props.contactId ? props.contactId : "",
        deal_type: "",
        deal_stage: "",
        deal_priority: "",
        property_full_street_address: "",
        property_address2: "",
        property_price: "",
        property_harid: "",
        property_listingid: "",
        property_for: "",
        deal_amount: "",
        estimated_deadline: "",
        notes: ""
    });


    useServiceDidSucceeded(contactSearch, (response) => {
        setContactSelected(true)
        let contact: GetDealsPipelineApi.IDealContact = {
            id: parseInt(response.id),
            first_name: response.first_name,
            last_name: response.last_name,
            middle_name: response.middle_name,
            city: response.city ? response.city : '',
            photo_url: response.photo_url,
            primary_email: response.primary_email ? response.primary_email : '',
            score: response.score,
            deleted:response.deleted
        }
        updateDeal('contact_id', response.id)
        saveContact(contact)
    })



    async function saveDeal(id?: number) {
        let valid = await validate()
        if (valid) {
            setLoading(true)
            setStage(deals?.deal_stage)
            if (id) {
                editDealAction.fetch({ deal_id: id.toString(), ...deals })
            } else {
                addDealAction.fetch(deals)
            }
            //reload?.()
        }

    }
    function updateDeal<K extends keyof AddDealApi.Request>(key: K, value: AddDealApi.Request[K]) {
        setDeal({ ...deals, [key]: value })

    }

    function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
        return obj[key];
    }

    async function validate(key?: DealApiKey): Promise<boolean> {
        let validMessage: validateMessage[] = []
        if (key) {
            if (deals && getProperty(deals, key) === "") {
                //  validMessage.push({ name: key, messages: "is a required field" })
            }
        } else {
            requiredFields.map((field: validateType) => {
                let message = validateField(field.key, getProperty(deals, field.key), field.validate, field.name ? field.name : '')
                if (message) {
                    validMessage.push({ key: field.key, messages: message })
                }
            })
        }
        if (validMessage.length > 0) {
            setValidation(validMessage)
            return false
        } else {
            return true
        }
    };


    function getError(type: DealApiKey): string {
        let field = validation?.filter((vd) => vd.key === type)[0]
        if (field && field?.messages) {
            return field?.messages
        } else {
            return ""
        }
    }

    function mapDeal(result: GetDealApi.Response) {
        if (result.status === 'ok') {
            setDeal({
                title: result.data.title,
                contact_id: result.data.contacts[0] ? result.data.contacts[0].id.toString() : "0",
                deal_type: result.data.deal_type,
                deal_type_text: result.data.deal_type_text,
                deal_stage: result.data.deal_stage,
                deal_stage_text: result.data.deal_stage_text,
                deal_priority: result.data.deal_priority,
                property_full_street_address: result.data.property_full_street_address,
                property_address2: result.data.property_address2,
                property_price: result.data.property_price,
                property_city: result.data.property_city,
                property_country: result.data.property_country,
                property_harid: result.data.property_harid,
                property_listingid: result.data.property_listingid,
                property_for: result.data.property_for,
                deal_amount: result.data.deal_amount.toString(),
                estimated_deadline: result.data.estimated_deadline ? format(parseISO(result.data.estimated_deadline), 'yyyy-MM-dd') : '',
                notes: result.data.notes,
            })
            setListing([{
                "city": result.data.property_city,
                "harid": result.data.property_harid,
                "for": 'sale',
                "full_address": result.data.property_full_street_address,
                "street_address": result.data.property_full_street_address,
                "list_price": result.data.property_price,
                "list_type": result.data.property_listtype,
                "listingid": result.data.property_listingid,
                "mlsnum": result.data.property_mlsnum,
                "photo": result.data.property_photo,
                "state": result.data.property_state,
                "country": result.data.property_country,
                "zip": result.data.property_zip.toString()
            }])
            if (!result.data.contacts[0]) {
                setContact([])
            }
            if (result?.data?.contacts[0] && !contactSelected) {
                saveContact(result?.data?.contacts[0])
            }



        }
    }

    function saveContact(contact: GetDealsPipelineApi.IDealContact) {
        setContact([{
            'id': Number(contact.id),
            'first_name': contact.first_name,
            'middle_name': contact.middle_name,
            'last_name': contact.last_name,
            'photo_url': contact.photo_url,
            'city': contact.city,
            'score': contact.score,
            'primary_email': contact.primary_email,
            'deleted': contact.deleted
        }])
    }

    const getStages = (char: string) => {
        const stages = lookupData.response?.deal_stage.items.filter((s) => s.short_value !== 'F').filter((s) => s.short_value !== 'N');
        let lookUpStage = stages?.filter((s) => s.short_value === char)[0];
        return lookUpStage?.long_value
    }

    function clean() {
        setValidation([])
        setListing([])
        if (!props.contactId) {
            setContact([])
        }
        setDeal({
            title: "",
            contact_id: props.contactId ? props.contactId : "",
            deal_type: "",
            deal_type_text: "",
            deal_stage: props.stage && props.stage !== 'F' ? props.stage : 'A',
            deal_stage_text: props.stage && props.stage !== 'F' ? getStages(props.stage) : getStages('A'),
            deal_priority: "",
            property_full_street_address: "",
            property_address2: "",
            property_harid: "",
            property_listingid: "",
            property_price: "",
            property_for: "",
            deal_amount: "",
            estimated_deadline: "",
            notes: ""
        })
    }


    useServiceDidFailed(addDeal, () => {
        setLoading(false)

    })

    useServiceDidFailed(editDeal, () => {
        setLoading(false)
    })


   
    useEffect(() => {
        console.log(deals)
    }, [deals])


    useEffect(() => {
        if (dealsId) {
            getDealAction.fetch({ deal_id: dealsId.toString() })
            setLoading(true)
        }
        setValidation([])

    }, [dealsId])


    useEffect(() => {
        if (getDeal.response) {
            mapDeal(getDeal.response)
            setLoading(false)
        }

    }, [getDeal])

    useEffect(() => {
        if (addDeal.response) {
            toggleShow?.()
            setLoading(false)
            reload?.(type)
            clean()
            displayMessage("The deal successfully added")

        }
    }, [addDeal])

    useEffect(() => {
        if (editDeal.response) {
            toggleShow?.()
            setLoading(false)
            reload?.(type)
            clean()
            displayMessage("The deal successfully updated")
            doRedirect?.()
        }
    }, [editDeal])
    useEffect(() => {
        if (props.contactId && props.contactId !== '0') {
            contactSearchAction.fetch({ contact_id: parseInt(props.contactId) })
        }
    }, [props.contactId])


    useEffect(() => {
        if (dealsId === 0) {
            clean()
            setLoading(false)
            setValidation([])
        }
    }, [visible])






    const DealsAddPlatform = useResponsiveChoose(DealsAddDesktop, DealsAddApp);
    return (
        <DealsAddPlatform
            {...props}
            deals={deals}
            dealsId={dealsId}
            lookup={lookupData}
            contact={contact}
            validation={validation}
            listing={listing}
            loading={loading}
            type={type}
            preSelected={contactSelected}
            setLoading={setLoading}
            setListing={setListing}
            setContact={setContact}
            setValidation={setValidation}
            validate={validate}
            getError={getError}
            saveDeal={saveDeal}
            setDeal={setDeal}
            updateDeal={updateDeal}
            reload={reload}
            toggleShow={toggleShow}
            clean={clean}
        />
    );
}
