import { Typeahead } from '../Typeahead';
import { ICityTypeaheadProps } from './CityTypeahead.def';
import './CityTypeahead.scss';
import { fetchCitiesService } from 'services/typeaheads/cities';

export const CityTypeahead = (props: ICityTypeaheadProps): JSX.Element => {
    const { initialValues, onChange } = props;

    const search = (value: string) => {
        return fetchCitiesService({ input: value });
    }

    const mapResult = (item: string) => {
        return item;
    }

    const getSelectedCity = (value: string[]) => {
        onChange?.(value);
    }

    return (
        <div>
            <Typeahead.Multi label='City' placeholder='City' search={search} mapResult={mapResult} initialValue={initialValues} onChange={(e) => getSelectedCity(e)} />
        </div>
    );
}
