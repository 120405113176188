import { IImportProviderContactScreenProps } from './ImportProviderContactScreen.def';
import './ImportProviderContactScreen.scss';
import {
    IonList,
    IonLabel, IonItem, IonCheckbox, IonModal, IonButton, IonHeader, IonToolbar, IonTitle, IonIcon, IonButtons, IonContent
} from "@ionic/react";
import { GetContactsApi } from 'services/contacts/provider/getContacts';
import { OrdinaryButton, PrimaryButton, ProminentButton } from 'views/forms/buttons/Button';
import { useEffect, useState } from 'react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useResponsive } from 'scripts/hooks/general';
import { useHistory, useLocation } from 'react-router';


export const ImportProviderContactScreenApp = (props: IImportProviderContactScreenProps): JSX.Element => {
    //importService, doImport
    const { response, loading, importService, doImport } = props
    const [selected, getSelected] = useState<string[]>()
    const [showImporting, setImporting] = useState(false);
    const [referrer, setReferrer] = useState(true);
    const [isPrevent, setIsPrevent] = useState(true);
    const isResponsive = useResponsive()
    const history = useHistory()
    const location = useLocation<any>()
    document.addEventListener('ionBackButton', (ev: any) => {
        ev.detail.register(10, () => {
            history.push('/contacts', { showImportPopup: referrer ? true : false })
        });
    });

    const handleBack = () => {
        history.push('/contacts', { showImportPopup: referrer ? true : false })
    }


    useEffect(() => {
        if (location.state && location.state.noReferrer) {
            setReferrer(false)
        }

    }, [])

    useServiceDidSucceeded(importService, () => {
        setIsPrevent(true)
        setImporting(false)
    })
    return (
        <>
            <IonModal isOpen={showImporting} trigger="open-modal" className="modal__autoheight" initialBreakpoint={1} breakpoints={[0, 1]} onIonModalDidDismiss={() => setImporting(false)} backdropDismiss={false}>
                <div className="ion-padding">
                    <h2 tabIndex={0} className="h2--simple mb-4 mt-3 text-center">Import</h2>
                    <div className="mb-5 p-2 bg_color_snow_white">
                        <div className="mb-4 pt-4 font_size--large_extra font_weight--bold text-center algin-self-center ">
                            You will import <span className="font_weight--semi_bold">{selected?.length}</span> contact from <span className="font_weight--bold">{response?.total}</span> to the Contact Management page
                        </div>
                        <div className="font_size--medium font_weight--semi_bold m-auto text-center algin-self-center color_auxiliary mb-4">
                            <div className='d-flex cursor--hand justify-content-center  ImpContacts-checkbox' onClick={() => setIsPrevent(!isPrevent)}>
                                <IonCheckbox id="prevent-checkbox" slot="start" checked={isPrevent}  ></IonCheckbox>
                                <div className='ml-2'  >Prevent updates on existing contacts</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 small-gutters">
                        <div className="col">
                            <OrdinaryButton onClick={() => setImporting(false)}>Cancel</OrdinaryButton>
                        </div>
                        <div className="col text-right">
                            <PrimaryButton className="btn btn--large" disabled={importService.loading} loading={importService.loading} onClick={() => doImport?.(isPrevent ? "1" : "0", selected ? selected : [])}>Confirm</PrimaryButton>
                        </div>
                    </div>
                </div>
            </IonModal >
            <IonModal className={`${!isResponsive ? `custom--modal` : `bg-white`}`} isOpen={true} backdropDismiss={false}>
                <IonHeader className="ion-padding-md ion-no-border border-bottom">
                    <IonToolbar>
                        <IonTitle className="modal-title">{response ? `Contacts (` + response?.total + `)` : `Contacts`}</IonTitle>
                        {!isResponsive ?
                            <IonButton color={"blue"} className="modal-closebtn" slot="end" fill="clear" onClick={handleBack}>
                                <IonIcon slot="icon-only" name="close" color="dark"></IonIcon>
                            </IonButton>
                            :
                            <IonButtons slot="start">
                                <IonButton color={"blue"} onClick={handleBack}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
                            </IonButtons>
                        }
                        <IonButtons slot="end">
                            <div className='min-w-70px'></div>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="bg-white">
                    <>
                        {loading &&
                            <LoadingState />
                        }
                        {!loading &&
                            <>
                                <IonList>
                                    {response?.list?.map((contact: GetContactsApi.IContactImport, index: number) =>
                                        <IonItem key={index}>
                                            <IonCheckbox className="custom--checkbox__gray" slot="start"
                                                onClick={() => !selected?.includes(contact.id) ? getSelected([...selected ? selected : [], contact.id]) : getSelected(selected.filter((c) => c !== contact.id))}
                                                checked={selected?.includes(contact.id)}
                                            ></IonCheckbox>
                                            <IonLabel>
                                                <div className="card--contact pt-3 pb-2">
                                                    <div className="card--contact__cont">
                                                        <div className="card--contact__cont__header flex-column">
                                                            <div className="card--contact__cont__header_name" >{contact?.first_name || contact?.last_name ? contact?.first_name + ' ' + contact?.last_name : contact?.primary_email}</div>
                                                            <div className=" font_size--small color_auxiliary text-truncate pb-1 font-weight-normal">{contact?.primary_email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                </IonList>
                                {response && response.list.length > 0 &&
                                    <div className="p-3 position-fixed bg_color_snow_white  w-100 border-color--cloudy-sky-light top-auto bottom--10px lef-0px right-0px zindex-111 opacity-95pr">
                                        <ProminentButton size='large' className="btn btn--large  w-100" loading={importService.loading} disabled={(!(selected && selected?.length > 0) || importService.loading)} onClick={() => setImporting(true)}     >Import Selected Contacts</ProminentButton>
                                    </div>
                                }
                            </>
                        }

                        {!loading && response && response.list.length === 0 &&
                            <EmptyState />
                        }

                    </>
                </IonContent>
            </IonModal >


        </>

    );

}

const LoadingState = () => {
    return (
        <div>
            <IonList>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr w-90pr"></div>

                            <div className="loading_text w-50pr w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>
                <IonItem>
                    <div className="card--contact">
                        <div className="card--contact__image">
                            <div className="agent_signature__photo loading w-32px h-32px"></div>
                        </div>
                        <div className="card--contact__cont">
                            <div className="loading_text w-90pr"></div>

                            <div className="loading_text w-50pr"></div>
                        </div>
                    </div>
                </IonItem>

            </IonList>
        </div>
    );
}

const EmptyState = () => {
    return (
        <div>
            <IonList>
                <IonItem>
                    <div className="card--contact pt-4">
                        <div className="card--contact__cont">
                            <div className="card--contact__cont__header flex-column">
                                <div className="card--contact__cont__header_name" >There are no contacts to import</div>

                            </div>
                        </div>
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
}