import { HomeScreenApp } from "./HomeScreen.app";
import { HomeScreenDesktop } from "./HomeScreen.desktop";
import { IHomeScreenProps } from "./HomeScreen.def";
import { useResponsiveChoose } from "scripts/hooks/general";
import { useFetchSearchEmailsService } from "services/emails/searchEmails";
import { useParams } from "react-router";
import { useFetchGetBasicInfoService } from "services/user/getBasicInfo";

export const HomeScreen = (props: IHomeScreenProps): JSX.Element => {
    const { taskid } = useParams<{ taskid: string }>();
    const [basicInfo] = useFetchGetBasicInfoService();
    const [mailService] = useFetchSearchEmailsService({ type: "inbox", keyword: '', sort_by: "recent", renew: "1", contact_id: props.contactId,page_size:'8' });

    const HomeScreenPlatform = useResponsiveChoose(HomeScreenDesktop, HomeScreenApp);
    return <HomeScreenPlatform
        mailService={mailService}
        memberService={basicInfo}
        taskId={taskid}
        {...props} />;
}
