import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace GetContactsApi {
    export const url = "/provider/contacts";
    export const method = "GET";
    export const defaultError = "Something went wrong while getting contacts please try later.";
    export const operations = apiOperations(GetContactsApi);
    export const scope = new class GetContactsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {

    }

    export interface Response {
        total: string,
        page: string,
        page_size: string,
        total_pages: string,
        next_page_url: string,
        prev_page_url: string,
        list: IContactImport[]

    }

    export interface IContactImport {
        id: string,
        first_name: string,
        last_name: string,
        primary_phone: string,
        primary_email: string,
        company: string,
        queued_dt: string,
        imported_dt: string,
        imported_status: string,
        queued_status: string,

    }
}

export const fetchGetContactsService = forwardOperation(GetContactsApi.operations, 'fetch');
export const useFetchGetContactsService = forwardOperation(GetContactsApi.operations, 'useFetch');
export const useGetContactsService = forwardOperation(GetContactsApi.operations, 'useService');