import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeleteDocumentsApi
{
    export const url = "/contacts/:contact_id/documents/:document_id";
    export const method = "DELETE";
    export const defaultError = "Failed to delete document.";
    export const operations = apiOperations(DeleteDocumentsApi);
    export const scope = new class DeleteDocumentsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        document_id:string
    }

    export interface Response
    {
        successMessage:string
    }
}

export const fetchDeleteDocumentsService = forwardOperation(DeleteDocumentsApi.operations, 'fetch');
export const useFetchDeleteDocumentsService = forwardOperation(DeleteDocumentsApi.operations, 'useFetch');
export const useDeleteDocumentsService = forwardOperation(DeleteDocumentsApi.operations, 'useService');