import { INotesScreenProps } from './NotesScreen.def';
//import { useComponent } from "scripts/context";
import { NotesScreenApp } from './NotesScreen.app';
import { NotesScreenDesktop } from './NotesScreen.desktop';
import { useResponsive } from 'scripts/hooks/general';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';

export const NotesScreen = (_props: INotesScreenProps): JSX.Element =>
{
  // const [reload, doReload] = useState(1);

  const NotesScreenPlatform = useResponsive(NotesScreenDesktop, NotesScreenApp);
  return (
    <ContactDetailsTemplate selectedMenu='Notes'>
    <NotesScreenPlatform  />
     </ContactDetailsTemplate>
  );
}