
import { DefaultPopUp } from "views/popups/DefaultPopUp";

import { IChooseTaskModalProps } from "../../AddTaskPopUp.def";
import { ModuleInfo } from "views/general/ModuleInfo";

export const ChooseTaskModalDesktop = (props: IChooseTaskModalProps) => {
    const { show, toggle, setCategory, toggleTask } = props;
    return (
        <div>
            <DefaultPopUp title="Choose an option" onDidDismiss={() => toggle(false)} className="custom--modal initmodal" isOpen={show} toggleShow={() => toggle(false)}>
                <DefaultPopUp.Body>
                    <div className="ion-padding bg-white h-100 d-flex">
                        <div className="align-self-center pl-3 pr-3 w-100">
                            <div className="row">
                                <div className="col-6">
                                    <a onClick={() => { setCategory("t"); toggleTask(true); toggle(false) }} className="cards p-3 text-center d-block d-flex font_size--large font_weight--bold cursor--hand height-170px">
                                        <div className="align-self-center w-100">
                                            <div className="mb-3 pt-3"><img className="w-34px" src="https://content.harstatic.com/media/icons/icon_task.svg"></img></div>
                                            <div className="align-center font_size--large font_weight--bold">New Task</div>
                                            <div className="align-center min-h-71px">
                                                <ModuleInfo type="tasks" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a onClick={() => { setCategory("e"); toggleTask(true); toggle(false) }} className="cards p-3 text-center d-block d-flex font_size--large font_weight--bold cursor--hand height-170px">
                                        <div className="align-self-center w-100 ">
                                            <div className="mb-3 pt-3"><img className="w-34px" src="https://content.harstatic.com/media/icons/icon_task.svg"></img></div>
                                        <div className="align-center font_size--large font_weight--bold ">New Event</div>
                                            <div className="align-center min-h-71px">
                                                <ModuleInfo type="events" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </DefaultPopUp.Body>
            </DefaultPopUp>
        </div>
    );
}
