import { IDefaultTemplateIonContentProps, IDefaultTemplateProps } from './DefaultTemplate.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { DefaultTemplateApp } from './DefaultTemplate.app';
import { DefaultTemplateDesktop } from './DefaultTemplate.desktop';
import { extractChildren } from 'scripts/utilities/react';

export function DefaultTemplate(props: IDefaultTemplateProps): JSX.Element
{
    const { ionContent } = extractChildren(props.children, {
        ionContent: DefaultTemplate.IonContent,
    });

    const DefaultTemplatePlatform = useResponsiveChoose(DefaultTemplateDesktop, DefaultTemplateApp);
    return (
        <DefaultTemplatePlatform {...props} ionContentProps={ionContent?.props} />
    );
}

export namespace DefaultTemplate
{
    export function IonContent(_:IDefaultTemplateIonContentProps) { return <></>; }
}