import { MapCSVApi, useMapCSVService } from "services/contacts/csvmapping/mapCSV";
import { CSVMapingRequestKey, IImportCsvMappedScreenProps } from "../ImportCsvMappedScreen.def";
import { forwardRef, useImperativeHandle } from "react";
import { useServiceDidSucceeded } from "scripts/hooks/useService";
import { PrimaryButton } from "views/forms/buttons/Button";
import { useResponsive } from "scripts/hooks/general";
import { useHistory } from "react-router";

export const ImportSetup = forwardRef((props: IImportCsvMappedScreenProps, ref) => {
    const { importSetup, setImportSetup } = props;
    const isResponsive = useResponsive();
    const history = useHistory();

    useImperativeHandle(ref, () => ({
        submit() {
            onSubmit()
        }

    }));
    const [mapingService, doMappingService] = useMapCSVService();
    const onSubmit = () => {
        doMappingService.fetch(importSetup as MapCSVApi.Request);
    }

    const onChangeHandler = (key: CSVMapingRequestKey, value: string) => {
        setImportSetup?.({
            ...importSetup as MapCSVApi.Request,
            [key]: value
        });
    }

    useServiceDidSucceeded(mapingService, (response: MapCSVApi.Response) => {
        if (response.data.import_id) {
            props.setImportId?.(response.data.import_id);
            props.onNext?.(2)
        }
    });

    return (
        <div className=" max-w-460">


            <div className='font_size--medium font_weight--bold mb-2'>Date format</div>
            <div role="radiogroup" className='mb-4'>
                <div role="radio" className="custom-control custom-radio custom-control-inline d-block">
                    <input type="radio" id="DateFormat_1" name="DateFormat" className="custom-control-input" value={'mm/dd/yyyy'} checked={importSetup?.date_format === 'mm/dd/yyyy' ? true : false} onChange={(e) => onChangeHandler("date_format", e.target.value)} />
                    <label className="custom-control-label" htmlFor="DateFormat_1"><span className="">MM/DD/YYYY (E.g. 04/29/2024)</span></label>
                </div>
                <div role="radio" className="custom-control custom-radio custom-control-inline d-block">
                    <input type="radio" id="DateFormat_2" name="DateFormat" className="custom-control-input" value={'dd/mm/yyyy'} checked={importSetup?.date_format === 'dd/mm/yyyy' ? true : false} onChange={(e) => onChangeHandler("date_format", e.target.value)} />
                    <label className="custom-control-label" htmlFor="DateFormat_2"><span className="">DD/MM/YYYY (E.g. 29/04/2024)</span></label>
                </div>
                <div role="radio" className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="DateFormat_3" name="DateFormat" className="custom-control-input" value={'yyyy/mm/dd'} checked={importSetup?.date_format === 'yyyy/mm/dd' ? true : false} onChange={(e) => onChangeHandler("date_format", e.target.value)} />
                    <label className="custom-control-label" htmlFor="DateFormat_3"><span className="">YYYY-MM-DD (E.g. 2024-04-29)</span></label>
                </div>
            </div>

            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-4">
                <input type="checkbox" id="checkbox_button_medium_1" name="checkbox_button_medium" className="custom-control-input" checked={importSetup?.has_header === '1' ? true : false} onChange={(e) => onChangeHandler("has_header", e.target.checked ? '1' : '0')} />
                <label className="custom-control-label" htmlFor="checkbox_button_medium_1">Does your file have a Header</label>
            </div>

            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-4">
                <input type="checkbox" id="checkbox_button_medium_2" name="checkbox_button_medium2" className="custom-control-input" checked={importSetup?.prevent_update === '1' ? true : false} onChange={(e) => onChangeHandler("prevent_update", e.target.checked ? '1' : '0')} />
                <label className="custom-control-label" htmlFor="checkbox_button_medium_2">Prevent Updates on Existing Contacts</label>
            </div>
            {!isResponsive &&
                <div className='pt-5'>
                    <PrimaryButton className='btn btn--primary mt-2 mb-3 mr-3' size='medium' loading={mapingService.loading} disabled={mapingService.loading} onClick={onSubmit}>Next</PrimaryButton>
                    <button type='button' className='btn btn--ordinary mt-2 mb-3' onClick={() => history.push('/contacts')}>Cancel</button>
                </div>
            }
        </div>
    );
})