import { IAddNotesPopUpProps } from './AddNotesPopUp.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AddNotesPopUpApp } from './AddNotesPopUp.app';
import { AddNotesPopUpDesktop } from './AddNotesPopUp.desktop';

export const AddNotesPopUp = (props: IAddNotesPopUpProps): JSX.Element =>
{
    const AddNotesPopUpPlatform = useResponsiveChoose(AddNotesPopUpDesktop, AddNotesPopUpApp);
    return (
        <AddNotesPopUpPlatform {...props} />
    );
}
