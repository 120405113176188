import { IChangeAliasPopUpProps } from './ChangeAliasPopUp.def';
import './ChangeAliasPopUp.scss';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IonInput, IonLabel, IonNote, IonText } from '@ionic/react';
import { useUpdateRoutineEmailService } from 'services/emails/updateRoutineEmail';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useRef, useState } from 'react';

export const ChangeAliasPopUpDesktop = (props:IChangeAliasPopUpProps): JSX.Element =>
{
    const { show, setting, onClose, onChange } = props
    const textField = useRef<HTMLIonInputElement | null>(null)
    const [error, setError] = useState("")

    const [updService, updServiceAction] = useUpdateRoutineEmailService()

    const validated = (): boolean => {
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (textField?.current?.value !== null) {
            if (typeof textField?.current?.value === 'string' && format.test(textField?.current?.value)) {
                setError("The email should not contain special characters")
                return false
            } else {
                setError("")
                return true
            }
        } else {
            return false
        }
    }

    const updateRoutineEmail = () => {
        if (validated() && textField?.current?.value !== null && typeof textField?.current?.value === 'string') {
            updServiceAction.fetch({ routine_email_alias: textField?.current?.value })
        }
    }

    useServiceDidSucceeded((updService), (response) => {
        if (response) {
            onClose?.();
            
            const alias = textField.current!.value as string;
            onChange?.({
                routine_email_alias: alias,
                routine_email_domain: setting.routine_email_domain,
                routine_email: `${alias}@${setting.routine_email_domain}`
            });
        }
    })

    return (
        <DefaultPopUp title="Change Routing Email" className="custom--modal__smallheight" isOpen={show} toggleShow={() => onClose?.()} >
            <DefaultPopUp.Body>
                <div className="p-5">
                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <IonLabel>Routing email</IonLabel>
                        <div className='d-flex border w-425px '>
                            <IonInput ref={textField} type='text' className="border-0 w-288px" maxlength={48} value={textField.current?.value ? textField.current?.value : setting?.routine_email_alias}></IonInput>
                            <IonText className='ml-auto pr-2 font_size--medium pt-3 border bg_color_cement_light p-2'> @{setting?.routine_email_domain}</IonText>
                        </div>
                        {error &&
                            <IonNote slot="helper">{error}<br /></IonNote>
                        }
                        <div className="warning mt-2"><div className="warning__text">This change will not affect your login email</div></div>
                    </div>
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <ShapelessButton onClick={() => onClose?.()}>Cancel</ShapelessButton>
                    </div>
                    <div className="col-auto"><PrimaryButton disabled={updService.loading} onClick={updateRoutineEmail}>Save</PrimaryButton></div>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp >
    )
}
