import { useHistory } from 'react-router';
import { IAvatarProps } from './Avatar.def';
import './Avatar.scss';
import { joinWithSpace } from 'scripts/helpers/texts';
import { Tag } from 'scripts/ui/tag';

export const Avatar = (props: IAvatarProps): JSX.Element => {
    const { id, firstName, responsive, lastName, link, referrer } = props
    const getAvatarClass = (num: number): string => {
        if (num === 0) {
            num = 1
        }
        let res = num % 16
        if (res === 0) {
            return 'nophoto_color_16'
        } else {
            return 'nophoto_color_' + res;
        }
    }
    var photo = props.photo ? props.photo : ''
    if (((!id || id === '0') || (Number(id) <= 0)) && !photo) {
        photo = 'https://content.harstatic.com/media/icons/agent_placeholder.svg'
    }


    let history = useHistory();
    const redirect = (link: string) => {
        history.push(link, referrer ? { referrer: referrer } : '')
    }
    var className = joinWithSpace("agent_signature__photo", props.className)
    return (
        <>
            {!!photo &&
                <Tag.Div className={className} cssVars={{ avatarAgentPicture: 'url(' + photo + ')' }}
                    onClick={() => {
                        if (link && id != '0') {
                            redirect(link)
                        }
                    }}
                >
                    {props.children}
                </Tag.Div>
            }
            {!!!photo &&
                <div className={`${!responsive ? className : ``} ${getAvatarClass(Number(id))}`} onClick={() => {
                    if (link && id != '0') {
                        redirect(link)
                    }
                }}>{!!props.children && <div>{props.children}</div>}
                    <span> {firstName?.charAt(0).toLocaleUpperCase()}{lastName?.charAt(0).toLocaleUpperCase()}</span>
                </div >
            }
        </>
    );
}
