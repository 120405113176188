import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { formatSystemDateShort } from "scripts/helpers/dates";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace GetCalendarTaskStatsApi
{
    export const url = "/tasks/summary";
    export const method = "GET";
    export const defaultError = "Failed to load tasks summary.";
    export const operations = apiOperations(GetCalendarTaskStatsApi);
    export const scope = new class GetCalendarTaskStatsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input,
                start_dt: formatSystemDateShort(input.start_dt),
                end_dt:formatSystemDateShort(input.end_dt),
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        contact_id?:string
        start_dt:Date
        end_dt:Date,
        types?:string,
        priorities?:string
        categories?:string
    }

    export interface Response
    {
        total: string,
        today: string,
        overdue: string,
        upcoming: string,
        completed: string,
        open:string,
        
        
    }
}

export const fetchGetCalendarTaskStatsService = forwardOperation(GetCalendarTaskStatsApi.operations, 'fetch');
export const useFetchGetCalendarTaskStatsService = forwardOperation(GetCalendarTaskStatsApi.operations, 'useFetch');
export const useGetCalendarTaskStatsService = forwardOperation(GetCalendarTaskStatsApi.operations, 'useService');