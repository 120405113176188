import { IEmailListManagedProps, IEmailListProps, IEmailListViewHeaderProps, IEmailListViewTypeProps } from './EmailList.def';
import { Dropdown } from 'react-bootstrap';
import './EmailList.scss';
import { EmailCard, EmailCardInDraft, EmailCardInForwarded, EmailCardInSent } from '../EmailCard/EmailCard';
import { IEmailList } from 'services/emails/searchEmails';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { LazyScroll } from 'views/general/LazyScroll';
import { IonSearchbar } from '@ionic/react';


export const EmailListDesktop = (props: IEmailListProps): JSX.Element => {
    const { loading, type, total, totalPages, hideHeader, list, page, keyword, connectionStatus, sort, remove, mark, reload, loadMore } = props;
    const [selected, setSelection] = useState<string[]>([])
    const [sortType, setSortType] = useState("Recent")
    const [checkedAll, setCheckedAll] = useState(false);
    const scrollRef = useRef<LazyScroll.RefElement>(null);
    let history = useHistory()

    const search = (key: string) => {
        props.search?.(key)
    }

    function selectEmail(id: string) {
        if (selected.includes(id)) {
            let arr = selected.filter((email) => email !== id)
            setSelection(arr)
        } else {

            setSelection([...selected, id])
        }
    }

    function selectAll(ids: string[]) {
        setSelection(ids)
    }

    useEffect(() => {
        if (checkedAll) {
            setCheckedAll(false)
        }
    }, [checkedAll]);


    useEffect(() => {
        if (scrollRef.current) { scrollRef.current.scrollTop = 0; }
        setSelection([])
    }, [type]);


    return (
        <LazyScroll
            hasScroll={!loading && totalPages && page && Number(totalPages) > page ? true : false}
            className="emails"
            page={page ? page : 1}
            onload={(page) => loadMore?.(page)}
            ref={scrollRef}
        >
            <div className="mb-5 mb-md-0">
                {!hideHeader ?
                    selected.length > 0 ?
                        <ManageState
                            selection={selected}
                            type={type}
                            list={list}
                            remove={remove}
                            reload={reload}
                            selectAll={setCheckedAll}
                            clearSelection={() => setSelection([])}
                            mark={mark} />
                        :
                        <Header total={total} search={search} sort={sort} keyword={keyword} sortType={sortType} setSortType={setSortType} />
                    :
                    <></>
                }

                {connectionStatus === false &&
                    <div className="row">
                        <div className="col-12"><div className="border_radius--default d-inline-block mb-2 bg_color_pending_light p-3 font_size--medium">
                            You have been disconnected from your email account. Please connect <a className='cursor--hand' onClick={() => history.push('/settings/account')}>now</a> in order to start sending emails.
                        </div>
                        </div>
                    </div>
                }

                {loading && page && page < 2 ?

                    <Loading />
                    :
                    <div>
                        {(type === 'inbox' || type === 'sent' || type === 'forwarded' || type === 'lead') &&
                            <ListViewWithLabel {...props} keyword={keyword} selected={selected} selectEmail={selectEmail} selectAll={selectAll} isSelectAll={checkedAll} />
                        }

                        {type === 'draft' &&
                            <ListView {...props} keyword={keyword} selected={selected} selectEmail={selectEmail} selectAll={selectAll} isSelectAll={checkedAll} />
                        }

                    </div>
                }
            </div>
        </LazyScroll>

    );
}

const ListViewWithLabel = (props: IEmailListProps | IEmailListViewTypeProps) => {
    const { loading, id, list, type, hideHeader, readList, redirect } = props as IEmailListProps;
    const { selected, isSelectAll, selectAll, selectEmail } = props as IEmailListViewTypeProps;
    const history = useHistory()

    function labels(list: IEmailList[]) {
        const groups = list.reduce(
            (groups, item: IEmailList) => {
                const key = item?.date_tag
                if (!groups[key]) { groups[key] = { label: key, list: [] }; }
                groups[key]!.list.push(item);
                return groups;

            },
            {} as Record<string, { label: string, list: IEmailList[] }>
        );
        return Object.values(groups)
    }

    function goto(id: string) {
        if (redirect) {
            redirect?.(id)

        } else {
            history.push(`/emails/` + type + '/' + id)
        }
    }




    useEffect(() => {
        if (isSelectAll) {
            let ids = list?.map((mails) => mails.id)
            if (ids)
                selectAll?.(ids)
        }
    }, [isSelectAll])


    return (
        <div className={hideHeader ? 'pt-4  emails_list' : 'pt-4 pb-4 emails_list'}>
            {!loading && list?.length === 0 &&
                <Empty />
            }
            {list && labels(list)?.map((data: any, ind: number) =>
                <div key={ind + data.label}>
                    <div className={`title__label ml-3 mb-3 ${ind !== 0 ? `mt-4` : ``}`}> {data.label}</div>
                    {data.list?.map((email: IEmailList, index: number) =>
                        <div key={index + '-' + email.id} className="border-bottom">
                            <div>
                                
                                {(type === 'inbox' || type === 'lead')  &&
                                    <EmailCard
                                        email={email}
                                        type={type}
                                        id={id}
                                        readList={readList}
                                        label={data.label}
                                        redirect={goto}
                                        hideCheckbox={hideHeader}
                                        toggleCheckbox={selectEmail}
                                        selected={selected}
                                    />
                                }                                 

                                {type === 'sent' &&
                                    <EmailCardInSent
                                        email={email}
                                        type={type}
                                        id={id}
                                        label={data.label}
                                        redirect={goto}
                                        hideCheckbox={hideHeader}
                                        toggleCheckbox={selectEmail}
                                        selected={selected}
                                    />
                                }

                                {type === 'forwarded' &&
                                    <EmailCardInForwarded
                                        email={email}
                                        type={type}
                                        id={id}
                                        label={data.label}
                                        redirect={goto}
                                        hideCheckbox={hideHeader}
                                        toggleCheckbox={selectEmail}
                                        selected={selected}
                                    />
                                }


                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )

}

const ListView = (props: IEmailListProps | IEmailListViewTypeProps) => {
    const { id, list, loading, type, hideHeader, page, updateList, update } = props as IEmailListProps;
    const { selected, isSelectAll, selectEmail, selectAll } = props as IEmailListViewTypeProps;
    const history = useHistory()

    function redirect(id: string) {
        history.push(`/emails/` + type + '/' + id, {
            type: type,
            list: page && page > 1 ? list : [],
            page: page

        })
    }

    function setup(data: { item: any, isNew: boolean }) {
        let emailItem: IEmailList = {
            id: data?.item.id,
            subject: data?.item?.subject,
            first_name: "",
            middle_name: "",
            last_name: "",
            first_last_name: "",
            short_message: data?.item?.short_message,
            created_dt: data?.item?.created_dt,
            from_address: "",
            date_tag: data?.item?.date_tag,
            contact_id: "",
            display_name: "",
            photo_url: "",
            is_lead: "0",
            read: "0",
            message_uid: data?.item?.message_uid,
            recipients: [],
            recipients_cc: [],
            recipients_bcc: [],
        }
        update?.(data, emailItem)
    }


    useEffect(() => {
        if (isSelectAll) {
            let ids = list?.map((mails) => mails.message_uid)
            if (ids)
                selectAll?.(ids)
        }
    }, [isSelectAll])

    useEffect(() => {
        if (updateList) {
            setup(updateList)
        }

    }, [updateList])



    return (
        <div  className={hideHeader ? 'pt-4  emails_list' : 'pt-4 pb-4 emails_list'}>
            {!loading && list?.length === 0 &&
                <Empty />
            }
            {list?.map((email: IEmailList, index: number) =>
                <EmailCardInDraft
                    key={index + '-' + email.id}
                    email={email}
                    type={type}
                    id={id}
                    label={email.date_tag}
                    redirect={redirect}
                    hideCheckbox={hideHeader}
                    toggleCheckbox={selectEmail}
                    selected={selected}

                />
            )}
            <div className='pt-3 pb-3'></div>
        </div >
    )

}

const Header = (props: IEmailListProps | IEmailListViewHeaderProps) => {
    const { total, keyword, search, reload, sort } = props as IEmailListProps
    const { sortType, setSortType } = props as IEmailListViewHeaderProps
    const [timer, setTimer] = useState<number | string>()

    const doSearch = (key: string) => {
        if (timer) {
            clearTimeout(timer)
        }
        if (key.length === 0) {
            reload?.()
        }
        setTimer(setTimeout(() => {
            search?.(key)
        }, 512))
    }

    return (
        <div className={`border-bottom p-2 email_list_header ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`}>

            <div className="row">
                <div className="col">
                    <IonSearchbar className={`input-search_form__shapeless searchwithborder--field emailSearch--field ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`} placeholder={total ? `Search ` + total + ' emails...' : 'Search emails'} onIonInput={(e: any) => doSearch?.(e.target.value.trim())} />
                </div>
                <div className="col-aut align-self-center pr-4">
                    <Dropdown className={`dropdown dropdown--custom__simple sort_by ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`}>
                        <Dropdown.Toggle className={`dropdown-toggle border-0 no-outline p-0 m-0 ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`} role="link" variant="none">Sort by {sortType}</Dropdown.Toggle>
                        <Dropdown.Menu className={`dropdown-menu animate cursor--hand slideIn pt-3 pb-3 ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`}>
                            <a className={`dropdown-item ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`} onClick={(e) => {
                                e.preventDefault()
                                sort?.("recent")
                                setSortType?.("Recent")
                            }}>Sort by Recent</a>
                            <a className={`dropdown-item ${keyword && keyword.length > 0 ? `emailSearch--field--onSearch` : ``}`} onClick={(e) => {
                                e.preventDefault()
                                sort?.("older")
                                setSortType?.("Older")
                            }}>Sort by Old</a>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {total && keyword && keyword.length > 0 &&
                <div className='row'>
                    <div className="col ml-5 pl-4 pt-2">{total} emails found</div>
                </div>
            }
        </div>

    );
}

const ManageState = (props: IEmailListManagedProps | IEmailListProps) => {
    const { type, remove, selection, list, mark } = props as IEmailListProps
    const { clearSelection, selectAll } = props as IEmailListManagedProps
    return (
        <div className="border-bottom pl-3 pt-4 pr-3 email_list_header selected min-h-71px">
            <div className="row small-gutters align-items-center">
                <div className="col-auto">
                    <div role="checkbox" className="ml-2 custom-control custom-checkbox  custom-control-inline custom-checkbox__indmt">
                        <input type="checkbox" id="CheckAll" name="CheckAll" className="custom-control-input" checked={(selection && list && list.length === selection.length)} onChange={() => (list && selection && list.length === selection.length) ? clearSelection?.() : selectAll?.(true)} readOnly />
                        <label className="custom-control-label mr-0" htmlFor="CheckAll"></label>
                    </div>
                </div>
                {(type === 'inbox' || type === 'lead') &&
                    <>
                        <div className="col-auto">
                            <button role="button" className="btn btn--black" onClick={() => {
                                clearSelection?.();
                                mark?.(selection ? selection : [], 1);
                            }}>Mark as read</button>
                        </div>

                        <div className="col-auto">
                            <button role="button" className="btn btn--black" onClick={() => {
                                clearSelection?.()
                                mark?.(selection ? selection : [], 0);

                            }}>Mark as unread</button>
                        </div>
                    </>
                }
                <div className="col-auto  d-none">
                    <button role="button" className="btn btn--black">Archive</button>
                </div>
                <div className="col-auto">
                    <button role="button" className="btn btn--black" onClick={() => {
                        clearSelection?.()
                        remove?.(selection ? selection : [])
                    }}>Delete</button>
                </div>
            </div>
        </div>

    );
}

const Loading = () => {
    const LoadingField = () => {
        return (

            <div className="card--email cursor--hand ">
                <div className="card--email__checkbox">
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                </div>
                <div className="card--email__agent"><div className="loading__photo mr-3 w-40px h-40px"></div></div>
                <div className="card--emaill__content overflow-hidden w-100">
                    <div className="card--email__content_heading d-flex">
                        {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                        <div className="mr-2 loading__labels mr-2 w-50px"></div>
                        <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                    </div>
                    <div className="card--email__text text-truncate">
                        <div className="loading__text_small w-100"></div>
                    </div>
                    <div className="loading__text_smaller w-88px"></div>
                </div>
            </div>

        )
    }

    const rows: number[] = [];
    for (let i = 0; i < 10; i++) {
        rows[i] = i
    }
    return (
        <div className="border-bottom">
            {
                rows.map((index: number) =>
                    <LoadingField key={index} />
                )
            }
        </div >



    );
}

const Empty = () => {
    return (
        <div className={` d-flex`}>
            <div className="w-100 align-self-center ">
                <div className=" font_size--large pb-4 pt-2 ml-4">You don’t have any emails</div>
            </div>
        </div>
    )
}
