import { isString } from "lodash-es";
import { eOnboardingStage } from "scripts/enums";
import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IFetchHookResponse } from "scripts/hooks/useFetch";
import { IApiClusterProvider } from "scripts/http/clusters";
import { IServiceFetchResponse } from "scripts/http/serviceFetch";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities";

const lookupKeys = [
    "address_type",
    "contact_score",
    "contact_source",
    "contact_type",
    "contact_suffix",
    "contact_title",
    "contact_lead_type",
    "deal_stage",
    "deal_type",
    "deal_priority",
    'gender',
    "income",
    "phone_type",
    "pipeline_stage",
    "property_type",
    "relationship",
    "reminder_minutes_prior",
    "reommendation_type",
    "score_color",
    "service_type",
    "task_priority",
    "task_type",
    "resource_type",
    "access_type",
    "onboarding_stage",
    "last_contact_filter",
    "task_repeat",
    "contact_reminder_days"
] as const;


export namespace LookupsApi
{
    /**
     * Lookup Cluster
     */
    class LookupClusterProvider implements IApiClusterProvider<Request, Response, string, IData>
    {
        mapRequest(request: Request<IKey>): readonly string[]
        {
            if(!isString(request)) { return request; }
            return [request];
        }

        buildRequest(tokens: string[]): Request<IKey>
        {
            return tokens as IKey[];
        }

        mapResponse(response:Response): Map<string, IData>
        {
            const result = new Map<string, IData>();
            const keys = Object.keys(response).filter(key => lookupKeys.includes(key as IKey));
            for(let key of keys) { result.set(key, response[key as keyof typeof response]); }
            return result;
        }

        buildResponse(list:[string, IData][]): Response
        {
            const response:Record<string, IData> = {};
            for(let [key, data] of list) { response[key] = data; }
            return response as Response;
        }
        
    }


    export const url = "/lookups";
    export const method = "GET";
    export const defaultError = "Failed to load system information.";
    export const operations = apiOperations(LookupsApi);
    export const cluster = new LookupClusterProvider();
    export const scope = new class LookupsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): Record<'keys', ReadonlyArray<IKey>> {
            if (input instanceof Array) { return { keys: input }; }
            return { keys: [input] };
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return Object.entries(data).reduce((data, [key, value]) => {
                const lookup = this.mapLookup(value);
                return { ...data, [key]: lookup };
            }, {} as Response);
        }

        private mapLookup<T extends IKey>(data: DeepPropertiesType<IData<T>, string>): IData<T> {
            return {
                caption: data.caption,
                items: data.items.map(item => {

                    // Mapping special keys
                    const custom = {} as Record<string, unknown>;
                    if ('is_filter' in item) { custom['is_filter'] = item.is_filter === '1'; }

                    return {
                        ...item,
                        ...custom,
                        display_order: parseInt(item.display_order)
                    } as IDataItem<T>
                })
            };

        }
    }();

    export type Request<K extends IKey = IKey> = K | ReadonlyArray<K>;

    export type Response<T extends Request = Request> = T extends Request<infer K> ?
        { [P in K]: IData<P>; } :
        { [P in IKey]?: IData<P>; }

    export type IKey = typeof lookupKeys[number];

    export interface IData<T extends IKey = IKey> {
        caption: string;
        items: Array<IDataItem<T>>;
    }

    export type IDataItem<T extends IKey = IKey> =
        T extends keyof ILookupCustomDataMap ?
        ILookupCustomDataMap[T] & IDefaultDataItem :
        IDefaultDataItem;

    export interface IDefaultDataItem {
        short_value: string;
        long_value: string;
        display_order: number;
    }

    interface ILookupCustomDataMap {
        contact_score: { is_filter: boolean },
        onboarding_stage: { short_value:eOnboardingStage }
    }
}

export const fetchLookupsService = forwardOperation(LookupsApi.operations, 'fetch') as
    <R extends LookupsApi.Request>(parameters: R) => IServiceFetchResponse<LookupsApi.Response<R>>;

export const useFetchLookupsService = forwardOperation(LookupsApi.operations, 'useFetch') as
    <R extends LookupsApi.Request>(parameters: R) => IFetchHookResponse<R, LookupsApi.Response<R>>;

// N/A
//export const useLookupsService = forwardOperation(LookupsApi.operations, 'useService');

export const getLongvalue = (lookup: LookupsApi.IData, shortValue: string | undefined, defaultValue?: string) => {
    if (!shortValue) { return defaultValue; }
    return lookup.items.find(x => x.short_value === shortValue)?.long_value ?? defaultValue;
}