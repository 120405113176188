import { IAddressesSectionProps, IAddressItem } from './AddressesSection.def';
//import { Textbox } from 'views/forms/textboxes/Textbox';
import './AddressesSection.scss';
import { Fragment, useEffect, useState } from 'react';
//import { DropDown } from 'views/forms/dropdowns/DropDown';
import { useParams } from 'react-router';
//import { fetchUpdateAddressService } from 'services/contacts/contactDetails/updateAddress';
import { useAddAddressService } from 'services/contacts/contactDetails/addAddress';
import { useRemoveAddressService } from 'services/contacts/contactDetails/removeAddress';
import { useFetchLookupsService } from 'services/general/lookups';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
//import { AddressesTypeahead } from 'views/forms/typeaheads/AddressesTypeahead';
//import { IAddressTypeaheadItem } from 'views/forms/typeaheads/AddressesTypeahead/AddressesTypeahead.def';
import { displayMessage } from 'scripts/hooks/feedbacks';

export const AddressesSectionDesktop = (props: IAddressesSectionProps): JSX.Element => {
    const { initialItems, maxItems, onItemsChange } = props;
    const mItem = maxItems ?? 10;
    const [formData, setAddresses] = useState<Array<IAddressItem>>(initialItems && mItem && initialItems?.length > mItem ? initialItems.slice(0, maxItems) : initialItems ? initialItems : []);
    const [, ActionRemoveAddress] = useRemoveAddressService()
    const { id } = useParams<{ id?: string }>();
    const [addAddressData, _ActionAddAddresses] = useAddAddressService({ contact_id: id });

    useServiceDidSucceeded(addAddressData, (response) => {
        formData[selectedAddress!]!.id = response.id;
        setAddresses([...formData]);
        setSelectedAddress(undefined);
        onItemsChange?.(formData);
        displayMessage("The contact has been successfully added")
    })
    
    useEffect(() => {
        setAddresses(initialItems && mItem && initialItems?.length > mItem ? initialItems.slice(0, maxItems) : initialItems ? initialItems : []);
        if (initialItems?.length! > 0 || id!) { setSelectedAddress(undefined) }
    }, [initialItems])
    /** 
     * To edit an address, set the address index
     * To add a new address, set the next index available (for example, for an array with 2 items, the next available index is "2")
     */
    const [selectedAddress, setSelectedAddress] = useState<number | undefined>(formData.length === 0 ? 0 : undefined);


    const add = () => setSelectedAddress(formData.length);

    const edit = (index: number) => setSelectedAddress(index);

    const remove = (index: number) => {
        const removingAddress = formData[index]!;
        formData.splice(index, 1);
        setSelectedAddress(formData.length === 0 ? 0 : undefined);
        setAddresses([...formData]);
        if (id) {
            ActionRemoveAddress.fetch({
                contact_id: id!,
                address_id: removingAddress?.id!.toString(),
            })
        }
    };

    /*  const save = (address: IAddressItem, buttonEvent: string) => {
          // If it has reached the limit
          if (selectedAddress === formData.length && formData.length >= mItem) { return; }
  
          if (selectedAddress !== undefined) { formData[selectedAddress] = address; } // Updating address
          else { formData.push(address); } // Adding new address
  
          if (id && buttonEvent === 'add') {
              ActionAddAddresses.fetch({
                  address1: address.address1.trim(),
                  address2: address?.address2!.trim(),
                  address_type: address.address_type,
                  city: address.city.trim(),
                  zipcode: address.zipcode.toString(),
              })
          }
  
          else {
              setAddresses([...formData]);
              setSelectedAddress(undefined);
              onItemsChange?.(formData);
          }
  
      };
  */
    const isAddressList = selectedAddress === undefined;
    return (<AddressListView isOpen={isAddressList} addressList={formData} onAdd={add} mItem={mItem} onEdit={edit} onRemove={remove} {...props} />);
}
/* return isAddressList ?
 
     <AddressListView isOpen={isAddressList} addressList={formData} onAdd={add} mItem={mItem} onEdit={edit} onRemove={remove} {...props} /> :
     <FormView addressList={formData} selectedAddress={formData[selectedAddress]} onEdit={edit} onRemove={() => remove(selectedAddress)} onCancel={() => setSelectedAddress(undefined)} onSave={save} {...props} />;
*/


/*const FormView = (props: IAddressesSectionProps.FormViewProps): JSX.Element => {

    const [address1, setAddress1] = useState({ value: '', error: '' });
    const [address2, setAddress2] = useState('');
    const [cityname, setCityName] = useState({ value: '', error: '' });
    const [address_type, setAddressType] = useState({ value: '', error: '' });
    const [zipCode, setZipCode] = useState({ value: undefined as number | undefined, error: '' });
  //  const [, ActionUpdateAddresses] = useFetchUpdateAddressService();
    const { id } = useParams<{ id?: string }>();
    const [lookupData] = useFetchLookupsService('address_type')

    useEffect(() => {
        const item = props.selectedAddress;
        setAddress1({ value: item?.address1 ?? '', error: '' });
        setAddress2(item?.address2 ?? '');
        setAddressType({ value: item?.address_type ?? '', error: '' });
        setCityName({ value: item?.city ?? '', error: '' });
        setZipCode({ value: item?.zipcode, error: '' });
    }, [props.selectedAddress])

    const addContactAddress = (e?: any) => {
        const buttonEvent = e?.target.name;
        if (address_type.value.length > 0 && address1.value.trim().length > 1 && zipCode.value !== undefined && zipCode.value.toString().length > 1 && cityname.value.trim().length > 1) {
            props.onSave({
                address1: address1.value.trim(),
                address2: address2.trim(),
                address_type: address_type.value,
                city: cityname.value.trim(),
                zipcode: zipCode.value,
                id: props.selectedAddress?.id!
            }, buttonEvent);
        } else {
            setAddress1({ value: address1?.value, error: address1?.value.length === 1 ? "This field should contain min 2 max 50 characters" : address1?.value === "" ? 'This field is required' : '' });
            setAddress2('')
            setAddressType({ value: address_type?.value, error: address_type?.value.length == 0 ? "This field is required" : address_type?.value === "" ? 'This field is required' : '' });
            setZipCode({ value: zipCode?.value, error: zipCode.value !== undefined && zipCode?.value?.toString().length === 1 ? "This field should contain min 2 max 15 characters" : zipCode?.value?.toString() === "" || zipCode?.value === undefined ? 'This field is required' : '' });
            setCityName({ value: cityname?.value, error: cityname?.value.length === 1 ? "This field should contain min 2 max 50 characters" : cityname?.value === "" ? 'This field is required' : '' });
        }

        if (id && buttonEvent === 'save') {
            if (id && props.selectedAddress?.id !== undefined) {
                fetchUpdateAddressService({
                    contact_id: id!,
                    address_id: props.selectedAddress?.id!.toString(),
                    address1: address1.value.trim(),
                    address2: address2.trim(),
                    address_type: address_type.value,
                    city: cityname.value.trim(),
                    zipcode: zipCode.value!.toString(),

                })
            }
        }
    }

    const deleteAddress = () => props.onRemove();
    const handleSelectAddress=(value?:IAddressTypeaheadItem)=>{
        setAddress1({ value:value?.address1 ?? '', error: '' });
        setCityName({value:value?.city ?? '',error:""})
         setZipCode({value:value?.zipcode,error:""})
    }

    const typeaheadValue = //address1.value && zipCode.value && cityname.value?    
        { address1:address1.value, zipcode:zipCode.value||0, city:cityname.value||"" }
        
       // : undefined;

    return (
        <div className='card p-3'>
            <div className="p-3">
                <h3 tabIndex={0}>Addresses</h3>
                {props.addressList!.length > 0 &&
                    <AddressListView selectedAddress={props.selectedAddress} addressList={props?.addressList} onAdd={addContactAddress} mItem={props.maxItems!} onEdit={props.onEdit} onRemove={props.onRemove} />
                }
                <label htmlFor="AddressType">Address Type</label>
                <DropDown id="AddressType" value={address_type.value} className="custom-select custom-select_large" containerClassName="mb-4" error={address_type.error} onChange={(e) => { setAddressType({ value: e.currentTarget.value, error: '' }) }}>
                    {lookupData?.response?.address_type?.items?.map((item, index) => (
                        <option key={index} value={item.short_value}>{item.long_value}</option>
                    ))}
                </DropDown>
                <label htmlFor="AddressType">Address 1</label>
                <AddressesTypeahead  initialValue={typeaheadValue} className='form-control' onTextChange={(value:string)=>setAddress1({ value: value, error: '' })} error={address1.error === '' ? undefined : address1.error} change={handleSelectAddress}/>
              
              {/**  <Textbox value={address1.value} placeholder='Address 1' maxlength={50} className="form-control" containerClassName="mb-4" id="Address" aria-describedby="Address" label='Address 1' error={address1.error === '' ? false : address1.error} onIonChange={(e) => { setAddress1({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' }) }} ></Textbox>*/
{/*  <Textbox value={address2} placeholder='Address 2' maxlength={100} className="form-control" containerClassName="mb-4 mt-4" id="Address" aria-describedby="Address" label='Address 2' onIonChange={(e) => { setAddress2(e.detail.value?.replace(/\s{2,}/g, ' ') ?? '') }} ></Textbox>
                <div className="row mb-4">
                    <div className="col-6">
                        <Textbox value={cityname.value} placeholder='City' maxlength={50} className="form-control" id="City" aria-describedby="City" label='City' error={cityname.error === '' ? false : cityname.error} onIonChange={(e) => { setCityName({ value: e.detail.value?.replace(/\s{2,}/g, ' ') ?? '', error: '' }) }} ></Textbox>
                    </div>
                    <div className="col-6">
                        <Textbox value={zipCode.value ?? ''} placeholder='Zip Code' maxlength={15} type='number' className="form-control" id="ZipCode" aria-describedby="ZipCode" label='Zip Code' error={zipCode.error === '' ? false : zipCode.error} onIonChange={(e) => { setZipCode({ value: parseInt(e.detail.value ?? '') || undefined, error: '' }) }} ></Textbox>
                    </div>
                </div>
                {!props.selectedAddress ?
                    <div className="text-right">
                        <button type="button" className="btn btn--shapeless mr-4" onClick={props.onCancel}>Cancel</button>
                        <button className="btn btn--primary" name='add' onClick={addContactAddress}>Save</button>
                    </div>
                    :
                    <div className="address__row__links text-right">
                        <Button kind='shapeless_red' size='medium' className='mr-2' onClick={deleteAddress} clickConfirmation='Are you sure to delete address?'>Delete this Address</Button>
                        <Button kind='shapeless' size='medium' className="" onClick={props.onCancel}>Cancel</Button>
                        <button type="button" className="btn btn--primary btn--medium mr-2" name='save' onClick={addContactAddress}>Save</button>
                    </div>
                }
            </div>
        </div>
    )
}*/}

const AddressListView = (props: IAddressesSectionProps.ListViewProps): JSX.Element => {
    const [lookupData] = useFetchLookupsService('address_type')
    /*const add = (e: React.MouseEvent) => {
       e.preventDefault();
       props.onAdd();
   };*/

    return (
        <>

            {props.loading ?
                <div className="card p-3 mb-5">
                    <div className='p-3'>
                        <h3 tabIndex={0}>Addresses</h3>

                        <div className="loading__textbox mb-2"></div>

                        <label htmlFor="Address2">Address 2</label>
                        <div className="loading__textbox mb-5"></div>


                        <div className="loading__textbox mb-2"></div>


                        <div className="row mb-4">
                            <div className="col-6">
                                <label htmlFor="City">City</label>
                                <div className="loading__textbox mb-2"></div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="ZipCode">Zip Code</label>
                                <div className="loading__textbox mb-2"></div>
                            </div>
                        </div>

                        <div className="text-right">
                            <button className="btn btn--primary" disabled>Add</button>
                        </div>
                    </div>


                </div>

                :
                <div className={"card p-0"}>
                    <div className={"p-3"}>

                        <div className="pl-3 pr-3 pt-3">
                            <h3 tabIndex={0}>Addresses</h3>
                        </div>

                        {props.addressList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    {props.selectedAddress !== item &&
                                        <div className={!props.isOpen ? "pl-0 address__row" : "address__row"}>
                                            <div className="address__row__desc">
                                                <div className="input_label__bold"> {lookupData?.response?.address_type?.items.filter((x) => x.short_value === item.address_type).map(item => item.long_value)} </div>
                                                {item.address1}{!!item.address2?<><br />{item.address2}</>:''}<br />{item.city}{item.city&&item.zipcode?", ":''}{(item.zipcode&&item.zipcode!=='')&&item.zipcode}{!!item.state&&","} {item.state}{!!item.country&&","} {item.country}
                                            </div> 
                                            {/* {props.isOpen &&
                                                <div className="address__row__links">
                                                   {<button type="button" className="btn btn--ordinary btn--small mr-2" onClick={() => props.onEdit(index)}>Edit</button>}
                                                    <OrdinaryButton size='small' className='mr-2' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete address?'>Remove</OrdinaryButton>
                                                </div>} */}
                                        </div>
                                    }
                                </Fragment>
                            )
                        })}
                    </div>
                    {/*props.addressList.length < props.mItem &&
                        <div className="p-3 border-top">
                            <a href="#" className='font_size--medium font_weight--semi_bold text-decoration-none pl-3' onClick={add}>+ Add Address</a>
                        </div>
                    */}
                </div>
            }
        </>

    );
        }
