import { useParams } from 'react-router';
import { HomeScreen } from 'views/screens/home/HomeScreen/HomeScreen';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { IContactOverviewScreenProps } from './ContactOverviewScreen.def';
import './ContactOverviewScreen.scss';
import { useState } from 'react';

export const ContactOverviewScreen = (_props: IContactOverviewScreenProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [reload, doReload] = useState(1);

    return (
        <ContactDetailsTemplate selectedMenu='Overview' onSwipeRefresh={() => doReload(reload + 1)}>
            <HomeScreen contactId={id} key={reload} isContactOverview={true} />
        </ContactDetailsTemplate>
    );
}
