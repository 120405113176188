import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useSearchDealService } from 'services/deals/searchDeal';
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { IContactDealScreenProps } from './ContactDealScreen.def';
import './ContactDealScreen.scss';
import { parseISO, format } from 'date-fns'
import { ProminentButton } from 'views/forms/buttons/Button';
import { Tag } from 'scripts/ui/tag';

export const ContactDealScreenApp = (_props: IContactDealScreenProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { dealid } = useParams<{ dealid: string }>();
    const [dealPop, setDealPop] = useState(false)
    const [dealId, setDealId] = useState(Number(dealid))
    const [dealView, setDealView] = useState(0)
    const [reload, doReload] = useState(1);
    const [searchDeal, searchDealAction] = useSearchDealService()
    const history = useHistory()
    useServiceDidSucceeded(searchDeal, () => {
        setDealId(0)
    });




    function formModeOpen(deal_id?: number) {
        if (deal_id) {
            setDealId(deal_id)
            history.push(`/contacts/${id}/deals/${deal_id}`)
        }
        setDealPop(true)
    }

    function init() {
        searchDealAction.fetch({ contact_id: id })
    }



    useEffect(() => {
        init()
        if (dealid) {
            formModeOpen(Number(dealid))
        } else {
            setDealId(0)
        }
    }, [])
    return (
        <>
            <DealsAdd visible={dealPop} toggleShow={() => {
                setDealPop(false);

            }} reload={() => init()} dealsId={dealId} contactId={id} doRedirect={() => history.push(`/contacts/` + id + `/deals`, { referrer: '/contacts' })} />
            <ContactDetailsTemplate selectedMenu='Deals' onSwipeRefresh={() => doReload(reload + 1)}>
                <div className="ion-padding" key={reload}>
                    <ProminentButton size='large' className="mb-4 pb-4 w-100 m-auto" onClick={() => formModeOpen()} > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>Add Deal</ProminentButton>
                    {searchDeal.loading && <div className="leadcard_app mt-3">loading</div>}
                    {searchDeal.response?.list.length === 0 &&
                        <div className="row mt-2">
                            <div className="col font_size--large">
                                There is no deal for this contact.
                            </div>
                        </div>
                    }
                    {searchDeal.response?.list.map((deal, index) =>
                        <div key={index} className="leadcard_app mt-3">
                            <div className="row small-gutters mb-4">
                                <div className="col-auto">
                                    <Tag.Div className=" border_radius--default bg--image min-h-80px min-w-140px listing_signature_photo" cssVars={{ listingSignaturePhoto: `url(${deal.property_photo ? deal.property_photo.replace('lr', 'hr') : `https://content.harstatic.com/img/common/no_image_bg.jpg`})`, width: `130px`, paddingBottom: `60%` }} ></Tag.Div>
                                </div>
                                <div className="col align-self-center font_size--medium">
                                    <div className="font_weight--semi_bold pb-2">${deal.deal_amount_formatted} Deal Value</div>
                                    {deal.property_full_street_address}<br />
                                    {deal.property_city}, {deal.property_state} {deal.property_zip}
                                </div>
                            </div>

                            <div className="mb-4">
                                <h3 className="mb-1" tabIndex={0}>{deal.title}</h3>

                                <div className="btn-group btn-group-toggle btn-group--dealstage mb-4 max-w-340px" data-toggle="buttons">
                                    <label className={`stage stage--active ${deal.deal_stage === 'A' ? `active` : ``}`}>
                                        Active/Offers
                                    </label>
                                    <label className={`stage stage--contract  ${deal.deal_stage === 'I' ? `active` : ``}`}>
                                        In Contract
                                    </label>
                                    <label className={`stage stage--close  ${deal.deal_stage === 'C' ? `active` : ``}`}>
                                        Closed
                                    </label>
                                    <label className={`stage stage--fellthrough ${deal.deal_stage === 'F' ? `active` : ``}`}>
                                        Fell Through
                                    </label>
                                </div>
                            </div>
                            <div className={dealView === deal.id ? 'text-center transition__all d-none' : 'text-center transition__all d-block'} onClick={() => setDealView(deal.id)}>
                                <div className="color_auxiliary line-h-1">View details</div>
                                <div className="ca_arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
                                        <path fill="#515b7a" fill-rule="evenodd" d="M7.687 6.328l5.83-5.83 1.658 1.657-7.458 7.458-.03-.03-.029.03L.2 2.155 1.858.498l5.83 5.83z" />
                                    </svg>
                                </div>
                            </div>
                            <div className={dealView === deal.id ? 'animate_show' : 'animate_hide'}>
                                <div className=" font_size--medium pb-4">
                                    <div className="font_weight--bold">Deal Type</div>
                                    <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal.deal_type_text}</div>

                                    <div className=" font_weight--bold">Create date</div>
                                    <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal?.created_dt && format(parseISO(deal.created_dt), 'MMM d, yyyy')}</div>

                                    <div className=" font_weight--bold">Last Updated</div>
                                    <div className="mb-2 pb-2 border-bottom border-color--cloudy-sky-light">{deal?.modified_dt && format(parseISO(deal.modified_dt), 'MMM d, yyyy')}</div>
                                    {deal.estimated_deadline
                                        &&
                                        <>
                                            <div className=" font_weight--bold">Estimated Deadline</div>
                                            <div className="mb-0">{format(parseISO(deal.estimated_deadline), 'MMM d, yyyy')}</div>
                                        </>
                                    }

                                </div>
                                <div className="font_size--large font_weight--semi_bold pb-2">Notes</div>
                                <div className=" font_size--medium mb-4">{deal.notes}</div>
                                <div className="ca_edit color_auxiliary d-inline-flex align-items-center" onClick={() => formModeOpen(deal.id)} ><a><img className="mr-2" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" /> Edit Deal</a></div>
                                <div className={dealView === deal.id ? 'text-center transition__all ca_opened' : 'text-center transition__all'} >

                                    <div className="ca_arrow m-auto text-center w-20pr" onClick={() => setDealView(0)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
                                            <path fill="#515b7a" fill-rule="evenodd" d="M7.687 6.328l5.83-5.83 1.658 1.657-7.458 7.458-.03-.03-.029.03L.2 2.155 1.858.498l5.83 5.83z" />
                                        </svg>
                                    </div>
                                    <div className="color_auxiliary line-h-1" onClick={() => setDealView(0)}>Hide details</div>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </ContactDetailsTemplate>
        </>
    );
}
