import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IIncompleteOnboardingScreenProps } from './IncompleteOnboardingScreen.def';
import './IncompleteOnboardingScreen.scss';
import { OrdinaryButton, PrimaryButton } from 'views/forms/buttons/Button';
import { history } from 'scripts/router/history';

export const IncompleteOnboardingScreen = (_props: IIncompleteOnboardingScreenProps): JSX.Element =>
{
    // Get current full url without origin
    const nextUrl = window.location.pathname + window.location.search;
    const onboardingUrl = '/onboarding?nexturl=' + encodeURIComponent(nextUrl);
    return (
        <DefaultTemplate title="Complete Onboarding">
            <div className="container pt-md-5 pt-5 bg_color_snow_white">
                <div className="cards p-0 ml-auto mr-auto max-w-1000px">
                    <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pl-0 pr-md-5 pr-0">
                        <div className="align-self-center d-flex">
                            <div className="onboarding__title onboarding__title_left ">CRM</div>
                            <div className="onboarding__title onboarding__title_right">Onboarding</div>
                        </div>
                    </div>
                    <div className="p-md-5 p-0 ml-auto mr-auto text-center">
                        <img className="mb-5 mt-4" src="https://content.harstatic.com/media/artwork/img_404.svg" alt="404 page" />
                        <p className='font_size--large_extra font_weight--bold mb-4'>Conclude your onboarding to see this page and unlock all CRM features.</p>

                        <PrimaryButton size='large' routerLink={onboardingUrl} className="mr-2">Continue Onboarding</PrimaryButton>
                        {history.length > 1 && <OrdinaryButton size='large' onClick={() => history.goBack()}>Go Back</OrdinaryButton>}
                    </div>
                </div>
            </div>
        </DefaultTemplate>
    );
}
