import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace MarkStatusRecommendedAction
{
    export const url = "/user/recommended_items/:id/:status";
    export const method = "PUT";
    export const defaultError =  "Something went wrong in mark status of this recommended item.";
    export const operations = apiOperations(MarkStatusRecommendedAction);
    
    export const scope = new class MarkStatusRecommendedItemsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        id: string,
        status:string
    }

    export interface Response
    {
        message:string,
        status:string
    }
    
}

export const fetchMarkStatusRecommendedItemsService = forwardOperation(MarkStatusRecommendedAction.operations, 'fetch');
export const useFetchMarkStatusRecommendedItemsService = forwardOperation(MarkStatusRecommendedAction.operations, 'useFetch');
export const useMarkStatusRecommendedItemsService = forwardOperation(MarkStatusRecommendedAction.operations, 'useService');