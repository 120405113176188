import { Auth } from "scripts/security/AuthService";

export function getNextUrl(): string | undefined
{
    const params = new URLSearchParams(window.location.search);
    return params.get('nexturl') ?? undefined;
}

export function handleSignIn(e:React.MouseEvent)
{
    e.preventDefault();
    const nextUrl = getNextUrl();
    Auth.signIn(nextUrl);
}

export function handleSignOut(e:React.MouseEvent)
{
    e.preventDefault();
    const nextUrl = getNextUrl();
    Auth.signOut(nextUrl);
}