import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace UpdateContactInsightApi {
    export const url = "/contacts/:contact_id/contact_insight";
    export const method = "PUT";
    export const defaultError = "Failed to update contact information.";
    export const operations = apiOperations(UpdateContactInsightApi);
    export const scope = new class UpdateContactInsightApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input,
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
            }
        }
    }();

    export interface Request {
        contact_id: string,
        score?: string;
        pipeline_status?: string;
        contact_type?: string;
        sources?: string;
        lead_type?: string;
        tags?: ITagItem[];
    }

    export interface Response {

    }

    export interface ITagItem {
        name: string;
    }

}



export const fetchUpdateContactInsightService = forwardOperation(UpdateContactInsightApi.operations, 'fetch');
export const useFetchUpdateContactInsightService = forwardOperation(UpdateContactInsightApi.operations, 'useFetch');
export const useUpdateContactInsightService = forwardOperation(UpdateContactInsightApi.operations, 'useService');