import { ISimpleCheckboxProps } from './SimpleCheckbox.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { SimpleCheckboxApp } from './SimpleCheckbox.app';
import { SimpleCheckboxDesktop } from './SimpleCheckbox.desktop';

export const SimpleCheckbox = (props: ISimpleCheckboxProps): JSX.Element =>
{
    const SimpleCheckboxPlatform = useResponsiveChoose(SimpleCheckboxDesktop, SimpleCheckboxApp);
    return (
        <SimpleCheckboxPlatform {...props} />
    );
}
