import { ICalendarTimelineScreenProps } from './CalendarTimelineScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { CalendarTimelineScreenApp } from './CalendarTimelineScreen.app';
import { CalendarTimelineScreenDesktop } from './CalendarTimelineScreen.desktop';
import { useParams } from 'react-router';

export const CalendarTimelineScreen = (props: ICalendarTimelineScreenProps): JSX.Element => {
    const { taskid } = useParams<{ taskid: string }>();
    const CalendarTimelineScreenPlatform = useResponsiveChoose(CalendarTimelineScreenDesktop, CalendarTimelineScreenApp);
    return (
        <CalendarTimelineScreenPlatform {...props} taskId={taskid} />
    );
}
