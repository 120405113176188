import { IEmailListProps } from './EmailList.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { EmailListApp } from './EmailList.app';
import { EmailListDesktop } from './EmailList.desktop';

export const EmailList = (props: IEmailListProps): JSX.Element => {
    const EmailListPlatform = useResponsiveChoose(EmailListDesktop, EmailListApp);
    return (
        <EmailListPlatform {...props} />
    );
}
