import { IGetStartedScreenProps } from './GetStartedScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { GetStartedScreenApp } from './GetStartedScreen.app';
import { GetStartedScreenDesktop } from './GetStartedScreen.desktop';

export const GetStartedScreen = (props: IGetStartedScreenProps): JSX.Element =>
{
    const GetStartedScreenPlatform = useResponsiveChoose(GetStartedScreenDesktop, GetStartedScreenApp);
    return (
        <GetStartedScreenPlatform {...props} />
    );
}
