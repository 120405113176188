import { isString } from "lodash-es";
import { Fragment, createElement } from "react";

export const joinJSXWithSeparator = (separator:string | JSX.Element, ...values:Array<JSX.Element | string | false | undefined>): JSX.Element =>
{
    const validItems =  values.filter(x => x && (isString(x)? x.trim(): true));
    if(!validItems.length) { return <></>; }

    const jsxSeparator = isString(separator)? <>{separator}</>: separator;
    const nodes = [validItems.shift()!];
    while(validItems.length)
    {
        nodes.push(jsxSeparator);
        nodes.push(validItems.shift()!);
    }

    return createElement(Fragment, {}, nodes);
}

export const joinJSXWithSpace = (...values:Array<JSX.Element | string | false | undefined>): JSX.Element =>
{
    return joinJSXWithSeparator(' ', ...values);
}

export const joinJSX = (...values:Array<JSX.Element | string | false | undefined>): JSX.Element =>
{
    return joinJSXWithSeparator('', ...values);
}