import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace BulkImportApi {
    export const url = "/contacts/bulk_import";
    export const method = "POST";
    export const defaultError = "Failed to import this time. Please try again later.";
    export const operations = apiOperations(BulkImportApi);
    export const scope = new class BulkImportApiScope implements IApiScope<Request, Response> {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        prevent_update: string,
        contacts?: IBulkContact[]
    }

    export interface Response {
        success_list: IBulkContactResponseSuccess[],
        errors_list: IBulkContactResponseError[],
        total: string,
        total_errors: string,
        total_success: string

    }
    export interface IBulkContact {
        first_name?: string,
        ref_id?: string,
        last_name?: string,
        company?: string,
        birthday?: string,
        emails?: IBulkContactEmail[],
        phones?: IBulkContactPhone[],
        addresses?: IBulkContactAddress[],
        links?: IBulkContactLink[]
    }



    export interface IBulkContactEmail {
        preferred: string
        email: string
    }

    export interface IBulkContactPhone {
        phone: string
        type?: string
        preferred: string
    }

    interface IBulkContactAddress {
        address1: string,
        address2?: string,
        address_type: string,
        city: string,
        zipcode: string
        state?: string,
        country?: string
    }

    interface IBulkContactLink {
        name: string;
        url: string;
    }

    export interface IBulkContactResponseSuccess {
        row_number: string
        email: string
        phone: string
        first_name: string
        last_name: string
        contact_id: string
        integration_id: string
        errors: any[]
    }

    export interface IBulkContactResponseError {
        ref_id: string
        row_number: string
        primar: string
        phone: string
        first_name: string
        last_name: string
        contact_id: string
        integration_id: string
        errors: IBulkContactError[]
    }


    export interface IBulkContactError {
        message: string
        reason: string
    }
}
export const fetchBulkImportService = forwardOperation(BulkImportApi.operations, 'fetch');
export const useFetchBulkImportService = forwardOperation(BulkImportApi.operations, 'useFetch');
export const useBulkImportService = forwardOperation(BulkImportApi.operations, 'useService');
