import "../ImportCsvMappedScreen.scss";
import Dropdown from 'react-bootstrap/Dropdown';
import  { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { OrdinaryButton, PrimaryButton } from "views/forms/buttons/Button";
import { OverlayTrigger, Popover  } from 'react-bootstrap';


export const SearchDropDown = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);

    const showVideo_1 = () => setShow(true);
    const showVideo_2 = () => setShow2(true);

    const [showScoreModal, setshowScoreModal] = useState(false);
    const [showStageModal, setshowStageModal] = useState(false);
    const [showSourceModal, setshowSourceModal] = useState(false);

    const [progress, setProgress] = useState(0);

    const popover_score = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Score</Popover.Title>
            <Popover.Content>
            The scoring system allows you to assign grades (e.g., A, B, C, D) to your Contacts. Assigning a Score helps to identify and prioritize the most qualified and sales-ready Contacts, enabling you to focus your efforts and allocate resources effectively.
            </Popover.Content>
        </Popover>
    );

    const popover_tags = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Tags</Popover.Title>
            <Popover.Content>
                Assigning Tags to your Contacts allows you to easily segment and group Contacts based on specific criteria, such as interests, demographics, or behaviors, enabling more targeted and personalized marketing and sales efforts.
                <br /><br />
                Use the dropdown menu to assign one of your 50 most-used Tags. You can also type the name of a Tag into the field below to search and select it.
            </Popover.Content>
        </Popover>
    );


    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    return 100;
                }
                return Math.min(oldProgress + 1, 100);
            });
        }, 100); // adjust time here
        return () => {
            clearInterval(interval);
        };
    }, []);
return (
    <>
        
        <div className="container pt-5 mt-5"> 
            <div className="pb-5 mb-5">

                <label>Score <OverlayTrigger trigger="hover" placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></label>
                <br /><br /><br /><br />
                <label>Tags <OverlayTrigger trigger="hover" placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></label>

            </div>
            
            <br /><br /><br /><br />
            <div className={`p-3 ion-no-padding contact_table_searchbar make-sticky managed`}>
                <div className='row no-gutters'>
                    <div className='col-auto border-0'>
                        <div role="checkbox" className="custom-control custom-checkbox  custom-control-inline custom-checkbox__ContactsSelection mt-2 ml-12px">
                            <input type="checkbox" id="CheckAll" name="CheckAll" className="custom-control-input"/>
                            <label className="custom-control-label mr-0" htmlFor="CheckAll"></label>
                        </div>
                    </div>
                    <div className="border-0 col">
                        <button role="button" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Download">Export</button>
                        <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small  mr-2" aria-label="Delete" >Delete</button>
                        <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete">Tags</button>
                        <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowScoreModal(true)} >Score</button>                    
                        <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowStageModal(true)} >Stage</button>
                        <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowSourceModal(true)} >Source</button>
                    </div>
                    <div className='col-auto ml-auto'>
                        <button role="button" className="btn btn--shapeless h-32px line-h-1 pr-2"><img className="mr-2 w-14px" src="https://content.harstatic.com/media/icons/close-white.svg" /></button>
                    </div>
                </div>
            </div>
            

            <DefaultPopUp title="Add Contact" isOpen={showScoreModal} onDidDismiss={() => setshowScoreModal(false)} >
                <DefaultPopUp.Body>
                    <div className="px-5 py-4">
                        <label htmlFor="PreferredPhone">First Name</label>
                        <input className="form-control mb-4" name="name" placeholder="First Name" type="text" aria-describedby="Name"></input>

                        <label htmlFor="PreferredPhone">Last Name</label>
                        <input className="form-control mb-4" name="name" placeholder="Last Name" type="text" aria-describedby="Last Name"></input>

                        <label htmlFor="PreferredPhone">Email</label>
                        <input className="form-control mb-4" name="name" placeholder="Email" type="Email" aria-describedby="Email"></input>

                        <label htmlFor="PreferredPhone">Phone number</label>
                        <input className="form-control mb-4" name="name" placeholder="Phone number" type="phone" aria-describedby="Phone number"></input>

                        <label htmlFor="PreferredPhone">Relationship Type </label>
                        <select name="relationType" id="relationType" className="custom-select custom-select_medium custom-select custom-select_large">
                            <option value="None">None</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Partner">Partner</option>
                            <option value="Child">Child</option><option value="Parent">Parent</option>
                            <option value="Friend">Friend</option>
                            <option value="Assistant">Assistant</option>
                            <option value="Wife">Wife</option>
                            <option value="Husband">Husband</option>
                            <option value="Sister">Sister</option>
                            <option value="Brother">Brother</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Son">Son</option>
                        </select>



                    </div>
                    
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <OrdinaryButton onClick={() => setshowScoreModal(false)} className="btn--simple btn--simple__auxiliary">Cancel</OrdinaryButton>
                        <PrimaryButton onClick={() => setshowScoreModal(false)} className="btn--primary btn w-100px">Save</PrimaryButton>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>


            {/*
            <DefaultPopUp title="Score" isOpen={showScoreModal} onDidDismiss={() => setshowScoreModal(false)} >
                <DefaultPopUp.Body>
                    <div className="border-bottom px-5 py-4">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">7 contact selected</div>
                        <div className="d-flex flex-wrap">
                            <div className="filterpill filterpill--closeable2"><div>David R. Putnam</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Virgl M. Hardy</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>John Frusciante</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Charles Aniston</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Jed S. Hayes</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Wartren A. Thomas</div><a href="#"></a></div>
                        </div>
                    </div>
                    <div className="px-5 py-4">                  
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Score</div>
                        <div className="d-flex">
                            <div className="choosable_checkbox">
                                <input type="radio" className="custom-control-input" name="select_score" id="radio_1" />
                                <label className="custom-control-label" htmlFor="radio_1">
                                    <div className="score_label score_label--a">
                                        <span className="score_label__alpha">A</span>
                                        <span className="score_label__text">Score</span>
                                    </div>
                                </label>
                            </div>
                            
                            <div className="choosable_checkbox">
                                <input type="radio" className="custom-control-input" name="select_score" id="radio_2" />
                                <label className="custom-control-label" htmlFor="radio_2">
                                    <div className="score_label score_label--b">
                                        <span className="score_label__alpha">B</span>
                                        <span className="score_label__text">Score</span>
                                    </div>
                                </label>
                            </div>
                            
                            <div className="choosable_checkbox">
                                <input type="radio" className="custom-control-input" name="select_score" id="radio_3" />
                                <label className="custom-control-label" htmlFor="radio_3">
                                    <div className="score_label score_label--c">
                                        <span className="score_label__alpha">C</span>
                                        <span className="score_label__text">Score</span>
                                    </div>
                                </label>
                            </div>
                            
                            <div className="choosable_checkbox">
                                <input type="radio" className="custom-control-input" name="select_score" id="radio_4" />
                                <label className="custom-control-label" htmlFor="radio_4">
                                    <div className="score_label score_label--d">
                                        <span className="score_label__alpha">D</span>
                                        <span className="score_label__text">Score</span>
                                    </div>
                                </label>
                            </div>

                            <div className="choosable_checkbox">
                                <input type="radio" className="custom-control-input" name="select_score" id="radio_5" />
                                <label className="custom-control-label" htmlFor="radio_5">
                                    <div className="score_label score_label--n">
                                        <span className="score_label__text">None Score</span>
                                    </div>
                                </label>
                            </div>

                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <OrdinaryButton onClick={() => setshowScoreModal(false)} className="btn--simple btn--simple__auxiliary">Cancel</OrdinaryButton>
                        <PrimaryButton onClick={() => setshowScoreModal(false)} className="btn--primary btn w-100px">Save</PrimaryButton>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>
             */}
            
            
            <DefaultPopUp title="Stage" isOpen={showStageModal} onDidDismiss={() => setshowStageModal(false)} >
                <DefaultPopUp.Body>
                    <div className="border-bottom px-5 py-4">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">7 contact selected</div>
                        <div className="d-flex flex-wrap">
                            <div className="filterpill filterpill--closeable2"><div>David R. Putnam</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Virgl M. Hardy</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>John Frusciante</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Charles Aniston</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Jed S. Hayes</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Wartren A. Thomas</div><a href="#"></a></div>
                        </div>
                    </div>
                    <div className="px-5 py-4">                  
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Stage</div>
                        <div className="row max-w-470">
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_1" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_1`}><div className={`stage stage stage__new`}>New</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_2" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_2`}><div className={`stage stage stage__qualified`}>Qualified</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_3" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_3`}><div className={`stage stage stage__cold`}>Cold</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_4" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_4`}><div className={`stage stage stage__warm`}>Warm</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_5" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_5`}><div className={`stage stage stage__hot`}>Hot</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_6" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_6`}><div className={`stage stage stage__undercontract`}>Active Listing</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_7" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_7`}><div className={`stage stage stage__closed`}>Closed</div></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_8" className="custom-control-input"/>
                                    <label className='custom-control-label stage' htmlFor={`Stage_8`}><div className={`stage stage`}>None</div></label>
                                </div>
                            </div>

                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <OrdinaryButton onClick={() => setshowScoreModal(false)} className="btn--simple btn--simple__auxiliary">Cancel</OrdinaryButton>
                        <PrimaryButton onClick={() => setshowScoreModal(false)} className="btn--primary btn w-100px">Save</PrimaryButton>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>


            <DefaultPopUp title="Source" isOpen={showSourceModal} onDidDismiss={() => setshowSourceModal(false)} >
                <DefaultPopUp.Body>
                    <div className="border-bottom px-5 py-4">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">7 contact selected</div>
                        <div className="d-flex flex-wrap">
                            <div className="filterpill filterpill--closeable2"><div>David R. Putnam</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Virgl M. Hardy</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>John Frusciante</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Charles Aniston</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Jed S. Hayes</div><a href="#"></a></div>
                            <div className="filterpill filterpill--closeable2"><div>Wartren A. Thomas</div><a href="#"></a></div>
                        </div>
                    </div>
                    <div className="px-5 py-4">                  
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Sources</div>
                        <div className="row max-w-470 btn-group--outline">
                            <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="None" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="None">None</label>
                                </div>
                            </div>
                                <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="HAR.com" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="HAR.com">HAR.com</label>
                                </div>
                                </div>
                                <div className="col-6">

                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="PersonalWebsite" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="PersonalWebsite">Personal Website</label>
                                </div>
                                </div>
                                <div className="col-6">
                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="DirectMail" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="DirectMail">Direct Mail</label>
                                </div>
                                </div>
                                <div className="col-6">

                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="Referral" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="Referral">Referral</label>
                                </div>
                                </div>
                                <div className="col-6">

                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="FormerCoworker" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="FormerCoworker">Former Coworker</label>
                                </div>
                                </div>
                                <div className="col-6">

                                <div role="radio" className="custom-control custom-radio">
                                    <input type="radio" id="OnlineAdvertising" name="Sources" className="custom-control-input" value="A" />
                                    <label className="custom-control-label" htmlFor="OnlineAdvertising">Online Advertising</label>
                                </div>
                                </div>
                                

                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <OrdinaryButton onClick={() => setshowScoreModal(false)} className="btn--simple btn--simple__auxiliary">Cancel</OrdinaryButton>
                        <PrimaryButton onClick={() => setshowScoreModal(false)} className="btn--primary btn w-100px">Save</PrimaryButton>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>

            
            <br /><br /><br /><br />


            <div className="cards p-0 max-w-900px">
                <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pl-0 pr-md-5 pr-0">
                    <h2 className="h2--simple h2--simple__black mb-0">Page Title</h2>
                </div>
                <div className="p-md-4 p-0">
                    <div className=" max-w-500 pl-4">
                        <a href="#" className=" color_har_blue font_size--large font_weight--semi_bold d-block">Do you want to remove contact</a>
                        <div className=" font_size--medium pb-3 border-bottom border-color--cloudy-sky-light mb-4 color_auxiliary">Description text for above link will goes here.</div>

                        <a href="#" className=" color_har_blue font_size--large font_weight--semi_bold d-block">You haven't interacted with contacts for 1 week</a>
                        <div className=" font_size--medium pb-3 border-bottom border-color--cloudy-sky-light mb-4 color_auxiliary">Description text for above link will goes here.</div>

                        <a href="#" className=" color_har_blue font_size--large font_weight--semi_bold d-block">Other bulk action link</a>
                        <div className=" font_size--medium pb-3 border-bottom border-color--cloudy-sky-light mb-4 color_auxiliary">Description text for above link will goes here.</div>
                    </div>
                    
                </div>
                <div className="p-md-4 p-0">
                
                </div>
            </div>
            
            <br /><br /><br /><br />
            <div className=" border_radius--default bg_color_cement_light p-4 color_auxiliary max-w-500">
                <div className="pb-2 font_size--medium">Importing Contacts</div>
                <ProgressBar now={progress} label={`${progress}%`}  variant={progress === 100 ? 'success' : 'primary'} />
            </div>

            <br /><br /><br /><br />

            <div className="row">
                        <div className="col">
                            <Tabs defaultActiveKey="ContactManagement" transition={false} id="ContactTabs" className="nav nav-tabs nav-tabs--simple">
                                <Tab eventKey="ContactManagement" title="Contact Management" className="mt-2">
                                    {/* Contact Management */}
                                    <a href="#" className=" text-decoration-none bg_color_cloudy_sky_light p-3 d-inline-flex mt-3 align-items-center border_radius--default">
                                        <div className="pr-2">
                                            <img src="https://content.harstatic.com/media/icons/clock_auxilary.svg" />
                                        </div>
                                        <div className="font_size--medium color_auxiliary">
                                            Importing contacts from Gmail account.
                                        </div>
                                    </a>
                                    <div className="pt-4">
                                    Contact table 
                                    </div>
                                </Tab>
                                <Tab eventKey="ContactPipeline" title="Contact Pipeline" className="mt-5">
                                    Pipline
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="col-auto">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" variant="none" className="btn w-100 btn--white btn--white__slate align-items-center d-flex btn--small pr-2 ">Watch & Learn</Dropdown.Toggle>
                                <Dropdown.Menu alignRight style={{minWidth:'250px'}}>
                                    <Dropdown.Item href="#" onClick={showVideo_1} className="d-flex align-items-center"><img className=" w-22px mr-1" src="https://content.harstatic.com/media/icons/listing_details/icon_play_for_button.svg" /><span>Contacts</span></Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={showVideo_2} className="d-flex align-items-center"><img className=" w-22px mr-1" src="https://content.harstatic.com/media/icons/listing_details/icon_play_for_button.svg" /><span>Contact Management</span></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                            <Modal show={show} onHide={handleClose} centered size="xl">
                                <Modal.Body className="p-0 position-relative">
                                    <div className=" cursor--hand position-absolute" style={{top:'-18px',right:'-28px'}} onClick={handleClose}><img className=" w-22px" src="https://content.harstatic.com/media/icons/cross_icon_white_circle.svg" /></div>
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/s8JgzBHDqmg?si=UcHtHGlFu6ZVu6dj" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </Modal.Body>                            
                            </Modal>

                            <Modal show={show2} onHide={handleClose2} centered size="xl">                
                                <Modal.Body className="p-0 position-relative">
                                    <div className=" cursor--hand position-absolute" style={{top:'-18px',right:'-28px'}} onClick={handleClose2}><img className=" w-22px" src="https://content.harstatic.com/media/icons/cross_icon_white_circle.svg" /></div>
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/YAWFPMfi6nY?si=XE2bTlgdB5AQFeMR" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                    
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>

            <br /><br /><br /><br />
            <div className="row max-w-900px">
                <div className="col-md-6 col-12">First Name</div>
                <div className="col-md-6 col-12">
                    <Dropdown className="multi_select">
                        <Dropdown.Toggle id="dropdown-basic" variant="none" className="btn w-100 btn--white btn--white__slate align-items-center d-flex btn--small pr-0 ">
                            <span className="pr-2 overflow-ellipsis d-block">First Name, Middle Name, Last Name,First Name, Middle Name, Last Name, First Name, Middle Name, Last Name, Preferred Email</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="min-w-255px pt-0" style={{willChange:'transform',maxHeight:'calc(100vh-200px)'}}>
                            <div className="p-3 position-sticky top-0 bg_color_snow_white zindex-1">
                                <input type="text" className="form-control" placeholder="Search" />
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_1" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_1">
                                        <div>First Name</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_2" checked />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_2">
                                        <div>Middle Name</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_3" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_3">
                                        <div>Last Name</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_4" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_4">
                                        <div>Preferred Email</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_5" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_5">
                                        <div>Preferred Phone</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_6" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_6">
                                        <div>Email</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_7" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_7">
                                        <div>Phone</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_7" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_7">
                                        <div>Date of Birth</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_8" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_8">
                                        <div>Company</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>
                            
                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_9" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_9">
                                        <div>Job</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_10" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_10">
                                        <div>Address Line 1</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_11" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_11">
                                        <div>Address Line 2</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_12" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_12">
                                        <div>Address City</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_13" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_13">
                                        <div>Address Type</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="pb-2 pl-3 pr-3">
                                <div className="choosable__withcheck mb-0">
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id="MultiSelect_14" />
                                    <label className="custom-control-label mb-0" htmlFor="MultiSelect_14">
                                        <div>Address Zip Code</div>
                                        <div className="check animage animate animate__fadeIn"></div>
                                    </label>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>

    </>
)};
