import { useEffect, useState } from "react";
import { Auth } from "scripts/security/AuthService";

export const useIsAuthenticated = () =>
{
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

    useEffect(() => {
        const subscription = Auth.isAuthenticated.then(setIsAuthenticated);
        return () => subscription.abort();
    }, []);

    return isAuthenticated;
}