import { IOnboardingStageHook } from "scripts/hooks/onboarding";
import { GetBasicInfoApi } from "services/user/getBasicInfo";

export enum eAccountConnectionAction
{
    Connect = 1,
    Disconnect
}

export interface IAccountConnectionProps
{
    settings?:boolean;
    justConnected?:boolean;
}

export interface IAccountConnectioContentnProps extends IAccountConnectionProps
{
    contentRef:React.MutableRefObject<HTMLIonContentElement | null>;
}

export interface IAccountConnectionChildProps
{
    settings:boolean;
    onboarding:IOnboardingStageHook.SuccessState;
    action:React.MutableRefObject<eAccountConnectionAction | undefined>;
    providerSync:ProviderSync.Result;
    contentRef:React.MutableRefObject<HTMLIonContentElement | null>;
    /**
     * Requests component to refresh
     * @param schedule In case schedule is given, refresh will only take place after promise is resolved
     */
    onRefresh?(action:eAccountConnectionAction, schedule?:Promise<unknown>): void;
}

export interface IAccountAdvancedSettingsProps
{
    user:GetBasicInfoApi.Response;
    contentRef:React.MutableRefObject<HTMLIonContentElement | null>;
    children?:React.ReactNode;
    changeEmailAccount?:()=>void;
    isConnected?:boolean
}


export enum eProviderStatus
{
    Connection = 1,
    Disconnection
}

export namespace ProviderSync
{
    export interface IActivation
    {
        status:eProviderStatus;
        onFail(): void;
    }
    export interface Result
    {
        active: boolean;
        loading: boolean;
        activate: (status:eProviderStatus, onFail:() => void) => boolean;
    }
}

export interface IAccountConnectionPopup{
    show?:boolean;
    onClose?:() => void

}
