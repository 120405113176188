import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { IDealsFilterPopoverProps } from './DealsFilterPopover.def';
import './DealsFilterPopover.scss';

export const DealsFilterPopoverApp = (props: IDealsFilterPopoverProps): JSX.Element => {
    const { show, lookupData, filters, onSave, onClear } = props
    const [dealType, setDealType] = useState("");
    const [propertType, setPropertType] = useState("");

    function search() {
        let param = {
            'property_types': propertType,
            'deal_types': dealType
        }
        onSave?.(param)

    }
    return (
        <IonModal className="gray_bg" trigger="open-modal" isOpen={show} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton strong={true} onClick={() => onClear?.()} className=' color_har_blue font-weight_semi-bold'>Reset</IonButton>
                    </IonButtons>
                    <IonTitle>Filters</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => search()} className=' color_har_blue font-weight_semi-bold'>
                            Apply
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                    <div className=" form_title__large">Property Type</div>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__fullwidth" type="radio" name="options" defaultValue={filters?.property_types}>
                        {lookupData?.response?.property_type?.items?.map((property, index) =>
                            <ToggleButton key={index} className={`btn--medium ${filters?.property_types === property.short_value ?  `font-weight_bold` : ``}`} id={property.short_value+'-deal-property'}  value={property.short_value} onClick={(e: any) => { setPropertType(e.target.value) }}>
                                {property.long_value}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </div>

                <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                    <div className=" form_title__large">Deal Type</div>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__fullwidth" type="radio" name="options" defaultValue={filters?.deal_types ? filters?.deal_types : ''}>
                        <ToggleButton className="btn--medium" id="dealType--toggle" value={''} onClick={() => { setDealType('') }}>
                            {'All'}
                        </ToggleButton>
                        {lookupData?.response?.deal_type?.items?.map((type, index) =>
                            <ToggleButton key={index} className="btn--medium" id={type.short_value + '-deal-type'} value={type.short_value} onClick={(e: any) => { setDealType(e.target.value) }}>
                                {type.long_value}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </div>

            </IonContent>
        </IonModal >
    );
}
