import { IContactsConfirmDeleteProps } from './ContactsConfirmDelete.def';
import './ContactsConfirmDelete.scss';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { ProminentButton, ShapelessButton } from 'views/forms/buttons/Button';

export const ContactsConfirmDeleteDesktop = (props: IContactsConfirmDeleteProps): JSX.Element => {
    const { show, contacts, toggleShow, unSelectAll } = props
    const removeContact = () => {
        if (props.removeContact) {
            props.removeContact("0");
            toggleShow?.()
            unSelectAll?.()
        }
    }
 
   // const areTheyProviderContacts = (contacts: any) => contacts.provider_mailbox_id === mailerBoxId?.toString()
    
    return (

        <DefaultPopUp isOpen={show} title="Delete Contact" onIonModalDidPresent={() => {}} onIonModalDidDismiss={() => toggleShow?.()}>
            <DefaultPopUp.Body>
                <div className="p-5 bg_color_snow_white">
                    {contacts.length === 1 && (contacts[0].first_name !== "" || contacts[0].last_name !== "") ?
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary mb-4">
                            {` Once you delete ${contacts[0].first_name} ${contacts[0].last_name} from your account, there is no getting it back. Make sure you want to delete this contact.`}
                            <br /><br />
                            If you delete this contact, you will:
                        </div>
                        :
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary mb-4">
                            {` Once you delete these ${contacts.length} records from your account, there is no getting it back. Make sure you want to delete this contact.`}
                            <br /><br />
                            If you delete this contact, you will:
                        </div>
                    }

                    <div className="border-bottom pb-3 mb-3 font_size--medium border-color--cloudy-sky-light">
                        <div className="row no-gutters">
                            <div className="col-auto pr-3"><img alt='img' className=' w-16px' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col color_sold">All Deals records will be deleted.</div>
                        </div>
                    </div>

                    <div className="border-bottom pb-3 mb-3 font_size--medium border-color--cloudy-sky-light">
                        <div className="row no-gutters">
                            <div className="col-auto pr-3"><img alt='img' className=' w-16px' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col color_sold">All Calendar and Documents will be deleted.</div>
                        </div>
                    </div>

                    <div className="border-bottom pb-3 mb-3 font_size--medium border-color--cloudy-sky-light">
                        <div className="row no-gutters">
                            <div className="col-auto pr-3"><img alt='img' className=' w-16px' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col color_sold">All Activity related to this contact will be deleted.</div>
                        </div>
                    </div>

                    <div className="border-bottom pb-3 mb-3 font_size--medium border-color--cloudy-sky-light d-none" >
                        <div className="row no-gutters">
                            <div className="col-auto pr-3"><img alt='img' className=' w-16px' src="https://content.harstatic.com/media/icons/close-thick.svg" /></div>
                            <div className="col color_sold">All collaborators with this contact will also lose their records.</div>
                        </div>
                    </div>
                    {/** 
                        <div className="border-bottom pt-3 pb-3 mb-3 font_size--medium border-color--cloudy-sky-light">
                            <h3>Remove contact</h3>
                            <div className="row no-gutters">
                                <div className="col-auto pr-3">
                                    <div className='d-flex justify-content-center  cursor--hand' onClick={() => setProvider(provider === "0" ? "" : "0")}>
                                        <IonCheckbox id="prevent-checkbox" slot="start" checked={provider === "0"}  ></IonCheckbox>
                                        <div className='font_size--medium font_weight--semi_bold color_auxiliary cursor--hand ml-2'>Remove from the CRM</div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className='d-flex justify-content-center cursor--hand' onClick={() => setProvider(provider === "1" ? "" : "1")}>
                                        <IonCheckbox id="prevent-checkbox" checked={provider === "1"} slot="start"  ></IonCheckbox>
                                        <div className='font_size--medium font_weight--semi_bold  color_auxiliary cursor--hand ml-2' >Remove from the CRM and Email Provider</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    */}
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="p-2">
                    <div className="row no-gutters">
                    <div className="col-auto min-w-120px"></div>
                        <div className="col text-center">
                            <ProminentButton className="btn--medium" onClick={removeContact} >Delete Permanently</ProminentButton>
                        </div>
                        <div className="col-auto min-w-120px">
                            <ShapelessButton className="btn--medium" onClick={() => toggleShow ? toggleShow() : ''}>Do Not Delete</ShapelessButton>
                        </div>
                        </div>
                    </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp>
    );
}
