import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace CitiesApi
{
    export const url = "/typeaheads/cities";
    export const method = "GET";
    export const defaultError = "Failed to load cities.";
    export const operations = apiOperations(CitiesApi);
    export const scope = new class CitiesApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(input:Record<'data', Response>) { return input.data; }
    }();
    
    export interface Request
    {
        input:string;
        limit?:number;
    }

    export type Response = Array<string>;
}

export const fetchCitiesService = forwardOperation(CitiesApi.operations, 'fetch');
export const useFetchCitiesService = forwardOperation(CitiesApi.operations, 'useFetch');
export const useCitiesService = forwardOperation(CitiesApi.operations, 'useService');