import { IonSpinner } from '@ionic/react';
import { ILoaderScreenProps } from './LoaderScreen.def';
import './LoaderScreen.scss';

export const LoaderScreen = (_props: ILoaderScreenProps): JSX.Element =>
{
    return (
        <div className="d-flex min-h-100vh align-items-center">
            <div className="text-center flex-grow-1">
                <IonSpinner className=' w-70px h-70px' color='primary' />
            </div>
        </div>
    );
}
