import { IonCheckbox } from '@ionic/react';
import { ISimpleCheckboxProps } from './SimpleCheckbox.def';
import './SimpleCheckbox.scss';

export const SimpleCheckboxApp = (props:ISimpleCheckboxProps): JSX.Element =>
{
    const { value, id } = props;
    return (
        <div>
       <div role="checkbox" aria-checked='true' >
                <IonCheckbox checked={props.checked} id={id} name={"checkbox_" + id + ""} onIonChange={e => props.onChange?.(e.detail.checked)}  >{value}</IonCheckbox>
           </div>
        </div>
        
    );
}

