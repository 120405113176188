import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IPage404Props } from './Page404.def';


export const Page404 = (props: IPage404Props): JSX.Element => {
    return (
        <DefaultTemplate title='Page not found'>
            <div className="pt-md-5 mt-md-5">
                <div className="mt-md-4 pt-5 pb-5 mb-md-5 text-center w-100">
                    <img className="mb-3" src="https://content.harstatic.com/media/icons/icon-404-error-page.svg"></img>
                    <h2 className="mb-4 text-center" tabIndex={0}>{props.title ?? 'Page not found'}</h2>
                    <div className="mb-4 text-center ml-auto mr-auto font_size--large">
                        {props.message ?? <>
                            We're sorry, but the page you are looking for does not exist. <br className="d-md-block d-none" />
                            The page may have expired or the page could have been moved to a different location.
                        </>}
                    </div>
                    <div className="mb-4 font_size--large font_weight--bold d-none">
                        Not John Frusciante? <a href="#">Switch accounts</a>
                    </div>
                    <a className="btn btn--primary" href="/home">Go to the homepage</a>
                </div>
            </div>
        </DefaultTemplate>
    );
}
