import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useValidateController } from 'scripts/hooks/validations';
import { useCreateContactService } from 'services/contacts/createContact';
import { useEffect, useRef, useState } from 'react';
import { IAddContactScreenProps } from './AddContactScreen.def';
import './AddContactScreen.scss';
import { BasicInformationSection } from 'views/contacts/BasicInformationSection/BasicInformationSection';
import { ContactInsightSection } from 'views/contacts/ContactInsightSection/ContactInsightSection';
import { AddressesSection } from 'views/contacts/AddressesSection/AddressesSection';
import { RelationshipSection } from 'views/contacts/RelationshipSection/RelationshipSection.app';
import { ContactSection } from 'views/contacts/ContactSection/ContactSection.app';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { IBasicInformationSectionForm } from 'views/contacts/BasicInformationSection/BasicInformationSection.def';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { IContactDataApp } from 'views/contacts/ContactSection/ContactSection.def';
import { IRelationItem } from 'views/contacts/RelationshipSection/RelationshipSection.def';
/*import { fetchEmailExistenceService } from 'services/contacts/emailExistence';
import { fetchPhoneExistenceService } from 'services/contacts/phoneExistence';*/
import { useHistory, useLocation } from 'react-router';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useIonAlert } from '@ionic/react';
import { IStageAddContactsProps } from 'views/contacts/pipeline/StageColumn/StageColumn.def';
import { redirectToUrlParam } from 'scripts/helpers/urls';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';
import { useMultiRef } from 'scripts/hooks/general';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { isNumber } from 'lodash-es';
import { ifElse } from 'scripts/helpers/general';
import { duplicatedContactMessage } from 'scripts/validations/messages';

 const emptyAddressForm={ 
   address1:"",
   address2:"",
   address_type:"",
   city:"",
   zipcode:""}

const emptyRelationForm = () => ({
  id:1,
  name:"",
  type:""
});
const emptyContactForm= () => ({ 
    emails: [{value:'',error:'',preferred:true,importEmail:true}], 
    phones: [{value:'',error:'',preferred:true,phone_type:"N"}], links: [] });

export const AddContactScreenApp = (_props: IAddContactScreenProps): JSX.Element => {
  //const {show,toggleShow} =props
  const [validation, validate] = useValidateController();
  const [createData, createActions] = useCreateContactService();
  const [basicInfo, setBasicInfo] = useState<IBasicInformationSectionForm>({});
  const [addresses, setAddresses] = useState<IAddressItem[]>([emptyAddressForm]);
  const [relationships, setRelationships] = useState<IRelationItem[]>([ emptyRelationForm() ]);
  const [contactInsight, setContactInsightForm] = useState<IContactInsightData>({});
  const [contactData, setContactData] = useState<IContactDataApp>(emptyContactForm);
  const history = useHistory();   
  const [isError, toggleError] = useState({ error:false });
  const formEmptyRef = useRef(true);
  const [presentError] = useIonAlert();
  const [presentCancelConfirmation, dismissCancelConfirmation] = useIonAlert();
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [ref, assignRef] = useMultiRef(modalRef);
  let location = useLocation<IStageAddContactsProps>();
  useServiceDidSucceeded(createData, () => redirectAfterSuccess())
 
  useServiceDidFailed(createData, (error) => {
    if(error.data?.is_duplicated) {
      error.preventDefault();
      presentError({
        header: 'Duplicated Contact',
        //message: error.message,
        message: duplicatedContactMessage(),
        buttons:['OK'],
        cssClass: 'p-3 line-h-1_2'
      });
    }
  });
  const showCancelConfirmation = () => {
    presentCancelConfirmation({
        header: 'Cancel',
        message: 'Are you sure you want to cancel Add contact, the data will be lost?',
        buttons: [
            { text: 'Continue to Add Contact', handler: dismissCancelConfirmation },
            { text: 'Cancel Add Contact', cssClass: 'btn btn--medium btn--primary', handler() { cancel(false); } }
        ]
    });
}

  
  const saveContact = async () => {
    const emails = contactData?.emails ?? [];
    const phones= contactData?.phones?? [];
   // if (!isValid && !emails.length) { return; }
    // List validations

    // if (!isValid) { return displayMessage("At least one required field should be filled");}
    // List validations
    //const emails = contactData?.emails ?? [];
    if (validation.loading) { return; }
    const isValid:boolean = await validate();
    if(!isValid) { return; }

    // Validating Required Fields
    if(!validateRequiredFields()) { return; }


  //  if (!!emails.length && emails[0]?.value ==='') {
  //   isValid = false;
  //    await setContactData((prev: any) => ({ ...prev, emails: [{ value: "", error: "This field is required", preferred: true, importEmail: false }] }))
  //     var myElement = document.getElementById("email");
  //     myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  //   if (!!phones.length && phones[0]?.value === '') {
  //     console.log('test');
  //     isValid = false;
  //     await setContactData((prev: any) => ({ ...prev, phones: [{ value: "", error: "This field is required", preferred: true,phone_type:""}] }))
  //   }
    //if(!isValid) { return; }
    
    const isPreferredFound = emails.find((item) => item.preferred === true)

  /*if(await checkEmailAndPhoneAvailablity()) {
      //setContactData(contactData)
      var myElement = document.getElementById("email");
      return myElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }*/

    const phonesData=(contactData?.phones.length==1 && (contactData?.phones[0]?.value.length??0) <3) ?[]: contactData?.phones.map((item: { value: string, preferred: boolean,phone_type:string, phone_extension?:number }) => ({ phone: item.value, preferred: item.preferred,phone_type:item.phone_type,phone_extension:item.phone_extension }))
    if (!isValid&& !!!emails.length&& !!!phones.length) { return toggleError({ error:true }); }
    
    const emailsData= emails.filter((item)=>item.value!=='').map((item: { value: string, preferred: boolean, importEmail: boolean }, index: number) => ({ email: item.value, preferred: !!isPreferredFound === false && index === 0 ? true : item.preferred }))
    

    createActions.fetch({
      photo_url: basicInfo.image?.value,
      first_name: basicInfo.firstName?.value,
      last_name: basicInfo.lastName?.value,
      middle_name: basicInfo.middleName?.value,
      title: basicInfo.title?.value,
      name_suffix: basicInfo.suffix?.value,
      gender: basicInfo.gender?.value,
      birthday: basicInfo!.dateOfBirth?.value,
      known_since: basicInfo!.knownSince?.value,
      income: basicInfo!.income?.value,
      job_title: basicInfo!.jobTitle?.value,
      reminder_days_after:basicInfo!.reminder_days_after?.value?.toString(),
      company: basicInfo!.company?.value,
      anniversary_date: basicInfo!.weddingAniversary?.value,
      home_anniversary_date: basicInfo!.homeAniversary?.value,
      score: contactInsight?.scoreType,
      pipeline_status: contactInsight?.pipelineType,
      sources: contactInsight?.sourceType,
      lead_type: contactInsight?.leadType,
      tags: contactInsight?.tags,
      emails:emailsData,
      phones: phonesData,
      addresses: addresses.map((item) => ({ id: item.id, address1: item.address1, address2: item?.address2, city: item.city, zipcode: item.zipcode, address_type: item.address_type,state:item.state,country:item.country })),
      relationships: relationships?.map(x => ({ type: x.type, related_contact_id:x.contact?.contact_id! })),
      links: contactData.links?.map(x => ({ name: x.link_type, url: x.value })),
      contact_type: 'None', // don't know
    });
  };

  const validateRequiredFields = (): boolean =>
  {
    const { firstName, lastName, company } = basicInfo ?? {};
    const email = contactData.emails.find(x => x.preferred)?.value?.trim();
    const phone = contactData.phones.find(x => x.preferred)?.value;
    const hasName = [firstName, lastName, company].some(x => !!x?.value?.trim());
    const hasContact = [email, phone].some(x => !!x?.trim());
    const isValid = hasName && hasContact;
    if(isValid) { return true; }

    // Showing errors
    const error = 'The field is required';

    if(!hasName) setBasicInfo(current => {
      current = current || {};
      if(!current.firstName) { current.firstName = { error, value:'' }; }
      if(!current.lastName) { current.lastName = { error, value:'' }; }
      if(!current.company) { current.company = { error, value:'' }; }
      current.firstName.error = current.lastName.error = current.company.error = error;
      return { ...current };
    });

    if(!hasContact) setContactData(current => {
      let email = current.emails.find(x => x.preferred);
      if(!email) { current.emails.unshift(email = { preferred:true, value:'', error:'', importEmail:false }); }
      email.error = error;

      let phone = current.phones.find(x => x.preferred);
      if(!phone) { current.phones.unshift(phone = { preferred:true, value:'', error:'', phone_type:'' }); }
      phone.error = error;
      phone.errorField = 'phone';

      return { ...current };
    });

    showRequiredFieldsError();

    return false;
  }

  const showRequiredFieldsError = () =>
  {
    presentError({
        header: 'Required Fields',
        message: `Please provide at least one of the following:<br />first name, last name or company, along with either a primary phone number or primary email address.`,
        buttons:['OK'],
        cssClass: 'p-5'
    });
  }

  /*const checkEmailAndPhoneAvailablity = async () => {

    const isEmailsInvalid = !await checkEmailListExistence();
    const isPhonesInvalid = !await checkPhoneListExistence();
    return isEmailsInvalid || isPhonesInvalid;
  }

  const checkEmailListExistence = async (): Promise<boolean> =>
  {
    const promises = contactData.emails.map((_, index) => checkEmailExistence(index));
    const results = await Promise.all(promises);
    return results.every(x => x);
  }

  const checkEmailExistence = async (index:number): Promise<boolean> =>
  {
    const data = contactData.emails[index]
    if(!data || !data.value?.trim()) { return true; } // valid

    const result = await fetchEmailExistenceService({ email: data.value })
    if (!result.isDuplicated) { return true; } // valid

    //data.error = 'This email already exists in the CRM, please type another email';
    setContactData(current => {
      const data = current.emails[index];
      if(data) { data.error = 'This email already exists in the CRM, please type another email'; }
      return data? { ...current }: current;
    });

    return false;
  }

  const checkPhoneListExistence = async (): Promise<boolean> =>
  {
    const promises = contactData.phones.map((_, index) => checkPhoneExistence(index));
    const results = await Promise.all(promises);
    return results.every(x => x);
  }

  const checkPhoneExistence = async(index:number): Promise<boolean> =>
  {
    const data = contactData.phones[index]
    if(!data || !data.value?.trim()) { return true; } // valid

    const phone = await fetchPhoneExistenceService({ phone: data.value })
    if (!phone.isDuplicated) { return true; } // valid
    
    setContactData(current => {
      const data = current.phones[index];
      if(data) { data.error = 'This phone already exists in the CRM, please type another phone'; }
      return data? { ...current }: current;
    });

    return false;
  }*/


  const redirectAfterSuccess = () => {
    displayMessage("The contact has been successfully added");
    if(redirectToUrlParam('redirect_url', { contact_id:createData.response?.id })) { return; }
    history.goBack();
  };

  useEffect(()=>{
    resetForm();
  },[]);

  const cancel = async (confirm: boolean) => {
    if (confirm && !formEmptyRef.current) { return showCancelConfirmation(); }
    resetForm()
   if (window.history.state) { await history.goBack(); }
   else { await history.push('/contacts'); }
  };

  const resetForm=async()=>{
    setAddresses([{ 
      address1:"",
      address2:"",
      address_type:"",
      city:"",
      zipcode:''}]);
    handleChangeContactField(0,"phones","");
    handleChangeContactField(0,"emails","");
  }
  /*** const handleAddAddress=()=>setAddresses((prev:any)=>([...prev,{id:prev?.length+1,...emptyAddressForm}]))
  const handleRemoveAddress = (index: number) => {
         addresses.splice(index, 1);
        setAddresses?.([...addresses]);
      };

   const handleChangeAddressField=(index:number,name:string,value:String)=>{
     setAddresses(current =>
           current.map(obj => {
             if (obj.id === index+1) {
               return {...obj, [name]: {value:value,error:""}};
            }
             return obj;
           }),
     );
   } */

  const handleChangeContactInsight = (data: IContactInsightData) => {
    setContactInsightForm(data);
    //setContactInsightForm({...contactInsight,[key]:value});
  }

  const handleAddRelation = () => setRelationships((prev: any) => ([...prev, { ...emptyRelationForm(), id: prev.length + 1 }]))
  const handleRemoveRelation = (index:number, _id?:string) => {
    // const index = relationships.findIndex(x => x.id === parseInt(id));
    // if(index === -1) { return; }
    
    relationships.splice(index, 1);
    setRelationships?.([...relationships]);
  };

  const handleChangeRelationField = (index: number, name: string, value: string) => {
    setRelationships(current =>
      current.map(obj => {
        if (obj.id === index + 1) {
          return { ...obj, [name]: value };
        }
        return obj;
      }),
    );
  }

  const handleAddContact = (name: string) => setContactData((prev: any) => ({ ...prev, [name]: [...prev[name], { value: "", error: "", preferred: false, importEmail: false,phone_type:"",link_type:""}] }))

  const handleRemoveContact = (name: string, index: number) => {
    if (name === "emails")
      contactData.emails.splice(index, 1)
    setContactData((prev: any) => ({ ...prev, emails: contactData.emails }));
    if (name === "phones")
      contactData.phones.splice(index, 1)
    setContactData((prev: any) => ({ ...prev, phones: contactData.phones }));
    if (name === "links")
      contactData.links.splice(index, 1)
    setContactData((prev: any) => ({ ...prev, links: contactData.links }));
  };

  const handleChangeContactField = (index: number, name: string, value: string, preferred?: boolean, importEmail?: boolean,phone_type?:string,phone_extension?:number|string,link_type?:string) => {

    setContactData(prev => {
      const { emails, phones, links } = prev;
      const item = prev[name as keyof typeof prev][index];

      // Handling preferred
      if((name === 'emails' || name === 'phones') && preferred && prev[name][index]?.preferred === false) {
        prev[name].forEach((item: any) => item.preferred = false);
      }

      // Updating data
      if(phone_extension !== undefined && !isNumber(phone_extension)) { phone_extension = ifElse(!!phone_extension.trim(), parseInt(phone_extension.trim().slice(0,6))) ?? undefined; }
      if(preferred === undefined) { preferred = item && 'preferred' in item? item.preferred: false; }
      if(name === 'emails') { emails[index] = { value: value, error: "", preferred, importEmail: importEmail || false }; }
      else if(name === 'phones') { phones[index] = { value: value, error: "", errorField:undefined, preferred, phone_type:phone_type??"", phone_extension }; }
      else if(name === 'links') { links[index] = { value: value, error: "", link_type:link_type??""}; }
      return { ...prev }
    });

    // if (name === "emails") {
    //   if (preferred !== contactData.emails[index]?.preferred) {
    //     setContactData((prev: any) => ({ ...prev, emails: contactData.emails.map((item) => ({ ...item, preferred: false })) }))
    //   }
    //   contactData.emails[index] = { value: value, error: "", preferred: preferred || false, importEmail: importEmail || false }
    //   setContactData((prev: any) => ({ ...prev, emails: contactData.emails }));
    // }
    // if (name === "phones") {
    //   if (preferred !== contactData.phones[index]?.preferred) {
    //     setContactData((prev: any) => ({ ...prev, phones: contactData.phones.map((item) => ({ ...item, preferred: false })) }))
    //   }
    //   contactData.phones[index] = { value: value, error: "", preferred: preferred || false,phone_type:phone_type??""}
    //   setContactData((prev: any) => ({ ...prev, phones: contactData.phones }));
    // }
    // if (name === "links") {
    //   contactData.links[index] = { value: value, error: "", link_type:link_type??""}
    //   setContactData((prev: any) => ({ ...prev, links: contactData.links }));
    // }
    }



  useEffect(() => {

    if (location.state) {

      if (location.state.primary_email) {
       setContactData({
          ...contactData,
          emails: [{ 'value': location.state.primary_email, 'importEmail': false, 'preferred': true, 'error': '' }]
        })
      }
      if (location.state.pipeline_status) {
        setContactInsightForm({ ...contactInsight, pipelineType: location.state.pipeline_status.toString() })
      }
    }
  }, [location]);

  // Watch for querystring parameters
  useEffect(() => {

    // Getting the following parameters from the querystring (first_name, last_name, primary_email, primary_phone)
    const params = new URLSearchParams(location.search);
    const firstName = params.get('first_name')?.trim();
    const lastName = params.get('last_name')?.trim();
    const primaryEmail = params.get('primary_email')?.trim();
    const primaryPhone = params.get('primary_phone')?.trim();

    // Setting each information
    if(firstName || lastName)
    {
      setBasicInfo((prev) => ({
        ...prev,
        firstName:{ value:firstName, error:'' },
        lastName:{ value:lastName, error:'' }
      }));
    }

    if(primaryEmail || primaryPhone)
    {
      setContactData((prev) => {
        const result = { ...prev };
        const { emails, phones } = result;
        if(!emails.length) { emails.push({ value:'', preferred: true, error: '', importEmail: false }) }
        if(!phones.length) { phones.push({ value:'', preferred: true, error: '', phone_type: '', id: undefined }) }
        if(primaryEmail) { emails[0]!.value = primaryEmail; }
        if(primaryPhone) { phones[0]!.value = primaryPhone; }
        return result;
      });
    }

    // Removing first_name, last_name, primary_email and primary_phone from query string
    ['first_name', 'last_name', 'primary_email', 'primary_phone'].forEach(x => params.delete(x));
    history.replace({ ...history.location, search:params.toString() });

  }, [location.search]);


  usePopUpAutoFocus(ref);

  return (
    <div>
      <IonModal ref={assignRef} className="gray_bg" trigger="open-modal" isOpen={true} onDidDismiss={() => history.goBack()} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar>
              <IonButtons className="w-15pr" slot="start">
                <IonButton color={"blue"} onClick={()=>cancel(true)}>Cancel</IonButton>
              </IonButtons>
              <IonTitle>Add Contact</IonTitle>
              <IonButtons className="w-15pr" slot="end">
                <IonButton color={"blue"} strong={true} onClick={saveContact}>
                  Save
                </IonButton>
              </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
          <BasicInformationSection errorRef={isError} validate={validation.signal} form={basicInfo} onFormChange={setBasicInfo} />
          <ContactInsightSection initialValues={contactInsight} onChange={handleChangeContactInsight} />
          <AddressesSection onItemsChange={setAddresses} validate={validation.signal} initialItems={addresses} />
          <RelationshipSection setRelationship={setRelationships} validate={validation.signal} handleAddNew={handleAddRelation} handleRemoveRelation={handleRemoveRelation} handleChangeField={handleChangeRelationField} items={relationships} />
          <ContactSection errorRef={isError} setContact={setContactData} validate={validation.signal} handleAddNew={handleAddContact} handleRemoveContact={handleRemoveContact} handleChangeField={handleChangeContactField} items={contactData} />
        </IonContent>
      </IonModal>
    </div>
  );
};
