
import {  IDropDownSecondaryProps,  } from './DropDownSecondary.def';
import './DropDownSecondary.scss';
import { Dropdown } from 'react-bootstrap';

export function DropDownSecondary(props: IDropDownSecondaryProps): JSX.Element {
    return (
       <Dropdown className='dropdown dropdown--custom dropdown--custom__inverse'>
            <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" variant="none" href="#">                                    
               {props.customButton?props.customButton:<div className="p-2 d-inline-block">{props.label}</div>}
            </Dropdown.Toggle>
            <Dropdown.Menu  className="dropdown-menu inline-dropdown agent_dropdown animate slideIn pt-3 pb-3" >
                        {props.children}              
            </Dropdown.Menu>
        </Dropdown>
    );
}

