import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace openDocumentApi
{
    export const url = `/contacts/:contact_id/documents/:document_id`;
    export const method = "GET";
    export const defaultError = "The File is not Opened.";

    export const operations = apiOperations(openDocumentApi);
    export const scope = new class OpenDocumentApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
                ...input

            }
        }

        response(input: Record<"data",Response>) {
            return input.data;
        }
    }();
    
    export interface Request
    {
        document_id:string;
        contact_id:string;
        no_redirect:string;
        
    }

    export type Response = { url:string
    };
}

export const fetchOpenDocumentService = forwardOperation(openDocumentApi.operations, 'fetch');
export const useFetchOpenDocumentService = forwardOperation(openDocumentApi.operations, 'useFetch');
export const useOpenDocumentService = forwardOperation(openDocumentApi.operations, 'useService');
