export enum eExternalEmbedContentSource
{
    Custom,
    Website,
    MemberOnlyArea,
    CMS
}

export interface IExternalEmbedContentProps<TCustomMessageMap extends object = {}>
{
    url:string;
    defaultTitle?:string;
    titleSeparator?:string;
    iframeTitle:string;
    source?:eExternalEmbedContentSource;
    initialPath?:string;
    queryString?:string;
    enabledInnerRoutes?:boolean;
    onMessage?<K extends keyof IMessageMap<TCustomMessageMap>>(e:IMessageEvent<TCustomMessageMap, K>): void;
    //onMessage?(e:IMessageEvent<string>): void;
}

export type IExternalEmbedContentViewProps = Omit<IExternalEmbedContentProps, 'source'>;

export interface IExternalEmbedContentIframeProps
{
    url?:string;
    visible?:boolean;
    title:string;
}

export type IMessageMap<T = {}> = IDefaultMessageMap & T;

export interface IDefaultMessageMap
{
    'com.har.pageLoad':{ url:string; title?:string; };
    'com.har.locationPush':{ url:string; redirect?:string; redirectKey?:string };
}

export type IDefaultMessageEvent<K extends keyof IDefaultMessageMap> = IMessageEvent<IDefaultMessageMap, K>;

export type IMessageEvent<V = IDefaultMessageMap, K extends keyof IMessageMap<V> = keyof IMessageMap<V>> =
    MessageEvent<{ event:K } & IMessageMap<V>[K]>;