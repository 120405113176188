import { Popover } from "react-bootstrap";

export const popover_reminder = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Reminder</Popover.Title>
        <Popover.Content>
            Select an option to receive a notification (reminder) to interact with this contact.<br></br>
            <br></br>
            You will automatically receive a notification for a birthday, any anniversary, task and deal.
        </Popover.Content>
    </Popover>
);
export const popover_reminder_text={content:<>Select an option to receive a notification (reminder) to interact with this contact.<br></br>
<br></br>
You will automatically receive a notification for a birthday, any anniversary, task and deal.</>,title:"Reminder"}