import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFetchLookupsService } from 'services/general/lookups';
import { DealsColumn } from 'views/deals/DealsColumn';
import { DealsFilterPopover } from 'views/deals/popover/DealsFilterPopover';
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IDealsLandingScreenProps } from './DealsLandingScreen.def';
import './DealsLandingScreen.scss';
import { useHistory } from 'react-router';
import { ModuleInfo } from 'views/general/ModuleInfo';

export const DealsLandingScreenApp = (props: IDealsLandingScreenProps): JSX.Element => {
    const { did, type, columns, filterLoading, locationFilter, setLocationFilter, filter, reload, redirect, onDragComplete, loadMore } = props
    const [dealPop, setDealPop] = useState(false)
    const [dealStage, setDealStage] = useState("")
    const [dealId, setDealId] = useState(0)
    const [filterButton,] = useState(!type ? 1 : type === 'expired' ? 2 : type === 'upcoming' ? 3 : 0)
    const [lookupData] = useFetchLookupsService(['deal_type', 'property_type']);
    const [dealFilterPop, setDealFilterPop] = useState(false)
    const history = useHistory()


    function formModeOpen(id: number, deal?: string) {
        setDealId(id)
        setDealPop(dealPop ? false : true)
        if (deal) {
            setDealStage(deal)
        } else {
            setDealStage("")
        }
    }

    const saveFilter = (param: IDealsLandingScreenProps.FilterType) => {
        setLocationFilter?.(param)
    }

    function dragEnd(param: any) {
        onDragComplete?.(param)
    }

    const Header = (): JSX.Element => {
        return (
            <div className="row no-gutters">
                <div className="col-auto pr-4 d-none"><div className="cursor--hand mr-2"><img className="mr-1" src="https://content.harstatic.com/media/icons/crm/icon_deals.svg"></img></div></div>
                <div className="col-auto pr-3" onClick={() => setDealFilterPop(true)}><div className="action_bar__link pr-1 cursor--hand w-30px" ><img className='ml-2' src="https://content.harstatic.com/media/icons/filters.svg" /></div></div>
                <div className="col-auto  cursor_pointer" onClick={(e: any) => {
                    e.preventDefault()
                    formModeOpen(0)
                }}><div className="cursor--hand"><img className=' w-20px' src="https://content.harstatic.com/media/icons/plus_auxilliary.svg"></img></div></div>
            </div>
        )
    }

    const redirecting = (path: string) => {
        if (!filterLoading) {
            history.push(path)
            return true;
        } else {
            return false
        }
    }







    useEffect(() => {
        if (did) {
            formModeOpen(Number(did))
        }
    }, [did])

    useEffect(() => {
        if (type) {
            if (type === 'expired') {
                filter?.({ ...locationFilter, expired: "1" })
            } else {
                filter?.({ ...locationFilter, expired_in_90_days: "1" })
            }
        } else {
            filter?.({ ...locationFilter })
        }
    }, [locationFilter])

    return (
        <>
            <DealsFilterPopover
                onSave={(param) => {
                    saveFilter(param)
                    setDealFilterPop(false)
                }}
                lookupData={lookupData}
                filters={locationFilter}
                onClear={() => {
                    setLocationFilter({});
                    saveFilter({})
                    setDealFilterPop(false)
                }}
                show={dealFilterPop}
            />
            <DealsAdd visible={dealPop} stage={dealStage} toggleShow={() => {
                setDealId(0);
                setDealPop(false);
                redirect?.()
            }} reload={() => {
                setDealId(0);
                reload?.(type)
            }} dealsId={dealId} type={type} />

            <DefaultTemplate title='Deals' CustomMobileHeader={Header} selectedMenu={'Deals'} noContent={true}>
                <div className="dealsapp_wrapper">
                    <div className=' ion-padding-start ion-padding-end'>

                        <div className="row mb-2">
                            <div className="col">
                                <div className="btn-group--custom  btn-group btn-group-toggle" defaultValue={filterButton}>
                                    <label id="tbg-radio-1" className={`btn ${type === '' && 'active'} btn-primary`} onClick={() => redirecting(`/deals`)}>All deals</label>
                                    <label id="tbg-radio-2" className={`btn ${type === 'expired' && 'active'} btn-primary`} onClick={() => redirecting(`/deals-expired`)}>Expired</label>
                                    <label id="tbg-radio-3" className={`btn ${type === 'upcoming' && 'active'} btn-primary`} onClick={() => redirecting(`/deals-upcoming`)}>Next 90 days</label>
                                </div>
                            </div>
                        </div>
                        <div className=" mb-2">
                            <ModuleInfo type="deals" />
                        </div>
                    </div>

                    <div className='horizental_scroll'>
                        <div className="horizental_scroll__inner">
                            <div className="pipeline_list">
                                <DragDropContext onDragEnd={dragEnd} >
                                    {columns?.map((column) =>
                                        <Droppable key={column.type} droppableId={`${column.type}`} type="deals">
                                            {(provided, _snapshot) => (
                                                <div className='pipeline_col__wrapper deals_col__wrapper'>
                                                    <div
                                                        className='deals_column min-w-255px mr-20px'
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        <DealsColumn
                                                            key={column.type}
                                                            column={column}
                                                            openAddPopUp={() => formModeOpen(0, column.type)}
                                                            openEditPopUp={(deal_id: number) => {
                                                                if (did !== deal_id.toString()) {
                                                                    setDealPop(false);
                                                                    redirect?.(deal_id.toString())
                                                                } else {
                                                                    formModeOpen(deal_id)
                                                                }
                                                            }}
                                                            filterLoading={filterLoading}
                                                            loadMore={loadMore}
                                                        />

                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    )}
                                </DragDropContext>
                            </div>
                        </div>
                    </div>

                </div>
            </DefaultTemplate>
        </>
    );
}



