import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"
import { formatStringToSystemTime, formatSystemDateShort } from "scripts/helpers/dates";
import { booleanToStr } from "scripts/helpers/texts";

export namespace UpdateCalendarTaskApi {
    export const url = "/tasks/:task_id";
    export const method = "PUT";
    export const defaultError = "Failed to load tasks.";
    export const operations = apiOperations(UpdateCalendarTaskApi);
    export const scope = new class UpdateCalendarTaskApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            const endTime = input.category === "e" ? `${formatSystemDateShort(input.end_dt)} ${formatStringToSystemTime(input.end_time)}` : `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}`
            const startTime = input.category === "e" ? `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}` : `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}`
            return {
                ...input,
                start_dt: startTime,
                end_dt: endTime,
                repeat_end_date: formatSystemDateShort(input.repeat_end_date),
                completed: booleanToStr(input.completed),
                notify_contacts: booleanToStr(input.notify_contacts),
                start_time: "",
                end_time:"",
            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
            "task_id": string,
        "category": string,
        "title": string,
        "start_dt"?: Date,
        "start_time": string,
        "end_dt"?: Date,
        "end_time"?: string,
        "task_type": string,
        "location"?: string,
        "property_photo"?: string,
        "notes"?: string,
        "reminder_minutes_prior"?: string,
        "contact_ids": Array<string>,
        "repeat": string,
        "repeat_every_value"?: string,
        "repeat_every_text"?: string,
        "repeat_on_day"?: string,
        "repeat_end_on"?: string,
        "repeat_end_date"?: Date,
        "repeat_month_option"?: string,
        "priority": string,
        "completed": boolean,
        "notify_contacts": boolean,
        "property_harid"?: string,
        "property_listingid"?: string,
        "property_for"?: string,
        "property_full_street_address"?: string,
        "property_address2"?: string,
        "property_city"?: string,
        "property_state"?: string,
        "property_zip"?: string,
        "option_id"?:string,
    }

    export interface Response {

    }
}

export const fetchUpdateCalendarTaskService = forwardOperation(UpdateCalendarTaskApi.operations, 'fetch');
export const useFetchUpdateCalendarTaskService = forwardOperation(UpdateCalendarTaskApi.operations, 'useFetch');
export const useUpdateCalendarTaskService = forwardOperation(UpdateCalendarTaskApi.operations, 'useService');