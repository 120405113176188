import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import "./styles";
/* Screens */
import { HomeLandingScreen } from 'views/screens/home/HomeLandingScreen';
//import { TaskScreen } from 'views/screens/TaskScreen';
import { GetStartedScreen } from 'views/screens/onboarding/GetStartedScreen';
import { AccountConnection, SettingsAccountConnection } from 'views/screens/onboarding/AccountConnection/AccountConnection';
import { LoginCallbackScreen, LoginScreen, LogoutScreen } from 'views/screens/auth/LoginScreen';
import { ExternalLoginCallbackScreen, ExternalLogoutScreen, ExternalLogoutCallbackScreen, ExternalLoginScreen } from 'views/screens/auth/ExternalLoginScreen';
import { ContactsLandingScreen } from 'views/screens/contacts/ContactsLandingScreen';
import { CalendarLandingScreen } from 'views/screens/calendar/CalendarLandingScreen';
import { AddContactScreen } from 'views/screens/contacts/AddContactScreen';
import { ContactDocumentsScreen } from 'views/screens/contacts/ContactDocumentsScreen';
import { WelcomeScreen } from 'views/screens/onboarding/WelcomeScreen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useHistory } from 'react-router-dom';
import { PopUpSpace } from 'views/spaces/PopUpSpace';
import { ContactOverviewScreen } from 'views/screens/contacts/ContactOverviewScreen';
import { ContactDetailScreen, EditContactDetailScreen } from 'views/screens/contacts/ContactDetailScreen';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { ContactPipelineScreen } from 'views/screens/contacts/ContactPipelineScreen';
import { ContactCalendarScreen } from 'views/screens/contacts/ContactCalendarScreen';
import { DealsLandingScreen } from 'views/screens/deals/DealsLandingScreen';
import { ContactEmailScreen } from 'views/screens/contacts/ContactEmailScreen';
import { EmailLandingScreen } from 'views/screens/emails/EmailLandingScreen';
import { RouteBuilder } from 'scripts/router/RouteBuilder';
import { ContactDealScreen } from 'views/screens/contacts/ContactDealScreen';
import { FeedbackSpace } from 'views/spaces/FeedbackSpace';
import { Notification } from 'views/screens/onboarding/NotificationScreen';
import { NotificationScreen } from 'views/screens/NotificationScreen';
import { ImportContacts } from 'views/screens/onboarding/ImportContacts';
import { Page404 } from 'views/screens/Page404';
import { setupIonicReact, isPlatform } from '@ionic/react';
import { GlobalSearchScreen } from 'views/screens/GlobalSearchScreen';
import { InitialScreen } from 'views/screens/InitialScreen';
import { platform } from 'scripts/context';
import { eOnboardingStage } from 'scripts/enums';
import { history } from 'scripts/router/history';
import { Auth } from 'scripts/security/AuthService';
import { ErrorBoundary } from "react-error-boundary";
import { InternalErrorScreen } from 'views/screens/errors/InternalErrorScreen';
import { ImportProviderContactScreen } from 'views/screens/contacts/ImportProviderContactScreen';
import { ImportProviderResultScreen } from 'views/screens/contacts/ImportProviderResultScreen';
import { EmailMarketingScreen } from 'views/screens/services/EmailMarketingScreen';
import { MarketInsightScreen } from 'views/screens/services/MarketInsightScreen';
import { TestScreen } from 'test';
import { PlaygroundScreen } from './views/screens/development/PlaygroundScreen';
import { isLocal, isProduction } from 'scripts/application/settings';
import { TrainingVideosScreen } from 'views/screens/support/TrainingVideosScreen';
import { ImportCsvMappedScreen } from 'views/screens/contacts/ImportCsvMappedScreen';
import { MyTransactionsScreen } from 'views/screens/services/MyTransactionsScreen';
import { ContactTransactionsScreen } from 'views/screens/contacts/ContactTransactionsScreen';
import { SupportScreen } from 'views/screens/support/SupportScreen';
import { NotesScreen } from 'views/screens/contacts/NotesScreen/NotesScreen';
import { ServicesLandingScreen } from 'views/screens/services/ServicesLandingScreen';
import { ContactServicesScreen } from 'views/screens/contacts/ContactServicesScreen';
import { ClientPortalScreen } from 'views/screens/services/ClientPortalScreen';

setupIonicReact({
  rippleEffect: false,
  mode: isPlatform('ios') ? 'ios' : 'md',
  innerHTMLTemplatesEnabled: true
});

const ScreenNotFound = () => {
  const location = useLocation();
  return <div><h3>No match for <code>{location.pathname}</code></h3></div>;
};

const RedirectTo = (path: string) => () => <Redirect to={path} />;

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  React.useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split(':/').pop();
      if (slug) {
        history.push(slug);
      }

    });
  }, []);

  return null;
};

const routes = RouteBuilder.New()
  .Private({ title: "My Dashboard", exact: true, path: "/home", component: HomeLandingScreen })
  .Private({ title: "My Dashboard", exact: true, path: "/home/task/:taskid", component: HomeLandingScreen })

  .Public({ path: '/', exact: true, component: platform.isApp ? RedirectTo('/home') : InitialScreen })

  .Private({ title: "Notifications", exact: true, path: "/notifications", component: NotificationScreen })
  .Private({ title: "GlobalSearch", exact: true, path: "/search", component: GlobalSearchScreen })
  .Private({ title: "GlobalSearch", exact: true, path: platform.isApp ? "/search" : "/search/:input", component: GlobalSearchScreen })

  .Private({ path: '/settings/account', title: "Settings", exact: true, component: SettingsAccountConnection })
  .Redirect({ path: '/settings', to: '/settings/account' })

  .Group({ path: "/onboarding", title: 'Onboarding' })
  .Onboarding({ path: '/welcome', title: "Welcome", exact: true, component: WelcomeScreen, step: eOnboardingStage.Welcome })
  .Onboarding({ path: '/basic', title: "Get Started", exact: true, component: GetStartedScreen, step: eOnboardingStage.BasicInfo })
  .Onboarding({ path: '/account', title: "Account Connection", exact: true, component: AccountConnection, step: eOnboardingStage.AccountConnection })
  .Onboarding({ path: '/contacts', title: "Contacts", exact: true, component: ImportContacts, step: eOnboardingStage.ContactImport })
  .Onboarding({ path: '/notification', title: "Notification", exact: true, component: Notification, step: eOnboardingStage.Complete })

  /** Temporary until Routing System is updated */
  .Onboarding({
    step: eOnboardingStage.Complete,
    path: '/', exact: true, component: RedirectTo('/home')
    /*fetchGetBasicInfoService().then(response => {
      props.history.replace(response.provider_email ? '/home' : '/onboarding/account');
    });*/
  })
  .EndGroup()

  .Group({ path: '/contacts', title: 'Contacts' })
  .Private({ path: '/', title: 'Contact Management', exact: true, component: ContactsLandingScreen })
  .Private({ path: '/pipeline', title: "Pipeline", exact: true, component: ContactPipelineScreen })
  .Private({ path: '/create', title: "New Contact", exact: true, component: AddContactScreen })
  .Private({ path: '/import', title: "Contact Import", exact: true, component: ImportProviderContactScreen })
  .Private({ path: '/import/result/:importId', title: "Contact Import Result", exact: true, component: ImportProviderResultScreen })
  .Private({ path: '/import/csv-mapping', title: "Contact Csv Mapped", exact: true, component: ImportCsvMappedScreen })



  .Group({ path: '/:id', title: 'Contact Profile' })
  .Private({ path: '/overview', title: "Overview", exact: true, component: ContactOverviewScreen })
  .Private({ path: '/overview/task/:taskid', title: "Calendar", exact: true, component: ContactOverviewScreen })
  .Private({ path: '/calendar', title: "Calendar", exact: true, component: ContactCalendarScreen })
  .Private({ path: '/calendar/task/:taskid', title: "Calendar", exact: true, component: ContactCalendarScreen })
  .Private({ path: '/documents', title: "Documents", exact: true, component: ContactDocumentsScreen })
  .Private({ path: '/deals/:dealid', title: "Deals", exact: true, component: ContactDealScreen })
  .Private({ path: '/deals', title: "Deals", exact: true, component: ContactDealScreen })
  .Private({ path: '/emails', title: "Emails", exact: false, component: ContactEmailScreen })
  .Private({ path: '/services/:path*', title: "Services", component: ContactServicesScreen })
  .Private({ path: '/transactions/:path*', title: "Transactions", component: ContactTransactionsScreen })
  .Private({ path: '/detail', title: "Detail", exact: true, component: ContactDetailScreen })
  .Private({ path: '/notes', title: "Notes", exact: true, component: NotesScreen })

  .Group({ path: '/detail', title: "Detail" })
  .Private({ path: '/edit', title: "Detail", exact: false, component: EditContactDetailScreen })
  .EndGroup()

  .Redirect({ path: '/', exact:true, to: 'overview' })
  .Private({
    path: '/*', exact: false,
    render: () => (
      <ContactDetailsTemplate>
        <ScreenNotFound />
      </ContactDetailsTemplate>
    )
  })
  .EndGroup()

  .EndGroup()



  .Group({ path: '/emails', title: 'Emails' })
  .Private({ path: '/inbox', title: "Email Detail", component: EmailLandingScreen })
  .Private({ path: '/lead', title: "Leads Email", component: EmailLandingScreen })
  .Private({ path: '/draft', title: "Draft Mails", component: EmailLandingScreen })
  .Private({ path: '/sent', title: "Sent Mails", component: EmailLandingScreen })
  .Private({ path: '/forwarded', title: "Forward", component: EmailLandingScreen })
  .EndGroup()

  .Group({ path: '/calendar', title: 'Calendar' })
  .Private({ path: '/', exact: true, component: CalendarLandingScreen })
  .Private({ path: '/task/:taskid', title: "Calendar Detail", exact: true, component: CalendarLandingScreen })
  .EndGroup()

  .Group({ path: '/deals', title: 'Deals' })
  .Private({ path: '/', exact: false, component: DealsLandingScreen })
  .Private({ path: '-:type', exact: false, component: DealsLandingScreen })
  .EndGroup()


  .Public({ path: '/404', title: "Overview", exact: true, component: Page404 })
  .Public({ path: "/login", title: 'Login', exact: true, component: Auth.isLoginExternal ? ExternalLoginScreen : LoginScreen })
  .Public({ path: "/login/callback", title: 'Login', component: Auth.isLoginExternal ? ExternalLoginCallbackScreen : LoginCallbackScreen, exact: true })
  .Public({ path: "/logout", title: 'Logout', component: Auth.isLoginExternal ? ExternalLogoutScreen : LogoutScreen, exact: true })
  .Public({ path: "/logout/callback", title: 'Logout', component: Auth.isLoginExternal ? ExternalLogoutCallbackScreen : Page404, exact: true })

  .Group({ path: '/services' })
  .Private({ path: '/', exact:true, title: "Services", component: ServicesLandingScreen })
  .Private({ path: '/emailmarketing/:path*', title: "EmailMarketing", component: EmailMarketingScreen })
  //.Private({ path: '/emailmarketingprod/:path*', title: "EmailMarketing", component: EmailMarketingProdScreen })
  .Member({ path: '/marketinsight/:path*', component: MarketInsightScreen })
  .Private({ path: '/mytransactions/:path*', title: "My Transactions", component: MyTransactionsScreen })
  .Private({ path: '/client-portal/:path*', title: "Client Portal", component: ClientPortalScreen })
  .EndGroup()

  .Group({ path: '/support', title: 'Support' })
  .Private({ path: '/', exact: true, component: SupportScreen })
  .Private({ path: '/training', title: "Training", component: TrainingVideosScreen })
  .EndGroup()

  .Private({ path: '/test', title: 'Test Playground', component: isLocal ? TestScreen : Page404 })
  .Group({ path: '/playground', title: 'Test Playground' })
    .Private({ path: '/', exact:true, component: !isProduction ? PlaygroundScreen : Page404 })
    .Private({ path: '/:page', component: !isProduction ? PlaygroundScreen : Page404 })
  .EndGroup()

  .Build();


const CRMApp: React.FC = () => (
  <ErrorBoundary fallbackRender={props => <InternalErrorScreen {...props} />}>
    <IonApp>
      <IonReactRouter basename={process.env.PUBLIC_URL} history={history}>
        <AppUrlListener></AppUrlListener>
        <IonRouterOutlet basePath={process.env.PUBLIC_URL}>
          <Switch>
            {routes}

            {/* <PrivateRoute title="My Dashboard" exact path="/home" component={HomeScreen} />
          <PrivateRoute title="Tasks" exact path="/tasks" component={TaskScreen} />
          <Route path="/onboarding" render={({ match }) => (<>
            <PrivateRoute title="Welcome" exact path={`${match.path}/welcome`} component={WelcomeScreen} />
            <PrivateRoute title="Basic Information" exact path={`${match.path}/basic`} component={BasicInfoScreen} />
          </>)} />

          <PrivateRoute exact path='/contacts/' component={ContactsLandingScreen} />
          <PrivateRoute exact path='/contacts/pipeline' component={ContactPipelineScreen} />
          <PrivateRoute exact path='/contacts/create' component={AddContactScreen} />
          <PrivateRoute exact path='/contacts/:id/*'>
            <ContactDetailsTemplate>
              <ScreenNotFound />
            </ContactDetailsTemplate>
          </PrivateRoute>
          <PrivateRoute exact path='/contacts/:id/overview' component={ContactOverviewScreen} />
          <PrivateRoute exact path='/contacts/:id/calendar' component={ContactCalendarScreen} />
          <PrivateRoute exact path='/contacts/:id/documents' component={ContactDocumentsScreen} />
          <PrivateRoute exact path='/contacts/:id/detail' component={ContactDetailScreen} />
          <PrivateRoute exact path='/contacts/:id/emails' component={ContactEmailScreen} />
          <PrivateRoute title="Emails" exact path='/emails' component={EmailLandingScreen} />
          <PrivateRoute title="Calendar" exact path='/calendar/' component={CalendarLandingScreen} />
          <PrivateRoute title="Deals" exact path='/deals' component={DealsLandingScreen} />

          <Route exact path="/login" component={LoginScreen} />
          <Route path="/login/callback" component={LoginCallbackScreen} exact />
          <Route path="/logout" component={LogoutScreen} exact />

          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          */}

            <Route component={Page404}></Route>
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
      <PopUpSpace />
      <FeedbackSpace />
    </IonApp>
  </ErrorBoundary>
);

export default CRMApp;
