import { IEditContactScreenProps } from './EditContactScreen.def';
//import { useComponent } from "scripts/context";
import { EditContactScreenApp } from './EditContactScreen.app';
import { EditContactScreenDesktop } from './EditContactScreen.desktop';
import { useResponsive } from 'scripts/hooks/general';

export const EditContactScreen = (props: IEditContactScreenProps): JSX.Element =>
{
  // const [reload, doReload] = useState(1);

  const EditContactScreenPlatform = useResponsive(EditContactScreenDesktop, EditContactScreenApp);
  return (
    // <ContactDetailsTemplate selectedMenu='ContactDetails' onSwipeRefresh={() => doReload(reload + 1)}>
    <EditContactScreenPlatform {...props} />
    // </ContactDetailsTemplate>
  );
}
