import { useIonAlert } from "@ionic/react";

export function useEventConfirmation()
{
    const [presentAlert] = useIonAlert();

    return function<E>(message:string | null | undefined, eventHandler?:(e:E) => any, eventCancelHandler?:(e:E) => any)
    {
        return function(this:unknown, e:E)
        {
            if(!eventHandler) { return; }
            if(!message) { return eventHandler.call(this, e); }

            presentAlert({
                header: message,
                buttons: [
                    { text: 'Yes', role: 'confirm', handler: () => eventHandler.call(this, e) },
                    { text: 'No', role: 'cancel',handler:()=>eventCancelHandler?.call(this, e) }
                ],
            })
        };
    }
}