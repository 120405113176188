import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace UploadDocumentApi
{
    export const url = "/contacts/:contact_id/documents";
    export const method = "POST";
    export const defaultError = "Failed to save document.";
    export const operations = apiOperations(UploadDocumentApi);
    export const scope = new class UploadDocumentApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return {...data,name:data.name.length>50?data.name.slice(0,49):data.name,version:1}; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        contact_id:string;
        name:string;
        path:string;
        extension:string;
        version:string
    }

    export interface Response
    {
            status:string,
            message: string
         
    }
}

export const fetchUploadDocumentService = forwardOperation(UploadDocumentApi.operations, 'fetch');
export const useFetchUploadDocumentService = forwardOperation(UploadDocumentApi.operations, 'useFetch');
export const useUploadDocumentService = forwardOperation(UploadDocumentApi.operations, 'useService');