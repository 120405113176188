import { IContactDetailPopoverProps } from './ContactDetailPopover.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ContactDetailPopoverApp } from './ContactDetailPopover.app';
import { ContactDetailPopoverDesktop } from './ContactDetailPopover.desktop';

export const ContactDetailPopover = (props: IContactDetailPopoverProps): JSX.Element =>
{
    const ContactDetailPopoverPlatform = useResponsiveChoose(ContactDetailPopoverDesktop, ContactDetailPopoverApp);
    return (
        <ContactDetailPopoverPlatform {...props} />
    );
}
