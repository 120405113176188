import { fetchSearchContactsService, SearchContactsApi } from 'services/contacts/searchContacts';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { Typeahead } from '../Typeahead';
import { IContactsTypeaheadProps } from './ContactsTypeahead.def';
import './ContactsTypeahead.scss';
import { Avatar } from 'views/contacts/Avatar';
import { ContactTitle, Title } from 'views/general/ContactTitle';
import { useHistory } from 'react-router';
export const ContactsTypeaheadDesktop = (props: IContactsTypeaheadProps): JSX.Element => {

    return (
        <>
            <Form {...props} />
            <Selection {...props} />
        </>
    );
}

const Selection = (props: IContactsTypeaheadProps): JSX.Element => {
    const { initialValues, nonRemoveable, remove } = props;
    const history = useHistory()
    return (
        <>
            {initialValues && initialValues.length === 1 &&
                <div>
                    <label className="mb-2">Contact</label>
                    <div className="contact_closable  mb-4">
                        {!nonRemoveable &&
                            <a className="contact_closable__close" href="#" onClick={(e) => {
                                e.preventDefault()
                                remove?.()
                            }}><img className=' w-10px' src="https://content.harstatic.com/media/icons/close_medium_gra.svg" /></a>
                        }
                        <div className={`agent_signature contact_closable__info ${!props.deleted && `cursor--hand`}`}>
                            <Avatar
                                firstName={initialValues[0]?.first_name}
                                lastName={initialValues[0]?.last_name}
                                id={initialValues[0]?.id ? initialValues[0].id.toString() : "0"}
                                photo={initialValues[0]?.photo_url}
                                responsive={false}
                                link={!props.deleted ? `/contacts/${initialValues[0]?.id}/overview` : undefined}

                            />
                            <div className="agent_signature__text">
                                <div className='d-flex'>
                                    <div className="agent_signature__text__title" onClick={() => {
                                        if (!props.deleted) {
                                            history.push(`/contacts/${initialValues[0]?.id}/overview`)
                                        }
                                    }}><ContactTitle contact={initialValues[0]} />  </div>
                                    {props.deleted &&
                                        <div className='ml-auto font_weight--semi_bold text-danger mr-4'>Deleted</div>
                                    }
                                </div>
                                {initialValues[0]?.city &&
                                    <div className="agent_signature__text__desc"><img src="https://content.harstatic.com/media/icons/map-view.svg" /> {initialValues[0]?.city}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    );
}

const Form = (props: IContactsTypeaheadProps): JSX.Element => {

    const { initialValues, className, minLength, change } = props;
    const search = (async (value: string) => {
        const response = await fetchSearchContactsService({ keyword: value });
        return response.list
    })
    const mapResult = (item: SearchContactsApi.IContactSearchItem): IDropDownOption => ({ id: `${Title({ contact: item })}`, text: item.company, photo_url: item.photo_url, title: item.id.toString(), first_name: item?.first_name, last_name: item?.last_name });
    return (
        <div className={`${initialValues && initialValues?.length > 0 ? `d-none` : `d-block`}`}>
            <Typeahead.Multi label='Contact' placeholder='Search Contacts'
                search={search}
                className={className}
                mapResult={mapResult}
                initialValue={initialValues}
                minLength={minLength}
                onChange={change}
                hideSelectedItem
                fullWidth={true}
            />
        </div>
    );
}
