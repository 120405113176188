import { IAddContactScreenProps } from './AddContactScreen.def';
//import { useComponent } from "scripts/context";
import { AddContactScreenApp } from './AddContactScreen.app';
import { AddContactScreenDesktop } from './AddContactScreen.desktop';
//import { useComponent } from 'scripts/context';
import { useResponsive } from 'scripts/hooks/general';

export const AddContactScreen = (props: IAddContactScreenProps): JSX.Element =>
{
  const AddContactScreenPlatform = useResponsive(AddContactScreenDesktop, AddContactScreenApp);
  return (
      <AddContactScreenPlatform {...props} />
  );
}
