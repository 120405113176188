import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace DeleteContactsApi {
    export const url = "/contacts/bulk_delete";
    export const method = "DELETE";
    export const defaultError = "Failed to delete contact list.";
    export const operations = apiOperations(DeleteContactsApi);
    export const scope = new class DeleteContactsApiScope implements IApiScope<Request, Response>
    {
        request(data: Request) {
            return {
                contact_ids: data.contact_ids,
                delete_provider: data.delete_provider
            };
        }
        response(data: Response) { return data; }
    }();

    export interface Request {
        contact_ids: string[],
        delete_provider?: string
    }

    export interface Response {
        status: string
        message: string
    }
}

export const fetchDeleteContactsService = forwardOperation(DeleteContactsApi.operations, 'fetch');
export const useFetchDeleteContactsService = forwardOperation(DeleteContactsApi.operations, 'useFetch');
export const useDeleteContactsService = forwardOperation(DeleteContactsApi.operations, 'useService');