import { isEmpty, inRange } from "lodash-es";
import { validateEmail, validateFieldLimit } from "./general";
import { invalidEmailMessage, invalidPhoneMessage, requiredMessage } from "./messages";

export const validateFirstName = (value?:string, required:boolean = false) =>
    validateName(value, required);

export const validateLastName = (value?:string, required:boolean = false) =>
    validateName(value, required);

export const validateRequiredFields = (firstName?:string, lastName?:string, email?:string, phone?:string, company?:string) =>
{
    const hasName = [firstName, lastName, company].some(x => !!x?.trim());
    const hasContact = [email, phone].some(x => !!x?.trim());
    return {
        status: hasName && hasContact,
        name: hasName,
        contact: hasContact
    }
}

export function validateContactEmail(value?:string, required:boolean = false): string
{
    const minLength = 8;
    const maxLength = 50;

    // Validate if the field is required
    value = value?.replaceAll(" ", "");
    if(!value || isEmpty(value)) { return required? requiredMessage(): ''; }

    // Validate the field limit
    if(!validateEmail(value) || !inRange(value.length, minLength, maxLength +1)) { return invalidEmailMessage(); }

    // All validations passed
    return '';
}

export function validateContactPhone(value?:string, required:boolean = false): string
{
    const minLength = 3;
    const maxLength = 25;
    const maxDigits = 15;

    // Validate if the field is required
    value = value?.replaceAll(" ", "");
    if(!value || isEmpty(value)) { return required? requiredMessage(): ''; }

    // Validating the field limit
    if(!inRange(value.length, minLength, maxLength +1)) { return invalidPhoneMessage(); }

    // Validating the number of digits
    const digits = value.replace(/[^0-9]/g,"").length
    if(digits > maxDigits) { return invalidPhoneMessage(); }

    // All validations passed
    return '';
}

export function validateName(value?:string, required:boolean = false): string
{
    const maxLength = 50;
    let error:string = '';

    // Validate if the field is required
    value = value?.replaceAll(" ", "");
    if(!value || isEmpty(value)) { return required? requiredMessage(): ''; }

    // Validate the field limit
    error = validateFieldLimit(value, maxLength);
    if(error) { return error; }

    // All validations passed
    return '';
}