import { ICalendarTemplateProps } from './CalendarTemplate.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { CalendarTemplateApp } from './CalendarTemplate.app';
import { CalendarTemplateDesktop } from './CalendarTemplate.desktop';

export const CalendarTemplate = (props: ICalendarTemplateProps): JSX.Element =>
{
    const CalendarTemplatePlatform = useResponsiveChoose(CalendarTemplateDesktop, CalendarTemplateApp);
    return (
        <CalendarTemplatePlatform {...props} />
    );
}
