import { IAssignTagsProps } from './AssignTags.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AssignTagsApp } from './AssignTags.app';
import { AssignTagsDesktop } from './AssignTags.desktop';

export const AssignTags = (props: IAssignTagsProps): JSX.Element =>
{
    const AssignTagsPlatform = useResponsiveChoose(AssignTagsDesktop, AssignTagsApp);
    return (
        <AssignTagsPlatform {...props} />
    );
}
