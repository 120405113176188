import { useComponent } from 'scripts/context';
import { ContactsEditTagsPopUpApp } from './ContactsEditTagsPopUp.app';
import { IContactsEditTagsPopUpProps } from './ContactsEditTagsPopUp.def';
import { ContactsEditTagsPopUpDesktop } from './ContactsEditTagsPopUp.desktop';
import './ContactsEditTagsPopUp.scss';


export const ContactsEditTagsPopUp = (props: IContactsEditTagsPopUpProps): JSX.Element => {
  return useComponent(<ContactsEditTagsPopUpDesktop {...props} />, <ContactsEditTagsPopUpApp {...props} />)
}
