import { IRelationshipSectionProps, IRelationItem, IRelationshipSectionAppProps, IRelationItemErrors } from './RelationshipSection.def';
import './RelationshipSection.scss';
import { useState, useEffect, Fragment } from 'react';
//import { Textbox } from 'views/forms/textboxes/Textbox';
import { useParams } from 'react-router';
import { useAddRelationshipService } from 'services/contacts/contactDetails/addRelationship';
//import { useUpdateRelationshipsService } from 'services/contacts/contactDetails/updateRelationships';
import { useDeleteRelationshipService } from 'services/contacts/contactDetails/deleteRelationship';
//import { useFetchLookupsService } from 'services/general/lookups';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { Avatar } from '../Avatar';
import { buildTelLink, formatFullPhone } from 'scripts/application/contacts';
import { ContactTitle } from 'views/general/ContactTitle';
import { IonIcon, IonRouterLink, IonText, useIonAlert } from '@ionic/react';
import { mailto } from 'scripts/helpers/urls';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useFetchLookupsService } from 'services/general/lookups';
import { extractPhone, validateAll } from './RelationshipSection.common';
import { IContactResult } from '../popups/SimpleContactFormPopUp/SimpleContactFormPopUp.def';
import { SearchContactsApi } from 'services/contacts/searchContacts';
import { IDropDownItemTemplateProps, IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { ContactsTextbox } from 'views/forms/textboxes/ContactsTextbox';
import { DropDown } from 'views/forms/dropdowns/DropDown';
import { close } from 'ionicons/icons';
import { Button } from 'views/forms/buttons/Button';
import { ScrollIntoView } from 'views/general/ScrollIntoView';
//import { DropDown } from 'views/forms/dropdowns/DropDown';
import { allEmptyOrNone } from 'scripts/helpers/texts';

export function RelationshipSection(props: IRelationshipSectionProps): JSX.Element
{
    const { id } = useParams<{ id: string }>()
    const { initialItems, maxItems, onItemsChange } = props;
    const mItem = maxItems ?? 10;
    const [relation, setRelation] = useState<Array<IRelationItem>>(initialItems && mItem && initialItems?.length > mItem ? initialItems.slice(0, maxItems) : initialItems ? initialItems : []);
    const [selectedRelation, setSelectedRelation] = useState<number | undefined>(relation.length === 0 ? 0 : undefined);
    const [, ActionRemoveRelationship] = useDeleteRelationshipService();
    const [addRelationShipData, _ActionAddRelationship] = useAddRelationshipService();

    useServiceDidSucceeded(addRelationShipData, (response) => {
        relation[selectedRelation!]!.id = response.id;
        setRelation([...relation]);
        setSelectedRelation(undefined);
        onItemsChange?.(relation);
    })

    useEffect(() => {
        setRelation(initialItems && mItem && initialItems?.length > mItem ? initialItems.slice(0, maxItems) : initialItems ? initialItems : [])
        if (initialItems?.length! > 0 || id!) { setSelectedRelation(undefined) }
    }, [initialItems, id, mItem, maxItems])

    const add = () => setSelectedRelation(relation.length);
    const edit = (index: number) => setSelectedRelation(index);
  //  const cancel = () => setSelectedRelation(undefined)

      const remove = (index: number) => {
        const relId = relation[index]?.id?.toString()
        relation.splice(index, 1);
        setSelectedRelation(relation.length === 0 ? 0 : undefined);
        setRelation([...relation]);
        if (id&&Object.keys(id!).length > 0) {
            ActionRemoveRelationship.fetch({
                contact_id: id!,
                id: relId!,
            })
        }
    };
   
    const isRelationList = selectedRelation === undefined;
    return <ListView isOpen={isRelationList} relationList={relation} onEdit={edit} onRemove={remove} mItem={mItem} onAdd={add} {...props} />;
}

const ListView = (props: IRelationshipSectionProps.ListViewProps): JSX.Element => {
   /* const add = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onAdd();
    };*/

    return (
        <>
            {props.loading ?
                <div className="card mb-5 p-3">
                    <div className="p-3">
                        <h3 tabIndex={0}>Relationship</h3>

                        <label htmlFor="PersonName">Related Contact</label>
                        <div className="loading__textbox mb-4"></div>

                        <label htmlFor="Relationship">Relationship</label>
                        <div className="loading__textbox mb-4"></div>

                        <div className="text-right">
                            <button className="btn btn--shapeless mr-4" disabled>Cancel</button>
                            <button className="btn btn--primary" disabled>Add</button>
                        </div>
                    </div>
                </div>
                :
                <div className={"card p-0"}>
                    <div className={"p-3"}>
                        <div className="pl-3 pr-3 pt-3">
                            <h3 tabIndex={0}>Relationship</h3>
                        </div>
                        {props.relationList.map((item, index) => {
                            console.log('item', item);
                            const { contact } = item;
                            const phone = contact?.primary_phone;

                            return (
                                <Fragment key={index}>
                                    {props.addRelation !== item &&
                                        <div className={!props.isOpen ? "pl-0 address__row" : "address__row"}>

                                            <div className="address__row__desc w-100">
                                                {!!item.type && <div className="input_label__bold">{item.type}</div>}
                                                {/* {item.name} */}

                                                {contact &&
                                                <div className="row mb-4">
                                                    <div className="col-6">
                                                        <div className="agent_signature agent_signature__large mb-0">
                                                            <Avatar
                                                                id={contact.contact_id.toString()}
                                                                photo={contact.photo_url}
                                                                link={`/contacts/${contact.contact_id}`}
                                                            />
                                                            <div className="agent_signature__text">
                                                                {(contact.first_name || contact.last_name) && <IonRouterLink routerLink={`/contacts/${contact.contact_id}`} className="d-block agent_signature__text__title"><ContactTitle contact={contact} /></IonRouterLink>}
                                                                {contact.email && <a href={mailto({ to:contact.email })} className="d-block agent_signature__text__address">{contact.email}</a>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {phone &&
                                                    <div className="col-6">
                                                        <div className="pb-3">
                                                            <div className="color_auxiliary font_size--medium">Phone</div>
                                                            <a href={buildTelLink(phone.number, phone.extension, phone.country_code)} className="d-block font_weight--bold">{formatFullPhone(phone.number, phone.extension, phone.country_code)}</a>
                                                        </div>
                                                    </div>}
                                                </div>}
                                            </div>
                                            {/* {props.isOpen &&
                                                <div className="address__row__links">
                                                    {<button type="button" className="btn btn--ordinary btn--small mr-2" onClick={() => props.onEdit(index)}>Edit</button>}
                                                    <OrdinaryButton size='small' className='mr-2' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete Relationship?'>Remove</OrdinaryButton>

                                                </div>} */}

                                        </div>}
                                </Fragment>
                            )
                        })}
                    </div>
                    {/*props.relationList.length < props.mItem &&
                        <div className="p-3 border-top">
                            <a href="#" className='font_size--medium font_weight--semi_bold text-decoration-none pl-3' onClick={add}>+ Add Relationship</a>
                        </div>
                    */ }
                </div>
            }
        </>

    );
}

export namespace RelationshipSection
{
    export const Form = RelationshipForm;
}

function RelationshipForm(props:IRelationshipSectionAppProps): JSX.Element
{
    const { items, handleAddNew} = props;
    const errorsState = useState<IRelationItemErrors[]>([]);
    const [errors, setErrors] = errorsState;
    const [, _notifyChange] = useValidateConsumer(props.validate, validate);
    const [lookupData] = useFetchLookupsService('relationship');

    function validate() { return validateAll(items, setErrors); }

    const isRelationList = !!items?.length;
    return (<>
    <div className="relationship-form card p-0 mb-5">
        <IonText className="sec_title font-weight_semi-bold ml-4  mt-4 pt-2">Relationships</IonText>
        <div className="secpadding_left secpadding_right">
            {isRelationList && items.map((item,key)=>
            <FormView lookupData={lookupData.response?.relationship.items} key={key} index={key}  selectedRelation={item} errors={errors[key]}  {...props} />)}
            {items.length<10 && <ScrollIntoView className="addable_item pb-3 pt-3 border-top" onClick={handleAddNew} selector='.relationship-form .relationship-item:not(:has(~ .relationship-item))' block='center'>{/* Last relationship-item */}
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Relationship</div>
            </ScrollIntoView>}
        </div>
    </div>
    </>);
}

const FormView = (props: IRelationshipSectionAppProps.FormViewProps): JSX.Element =>
{
    const { contactId, handleRemoveRelation, index, handleChangeField, lookupData, errors = {}, items } = props;
    const [presentAlert] = useIonAlert();
    const contact = props.selectedRelation?.contact;
    const excludeContactIds = contactId? [contactId]: [];
    items.forEach(item => item.contact && excludeContactIds.push(item.contact.contact_id));
    //const phone = contact?.primary_phone;

    const onContactChange = (input?:SearchContactsApi.IContactSearchItem) =>
    {
        if(!input) { return handleChangeField(index, 'contact', undefined); }

        const { id, first_name, middle_name, last_name, primary_email:email, photo_url } = input;
        const primary_phone = extractPhone(input);
        const contact:IRelationItem['contact'] = { contact_id: id, first_name, middle_name, last_name, email, photo_url, primary_phone };
        handleChangeField(index, 'contact', contact);
    }

    const itemTemplate = (item:IDropDownOption<number>) =>
    {
        const { id, first_name, last_name, photo_url, email } = item;
        return ({ onSelect }: IDropDownItemTemplateProps) => (
            <div className="typeahead__item p-3 mb-0" onClick={() => onSelect()}>
                <div className="agent_signature agent_signature__large mb-0">
                    <Avatar id={id.toString()} firstName={first_name} lastName={last_name} photo={photo_url} />
                    <div className="agent_signature__text">
                    <div className="agent_signature__text__title"><ContactTitle contact={{ first_name, last_name }} /></div>
                    {email && <div className="agent_signature__text__address">{email}</div>}
                    </div>
                </div>
            </div>
        )
    }

    const onContactCreate = (input:IContactResult) =>
    {
        const { id:contact_id, firstName:first_name, lastName:last_name, email, phone } = input;
        const number = phone? parseInt(phone): undefined;
        const primary_phone = number ? { id:0, number }: undefined;
        const contact:IRelationItem['contact'] = { contact_id, first_name, last_name, email, primary_phone, photo_url:'', middle_name:'' };
        handleChangeField(index, 'contact', contact);
    }

    const onRemove = () =>
    {
        const handler = () => handleRemoveRelation(index, props.selectedRelation?.id?.toString());
		if(allEmptyOrNone(props.selectedRelation?.contact?.contact_id??"",props.selectedRelation?.type??"")) { return handler(); }

        presentAlert({
            header: 'Are you sure you want to delete this Relationship?',
            buttons: [
                { text: 'Yes', role: 'confirm', handler },
                { text: 'No', role: 'cancel' }
            ],
        })
    }

    return (
        <div className="relationship-item">
            <div className="row">
            <div className="col">

                {!contact && <>
                <label className='mt-4' htmlFor="PersonName">Related Contact</label>
                    <div className='typeaheader_absolute'>
                <ContactsTextbox.Single
                    initialContact={undefined}
                    placeholder="Contacts"
                            exclude={excludeContactIds}
                    showCreateContact
                    itemTemplate={itemTemplate}
                    onContactChange={onContactChange}
                    onContactCreate={onContactCreate}
                />
                </div>
                </>}

                {contact && <div className="border_radius--default bg_color_cement_light border-color--cloudy-sky-light pt-2 pl-4 pr-4 pb-1 mb-3 position-relative">
                    <div className="row mt-3 mb-4 align-items-center">

                    <div className="col-6">
                            <label>Related Contact</label>
                    <div className="agent_signature agent_signature__large mb-0">
                        <Avatar
                        id={contact.contact_id.toString()}
                        firstName={contact.first_name}
                        middleName={contact.middle_name}
                        lastName={contact.last_name}
                        photo={contact.photo_url}
                        />

                        <div className="agent_signature__text">
                        {(contact.first_name || contact.last_name) && <div className="agent_signature__text__title"><ContactTitle contact={contact} /></div>}
                        {contact.email && <div className="agent_signature__text__address">{contact.email}</div>}
                        </div>
                    </div>
                    </div>

                        <div className="col-6">
                            <label className='mt-2' htmlFor="PersonName">Choose your Relationship</label>
                            <DropDown id="relationType" name='relationType' value={props.selectedRelation?.type} className="custom-select custom-select_large" error={errors.type || false} onChange={(e) => { handleChangeField(index,'type',String(e.target.value?.replace(/\s{2,}/g, ' ') ?? '')) }}>
                            {lookupData?.map((item, index) => (
                                <option key={index} value={item.short_value}>{item.long_value}</option>
                            ))}
                            </DropDown>
                        </div>

                        <IonIcon
                            slot="icon-only"
                            icon={close}
                            color="dark"
                            className='font_size--large_extra_extra position-absolute cursor_pointer'
                            style={{ top:10, right:10 }}
                            onClick={onRemove}
                        />
                    </div>

                    {/* {phone && <div className="col-6">
                        <div className="pb-3">
                            <div className="color_auxiliary font_size--medium">Phone</div>
                            <div className="font_weight--bold">{formatFullPhone(phone.number, phone.extension, phone.country_code)}</div>
                        </div>
                    </div>} */}

                </div>
                }
            </div>
            </div>
            {!contact && <div className="address__row__links text-right" style={{textAlign:"end"}}>
                <Button kind='shapeless_red' size='medium' className=' shapeless_red' onClick={()=>onRemove()}>Delete</Button>
            </div>}
            </div>
    )
}