import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { SearchContactsApi, useSearchContactsService } from 'services/contacts/searchContacts';
import { IContactsTypeaheadProps } from './ContactsTypeahead.def';
import './ContactsTypeahead.scss';
import { ContactsTypeaheadMultiApp } from './ContactsTypeaheadMulti.app';
import { Avatar } from 'views/contacts/Avatar';
import { usePopUp, usePopUpAutoFocus } from 'scripts/hooks/popups';
import { ContactTitle } from 'views/general/ContactTitle';
import { ISimpleContactFormPopUpProps } from 'views/contacts/popups/SimpleContactFormPopUp/SimpleContactFormPopUp.def';
import { SimpleContactFormPopUp } from 'views/contacts/popups/SimpleContactFormPopUp';
import { useHistory } from 'react-router';

export const ContactsTypeaheadApp = (props: IContactsTypeaheadProps): JSX.Element => {
    return (
        props.multi ? <ContactsTypeaheadMultiApp {...props} /> :
            <>
                <Form {...props} />
                <Selection {...props} />
            </>
    );
}

const Selection = (props: IContactsTypeaheadProps): JSX.Element => {
    const { initialValues, remove, nonRemoveable } = props;
    const history = useHistory()
    return (
        <>
            {initialValues && initialValues.length === 1 &&
                <div>
                    <div className="contact_closable bg-white mb-0">
                        <div className="pb-3 color_auxiliary font_size--large w-75 pb-3">Contact</div>
                        {!nonRemoveable &&
                            <a className="contact_closable__close pt-3" href="#" onClick={(e) => {
                                e.preventDefault()
                                remove?.()
                            }}><img className=' w-10px' src="https://content.harstatic.com/media/icons/close_medium_gra.svg" />
                            </a>
                        }
                        <div className={`contact_closable__info ${!props.deleted && `cursor--hand`}`}>
                            <Avatar
                                firstName={initialValues[0]?.first_name}
                                lastName={initialValues[0]?.last_name}
                                id={initialValues[0]?.id ? initialValues[0].id.toString() : "0"}
                                photo={initialValues[0]?.photo_url}
                                responsive={false}
                                link={!props.deleted ? `/contacts/${initialValues[0]?.id}/overview` : undefined}

                            />
                            <div className="agent_signature__text">
                                <div className='d-flex'>
                                    <div className="agent_signature__text__title" onClick={() => {
                                        if (!props.deleted) {
                                            history.push(`/contacts/${initialValues[0]?.id}/overview`)
                                        }
                                    }}><ContactTitle contact={initialValues[0]} />  </div>
                                    {props.deleted &&
                                        <div className='ml-auto font_weight--semi_bold text-danger mr-4'>Deleted</div>
                                    }
                                </div>
                                {initialValues[0]?.city &&
                                    <div className="agent_signature__text__desc"><img src="https://content.harstatic.com/media/icons/map-view.svg" /> {initialValues[0]?.city}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    );
}

const Form = (props: IContactsTypeaheadProps): JSX.Element => {
    const [searchContact, searchContactAction] = useSearchContactsService()
    const contactModal = useRef<HTMLIonModalElement>(null);
    const { initialValues, change, exclude, showCreateContact, onContactCreate } = props;
    const [searchTxt, setSearchTxt] = useState("")
    const searchField = useRef<HTMLIonSearchbarElement>(null);
    const [timer, setTimer] = useState<number | undefined>();
    const [popupProps, setPopupProps] = useState<ISimpleContactFormPopUpProps>();
    const excludeList = exclude? (Array.isArray(exclude)? exclude: [exclude]): [];

    useEffect(() => {
        if(popupProps) { present(); }
      }, [popupProps]);

    const [present] = usePopUp<ISimpleContactFormPopUpProps>(SimpleContactFormPopUp, {
        ...popupProps,
        onSave(input) {
          onContactCreate?.(input)
          setPopupProps(undefined);
          contactModal?.current?.dismiss()
        },
    });

    const onContactCreateRequest = () =>
    {
        const input = searchTxt;
        const props:ISimpleContactFormPopUpProps = {};
        if(input.includes('@')) { props.email = input; }
        else {
            const [firstName, ...lastName] = input.split(' ').filter(x => x.trim());
            props.firstName = firstName;
            props.lastName = lastName.join(' ');
        }
    
        setPopupProps(props);
    }
    
    const footer = !showCreateContact? undefined: (
        <IonItem onClick={() => onContactCreateRequest()} className='pt-3 pb-3'>
            <IonLabel className="font_size--medium">
                Create Contact
            </IonLabel>
        </IonItem>
    );

    const search = (value: string) => {
        if (value.length === 0) {
            setSearchTxt('')
            return
        }
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            searchContactAction.fetch({ keyword: value });
        }, 800))
    }

    const onSelect = (contact: SearchContactsApi.IContactSearchItem) => {
        let arr = []
        arr.push(contact)
        change?.(arr)
        contactModal?.current?.dismiss()
    }

    usePopUpAutoFocus(contactModal, 2000);
    const focusFirstField = () => {
        contactModal.current?.present();
        setTimeout(() => { searchField.current?.setFocus(); }, 1000)
    }

    const isNotExcluded = (item: SearchContactsApi.IContactSearchItem) => {
        if(!excludeList.length) { return true; }
        return !excludeList.includes(item.id);
    }

    return (
        <div className={`${initialValues && initialValues?.length > 0 ? `d-none` : `d-block`}`}>
            <IonItem className="tab__nextpage " id="contact-modal" onClick={focusFirstField}>
                <div className="tab__nextpage_label ">
                    Choose a Contact
                </div>
                <div className="tab__nextpage_value">
                    None
                </div>
                <img className="tab__nextpage_arrow" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
            </IonItem>

            <IonModal id="contact-modal" ref={contactModal} trigger="contact-modal" backdropDismiss={false}>
                <IonContent>
                    <div>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons className='w-15pr' slot="start">
                                    <IonButton className='color_0738CD' onClick={() => contactModal?.current?.dismiss()}>Cancel</IonButton>
                                </IonButtons>
                                <IonTitle className=' text-center'>Add Contact</IonTitle>

                            </IonToolbar>
                            <div className="ion-padding-start ion-padding-end border-bottom">
                                <IonSearchbar value={searchField.current?.value ? searchField.current?.value : searchTxt} ref={searchField} className="custom ion-no-padding" placeholder="Search Contacts" onIonInput={(e: any) => search(e.target.value)}></IonSearchbar>
                            </div>

                        </IonHeader>


                    </div>
                    {searchContact?.loading &&
                        <LoadingState />
                    }
                    <div className="">
                        <IonRadioGroup >
                            {searchContact?.response?.list?.filter(isNotExcluded)?.map((contact) =>
                                <IonItem onClick={() => onSelect(contact)}>
                                    <IonLabel className="font_size--medium">
                                        <div className="agent_signature agent_signature__large">
                                            <Avatar
                                                firstName={contact.first_name}
                                                lastName={contact.last_name}
                                                id={contact.id.toString()}
                                                photo={contact.photo_url}
                                            />
                                            <div className="agent_signature__text">
                                                <div className="agent_signature__text__title"><ContactTitle contact={contact} /></div>
                                            </div>
                                        </div>
                                    </IonLabel>
                                    <IonRadio slot="end"></IonRadio>
                                </IonItem>
                            )}
                        </IonRadioGroup>
                    </div>
                    {footer}
                </IonContent>
            </IonModal>
        </div >

    );

}
const LoadingState = () => {
    const LoadingField = (): JSX.Element => {
        return (
            <div className="cards cards__deals_wt cursor--hand ui-sortable-handle d-block" >
                <div className="listing_signature listing_signature__small mb-3">
                    <div className="loading__photo mr-3 w-40px h-40px"></div>
                    <div className="listing_signature__details">
                        <div className="loading__text_small mb-2 w-120px"></div>
                        <div className="loading__text_small w-150px"></div>
                    </div>
                </div>

            </div >
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < 8; i++) {
        rows[i] = i
    }
    return (
        <IonItem className='py-3'>
            <div className="typeahead w-100 border-0">
                {rows.map((index: number) =>
                    <LoadingField key={index} />
                )}
            </div>
        </IonItem>

    );
}