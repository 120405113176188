
import { useResponsiveChoose } from 'scripts/hooks/general';
import { IStageColumnProps } from './StageColumn.def';
import './StageColumn.scss';
import { StageColumnDesktop } from './StageColumn.desktop';
import { StageColumnApp } from './StageColumn.app';


export const StageColumn = (props: IStageColumnProps): JSX.Element => {
    const StageColumnPlatform = useResponsiveChoose(StageColumnDesktop, StageColumnApp);
    return (
        <StageColumnPlatform {...props} />
    );
}