import { ITextboxProps } from './Textbox.def';
import './Textbox.scss';
import { IonLabel, IonInput, IonItem, IonNote } from '@ionic/react';

export function TextboxCommonApp(props: ITextboxProps): JSX.Element {
    const { containerClassName, containerId, containerStyle, error, label, className: userClassName, value: userValue, innerRef, ...ionicProps } = props;
    const value = userValue !== undefined ? userValue : null;
    return (
        <>
            {props.label && <IonLabel>{props.label}</IonLabel>}
            <IonItem>
                <IonInput type="text" {...ionicProps} value={value} ref={innerRef}
                    onIonChange={(e: any) => {
                        if (props.regex) {
                            var re = new RegExp(props.regex, "i");
                            e.target.value = e.target.value.replace(re, "")
                        }
                        props.onIonChange?.(e)
                    }}
                ></IonInput>
                {error &&
                    <IonNote slot="helper">{error}</IonNote>
                }
            </IonItem>
        </ >
    );
}

