import 'scripts/utilities/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "scripts/logging/user-feedback";
import "scripts/logging/error-report";
import "scripts/logging/matomo";
import { forceHttps } from 'scripts/application/settings';


// Redirects http to https
if(forceHttps) {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}


/*const App:React.FC = (() =>
{
  // For Production
  if(process.env.NODE_ENV !== 'development') { return DefaultApp }

  // For Development
  const { load } = require("./scripts/debugging/DebugApp");
  return load(DefaultApp);
  
})();*/

// Rendering Component
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
