import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities";


export namespace GetContactSummaryApi {
    export const url = "/contacts/:contact_id/summary";
    export const method = "GET";
    export const defaultError = "Failed to load contact information.";
    export const operations = apiOperations(GetContactSummaryApi);
    export const scope = new class GetContactSummaryApiScope implements IApiScope<Request, Response>
    {
        request(data: Request): PropertiesType<Request, string> {
            return { ...data, contact_id: data.contact_id.toString() }
        }
        response(data: Response) { return data }

        // response(input: Record<'data', PropertiesType<Response, string>>): Response {
        // return input.data
        // .map(item => ({
        //     ...item,
        //     id:parseInt(item.id),
        //     created_dt:new Date(item.created_dt)
        // }));
        // }
    }();

    export interface Request {
        contact_id: number;
        renew?:string;
    }

    // export type Response = Array<IGetContactSummary>;

    export interface Response {
        data:
        {
            id: number,
            photo_url?: string,
            first_name?: string,
            last_name?: string,
            middle_name?: string,
            known_since?: string,
            score?: string,
            pipeline_status?: string,
            pipeline_status_text?: string,
            sources?: string,
            primary_email_id?: string,
            primary_email?: string,
            primary_phone_id?: string,
            primary_phone?: string,
            primary_phone_type?:string,
            primary_phone_extension?:string,
            primary_phone_country_code?:string,
            title?:string,
            name_suffix?:string,
            is_object_owner:string,
            created_dt: Date,
            last_contact_date:Date,

            lead_type:string
        }
    }
}

export const fetchGetContactSummaryService = forwardOperation(GetContactSummaryApi.operations, 'fetch');
export const useFetchGetContactSummaryService = forwardOperation(GetContactSummaryApi.operations, 'useFetch');
export const useGetContactSummaryService = forwardOperation(GetContactSummaryApi.operations, 'useService');