import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace MarkNotificationApi
{
    export const url = "/notifications/mark";
    export const method = "POST";
    export const defaultError = "Failed to load data";
    export const operations = apiOperations(MarkNotificationApi);
    export const scope = new class MarkNotificationApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string|string[]>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        ids?: string[],
        read?: string
      }

    export interface Response
    {
        status: string,
        message:string
      }
}

export const fetchMarkNotificationService = forwardOperation(MarkNotificationApi.operations, 'fetch');
export const useFetchMarkNotificationService = forwardOperation(MarkNotificationApi.operations, 'useFetch');
export const useMarkNotificationService = forwardOperation(MarkNotificationApi.operations, 'useService');