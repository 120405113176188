import { IGlobalSearchTypeaheadProps } from './GlobalSearchTypeahead.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { GlobalSearchTypeaheadApp } from './GlobalSearchTypeahead.app';
import { GlobalSearchTypeaheadDesktop } from './GlobalSearchTypeahead.desktop';

export const GlobalSearchTypeahead = (props: IGlobalSearchTypeaheadProps): JSX.Element =>
{
    const GlobalSearchTypeaheadPlatform = useResponsiveChoose(GlobalSearchTypeaheadDesktop, GlobalSearchTypeaheadApp);
    return (
        <GlobalSearchTypeaheadPlatform {...props} />
    );
}
