import { IAddressesSectionProps, IAddressItem, IAddressItemErrors } from './AddressesSection.def';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import './AddressesSection.scss';
import { IonItem, IonText, IonSelect, IonSelectOption } from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { SetStateAction, useEffect, useState } from 'react';
import { AddressesTypeahead } from 'views/forms/typeaheads/AddressesTypeahead';
import { isString } from 'lodash-es';
import { useFetchLookupsService } from 'services/general/lookups';
import { allEmpty } from 'scripts/helpers/texts';

//import { useParams } from 'react-router';
const emptyAddress = () => ({
  address1: '',
  address2: '',
  address_type: '',
  city: '',
  state: '',
  country: '',
  zipcode: ""
})

export const AddressesSectionApp = (props: IAddressesSectionProps): JSX.Element => {
  const { initialItems, onItemsChange } = props;
  const [items, setAddresses] = useState<Array<IAddressItem>>([]);
  const [, notifyChange] = useValidateConsumer(props.validate, validate);
  const [errors, updateErrors] = useState<IAddressItemErrors[]>([]);

  const maxItems = props.maxItems ?? 10;

  useEffect(() => {
    if (initialItems === items) { return; }

    const addresses = initialItems ?? [];
    setAddresses(addresses.length > maxItems ? addresses.slice(0, maxItems) : addresses);
    notifyChange()

  }, [initialItems]);

    /*const fieldCheck = (key: any, required: boolean = true): boolean => {
    let property = key;
    const value = property.toString()?.replaceAll("  ", "")
    if (!value || value === '') {
      return !required;
    }

    if (value.length < 3) {
      return false;
    }
    return true;
  };*/


  function validate() {
    if (items.length === 0) { return true; }
  //  const fieldEmptyMsg='This field is required';


    const form = items.map((item) => {
      const errors:IAddressItemErrors = {};
      //const addressType = (item.address_type || 'None') !== 'None'? item.address_type: '';
      if(allEmpty(item.address1,item?.address2??"", item.city, item.zipcode)) { return errors; }

      //errors.address1 = !fieldCheck(item.address1) ? fieldEmptyMsg :'';
      //errors.address_type = '';//!fieldCheck(addressType, false) ? fieldEmptyMsg :'';   
      //errors.city = !fieldCheck(item.city) ? fieldEmptyMsg : '';
      //errors.zipcode = !fieldCheck(item.zipcode) ? fieldEmptyMsg : '';
      return errors;
    });

    /*const form:Array<IAddressItem>=[]; 
      items.map((item )=>{
      form.push({
        ...item,
        address1: {
          value: item.address1?.value,
          error: !fieldCheck(item.address1) ? fieldEmptyMsg : '',
        },
        city: {
          value: item.city.value,
          error: !fieldCheck(item.city) ? fieldEmptyMsg : '',
        },
        zipcode: {
          value: item.zipcode?.value,
          error: !fieldCheck(item.zipcode) ? fieldEmptyMsg : '',
        }
      });
  })*/
    updateErrors(form);

    const isValid = form.map((item) => Object.entries(item).every(([, value]) => !value)).includes(true);
    return isValid;

  }

  const handleAddNew = () => {
    updateErrors([...errors, {}]);
    updateAddresses([...items, emptyAddress()]);
  }

  const handleRemoveAddress = (index: number) => {
    items.splice(index, 1);
    errors.splice(index, 1);
    updateErrors([...errors]);
    updateAddresses([...items]);
  };

  const handleChangeField = (key: number, name: string, value: string) => updateAddresses((current: any) => {
    current[key][name] = value;
   // notifyChange();
   // name!=='address_type'&& validate();
    return current;
  });

  const updateAddresses = (value: SetStateAction<IAddressItem[]>) => {
    if ('call' in value) {
      return setAddresses((current: IAddressItem[]) => {
        const result = value(current);
        onItemsChange?.(result);
        return result;
      });
    }

    setAddresses?.(value);
    onItemsChange?.(items);
    validate()
  };

  const isAddressList = !!items?.length;
  return (<>
    <div className="mb-3 secpadding_left">
      <IonText className="sec_title font-weight_semi-bold">Address</IonText>
    </div>
    <div className="form_sec secpadding_left secpadding_right">
      {isAddressList && items.map((item, key) =>
        <FormView
          key={key}
          addressList={items}
          address={item}
          errors={errors[key]!}
          onRemove={() => handleRemoveAddress(key)}
          onUpdate={(name, value) => handleChangeField(key, name, value)}
        />
      )}
      {items.length < 10 && <div className="addable_item pb-3 pt-3" onClick={handleAddNew}>
        <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
        <div className="pl-4 align-self-center">Add Address</div>
      </div>}
    </div>
  </>)
}

const FormView = (props: IAddressesSectionProps.AppFormViewProps): JSX.Element => {
  const { addressList, address, errors, onRemove, onUpdate } = props;
  //const [address1, setAddress1] = useState({ value: '', error: '' });
    const [lookupData] = useFetchLookupsService('address_type')

  const index = address ? addressList.indexOf(address) : addressList.length;

  const onChange = (value: any) => {
    onUpdate("address1", String(value?.address1?.replace(/\s{2,}/g, ' ') ?? ''));
    onUpdate("zipcode", String(value?.zipcode));
    onUpdate("city", String(value?.city?.replace(/\s{2,}/g, ' ') ?? ''))
    onUpdate("state", String(value?.state?.replace(/\s{2,}/g, ' ') ?? ''))
    onUpdate("country", String(value?.country?.replace(/\s{2,}/g, ' ') ?? ''))

  }

  return (
    <div className="border-bottom">
      <div className="row no-gutters">
        <div className="col-auto align-self-center">
          <div className="delete_item d-flex">
              {<img onClick={()=>onRemove()}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />}
            <div className="delete_item_value">Addr. {index + 1} </div>
            <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
          </div>
        </div>
        <div className="col">
          <IonItem className=' mt-7px'>
              <IonSelect value={address?.address_type} name='address_type'  placeholder="Address Type"  onIonChange={(e) => onUpdate("address_type",e.detail.value?.replace(/\s{2,}/g, ' ') ?? '')}>
                {lookupData.response?.address_type?.items?.map((item, index) => (
                        <IonSelectOption key={index} value={item.short_value}>{item.long_value}</IonSelectOption>
                    ))}
            </IonSelect>
          </IonItem>
          {isString(errors?.address_type) && errors?.address_type?.trim() !== '' ?
            <div className=' color_sold ml-15px'>{errors?.address_type}</div> : null}
          <AddressesTypeahead initialValue={address} className="form-control" onTextChange={(value: string) => onUpdate("address1", String(value?.replace(/\s{2,}/g, ' ') ?? ''))} error={errors?.address1} change={onChange} />
            <Textbox  value={address?.address2} placeholder='Address 2' name='address2' maxlength={100} containerClassName="mb-4" id="Address" aria-describedby="Address" onIonChange={(e) => onUpdate(e.target.name, e.detail.value?.replace(/\s{2,}/g, ' ') ?? '')} ></Textbox>          
            <Textbox value={address?.city} name='city' placeholder='City' maxlength={50} id="City" aria-describedby="City" error={errors?.city} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
            <Textbox value={address?.zipcode || ''} name='zipcode' placeholder='Zip Code' maxlength={10} type='text' id="ZipCode" aria-describedby="ZipCode" error={errors?.zipcode} onIonChange={(e) => onUpdate(e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
          <Textbox value={address?.state} name='state' placeholder='State' maxlength={50} className="form-control" id="State" aria-describedby="State" error={errors?.state} onIonChange={(e) => onUpdate(e.target.name, String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>
          <Textbox value={address?.country} name='country' placeholder='Country' maxlength={50} className="form-control" id="Country" aria-describedby="Country" error={errors?.country} onIonChange={(e) => onUpdate(e.target.name, String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox>

        </div>
      </div>
    </div>
  )
}