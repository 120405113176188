import { isEqual, range } from 'lodash-es';
import { useEffect, useState } from 'react';
import { joinWithSpace } from 'scripts/helpers/texts';
import { usePrevious } from 'scripts/hooks/general';
import { LookupsApi, useFetchLookupsService } from 'services/general/lookups';
import { IContactScore, IFormOptionsProps, IOptionProps, IScoreOptionsMultiProps, IScoreOptionsSingleProps } from './ScoreOptions.def';
import './ScoreOptions.scss';
import { Dropdown } from 'react-bootstrap';

export function ScoreOptionsDesktop(props: IScoreOptionsSingleProps): JSX.Element {
    const { initialSelected, onScoreChange, isFilter, ...formProps } = props;
    const [selected, select] = useState(initialSelected);
    const selections = new Set(selected ? [selected] : []);


    useEffect(() => { select(initialSelected) }, [initialSelected]);

    const onSelectScore = (value:LookupsApi.IDataItem<'contact_score'>) => {
        const current = value.short_value !== selected ? value.short_value : undefined;
        select(current);
        onScoreChange?.(current);
    };

    return (
        <Form
            {...formProps}
            type='radio'
            selections={selections}
            isFilter={isFilter}
            onSelectScore={onSelectScore}
        />
    );
}

export namespace ScoreOptionsDesktop
{
    export function Multi(props: IScoreOptionsMultiProps)
    {
        const { initialSelections, onScoresChange, isFilter, ...formProps } = props;
        const [selections, select] = useState(() => new Set(initialSelections));
        const previousInitialSelections = usePrevious(initialSelections);
        //const selections = Array.from(selectionsSet.values());

        useEffect(() => {
            const initialSet = new Set(initialSelections);
            const previousSet = new Set(previousInitialSelections);
            if (!isEqual(initialSet, previousSet)) { select(initialSet); }
        }, [initialSelections, previousInitialSelections]);

        const onSelectScore = (data:IContactScore) =>
        {
            const value = data.short_value;
            selections[selections.has(value) ? 'delete' : 'add'](value);
            /*if (data.is_filter && selections.has(value)) {
                const currentSet = new Set([]);
                select(currentSet);
                onScoresChange?.([]);
            } else {*/
            const list = Array.from(selections.values()) as string[];
            const currentSet = new Set(list);
            select(currentSet);
            onScoresChange?.(list);
            //}
        };

        return (
            <Form
                {...formProps}
                type='checkbox'
                selections={selections}
                isFilter={isFilter}
                onSelectScore={onSelectScore}
            />
        );
    }
}

export function Form(props: IFormOptionsProps)
{
    const { type, label, selections, dropDown, onSelectScore, isFilter, loading, ...divProps } = props;
    //const [{ ontact_score, score_color }] = useFetchLookupsService(['contact_score', 'score_color']);
    const [lookupData] = useFetchLookupsService(['contact_score']);

    // Error State
    if(lookupData.error) { return <></>; }

    // Loading State
    if(loading || lookupData.loading) {
        return (
            <div {...divProps}>
                {label && <label>{label}</label>}
                <div className="row small-gutters">
                    {range(4).map(index =>
                        <div className="col mb-2" key={index}><div className="loading__square"></div></div>
                    )}
                </div>
            </div>
        );
    }

    const scores = lookupData.response.contact_score.items.filter(x => !x.is_filter || x.is_filter === isFilter);
    const scoresMap = scores.reduce((map, item) => map.set(item.short_value, item), new Map<string, IContactScore>())
    const isMultiple = type === 'checkbox';

    const options = (
        <div id="Source" className="btn-group btn-group-toggle btn-group--source " data-toggle="buttons">
            {scores.map((score, index) => {
                const selected = selections.has(score.short_value);
                return <Option type={type} score={score} key={score.short_value} index={index} selected={selected} onSelect={onSelectScore} />
            })}
        </div>
    );

    if (dropDown) {

        // If this is multi selection, throw an error
        if (type === 'checkbox') { throw new Error('Multi selection is not allowed for Score DropDown.'); }

        const selection = Array.from(selections).shift()!;
        const scoreSelection = scoresMap.get(selection);
        const scoreClass = selection ? `score_label--${scoreSelection?.short_value?.toLowerCase()}` : 'score_label--n';

        return <div>
            <Dropdown className='dropdown dropdown--custom dropdown--custom__inverse dropdown__selectscore'>
                <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" variant="none" href="#">
                    <div className={`score_label score_label__large__withover w-88px score_label__large ${scoreClass}`}>
                        <span className="score_label__large__alpha">{scoreSelection?.long_value ?? 'N/A'}</span>
                        {label && <span className="score_label__large__text">{label}</span>}
                        <span className="overlay_btn" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
                                <path fill="#ffffff" fillRule="evenodd" d="M7.687 6.328l5.83-5.83 1.658 1.657-7.458 7.458-.03-.03-.029.03L.2 2.155 1.858.498l5.83 5.83z" />
                            </svg>
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu animate slideIn pt-3 pb-3">
                    <div className="ml-4 mr-4 min-w-190px">
                        {options}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>;
    }

    return (
        <div {...divProps}>
            <div className="pb-4">
                {isMultiple &&
                    <div className="row">
                        <div className="col">
                            {label && <div className="font_size--large font_weight--semi_bold mb-4">{label}</div>}
                        </div>
                        <div className="col-auto d-none"><a className="custom_link" href="#">Select all</a></div>
                    </div>}

                {options}
                {/* </div> */}
            </div>

        </div>
    );
}

export const Option = (props: IOptionProps): JSX.Element => {
    const { type, score, index, selected, onSelect } = props;
    const value = score.short_value.toLowerCase();
    const text = score.long_value;
    const className = joinWithSpace(`btn btn--${value}`, selected && 'active')
    const id = `option${index}`;

    const clickHandler = (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        console.log('clicked');
        onSelect?.(score);
    };

    if (score.is_filter) {
        return (
            /*<div className="choosable_checkbox" data-toggle="buttons" onClick={clickHandler}>
                <input type={type} defaultChecked={selected ? true : false} name="option" className="custom-control-input" id={id} />
                <label className={joinWithSpace(`custom-control-label btn--${value}`, selected && 'active')} htmlFor={id}>
                    <div className={`score_label score_label--n`}>
                        <span className="score_label__text">{text} Score</span>
                    </div>
                </label>
            </div>*/
            <label className={className} onClick={clickHandler}>
                <input type={type} defaultChecked={selected ? true : false} name="options" id={id} />
                <span className="btn__alpha"></span>
                <span className="btn__text">{text} Score</span>
            </label>
        )
    }

    return (
        <label className={className} onClick={clickHandler}>
            <input type={type} defaultChecked={selected ? true : false} name="options" id={id} />
            <span className="btn__alpha">{text}</span>
            <span className="btn__text">Score</span>
        </label>
    );


}


Option.count = 0;