import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace GetDealsPipelineApi {
    export const url = "/deals/pipeline";
    export const method = "GET";
    export const defaultError = "Failed to load deals";
    export const operations = apiOperations(GetDealsPipelineApi);
    export const scope = new class GetDealsApiScope implements IApiScope<Request, Response> {
        request(input: Request): DeepPropertiesType<Request, string> {
            const { page, page_size, deal_stages, property_types, expired, deal_types, expired_in_90_days, renew } = input;
            return {
                page: page,
                page_size: page_size,
                deal_stages: deal_stages,
                property_types: property_types,
                expired: expired,
                expired_in_90_days: expired_in_90_days,
                deal_types: deal_types,
                renew: renew
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            const { stages, ...other } = data;
            return {
                stages: stages.map(this._mapDealsTypes, this),
                ...other
            }
        }

        _mapDealsTypes(input: DeepPropertiesType<IDealsTypeResponse, string>): IDealsTypeResponse {
            const { key, ...other } = input;
            return {
                key: key?.map(this._mapDealsApiResponse, this),
                ...other
            }
        }

        _mapDealsApiResponse(input: DeepPropertiesType<IDealsApiResponse, string>): IDealsApiResponse {
            const { total, total_pages, total_amount, total_amount_formatted, page, page_size, list, ...other } = input;
            return {
                total: parseInt(total),
                total_pages: parseInt(total_pages),
                page: parseInt(page),
                page_size: parseInt(page_size),
                total_amount: parseInt(total_amount),
                total_amount_formatted: total_amount_formatted,
                list: list.map(this._mapDealListItem, this),
                ...other
            }
        }

        _mapDealListItem(input: DeepPropertiesType<IDealList, string>): IDealList {
            const { id, title, deal_type, deal_type_text, property_photo, property_price, property_full_street_address, property_address2, property_city,
                property_state, property_zip, property_type, property_type_text, property_listtype, deal_amount, deal_amount_formatted, estimated_deadline, notes: notes, contacts, ...other } = input;
            return {
                id: Number(id),
                title: title,
                deal_type: deal_type,
                deal_type_text: deal_type_text,
                property_photo: property_photo,
                property_price: property_price,
                property_listtype: property_listtype,
                property_full_street_address: property_full_street_address,
                property_address2: property_address2,
                property_city: property_city,
                property_state: property_state,
                property_zip: Number(property_zip),
                property_type: property_type,
                property_type_text: property_type_text,
                deal_amount: Number(deal_amount),
                deal_amount_formatted: deal_amount_formatted,
                estimated_deadline: estimated_deadline,
                notes: notes,
                contacts: contacts.map(this._mapDealContacts, this),
                ...other
            };
        }
        _mapDealContacts(input: DeepPropertiesType<IDealContact, string>): IDealContact {
            const { id, first_name, last_name, city, middle_name, score, photo_url, ...other } = input;
            //let MiddleName = (middle_name) ? ' ' + middle_name : ''
            return {
                id: Number(id),
                first_name: first_name,
                last_name: last_name,
                city: last_name,
                middle_name: middle_name,
                score: score,
                photo_url: photo_url,
                ...other
            }
        }

    }();

    export interface Request {
        page?: string,
        page_size?: string,
        deal_stages?: string,
        property_types?: string,
        expired?: string,
        expired_in_90_days?: string,
        deal_types?: string,
        renew?: string
    }

    export interface Response {
        stages: Array<IDealsTypeResponse>
    }

    export interface IDealsTypeResponse {
        key: Array<IDealsApiResponse>
    }


    export interface IDealsApiResponse {
        total: number,
        page: number,
        page_size: number,
        total_pages: number,
        total_amount: number,
        total_amount_formatted: string,
        next_page_url: string,
        prev_page_url: string,
        list: Array<IDealList>;
    }


    export interface IDealList {
        id: number,
        title: string,
        deal_type: string,
        deal_type_text: string,
        deal_stage: string,
        deal_stage_text: string,
        deal_priority: string,
        deal_amount: number,
        deal_amount_formatted: string,
        property_photo: string,
        property_price: string,
        property_full_street_address: string,
        property_address2: string,
        property_city: string,
        property_state: string,
        property_harid: string,
        property_listingid: string,
        property_listtype: string,
        property_mlsnum: string,
        property_for: string,
        property_zip: number,
        property_country: string,
        property_type: string,
        property_type_text: string,
        estimated_deadline: string,
        created_dt?: string,
        modified_dt?: string,
        notes: string
        contacts: Array<IDealContact>
    }

    export interface IDealContact {
        id: number,
        first_name: string,
        last_name: string,
        primary_email: string,
        middle_name: string,
        city: string,
        photo_url: string,
        score: string
        deleted?: string
    }
}

export const fetchGetDealsPipelineService = forwardOperation(GetDealsPipelineApi.operations, 'fetch');
export const useFetchGetDealsPipelineService = forwardOperation(GetDealsPipelineApi.operations, 'useFetch');
export const useGetDealsPipelineService = forwardOperation(GetDealsPipelineApi.operations, 'useService');