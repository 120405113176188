import { Loading as LoadingTaskCard } from "views/calendar/TaskCard/components/loading";

export const Loading = () =>
{
  const cardList = [1,2,3,4,5,6];

  return (
    <div className="pt-4 pl-4 pr-4 pb-4">      
      <div className="row no-gutters">        
        <div className="col">
            <div className="pb-4 border-bottom mb-5">
                <div className="row">
                    {cardList.map((_value,ind)=><div key={ind} className="col-12 col-md-6">
                        <LoadingTaskCard />
                    </div>)}
                  </div>
                </div>
            </div>
        </div>
    </div>
  );
}
