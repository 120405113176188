import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace PhoneExistenceApi
{
    export const url = "/contacts/phone_existence";
    export const method = "GET";
    export const defaultError = "Failed to verify phone.";
    export const operations = apiOperations(PhoneExistenceApi);
    export const scope = new class PhoneExistenceApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:{ is_duplicate:string })
        {
            const isDuplicated = !!parseInt(data.is_duplicate);
            return { isDuplicated };
        }
    }();
    
    export interface Request
    {
        phone:string;
    }

    export interface Response
    {
        isDuplicated:boolean;
    }
}

export const fetchPhoneExistenceService = forwardOperation(PhoneExistenceApi.operations, 'fetch');
export const useFetchPhoneExistenceService = forwardOperation(PhoneExistenceApi.operations, 'useFetch');
export const usePhoneExistenceService = forwardOperation(PhoneExistenceApi.operations, 'useService');