import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { ISupportScreenProps } from './SupportScreen.def';
import './SupportScreen.scss';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';

export const SupportScreen = (_props: ISupportScreenProps): JSX.Element =>
{
    return (
        <DefaultTemplate title='' fluid>

            <ExternalEmbedContent
                url='/contact-us?crm=1'
                source={eExternalEmbedContentSource.Website}
                iframeTitle='Support'
                //title='Support'
            />

        </DefaultTemplate>
    );
}
