import { IPropertyTypeaheadProps } from './PropertyTypeahead.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { PropertyTypeaheadApp } from './PropertyTypeahead.app';
import { PropertyTypeaheadDesktop } from './PropertyTypeahead.desktop';

export const PropertyTypeahead = (props: IPropertyTypeaheadProps): JSX.Element =>
{
    const PropertyTypeaheadPlatform = useResponsiveChoose(PropertyTypeaheadDesktop, PropertyTypeaheadApp);
    return (
        <PropertyTypeaheadPlatform {...props} />
    );
}
