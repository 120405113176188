import { useHistory } from 'react-router';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { IErrorDetailProps, IImportProviderResultScreenProps } from './ImportProviderResultScreen.def';
import './ImportProviderResultScreen.scss';
import { useState } from 'react';
import { ImportResultApi } from 'services/contacts/provider/importResult';
import { ImportContactApi } from 'services/contacts/provider/importContact';
import { refreshCircleOutline } from 'ionicons/icons';

export const ImportProviderResultScreenApp = (props: IImportProviderResultScreenProps): JSX.Element => {
    const { result } = props
    const history = useHistory()
    const [errorList, setList] = useState<ImportResultApi.IImportContactFailed[]>()
    const [error, showError] = useState(false)
    const [name, setName] = useState("")
    return (
        <>
            <ErrorDetailPopup show={error} errors={errorList} onClose={() => showError(false)} name={name} />
            <IonModal className={`bg-white`} isOpen={true} canDismiss={false} backdropDismiss={false}>
                <IonHeader className="ion-padding-md ion-no-border border-bottom">

                    <IonToolbar>
                        <IonTitle className="modal-title">Import Contacts</IonTitle>

                        <IonButtons slot="start">
                            <IonButton color={"blue"} onClick={() => history.push('/contacts/import')}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <div className='min-w-70px'></div>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="bg-white">
                    <div className="pt-5 ml-auto mr-auto">
                        {!result.loading && result.response?.total_added !== "0" &&

                            <div className="d-flex px-2  pb-3">
                                <img className='w-16px' src="https://content.harstatic.com/media/icons/check_green_2.svg" />
                                <div className='color_available align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_added} contact{Number(result.response?.total_added) > 1 ? `s` : ``} successfully added.
                                </div>
                            </div>
                        }
                        {!result.loading && result.response?.total_updated !== "0" &&
                            <div className="d-flex px-2  pb-3">
                                <IonIcon icon={refreshCircleOutline} className='updatedIcon color_custom_5' ></IonIcon>
                                <div className='align-self-center font_weight--semi_bold font_size--medium pl-2 color_custom_5'>
                                    {result.response?.total_updated} contact{Number(result.response?.total_updated) > 1 ? `s` : ``} updated.
                                </div>
                            </div>
                        }

                        {!result.loading && result.response?.total_failed !== "0" &&

                            <div className="d-flex px-2 pb-3">
                                <img className=' w-16px' src="https://content.harstatic.com/media/icons/icon-warning.svg" />
                                <div className='color_sold align-self-center font_weight--semi_bold font_size--medium pl-2' >
                                    {result.response?.total_failed} contact{Number(result.response?.total_failed) > 1 ? `s` : ``} failed to import.
                                </div>
                            </div>
                        }
                        <div className='border-top mx-auto p-3'>
                            {result?.response?.total_failed !== "0" &&
                                <div className="pb-4 mb-4 pt-2">
                                    <div className="pt-3 ">
                                        {result?.response?.list.map((contact: ImportContactApi.IImportContactResult, key: number) =>
                                            <div key={key} className={`message ${contact.status === 'added' && `bg_color_available_light`} 
                                    ${contact.status === 'updated' && `bg_color_updated`}
                                    ${contact.status !== 'updated' && contact.status !== 'added' && `bg_color_sold_light`}  mb-2 w-100`}>{contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.primary_email ? contact.primary_email : contact.primary_phone}
                                                <span className='font_weight--bold'>
                                                    {contact.status !== 'updated' && contact.status !== 'added' && ` Failed`}
                                                    {contact.status === 'added' && ` New`}
                                                    {contact.status === 'updated' && ` Updated`}
                                                    {contact.errors.length > 0 && <a className='cursor--hand ml-2 font_size--small' onClick={() => {
                                                        setList(contact.errors)
                                                        setName(contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.primary_email ? contact.primary_email : contact.primary_phone)
                                                        showError(true)
                                                    }}> view detail</a>}

                                                </span>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            }


                        </div>
                    </div >
                </IonContent>
            </IonModal >
        </>

    );


}
const ErrorDetailPopup = (props: IErrorDetailProps) => {
    return (
        <IonModal isOpen={props.show} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Error Detail</IonTitle>
                    <IonButtons slot="start">
                        <IonButton color={"blue"} onClick={() => props.onClose?.()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />  Back</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div className="contact_detail min-h-250px p-4">
                    <div className='d-flex border-bottom mb-3'>
                        <div className='font_weight--semi_bold font_size--large pb-3 text-truncate'>{props.name} </div>
                    </div>
                    {props.errors?.map((error: ImportResultApi.IImportContactFailed) =>
                        <ul className='row'>
                            <li className='ml-3 color_sold text-break'>{error.message}</li>
                        </ul>
                    )}
                </div>
            </IonContent>
        </IonModal >
    )

}