import { IEmailLandingLocationProps, IEmailLandingScreenProps, TEmailKind } from './EmailLandingScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { EmailLandingScreenApp } from './EmailLandingScreen.app';
import { EmailLandingScreenDesktop } from './EmailLandingScreen.desktop';
import { IEmailList, SearchEmailsApi, useSearchEmailsService } from 'services/emails/searchEmails';
import { useEffect, useState } from 'react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useHistory, useLocation } from 'react-router';
import { useDeleteEmailsService } from 'services/emails/deleteEmails';
import { useMarkEmailReadService } from 'services/emails/markEmailRead';
import { useFetchGetBasicInfoService } from 'services/user/getBasicInfo';
import { displayMessage } from 'scripts/hooks/feedbacks';

export const EmailLandingScreen = (props: IEmailLandingScreenProps): JSX.Element => {
    //const { id, } = useParams<{ id: string }>();
    const [mails, setMail] = useState<SearchEmailsApi.Response>()
    const [page, setPage] = useState(1)
    const [id, setEmailId] = useState<string>()
    const [list, setList] = useState<IEmailList[]>([])
    const [type, setType] = useState<TEmailKind>();
    const [sort, setSort] = useState<"recent" | "older">("recent");
    const [unReadCount, setUnReadCount] = useState(0)
    const [draftCount, setDraftCount] = useState(0)
    const [leadCount, setLeadCount] = useState(0)
    
    const [readList, setReadList] = useState<string[]>([])
    const [keyword, setKeyword] = useState("");


    const [basicInfo] = useFetchGetBasicInfoService();
    const [mailService, useMailService] = useSearchEmailsService()
    const [deleteService, deleteServiceAction] = useDeleteEmailsService()
    const [markedReadService, markedReadServiceAction] = useMarkEmailReadService()
    const location = useLocation<IEmailLandingLocationProps>()
    const history = useHistory()


    useServiceDidSucceeded(mailService, (response) => {
        setMail(response)
        setUnReadCount(Number(response.total_unread))
        setDraftCount(Number(response.total_draft))
        setLeadCount(Number(response.total_unread_lead))
        let newList = list ? [...list, ...response.list] : response.list
        setList(newList)
    })

    useServiceDidSucceeded(deleteService, () => {
        if (type) {
            reload()
            displayMessage("The emails successfully Deleted")
        }

    })
    useServiceDidSucceeded(markedReadService, () => {
        if (type) {

            setList([])
            init(type, "", 1)
        }
    })


    const init = (t: TEmailKind, key?: string, p?: number, s?: "recent" | "older") => {
        if (!p || p === 1) { setList([]); }
        useMailService.fetch({ type: t, keyword: key || key === "" ? key : keyword, sort_by: s ? s : sort, renew: "1", page: p ? p.toString() : page.toString() })
        if (s) {
            setSort(s)
        }
        if (key || key === "") {
            setKeyword(key)
        }
        if (p) {
            setPage(p)
        }
    }

    const search = (key: string) => {
        setList([])
        if (type) {
            init(type, key, 1)
        }
    }

    const loadMore = (p: number) => {
        if (type) {
            init(type, keyword, p)
        }
    }

    const doSort = (sort: "recent" | "older") => {
        setList([])
        if (type)
            init(type, keyword, 1, sort)
    }

    const deleteEmail = (ids: string[]) => {
        if (type) {
            if (type === 'draft') {
                deleteServiceAction.fetch({ type: 'drafts', draft_ids: ids })
            } else {
                deleteServiceAction.fetch({ type: type, email_ids: ids })
            }
        }
    }

    const markEmail = (ids: string[], read: number) => {
        setReadList([])
        markedReadServiceAction.fetch({ read: read, email_ids: ids })
        let readText = read ? 'read' : 'unread'
        let emailText = ids.length > 1 ? 'emails' : 'email'
        displayMessage("The " + emailText + " marked as " + readText + " successfully")

    }

    const updateCounter = (id: string, type: TEmailKind,isLead?:boolean) => {
        if (type === 'inbox') {
            setReadList([...readList, id])
            setUnReadCount(unReadCount - 1)
            if(isLead){
                setLeadCount(leadCount - 1)
            }
        }

        if (type === 'draft') {
            setDraftCount(draftCount - 1)
        }

        if (type === 'lead') {
            setReadList([...readList, id])
            setUnReadCount(unReadCount - 1)
            setLeadCount(leadCount - 1)
        }

    }


    const loadType = (t: TEmailKind) => {
        setType(t)
        init(t, undefined, 1)
    }


    const reload = () => {
        if (type) {
            setList([])
            init(type, "", 1)
            if (id) {
                setEmailId("")
                history.push('/emails/' + type)
            }
        }
    }

    const update = (data: { item: any, isNew: boolean }, emailItem: IEmailList) => {
        if (data?.isNew) {
            if (list && list?.length > 0) {
                setList([emailItem, ...list])
            } else {
                setList([emailItem])
            }
        } else {
            list?.map((item: IEmailList) => {
                if (item.id === data?.item.id) {
                    item.short_message = data?.item?.short_message
                    item.subject = data?.item?.subject
                }
                return item
            })
        }
    }


    useEffect(() => {
        if (location.pathname) {
            var url = location.pathname.split('/')
            if (url[3]) {
                setEmailId(url[3])
            } else {
                setEmailId("")
            }
            if (url[2] && url[2] !== type) {
                if (url[2] === 'inbox') {
                    loadType('inbox')
                } else if (url[2] === 'draft') {
                    loadType('draft')
                } else if (url[2] === 'sent') {
                    loadType('sent')
                } else if (url[2] === 'forwarded') {
                    loadType('forwarded')
                }  else if (url[2] === 'lead') {
                    loadType('lead')
                }
            }
        }

    }, [location.pathname])

    useEffect(() => {
        var url = location.pathname.split('/')
        if (url[3]) {
            setEmailId(url[3])
        }
    }, [location])


    useEffect(() => {
        if (type && keyword.length === 0) {
            init(type, "", 1)
        }
    }, [keyword])


    const EmailLandingScreenPlatform = useResponsiveChoose(EmailLandingScreenDesktop, EmailLandingScreenApp);
    return (
        <EmailLandingScreenPlatform
            mails={mails}
            list={list}
            type={type}
            id={id}
            loading={mailService.loading}
            keyword={keyword}
            page={page}
            total_draft={draftCount}
            total_unread={unReadCount}
            total_unread_lead={leadCount}
            readList={readList}
            member={basicInfo.response}
            currentSort={sort}
            reload={reload}
            loadMore={loadMore}
            search={search}
            sort={doSort}
            mark={markEmail}
            remove={deleteEmail}
            update={update}
            setCounter={updateCounter}
            init={init}
            {...props} />
    );
}
