export function wait(timeout:number): Promise<void>
{
    return new Promise(resolve => setTimeout(resolve, timeout));
}

export function intervalAttempts(attemps:number, interval:number, action:() => boolean | Promise<boolean>, fallback:() => void)
    : { abort(): void }
{
    let aborted = false;
    const execute = async () =>
    {
        while(attemps > 0)
        {
            await wait(interval);
            if(aborted) { return; }
            if(await action()) { return; }
            attemps--;
        }

        fallback();
    };

    execute();

    return {
        abort() { aborted = true; }
    }
}