import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IAddContactScreenProps } from './AddContactScreen.def';
import './AddContactScreen.scss';
import { EditContactScreen } from '../EditContactScreen/EditContactScreen';

/*
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { createBrowserHistory } from "history";
import { AddressesSection } from 'views/contacts/AddressesSection';
import { BasicInformationSection } from 'views/contacts/BasicInformationSection';
import { ContactInsightSection } from 'views/contacts/ContactInsightSection';
import { ContactSection } from 'views/contacts/ContactSection';
import { LinksSection } from 'views/contacts/LinksSection';
import { RelationshipSection } from 'views/contacts/RelationshipSection';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { OrdinaryButton, ProminentButton } from 'views/forms/buttons/Button';
import { useValidateController } from 'scripts/hooks/validations';
import { useCreateContactService } from 'services/contacts/createContact';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { IBasicInfoData } from 'views/contacts/BasicInformationSection/BasicInformationSection.def';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { IContactData } from 'views/contacts/ContactSection/ContactSection.def';
import { ILinkItem } from 'views/contacts/LinksSection/LinksSection.def';
import { IRelationItem } from 'views/contacts/RelationshipSection/RelationshipSection.def';
//import { ErrorDialog } from 'views/dialogs/ErrorDialog';
import { useParams } from 'react-router';
import { useClassOnSticky } from 'scripts/hooks/general';
import { IStageAddContactsProps } from 'views/contacts/pipeline/StageColumn/StageColumn.def';
import { useIonAlert } from '@ionic/react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
//import { savePhone } from 'views/contacts/ContactSection/ContactSection';


const browserHistory = createBrowserHistory({ forceRefresh: true });*/

export const AddContactScreenDesktop = (_props: IAddContactScreenProps): JSX.Element => {
  /*  const [validation, validate] = useValidateController();
    const [createData, createActions] = useCreateContactService();
    const history = useHistory();
    const [basicInfo, setBasicInfo] = useState<IBasicInfoData>(basicInfoDataIntial);
    const [addresses, setAddresses] = useState<IAddressItem[]>();
    const [relationships, setRelationships] = useState<IRelationItem[]>();
    const [contactInsight, setContactInsight] = useState<IContactInsightData>();
    const [contactData, setContactData] = useState<IContactData>();
    const [isError, toggleError] = useState(false);
    const [links, setLinks] = useState<ILinkItem[]>();
    const { id } = useParams<{ id: string }>();
    const stickyRef = useRef<HTMLDivElement>(null);
    const formEmptyRef = useRef(true);
    const [presentCancelConfirmation, dismissCancelConfirmation] = useIonAlert();
    const [showConfirmation, setShowConfirmation] = useState(true);
//console.log("createData",createData)
    useServiceDidSucceeded(createData, () => redirectAfterSuccess())

    const showCancelConfirmation = () => {
        presentCancelConfirmation({
            header: 'Cancel Add Contact?',
            message: 'Are you sure you want to cancel Add contact, the data will be lost?',
            buttons: [
                { text: 'Continue to Add Contact', handler: dismissCancelConfirmation },
                { text: 'Cancel Add Contact', cssClass: 'btn btn--medium btn--primary', handler() { cancel(false); } }
            ]
        });
    }

    useEffect(() => {
        const confirmationFunction = (e:any) => {
            if(showConfirmation){
            const confirmationMessage = 'Are you sure you want to leave this page? Your changes may not be saved.';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
            }
            return undefined;
          };
        window.addEventListener('beforeunload', confirmationFunction);
        return () => {
          window.removeEventListener('beforeunload', confirmationFunction);
        };
      }, [showConfirmation]); 
    
    let location = useLocation<IStageAddContactsProps>();

    useClassOnSticky(stickyRef, { sticky: 'sticked', target: document.body });

    const saveContact = async () => {
      
        if (validation.loading) { return }
        const isValid = await validate();
        const emails = contactData?.emails ?? [];
      const Phones= contactData?.phones?? [];

        if (!isValid&& !!!emails.length&& !!!Phones.length) { return toggleError(true); }

        // List validations
        createActions.fetch({
            photo_url: basicInfo!.image,
            first_name: basicInfo!.firstName!,
            last_name: basicInfo!.lastName!,
            middle_name: basicInfo!.middleName,
            gender: basicInfo!.gender!,
            birthday: basicInfo!.dateOfBirth,
            known_since: basicInfo!.knownSince,
            income: basicInfo!.income,
            job_title: basicInfo!.jobTitle,
            company: basicInfo!.company,
            anniversary_date: basicInfo!.weddingAniversary,
            home_anniversary_date: basicInfo!.homeAniversary,
            score: contactInsight?.scoreType,
            pipeline_status: contactInsight?.pipelineType ?? '10',
            sources: contactInsight?.sourceType?? 'None',
            tags: contactInsight?.tags,
            emails: emails,
            phones: contactData?.phones,
            addresses: addresses,
            relationships: relationships?.map(x => ({ name: x.name, type: x.relationType })),
            links: links?.map(x => ({ name: x.label, url: x.url })),
            contact_type: 'None', // don't know
        });
        await setShowConfirmation(false)

        return true
    };

    const setBasicInfoFromUser = (data: IBasicInfoData) => setDataFromUser(data, setBasicInfo);
    const setContactInsightFromUser = (data: IContactInsightData) => setDataFromUser(data, setContactInsight);
    const setAddressesFromUser = (data: IAddressItem[]) => setDataFromUser(data, setAddresses);
    const setRelationshipsFromUser = (data: IRelationItem[]) => setDataFromUser(data, setRelationships);
    const setContactsFromUser = (data: IContactData) => setDataFromUser(data, setContactData);
    const setLinksFromUser = (data: ILinkItem[]) => setDataFromUser(data, setLinks);

    function setDataFromUser<T>(data: T, setData: (data: T) => void) {
        formEmptyRef.current = false;
        setData(data);
    };

    const redirectAfterSuccess =() => {
        browserHistory.push('/contacts');
    };

    const onCancel = () => { cancel(true); }

    const cancel = async (confirm: boolean) => {
        if (confirm && !formEmptyRef.current) { return showCancelConfirmation(); }
        if (window.history.state) { history.goBack(); }
        else { history.push('/contacts'); }

    };
    useEffect(() => {
        if (location.state) {
            if (location.state.first_name) {
                if (location.state.last_name) {
                    setBasicInfo({
                        ...basicInfo,
                        firstName: location.state.first_name,
                        lastName: location.state.last_name
                    })
                } else {
                    setBasicInfo({
                        ...basicInfo,
                        firstName: location.state.first_name
                    })
                }
            }
            if (location.state.primary_email) {
                setContactData({
                    'emails': [{
                        email: location.state.primary_email,
                        preferred: true,
                    }],
                    'phones': []
                  
                })
            }
            if (location.state.pipeline_status) {
                setContactInsight({ pipelineType: location.state.pipeline_status.toString() })
            }
        }
    }, [location]);

*/
    return (
        <DefaultTemplate title="Contacts">
            <EditContactScreen />
            
            {/**<div className="position-sticky page-sticky-header top--1px" ref={stickyRef}>
                <div className="container">
                    <div className="blured_bg">
                        <div className="row pt-4 pb-4">
                            <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-3">
                                <h2 tabIndex={(0)} className="h2--simple h2--simple__black mb-0">Add Contact</h2>
                            </div>
                            <div className="col-sm-6 col-12 align-self-center text-left text-sm-right">
                                <OrdinaryButton className="mb-0" onClick={onCancel}>Cancel</OrdinaryButton>
                                <ProminentButton disabled={createData.loading} className = "btn btn--prominent__v2 ml-4 mb-0" onClick = {saveContact} loading={validation.loading}>Save Contact</ProminentButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pb-5">    
                <div className="row mt-1" autoFocus={true}>
                    <div className="col-lg-6 col-12">
                        <BasicInformationSection isError={isError} id={id} validate={validation.signal} onItemsChange={setBasicInfoFromUser} initialData={basicInfo} />
                        <AddressesSection onItemsChange={setAddressesFromUser}/>
                    </div>
                    <div className="col-lg-6 col-12">
                        <ContactInsightSection validate={validation.signal} onChange={setContactInsightFromUser} initialValues={contactInsight} />
                        <ContactSection isError={isError} onChange={setContactsFromUser} validate={validation.signal} initialEmails={contactData?.emails} />
                        <LinksSection onLinkChange={setLinksFromUser} />
                        <RelationshipSection onItemsChange={setRelationshipsFromUser} />
                    </div>
                </div>*/}
                {/*createData.response && <SimpleDialog message='Contact created successfully.' autoClose={true} onClose={redirectAfterSuccess} />*/}
                {/*createData.error && <ErrorDialog title="Contact not created" autoClose={true}>{createData.error.message ?? 'Internal Error'}</ErrorDialog>*/}
           {/* </div>*/} 
        </DefaultTemplate>
    );
}