import { ITextAreaProps } from './TextArea.def';
import { useEffect, useRef, useState } from 'react';
import './TextArea.scss';

export const TextAreaApp = (props: ITextAreaProps): JSX.Element => {
    const { label, labelAttr, showCounter, ...elementProps } = props
    const [characterLimit, setLimit] = useState(props.maxLength ? props.maxLength : 0)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const KeyUp = (e: any) => {
        if (props.maxLength && showCounter) {
            setLimit(props.maxLength - e.target.value.length)
        }
    }
    useEffect(() => {
        if (textareaRef !== null && textareaRef?.current?.value && textareaRef?.current?.value?.length > 0 && props.maxLength) {
            setLimit(props.maxLength - textareaRef?.current?.value?.length)
        }
    }, [])

    const CharaterLimits = () => {
        return (
            <>
                {showCounter && props.maxLength &&
                    <div className='mt-2 mb-3 d-block'>
                        Please limit your text to {characterLimit} characters.
                    </div>
                }
            </>
        );
    }
    return (
        <>
            {label &&
                <label {...labelAttr}>{label}</label>
            }
            <textarea ref={textareaRef} {...elementProps}

                onFocus={() => {

                    textareaRef.current?.style.setProperty("height", "500px"); textareaRef.current?.scrollIntoView({ inline: "end" })
                }}
                onBlur={() => { textareaRef.current?.style.setProperty("height", "200px"); }}
                onKeyUp={(e: any) => KeyUp(e)} />
            <CharaterLimits />
        </>

    );
}
