import { isObject } from "lodash-es";
import { Fragment, ReactNode } from "react";


/*type HTMLCamelCaseAttributes =
{
    [K in keyof HTMLAttributes<unknown> as K extends Lowercase<K>? never: K]: HTMLAttributes<unknown>[K];
};

type ExoticKeys<T> =
{
    [K in keyof T & string as K extends keyof HTMLCamelCaseAttributes? K: Lowercase<K>]: T[K];
};*/


export function extractChildren<K extends string, T extends Record<K, (...args:any[]) => ReactNode>>
    (children:ReactNode, mapper:T)
{
    const result:Partial<Record<K, JSX.Element>> = {};
    if(!children) { return result; }
    const nodes = (() => {
        if(children === true || !isObject(children)) { return []; }
        if(!Array.isArray(children) && 'type' in children && children.type === Fragment) { children = children.props.children; }
        if(Array.isArray(children)) { return children as ReactNode[]; }
        return [children];
    })() ?? [];
    

    for(let [key, value] of Object.entries(mapper))
    {
        result[key as K] = nodes.find((x): x is JSX.Element => isObject(x) && 'type' in x && x.type === value);
    }

    return result;
}


/*export function toExoticProps<T extends object>(input:T): ExoticKeys<T>
{
    const result:any = {};
    const keys = Object.keys(input) as Array<keyof T & string>;
    for(let key of keys)
    {
        let finalKey:string = key;
        if(!reactCamelCaseAttributes.includes(key)) {
            const lowerKey = key.toLowerCase() as keyof T & string;
            if(keys.includes(lowerKey)) { continue; }
            finalKey = lowerKey;
        }

        result[finalKey] = input[key];
    }

    return result as ExoticKeys<T>;
}

enum ReactCamelCaseAttributesEnum
{
    defaultChecked, defaultValue, suppressContentEditableWarning, suppressHydrationWarning, accessKey,
    className, contentEditable, contextMenu, spellCheck, tabIndex, radioGroup, autoCapitalize, autoCorrect,
    autoSave, itemProp, itemScope, itemType, itemID, itemRef, inputMode, dangerouslySetInnerHTML, onCopy,
    onCopyCapture, onCut, onCutCapture, onPaste, onPasteCapture, onCompositionEnd, onCompositionEndCapture,
    onCompositionStart, onCompositionStartCapture, onCompositionUpdate, onCompositionUpdateCapture, onFocus,
    onFocusCapture, onBlur, onBlurCapture, onChange, onChangeCapture, onBeforeInput, onAnimationEndCapture,
    onAnimationIteration, onAnimationIterationCapture, onTransitionEnd, onTransitionEndCapture, onWheelCapture,
    onAnimationStart, onAnimationStartCapture, onAnimationEnd, onLostPointerCaptureCapture, onScroll, onScrollCapture,
    onWheel, onPointerOutCapture, onGotPointerCapture, onGotPointerCaptureCapture, onLostPointerCapture,
    onPointerLeaveCapture, onPointerOver, onPointerOverCapture, onPointerOut, onPointerCancelCapture, onPointerEnter,
    onPointerEnterCapture, onPointerLeave, onPointerMoveCapture, onPointerUp, onPointerUpCapture, onPointerCancel,
    onTouchStartCapture, onPointerDown, onPointerDownCapture, onPointerMove, onTouchEndCapture, onTouchMove,
    onTouchMoveCapture, onTouchStart, onSelectCapture, onTouchCancel, onTouchCancelCapture, onTouchEnd,
    onMouseOverCapture, onMouseUp, onMouseUpCapture, onSelect, onMouseMoveCapture, onMouseOut, onMouseOutCapture,
    onMouseOver, onMouseDownCapture, onMouseEnter, onMouseLeave, onMouseMove, onBeforeInputCapture, onInput,
    onInputCapture, onReset, onResetCapture, onSubmit, onSubmitCapture, onInvalid, onInvalidCapture, onLoad,
    onLoadCapture, onError, onErrorCapture, onKeyDown, onKeyDownCapture, onKeyPress, onKeyPressCapture, onKeyUp,
    onKeyUpCapture, onAbort, onAbortCapture, onCanPlay, onCanPlayCapture, onCanPlayThrough, onCanPlayThroughCapture,
    onDurationChange, onDurationChangeCapture, onEmptied, onEmptiedCapture, onEncrypted, onEncryptedCapture, onEnded,
    onEndedCapture, onLoadedData, onLoadedDataCapture, onLoadedMetadata, onLoadedMetadataCapture, onLoadStart,
    onLoadStartCapture, onPause, onPauseCapture, onPlay, onPlayCapture, onPlaying, onPlayingCapture, onProgress,
    onProgressCapture, onRateChange, onRateChangeCapture, onSeeked, onSeekedCapture, onSeeking, onSeekingCapture,
    onStalled, onStalledCapture, onSuspend, onSuspendCapture, onTimeUpdate, onTimeUpdateCapture, onVolumeChange,
    onVolumeChangeCapture, onWaiting, onWaitingCapture, onAuxClick, onAuxClickCapture, onClick, onClickCapture,
    onContextMenu, onContextMenuCapture, onDoubleClick, onDoubleClickCapture, onDrag, onDragCapture, onDragEnd,
    onDragEndCapture, onDragEnter, onDragEnterCapture, onDragExit, onDragExitCapture, onDragLeave, onDragLeaveCapture,
    onDragOver, onDragOverCapture, onDragStart, onDragStartCapture, onDrop, onDropCapture, onMouseDown,autoFocus
};

type AttributesInput<K extends string> = Record<K, number> & Record<keyof HTMLCamelCaseAttributes, number>;

const reactCamelCaseAttributes =
    (<K extends keyof HTMLCamelCaseAttributes>(input:AttributesInput<K>): string[] => Object.keys(input))
    <keyof typeof ReactCamelCaseAttributesEnum>(ReactCamelCaseAttributesEnum);*/