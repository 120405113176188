import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { joinWithSpace } from "scripts/helpers/texts";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities";

export namespace SearchContactsApi {
    export const url = "/contacts";
    export const method = "GET";
    export const defaultError = "Failed to search contacts.";
    export const operations = apiOperations(SearchContactsApi);
    export const scope = new class SearchContactsApiScope implements IApiScope<Request, Response> {
        request(data: Request): PropertiesType<Request, string | number> {
            return {
                contact_ids: data.contact_ids?.join(','),
                page: data.page?.toString(),
                page_size: data.page_size?.toString(),
                sort_dir: data.sort_dir,
                sort_by: data.sort_by,
                name: data.name,
                keyword: data.keyword,
                cities: data.cities?.join(','),
                zip_codes: data.zip_codes?.join(','),
                tag_ids: data.tag_ids?.join(','),
                stages: data.stages?.join(','),
                scores: data.scores?.join(','),
                object_owners: data.object_owners?.join(','),
                sources: data.sources?.join(','),
                companies: data.companies?.join(','),
                deal_stages: data.deal_stages?.join(','),
                renew: data.renew,
                show_duplicates: data.show_duplicates,
                is_filter: data.is_filter,
                birthday_start: data.birthday_start,
                birthday_end: data.birthday_end,
                home_anniversary_start: data.home_anniversary_start,
                home_anniversary_end: data.home_anniversary_end,
                wedding_anniversary_start: data.wedding_anniversary_start,
                wedding_anniversary_end: data.wedding_anniversary_end,
                known_since_start: data.known_since_start,
                known_since_end: data.known_since_end,
                lead_type: data.lead_type,

            };
        }

        response(input: Record<'data', DeepPropertiesType<ServerResponse, string>>): Response {
            const { data } = input;
            const { total, total_pages, page, page_size, list, ...other } = data;
            return {
                total: parseInt(total),
                total_pages: parseInt(total_pages),
                page: parseInt(page),
                page_size: parseInt(page_size),
                list: list.map(this._mapContactItem, this),
                ...other
            }
        }

        _mapContactItem(input: DeepPropertiesType<IServerContactSearchItem, string>): IContactSearchItem {
            const { id, deal_stages, tags, ...other } = input;
            return {
                ...other,
                id: parseInt(id),
                first_last_name: joinWithSpace(other.first_name, other.last_name),
                deal_stages: deal_stages.map(this._mapDealStages, this),
                tags: tags.map(this._mapTag, this),
            };
        }

        _mapDealStages(input: PropertiesType<IDealStage, string>): IDealStage {
            const { total_deals, ...other } = input;
            return { total_deals: parseInt(total_deals), ...other };
        }

        _mapTag(input: PropertiesType<ITag, string>): ITag {
            const { id, contacts_count, ...other } = input;
            return { id: parseInt(id), contacts_count: parseInt(contacts_count), ...other };
        }
    }();

    export interface Request {

        contact_ids?: number[],
        page?: number;
        page_size?: number;
        sort_by?: string;
        sort_dir?: string;
        name?: string,
        keyword?: string,
        cities?: string[],
        zip_codes?: string[];
        tag_ids?: string[],
        stages?: number[],
        scores?: string[],
        object_owners?: string[],
        sources?: string[],
        companies?: string[],
        deal_stages?: string[],
        renew?: number,
        last_contact_date?: string,
        show_duplicates?: number,
        birthday_start?: string,
        birthday_end?: string,
        home_anniversary_start?: string,
        home_anniversary_end?: string,
        wedding_anniversary_start?: string,
        wedding_anniversary_end?: string,
        known_since_start?: string,
        known_since_end?: string,
        lead_type?: string,

        is_filter?: string,

    }

    export interface ServerResponse {
        total: number,
        page: number,
        page_size: number,
        total_pages: number,
        next_page_url: string,
        prev_page_url: string,
        list: Array<IServerContactSearchItem>;

    }

    export interface Response extends Omit<ServerResponse, 'list'> {
        list: Array<IContactSearchItem>;
    }

    export interface IServerContactSearchItem {
        id: number,
        photo_url: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        company: string,
        score: string,
        pipeline_status: string,
        sources: string,
        city: string,
        deal_stages: Array<IDealStage>,
        tags: Array<ITag>,
        primary_email: string,
        primary_phone_id: string
        primary_phone: string,
        primary_phone_extension?: string,
        primary_phone_country_code?: string,
        deleted?: string
    }

    export interface IContactSearchItem extends IServerContactSearchItem {
        first_last_name: string;
    }

    export interface IDealStage {
        id: string;
        name: string;
        total_deals: number;
    }

    export interface ITag {
        id: number;
        name: string;
        contacts_count: number;
    }
}

export const fetchSearchContactsService = forwardOperation(SearchContactsApi.operations, 'fetch');
export const useFetchSearchContactsService = forwardOperation(SearchContactsApi.operations, 'useFetch');
export const useSearchContactsService = forwardOperation(SearchContactsApi.operations, 'useService');