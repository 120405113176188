
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IHomeLandingScreenProps, showVersion } from './HomeLandingScreen.def';
import './HomeLandingScreen.scss';
import { HomeScreen } from '../HomeScreen/HomeScreen';
import {  version } from 'scripts/application/settings';
import { useHistory } from 'react-router';
import { useFetchNotificationSummaryService } from 'services/notifications/notificationSummary';
import { useState } from 'react';
import { fetchUpdateCounterService } from 'services/notifications/updateCounter';

export const HomeLandingScreen = (_props: IHomeLandingScreenProps): JSX.Element => {

    const [reload, doReload] = useState(1);
    return (
        <DefaultTemplate
            selectedMenu='Home'
            //title={<>CRM{!isProduction && <span className="font_size--medium"> v{version}</span>}</>}
            title={<>CRM{showVersion && <span className="font_size--medium"> v{version}</span>}</>}
            CustomMobileHeader={CustomHeader}
            onSwipeRefresh={() => doReload(reload + 1)}
            showIcon>
            <HomeScreen key={reload} />
        </DefaultTemplate>
    );
}


const CustomHeader = () => {
    const history = useHistory();
    const [data] = useFetchNotificationSummaryService()
    const handleNavigate=async()=>{
        await fetchUpdateCounterService();
         history.push(`/notifications`);
       }
    return (<>

        <div className="row no-gutters" >
            <div className="col-auto">
                <div onClick={handleNavigate} className="position-relative pl-2 pr-1 mr-2">
                    <img className="rounded-circle bg--image w-24px" src="https://content.harstatic.com/media/icons/icon-notifications.svg" />
                    {data.response?.total_unread !== 0 && <div className="counterBubble">{data.response?.total_unread}</div>}
                </div>
            </div>

        </div>


    </>);
}