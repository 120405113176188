import { useEffect, useRef, useState } from 'react';
import { ITextAreaProps } from './TextArea.def';
import './TextArea.scss';

export const TextAreaDesktop = (props: ITextAreaProps): JSX.Element => {
    const { label, labelAttr, showCounter, ...elementProps } = props
    const [characterLimit, setLimit] = useState(props.maxLength ? props.maxLength : 0)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const KeyUp = (e: any) => {
        if (props.maxLength && showCounter) {
            setLimit(props.maxLength - e.target.value.length)
        }
    }
    useEffect(() => {
        if (textareaRef !== null && textareaRef?.current?.value && textareaRef?.current?.value?.length > 0 && props.maxLength) {
            setLimit(props.maxLength - textareaRef?.current?.value?.length)
        }
    }, [])
    return (
        <>
            {label &&
                <label {...labelAttr}>{label}</label>
            }
            <textarea ref={textareaRef} {...elementProps} onKeyUp={(e: any) => KeyUp(e)} />
            {showCounter && props.maxLength &&
                <div className='mt-2 font_weight--semi_bold'>
                    Please limit your text to {characterLimit} characters.
                </div>
            }

        </>
    );
}
