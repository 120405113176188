import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace MapCSVApi {
    export const url = "/contacts/csv/upload";
    export const method = "POST";
    export const defaultError = "Failed to load csv information";
    export const operations = apiOperations(MapCSVApi);
    export const scope = new class MapCSVApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: DeepPropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        date_orientation?: string
        date_format?: string
        prevent_update?: string
        rows_limit?: string
        has_header?: string
        file_key: string,
        version?: string,
        mode?: string,
        queue?: string

    }

    export interface Response {
        status: string,
        message: string,
        data: IDataInfo

    }

    export interface IDataInfo {
        columns_count?: string,
        has_header?: string,
        columns?: IColumns[]
        file_key?: string,
        default_selection?: string[]
        crm_fields?: ICrmFields[],
        rows?: IRows[],
        rows_count?: string,
        import_id?: string,


    }

    export interface ICrmFields {
        short_value: string
        long_value: string
        limit: string
    }

    export interface IRows {
        key: string,
        value: string
    }

    export interface IColumns {
        column: string,
        index: string,
        column_value?: string
    }
}

export const fetchMapCSVService = forwardOperation(MapCSVApi.operations, 'fetch');
export const useFetchMapCSVService = forwardOperation(MapCSVApi.operations, 'useFetch');
export const useMapCSVService = forwardOperation(MapCSVApi.operations, 'useService');