 import { IContactDocumentsScreenProps } from './ContactDocumentsScreen.def';
import './ContactDocumentsScreen.scss';
import {  IonButton, IonContent,IonToolbar,IonHeader,IonButtons,IonTitle,IonLabel,IonInput, IonIcon, IonSpinner} from "@ionic/react";
import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList } from "@ionic/react";
import { FileStack } from 'views/forms/files/FileStack';
import { useHistory, useParams } from 'react-router';
import { DefaultPopUpApp } from 'views/popups/DefaultPopUp/DefaultPopUp.app';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { formatSystemDateShort } from 'scripts/helpers/dates';
import { handleDownloadFile, handleOpenFile } from './actions/actions';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { ellipsisVerticalSharp } from 'ionicons/icons';
import { useState } from 'react';


export const ContactDocumentsScreenApp = (props:IContactDocumentsScreenProps): JSX.Element => {
    const {data,update,remove,openEdit,isEditPopupShow,setSelectedData,selectedData,uploadFile,loading}=props;
    const history=useHistory()
    const { id } = useParams<{ id: string }>();

    const [downloadLoading,setDownloadLoading]=useState('0');
    const handleDownload = async(name:string,docID:string,ext:string)=>{
        setDownloadLoading(docID)
       await handleDownloadFile(name,docID,id,ext); 
       setDownloadLoading("0");
    }
    return (<>
          <div className="mt-4">
                {loading?<Loading/>:!!data.length?data.map((item, index) => {
                    return (<div className='border-bottom border-color--cloudy-sky-light pb-2 mb-3'>
                                <div className="row no-gutters pt-3 pb-2" key={index}>
                                    <div className="col-auto pr-3">
                                        <div className=' w-36px'><img className="mr-2 ml-md-2" src={"https://content.harstatic.com/media/icons/"+item.icon} alt='img' /></div>
                                    </div>
                                    <div className="col-8">
                                        <div className="font_size--large font_weight--semi_bold cursor--hand" onClick={()=>handleOpenFile(item.name,item.id,id)/* window.open(item.path, "_blank")*/}>{`${item.name.length>25?item.name.substring(0,25)+"...":item.name} (${item.extension})`}</div>
                                        <div className="font_size--small fadded_text" >{formatSystemDateShort(moment(item.created_dt).toDate())} </div>
                                    </div>
                                    
                                    <div className="col-1 ml-auto mr-0" >
                                    {item.id==downloadLoading?<IonSpinner className=' w-20px h-20px' color='primary' />:
                                      <Dropdown alignRight className="dropdown--custom__withoutarrow">
                                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                                                <IonIcon  slot="icon-only" icon={ellipsisVerticalSharp} color="dark"></IonIcon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item  onClick={()=> handleDownload(item.name,item.id,item.extension)}>Download</Dropdown.Item>
                                                <Dropdown.Item  onClick={()=>openEdit(item)}>Rename</Dropdown.Item>
                                                <Dropdown.Item  onClick={()=>remove(item.id)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    </div>
                                    
                                </div></div>
                    )}):
                <div >
                    <div className=" pb-5 mr-4 ml-4">
                        <div className="font_weight--semi_bold font_size--large">You don't have any documents</div>
                        <div>Add relevant documents for this contact, such as tax slips, renovation, property documents.</div>
                        </div>
                        <div  className="text-center d-flex justify-content-center mt-100px"><FileStack.Document text='Add a document' onUploadDone={(data) => { uploadFile(data) }} /></div>
                </div>}
            </div>
           {/**  <IonItemOptions>
                                <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold" onClick={()=>handleDownloadFile(item.name,item.id,id,item.extension)}>Download</div></IonItemOption>
                                <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold" onClick={()=> openEdit(item)}>Edit</div></IonItemOption>
                                <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold" onClick={()=>remove(item.id)}>Delete</div></IonItemOption>
                            </IonItemOptions>  */} 
            <div  className="text-center d-flex justify-content-center mt-100px">{!!data.length && <FileStack.Document text='Add Document' onUploadDone={(data) => { uploadFile(data) }} />}</div>
            {/*!!docUploadData? <DefaultPopUpApp className="bg-white" isOpen={!!docUploadData} onDidDismiss={()=>openEdit()} hideHeader  
                customHeader={ <IonHeader>
                        <IonToolbar>
                        <IonButtons className="w-15pr" slot="start">
                            <IonButton color={"blue"} onClick={() => history.goBack()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Upload Document</IonTitle>
                        <IonButtons className="w-15pr" slot="end">
                            <IonButton color={"blue"} onClick={saveUploadData}>Save</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader >}>
                <DefaultPopUp.Body>
                    <IonContent>
                        <div className="text-center d-flex justify-content-center">
                            <div className="align-self-center  w-100">
                                <IonItem>
                                    <IonLabel className='mb-10px mt-10px' position="stacked">Document Name </IonLabel>
                                    <IonInput autofocus={true} type="text" placeholder="Name" value={docUploadData.name} onIonChange={(e)=>docUploadData&&props.setDocUploadData({...docUploadData,name:e.detail.value??""})} required={true} />
                                </IonItem>
                            </div>
                        </div>
                    </IonContent>
                 </DefaultPopUp.Body>
                </DefaultPopUpApp>:<></>*/}

           {isEditPopupShow?<DefaultPopUpApp className="bg-white" isOpen={isEditPopupShow} onDidDismiss={()=>openEdit()} hideHeader  
                customHeader={ <IonHeader>
                        <IonToolbar>
                        <IonButtons className="w-15pr" slot="start">
                            <IonButton color={"blue"} onClick={() =>{openEdit(); history.replace(`/contacts/${id}/documents`)}}><img onClick={() =>{openEdit(); history.replace(`/contacts/${id}/documents`)}} src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Edit Document</IonTitle>
                        <IonButtons className="w-15pr" slot="end">
                            <IonButton color={"blue"} onClick={update}>Save</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader >}>
                
                <DefaultPopUp.Body>
                    <IonContent>
                        <div className="text-center d-flex justify-content-center">
                            <div className="align-self-center  w-100">
                                <IonItem>
                                    <IonLabel className=' mt-10px mb-10px' position="stacked">Rename Document</IonLabel>
                                    <IonInput autofocus={true} type="text" placeholder="Rename file Name" value={selectedData?.name} onIonChange={(e)=>selectedData&&setSelectedData({...selectedData,name:e.detail.value??""})} required={true} />
                                </IonItem>
                            </div>
                        </div>
                    </IonContent>
                 </DefaultPopUp.Body>
            </DefaultPopUpApp>:<></>}
            </>
        
    )
}
function Loading(_props: { }): JSX.Element {
    return (
      
<IonList className="mt-4">
    <IonItemSliding>
        <IonItem id="type-modal">
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3 w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
 


        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    <IonItemSliding>
        <IonItem>
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3 w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    <IonItemSliding>
        <IonItem>
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3  w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto  w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    <IonItemSliding>
        <IonItem>
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3  w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto  w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    <IonItemSliding>
        <IonItem>
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3  w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    <IonItemSliding>
        <IonItem>
            <div className="row pt-3 pb-3">
                <div className="col-5 align-self-center d-flex align-items-center">
                    <div className=" loading__square mr-3 w-36px h-36px"></div>
                    <div className=" loading__text w-150px"></div>
                </div>
                <div className="col-5 text-sm-left text-right align-self-center color_custom_4">
                    <div className=" loading__text w-70px"></div>
                </div>
                <div className="col-2 text-right align-self-center">
                    <div className=" loading__square ml-auto  w-36px h-36px"></div>
                </div>
            </div>
        </IonItem>
        <IonItemOptions>                
           <IonItemOption color="skyblue_light"><div className="pl-3 pr-3 font_weight--bold">Edit</div></IonItemOption>
           <IonItemOption color="red"><div className="pl-3 pr-3 font_weight--bold">Delete</div></IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

</IonList>        
 )
 }