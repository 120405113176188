
import { useHistory } from "react-router";

export const useStatic = () => {
    const history = useHistory();
    const STATIC_PARAMETER = 'static';
    const params = new URLSearchParams(history.location.search);
    const value = params.get(STATIC_PARAMETER);
    const isDropDownSearch = value === '1';
    const isTableGrid = value === '2';
    const isStatic = isDropDownSearch || isTableGrid;;

    return { isStatic, isDropDownSearch, isTableGrid } as const;
}