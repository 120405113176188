import { IWelcomeScreenProps } from './WelcomeScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { WelcomeScreenApp } from './WelcomeScreen.app';
import { WelcomeScreenDesktop } from './WelcomeScreen.desktop';

export const WelcomeScreen = (props: IWelcomeScreenProps): JSX.Element =>
{
    const WelcomeScreenPlatform = useResponsiveChoose(WelcomeScreenDesktop, WelcomeScreenApp);
    return (
        <WelcomeScreenPlatform {...props} />
    );
}
