

export const Loading = (props:{column?:number}) =>
(
     <div className="pt-5 row">
                        <div className={`col-${props.column??6}`}>
                            <div className="cards cards__taskstatus">
                                <div className="cards__taskstatus__date"><div className="loading__text" style={{width:'35px',height:'35px'}}></div></div>
                                <div className="cards__taskstatus__status"><div className="loading__photo" style={{width:'40px',height:'40px'}}></div></div>				
                                <div className="cards__taskstatus__text"><div className="loading__text_small mt-3" style={{width:'90px'}}></div></div>
                            </div>
                        </div>
                        <div className={`col-${props.column??6}`}>
                            <div className="cards cards__taskstatus">
                                <div className="cards__taskstatus__date"><div className="loading__text" style={{width:'35px',height:'35px'}}></div></div>
                                <div className="cards__taskstatus__status"><div className="loading__photo" style={{width:'40px',height:'40px'}}></div></div>				
                                <div className="cards__taskstatus__text"><div className="loading__text_small mt-3" style={{width:'90px'}}></div></div>
                            </div>
                        </div>
                        
                        <div className={`col-${props.column??6}`}>
                            <div className="cards cards__taskstatus">
                                <div className="cards__taskstatus__date"><div className="loading__text" style={{width:'35px',height:'35px'}}></div></div>
                                <div className="cards__taskstatus__status"><div className="loading__photo" style={{width:'40px',height:'40px'}}></div></div>				
                                <div className="cards__taskstatus__text"><div className="loading__text_small mt-3" style={{width:'90px'}}></div></div>
                            </div>
                        </div>
                    </div>
);
