import { IdeleteWarningProps, IemptyProps, IListManageTagsPopupProps, IManageTagsPopUpProps } from './ManageTagsPopUp.def';
import './ManageTagsPopUp.scss';
//import { fetchGetTagsService } from 'services/contacts/tags/getTags';
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList, IonModal, IonNote, IonTitle, IonToolbar } from "@ionic/react";
import { useState, useEffect, useRef } from 'react';
import { fetchGetTagsService } from 'services/contacts/tags/getTags';
import { useDeleteTagService } from 'services/contacts/tags/deleteTag';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useUpdateTagService } from 'services/contacts/tags/updateTag';
import { SimpleDialog } from 'views/dialogs/SimpleDialog/SimpleDialog.app';
import { useCreateTagService } from 'services/contacts/tags/createTag';
//import { useMultiRef } from 'scripts/hooks/general';
//import { usePopUpAutoFocus } from 'scripts/hooks/popups';

//import { useCreateTagService } from 'services/contacts/tags/createTag';

export const ManageTagsPopUpApp = (props: IManageTagsPopUpProps): JSX.Element => {
  const { show, toggleShow } = props
  const [warning, setWarning] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [newTag, setNewTag] = useState<string>("");
  const [newTagError, setNewTagError] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<IListManageTagsPopupProps>({ id: 0, name: "", contacts_count: 0 });
  const [updatingTag, setUpdatingTag] = useState<any>({ id: 0, name: "", error: "" });
  const [tagPopupList, setTagPopupList] = useState<Array<IListManageTagsPopupProps>>();
  const [saveNewTagData, saveNewTagActions] = useCreateTagService();
  const [updateData, updateActions] = useUpdateTagService();
  const [deleteTagInfo, deleteTagAction] = useDeleteTagService();
  const [timer, setTimer] = useState<number | string>()
  const [notify, setNotify] = useState(false)
  const ionRef = useRef<HTMLIonInputElement>(null);

  const fetchData = async (): Promise<void> => {
    const response = await fetchGetTagsService();
    setTagPopupList(response);
    return;
  };

  useEffect(() => {
    if (tagPopupList?.length === 0 && show) {
      setEmpty(true)
    }
  }, [tagPopupList, show]);

  const handleNew = async () => {
    if (newTag.length === 0 || newTag === "") {
      setNewTagError("Please enter a tag name")

      return
    }
    try {
      await saveNewTagActions.fetch({ name: newTag });
    } catch (error) { }
  }

  const handleUpdate = async () => {
    try {
      await updateActions.fetch(updatingTag);
    } catch (error) { }
    fetchData()

  };

  useEffect(() => {
    if (saveNewTagData.error) {
      setNewTagError(saveNewTagData?.error?.message)
    }
    if (saveNewTagData.response) {
      showNotifcation()
      setNewTagError('')
      setNewTag('')
      fetchData()
    }
  }, [saveNewTagData])



  const handelDelete = async () => {
    try {
      await deleteTagAction.fetch({ id: selectedTag.id });
    } catch (error) { }
  }
  useEffect(() => {
    if (deleteTagInfo.response) {
      showNotifcation()
      setWarning(false)
      fetchData()
    }
  }, [deleteTagInfo])

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (newTag.length > 0) {
      saveNewTagActions.fetch({ name: newTag });
    }
  }, [updatingTag])

  useEffect(() => {
    if (updateData.response) {
      showNotifcation()
    }
  }, [updateData])






  function showNotifcation() {
    setNotify(true)
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(setTimeout(() => {
      setNotify(false)


    }, 3500))
  }


  //usePopUpAutoFocus(ref);

  return (
    <>
      <EmptyState show={empty && show ? true : false} toggleShow={() => setEmpty(empty ? false : true)} backToContact={() => toggleShow ? toggleShow() : ''} />
      <DeleteWarning tag={selectedTag} handleDelete={() => handelDelete()} show={warning} toggleShow={() => setWarning(warning ? false : true)} />
      <IonModal className="bg-white" isOpen={show} trigger="open-modal" backdropDismiss={false} onIonModalDidPresent={() => {
        setNewTagError('');
        ionRef.current?.setFocus()
      }}>
        <IonHeader>
          <IonToolbar>
            <IonButtons className="w-15pr" slot="start">
              <IonButton className='color_0738CD' onClick={() => toggleShow ? toggleShow() : ""}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
            </IonButtons>
            <IonTitle>Manage Tags</IonTitle>
            <IonButtons className="w-15pr" slot="end">
              <IonButton className='color_0738CD' onClick={() => handleNew()}>Ok</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonInput ref={ionRef} className="tag_input" placeholder="Add New Tag…" value={newTag} onIonInput={(e: any) => setNewTag(e.target.value)}></IonInput>
            {newTagError &&
              <IonNote slot="helper" className="input-description input-description__error px-2">{newTagError}</IonNote>
            }
          </IonItem>

          <IonList>
            {tagPopupList?.map((item: IListManageTagsPopupProps) =>
              <IonItemSliding key={'item_' + item.id} id={'item_' + item.id}>
                <IonItem>
                  <img className="mr-3" src="https://content.harstatic.com/media/icons/a_all/icon_tags_blue.svg" />
                  <IonInput placeholder="Change Tag Name"
                    value={item.id === updatingTag.id ? updatingTag.name : item.name + ' (' + item.contacts_count + ')'}
                    onIonInput={(e: any) => setUpdatingTag({ ...updatingTag, name: e.target.value })}
                    onClick={() => {
                      setUpdatingTag({ id: item.id, name: item.name })
                    }}
                    onIonBlur={() => {
                      if (updatingTag.id !== 0) {
                        handleUpdate()
                      }
                    }}
                  ></IonInput>
                </IonItem>

                <IonItemOptions>
                  {item.id === updatingTag.id ?
                    <IonItemOption color="blue" onClick={() => { handleUpdate() }}>Update</IonItemOption>
                    :
                    <IonItemOption color="red" onClick={() => { setSelectedTag(item); setWarning(true) }}>Delete</IonItemOption>
                  }
                </IonItemOptions>
              </IonItemSliding>


            )}
          </IonList>
          {saveNewTagData?.response && notify && <SimpleDialog message="Successfully saved." />}
          {deleteTagInfo?.response && notify && <SimpleDialog message={'Tag ' + selectedTag.name + ' deleted.'} />}
          {updateData?.response && notify && <SimpleDialog message="Successfully saved." />}
          {/*updateData.error && <ErrorDialog title={'Error'}>{updateData?.error?.message}</ErrorDialog>*/}
          {/*deleteTagInfo.error && <ErrorDialog title={'Error'}>{deleteTagInfo?.error?.message}</ErrorDialog>*/}

        </IonContent>
      </IonModal>
    </>
  )
}

const DeleteWarning = (props: IdeleteWarningProps) => {
  const { tag, show, handleDelete, toggleShow } = props
  return (
    <>
      <IonModal isOpen={show} className="ion-custom-modal__center" id="example-modal" trigger="open-custom-dialog" backdropDismiss={false}>
        <div className="p-4">
          <h2 className="mb-3" tabIndex={0}>Delete Tag?</h2>
          <div className="color_sold font_size--medium font_weight--semi_bold mb-2">ATTENTION:</div>
          <ul className="list list--inside">
            <li className="mb-2">There are {tag.contacts_count} contacts with the tag {tag.name}.</li>
            <li className="mb-2">Deleting this tag is permanent and can’t be undone.</li>
            <li className="mb-2">All contacts allocated to it will be untagged.</li>
          </ul>
        </div>
        <div className="row">
          <div className="col">
            <ShapelessButton className="btn--large" onClick={() => toggleShow ? toggleShow() : ''}>Cancel</ShapelessButton>
          </div>
          <div className="col-auto text-right">
            <ShapelessButton className="btn--large btn--shapeless_dark btn--redtext" onClick={() => handleDelete ? handleDelete() : ''}>Yes, Delete Tag</ShapelessButton>
          </div>
        </div>
      </IonModal>
    </>
  )
}

const EmptyState = (props: IemptyProps) => {
  const { show, toggleShow, backToContact } = props
  const backTo = () => {
    if (toggleShow) {
      toggleShow()
    }
    if (backToContact) {
      backToContact()
    }

  }
  return (
    <IonModal className="bg-white" isOpen={show} trigger="open-modal" backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonButtons className="w-15pr" slot="start">
            <IonButton color={"blue"} onClick={() => backTo()}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> Back</IonButton>
          </IonButtons>
          <IonTitle>Manage Tags</IonTitle>

        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="text-center h-100 d-flex justify-content-center">
          <div className="align-self-center font_size--large_extra color_cement_dark">
            <div className="font_weight--bold mb-3">No tag created</div>
            <div className="mb-4">Create tags to better organize and easily find your contacts</div>
            <PrimaryButton className="btn--large w-100" onClick={() => toggleShow ? toggleShow() : ''}>New Tag</PrimaryButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
}


