import { Popover } from "react-bootstrap";

export const popover_score = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Score</Popover.Title>
        <Popover.Content>
        The scoring system allows you to assign grades (e.g., A, B, C, D) to your Contacts. Assigning a Score helps to identify and prioritize the most qualified and sales-ready Contacts, enabling you to focus your efforts and allocate resources effectively.
        </Popover.Content>
    </Popover>
);

export const popover_tags = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Tags</Popover.Title>
        <Popover.Content>
            Assigning Tags to your Contacts allows you to easily segment and group Contacts based on specific criteria, such as interests, demographics, or behaviors, enabling more targeted and personalized marketing and sales efforts.
            <br /><br />
            Use the dropdown menu to assign one of your 50 most-used Tags. You can also type the name of a Tag into the field below to search and select it.
        </Popover.Content>
    </Popover>
);
export const popover_tags_text={content:<>Assigning Tags to your Contacts allows you to easily segment and group Contacts based on specific criteria, such as interests, demographics, or behaviors, enabling more targeted and personalized marketing and sales efforts.<br></br><br></br> Use the dropdown menu to assign one of your 50 most-used Tags. You can also type the name of a Tag into the field below to search and select it.</>,title:"Tags"}