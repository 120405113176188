import { booleanToStr } from 'scripts/helpers/texts';
import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace EditCalendarTaskCompletedApi
{
    export const url = "/tasks/:id/completed/:status_id";
    export const method = "PUT";
    export const defaultError = "Failed to update task.";
    export const operations = apiOperations(EditCalendarTaskCompletedApi);
    export const scope = new class EditCalendarTaskCompletedApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input,
                status_id:booleanToStr(input.status_id)
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        id:string,
        status_id:boolean
    }

    export interface Response
    {
        status: string,
        message:string
    }
}

export const fetchEditCalendarTaskCompletedService = forwardOperation(EditCalendarTaskCompletedApi.operations, 'fetch');
export const useFetchEditCalendarTaskCompletedService = forwardOperation(EditCalendarTaskCompletedApi.operations, 'useFetch');
export const useEditCalendarTaskCompletedService = forwardOperation(EditCalendarTaskCompletedApi.operations, 'useService');