
import { Tag } from 'scripts/ui/tag';
import {IContactPhotoProps} from './ContactPhoto.def'
export function ContactPhoto(props:IContactPhotoProps) {
      const getRandomClass = (num: number): string => {
        let res = num % 16
        if (res === 0) {
            return 'nophoto_color_16'
        } else {
            return 'nophoto_color_' + res;
        }
    }
    return (
         props.contact?.photo_url ?
            <Tag.Div className="agent_signature__photo" cssVars={{ avatarAgentPicture: `url(${props.contact.photo_url})` }}></Tag.Div>:
            <div className={`agent_signature__photo ${getRandomClass(props.index||16)}`} >{props.contact?.first_name?.charAt(0).toLocaleUpperCase()}{props.contact?.last_name?.charAt(0).toLocaleUpperCase()} </div>
        );
}

