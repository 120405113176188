import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace SearchTagsApi {
    export const url = "/typeaheads/tags";
    export const method = "GET";
    export const defaultError = "Failed to load tags.";
    export const operations = apiOperations(SearchTagsApi);
    export const scope = new class SearchTagsApiScope implements IApiScope<Request, Response>
    {
        request(data: Request) {
            const { input, current } = data;
            return { input, current: current?.join(',') };
        };

        response(input: ServerResponse): Response {
            const { data } = input;
            return data.map(({ id, name }) => ({ id: parseInt(id), name }));
        }
    }();

    export interface Request {
        input: string;
        current?: Array<string>; // TODO: Convert to array of number
    }

    export type Response = Array<ITag>;
    export type ServerResponse = Record<'data', ITag<string>[]>;

    export interface ITag<K = number> {
        id: K;
        name: string;
    }
}

export const fetchSearchTagsService = forwardOperation(SearchTagsApi.operations, 'fetch');
export const useFetchSearchTagsService = forwardOperation(SearchTagsApi.operations, 'useFetch');
export const useSearchTagsService = forwardOperation(SearchTagsApi.operations, 'useService');