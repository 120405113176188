import { useValidateController } from 'scripts/hooks/validations';
import { useEffect, useRef, useState } from 'react';
import { IEditContactScreenProps, ILastRequiredFields } from './EditContactScreen.def';
import './EditContactScreen.scss';
import { BasicInformationSection } from 'views/contacts/BasicInformationSection/BasicInformationSection';
import { ContactInsightSection } from 'views/contacts/ContactInsightSection/ContactInsightSection';
import { AddressesSectionApp } from './components/AddressesSection/AddressesSection.app';
import { RelationshipSection } from 'views/contacts/RelationshipSection/RelationshipSection.app';
import { ContactSection } from './components/ContactSection/ContactSection.app';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { IBasicInfoData } from 'views/contacts/BasicInformationSection/BasicInformationSection.def';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { IContactDataApp } from 'views/contacts/ContactSection/ContactSection.def';
import { IRelationItem, IRelationItem as IRelationItemApp } from 'views/contacts/RelationshipSection/RelationshipSection.def';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { useFetchGetContactService } from 'services/contacts/contactDetails/getContact';

/*import { fetchEmailExistenceService } from 'services/contacts/emailExistence';
import { fetchPhoneExistenceService } from 'services/contacts/phoneExistence';
import { PromiseController } from 'scripts/helpers/async';
import { cloneDeep } from 'lodash-es';*/
import { isNumber } from 'lodash-es';
import { useHistory, useLocation, useParams } from 'react-router';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useIonAlert } from '@ionic/react';
import { IStageAddContactsProps } from 'views/contacts/pipeline/StageColumn/StageColumn.def';
import { initialAddress, initialContactInsight,transformEditData} from 'services/contacts/contactDetails/updateContact'
import { AddEmailApi, fetchAddEmailService } from 'services/contacts/contactDetails/addEmail';
import { fetchDeleteEmailService } from 'services/contacts/contactDetails/deleteEmail';
import { useDeleteRelationshipService } from 'services/contacts/contactDetails/deleteRelationship';
import { fetchUpdateBasicInformationService, useUpdateBasicInformationService } from 'services/contacts/contactDetails/updateBasicInformation';
import { useTimeoutMap } from 'scripts/hooks/timers';
import { fetchDeletePhoneService } from 'services/contacts/contactDetails/deletePhone';
import { AddPhoneApi, fetchAddPhoneService } from 'services/contacts/contactDetails/addPhone';
import { fetchAddLinkService } from 'services/contacts/contactDetails/addLink';
import { fetchDeleteLinkService } from 'services/contacts/contactDetails/deleteLink';
import { fetchAddRelationshipService } from 'services/contacts/contactDetails/addRelationship';
import { fetchUpdateRelationshipService } from 'services/contacts/contactDetails/updateRelationship';
import { fetchUpdateContactInsightService } from 'services/contacts/contactDetails/updateContactInsight';
import { fetchAddAddressService } from 'services/contacts/contactDetails/addAddress';
import { fetchUpdateAddressService } from 'services/contacts/contactDetails/updateAddress';
import { fetchUpdateEmailService, UpdateEmailApi } from 'services/contacts/contactDetails/updateEmail';
import { useDeleteContactService } from 'services/contacts/contactDetails/deleteContact';
//import { fetchGetEmailService } from 'services/contacts/contactDetails/getEmails';
import { fetchUpdatePhoneService, UpdatePhoneApi } from 'services/contacts/contactDetails/updatePhone';
import { OrdinaryButton } from 'views/forms/buttons/Button';
import { ServiceError } from 'scripts/exceptions';
import { duplicatedContactMessage } from 'scripts/validations/messages';
import { ifElse } from 'scripts/helpers/general';
import { fetchUpdateLinkService } from 'services/contacts/contactDetails/updateLink';
//import { IEmailDataApp, IPhoneDataApp } from './components/ContactSection/ContactSection.def';
// const emptyAddressForm={ 
//   address1:{value:"",error:""},
//   address2:{value:"",error:""},
//   address_type:{value:"",error:""},
//   city:{value:"",error:""},
//   zipcode:{value:"",error:""}}
const emptyRelationForm = {
  name:"",
  type:""
}
//var timeout:any;

//const emptyContactForm={ 
   // emails: [{value:'',error:'',preferred:true,importEmail:true,id:undefined}], 
   // phones: [{value:'',error:'',preferred:true,phone_type:"N",id:undefined}],
    // links: [{id:undefined,value:"",error:"",link_type:""}] }
export const EditContactScreenApp = (props: IEditContactScreenProps): JSX.Element => {
  //const {show,toggleShow} =props
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const formEmptyRef = useRef(true);

  const [validation] = useValidateController();
  const [contactDetails,getContactDetails] = useFetchGetContactService({ contact_id:parseInt(id), renew: '1' });
  const [, ActionDeleteRelationship] = useDeleteRelationshipService();
  const [updateBasicInfo]=useUpdateBasicInformationService();
  //const [, ActionDeleteEmail] = useDeleteEmailService();
  const [basicInfo,setBasicInfo] = useState<IBasicInfoData>();
  const [addresses, setAddresses] = useState<IAddressItem[]>(() => [initialAddress()]);
  const [relationships, setRelationships] = useState<IRelationItemApp[]>([{id:undefined,type:""}]);
  const [contactInsight, setContactInsightForm] = useState<IContactInsightData>(initialContactInsight);
  const [contactData, setContactData] = useState<IContactDataApp>({ 
    emails: [{value:'',error:'',preferred:true,importEmail:true,id:undefined}], 
    phones: [{value:'',error:'',preferred:true,phone_type:"N",id:undefined}],
     links: [{value:'',error:'',link_type:''}] });
  //const [lastContactData, setLastContactData] = useState<IContactDataApp>(contactData);
  const [presentError] = useIonAlert();
  const [presentCancelConfirmation, dismissCancelConfirmation] = useIonAlert();
  const [lastRequiredFields, setRequiredFields] = useState<ILastRequiredFields>();
  const [removedContact, ActionRemoveContact] = useDeleteContactService();
  const removeContact = () => {
    ActionRemoveContact.fetch({
        contact_id: id
    })
}

useEffect(() => {
  if (removedContact.response) {
      window.location.href = "/contacts"
  }
}, [removedContact]);

  //const modalRef = useRef<HTMLIonModalElement>(null);
  let location = useLocation<IStageAddContactsProps>();
 // useServiceDidSucceeded(createData, () => redirectAfterSuccess())

  const addEmailTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = contactData.emails[index];
    if(!data) { return; }

    // Existing email
    if(data.id) { return updateEmailTimer.run(index); }

    // Getting parameters
    const { preferred } = data;
    const email = data.value;

    // 1. Check Email Existence
    /*const isValid = yield* this.step(checkEmailAvailability, index);
    if(!isValid) { return console.log('email not valid!') }*/

    // 2. Add a new email
    let response:AddEmailApi.Response;
    try { response = yield* this.step(fetchAddEmailService, { contact_id, email, preferred }); }
    catch(err:any) {
      if(handleContactDuplication(err)) { revertPrimaryEmail(false); }
      throw err;
    }

    setContactData(prev => {
      const data = prev.emails[index];
      if(data) { data.id = response.id; }
      return prev;
    });

    // 3. Updating Required Field
    const emailData = { id:response.id, value:email };
    if(data.preferred) { setRequiredFields(fields => ({ ...fields, email:emailData })); }
    //updateLastContactData();
    
    // 4. Replacing Summary
    props.onSummaryChange?.();

  }, 1000);

  const updateEmailTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = contactData.emails[index];
    if(!data || !data.id) { return; }
    const { id:emailID, preferred } = data;
    const email = data.value.trim();

    // Delete if there is no email
    if(!email) { return handleRemoveContact('emails', index); }

    // Checking required fields
    if(!validateRequiredFields()) { return; }

    // 1. Check Email Existence
    /*const existence = lastContactData.emails.filter(x => !!x.id).some(x => x.value === email);
    if(!existence) {
      const isValid = yield* this.step(checkEmailAvailability, index);
      if(!isValid) { return console.log('email not valid!') }
    }*/

    // 2. Add a new email and updating info
    let response:UpdateEmailApi.Response;
    try { response = yield* this.step(fetchUpdateEmailService, { contact_id, email, preferred:preferred?"1":"0",email_id:emailID.toString() }); }
    catch(err:any) {
      if(handleContactDuplication(err)) { revertPrimaryEmail(false); }
      throw err;
    }
    // const getEmails=yield* this.step(fetchGetEmailService,{contact_id,renew:"1"});
    if(response.id)
    {
      setContactData(prev => {
        const data = prev.emails[index];
        if(data) { data.id = response.id; }
        return prev;
      });
    }

    // 3. Updating Required Field
    const emailData = { id:emailID, value:email };
    if(data.preferred) { setRequiredFields(fields => ({ ...fields, email:emailData })); }
    //updateLastContactData();

    // 4. Replacing Summary
    props.onSummaryChange?.();
    
  }, 1000);
  //Phone 
  const addPhoneTimer = useTimeoutMap(async function*(index:number)
  {
  // Getting parameters
  const contact_id = id;
  const data = contactData.phones[index];
  if(!data) { return; }

  // Existing phone
  if(data.id) { return updatePhoneTimer.run(index); }

  // Getting parameters
  const phone = data.value;
  const {phone_type, phone_extension, preferred} = data;

  // 1. Check phone Existence
  /*const isValid = yield* this.step(checkPhoneAvailability, index);
  if(!isValid) { return console.log('phone not valid!') }*/

  // 2. Add a new phone
  let response:AddPhoneApi.Response;
  try { response = yield* this.step(fetchAddPhoneService, { contact_id, phone, phone_type, phone_extension, preferred:preferred?'1':'0'}); }
  catch(err:any) {
    if(handleContactDuplication(err)) { revertPrimaryPhone(false); }
    throw err;
  }

  setContactData(prev => {
    const data = prev.phones[index];
    if(data) { data.id = response.id; }
    return prev;
  });

  // 3. Updating Required Field
  const phoneData = { id:response.id, value:phone, phoneType:phone_type };
  if(data.preferred) { setRequiredFields(fields => ({ ...fields, phone:phoneData })); }
  //updateLastContactData();

  // 4. Replacing Summary
  props.onSummaryChange?.();

  }, 1000);

  const updatePhoneTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = contactData.phones[index];
    if(!data || !data.id) { return; }
    const { id:phoneID, preferred } = data;
    const phone = data.value.trim();
    const phone_extension = data.phone_extension;
    const phone_type = data.phone_type;

    // Checking required fields
    if(!validateRequiredFields()) { return; }

    // Delete if there is no email
    if(!phone) { return handleRemoveContact('phones', index); }

    // 1. Check phone Existence
    /*const isValid = yield* this.step(checkPhoneAvailability, index);
    if(!isValid) { return console.log('phone not valid!') }*/

    // 2. Add a new phone and updating info
    let response:UpdatePhoneApi.Response;
    try { response = yield* this.step(fetchUpdatePhoneService, { contact_id, phone,phone_id:phoneID.toString(), phone_type, phone_extension, preferred:preferred?'1':'0'}); }
    catch(err:any) {
      if(handleContactDuplication(err)) { revertPrimaryPhone(false); }
      throw err;
    }

    if(response.id) {
      setContactData(prev => {
        const data = prev.phones[index];
        if(data) { data.id = response.id; }
        return prev;
      });
    }

    // 3. Updating Required Field
    const phoneData = { id:phoneID, value:phone, phoneType:phone_type };
    if(data.preferred) { setRequiredFields(fields => ({ ...fields, phone:phoneData })); }
    //updateLastContactData();

    // 4. Replacing Summary
    props.onSummaryChange?.();

  }, 1000);

  const addLinkTimer = useTimeoutMap(async function*(index:number)
  {
  // Getting parameters
  const contact_id = id;
  const data = contactData.links[index];
  if(!data) { return; }
  // Existing phone
  if(data.id) { return updateLinkTimer.run(index); }

  // Getting parameters
  const name = data.link_type;
  const url = data.value;
  

  // 2. Add a new link
  const response = yield* this.step(fetchAddLinkService, { contact_id,url,name});
  setContactData(prev => {
    const data = prev.links[index];
    if(data) { data.id = response.id; }
    return prev;
  });

  //updateLastContactData();

  }, 1000);

  const updateLinkTimer = useTimeoutMap(async function*(index:number)
  {
    
    // Getting parameters
    const contact_id = id;
    const data = contactData.links[index];
    if(!data || !data.id) { return; }
    const { id:linkID } = data;
    const name = data.link_type;
    const url = data.value.trim();

    // Delete if there is no email
    if(data.id &&(url=='' && name=='')) { return handleRemoveContact('links', index); }
    if(url=='' && name==''){return;}
    // 2. Add a new link and updating info
    const added = yield* this.step(fetchUpdateLinkService, { contact_id,link_id:linkID.toString(), name, url});
    setContactData(current => {
      const data = current.links[index];
      if(!data) { return current; }
      data.id = added.link_id;
      return { ...current };
    });

    // 3. Remove previous phone
    // We don't use "step" here, because deletion must happen after phone is added
  //  await fetchDeleteLinkService({ contact_id, link_id:linkID.toString() });
    //updateLastContactData();

  }, 1000);

  const addRelationshipTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = relationships[index];
    if(!data) { return; }

    // Existing email
    if(data.id) { return updateRelationshipTimer.run(index); }

    // Getting parameters
    const type  = data.type;
    //const  name= data.name;
    const related_contact_id = data.contact?.contact_id;

    // 2. Add a new email
    const response = yield* this.step(fetchAddRelationshipService, { contact_id,type,related_contact_id });
    setRelationships(prev => {
      const data = prev[index];
      if(data) { data.id = response.id; }
      return prev;
    });

  }, 1000);

  const updateRelationshipTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = relationships[index];
    if(!data || !data.id) { return; }
    const relationshipId = data.id.toString();
    const type  = data.type;
    //const  name= data.name;
    const related_contact_id = data.contact?.contact_id;

    // 2. Add a new link and updating info
  /*const added=*/ yield* this.step(fetchUpdateRelationshipService, { contact_id,id:relationshipId,type,related_contact_id });
   /* setRelationships(current => {
      if(!data) { return current; }
      current[index]={id:parseInt(added.id),name:{value:name,error:""},type:{value:type,error:""} }
      return { ...current };
    });
*/
  }, 1000);
   const updateBasicInfoTimer = useTimeoutMap(async function*()
  {
    // Checking required fields
    if(!validateRequiredFields()) { return; }

    // Getting parameters
    const contact_id = id;
    const data = basicInfo;
    if(!data || !data.id) { return; }
    const photo_url = data.image
    const first_name = data?.firstName??""
    const last_name = data?.lastName??""
    const middle_name = data.middleName;
    const title = data.title;
    const name_suffix = data.suffix;
    const company = data.company
    const income = data.income
    const known_since = data.knownSince
    const birthday = data.dateOfBirth
    const job_title = data.jobTitle
    const reminder_days_after=data.reminder_days_after?.toString()
    const home_anniversary_date = data.homeAniversary
    const anniversary_date = data.weddingAniversary
    const gender = data.gender
   
    // 2. Add a new email and updating info
    try { yield* this.step(fetchUpdateBasicInformationService, {contact_id,photo_url,first_name,middle_name,last_name,title,name_suffix,gender,company,income,known_since,birthday ,job_title,reminder_days_after,home_anniversary_date,anniversary_date}); }
    catch(err:any) {
      if(handleContactDuplication(err)) { revertNames(false); }
      throw err;
    }
   
   /* setBasicInfo(current => {
      const data = current;
      if(!data) { return current; }
      data.firstName = added.first_name;
      return { ...current };
    });*/

    // 3. Updating Required Field
    setRequiredFields(fields => ({ ...fields, firstName:first_name, lastName:last_name, company:company }));

    // 4. Replacing Summary
    props.onSummaryChange?.();

  }, 1000);
  const updateContactInsightTimer = useTimeoutMap(async function*()
  {
    // Getting parameters
    const contact_id = id;
    const data = contactInsight
    if(!data) { return; }
    const pipeline_status = data.pipelineType
    const score = data.scoreType
    const sources = data.sourceType
    const lead_type = data.leadType;
    const tags = data.tags
    // 2. Add a new email and updating info
   /* const added = */yield* this.step(fetchUpdateContactInsightService, {contact_id,pipeline_status,score,sources,lead_type,tags});
   /* setBasicInfo(current => {
      const data = current;
      if(!data) { return current; }
      data.firstName = added.first_name;
      return { ...current };
    });*/

    // 3. Replacing Summary
    props.onSummaryChange?.();

  }, 1000);
  const addAddressTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = addresses[index];
    if(!data) { return; }

    // Existing email
    if(data.id) { return updateAddressTimer.run(index); }

    // Getting parameters
    const address1 = data.address1;
    const address2 = data.address2;
    const zipcode = data.zipcode.toString();
    const address_type = data.address_type;
    const city = data.city;
    const state = data.state;
    const country = data.country;
    
    // 2. Add a new address
    const added =  yield* this.step(fetchAddAddressService, { contact_id,address1, address2, address_type, zipcode, city,state,country});
    setAddresses(current => {
      const data = current[index];
      if(!data) { return current; }
      data.id = added.id;
      return [ ...current ];
    });
  }, 1000);

  const updateAddressTimer = useTimeoutMap(async function*(index:number)
  {
    // Getting parameters
    const contact_id = id;
    const data = addresses[index];
    if(!data || !data.id) { return; }

    const address_id=data.id.toString()
    const address1 = data.address1;
    const address2 = data.address2;
    const zipcode = data.zipcode.toString();
    const address_type = data.address_type;
    const city = data.city;
    const state = data.state;
    const country = data.country;
    // 2. Add a new address and updating info
     yield* this.step(fetchUpdateAddressService, { contact_id,address_id, address1, address2, zipcode,address_type,city,state,country});
    
  }, 1000); 

  useEffect(()=>{
    if(!contactDetails.response){ getContactDetails.fetch({ contact_id:parseInt(id), renew: '1' })}else{
    const {basicInfoDt,addressesDt,relationshipsDt,contactDataDt,contactInsightDt}= transformEditData(contactDetails?.response);
     setAddresses(addressesDt??[initialAddress()]);
     setRelationships(relationshipsDt);
     setContactInsightForm(contactInsightDt??initialContactInsight);
     setContactData(contactDataDt);
     setBasicInfo(basicInfoDt);
     //updateLastContactData();
    }
  },[contactDetails.response])
  // useEffect(()=>{
  //   if(contactDetails.response&&isAnyChange(contactInsight,contactDetails.response,"contactInsight")){
  //     handleContactInsightSave();
  //   }
  // },[contactInsight]);

  useServiceDidSucceeded(contactDetails, (data) => {
    const { first_name:firstName, last_name:lastName, company } = data;
    const preferredEmail = data.emails.find(x => x.preferred);
    const preferredPhone = data.phones.find(x => x.preferred);
    const email = preferredEmail? { id:preferredEmail.id, value:preferredEmail.email }: undefined;
    const phone = preferredPhone? { id:preferredPhone.id, value:preferredPhone.phone, phoneType:preferredPhone.phone_type }: undefined;
    const timeout = setTimeout(() => setRequiredFields({ firstName, lastName, company, email, phone }), 500);
    return () => clearTimeout(timeout);
  });

  /*const updateLastContactData = () => setContactData(prev =>
  {
    setLastContactData(cloneDeep({ ...prev }));
    return prev;
  });*/

  const validateRequiredFields = (): boolean =>
  {
    const { firstName, lastName, company } = basicInfo ?? {};
    const email = contactData.emails.find(x => x.preferred)?.value?.trim();
    const phone = contactData.phones.find(x => x.preferred)?.value;
    const hasName = [firstName, lastName, company].some(x => !!x?.trim());
    const hasContact = [email, phone].some(x => !!x?.trim());
    const isValid = hasName && hasContact;
    if(isValid) { return true; }

    // Reverting last values
    revertNames();
    revertPrimaryEmail();
    revertPrimaryPhone();

    // Showing message
    showRequiredFieldsError();
    return false;
  }

  function revertNames(onlyEmpty = true)
  {
    const lastFields = lastRequiredFields ?? {};
    setBasicInfo(current => {
      current = current || {};
      if(!onlyEmpty || !current.firstName) { current.firstName = lastFields.firstName; }
      if(!onlyEmpty || !current.lastName) { current.lastName = lastFields.lastName; }
      if(!onlyEmpty || !current.company) { current.company = lastFields.company; }
      return { ...current };
    });
  }

  function revertPrimaryEmail(onlyEmpty = true)
  {
    const lastFields = lastRequiredFields ?? {};
    setContactData(current => {
      const emailId = lastFields.email?.id;
      let emailItem = (emailId? current.emails.find(x => x.id === emailId): null) ?? current.emails.find(x => x.preferred);
      if(!emailItem) { current.emails.push(emailItem = { preferred:true, value:'', error:'', importEmail:false }); }
      emailItem.preferred = true;
      if(!!lastFields.email && (!onlyEmpty || !emailItem.value)) {
        emailItem.id = lastFields.email.id;
        emailItem.value = lastFields.email.value ?? '';
      }

      return { ...current };
    });
  }

  function revertPrimaryPhone(onlyEmpty = true)
  {
    const lastFields = lastRequiredFields ?? {};
    setContactData(current => {
      const phoneId = lastFields.phone?.id;
      let phoneItem = (phoneId? current.phones.find(x => x.id === phoneId): null) ?? current.phones.find(x => x.preferred);
      if(!phoneItem) { current.phones.push(phoneItem = { preferred:true, value:'', error:'', phone_type:'' }); }
      phoneItem.preferred = true;
      if(lastFields.phone && (!onlyEmpty || !phoneItem.value)) {
        phoneItem.id = lastFields.phone.id;
        phoneItem.value = lastFields.phone.value ?? '';
        phoneItem.phone_type = lastFields.phone.phoneType;
      }

      return { ...current };
    });
  }

  const showRequiredFieldsError = () =>
  {
    presentError({
        header: 'Required Fields',
        message: `Please provide at least one of the following:<br />first name, last name or company, along with either a primary phone number or primary email address.`,
        buttons:['OK'],
        cssClass: 'p-5'
    });
  }

  const handleContactSave = (index:number, name:string) =>
  {     
    if(lastRequiredFields === undefined) { return; }

    if (name === "emails") {
      const data = contactData.emails[index];
      if(!data) { return; }

      if(data.id) { updateEmailTimer.schedule(index); }
      else { addEmailTimer.schedule(index); }
    }

    if (name === "phones") {
      const data = contactData.phones[index];
      if(!data) { return; }
      if(data.id) { updatePhoneTimer.schedule(index);}
      else {addPhoneTimer.schedule(index); }
    }
    if (name === "links") {

      const data = contactData.links[index];
      if(!data) { return; }
      if(data.id) { updateLinkTimer.schedule(index);}
      else { 
        addLinkTimer.schedule(index); }
    }

  }; 
  
  const handleRelationshipSave=(index:number)=>
  {
    if(lastRequiredFields === undefined) { return; }

    const data = relationships[index];
    if(!data||!data.contact) { return; }
    if(data.id) { updateRelationshipTimer.schedule(index); } else { addRelationshipTimer.schedule(index); }
  }

  const handleAddressSave=(index:number)=>
  {
    if(lastRequiredFields === undefined) { return; }

    const data = addresses[index];
    if(!data) { return; }
    else if(data.address1 === '' && data.address2 === ''&& data.city === ''&& !data.zipcode&& data.state === ''&& data.country=== '') {return;}

    if(data.id) { updateAddressTimer.schedule(index); }
    else { addAddressTimer.schedule(index); }
  }

  const handleBasicInfoSave= () =>
 {   
  if(lastRequiredFields === undefined) { return; }

   if (basicInfo) {
     const data = basicInfo;
     if(!data) { return; }
 
     updateBasicInfoTimer.schedule(0); 
    
   }
  }

  const handleContactInsightSave= (data?:IContactInsightData) =>
  {     
    if(lastRequiredFields === undefined) { return; }
    if(!data) { data = contactInsight; }
    if (data) { updateContactInsightTimer.schedule(0); }
  }

  const showCancelConfirmation = () => {
    presentCancelConfirmation({
        header: 'Cancel Update Contact?',
        message: 'Are you sure you want to cancel Update contact, the data will be lost?',
        buttons: [
            { text: 'Continue to Update Contact', handler: dismissCancelConfirmation },
            { text: 'Cancel Update Contact', cssClass: 'btn btn--medium btn--primary', handler() { cancel(false); } }
        ]
    });
  }

  /*const checkEmailAvailability = (index:number): PromiseController<boolean> =>
   {
    const data = contactData.emails[index];
    if(!data) { return PromiseController.resolve(false); }
    if(isEmpty(data.value)) { return PromiseController.resolve(true); }

    const msg = 'This email already exists in the CRM, please type another email';
    //const assignObj = contactData?.emails[index] || { value: "", error: "", preferred: false, importEmail: false }
    //if(!contactData?.emails[index]?.id) {

    return fetchEmailExistenceService({ email: data.value }).then(email => {
        if (email?.isDuplicated) {
        setContactData((prev: any) => {
          prev.emails.splice(index, 1, { ...data, error: msg });
          return { ...prev };
        });
        //contactData.emails.splice(index, 1, { ...data, error: msg });
          return false;
        }
        return true;
      });
   }

  const checkPhoneAvailability = (index:number): PromiseController<boolean> =>
  {
   const data = contactData.phones[index];
   if(!data) { return PromiseController.resolve(false); }

   const msg = 'This phone already exists in the CRM, please type another phone';
   const assignObj = contactData?.phones[index] || { value: "", error: "", preferred: false, phone_type: "" }
   if(!contactData?.phones[index]?.id && !isEmpty(assignObj.value)) {

     return fetchPhoneExistenceService({ phone: assignObj.value }).then(phone => {
       if (phone?.isDuplicated) {
         contactData.phones.splice(index, 1, { ...assignObj, error: msg });
         return false;
       }
       return true;
     });
  }
  else { return PromiseController.resolve(true); }
 }*/

  const handleContactDuplication = (error:ServiceError<any>): boolean =>
  {
    if(!error.data?.is_duplicated && !error.data?.data?.is_duplicated) { return false; }

    error.preventDefault();
    presentError({
      header: 'Duplicated Contact',
      //message: error.message,
      message: duplicatedContactMessage(),
      buttons:['OK'],
      cssClass: 'p-3 line-h-1_2'
    });

    return true;
  }

  const redirectAfterSuccess = () => {
    history.replace(`/contacts`)
  };
  
  const cancel = async (confirm: boolean) => {
    if (confirm && !formEmptyRef.current) { return showCancelConfirmation(); }
    if (window.history.state) { history.goBack(); }
    else { history.push('/contacts'); }
  };

  const handleChangeContactInsight = (data: IContactInsightData) => {
    setContactInsightForm(data);
    handleContactInsightSave(data);
  }

  const handleAddRelation = () => setRelationships((prev: any) => ([...prev, { ...emptyRelationForm }]))
  const handleRemoveRelation =async (index:number, rid?:string) => {
    if(!rid?.trim()) { return setRelationships(prev => prev.filter((_, i) => i !== index)); }

    await  ActionDeleteRelationship.fetch({
        contact_id: id!,
        id: rid,
    });

    // deleteRelation.response&&getContactDetails.fetch({contact_id:parseInt(id), renew: '1'})
    setRelationships(prev => {
      const id = parseInt(rid);
      return prev.filter(x => x.id !== id);
    });
  }

  function handleChangeRelationField<K extends keyof IRelationItem>(index: number, name:K, value:IRelationItem[K])
  {
    const item = relationships[index];
    //const itemValue = { value, error:'' };
    if(item) { item[name] = value; } //{ ...item[name as 'name' | 'type'], ...itemValue }; }

    setRelationships(current =>
      current?.map((obj,key) => {
        if (key !== index) { return obj; }
        //return { ...obj, [name]: itemValue };
        return { ...obj, [name]: value };
      }),
    );
  }

  const handleAddContact = (name: string) => setContactData((prev: any) => ({ ...prev, [name]: [...prev[name], { value: "", error: "", preferred: false, importEmail: false,phone_type:"",link_type:""}] }))

  const handleRemoveContact = async (name: string, index: number) => {

    // Getting item
    const bucket = contactData[name as keyof typeof contactData];
    const item = bucket[index];
    if(!item) { return; }

    // Required fields validation
    if(name === 'emails' || name === 'phones') {
      if(!validateRequiredFields()) { return; }
    }
    
    // Removing item
    let removePromise:Promise<any> | undefined = undefined;
    const removed = bucket[index]!;
    const isPreferred = 'preferred' in removed? removed.preferred: undefined;
    if (name === "emails")
    {
      // if((item as IEmailDataApp).preferred) {
      //   item.error = 'You can’t delete preferred email, you can switch another email to Preferred and then delete this one';
      // }
      if(item.id !== undefined){
          //try{

          removePromise = fetchDeleteEmailService({ contact_id: id!, id: item.id.toString()??"" });
              //contactData?.emails.splice(index, 1)
            //setContactData((prev: any) => ({ ...prev, emails:bucket }));
          //}catch(e){}
      } //else{
        //contactData?.emails.splice(index, 1)
      setContactData((prev) => {
        prev.emails.splice(index, 1);
        return { ...prev };
      });
    }
    //}
    if (name === "phones"){
      if(item?.id) { removePromise = fetchDeletePhoneService({ contact_id:id, id:item.id.toString() }); }
      //contactData?.phones.splice(index, 1)
      setContactData((prev) => {
        prev.phones.splice(index, 1);
        return { ...prev };
      });
    }
    if (name === "links") {
      if(item?.id) { removePromise = fetchDeleteLinkService({contact_id:id,link_id:item.id.toString() }); }
      //contactData?.links?.splice(index, 1)
      setContactData((prev) => {
        prev.links.splice(index, 1)
        return { ...prev };
      });
    }

    // Reverting in case it fails
    if(isPreferred)
    {
      const key = name.slice(0, -1) as 'email' | 'phone';

      try
      {
        await removePromise;
        setRequiredFields(fields => ({ ...fields, [key]:{ id:undefined, value:'' } }));
        props.onSummaryChange?.();
      }
      catch(e)
      {
        setContactData((prev) => {
          const prevKey = name as 'emails' | 'phones';
          removed.id = lastRequiredFields?.[key]?.id ?? undefined;
          removed.value = lastRequiredFields?.[key]?.value ?? '';
          if(prev[prevKey][index] !== removed) { prev[prevKey].splice(index, 0, removed as any); }
          
          return { ...prev };
        })
      }
    }
  };

  const handleChangeContactField = (index: number, name: string, value: string, preferred?: boolean, importEmail?: boolean,phone_type?:string,phone_extension?:number|string,link_type?:string) => {
    
    if (name === "emails") {
      const data = contactData.emails[index];
      if(!data) { return; }

      const id = data.id;
      if (preferred !== data.preferred) {
        contactData.emails.forEach((item) => item.preferred = false);
        setContactData((prev: any) => ({ ...prev, emails: contactData.emails.map((item) => ({ ...item, preferred: false })) }))
      }
      contactData.emails[index] = {id, value: value, error: data.error, preferred: preferred || false, importEmail: importEmail || false }
      setContactData((prev: IContactDataApp) => {
        prev.emails[index] = contactData.emails[index]!;
        return { ...prev };
      });

      if(data.id) { updateEmailTimer.clear(index); }
      else { addEmailTimer.clear(index); }
    }
    if (name === "phones") {
      const data = contactData.phones[index];
      if(!data) { return; }

      const id = data.id;
      if (preferred !== contactData.phones[index]?.preferred) {
        setContactData((prev: any) => ({ ...prev, phones: contactData.phones.map((item) => ({ ...item, preferred: false })) }))
      }

      if(phone_extension !== undefined && !isNumber(phone_extension)) { phone_extension = ifElse(!!phone_extension.trim(), parseInt(phone_extension.trim().slice(0,6))) ?? undefined; }if(phone_extension !== undefined && !isNumber(phone_extension)) { phone_extension = parseInt(phone_extension);}
      contactData.phones[index] = {id, value: value, error: data.error, preferred: preferred || false,phone_type:phone_type??"",phone_extension}
      setContactData((prev: any) => ({ ...prev, phones: contactData.phones }));

      if(data.id) { updatePhoneTimer.clear(index); }
      else { addPhoneTimer.clear(index) }
    }

    if (name === "links") {
      const data = contactData.links[index];
      if(!data) { return; }
      const id = data.id;
      contactData.links[index] = {id, value: value, error: "", link_type:link_type??""}
      setContactData((prev: any) => ({ ...prev, links: contactData.links }));
      console.log("link1",data)

      if(data.id) { updateLinkTimer.clear(index); }
      else { addLinkTimer.clear(index) }
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.primary_email) {
        setContactData({
          ...contactData,
          emails: [{ 'value': location.state.primary_email, 'importEmail': false, 'preferred': true, 'error': '' }]
        })

      }
      if (location.state.pipeline_status) {
        setContactInsightForm({ ...contactInsight, pipelineType: location.state.pipeline_status.toString() })
      }
    }
  }, [location]);

  return (
    <div className='page_gray_bg'>
      <BasicInformationSection isEdit={true} validate={validation.signal} onItemsChange={setBasicInfo} data={basicInfo} handleSave={handleBasicInfoSave}/>
      <ContactInsightSection isEdit={true} initialValues={contactInsight} onChange={handleChangeContactInsight}/>
      <AddressesSectionApp onItemsChange={setAddresses} validate={validation.signal} initialItems={addresses} handleSave={handleAddressSave}/>
      <RelationshipSection contactId={id? parseInt(id): undefined} setRelationship={setRelationships} validate={validation.signal} handleAddNew={handleAddRelation} handleRemoveRelation={handleRemoveRelation} handleChangeField={handleChangeRelationField} items={relationships} handleSave={handleRelationshipSave} />
          <ContactSection setContact={setContactData} validate={validation.signal} handleAddNew={handleAddContact} handleRemoveContact={handleRemoveContact} handleChangeField={handleChangeContactField} handleSave={handleContactSave} items={contactData} lastRequiredFields={{ email:lastRequiredFields?.email, phone:lastRequiredFields?.phone }} />
          <div className="pb-5 ml-4">
                        <OrdinaryButton className="btn btn--simple btn--red btn--small" clickConfirmation='Do you want to delete contact?' aria-label="Search" onClick={removeContact} >Delete this contact</OrdinaryButton>
                    </div>
	
                    <div className='h-50px'></div>
      {updateBasicInfo.response && <SimpleDialog message='The contact has been successfully updated.' autoClose={true} onClose={redirectAfterSuccess} />}
    </div>
  );
};
