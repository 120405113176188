import { IonButton, IonButtons,IonHeader, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonTitle, IonToolbar } from '@ionic/react';
import { useRef, useState } from 'react';
import { useMultiRef } from 'scripts/hooks/general';
import { IAddressesTypeaheadProps } from './AddressesTypeahead.def';
import './AddressesTypeahead.scss';
import { parseComponents ,IFeature} from 'types/vendors/mapbox';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';

export const AddressesTypeaheadApp = (props: IAddressesTypeaheadProps): JSX.Element => {
    return (
        <>
            <Form {...props} />
        </>
    );
}


const Form = (props: IAddressesTypeaheadProps): JSX.Element => {
    const { initialValue, change ,modalRef} = props;

    const [Address,setAddresses]=useState<Array<IFeature>>()
    const [selectedAddress,setSelectedAddress]=useState<IFeature>()
    //const searchField = useRef<HTMLIonSearchbarElement>(null)
    const [ref, assignRef] = useMultiRef(modalRef);

    const searchByMapbox = (async (value: string) => {

        const mapboxSearchApiEndpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?bbox=-106.64840698242188%2C25.822144306879686%2C-93.49365234375%2C36.500529337632265&access_token=`+process.env.REACT_APP_AS_MAPBOX_TOKEN;
        try {
          let response = await fetch(mapboxSearchApiEndpoint);
          let data = await response.json();
          setAddresses(data?.features)
        } catch (error) {
          console.log(error);
        }
      })
    const [showModal,setShowModal]=useState(false)
    const addressModal = useRef<HTMLIonModalElement>(null);
    const [timer, setTimer] = useState<number | undefined>()
    const search = (value: string) => {
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            searchByMapbox(value);
        }, 800))
    }

    const onSelect = () => {
        const v=selectedAddress
        const components = v? parseComponents(v): undefined;
        change?.({address1:`${v?.place_name}`,zipcode:components?.zipCode?.toString()??"",city:components?.city??"",state:components?.state??"",country:components?.country??""});
   
    }
    usePopUpAutoFocus(ref);
    return (
        <div  >
            <IonItem onClick={()=>setShowModal(true)} className="tab__nextpage " id="contact-modal">
                <div className="tab__nextpage_label ">
                {initialValue?.address1?"":"Address 1"}
                </div>
                <div className="tab__nextpage_value">
                {initialValue?initialValue.address1:"None"}
                </div>
                <img className="tab__nextpage_arrow" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
            </IonItem>
          {initialValue?.address1==''?<div  className='mt-2 ml-3 py-1 px-2 color_sold border_radius--default font_size--small'>{props.error}</div>:null}

            <IonModal isOpen={showModal}  ref={assignRef} backdropDismiss={false}>
               
                    <div>
                        <IonHeader>
                        <IonToolbar>
                            <IonButtons className="w-15pr" slot="start">
                                <IonButton color={"blue"} onClick={() => {addressModal?.current?.dismiss();setShowModal(false)}}>Cancel</IonButton>
                            </IonButtons>
                            <IonTitle>Add Address</IonTitle>
                            <IonButtons className="w-15pr" slot="end">
                                <IonButton  color={"blue"} onClick={() => {onSelect();addressModal?.current?.dismiss();setShowModal(false)}}>Ok</IonButton>
                            </IonButtons>
                        </IonToolbar>
                       </IonHeader>
                    
                    </div>
                    <div className="pt-2 pl-2 pb-2 ion-padding-end border-bottom">
                        <input autoFocus={true} style={{ width:400 }} onChange={(e: any) => search(e.target.value)} type="text" className="custom_search" placeholder="Search Address Here" ></input>

                          {/**  <IonSearchbar className="custom ion-no-padding" placeholder="Search Address Here" onIonInput={(e: any) => search(e.target.value)}></IonSearchbar>*/}
                        </div>
                    <div className="">
                        <IonRadioGroup >
                            {Address?.map((item,ind) =>
                                <IonItem key={ind} onClick={() => setSelectedAddress(item)}>
                                    <IonLabel className="font_size--medium">
                                        <div className="agent_signature agent_signature__large">
                                            <div className="agent_signature__text">
                                                <div className="agent_signature__text__title">{item?.place_name}</div>
                                            </div>
                                        </div>
                                    </IonLabel>
                                    <IonRadio slot="end"></IonRadio>
                                </IonItem>
                            )}
                        </IonRadioGroup>
                    </div>
                
            </IonModal>
        </div >

    );

}

/** TODO: Use feature and context to do a safer parse of the components  */
{/**const parseAddressComponents=(value:{place_name:string}): IAddressTypeaheadItem => {
    const address=value?.place_name?.replace(/\s{2,}/g, ' ') ?? ''
   const address1=address?.split(",")?.[0] ?? '';
    const zipcode=parseInt(address?.split(",")?.map(s => s.trim().match(/(\d{5}([ -]\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[ -]\d{1}[A-Z]{1}\d{1}$)/))?.filter(e => e)?.[0]?.[0]??"0");
    const city=address?.split(",")?.[2]??"";

    return { address1, city, zipcode }
}*/}

{/**const LoadingState = () => {
    const LoadingField = (): JSX.Element => {
        return (
            <div className="cards cards__deals_wt cursor--hand ui-sortable-handle d-block" >
                <div className="listing_signature listing_signature__small mb-3">
                    <div className="loading__photo mr-3"></div>
                    <div className="listing_signature__details">
                        <div className="loading__text_small mb-2"></div>
                        <div className="loading__text_small"></div>
                    </div>
                </div>

            </div >
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < 8; i++) {
        rows[i] = i
    }
    return (
        <IonItem className='py-3'>
            <div className="typeahead">
                {rows.map((index: number) =>
                    <LoadingField key={index} />
                )}
            </div>
        </IonItem>

    );
}*/}