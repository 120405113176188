import { useLocation, useParams } from 'react-router';
import { IContactServicesScreenProps } from './ContactServicesScreen.def';
import './ContactServicesScreen.scss';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';

export const ContactServicesScreen = (_props: IContactServicesScreenProps): JSX.Element =>
{
    const { id, path = '' } = useParams<{ id:string, path:string }>();
    const location = useLocation();

    return (
        <ContactDetailsTemplate forceFullHeight selectedMenu='Services'>

            <ExternalEmbedContent
                url={`/mopx_services/${id}`}
                initialPath={path}
                queryString={location.search}
                source={eExternalEmbedContentSource.Website}
                iframeTitle='Services'
                enabledInnerRoutes
            />

        </ContactDetailsTemplate>
    );
}
