import { IAssignTagsProps } from './AssignTags.def';
import './AssignTags.scss';
import { TagsTextbox } from 'views/forms/textboxes/TagsTextbox';
import { ITag } from 'views/forms/textboxes/TagsTextbox/TagsTextbox.def';
import {  OverlayTrigger  } from 'react-bootstrap';
import { popover_tags } from 'views/contacts/ContactInsightSection/components/InfoOverlayTrigger';

export const AssignTagsDesktop = (props: IAssignTagsProps): JSX.Element => {
    const { contact,setTags,tags } = props
    
    function selectTags(tags: ITag[]) {
        setTags(tags);
    }

    return (
        <>
           <div className="px-5 py-2">
                        <div>                  
                            <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Tags  <OverlayTrigger trigger="hover" placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                            <TagsTextbox initialTags={tags||contact.tags} onTagsChange={selectTags} maxlength={15}  />
                        </div>
                        </div>
           
        </>
    );
}
