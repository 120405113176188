import { ITextAreaProps } from './TextArea.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { TextAreaApp } from './TextArea.app';
import { TextAreaDesktop } from './TextArea.desktop';

export const TextArea = (props: ITextAreaProps): JSX.Element =>
{
    const TextAreaPlatform = useResponsiveChoose(TextAreaDesktop, TextAreaApp);
    return (
        <TextAreaPlatform {...props} />
    );
}
