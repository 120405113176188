import {  IPhotoUploadPopupViewProps } from "./PhotoUploadPopup.def";
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { PrimaryButton, ShapelessButton } from "views/forms/buttons/Button";
import { DefaultPopUpApp } from "views/popups/DefaultPopUp/DefaultPopUp.app";
import { IonContent } from "@ionic/react";
import { Avatar } from "../Avatar";


export const PhotoUploadPopupApp = (props:IPhotoUploadPopupViewProps): JSX.Element =>
{
  
    return (<DefaultPopUpApp hideHeader initialBreakpoint={0.60} breakpoints={[0, 0.60, 0.7, 0.85]} isOpen={props.show} toggleShow={()=>props.toggleShow(false)} onDidDismiss={()=>props.toggleShow(false)} closeButton>
                <DefaultPopUp.Body>
                <IonContent className="ion-padding">
                    <h2 tabIndex={0}>Profile Photo</h2>
                   
                    <Avatar className="agent_signature__photo ml-auto mr-auto mb-4 big_fonts w-190px h-190px"  id={props.id.toString()} firstName={props.firstName} lastName={props.lastName} photo={props.imageUrl}/>
                    <div className="text-center mt-1">Ensure photo is a PNG or JPG file, not exceeding 5MB, and originally stored at 320 x 320 pixels<br/>
                     (displayed at 110 x 100 pixels).</div>
                   {/**  <div className="agent_signature__photo ml-auto mr-auto mb-4" */}                                      
                   <div className="pb-2 pt-3">                                                    
                            <PrimaryButton  onClick={props.onOpen} className="btn--large w-100">Change Photo</PrimaryButton>
                        </div>                    
                        {props.imageUrl&&<div className="pb-2">
                            <ShapelessButton clickConfirmation="Are you sure you want to delete photo" onClick={props.onDelete} className="btn--medium w-100"><span className="font_weight--bold">Delete Photo</span></ShapelessButton>
                        </div>}
                </IonContent>
                </DefaultPopUp.Body>
                </DefaultPopUpApp>);
    }