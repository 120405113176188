/**
 * Lazy Decorator for Lazy Loading getter properties
 */
export function lazy()
{
    /** Returning function to handle lazy properties */
    return function(_target: any, propertyKey: string, descriptor: PropertyDescriptor)
    {
        /** Checking get method */
        const { get:getter } = descriptor;
        if(!getter) { throw new Error('Lazy decorator may only be applied for getters.'); }

        /** Replacing get method with lazy call */
        //const lazy = new Lazy(() => getter.call(target));
        descriptor.get = function()
        {
            const value = getter.call(this);
            Object.defineProperty(this, propertyKey, { value, writable:false });
            return value;
            //return lazy.value;
        };
    };
}

/**
 * Lock Decorator getter properties
 * If a second request is done while first is still in progress,
 * it will wait the first process to finish and return its value
 */
export function lockProperty()
{
    /** Returning function to handle lock properties */
    return function<T, P extends Promise<T>>(_target: any, _propertyKey: string, descriptor:TypedPropertyDescriptor<P>)
    {
        if(descriptor.get) { descriptor.get = lockMember(descriptor.get); }
        else { throw new Error('LockProperty decorator may only be applied for getters.'); }
    };
}

/**
 * Lock Decorator getter properties
 * If a second request is done while first is still in progress,
 * it will wait the first process to finish and return its value
 */
export function lock()
{
    /** Returning function to handle lock properties */
    return function<T, P extends Promise<T>>(_target: any, _propertyKey: string, descriptor:TypedPropertyDescriptor<(...args:any[]) => P>)
    {
        if(descriptor.value) { descriptor.value = lockMember(descriptor.value) }
        else { throw new Error('Lock decorator may only be applied for methods.'); }
    };
}

function lockMember<T, P extends Promise<T>>(executor:(this:any, ...args:any[]) => P)
{
        /** Storing current process */
    let process:P | undefined = undefined;

        /** Replacing get method with lazy call */
    return function(this:any, ...args:any[])
    {
        if(process) { return process; }
        process = executor.call(this, ...args);
        process.finally(() => process = undefined);
        return process;
    };
}