import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace CreateTagApi
{
    export const url = "/tags";
    export const method = "POST";
    export const defaultError = "Failed to save tag.";
    export const operations = apiOperations(CreateTagApi);
    export const scope = new class CreateTagApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        name:string;
    }

    export interface Response
    {data:{
        id:string,
        name:string}
    }
}

export const fetchCreateTagService = forwardOperation(CreateTagApi.operations, 'fetch');
export const useFetchCreateTagService = forwardOperation(CreateTagApi.operations, 'useFetch');
export const useCreateTagService = forwardOperation(CreateTagApi.operations, 'useService');