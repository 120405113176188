import { INotificationProps } from './Notification.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { NotificationApp } from './Notification.app';
import { NotificationDesktop } from './Notification.desktop';

export const Notification = (props: INotificationProps): JSX.Element =>
{
    const NotificationPlatform = useResponsiveChoose(NotificationDesktop, NotificationApp);
    return (
        <NotificationPlatform {...props} />
    );
}
