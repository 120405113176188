import { useState } from 'react';
import { IAssignStageProps } from './AssignStage.def';
import './AssignStage.scss';
import { useQuickUpdateService } from 'services/contacts/quickUpdate';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { SelectedContacts } from '../SelectedContacts';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { stagesOptions } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';

export const AssignStageDesktop = (props: IAssignStageProps): JSX.Element => {
    const { contacts, selectToggle, onAssignment } = props
    const [open, doOpen] = useState(false)
    const [stage, setStage] = useState<string>()
    const [stageAssignService, stageAssignAction] = useQuickUpdateService()
    const Launcher = () => {
        return (
            <button role="button" id="contact-assign-stage" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" aria-label="Assign Score" onClick={() => doOpen(true)}>Stages</button >
        )
    }
    const doAssign = () => {
        let ids: any = [];
        contacts?.map((c: any) => {
            ids.push(c.id)
        })
        if (stage) {
            stageAssignAction.fetch({
                contact_ids: ids,
                field_name: "pipeline_status",
                field_value: stage
            })
        }
    }

    useServiceDidSucceeded(stageAssignService, () => {
        doOpen(false)
        onAssignment?.()

    })
    return (
        <>
            <Launcher />
            <DefaultPopUp title="Stages" isOpen={open} onWillDismiss={() => doOpen(false)} onIonModalWillDismiss={() => setStage(undefined)}>
                <DefaultPopUp.Body>
                    <div className=" px-5 py-2">
                        <SelectedContacts contacts={contacts} selectToggle={selectToggle} />
                    </div>
                    <div className="px-5 py-2">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Stages</div>
                        <div className="row">
                            {stagesOptions.map((item, index) => {
                                const checked = item.short_value.toString() === stage;
                                return (
                                    <div className="col-6 pb-2" key={index}>
                                        <div role="radio" className="custom-control custom-radio">
                                            <input type="radio" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} className="custom-control-input" onChange={() => setStage(`${item.short_value}`)} />
                                            <label className='custom-control-label stage pt-1' htmlFor={`${item.short_value}`}><div className={`stage ${item.class}`}>{item.long_full_value??item.long_value}</div></label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <div className='row no-gutters'>
                            <div className='col-auto min-w-120px'></div>
                            <div className='col text-center'><PrimaryButton loading={stageAssignService.loading} disabled={stageAssignService.loading} onClick={() => doAssign()} className="btn--primary btn w-100px">Apply</PrimaryButton></div>
                            <div className='col-auto min-w-120px text-right'><ShapelessButton onClick={() => doOpen(false)}>Cancel</ShapelessButton></div>
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>
        </>
    );
}
