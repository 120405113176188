import {  IPhotoUploadPopupViewProps } from "./PhotoUploadPopup.def";
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { PrimaryButton, ShapelessButton } from "views/forms/buttons/Button";
import { Avatar } from "../Avatar";
export const PhotoUploadPopupDesktop = (props:IPhotoUploadPopupViewProps): JSX.Element =>
{
  
    return (<DefaultPopUp title="Profile Photo" className="custom--modal custom--modal__blackoverlay custom--modal__smallheight" isOpen={props.show} toggleShow={()=>props.toggleShow(false)} onDidDismiss={()=>props.toggleShow(false)} closeButton>
    <DefaultPopUp.Body>
        <div className="text-center h-60 d-flex justify-content-center pt-2 pb-2">
            <div className="align-self-center">
             <Avatar className="agent_signature__photo ml-auto mr-auto big_fonts h-190px w-190px" id={props.id.toString()} firstName={props.firstName} lastName={props.lastName} photo={props.imageUrl}/>
              {/**  <div className="agent_signature__photo ml-auto mr-auto"></div> */}                                       
            </div>
        </div>
        <div className="ml-auto mr-auto color_auxiliary pb-3 text-center mt-1 max-w-390">Ensure photo is a PNG or JPG file, not exceeding 5MB, and originally stored at 320 x 320 pixels
         (displayed at 110 x 100 pixels).</div>
    </DefaultPopUp.Body>
    <DefaultPopUp.ActionBar>
        <div className="ml-4 mr-4">
            <div className="row pt-2 mb-2 justify-content-center">
                {props.imageUrl&&<div className="col-auto algin-self-center">
                    <ShapelessButton className="btn--medium" clickConfirmation="Are you sure you want to delete photo" onClick={props.onDelete}><span className="font_weight--bold">Delete Photo</span></ShapelessButton>
                </div>}
                <div className="col-auto">                                                    
                    <PrimaryButton className="btn--medium" onClick={props.onOpen}>Change Photo</PrimaryButton>
                </div>

            </div>
        </div>
    </DefaultPopUp.ActionBar>
    </DefaultPopUp>);
}
