import { usePostCSVService } from "services/contacts/csvmapping/postCSV"
import { IImportCsvMappedScreenProps } from "../ImportCsvMappedScreen.def"
import { IonSpinner, IonText } from "@ionic/react"
import { useEffect, } from "react"
import { isProduction } from "scripts/application/settings"

export const ImportingCsv = (props: IImportCsvMappedScreenProps) => {
    const { import_id } = props
    const [importSer, doImport] = usePostCSVService()





    const startImport = (id: string) => {
        doImport.fetch({ import_id: id, queue: !isProduction ? '1' : '0' })
    }

    const LoadingState = () => {
        return (
            <div className='pt-3 pb-3' >
                <div className='mx-auto d-flex w-290px min-h-260px'>
                    <IonSpinner className='col-auto' name="bubbles"></IonSpinner>
                    <IonText className='col pt-1'>Please wait while we are importing your file.</IonText>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (import_id) {
            startImport(import_id)
        }
    }, [])

    return (
        <div className="pt-5" style={{ maxWidth: '374px' }}>
            {importSer.loading && <LoadingState />}
            {!importSer.loading && importSer.response && importSer.response.status === 'ok' &&
                <div className="font_size--medium alert-success p-4 d-flex">
                    <img className="mr-2" src="https://content.harstatic.com/media/icons/checkmark_green.svg" />
                    <div className="pl-2">
                        We have initiated the importing process. We will notify you once it is completed.
                    </div>
                </div>
            }

            {!importSer.loading && importSer.response && importSer.response.status !== 'ok' &&
                <div className="font_size--medium alert-danger p-4 d-flex">
                    <div>
                        Something went wrong during the importing process. Please try again later.
                    </div>
                </div>
            }
        </div>

    );
}
