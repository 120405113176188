import { parseISO, format } from 'date-fns'
export function currencyFormat(amount: string) {
    var options = { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    var formatter = new Intl.NumberFormat('en-US', options);
    if (formatter.format(Number(amount))) {
        return formatter.format(Number(amount))
    } else {
        return amount
    }
}


export function dateFormat(value: string) {
    return format(parseISO(value), 'yyyy-MM-dd')
}



export const getMonthName = (num: number): string => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let name = num < 12 ? months[num] : "Jan"
    return name ? name : " "
}

export const strToDate = (date: string): Date => {
    let dateArray: any = date.split(/[- :]/)
    return new Date(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3], dateArray[4], dateArray[5]);
}

