import { Textbox } from 'views/forms/textboxes/Textbox';
import { IBasicInformationSectionProps, genderType, IBasicInfoData, IBasicInfoDataLocation } from './BasicInformationSection.def';
import './BasicInformationSection.scss';
import { useEffect, useState } from 'react';
import { FormLabel, OverlayTrigger } from 'react-bootstrap';
import { DropDown } from 'views/forms/dropdowns/DropDown';
import { DropDownModal } from 'views/forms/dropdowns/DropDownModalMulti';

//import { mapValues } from 'lodash-es';
import { IImageObj } from '../PhotoUploadSection/PhotoUploadSection.def';
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useLocation, useParams } from 'react-router';
import { useFetchLookupsService } from 'services/general/lookups';
import { IDateTimeTextboxProps } from 'views/forms/textboxes/DateTimeTextbox/DateTimeTextbox.def';
import { PhotoUploadSection } from '../PhotoUploadSection';
import { MuiDatePicker } from 'views/forms/material/MuiDatePicker';
import { formatSystemDateShort,formatSystemDate } from 'scripts/helpers/dates';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { joinWithSpace } from 'scripts/helpers/texts';
import { popover_reminder } from './components/InfoOverlayTrigger';
/*interface IFormField<T = any> {
    value: T;
    error: string;
}

type IForm =
    {
        [K in keyof IBasicInfoData]: IFormField<IBasicInfoData[K]>;
    }*/

export const BasicInformationSectionDesktop = (props: IBasicInformationSectionProps): JSX.Element => {

    const { data, onItemsChange, errorRef ,isEdit} = props;
    const [, notifyChange] = useValidateConsumer(props.validate, validate);
    const [formErrors, setFormErrors] = useState({} as Record<keyof IBasicInfoData, string>);
    const [contactId, getId] = useState(false);
    //const [informationCardDetails, basicInformationCard] = useState<IBasicInfoData>(data ?? {});
   // const [, setPrevInformation] = useState<IBasicInfoData>({})
    const { id } = useParams<{ id: string }>();
    const [lookupData] = useFetchLookupsService(['income','contact_suffix','contact_title','contact_reminder_days'])
    const location = useLocation<IBasicInfoDataLocation>()
    const search = location.search;
    const params = new URLSearchParams(search);
    const edit = params.get('edit');
    const informationCardDetails = data ?? {};
    const isError = errorRef?.error;
        useEffect(() => {
        if (id && (!(location?.state && location?.state?.editMode) && edit !== "1")) {
            getId(Object.keys(id!).length > 0 ? true : false)
        };
        /*if (data !== undefined) {
            console.log('initialData', data);
            setBasicInfoForm(toFormField());
            //basicInformationCard(data)
        }*/

    }, []);

    const onChangeHandler = (e: IonInputCustomEvent<InputChangeEventDetail>): void => {
        handleChange(e.target.name, e.detail.value, true);
    }

    const onDateTimeChangeHandler = (e: IDateTimeTextboxProps.ValueChangeArgs): void => {
        handleChange(e.name!, e.value, true);
    }
   
    const onDropDownChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        handleChange(e.target.name, e.currentTarget.value, true);
    }

    const onChangeImage = (image: IImageObj) => {
        handleChange('image', image.imageUrl);
    }

    function handleChange<K extends keyof IBasicInfoData>(key: K, value: IBasicInfoData[K]): void;
    function handleChange(key: string, value: unknown, skipCheck: true): void; // should only when key is not statically known
    function handleChange(key: string, value: unknown, _?: true) {
        // console.warn('test', key, value);
        //const item = { value, error: '' };
        //basicInformationCard({ ...informationCardDetails, [key]: value })
        const newForm = { ...data, [key]: value };
        setFormErrors({ ...formErrors, [key]:'' });
        onItemsChange?.(newForm);
        notifyChange();
    }
    const fieldCheck = (key: any, required: boolean = true, maxLength:number = 50): boolean => {
        let property = key
        const value = property?.value?.replaceAll(" ", "");
        if (!value || value === '') { return !required; }

        if (value.length < 1) {
            return false;
        }
        if (value.length > maxLength) {
            return false;
        }
        return true;
    }

    function validate() { return validateForm(false); }

    function validateForm(validateRequired:boolean) {
        const required = false;

        // HACK: This flag indicates form doesn't have required fields
        // Validating only required fields
        if (validateRequired) {
            const error = 'The field is required';
            const { firstName, lastName, company } = informationCardDetails;
            const hasValues = [firstName, lastName, company].some(x => !!x?.trim());
            if (!hasValues) {
                setFormErrors({ ...formErrors, firstName: error, lastName: error, company: error });
                return false;
            }
        }

        const errors = {
            ...formErrors,
            firstName: !fieldCheck(informationCardDetails.firstName, required) ? "Field should contain min 1 max 15 Latin letters." : "",
            middleName: !fieldCheck(informationCardDetails.middleName, false) ? "Field should contain min 1 max 15 Latin letters." : "",
            lastName: !fieldCheck(informationCardDetails.lastName, required) ? "Field should contain min 1 max 15 Latin letters." : "",
            company: !fieldCheck(informationCardDetails.company, required) ? "Field should contain min 1 max 15 Latin letters." : "",
            jobTitle: !fieldCheck(informationCardDetails.jobTitle, false, 45) ? "Field should contain min 1 max 45 Latin letters." : ""
        };

        setFormErrors(errors);

        const isValid = Object.entries(errors).every(([, value]) => !value);
        props.onValidationChange?.(isValid);
        return isValid;
    }

    /*function toFormField(): IForm {
        const newData = { ...data };
        return mapValues(newData, (value) => ({ value, error: '' })) as IForm;
    }*/

    /*function triggerChange(form: IForm) {
        const result = mapValues(form, (item) => item?.value) as IBasicInfoData;
        onItemsChange?.(result);
    }*/

    useEffect(() => { isError && validateForm(true) }, [errorRef]);

    const DatePickers = () => {
        return <><MuiDatePicker
            label={"Date of Birth"}
            className='mb-4'
            onChange={(date: any) => onDateTimeChangeHandler({ value: formatSystemDate(date), name: 'dateOfBirth' })}
            value={informationCardDetails?.dateOfBirth ? moment(informationCardDetails.dateOfBirth).toDate() : undefined}
            maxDate={parseISO(new Date().getFullYear()+'-12-31')}
            minDate={parseISO('1940-01-01')}
            changeOnlyOnValidDate={true}

        />
            <MuiDatePicker
                label={"Known Since"}
                className='mb-4'
                onChange={(date: any) => onDateTimeChangeHandler({ value: formatSystemDate(date), name: 'knownSince' })}
                value={informationCardDetails?.knownSince ? moment(informationCardDetails.knownSince).toDate() : undefined}
                maxDate={parseISO(new Date().getFullYear()+'-12-31')}
                minDate={parseISO('1940-01-01')}
                changeOnlyOnValidDate={true}
                
            />
            <MuiDatePicker
                label={"Home Anniversary"}
                className='mb-4'
                onChange={(date: any) => onDateTimeChangeHandler({ value: formatSystemDate(date), name: 'homeAniversary' })}
                value={informationCardDetails?.homeAniversary ? moment(informationCardDetails.homeAniversary).toDate() : undefined}
                maxDate={parseISO(new Date().getFullYear()+'-12-31')}
                minDate={parseISO('1940-01-01')}
                changeOnlyOnValidDate={true}

            />
            <MuiDatePicker
                label={"Wedding Anniversary"}
                className='mb-4'
                onChange={(date: any) => onDateTimeChangeHandler({ value: formatSystemDate(date), name: 'weddingAniversary' })}
                value={informationCardDetails?.weddingAniversary ? moment(informationCardDetails.weddingAniversary).toDate() : undefined}
                maxDate={parseISO(new Date().getFullYear()+'-12-31')}
                minDate={parseISO('1940-01-01')}
                changeOnlyOnValidDate={true}
            />  
             </>
    }

    const imageValue = informationCardDetails.image;
    const longValue = informationCardDetails.income !== '' ? lookupData.response?.income.items.filter((x => x.short_value === informationCardDetails.income)) : null

    return (
        <>{props.loading || lookupData?.loading ?
            <div className="card p-3 mb-5">
                <div className='p-3'>
                    <h3 tabIndex={0}>Basic Information</h3>


                    <FormLabel>First Name</FormLabel>
                    <div className="row small-gutters">
                        <div className="col-sm-4 col-12 mb-4">
                            <div className="loading__textbox"></div>
                        </div>
                        <div className="col-sm-4 col-12 mb-4">
                            <div className="loading__textbox"></div>
                        </div>
                        <div className="col-sm-4 col-12 mb-4">
                            <div className="loading__textbox"></div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <FormLabel>Gender</FormLabel>
                        <div className="loading__textbox"></div>
                    </div>

                    <FormLabel>Date of Birthday</FormLabel>
                    <div className="loading__textbox mb-4"></div>

                    <FormLabel>Known Since</FormLabel>
                    <div className="loading__textbox mb-4"></div>

                    <FormLabel>Home Anniversary</FormLabel>
                    <div className="loading__textbox mb-4"></div>

                    <FormLabel>Wedding Anniversary</FormLabel>
                    <div className="loading__textbox mb-4"></div>


                    <div className="mb-4 border-bottom pb-4">
                        <FormLabel>Income</FormLabel>
                        <div className="loading__textbox"></div>
                    </div>

                    <div className="mb-4">
                        <FormLabel>Job Title</FormLabel>
                        <div className="loading__textbox"></div>
                    </div>

                    <div className="mb-4">
                        <FormLabel>Company</FormLabel>
                        <div className="loading__textbox"></div>
                    </div>

                    <div className="mb-4">
                        <FormLabel>Reminder</FormLabel>
                        <div className="loading__textbox"></div>
                    </div>
                </div>
            </div>
            :
            <div className="mb-5 p-0 card">
                { !isEdit&&contactId && data?
                    <div className="show_over">
                        <>
                            <div className="p-3 form_section_border" >
                                <div className="p-3">
                                    <div className="row pb-3">
                                        <div className="col">
                                            <h3 tabIndex={0}>Basic Information</h3>
                                        </div>
                                      {/**   {contactId &&
                                            <div className="col-auto text-right">
                                                <button className="btn btn--white show_over_item animate animate__fadeIn" type="button" onClick={editInformationDetail} >Edit</button>
                                            </div>}*/}
                                    </div>
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Name</div>
                                        <div>{joinWithSpace(informationCardDetails.title !== 'N' && informationCardDetails.title, informationCardDetails.firstName, informationCardDetails.middleName, informationCardDetails.lastName, informationCardDetails.suffix !== 'N' && informationCardDetails.suffix)}</div>
                                    </div>

                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Gender</div>
                                        <div>{informationCardDetails.gender !== '' ? informationCardDetails.gender : 'None'}</div>
                                    </div>
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Date of birthday</div>
                                        <div>{informationCardDetails?.dateOfBirth ? formatSystemDateShort(parseISO(informationCardDetails?.dateOfBirth)) : 'N/A'}</div>
                                    </div>
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Known since</div>
                                        <div>{informationCardDetails?.knownSince ? formatSystemDateShort(parseISO(informationCardDetails?.knownSince)) : 'N/A'}</div>
                                    </div>
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Home Anniversary</div>
                                        <div>{informationCardDetails?.homeAniversary ? formatSystemDateShort(parseISO(informationCardDetails?.homeAniversary)) : 'N/A'}</div>
                                    </div>
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Wedding Anniversary</div>
                                        <div>{informationCardDetails?.weddingAniversary ? formatSystemDateShort(parseISO(informationCardDetails?.weddingAniversary)) : 'N/A'} </div>
                                    </div>
                                    <div className="font_size--medium">
                                        <div className="input_label__bold">Income</div>
                                        <div>{longValue !== null && longValue!.length > 0 ? longValue?.[0]!.long_value : 'None'}</div>
                                    </div>
                                </div>
                            </div >
                            <div className="p-3">
                                <div className="p-3">
                                    <div className="pb-3 mb-3 border-bottom font_size--medium">
                                        <div className="input_label__bold">Job title</div>
                                        <div>{informationCardDetails.jobTitle ?? 'N/A'} </div>
                                    </div>
                                    <div className="pb-3 mb-3 font_size--medium border-bottom">
                                        <div className="input_label__bold">Company</div>
                                        <div>{informationCardDetails.company ?? 'N/A'}</div>
                                    </div>
                                    <div className="font_size--medium">
                                        <div className="input_label__bold">Reminder <OverlayTrigger trigger="hover" placement="right" overlay={popover_reminder}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                                        <div>{informationCardDetails.reminder_days_after_text?? 'N/A'} </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div >
                    :
               
                    <div className='p-3'>
                        <div className="p-3">
                            <div className="row pb-3">
                                <div className="col">
                                    <h3 tabIndex={0}>Basic Information</h3>
                                </div>
                             {/**    {id &&
                                    <div className="col-auto text-right">
                                       <button className="btn btn--shapeless btn--small" type="button" onClick={discardInformation}>Discard</button>
                                        <button className="btn btn--primary btn--small" type="button" onClick={saveInformationDetail}>Save</button>
                                    </div>
                                }*/}
                            </div>

                            {!id && <div className="row items-left small-gutters mb-4">
                                <div className="col-auto">
                                    <div className="photophoto">
                                        <PhotoUploadSection imageUrl={imageValue} onItemsChange={onChangeImage} />
                                    </div>
                                </div>
                            </div>}
                            <div className="row small-gutters">
                                <div className="col-sm-4 col-12 mb-4">
                                    <Textbox value={informationCardDetails.firstName} error={formErrors?.firstName} placeholder='First Name' name="firstName" maxlength={50} className="form-control" id="firstName" aria-describedby="firstName" label='First Name' onIonChange={e => onChangeHandler(e)} ></Textbox>
                                </div>
                                <div className="col-sm-4 col-12 mb-4">
                                    <Textbox value={informationCardDetails.middleName} error={formErrors?.middleName} placeholder='Middle Name' name="middleName" maxlength={50} className="form-control" id="middleName" aria-describedby="middleName" label='Middle Name' onIonChange={(e) => onChangeHandler(e)} ></Textbox>
                                </div>
                                <div className="col-sm-4 col-12 mb-4">
                                    <Textbox value={informationCardDetails.lastName} error={formErrors?.lastName} placeholder='Last Name' name="lastName" maxlength={50} className="form-control" id="lastName" aria-describedby="lastName" label='Last Name' onIonChange={(e) => onChangeHandler(e)} ></Textbox>
                                </div>
                            </div>

                            <div className="row small-gutters">
                                <div className="col-sm-4 col-12 mb-4">
                                    <FormLabel>Title</FormLabel>
                                    <DropDown name='title' className=" custom-select_large" value={informationCardDetails.title} onChange={(e) => onDropDownChangeHandler(e)}>
                                    {lookupData?.response?.contact_title?.items?.map((item, index) =>
                                        <option key={index} value={item.short_value}>{item.long_value}</option>)
                                    }
                                    </DropDown>
                                </div>
                                <div className="col-sm-4 col-12 mb-4">
                                    <FormLabel>Suffix</FormLabel>
                                    <DropDown name='suffix' className=" custom-select_large" value={informationCardDetails.suffix} onChange={(e) => onDropDownChangeHandler(e)}>
                                    {lookupData?.response?.contact_suffix?.items?.map((item, index) =>
                                        <option key={index} value={item.short_value}>{item.long_value}</option>)
                                    }
                                    </DropDown>
                                </div>
                                
                            </div>

                            <div className="mb-4">
                                <FormLabel>Gender</FormLabel>
                                <DropDown id="gender" name='gender' value={informationCardDetails.gender} className=" custom-select_large" onChange={(e) => onDropDownChangeHandler(e)}>
                                    {Object.keys(genderType).map(key => (
                                        key.length > 1 ? <option key={key} value={key}>{key}</option> : ''
                                    ))}
                                </DropDown>
                            </div>
                           
                              {/**onValueChange={onDateTimeChangeHandler} type="date" class="mb-4" label={category==="e"?"Start Date":"Date"} placeholder="" error={formData.start_dt.error} value={formatSystemDate(moment(formData.start_dt.value))} maxValue={getMaxTime} id="Date" name="start_dt" disabled={false} */} 
                            <DatePickers />
                             
                            <div className="mb-4 border-bottom pb-4">
                                <FormLabel>Income</FormLabel>
                                <DropDown id="income" name='income' value={informationCardDetails.income} className="custom-select custom-select_large" onChange={onDropDownChangeHandler}>
                                    {lookupData?.response?.income?.items.map((item, index) => <option key={index} value={item.short_value}>{item.long_value}</option>)}
                                </DropDown>
                            </div>
                            <div className="mb-4">
                                <Textbox value={informationCardDetails.jobTitle} error={formErrors?.jobTitle} placeholder='Job Title' name="jobTitle" maxlength={45} className="form-control" id="jobTitle" aria-describedby="jobTitle" label='Job Title' onIonChange={onChangeHandler} ></Textbox>
                            </div>
                            <div className="mb-4">
                                <Textbox value={informationCardDetails.company} error={formErrors?.company} placeholder='Company' name="company" maxlength={45} className="form-control" id="company" aria-describedby="company" label='Company' onIonChange={onChangeHandler} ></Textbox>
                            </div>
                            <div className="mb-4 pb-4">
                                <FormLabel>Reminder <OverlayTrigger trigger="hover" placement="right" overlay={popover_reminder}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></FormLabel>
                                <DropDownModal placeHolder="Reminder" label='Reminder'  customClassName='custom-select custom-select_large'   values={informationCardDetails.reminder_days_after??[]} handleSave={(e)=> handleChange('reminder_days_after',e)} optionsValues={lookupData?.response?.contact_reminder_days?.items}></DropDownModal>
                            </div>
                        </div>
                    </div>
                }
            </div >
        }
        </>

    );
}