import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IQuickEditPopupProps } from './QuickEditPopup.def';
import './QuickEditPopup.scss';
import { Tabs,Tab } from 'react-bootstrap';
import { AssignStage } from './component/AssignStage';
import { AssignSource } from './component/AssignSource';
import { AssignScore } from './component/AssignScore';
import { AssignTags } from './component/AssignTags';
import { allEmpty,isEmpty, joinWithSpace } from 'scripts/helpers/texts';
import {  transformPhoneNumber } from 'scripts/helpers/texts';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useEffect, useState } from 'react';
import { useQuickUpdateService } from 'services/contacts/quickUpdate';
import { useAddTagsToContactsService } from 'services/contacts/tags/addTagsToContact';


export const QuickEditPopupDesktop = (props: IQuickEditPopupProps): JSX.Element => {
    const { contact, onAssignment,open, close,selectedTab } = props;
    const [stage, setStage] = useState<string>()
    const [stageAssignService, stageAssignAction] = useQuickUpdateService()
    const [score, setScore] = useState<string>()
    const [scoreAssignService, scoreAssignAction] = useQuickUpdateService()
    const [source, setSource] = useState<string>()
    const [sourceAssignService, sourceAssignAction] = useQuickUpdateService()
    const [tags, setTags] = useState(contact?.tags??[]);
    const [tagsAssignService, tagsAssignAction] = useAddTagsToContactsService();

    useEffect(()=>{setTags(contact.tags??[]);
        setStage(contact.pipeline_status);
        setSource(contact.source);
        setScore(contact.score)},[contact])
  
    const doAssign = () => {
     
        if (stage&&stage!==contact.pipeline_status) {
            stageAssignAction.fetch({
                contact_ids: [contact.id],
                field_name: "pipeline_status",
                field_value: stage
            })
        }
        if (score&&score!==contact.score) {
            scoreAssignAction.fetch({
                contact_ids: [contact.id],
                field_name: "score",
                field_value: score
            })
        }
        if (source&&source!==contact.source) {
            sourceAssignAction.fetch({
                contact_ids: [contact.id],
                field_name: "sources",
                field_value: source
            })
        }
        if (tags&&tags!==contact.tags) {
            tagsAssignAction.fetch({
               contact_id: contact.id,
               tags: tags.map((item:any)=>({id:item?.id||undefined,name:item.name}))
            }).then(()=>{ onAssignment?.()})
        }
        close()
        onAssignment?.()
    }

  
    const getName=()=>{
        if(allEmpty(contact.first_name,contact.last_name)){
           return contact.company
        }
        return joinWithSpace(contact.first_name,contact.last_name);
    }
    return (
            <DefaultPopUp title="Quick Edit" isOpen={open} onWillDismiss={() => close()} /*onIonModalWillDismiss={() => setScore(undefined)}*/>
                <DefaultPopUp.Body>
                <div className="text-center pt-3 font_size--medium border-bottom pb-3 mb-4"><span className="font_weight--bold">{getName()}</span> {(!!contact.primary_email||!!contact.primary_phone)&&<span className="color_auxiliary">({isEmpty(contact.primary_email)?transformPhoneNumber(contact.primary_phone_country_code, contact.primary_phone, contact.primary_phone_extension):contact.primary_email})</span>}</div>
                    <div>
                        <Tabs defaultActiveKey={selectedTab} transition={false} id="ContactTabs" className="nav nav-tabs px-5 pt-4 nav-tabs--simple">
                            <Tab eventKey="Score" title="Score" className="mt-4"> <AssignScore  contact={contact} score={score} setScore={setScore}   /> </Tab>
                            <Tab eventKey="Stages" title="Stages" className="mt-4 px-5 pt-2"> <AssignStage  stage={stage} setStage={setStage}  /> </Tab>
                            <Tab eventKey="Tags" title="Tags" className="mt-4">  <AssignTags  contact={contact} tags={tags} setTags={setTags} /></Tab>
                            <Tab eventKey="Source" title="Source" className="mt-4">  <AssignSource contact={contact}  source={source} setSource={setSource}  /></Tab>

                        </Tabs>
                    </div>
                </DefaultPopUp.Body>
       <DefaultPopUp.ActionBar>
            <div className="p-2">
                <div className='row no-gutters'>
                    <div className='col-auto min-w-120px'></div>
                    <div className='col text-center'><PrimaryButton loading={stageAssignService.loading||scoreAssignService.loading||tagsAssignService.loading||sourceAssignService.loading} disabled={stageAssignService.loading||scoreAssignService.loading||tagsAssignService.loading||sourceAssignService.loading}  onClick={() => doAssign()} className="btn--primary btn w-100px">Apply</PrimaryButton></div>
                    <div className='col-auto min-w-120px text-right'>
                        <ShapelessButton onClick={() => close()} >Cancel</ShapelessButton>
                    </div>
                </div>
            </div>
        </DefaultPopUp.ActionBar>        
            </DefaultPopUp>
    );
}
