import { IRelationItem, IRelationItemErrors, IRelationshipSectionAppProps } from './RelationshipSection.def';
import './RelationshipSection.scss';
import { IonItem, IonSelect, IonSelectOption, IonText, useIonAlert} from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useFetchLookupsService } from 'services/general/lookups';
import { useState } from 'react';
import { extractPhone, validateAll } from './RelationshipSection.common';
import { ContactsTypeahead } from 'views/forms/typeaheads/ContactsTypeahead';
import { SearchContactsApi } from 'services/contacts/searchContacts';
import { IContactResult } from 'views/contacts/popups/SimpleContactFormPopUp/SimpleContactFormPopUp.def';
import { Avatar } from 'views/contacts/Avatar';
import { ContactTitle } from 'views/general/ContactTitle';

export const RelationshipSection = (props:IRelationshipSectionAppProps): JSX.Element => {
    const { items,handleAddNew} = props;
    const [errors, setErrors] = useState<IRelationItemErrors[]>([]);
    const [, _notifyChange] = useValidateConsumer(props.validate, validate);
    const [lookupData] = useFetchLookupsService('relationship');

      /*const fieldCheck = (key: any, required: boolean = true): boolean => {
        let property = key;
        const value = property?.value?.replaceAll("  ","")
        if (!value || value === '') {
          return !required;
        }

        if (value.length < 3) {
          return false;
        }
        return true;
  };
  function validate() {
    if(items.length===0){return true;}
    const fieldEmptyMsg='The field should contain max 15 latin letters, can’t be empty or with space only'

    const errors = items.map(item => !fieldCheck(item.name) ? fieldEmptyMsg : '');
    setRelationship(prev => {
      const form:Array<IRelationItemApp>=[]; 
      prev.forEach((item, index)=>{
          form.push({
            ...item,
            name: {
              value: item.name.value,
              error: errors[index] ?? '',
            }
          });
      });

      return form;
    });

    const isValid = errors.every(x => !!x);
    return isValid;
  }*/

  function validate() { return validateAll(items, setErrors); }

  const handleSave = (index:number) => {
    if(!items) { return; }

    const item = items[index];
    if(!item) { return; }

    const isValid = validate()
    console.log(isValid, item.contact);
    if(!isValid || !item.contact?.contact_id) { return; }

    props.handleSave?.(index);
  }

  const isRelationList = !!items?.length;
  return (<>
        <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Relationships</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">
            {isRelationList && items.map((item,key)=>
            <FormView lookupData={lookupData.response?.relationship.items} key={key} index={key}  selectedRelation={item} errors={errors[key]} {...props} handleSave={()=>handleSave(key)} />)}
            {items.length<10&&<div className="addable_item pb-3 pt-3" >                              
                <div onClick={handleAddNew} className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Relationship</div>
            </div>} 
         </div>
     </>)
                               
}

const FormView = (props: IRelationshipSectionAppProps.FormViewProps): JSX.Element => {
  const {handleRemoveRelation,index,lookupData, contactId}=props
  const contact = props.selectedRelation?.contact;
  const [presentAlert] = useIonAlert();

  function handleChangeField<K extends keyof IRelationItem>(index:number, name:K, value:IRelationItem[K]): void
  {
    props.handleChangeField(index, name, value);
    props.handleSave?.(index);
  }

  const onContactChange = (input?:SearchContactsApi.IContactSearchItem) =>
  {
    if(!input) { return handleChangeField(index, 'contact', undefined); }

    const { id, first_name, middle_name, last_name, primary_email:email, photo_url } = input;
    const primary_phone = extractPhone(input);
    const contact:IRelationItem['contact'] = { contact_id: id, first_name, middle_name, last_name, email, photo_url, primary_phone };
    handleChangeField(index, 'contact', contact);
  }

  const onContactCreate = (input:IContactResult) =>
  {
    const { id:contact_id, firstName:first_name, lastName:last_name, email, phone } = input;
    const number = phone? parseInt(phone): undefined;
    const primary_phone = number ? { id:0, number }: undefined;
    const contact:IRelationItem['contact'] = { contact_id, first_name, last_name, email, primary_phone, photo_url:'', middle_name:'' };
    handleChangeField(index, 'contact', contact);
  }

  const onRemove = () =>
  {
    const handler = () => handleRemoveRelation(index, props.selectedRelation?.id?.toString());
    if(!props.selectedRelation?.id) { return handler(); }

      presentAlert({
        header: 'Are you sure to delete Relationship?',
        buttons: [
            { text: 'Yes', role: 'confirm', handler },
            { text: 'No', role: 'cancel' }
        ],
      })
  }

  return ( <div className="border-bottom">
                <div className="row no-gutters">
                    <div className="col-auto align-self-center">
                        <div className="delete_item d-flex">
                            <img onClick={()=>onRemove()}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
                            <div className="delete_item_value">Relat. {index+1} </div>
                            <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
                        </div>
                    </div>
                    <div className="col">
       
                  <IonItem>
                    <IonSelect name='relationType' placeholder="Choose your Relationship" value={props.selectedRelation?.type} onIonChange={(e) => { handleChangeField(index,"type",String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? '')) }}>
                        {lookupData?.map((item, index) => (
                            <IonSelectOption key={index} value={item.short_value}>{item.long_value}</IonSelectOption>
                        ))}
                    </IonSelect></IonItem>
                    {/* <Textbox containerClassName='' value={props.selectedRelation?.name} name='name' placeholder='Name' maxlength={50} id="Name" aria-describedby="Name" error={errors?.name} onIonChange={(e) => handleChangeField(index,e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))} ></Textbox> */}
                    {!contact && <ContactsTypeahead
                      initialValues={[]}
                      change={contacts => onContactChange(contacts?.[0] as SearchContactsApi.IContactSearchItem)}
                      remove={() => handleChangeField(index, 'contact', undefined)}
                      exclude={contactId}
                      showCreateContact
                      onContactCreate={onContactCreate}
                    />}
                    {contact && <IonItem className='mb-3'>
                      <div className="contact_closable__info">
                        <Avatar
                          id={contact.contact_id.toString()}
                          firstName={contact.first_name}
                          middleName={contact.middle_name}
                          lastName={contact.last_name}
                          photo={contact.photo_url}
                        />
                        <div className="agent_signature__text">
                          <div className="agent_signature__text__title"><ContactTitle contact={contact} /></div>
                        </div>
                      </div>
                    </IonItem>}
             </div>
            </div>
            </div>
        
    )
}