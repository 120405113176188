import { useHistory } from 'react-router';
import { IOnBoardingTemplateProps, } from './OnBoardingTemplate.def';
import './OnBoardingTemplate.scss';
import { IonButton, IonButtons, IonContent,  IonHeader, IonPage } from '@ionic/react';
// import { Sidebar } from './Sidebar';
// import { useHistory } from 'react-router';
// import { eOnboardingStageIndex } from 'scripts/enums';


const GeneralHeader = (props:IOnBoardingTemplateProps): JSX.Element =>{
     const history = useHistory();
    // const stage = props.onboardingStage;
    // const enableLink = stage? eOnboardingStageIndex[stage] > eOnboardingStageIndex.AccountConnection: false;

    return (
        <IonHeader className="inner_page_header ion-padding-start ion-padding-end">
            <div className="d-flex">
                {!props.onboarding && <IonButtons slot="start">
                    <IonButton color={"blue"} onClick={()=>history.push(`/home`)}>
                        <img className=' h-18px' src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />
                    </IonButton>
                </IonButtons>}
                <div className="align-self-center mr-3">
                    <img src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" className='rounded innerscreen_logo rounded' />
                    {/* <img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='innerscreen_logo rounded' /> */}
                    {/* <IonImg className="innerscreen_logo" alt="HAR" src="https://content.harstatic.com/resources/images/common/logo-blue.svg" /> */}
                </div>
                <div className="align-self-center d-flex">
                    <div className="innerscreen_name">CRM</div>                                        
                </div>
            </div>            
        </IonHeader>
    );
}
        
export const OnBoardingTemplateApp = (props:IOnBoardingTemplateProps): JSX.Element =>
{
    return (
        <IonPage >
            <GeneralHeader {...props} />
            <IonContent ref={props.contentRef} fullscreen={true} className=" ion-padding-start ion-padding-end ion-padding-top ion-padding-bottom">        
                {props.children}
            </IonContent>
        </IonPage>
    );
}

   



// const CardContent = (props:IOnBoardingCardContentProps): JSX.Element =>
// (
//     <div className="d-flex flex-column h-100">
//         <div>
//             <Sidebar active={props.active} />
//             {props.children}
//         </div>
//     </div>
// );