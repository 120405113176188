export const Loading = () =>
(
    <div className="cards cards--tasks">
        <div className="cards--tasks__content">
            <div className="cards--tasks__content_header">
                <div className="cards--tasks__content_header_title">
                    <h4 tabIndex={0}><div className="loading__text w-136px"></div></h4>
                </div>
                <div className="cards--tasks__content_header_labels">                            
                    <div className="loading__text_small mb-2 ml-auto mr-0 w-70px"></div>
                    <div className="font_weight--semi_bold ml-auto mr-0" ><div className="loading__text_small w-90px"></div></div>
                </div>
            </div>
            
            <div className="cards--tasks__content_text pt-3 mb-3">
                <div className="loading__text_small mb-2 w-150px"></div>
                <div className="loading__text_small w-120px"></div>
            </div>
            
            <div className="agent_signature agent_signature__mini">
                <div className="loading__photo mr-2 h-18px w-18px"></div>
                <div className="agent_signature__text"><div className="loading__text_smaller w-100px"></div></div>
            </div>
            
        </div>
        <div className="cards--tasks__footer">
            <div className="cards--tasks__footer__checkbox">
                <div className="d-flex">
                    <div className="loading__text mr-2 w-20px"></div>
                    <div className="loading__text_smaller mt-2 w-120px"></div>
                </div>
            </div>
        </div>
    </div>
);