import { IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { IExternalLoginScreenProps } from './ExternalLoginScreen.def';
import './ExternalLoginScreen.scss';
import { handleSignIn } from './ExternalLoginScreen.common';
import { version } from 'scripts/application/settings';
import { LoginFeedback } from 'views/auth/LoginFeedback';
import { Tag } from 'scripts/ui/tag';

export const ExternalLoginScreenApp = (_props: IExternalLoginScreenProps): JSX.Element =>
{
  //const versionText = isProduction? '': version;
  const versionText = ` v${version}`;
  const currentYear = new Date().getFullYear();

  return (
    <IonPage>
      <Tag.Div className="d-flex justify-content-center flex-column min-h-100vh bg--image login_sreen_bg" cssVars={{ loginScreenBg:`url(https://content.harstatic.com/media/bgs/crm/login_bg_image_2.jpg)`}}>
        <div className="p-3 pt-5 mt-5 pt-md-0 text-center align-self-center">
          <div className='mt-90px'>
            <img className='w-127px' src="https://content.harstatic.com/media/icons/crm/logo_white@3x.png" alt="HAR Logo" />
            <div className="mb-3 font_size--large color_snow_white">{versionText}</div>
            <div className="font_size--large_extra_extra color_snow_white text-center ml-auto mr-auto mb-5 pb-5">Simple 1 way to organize your leads, contacts, emails and more.</div>
            </div>
            <div className="ml-auto mr-auto pt-4 max-w-280">
                <button className="btn btn--prominent btn--large w-100 ml-auto mr-auto mt-5 mb-4" onClick={handleSignIn}>Sign in with HAR account</button>
            </div>
        </div>
        <div className="crm_footer mt-auto pt-3 pb-3 pb-md-0 mt-auto text-center color_snow_white border-top-rgba_1 opacity-86pr">
            <div className="font_size--small color_snow_white pl-2 pr-2 pb-2">
                COPYRIGHT© {currentYear} HOUSTON REALTORS® INFORMATION SERVICE, INC. All Rights Reserved.
            </div>

            <div className="container container--v2 pt-md-2 pb-md-3 font_size--small">
                <a href="https://cms.har.com/accessibility/" target="_blank" className="d-inline-block mr-md-3 mr-1 color_snow_white" rel='noreferrer'>Accessibility</a>
                <a href="https://cms.har.com/privacy-policy-2/" target="_blank" className="d-inline-block mr-md-3 mr-1 color_snow_white" rel='noreferrer'>Privacy Policy</a>
                <a href="https://cms.har.com/termsofuse/" target="_blank" className="d-inline-block mr-md-3 mr-1 color_snow_white" rel='noreferrer'>Terms of Use</a>
                <a href="https://cms.har.com/contact-us/" target="_blank" className="d-inline-block mr-md-3 color_snow_white" rel='noreferrer'>Contact Us</a>
            </div>
        </div>
    </Tag.Div>
      <LoginFeedback />
    </IonPage>
  );
};

export const ExternalLoginCallbackScreenApp = (_props: RouteComponentProps) => {
  return (
    <IonPage className="items-center">
      <div className="card flex items-center justify-center">
        <div className="container flex items-center justify-center h-20 font-20">
          <p>Signing in ....</p>
        </div>
      </div>
    </IonPage>
  );
};

export const ExternalLogoutScreenApp = (_props: RouteComponentProps) => {
  return (
    <IonPage className="items-center">
      <div className="card flex items-center ">
        <div className="container flex items-center  h-20 font-20">
          <p>Signing out....</p>
        </div>
      </div>
    </IonPage>
  );
};
