import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import './ContactEmailScreen.scss';
import { EmailDetailsApi } from 'services/emails/emailDetails';
import { IEmailRecipient } from 'services/emails/searchEmails';
import { useEffect, useState } from 'react';
import { EmailList } from 'views/emails/EmailList';
import { EmailDetail } from 'views/emails/EmailDetail';
import { IEmailLandingScreenProps } from 'views/screens/emails/EmailLandingScreen/EmailLandingScreen.def';
import { NewEmail } from 'views/emails/popups/NewEmail';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';


export const ContactEmailScreenDesktop = (props: IEmailLandingScreenProps): JSX.Element => {
    const { list, id, loading, type, mails, page, member, keyword, readList, contactId, redirect, update, search, sort, reload, remove, mark, loadMore, setCounter } = props;
    const [newEmailShow, setNewEmailShow] = useState(false)
    const [messageId, setMessageId] = useState("")
    const [cid, setCid] = useState("")
    const [reloadPreview, setReloadPreview] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>()
    const [emailItem, setEmailItem] = useState<{ item: any, isNew: boolean } | undefined>()
    const [action, setAction] = useState<'reply' | 'forward' | "draft" | "">("")
    const [, setDraftContacts] = useState<IEmailRecipient[] | any>()


    //service
    useEffect(() => {
        if (member && typeof member?.provider_status !== 'undefined') {
            setConnectionStatus(member.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [member])

    useEffect(() => {
        if (contactId)
            setCid(contactId)
    }, [contactId])

    const startWriting = (mid?: string, action?: 'reply' | 'forward' | 'draft' | "", contact?: IEmailRecipient[] | any) => {
        if (mid) {
            setMessageId(mid)
        } else {
            setMessageId("")
        }
        setAction(action ? action : '')
        setDraftContacts(contact)
        setNewEmailShow(true)
        if (contactId)
            setCid(contactId)
    }

    const forwardDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "forward")
    }


    const replyDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "reply", "")

    }
    return (
        <>
            <NewEmail
                show={newEmailShow}
                messageId={messageId}
                type={type}
                action={action}
                changePreview={(item?: any, isNew?: boolean) => {
                    if (!isNew) {
                        setReloadPreview(true)
                    }
                    if (item) {
                        setEmailItem({ 'item': item, 'isNew': isNew ? isNew : false })
                    }
                }}
                connectionStatus={connectionStatus}
                provider_email={member?.provider_email}
                onClose={() => {
                    setNewEmailShow(false);
                    setMessageId("")
                    setAction("")
                    setCid('')
                }}
                contactId={cid}
                reload={reload} />
            <ContactDetailsTemplate selectedMenu='Emails'>
                <div className="container">
                    <div className="page_title_container">
                        <div className="row d-none">
                            <div className="col align-self-center">
                                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Emails</h2>
                            </div>
                            <div className="col-auto align-self-center">
                                <a className="btn btn btn--prominent__v2 btn--small ml-4 d-inline-flex" onClick={() => startWriting("", "")}>
                                    <img className="mr-1 w-22px" src="https://content.harstatic.com/media/icons/icon_plus_white.svg" /> Create
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col align-self-center">
                            <h2 className="h2--simple h2--simple__black mb-0">Emails</h2>
                        </div>
                        <div className="col-auto align-self-center">
                            {member && connectionStatus &&
                                <a className='cursor--hand' onClick={() => startWriting("", '')}>+New Email</a>
                            }
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col">
                            {member && typeof connectionStatus !== 'undefined' &&
                                <div className="email_container h-calc184">
                                    <EmailList
                                        loading={loading}
                                        list={list}
                                        type={type}
                                        id={id}
                                        total={mails?.total}
                                        totalPages={mails?.total_pages}
                                        page={page}
                                        keyword={keyword}
                                        connectionStatus={connectionStatus}
                                        updateList={emailItem}
                                        readList={readList}
                                        redirect={redirect}
                                        loadMore={loadMore}
                                        search={search}
                                        reload={reload}
                                        sort={sort}
                                        remove={remove}
                                        mark={mark}
                                        update={update}
                                    />

                                    <EmailDetail
                                        type={type}
                                        id={id}
                                        mails={mails}
                                        reload={reloadPreview}
                                        setReload={setReloadPreview}
                                        createDraft={(mail: EmailDetailsApi.Response, isReply: boolean) => {
                                            if (isReply) {
                                                replyDraft(mail)
                                            } else {
                                                forwardDraft(mail)
                                            }
                                        }}
                                        openMessage={(Id: string) => {
                                            startWriting(Id, "draft")
                                        }}
                                        connectionStatus={connectionStatus}
                                        remove={remove}
                                        setCounter={setCounter}

                                    />

                                </div>
                            }
                            {member && typeof connectionStatus === 'undefined' &&
                                <div className=' h-calc184'>
                                    <NoConnectionBox className='pt-4 pl-4' />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </ContactDetailsTemplate>
        </>


    );
}
