import { useEffect, useState } from 'react';
import { TagsTextbox } from 'views/forms/textboxes/TagsTextbox';
import { CityTypeahead } from 'views/forms/typeaheads/CityTypeahead';
import { CompanyTypeahead } from 'views/forms/typeaheads/CompanyTypeahead';
import { IContactsFilterPopUpProps, stagesOptions, IFormFieldProps, dealsOptions, IContactsFilters, IContactCommonFilters, IContactCommonFiltersKeys } from './ContactsFilterPopUp.def';
import './ContactsFilterPopUp.scss';
import { ITag } from 'views/forms/textboxes/TagsTextbox/TagsTextbox.def';
import { ScoreOptions } from 'views/contacts/ScoreOptions';
import { ZipCodeTypeahead } from 'views/forms/typeaheads/ZipCodeTypeahead'; import { Button } from 'views/forms/buttons/Button';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { useResponsiveRender } from 'scripts/hooks/general';
import { ContactsFilterPopUpApp } from './ContactsFilterPopUp.app';
import { OwnersTextbox } from 'views/forms/textboxes/OwnersTextbox';
import { ObjectOwnerApi } from 'services/typeaheads/objectOwner';
import { useFetchLookupsService } from 'services/general/lookups';
import { OverlayTrigger } from 'react-bootstrap';
import { popover_score, popover_tags } from 'views/contacts/ContactInsightSection/components/InfoOverlayTrigger';
import { MuiDatePicker } from 'views/forms/material/MuiDatePicker';
import { parseISO, format } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { SelectWithSearch } from 'views/forms/select/SelectWithSearch';
import { IMultiSourceDropDownProps } from 'views/forms/dropdowns/SourceDropDown/SourceDropDown.def';

const ContactsFilterPopUpXL = (props: IContactsFilterPopUpProps): JSX.Element => {
    const { filters, filtersCount, count, contactsApiLoading, loadCount, onChange, ...filtersProps } = props;
    const [showFilter, setShowFilter] = useState(false)
    const [showMoreFilters, setShowMoreFilter] = useState(false)

    const { cities, companies, stages, deal_stages, scores, owners, zip_codes, tags, sources, last_contact_date } = filters ? filters : filtersProps;


    function updateField<K extends keyof IContactsFilters>(name: K, value: IContactsFilters[K]) {
        onChange?.(name, value);
        if (filters) {
            loadCount?.();
        }
    }


    function onCityFilterChange(value: string[]) {
        updateField('cities', value);
    }

    function onContactFilterChange(value: string) {
        updateField('last_contact_date', value);
    }


    function onStagesFilterChange(value: number[]) {
        updateField('stages', value);
    }

    function onDealsFilterChange(value: string[]) {
        updateField('deal_stages', value);
    }


    function onSourcesChange(value: string[]) {
        updateField('sources', value);
    }

    function onTagsFilterChange(value: ITag[]) {
        updateField('tags', value);
    }

    function onCompanyFilterChange(value: string[]) {
        updateField('companies', value);
    }

    function onScoreFilterChange(value: string[]) {
        updateField('scores', value);
    }

    function onZCodeFilterChange(value: number[]) {
        updateField('zip_codes', value);
    }

    function onOwnerChange(item: ObjectOwnerApi.IObjectList[]) {
        updateField('owners', item);

    }

    function removeOwner(agentkey: string) {
        let restOwners = owners?.filter((i) => i.agent_key !== agentkey)
        updateField('owners', restOwners);

    }

    function onDateRangeChange<K extends keyof IContactCommonFiltersKeys>(key: K, value: string) {
        updateField(key, value);
    }
    const filterLanuchDesktop = (): JSX.Element => {
        return (
            <button disabled={props.disabled} className={`filterpill ${filtersCount && filtersCount > 0 ? `filterpill--all` : ``}`}
                onClick={() => setShowFilter(showFilter ? false : true)}
                aria-label="Filter by price">Filters {filtersCount && filtersCount > 0 ? <span>{filtersCount}</span> : ''}</button>
        );
    }

    const filterLanuchApp = (): JSX.Element => {
        return (
            <a className="action_bar__link pl-2 cursor_pointer" onClick={() => setShowFilter(showFilter ? false : true)}><img src="https://content.harstatic.com/media/icons/filters.svg" /></a>
        );
    }

    const Launch = (): JSX.Element => {
        return useResponsiveRender(filterLanuchDesktop, filterLanuchApp)
    }







    return (
        <>
            <Launch />
            <DefaultPopUp className='custom--modal__fullheight custom--modal__filters' title='Filters' isOpen={showFilter} toggleShow={() => setShowFilter(false)} >
                <DefaultPopUp.Body>
                    <div className="py-4 px-5">

                        <div className="font_size--large font_weight--semi_bold mb-2">Location</div>
                        <div className="row">
                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                <CityTypeahead initialValues={cities} onChange={onCityFilterChange} />
                            </div>
                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                <ZipCodeTypeahead initialValues={zip_codes} onChange={onZCodeFilterChange} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <LastContact initialSelections={last_contact_date} onChange={onContactFilterChange} />
                        </div>

                        <div className=" font_size--large font_weight--semi_bold mb-3">Stages</div>
                        <Stages initialSelections={stages} onChange={onStagesFilterChange} />

                        <div className="pb-4">
                            <Deals initialSelections={deal_stages} onChange={onDealsFilterChange} />
                        </div>

                        <div className="mb-2">
                            <div className="font_size--large font_weight--semi_bold mb-2">Scores <OverlayTrigger trigger="hover" placement="right" overlay={popover_score}><img className='ml-2' src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>
                            </div>
                            <ScoreOptions.Multi initialSelections={scores} isFilter onScoresChange={onScoreFilterChange} />
                        </div>

                        <div className="mb-4  d-none">
                            <div className="font_size--large font_weight--semi_bold mb-2">Object Owners</div>
                            <OwnersTextbox initialItems={owners} onItemChange={onOwnerChange} onRemoveItem={removeOwner} readonly />
                        </div>


                        <div className="mb-4">
                            <div className="font_size--large font_weight--semi_bold mb-2">Tags
                                <OverlayTrigger trigger="hover" placement="right" overlay={popover_tags}><img className='ml-2' src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>
                            </div>
                            <TagsTextbox initialTags={tags} onTagsChange={onTagsFilterChange} noAdd={true} allowNone={true} />
                        </div>
                        <div className="mb-4">
                            <div className=" font_size--large font_weight--semi_bold mb-2">Sources</div>
                            <SourceDropDown values={sources} onChange={onSourcesChange} />

                        </div>

                        <div className={`more-filters-content ${!showMoreFilters ? 'slide-out' : 'slide-in'}`}>
                            <div className="mb-4">
                                <div className=" font_size--large font_weight--semi_bold mb-2">Companies</div>
                                <CompanyTypeahead initialValues={companies} onChange={onCompanyFilterChange} hideId={true} />
                            </div>

                            <div className="mb-4">
                                <div className="font_size--large font_weight--semi_bold mb-4">Lead type</div>
                                <LeadTypes value={filters?.lead_type} onChange={onDateRangeChange} />
                            </div>

                            <div className="mb-2">
                                <RangeComponent
                                    startValue={filters?.birthday_start}
                                    endValue={filters?.birthday_end}
                                    startDate={'birthday_start'}
                                    endDate={'birthday_end'}
                                    title={'Date of birth'}
                                    onChange={onDateRangeChange}
                                />
                            </div>

                            <div className="mb-2">
                                <RangeComponent
                                    startValue={filters?.home_anniversary_start}
                                    endValue={filters?.home_anniversary_end}
                                    startDate={'home_anniversary_start'}
                                    endDate={'home_anniversary_end'}
                                    title={'Home Anniversary'}
                                    onChange={onDateRangeChange}
                                />
                            </div>

                            <div className="mb-2">
                                <RangeComponent
                                    startValue={filters?.wedding_anniversary_start}
                                    endValue={filters?.wedding_anniversary_end}
                                    startDate={'wedding_anniversary_start'}
                                    endDate={'wedding_anniversary_end'}
                                    title={'Wedding Anniversary'}
                                    onChange={onDateRangeChange}
                                />
                            </div>


                            <div className="mb-0">
                                <RangeComponent
                                    startValue={filters?.known_since_start}
                                    endValue={filters?.known_since_end}
                                    startDate={'known_since_start'}
                                    endDate={'known_since_end'}
                                    title={'Known Since'}
                                    onChange={onDateRangeChange}
                                />
                            </div>
                        </div>
                        <div id="showMoreFilters" className="mb-4 font_size--medium cursor--hand font_weight--bold color_har_blue" onClick={() => setShowMoreFilter(!showMoreFilters)}>{!showMoreFilters ? 'More Filters' : 'Hide more Filters'}</div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <div className="row">
                            <div className="col-auto">
                                <div style={{ minWidth: '100px' }}></div>
                            </div>
                            <div className="col">
                                <Button kind='primary' className='mt-4' onClick={() => {
                                    props.applyFilter?.()
                                    setShowFilter(false)
                                }} loading={contactsApiLoading}>
                                    {!contactsApiLoading && count && count > 0 ?
                                        `Show ${count} contacts`
                                        :
                                        'Apply'
                                    }</Button>    </div>
                            <div className="col-auto text-right">
                                <div style={{ minWidth: '100px' }}> {count && count >= 0 ?
                                    <Button kind='shapeless' className='mt-4' onClick={() => { props.onClear?.(); setShowFilter(false) }}> Clear Filters</Button>
                                    :
                                    <Button kind='shapeless' className='mt-4' onClick={() => setShowFilter(false)}>Cancel</Button>
                                }</div>
                            </div>
                        </div>
                    </div>

                </DefaultPopUp.ActionBar>
            </DefaultPopUp >
        </>
    );
}


export const RangeComponent = (props: IContactCommonFilters) => {

    return (
        <>
            <div className=" font_size--large font_weight--semi_bold mb-2">{props.title}</div>
            <div className="row">
                <div className="col-md-6 col-12 pb-md-2 pb-0">
                    <MuiDatePicker
                        label='Start Date'
                        showMonthAndDay={true}
                        hideYearSelection={true}
                        value={props.startValue ? parseISO(new Date().getFullYear() + '-' + props.startValue) : undefined}
                        onChange={(date) => {
                            props.onChange?.(props.startDate, format(date, 'MM-dd'))
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 pb-md-2 pb-0">
                    <MuiDatePicker
                        label='End Date'
                        showMonthAndDay={true}
                        hideYearSelection={true}
                        value={props.endValue ? parseISO(new Date().getFullYear() + '-' + props.endValue) : undefined}
                        disabled={!props.startValue}
                        minDate={props.startValue ? parseISO(new Date().getFullYear() + '-' + props.startValue) : undefined}
                        onChange={(date) => props.onChange?.(props.endDate, format(date, 'MM-dd'))}
                    />
                </div>
            </div>

        </>
    )
}

export const LeadTypes = (props: { value?: string, onChange?: <K extends keyof IContactCommonFiltersKeys>(key: K, value: string) => void, }) => {
    const [lookupData] = useFetchLookupsService(['contact_lead_type']);
    return (
        <div className="row px-3">

            <Dropdown className="dropdown dropdown--custom dropdown--custom_large mb-0 w-98 mx-auto" aria-multiselectable={true}>
                <Dropdown.Toggle className="dropdown-toggle font_weight--normal">
                    {props.value ? lookupData?.response?.contact_lead_type.items.filter((i) => i.short_value === props.value)[0]?.long_value : 'Lead Type'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' >
                    {lookupData?.response?.contact_lead_type.items.filter((i) => i.short_value !== 'N').map((item, index) =>
                        <Dropdown.Item value={props.value === item.short_value ? item.long_value : undefined} onClick={() => {
                            props.onChange?.('lead_type', item.short_value)

                        }} key={index} href="#" className={`d-flex ${index + 1 !== lookupData?.response?.contact_lead_type.items.length ? `border-bottom ` : ``} font_weight--bold`}>
                            <span>{item.long_value}</span>
                            <span className="ml-auto"></span>
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export const SourceDropDown = (props: IMultiSourceDropDownProps) => {
    const [lookupData] = useFetchLookupsService(['contact_source']);

    return (
        <>
            {lookupData?.response &&
                <SelectWithSearch
                    title='Sources'
                    onSelect={(obj: any) => {
                        props.onChange?.([...props.values ?? [], obj.value])
                    }}
                    onRemove={(obj: string) => {
                        props.onChange?.(props.values?.filter((i) => i !== obj) ?? [])
                    }}
                    multiple={true}
                    selectedValue={props.values}
                    options={lookupData?.response?.contact_source?.items.map((item) => { return { value: item.short_value, label: item.long_value } })}
                />
            }
        </>
    )
}

export const Stages = (props: IFormFieldProps<number[]>) => {

    const [selections, setSelections] = useState(props.initialSelections ?? []);
    const [select, selectAll] = useState<boolean | undefined>(undefined);
    let newSelections = new Array<number>();
    useEffect(() => { setSelections(props.initialSelections ?? []); console.log(selections) }, [props.initialSelections]);

    const selectAllStages = () => {
        selectAll(true);
        const newSel = stagesOptions.map(x => { return x.short_value });
        setSelections(newSel);
    }

    const UnselectAllStages = () => {
        selectAll(false);
        setSelections([]);
    }

    const toggleStage = (value: number) => {

        if (selections.includes(value)) {
            newSelections = selections.filter(selection => selection !== value);
        } else {
            newSelections = [...selections, value];
        }
        if (select) {
            selectAll(undefined);
        }
        setSelections(newSelections);
        props.onChange?.(newSelections);

    }

    return (
        <>
            <div className="row">
                <div className="col-auto d-none">{select ?
                    <a className="custom_link" onClick={UnselectAllStages}> Unselect All</a>
                    :
                    <a className="custom_link" onClick={selectAllStages}>Select All</a>}
                </div>
            </div>

            <div className="row mb-2">
                {stagesOptions.map((item, index) => {
                    const checked = select ?? selections.includes(item.short_value);
                    return (
                        <div className="col-3" key={index}>
                            <div role="checkbox" className="custom-control custom-checkbox">
                                <input type="checkbox" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} value={item.short_value} className="custom-control-input" onChange={(e) => toggleStage(Number(e.target.value))} />
                                <label className='custom-control-label stage' htmlFor={`${item.short_value}`}>
                                    <div className={`stage ${item.class}`}>{item.long_full_value ?? item.long_value}</div>
                                </label>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export const Deals = (props: IFormFieldProps) => {

    const [selections, setSelections] = useState(props.initialSelections ?? []);
    useEffect(() => { setSelections(props.initialSelections ?? []) }, [props.initialSelections]);

    const toggleDeals = (value: string) => {
        let newSelections = new Array<string>();
        if (selections.includes(value)) {
            newSelections = selections.filter(selection => selection !== value);
        } else {
            newSelections = [...selections, value];
        }
        setSelections(newSelections);
        props.onChange?.(newSelections);

    }

    return (
        <>
            <div className=" font_size--large font_weight--semi_bold mb-3">Deals</div>
            <div className="row">
                {dealsOptions.map((item, index) => {
                    const checked = selections.includes(item.short_value);
                    return (
                        <div className="col-4" key={index}>
                            <div role="checkbox" className="custom-control custom-checkbox">
                                <input type="checkbox" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} value={item.short_value} className="custom-control-input" onChange={(e) => toggleDeals(e.target.value)} />
                                <label className='custom-control-label' htmlFor={`${item.short_value}`}>{item.long_value}</label>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export const LastContact = (props: IFormFieldProps<string>) => {
    const { initialSelections } = props;
    const [lookupData] = useFetchLookupsService('last_contact_filter');
    return (
        <>
            <div className="col-6">
                <div className=" font_size--large font_weight--semi_bold mb-2">Last Updated</div>
                <select className="custom-select custom-select_medium custom-select_large" defaultValue={initialSelections} onChange={(e) => { props.onChange?.(e.target.value) }}>
                    <option value={``} >Last Updated</option>
                    {lookupData?.response?.last_contact_filter?.items.map((item, index: number) =>
                        <option value={`${item.short_value}`} key={index}>{item.long_value}</option>
                    )
                    }
                </select>
            </div>

        </>
    )
}

export const ContactsFilterPopUpDesktop = (props: IContactsFilterPopUpProps): JSX.Element => {
    return useResponsiveRender(ContactsFilterPopUpXL(props), ContactsFilterPopUpApp(props));
}
