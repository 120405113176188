import { useHistory } from 'react-router';
import { StageColumn } from 'views/contacts/pipeline/StageColumn';
import { ContactManageTemplate } from 'views/templates/ContactManageTemplate';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { IContactPipelineScreenProps } from './ContactPipelineScreen.def';
import './ContactPipelineScreen.scss';
import { useEffect, useState } from 'react';
import { PipelineRemovePopUp } from './states/pipelineRemovePopUp';
import { ContactCard } from 'views/contacts/pipeline/StageColumn/ContactCard';
import { AddPopover } from 'views/contacts/pipeline/StageColumn/AddPopover';
import { ActionSheetButton } from '@ionic/core';
import { ContactsFilterPopUp } from 'views/contacts/popups/ContactsFilterPopUp';
import { ModuleInfo } from 'views/general/ModuleInfo';
import { useMatrix } from '../hooks/useMatrix';
import { useCSVImportStatus } from '../hooks/useCSVImportStatus';


export const ContactPipelineScreenApp = (props: IContactPipelineScreenProps): JSX.Element => {
    const { trash, columns, contacts, filters, filterCount, loading, filterhandler, applyFilter, clearFilter, loadMore, setTrash, updateAndSort, addContact, removeContact } = props
    const [showDeleteWarn, setShowDeleteWarn] = useState(false)
    const [animate, startAnimate] = useState(false)
    const [isDragging, setDragging] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const { matrixContactStatus, matrixContactImportStatusId, MatrixImportMessage, IntiateMatrix } = useMatrix()
    const { csvImporting, CSVStatusBlock } = useCSVImportStatus()

    const [timer, setTimer] = useState<number | string>()
    const [contactId, setContactId] = useState(0)
    const [contactPipeline, setContactPipeline] = useState(0)
    const [contactName, setContactName] = useState("")
    const [openPopover, setOpenPopover] = useState(0)
    const filterButton: (string | ActionSheetButton<any>)[] = [{
        text: 'Filters',
        cssClass: 'font_weight font_weight--semi_bold',
        data: {
            action: 'delete',
        },
        handler: () => {
            setShowFilter(true)
        }
    }]
    const downloadContact = (param: any) => {
        if (props.downloadContact) {
            const result = props.downloadContact(param)
            return result;
        }
    }
    const onRemoveContact = (id: number, pipeline: number, name: string) => {
        setContactId(id)
        setContactPipeline(pipeline)
        setContactName(name)

    }
    let history = useHistory();
    const dragEnd = (result: any) => {
        if (result.destination) {
            updateAndSort?.(result)
        }
        if (timer) {
            clearTimeout(timer)
        }

        setTimer(setTimeout(() => {
            setDragging(animate ? isDragging : false)
        }, 500))

    }


    const emptyBin = () => {
        setTimeout(() => {
            setTrash?.([])
            startAnimate(false)
        }, 1000)

    }

    useEffect(() => {
        if (trash && trash?.length !== 0) {
            startAnimate(true)
            emptyBin()
        }
    }, [trash])

    useEffect(() => {
        if (showFilter) {
            setTimeout(() => {
                setShowFilter(false)
            }, 2000)
        }
    }, [showFilter])

    return (
        <>

            <AddPopover visible={openPopover !== 0 ? true : false} setVisiblity={() => setOpenPopover?.(0)} pipeline={openPopover} addContact={addContact} />
            <ContactsFilterPopUp
                showProps={showFilter}
                filters={filters ? filters : {}}
                onChange={filterhandler}
                onClear={clearFilter}
                applyFilter={applyFilter}
                filtersCount={filterCount}
                hideLanucher={true}
            />
            <PipelineRemovePopUp
                show={showDeleteWarn}
                name={contactName}
                toggleShow={() => setShowDeleteWarn(false)}
                removeContact={() => {
                    if (removeContact) {
                        removeContact(contactId, contactPipeline, contactName)
                    }
                    setShowDeleteWarn(false)
                }} />
            <ContactManageTemplate downloadContact={() => downloadContact([])} fluidTemplate={true} isDragging={isDragging} noContent={true} headerButton={filterButton}
                matrixStatusId={matrixContactImportStatusId} matrixInit={IntiateMatrix}>
                <div className='pipline_container'>
                    {isDragging && <div className=' h-73px'></div>}
                    <DragDropContext onDragEnd={dragEnd} onDragStart={() => setDragging(true)} >
                        <div className={`OverlayPlaceholder ${isDragging ? 'h-120px' : 'h-0px'} overflow-hidden position-fixed zindex-1000`} onDrop={(ev: any) => console.log(ev)} onDragOver={(ev: any) => console.log(ev)}>
                            <Droppable droppableId={`0`} type="contacts">
                                {(provided, _snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {trash && trash?.length !== 0 &&
                                            <div className={`trash--Box ${animate ? `delayNone` : ``}`}>
                                                <ContactCard
                                                    contact={trash}
                                                    removeContact={removeContact}
                                                />
                                            </div>
                                        }
                                        {trash && trash?.length === 0 ?
                                            <div className="row pt-3 n-gutters items-center justify-content-center m-auto h-110px w-100">
                                                <div className="col-auto">
                                                    <img className=' w-24px' src="https://content.harstatic.com/media/icons/icon-archive-outlined-slate.svg" />
                                                </div>
                                                <div className="col-auto pl-2 font_size--large font_weight--semi_bold">Drop here to remove from pipeline</div>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <nav id="ContactTabs" className={`nav nav-tabs nav-tabs--simple  nav-tabs--fullwidth`}>
                            <a title="Contact Management" className="mt-2 mt-md-5 nav-item nav-link" onClick={() => history.push('/contacts', { 'filters': filters })} >
                                Contact Management
                            </a>
                            <a title="Contact Management" className="mt-2 mt-md-5 nav-item  nav-link active" onClick={() => history.push('/contacts/pipeline', { 'filters': filters })} >
                                Contact Pipeline
                            </a>
                        </nav>

                        <div className="horizental_scroll">
                            <div className='mt-2'>
                                <ModuleInfo type="pipeline" />
                            </div>
                            {matrixContactStatus &&
                                <MatrixImportMessage />
                            }
                            {csvImporting &&
                                <CSVStatusBlock />
                            }
                            <div className="horizental_scroll__inner">
                                <div className="pipeline_list mt-4">
                                    {columns?.map((column, index: number) =>
                                        <Droppable key={index} droppableId={`${column.pipeline}`} type="contacts">
                                            {(provided, _snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    <StageColumn
                                                        {...column}
                                                        contacts={contacts?.filter((contact) => contact.pipeline === column.pipeline)[0]}
                                                        loadMore={loadMore}
                                                        loading={loading}
                                                        addContact={addContact}
                                                        removeContact={onRemoveContact}
                                                        setOpenPopover={setOpenPopover}
                                                    />
                                                </div>
                                            )}
                                        </Droppable>
                                    )}

                                </div>
                            </div>
                        </div>
                    </DragDropContext>

                </div >
            </ContactManageTemplate ></>

    );
}

/*
const HeaderWhileDragging = (_props: IPipelineDraggingHeader) => {
  return (

        <div className="OverlayPlaceholder">
            <div className="row no-gutters items-center justify-content-center">
                <div className="col-auto">
                    <img src="https://content.harstatic.com/media/icons/mls_small_icons/icon-property-archive.svg" />
                </div>
                <div className="col-auto pl-2 font_size--large font_weight--semi_bold">Drop here to remove from pipeline</div>
            </div>
        </div>
  )
}*/

