import { ITaskDetailsPopUpProps } from './TaskDetailsPopUp.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { TaskDetailsPopUpApp } from './TaskDetailsPopUp.app';
import { TaskDetailsPopUpDesktop } from './TaskDetailsPopUp.desktop';

export const TaskDetailsPopUp = (props: ITaskDetailsPopUpProps): JSX.Element =>
{
    const TaskDetailsPopUpPlatform = useResponsiveChoose(TaskDetailsPopUpDesktop, TaskDetailsPopUpApp);
    return (
        <TaskDetailsPopUpPlatform {...props} />
    );
}
