import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateLinkApi {
    export const url = "/contacts/:contact_id/links/:link_id";
    export const method = "PUT";
    export const defaultError = "Failed to update link.";
    export const operations = apiOperations(UpdateLinkApi);
    export const scope = new class UpdateLinkApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                link_id: parseInt(data.link_id)
            }
        }
    }();

    export interface Request {
        contact_id: string,
        link_id: string,
        name: string,
        url: string
    }

    export interface Response {
        link_id: number
    }
}

export const fetchUpdateLinkService = forwardOperation(UpdateLinkApi.operations, 'fetch');
export const useFetchUpdateLinkService = forwardOperation(UpdateLinkApi.operations, 'useFetch');
export const useUpdateLinkService = forwardOperation(UpdateLinkApi.operations, 'useService');