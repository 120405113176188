import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { ITrainingVideosScreenProps } from './TrainingVideosScreen.def';
import './TrainingVideosScreen.scss';
import Dropdown from 'react-bootstrap/Dropdown';

export const TrainingVideosScreen = (_props: ITrainingVideosScreenProps): JSX.Element =>
{
    
    return (
        <DefaultTemplate title="Training">
            <div className='container'>
                <div className="pt-5 pb-5">
                    <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Video Tutorials</h2>

                    <div className='pt-5 mt-5 pb-5 mb-5 d-none'>
                        
                        <Dropdown className='popover--dropdown max-w-374'>
                            <Dropdown.Toggle className='popover--dropdown__btn popover--dropdown__btn__active' variant="success" id="dropdown-basic">
                                Dropdown Button
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='dropdown-menu dropdown-menu--unclosable  popover--dropdown__content'>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className='row pt-5'>
                        <div className='col-md-6 col-12 pb-5'>
                            <div className='p-md-5'>
                                <div className="embed-responsive embed-responsive-16by9 border_radius--pudgy depth--above_all">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/SkIkfkP-zFk?si=e5vjezq3X2YtU0xE" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-12 pb-5'>
                            <div className='p-md-5'>
                                <div className="embed-responsive embed-responsive-16by9 border_radius--pudgy depth--above_all">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/_hJxWWIinFM?si=O7M5c-aHfY1aW59L" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-12 pb-5'>
                            <div className='p-md-5'>
                                <div className="embed-responsive embed-responsive-16by9 border_radius--pudgy depth--above_all">                                    
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/YAWFPMfi6nY?si=EQCizD_-rFIFFNVj" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-md-6 col-12 pb-5'>
                            <div className='p-md-5'>
                                <div className="embed-responsive embed-responsive-16by9 border_radius--pudgy depth--above_all">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5owhzll9nX4?si=kJIhVsKcfBLujWWI" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-12 pb-5'>
                            <div className='p-md-5'>
                                <div className="embed-responsive embed-responsive-16by9 border_radius--pudgy depth--above_all">                                
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/s8JgzBHDqmg?si=AKL6tgrFSe4BCVzb" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        
                    </div>


                    
                </div>
            </div>
        </DefaultTemplate>
    );
}
