import { useRef, useState } from 'react';
import { ISelectWithSearchOptions, ISelectWithSearchProps } from './SelectWithSearch.def';
import './SelectWithSearch.scss'; import Dropdown from 'react-bootstrap/Dropdown';
import { IonIcon } from '@ionic/react';


export const SelectWithSearchDesktop = (props: ISelectWithSearchProps): JSX.Element => {
    const { title, options, selectedValue, multiple, placeholder, label, emptyOption, onSelect, onSearch, onRemove } = props
    const isMultiple = multiple;
    const dropDownElem = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLInputElement>(null)
    const [internalOptions, setInternalOptions] = useState<ISelectWithSearchOptions[] | undefined>(emptyOption ? [{ label: emptyOption, value: '' }, ...options ?? []] : options)
    const clickHandler = (obj: ISelectWithSearchOptions) => {
        if (isMultiple) {
            if (selectedValue?.includes(obj.value)) {
                onRemove?.(obj.value)
            } else {
                onSelect?.(obj);
            }
        } else {
            onSelect?.(obj);
            dropDownElem.current?.click()
        }
    }

    const searchHandler = (e: any) => {
        let query = e.target.value.toLowerCase()
        var searchResult = options?.filter((obj: any) => obj.label.toLowerCase().indexOf(query) >= 0 ? true : false)
        setInternalOptions(searchResult)
        onSearch?.()
    }

    const isSeletected = (val: ISelectWithSearchOptions) => {
        if (isMultiple && selectedValue && typeof selectedValue === 'object') {
            return selectedValue?.find((option) => option === val.value) ? true : false;
        } else {
            return options?.find((option) => option.value === selectedValue && option.value === val.value) ? true : false;
        }
    }

    const getLabel = (val: string) => {
        return options?.filter((option) => option.value === val)[0]?.label;
    }

    const isEmpty = () => {
        if (!selectedValue) {
            return true;
        } else if (selectedValue && typeof selectedValue === 'object' && selectedValue.length === 0) {
            return true;
        } else if (selectedValue && typeof selectedValue === 'string' && selectedValue === '') {
            return true;
        } else {
            return false;
        }
    }


    const moveCursorToSpot = () => {
        const timeOut = setTimeout(() => {
            textRef?.current?.focus()
        }, 500)
        return () => {
            clearTimeout(timeOut);
        };
    }



    return (
        <div className="row">
            {label &&
                <div className="col-md-auto col-12">{label}</div>
            }
            <div className="col-12">
                <Dropdown ref={dropDownElem} className="multi_select" onClick={() => {
                    moveCursorToSpot()
                }} >
                    <Dropdown.Toggle id={`dropdown-basic-${props.key}`} variant="none" className="btn tagsinput w-100 btn--white btn--white__slate align-items-center d-flex btn--medium pr-0">
                        {selectedValue && !isMultiple && typeof selectedValue === 'string' &&
                            <span className="pr-2 overflow-ellipsis d-block">{getLabel(selectedValue)}</span>
                        }
                        {selectedValue && isMultiple && typeof selectedValue === 'object' && selectedValue.length > 0 &&
                            <span className="pr-2 overflow-ellipsis d-block">{typeof selectedValue === 'object' &&
                                selectedValue?.map((value) =>
                                    <div className='filterpill filterpill--closeable2'>{value}
                                        <a onClick={() => {
                                            onRemove?.(value)
                                        }}></a>
                                    </div>
                                )}</span>
                        }
                        {isEmpty() && placeholder &&
                            <span className="pr-2 overflow-ellipsis d-block">{placeholder}</span>
                        }
                        {isEmpty() && title &&
                            <span className="pr-2 overflow-ellipsis d-block">{title}</span>
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`w-100 max-h-240px pt-0`} style={{ willChange: 'transform' }} >
                        <div className="p-3 position-sticky top-0 bg_color_snow_white zindex-1">
                            <input ref={textRef} type="text" className="form-control" placeholder="Search" onKeyUp={searchHandler} />
                        </div>
                        {internalOptions?.map((option, index) =>
                            <div className="pb-2 pl-3 pr-3" key={index}>
                                <a className="choosable__withcheck text-decoration-none mb-0" onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    clickHandler(option);
                                }}>
                                    <input type="checkbox" className="custom-control-input" name="MultiSelect" id={`MultiSelect_${index}`} defaultChecked={isSeletected(option)} />
                                    <label className="custom-control-label mb-0" htmlFor={`MultiSelect_${index}`}>
                                        <div>{option.label}</div>
                                        {isSeletected(option) &&
                                            <div className="selected ml-auto pr-2"><IonIcon className='w-20px h-20px' name="checkmark-outline"></IonIcon></div>
                                        }
                                    </label>
                                </a>
                            </div>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div >
    );
}
