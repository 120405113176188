import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace RenameDocumentsApi
{
    export const url = "/contacts/:contact_id/documents/:document_id";
    export const method = "PUT";
    export const defaultError = "Failed to update document";
    export const operations = apiOperations(RenameDocumentsApi);
    export const scope = new class RenameDocumentsApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return {...data,name:data.name.length>50?data.name.slice(0,49):data.name}; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        contact_id:string;
        name:string;
        document_id:string;

    }

    export interface Response
    {
        status: string,
        message:string
      }
}

export const fetchRenameDocumentsService = forwardOperation(RenameDocumentsApi.operations, 'fetch');
export const useFetchRenameDocumentsService = forwardOperation(RenameDocumentsApi.operations, 'useFetch');
export const useRenameDocumentsService = forwardOperation(RenameDocumentsApi.operations, 'useService');