import './NewTagPopUp.scss';
import { INewTagPopUpProps } from './NewTagPopUp.def';
import { useEffect, useState } from 'react';
import { Textbox } from 'views/forms/textboxes/Textbox';
import { useCreateTagService } from 'services/contacts/tags/createTag';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { ConfirmPopUp } from 'views/popups/ConfirmPopUp';

export const NewTagPopUp = (props: INewTagPopUpProps) => {
  const { show, toggleShow,onNewTagAdded } = props
  const [saveData, saveActions] = useCreateTagService();
  const [error, setError] = useState("")
  const [name, setName] = useState("")

  const handlePostNewData = () => {
    if (name.length === 0) {
      setError('Tag should be not empty.');
      return
    }
    try {
      saveActions.fetch({ name: name });
    } catch (error) { }
  };

  useEffect(() => {
    if (saveData.error) {
      setError(saveData?.error?.message)
    }
    if (saveData.response) {
      setError('')
      setName('')
      if (toggleShow) {
        toggleShow()
      }
      onNewTagAdded?.()



    }
  }, [saveData])

  return (
    <div>
      <ConfirmPopUp title="New Tag" isOpen={show} toggleShow={() => toggleShow ? toggleShow() : ''} closeButton>
        <ConfirmPopUp.Body>
          <div className="h-50 d-flex justify-content-center">
            <div className="align-self-center m-w-400px">
              <Textbox
                label="New Tag"
                placeholder="Add Tag"
                required={true}
                onIonInput={(e: any) => setName(e.target.value)}
                value={name}
                error={error}
                autofocus
              />
            </div>
          </div>
        </ConfirmPopUp.Body>
        <ConfirmPopUp.ConfirmButton onClick={handlePostNewData}>Save</ConfirmPopUp.ConfirmButton>
      </ConfirmPopUp>
      {saveData.response && <SimpleDialog message="New Tag have been created." />}
    </div>
  );
};
