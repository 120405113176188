import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace IsAuthenticatedApi
{
    export const url = "/user/is_authenticated";
    export const method = "GET";
    export const defaultError = "Authentication could not be verified";
    export const operations = apiOperations(IsAuthenticatedApi);
    export const authenticate = false;
    export const scope = new class IsAuthenticatedApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        
    }
}

export const fetchIsAuthenticatedService = forwardOperation(IsAuthenticatedApi.operations, 'fetch');
export const useFetchIsAuthenticatedService = forwardOperation(IsAuthenticatedApi.operations, 'useFetch');
export const useIsAuthenticatedService = forwardOperation(IsAuthenticatedApi.operations, 'useService');