import { IonIcon, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { useResponsive } from "scripts/hooks/general";
import { close as closeIcon } from 'ionicons/icons'
import { useFetchGetBasicInfoService } from "services/user/getBasicInfo";
import { useServiceDidSucceeded } from "scripts/hooks/useService";
import { useGetCSVContentService } from "services/contacts/csvmapping/getCSVContent";
import { EasyWayCSVResult } from "views/contacts/popups/EasyWayCSVResult";


export const useCSVImportStatus = () => {
    const isResponsive = useResponsive()
    const [basicInfo] = useFetchGetBasicInfoService();
    const [import_id, setImportId] = useState<string | undefined>()
    const [csvStatus, getCSVAction] = useGetCSVContentService();
    const [csvImporting, setPending] = useState<boolean | undefined>();
    const [intervalCount, setIntervalCount] = useState(0);
    const [, setProgress] = useState(0);
    const [contactUploaded, setContactUploaded] = useState(0);
    const [totalContacts, setTotalContact] = useState(0);
    const [error, setError] = useState("");
    const [done, setDone] = useState(false)
    const [visibility, setVisibility] = useState(true)
    const [showResult, setShowResult] = useState(false)
    const intiateCSVStatusCheck = (import_id: string) => {
        setError('')
        setProgress(0)
        setContactUploaded(0)
        setVisibility(true)
        getCSVAction.fetch({ id: import_id, queue: '1' })
    }


    const close = () => {
        setPending(false)
        setProgress(0)
        setVisibility(false)
    }


    useServiceDidSucceeded(basicInfo, (response) => {
        if (response.csv?.in_queue === '1' && response.csv?.import_id) {
            intiateCSVStatusCheck(response.csv?.import_id)
            setImportId(response.csv?.import_id)
            setPending(true)

        } else {
            setImportId(undefined)
            setPending(false)
        }
    });

    useServiceDidSucceeded(csvStatus, (response) => {
        if (response?.success_count && response?.errors_count) {
            setContactUploaded(Number(response?.success_count) + Number(response?.errors_count))
        }
        if (response?.rows_count && totalContacts === 0) {
            setTotalContact(Number(response.rows_count))
        }
        let effectedRows = Number(response?.added_count) + Number(response?.errors_count);

        if (effectedRows > 0 && response?.rows_count) {
            let percentage = effectedRows / Number(response.rows_count) * 100
            setProgress(percentage);
        }

        if (csvStatus.response?.status === '5') {
            setDone(true)
        }
    })


    useEffect(() => {
        const timeout = setTimeout(() => {

            if (csvStatus.response?.status !== '5') {
                setIntervalCount(intervalCount + 1);
                if (import_id) {
                    getCSVAction.fetch({ id: import_id, queue: '1' })
                }

            } else {
                setDone(true)
            }
        }, 30000);

        return () => clearTimeout(timeout);
    }, [intervalCount]);




    const CSVStatusBlock = () => {


        return <>
            <EasyWayCSVResult show={showResult} import_id={import_id} onClose={() => setShowResult(false)} />
            {csvImporting && visibility &&
                <div className='mt-2'>
                    <div className={`bg_color_cloudy_sky_light p-3 d-inline-flex align-items-center border_radius--default position-relative ${isResponsive && `w-100`}`} >
                        <div className='position-absolute top-0  right-0px'>
                            <a className="modal-closebtn cursor--hand" onClick={() => {
                                close()
                            }}>
                                <IonIcon className="w-20px h-20px " slot="icon-only" icon={closeIcon} color="dark"></IonIcon>
                            </a>
                        </div>
                        {!error &&
                            <div className={`border_radius--default py-3 mt-2 px-3  color_auxiliary ${isResponsive ? `w-100` : `min-w-400px`} `}>
                                <div className="d-flex">
                                    {!done && <IonSpinner className='col-auto' name="bubbles"></IonSpinner>}
                                    <div className="pb-2 font_size--medium pt-1 pl-2">Importing Contacts from CSV {contactUploaded && contactUploaded > 0 ? <>({contactUploaded + `/` + totalContacts + `  have been accessed`})</> : ''}</div>
                                </div>
                                {done &&
                                    <div className="mt-1  ml-2">
                                        Importing of contacts has been completed. <a className="cursor--hand color_0738CD" onClick={() => setShowResult(true)}>View result.</a>
                                    </div>
                                }
                            </div>
                        }

                        {error &&
                            <div className={`border_radius--default py-3 mt-2 px-4  color_auxiliary ${isResponsive ? `w-100` : `min-w-400px`} `}>
                                <div className="pb-2 font_size--medium color_cc0000"> {error}</div>
                            </div>
                        }


                    </div>
                </div>
            }

        </>
    }

    return { csvImporting, CSVStatusBlock, } as const;
}