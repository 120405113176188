import { ITextboxProps } from './Textbox.def';
import './Textbox.scss';
import { IonLabel, IonInput } from '@ionic/react';
import { isString } from 'lodash-es';
import { joinWithSpace } from 'scripts/helpers/texts';
import { useState } from 'react';

export function Textbox(props: ITextboxProps): JSX.Element {
    const { containerClassName, containerId, containerStyle, error, label, className: userClassName, value: userValue, innerRef, ...ionicProps } = props;
    const errorClassName = (isString(props.error) && props.error.trim()) || props.error ? 'is-invalid' : undefined;
    const className = joinWithSpace('form-control', errorClassName, userClassName);
    const value = userValue !== undefined ? userValue : (props.defaultValue? undefined: null);
    return (
        <div id={containerId} className={containerClassName}>
            {props.label && <IonLabel>{props.label}</IonLabel>}
            <IonInput type="text" {...ionicProps} value={value} ref={innerRef} className={className}
                onIonChange={(e: any) => {
                    if (props.regex) {
                        var re = new RegExp(props.regex, "i");
                        e.target.value = e.target.value.replace(re, "")
                    }
                    props.onIonChange?.(e)
                }}
            ></IonInput>
            {
                isString(props.error) && props.error.trim() !== ''
                    ?
                    <div className='invalid-feedback'>{props.error}</div>
                    :
                    null
            }
        </div >
    );
}

export namespace Textbox {
    export const useRef = () => useState<typeof IonInput>();
}