import { useState } from 'react';
import { IAssignSourceProps } from './AssignSource.def';
import './AssignSource.scss';
import { useQuickUpdateService } from 'services/contacts/quickUpdate';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { SelectedContacts } from '../SelectedContacts';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useFetchLookupsService } from 'services/general/lookups';

export const AssignSourceDesktop = (props: IAssignSourceProps): JSX.Element => {
    const { contacts, selectToggle, onAssignment } = props
    const [open, doOpen] = useState(false)
    const [source, setSource] = useState<string>()
    const [lookupData] = useFetchLookupsService(['contact_source']);
    const [sourceAssignService, sourceAssignAction] = useQuickUpdateService()
    const Launcher = () => {
        return (
            <button role="button" id="contact-assign-source" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" aria-label="Assign Score" onClick={() => doOpen(true)}>Source</button >
        )
    }
    const doAssign = () => {
        let ids: any = [];
        contacts?.map((c: any) => {
            ids.push(c.id)
        })
        if (source) {
            sourceAssignAction.fetch({
                contact_ids: ids,
                field_name: "sources",
                field_value: source
            })
        }
    }

    useServiceDidSucceeded(sourceAssignService, () => {
        doOpen(false)
        onAssignment?.()

    })
    return (
        <>
            <Launcher />
            <DefaultPopUp title="Sources" className='custom--modal__fullheight custom--modal_source' isOpen={open} onWillDismiss={() => doOpen(false)} onIonModalWillDismiss={() => setSource(undefined)}>
                <DefaultPopUp.Body>
                    <div className=" px-5 py-2">
                        <SelectedContacts contacts={contacts} selectToggle={selectToggle} />
                    </div>
                    <div className="px-5 py-2">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Sources</div>
                        <div className="row">
                            {lookupData.response?.contact_source.items.map((item, key) => {
                                const checked = item.short_value === source
                                return (
                                    <div className="col-6 d-flex pb-2" key={key}>
                                        <div role="checkbox" className="custom-control custom-radio w-24px">
                                            <input type="radio" checked={checked} id={`source-${item.short_value}`} name={`${item.short_value}`} className="custom-control-input" onChange={() => setSource(`${item.short_value}`)} />
                                            <label className='custom-control-label stage' htmlFor={`source-${item.short_value}`}></label>
                                        </div>
                                        <div className='pl-0 font_size--medium cursor--hand' onClick={() => setSource(`${item.short_value}`)}>{item.long_value}</div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="p-2">
                        <div className='row no-gutters'>
                            <div className='col-auto min-w-120px'></div>
                            <div className='col text-center'><PrimaryButton loading={sourceAssignService.loading} disabled={sourceAssignService.loading} onClick={() => doAssign()} className="btn--primary btn w-100px">Apply</PrimaryButton></div>
                            <div className='col-auto min-w-120px text-right'><ShapelessButton onClick={() => doOpen(false)}>Cancel</ShapelessButton></div>
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>
        </>
    );

}
