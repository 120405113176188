import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities";

export namespace GetTagsApi {
    export const url = "/tags";
    export const method = "GET";
    export const defaultError = "Failed to load tags.";
    export const operations = apiOperations(GetTagsApi);
    export const scope = new class GetTagsApiScope implements IApiScope<Request, Response>
    {
        request(data: Request) { return data; }
        response(input: Record<'data', ServerResponse>): Response {
            const { data } = input;
            return data.map(({ id, contacts_count, ...other }) => ({
                id: parseInt(id), contacts_count: parseInt(contacts_count), ...other
            }));
        }
    }();

    export interface Request {

    }

    export type ServerResponse = Array<PropertiesType<ITag, string>>

    export type Response = Array<ITag>;

    export interface ITag {
        id: number;
        name: string;
        contacts_count: number;
    }
}

export const fetchGetTagsService = forwardOperation(GetTagsApi.operations, 'fetch');
export const useFetchGetTagsService = forwardOperation(GetTagsApi.operations, 'useFetch');
export const useGetTagsService = forwardOperation(GetTagsApi.operations, 'useService');