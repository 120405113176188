import { ICalendarTimelineScreenProps, ICalendarTimelineScreenState } from './CalendarTimelineScreen.def';
import './CalendarTimelineScreen.scss';
import { MiniCalendar } from "views/calendar/MiniCalendar";
import { TaskTimeline } from "views/calendar/TaskTimeline";
import { useEffect, useState } from 'react';
import { fetchGetCalendarTasksService, GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { TaskStats } from 'views/calendar/TaskStats';
import { fetchGetCalendarTaskStatsService, GetCalendarTaskStatsApi } from 'services/calendar/getCalendarTaskStats';
import { useFetchLookupsService } from 'services/general/lookups'
import { FilterPopUp } from 'views/calendar/popups/FilterPopUp';
import { getDayNumber } from 'scripts/helpers/dates';
import { Dropdown } from 'react-bootstrap';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';

import moment from 'moment';
const typesFilter = "CALL,APPT,EMAIL,FOLLW,OFFER,MEETG,INSPT,CLOSE,PREVW,OH,VOH,BOH,SHOW,VSHOW,EVENT,VOLUN,TRAIN"
var timeout: any;


export const CalendarTimelineScreenDesktop = (props: ICalendarTimelineScreenProps): JSX.Element => {

    useEffect(() => {
       // props.month !== filter.start_dt && handleFilterData({ start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), completed: undefined });
        refreshData();
        try {
           state?.filter && history.replace(history.location.pathname, { 'filter': JSON.stringify({ ...JSON.parse(state.filter), 'start_dt': moment(props.month).startOf('month'), 'end_dt': moment(props.month).endOf('month') }) });
        } catch (error) { }
    }, [props.refreshData, props.month, props.filter]);

    /*  useEffect(() => {
          getStatsData();
      }, [props.month]);*/

    useEffect(() => {
        if (props.filter?.date) {
            setTimeout(() => handleNavigateToDate(props.filter?.date || new Date()), 2000);
        }
    }, [props.filter])


    const history = useHistory<ICalendarTimelineScreenState>();
    const { state = {} } = history.location;
    const getInitialFilters = () => {
        try {
            if (state?.filter) {
                return JSON.parse(state.filter);
            }
        } catch (error) { }
        return initialFilter
    }
    const initialFilter = { completed: undefined, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), priorities: "H,M,L", types: typesFilter, status_filter: "", contact_id: props.contactId, sort_dir: "ASC", categories: "T,E" }

    const lookups = useFetchLookupsService(["task_type", "task_priority"]);
    const [filter, setFilter] = useState<GetCalendarTasksApi.Request>(getInitialFilters());
    const [tasks, setTasks] = useState<GetCalendarTasksApi.Response>();
    const [taskStats, setTaskStats] = useState<GetCalendarTaskStatsApi.Response>();
    const [calendarData, setCalendarData] = useState<{ upcoming: Array<string>, due: Array<string>, completed: Array<string>, show: boolean }>({ upcoming: [], due: [], completed: [], show: false });
    const [showLoading, setShowLoading] = useState(false);

    const onChangeSearchText = async (text: string) => {
        clearTimeout(timeout);
        if (text.length > 0) {
            timeout = setTimeout(() => getTaskData({ ...filter, keyword: text }), 500);
            setFilter({ ...filter, keyword: text });
        } else if (text.length === 0) {
            getTaskData({ ...filter, keyword: "" });
        }
    };

    const getMiniCalendarData = async (tasksData: GetCalendarTasksApi.Response) => {
        const data: { upcoming: Array<string>, due: Array<string>, completed: Array<string> } = { upcoming: [], due: [], completed: [] };
        try {
            tasksData.list.map((item) => {
                moment(item.end_dt).diff(moment()) > 0 &&
                    !item.completed && data.upcoming.push(moment(item.start_dt).format("YYYY-MM-DD")) ||
                    !item.completed && data.due.push(moment(item.start_dt).format("YYYY-MM-DD")) ||
                    item.completed && data.completed.push(moment(item.start_dt).format("YYYY-MM-DD"))
            });
            setCalendarData({ ...data, show: true });
        } catch (error) { }
    }

    const getTaskData = async (filter: GetCalendarTasksApi.Request) => {
        try {
            setShowLoading(true)
            if(filter.priorities=='none'){filter.priorities=undefined};
            if(filter.types=='none'){filter.types=undefined};
            //console.log("props.filter",filter)
			setTaskStats(undefined);
            const responses = await fetchGetCalendarTasksService({ ...filter,...props.filter })
            setTasks(responses);
            getMiniCalendarData(responses);
            getStatsData({ ...filter,...props.filter });

            setShowLoading(false)
        } catch (error) { }
    }

    const getStatsData = async (filtered?: GetCalendarTasksApi.Request) => {
        try {
            const responses = await fetchGetCalendarTaskStatsService({ contact_id: props.contactId, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(),types:filtered?.types??undefined,priorities:filtered?.priorities??undefined,...props.filter })
            setTaskStats(responses);
        } catch (error) { }
    }

    const refreshData = () => {
        setTasks(undefined)
        getTaskData({ ...filter, start_dt: moment(props.month).startOf('month').toDate(), end_dt: moment(props.month).endOf('month').toDate(), ...props.filter, date: undefined });
    }

    const prioritiesFilterData = lookups[0].response?.task_priority.items || []
    const typesFilterData = lookups[0].response?.task_type.items || []

    const handleFilterData = async (newFilter: GetCalendarTasksApi.Request) => {
        if (newFilter.date) {
            setTimeout(() => handleNavigateToDate(newFilter?.date || new Date()), 500);
        }
        
        setTasks(undefined);
        getTaskData({ ...filter, ...newFilter })

        setFilter((prev) => ({ ...prev, ...newFilter }))
        history.replace(history.location.pathname, { 'filter': JSON.stringify({ ...filter, ...newFilter }) });

    }

    const handleNavigateToDate = (date: Date) => {
        var myElement = document.getElementById(getDayNumber(date))?.offsetTop;
        props.contentRef?.current?.scrollToPoint(0, myElement! + 50, 500)
    }
    const loadMore = async () => {
        handleFilterData({ page: (tasks?.page ?? 0) + 1 })
    }

    return (<div className="row" >
        <div className="col-md-7 col-12">
            <div className="card mb-5 mb-md-0">
                <div className="border-bottom p-3">
                    <div className="row">
                        <div className="col">
                            <input onChange={(e) => onChangeSearchText(e.target.value)} type="text" className=" max-w-250 form-control input-search_form searchwithborder--field" placeholder="Search events and tasks…" />
                        </div>

                        <div className="col-auto">
                            <div className="row no-gutters">
                                <div className="col-auto">
                                    <FilterPopUp
                                        selectedValue={filter.priorities || ""}
                                        handleFilter={handleFilterData}
                                        filterName="priorities"
                                        items={prioritiesFilterData}
                                        label="Priorities"
                                    />
                                </div>
                                <div className="col-auto">
                                    <FilterPopUp
                                        selectedValue={filter.types || ""}
                                        handleFilter={handleFilterData}
                                        filterName="types"
                                        items={typesFilterData}
                                        label="Types"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="pt-4 pl-4 pr-4 pb-4">
                    <div className="pb-2 d-inline-block">
                        <Dropdown className='dropdown dropdown--custom dropdown--custom__simple dropdown mb-3'>
                            <Dropdown.Toggle className="dropdown-toggle dropdown-toggle btn btn-none pl-0" role="link" variant="none" href="#">Sort by {filter.sort_dir}</Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu agent_dropdown animate slideIn pt-3 pb-3">
                                <a className="dropdown-item cursor--hand" onClick={() => handleFilterData({ sort_dir: "DESC" })}>Sort by Descending</a>
                                <a className="dropdown-item cursor--hand" onClick={() => handleFilterData({ sort_dir: "ASC" })}>Sort by Ascending</a>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {showLoading &&
                        <div className='ml-320px'> <IonSpinner name="lines"></IonSpinner></div>}

                    <TaskTimeline tasks={tasks} refreshTasksList={refreshData} taskId={props.taskId} filter={filter}
                        returnUrl={props.contactId ? `/contacts/${props.contactId}/calendar` : `/calendar`}
                        redirect={
                            (id: string) => { history.push(props.contactId ? `/contacts/${props.contactId}/calendar/task/${id}` : '/calendar/task/' + id) }
                        }
                    />
                </div>
                {tasks?.page !== tasks?.total_pages && <div className=' text-right'>
                    <ShapelessButton color={"blue"} onClick={loadMore} > View more results</ShapelessButton></div>}

            </div>
        </div>
        <div className="col-md-5 col-12" >
            <MiniCalendar
                show={calendarData.show}
                completed={calendarData.completed}
                upcoming={calendarData.upcoming}
                due={calendarData.due}
                handleNavigateToDate={handleNavigateToDate}
                filter={filter}
                month={props.month} />

            <TaskStats
                data={taskStats}
                handleSetFilter={handleFilterData}
                filter={filter}
                initialFilter={initialFilter}
            />
        </div>
    </div>);
}
