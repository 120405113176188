import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import { PhotoUploadSection } from '../PhotoUploadSection/PhotoUploadSection';
import { IBasicInformationSectionProps, IBasicInfoData, genderType, IBasicInformationSectionForm } from './BasicInformationSection.def';
import './BasicInformationSection.scss';
import { useEffect, useState } from 'react';
import { mapValues } from 'lodash-es';
import { IImageObj } from '../PhotoUploadSection/PhotoUploadSection.def';
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { IonItem, IonText, } from '@ionic/react';
import { formatSystemDate, formatSystemDateShort } from 'scripts/helpers/dates';
import { NativeDateTimePicker } from 'views/general/NativeDateTimePicker';
import { DropDownModal } from 'views/forms/dropdowns/DropDownModal/DropDownModal.app';

//import { ShapelessButton, PrimaryButton } from 'views/forms/buttons/Button';
import { useUpdateBasicInformationService } from 'services/contacts/contactDetails/updateBasicInformation';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { DropDown } from 'views/forms/dropdowns/DropDown/DropDown.app';
import { getLongvalue, useFetchLookupsService } from 'services/general/lookups';
import { useLocation } from 'react-router';
import { IStageAddContactsProps } from '../pipeline/StageColumn/StageColumn.def';
import { parseISO } from 'date-fns';
import { dateFormat } from 'scripts/helpers/number';
import { popover_reminder_text } from './components/InfoOverlayTrigger';

export const BasicInformationSectionApp = (props: IBasicInformationSectionProps): JSX.Element => {
  const { data, form, onFormChange, onItemsChange,handleSave } = props;
  const [, notifyChange] = useValidateConsumer(props.validate, validate);
  const [localBasicInfoForm, setLocalBasicInfoForm] = useState<IBasicInformationSectionForm>(toFormField());
  const [, toggleEditMode] = useState(false);
  const [updateData, _actionUpdate] = useUpdateBasicInformationService();
  const [lookupData] = useFetchLookupsService(['income', 'contact_title', 'contact_suffix','contact_reminder_days']);
  const incomeOptions = lookupData.response?.income.items
  let location = useLocation<IStageAddContactsProps>();
  const basicInfoForm = form ?? localBasicInfoForm;
  const setBasicInfoForm:typeof setLocalBasicInfoForm = (value) => {
    if(!form) { return setLocalBasicInfoForm(value); }
    onFormChange?.(value);
  }
  useEffect(() => {
    if (data !== undefined) {
      setBasicInfoForm(toFormField());
    }
  }, [data]);

  const onChangeHandler = (e: IonInputCustomEvent<InputChangeEventDetail>): void => {
    handleChange(e.target?.name, e.detail?.value);
    notifyChange();
    handleSave?.();
  };

  const onChangeHandlerDate = (e: { value: string, name: string }): void => {
    handleChange(e.name, dateFormat(e.value));
    notifyChange();
    handleSave?.();
  };

  const onChangeHandlerSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    handleChange(e.target.name, e.target.value);
    notifyChange();
    handleSave?.();
  };

  const onChangeImage = (image: IImageObj) => {
    handleChange('image', image.imageUrl);
  };

  const handleChange = (key: string, value: unknown) => {
    const item = { value, error: '' };
    const newForm = { ...basicInfoForm, [key]: item };
    setBasicInfoForm(newForm);
    triggerChange(newForm);
    notifyChange();
  };

  const fieldCheck = (key: any, required: boolean = true, maxLength:number = 50): boolean => {
    let property = key;
    const value = property?.value?.replaceAll(" ", "");
    if (!value || value === '') {
      return !required;
    }

    if (value.length < 1) {
      return false;
    }
    if (value.length > maxLength) {
      return false;
    }
    return true;
  };

  function validate() {
    const fieldEmptyMsg = 'Field should contain min 1 max 15 Latin letters'
    const form = {
      ...basicInfoForm,
      firstName: {
        value: basicInfoForm.firstName?.value,
        error: !fieldCheck(basicInfoForm.firstName, false) ? fieldEmptyMsg : '',
      },
      lastName: {
        value: basicInfoForm.lastName?.value,
        error: !fieldCheck(basicInfoForm.lastName, false) ? fieldEmptyMsg : '',
      },
      company: { // reseting company validation
        value:basicInfoForm.company?.value,
        error: ''
      },
      jobTitle: {
        value: basicInfoForm.jobTitle?.value,
        error: !fieldCheck(basicInfoForm.jobTitle, false, 45) ? "Field should contain min 1 max 45 Latin letters." : ""
      }
    };
    setBasicInfoForm(form);

    const isValid = Object.entries(form).every(([, value]) => !value.error);

    return isValid;
  }

  function toFormField(): IBasicInformationSectionForm {
    const newData = { ...data };
    return mapValues(newData, (value) => ({ value, error: '' })) as IBasicInformationSectionForm;
  }

  function triggerChange(form: IBasicInformationSectionForm) {
    const result = mapValues(form, (item) => item?.value) as IBasicInfoData;
    onItemsChange?.(result);
  }
 {/**const updateContact = () => {
    if (validate()) {
      actionUpdate.fetch({
        contact_id: initialData?.id?.toString() ?? "0",
        first_name: basicInfoForm.firstName?.value!,
        last_name: basicInfoForm.lastName?.value!,
        middle_name: basicInfoForm.middleName?.value,
        gender: basicInfoForm.gender?.value,
        birthday: basicInfoForm.dateOfBirth?.value,
        known_since: basicInfoForm.knownSince?.value,
        income: basicInfoForm.income?.value,
        job_title: basicInfoForm.jobTitle?.value,
        company: basicInfoForm.company?.value,
        anniversary_date: basicInfoForm.weddingAniversary?.value,
        home_anniversary_date: basicInfoForm.homeAniversary?.value,
        photo_url: basicInfoForm.image?.value
      });
    }
  } */}
  useEffect(() => {
    if (location.state) {
      if (location.state.first_name || location.state.last_name) {
        const form = {
          ...basicInfoForm,
          firstName: {
            value: location.state.first_name ? location.state.first_name : '',
            error: '',
          },
          lastName: {
            value: location.state.last_name ? location.state.last_name : '',
            error: '',
          }
        };
        setBasicInfoForm(form);
      }
    }
  }, [location])
  const isSelected=(value:string)=>{return basicInfoForm.reminder_days_after?.value?.includes(value)}

  const removeReminder=(value:string)=>{ 
      handleChange('reminder_days_after',basicInfoForm.reminder_days_after?.value?.filter((a)=> value&&a!==value&&value!=="NaN"&&value!=="")); 
      handleSave?.();
  }

  const addReminder=(value:string)=>{
      basicInfoForm.reminder_days_after?.value?.push(value)
      handleChange('reminder_days_after',basicInfoForm.reminder_days_after?.value??[value] )//(basicInfoForm.reminder_days_after?.value?.push(value)) )
      handleSave?.();
  }

  const reminderValue=()=> {
      const value=basicInfoForm?.reminder_days_after?.value?.filter((a)=> a!=="")
      const contact_reminder_days=lookupData.response?.contact_reminder_days||{items:[],caption:""}
      console.log("Reminder Value",value)
      if(value){
        return value.length >0?(getLongvalue(contact_reminder_days,value[0])||"")+(value.length>1?" +"+(value.length-1):""):""
      }
      return ''
  }

  useServiceDidSucceeded(updateData, () => { displayMessage('The contact has been successfully added', { onClose: onSuccess }); onSuccess() })
  const onSuccess = () => {
    toggleEditMode(false);
    props.onSuccess?.()
  }
  if (data && !props.isEdit) { return <ViewForm isEdit={props.isEdit} {...props} />; }
  const imageValue = basicInfoForm.image?.value;
  return (<>
    {!data && <div className="pb-5 pt-3 text-center form_sec">
      <div className=" ion-padding-bottom">
        <div className="photophoto__image ml-auto mr-auto">
          <PhotoUploadSection imageUrl={imageValue} onItemsChange={onChangeImage} />
        </div>
      </div>
    </div>}
    <div className='pt-4'>
      <div className={data ? 'card pt-4 mb-4 ml-3 mr-3' : ""}>
        <div className="mb-3 secpadding_left">
          <div className="row pb-3">
            <div className="col align-self-center">
              <IonText className="sec_title font-weight_semi-bold">Basic Information</IonText></div>
            {/**{initialData && <div className="col-auto text-right mr-3">
              <ShapelessButton onClick={() => toggleEditMode(false)}>Cancel</ShapelessButton>
    <PrimaryButton onClick={updateContact}>Save</PrimaryButton></div>}*/}
          </div>
        </div>
        <div className="form_sec">
          <Textbox
          autofocus
            value={basicInfoForm.firstName?.value}
            error={basicInfoForm?.firstName?.error}
            placeholder="First Name"
            name="firstName"
            maxlength={50}
            id="firstName"
            aria-describedby="firstName"
            onIonChange={onChangeHandler}
            //onBlur={handleSave}
          />
          <Textbox
          //autofocus={true}
            value={basicInfoForm.middleName?.value}
            error={basicInfoForm?.middleName?.error}
            placeholder="Middle Name"
            name="middleName"
            maxlength={50}
            id="middleName"
            aria-describedby="middleName"
            onIonChange={onChangeHandler}
            //onBlur={handleSave}
          />
          <Textbox
          //autofocus={true}
            value={basicInfoForm.lastName?.value}
            error={basicInfoForm?.lastName?.error}
            placeholder="Last Name"
            name="lastName"
            maxlength={50}
            id="lastName"
            aria-describedby="lastName"
            onIonChange={onChangeHandler}
            //onBlur={handleSave}
          />
          <DropDown name='title' hideIcon label='Title' value={basicInfoForm?.title?.value} className=" custom-select_large mr-3" onChange={onChangeHandlerSelect}>
            {lookupData.response?.contact_title?.items?.map((value, key) => (
              <option key={key} value={value.short_value}>{value.long_value}</option>
            ))}
          </DropDown>
          <DropDown name='suffix' hideIcon label='Suffix' value={basicInfoForm?.suffix?.value} className=" custom-select_large mr-3" onChange={onChangeHandlerSelect}>
            {lookupData.response?.contact_suffix?.items?.map((value, key) => (
              <option key={key} value={value.short_value}>{value.long_value}</option>
            ))}
          </DropDown>
          <DropDown /*onBlur={handleSave}*/ id="gender" name='gender' hideIcon label='Gender' value={basicInfoForm?.gender?.value} className=" custom-select_large mr-3" onChange={onChangeHandlerSelect}>
            {Object.keys(genderType).map(key => (
              key.length > 1 ? <option key={key} value={key}>{key}</option> : ''
            ))}
          </DropDown>
          <IonItem  className="date__input">
            <div className="date__input__label w-50pr">Date of Birthday</div>

            {(!data || basicInfoForm.dateOfBirth) && <NativeDateTimePicker
                name="dateOfBirth"
              label=""
              value={basicInfoForm.dateOfBirth?.value ? formatSystemDate(parseISO(basicInfoForm.dateOfBirth?.value)) : ''}
              onChange={(e: any) => onChangeHandlerDate({ name: "dateOfBirth", value: e })}
              customClass='min-w-150px w-150px mr-0'
              maxDate={formatSystemDate(new Date())}
              minDate='1940-01-01'
            />}
          </IonItem>
          <IonItem className="date__input">
            <div className="date__input__label  w-50pr">Known Since</div>
            {(!data || basicInfoForm.knownSince) &&
              <NativeDateTimePicker
                label=""
                name="knownSince"
                value={basicInfoForm.knownSince?.value ? formatSystemDate(parseISO(basicInfoForm.knownSince.value)) : undefined}
                onChange={(e: any) => onChangeHandlerDate({ name: "knownSince", value: e })}
                customClass='min-w-150px w-150px mr-0'
                maxDate={formatSystemDate(new Date())}
                minDate='1940-01-01'
              />}
          </IonItem>
          <IonItem className="date__input">
            <div className="date__input__label  w-50pr">Home Anniversary</div>
            {(!data || basicInfoForm.homeAniversary) &&
              <NativeDateTimePicker
                label=""
                name="homeAniversary"
                value={basicInfoForm.homeAniversary?.value ? formatSystemDate(parseISO(basicInfoForm.homeAniversary.value)) : undefined}
                onChange={(e: any) => onChangeHandlerDate({ name: "homeAniversary", value: e })}
                customClass='min-w-150px w-150px mr-0 '
                maxDate={formatSystemDate(new Date())}
                minDate='1940-01-01'
              />}
          </IonItem>
          <IonItem className="date__input">
            <div className="date__input__label  w-50pr">Wedding Anniversary</div>
            {(!data || basicInfoForm.weddingAniversary) &&
              <NativeDateTimePicker
                label=""
                name="weddingAniversary"
                value={basicInfoForm.weddingAniversary?.value ? formatSystemDate(parseISO(basicInfoForm.weddingAniversary.value)) : undefined}
                onChange={(e: any) => onChangeHandlerDate({ name: "weddingAniversary", value: e })}
                customClass='min-w-150px w-150px mr-0'
                minDate='1940-01-01'
                maxDate={formatSystemDate(new Date())}
              />}
          </IonItem>
          <DropDown /*onBlur={handleSave}*/ id="income" name='income' label='Income' hideIcon value={basicInfoForm?.income?.value} className=" custom-select_large mr-3" onChange={onChangeHandlerSelect}>
            {incomeOptions?.map((value, key) => (
              value.short_value.length > 1 ? <option key={key} value={value.short_value}>{value.long_value}</option> : ''
            ))}
          </DropDown>
          <div className="">
            <Textbox /*onBlur={handleSave}*/ maxlength={45} type="text" name="jobTitle" placeholder="Job Title" value={basicInfoForm.jobTitle?.value} error={basicInfoForm?.jobTitle?.error} onIonChange={onChangeHandler} />
            <Textbox /*onBlur={handleSave}*/ maxlength={45} type="text" name="company" placeholder="Company" value={basicInfoForm.company?.value} error={basicInfoForm?.company?.error} onIonChange={onChangeHandler} />
            <DropDownModal 
                  name="reminder_days_after" 
                  label='Reminder'
                  value={reminderValue()}
                // handleSave={()=>setFormData((prevState)=>({...prevState,reminder_minutes_prior:reminder}))} 
                  resetSelectedValue={()=>handleChange('reminder_days_after',[])} OverlayButton={popover_reminder_text}>
                  {lookupData.response?.contact_reminder_days?.items.map((item,ind)=>
                      <div key={ind} className="choosable__stage">
                          <input type="checkbox" className="custom-control-input" checked={isSelected(item.short_value)} name="reminder_days_after" id={"A"+item.short_value} value={item.short_value} 
                          onChange={()=>isSelected(item.short_value)?removeReminder(item.short_value):addReminder(item.short_value) }/>
                          <label className="custom-control-label" htmlFor={"A"+item.short_value}>
                              <div>{item.long_value}</div>
                              <div className="check"></div>
                          </label>
                  </div>)}
          </DropDownModal>
          </div>
        </div>
      </div>
      </div>
  </>
  );
};

const ViewForm = (props: IBasicInformationSectionProps) => {
  const data = props.data || {}
  return (<div className='card mt-4 mb-4 p-4 ml-3 mr-3'>
    <div className="row pb-3">
      <div className="col align-self-center">
        <h3 tabIndex={0}>Basic Information</h3>
      </div>
     {/**  <div className="col-auto text-right"><button className="btn btn--white" onClick={() => props.toggleEdit?.(true)} type="button">Edit</button></div>*/}
    </div>
    <div className="">
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">NAME</div>
          {`${data.firstName} ${data.middleName ?? ''} ${data.lastName ?? ''}`}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">GENDER</div>
          {data.gender || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">DATE OF BIRTHDAY</div>
          {!!data.dateOfBirth&&formatSystemDateShort(parseISO(data.dateOfBirth)) || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">KNOWN SINCE</div>
          {!!data.knownSince&&formatSystemDateShort(parseISO(data.knownSince)) || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">HOME ANNIVERSARY</div>
          {!!data.homeAniversary&&formatSystemDateShort(parseISO(data.homeAniversary)) || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">WEDDING ANNIVERSARY</div>
          {!!data.weddingAniversary&&formatSystemDateShort(parseISO(data.weddingAniversary)) || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">INCOME</div>
          {data.income || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">JOB TITLE</div>
          {data.jobTitle || "N/A"}
        </div>
      </div>
      <div className="">
        <div className="border-bottom pb-3 mb-3 font_size--medium">
          <div className="value_headings">Company</div>
          {data.company || "N/A"}
        </div>
      </div>
    </div>
  </div>)
}
