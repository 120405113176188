//  import { IExternalEmbedContentProps } from './ExternalEmbedContent.def';
//  import { IonPage, IonContent } from '@ionic/react';
import { Auth } from 'scripts/security/AuthService';
import { IExternalEmbedContentIframeProps, IMessageEvent, IExternalEmbedContentProps, IExternalEmbedContentViewProps, eExternalEmbedContentSource, IDefaultMessageEvent, IDefaultMessageMap } from './ExternalEmbedContent.def';
import './ExternalEmbedContent.scss';
import { loadApiUrl, loadHeaders } from 'scripts/http/serviceFetch';
import { RedirectApi } from 'services/auth/redirect';
import { useEffect, useRef, useState } from 'react';
import { LoaderScreen } from 'views/screens/LoaderScreen';
import { joinWithSpace } from 'scripts/helpers/texts';
import { buildCMSUrl, buildMopUrl, buildWebsiteUrl } from 'scripts/application/integrations';
import { mopHost, websiteHost } from 'scripts/application/settings';
import { history } from 'scripts/router/history';
import { useLocation } from 'react-router';
import { addQueryStringToUrl } from 'scripts/helpers/urls';
import { isString } from 'lodash-es';
import { cmsHost } from 'scripts/application/settings';

// export const ExternalEmbedContent = (_props: IExternalEmbedContentProps): JSX.Element =>
// {
//     return (
//         <IonPage>
//             <IonContent>
//                 <div className="container pt-md-5 mt-md-5 pt-5 bg_color_snow_white">
//                     <div className="cards p-0 ml-auto mr-auto max-w-900px">
//                         <div className="border-bottom pb-md-4 pb-3 pt-md-4 pt-3 pl-md-5 pl-0 pr-md-5 pr-0">
//                             <h2 className='mb-0'>Coming Soon.</h2>
//                         </div>
//                         <div className="p-md-5 p-0 ml-auto mr-auto text-center">
//                             <p className='font_size--large_extra font_weight--bold'>This feature is under development and it will be available soon.</p>
//                         </div>
//                     </div>
//                 </div>
//             </IonContent>
//         </IonPage>
//     );
// }

export function ExternalEmbedContent(props: IExternalEmbedContentProps): JSX.Element
{
    const { useCookies } = Auth.loginProvider;
    const { url:baseUrl, source, enabledInnerRoutes, initialPath, defaultTitle, queryString, ...otherProps } = props;
    const [key, setKey] = useState(1);
    const location = useLocation<{ fromEmbedContent?:boolean }>();
    useMessageEvents(props);
    
    useEffect(() => {
        if(!location.state?.fromEmbedContent) { setKey(key + 1);}
    }, [location.state?.fromEmbedContent === true]);

    const url = addQueryStringToUrl(initialPath? `${baseUrl}/${initialPath}` : baseUrl, queryString);
    const fullUrl = loadUrl(url, source);
    return useCookies?
        <IFrame key={key} url={fullUrl} title={props.iframeTitle} />:
        <CookieLess key={key} {...otherProps} url={fullUrl} />;
}

const CookieLess = (props: IExternalEmbedContentViewProps): JSX.Element =>
{
    const [token, setToken] = useState<string>('');
    const formRef = useRef<HTMLFormElement>(null);
    const url = props.url;

    useEffect(() => {
        let active = true;
        Promise.all([ Auth.userValidToken, loadHeaders(RedirectApi.url) ]).then(([ token, headers ]): void => {
            if(!active) { return; }

            setToken(token);
            addInput(formRef, 'har_key', token);
            addInputs(formRef, headers);
            formRef.current?.submit();
        });

        return () => { active = false; }
    }, []);

    return (<>
        <form method="post" target="embed" action={loadApiUrl(RedirectApi.url)} ref={formRef}>
            <input type="hidden" name="redirect_url" value={url} />
        </form>
        {!token && <LoaderScreen />}
        <IFrame title={props.iframeTitle} visible={!!token} />
    </>);
}

const IFrame = (props:IExternalEmbedContentIframeProps): JSX.Element =>
{
    // Keeping iframe static
    const [src] = useState(props.url);
    const [title] = useState(props.title);
    const className = joinWithSpace('w-100 h-100 border-0', props.visible === false && 'invisible');
    return <iframe name="embed" className={className} src={src} title={title}></iframe>;
}

function useMessageEvents(props: IExternalEmbedContentProps)
{
    const { url, source, enabledInnerRoutes, defaultTitle, initialPath = '', titleSeparator = ' - ' } = props;
    const location = useLocation();
    const path = useRef('/' + initialPath);
    const routeUrl = location.pathname;
    const baseUrl = extractBaseUrl(routeUrl, initialPath);
    const baseTitle = document.title;

    // Putting initialTitle if applied
    useEffect(() => {
        if(!defaultTitle) { return; }
        document.title = defaultTitle + titleSeparator + baseTitle;
        return () => { document.title = baseTitle; };
    }, []);

    const extractPath = function extractPath(messageUrl:string): string
    {
        if(messageUrl.startsWith(url)) { return messageUrl.substring(url.length); }
        const fullUrl = url + path.current;
        if(messageUrl.startsWith(fullUrl)) { return messageUrl.substring(fullUrl.length); }
        return messageUrl;
    }

    const handlePageLoad = function handlePageLoad(e:IDefaultMessageEvent<'com.har.pageLoad'>)
    {
        if(!enabledInnerRoutes || !e.data.url) { return; }

        // Updating url
        const currentPath = path.current;
        path.current = extractPath(e.data.url);
        const url = (baseUrl + path.current).replaceAll('//', '/').replaceAll(/(\?|&)(appview=1)/g, '');
        if(path.current !== currentPath) { history.replace(url, { fromEmbedContent:true }); }
        //window.history.replaceState(history.location.state, '', `/services/emailmarketing/${path.current}`);        

        // Updating title
        const title = e.data.title && isString(e.data.title)? e.data.title?.trim() || defaultTitle: defaultTitle;
        if(title) { document.title = title + titleSeparator + baseTitle; }
        else { document.title = baseTitle; }
    }

    const handleLocationPush = (e:IDefaultMessageEvent<'com.har.locationPush'>) =>
    {
        if(!e.data.url) { return; }
        const isAbsolute = e.data.url.startsWith('http');
        //const targetHistory = isAbsolute? browserHistory: history;
        //if(e.data.url.startsWith('http')) { return console.error('Absolute paths are not allowed.'); }

        let { url } = e.data;
        const { redirect, redirectKey = 'redirect' } = e.data;
        if(redirect)
        {
            const isRedirectAbsolute = redirect.startsWith('http');
            const redirectUrl = isAbsolute && !isRedirectAbsolute? window.location.origin + redirect: redirect;
            url = addQueryStringToUrl(url, { [redirectKey]:redirectUrl });
        }

        if(isAbsolute) { window.open(url, '_self'); }
        else { history.push(url); }
    }

    const parseEvents = function parseEvents(e:IMessageEvent)
    {
        handleEvent(e, 'com.har.pageLoad', handlePageLoad);
        handleEvent(e, 'com.har.locationPush', handleLocationPush);
    }

    const handleEvent = function handleEvent<K extends keyof IDefaultMessageMap>(e:IMessageEvent, event:K, handler:(e:IDefaultMessageEvent<K>) => void)
    {
        if(e.data.event === event) { handler(e as IDefaultMessageEvent<K>); }
    }

    const receiveMessage = function receiveMessage(e:IMessageEvent)
    {
        if(!validateOrigin(url, e.origin, source)) { return; }
        if(!e.data || !e.data.event) { return; }
        parseEvents(e);
        props.onMessage?.(e);
    }

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        return () => { window.removeEventListener("message", receiveMessage); }
    }, [enabledInnerRoutes]);
}

function loadUrl(path:string, source?:eExternalEmbedContentSource): string
{
    switch(source)
    {
        case eExternalEmbedContentSource.Website: return buildWebsiteUrl(path, 'appview=1', true);
        case eExternalEmbedContentSource.MemberOnlyArea: return buildMopUrl(path, 'appview=1', true);
        case eExternalEmbedContentSource.CMS: return buildCMSUrl(path, 'appview=1', true);
        default: return path;
    }   
}

function extractBaseUrl(url:string, path?:string): string
{
    if(!path?.trim() || url.lastIndexOf(path) === -1) { return url; }
    return url.slice(0, url.lastIndexOf(path));
}

function addInputs(form:React.RefObject<HTMLFormElement>, items:Record<string, string | number>): void
{
    for(let item of Object.keys(items))
    {
        addInput(form, item, items[item]?.toString() ?? '');
    }
}

function addInput(form:React.RefObject<HTMLFormElement>, name:string, value:string): void
{
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    form.current?.appendChild(input);
}

function validateOrigin(url:string, origin:string, source?:eExternalEmbedContentSource): boolean
{
    switch(source)
    {
        case eExternalEmbedContentSource.Website: return origin === websiteHost;
        case eExternalEmbedContentSource.MemberOnlyArea: return origin === mopHost;
        case eExternalEmbedContentSource.CMS: return origin === cmsHost;
    }

    return new URL(url).origin === origin;
}