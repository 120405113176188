import { IonRouterLink } from '@ionic/react';
import { StageColumn } from 'views/contacts/pipeline/StageColumn';
import { ContactManageTemplate } from 'views/templates/ContactManageTemplate';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { IContactPipelineScreenProps, IPipelineMoveCloseConfirmation } from './ContactPipelineScreen.def';
import './ContactPipelineScreen.scss';
import { ManageTagsPopUp } from 'views/contacts/popups/ManageTagsPopUp';
import { ImportContactsPopUp } from 'views/contacts/popups/ImportContactsPopUp';
import { useState } from 'react';
import { PipelineRemovePopUp } from './states/pipelineRemovePopUp';
import { ContactDetailPopover } from 'views/contacts/popovers/ContactDetailPopover';
import { useHistory } from 'react-router';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { ContactsFilterPopUp } from 'views/contacts/popups/ContactsFilterPopUp';
import { ModuleInfo } from 'views/general/ModuleInfo';
import { useMatrix } from '../hooks/useMatrix';
import { useCSVImportStatus } from '../hooks/useCSVImportStatus';
import { WatchNLearn } from 'views/contacts/ContactsTable/ContactsTable.desktop';

export const ContactPipelineScreenDesktop = (props: IContactPipelineScreenProps): JSX.Element => {
    const { columns, contacts, filters, filterCount, loading, loadMore, updateAndSort, clearFilter, addContact, removeContact, applyFilter, filterhandler } = props
    const [showManageTag, setShowManageTag] = useState(false);
    const [showImportPopUp, setShowImportPopUp] = useState(false);
    const [showDeleteWarn, setShowDeleteWarn] = useState(false)
    const [showMoveCloseWarn, setShowMoveCloseWarn] = useState(false)
    const [dropObject, setDropObject] = useState<any>()
    const [contactId, setContactId] = useState(0)
    const [contactPipeline, setContactPipeline] = useState(0)
    const [contactName, setContactName] = useState("")
    const [showDetailPopOver, setShowDetailPopOver] = useState(false)
    const [openPopover, setOpenPopover] = useState(0)
    const { matrixContactStatus, matrixContactImportStatusId, MatrixImportMessage, IntiateMatrix } = useMatrix()
    const { csvImporting, CSVStatusBlock } = useCSVImportStatus()

    let history = useHistory();

    const downloadContact = (param: any) => {
        if (props.downloadContact) {
            const result = props.downloadContact(param)
            return result;
        }
    }


    const Header = (): JSX.Element => {
        return (
            <>
                <div className="page_title_container">
                    <div className="row">
                        <div className="col align-self-center">
                            <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Contacts</h2>
                        </div>
                        <div className="col-auto justify-content-end d-none d-md-flex">
                            <ManageTagsPopUp show={showManageTag} toggleShow={() => setShowManageTag(showManageTag ? false : true)} />
                            <button role="button" className="btn btn--white btn--white__slate btn--inlineicon mr-2" onClick={() => downloadContact([])}><img src="https://content.harstatic.com/media/icons/up_arrow_auxialary.svg" /><span>Export All</span></button>
                            <ImportContactsPopUp show={showImportPopUp} toggleShow={() => setShowImportPopUp(showImportPopUp ? false : true)} matrixStatusId={matrixContactImportStatusId} onMatrixInit={IntiateMatrix} />
                            <IonRouterLink routerLink="/contacts/create">
                                <button role="button" className="btn btn--prominent__v2 btn--small btn--inlineicon ml-2 justify-content-end"><img className="justify-content-end mr-1 opacity-64pr w-12px" src="https://content.harstatic.com/media/icons/add_white_2.svg" /><span>New Contact</span></button>
                            </IonRouterLink>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <nav id="ContactTabs" className="nav nav-tabs nav-tabs--simple">
                            <a title="Contact Management" onClick={() => history.push('/contacts', { 'filters': filters })} className="nav-item nav-link cursor_pointer" >
                                Contact Management
                            </a>
                            <a title="Contact Management" onClick={() => history.push('/contacts/pipeline', { 'filters': filters })} className="nav-item  nav-link active cursor_pointer" >
                                Contact Pipeline
                            </a>
                        </nav>


                    </div>
                    <div className='col-auto ml-auto pr-0'>
                        <ContactsFilterPopUp
                            filters={filters ? filters : {}}
                            onChange={filterhandler}
                            onClear={clearFilter}
                            applyFilter={applyFilter}
                            filtersCount={filterCount} />


                    </div>
                    <div className='col-auto ml-auto'>
                        <WatchNLearn />
                    </div>
                </div>
                <div className="mt-3">
                    <ModuleInfo type="pipeline" />
                </div>
                {matrixContactStatus &&
                    <MatrixImportMessage />
                }
                {csvImporting &&
                    <CSVStatusBlock />
                }
            </>
        );
    }
    const onRemoveContact = (id: number, pipeline: number, name: string) => {
        setContactId(id)
        setContactPipeline(pipeline)
        setContactName(name)

        setShowDetailPopOver(true)
    }



    const dragEnd = (result: any) => {
        if (result.destination.droppableId === '70') {
            //find contact in the pipeline
            let contact = contacts?.filter((c) => c.pipeline === Number(result.source.droppableId))[0]?.list?.filter((li) => li.id === result.draggableId)[0]
            //check contact have deals which are not closed.
            if (contact?.deal_stages && contact?.deal_stages?.find((deal: any) => deal.name !== 'Closed')) {
                setDropObject(result)
                setShowMoveCloseWarn(true)
                return
            }
        }
        updateAndSort?.(result)
    }

    return (
        <>
            <MoveCloseConfirmation
                show={showMoveCloseWarn}
                close={() => setShowMoveCloseWarn(false)}
                moveContact={() => {
                    updateAndSort?.(dropObject);
                    setShowMoveCloseWarn(false);
                    setDropObject({})
                }}
            />
            <PipelineRemovePopUp
                show={showDeleteWarn}
                name={contactName}
                toggleShow={() => setShowDeleteWarn(false)}
                removeContact={() => {
                    if (removeContact) {
                        removeContact(contactId, contactPipeline, contactName)
                    }
                    setShowDeleteWarn(false)
                }} />

            <ContactDetailPopover
                show={showDetailPopOver}
                toggleShow={() => setShowDetailPopOver(false)}
                id={contactId}
                isPipeline={true}
                viewProfile={() => {
                    setShowDetailPopOver(false);
                    setTimeout(() => {
                        history.push(`/contacts/${contactId}/overview`, { referrer: '/contacts/pipeline' })
                    }, 400)
                }}
                removeContact={() => {
                    setShowDetailPopOver(false);
                    setShowDeleteWarn(true)
                }}
            />

            <ContactManageTemplate header={Header()} fluidTemplate={true} onBodyClick={() => openPopover ? setOpenPopover(0) : ''}>
                <div className="pipeline_list fancy_scroll mt-3">
                    <DragDropContext onDragEnd={dragEnd}>
                        {columns?.map((column, index: number) =>
                            <Droppable key={index} droppableId={`${column.pipeline}`} type="contacts">
                                {(provided, _snapshot) => (
                                    <div
                                        className='pipeline_col__wrapper'
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <StageColumn
                                            {...column}
                                            contacts={contacts?.filter((contact) => contact.pipeline === column.pipeline)[0]}
                                            openPopover={openPopover}
                                            loading={loading}
                                            loadMore={loadMore}
                                            addContact={addContact}
                                            removeContact={onRemoveContact}
                                            setOpenPopover={setOpenPopover}

                                        />
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </DragDropContext>

                </div>
            </ContactManageTemplate >
        </>
    );
}


export const MoveCloseConfirmation = (props: IPipelineMoveCloseConfirmation) => {
    const { show, close, moveContact } = props
    return (
        <DefaultPopUp isOpen={show} title="Move To Close?" className='modal--size--small' >
            <DefaultPopUp.Body>
                <div className="p-5 bg_color_snow_white">
                    <div className="font_size--medium font_weight--semi_bold m-auto text-center algin-self-center color_auxiliary mb-4">
                        Are you sure ?
                    </div>
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="ml-4 mr-4">
                    <div className="row pt-2 mb-2 m-auto">
                        <div className="col">
                            <div className="text-center">
                                <PrimaryButton onClick={() => {
                                    moveContact?.()
                                }} >Yes</PrimaryButton>
                                <ShapelessButton onClick={() => {
                                    close?.()
                                }} >No</ShapelessButton>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>


        </DefaultPopUp>
    );
}