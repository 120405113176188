import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { IMuiDatePickerProps } from './MuiDatePicker.def';
import './MuiDatePicker.scss';
import styled from '@emotion/styled';
import { IonLabel } from '@ionic/react';
import { addYears, isValid, subYears, set, isWithinInterval } from 'date-fns';


export const MuiDatePicker = (props: IMuiDatePickerProps): JSX.Element => {
    const { placeholder, value, className, label, maxDate, minDate, error, disabled, changeOnlyOnValidDate, onChange } = props
    const Styled = styled(PickersDay)(() => ({
        fontSize: '14px',
    }));
    const maxDefaultDate = addYears(new Date(), 10)
    const minDefaultDate = subYears(new Date(), 2)

    // const changeOnValidDate = (date: any):boolean => { return isValid(date)  }

    const inRange = (date: any): boolean => {
        const start = set(minDate ? minDate : minDefaultDate, { hours: 0, minutes: 0, seconds: 0 })
        const end = set(maxDate ? maxDate : maxDefaultDate, { hours: 23, minutes: 59, seconds: 59 })
        return isWithinInterval(date, { start, end })
    }
    return (
        <div className={`datewraper ${className && className}`}>
            {label &&
                <IonLabel> {label}</IonLabel>
            }
            <DatePicker label={placeholder ? placeholder : ''}
                formatDensity='spacious'
                defaultValue={value}
                value={value}

                slots={{
                    day: Styled,
                }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        placeholder: label ? label : '',
                        sx: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: error ? '#dc3545' : '#AFB8D9',
                                    borderWidth: '1px',
                                    padding: '5px auto'
                                },

                                '&.Mui-focused fieldset': {
                                    borderColor: '#AFB8D9',
                                    borderWidth: '1px',
                                },
                            },
                            '& .MuiInputBase-input': {
                                padding: `12.5px 14px`,
                            }
                        }
                    },
                    calendarHeader: {
                        sx: {

                            '.MuiPickersCalendarHeader-label': props.hideYearSelection ?
                                {
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '57px'
                                }
                                :
                                {
                                    fontSize: '18px',

                                }

                        },
                    }, actionBar: props.clearOption ? {
                        actions: ['clear'],
                    } : {},




                }}
                disabled={disabled}
                onChange={(date: any) => {
                    if (changeOnlyOnValidDate) {
                        if (isValid(date) && inRange(date)) {
                            onChange?.(date)
                        }
                    } else {
                        onChange?.(date)
                    }
                }}
                maxDate={maxDate ? maxDate : maxDefaultDate}
                minDate={minDate ? minDate : minDefaultDate}
                views={props.showMonthAndDay ? ['month', 'day'] : undefined}

                shouldDisableYear={props.hideYearSelection ? (year) => year === Number(new Date().getFullYear()) : undefined}


            />
            {error &&
                <div className='invalid-feedback d-flex'>{error}</div>
            }
        </div >
    );
}
