import { IContactTitleProps } from './ContactTitle.def';
import './ContactTitle.scss';

export const ContactTitle = (props: IContactTitleProps): JSX.Element => {
    return (
        <>
            {Title(props)}
        </>

    );
}

export const Title = (props: IContactTitleProps): string => {
    const { contact } = props;
    const middle_name = !!contact.middle_name ? ' ' + contact.middle_name + ' ' : ' ';
    const last_name = !!contact.last_name ? ' ' + contact.last_name : '';
    const first_name  = !!contact.first_name ? contact.first_name : ''; 
    const title = !!contact.title ? contact.title+' ' : '';
    const suffix = !!contact.name_suffix ? ' ' + contact.name_suffix : '';

    return title + (first_name || last_name )?
    title + first_name + middle_name + last_name + suffix
        : contact.primary_email ?
            contact.primary_email
            :
            contact.email ?
                contact.email
                :
                contact.primary_phone ?
                    contact.primary_phone
                    :
                    contact.phone ?
                        contact.phone
                        :
                        ''
}




