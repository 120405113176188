import { FormLabel } from "react-bootstrap"

export function Loader():JSX.Element {
  return (
    <div className="card p-3 mb-5">
    <div className='p-3'>
        <h3 tabIndex={0}>Contact Insight</h3>

        <div className="pt-4">
            <FormLabel>Score</FormLabel>
            <div className="row small-gutters">

                <div className="col mb-2"><div className="loading__square"></div></div>
                <div className="col mb-2"><div className="loading__square"></div></div>
                <div className="col mb-2"><div className="loading__square"></div></div>
                <div className="col mb-2"><div className="loading__square"></div></div>
            </div>
        </div>

        <div className="mb-4">
            <FormLabel>Stage on Pipeline</FormLabel>
            <div className="loading__textbox"></div>
        </div>

        <div className="mb-4">
            <FormLabel>Sources</FormLabel>
            <div className="loading__textbox"></div>
        </div>


        <div className="mb-4">
            <FormLabel>Tags</FormLabel>
            <div className="loading__textbox"></div>
        </div>
    </div>

</div>
  )
}





