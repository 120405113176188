import { IAssignSourceProps } from './AssignSource.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AssignSourceApp } from './AssignSource.app';
import { AssignSourceDesktop } from './AssignSource.desktop';

export const AssignSource = (props: IAssignSourceProps): JSX.Element =>
{
    const AssignSourcePlatform = useResponsiveChoose(AssignSourceDesktop, AssignSourceApp);
    return (
        <AssignSourcePlatform {...props} />
    );
}
