import { isEqual, range } from 'lodash-es';
import { useEffect, useState } from 'react';
import { joinWithSpace } from 'scripts/helpers/texts';
import { usePrevious } from 'scripts/hooks/general';
import { useFetchLookupsService } from 'services/general/lookups';
import { IContactScore, IFormOptionsProps, IOptionProps, IScoreOptionsMultiProps, IScoreOptionsSingleProps } from './ScoreOptions.def';
import './ScoreOptions.scss';

export function ScoreOptionsApp(props: IScoreOptionsSingleProps): JSX.Element {
    const { initialSelected, onScoreChange, ...formProps } = props;
    const [selected, select] = useState(initialSelected);
    const selections = new Set(selected ? [selected] : []);


    useEffect(() => { select(initialSelected) }, [initialSelected]);

    const onSelectScore = (score: IContactScore) => {
        const value = score.short_value;
        const current = value !== selected ? value : undefined;
        select(current);
        onScoreChange?.(current);
    };

    return <Form type='radio' {...formProps} selections={selections} onSelectScore={onSelectScore} />;
}

export namespace ScoreOptionsApp {
    export function Multi(props: IScoreOptionsMultiProps) {
        const { initialSelections, onScoresChange, isFilter, ...formProps } = props;
        const [selections, select] = useState(() => new Set(initialSelections));
        const previousInitialSelections = usePrevious(initialSelections);
        //const selections = Array.from(selectionsSet.values());

        useEffect(() => {
            const initialSet = new Set(initialSelections);
            const previousSet = new Set(previousInitialSelections);
            if (!isEqual(initialSet, previousSet)) { select(initialSet); }
        }, [initialSelections, previousInitialSelections]);

        const onSelectScore = (score: IContactScore) => {
            const value = score.short_value;
            selections[selections.has(value) ? 'delete' : 'add'](value);
            /*if (score.is_filter) {
                const list = Array.from([]);
                const currentSet = new Set([]);
                select(currentSet);
                onScoresChange?.(list);
            } else {*/
            const list = Array.from(selections.values());
            const currentSet = new Set(list);
            select(currentSet);
            onScoresChange?.(list);
            //}
        };

        return <Form type='checkbox' isFilter={isFilter} {...formProps} selections={selections} onSelectScore={onSelectScore} />;
    }
}

export const Form = (props: IFormOptionsProps) => {
    const { type, label, selections, dropDown, isFilter, loading, onSelectScore, ...divProps } = props;
    const [lookupData] = useFetchLookupsService(['contact_score']);

    // Error State
    if (lookupData.error) { return <></>; }

    // Loading State
    if (loading || lookupData.loading) {
        return (
            <div className='d-flex' {...divProps}>
                <div className="row small-gutters">
                    {range(4).map((index: number) =>
                        <div key={index} className="col mb-2"><div className="loading__square"></div></div>
                    )}
                </div>
            </div>
        );
    }

    const scores = lookupData.response.contact_score.items.filter(x => !x.is_filter || x.is_filter === isFilter);
    if (dropDown) {
        return <div>DropDown state not implemented.</div>;
    }

    return (
        <div className='d-flex' {...divProps}>
            {scores?.map((score, index) => {
                const selected = selections.has(score.short_value);
                return <Option type={type} score={score} key={score.short_value} index={index} selected={selected} onSelect={onSelectScore} />
            })}
        </div>
    );
}

export const Option = (props: IOptionProps): JSX.Element => {
    const { type, score, index, selected, onSelect } = props;
    const value = score.short_value.toLowerCase();
    const text = score.long_value;
    const className = joinWithSpace(`custom-control-label btn--${value}`, selected && 'active');
    const id = `option${index}`;
    const scoreClassName = ['score_label--a', 'score_label--b', 'score_label--c', 'score_label--d', 'score_label--n']

    const clickHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        onSelect?.(score);
    };

    return (
        <div className="choosable_checkbox w-100 mr-2" data-toggle="buttons" onClick={clickHandler}>
            <input type={type} checked={selected ? true : false} name="option" className="custom-control-input" readOnly id={id} />
            <label className={className} htmlFor={id}>
                <div className={`score_label score_label__medium  ${scoreClassName[index]}`}>
                    <span className="score_label__alpha">{text}</span>
                    <span className="score_label__text">Score</span>
                </div>
            </label>
        </div>

    );
}
