import { ISimpleCheckboxProps } from './SimpleCheckbox.def';
// import { useState } from 'react';
import './SimpleCheckbox.scss';

export const SimpleCheckboxDesktop = (props: ISimpleCheckboxProps): JSX.Element => {
    const { value, id, removeSpacing } = props;
    return (
        <div role="checkbox" aria-checked='false' className="custom-control custom-checkbox custom-control-inline mr-0">
            <input checked={props.checked} type="checkbox" id={id} name={"checkbox_" + id + ""} className="custom-control-input" onChange={e => props.onChange?.(e.currentTarget.checked)} />
            <label className={`custom-control-label ${removeSpacing && `mr-1 pr-0`}`} htmlFor={id}>{value}</label>
        </div>
    );
}
