import { OrdinaryButton, ProminentButton } from "views/forms/buttons/Button"
import { IWelcomeScreenProps } from "./WelcomeScreen.def";
import { Auth } from "scripts/security/AuthService";
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { GetBasicInfoApi } from "services/user/getBasicInfo";

export const GetStartedButton = (props:IWelcomeScreenProps.ButtonProps): JSX.Element =>
{
    const { onboarding, loading, onClick, ...buttonProps } = props;
    if(onboarding.error) { return <></>; }

    const isLoading = (onboarding.loading || onboarding.next.loading) || loading;
    
    const handleGetStarted = async (e:React.MouseEvent<HTMLIonButtonElement, MouseEvent>) =>
    {
        e.preventDefault();
        onClick?.(e);
        onboarding.next?.();
    }

    const disabled = isPasswordValid(onboarding.user) === false;
    return (
        <ProminentButton
            {...buttonProps}
            onClick={handleGetStarted}
            loading={isLoading}
            disabled={disabled}>
                Set up your CRM
        </ProminentButton>
    );
}

export const SkipButton = (props:IWelcomeScreenProps.ButtonProps): JSX.Element =>
    {
        const { onboarding, loading, onClick, ...buttonProps } = props;
        if(onboarding.error) { return <></>; }
    
        const isLoading = (onboarding.loading || onboarding.next.loading) || loading;
        
        const handleSkip = async (e:React.MouseEvent<HTMLIonButtonElement, MouseEvent>) =>
        {
            e.preventDefault();
            onClick?.(e);
            onboarding.next?.(true);
        }
    
        return (
            <OrdinaryButton
                {...buttonProps}
                onClick={handleSkip}
                loading={isLoading}>
                {onboarding.labels.skipLabel}
            </OrdinaryButton>
        );
    }

export const PasswordPopUp = (props:IWelcomeScreenProps.IPasswordPopUpProps) =>
{
    if(isPasswordValid(props.user) !== false) { return <></>; }
    
    const signOutHandler = (e:React.MouseEvent) =>
    {
        e.preventDefault();
        Auth.signOut();
    };

    return (
        <DefaultPopUp title="" hideHeader className="custom--modal initmodal" isOpen={true} toggleShow={() => Auth.signOut()}>
            <DefaultPopUp.Body>
                <div className="ion-padding bg-white h-100 d-flex">
                    <div className="align-self-center w-100 font-20 text-center">
                        Update your password to min 8 characters in the Members Only Area
                    </div>
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="align-self-center text-center">
                    <ProminentButton onClick={signOutHandler} className="btn--medium">Close</ProminentButton>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp>
    );
}

function isPasswordValid(user:GetBasicInfoApi.Response): boolean;
function isPasswordValid(user?:GetBasicInfoApi.Response): boolean | undefined;
function isPasswordValid(user?:GetBasicInfoApi.Response)
{
    if(!user) { return undefined; }
    return parseInt(user.agent_data.pwd_length ?? '0') >= 8;
}