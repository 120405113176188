import { IAssignStageProps } from './AssignStage.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AssignStageApp } from './AssignStage.app';
import { AssignStageDesktop } from './AssignStage.desktop';

export const AssignStage = (props: IAssignStageProps): JSX.Element =>
{
    const AssignStagePlatform = useResponsiveChoose(AssignStageDesktop, AssignStageApp);
    return (
        <AssignStagePlatform {...props} />
    );
}
