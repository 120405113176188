import { useHistory } from "react-router";
import { useFetchGetBasicInfoService } from "services/user/getBasicInfo";

export const ContactImportStatus = (props: IContactImoprtStatus): JSX.Element => {

    const [basicInfo] = useFetchGetBasicInfoService();
    const history = useHistory()
    return (
        <>

            {basicInfo.response?.provider_status && basicInfo.response?.provider_status.toLowerCase() === 'ok' && !basicInfo.response.sync_provider_to_nylas &&

                <div className={`bg_color_cloudy_sky_light cursor--hand p-3 d-inline-flex align-items-center border_radius--default ${props.className}`} onClick={() => history.push('/contacts/import', { noReferrer: 1 })}>
                    <div className="pr-md-2"><img src="https://content.harstatic.com/media/icons/clock_auxilary.svg" /></div>
                    <div className="font_size--medium color_auxiliary ml-2"> We are currently in the process of importing contacts. It can take up to 24h</div>
                </div>
            }
        </>

    )
}

interface IContactImoprtStatus {
    className?: string,
}