//import { useEffect, useState } from 'react';
import { EditContactScreen } from '../EditContactScreen/EditContactScreen';
import { IContactDetailScreenProps } from './ContactDetailScreen.def';
import './ContactDetailScreen.scss';
/*import { BasicInformationSectionApp } from 'views/contacts/BasicInformationSection/BasicInformationSection.app';
import { useParams } from 'react-router';
import { GetContactApi, useFetchGetContactService } from 'services/contacts/contactDetails/getContact';
import { ContactInsightSectionApp } from 'views/contacts/ContactInsightSection/ContactInsightSection.app';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { ContactSection } from 'views/contacts/ContactSection/ContactSectionEdit.app';
import { AddressesSectionEditApp } from 'views/contacts/AddressesSection/AddressesSectionEdit.app';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { RelationshipSectionEditApp } from 'views/contacts/RelationshipSection/RelationshipSectionEdit.app';
import { IRelationItem } from 'views/contacts/RelationshipSection/RelationshipSection.def';
import { LinksSectionEditApp } from 'views/contacts/LinksSection/LinksSectionEdit.app';
import { ILinkItem } from 'views/contacts/LinksSection/LinksSection.def';
*/

export const ContactDetailScreenApp = (props:IContactDetailScreenProps): JSX.Element =>
{
    /*const { id } = useParams<{ id: string }>();
    const [contactDetails,ActionContactDetails] = useFetchGetContactService({ contact_id: parseInt(id), renew: '1' });
   
    // For Contact
    const [email, PreferredEmail] = useState<GetContactApi.IEmailItemResponse[]>([]);
    const [phone, preferredPhone] = useState<GetContactApi.IPhoneItemResponse[]>([]);
    const [addresses, setAddresses] = useState<IAddressItem[]>([]);
    const [relationships, setRelationships] = useState<IRelationItem[]>([]);
    const [link, setLink] = useState<ILinkItem[]>([]);

    //For Basic Information
    const data=contactDetails?.response
    const basicData={   id:parseInt(data?.id||"0"),
        image:data?.photo_url||'',
        firstName:data?.first_name||'',
        middleName:data?.middle_name||'',
        lastName:data?.last_name||'',
        gender:data?.gender||'',
        dateOfBirth:data?.birthday||'',
        knownSince:data?.known_since||'',
        homeAniversary:data?.home_anniversary_date||'',
        weddingAniversary:data?.anniversary_date||'',
        income:data?.income||'',
        jobTitle:data?.job_title||'',
        company:data?.company||''}
        
    //For Contact Insight
        const [contactInsightData,setContactInsightData]=useState<IContactInsightData>({});
        useEffect(()=>{!!data&&setContactInsightData({
            scoreType: data?.score??"",
            pipelineType: data?.pipeline_status??"",
            sourceType: data?.sources??"",
            tags:data?.tags??[]
        });
        PreferredEmail(data?.emails??[]);
        preferredPhone(data?.phones??[]);
        setAddresses(data?.addresses.map?.(val => ({
            address1: val.address1,
            address2: val.address2,
            address_type: val.address_type,
            city: val.city,
            zipcode: parseInt(val.zipcode),
            id: parseInt(val.id)
        }))??[]);
        setRelationships(data?.relationships?.map?.(val => ({
            name: val.name,
            relationType: val.type,
            id: parseInt(val.id),
        }))??[])
        setLink(data?.links?.map?.(val => ({ label: val.name, url: val.url, id: val.id }))??[]);
    },[contactDetails]);

        
       const refresh=()=>{ActionContactDetails.fetch({contact_id: parseInt(id), renew: '1'})}
*/  
  return (<EditContactScreen {...props}/>);
     {/**  <ContactInsightSectionApp initialValues={contactInsightData} onChange={setContactInsightData}/>
         <BasicInformationSectionApp initialData={basicData} onSuccess={refresh}/>
         <ContactSection loading={contactDetails.loading} initialEmails={email} initialPhones={phone} notifyContact={props.onChange}/> 
         <AddressesSectionEditApp loading={contactDetails.loading} initialItems={addresses} />
         <RelationshipSectionEditApp loading={contactDetails.loading} initialItems={relationships}/>
         <LinksSectionEditApp loading={contactDetails.loading} initialLinks={link} />
        */}
}
