import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { switchCase } from "scripts/helpers/general";
import { joinWithSeparator } from "scripts/helpers/texts";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities";

export namespace CreateContactApi
{
    export const url = "/contacts";
    export const method = "POST";
    export const defaultError = "Failed to save contact.";
    export const operations = apiOperations(CreateContactApi);
    export const scope = new class CreateContactApiScope implements IApiScope<Request, Response, ResponseError>
    {
        request(data:Request): DeepPropertiesType<Request, string|number>
        {
            return {
                ...data,
                score: data.score,
                emails: data.emails?.map(item => ({ ...item, preferred: item.preferred === true? '1': '0' })),
                phones: data.phones?.map(item => ({ ...item, phone:this._buildPhone(item), preferred: item.preferred === true? '1': '0' })),
                addresses: data.addresses?.map(item => ({ ...item, zipcode:item.zipcode.toString() })),
                tags: data.tags?.map(item => ({ ...item, id:item.id?.toString() }))
            }
        }

        response({ data }:Record<'data', PropertiesType<Response, string>>)
        {
            return { id: parseInt(data.id) };
        }

        responseError(response:ServerResponseError): ResponseError
        {
            const { data, ...other } = response;
            const is_duplicated = switchCase(data?.is_duplicated, '1', true, '0', false);
            return { ...other, ...data, is_duplicated };
        }

        _buildPhone(item:IPhoneItem) {
            const { phone, phone_extension, phone_type } = item;
            delete item.phone_extension;
            return buildFullPhone(phone, phone_extension, phone_type);
        }
    }();
    
    export interface Request
    {
        photo_url?: string,
        first_name?: string,
        last_name?: string,
        middle_name?: string,
        title?: string,
        name_suffix?: string,
        gender?: string,
        birthday?: string,
        known_since?: string,
        income?: string,
        job_title?: string,
        company?: string,
        score?: string,
        pipeline_status?: string,
        anniversary_date?: string,
        home_anniversary_date?: string,
        contact_type?: string,
        sources?: string,
        emails?: Array<IEmailItem>,
        phones?: Array<IPhoneItem>,
        addresses?: Array<IAddressItem>,
        relationships?: Array<IRelationshipItem>,
        links?: Array<ILinkItem>,
        tags?: Array<ITagItem>;
        lead_type?: string;
        reminder_days_after?:string
    }

    export interface Response
    {
        id:number;
    }

    export interface ServerResponseError
    {
        data?: PropertiesType<ResponseError, string>;
        message:string;
    }

    export interface ResponseError
    {
        is_duplicated?:boolean;
        message:string;
    }

    export interface IEmailItem
    {
        email:string;
        preferred?: boolean;
    }

    export interface IPhoneItem
    {
        phone:string;
        phone_type: string;
        phone_extension?: number;
        preferred?: boolean;
    }

    export interface IAddressItem
    {
        address1: string,
        address2?: string,
        address_type: string,
        city: string,
        zipcode: string
        state?: string,
        country?: string
    }

    export interface IRelationshipItem
    {
        type: string;
        related_contact_id: number;
        //name?: string;
    }

    export interface ILinkItem
    {
        name: string;
        url: string;
    }

    export interface ITagItem
    {
        id?: number;
        name: string;
    }
}

export function hasPhoneExtension(phoneType?:string)
{
    return ['N','W',''].includes(phoneType ?? '');
}

export function buildFullPhone(value:string, extension?:number, phoneType?:string)
{
    if(!extension) { return value; }
    return hasPhoneExtension(phoneType)? joinWithSeparator(' ext.', value, extension): value;
}

export const fetchCreateContactService = forwardOperation(CreateContactApi.operations, 'fetch');
export const useFetchCreateContactService = forwardOperation(CreateContactApi.operations, 'useFetch');
export const useCreateContactService = forwardOperation(CreateContactApi.operations, 'useService');