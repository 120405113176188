import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { joinWithSpace } from "scripts/helpers/texts";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"

export namespace SearchEmailsApi {
    export const url = "/emails";
    export const method = "GET";
    export const defaultError = "Bad Request";
    export const operations = apiOperations(SearchEmailsApi);
    export const scope = new class SearchEmailsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            const { page, page_size, keyword, sort_by, sort_dir, type, contact_id, renew } = input;
            return {
                page: page,
                page_size: page_size,
                keyword: keyword,
                type: type,
                sort_by: sort_by,
                sort_dir: sort_dir,
                contact_id: contact_id,
                renew: renew
            }
        }

        response(input: Record<'data', DeepPropertiesType<ServerResponse, string>>): Response {

            return {
                ...input.data,
                list: input.data.list.map(item => ({
                    ...item,
                    first_last_name: joinWithSpace(item.first_name, item.last_name),
                    recipients: item.recipients.map(recipient => ({
                        ...recipient,
                        first_last_name: joinWithSpace(recipient.first_name, recipient.last_name)
                    })),
                    recipients_cc: item.recipients.map(recipient => ({
                        ...recipient,
                        first_last_name: joinWithSpace(recipient.first_name, recipient.last_name)
                    })),
                    recipients_bcc: item.recipients.map(recipient => ({
                        ...recipient,
                        first_last_name: joinWithSpace(recipient.first_name, recipient.last_name)
                    })),

                }))

                // list: input.data.list.map(x => ({
                //     id: x.id,
                //     subject: x.subject,
                //     short_message: x.short_message,
                //     created_dt: x.created_dt,
                //     from_address: x.from_address,
                //     to_address: x.to_address,
                //     cc_address: x.cc_address,
                //     bcc_address: x.bcc_address,
                //     first_name: x.first_name,
                //     middle_name: x.middle_name,
                //     last_name: x.last_name,
                //     display_name: x.display_name,
                //     photo_url: x.photo_url,
                //     isLead: (x.isLead === '1'),
                // })),
                // recipients: input.data.recipients.map(x => ({
                //     id: x.id,
                //     first_name: x.first_name,
                //     last_name: x.last_name,
                //     middle_name: x.middle_name,
                //     photo_url: x.photo_url,
                //     score: x.score,
                // })),
                // recipients_cc: input.data.recipients_cc.map(x => ({
                //     id: x.id,
                //     first_name: x.first_name,
                //     last_name: x.last_name,
                //     middle_name: x.middle_name,
                //     photo_url: x.photo_url,
                //     score: x.score,
                // })),
                // recipients_bcc: input.data.recipients_bcc.map(x => ({
                //     id: x.id,
                //     first_name: x.first_name,
                //     last_name: x.last_name,
                //     middle_name: x.middle_name,
                //     photo_url: x.photo_url,
                //     score: x.score,
                // }))

            }
        }
    }();

    export interface Request {
        page?: string,
        page_size?: string,
        type?: "inbox" | "sent" | "draft" | "lead" | "archived" | "forwarded",
        keyword?: string,
        sort_by?: "message_dt" | "recent" | "older",
        sort_dir?: string,
        renew?: string,
        contact_id?: string,
    }

    export interface ServerResponse {
        total: string,
        total_draft: string,
        total_unread: string,
        total_unread_lead:string,
        page: string,
        page_size: string,
        total_pages: string,
        next_page_url: string,
        prev_page_url: string,
        list: IServerEmailList[]
    }

    export interface Response extends Omit<ServerResponse, 'list'>
    {
        list: IEmailList[]
    }
}

export interface IServerEmailList {
    id: string,
    subject: string,
    first_name: string,
    middle_name: string,
    last_name: string,
    short_message: string,
    created_dt: string,
    from_address: string,
    date_tag: string,
    contact_id: string,
    folder?:string,
    display_name: string,
    photo_url: string,
    is_lead: string,
    read: string,
    message_uid: string,
    recipients: IServerEmailRecipient[],
    recipients_cc: IServerEmailRecipient[],
    recipients_bcc: IServerEmailRecipient[],

}

export interface  IEmailList extends Omit<IServerEmailList, 'recipients' | 'recipients_cc' | 'recipients_bcc'>
{
    first_last_name:string;
    recipients: IEmailRecipient[],
    recipients_cc: IEmailRecipient[],
    recipients_bcc: IEmailRecipient[],
}

export interface IServerEmailRecipient {
    id: string,
    first_name: string,
    last_name: string,
    middle_name: string,
    email: string,
    photo_url: string,
    score: string,
}

export interface IEmailRecipient extends IServerEmailRecipient {
    first_last_name: string,
}


export const fetchSearchEmailsService = forwardOperation(SearchEmailsApi.operations, 'fetch');
export const useFetchSearchEmailsService = forwardOperation(SearchEmailsApi.operations, 'useFetch');
export const useSearchEmailsService = forwardOperation(SearchEmailsApi.operations, 'useService');