import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"
export namespace PostCSVValidateApi {
    export const url = "/contacts/csv/:import_id/validation";
    export const method = "POST";
    export const defaultError = "Failed to validate csv file.";
    export const operations = apiOperations(PostCSVValidateApi);
    export const scope = new class PostCSVValidateApiScope implements IApiScope<Request, Response> {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        import_id: string
        selected_value_mappings?: ISelectedMappings
        queue?: string
    }

    export interface Response {
        rows_errors: any[],
        rows_warnings: any[],
        total_errors: string,
        columns?: string[],
        total_warnings: string,
        total_success: string,
        errors_file: string,
        warnings_file: string,
        selected_columns?: ISelectedColumns[],
    }



    export interface ISelectedMappings {
        [key: string]: ICSValues[];
    }


    export interface ICSValues {
        csv: string,
        crm: string,
    }

    export interface IRows {
        key: string,
        value: string
    }

    export interface ISelectedColumns {
        short_value: string,
        long_value: string,
        csv_field: string
    }




}

export const fetchPostCSVValidateService = forwardOperation(PostCSVValidateApi.operations, 'fetch');
export const useFetchPostCSVValidateService = forwardOperation(PostCSVValidateApi.operations, 'useFetch');
export const usePostCSVValidateService = forwardOperation(PostCSVValidateApi.operations, 'useService');