import { Textbox } from 'views/forms/textboxes/Textbox';
import { IContactSectionProps, IEmailItem, IPhoneItem } from './ContactSection.def';
import  { Fragment, useEffect, useState } from 'react';
import './ContactSection.scss';
import { Button } from 'views/forms/buttons/Button';
import { fetchEmailExistenceService } from 'services/contacts/emailExistence';
import { fetchPhoneExistenceService } from 'services/contacts/phoneExistence';
import { useParams } from 'react-router';
import { useAddEmailService } from 'services/contacts/contactDetails/addEmail';
import { useDeleteEmailService } from 'services/contacts/contactDetails/deleteEmail';
import { useAddPhoneService } from 'services/contacts/contactDetails/addPhone';
import { useDeletePhoneService } from 'services/contacts/contactDetails/deletePhone';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useFetchLookupsService } from 'services/general/lookups';
import { SimpleCheckboxDesktop } from 'views/forms/checkboxes/SimpleCheckbox/SimpleCheckbox.desktop';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { isLocal } from 'scripts/application/settings';
import {  transformPhoneNumber } from 'scripts/helpers/texts';

export const ContactSection = (props: IContactSectionProps): JSX.Element => {
    const { initialEmails, initialPhones, maxEmails, maxPhones, onChange, loading } = props;
    const { id } = useParams<{ id: string }>();

    const mEmails = maxEmails ?? 10;
    const mPhones = maxPhones ?? 10;
    const [phonesData, setPhonesData] = useState<IPhoneItem[]>(mPhones && initialPhones && initialPhones.length > mPhones ? initialPhones.slice(0, mPhones) : initialPhones ? initialPhones : []);
    const [emailsData, setEmailData] = useState(mEmails && initialEmails && initialEmails.length > mEmails ? initialEmails.slice(0, mEmails) : initialEmails ? initialEmails : []);

    useEffect(() => {
       id&& setPhonesData(mPhones && initialPhones && initialPhones.length > mPhones ? initialPhones.slice(0, mPhones) : initialPhones ? initialPhones : [])
       setEmailData((mEmails && initialEmails && initialEmails.length > mEmails) ? initialEmails.slice(0, mEmails) : initialEmails ? initialEmails : [])
    }, [initialEmails, initialPhones, mEmails, mPhones])

    const onEmailsChange = (emails: IEmailItem[]) => {
        setEmailData([...emails]);
        onChange?.({ emails, phones: phonesData })
    };

    const onPhonesChange = (phones: IPhoneItem[]) => {
        setPhonesData([...phones]);
        onChange?.({ emails: emailsData, phones });
    };

    return (
        <div className="card p-0 mb-5">
            <EmailSection {...props} loading={loading} initialValues={initialEmails} formData={emailsData} maxItems={mEmails} onChange={onEmailsChange} notifyContact={props.notifyContact} />
            <PhoneSection {...props} loading={loading} initialValues={initialPhones} formData={phonesData} maxItems={mPhones} onChange={onPhonesChange} notifyContact={props.notifyContact} />
        </div>

    );
}



//Email Sections
const EmailSection = (props: IContactSectionProps.SectionViewProps<IEmailItem>) => {
    const { maxItems, formData: emailsData, onChange, initialValues, loading } = props;
    const [selectedEmail, setSelectedEmail] = useState<number | undefined>(emailsData.length === 0 ? 0 : undefined);
    const [addEmailData, ActionAddEmail] = useAddEmailService();
    const [, ActionDeleteEmail] = useDeleteEmailService();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (initialValues?.length! > 0 || (id && Object.keys(id!).length > 0)) { setSelectedEmail(undefined) }
    }, [initialValues, id]);

    useServiceDidSucceeded(addEmailData, (response) => {
        emailsData[selectedEmail!]!.id = response.id;
        setSelectedEmail(undefined);
        onChange?.(emailsData);
    })
  

    const saveEmail = (value: IEmailItem) => {
        if (selectedEmail === emailsData.length && emailsData.length >= maxItems) { return; }
        if (selectedEmail !== undefined) { emailsData[selectedEmail] = value; }
        else { emailsData.push(value); }

        if (id && Object.keys(id!).length > 0) {
            ActionAddEmail.fetch({
                contact_id: id!,
                email: value.email,
                preferred: value.preferred,
            })
        } else {
            setSelectedEmail(undefined);
            onChange?.(emailsData);
        }
    }

    const removeEmail = async (index: number) => {
        const email = emailsData[index]!;
        const newEmail = index === 0 ? emailsData[index + 1]! : emailsData[0]!
        if (id !== undefined && Object.keys(id!).length > 0 && email.id) {
            if (email.preferred) {
                newEmail.preferred = true;
                await ActionAddEmail.fetch({
                    contact_id: id!,
                    email: newEmail.email,
                    preferred: newEmail.preferred,
                })
                ActionDeleteEmail.fetch({
                    contact_id: id!,
                    id: email.id.toString(),
                });
                props.notifyContact?.([{ action: 'email', payload: newEmail.email }])!
            } else {
                ActionDeleteEmail.fetch({
                    contact_id: id!,
                    id: email.id.toString(),
                })
            }
        }
        emailsData.splice(index, 1);
        setSelectedEmail(emailsData.length === 0 ? 0 : undefined);
        onChange?.(emailsData);


    }

    const addEmail = () => {
        setSelectedEmail(emailsData.length);
    };
    const editEmail = (index: number) => {
        setSelectedEmail(index);
    };

    const isEmail = selectedEmail === undefined;
    return isEmail ?
        <EmailListView isOpen={isEmail}  loading={loading} notifyContact={props.notifyContact} list={emailsData} onAdd={addEmail} onRemove={removeEmail} onEdit={(index) => editEmail(index)} maxItems={maxItems} /> :
        <EmailFormView {...props} formData={emailsData} notifyContact={props.notifyContact} selectedValue={emailsData[selectedEmail]} maxItems={maxItems} onSave={saveEmail} onCancel={() => setSelectedEmail(undefined)} onRemove={() => removeEmail(selectedEmail)} />
}

const EmailListView = (props: IContactSectionProps.ListViewProps<IEmailItem>) => {
    const { list, maxItems } = props;
    const [selectedEmail, setSelectedEmail] = useState(undefined as number | undefined);
    const [, ActionAddEmail] = useAddEmailService();
    const [, ActionDeleteEmail] = useDeleteEmailService();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setSelectedEmail(undefined);
    }, [list.length]);
   /* const addEmail = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onAdd();
    }*/

   {/**  const editEmail = (index: number) => {

        // list.splice(index, 1);
        setSelectedEmail(index);
    }*/}

    const saveEmail = async (value: IEmailItem) => {

        if (selectedEmail === list.length && list.length >= maxItems) { return; }

        if (id !== undefined && Object.keys(id!).length > 0 && value.id) {
            await ActionAddEmail.fetch({
                contact_id: id!,
                email: value.email,
                preferred: value.preferred,
            })
            ActionDeleteEmail.fetch({
                contact_id: id!,
                id: value.id.toString(),
            });
            if (value.preferred) {
                props.notifyContact?.([{ action: 'email', payload: value.email }])!
            }
        }
        if (selectedEmail !== undefined) {
            list[selectedEmail] = value;
        }
        else { list.push(value) }
        setSelectedEmail(undefined);
    }

    const removeEmail = (index: number) => {
        list.splice(index, 1);
        if (list.length < 1) {
            props.onRemove(index);
        }
        setSelectedEmail(list.length < 0 ? 0 : undefined);
    }

    return (
        <>
            {props.loading ?
                <div className="card mb-5 p-3">
                    <div className="p-3">
                        <h3 tabIndex={0}>Contact</h3>

                        <label htmlFor="PreferredEmail">Preferred Email</label>
                        <div className="loading__textbox"></div>
                        <div className="text-right mb-4 mt-3">
                            <button className="btn btn--primary" disabled>Add email</button>
                        </div>
                    </div>
                </div>
                :
                <div className='p-3'>
                    <div className="pl-3 pr-3 pt-3">
                        <h3 tabIndex={0}>Contact</h3>
                    </div>

                    <div className="pl-3 pr-3 input_label__bold_large">Email</div>
                    {list.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {selectedEmail !== index &&
                                    <div className="address__row address__row_noborder">
                                        <div className="address__row__desc">
                                            <div className="input_label__bold">{list.length==1||item.preferred? 'Preferred' : 'Other'}</div>
                                            <div className="d-flex">
                                                <div className="align-self-center">
                                                    <img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/email-gray.svg" alt='img'></img>
                                                </div>
                                                <a href={'mailto:'+item.email} className="align-self-center font_weight--semi_bold font_size--medium">
                                                    {item.email}
                                                </a>
                                                </div>
                                            </div>
                                        {/* {props.isOpen && selectedEmail === undefined ?
                                            <div className="address__row__links">
                                                {<button type="button" className="btn btn--ordinary btn--small mr-2" onClick={() => editEmail(index)}>Edit</button>}
                                                {list.length > 1 &&
                                                    <OrdinaryButton className='btn btn--ordinary btn--small' size='small' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete email?'>Remove</OrdinaryButton>
                                                }
                                            </div>
                                            : null} */}
                                    </div>}
                                {selectedEmail !== undefined && selectedEmail === index ?
                                    <EmailFormView formData={list} selectedValue={list[selectedEmail]} maxItems={maxItems} onSave={saveEmail} onCancel={() => setSelectedEmail(undefined)} onRemove={() => removeEmail(selectedEmail)} /> :
                                    null
                                }
                            </Fragment>

                        )
                    })}
                </div>
            }


            {/*props.list.length < props.maxItems && props.isOpen ?
                <div className="p-3 border-top form_section_border">
                    <div className="pl-3 pr-3">
                        <a href="javascript:void(0);" className="font_size--medium font_weight--semi_bold text-decoration-none" onClick={addEmail}>+ Add Email</a>
                    </div>
                </div> : null
           
        */ }


        </>
    )
}

const EmailFormView = (props: IContactSectionProps.FormViewProps<IEmailItem>) => {
    const { selectedValue, formData: emailsData,validate,isError } = props;
    const preferredEmailInActive=emailsData.length==0||(selectedValue && emailsData.length==1)?true:false;
    const [isChecked, setChecked] = useState(preferredEmailInActive?true:selectedValue?.preferred)
    const [mailAddress, setMailAddress] = useState<{ email: IEmailItem, error: string }>({ email: { email: '', preferred: emailsData.length === 0, id: undefined }, error: '' });
    const [, setValidating] = useState(false);
    //const [serverError, setServerError] = useState<Error | undefined>();



    function validateEmailRequired(){

        var error=""
        if (!emailsData || emailsData.length === 0) { saveEmail() }
        setMailAddress({ email: mailAddress.email, error: error});
        return true
    }

    const [,notifyChange] = useValidateConsumer(validate,validateEmailRequired);

    const [, setSelectedEmail] = useState<number | undefined>(emailsData.length === 0 ? 0 : undefined);
    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        const mail = selectedValue;
        if (mail && emailsData?.length) { setMailAddress({ email: mail, error: '' }) }
        else {
            if (emailsData.length === 0) {
                const emptyItem = { email: '', preferred: emailsData.length === 0 };
                setMailAddress({ email: emptyItem, error: '', });
            }
        }
    }, [selectedValue, emailsData]);

    const removeEmail = () => {
        props.onRemove();
    }

    const editEmail = (index: number) => {
        setSelectedEmail(index);
    }


    const saveEmail = async () => {
  /*try {
            setServerError(undefined)*/
        setValidating(true);
        const error = await validateEmail();
        setValidating(false);
        if (error) { setMailAddress({ email: mailAddress.email, error }); }
        else if (isChecked && emailsData.length > 0) {
            const isPreferred = emailsData.filter(x => x.preferred === true);
            isPreferred.map(x => x.preferred = false);
            mailAddress.email!.preferred = isChecked;
            props.notifyContact?.([{ action: 'email', payload: mailAddress.email.email }]);
            props.onSave(mailAddress?.email!);
        }
        else { props.onSave(mailAddress?.email!); }
        /*}
        catch (e) {
            setServerError(e as Error);
        }*/
    }

    const validateEmail = async (): Promise<string | undefined> => {
        const email = mailAddress.email?.email;
        const emailValidationExpression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
       if (!email || email.length === 0) { return 'The field should contain unique valid email, min 5 max 60 characters (user@har.com)'; }
        if (email.length < 4) { return 'The field should contain unique valid email, min 5 max 60 characters (user@har.com)'; }
       if (!email.match(emailValidationExpression)) { return 'The field should contain unique valid email, min 5 max 60 characters (user@har.com)'; }
       // if (email === selectedValue?.email) { return; }
       // if(email.length < 4||!email.match(emailValidationExpression)||email.length === 0){return 'The field should contain unique valid email, min 5 max 60 characters (user@har.com)';}
        const isEmailAdded = emailsData.map(x => x.email).some(val => val === email);
        if (isEmailAdded) { return 'This email already exists in the Contact, please type another email'; }

        const response = await fetchEmailExistenceService({ email });
        if (response.isDuplicated) { return 'This email already exists in the CRM, please type another email'; }

        return undefined; // no error
    }

    const onEmailChange=(value:string)=>{
        setMailAddress({ email: { ...mailAddress.email, email:value }, error: '' })
        notifyChange()
    }
    useEffect(()=>{isError&&saveEmail()},[isError]);

    return (
        <>
            {emailsData.length > 0 && !props.selectedValue ?
                <EmailListView list={emailsData} onAdd={saveEmail} onRemove={removeEmail} onEdit={(index) => editEmail(index)} maxItems={props.maxItems} />
                : null}

            <div className='p-3'>
                <div className={!props.selectedValue ? 'pl-3 pr-3' : ''}>
                    {!id && !props.selectedValue && emailsData.length === 0 ?
                        <div className="pb-3 pr-3 pt-3">
                            <h3 tabIndex={0}>Contact</h3>
                            <div className="input_label__bold_large">Email</div>
                        </div> : null}
                    <Textbox containerClassName='mb-4' maxlength={60} value={mailAddress.email?.email} label={isChecked ? 'Preferred Email' : 'Other Email'} error={ (mailAddress.error ?? false)} placeholder='Email' onIonChange={(e) =>onEmailChange(e.detail.value??"") } />
                    <div className="row">
                        <div className="col-auto">
                            <div role="checkbox" aria-checked='true' className="custom-control custom-checkbox custom-control-inline">
                                <input disabled={preferredEmailInActive} checked={isChecked}  type="checkbox" id={id} name={"checkbox_" + id + ""} className="custom-control-input" onChange={() => setChecked(!isChecked)} />
                                <label className="custom-control-label" htmlFor={id}>make it preferred</label>
                            </div>
                        </div>
                       {isLocal&& <div className="col-auto"><SimpleCheckboxDesktop id="ImportEmails" value="import emails"></SimpleCheckboxDesktop></div>}
                    </div>
                    {!props.selectedValue ?
                        <div className="text-right mb-4">
                             {/** <Button kind='shapeless' size='medium' className='mr-3' onClick={props.onCancel}>Cancel</Button>
                           <Button kind='primary' size='medium' onClick={saveEmail} loading={validating}>Save </Button>*/}
                        </div> :
                        <div className="address__row__links text-right">
                            {emailsData.length > 1 &&
                                <Button kind='shapeless_red' size='medium' className='shapeless_red' onClick={removeEmail} clickConfirmation='Are you sure to delete email?'>Delete this Email</Button>
                            }
                            <Button kind='shapeless' size='medium' className="" onClick={props.onCancel}>Cancel</Button>
                            <Button kind='primary' size='medium' className="" onClick={saveEmail}>Save</Button>
                        </div>
                    }
                    {/*serverError && <ErrorDialog title="Internal Error">{serverError}</ErrorDialog>*/}
                </div>
            </div>
        </>
    )
}

//Phone Sections
const PhoneSection = (props: IContactSectionProps.SectionViewProps<IPhoneItem>) => {
    const { maxItems, formData: phonesData, onChange, initialValues, loading } = props;
    const [selectedPhone, setSelectedPhone] = useState(phonesData.length === 0 ? 0 : undefined);
    const [addPhoneData, ActionAddPhone] = useAddPhoneService();
    const [, ActionDeletePhone] = useDeletePhoneService();
    const { id } = useParams<{ id: string }>();
 
    useEffect(() => {
        
        if (initialValues?.length! > 0 || (!!id && Object.keys(id!).length > 0)) { setSelectedPhone(undefined) }
    }, [initialValues, id])

    useServiceDidSucceeded(addPhoneData, (response) => {
        phonesData[selectedPhone!]!.id = response.id;
        // phonesData[selectedPhone!]!.phone_type = response.phone_type;
        setSelectedPhone(undefined);

        onChange?.(phonesData);
    })

    const savePhone = (value: IPhoneItem) => {
        if (selectedPhone === phonesData.length && phonesData.length >= maxItems) { return; }
        const newValue = { ...value, phone: value.phone };
        if (selectedPhone !== undefined) { phonesData[selectedPhone] = newValue; }
        else {  phonesData.push(newValue); }
        // setSelectedPhone(undefined);
        // onChange?.(phonesData);
        if (id !== undefined && Object.keys(id!).length > 0) {
            ActionAddPhone.fetch({
                contact_id: id!,
                phone: newValue.phone,
                preferred: newValue.preferred ? '1' : '0',
                phone_type: newValue.phone_type ?? 'N'
            })
        } else {
            onChange?.(phonesData);
            setSelectedPhone(undefined);
          
        }
    }

    const removePhone = async (index: number) => {
        const phone = phonesData[index]!;
        const newPhone = index === 0 ? phonesData[index + 1]! : phonesData[0]!;
        if (id !== undefined && Object.keys(id!).length > 0 && phone?.id!) {
            if (phonesData.length > 1 && phone.preferred) {
                newPhone.preferred = true;
                await ActionAddPhone.fetch({
                    contact_id: id!,
                    phone: newPhone.phone,
                    preferred: newPhone.preferred ? '1' : '0',
                    phone_type: newPhone.phone_type!
                });
                props.notifyContact?.([{ action: 'phone', payload: newPhone.phone }]);
            } else {
                await ActionDeletePhone.fetch({
                    contact_id: id!,
                    id: phone?.id!.toString(),
                })
            }
        }
        if (newPhone === undefined) { props.notifyContact?.([{ action: 'phone', payload: newPhone }]) }
        phonesData.splice(index, 1);
        setSelectedPhone(phonesData.length === 0 ? 0 : undefined);
        onChange?.(phonesData);

    }

    const addPhone = () => {setSelectedPhone(phonesData.length);setSelectedPhone(phonesData.length);};
    const editPhone = (index: number) => {
        setSelectedPhone(index);
    };

    const isPhone = selectedPhone === undefined;
    return isPhone ?
        <PhoneListView isOpen={isPhone} notifyContact={props.notifyContact} loading={loading} list={phonesData} onCancel={() => setSelectedPhone(undefined)} onAdd={addPhone} onEdit={(index) => editPhone(index)} onRemove={removePhone} maxItems={maxItems} /> :
        <PhoneFormView {...props} formData={phonesData} notifyContact={props.notifyContact} selectedValue={phonesData[selectedPhone]} maxItems={maxItems} onSave={savePhone} onCancel={() => setSelectedPhone(undefined)} onRemove={() => removePhone(selectedPhone)} />
}
const PhoneListView = (props: IContactSectionProps.ListViewProps<IPhoneItem>) => {
    const { list, maxItems } = props;
    const [selectedPhone, setSelectedPhone] = useState(undefined as number | undefined);
    // const [lookup, setLookUp] = useState([])
    const [, ActionAddPhone] = useAddPhoneService();
    const [, ActionDeletePhone] = useDeletePhoneService();
    const { id } = useParams<{ id: string }>();
    const [lookupData] = useFetchLookupsService(['phone_type']);

    useEffect(() => {
        setSelectedPhone(undefined);
    }, [list.length]);

    // useEffect(() => {
    //     setLookUp(lookupData.response?.phone_type.items!);
    // }, [lookupData.response]);

    {/**const editPhone = (index: number) => {
        setSelectedPhone(index);
    }*/}

   /* const addPhone = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onAdd();
    }*/

    const savePhone = async (value: any) => {
        if (selectedPhone === list.length && list.length >= maxItems) { return; }
        const newValue = { ...value, phone: value.phone, phone_type: value.phone_type };
        if (selectedPhone !== undefined) { list[selectedPhone] = newValue; }
        else { list.push(newValue); }

        setSelectedPhone(undefined);
        if (Object.keys(id!).length > 0 && newValue.id) {
            await ActionDeletePhone.fetch({
                contact_id: id!,
                id: newValue.id!.toString(),
            })
            ActionAddPhone.fetch({
                contact_id: id!,
                phone: newValue.phone,
                preferred: newValue.preferred ? '1' : '0',
                phone_type: newValue.phone_type ?? 'N',
            })
        }
        if (newValue.preferred) {
            props.notifyContact?.([{ action: 'phone', payload: newValue.phone }]);
            props.notifyContact?.([{ action: 'phoneType', payload: newValue.phone_type }]);
        }
    }

    const removePhone = (index: number) => {
        props.onRemove(index);
        list.splice(index, 1);
        setSelectedPhone(list.length < 0 ? 0 : undefined);
    }

    return (
        <>
            {props.loading ?
                <div className="card mb-5">
                    <div className="p-4">
                        <h3 tabIndex={0}>Contact</h3>
                        <label htmlFor="PreferredPhone">Preferred Phone number</label>
                        <div className="loading__textbox"></div>
                        <div className="text-right mt-3">
                            <button className="btn btn--primary" disabled>Add phone number</button>
                        </div>
                    </div>
                </div>
                :
                <div className='p-3'>
                    <div className="pl-3 pr-3 input_label__bold_large">Phone</div>
                    {list.length > 0 && list.map((item, index) => {
                        const phoneType = lookupData.response?.phone_type?.items.find(x => x.short_value === item.phone_type)
                        return (
                            <Fragment key={index}>
                                {selectedPhone !== index && item.phone !== '' ?
                                    <div className="address__row address__row_noborder">
                                        <div className="address__row__desc">
                                            {/* <div className="input_label__bold">{phoneType}</div> */}
                                            <div>
                                                <div className="input_label__bold">{list.length==1||item.preferred ? `PREFERRED ${phoneType?.long_value?`(${phoneType?.long_value})`:""} ` : `OTHER (${phoneType?.long_value ?? 'None'})`}</div>
                                                {/* <div className="input_label__bold">{item.phone_type}</div> */}
                                                {/* {phoneType !== undefined &&
                                                    <div className="input_label__bold">{phoneType?.[0]!.long_value ?? phoneType}</div>
                                                } */}

                                            </div>
                                            {/* <div className="input_label__bold">{phoneType !== undefined && phoneType!.length > 0 ? phoneType?.[0]!.long_value : 'None'}</div> */}
                                            <div className="d-flex">
                                                <div className="align-self-center">
                                                    <img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/business_card/phone.svg" alt='phone'></img>
                                                </div>
                                                {/*<a href={buildTelLink(item.phone, item.phone_extension, item.country_code)} className="align-self-center font_weight--semi_bold font_size--medium">
                                                    {formatFullPhone(item.phone, item.phone_extension, item.country_code)}</a>*/}
                                                <a href={'tel:'+transformPhoneNumber(item.country_code,item.phone,item.phone_extension)} className="align-self-center font_weight--semi_bold font_size--medium">
                                                    {transformPhoneNumber(item.country_code,item.phone,item.phone_extension)}
                                                </a>
                                                </div>
                                            </div>
                                        {/* {props.isOpen && selectedPhone === undefined ?
                                            <div className="address__row__links">
                                                {<button type="button" className="btn btn--ordinary btn--small mr-2" onClick={() => editPhone(index)}>Edit</button>}
                                                <OrdinaryButton className='btn btn--ordinary btn--small' size='small' onClick={() => props.onRemove(index)} clickConfirmation='Are you sure to delete phone?'>Remove</OrdinaryButton>
                                            </div> : null} */}
                                    </div>
                                    : null
                                }

                                {selectedPhone === index ?
                                    <PhoneFormView formData={list} selectedValue={list[selectedPhone]} maxItems={maxItems} onSave={savePhone} onCancel={() => setSelectedPhone(undefined)} onRemove={() => removePhone(selectedPhone)} />
                                    : null
                                }
                            </Fragment>

                        )
                    })}
                </div>
            }


            {/*list.length < maxItems && props.isOpen ?
                <div className="p-3 border-top">
                    <div  className="pl-3 pr-3"><a href="javascript:void(0);" className="font_size--medium font_weight--semi_bold text-decoration-none" onClick={addPhone}>+ Add Phone</a></div>
                </div>
                : null
        */}
        </>
    )
}
const PhoneFormView = (props: IContactSectionProps.FormViewProps<IPhoneItem>) => {
    const { selectedValue, formData: phonesData,validate,isError } = props;
    const preferredPhoneInActive=phonesData.length==0||(selectedValue && phonesData.length==1);
    const [, setSelectedPhone] = useState(undefined as number | undefined);
    const initialPhoneNum: IPhoneItem = { phone: '', preferred: phonesData.length === 0, phone_type: '' };
    const [phoneNum, setPhoneNum] = useState({ phone: initialPhoneNum, error: '' });
    const [validating, setValidating] = useState(false);
    //const [serverError, setServerError] = useState<Error | undefined>();
    const [isChecked, setChecked] = useState(preferredPhoneInActive?true:selectedValue?.preferred);
    const [lookupData] = useFetchLookupsService(['phone_type']);
    function validatePhoneRequired(){
        var error=""
        if (!phonesData || phonesData.length === 0) { savePhone() }
        setPhoneNum({ phone: phoneNum.phone, error: error});
        return true
    }
    const [,notifyChange] = useValidateConsumer(validate,validatePhoneRequired);

    useEffect(() => {
        const phone = Number(selectedValue?.phone?.replace(/[- )(]/g, ''));
        const item = !isNaN(phone) ? selectedValue! : { phone: '', preferred: phonesData.length === 0, phone_type: '' };
        setPhoneNum({ phone: item, error: '' })
    }, [selectedValue, phonesData.length]);

    const savePhone = async () => {

        /*try {
            setServerError(undefined)*/
        setValidating(true);
        const error = await validatePhone();
        setValidating(false);
        if (error) { 
            setPhoneNum({ phone: phoneNum.phone, error }); 
        }
        else if (isChecked && phonesData.length > 0) {
            const isPreferred = phonesData.filter(x => x.preferred === true);
            isPreferred.map(x => x.preferred = false);
            phoneNum.phone.preferred = isChecked;
            props.notifyContact?.([{ action: 'phone', payload: phoneNum.phone.phone }])
            props.onSave(phoneNum?.phone!);
        }
        else { 
            props.notifyContact?.([{ action: 'phone', payload: phoneNum.phone.phone }])
            props.onSave(phoneNum?.phone!);
         }
        
        if (phoneNum.phone.preferred && phonesData.length === 1) {
            props.notifyContact?.([{ action: 'phone', payload: phoneNum.phone.phone }])
        }
        /*}
        catch (e) {
            setServerError(e as Error);
        }*/
    }

    const validatePhone = async (): Promise<string | undefined> => {
        const phone = phoneNum.phone?.phone;
        const phoneRegex=/^\d{16}$/

        // const type = phoneNum.phone?.phone_type;
        if (!phone || phone.length === 0) { return 'This field is required'; }

        if ( !phone || phone.trim() === '') { return 'This field is required'; }
        // if (!type) { return 'Phone type is required'; }
        if (!phone.match(phoneRegex)) { return 'This field should contain max 16 digits, only numbers can be added'; }
        if (phone === selectedValue?.phone.replace(/[- )(]/g, '')) { return; }
       

        const isPhoneAdded = phonesData.map(x => x.phone).some(val => val.replace(/[- )(]/g, '') === phone);
        if (isPhoneAdded) { return 'This phone number already exists in the Contact, please type another phone'; }

        const response = await fetchPhoneExistenceService({ phone: phoneNum.phone?.phone.toString() });
        if (response.isDuplicated) { return 'This phone number already exists in the CRM, please type another phone'; }

        return undefined; // no error
    }
    
    const onPhoneChange=(value:string)=>{
        setPhoneNum({ phone: { ...phoneNum.phone, phone:value }, error: '' })
        notifyChange()
    }
    useEffect(()=>{isError&&savePhone()},[isError]);

    const removePhoneNum = () => {
        props.onRemove()
    }

    return (
        <>
            {phonesData.length > 0 && !props.selectedValue ?
                <PhoneListView list={phonesData} onCancel={() => setSelectedPhone(undefined)} onAdd={savePhone} onEdit={(index) => setSelectedPhone(index)} onRemove={removePhoneNum} maxItems={props.maxItems} />
                : null}
            <div className='p-3'>
                <div className={!props.selectedValue ? 'pl-3 pr-3' : ''}>
                    {!props.selectedValue && phonesData.length === 0 ?
                        <div className="pb-3 pr-3">
                            <div className="input_label__bold_large">Phone</div>
                        </div> : null
                    }
                    {/* <Textbox containerClassName='mb-4' value={phoneNum.phone.phone ?? ''} type='tel' pattern='tel' maxlength={15} label={isChecked ? 'Preferred Phone' : 'Other Phone'} error={phoneNum.error ?? false} placeholder='Phone number' onIonChange={(e) => setPhoneNum({ phone: { ...phoneNum.phone, phone: e.detail.value ?? '' }, error: '' })} /> */}
                    <div className="row small-gutters">
                        <div className="col-7">
                            <Textbox containerClassName='mb-2 ' value={phoneNum.phone.phone ?? ''} type='text' maxlength={25} label={isChecked ? 'Preferred Phone' : 'Other Phone'} error={phoneNum.error ?? false} placeholder='Phone number' onIonChange={(e) =>onPhoneChange(e.detail.value??"")}  />
                        </div>
                        <div className="col-5">
                            <select className="custom-select custom-select_medium mt-4" onChange={(e) => setPhoneNum({ phone: { ...phoneNum.phone, phone_type: e.currentTarget.value ?? 'N' }, error: '' })}>
                                {lookupData.response?.phone_type.items.map((x, index) => (
                                    <option key={index} /*value={selectedValue?.phone_type === x.short_value}*/ value={x.short_value}>{x.long_value}</option>
                                ))}
                                {/* <option selected value='null'>Phone type</option>
                                <option value="1">Office Phone</option>
                                <option value="2">Home Phone</option>
                                <option value="3">Mobile/Cell Phone</option>
                                <option value="4">Pager</option>
                                <option value="5">Office fax number</option>
                                <option value="6" selected>None</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                    <div className="col-auto mt-2">
                        <div role="checkbox" aria-checked='true' className="custom-control custom-checkbox custom-control-inline">
                            <input disabled={preferredPhoneInActive} checked={isChecked} type="checkbox" id='MakeItPreferred_2' name={"checkbox_MakeItPreferred_2"} className="custom-control-input" onChange={() => setChecked(!isChecked)} />
                            <label className="custom-control-label" htmlFor='MakeItPreferred_2'>make it preferred</label>
                        </div>
                    </div></div>
                    {!props.selectedValue ?
                        <div className="text-right">
                            <Button kind='shapeless' size='medium' className='mr-3' onClick={props.onCancel}>Cancel</Button>
                            <Button kind='primary' size='medium' onClick={savePhone} loading={validating}>Save</Button>
                        </div> :
                        <div className="address__row__links text-right">
                            <Button kind='shapeless_red' size='medium' className='' onClick={removePhoneNum} clickConfirmation='Are you sure to delete phone?'>Delete this Phone</Button>
                            <Button kind='shapeless' size='medium' className="" onClick={props.onCancel}>Cancel</Button>
                            <Button kind='primary' size='medium' className="" onClick={savePhone}>Save</Button>
                        </div>
                    }
                    {/*serverError && <ErrorDialog title="Internal Error">{serverError}</ErrorDialog>*/}
                </div>
            </div>
        </>
    )
}
