import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace ValueMappingApi {
    export const url = "/contacts/csv/:import_id/values_mapping";
    export const method = "POST";
    export const defaultError = "Failed to Mapped Columns.Please try later.";
    export const operations = apiOperations(ValueMappingApi);
    export const scope = new class ValueMappingApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string | IFieldMappings[]> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        import_id: string
        field_mappings: IFieldMappings[],
        queue?: string
    }

    export interface Response {
        file_key: string,
        import_id: string,
        has_value_mapping: string,
        mappings: IMappings[]

    }
    export interface IMappings {
        name_suffix?: IAddressMapping,
        gender?: IAddressMapping,
        title?: IAddressMapping,
        income?: IAddressMapping,
        score?: IAddressMapping,
        pipeline_status?: IAddressMapping,
        sources?: IAddressMapping,
        lead_type?: IAddressMapping,
        primary_phone_type?: IAddressMapping,
        phone_type_1?:IAddressMapping,
        phone_type_2?:IAddressMapping,
        phone_type_3?:IAddressMapping,
        
        

    }

    export interface IAddressMapping {
        csv: ICSVLabels[],
        crm: ICRMValueMapping[]
    }
    export interface ICSVLabels {
        short_value: string,
        long_value: string,
    }
    export interface ICRMValueMapping {
        short_value: string,
        long_value: string,
        display_order: string,
        is_filter: string,
        provider_value: string
    }

    export interface IFieldMappings {
        index: string,
        value: string
    }
}

export const fetchValueMappingService = forwardOperation(ValueMappingApi.operations, 'fetch');
export const useFetchValueMappingService = forwardOperation(ValueMappingApi.operations, 'useFetch');
export const useValueMappingService = forwardOperation(ValueMappingApi.operations, 'useService');