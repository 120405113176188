export class ServiceError<TData = undefined> extends Error
{
    private _data?: TData;
    public get data() { return this._data; }

    private _response?: Response;
    public get response() { return this._response; }

    public static from<TData = undefined>(source?:Error | string, response?:Response, data?:TData): ServiceError<TData>
    {
        const exception = source instanceof Error? source: undefined;
        const message = exception? exception.message: source as string;
        const cause = exception? exception: undefined;
        const error = new ServiceError<TData>(message, { cause });
        error._response = response;
        error._data = data;
        if(exception) {
            error.name = exception.name;
            error.stack = exception.stack;
        }
        
        return error;
    }

    private _defaultPrevented: boolean = false;
    public get isDefaultPrevented() { return this._defaultPrevented; }
    public preventDefault() { this._defaultPrevented = true; }
}