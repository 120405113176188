import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"

export namespace ObjectOwnerApi {
    export const url = "/typeaheads/object_owners";
    export const method = "GET";
    export const defaultError = "Something went wrong";
    export const operations = apiOperations(ObjectOwnerApi);
    export const scope = new class ObjectOwnerApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                input: input.input,
                resource_type: input.resource_type,
                limit: input.limit
            }
        }

        response(input: Response): Response {
            const { data } = input;
            return {
                data: data.map(this._mapObjectItem, this),
            }
        }

        _mapObjectItem(input: DeepPropertiesType<IObjectList, string>): IObjectList {
            const { agent_key, member_number, first_name, last_name, photo_url, email } = input;
            return {
                agent_key: agent_key,
                member_number: member_number,
                first_name: first_name,
                last_name: last_name,
                photo_url: photo_url,
                email: email
            };
        }
    }();

    export interface Request {
        input?: string
        resource_type?: string
        limit?: string
    }

    export interface Response {
        data: Array<IObjectList>
    }

    export interface IObjectList {
        agent_key: string,
        member_number?: string,
        first_name: string,
        last_name: string,
        photo_url: string,
        email: string,
    }
}

export const fetchObjectOwnerService = forwardOperation(ObjectOwnerApi.operations, 'fetch');
export const useFetchObjectOwnerService = forwardOperation(ObjectOwnerApi.operations, 'useFetch');
export const useObjectOwnerService = forwardOperation(ObjectOwnerApi.operations, 'useService');