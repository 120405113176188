import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useGetContactDetailService } from 'services/contacts/pipeline/getContact';
import { stagesOptions } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { IContactDetailPopoverProps } from './ContactDetailPopover.def';
import './ContactDetailPopover.scss';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import CopyToClipboard from 'react-copy-to-clipboard';
import { close } from 'ionicons/icons'
import { Avatar } from 'views/contacts/Avatar';
import { ContactTitle } from 'views/general/ContactTitle';
import { useRemoveContactService } from 'services/contacts/pipeline/removeContact';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { OrdinaryButton, PrimaryButton } from 'views/forms/buttons/Button';
import { Tag } from 'scripts/ui/tag';
import { parseISO, format } from 'date-fns'
import { useHistory } from 'react-router';
import { useAddNotesService } from 'services/contacts/contactDetails/addNotes';
import { TextArea } from 'views/forms/textarea/TextArea';
import { useUpdateNotesService } from 'services/contacts/contactDetails/updateNotes';
import { formatHarDate } from 'scripts/helpers/dates';
import { transformPhoneNumber } from 'scripts/helpers/texts';

export const ContactDetailPopoverDesktop = (props: IContactDetailPopoverProps): JSX.Element => {
    const { id, show, trigger, toggleShow, reload, viewProfile } = props
    const [contact, setContact] = useState<any>({})
    const [visible, setVisible] = useState(false)
    const [writeNote, setWriteNote] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alert, setAlert] = useState(false)
    const [contactDetail, contactDetailAction] = useGetContactDetailService()
    const [removeContactSer, removeContactAction] = useRemoveContactService()
    const history = useHistory()

    function numberFormat(amount: string) {
        var options = { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 0 };
        var formatter = new Intl.NumberFormat('en-US', options);
        if (formatter.format(Number(amount))) {
            return formatter.format(Number(amount))
        } else {
            return amount
        }
    }

    function getStageOption(status: string) {
        let stage = stagesOptions.filter((s) => Number(status) === s.short_value)
        return stage[0]
    }
    useServiceDidSucceeded(removeContactSer, () => {
        contactDetailAction.fetch({ id: id ? id : 0 })
        reload?.()
    });

    useEffect(() => {
        if (show) {
            contactDetailAction.fetch({ id: id ? id : 0 })
            setVisible(true)
            setWriteNote(false)
        } else {
            setVisible(false)
        }
    }, [show])

    useEffect(() => {
        if (alert) {
            setTimeout(() => {
                setAlert(false)
                setAlertMessage("")
            }, 5000)
        }
    }, [alert])


    useEffect(() => {
        if (contactDetail.response) {
            setContact(contactDetail.response)
        }
    }, [contactDetail])


    return (
        <>
            {alert &&
                <SimpleDialog message={alertMessage} />
            }
            <IonModal id="contact-detail-modal" className='PopoverContactDetailDk' isOpen={visible} trigger={trigger} onDidDismiss={() => toggleShow ? toggleShow() : ''} backdropDismiss={false}>





                <div className=" max-w-1000px ">
                    <div className="contact_detail">
                        <div className="p-4 border-bottom border-color--cloudy-sky-light position-relative">
                            <div className="text-center font_size--large font_weight--bold">Quick Overview</div>
                            <div className="position-absolute top-0 right-0px cursor--hand pt-3 pr-3" onClick={() => setVisible(false)}><IonIcon icon={close} size="large"></IonIcon></div>
                        </div>
                        {contactDetail.loading ?
                            <LoadingState />
                            :
                            <>
                                <div className="row no-gutters min-h-475px">
                                    <div className="col-8">
                                        <div className="p-4">
                                            {contact.last_contact_date &&
                                                <div className="warning w-100 mb-4">
                                                    <div className="warning__icon"><img src="https://content.harstatic.com/media/icons/event/clock.svg" /></div>
                                                    <div className="warning__text">
                                                        <span className="font_weight--semi_bold">{contact.last_contact_date_text} •</span> Last time you updated {contact?.first_name ? contact?.first_name : ''}
                                                    </div>
                                                </div>
                                            }

                                            <div className="pb-3 mb-3 border-bottom">


                                                <div className="d-flex">
                                                    <a className="agent_signature agent_signature__large mb-0 flex-grow-1">
                                                        <Avatar
                                                            id={contact.id}
                                                            photo={contact.photo_url}
                                                            firstName={contact.first_name}
                                                            middleName={contact.middle_name}
                                                            lastName={contact.last_name}
                                                        />
                                                        <div className="agent_signature__text">
                                                            <div className="agent_signature__text__title"><ContactTitle contact={contact} /></div>
                                                            <div className="agent_signature__text__address">
                                                                <div className="d-flex">
                                                                    <img className="align-self-center mr-2 w-14px" src="https://content.harstatic.com/media/icons/map.svg"></img>
                                                                    <div className="color_auxiliary align-self-center font_weight--semi_bold">{contact.city ? contact.city : 'no location'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>


                                                    {contact.pipeline_status && contact.pipeline_status !== '0' &&
                                                        <div className="mx-2">
                                                            <div className={`stage stage__large stage__${getStageOption(contact.pipeline_status)?.class}`}>{getStageOption(contact.pipeline_status)?.long_value}</div>
                                                        </div>

                                                    }
                                                    {contact.score ?
                                                        <div className="ml-auto ">
                                                            <div className={`score_label max-w-42 h-42px score_label__small score_label--${contact?.score.toLocaleLowerCase()}`} >
                                                                <span className="score_label__alpha">{contact.score}</span>
                                                                <span className="score_label__text mt-1">Score</span>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className="ml-auto">
                                                            <div className={`score_label score_label__medium score_label--n max-w-48 h-48px`} >
                                                                <span className="score_label__alpha">N/A</span>
                                                                <span className="score_label__text mt-1">Score</span>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                            {(contact.primary_phone || contact.primary_email) &&
                                                <div className="pb-3 mb-3 border-bottom">
                                                    {contact.primary_phone &&
                                                        <div className="d-inline-flex mr-4">
                                                            <div className="align-self-center"><img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/phone_carbon.svg" /></div>
                                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">
                                                                <CopyToClipboard text={transformPhoneNumber(contact.primary_phone_country_code, contact.primary_phone, contact.primary_phone_extension)}
                                                                    onCopy={() => { setAlertMessage("Phone number has been copied"); setAlert(true) }}>
                                                                    <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">{transformPhoneNumber(contact.primary_phone_country_code, contact.primary_phone, contact.primary_phone_extension)}</div>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                    }
                                                    {contact.primary_email &&
                                                        <div className="d-inline-flex">
                                                            <div className="align-self-center"><img className="mr-2 w-18px" src="https://content.harstatic.com/media/icons/email-lead.svg" /></div>
                                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium"><CopyToClipboard text={contact.primary_email}
                                                                onCopy={() => { setAlertMessage("Email has been copied"); setAlert(true) }}>
                                                                <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">{contact.primary_email}</div>
                                                            </CopyToClipboard></div>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="font_size--medium font_weight--bold mb-2">Upcoming Tasks/Events</div>
                                                    {contact.tasks && contact.tasks.length > 0 &&
                                                        <>
                                                            {contact.tasks.map((activity: any) =>
                                                                <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">
                                                                    <div className=" font_weight--bold">{activity.title}</div>
                                                                    <div className="pb-2">{format(parseISO(activity.start_dt), 'd MMM . hh:mm aa')} {activity.end_dt &&
                                                                        <>
                                                                            {' to ' + format(parseISO(activity.end_dt), 'd MMM . hh:mm aa')}
                                                                        </>
                                                                    } </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className={`label  label--${activity.type.toLowerCase()} mr-3 mb-0`}>{activity.type === 'EVENT' ? 'Event' : 'Task'}</div>
                                                                        {activity.priority_text &&
                                                                            <div className={`font_weight--semi_bold ${activity.priority_text?.toLowerCase() === 'high' && 'color_sold'}  ${activity.priority_text?.toLowerCase() === 'medium' && 'color_pending'} ${activity.priority_text?.toLowerCase() === 'low' && 'color_auxiliary_light'}`}>{activity.priority_text}</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <a className="font_size--small_extra font_weight--bold mt-2" href={`/contacts/${contact.id}/calendar`}>View all Tasks/Events</a>

                                                        </>
                                                    }
                                                    {contact.tasks && contact.tasks.length === 0 &&
                                                        <div className="font_size--small_extra pb-3">
                                                            <div className='color_custom_1'>No upcoming tasks/events</div>
                                                        </div>
                                                    }

                                                </div>

                                                <div className="col-6">
                                                    <div className="font_size--medium font_weight--bold mb-2">Upcoming Deal</div>
                                                    {contact?.deals && contact.deals.length > 0 &&
                                                        <div className="font_size--small_extra cursor--hand" onClick={() => history.push(`/contacts/${contact.id}/deals`, { referrer: '' })}>
                                                            {contact?.deals.map((deal: any, key: number) =>
                                                                <div key={key} className="d-flex mb-4">
                                                                    <Tag.Div className="bg--image mr-3 border_radius--default w-56px h-40px listing_signature_photo" cssVars={{ listingSignaturePhoto: "url(" + deal.property_photo + ")" }}></Tag.Div>
                                                                    <div>
                                                                        <div className="font_weight--bold">{deal.title}</div>
                                                                        <div>
                                                                            Deal Type: {deal.deal_type_text}<br />
                                                                            Start Date: {deal.created_dt}<br />
                                                                            Est. deadline: {format(parseISO(deal.estimated_deadline), 'MMM d, yyyy')}<br />
                                                                            Deal Value: {numberFormat(deal.deal_amount)}<br />
                                                                            Priority: {deal.deal_priority}<br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <a className="font_size--small_extra font_weight--bold mt-2" href={`/contacts/${contact.id}/deals`}>View all deals</a>
                                                        </div>
                                                    }
                                                    {contact.deals && contact.deals.length === 0 &&
                                                        <div className="font_size--small_extra pb-3 ">
                                                            <div className='color_custom_1'>No upcoming deal</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="p-4">
                                            <NotesComponnent contact={contact} writeNote={writeNote} setWriteNote={setWriteNote} onSave={() => contactDetailAction.fetch({ id: id ? id : 0 })} />

                                        </div>
                                    </div>
                                </div>

                                <div className="pl-4 pr-4 pt-3 pb-3 border-top border-color--cloudy-sky-light">
                                    <div className="row no-gutters justify-content-center">
                                        {(contact.pipeline_status && contact.pipeline_status !== '0') &&
                                            <div className="col"></div>
                                        }
                                        <div className={`col ${!contact.pipeline_status || contact.pipeline_status === '0' && `text-center`}`}>
                                            <IonButton fill="clear" class="btn btn--primary btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--primary
    " role="button" onClick={() => {
                                                    setVisible(false)
                                                    viewProfile?.()
                                                }}>View Profile </IonButton>
                                        </div>
                                        {(contact.pipeline_status && contact.pipeline_status !== '0') &&
                                            <div className="col-auto ml-auto">

                                                <OrdinaryButton onClick={() => {
                                                    removeContactAction.fetch({ id: Number(contact.id) })
                                                }} class="btn--shapeless_light ml-auto btn btn--shapeless_red btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--shapeless_red
    " loading={removeContactSer.loading} disabled={removeContactSer.loading}>Remove from Contact Pipeline</OrdinaryButton>

                                            </div>}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div >



            </IonModal >
        </>

    );



}

const LoadingState = () => {
    return (
        <>
            <div className="row no-gutters">
                <div className="col-8">
                    <div className="p-4">
                        <div className="d-flex w-100 mb-4">
                            <div className="warning__icon"><img src="https://content.harstatic.com/media/icons/event/clock.svg" /></div>
                            <div className="warning__text agent_signature__loading__textline h-48px min-w-400px"> </div>
                        </div>
                        <div className="pb-3 mb-3 border-bottom">
                            <div className="d-flex">
                                <a href="#" className="agent_signature agent_signature__large agent_signature__loading mb-0 flex-grow-1">
                                    <div className="agent_signature__photo loading"></div>
                                    <div className="agent_signature__text">
                                        <div className="agent_signature__loading__textline max-w-50"></div>
                                        <div className="agent_signature__text__address">
                                            <div className="d-flex">
                                                <img className="align-self-center mr-2 w-14px" src="https://content.harstatic.com/media/icons/map.svg"></img>
                                                <div className="agent_signature__loading__textline min-w-100px"></div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div className="ml-auto pr-3">
                                    <div className="loading_text min-w-100px"></div>
                                </div>
                                <div className="score_label score_label__medium score_label--a max-w-48 h-48px ml-auto mr-0 loading_text">
                                </div>
                            </div>
                        </div>

                        <div className="pb-3 mb-3 border-bottom">
                            <div className="d-inline-flex mr-4">
                                <div className="align-self-center"><img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/phone_carbon.svg" /></div>
                                <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">
                                    <div className="agent_signature__loading__textline min-w-120px h-30px"></div>
                                </div>
                            </div>

                            <div className="d-inline-flex">
                                <div className="align-self-center"><img className="mr-2 w-18px" src="https://content.harstatic.com/media/icons/email-lead.svg" /></div>
                                <div className="agent_signature__loading__textline min-w-172px h-30px"></div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="font_size--medium font_weight--bold mb-3">
                                    <div className="agent_signature__loading__textline min-w-172px h-30px"></div>
                                </div>

                                <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">
                                    <div className=" font_weight--bold"> <div className="agent_signature__loading__textline min-w-100px h-20px"></div></div>
                                    <div className="pb-2"><div className="loading__textline min-w-100px h-20px"></div></div>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-3 mb-0">
                                            <div className="agent_signature__loading__textline min-w-72px h-20px"></div>
                                        </div>
                                        <div className="font_weight--semi_bold ">
                                            <div className="agent_signature__loading__textline min-w-100px h-20px"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">
                                    <div className=" font_weight--bold"><div className="agent_signature__loading__textline  min-w-100px h-20px"></div></div>
                                    <div className="pb-2">
                                        <div className="agent_signature__loading__textline min-w-100px"></div>
                                    </div>
                                    <div className="d-flex align-items-center">

                                        <div className="agent_signature__loading__textline min-w-100px h-20px"></div>
                                        <div className="font_weight--semi_bold ml-3">
                                            <div className="agent_signature__loading__textline min-w-100px h-20px"></div>
                                        </div>
                                    </div>
                                </div>

                                <a className="font_size--small_extra font_weight--bold">
                                    <div className="agent_signature__loading__textline min-w-100px"></div>
                                </a>
                            </div>

                            <div className="col-6">
                                <div className="font_size--medium font_weight--bold mb-4">
                                    <div className="agent_signature__loading__textline min-w-172px h-30px"></div>
                                </div>
                                <div className="font_size--small_extra cursor--hand">
                                    <div className="d-flex mb-4">
                                        <div className="loading__textline min-w-100px h-48px" ></div>
                                        <div>
                                            <div className="font_weight--bold">
                                                <div className="agent_signature__loading__textline  min-w-100px "></div>
                                            </div>
                                            <div>
                                                <div className="agent_signature__loading__textline  min-w-100px mt-1"></div>
                                                <div className="agent_signature__loading__textline  min-w-100px mt-1"></div>
                                                <div className="agent_signature__loading__textline  min-w-100px mt-1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="font_size--small_extra font_weight--bold">
                                        <div className="agent_signature__loading__textline min-w-100px"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="p-4">
                        <div className="font_size--medium font_weight--bold mb-3"> <div className="agent_signature__loading__textline  min-w-100px h-36px"></div></div>
                        <div className=" mb-4">

                            <div className="note__text min-h-200px  agent_signature__loading__textline" >

                            </div>
                            <div className='row pt-3'>
                                <div className='col'>
                                    <div className="agent_signature__loading__textline  min-w-70px"></div>
                                </div>
                                <div className='col-auto'>
                                    <div className="agent_signature__loading__textline  min-w-100px"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="pl-4 pr-4 pt-3 pb-3 border-top border-color--cloudy-sky-light">
                <div className="row no-gutters justify-content-center">
                    <div className='col'></div>
                    <div className="col">
                        <PrimaryButton disabled={true} loading={true} class="btn btn--primary btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--primary
    " >View Profile </PrimaryButton>
                    </div>
                    <div className="col-auto ml-auto">

                        <OrdinaryButton className="btn--shapeless_light ml-auto btn btn--shapeless_red btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--shapeless_red
    " loading={true} disabled={true}>Remove from Contact Pipeline</OrdinaryButton>
                    </div>
                </div>
            </div>
        </>
    )
}

const NotesComponnent = (props: { contact: any, setWriteNote: React.Dispatch<React.SetStateAction<boolean>>, writeNote: boolean, onSave?: () => void }) => {
    const { contact, writeNote, } = props
    const [addNoteSer, setAddNoteSerAct] = useAddNotesService()
    const [editNoteSer, setEditNoteSerAct] = useUpdateNotesService()
    const [timer, setTimer] = useState(0)
    const [loading, setLoading] = useState(false)
    const [initiating, setInitiating] = useState(false)
    const [nodeText, setNodeText] = useState('')
    const [noteId, setNoteId] = useState(0)
    const [localNote, setNote] = useState<{ id: number, notes: string | undefined, focus?: boolean, created_dt?: string }[]>()
    const saveNote = async (e: any, node_id?: number) => {
        e.preventDefault()
        if (e.target.value === '' || addNoteSer.loading || editNoteSer.loading) {
            return
        }
        if (timer) {
            clearTimeout(timer)
        }
        setInitiating(true)
        setTimer(setTimeout(() => {
            setInitiating(false)
            if (node_id === 0 || (node_id && node_id < 0)) {
                setAddNoteSerAct.fetch({ contact_id: contact.id, notes: e.target.value })
                setNodeText(e.target.value)
            } else if (node_id) {
                setEditNoteSerAct.fetch({ contact_id: contact.id, notes: e.target.value, notes_id: node_id.toString() })

                localNote?.map((note) => {
                    if (note.id === node_id) {
                        note.notes = e.target.value
                    }
                    return note
                })
            }


        }, 600))

        return () => {
            clearTimeout(timer);
        };

    }

    const getMinId = (): number => {
        const totals = localNote
            ?.map(x => x.id);
        return Math.min(...totals ?? []);
    }


    const datePattern = (date: string) => {
        const pattern = date.split(' ');
        if (pattern?.[0] && pattern?.[1]) {
            let format = new Date(Date.parse(pattern?.[0])).toDateString();
            return `${format}   ${pattern?.[1]}`;
        } else {
            return false
        }


    }


    useServiceDidSucceeded(addNoteSer, (resp: any) => {
        localNote?.map((note) => {
            if (note.id === noteId) {
                note.id = resp.id
                note.notes = nodeText
            }
            return note
        })
        setNoteId(resp.id)

    })

    useEffect(() => () => {
        if (timer) { clearTimeout(timer); }
    }, []);

    useEffect(() => {
        if (contact.notes && contact.notes.length > 0) {
            let notes: any = []
            contact.notes.map((note: any) => {
                notes.push({ id: note.id, notes: note.notes, created_dt: note.created_dt, focus: true })
            });
            setNote(notes)
        } else {
            setNote([{ id: 0, notes: '', focus: false }])
            setNoteId(0)
        }
    }, [contact.notes])




    return (
        <>

            <div className="font_size--medium font_weight--bold mb-3">Notes</div>
            <div className={`${loading && `opacity-64pr`}`}>
                {localNote?.map((note: any, key: number) =>
                    noteId === note.id ?
                        <div className={`autoheight_container s1 ion-padding bg-color-fff29b mb-2 `} >
                            <TextArea autoFocus={note.focus} rows={10} showCounter={true} defaultValue={note.notes} maxLength={3000} className="form-control form-control__notes ion-no-border border-0 textarea__atuogrow font_size--small" placeholder="Write some notes..." onChange={(e: any) => saveNote(e, note.id)} />
                        </div>
                        :
                        <div className="note overflow-hidden mb-4" key={key}>
                            {note.created_dt &&
                                <div className="note__date">{datePattern(note.created_dt) ? datePattern(note.created_dt) : formatHarDate(note.created_dt)}</div>
                            }
                            <div className="note__text h-110px verticalScollable">{note.notes}</div>
                            <div className="note__edit pt-2"><a className='cursor--hand' onClick={() => {
                                setNodeText(note.notes)
                                setNoteId(note.id)
                            }}>Edit</a></div>
                        </div>
                )}
            </div>

            {localNote && localNote?.length === 0 &&
                <div className={`color_custom_1 min-h-200px verticalScollable ${!writeNote ? `display-block` : `display-none`}`}>No notes available</div>
            }
            <div className="row pt-3">
                <div className="col"><a className={`font_weight--bold cursor--hand ${(addNoteSer.loading || editNoteSer.loading || initiating || loading) ? `opacity-64pr` : ``}`} onClick={() => {
                    if (addNoteSer.loading || editNoteSer.loading || initiating || loading) {
                        return
                    }
                    let newNote: any = [];
                    var nid: number;
                    setLoading(true)
                    if (localNote) {
                        if (localNote?.filter((note) => note.id <= 0).length > 0) {
                            nid = getMinId() - 1;
                        } else {
                            nid = 0
                        }
                        newNote = [{ id: nid, notes: '', focus: true }, ...localNote.slice(0, 1)]
                        setNoteId(nid);
                    }
                    let timer = setTimeout(() => {
                        setNote(newNote)
                        setLoading(false)
                        setNoteId(nid)
                    }, 1000);
                    return () => {
                        clearTimeout(timer);
                    };

                }}>+ Write Note</a></div>
                {localNote && localNote?.filter((note) => note.id !== 0).length > 0 &&
                    <div className="col-auto"><a href={`/contacts/${contact.id}/notes`} className="font_weight--bold" >View all Notes</a></div>
                }
            </div>
            <div className='d-none'>
                <div className='alert-success font_size--small ml-auto h-auto px-2 ml-auto'>{`Notes have been saved.`}</div>
            </div>
        </>
    )
}
