import { ValidationSignal } from "scripts/hooks/validations";
import { IUpdateState } from "../ContactInsightSection/ContactInsightSection.def";

export interface IBasicInformationSectionProps {
    form?: IBasicInformationSectionForm;
    data?: IBasicInfoData;
    validate?: boolean | ValidationSignal;
    onItemsChange?: (basicInfo: IBasicInfoData) => void;
    onFormChange?: (value: React.SetStateAction<IBasicInformationSectionForm>) => void;
    onValidationChange?: (isValid: boolean) => void;
    id?: string;
    loading?: boolean;
    notifyImg?: (data: Array<IUpdateState>) => void;
    toggleEdit?: (value: boolean) => void;
    onSuccess?: () => void;
    errorRef?: { error:boolean };
    isEdit?:boolean;
    handleSave?():void;
}

export interface IBasicInfoData {
    id?: number;
    image?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    title?: string,
    suffix?: string,
    gender?: string;
    dateOfBirth?: string;
    knownSince?: string;
    homeAniversary?: string;
    weddingAniversary?: string;
    income?: string;
    jobTitle?: string;
    company?: string;
    reminder_days_after?:Array<string>
    reminder_days_after_text?:string

}

export enum genderType {
    None = '',
    Male = 'Male',
    Female = 'Female',
    Other = 'Other',
}


export interface IBasicInfoDataLocation {
    editMode?: boolean
}

export const basicInfoDataIntial= {
    image: "",
    firstName: "",
    middleName:"",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    knownSince: "",
    homeAniversary:"",
    weddingAniversary: "",
    income: "",
    jobTitle: "",
    company: "",
    reminder_days_after:[],
    reminder_days_after_text:""
}

interface IFormField<T = any> {
    value: T;
    error: string;
  }
  
export type IBasicInformationSectionForm = {
    [K in keyof IBasicInfoData]: IFormField<IBasicInfoData[K]>;
};

// export const incomeLookup = {
//     caption: "Income",
//     items: [
//         {
//             "short_value": "1mplus",
//             "long_value": "$1,000,000 and over",
//             "display_order": "1"
//         },
//         {
//             "short_value": "500k-1m",
//             "long_value": "$500,000 to $1,000,000",
//             "display_order": "1"
//         },
//         {
//             "short_value": "200k-500k",
//             "long_value": "$200,000 to $500,000",
//             "display_order": "2"
//         },
//         {
//             "short_value": "100k-200k",
//             "long_value": "$100,000 to $200,000",
//             "display_order": "3"
//         },
//         {
//             "short_value": "75k-100k",
//             "long_value": "$75,000 to $100,000",
//             "display_order": "4"
//         },
//         {
//             "short_value": "50k-75k",
//             "long_value": "$50,000 to $75,000",
//             "display_order": "5"
//         },
//         {
//             "short_value": "40k-50k",
//             "long_value": "$40,000 to $50,000",
//             "display_order": "6"
//         },
//         {
//             "short_value": "30k-40k",
//             "long_value": "$30,000 to $40,000",
//             "display_order": "7"
//         },
//         {
//             "short_value": "20k-30k",
//             "long_value": "$20,000 to $30,000",
//             "display_order": "9"
//         }
//     ]
// }     