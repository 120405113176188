import { useResponsiveRender } from "scripts/hooks/general";
import { DefaultTemplate } from "views/templates/DefaultTemplate";
import { useState } from 'react';
import { IonButton, IonHeader, IonIcon } from "@ionic/react";
import { OrdinaryButton, PrimaryButton, ShapelessButton } from "views/forms/buttons/Button";
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import React, { useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ScoreOptionsDesktop } from "views/contacts/ScoreOptions/ScoreOptions.desktop";
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Textbox } from "views/forms/textboxes/Textbox";
import { IonInput } from "@ionic/react";



export function ContactPlayground()
{
    // For Responsive
    return useResponsiveRender(ContactPlaygroundDesktop, ContactPlaygroundApp);

    // For Web / App
    //return useComponent(ContactPlaygroundDesktop, ContactPlaygroundApp);
}

export function ContactPlaygroundDesktop()
{     
    const classes = ['bg_option_1', 'bg_option_2', 'bg_option_3', 'bg_option_4', 'bg_option_5', 'bg_option_6'];
    const [currentClass, setCurrentClass] = useState(0);

    const handleClick = () => {
    setCurrentClass((currentClass + 1) % classes.length);
    };

    const [showStageModal, setshowQuickEditsModal] = useState(false);
    const [showFiltersModal, setshowFiltersModal] = useState(false);
    const [showScoreModal, setshowCreateContact] = useState(false);
    const [showExportModal, setshowExportCSVModal] = useState(false);

    const popover_score = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Score</Popover.Title>
            <Popover.Content>
            The scoring system allows you to assign grades (e.g., A, B, C, D) to your Contacts. Assigning a Score helps to identify and prioritize the most qualified and sales-ready Contacts, enabling you to focus your efforts and allocate resources effectively.
            </Popover.Content>
        </Popover>
    );


    const [isMoreFiltersVisible, setMoreFiltersVisible] = useState(false);
    const [isEditingNote, setIsEditingNote] = useState(false);
    const [noteContent, setNoteContent] = useState('Add a note here...');
    

    const defaultPopUpBodyRef = useRef<HTMLDivElement>(null);
    const toggleMoreFiltersVisibility = () => {
        setMoreFiltersVisible(!isMoreFiltersVisible);

        const element = defaultPopUpBodyRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    };

    const handleNoteClick = () => {
        setIsEditingNote(true);
    };
    const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNoteContent(event.target.value);
    };
    const handleNoteBlur = () => {
        setIsEditingNote(false);
    };

    return (
        <>
        <DefaultTemplate title="Contact Playground">
            <div className="header_blue position-relative top-0 d-none">
                <div className="container container__large pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-auto col-12">
                            <div  className="ap_change cursor--hand agent_signature agent_signature__large__extra agent_signature__nagative mb-0 animate__animated animate__fadeInUp animate__faster">
                                <div className="agent_signature__photo" style={{ backgroundImage: 'url(https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703)' }}>
                                    <div className="ap_overlay">
                                        <img src="https://content.harstatic.com/media/icons/icon-change-picture.svg"></img>
                                        <div>Change</div>
                                    </div>
                                </div>
                                <div className="agent_signature__text">
                                    <div className="agent_signature__text__title">David R. Putnam</div>                                
                                    <div className="agent_signature__text__tags">
                                        <div className="agent_signature__text__tags_tag"><img style={{ width: '14px' }} src="https://content.harstatic.com/media/icons/phone_white_outline.svg" /> (713) 629-1900</div>
                                        <div className="agent_signature__text__tags_tag"><img className="mr-2" style={{ width: '14px' }} src="https://content.harstatic.com/media/icons/email_white_outline.svg" /> Email</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md pt-4 col-12 align-self-center text-md-right animate__animated animate__fadeIn animate__faster">
                            <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm">
                               
                                <div className="pr-md-4 pr-3">
                                    <div className="score_label score_label--a">
                                        <span className="score_label__alpha">A</span>
                                        <span className="score_label__text">Score</span>
                                    </div>
                                </div>
                                <div className="align-self-center color_snow_white pr-md-4 pr-3" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.16)' }}>
                                    <div className="font_weight--bold">Stage</div>
                                    <div><div className="stage mb-0 stage__cold">Cold</div></div>
                                </div>
                                
                                <div className="align-self-center color_snow_white pl-md-4 pl-3  pr-md-4 pr-3 border-right_custom_1">
                                    <div className="font_weight--bold">Source</div>
                                    <div>HAR.com</div>
                                </div>
                                <div className="align-self-center color_snow_white pl-md-4 pl-3">
                                    <div className="font_weight--bold">Created Date</div>
                                    <div>23th Jun 2024</div>
                                </div>                                
                                <div className="align-self-center color_snow_white pl-md-4 pl-3">
                                    <OrdinaryButton className="ml-4">Edit Contact</OrdinaryButton>                             
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className={`alert_bar ${classes[currentClass]}`}>
                <div className="container">
                    <div className="row small-gutters align-items-center">
                        <div className="col-auto">
                            <img src="https://content.harstatic.com/media/icons/warning.svg"></img>
                        </div>
                        <div className="col font_size--medium">
                                <span className="font_weight--bold">Lorem Ipsum</span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since <span className=" cursor--hand font_weight--bold"  onClick={handleClick}>Change Color</span>.
                        </div>
                        <div className="col-auto">
                            <a href="#"><img src="https://content.harstatic.com/media/icons/close_medium_white.svg"></img></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <br /><br /><br /><br />

            <div className="container pt-5 mt-5">

                {/* Tabs for email items */}
                <div style={{maxWidth: '500px'}}>
                    <Tabs
                        id="controlled-tab-example"                        
                        className="mb-3 nav nav-tabs nav-tabs--filters"
                        //activeKey={'inbox'}
                    >
                        <Tab eventKey="inbox" title="Inbox">
                            <div>Inbox content goes here.</div>
                        </Tab>
                        <Tab eventKey="leads" title="Leads">
                            <div>Leads content goes here.</div>
                        </Tab>
                        <Tab eventKey="draft" title="Draft">
                            <div>Draft content goes here.</div>
                        </Tab>
                        <Tab eventKey="sent" title="Sent">
                            <div>Sent content goes here.</div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <br /><br /><br /><br />

            <div className="container pt-5 mt-5">
            <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowFiltersModal(true)} >Open Filters</button>

            <DefaultPopUp title="Filters" class="custom--modal__fullheight custom--modal__filters" isOpen={showFiltersModal} onDidDismiss={() => setshowFiltersModal(false)} >
                <DefaultPopUp.Body>
                    <div className="py-4 px-5">                            
                        <div className="font_size--large font_weight--semi_bold mb-2">Location</div>
                        <div className="row">
                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                <label>City</label>
                                <Textbox className="form-control--large mb-3" title="" placeholder="City" />
                            </div>
                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                <label>Zip Code</label>
                                <Textbox className="form-control--large" title="" placeholder="Zip Code" />
                            </div>
                        </div>
                        
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className=" font_size--large font_weight--semi_bold mb-2">Last contact</div>
                                <select className="custom-select custom-select_medium custom-select_large">
                                    <option value="lc_1">Less than a week ago</option>
                                    <option value="lc_2">3 months ago</option>
                                    <option value="lc_3">6 months ago</option>
                                    <option value="lc_4">9 months ago</option>
                                    <option value="lc_5">12 months ago</option>
                                    <option value="lc_6">More than 1 year</option>
                                </select>
                            </div>
                        </div>  
                            
                        <div className=" font_size--large font_weight--semi_bold mb-3">Stages</div>
                        <div className="row mb-2">
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="10" name="10" className="custom-control-input" value="10" />
                                    <label className="custom-control-label stage" htmlFor="10">
                                        <div className="stage stage stage__new">New</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="20" name="20" className="custom-control-input" value="20" />
                                    <label className="custom-control-label stage"  htmlFor="20">
                                        <div className="stage stage stage__qualified">Qualified</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="30" name="30" className="custom-control-input" value="30" />
                                    <label className="custom-control-label stage"  htmlFor="30">
                                        <div className="stage stage stage__cold">Cold</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="40" name="40" className="custom-control-input" value="40" />
                                    <label className="custom-control-label stage"  htmlFor="40">
                                        <div className="stage stage stage__warm">Warm</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="50" name="50" className="custom-control-input" value="50" />
                                    <label className="custom-control-label stage"  htmlFor="50">
                                        <div className="stage stage stage__hot">Hot</div>
                                    </label>
                                </div>
                            </div>                            
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="100" name="100" className="custom-control-input" value="100" />
                                    <label className="custom-control-label stage"  htmlFor="100">
                                        <div className="stage stage stage__closed">Closed</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="60" name="60" className="custom-control-input" value="60" />
                                    <label className="custom-control-label stage"  htmlFor="60">
                                        <div className="stage stage stage__undercontract ">Active Listing / Under Contract</div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-3">
                                <div role="checkbox" className="custom-control custom-checkbox">
                                    <input type="checkbox" id="0" name="0" className="custom-control-input" value="0" />
                                    <label className="custom-control-label stage"  htmlFor="0">
                                        <div className="stage ">None</div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="pb-4">
                            <div className=" font_size--large font_weight--semi_bold mb-3">Deals</div>                                                          
                            <div className="row">
                                <div className="col-4">
                                    <div role="checkbox" className="custom-control custom-checkbox"><input type="checkbox" id="A" name="A" className="custom-control-input" value="A" /><label className="custom-control-label" htmlFor="A">Active/Offers</label></div>
                                </div>
                                <div className="col-4">
                                    <div role="checkbox" className="custom-control custom-checkbox"><input type="checkbox" id="I" name="I" className="custom-control-input" value="I" /><label className="custom-control-label" htmlFor="I">In Contract</label></div>
                                </div>
                                <div className="col-4">
                                    <div role="checkbox" className="custom-control custom-checkbox"><input type="checkbox" id="C" name="C" className="custom-control-input" value="C" /><label className="custom-control-label" htmlFor="C">Closed</label></div>
                                </div>
                                <div className="col-4">
                                    <div role="checkbox" className="custom-control custom-checkbox"><input type="checkbox" id="F" name="F" className="custom-control-input" value="F" /><label className="custom-control-label" htmlFor="F">Fell Through</label></div>
                                </div>
                                <div className="col-4">
                                    <div role="checkbox" className="custom-control custom-checkbox"><input type="checkbox" id="N" name="N" className="custom-control-input" value="N" /><label className="custom-control-label" htmlFor="N">No Deals</label></div>
                                </div>
                            </div>
                        </div>

                            <div className="mb-2">
                                <div className=" font_size--large font_weight--semi_bold mb-2">Score</div>
                                <div id="Source" className="btn-group btn-group-toggle btn-group--source mb-4" data-toggle="buttons">
                                    <label className="btn btn--a"><input type="checkbox" name="options" id="option0" /><span className="btn__alpha">A</span><span className="btn__text">Score</span></label>
                                    <label className="btn btn--b"><input type="checkbox" name="options" id="option1" /><span className="btn__alpha">B</span><span className="btn__text">Score</span></label>
                                    <label className="btn btn--c"><input type="checkbox" name="options" id="option2" /><span className="btn__alpha">C</span><span className="btn__text">Score</span></label>
                                    <label className="btn btn--d"><input type="checkbox" name="options" id="option3" /><span className="btn__alpha">D</span><span className="btn__text">Score</span></label>
                                    <label className="btn btn--n"><input type="checkbox" name="options" id="option4" /><span className="btn__alpha"></span><span className="btn__text">None Score</span></label>
                                </div>
                                                      
                            </div>


                            <div className="mb-4">
                                <div className=" font_size--large font_weight--semi_bold mb-2">Tags</div>                                                          
                                <IonInput className="form-control form-control--large tags-input" placeholder="Tags" type="text" required={true}> </IonInput>
                            </div>
                            <div className="mb-4">
                                    
                                    <div className=" font_size--large font_weight--semi_bold mb-2">Sources</div>                                                          
                                    <select className="custom-select custom-select_medium custom-select_large">
                                        <option value="lc_1">All Sources</option>
                                        <option value="lc_2">Option 1</option>
                                        <option value="lc_3">Option 2</option>
                                        <option value="lc_4">Option 3</option>
                                    </select>                            
                                
                            </div>
                           
                            
                            <div className={`more-filters-content ${isMoreFiltersVisible ? 'slide-out' : 'slide-in'}`}>
                                    <div className="mb-4">
                                            
                                            <div className=" font_size--large font_weight--semi_bold mb-2">Lead type</div>                                                          
                                            <select className="custom-select custom-select_medium custom-select_large">
                                                <option value="lc_1">Buyer</option>
                                                <option value="lc_2">Seller</option>
                                                <option value="lc_3">Renter</option>
                                                <option value="lc_4">Prospect</option>
                                                <option value="lc_4">Investor</option>
                                            </select>                            
                                        
                                    </div>

                                    <div className="mb-4">
                                        <div className=" font_size--large font_weight--semi_bold mb-2">Companies</div>                                                          
                                        <Textbox className="form-control--large mb-3" title="" placeholder="Company" />                            
                                    </div>
                                    
                                    <div className="mb-0">
                                        <div className=" font_size--large font_weight--semi_bold mb-2">Date of birth</div>     
                                        <div className="row">
                                            <div className="col-md-6 col-12 pb-md-2 pb-0">
                                                <label>Start Date</label>
                                                <Textbox className="form-control--large mb-3" title="" placeholder="Start Date" />
                                            </div>
                                            <div className="col-md-6 col-12 pb-md-2 pb-0">
                                                <label>End Date</label>
                                                <Textbox className="form-control--large" title="" placeholder="End Date" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-0">
                                        <div className=" font_size--large font_weight--semi_bold mb-2">Anniversary</div>     
                                        <div className="row">
                                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                                <label>Start Date</label>
                                                <Textbox className="form-control--large mb-3" title="" placeholder="Start Date" />
                                            </div>
                                            <div className="col-md-6 col-12 pb-md-3 pb-0">
                                                <label>End Date</label>
                                                <Textbox className="form-control--large" title="" placeholder="End Date" />
                                            </div>
                                        </div>
                                    </div>
                            </div>

                            <div id="BtnMoreFilters" className="mb-4 font_size--medium cursor--hand font_weight--bold color_har_blue" onClick={toggleMoreFiltersVisibility}>{isMoreFiltersVisible ? 'More Filters' : 'Hide more Filters'}</div>
                            
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <div className="row">
                            <div className="col-auto">
                                <div style={{minWidth: '100px'}}></div>
                            </div>
                            <div className="col">
                                <PrimaryButton onClick={() => setshowFiltersModal(false)} className="btn--primary btn w-100px">Apply</PrimaryButton>
                            </div>
                            <div className="col-auto text-right">
                                <div style={{minWidth: '100px'}}><ShapelessButton onClick={() => setshowFiltersModal(false)} >Cancel</ShapelessButton></div>
                            </div>
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>

            <br /><br />


                
                <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowQuickEditsModal(true)} >Open Quick Edits</button>

                <DefaultPopUp title="Quick Edit" isOpen={showStageModal} onDidDismiss={() => setshowQuickEditsModal(false)} >
                    <DefaultPopUp.Body>
                        <div className="text-center pt-3 font_size--medium border-bottom pb-3 mb-4"><span className="font_weight--bold">Taqi Rizvi</span> <span className="color_auxiliary">(taqi@har.com)</span></div>
                        <div className="py-4 px-5">                            
                            <Tabs defaultActiveKey="Stages" transition={false} id="ContactTabs" className="nav nav-tabs nav-tabs--simple ">
                                <Tab eventKey="Stages" title="Stages" className="mt-4">                                    
                                    <div className="">                  
                                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Stage</div>
                                        <div className="row max-w-470">
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_1" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_1`}><div className={`stage stage stage__new`}>New</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_2" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_2`}><div className={`stage stage stage__qualified`}>Qualified</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_3" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_3`}><div className={`stage stage stage__cold`}>Cold</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_4" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_4`}><div className={`stage stage stage__warm`}>Warm</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_5" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_5`}><div className={`stage stage stage__hot`}>Hot</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_6" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_6`}><div className={`stage stage stage__undercontract`}>Active Listing</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_7" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_7`}><div className={`stage stage stage__closed`}>Closed</div></label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div role="radio" className="custom-control custom-radio">
                                                    <input type="radio" name={`Stage`} value={'Stage'} id="Stage_8" className="custom-control-input"/>
                                                    <label className='custom-control-label stage' htmlFor={`Stage_8`}><div className={`stage stage`}>None</div></label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>{/* Stage tab ended */}
                                <Tab eventKey="Score" title="Score" className="mt-4">
                                    <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Score</div>
                                    <ScoreOptionsDesktop></ScoreOptionsDesktop>
                                </Tab>{/* Score tab ended */}
                                <Tab eventKey="Source" title="Source" className="mt-4">
                                    <div className="">                  
                                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Source</div>
                                        <select className="custom-select custom-select_medium custom-select_large">
                                            <option value="None">None</option>
                                            <option value="HAR.com">HAR.com</option>
                                            <option value="Personal Website">Personal Website</option>
                                            <option value="Direct Mail">Direct Mail</option>
                                            <option value="Past Client">Past Client</option>
                                            <option value="Referral">Referral</option>
                                            <option value="Former Coworker">Former Coworker</option>
                                            <option value="Online Advertising">Online Advertising</option>
                                            <option value="Online Advertising (Google Ads)">Online Advertising (Google Ads)</option>
                                            <option value="Online Advertising (Facebook)">Online Advertising (Facebook)</option>
                                            <option value="Purchased Leads (Zillow)">Purchased Leads (Zillow)</option>
                                            <option value="Purchased Leads (Realtor.com)">Purchased Leads (Realtor.com)</option>
                                            <option value="FSBO Listings">FSBO Listings</option>
                                            <option value="Expired Listings">Expired Listings</option>
                                            <option value="Social Media Marketing">Social Media Marketing</option>
                                            <option value="Open Houses">Open Houses</option>
                                            <option value="Sign Call">Sign Call</option>
                                            <option value="gmail">Email Provider "Gmail"</option>
                                            <option value="outlook">Email Provider "Outlook"</option>
                                            <option value="yahoo">Email Provider "Yahoo"</option>
                                            <option value="aol">Email Provider "AOL"</option>
                                            <option value="icloud">Email Provider "iCloud"</option>
                                            <option value="hotmail">Email Provider "Hotmail"</option>
                                            <option value="custom">Email Provider "Custom"</option>
                                            <option value="soi">SOI (Sphere of Influence)</option>
                                            <option value="eas">Email Provider "Microsoft"</option>
                                            <option value="client_portal">Client Portal</option>
                                            <option value="showingsmart">ShowingSmart</option>
                                            <option value="other">Other</option>
                                            </select>
                                    </div>
                                </Tab>{/* Source tab ended */}
                                <Tab eventKey="Tags" title="Tags" className="mt-4">
                                <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Tags <OverlayTrigger trigger="hover" placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                                    <div className="tagsinput mb-2">		    	
                                        <div className="tagsinput__item">                                            
                                            <div className="filterpill filterpill--closeable2"><div>Big House</div><a href="#"></a></div>                                            
                                            <div className="filterpill filterpill--closeable2"><div>Pets</div><a href="#"></a></div>                                            
                                            <div className="filterpill filterpill--closeable2"><div>Big House</div><a href="#"></a></div>                                            
                                            <div className="tagsinput__input">
                                                <input type="text" className="form-control" placeholder="Tags" autoFocus  required={true}/>
                                            </div>
                                        </div>		    	
                                    </div>
                                </Tab>{/* Tags tab ended */}
                            </Tabs>
                        </div>
                    </DefaultPopUp.Body>
                    <DefaultPopUp.ActionBar>
                        <div className="text-center p-2">
                            <div className="row">
                                <div className="col-auto">
                                    <div style={{minWidth: '100px'}}></div>
                                </div>
                                <div className="col">
                                    <PrimaryButton onClick={() => setshowQuickEditsModal(false)} className="btn--primary btn w-100px">Apply</PrimaryButton>
                                </div>
                                <div className="col-auto text-right">
                                    <div style={{minWidth: '100px'}}><ShapelessButton onClick={() => setshowQuickEditsModal(false)} >Cancel</ShapelessButton></div>
                                </div>
                            </div>
                        </div>
                    </DefaultPopUp.ActionBar>
                </DefaultPopUp>
                
                <br /><br />
                <button role="button" id="deleteContactPopUp" className="btn btn--simple btn--simple__auxiliary btn--small mr-2" aria-label="Delete" onClick={() => setshowExportCSVModal(true)} > Export CSV popup</button>

                <DefaultPopUp title="Export" className=" custom--modal__fullheight custom--modal__export" isOpen={showExportModal} onDidDismiss={() => setshowExportCSVModal(false)} >
                    <DefaultPopUp.Body>
                        <div className="p-5 text-center">
                            <div className="ml-auto pt-5 mr-auto" style={{maxWidth:'450px'}}>
                                <img src="https://content.harstatic.com/media/icons/crm/agent_found.svg" />
                                <div className="mb-1 pt-5 pb-5 font_size--large_extra font_weight--bold">
                                    You are selected 7556 records to download.
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn btn--primary mr-4 mb-3">Download as Spreadsheet</button>
                                <button type="button" className="btn btn--primary mb-3">Download as SCV</button>
                            </div>
                        </div>
                    </DefaultPopUp.Body>
                </DefaultPopUp>

                
                <br /><br /><br />
                

                <div className="row mb-5 pb-5">
                    <div className="col-6">
                        <div className="card mb-5">
                            <div className="p-4">
                                <h3 tabIndex={0}>Relationships</h3>
                                
                                <label htmlFor="PreferredPhone">Related Contact</label>
                                
                                <div className="position-relative">
                                    <input className="form-control mb-4" name="name" placeholder="Name" type="text" aria-describedby="Name"></input>
                                    <div className="typeahead position-absolute max-w-300 w-100 zindex-1 top-40px" style={{top:'40px'}}>
                                        <div className="typeahead__inner p-0">            		
                                            
                                            {/* typeahead__item */}
                                            <div className="typeahead__item p-3 mb-0">			
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">pirani@testdrive.io</div>
                                                    </div>
                                                </a>
                                            </div>
                                            
                                            {/* typeahead__item */}
                                            <div className="typeahead__item p-3 mb-0">			
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">abdc@gmail.com</div>
                                                    </div>
                                                </a>
                                            </div>
                                            
                                            <div className="border-top p-3 border-color--cloudy-sky-light">
                                                <div className=" font_weight--bold cursor--hand color_har_blue font_size--medium"  onClick={() => setshowCreateContact(true)} >Create Contact</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="PreferredPhone">Relationship Type </label>
                                    <select name="relationType" id="relationType" className="custom-select custom-select_medium custom-select custom-select_large">
                                        <option value="None">None</option>
                                        <option value="Spouse">Spouse</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Child">Child</option><option value="Parent">Parent</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Assistant">Assistant</option>
                                        <option value="Wife">Wife</option>
                                        <option value="Husband">Husband</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Daughter">Daughter</option>
                                        <option value="Son">Son</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                    <DefaultPopUp title="Add Contact" isOpen={showScoreModal} onDidDismiss={() => setshowCreateContact(false)} >
                        <DefaultPopUp.Body>
                            <div className="px-5 py-4">
                                <label htmlFor="PreferredPhone">First Name</label>
                                <input className="form-control mb-4" name="name" placeholder="First Name" type="text" aria-describedby="Name"></input>

                                <label htmlFor="PreferredPhone">Last Name</label>
                                <input className="form-control mb-4" name="name" placeholder="Last Name" type="text" aria-describedby="Last Name"></input>

                                <label htmlFor="PreferredPhone">Email</label>
                                <input className="form-control mb-4" name="name" placeholder="Email" type="Email" aria-describedby="Email"></input>

                                <label htmlFor="PreferredPhone">Phone number</label>
                                <input className="form-control mb-4" name="name" placeholder="Phone number" type="phone" aria-describedby="Phone number"></input>

                            </div>
                            
                        </DefaultPopUp.Body>
                        <DefaultPopUp.ActionBar>
                            <div className="text-center p-2">
                                <OrdinaryButton onClick={() => setshowCreateContact(false)} className="btn--simple btn--simple__auxiliary">Cancel</OrdinaryButton>
                                <PrimaryButton onClick={() => setshowCreateContact(false)} className="btn--primary btn w-100px">Save</PrimaryButton>
                            </div>
                        </DefaultPopUp.ActionBar>
                    </DefaultPopUp>



                <br /><br /><br /><br /><br />

                <div className="row mb-5 pb-5">
                    <div className="col-6">
                        <div className="card mb-5">
                            <div className="p-4">
                                <h3 tabIndex={0}>Relationships</h3>
                                
                                <label htmlFor="PreferredPhone">Related Contact</label>
                                
                                <div className="position-relative">
                                    <input className="form-control mb-4" name="name" placeholder="Name" type="text" aria-describedby="Name"></input>
                                    <div className="typeahead position-absolute max-w-300 w-100 zindex-1 top-40px d-none">
                                        <div className="typeahead__inner p-0">            		
                                            
                                            {/* typeahead__item */}
                                            <div className="typeahead__item p-3 mb-0">			
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">pirani@testdrive.io</div>
                                                    </div>
                                                </a>
                                            </div>
                                            
                                            {/* typeahead__item */}
                                            <div className="typeahead__item p-3 mb-0">			
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">abdc@gmail.com</div>
                                                    </div>
                                                </a>
                                            </div>
                                            
                                            <div className="border-top p-3 border-color--cloudy-sky-light">
                                                <div className=" font_weight--bold cursor--hand color_har_blue font_size--medium">Create Contact</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className=" border_radius--default bg_color_cement_light border-color--cloudy-sky-light mb-3">
                                    <div className=" text-right">
                                        <div className="ml-auto pr-3 cursor--hand d-inline-block pt-3"><img src="https://content.harstatic.com/media/icons/close.svg" /></div>
                                    </div>
                                    <div className="px-4 pb-4">
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">pirani@testdrive.io</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-6">
                                                <div className="pb-3">
                                                    <div className="color_auxiliary font_size--medium">Phone</div>
                                                    <div className="font_weight--bold">+101244 1245 12</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <label htmlFor="PreferredPhone">Relationship Type </label>
                                            <select name="relationType" id="relationType" className="custom-select custom-select_medium custom-select custom-select_large">
                                                <option value="None">None</option>
                                                <option value="Spouse">Spouse</option>
                                                <option value="Partner">Partner</option>
                                                <option value="Child">Child</option><option value="Parent">Parent</option>
                                                <option value="Friend">Friend</option>
                                                <option value="Assistant">Assistant</option>
                                                <option value="Wife">Wife</option>
                                                <option value="Husband">Husband</option>
                                                <option value="Sister">Sister</option>
                                                <option value="Brother">Brother</option>
                                                <option value="Daughter">Daughter</option>
                                                <option value="Son">Son</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <br/><br/>

                                <div className=" border_radius--default bg_color_cement_light border-color--cloudy-sky-light mb-3">
                                    <div className=" text-right">
                                        <div className="ml-auto pr-3 cursor--hand d-inline-block pt-3"><img src="https://content.harstatic.com/media/icons/close.svg" /></div>
                                    </div>
                                    <div className="px-4 pb-4">
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <a href="#" className="agent_signature agent_signature__large mb-0">
                                                    <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                    <div className="agent_signature__text">
                                                        <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                        <div className="agent_signature__text__address">pirani@testdrive.io</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="PreferredPhone">Relationship Type </label>
                                                <select name="relationType" id="relationType" className="custom-select custom-select_medium custom-select custom-select_large">
                                                    <option value="None">None</option>
                                                    <option value="Spouse">Spouse</option>
                                                    <option value="Partner">Partner</option>
                                                    <option value="Child">Child</option><option value="Parent">Parent</option>
                                                    <option value="Friend">Friend</option>
                                                    <option value="Assistant">Assistant</option>
                                                    <option value="Wife">Wife</option>
                                                    <option value="Husband">Husband</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Daughter">Daughter</option>
                                                    <option value="Son">Son</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                <div className=" max-w-1000px">
                    <div className="contact_detail">
                        <div className="p-4 border-bottom border-color--cloudy-sky-light position-relative">
                            <div className="text-center font_size--large font_weight--bold">Quick Overview</div>
                            <div className="position-absolute top-0 right-0px cursor--hand pt-3 pr-3"><IonIcon name="close" size="large"></IonIcon></div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-8">
                                <div className="p-4">
                                    <div className="warning w-100 mb-4">
                                        <div className="warning__icon"><img src="https://content.harstatic.com/media/icons/event/clock.svg" /></div>
                                        <div className="warning__text">
                                            <span className="font_weight--bold">Two months ago •</span> Last time you updated John
                                        </div>
                                    </div>

                                    <div className="pb-3 mb-3 border-bottom">
                                                

                                        <div className="d-flex">
                                            <a href="#" className="agent_signature agent_signature__large mb-0 flex-grow-1">
                                                <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                <div className="agent_signature__text">
                                                    <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                    <div className="agent_signature__text__address">
                                                        <div className="d-flex">
                                                            <img className="align-self-center mr-2 w-14px" src="https://content.harstatic.com/media/icons/map.svg"></img>
                                                            <div className="color_auxiliary align-self-center font_weight--semi_bold">Houston</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>                                    
                                            
                                            <div className="ml-auto pr-3">
                                                <div className="stage stage__large stage__qualified">Qualified</div>
                                            </div>
                                            <div className="score_label score_label__medium score_label--a max-w-48 h-48px ml-auto mr-0">
                                                <span className="score_label__alpha">A</span>
                                                <span className="score_label__text">Score</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="pb-3 mb-3 border-bottom">
                                        <div className="d-inline-flex mr-4">
                                            <div className="align-self-center"><img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/phone_carbon.svg"/></div>
                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">(713) 629-1900</div>
                                        </div>

                                        <div className="d-inline-flex">
                                            <div className="align-self-center"><img className="mr-2 w-18px" src="https://content.harstatic.com/media/icons/email-lead.svg"/></div>
                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">john.frusciante@gmail.com</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="font_size--medium font_weight--bold mb-3">Upcoming Tasks/Events</div>
                                            
                                            <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">                                    
                                                <div className=" font_weight--bold">QA and Support Meeting</div>
                                                <div className="pb-2">11 Jun ⋅ 09:00 AM to 11 Jun ⋅ 10:00 AM</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="label  label--event mr-3 mb-0">Event</div>
                                                    <div className="font_weight--semi_bold color_pending">Medium Priority</div>
                                                </div>
                                            </div>

                                            <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">                                    
                                                <div className=" font_weight--bold">Communication-Task-123</div>
                                                <div className="pb-2">11 Jun ⋅ 09:00 AM to 11 Jun ⋅ 10:00 AM</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="label  label--virtualopenhouse mr-3 mb-0">Virtual Open House</div>
                                                    <div className="font_weight--semi_bold color_pending">Medium Priority</div>
                                                </div>
                                            </div>

                                            <a className="font_size--small_extra font_weight--bold" href="#">View all Tasks/Events</a>                                       
                                        </div>

                                        <div className="col-6">
                                            <div className="font_size--medium font_weight--bold mb-4">Upcoming Deal</div>
                                            <div className="font_size--small_extra cursor--hand">
                                                <div className="d-flex mb-4">
                                                    <div className="bg--image mr-3 border_radius--default" style={{backgroundImage:"url('https://photos.harstatic.com/215939156/lr/img-1.jpeg?ts=2022-12-30T10:33:27.803')",width:'56px',height:'40px'}}></div>
                                                    <div>
                                                    <div className="font_weight--bold">Deal Title placeholder</div>                                                        
                                                        <div>
                                                            Deal Type: Buying a Home<br />
                                                            Start Date: 03/30/22<br />
                                                            Est. deadline: 06/30/24<br />
                                                            Priority: Medium<br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <a className="font_weight--bold" href="#">View all deals</a>
                                            </div>    
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="col-4">
                                <div className="p-4">
                                    <div className="font_size--medium font_weight--bold mb-3">Notes</div>
                                    <div className="note mb-4">
                                        {isEditingNote ? (
                                            <textarea
                                                className="note__text h-110px"
                                                value={noteContent}
                                                onChange={handleNoteChange}
                                                onBlur={handleNoteBlur}
                                                autoFocus
                                            />
                                        ) : (
                                            <div className="note__text h-110px" onClick={handleNoteClick}>
                                                {noteContent}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pl-4 pr-4 pt-3 pb-3 border-top border-color--cloudy-sky-light">
                            <div className="row no-gutters justify-content-center">
                                <div className="col-auto">
                                    <IonButton fill="clear" class="btn btn--primary btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--primary
    " role="button">View Profile </IonButton>
                                </div>
                                <div className="col-auto text-right">
                                    <IonButton class="btn--shapeless_light btn btn--shapeless_red btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--shapeless_red
    " fill="clear" role="button">Remove from pipeline</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                <div className=" max-w-1000px">
                    <div className="contact_detail">
                        <div className="p-4 border-bottom border-color--cloudy-sky-light position-relative">
                            <div className="text-center font_size--large font_weight--bold">Quick Overview</div>
                            <div className="position-absolute top-0 right-0px cursor--hand pt-3 pr-3"><IonIcon name="close" size="large"></IonIcon></div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-8">
                                <div className="p-4">
                                    <div className="warning w-100 mb-4">
                                        <div className="warning__icon"><img src="https://content.harstatic.com/media/icons/event/clock.svg" /></div>
                                        <div className="warning__text">
                                            <span className="font_weight--bold">Two months ago •</span> Last time you updated John
                                        </div>
                                    </div>

                                    <div className="pb-3 mb-3 border-bottom">
                                                

                                        <div className="d-flex">
                                            <a href="#" className="agent_signature agent_signature__large mb-0 flex-grow-1">
                                                <div className="agent_signature__photo" style={{ backgroundImage:"url(" + "https://pics.harstatic.com/agent/587834.jpg?ts=2020-08-15T21:27:009703" + ")"}}></div>
                                                <div className="agent_signature__text">
                                                    <div className="agent_signature__text__title">Johnathan Lewis</div>
                                                    <div className="agent_signature__text__address">
                                                        <div className="d-flex">
                                                            <img className="align-self-center mr-2 w-14px" src="https://content.harstatic.com/media/icons/map.svg"></img>
                                                            <div className="color_auxiliary align-self-center font_weight--semi_bold">Houston</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>                                    
                                            
                                            <div className="ml-auto pr-3">
                                                <div className="stage stage__large stage__qualified">Qualified</div>
                                            </div>
                                            <div className="score_label score_label__medium score_label--a max-w-48 h-48px ml-auto mr-0">
                                                <span className="score_label__alpha">A</span>
                                                <span className="score_label__text">Score</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="pb-3 mb-3 border-bottom">
                                        <div className="d-inline-flex mr-4">
                                            <div className="align-self-center"><img className="mr-2 w-16px" src="https://content.harstatic.com/media/icons/phone_carbon.svg"/></div>
                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">(713) 629-1900</div>
                                        </div>

                                        <div className="d-inline-flex">
                                            <div className="align-self-center"><img className="mr-2 w-18px" src="https://content.harstatic.com/media/icons/email-lead.svg"/></div>
                                            <div className="align-self-center color_auxiliary font_weight--semi_bold font_size--medium">john.frusciante@gmail.com</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="font_size--medium font_weight--bold mb-3">Upcoming Tasks/Events</div>
                                            
                                            <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">                                    
                                                <div className=" font_weight--bold">QA and Support Meeting</div>
                                                <div className="pb-2">11 Jun ⋅ 09:00 AM to 11 Jun ⋅ 10:00 AM</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="label  label--event mr-3 mb-0">Event</div>
                                                    <div className="font_weight--semi_bold color_pending">Medium Priority</div>
                                                </div>
                                            </div>

                                            <div className="border border-color--cloudy-sky-light p-2 mb-3 font_size--small_extra cursor--hand">                                    
                                                <div className=" font_weight--bold">Communication-Task-123</div>
                                                <div className="pb-2">11 Jun ⋅ 09:00 AM to 11 Jun ⋅ 10:00 AM</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="label  label--virtualopenhouse mr-3 mb-0">Virtual Open House</div>
                                                    <div className="font_weight--semi_bold color_pending">Medium Priority</div>
                                                </div>
                                            </div>

                                            <a className="font_size--small_extra font_weight--bold" href="#">View all Tasks/Events</a>                                       
                                        </div>

                                        <div className="col-6">
                                            <div className="font_size--medium font_weight--bold mb-4">Upcoming Deal</div>
                                            <div className="font_size--small_extra cursor--hand">
                                                <div className="d-flex mb-4">
                                                    <div className="bg--image mr-3 border_radius--default" style={{backgroundImage:"url('https://photos.harstatic.com/215939156/lr/img-1.jpeg?ts=2022-12-30T10:33:27.803')",width:'56px',height:'40px'}}></div>
                                                    <div>
                                                    <div className="font_weight--bold">Deal Title placeholder</div>                                                        
                                                        <div>
                                                            Deal Type: Buying a Home<br />
                                                            Start Date: 03/30/22<br />
                                                            Est. deadline: 06/30/24<br />
                                                            Priority: Medium<br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <a className="font_weight--bold" href="#">View all deals</a>
                                            </div>    
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="col-4">
                                <div className="p-4">
                                    <div className="note mb-4">
                                        <div className="note__date">Fri Jun 07 2024   14:31:03</div>
                                        <div className="note__text h-110px">Note text placeholder...</div>                                        
                                    </div>

                                    <div className="note mb-4">
                                        <div className="note__date">Fri Jun 07 2024   14:31:03</div>
                                        <div className="note__text h-110px">Note text placeholder...</div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="col"><a href="#" className="font_weight--bold">+ Write Note</a></div>
                                        <div className="col-auto"><a href="#" className="font_weight--bold">View all Notes</a></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="pl-4 pr-4 pt-3 pb-3 border-top border-color--cloudy-sky-light">
                            <div className="row no-gutters justify-content-center">
                                <div className="col-auto">
                                    <IonButton fill="clear" class="btn btn--primary btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--primary
    " role="button">View Profile </IonButton>
                                </div>
                                <div className="col-auto text-right">
                                    <IonButton class="btn--shapeless_light btn btn--shapeless_red btn--medium undefined md button button-clear ion-activatable ion-focusable hydrated btn--shapeless_red
    " fill="clear" role="button">Remove from pipeline</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br /><br /><br /><br />
                <div className=" max-w-300">
                    <div className="cards cards__deals_wt cursor--hand ui-sortable-handle">
                        <div className=" font_size--medium font_weight--bold pb-1">Placeholder for Title</div>  
                        <div className="listing_signature listing_signature__small mb-2">
                            <div className="listing_signature__photo" style={{backgroundImage:'url(https://listingmedia7.harstatic.com/428622427/lr/1.jpeg)'}}></div>
                            <div className="listing_signature__details">
                                <div className="font_weight--semi-bold">4607 Butler Springs Ln</div>
                                <div className="listing_signature__details__address">KATY, TX 774494</div>
                            </div>
                        </div>
                        <div className="message message--success d-block mb-2">
                            <div className="d-flex justify-content-between">
                                <div>Buying a Home</div>
                                <div className="text-right font_weight--semi_bold">Value $513,254</div>
                            </div>
                        </div>
                        <div className={'warning warning__small'}>
                            <div className="warning__small__text">
                                <span>Expired on<span> : May 29, 2024</span></span>
                            </div>
                        </div>
                    </div>
                
                </div>



                <br /><br /><br /><br /><br />
                <div className="row mb-5 pt-5">
                    <div className="col-6">
                        <div className="card mb-5">
                            <div className="p-4">
                                <h3 tabIndex={0}>Phone</h3>
                                
                                <label htmlFor="PreferredPhone">Preferred Phone</label>
                                <div className="row small-gutters">
                                    <div className="col-5">
                                        <input type="phone" className="form-control mb-4" id="PreferredPhone" aria-describedby="PreferredPhone" placeholder="Phone number"></input>				
                                    </div>
                                    <div className="col-2">
                                        <input type="number" className="form-control mb-4" id="Ext" aria-describedby="Ext" placeholder="Ext"></input>				
                                    </div>
                                    <div className="col-5">
                                        <select className="custom-select custom-select_medium">                                    
                                            <option value="N">None</option>
                                            <option value="W">Office Phone</option>
                                            <option value="H">Home Phone</option>
                                            <option value="M">Mobile/Cell Phone</option>
                                            <option value="P">Pager</option>
                                            <option value="F">Office Fax number</option>
                                        </select>
                                    </div>
                                </div>
                                        
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </DefaultTemplate>
        </>
    );
}

export function ContactPlaygroundApp()
{
    return <>
        <div className="hb_app">
            <IonHeader>
                    <div className="header_blue ion-padding-bottom">
                        <div className="ion-padding-start ion-padding-end hb_section">

                        <div className="d-inline-flex ml-md-auto font_size--medium font_size--small_sm">                                    
                                    <div className='align-self-center color_snow_white pr-md-4 pr-3 pl-md-4 pl-3 border-right_custom_1'>
                                        <div className="score_label score_label--a">
                                            <span className="score_label__alpha">A</span>
                                            <span className="score_label__text">Score</span>
                                        </div>
                                    </div>

                                    <div className="align-self-center color_snow_white pl-md-4 pl-3  pr-md-4 pr-3 border-right_custom_1">
                                        <div className="font_weight--bold">Source</div>
                                        <div>HAR.com</div>
                                    </div>

                                    <div className="align-self-center color_snow_white pl-md-4 pl-3">
                                        <div className="font_weight--bold">Created Date</div>
                                        <div>23th Jun 2024</div>
                                    </div>

                            </div>
                        </div>
                    </div>
            </IonHeader>
        </div>


        <div className="border-bottom">
                <div className="row no-gutters">
                    <div className="col-auto align-self-top">
                        <div className="delete_item d-flex">
                            <img className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
                        </div>
                    </div>
                    <div className="col pl-3">

                        {/* this is start to add row cols for ext input */}
                        <div className="row small-gutters">
                            <div className="col-9">
                                <input type="phone" className="form-control mb-4" id="PreferredPhone" aria-describedby="PreferredPhone" placeholder="Phone number"></input>				
                            </div>
                            <div className="col-3">
                                <input type="number" className="form-control mb-4" id="Ext" aria-describedby="Ext" placeholder="Ext"></input>				
                            </div>
                        </div>
                        {/* this is end to add row cols for ext input */}

                    </div>
                </div>
            </div>   
    </>;
}