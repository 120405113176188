import { useEffect, useState } from 'react';
import { CityTypeahead } from 'views/forms/typeaheads/CityTypeahead';
import { CompanyTypeahead } from 'views/forms/typeaheads/CompanyTypeahead';
import { IContactsFilterPopUpProps, stagesOptions, IFormFieldProps, dealsOptions, IContactsFilters, IContactCommonFiltersKeys, IContactCommonFilters } from './ContactsFilterPopUp.def';
import './ContactsFilterPopUp.scss';
import { ITag } from 'views/forms/textboxes/TagsTextbox/TagsTextbox.def';
import { ZipCodeTypeahead } from 'views/forms/typeaheads/ZipCodeTypeahead';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { ScoreOptions } from 'views/contacts/ScoreOptions/ScoreOptions';
import { OwnersTextbox } from 'views/forms/textboxes/OwnersTextbox';
import { ObjectOwnerApi } from 'services/typeaheads/objectOwner';
import { useFetchLookupsService } from 'services/general/lookups';
import { OverlayTrigger } from 'react-bootstrap';
import { popover_score, popover_tags } from 'views/contacts/ContactInsightSection/components/InfoOverlayTrigger';
import { optionsOutline } from 'ionicons/icons'
import { NativeDateTimePicker } from 'views/general/NativeDateTimePicker';
import { parseISO, format } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { SelectWithSearch } from 'views/forms/select/SelectWithSearch';
import { IMultiSourceDropDownProps } from 'views/forms/dropdowns/SourceDropDown/SourceDropDown.def';
import { fetchSearchTagsService } from 'services/typeaheads/searchTags';


export const ContactsFilterPopUpApp = (props: IContactsFilterPopUpProps): JSX.Element => {
    const { filters, filtersCount, hideLanucher, showProps, count, loadCount, contactsApiLoading, numberOfFilter, onChange, ...filtersProps } = props;
    const [showFilter, setShowFilter] = useState(false)
    const { cities, companies, stages, deal_stages, scores, zip_codes, owners, tags, sources, last_contact_date } = filters ? filters : filtersProps;
    function updateField<K extends keyof IContactsFilters>(name: K, value: IContactsFilters[K]) {
        onChange?.(name, value)
        loadCount?.();
    }



    function onCityFilterChange(value: string[]) {
        updateField('cities', value);
    }

    function onContactFilterChange(value: string) {
        updateField('last_contact_date', value);
    }



    function onStagesFilterChange(value: number[]) {
        updateField('stages', value);
    }

    function onDealsFilterChange(value: string[]) {
        updateField('deal_stages', value);
    }


    function onOwnerChange(item: ObjectOwnerApi.IObjectList[]) {
        updateField('owners', item);

    }

    function removeOwner(agentkey: string) {
        let restOwners = owners?.filter((i) => i.agent_key !== agentkey)
        updateField('owners', restOwners);

    }

    function onSourcesChange(value: string[]) {
        updateField('sources', value);
    }

    function onTagsFilterChange(value: ITag[]) {
        updateField('tags', value);
    }

    function onCompanyFilterChange(value: string[]) {
        updateField('companies', value);
    }

    function onScoreFilterChange(value: string[]) {

        updateField('scores', value);
    }

    function onZCodeFilterChange(value: number[]) {
        updateField('zip_codes', value);
    }

    /*not supporting filter count right since team change their mind we can use that/
    function toggleFilters() {
        setShowFilter(showFilter ? false : true)
        if (numberOfFilter)
            numberOfFilter()
    }*/
    const FilterLanuchApp = (): JSX.Element => {
        return (
            <a className={`action_bar__link px-2 pt-1 cursor_pointer ${props.disabled && `filterAppDisabled`} ${filtersCount && filtersCount > 0 && `filterAppActive`}`} onClick={() => {
                if (props.disabled) {
                    return
                }
                setShowFilter(showFilter ? false : true)
            }}><IonIcon className='w-20px h-20px' icon={optionsOutline} title='Filters'></IonIcon></a>
        );
    }

    function onDateRangeChange<K extends keyof IContactCommonFiltersKeys>(key: K, value: string) {
        updateField(key, value);
    }

    useEffect(() => {
        if (showProps)
            setShowFilter(true)
    }, [showProps])


    return (
        <>
            {!hideLanucher ?
                FilterLanuchApp() : <></>
            }
            <IonModal title='Filters' isOpen={showFilter} backdropDismiss={false}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            {count && count >= 0 ?
                                <IonButton color={"blue"} onClick={() => {
                                    props.onClear?.()
                                    setShowFilter(false)
                                }}> Clear Filters</IonButton>
                                :
                                <IonButton color={"blue"} onClick={() => setShowFilter(false)}>Cancel</IonButton>
                            }
                        </IonButtons>
                        <IonTitle>Filters</IonTitle>
                        <IonButtons slot="end">
                            <IonButton color={"blue"} strong={true} onClick={() => {
                                if (props.applyFilter) {
                                    props.applyFilter()
                                }
                                setShowFilter(false)
                            }}>
                                Apply
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Location</div>
                        <CityTypeahead initialValues={cities} onChange={onCityFilterChange} />
                        <div className='mt-4'>
                            <ZipCodeTypeahead initialValues={zip_codes} onChange={onZCodeFilterChange} />
                        </div>
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Last contact</div>
                        <LastContact initialSelections={last_contact_date} onChange={onContactFilterChange} />
                    </div>
                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Stages</div>
                        <Stages initialSelections={stages} onChange={onStagesFilterChange} />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Deals</div>
                        <Deals initialSelections={deal_stages} onChange={onDealsFilterChange} />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Score
                            <OverlayTrigger trigger="hover" placement="right" overlay={popover_score}><img className='ml-2' src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>
                        </div>
                        <ScoreOptions.Multi initialSelections={scores} onScoresChange={onScoreFilterChange} />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom d-none">
                        <div className=" form_title__large">Object Owners</div>
                        <OwnersTextbox initialItems={owners} onItemChange={onOwnerChange} onRemoveItem={removeOwner} readonly />

                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">

                        <div className=" form_title__large">Tags
                            <OverlayTrigger trigger="hover" placement="right" overlay={popover_tags}><img className='ml-2' src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>
                        </div>
                        <SearchTags values={tags} onChange={onTagsFilterChange} />

                    </div>



                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Companies</div>
                        <CompanyTypeahead initialValues={companies} onChange={onCompanyFilterChange} hideId={true} />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Sources</div>
                        <SourceDropDown values={sources} onChange={onSourcesChange} />
                    </div>



                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Lead type                        </div>
                        <LeadType value={filters?.lead_type} onChange={onDateRangeChange} />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Date of birth</div>
                        <RangeComponent
                            startValue={filters?.birthday_start}
                            endValue={filters?.birthday_end}
                            startDate='birthday_start'
                            endDate='birthday_end'
                            onChange={onDateRangeChange}
                        />
                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Home Anniversary                        </div>
                        <RangeComponent
                            startValue={filters?.home_anniversary_start}
                            endValue={filters?.home_anniversary_end}
                            startDate='home_anniversary_start'
                            endDate='home_anniversary_end'
                            onChange={onDateRangeChange}
                        />

                    </div>

                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Wedding Anniversary                       </div>
                        <RangeComponent
                            startValue={filters?.wedding_anniversary_start}
                            endValue={filters?.wedding_anniversary_end}
                            startDate='wedding_anniversary_start'
                            endDate='wedding_anniversary_end'
                            onChange={onDateRangeChange} />
                    </div>
                    <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <div className=" form_title__large">Known Since                       </div>
                        <RangeComponent
                            startValue={filters?.known_since_start}
                            endValue={filters?.known_since_end}
                            startDate='known_since_start'
                            endDate='known_since_end'
                            onChange={onDateRangeChange} />

                    </div>




                </IonContent>
            </IonModal>
        </>
    );
}



export const RangeComponent = (props: IContactCommonFilters) => {
    return (
        <div className='row'>
            <div className='col'>
                <NativeDateTimePicker
                    label='Starts'
                    name={props.startDate.toLocaleLowerCase()}
                    value={props.startValue ? new Date().getFullYear() + '-' + props.startValue : undefined}
                    format={'MM-dd'}
                    onChange={(date) => {
                        props.onChange?.(props.startDate, format(parseISO(date), 'MM-dd'))
                    }}
                />

            </div>
            <div className='col'>
                <NativeDateTimePicker
                    name={props.endDate.toLocaleLowerCase()}
                    label='Ends'
                    format={'MM-dd'}
                    value={props.endValue ? new Date().getFullYear() + '-' + props.endValue : undefined}
                    disabled={!props.startValue}
                    minDate={props.startValue ? new Date().getFullYear() + '-' + props.startValue : undefined}
                    onChange={(date) => {
                        props.onChange?.(props.endDate, format(parseISO(date), 'MM-dd'))
                    }}

                />

            </div>
        </div>
    )
}


export const LeadType = (props: { value?: string, onChange?: <K extends keyof IContactCommonFiltersKeys>(key: K, value: string) => void, }) => {
    const [lookupData] = useFetchLookupsService(['contact_lead_type']);
    return (
        <div className="row px-3">

            <Dropdown className="dropdown dropdown--custom dropdown--custom_large mb-0 w-100">
                <Dropdown.Toggle className="dropdown-toggle font_weight--normal">
                    {props.value ? lookupData?.response?.contact_lead_type.items.filter((i) => i.short_value === props.value)[0]?.long_value : 'Lead Type'}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                    {lookupData?.response?.contact_lead_type.items.filter((i) => i.short_value !== 'N').map((item, index) =>
                        <Dropdown.Item value={props.value === item.short_value ? item.long_value : undefined} onClick={() => {
                            props.onChange?.('lead_type', item.short_value)

                        }} key={index} href="#" className={`d-flex ${index + 1 !== lookupData?.response?.contact_lead_type.items.length ? `border-bottom ` : ``} font_weight--bold`}>
                            <span>{item.long_value}</span>
                            <span className="ml-auto"></span>
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}


export const SourceDropDown = (props: IMultiSourceDropDownProps) => {
    const [lookupData] = useFetchLookupsService(['contact_source']);
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            {props.values && props.values.length > 0 ?
                <div className='tagsinput p-2 m-2' onClick={() => setShowModal(true)}>
                    <span className="pr-2 horizentalScollable d-flex">
                        {props.values.map((value, index) =>
                            <div key={index} className='filterpill filterpill--closeable2 px-2'>
                                <span className='white-space-nowrap mr-1'>{value}</span>
                                <a onClick={(e) => {
                                    e.stopPropagation();
                                    props.onChange?.(props.values?.filter((i) => i !== value) ?? [])
                                }}></a>
                            </div>
                        )}
                    </span>
                </div> :
                <div className='tagsinput p-3' onClick={() => setShowModal(true)} >
                    <a className='color_custom_1 font_size--large font_weight--semi-bold'> Sources</a>
                </div>
            }
            {lookupData?.response &&
                <SelectWithSearch
                    autoOpen={showModal}
                    title='Sources'
                    onSelect={(obj: any) => {
                        props.onChange?.([...props.values ?? [], obj.value])
                    }}
                    onRemove={(obj: string) => {
                        props.onChange?.(props.values?.filter((i) => i !== obj) ?? [])
                    }}
                    onClose={() => setShowModal(false)}
                    placeholder='Search Sources'
                    multiple={true}
                    selectedValue={props.values}
                    options={lookupData?.response?.contact_source?.items.map((item) => { return { value: item.short_value, label: item.long_value } })}
                />
            }
        </>
    )
}


export const SearchTags = (props: { values: ITag[] | undefined, onChange?: (tags: ITag[]) => void }) => {

    const [showModal, setShowModal] = useState(false);
    const search = (value: string) => {

        return fetchSearchTagsService({ input: value }).then((response) => {
            return response.map((item: any) => { return { value: item.id.toString(), label: item.name.toString() } })

        })
    }
    return (
        <>
            <div className='tagsinput py-1' onClick={() => setShowModal(true)}>
                {props.values && props.values.length > 0 ?
                    <span className="pr-2 horizentalScollable d-flex">
                        {props.values.map((value, index) =>
                            <div key={index} className='filterpill filterpill--closeable2 px-2'>
                                <span className='white-space-nowrap mr-1'>{value.name}</span>
                                <a onClick={(e) => {
                                    e.stopPropagation();
                                    props.onChange?.(props.values?.filter((i) => i !== value) ?? [])
                                }}></a>
                            </div>
                        )}
                    </span>
                    :
                    <div className='p-2' onClick={() => setShowModal(true)} >
                        <a className='color_custom_1 font_size--large font_weight--semi-bold'> Tags</a>
                    </div>
                }
            </div>
            <div className="d-flex ">
                <input className='select-contacts-all mx-1' type="checkbox" id="noneTag" checked={props.values?.find((t) => t.id === 0) ? true : false} name="noneTag" readOnly onChange={(e: any) => {
                    if (e.target.checked) {
                        props.onChange?.([...props.values ?? [], { id: 0, name: 'None' }]);
                    } else {
                        props.onChange?.(props.values?.filter(x => x.id !== 0) ?? []);
                    }
                }} />
                <label htmlFor="noneTag" className='color_0738CD font_size--small pt-3'>search for contacts without tags</label>
            </div>
            <SelectWithSearch
                title='Tags'
                autoOpen={showModal}
                selectedValue={props.values?.map((i: any) => i.id.toString()) ?? []}
                search={search}
                onSelect={(obj: any) => {
                    props.onChange?.([...props.values ?? [], { id: obj.value, name: obj.label }])
                }}
                onRemove={(obj: string) => {
                    props.onChange?.(props.values?.filter((i) => i.id?.toString() !== obj) ?? [])
                }}
                placeholder='Search Tags'
                noErrorMessage={true}
                multiple={true}
                onClose={() => setShowModal(false)}

            />
        </>
    )
}



export const Stages = (props: IFormFieldProps<number[]>) => {

    const [selections, setSelections] = useState(props.initialSelections ?? []);
    const [select, selectAll] = useState<boolean | undefined>(undefined);
    let newSelections = new Array<number>();

    useEffect(() => { setSelections(props.initialSelections ?? []) }, [props.initialSelections]);



    const toggleStage = (value: number) => {

        if (selections.includes(value)) {
            newSelections = selections.filter(selection => selection !== value);
        } else {
            newSelections = [...selections, value];
        }
        if (select) {
            selectAll(undefined);
        }
        setSelections(newSelections);
        props.onChange?.(newSelections);

    }

    return (
        <div className="input-group--outline__grid">
            {stagesOptions.map((item, index) => {
                const checked = select ?? selections.includes(item.short_value);
                return (
                    <div className="choosable_topic" key={index}>
                        <input type="checkbox" className="custom-control-input" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} value={item.short_value} onChange={(e) => toggleStage(Number(e.target.value))} />
                        <label className="choosable_topic-label" htmlFor={`${item.short_value}`} data-toggle="tooltip" data-placement="top" title="Chosable Topic" data-original-title="Chosable Topic">
                            <div className={`stage stage__${item.class}`}>{item.long_value}</div>
                        </label>
                    </div>
                )
            })}
        </div>


    )
}

export const Deals = (props: IFormFieldProps) => {

    const [selections, setSelections] = useState(props.initialSelections ?? []);
    useEffect(() => { setSelections(props.initialSelections ?? []) }, [props.initialSelections]);

    const toggleDeals = (value: string) => {
        let newSelections = new Array<string>();
        if (selections.includes(value)) {
            newSelections = selections.filter(selection => selection !== value);
        } else {
            newSelections = [...selections, value];
        }
        setSelections(newSelections);
        props.onChange?.(newSelections);

    }

    return (
        <div className="input-group--outline__grid">
            {dealsOptions.map((item, index) => {
                const checked = selections.includes(item.short_value);
                return (
                    <div className="choosable_topic" key={index}>
                        <input type="checkbox" checked={checked} id={`${item.short_value}`} name={`${item.short_value}`} value={item.short_value} className="custom-control-input" onChange={(e) => toggleDeals(e.target.value)} />
                        <label className="choosable_topic-label" htmlFor={`${item.short_value}`} data-toggle="tooltip" data-placement="top" title="Chosable Topic" data-original-title="Chosable Topic">
                            <div className="stage ">{item.long_value}</div>
                        </label>
                    </div>
                )
            })}
        </div>

    )
}

export const LastContact = (props: IFormFieldProps<string>) => {
    const { initialSelections } = props;
    const [lookupData] = useFetchLookupsService('last_contact_filter');
    return (
        <div className="input-group--outline__grid">
            {lookupData?.response?.last_contact_filter?.items.map((item, index: number) => {
                const checked = initialSelections === item.short_value ? true : false;
                return (
                    <div className="choosable_topic" key={index}>
                        <input type="checkbox" checked={checked} id={`${`short-value-` + index}`} name={`${item.short_value}`} value={item.short_value} readOnly className="custom-control-input" onChange={(e) => { console.log(e.target.value); props.onChange?.(e.target.value) }} />
                        <label className="choosable_topic-label" htmlFor={`${`short-value-` + index}`} data-toggle="tooltip" data-placement="top" title="Chosable Topic" data-original-title="Chosable Topic">
                            <div className="stage ">{item.long_value}</div>
                        </label>
                    </div>
                )
            })
            }
            {initialSelections &&
                <a className='cursor--hand d-block' onClick={() => props.onChange?.('')}>Unselect</a>
            }
        </div >
    )
}
