import { IImageObj, IPhotoUploadPopupProps } from "./PhotoUploadPopup.def";
import { useState } from "react";
import * as filestack from 'filestack-js';
import { useUpdatePhotoService } from "services/contacts/contactDetails/updatePhoto";
import { useParams } from "react-router";
import { useDeletePhotoService } from "services/contacts/contactDetails/deletePhoto";
import { useServiceDidSucceeded } from "scripts/hooks/useService";
import { displayMessage } from "scripts/hooks/feedbacks";
import { PhotoUploadPopupApp } from "./PhotoUploadPopup.app";
import { PhotoUploadPopupDesktop } from "./PhotoUploadPopup.desktop";
import { useResponsiveChoose } from "scripts/hooks/general";
import { clientId } from "scripts/vendors/filestack";
import { isProduction } from "scripts/application/settings";

export const PhotoUploadPopup = (props:IPhotoUploadPopupProps): JSX.Element =>
{
    const { id } = useParams<{ id: string }>();
    const { imageUrl, onItemsChange } = props;
    const [imageObj, setImageObj] = useState<IImageObj>( { imageUrl: imageUrl??"", imageType: '' });
    const [updatePhoto,uploadPhoto]= useUpdatePhotoService()
    const [deletePhoto,actionDeletePhoto]=useDeletePhotoService()
    const fileStack = filestack.init(clientId);
    let time = new Date().getTime();

    useServiceDidSucceeded(updatePhoto, () => { displayMessage('Photo updated.');  props.refresh();  props.toggleShow(false);});
    useServiceDidSucceeded(deletePhoto, () => { 
        props.refresh()   
    displayMessage('Photo removed.');//save({
       // imageUrl: 'h',
       // imageType: ''}); 
       
     props.toggleShow(false);
    

    });

    const filestackOpener = () => {
        const path=isProduction?'crmapp/temp/':'crmapp_test/temp/';
        const options: filestack.PickerOptions = {
            fromSources: ['local_file_system', 'googledrive', 'unsplash', 'facebook', 'instagram'],
            maxFiles: 1,
            accept: ['image/heic', 'image/jpeg', 'image/png', 'image/gif'],
            uploadInBackground: false,
           // imageDim: [400, 400],
           // imageMin: [400, 400],
            imageMax: [400, 400],
            maxSize: 5242880,
            customText:{"Select Files to Upload":"Select Files to Upload. Ensure photo is a PNG or JPG file, not exceeding 5MB, and originally stored at 320 x 320 pixels (displayed at 110 x 100 pixels)."},
            storeTo: {
                location: 's3',
                path: path + time,
                container: 'content.har.com',
                access: 'public',
                region: 'us-east-1'
            },

            onOpen: () => console.log('opened!'),
            onFileSelected: (file: any) => {
                if (file.size > 5 * 1024 * 1024) {
                    alert('max file size')
                }
            },
            onUploadDone: (res: any) => {
                if (res?.filesUploaded.length > 0) {
                    const image = res?.filesUploaded;
                    const ext=image[0].filename?.split(/[#?]/)[0].split('.').pop().trim();
                    uploadPhoto.fetch({photo_key: image[0].key.replace(path,""),photo_ext:ext,contact_id:id})
                    save({
                        imageUrl: 'https://content.harstatic.com/' + image[0].key,
                        imageType: image[0].mimetype
                    })
                }
            },
        };
        fileStack.picker(options).open();
    }

    const save = (obj: IImageObj) => {
        setImageObj(obj)
        onItemsChange?.(obj);
    };
const handleDeletePhoto=()=>{
    actionDeletePhoto.fetch({contact_id:id});
   
}
const PhotoUploadPopupPlatform = useResponsiveChoose(PhotoUploadPopupDesktop, PhotoUploadPopupApp);

    return <PhotoUploadPopupPlatform {...props} imageUrl={imageObj.imageUrl} onOpen={filestackOpener} onDelete={handleDeletePhoto}/>;
}
