import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"
import { MapCSVApi } from "./mapCSV";

export namespace GetCSVContentApi {
    export const url = "/contacts/csv/:id";
    export const method = "GET";
    export const defaultError = "Failed to load CSV Content.";
    export const operations = apiOperations(GetCSVContentApi);
    export const scope = new class GetCSVContentApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            return {
                id: input.id,
                queue: input.queue,

            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        id: string
        queue?: string,

    }

    export interface Response extends MapCSVApi.IDataInfo {
        rows_count: string
        success_count: string,        //current successful rows
        errors_count: string,
        added_count: string,
        updated_count: string,
        status: string,
        errors_file: string,
        warnings_file: string,
        total_errors: string,
        total_warnings: string,
        total_success: string,
    }
}

export const fetchGetCSVContentService = forwardOperation(GetCSVContentApi.operations, 'fetch');
export const useFetchGetCSVContentService = forwardOperation(GetCSVContentApi.operations, 'useFetch');
export const useGetCSVContentService = forwardOperation(GetCSVContentApi.operations, 'useService');