import { md5 } from "scripts/utilities/encryption";
import { trimStart } from "lodash-es";
import { isLocal, isProduction, version } from "scripts/application/settings";
import { platform } from "scripts/context";
import { joinJSXWithSeparator } from "scripts/helpers/jsx";
import { queryString } from "scripts/helpers/urls";
import { displayError } from "scripts/hooks/feedbacks";
import { Auth } from "scripts/security/AuthService";
import { ApiContentTypes, IApi } from "types/apis";
import { Device } from '@capacitor/device';
import { PromiseController } from "scripts/helpers/async";
import { ApiCluster } from "./clusters";
import { ServiceError } from "scripts/exceptions";

type UrlParameters<TUrl extends string> =
    TUrl extends `${string}:${infer S}/${infer S2}` ? S | UrlParameters<S2> :
    TUrl extends `${string}:${infer S}` ? S :
    keyof object;

export type IServiceParametersRequest<TUrl extends string, TContentType extends string | undefined = undefined> =
    //Record<UrlParameters<TUrl> | (TContentType extends 'json' | undefined? keyof object: keyof object), any>;
    Record<UrlParameters<TUrl> | ([TContentType] extends [Exclude<ApiContentTypes, 'json'>] ? 'content_type' : keyof object), any>;

export interface IServiceEndpoint<TUrl extends string = string> {
    url: TUrl;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
}
export type IServiceFetchResponse<T> = PromiseController<T>;

export type IFetchFunction<TRequest, TResponse> =
    {} extends TRequest ?
    (parameters?: TRequest) => IServiceFetchResponse<TResponse> :
    (parameters: TRequest) => IServiceFetchResponse<TResponse>;


// export type IResponseData<T> = {} extends T? true: T;

export type IServiceResult = Readonly<
    { status: 'ok' } |
    { status: 'error', message?: string, reason?: string }
>;



const TOKEN = "Fhy7Jh898ynQnnPQjkk";
//const queryString = (params: any) => decodeURIComponent(new URLSearchParams(params).toString());

export const serviceFetch = <TServerRequest, TRequest, TServerResponse, TResponse>(api: IApi<TServerRequest, TRequest, TServerResponse, TResponse, any, any>, parameters: TRequest): IServiceFetchResponse<TResponse> => {
    const aborter = new AbortController();
    const response = requestServiceFetch<TServerRequest, TRequest, TResponse>(api, parameters, aborter.signal)
        .then(response => response ?? {} as TResponse);

    //const response = output.then(api._response);
    return PromiseController.from(response, aborter);
};

async function requestServiceFetch<TServerRequest, TRequest, TResponse>(api: IApi<TServerRequest, TRequest, any, TResponse, any, any>, parameters: TRequest, signal: AbortSignal): Promise<TResponse> {
    // No clustering
    if (!api.cluster) { return callRequest<TServerRequest, TResponse>(api, api.scope.request(parameters), signal); }

    // Handling Clustering
    const cluster = ApiCluster.getCluster(api);
    return new Promise<TResponse>((resolve, reject) => {
        const { session } = cluster;
        session.add(parameters ?? {} as TRequest, resolve);

        // Handle Abortion
        let handleAbort: () => void;
        signal.addEventListener('abort', handleAbort = () => {
            signal.removeEventListener('abort', handleAbort);
            session.remove(parameters);

            try { signal.throwIfAborted(); }
            catch (e) { return reject(e); }
            reject(new DOMException('AbortError'));
        });
    });
}

export async function callRequest<TServerRequest, TResponse>(api: IApi<any, any, any, any, any, any>, input: TServerRequest, signal: AbortSignal): Promise<TResponse>
{
    // Handling Content-Type
    let contentType = "application/json";
    const parameters = input as { content_type?: string };
    if (parameters && 'content_type' in parameters) {
        if (parameters.content_type) { contentType = parameters.content_type; }
        delete parameters.content_type;
    }

    // Loading url
    const url = loadUrl(api.url, parameters);
    const query = api.method === 'GET' && parameters ?
        queryString(parameters, true).replace(/\+/g, '').replace(/#/g, "%23"):
        undefined;
        
    const urlWithQuery = query ? `${url}?${query}` : url;

    // Loading authentication and headers
    const { authenticate = true } = api;
    const [authentication, authHeaders] = await Promise.all([
        authenticate? Auth.loginProvider.getAuthentication(): undefined,
        loadHeaders(urlWithQuery)
    ]);

    // Validating authentication
    if (authenticate && !Auth.loginProvider.useCookies && !authentication) {
        //Auth.captureLogoutMessage('No storage value (from services)');
        Auth.signOut(true);
        throw new ServiceError('User not authenticated!');
    }
    
    // Merging headers
    const headers = {
        "Content-Type": contentType,
        "Authorization": authentication?.authorization ?? '',
        ...authHeaders
    };

    // Fetching API
    //const baseUrl = isLocal && platform.isDesktop? '/har-crm-api': 'https://har-crm-api.har.com';
    const fullUrl = loadApiUrl(urlWithQuery);
    const apiCall = fetch(fullUrl, {
        method: api.method,
        body: api.method !== "GET" && parameters ? JSON.stringify(parameters) : undefined,
        signal,
        credentials:Auth.loginProvider.credentials,
        headers,
    });

    //const output = handleResponse<IServiceResult>(api, apiCall);
    const output = handleResponse<IServiceResult>(api, apiCall, { ...headers, token:authentication?.token });
    return output.then(([response, result]) => {
        if (result.status === 'error') { throw ServiceError.from(result.message ?? 'Internal Error!', response); }
        return (result ? api.scope.response(result) : {}) as TResponse;
    });
}

export function loadApiUrl(pathWithQuery:string): string
{
    //const baseUrl = isLocal? '/har-crm-api': 'https://har-crm-api.har.com';
    //const baseUrl = isLocal && platform.isDesktop? '/har-crm-api': 'https://har-crm-api.har.com';
    //const baseUrl = isLocal && Auth.loginProvider.useCookies? '/har-crm-api': 'https://har-crm-api.har.com';
    return loadApiBaseUrl() + pathWithQuery;
}

function loadApiBaseUrl(): string
{
    if(isProduction) { return 'https://har-crm-api.har.com'; }
    else if (isLocal && window.location.port !== '80' && window.location.port !== '') { return '/har-crm-api'; } // docker
    //else if(isLocal) { return '/har-crm-api'; }
    return 'https://har-crm-api-test.har.com';
}

export async function loadHeaders(url:string): Promise<Record<string, string | number>>
{
    const [{operatingSystem,osVersion},{uuid}] = await Promise.all([
        Device.getInfo(),
        Device.getId()
    ]);

    const expiration = loadExpiration();
    const xAuth = signUrl(url, expiration);
    const deviceType = platform.isIos ? "ios" : (platform.isAndroid ? "android" : "web");

    return {
        "X-Auth": xAuth,
        "X-Token": TOKEN,
        "X-Expires": expiration.toString(),
        "X-Device-Type": operatingSystem + ":" + osVersion,
        "X-Device-ID": uuid,
        "X-App-Type": deviceType,
        "X-App-Name": "com.har.crm",
        "X-Test-Mode": isProduction ? "0" : "1",  //(0=live, 1=test)
        "X-Origin": window.location.origin,
        "X-App-Version": version.toString()
    }
}

async function handleResponse<TResponse>(endpoint: IApi<any, any, any, any, any, any>, request: Promise<Response>, headers:Record<string, string | number | undefined>): Promise<[Response, TResponse]> {
    let content: string | JSX.Element | undefined;
    let systemContent: string;
    let error: ServiceError<unknown>;
    let response:Response | undefined;

    try {
        // Success
        response = await request;
        const promise = response[endpoint.contentType ?? 'json']();
        if (response.ok) { return [response, await promise]; }

        // Checking for logout
        const { authenticate = true } = endpoint;
        if(authenticate && response.status === 401) {
            Auth.captureLogoutMessage(`API returned 401 [${endpoint.url}]`, { tags:headers });
            Auth.signOut(true);
            throw new Error('forbidden');
        }

        const result = await promise as { message?: string; system_message?: string };
        systemContent = result ? JSON.stringify(result) : response.statusText;
        content = isLocal ? joinJSXWithSeparator(<br />, result.message, result.system_message) : result.message;
        if (!isProduction) { console.error(result ?? response.statusText); }
        error = ServiceError.from(result.message ?? response.statusText ?? 'Internal Error', response, endpoint.scope.responseError?.(result) ?? result);
    }
    catch (e: unknown) {
        if (e && e instanceof DOMException && e.name === 'AbortError') { throw e; }
        if (e && e instanceof Error && e.message === 'forbidden') { throw new Error('User is not authenticated.'); }

        systemContent = e instanceof Error ? e.message : JSON.stringify(e);
        content = e instanceof Error ? e.message : undefined;
        if (!isProduction) { console.error(e); }
        error = e instanceof Error? ServiceError.from(e, response): ServiceError.from(content, response);
    }

    // Display Error
    setTimeout(() => {
        if(error.isDefaultPrevented) { return; }
        content = (<>
            {content ?? 'Internal Error'}
            {!isProduction && <><br /><em>[Debug] Check console to see more details</em></>}
        </>);
        displayError(endpoint.defaultError, { content, systemContent });
    }, 10);

    throw error;
}

function loadUrl(url: string, parameters: any): string {
    // Looking for dynamic parameters
    const expression = /(\/:[A-Za-z0-9_]+)+?/;
    let result = expression.exec(url);
    while (result) {
        const parameter = result[0];
        if (!parameter) { continue; }

        const name = parameter.slice(2);
        if (!parameters[name]) { throw new ServiceError(`parameter ${name} missing.`); }
        url = url.replace(parameter, `/${parameters[name]}`);
        delete parameters[name];

        result = expression.exec(url);
    }

    return '/' + trimStart(url, '/').trim();
}

export function loadExpiration() {
    const timestamp = new Date();
    timestamp.setHours(timestamp.getHours() + 2);
    return timestamp.getTime();
}

export function signUrl(url: string, expiration: number): string {
    const secretKey = "HArG1@b1A1S3crE7K3y-0r1va7e0n14)";
    // const encodedUrl = encodeURIComponent(url);
    // const encodedUrl = encodeURIComponent(url).replace(/%20/g, '+');
    const encodedUrl = encodeURIComponent(url)
        .replace(/%20/g, '+')
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29")
        .replace(/\*/g, "%2A")
        .replace(/!/g, "%21")
        .replace(/'/g, "%27")
        .replace(/@/g, "%40")  // Replace @ with %40
        //.replace(/#/g, "%23")  // Replace # with %23
        .replace(/\$/g, "%24"); // Replace $ with %24
    //console.log(encodedUrl);
    // var endpoint_method = url.replace("https://api.har.com", "");
    // const value = md5('123456789').toString();
    // console.log(value)
    return md5(`${encodedUrl}${TOKEN}${secretKey}${expiration}`).toString();
}