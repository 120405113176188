import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IAssignScoreProps } from './AssignScore.def';
import './AssignScore.scss';
import { ScoreOptions } from 'views/contacts/ScoreOptions';
import { OverlayTrigger } from 'react-bootstrap';
import { popover_score } from 'views/contacts/ContactInsightSection/components/InfoOverlayTrigger';


export const AssignScoreDesktop = (props: IAssignScoreProps): JSX.Element => {
    const { contact ,score,setScore} = props

    return (
        <>
            <DefaultPopUp.Body>
                    <div className="px-5 py-2">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Select Score  <OverlayTrigger trigger="hover" placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                        <ScoreOptions initialSelected={score||contact.score} onScoreChange={setScore} />
                    </div>
                </DefaultPopUp.Body>
              
        </>
    );
}
