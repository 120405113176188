import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IServiceSection, IServicesLandingScreenProps, services } from './ServicesLandingScreen.def';
import './ServicesLandingScreen.scss';
import { joinWithSpace } from 'scripts/helpers/texts';
import { useResponsive } from 'scripts/hooks/general';
import { IonRouterLink } from '@ionic/react';

export const ServicesLandingScreen = (_props: IServicesLandingScreenProps): JSX.Element =>
{
    const isResponsive = useResponsive();

    return (
        <DefaultTemplate title="Services">
            <div className="container">
                <div className="pt-5">
                    {!isResponsive && <h2 tabIndex={0} className="font_weight--semi_bold font_size--large_extra_extra_extra">Services</h2>}
                    <div className="font_size--large mb-5">
                        <div className="font_weight--bold">Engage your clients to keep them informed on real estate trends, current market activity and more!</div>
                        Below are tools to assist you in providing superior service and increase client engagement.
                    </div>

                    <div className='row'>
                        <div className='col-md-8 col-12'>
                            <ServicesCards className='col-lg-6' />
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className=' border_radius--pudgy border light py-4 px-4'>
                                <h2 tabIndex={0} className="font_weight--semi_bold font_size--large_extra_extra mb-3">CRM Integrated Tools</h2>
                                <div className='pb-4 font_size--large color_auxiliary'>The following HAR tools and features are integrated into the HAR CRM:</div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Showing Smart</div>
                                    <div className='font_size--medium color_auxiliary mb-2'>HAR’s showing service that was developed for all MLS subscribers. Manage and record all showings and tours for your clients.</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.showingsmart.com/agent' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                        <span className=' color_auxiliary_light pl-2 pr-2'>|</span>
                                        <div><a href='https://www.showingsmart.com/' target='_blank' className='font_size--medium font_weight--semi_bold'>Learn more</a></div>
                                    </div>
                                </div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Instant CMA</div>
                                    <div className='font_size--medium color_auxiliary mb-2'>Create an Instant CMA for your clients to assist in determining the best price for a property.</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.har.com/moa_cma/instant_cma' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                        <span className=' color_auxiliary_light pl-2 pr-2'>|</span>
                                        <div><a href='https://cms.har.com/instantcma/' target='_blank' className='font_size--medium font_weight--semi_bold'>Learn more</a></div>
                                    </div>
                                </div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Fix & Flip Analysis</div>
                                    <div className='font_size--medium color_auxiliary mb-2'>Generate a comprehensive analysis of a property's potential value, including estimated market value, repair and remodel costs, and After Repair Value (ARV).</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.har.com/moa_mlstools/investment_tools/fix_flip' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                    </div>
                                </div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Cash Flow (Cap Rate)</div>
                                    <div className='font_size--medium color_auxiliary mb-2'>Utilize this calculator to provide a comprehensive analysis of a property's financial performance.</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.har.com/moa_mlstools/investment_tools/cash_flow' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                    </div>
                                </div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Seller’s Net Sheet</div>
                                    <div className='font_size--medium color_auxiliary mb-2'>Create a report that estimates the amount a property seller will receive from the sale after accounting for various costs and fees.</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.har.com/moa_financial_tools/seller_netsheet' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                    </div>
                                </div>
                                
                                <div className='border-bottom border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Buyer’s Closing Costs</div>
                                    <div className='font_size--medium color_auxiliary'>Calculate the estimated additional expenses a homebuyer can expect to pay at the closing of a real estate transaction.</div>
                                    <div className='d-flex'>
                                        <div><a href='https://www.har.com/moa_financial_tools/buyer_worksheet' target='_blank' className='font_size--medium font_weight--semi_bold'>Access</a></div>
                                    </div>
                                </div>

                                <div className='border-color--cloudy-sky-light py-4'>
                                    <div className='font_weight--semi_bold font_size--large'>Agent-Branded App</div>
                                    <div className='font_size--medium color_auxiliary'>The app allows Platinum subscribers to directly collaborate with their clients with the Mobile App Chat, Agent-branded listings, access to Premium Content, and more.</div>                                        
                                    <div className='d-flex'>
                                        <div><a href='https://cms.har.com/agentbrandedapp/' target='_blank' className='font_size--medium font_weight--semi_bold'>Learn more</a></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultTemplate>
    );
}

function ServicesCards(props:{ className?:string })
{
    return (
        <div className="row">
            {services.map((service, index) => (
                <div key={index} className={joinWithSpace('service-section col-md-6 col-12 pb-4', props.className)}>
                    <div className={joinWithSpace('service-section-container border_radius--pudgy border py-md-5 px-3 px-md-3 py-3 text-center h-100', service.disabled && 'unavailable')}>
                        <div className="pb-4"><img src={service.icon} alt={service.title} /></div>
                        <div className="font_size--large_extra font_weight--bold pb-3">{service.title}</div>
                        <div className="font_size--medium text-center ml-auto mr-auto pb-5 color_cement_dark max-w-300">{service.description}</div>
                        <Link service={service} />
                        {service.learnMoreLink && <div className="pt-4">
                            <a href={service.learnMoreLink} target='_blank' className={joinWithSpace("font_weight--semi_bold font_size--medium", service.disabled && 'disabled')} rel="noreferrer">Learn more</a>
                        </div>}
                    </div>
                </div>
            ))}
        </div>
    );
}

const Link = (props:Record<'service', IServiceSection>) =>
{
    const { service } = props;
    const { link } = service;
    const disabled = service.disabled || !link;
    const className = joinWithSpace("d-inline-block btn--large btn btn--primary", disabled && 'disabled');
    const label = service.actionLabel ?? 'Access Dashboard';
    const isRelative = link?.startsWith('/');
    const linkKey = isRelative? 'routerLink' : 'href';
    const target = isRelative? undefined : '_blank';
    const computedProps = { [linkKey]:disabled? undefined: link, target, className };
    return isRelative?
        <IonRouterLink {...computedProps}>{label}</IonRouterLink>:
        <a {...computedProps}>{label}</a>
}