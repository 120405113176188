import { join, joinWithSeparator } from "scripts/helpers/texts";

export function formatFullPhone(phone:number | string, extension?:number | string, country_code?:number | string)
{
    return joinWithSeparator(' ext.', formatPhone(join(country_code, phone)).toString().split(',').join(''), extension);
}

export function buildTelLink(phone:number | string, extension?:number | string, country_code?:number | string)
{
    return 'tel:' + joinWithSeparator(',', join(country_code, phone.toString()).split(',').join(''), extension);
}


/** TODO: Improve and simplify below function later */
const formatPhone = (value: string) => {

    const newValue = value.replace(/[""() -]/g, "");
    const size = newValue.length + 4;
    const firstIndex = '(';
    const fourthIndex = ')';
    const fifthIndex = ' ';
    const lastIndex = '-';
    var patternArray = new Array(size);
    patternArray[0] = firstIndex;
    patternArray[4] = fourthIndex;
    patternArray[5] = fifthIndex;
    if (newValue.length > 6) {
        patternArray[9] = lastIndex;
    }
    for (let i = 0; i < newValue.length; i++) {
        if (patternArray[i] === undefined) {
            patternArray[i] = newValue[i]
        } else if (patternArray[i + 1] === undefined) {
            patternArray[i + 1] = newValue[i]
        } else if (patternArray[i + 2] === undefined) {
            patternArray[i + 2] = newValue[i]
        } else if (patternArray[i + 3] === undefined) {
            patternArray[i + 3] = newValue[i]
        } else if (patternArray[i + 4] === undefined) {
            patternArray[i + 4] = newValue[i]
        }

    }
    return patternArray;
}