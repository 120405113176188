import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace SearchContactsCountApi {
    export const url = "/contacts/count";
    export const method = "GET";
    export const defaultError = "Failed to search contacts.";
    export const operations = apiOperations(SearchContactsCountApi);
    export const scope = new class SearchContactsCountApiScope implements IApiScope<Request, Response> {
        request(data: Request) {
            return {
                cities: data.cities,
                zip_codes: data.zip_codes,
                deal_stages: data.deal_stages,
                stages: data.stages,
                scores: data.scores,
                object_owners: data.object_owners,
                companies: data.companies,
                tag_ids: data.tag_ids,
                sources: data.sources,
                last_contact_date: data.last_contact_date,
                is_filter: data.is_filter,
                birthday_start: data.birthday_start,
                birthday_end: data.birthday_end,
                home_anniversary_start: data.home_anniversary_start,
                home_anniversary_end: data.home_anniversary_end,
                wedding_anniversary_start: data.wedding_anniversary_start,
                wedding_anniversary_end: data.wedding_anniversary_end,
                known_since_start: data.known_since_start,
                known_since_end: data.known_since_end,
                lead_type: data.lead_type,


            };
        }
        response(input: Record<'data', Response>) { return input.data; }
    }();

    /** Request is same as Search Contacts API */
    export interface Request {
        cities: string,
        zip_codes: string,
        deal_stages: string,
        stages: string,
        scores: string,
        object_owners: string,
        companies: string,
        tag_ids: string,
        sources: string,
        last_contact_date: string,
        is_filter?: string,
        birthday_start?: string,
        birthday_end?: string,
        home_anniversary_start?: string,
        home_anniversary_end?: string,
        wedding_anniversary_start?: string,
        wedding_anniversary_end?: string,
        known_since_start?: string,
        known_since_end?: string,
        lead_type?: string,
    }

    export interface Response {
        total: number;
    }

}

export const fetchSearchContactsCountService = forwardOperation(SearchContactsCountApi.operations, 'fetch');
export const useFetchSearchContactsCountService = forwardOperation(SearchContactsCountApi.operations, 'useFetch');
export const useSearchContactsCountService = forwardOperation(SearchContactsCountApi.operations, 'useService');