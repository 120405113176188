import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"
import { GetContactApi } from "./getContact";
import { switchCase } from "scripts/helpers/general";
import { buildFullPhone } from "../createContact";

export namespace UpdateContactApi
{
    export const url = "/contacts/:contact_id";
    export const method = "PUT";
    export const defaultError = "Failed to load contact information";
    export const operations = apiOperations(UpdateContactApi);
    export const scope = new class UpdateContactApiScope implements IApiScope<Request, Response, ResponseError>
    {
        request(data:Request): DeepPropertiesType<Request, string|number>
        {
            return {
                ...data,
                score: data.score,
                emails: data.emails?.map(item => ({ ...item, preferred: item.preferred === true? '1': '0',id:item.id?.toString() })),
                phones: data.phones?.map(item => ({ ...item, phone:this._buildPhone(item), preferred: item.preferred === true? '1': '0',id:item.id?.toString() })),
                addresses: data.addresses?.map(item => ({ ...item, zipcode:item.zipcode.toString(),id:item.id?.toString() })),
                tags: data.tags?.map(item => ({ ...item, id:item.id?.toString() })),
                
            }
        }

        response(data:Response) { return data; }

        responseError(response:ServerResponseError): ResponseError
        {
            const { data, ...other } = response;
            const is_duplicated = switchCase(data?.is_duplicated, '1', true, '0', false);
            return { ...other, ...data, is_duplicated };    
        }

        _buildPhone(item:IPhoneItem) {
            const { phone, phone_extension, phone_type } = item;
            delete item.phone_extension;
            return buildFullPhone(phone, phone_extension, phone_type);
        }
    }();
    
    export interface Request
    {
            contact_id:string
            photo_url?: string,
            first_name: string,
            last_name: string,
            middle_name?: string,
            title?: string,
            name_suffix?: string,
            gender: string,
            birthday?: string,
            known_since?: string,
            income?: string,
            job_title?: string,
            company?: string,
            score?: string,
            pipeline_status?: string,
            anniversary_date?: string,
            home_anniversary_date?: string,
            contact_type?: string,
            sources?: string,
            emails?: Array<IEmailItem>,
            phones?: Array<IPhoneItem>,
            addresses?: Array<IAddressItem>,
            relationships?: Array<IRelationshipItem>,
            links?: Array<ILinkItem>,
            tags?: Array<ITagItem>,
            lead_type?: string,
            reminder_days_after?:string
    }

    export interface Response
    {
      
    }

    export interface ServerResponseError
    {
        data?: PropertiesType<ResponseError, string>;
        message:string;
    }

    export interface ResponseError
    {
        is_duplicated?:boolean;
        message:string;
    }
    
    export interface IEmailItem
    {
        id?:number;
        email:string;
        preferred?: boolean;
    }

    export interface IPhoneItem
    {
        id?:number;
        phone:string;
        phone_type:string;
        phone_extension?:number;
        preferred?: boolean;
    }

    export interface IAddressItem
    {
        id?:number;
        address1: string,
        address2?: string,
        address_type: string,
        city: string,
        zipcode: string
    }

    export interface IRelationshipItem
    {
        id?:number;
        type: string;
        related_contact_id:number;
        //name?: string;
    }

    export interface ILinkItem
    {
        id?:number;
        name: string;
        url: string;
    }

    export interface ITagItem
    {
        id?: number;
        name: string;
    }
}
    export const initialAddress = () => ({address1:"",address2:"",address_type:"",city:"",zipcode:""});
    export const initialContactInsight = () => ({scoreType: "",
    pipelineType: "",
    sourceType: "",
    tags: [{name:"",id:undefined}]});

    export const transformEditData=(data:GetContactApi.Response|undefined)=>{
        if(!data){return {basicInfoDt:{},contactDataDt:{_placeholder:true, emails:[],phones:[],links:[]},relationshipsDt:[]}}
        const initialEmail={value:"",error:"",preferred:true,importEmail:true,id:undefined};
        const initialPhone={value: "",error:"",preferred:true,phone_type:"",phone_extension:undefined};
        const initialLink={value:"",error:"",link_type:""};
      
        const initialRelationship={type:""}
        return{
        basicInfoDt:{ 
            id: parseInt(data.id),
            image: data.photo_url,
            firstName: data.first_name,
            middleName: data.middle_name,
            lastName: data.last_name,
            title: data.title,
            suffix: data.name_suffix,
            gender: data.gender,
            dateOfBirth: data.birthday,
            knownSince: data.known_since,
            homeAniversary: data.home_anniversary_date,
            weddingAniversary: data.anniversary_date,
            income: data.income,
            jobTitle: data.job_title,
            company: data.company,
            reminder_days_after:data.reminder_days_after?.split(","),},
            contactInsightDt:{scoreType: data.score,
                pipelineType: data.pipeline_status,
                sourceType: data.sources,
                tags:data.tags,
                leadType:data.lead_type}??initialContactInsight(),

        contactDataDt:{
           emails:!!data.emails.length? data.emails.map((x)=>({value:x.email,importEmail:false,error:"",preferred:x.preferred,id:x.id})):[initialEmail],
           phones:!!data.phones.length? data.phones.map((x)=>({value:x.country_code+x.phone,error:"",preferred:x.preferred,phone_type:x.phone_type,phone_extension:x.phone_extension,id:x.id})):[initialPhone],
           links:!!data.links.length? data.links.map((x)=>({value:x.url,error:"",link_type:x.name,id:x.id?Number(x.id):undefined})):[initialLink],
        },

           addressesDt:!!data.addresses.length? data.addresses.map((x)=>({...x,id:x.id?Number(x.id):undefined,zipcode:x.zipcode,address1:x.address1,address2:x.address2,city:x.city,address_type:x.address_type})):[initialAddress()],

           relationshipsDt:!!data.relationships.length? data.relationships.map((x)=>({id:x.id?Number(x.id):undefined,type:x.type,contact:x.contact})):[initialRelationship],

           linksDt: data.links.map((x)=>({...x,label:(x.name)})),
         
        }
    }


    


export const fetchUpdateContactService = forwardOperation(UpdateContactApi.operations, 'fetch');
export const useFetchUpdateContactService = forwardOperation(UpdateContactApi.operations, 'useFetch');
export const useUpdateContactService = forwardOperation(UpdateContactApi.operations, 'useService');