import { useOnboardingStage } from 'scripts/hooks/onboarding';
import { IWelcomeScreenProps } from './WelcomeScreen.def';
import './WelcomeScreen.scss';
import { eOnboardingStage } from 'scripts/enums';
import { OnBoardingTemplate } from 'views/templates/OnBoardingTemplate';
import { Slider } from '../Slider';
import { GetStartedButton, PasswordPopUp, SkipButton } from './WelcomeScreen.common';


export const WelcomeScreenDesktop = (_props:IWelcomeScreenProps): JSX.Element =>
{ 
    const onboardingState = useOnboardingStage(eOnboardingStage.BasicInfo);
    if(onboardingState.error) { return <></>; }
 
    return (
        <OnBoardingTemplate onboarding simple>
          
            <div className="pt-md-5 pt-0 pb-0 pb-md-4 position-relative">
                <Slider />   
                <div className="pt-4 text-center mt-1">
                    <GetStartedButton onboarding={onboardingState} className="btn--large btn--prominent__v2" />
                    <SkipButton onboarding={onboardingState}>Skip</SkipButton>
                </div> 
               <PasswordPopUp user={onboardingState.user} />
            </div>
          
          
            {/* <div className="row pt-md-4 pt-0 pb-0 pb-md-4">
                <div className="col-12 col-md-6 pb-md-0 pb-5">
                    <div>
                        <h3 >Simple way to organize your leads,<br />contacts, emails and more.</h3>
                        <p className="font_size--large mb-5">
                            CRM is more than initiating contact with potential leads.It involves nurturing contacts and building loyalty while maintaining a dynamic repository of contact information and client history.Making this information accessible for collaborative teams and piping it out to other business software solutions via smart integrations is the logical next step.
                            <br /><br />
                            User experience (UX) has become more important than ever, and being able to efficiently manage a businesses’ relationship with customers through the entire sales and after-sales process is a key consideration.For small to midsize businesses (SMBs) trying to emulate CRM functionality on a large and unwieldy spreadsheet could lead to a lot of confusion and redundancy.
                        </p>
                        <button type="button" className="btn btn--prominent btn--large" onClick={handleGetStarted} disabled={onboardingState.loading || onboardingState.next.loading}>Get Started</button>
                    </div>
                </div>
                <div className="col-12 col-md-6"><Slider /></div>
            </div > */}
        </OnBoardingTemplate >
    );
}

