import { apiOperations, forwardOperation } from 'scripts/helpers/apis';
import { IApiScope } from 'types/apis';

export namespace getSampleImport {
  export const url = '/contacts/import_export_template';
  export const method = 'GET';
  export const defaultError = "unable to download.";
  export const operations = apiOperations(getSampleImport);
  export const scope = new (class ImportCsvApiScope implements IApiScope<Request, Response> {
    request(data: Request) {
      return data;
    }
    response(data: Response) {
      return data;
    }
  })();

  export interface Request {
  }

  export interface Response {
    status: string,
    message: string,
    data: {
      file: string
    }
  }
}

export const fetchGetSampleImportService = forwardOperation(getSampleImport.operations, 'fetch');
export const useFetchGetSampleImportService = forwardOperation(getSampleImport.operations, 'useFetch');
export const useGetSampleImportService = forwardOperation(getSampleImport.operations, 'useService');
