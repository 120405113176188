import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"
// import { INoteList } from "views/screens/contacts/ContactDetailScreen/ContactDetailScreen.desktop";

export namespace GetNotesApi {
    export const url = "/contacts/:contact_id/notes";
    export const method = "GET";
    export const defaultError = "Couldn't found notes";
    export const operations = apiOperations(GetNotesApi);
    export const scope = new class GetNotesApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): DeepPropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                // list: data?.list.map((x) => ({
                //     id: x.id,
                //     notes: x.notes,
                //     created_dt: x.created_dt,
                //     modified_dt: x.modified_dt
                // }))
            }
        }
    }();

    export interface Request {
        contact_id: string;
    }

    export interface Response {
        total: string,
        page: string,
        page_size: string,
        total_pages: string,
        list: {
            id: string,
            notes: string,
            created_dt: string,
            modified_dt: string
        }[],

    }

    // export interface INoteListResponse extends INoteList {
    //     id:string,
    //     notes:string,
    //     created_dt:string,
    //     modified_dt:string
    // }

}

export const fetchGetNotesService = forwardOperation(GetNotesApi.operations, 'fetch');
export const useFetchGetNotesService = forwardOperation(GetNotesApi.operations, 'useFetch');
export const useGetNotesService = forwardOperation(GetNotesApi.operations, 'useService');