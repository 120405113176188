import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSearchContactsService } from 'services/contacts/searchContacts';
import { ContactCard } from '../ContactCard';
import { IStageAddContactsProps, IStageColumnLoadingProps } from '../StageColumn.def';
import { IAddPopoverProps } from './AddPopover.def';
import './AddPopover.scss';

export const AddPopoverDesktop = (props: IAddPopoverProps): JSX.Element => {
    const { visible, pipeline, setVisiblity, addContact } = props
    const [timer, setTimer] = useState<number | string>()
    const [contacts, setContact] = useState([]);
    const [limit, setLimit] = useState(0)
    const [contactSearch, ContactSearchAction] = useSearchContactsService()
    const searchField = useRef<HTMLInputElement | null>(null)
    let history = useHistory<IStageAddContactsProps>();

    const doSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (e.target.value.length === 0) {
            init()
            return
        }
        if (timer) {
            clearTimeout(timer)
        }
        setLimit(8)
        setTimer(setTimeout(() => {
            ContactSearchAction.fetch({ name: e.target.value, sort_by: 'first_name', sort_dir: e.target.value.length > 0 ? 'asc' : 'desc', page: 1 })
        }, 500))
    }

    const overrideAddContact = (contact: any, param: any) => {
        let updatedContact = contacts.filter((c: any) => c.id !== contact.id)
        setContact(updatedContact);
        addContact?.(contact, param)
    }

    const reset = () => {
        setContact([])
        if (null !== searchField.current) {
            searchField.current.value = ""
        }
        if (setVisiblity) {
            setVisiblity()
        }
    }
    const init = () => {
        setLimit(4)
        ContactSearchAction.fetch({ name: '', stages: [0], sort_by: 'created_dt', sort_dir: 'desc', page: 1 })
    }


    const loadContacts = () => {
        const newContacts: any = [];
        if (contactSearch.response) {
            contactSearch.response?.list.map((contact: any, index: number) => {
                if (index < limit)
                    newContacts.push(contact);
            })
            setContact(newContacts);
        }
    }


    useEffect(() => {
        if (visible) {
            init()
            if (null !== searchField.current) {
                searchField.current.value = ""
            }
        }
    }, [visible])

    useEffect(() => {
        loadContacts()
    }, [contactSearch])

    return (
        <div >
            <div className={`add_pipeline_item ${visible ? 'd-block' : 'd-none'}`} style={{ display: visible ? 'block' : 'none' }}>
                <div className="add_pipeline_item__title">
                    <div className="">Add Contacts to Pipeline</div>
                    <a className="add_pipeline_item__close" href="#" onClick={(e: any) => {
                        e.preventDefault()
                        if (setVisiblity) {
                            setVisiblity()
                        }
                    }}><img src="https://content.harstatic.com/media/icons/close_medium_gra.svg" /></a>
                </div>
                <div className="add_pipeline_item__content">
                    <div className="pt-2 pb-4">
                        <label htmlFor="SearchKeyWord" className="sr-only"></label>
                        <input ref={searchField} type="text" className="form-control" onChange={(e) => doSearch(e)} id="SearchKeyWord" aria-describedby="SearchKeyWord" placeholder="Search contacts" />
                    </div>

                    <div className="results max-h-220px overflow-scroll">
                        {!contactSearch.loading && searchField.current &&
                            <>
                                {contacts.length === 0 ?
                                    <div>
                                        <div className='pb-2'>No Contacts Found</div>
                                        <div className='border-top pt-3'>
                                            <div className="agent_signature__text__title text-truncate">{searchField.current.value}</div>
                                            <a href='#' className='font-weight-bold' onClick={(e: any) => {
                                                e.preventDefault()
                                                setTimeout(() => {
                                                    if (searchField.current) {
                                                        let array = searchField.current.value.split(" ")
                                                        history.push(`/contacts/create`, {
                                                            first_name: array[0] ? array[0] : searchField.current.value,
                                                            last_name: array[1] ? array[1] : "", pipeline_status: pipeline ? pipeline : 0
                                                        })

                                                    } else {
                                                        history.push(`/contacts/create`)
                                                    }
                                                }, 400)
                                            }}>

                                                Add New Contact
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <div className='pb-3'>
                                        {searchField.current.value.length === 0 ?
                                            <div> Recent Contacts</div>
                                            :
                                            <div> Contacts Found</div>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {!contactSearch.loading && contacts.map((contact: any, index: any) =>
                            <div key={index} className={`card card_hover_edit text-left mb-3 border-0 card__mini`}>
                                <ContactCard
                                    key={contact.id}
                                    contact={contact}
                                    inList={Number(contact.pipeline_status) === pipeline ? true : false}
                                    pipeline={pipeline}
                                    showDeal={false}
                                    addContact={overrideAddContact}
                                    clear={reset}
                                    miniCard={true}
                                />
                            </div>
                        )}

                    </div>
                    {
                        contactSearch.loading &&
                        <LoadingState fields={2} />
                    }
                </div>
            </div>
        </div>
    );
}

const LoadingState = (props: IStageColumnLoadingProps): JSX.Element => {
    const { fields } = props
    const LoadingField = (): JSX.Element => {
        return (
            <div className="card text-left mb-3 border-0 card__mini">
                <div className="agent_signature agent_signature__medium agent_signature__loading">
                    <div className="agent_signature__photo loading"></div>
                    <div className="agent_signature__text">
                        <div className="agent_signature__loading__textline max-w-50"></div>
                        <div className="agent_signature__loading__textline max-w-90"></div>
                    </div>
                </div>
            </div>
        );
    }
    const rows: number[] = [];
    for (let i = 0; i < fields; i++) {
        rows[i] = i
    }
    return (
        <>
            {
                rows.map((index: number) =>
                    <LoadingField key={index} />
                )
            }
        </>
    );

}
