import './NotificationScreen.scss';
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { INotificationScreenProps } from './NotificationScreen.def';
import './NotificationScreen.scss';
import { useFetchGetNotificationsService,GetNotificationsApi} from 'services/notifications/getNotifications';
import { NotificationsCard,Empty } from './components/NotificationsPopup/NotificationsPopup';
//import { FilterPopUp } from 'views/calendar/popups/FilterPopUp';
import { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonHeader, IonSearchbar, IonToolbar,IonTitle,IonContent} from '@ionic/react';
import { useHistory } from 'react-router';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import {  ShapelessButton } from 'views/forms/buttons/Button';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { fetchMarkNotificationService } from 'services/notifications/markNotification';
import { displayMessage } from 'scripts/hooks/feedbacks';

//const filterTypes= [{long_value:"Email",short_value:"Email"},{long_value:"Deal",short_value:"Deal"},{long_value:"Service",short_value:"Service"},{long_value:"Document",short_value:"Document"},{long_value:"Calendar",short_value:"Calendar"},{long_value:"Contact",short_value:"Contact"}]
    var timeout: any;
export const NotificationScreenApp = (_props:INotificationScreenProps): JSX.Element =>
{
   const [{response,loading},action]=useFetchGetNotificationsService();
   const [data,setData]=useState<Array<GetNotificationsApi.IListData>>([])
    const [filter,setFilter]=useState({input:"",types:""})
    const history=useHistory()
    const [showFilter, setShowFilter] = useState(false)

    const handleFilterData = async (type: string) => {
        action.fetch({types:type,input:filter.input,page_size:(response?.page_size??20)+20})
        setFilter({...filter,types:type??""})
        
    }
    useEffect(()=>{!loading&&setData(response?.list??[])},[response])
    const loadMore = async () => {
        await action.fetch({...filter,page_size:(response?.page_size??20)+20})
    }

    const onChangeSearchText = async (text: string) => {
        clearTimeout(timeout);
        if (text.length > 0) {
            timeout = setTimeout(() => action.fetch({ ...filter, input: text }), 500);
            setFilter({ ...filter, input: text });

        } else if (text.length === 0) {
            action.fetch({ ...filter, input: "" })
        }
    };
    const handleMarkAllRead=async()=>{
        await fetchMarkNotificationService({read:"1"});
        action.fetch(filter);
        displayMessage("Successfully marked all as read");
     }
 return (<>
   <Filter  handleSetFilter={(type)=>handleFilterData(type)} show={showFilter} onClose={() => setShowFilter(false)} type={filter.types}  />

      <DefaultTemplate  title="Notifications">
      
        <div className="">
            {/*<h2 className='pl-4 '>Notifications</h2>
            <div className=''> 
                <button role="button" className="btn btn--black mt-3 ml-4" onClick={handleMarkAllRead}><img className="mr-2" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" />Mark all as read</button> 
            </div>  
             */}
            
                <div className='row no-gutters align-items-center'>
                    <div className="col">
                        <ShapelessButton onClick={()=>history.push(`/home`)}><img  onClick={()=>history.push(`/home`)}  src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />Home</ShapelessButton>
                    </div>
                    <div className="col-auto">
                        <div className="font_weight--bold cursor--hand color_har_blue ion-padding-end" onClick={handleMarkAllRead}>
                            {/*<img className="" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" />*/}
                            Mark all as read
                        </div>
                    </div>
                </div>

        </div>
  <div className=" pl-3">
     <div className="row">
               <div className="col-md-9">
               <IonSearchbar onIonChange={(e)=>onChangeSearchText(e.detail.value ?? "")} className="custom ion-no-padding ion-padding-end" placeholder="Search notifications.."></IonSearchbar>
               </div>
             </div>
     <div className="action_bar" >
         {response?.total == 0?<div className="align-self-center flex-grow-1"></div>: <div className="align-self-center flex-grow-1">{response?.total??0} notifications </div>}
            <div className="action_bar__link">
           {filter.types.split(",").length>1&& <div className="filterpill filterpill--filters ml-auto flex-grow-0 mr-2 text-capitalize">
              {(filter.types.split(",")[1]??"") +(filter.types.split(",").length>2? (" +"+(filter.types.split(",").length-2)):"")}
                 <a onClick={() =>handleFilterData("")} ></a>
                 </div>}
                 <a className="action_bar__link ml-2 cursor--hand" onClick={() => setShowFilter(true)}><img className=' w-24px' src="https://content.harstatic.com/media/icons/filters.svg" /> </a>
            </div>
        </div>      
         </div>
       
         <div className="pt-4 pl-4 pr-4 pb-4">
            {data.find((item)=>item.date_tag==='today')&&<div className="title__label mb-3 color_snow_white">New</div>}
                {data.map((item,index)=>item.date_tag=='today'&&<div className='card--email cursor--hand pr-0 pl-0 bg-color-f3f4f7'> <NotificationsCard key={index} {...item}/></div> )}
            {data.find((item)=>item.date_tag==='older'||item.date_tag==='yesterday')&&<div className="title__label mt-3 mb-3 color_snow_white">Previous</div>}
                {data.map((item,index)=>item.date_tag!=='today'&&<NotificationsCard key={index} {...item}/> )}
                {loading&&<Loading/>}
                {response?.list.length == 0 && <Empty title='notifications'/>}
         </div> 
        
         <>
           {response?.page!==response?.total_pages&&<div className="notification_card_footer p-1 text-right">
                <ShapelessButton loading={loading} color={"blue"} onClick={loadMore} size='small' >  View more results</ShapelessButton></div>}
                </> 
 
              
            </DefaultTemplate></>
    )
}

function Loading(_props: { }): JSX.Element {
    return (
                <>    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    </>
    )
}
  

interface IFilter {
    show: boolean,
    type: string,
    onClose: () => void
    handleSetFilter:(types:string)=>void
}

const Filter = (props: IFilter) => {
    const { show, type, onClose,handleSetFilter } = props
    const isChecked=(key:string)=>type.split(",").includes(key);
    
    const setFilter=(key:string)=>{
        if(isChecked(key)){
           // handleSetFilter(type.replace(","+key,""))
        }else{
        handleSetFilter(key)}
    }
    return (
        <DefaultPopUp hideHeader className="gray_bg" isOpen={show} onDidDismiss={() => onClose?.()} >
            <DefaultPopUp.Body>
            <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonButtons className="w-15pr" slot="start">
                        <IonButton color={"blue"} strong={true} onClick={() => handleSetFilter("")}>Reset</IonButton>
                    </IonButtons>
                    <IonTitle>Filters</IonTitle>
                    <IonButtons className="w-15pr" slot="end">
                    <IonButton  color={"blue"} strong={true} onClick={() => onClose?.()}>Ok</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
                <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                    <div className=" form_title__large">Type</div>
                    <ToggleButtonGroup className="input-group--outline input-group--outline__grid" type="checkbox" value={type.split(",")} name="Type" onChange={(e)=>setFilter(e.map((val)=>val).toString())} >
                        <ToggleButton className="btn--medium" checked={isChecked("Contact")} id="type-contacts" value={"Contact"}  >
                          Contact
                        </ToggleButton>
                        <ToggleButton className="btn--medium" id="type-deals" checked={isChecked("Deal")} value={"Deal"}>
                          Deal
                        </ToggleButton>
                        <ToggleButton className="btn--medium" id="type-task" checked={isChecked("Calendar")} value={"Calendar"}>
                           Calendar
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>

            </IonContent>
            </DefaultPopUp.Body>
        </DefaultPopUp >
    );
}