import { useTimeout } from "scripts/hooks/timers";
import { output } from "./testUtil";
import { useEffect, useState } from "react";
import { PromiseController } from "scripts/helpers/async";


// Choosing Process to Target
enum eProcess { Async, SingleGenerator, MultiGenerator }
const useProcess = eProcess.MultiGenerator as eProcess;
let process:() => void;
switch(useProcess)
{
    case eProcess.Async: process = asyncProcess; break;
    case eProcess.SingleGenerator: process = generatorProcess; break;
    case eProcess.MultiGenerator: process = generatorProcessMulti; break;
}

export function TimersTest(): JSX.Element
{
    //return <SingleTimer.SingleCall />;
    //return <SingleTimer.MultipleCallsReschedule />;
    //return <SingleTimer.MultipleCallsNoCollision />;
    //return <SingleTimer.MultipleCallsUpcoming />;
    //return <SingleTimer.MultipleCallsCollision />;
    //return <SingleTimer.AbortCall />;
    //return <SingleTimer.CleanUp.Scheduled />;
    return <SingleTimer.CleanUp.Running />; // Fails
}

namespace SingleTimer
{
    export function SingleCall(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
        };

        return <></>;
    }

    export function MultipleCallsReschedule(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
            timer.schedule();
            await wait(800);
            timer.schedule();
            
        };

        return <></>;
    }

    export function MultipleCallsNoCollision(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
            await wait(2500);
            timer.schedule();
        };

        return <></>;
    }

    export function MultipleCallsUpcoming(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
            await wait(3000);
            timer.schedule();
        };

        return <></>;
    }

    export function MultipleCallsCollision(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
            await wait(1500);
            timer.schedule();
        };

        return <></>;
    }

    export function AbortCall(): JSX.Element
    {
        const timer = useTimeout(process, 1000);
        useEffect(() => { run(); }, []);

        async function run()
        {
            timer.schedule();
            await wait(1500);
            timer.clear();
            //timer.schedule();
        };

        return <></>;
    }

    export namespace CleanUp
    {
        export function Scheduled(): JSX.Element
        {
            const [unload, setUnload] = useState(false);
            useEffect(() => { run(); }, []);

            async function run()
            {
                await wait(800);
                setUnload(true);
            };

            return unload? <Final />: <Initial />;
        }

        export function Running(): JSX.Element
        {
            const [unload, setUnload] = useState(false);
            useEffect(() => { run(); }, []);

            async function run()
            {
                await wait(1200);
                setUnload(true);
            };

            return unload? <Final />: <Initial />;
        }

        function Initial(): JSX.Element
        {
            const timer = useTimeout(process, 1000);
            useEffect(() => { run(); }, []);

            async function run()
            {
                timer.schedule();
                await wait(1200);
                timer.schedule();
            };

            return <></>;
        }

        function Final(): JSX.Element
        {
            useEffect(() => { run(); }, []);

            async function run()
            {
                output.log('Final Started!');
            };

            return <></>;
        }
    }
}

async function asyncProcess()
{
    await logWait('Email Validated', 500);
    await logWait('Duplication checked', 800);
    await logWait('Email Saved', 600);
    await logWait('Data Logged', 300);
}

async function* generatorProcess()
{
    yield* PromiseController.step(logWait, 'Email Validated', 500);
    yield* PromiseController.step(logWait, 'Duplication checked', 800);
    yield* PromiseController.step(logWait, 'Email Saved', 600);
    yield* PromiseController.step(logWait, 'Data Logged', 300);
}

async function* generatorProcessMulti()
{
    const isValid = yield* PromiseController.step(validateEmail);
    output.log('isValid: ' + isValid);
    yield* PromiseController.step(logWait, 'Email Saved', 600);
    await logWait('Data Logged', 300);
}

const validateEmail = () => PromiseController.from(async function*()
{
    yield* PromiseController.step(logWait, 'Email Validated', 500);
    yield* PromiseController.step(logWait, 'Duplication checked', 800);
    return true;
});

async function logWait(message:string, timeout:number)
{
    await wait(timeout);
    output.log(message);
}

function wait(timeout:number): Promise<void>
{
    return new Promise<void>(resolve => setTimeout(resolve, timeout));
}