import { IClientPortalScreenProps } from './ClientPortalScreen.def';
import './ClientPortalScreen.scss';
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';
import { useLocation, useParams } from 'react-router';

export const ClientPortalScreen = (_props: IClientPortalScreenProps): JSX.Element =>
{
    const { path = '' } = useParams<{ path:string }>();
    const location = useLocation();

    return (
        <DefaultTemplate title='Client Portal' fluid>
            
            <ExternalEmbedContent
                url='/client-portal/admin'
                initialPath={path}
                queryString={location.search}
                source={eExternalEmbedContentSource.Website}
                defaultTitle='Client Portal'
                iframeTitle='Client Portal'
                enabledInnerRoutes
            />

        </DefaultTemplate>
    );
}
