import { IAssignSourceProps } from './AssignSource.def';
import './AssignSource.scss';
import { useFetchLookupsService } from 'services/general/lookups';

export const AssignSourceDesktop = (props: IAssignSourceProps): JSX.Element => {
    const { contact ,setSource,source} = props
    const [lookupData] = useFetchLookupsService(['contact_source']);

    return (
      
                    <div className="px-5 py-2">
                        <div>                  
                            <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-3">Select Sources</div>
                            <select value={source||contact.sources} className="custom-select custom-select_medium custom-select_large" onChange={(e)=>setSource(e.target.value)}>
                                    {lookupData.response?.contact_source.items.map((item, key) => {
                                return ( <option key={key} value={item.short_value}>{item.long_value}</option>)})}    
                                            </select>
                        </div>
                        </div>
                   
       
    );

}
