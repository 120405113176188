import { useState } from 'react';
import { useMultiRef } from 'scripts/hooks/general';
import { Button } from 'views/forms/buttons/Button';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IAddNotesPopUpProps } from './AddNotesPopUp.def';
import './AddNotesPopUp.scss';

export const AddNotesPopUpDesktop = (props: IAddNotesPopUpProps): JSX.Element => {

    const { selectedNote, onSave, onRemove,isLoading, modalRef, ...popUpProps } = props;
    const [notesData, setNotesInput] = useState(selectedNote?.notes ?? '');
    const [ref, assignRef] = useMultiRef(modalRef);
    const [disableSave,setDisableSave]=useState(false)
    const removeNote = () => {
        onRemove?.(selectedNote!);

    }
    const handleNotesChange = (e:any) => {
        const inputValue = e.target.value;
        // You can set a maximum length if needed
        const maxLength = 3000;
        
        if (inputValue.length <= maxLength) {
          setNotesInput(inputValue);
        }
      };
    const saveNote = async() => {
        setDisableSave(true);
        await props.onSave?.(notesData);
        setDisableSave(false)
    }
    
    return (
        <DefaultPopUp {...popUpProps} title='Notes' modalRef={assignRef}>
            <DefaultPopUp.Body>
                <div className="autoheight_container ion-padding bg-color-fff29b">
                    <div className="form_sec border-0 mb-5 autoheight_container--bottom">
                        <textarea value={notesData} maxLength={3000} className="form-control form-control__notes ion-no-border border-0 textarea__atuogrow " placeholder="Write some notes..." onChange={handleNotesChange} ></textarea>
                        <p>Character Count: {notesData.length}/3000</p>

                      {notesData.length>3000&&  <div className='mt-2 py-1 px-2 bg-color-ffd8d8 color_cc0000 border_radius--default font_size--small_extra'>{"This field should contain max 3000 characters"}</div>}

                    </div>
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="ml-4 mr-4">
                    <div className="row pt-2 mb-2">
                        <div className="col-auto algin-self-center">
                            {selectedNote &&
                                <Button kind='shapeless_red' className="btn--medium" clickConfirmation='Are you sure to delete this note?' onClick={removeNote} >Delete</Button>
                            }
                        </div>
                        <div className="col-auto ml-auto">
                            <Button kind='shapeless' className="btn--medium" onClick={() => ref.current?.dismiss()}>Cancel</Button>
                            <Button kind='primary' loading={disableSave} disabled={disableSave||notesData.length === 0 ? true : false} className="btn--medium" onClick={saveNote} >Save</Button>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp >
    );
}
