export const Empty = (props:{handleShowAddTaskPopup:(value:boolean)=>void}) =>
(
    <div className="card_empty">
        <div className="card_empty__content pt-md-5 pb-md-5 mb-md-5 mt-md-5 max-w-320px">
            <div className="mb-3 d-sm-block d-none"><img className="w-85px" src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
            <div className="mb-3 d-sm-none d-block"><img className="w-50px" src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
            <div className="pt-3 pb-2">
                You currently don’t have tasks.<br />
                Tasks are good to keep you on track.
            </div>
            <div className="font_weight--semi_bold color_har_blue cursor--hand" onClick={()=>props.handleShowAddTaskPopup(true)}>Create an event or task</div>
        </div>
    </div>
);