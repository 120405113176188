import * as Sentry from "@sentry/react";
import { environment } from "scripts/application/settings";
import { history } from 'scripts/router/history';

export interface IMessageOptions
{
  tags: Record<string, string | number | undefined>;
}

const enabled = true;
if(enabled) { configureSentry(); }

function configureSentry()
{
  Sentry.init({
    environment,
    dsn: "https://4e183d34a2de34c748c61389acfde46b@o89982.ingest.sentry.io/4506513926914048",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/har-crm-api\.har\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function login(username:string, email:string)
{
    Sentry.setUser({ email, username });
}

export function logout()
{
    Sentry.setUser(null);
}

export function captureException(ex:any, options?:IMessageOptions): string
{
  return Sentry.captureException(ex, options);
}

export function captureMessage(message:string, options?:IMessageOptions): string
{
  return Sentry.captureMessage(message, options);
}