import { useState } from 'react';
import { useMultiRef } from 'scripts/hooks/general';
import { Button } from 'views/forms/buttons/Button';
import { IonHeader,IonToolbar,IonButtons,IonButton,IonTitle, IonTextarea } from '@ionic/react';
import { DefaultPopUpApp } from 'views/popups/DefaultPopUp/DefaultPopUp.app';
import { usePopUpAutoFocus } from 'scripts/hooks/popups';
import { IAddNotesPopUpProps } from './AddNotesPopUp.def';
import './AddNotesPopUp.scss';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { formatHarDayDateTime } from 'scripts/helpers/dates';
import { parseISO } from 'date-fns';

export const AddNotesPopUpApp = (props:IAddNotesPopUpProps): JSX.Element =>
{
    const { selectedNote, onSave,isLoading, onRemove, modalRef, ...popUpProps } = props;
    const [notesData, setNotesInput] = useState(selectedNote?.notes ?? '');
    const [ref, assignRef] = useMultiRef(modalRef);
    const [disabledSave,setDisabledSave]=useState(false)
    const removeNote = () => {
        onRemove(selectedNote!);
    }
    const handleNotesChange = (e:any) => {
        const inputValue = e.detail.value;
        // You can set a maximum length if needed
        const maxLength = 3000;
        
        if (inputValue.length <= maxLength) {
          setNotesInput(inputValue);
        }
      };

    const saveNote = async() => {
        setDisabledSave(true)
       await onSave(notesData)
        setDisabledSave(false)

    }
    usePopUpAutoFocus(ref);

    return (
        <DefaultPopUpApp {...popUpProps} hideHeader  title='Notes' modalRef={assignRef}
        customHeader={<IonHeader>
            <IonToolbar>
                <IonButtons className="w-15pr" slot="start">
                    <IonButton color={"blue"} onClick={()=>ref.current?.dismiss()}>Cancel</IonButton>
                </IonButtons>
                <IonTitle>{selectedNote?'Edit':'Add'} Note</IonTitle>
                <IonButtons className="w-15pr" slot="end">
                    <IonButton color={"blue"} disabled={isLoading||notesData.length === 0||disabledSave} strong={true} onClick={saveNote}>Save</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>}
        >
            <DefaultPopUp.Body>
                <div className='bg-color-fff29b h-100 add_notes'>
                    <div className="autoheight_container ion-padding bg-color-fff29b">
                        <div className="form_sec border-0 mb-5 autoheight_container--bottom">
                          <IonTextarea autofocus value={notesData}  className="form-control form-control__notes ion-no-border border-0 mt-0 h-100 textarea__atuogrow " placeholder="Write some notes..." onIonChange={handleNotesChange} ></IonTextarea>

                           {/**  <textarea autoFocus={true} value={notesData} maxLength={3000} className="form-control form-control__notes ion-no-border border-0 textarea__atuogrow " placeholder="Write some notes..." onChange={handleNotesChange} ></textarea>*/}
                            <p>Character Count: {notesData.length}/3000</p>
                        {selectedNote&&<div className='d-flex row justify-content-between align-items-center notes_footer'>
                            <Button kind='shapeless_red' loading={isLoading} className="btn--large" clickConfirmation='Are you sure to delete this note?' onClick={removeNote} >Delete</Button>
                            <div className='mt-10 pr-3'>{formatHarDayDateTime(parseISO(selectedNote?.modified_dt))}</div>
                            </div>}
                        </div>
                    </div>
                </div>
            </DefaultPopUp.Body>
        </DefaultPopUpApp >
    );
}
