
import { apiOperations, forwardOperation } from 'scripts/helpers/apis';
import { IApiScope } from 'types/apis';

export namespace UpdateTagApi {
  export const url = '/tags/:id';
  export const method = 'PUT';
  export const defaultError = "Failed to update tag.";
  export const operations = apiOperations(UpdateTagApi);
  export const scope = new (class UpdateTagApiScope implements IApiScope<Request, Response> {
    request(data: Request) {
      return data;
    }
    response(data: Response) {
      return {
        status: data.status,
        message: data.message
      }
    }
  })();

  export interface Request {
    id: number;
    name: string;
  }

  export interface Response {
    status?: string,
    message?: string

  }
}

export const fetchUpdateTagService = forwardOperation(UpdateTagApi.operations, 'fetch');
export const useFetchUpdateTagService = forwardOperation(UpdateTagApi.operations, 'useFetch');
export const useUpdateTagService = forwardOperation(UpdateTagApi.operations, 'useService');
