import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace AddTagsToContactsApi
{
    export const url = "/contacts/:contact_id/tags";
    export const method = "PUT";
    export const defaultError = "Failed to update tags.";
    export const operations = apiOperations(AddTagsToContactsApi);
    export const scope = new class AddTagsToContactsApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        contact_id:number;
        tags:ITag[];
        
    }

    export interface Response
    {
        status:string,message:string
    }

    export interface ITag
    {
        id?:number;
        name:string;
    }
}

export const fetchAddTagsToContactsService = forwardOperation(AddTagsToContactsApi.operations, 'fetch');
export const useFetchAddTagsToContactsService = forwardOperation(AddTagsToContactsApi.operations, 'useFetch');
export const useAddTagsToContactsService = forwardOperation(AddTagsToContactsApi.operations, 'useService');