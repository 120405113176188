import { INotificationScreenProps } from './NotificationScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { NotificationScreenApp } from './NotificationScreen.app';
import { NotificationScreenDesktop } from './NotificationScreen.desktop';

export const NotificationScreen = (props: INotificationScreenProps): JSX.Element =>
{
    const NotificationScreenPlatform = useResponsiveChoose(NotificationScreenDesktop, NotificationScreenApp);
    return (
        <NotificationScreenPlatform {...props} />
    );
}
