import { IRecomendedItemsCardProps } from './RecomendedItemsCard.def';
import './RecomendedItemsCard.scss';
import { GetRecommendedItemsApi } from 'services/recommendedActionItems/getRecommendedItems';
import { isEmpty, strToBoolean } from 'scripts/helpers/texts';
import { useHistory } from 'react-router';
import { Avatar } from 'views/contacts/Avatar';
import { useDeleteRecommendedItemsService } from 'services/recommendedActionItems/deleteRecommendedItem';
import { useMarkStatusRecommendedItemsService } from 'services/recommendedActionItems/markStatusRecommendedItem';

import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { ShapelessButton } from 'views/forms/buttons/Button';

export const RecommendedItemsCard = (props: IRecomendedItemsCardProps): JSX.Element =>
{    const history = useHistory();
    const { data,loadRecommendedItems,showAddDeal,showAddTask,setCategory,showWriteEmail } = props;
   // const [isCompleted, setCompleted] = useState<boolean>(strToBoolean(data.completed));
    const redirect = (id?: string) => {
        id && history.push(`/contacts/${id}/overview`)
    }
    const [remove, removeAction] = useDeleteRecommendedItemsService()
    const [markStatus, markStatusAction] = useMarkStatusRecommendedItemsService()

    //   let history = useHistory()


    useServiceDidSucceeded(remove, () => {
        displayMessage("The recommended item successfully Deleted.");
        loadRecommendedItems();

    })
    useServiceDidSucceeded(markStatus, () => {
        displayMessage(markStatus.response?.message??"Recommended action item has been updated");
    })
    function removeRecommendedItem(id:string) {
        removeAction.fetch({ id })

    }
 /* const redirect = (id?: string) => {
      id&&  history.push(`/contacts/${id}/overview`)
    }*/
    const handleNavigate=(data:GetRecommendedItemsApi.IResponseList)=>{
        console.log("data",data)
        if(data.type=="Contact"&& (data.sub_type=="home_anniversary"||data.sub_type=="birthday"||data.sub_type=="wedding_anniversary")) {
            data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/detail`,{referrer:'/home'})
        }else if(data.type=="Contact"&&data.sub_type== "stay_in_touch_email"){
            data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/emails`,{referrer:'/home'})
        }else if(data.type=="Contact"&&data.sub_type== "stay_in_touch"){
            data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/overview`,{referrer:'/home'})
        }else if(data.type=="Import"){
            data.type_object_id&&  history.push(`/settings/account`,{referrer:'/home'})
        }else if(data.type=="Contact"&&data.sub_type== "stay_in_touch") {
        data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/overview`);}
        else if(data.type=="Contact"&&data.sub_type== "stay_in_touch") {
            data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/overview`);}
        else if(data.type=="Contact") {
                data.type_object_id&&  history.push(`/contacts/${data.type_object_id}/overview`);}

        if(data.type=="Deal"&&data.sub_type== "closed_next_week"||data.sub_type== "closed_today") {data.payload.contact_id.length>0?  history.push(`/contacts/${data.payload.contact_id[0]}/deals/${data.type_object_id}`):history.push(`/deals/${data.type_object_id}`)}
        else if(data.type=="Deal"){
             history.push(`/contacts/${data.payload.contact_id}/deals`);
        }

        if(data.type=="Onboarding") {
            if(data.sub_type== "onboarding_email"){ showWriteEmail(true);}
            else if(data.sub_type== "onboarding_deal"){ showAddDeal(true);}
            else if(data.sub_type== "onboarding_calendar"){ data.title=='Create an Event on the Calendar page'? setCategory("e"):setCategory("t");showAddTask(true);}
            else if(data.sub_type== "onboarding_contact"){ history.push("/contacts/create");} 
            else if(data.sub_type== "onboarding_note"|| data.sub_type=='onboarding_document') {
                history.push(`/contacts`);
            }
        }
    }
    const handleTaskCompleted=(id:string,status:string)=>{
        markStatusAction.fetch({ id,status }).then(()=>loadRecommendedItems());

    }
    const labelClass=(subType:string)=>{
        switch(subType){
            case "Open House":{ return "openhouse"}
            case "Anniversary":{ return "double__homeanniversary"}
            case "Showings":{ return "showings"}
            case "Stay in touch":{ return "keepintouch"}
            case "Home Anniversary":{ return "double__homeanniversary"}
            case "Wedding Anniversary":{ return "double__homeanniversary"}
            case "Deal":{ return "appointment"}
            case "Task":{ return "event"}
            case "Birthday":{ return "double__followup"}
            case "Follow up":{ return "double__followup"}

        }
        return ""
    }
      if(props.compact){
        return (<div className="col-12 col-md-12">
            <div  className= {strToBoolean(data.completed)? 'cards cards--taskcomp selected': 'cards cards--taskcomp'}>
            <div className="cards--taskcomp__left">
                    <div className="cards--taskcomp__chkbx">
                        <div className="cards--taskcomp__footer__checkbox">
                         <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                             <a data-toggle="tooltip" title="Mark as complete">
                                <input type="checkbox" id={"complete_"+data.id.toString()} name="card_checkbox" className="custom-control-input " checked={strToBoolean(data.completed)} onChange={() => { handleTaskCompleted(data.id,strToBoolean(data.completed)?'0':'1') }} />
                                <label onClick={() => { handleTaskCompleted(data.id,strToBoolean(data.completed)?'0':'1') }} className="custom-control-label" htmlFor={data.id.toString()}> </label></a>
                            </div>
                         </div>
                        </div>
                          <div  onClick={()=>handleNavigate(data)}  className="cards--taskcomp__title">
                                <h4 tabIndex={0}>{data.title}</h4>
                                <div className="cards--taskcomp__title__date">{}</div>
                            </div>					
            </div> 


                        <div className="cards--taskcomp__right">
                            <div className="cards--taskcomp__right__user">
                                <div className="d-flex">
                                <div className="agent_signature agent_signature__mini">
                                    <div onClick={() => redirect(data.type_object_id)} className="agent_signature agent_signature__mini cursor--hand">
                                        {(isEmpty(data.first_name)&&isEmpty(data.last_name)&&isEmpty(data.photo)) ?<></>:
                                        <Avatar id={data.type_object_id} firstName={data.first_name} lastName={data.last_name} photo={data.photo} />}
                                            <div className="agent_signature__text">{data.first_name} {data.last_name}</div>
                                        
                                        </div> 
                                    </div>
                                </div>
                            </div>
                       {!isEmpty(data.sub_type_text)&& <div onClick={()=>handleNavigate(data)} className="cards--taskcomp__right__status">                            
                            {/*data.type_text==="Birthday"||data.type_text==="Home Anniversary"||data.type_text==="Birthday"?
                            <div className="label label--double">
                            <div className="label--double__touch">{data.status_text}</div>
                                <div className={`label--double__${labelClass(data.type_text)}`}>{data.type_text}</div>
                            </div>:*/}
                            <div className={`label label--${labelClass(data.sub_type_text)}`}>{data.sub_type_text}</div>
                            
                            </div> }
                        </div>
                    </div></div>) 
    }
    return (<div className="col-12 col-md-6" > 
       <div className= {strToBoolean(data.completed)? 'cards cards--recommended selected': 'cards cards--recommended'}>
            <div className="cards--recommended__content  cursor--hand">
                <div onClick={()=>handleNavigate(data)}  className="cards--recommended__content_header">    
                 <div className="cards--recommended__content_header_title">
                    <div onClick={() => redirect(data.type_object_id)} className="agent_signature agent_signature__mini cursor--hand">
                         {(isEmpty(data.first_name)&&isEmpty(data.last_name)&&isEmpty(data.photo)) ?<></>:
                         <Avatar id={data.type_object_id} firstName={data.first_name} lastName={data.last_name} photo={data.photo} />}
                            <div className="agent_signature__text">{data.first_name} {data.last_name}</div>
                           
                        </div>                       
                    </div>
                    {!isEmpty(data.sub_type_text)&&<div className="cards--recommended__content_header_labels">                            
                        {/*data.type_text==="Birthday"||data.type_text==="Home Anniversary"||data.type_text==="Birthday"?
                        <div className="label label--double">
                           <div className="label--double__touch">{data.status_text}</div>
                           <div className={`label--double__${labelClass(data.type_text)}`}>{data.type_text}</div>
                        </div>*/}
                        <div className={`label label--${labelClass(data.sub_type_text)}`}>{data.sub_type_text}</div>
                         
                        </div> }
                </div>
                <div onClick={()=>handleNavigate(data)} className="cards--recommended__content_text w-200px">
                {data.title}
                </div>
             </div>
            <div className="cards--tasks__footer">
                <div className="cards--tasks__footer__checkbox">
                    <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0 mt-3">
                    <a data-toggle="tooltip" title="Mark as complete">
                        <input type="checkbox" id={"complete_"+data.id.toString()} name="card_checkbox" className="custom-control-input " checked={strToBoolean(data.completed)} onChange={() => {  handleTaskCompleted(data.id,strToBoolean(data.completed)?'0':'1') }} />
                        <label onClick={() => { handleTaskCompleted(data.id,strToBoolean(data.completed)?'0':'1') }} className="custom-control-label" htmlFor={data.id.toString()}> {markStatus.loading?"":data.completed=='1'? 'Mark as not complete': 'Mark as complete'}</label></a>

                    </div>
                   </div>
                   <div className="cards--recommended__footer__viewmorelink cursor--hand custom_font color_har_blue">
                    <ShapelessButton clickConfirmation="Are you sure? you want to delete this action item?" onClick={() => { removeRecommendedItem(data.id) }}>Delete</ShapelessButton>

                       </div>
                 </div>
        </div>
        </div>
    );
}
