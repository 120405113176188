import { IEmailDetailProps } from './EmailDetail.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { EmailDetailApp } from './EmailDetail.app';
import { EmailDetailDesktop } from './EmailDetail.desktop';

export const EmailDetail = (props: IEmailDetailProps): JSX.Element =>
{
    const EmailDetailPlatform = useResponsiveChoose(EmailDetailDesktop, EmailDetailApp);
    return (
        <EmailDetailPlatform {...props} />
    );
}
