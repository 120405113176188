import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { formatSystemDateShort } from 'scripts/helpers/dates';
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace CustomRepeatOptionsApi
{
    export const url = "/tasks/repeat_options";
    export const method = "GET";
    export const defaultError = "error getting data";
    export const operations = apiOperations(CustomRepeatOptionsApi);
    export const scope = new class CustomRepeatOptionsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input,
                date:formatSystemDateShort(input.date)
    
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
       "date":Date 
    }

    export interface Response
    {
        "month": Array<options>,
        "week": Array<options>,
        "day": Array<options>
    }
}
export interface options{
                "short_value": string,
                "long_value": string
}

export const fetchCustomRepeatOptionsService = forwardOperation(CustomRepeatOptionsApi.operations, 'fetch');
export const useFetchCustomRepeatOptionsService = forwardOperation(CustomRepeatOptionsApi.operations, 'useFetch');
export const useCustomRepeatOptionsService = forwardOperation(CustomRepeatOptionsApi.operations, 'useService');