import { IBasicInformationSectionProps } from "./BasicInformationSection.def";
import { BasicInformationSectionDesktop } from "./BasicInformationSection.desktop";
import { BasicInformationSectionApp } from "./BasicInformationSection.app";
import { useResponsive } from "scripts/hooks/general";

export const BasicInformationSection = (props:IBasicInformationSectionProps): JSX.Element =>
{
    const BasicInformationSectionPlatform = useResponsive(BasicInformationSectionDesktop, BasicInformationSectionApp);
    return <BasicInformationSectionPlatform {...props} />;
}
