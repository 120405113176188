import { IContactInsightSectionProps } from "./ContactInsightSection.def";
import { ContactInsightSectionDesktop } from "./ContactInsightSection.desktop";
import { ContactInsightSectionApp } from "./ContactInsightSection.app";
import { useResponsive } from "scripts/hooks/general";
export const ContactInsightSection = (props: IContactInsightSectionProps): JSX.Element =>
{
  const ContactInsightSectionPlatform = useResponsive(ContactInsightSectionDesktop, ContactInsightSectionApp);
  return (
      <ContactInsightSectionPlatform {...props} />
  );
}