
import { useEffect, useState } from 'react';
import { ImportCsvMappedScreenApp } from './ImportCsvMappedScreen.app';
import { IImportCsvMappedLocation, IImportCsvMappedScreenProps } from './ImportCsvMappedScreen.def';
import { ImportCsvMappedScreenDesktop } from './ImportCsvMappedScreen.desktop';
import './ImportCsvMappedScreen.scss';
import { useResponsiveChoose } from 'scripts/hooks/general';
import { ValueMappingApi } from 'services/contacts/csvmapping/valueMapping';
import { PostCSVValidateApi } from 'services/contacts/csvmapping/postCSVValidate';
import { MapCSVApi } from 'services/contacts/csvmapping/mapCSV';
import { useLocation } from 'react-router';
import { useStatic } from './hooks/hooks';
import { CustomTableGrid } from './static/CustomTableGrid';
import { isProduction } from 'scripts/application/settings';
import { SearchDropDown } from './static/SearchDropDown';

export const ImportCsvMappedScreen = (props: IImportCsvMappedScreenProps): JSX.Element => {
    const location = useLocation<IImportCsvMappedLocation>();
    const { isStatic, isDropDownSearch, isTableGrid } = useStatic();
    const [importSetup, setImportSetup] = useState<MapCSVApi.Request>({
        file_key: !isStatic && location.state.file_key ? location.state.file_key : '',
        date_format: 'mm/dd/yyyy',
        prevent_update: '1',
        has_header: '1',
        version: '1',
        queue: !isProduction ? '1' : '0'
    });
    const [field_mappings, setFieldMappings] = useState<ValueMappingApi.IFieldMappings[]>();
    const [activeStep, setActiveStep] = useState<number>(1);
    const [import_id, setImportId] = useState<string>('');
    const [mapping, setMapping] = useState<ValueMappingApi.IMappings>();
    const [selectedMapping, setSelectedMapping] = useState<PostCSVValidateApi.ISelectedMappings>();
    const onNext = (stepId: number, valueMapping?: ValueMappingApi.IMappings, selectedMapping?: PostCSVValidateApi.ISelectedMappings) => {
        setActiveStep(stepId);
        if (valueMapping) {
            setMapping(valueMapping)
        }
        if (selectedMapping) {
            setSelectedMapping(selectedMapping)
        }
    }

    useEffect(() => {
        if (!isStatic && location.state.file_key) {
            setImportSetup({
                ...importSetup,
                ['file_key']: location.state.file_key
            });
            setFieldMappings(undefined);
        }
    }, [location.state]);



    const ImportCsvMappedScreenPlatform = useResponsiveChoose(ImportCsvMappedScreenDesktop, ImportCsvMappedScreenApp);


    if (isStatic && isDropDownSearch && !isProduction) {
        return <SearchDropDown />;
    } else if (isStatic && isTableGrid && !isProduction) {
        return <CustomTableGrid />;
    } else {
        return <ImportCsvMappedScreenPlatform
            selectedMapping={selectedMapping ?? undefined}
            mapping={mapping}
            import_id={import_id}
            step_id={activeStep}
            importSetup={importSetup}
            field_mappings={field_mappings}
            setSelectedMapping={setSelectedMapping}
            setFieldMappings={setFieldMappings}
            setImportSetup={setImportSetup}
            setStepId={setActiveStep}
            setImportId={setImportId}
            onNext={onNext}
            {...props} />;
    }
}
