
import { SendEmailsApi } from 'services/emails/sendEmails';
import { IAttachmentFiles } from '../NewEmail.def';
import { IonIcon } from '@ionic/react';
import { documentOutline } from 'ionicons/icons';


export const AttachmentFiles = (props: IAttachmentFiles): JSX.Element => {
    const { attachments, removeAttach } = props
    return (
        <div className="border-bottom">
            {attachments && attachments.length > 0 &&
                <div className="ml-md-5 ml-4 mr-md-5 mr-4 pt-3 pb-3">
                    <div className="font_size--medium font_weight--semi_bold pb-2">Attachments:</div>
                    <div className="d-flex pb-3 overflow-auto">
                        {attachments.map((attach: SendEmailsApi.IEmailAttachment, key: number) =>
                            <div key={key} className="propertypill">
                                <div className="propertypill--img align-self-center"  ><IonIcon className=' mt-2 ml-1' size='large' icon={documentOutline}></IonIcon></div>
                                <div className="propertypill--text text-overflow-clip">
                                    {(attach.content_type && attach.file_name && attach.file_name.length >= 18) ?
                                        attach.file_name.substring(0, 11) + '...' + attach.file_name.substring(attach.file_name.length - 5)
                                        :
                                        attach.file_name
                                    }
                                </div>
                                <a className="propertypill--clossbtn cursor--hand" onClick={() => attach.id ? removeAttach?.(attach.id, "id") : attach.key ? removeAttach?.(attach.key, "key") : ''}><img src="https://content.harstatic.com/media/icons/close_black.svg" /></a>
                            </div>
                        )}

                    </div>
                </div>
            }
        </div>
    );



}