import { DateTimePicker, PickersDay } from '@mui/x-date-pickers';
import { IMuiDateTimePickerProps } from './MuiDateTimePicker.def';
import './MuiDateTimePicker.scss';
import { styled } from '@mui/material/styles';
import { addYears, subYears } from 'date-fns';


export const MuiDateTimePicker = (props: IMuiDateTimePickerProps): JSX.Element => {
    const { placeholder, value, className, label, maxDate, minDate, error, disabled, onChange } = props
    const maxDefaultDate = addYears(new Date(), 10)
    const minDefaultDate = subYears(new Date(), 2)

    const Styled = styled(PickersDay)(() => ({
        fontSize: '14px',
    }));
    return (
        <div className={`datewraper ${className && className}`}>
            {label &&
                <label className='form-label'> {label}</label>
            }
            <DateTimePicker label={placeholder ? placeholder : ''}
                formatDensity='spacious'
                defaultValue={value}
                slots={{
                    day: Styled,
                }}
                timeSteps={{ minutes: 1 }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        placeholder: label ? label : '',
                        sx: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: error ? '#dc3545' : '#AFB8D9',
                                    borderWidth: '1px',
                                    padding: '5px auto'
                                },

                                '&.Mui-focused fieldset': {
                                    borderColor: '#AFB8D9',
                                    borderWidth: '1px',
                                },
                            },
                            '& .MuiInputBase-input': {
                                padding: `12.5px 14px`,
                            }
                        }
                    },
                    calendarHeader: {
                        sx: {
                            '.MuiPickersCalendarHeader-label': {
                                fontSize: '18px',
                            }

                        },
                    },

                }}
                disabled={disabled}
                onChange={(date: any) => {
                    onChange?.(date)
                }}
                maxDateTime={maxDate ? maxDate : maxDefaultDate}
                minDateTime={minDate ? minDate : minDefaultDate}
            />
            {error &&
                <div className='invalid-feedback d-flex'>{error}</div>
            }
        </div>
    );
}
