import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace LoginApi
{
    export const url = "/user/login";
    export const method = "POST";
    export const defaultError = "Login failed";
    export const operations = apiOperations(LoginApi);
    export const authenticate = false;
    export const scope = new class LoginApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input,
                remember_me: (input.remember_me ?? true)? '1': '0'
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        username:string;
        password:string;
        remember_me?:boolean;
    }

    export interface Response
    {
        har_key:string;
    }
}

export const fetchLoginService = forwardOperation(LoginApi.operations, 'fetch');
export const useFetchLoginService = forwardOperation(LoginApi.operations, 'useFetch');
export const useLoginService = forwardOperation(LoginApi.operations, 'useService');