import { IAssignScoreProps } from './AssignScore.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AssignScoreApp } from './AssignScore.app';
import { AssignScoreDesktop } from './AssignScore.desktop';

export const AssignScore = (props: IAssignScoreProps): JSX.Element =>
{
    const AssignScorePlatform = useResponsiveChoose(AssignScoreDesktop, AssignScoreApp);
    return (
        <AssignScorePlatform {...props} />
    );
}
