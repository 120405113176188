
export const Loading = () =>
(
   <div className="cards p-3 max-w-600">
        <div className="loading_text mb-5 max-w-122"></div>
        <div className="row small-gutters mt-3 mb-3">
            <div className="col"><div className="loading_text"></div></div>
            <div className="col"><div className="loading_text"></div></div>
            <div className="col"><div className="loading_text"></div></div>
            <div className="col"><div className="loading_text"></div></div>
            <div className="col"><div className="loading_text"></div></div>
            <div className="col"><div className="loading_text"></div></div>
        </div>
        <div className="loading__text_small mt-2 mb-2 ml-auto max-w-60pr"></div>
        <div className="loading__text_small mt-3 mb-3 ml-auto"></div>
        <div className="loading__text_small mt-3 mb-3 ml-auto"></div>
        <div className="loading__text_small mt-3 mb-3 ml-auto"></div>
        <div className="loading__text_small mt-3 mb-3 ml-auto"></div>
        <div className="loading__text_small mt-2 mb-2 max-w-30pr"></div>
    </div>
);

