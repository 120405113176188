import { IContactSectionAppProps,IEmailDataApp,IPhoneDataApp,ILinkDataApp } from './ContactSection.def';
import './ContactSection.scss';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox';
import { IonToggle} from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useFetchLookupsService } from 'services/general/lookups';
import { Button } from 'views/forms/buttons/Button';
import { useEffect, useRef } from 'react';
import { switchCase } from 'scripts/helpers/general';
import { cloneDeep, upperFirst } from 'lodash-es';
//import { fetchEmailExistenceService } from 'services/contacts/emailExistence';
import { hasPhoneExtension } from 'services/contacts/createContact';
import { allEmptyOrNone } from 'scripts/helpers/texts';

const PREFERRED_FORCE_FIRST = true;

            
export const ContactSection = (props:IContactSectionAppProps): JSX.Element =>
  {
    const { items,handleAddNew,setContact, errorRef } = props;
    const isError = errorRef?.error;
    const initialItems = useRef(items);

    // HACK: Assign the first time the initial items coming from API
    if('_placeholder' in initialItems.current && !('_placeholder' in items)) {
      initialItems.current = cloneDeep(items);
    }


    const isEmailList = !!items.emails?.length;
    const isPhonesList = !!items.phones?.length;
    const isLinksList = !!items.links?.length;
    const [, _notifyChange] = useValidateConsumer(props.validate, validate);
    const [lookupData] = useFetchLookupsService(['phone_type']);

      const fieldCheck =(item: any, isEmail:boolean =false ,isPhone:boolean=false):string  => {
          const fieldEmptyMsg='This field is required'
          const incorrectEmailMsg='This field should contain min 8 max 55 characters, qw@qw.co'   
          const incorrectPhoneMsg='This field should contain min 3 max 15 digits'

        const value = item?.value?.replaceAll("  ","")
        if (!value || value === '') {
          return fieldEmptyMsg;
        }

        if (value.length < 3) {
          return isPhone?incorrectPhoneMsg:incorrectEmailMsg;
        }
        
          const regexEmail=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(isEmail&&!regexEmail.test(value)){
            return incorrectEmailMsg
        }
        const phoneNumberLength = value.replace(/[^0-9]/g,"").length

        if (isPhone && (value.length>25|| phoneNumberLength>15  )) {
          return incorrectPhoneMsg;
        }
      return '';
    };

  /*function checkEmailAvailability(index:number): Promise<string>
  {
    const data = items.emails[index];
    if(!data) { return Promise.resolve(''); }
    if(isEmpty(data.value)) { return Promise.resolve(''); }

    // Checking if email is already in the initial items
    const existence = initialItems.current.emails.some(x => !!x.id && x.value === data.value);
    if(existence) { return Promise.resolve(''); }

    const msg = 'This email already exists in the CRM, please type another email';
    return fetchEmailExistenceService({ email:data.value }).then(email => email?.isDuplicated? msg: '');
  }*/

  function validate() { return validateForm(false); }

  async function validateForm(globalRequired:boolean)
  {
    const checkRequired = globalRequired;
    globalRequired = false;

    // HACK: This flag indicates form doesn't have required fields
    // Validating only required fields
    if(checkRequired)
    {
      const preferredEmail = items.emails.find(x => x.preferred);
      const preferredPhone = items.phones.find(x => x.preferred);
      const hasValues = [preferredEmail, preferredPhone].some(x => x?.value?.trim());
      if(!hasValues)
      {
        const error = 'The field is required';
        setContact(current => {
          let email = current.emails.find(x => x.preferred);
          if(!email) { current.emails.unshift(email = { preferred:true, value:'', error:'', importEmail:false }); }
          email.error = error;
    
          let phone = current.phones.find(x => x.preferred);
          if(!phone) { current.phones.unshift(phone = { preferred:true, value:'', error:'', phone_type:'' }); }
          phone.error = error;
          phone.errorField = 'phone';
    
          return { ...current };
        });

        return false;
      }
    }


    // var isValidate1,isValidate2,isValidate3=false
    // const form:IContactDataApp={emails:[],phones:[],links:[]}; 
       const emailsErrorsPromise = items.emails.map(async (item:IEmailDataApp): Promise<string> => {

        const isFilled = !!item.value.trim();
        const isRequiredField = globalRequired && item.preferred;
        let error = isFilled || isRequiredField? fieldCheck(item,true): '';

        // Special case: If preferred email has id, then it must not be empty
        /*if(!error && item.preferred && item.id && !isFilled) {
          error = "You can't delete preferred email";
          if(!PREFERRED_FORCE_FIRST) { error += ", you can switch another email to Preferred and then delete this one"; }
        }*/

        // Validating email availability
        //if(!globalRequired && !error) { error = await checkEmailAvailability(index); }

        return error;

        // Setting error
        // setContact(prev => {
        //   const email = prev.emails[index];
        //   if(email) { email.error = error; }
        //   return { ...prev };
        // });

        // form.emails.push({
        //       value: item.value,
        //       error,
        //       preferred:item.preferred,
        //       importEmail:item.importEmail
        //     });
            
        });
        const phoneErrors = items.phones.map((item:IPhoneDataApp): Pick<IPhoneDataApp, 'error' | 'errorField'> =>{
         
          /*const error = (ind===0)?
            (items.phones[0]?.value.length??0)>1||item.phone_type!=="N"? fieldCheck(item,false,true):"":
            fieldCheck(item,false,true);*/

            // Validating Phone
            item.phone_type ||= 'N';
            const isFilled = !!item.value.trim() //|| item.phone_type !== 'N' || item.phone_extension !== undefined;
            const isRequiredField = globalRequired && item.preferred;
            const phoneError = isFilled || isRequiredField? fieldCheck(item,false,true): '';
            if(phoneError) { return { error:phoneError, errorField:'phone' }; }

            // Validating Extension
            if(item.phone_extension && hasPhoneExtension(item.phone_type) && item.phone_extension > 999999) {
              return { error:'This field should contain max 6 digits', errorField:'ext' };
            }

            return { error:'' };
        
        // form.phones.push({
        //       value: item.value,
        //       error,
        //       preferred:item.preferred,
        //       phone_type: item.phone_type
        //     });
      });


      const linkErrors = items.links.map((_item:ILinkDataApp): string => {
       // const isFilled = !!item.value.trim() || !!item.link_type.trim();
        //return isFilled? fieldCheck(item): ''
        return '';
          // form.links.push({
          //   value: item.value,
          //   error,
          //   link_type: item.link_type??""
          // });
      });

      // Setting errors
      const emailErrors = await Promise.all(emailsErrorsPromise);
      setContact(prev => {
        prev.emails.forEach((email, index) => { email.error = emailErrors[index] ?? ''; });
        prev.phones.forEach((phone, index) => {
          phone.error = phoneErrors[index]?.error ?? '';
          phone.errorField = phoneErrors[index]?.errorField;
        });
        prev.links.forEach((link, index) => { link.error = linkErrors[index] ?? ''; });
        return { ...prev };
      });

      const isEmailsValid = emailErrors.every(x => !x);
      const isPhonesValid = phoneErrors.every(x => !x.error);
      const isLinksValid = linkErrors.every(x => !x);
      return isEmailsValid && isPhonesValid && isLinksValid;

    //   isValidate1= Object.entries(form.emails).every(([, value]) => value.error==='');
    //   isValidate2= Object.entries(form.phones).every(([, value]) => value.error==='');
    //   isValidate3= Object.entries(form.links).every(([, value]) => value.error==='');
    //   setContact(form);
       
    // return isValidate1&&isValidate2&&isValidate3
  }

  useEffect(() => { isError && validateForm(true); }, [errorRef]);

    return (<>
       
         <div className="card p-0 mb-5">
            <h3 className="sec_title font-weight_semi-bold ml-4 mt-4 pt-2">Email</h3>
            <div className="secpadding_left secpadding_right">
            {isEmailList && items?.emails.map((item,key)=>
            <FormView preferred isImportEmail key={key} index={key} itemsLength={items.emails.length} selectedItem={item} name="emails" placeHolder="Emails"  {...props} />)}
            {items.emails.length<10&&<div id="email" className="addable_item pb-3 pt-3 border-top" onClick={()=>handleAddNew("emails")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Email</div>
            </div>} 
         </div></div>
         <div className="card p-0 mb-5">
          <h3 className="sec_title font-weight_semi-bold ml-4 mt-4 pt-2">Phone</h3>
           <div className="secpadding_left secpadding_right">
            {isPhonesList && items?.phones.map((item,key)=>
            <FormView lookupData={lookupData.response?.phone_type.items} preferred key={key} index={key} itemsLength={items.phones.length} selectedItem={item} name="phones" placeHolder="Phones" {...props} />)}
            {items.phones.length<10&&<div className="addable_item pb-3 pt-3 border-top" onClick={()=>handleAddNew("phones")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Phone</div>
            </div>} 
         </div></div>

         <div className="card p-0 mb-5">
             <h3 className="sec_title font-weight_semi-bold ml-4 mt-4 pt-2">Links</h3>
            <div className="secpadding_left secpadding_right"> 
                     
            {isLinksList && items?.links.map((item,key)=>
             <FormView key={key} index={key} itemsLength={items.links.length}  selectedItem={item} name="links" placeHolder="http://www…"  {...props} />)}
            {items.links.length<10&&<div className="addable_item pb-3 pt-3 border-top" onClick={()=>handleAddNew("links")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Link</div>
            </div>} 
         </div></div>
     </>)
}

const FormView = (props: IContactSectionAppProps.FormViewProps): JSX.Element => {

 // const { id } = useParams<{ id: string }>();
const {handleRemoveContact,index,handleChangeField,name,placeHolder,preferred,/*isImportEmail,*/itemsLength,lookupData}=props
useEffect(()=>{
  handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,String(props.selectedItem?.phone_type),props.selectedItem?.phone_extension,props.selectedItem?.link_type)
 },[lookupData]);

 const showPhoneExt = hasPhoneExtension(props.selectedItem?.phone_type);

 const isPreferredDisabled = () => {
    if(PREFERRED_FORCE_FIRST) { return false; }
    if(itemsLength<2) { return true; }

    const selectedItem = props.selectedItem;
    if(!selectedItem?.value?.trim() || !!selectedItem.error.trim()) { return true; }
    if(!!selectedItem?.preferred && name === 'emails') { return true; }
    return false;
  };

  const isPreferredChecked = () => {
    return itemsLength < 2 && !PREFERRED_FORCE_FIRST? true: props.selectedItem?.preferred;
  };

    // HACK: errorField based on the name
    const mainErrorField = name.slice(0, -1);

    const id = `txt${placeHolder}`;
    return (
       <div className="">
        <label htmlFor={id} className='mt-4'>{switchCase(preferred, true, props.selectedItem?.preferred?`Preferred ${name.replaceAll("s","")}` : `Other ${name.replaceAll("s","")}`, upperFirst(name.replaceAll("s","")))}</label>
                <div className="row small-gutters">
                    <div className="col">

                   {name==="links"&&
                    <Textbox value={props.selectedItem?.link_type} placeholder='e.g. Facebook profile' id='link_type' className="form-control mb-4" name={name}  maxlength={40} error={props.selectedItem?.error} onIonChange={(e)=> handleChangeField(index,e.target.name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,props.selectedItem?.phone_extension,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}/>}
                  
                    <Textbox
                      type={"text"}
                      value={props.selectedItem?.value}
                      name={name}
                      placeholder={placeHolder}
                      maxlength={switchCase(name, 'links', 200, 'emails', 55, 25)}
                      id={id}
                      aria-describedby={placeHolder}
                      error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === mainErrorField: props.selectedItem?.error}
                      max={name==="emails"?16:25}
                      onIonChange={(e) => handleChangeField(index,e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''),props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,props.selectedItem?.phone_extension,props.selectedItem?.link_type)}>
                    </Textbox>
                  {/* <IonIcon></IonIcon> */}
                  </div>
                  {name==="phones" && <>
                    {showPhoneExt && <div className="col-3">
                      <Textbox
                        type="number"
                        className="form-control"
                        aria-describedby="Ext"
                        placeholder="Ext"
                        value={props.selectedItem?.phone_extension}
                        maxlength={6}
                        min={0}
                        max={999999}
                        error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === 'ext': undefined}
                        onIonChange={(e) => handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,e.target.value ?? '')}
                      />
                    </div>}
                    <div className="col-4">
                      <select className="custom-select custom-select_large" value={props.selectedItem?.phone_type} onChange={(e) => handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,String(e.target.value?.replace(/\s{2,}/g, ' ') ?? ''),props.selectedItem?.phone_extension)} defaultValue="None">
                                {lookupData?.map((x, index) => (
                                    <option key={index} value={x.short_value}>{x.long_value}</option>
                                ))}
                            </select>
              </div>
                  </>}
              </div>
              {props.selectedItem?.errorField && props.selectedItem?.error && <div className="row">
                <div className="col">
                  <div className='invalid-feedback d-block'>{props.selectedItem.error}</div>
                </div>
              </div>}

              <div className="row">
              {preferred && (!PREFERRED_FORCE_FIRST || (index === 0 && name !== 'emails')) && <div className="col">
                      <div className="addable_item pl-1 pb-3 pt-0 mb-3 mt-2">                              
                        <IonToggle disabled={isPreferredDisabled()} checked={isPreferredChecked()} onIonChange={()=>handleChangeField(index,name,props.selectedItem?.value??"",!props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,props.selectedItem?.phone_extension,props.selectedItem?.link_type)}></IonToggle>
                        <div className="align-self-center pr-3 pl-3">{props.selectedItem?.preferred?`Preferred ${name.replaceAll("s","")}`:`Other ${name.replaceAll("s","")}`}</div>
                     </div>
                   </div>}
                     
                 {(name=== "links" || PREFERRED_FORCE_FIRST) && <div className="col"></div>}
                          {/**  <div role="checkbox" aria-checked='true' className="custom-control custom-checkbox custom-control-inline">
                                <input disabled={itemsLength<2?true:false} checked={itemsLength<2?true:props.selectedItem?.preferred} type="checkbox" className="custom-control-input" onChange={()=>handleChangeField(index,name,props.selectedItem?.value??"",!props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.id?.toString())} />
                                <label className="custom-control-label" >Make it preferred</label>
                            </div>*/}
                   
                    <div className="col-auto">
                    {/*((name === 'emails'&& !props.selectedItem?.preferred) || name === 'phones' || name === 'links')&& <div className="address__row__links text-right" style={{textAlign:"end"}}>*/}
                      <div className="address__row__links text-right" style={{textAlign:"end"}}>
                        <Button kind='shapeless_red' size='medium' className='shapeless_red' onClick={()=>handleRemoveContact(name,index)} clickConfirmation={!allEmptyOrNone(props.selectedItem?.phone_type??"",props.selectedItem?.value??"",props.selectedItem?.link_type??"")? `Are you sure to delete ${name.replaceAll("s","")}?`:undefined}>Delete</Button>

                           {/**<img onClick={()=>handleRemoveContact(name,index)}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
                           {name==="links"&& <><div className="delete_item_value">Link. {index+1} </div>
                            <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" /></>}*/}
                       </div></div></div>
                  
                    {/*isImportEmail&&<div className="col">
                    <div className="addable_item pb-3 pt-1" >                              
                        <div className="pl-4 align-self-center">Import emails</div>
                      <IonToggle  checked={props.selectedItem?.importEmail} onIonChange={()=>handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,!props.selectedItem?.importEmail)} ></IonToggle>
                    </div>
</div>*/}
        </div>
      
    )
}