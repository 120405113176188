import { ITextboxProps } from './Textbox.def';
import './Textbox.scss';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import { isString } from 'lodash-es';
import { useState } from 'react';
import { joinWithSpace } from 'scripts/helpers/texts';

export function Textbox(props: ITextboxProps): JSX.Element
{
    const { containerClassName, containerId, containerStyle, error, label, className:userClassName, value:userValue, innerRef, ionItemClassName, ...ionicProps } = props;
    const errorClassName = (isString(props.error) && props.error.trim()) || props.error ? 'is-invalid' : undefined;
    const className = joinWithSpace(errorClassName, userClassName);
    const value = userValue !== undefined? userValue: null;

    return (
        <div id={containerId} className={containerClassName} >
             {props.label && <IonLabel>{props.label}</IonLabel>}
           <IonItem className={ionItemClassName}> <IonInput {...ionicProps} className={className} value={value} ref={innerRef} ></IonInput>
         </IonItem>
         {isString(props.error) && props.error.trim() !== ''?
            <div className=' color_sold ml-3'>{props.error}</div>:null}
        </div>
    );
}

export namespace Textbox
{
    export const useRef = () => useState<typeof IonInput>();
}