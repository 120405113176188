import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTimeout } from "scripts/hooks/timers";
import { ProviderSync, eProviderStatus } from "../AccountConnection.def";
import { IOnboardingStageHook } from 'scripts/hooks/onboarding';
import { GetBasicInfoApi } from 'services/user/getBasicInfo';
import { authorize, reauthenticate } from 'scripts/http/harconnectService';
import { origin } from 'scripts/context';
import { join } from 'scripts/helpers/texts';

type ExpectedStatusFunction = (user:GetBasicInfoApi.Response) => boolean;

const providerStatusMap:Record<eProviderStatus, ExpectedStatusFunction> =
{
    [eProviderStatus.Connection]: (x) => x.provider_status?.trim()?.toLowerCase() === 'ok',
    [eProviderStatus.Disconnection]: (x) => !x.provider_emails_all.some(x => x.sync_mode),
};

export const useProviderSync = (onboarding:IOnboardingStageHook): ProviderSync.Result =>
{
    const ATTEMPTS = 5;
    const activationRef = useRef<ProviderSync.IActivation>();
    const attempts = useRef(ATTEMPTS);
    const activation = activationRef.current;
    const refresh = useTimeout(() => refreshOnboarding(onboarding), 2000);
    const [increment, update] = useState(0);

    // Activate Function
    const activate = (status:eProviderStatus, onFail:() => void): boolean =>
    {
        if(!activationRef.current && onboarding.user)
        {
            // Comparing expected status
            const matches = providerStatusMap[status](onboarding.user);
            if(matches) { return false; }

            // Schedule new call
            refresh.schedule();
            setTimeout(() => update(increment +1), 0);
        }

        activationRef.current = { status, onFail };
        return true;
    };

    // In case onboarding has been loaded
    const active = activation !== undefined;
    const loading = active && attempts.current > 0;
    if(active && onboarding.user)
    {
        // Process End: Either finished with status matching or not
        if(!loading || providerStatusMap[activation.status](onboarding.user))
        {
            activationRef.current = undefined;
            refresh.clear();

            // Ended without status matching
            if(!loading) {
                attempts.current = ATTEMPTS;
                activation.onFail();
            }

            return { active:false, loading:false, activate };
        }

        // In case system needs to wait to load provider
        if(!refresh.isScheduled)
        {
            attempts.current--;
            refresh.schedule();
        }
    }

    return { active, loading, activate };
}

export const useConnectedParameter = () =>
{
    const history = useHistory();
    const CONNECTED_PARAMETER = 'connected';
    const params = new URLSearchParams(history.location.search);
    const value = params.get(CONNECTED_PARAMETER);
    const authorized = value === '1';
    const reauthorized = value === '2';
    const isConnected = authorized || reauthorized;

    // Cleaning URL
    useEffect(() => {
        if(!isConnected) { return; }

        params.delete(CONNECTED_PARAMETER);
        console.log(params.toString());
        history.replace({ ...history.location, search:params.toString() });
    }, [isConnected]);

    return { isConnected, authorized, reauthorized } as const;
}

export const useProviderAuthorization = (memberNumber:string, onboarding:boolean, nextUrl?:string) =>
{
    if(nextUrl) { nextUrl = encodeURIComponent(nextUrl.trim()); }
    const basePath = onboarding? 'onboarding': 'settings';
    const baseUrl = join(`${origin}/${basePath}/account?`, nextUrl && `nexturl=${nextUrl}&`);
    const authorizeUrl = baseUrl + 'connected=1';
    const reauthorizeUrl = baseUrl + 'connected=2';
    const authorizeService = authorize(memberNumber, { redirect_uri:authorizeUrl });
    const reauthenticateService = reauthenticate(memberNumber, { redirect_uri:reauthorizeUrl });
    return { authorize:authorizeService, reauthenticate:reauthenticateService } as const;
}

function refreshOnboarding(onboarding:IOnboardingStageHook): void
{
    if(!onboarding.loading) { onboarding.refresh(); }
}