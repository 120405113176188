import { DefaultPopUp } from 'views/popups/DefaultPopUp'
import { IChooseRecurringOptionsProps } from '../../AddTaskPopUp.def';
import { Button, ProminentButton } from 'views/forms/buttons/Button';
import { useState } from 'react';

export function ChooseRecurringOption(props:IChooseRecurringOptionsProps) {
    const {selectedRecurringOption,setRecurringOption,showRecurringPopup,setShowRecurringPopup,handleTaskUpdate}=props;
    const [isDisabled,setIsDisabled]=useState(false);
  return (
  <DefaultPopUp title="Edit Recurring Task" className="custom--modal initmodal" isOpen={showRecurringPopup} toggleShow={() => setShowRecurringPopup(false)} onDidDismiss={() => setShowRecurringPopup(false)}>
    <DefaultPopUp.Body>
       <div className="bg-white d-flex justify-content-center">
        <div className="align-self-center pt-4" >
          <div role="radio" className="custom-control custom-radio custom-control-inline d-flex">
              <input type="radio" id="radio_button_medium_1" checked={selectedRecurringOption=="0"} value={"0"} onChange={()=>setRecurringOption("0")} name="radio_button_medium" className="custom-control-input"/>
              <label className="custom-control-label" htmlFor="radio_button_medium_1">Edit this task</label>
          </div>
          <div role="radio2" className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="radio_button_medium_3" checked={selectedRecurringOption=="2"} value={"2"} onChange={()=>setRecurringOption("2")} name="radio_button_medium" className="custom-control-input"/>
              <label className="custom-control-label" htmlFor="radio_button_medium_3">Edit all tasks</label>
          </div>
       </div>
     </div>
    </DefaultPopUp.Body>
    <DefaultPopUp.ActionBar>
        <div className="ml-4 mr-4">
            <div className="row pt-2 mb-2">
                <div className="col-auto algin-self-center">
                <Button kind="shapeless" className="btn--medium" onClick={()=>setShowRecurringPopup(false)}>Cancel</Button>
                </div>
                <div className="col-auto ml-auto">
                      <ProminentButton disabled={isDisabled} loading={isDisabled} onClick={()=>{setIsDisabled(true);handleTaskUpdate()}}>Submit</ProminentButton>
                </div>
            </div>
        </div>
    </DefaultPopUp.ActionBar>                           
  </DefaultPopUp>
  )
}
