import { fetchObjectOwnerService, ObjectOwnerApi } from 'services/typeaheads/objectOwner';
import { Typeahead } from 'views/forms/typeaheads/Typeahead';
import { IOwnerSelection, IOwnersTextboxProps } from './OwnersTextbox.def';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import './OwnersTextbox.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Tag } from 'scripts/ui/tag';


export const OwnersTextbox = (props: IOwnersTextboxProps): JSX.Element => {
    const { initialItems, maxItems, onItemChange, onRemoveItem } = props


    const search = (async (value: string) => {
        const response = await fetchObjectOwnerService({ input: value });
        return response.data
    })

    const mapResult = (item: ObjectOwnerApi.IObjectList): IDropDownOption => {
        return { id: Number(item.member_number), text: item.first_name + " " + item.last_name, photo_url: item.photo_url }
    }
    return (
        <div>
            <Typeahead.Multi maxValues={maxItems} placeholder='Owners' search={search} mapResult={mapResult} initialValue={initialItems} onChange={onItemChange} hideSelectedItem />
            <Owners selection={initialItems} onRemoveItem={onRemoveItem} />
        </div>
    );
}


const Owners = (props: IOwnerSelection) => {


    return (
        <>
            {props.selection && props.selection.length > 0 &&
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={12}
                    slidesPerGroup={1}
                    slidesOffsetAfter={50}
                    loop={false}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation]}
                    className="owners_desktop"
                >
                    {props.selection.map((item, ind) =>
                        <SwiperSlide key={ind}>
                            <div className='pt-3'>
                                <div className="agent_signature agent_signature__inline">
                                    <Tag.Div className="agent_signature__photo owner_textbox_typeahead" cssVars={{ ownerTextBox: 'url(' + item.photo_url + ')' }}>
                                        <div onClick={() => props.onRemoveItem?.(item.agent_key)} className="agent_signature__photo_cross"><img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" /></div>
                                    </Tag.Div>
                                    <div className="agent_signature__text text-center">{`${item.first_name} ${item.last_name}`}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            }

        </>)
}
