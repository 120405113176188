import { IContactsEditTagsPopUpProps, IResponseData, IResponseStatusInfo } from './ContactsEditTagsPopUp.def';
import './ContactsEditTagsPopUp.scss';
import { useEffect, useRef, useState } from 'react';
import { fetchEditContactsTagsService } from 'services/contacts/editContactsTags';
//import { ErrorDialog } from 'views/dialogs/ErrorDialog';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { useGetTagsService } from 'services/contacts/tags/getTags';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonModal, IonNote, IonTitle, IonToolbar } from '@ionic/react';
import { useCreateTagService } from 'services/contacts/tags/createTag';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';



export const ContactsEditTagsPopUpApp = (props: IContactsEditTagsPopUpProps): JSX.Element => {
  const { selectedContacts, reload } = props;

  const [showPopup, setShowPopUp] = useState(false)
  const [searchTagsData, setSearchTagsData] = useState<Array<IResponseData>>()
  const [newTagError, setNewTagError] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [saveData, setSaveData] = useState<IResponseStatusInfo>({ status: '', message: "" })
  const [saveNewTagData, saveNewTagActions] = useCreateTagService();
  const [getTags, getTagsAction] = useGetTagsService()
  const [tags, setTags] = useState<Array<any>>()


  const addNewTag = useRef<HTMLIonInputElement>(null)

  const doSelectTags = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedTags([...selectedTags, id])
    } else {
      let list = selectedTags?.filter((tag) => id !== tag)
      setSelectedTags(list ? list : [])
    }


  }
  const fetchData = () => {
    getTagsAction.fetch();
  };


  useServiceDidSucceeded(getTags, (response) => {
    if (response) {
      setSearchTagsData(response)
    }
  })


  const handleNew = async () => {
    if (addNewTag?.current?.value && addNewTag?.current?.value?.toString().length <= 2) {
      setNewTagError("Tag value should be at least 3 characters.")
      return
    }
    if (typeof addNewTag.current?.value === "string") {
      try {
        await saveNewTagActions.fetch({ name: addNewTag?.current?.value });
      } catch (error) { }
    }

  }


  const handleUpdate = async () => {

    if (addNewTag?.current?.value !== "") {
      handleNew()
      return
    }
    let requestTag: any = []
    selectedTags.map((tag) => requestTag.push({ id: tag, value: 1 }))
    tags?.filter((tag) => tag.status !== 1).filter((tag) => !selectedTags?.includes(tag.id)).map((tag) => requestTag.push({ id: tag.id, value: 0 }))
    let contactIds: any = [];
    selectedContacts.map(({ id }) => contactIds.push(id));
    try {
      const data: any = await fetchEditContactsTagsService({ contact_ids: contactIds, tags: requestTag });
      if (data.status === 'ok') {
        displayMessage('Tags have been assigned')
      }
      setSaveData(data)
      if (reload) {
        reload()
      }
      setShowPopUp(false)


    } catch (error: any) {
      setSaveData({ status: 'error', message: "Tags are required" })
    }

    setTimeout(() => setSaveData({ status: '', message: "" }), 5000)
  }


  const tagStatus = (id: number): number => {
    let arr: any[] = []
    selectedContacts.map((contact: any) => {
      const filtered = contact.tags.filter((tag: any) => { return tag.id === id })
      if (filtered.length > 0) {
        arr.push({ [contact.id]: id })
      }
    })
    if (arr.length === 0) {
      return 0;
    } else if (selectedContacts.length === arr.length) {
      return 2
    } else {
      return 1
    }
  }

  /*
  const isCheckable = (id: number): boolean => {
    if (selectedTags.find((item) => item === id)) {
      return true
    } else {
      return false
    }
  }*/


  function setUpTags() {
    let arr: any = []
    searchTagsData?.map((tags) => {
      arr.push({ id: tags.id, name: tags.name, contacts_count: tags.contacts_count, status: tagStatus(tags.id) })

    })
    arr.sort(function (a: any, b: any) {
      return b.status - a.status
    })
    setTags(arr)
  }

  function selection() {
    let arr: any = []
    tags?.map((tags) => {
      if (tagStatus(tags.id) === 2) {
        arr.push(tags.id)
      }
    })
    setSelectedTags(arr)
  }


  useEffect(() => {
    if (saveNewTagData.error) {
      setNewTagError(saveNewTagData?.error?.message)
    }
    if (saveNewTagData.response) {
      setNewTagError('')
      if (typeof addNewTag.current?.value === "string") {
        addNewTag.current.value = ""
      }
      fetchData()
    }

  }, [saveNewTagData])


  useEffect(() => {

    if (selectedContacts.length === 0) {
      setShowPopUp(false)
    } else {
      setSelectedTags([])
      setUpTags()
    }

  }, [selectedContacts])


  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    selection()
  }, [tags])


  useEffect(() => {
    setUpTags()

  }, [searchTagsData])


  { saveData.status === 'ok' && <SimpleDialog message={saveData.message} /> }
  {/* saveData.status === 'error' && <ErrorDialog title={'Internal Error'}>{saveData.message}</ErrorDialog> */ }

  return (
    <>
      <ShapelessButton disabled={selectedContacts.length > 0 ? false : true} aria-label="Change Tags" onClick={() => setShowPopUp(showPopup ? false : true)}><span className="font_size--large">Tags</span></ShapelessButton>
      <IonModal isOpen={showPopup} className="bg-white" backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonButtons className="w-17pr" slot="start">
              <div className=''>
                <IonButton className='color_har_blue' onClick={() => setShowPopUp(false)}><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />Back</IonButton>
              </div>
            </IonButtons>
            <IonTitle>Change Tags</IonTitle>
            <IonButtons className="w-17pr" slot="end">
              <div className=''>
                <IonButton className='min-w-70px color_har_blue' onClick={handleUpdate}>Save</IonButton>
              </div>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonInput ref={addNewTag} type="text" className="tag_input" placeholder="Add New Tag…"></IonInput>
            {newTagError &&
              <IonNote slot="helper" className="input-description input-description__error">{newTagError}</IonNote>
            }
          </IonItem>
          {tags?.map((tag, ind: number) =>
            <div key={ind} className="border-bottom pb-3 pl-3 pr-3 mb-3">
              <div className="row no-gutters">
                <div className="col-auto align-self-center"><img className="mr-3" src="https://content.harstatic.com/media/icons/a_all/icon_tags_blue.svg" /></div>
                <div className="col font_size--large">{tag.name} ({tag.contacts_count}) </div>
                <div className="col-auto">
                  <div role="checkbox" className={`custom-control custom-checkbox custom-control-inline mr-0 ${tag.status === 1 ? `` : ``}`}>
                    <input type="checkbox" id="seelct_tag_2" name={`selected_tag_${ind}`} className={`custom-control-input`} onChange={(e) => doSelectTags(tag.id, e.target.checked)} />
                    <label className="custom-control-label mr-0" htmlFor={`selected_tag_${ind}`}></label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
}
