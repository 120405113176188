import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeletePhoneApi
{
    export const url = "/contacts/:contact_id/phones/:id";
    export const method = "DELETE";
    export const defaultError = "Failed to delete phone.";
    export const operations = apiOperations(DeletePhoneApi);
    export const scope = new class DeletePhoneApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        id:string
    }

    export interface Response
    {
        
    }
}

export const fetchDeletePhoneService = forwardOperation(DeletePhoneApi.operations, 'fetch');
export const useFetchDeletePhoneService = forwardOperation(DeletePhoneApi.operations, 'useFetch');
export const useDeletePhoneService = forwardOperation(DeletePhoneApi.operations, 'useService');