import { IImportProviderContactScreenProps } from './ImportProviderContactScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ImportProviderContactScreenApp } from './ImportProviderContactScreen.app';
import { ImportProviderContactScreenDesktop } from './ImportProviderContactScreen.desktop';
import { useFetchGetContactsService } from 'services/contacts/provider/getContacts';
import { ImportContactApi, useImportContactService } from 'services/contacts/provider/importContact';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useHistory } from 'react-router';

export const ImportProviderContactScreen = (props: IImportProviderContactScreenProps): JSX.Element => {
    //contactService
    const [contactService, contactServiceAction] = useFetchGetContactsService()
    const [importContactService, importContactServiceAction] = useImportContactService()
    let history = useHistory()
    const init = () => {
        contactServiceAction.fetch()
    }

    const doImport = (prevent: string, ids: string[]) => {
        importContactServiceAction.fetch({ prevent_update: prevent, ids: ids })
    }

    useServiceDidSucceeded(importContactService, (response: ImportContactApi.Response) => {
        init()
      // displayMessage("Contacts submitted successfully.")
        history.push("/contacts/import/result/" + response.import_id)

    })




    const ImportProviderContactScreenPlatform = useResponsiveChoose(ImportProviderContactScreenDesktop, ImportProviderContactScreenApp);
    return (
        <ImportProviderContactScreenPlatform {...props}
            response={contactService.response}
            loading={contactService.loading}
            doImport={doImport}
            importService={importContactService} />
    );
}
