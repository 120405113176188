
import { ObjectOwnerApi } from "services/typeaheads/objectOwner";
import { ITag } from "views/forms/textboxes/TagsTextbox/TagsTextbox.def";

export interface IContactsFilterPopUpProps extends IContactsFilters {
    filters?: IContactsFilters,
    filtersCount?: number,
    showProps?: boolean,
    hideLanucher?: boolean,
    count?: number,
    disabled?: boolean,
    contactsApiLoading?: boolean,
    loadCount?: () => void,
    onChange?: <K extends keyof IContactsFilters>(key: K, value: IContactsFilters[K]) => void;
    numberOfFilter?: () => void,
    onClear?: () => void,
    applyFilter?: () => void
}
export interface IContactsFilters {
    cities?: string[];
    companies?: string[];
    stages?: number[];
    scores?: string[];
    owners?: ObjectOwnerApi.IObjectList[];
    deal_stages?: string[];
    tags?: ITag[];
    zip_codes?: number[];
    sources?: string[];
    last_contact_date?: string;
    birthday_start?: string;
    birthday_end?: string;
    home_anniversary_start?: string;
    home_anniversary_end?: string;
    wedding_anniversary_start?: string,
    wedding_anniversary_end?: string,
    known_since_start?: string,
    known_since_end?: string,
    lead_type?: string;
}

export interface ILocationFilter {
    filters: IContactsFilters
    showImportPopup?: boolean
}


export interface IFormFieldProps<T = string[]> {
    initialSelections?: T;
    onChange?: (selections: T) => void

}

export const dealsOptions = [
    {
        "short_value": "A",
        "long_value": "Active/Offers",
        "display_order": "1",

    },
    {
        "short_value": "I",
        "long_value": "In Contract",
        "display_order": "2"
    },
    {
        "short_value": "C",
        "long_value": "Closed",
        "display_order": "3"
    },
    {
        "short_value": "F",
        "long_value": "Fell Through",
        "display_order": "4"
    },
    {
        "short_value": "N",
        "long_value": "No Deals",
        "display_order": "4"
    }
]

export const stagesOptions = [
    {
        "short_value": 10,
        "long_value": "New",
        "display_order": "1",
        "class": "stage stage__new"
    },
    {
        "short_value": 20,
        "long_value": "Qualified",
        "display_order": "2",
        "class": "stage stage__qualified"
    },
    {
        "short_value": 30,
        "long_value": "Cold",
        "display_order": "3",
        "class": "stage stage__cold"
    },
    {
        "short_value": 40,
        "long_value": "Warm",
        "display_order": "4",
        "class": "stage stage__warm"
    },
    {
        "short_value": 50,
        "long_value": "Hot",
        "display_order": "5",
        "class": "stage stage__hot"
    },
    {
        "short_value": 60,
        "long_value": "Active Listing",
        "long_full_value": "Active ",
        "display_order": "6",
        "class": "stage stage__undercontract "
    },
    {
        "short_value": 100,
        "long_value": "Closed",
        "display_order": "7",
        "class": "stage stage__closed"
    },
    {
        "short_value": 0,
        "long_value": "None",
        "display_order": "8",
        "class": ""
    }
]
export const lastContact = [
    {
        "short_value": "1",
        "long_value": 'Less than a week ago',
        "display_order": "1"
    },
    {
        "short_value": "2",
        "long_value": '9 months ago',
        "display_order": "2"
    },
    {
        "short_value": "3",
        "long_value": '3 months ago',
        "display_order": "3"
    },
    {
        "short_value": "4",
        "long_value": '12 months ago',
        "display_order": "4"
    },
    {
        "short_value": "5",
        "long_value": '6 months ago',
        "display_order": "5"
    },
    {
        "short_value": "6",
        "long_value": 'more than 1 year',
        "display_order": "6"
    },
]

export interface IContactCommonFiltersKeys {
    birthday_start: string,
    birthday_end: string,
    home_anniversary_start: string,
    home_anniversary_end: string,
    wedding_anniversary_start: string,
    wedding_anniversary_end: string,
    known_since_start: string,
    known_since_end: string,
    lead_type: string

}


export interface IContactCommonFilters {
    startValue?: string,
    endValue?: string,
    onChange?: <K extends keyof IContactCommonFiltersKeys>(key: K, value: string) => void,
    value?: string
    title?: string
    startDate: keyof IContactCommonFiltersKeys
    endDate: keyof IContactCommonFiltersKeys
}