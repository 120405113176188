import { IFilterPopUpProps } from './FilterPopUp.def';
import { useResponsiveChoose } from "scripts/hooks/general";
//import { FilterPopUpApp } from './FilterPopUp.app';
import { FilterPopUpDesktop } from './FilterPopUp.desktop';

export const FilterPopUp = (props: IFilterPopUpProps): JSX.Element =>
{
    const FilterPopUpPlatform = useResponsiveChoose(FilterPopUpDesktop, FilterPopUpDesktop);
    return (
        <FilterPopUpPlatform {...props} />
    );
}
