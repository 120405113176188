import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities";
export namespace DownloadContactsApi {
    export const url = "/contacts/download";
    export const method = "GET";
    export const defaultError = "Failed to download contact list.";
    export const operations = apiOperations(DownloadContactsApi);
    export const scope = new class DownloadContactsApiScope implements IApiScope<Request, Response>
    {
        request(data: Request): PropertiesType<Request, string | number> {
            return {
                version: 1,
                contact_ids: data.contact_ids?.join(','),
                format: data.format,

            };
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            const { total, file } = data;
            return {
                total: parseInt(total),
                file: file
            }
        }



    }();

    export interface Request {
        contact_ids?: string[];
        format?: string
        version?: number
    }

    export interface Response {
        total: number,
        file: string,
    }



    export interface IContactSearchItem {
        id: number,
        photo_url: string,
        first_name: string,
        last_name: string,
        middle_name: string,
        company: string,
        score: string,
        pipeline_status: string,
        sources: string,
        city: string,
        deal_stages: Array<IDealStage>,
        tags: Array<ITag>
    }


    export interface IDealStage {
        id: string;
        name: string;
        total_deals: number;
    }

    export interface ITag {
        id: number;
        name: string;
        contacts_count: number;
    }
}

export const fetchDownloadContactsService = forwardOperation(DownloadContactsApi.operations, 'fetch');
export const useFetchDownloadContactsService = forwardOperation(DownloadContactsApi.operations, 'useFetch');
export const useDownloadContactsService = forwardOperation(DownloadContactsApi.operations, 'useService');