import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IConfirmation, INewEmailProps } from './NewEmail.def';
import './NewEmail.scss';
import { Editor } from '@tinymce/tinymce-react';
import { IonModal } from "@ionic/react";
import { Textbox } from 'views/forms/textboxes/Textbox';
import { OrdinaryButton, PrimaryButton, ShapelessButton, ShapelessGreenButton } from 'views/forms/buttons/Button';
import { useEffect, useState } from 'react';
import { SendEmailsApi, useSendEmailsService } from 'services/emails/sendEmails';
import { fetchSearchContactsService, } from 'services/contacts/searchContacts';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useCreateDraftService } from 'services/emails/createDraft';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useDeleteMultiDraftsService } from 'services/emails/deleteMultiDrafts';
import { useEmailDetailsService } from 'services/emails/emailDetails';
import { IEmailRecipient } from 'services/emails/searchEmails';
import { EmailTags } from 'views/general/EmailTags';
import { Attachment } from './components/Attachment';
import { AttachmentFiles } from './components/AttachmentFiles';
import { format, parseISO } from 'date-fns';
import { ToField } from 'views/emails/components/ToField';





export const NewEmailDesktop = (props: INewEmailProps): JSX.Element => {
    const { title, show, messageId, action, type, provider_email, contactId, connectionStatus, onClose, changePreview, reload } = props
    const [CC, CCToggle] = useState(false)
    const [BCC, BCCToggle] = useState(false)
    const [sending, setSending] = useState(false)
    const [updatingApi, setUpdatingApi] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [draftNotify, setDraftNotify] = useState(false)
    const [counter, setCounter] = useState(0)
    const [warning, setWarning] = useState("")

    const [error, setError] = useState("")
    const [confirmMessage, setConfirmMessage] = useState("")
    const [oldMessage, setOldMessage] = useState("")
    const [draftTimer, setDraftTimer] = useState(0)
    const [notiTimer, setNotiTimer] = useState(0)

    const [email, setEmail] = useState<SendEmailsApi.Request>({
        id: ""
    })
    const [ToContacts, saveToContact] = useState<IEmailRecipient[]>()
    const [CClist, setCClist] = useState<string[] | undefined>()
    const [BCClist, setBCClist] = useState<string[]>()
    type EmailApiKey = keyof SendEmailsApi.Request

    //services
    const [emailService, emailServiceAction] = useEmailDetailsService();
    const [newDraftSer, newDraftSerAction] = useCreateDraftService()
    const [saveEmailSer, saveEmailSerAction] = useSendEmailsService()
    const [deleteDraftSer, deleteDraftSerAction] = useDeleteMultiDraftsService()



    const sendEmail = () => {
        if (!connectionStatus) {
            return
        }
        if (!ToContacts || ToContacts.length === 0) {
            setError("Select contacts to send")
            setSending(false)
        } else {
            setError("")
            setSending(true)
            saveEmailSerAction.fetch({
                send: "1",
                ...email
            })

        }
    }

    const discard = () => {
        if (email.id) {
            deleteDraftSerAction.fetch({ draft_ids: [email.id] })
        }
        clear()
        onClose?.()
    }

    function clear() {
        setUpdatingApi(false)
        saveToContact([])
        setCClist([])
        setError("")
        setBCClist([])
        setOldMessage("")
        setEmail({
            id: "",
        })
    }

    function updateEmail(key: EmailApiKey, value: string | string[] | SendEmailsApi.IEmailAttachment[]) {
        if (!updatingApi) {
            setUpdatingApi(true)
        }
        setEmail({ ...email, [key]: value })
    }

    function checkInlineImages(html: string) {
        const pattern = /<img src="[blob:|data:].*?>/g
        var message = html
        html.match(pattern)?.map((_images: any) => {
            message = message.replace(pattern, "");

        })
        updateEmail("message", message)
    }


    function selected(person: IEmailRecipient, contact: IEmailRecipient[], action: React.Dispatch<React.SetStateAction<IEmailRecipient[] | undefined>>) {
        const allContacts = [...ToContacts ? ToContacts : []]
        if (allContacts.find((contact) => contact.email === person.email)) {
            setWarning('Contacts already been selected')
        } else {
            let allContacts = [...contact ? contact : [], person]

            action(allContacts)
            prepareSender("recipients", allContacts)
        }
    }

    function removeContact(person: IEmailRecipient, contact: IEmailRecipient[], action: React.Dispatch<React.SetStateAction<IEmailRecipient[] | undefined>>) {
        let contacts = contact?.filter((contact) => {
            if (person.id) {
                return contact.id !== person.id
            } else {
                return contact.email !== person.email
            }

        })
        action(contacts)
        prepareSender("recipients", contacts)
    }

    function prepareSender(key: EmailApiKey, contact: IEmailRecipient[] | undefined) {
        let emails: string[] = []
        contact?.map((contact) =>
            emails.push(contact.id ? contact.id : contact.email)
        )
        updateEmail(key, emails)
    }

    function saveDraft() {
        if (!connectionStatus) {
            return
        }
        if (email.id === "") {
            if (newDraftSer.loading || saveEmailSer.loading) {
                return
            }
            newDraftSerAction.fetch({
                subject: email.subject,
                message: email.message,
                recipients: email.recipients,
                recipients_cc: CClist,
                recipients_bcc: BCClist,
                attachments: email.attachments
            })
        } else {
            saveEmailSerAction.fetch({
                id: email.id,
                subject: email.subject,
                message: email.message,
                recipients: email.recipients,
                recipients_cc: CClist,
                recipients_bcc: BCClist,
                attachments: email.attachments
            })
        }
    }

    const hideDraft = () => {
        setTimeout(() => {
            setDraftNotify(false)
        }, 1000)
    }



    const removeAttach = (key: string, type: "key" | "id") => {
        let attachmentInfo = email.attachments?.filter((attach) => (type === 'key' && attach.key !== key) || (type === 'id' && attach.id !== key))
        if (attachmentInfo) {
            updateEmail("attachments", attachmentInfo)
        }

    }


    //Create new Draft
    useServiceDidSucceeded((newDraftSer), (response) => {
        setDraftNotify(true)
        if (response) {
            if (sending && connectionStatus) {
                saveEmailSerAction.fetch({ ...email, id: response.data.id, send: "1" })
            } else {
                setEmail({ ...email, id: response.data.id, attachments: response.data.attachments })
            }
        }

        hideDraft()
        if (type === 'draft') {
            changePreview?.({
                id: response.data.message_id,
                message_uid: response.data.id,
                short_message: response.data.short_message,
                subject: email.subject,
                date_tag: 'Today',
                created_dt: response.data.created_dt
            }, true)
        }
    })

    //Update Draft or Email Sent
    useServiceDidSucceeded((saveEmailSer), (response: any) => {
        if (sending) {
            displayMessage("Email have been sent.")
            clear()
            onClose?.()
            setSending(false)
            if (messageId && type === 'draft') {
                reload?.()
            }
        } else {

            setDraftNotify(true)
            hideDraft()
            if (messageId && type === 'draft') {
                changePreview?.({
                    id: response?.data.message_id,
                    message_uid: response.data.id,
                    short_message: response.data.short_message,
                    subject: email.subject,

                }, false)
            }
            if (response.data.attachments) {
                setUpdatingApi(false)
                setEmail({ ...email, attachments: response.data.attachments })
            }
        }

    })

    useServiceDidFailed((saveEmailSer), () => {
        setSending(false)

    })


    //get Message for draft.
    //get Message for reply.
    //get Message for forward.
    useServiceDidSucceeded((emailService), (response) => {
        if (response) {
            setUpdatingApi(false)
            if (type && response.message_uid && type === `draft`) {
                let recipientIds: string[] = []
                if (response.recipients) {
                    response.recipients.map((contact) => recipientIds.push(contact.id))
                }
                saveToContact(response.recipients)
                setEmail({
                    id: response.message_uid,
                    subject: response.subject,
                    message: response.message,
                    recipients: recipientIds,
                    recipients_cc: response.recipients_cc,
                    recipients_bcc: response.recipients_bcc,
                    attachments: response.attachments

                })
                setOldMessage(response.message)
                setCClist(response.recipients_cc)
                setBCClist(response.recipients_bcc)
            } else if (type !== 'draft' && action === 'reply') {
                let recipientIds: string[] = []
                if (response.recipients) {
                    response.recipients.map((contact) => recipientIds.push(contact.id))
                }
                if (type === 'sent') {
                    saveToContact(response.recipients)
                }
                saveContact(Number(response.contact_id))
                setEmail({
                    id: "",
                    subject: 'Re:' + response.subject,
                    message: '<br/><br/>' + response.message,
                    recipients: [response.contact_id],
                    recipients_cc: type === 'sent' ? recipientIds : response.recipients_cc,
                    recipients_bcc: response.recipients_bcc,
                    reply_to_message_id: response.message_uid,
                    attachments: response.attachments
                })
                setOldMessage('<br/><br/>' + response.message)

                setCClist(response.recipients_cc)
                setBCClist(response.recipients_bcc)

            } else if (type !== 'draft' && action === 'forward') {

                // Mon, Jun 26, 2023 at 10:16 PM
                let mailDate = new Date(parseISO(response.created_dt))
                console.log(typeof mailDate)
                let dateFormat = format(mailDate, "EEE, MMM dd, yyyy' at 'HH:mm:ss a")
                let name = response.first_name || response.last_name ? response.first_name + ' ' + response.last_name : ''

                let fwdMessage = '<br/><br/><div dir="ltr">---------- Forwarded message ---------<br>From: <b dir="auto">' + name + '</b> <span dir="auto">&lt;<a href="mailto:' + response.from_address + '">' + response.from_address + '</a>&gt;</span><br>Date:' + dateFormat + '<br>Subject:' + response.subject + '</div><br/><br/>'
                setEmail({
                    id: "",
                    subject: 'Fwd:' + response.subject,
                    message: fwdMessage + response.message,
                    attachments: response.attachments
                })
                setOldMessage(fwdMessage + response.message)
                setCClist(response.recipients_cc)
                setBCClist(response.recipients_bcc)
            }


        }


    })


    //delete Draft
    useServiceDidSucceeded((deleteDraftSer), (response) => {
        if (response) {
            reload?.()
        }
    })

    async function saveContact(contact_id: number) {
        const contactService = await fetchSearchContactsService({ contact_ids: [contact_id] })
        if (contactService.list[0]) {
            saveToContact([{
                id: contactService.list[0].id.toString(),
                first_name: contactService.list[0].first_name,
                last_name: contactService.list[0].last_name,
                first_last_name: contactService.list[0].first_last_name,
                middle_name: contactService.list[0].middle_name,
                email: contactService.list[0].primary_email,
                photo_url: contactService.list[0].photo_url,
                score: contactService.list[0].score,
            }])
        }
    }




    const flashNotification = (action: React.Dispatch<React.SetStateAction<string>>) => {
        setNotiTimer(setTimeout(() => {
            action('')
        }, 2000))
        return () => {
            if (notiTimer) {
                clearTimeout(notiTimer)
            }
        }
    }

    const flashDraftNotification = () => {
        if (updatingApi) {
            if (draftTimer) { clearTimeout(draftTimer) }

            setDraftTimer(setTimeout(() => {
                saveDraft()
            }, 900))
        }
        return () => {
            if (draftTimer) {
                clearTimeout(draftTimer)
            }
        }
    }



    useEffect(() => {
        return flashDraftNotification()
    }, [email])


    useEffect(() => {
        return flashNotification(setWarning)
    }, [warning])

    useEffect(() => {
        return flashNotification(setError)
    }, [error])




    useEffect(() => {
        if (contactId) {
            setUpdatingApi(false)
            saveContact(Number(contactId))
            setEmail({ ...email, recipients: [contactId] })
        }
    }, [contactId])

    useEffect(() => {
        if (messageId && type) {
            emailServiceAction.fetch({ id: messageId, type: type })
            setCounter(0)
        } else {
            clear()
            setCounter(1)
        }
    }, [messageId])





    return (
        <>
            <Confirmation
                show={showConfirmation}
                message={confirmMessage}
                onClose={() => setShowConfirmation(false)}
                affirmative={() => {
                    setSending(true)
                    sendEmail();
                    setShowConfirmation(false)
                }}
                negative={() => setShowConfirmation(false)} />
            <DefaultPopUp isOpen={show} title={title} className="custom--modal custom--modal__fullheight custom--modal__fullwidth" toggleShow={() => {
                clear()
                onClose?.()
            }} >
                <DefaultPopUp.Body>
                    <div className="pl-5 pr-5 email_body_container">
                        <div className="emailbody_header">
                            <div className="pb-2 pt-2 border-bottom">
                                <div className="inputauto h-40px">
                                    <div className="inputauto__title">From:</div>
                                    <div className="inputauto__item" ><div className="email_tag">{provider_email} <span>(me)</span></div></div>
                                    <div className="inputauto__input"></div>
                                </div>
                            </div>

                            <div className="pt-2 pb-2 border-bottom">
                                <div className="inputauto">
                                    <ToField selected={(contact) => selected(contact, ToContacts ? ToContacts : [], saveToContact)} contacts={ToContacts} removed={(contact) => removeContact(contact, ToContacts ? ToContacts : [], saveToContact)} />
                                    <div className="inputauto__link font_size--large">
                                        <a className="pl-3 cursor_pointer" onClick={() => CCToggle(CC ? false : true)}>Cc</a>
                                        <a className="pl-3 cursor_pointer" onClick={() => BCCToggle(BCC ? false : true)}>Bcc</a></div>

                                </div>

                            </div>
                            {warning &&
                                <div className="alert alert-warning box-shadow-none position-absolute" role="alert">
                                    {warning}
                                </div>
                            }
                            {error &&
                                <div className="alert alert-danger position-absolute box-shadow-none" role="alert">
                                    {error}
                                </div>
                            }


                            <div id="CC-container" className={`pt-2 pb-2 border-bottom ${CC ? `d-block` : 'd-none'}`} >
                                <div className="inputauto">
                                    <div className="inputauto__title">CC:</div>
                                    <div className="inputauto__item w-82pr">
                                        <EmailTags list={CClist ? CClist : []} save={(cc?: string[]) => {
                                            if (cc) {
                                                setCClist(cc)
                                                updateEmail('recipients_cc', cc)
                                            }
                                        }} placeholder='CC' />
                                    </div>
                                    <div className="inputauto__input h-40px"></div>
                                </div>
                            </div>
                            <div id="BCC-container" className={`pt-2 pb-2 border-bottom ${BCC ? `d-block` : 'd-none'}`} >
                                <div className="inputauto">
                                    <div className="inputauto__title">BCC:</div>
                                    <div className="inputauto__item w-82pr">
                                        <EmailTags list={BCClist} save={(bcc?: string[]) => {
                                            if (bcc) {
                                                setBCClist(bcc)
                                                updateEmail('recipients_bcc', bcc)
                                            }

                                        }} placeholder='BCC' />
                                    </div>
                                    <div className="inputauto__input h-40px"></div>

                                </div>
                            </div>


                            <div className="pb-3 pt-3 border-bottom">
                                <div className="inputauto pt-1 pb-1">
                                    <div className="inputauto__title">Subject:</div>
                                    <div className="inputauto__item w-80pr">
                                        <Textbox class="input--shapless font_weight--bold" placeholder='Subject' value={email.subject ? email.subject : ''} onIonInput={(e: any) => updateEmail('subject', e.target.value)}></Textbox>
                                        <div className="email_tag"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="emailbody_text d-flex">

                            <div className="textarea_container">
                                <Editor
                                    apiKey={process.env.REACT_APP_AS_TINYMCE_KEY}
                                    onEditorChange={(html) => {
                                        if (counter > 0) {
                                            checkInlineImages(html)
                                        } else {
                                            setCounter(1)
                                        }

                                    }}
                                    initialValue={oldMessage ? oldMessage : ''}
                                    init={{
                                        height: 500,
                                        width: '100%',
                                        menubar: false,
                                        paste_block_drop: true,
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat ',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />


                            </div>
                        </div>

                    </div>


                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <AttachmentFiles removeAttach={removeAttach} attachments={email.attachments} />
                    <div className="ml-5 mr-5">
                        <div className="row pt-3 mb-3">
                            <div className="col-auto algin-self-center">
                                {connectionStatus &&
                                    <>
                                        <PrimaryButton disabled={sending} className="btn--medium" onClick={() => {
                                            if (action === "forward" || action === 'reply' || action === 'draft') {
                                                setSending(true)
                                                if (email.id === "") {
                                                    saveDraft()
                                                } else {
                                                    sendEmail()
                                                }
                                            } else if (typeof email?.subject !== 'undefined' && email?.subject?.trim() !== '' && typeof email?.message !== 'undefined' && email?.message?.trim() !== '') {
                                                sendEmail()
                                            } else {
                                                let message = 'Are you sure you want to send email without'
                                                if (typeof email.subject === 'undefined' || email.subject === "") {
                                                    message += " Subject "
                                                }
                                                if (typeof email.message === 'undefined' || email.message === "") {
                                                    let assume = (typeof email.subject === 'undefined' || email?.subject === "") ? 'and' : ''
                                                    message += assume + " Message"
                                                }
                                                setConfirmMessage(message)
                                                setShowConfirmation(true)
                                            }



                                        }}>{sending ? `Sending` : `Send`}</PrimaryButton>
                                        {!email.attachments || (email.attachments && email?.attachments.length < 5) ?
                                            <Attachment attachments={email.attachments} updateEmail={updateEmail} files_count={email.attachments?.length} />
                                            :
                                            <></>
                                        }

                                        {draftNotify &&
                                            <ShapelessGreenButton className="btn--medium"><img src="https://content.harstatic.com/media/icons/checkmark-rounded-green.svg" />Draft saved</ShapelessGreenButton>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col-auto ml-auto">
                                <ShapelessButton className="btn--shapeless_light btn--medium" onClick={() => discard()}>Discard</ShapelessButton>
                            </div>
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp >
        </>
    );


}

const Confirmation = (props: IConfirmation) => {
    const { show, message, onClose, affirmative, negative } = props

    return (
        <IonModal isOpen={show} title="New Email" className="custom--modal__headerless custom--modal__blackoverlay custom--modal__smallheight" onDidDismiss={() => onClose?.()} backdropDismiss={false}>
            <div className='p-5 border border_radius--20px'>

                <div className="text-center border-none pt-4 pb-4">
                    {message &&
                        <div className="font_weight font_weight--semi_bold">{message}</div>
                    }
                </div>
                <div className='row text-center m-auto d-block'>
                    <PrimaryButton onClick={() => affirmative?.()} >Yes</PrimaryButton>
                    <OrdinaryButton onClick={() => negative?.()} >No</OrdinaryButton>
                </div>
            </div>

        </IonModal >
    )

}


/*
const ContactsTypeaheadForEmail = (props: IContactEmailTypeahead) => {

    const [contacts, setContacts] = useState<SearchContactsApi.IContactSearchItem[]>([])
    const [contactSearch, ContactSearchAction] = useSearchContactsService()
    const [timer, setTimer] = useState<number | string>()
    const searchField = useRef<HTMLIonInputElement | null>(null)

    useServiceDidSucceeded(contactSearch, (response) => {
        const newContacts: any = [];
        if (response) {
            response?.list.map((contact: any) => {
                newContacts.push(contact);
            })
            setContacts(newContacts);
        }
    })
    const search = ((value: string) => {
        if (value.length === 0) {
            return
        }

        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            ContactSearchAction.fetch({ keyword: value })
        }, 500))
    })

    function onSelect(contact: IEmailRecipient) {
        setContacts([])
        props.selected(contact)
        if (null !== searchField.current) {
            searchField.current.value = ""
        }
    }



    const LoadingState = (): JSX.Element => {
        const LoadingField = (): JSX.Element => {
            return (
                <div className="card text-left mb-3 border-0 card__mini">
                    <div className="agent_signature agent_signature__medium agent_signature__loading">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-50"></div>
                            <div className="agent_signature__loading__textline max-w-90"></div>
                        </div>
                    </div>
                </div>
            );
        }
        const rows: number[] = [];
        for (let i = 0; i < 3; i++) {
            rows[i] = i
        }
        return (
            <div className=' position-absolute zindex-200 bg_color_snow_white p-2 min-w-350'>
                {
                    rows.map((index: number) =>
                        <LoadingField key={index} />
                    )
                }
            </div>
        );

    }

    return (
        <div >
            <IonInput ref={searchField} className="form-control border-0" placeholder='Search contacts' onIonInput={(e: any) => search(e.target.value)} />
            {
                contactSearch.loading &&
                <LoadingState />
            }
            {
                contactSearch.response &&
                <div className={`border_radius--pudgy  depth--standed_out position-absolute  min-w-350px max-h-240px h-auto zindex-200 overflow-hidden ${searchField.current?.value ? `d-block` : `d-none`}`}>
                    {contacts.map((contact, index: number) =>
                        <div key={index} className={`agent_signature agent_signature__medium border-bottom p-3 bg_color_snow_white`} onClick={() => onSelect({
                            id: contact.id.toString(),
                            first_name: contact.first_name,
                            middle_name: contact.middle_name,
                            first_last_name: contact.first_last_name,
                            photo_url: contact.photo_url,
                            last_name: contact.last_name,
                            score: contact.score,
                            email: contact.primary_email,
                        })}>
                            <Avatar
                                firstName={contact.first_name}
                                lastName={contact.last_name}
                                photo={contact.photo_url}
                                className={'cursor--hand'}
                                id={contact.id.toString()}
                            />

                            <div className="agent_signature__text text-truncate cursor--hand">
                                <div className="row no-gutters">
                                    <div className="col  text-truncate">
                                        <div className="agent_signature__text__title text-truncate">{contact.first_name || contact.last_name ? contact.first_name + " " + contact.last_name : contact.primary_email}</div>
                                        <div className="agent_signature__text__desc">{contact.primary_email}</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}

                </div>
            }


        </div >
    );

}*/
