import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"
import { strToBoolean } from 'scripts/helpers/texts';
import moment from 'moment';

export namespace GetCalendarTaskDetailsApi {
    export const url = "/tasks/:id";
    export const method = "GET";
    export const defaultError = "Failed to load task information.";
    export const operations = apiOperations(GetCalendarTaskDetailsApi);
    export const scope = new class GetCalendarTaskDetailsApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                completed: strToBoolean(data.completed),
                id: (data.id),
                start_dt: moment(data.start_dt).toDate(),
                end_dt: moment(data.end_dt).toDate(),
                created_dt: moment(data.created_dt).toDate(),
                reminder_minutes_prior: data.reminder_minutes_prior,
                contacts: data.contacts.map((y) => ({
                    ...y,
                    id: parseInt(y.id)
                }))

            }
        }
    }();

    export interface Request {
        id: string
    }

    export interface Response {
        notes: string,
        id: string,
        title: string,
        property_full_street_address: string,
        property_city: string,
        property_zip: string,
        property_state: string,
        property_country: string,
        type: string,
        type_text: string,
        start_dt: Date,
        end_dt: Date,
        dateTime: string,
        created_dt: Date,
        completed_dt: string,
        completed: boolean,
        status: string,
        status_text: string,
        category: string,
        category_text: string,
        priority: string,
        priority_text: string,
        reminder_minutes_prior: string,
        reminder_minutes_prior_text: string,
        repeat: string,
        is_recurrent: string,
        repeat_text: string,
        repeat_every_value: string,
        repeat_every_text: string,
        repeat_on_day: string,
        repeat_end_on: string,
        repeat_end_occurrences: string,
        repeat_end_date: string,
        repeat_month_option: string,
        contacts: Array<IContact>,
        property_harid: string,
        property_listingid: string,
        property_for: string,
        property_address2: string,
        property_mlsnum: string,
        property_photo: string,
        property_price: string,
        property_type: string,
        property_type_text: string,
        property_url: string,
        location: string,
        harid: string,
        listingid: string,
        for: string,
    }
}

export interface IContact {
    id: number,
    first_name: string,
    middle_name: string,
    last_name: string,
        photo_url:string,
        email:string,
        phone:string,
        phone_extension:string,
        phone_country_code:string,
        deleted:string
}
export const fetchGetCalendarTaskDetailsService = forwardOperation(GetCalendarTaskDetailsApi.operations, 'fetch');
export const useFetchGetCalendarTaskDetailsService = forwardOperation(GetCalendarTaskDetailsApi.operations, 'useFetch');
export const useGetCalendarTaskDetailsService = forwardOperation(GetCalendarTaskDetailsApi.operations, 'useService');
