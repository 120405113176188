import { IChangeAliasPopUpProps } from './ChangeAliasPopUp.def';
import './ChangeAliasPopUp.scss';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useUpdateRoutineEmailService } from 'services/emails/updateRoutineEmail';
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonModal, IonNote, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useRef, useState } from 'react';

export const ChangeAliasPopUpApp = (props:IChangeAliasPopUpProps): JSX.Element =>
{
    const { show, setting, onClose, onChange } = props
    const textField = useRef<HTMLIonInputElement | null>(null)
    const [error, setError] = useState("")

    const [updService, updServiceAction] = useUpdateRoutineEmailService()

    const validated = (): boolean => {
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (textField?.current?.value !== null) {
            if (typeof textField?.current?.value === 'string' && format.test(textField?.current?.value)) {
                setError("The email should not contain special characters")
                return false
            } else {
                setError("")
                return true
            }
        } else {
            return false
        }
    }

    const updateRoutineEmail = () => {
        if (validated() && textField?.current?.value !== null && typeof textField?.current?.value === 'string') {
            updServiceAction.fetch({ routine_email_alias: textField?.current?.value })
        }
    }

    useServiceDidSucceeded((updService), (response) => {
        if (response) {
            onClose?.()
            
            const alias = textField.current!.value as string;
            onChange?.({
                routine_email_alias: alias,
                routine_email_domain: setting.routine_email_domain,
                routine_email: `${alias}@${setting.routine_email_domain}`
            });
        }
    })

    return (
        <IonModal className="" trigger="open-modal" isOpen={show} onDidDismiss={() => onClose?.()} initialBreakpoint={0.80} breakpoints={[0, 0.70, 0.65, 0.85]} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color={"blue"} strong={true} onClick={() => onClose?.()}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Change Routing Email</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color={"blue"} strong={true} onClick={updateRoutineEmail}>
                            Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent>
                <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                    <IonLabel>Routing email</IonLabel>
                    <div className='d-flex border w-95pr'>
                        <IonInput ref={textField} type='text' className="border-0 w-288px" maxlength={48} value={textField.current?.value ? textField.current?.value : setting?.routine_email_alias}></IonInput>
                        <IonText className='ml-auto pr-2 font_size--medium pt-3 border bg_color_cement_light p-2'> @{setting?.routine_email_domain}</IonText>
                    </div>
                    {error &&
                        <IonNote slot="helper">{error}<br /></IonNote>
                    }
                    <div className="warning mt-2"><div className="warning__text">This change will not affect your login email</div></div>
                </div>
            </IonContent>
        </IonModal>
    )
}
