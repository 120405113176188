import { isProduction } from "scripts/application/settings";
import { queryString } from "scripts/helpers/urls";
//import { Auth } from "scripts/security/AuthService";


const APPLICATION_ID = 'com.har.crm';
const BASE_URL = isProduction? 'https://har-connect-service.har.com': 'https://har-connect-service-test.har.com';

export class HARConnectServiceRequest<P extends object = object>
{
    private readonly TOKEN = '0dae2741-3ca4-4612-82ce-cba95e4fca83';
    private readonly baseParams?:P;
    public readonly baseUrl:string;

    public constructor(path:string, params?:P)
    {
        //params = Object.assign(params ?? {}, { api_key:this.TOKEN });
        this.baseParams = params;
        //const query = queryString(params, true);
        this.baseUrl = BASE_URL + path;
    }

    public async redirect(params:Partial<P> = {})
    {
        const authorization = await this.loadToken();
        window.location.href = this.buildUrl(!authorization, { ...params, authorization });
    }

    public async fetch()
    {
        const accessToken = await this.loadToken();
        const url = this.buildUrl(!accessToken);
        const headers = accessToken? { Authorization: `Bearer ${accessToken}` }: undefined;
        return fetch(url, { headers }).then(x => x.json());
    }

    private buildUrl(includeApiKey:boolean, params?:object): string
    {
        params = this.buildParams(params);
        if(includeApiKey) { params = Object.assign(params ?? {}, { api_key:this.TOKEN }); }
        const query = params? '?' + queryString(params, true): '';
        return this.baseUrl + query;
    }

    private buildParams(params?:object): object | undefined
    {
        if(!params) { return this.baseParams; }
        if(!this.baseParams) { return params; }
        return Object.assign(this.baseParams, params);
    }

    private async loadToken(): Promise<string | undefined>
    {
        return undefined;
        //return Auth.loginProvider.useCookies? undefined: Auth.userValidToken;
    }
}


export const authorize = (userId:number | string, { redirect_uri, login_hint }: { redirect_uri:string, login_hint?:string }) => 
    new HARConnectServiceRequest(`/api/v1/providers/authorize/${userId}`, { application_id:APPLICATION_ID, redirect_uri, login_hint });

export const reauthenticate = (userId:number | string, { redirect_uri }: { redirect_uri:string }) => 
    new HARConnectServiceRequest(`/api/v1/providers/reauthenticate/${userId}`, { application_id:APPLICATION_ID, redirect_uri });

/*export const revoke = (userId:number | string) =>
    new HARConnectServiceRequest(`/api/v1/providers/revoke/${userId}`);*/