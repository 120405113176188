import { useEffect, useState } from 'react';
import { IDealsCardProps } from './DealsCard.def';
import './DealsCard.scss';
import { formatHarDate } from 'scripts/helpers/dates';
import { parseISO, isPast } from 'date-fns'
import { Tag } from 'scripts/ui/tag';
import { ContactTitle } from 'views/general/ContactTitle';
import { Avatar } from 'views/contacts/Avatar';

export const DealsCard = (props: IDealsCardProps): JSX.Element => {
    const { deals, openEditPopUp } = props
    const [expired, setExpiry] = useState<boolean | undefined>()
    useEffect(() => {
        if (deals.estimated_deadline) {
            setExpiry(isPast(new Date(parseISO(deals.estimated_deadline))))
        }

    }, [deals])
    return (
        <div className="cards cards__deals_wt cursor--hand ui-sortable-handle" onClick={() => openEditPopUp?.(deals.id)}>
            {deals.title &&
                <div className=" font_size--medium font_weight--bold pb-1">{deals.title}</div>
            }
            <div className="listing_signature listing_signature__small mb-2">

                <Tag.Div className="listing_signature__photo listing_signature_photo" cssVars={{ listingSignaturePhoto: `url(${deals.property_photo ? deals.property_photo : `https://content.harstatic.com/img/common/no_image_bg.jpg`})` }}></Tag.Div>
                <div className="listing_signature__details">
                    <div className="font_weight--semi-bold">{deals.property_full_street_address}</div>
                    <div className="listing_signature__details__address">
                        {deals.property_city ?
                            <>
                                {deals.property_city}{deals.property_state === "" && deals.property_zip ? `` : `,`} {deals.property_state} {deals.property_zip}
                            </>
                            :
                            <>
                                {deals.property_state} {deals.property_zip}
                            </>

                        } </div>
                </div>
            </div>
            <div className="message message--success d-block mb-2">
                <div className="d-flex justify-content-between">
                    <div>{deals.deal_type_text}</div>
                    <div className="text-right font_weight--semi_bold">Value ${deals.deal_amount_formatted}</div>
                </div>
            </div>
            {typeof expired !== 'undefined' &&
                <div className={`${expired ? `warning` : ``} warning__small`}>
                    <div className="warning__small__text">
                        <span><span className="font_weight--semi_bold">{expired ? `Expired on` : `Expected Closing on`}: </span></span><span>{formatHarDate(deals.estimated_deadline)}</span>
                    </div>
                </div>
            }
            {deals.contacts?.[0] &&
                <div className="d-flex pt-2 agent_signature__mini">
                    <Avatar
                        id={deals?.contacts[0]?.id.toString() ?? '0'}
                        firstName={deals?.contacts[0]?.first_name}
                        lastName={deals?.contacts[0]?.last_name}
                        photo={deals?.contacts[0]?.photo_url}
                    />
                    <div className="font_size--small_extra color_auxiliary pt-1 font_weight--semi_bold min-h-17px"><ContactTitle contact={deals.contacts[0]} /></div>
                </div>
            }
        </div>
    );
}
