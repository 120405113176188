import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import './ContactDocumentsScreen.scss';
import { useFetchListDocumentsService,ListDocumentsApi} from 'services/contacts/documents/listDocuments';
import { useEffect, useState } from 'react';
import { PropsWithChildren } from 'react';
import { useUploadDocumentService } from 'services/contacts/documents/uploadDocument';
import {  useParams } from 'react-router';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useDeleteDocumentsService } from 'services/contacts/documents/deleteDocuments';
import { useRenameDocumentsService } from 'services/contacts/documents/renameDocuments';
import { useEventConfirmation } from 'scripts/hooks/forms';
import { ContactDocumentsScreenApp } from './ContactDocumentsScreen.app';
import { ContactDocumentsScreenDesktop } from './ContactDocumentsScreen.desktop';
import { useResponsive } from 'scripts/hooks/general';

export const Container = (props: PropsWithChildren<{}>) =>
(
    <>{props.children}</>
);



export const ContactDocumentsScreen = (): JSX.Element => {
    const confirmation=useEventConfirmation()
    const [isOpen, toggleOpenEditPopup] = useState(false);
    const [data, setData] = useState<ListDocumentsApi.IDocumentData>();
    const { id } = useParams<{ id: string }>();
    const [reload, doReload] = useState(1);
    const [documentsList,ActionGetDocumentList] = useFetchListDocumentsService({ contact_id: id, page: '1', renew: '1' });
    const [uploadDocument, ActionUploadDocument] = useUploadDocumentService();
    const [removedDocument,ActionRemovedocument]=useDeleteDocumentsService();
    const [updateDocument,ActionUpdateDocument]=useRenameDocumentsService();

  
    useServiceDidSucceeded(uploadDocument.response, () => {  displayMessage('Document uploaded successfully.')});
    useServiceDidSucceeded(updateDocument.response, () => { displayMessage('Document updated successfully.')});
    useServiceDidSucceeded(removedDocument.response, () => {displayMessage('Document removed successfully.')});

    /*useServiceDidSucceeded(updateDocument.error, () => { displayMessage(updateDocument.error? "Document name must be unique":"")});
   useServiceDidSucceeded(uploadDocument.error, () => { displayMessage(uploadDocument.error?.message??"")});
   useServiceDidSucceeded(removedDocument.error, () => { displayMessage(removedDocument.error?.message??"")});*/

   // const [editedFile, setEditedFile] = useState({ name: '', path: '', extension: '' });


     useEffect(() => {
        ActionGetDocumentList.fetch({ contact_id: id, page: '1', renew: '1' });
     }, [updateDocument,uploadDocument,removedDocument]);

    const uploadFile = (data: any) => {
        const ext=data.filename?.split(/[#?]/)[0].split('.').pop().trim();
        ActionUploadDocument.fetch({
            name: data.file.filename, path: data.url, extension: ext, contact_id: id,version:""
        })
        return true;
    }


   
    const handleOpenEditpopup = (data?:ListDocumentsApi.IDocumentData) => {
        toggleOpenEditPopup(data?true:false)
        data&& setData(data)
    }
    
    const removeDocuments = confirmation("Do you want to delete document?",(document_id:string) => {
        ActionRemovedocument.fetch({
            contact_id:id,
            document_id:document_id
        })
        
    })

   const handleUpdateDocument=()=>{

    data&&  ActionUpdateDocument.fetch({
        contact_id:id,
        document_id:data.id,
        name:data.name
    })
    toggleOpenEditPopup(false);

   }  
   const ContactDocumentPlatform= useResponsive(ContactDocumentsScreenDesktop, ContactDocumentsScreenApp);
   return <ContactDetailsTemplate selectedMenu='Documents'  onSwipeRefresh={() => doReload(reload + 1)}>
            <ContactDocumentPlatform data={documentsList.response?.list??[]} setSelectedData={setData} isEditPopupShow={isOpen} selectedData={data} loading={documentsList.loading} update={handleUpdateDocument} remove={removeDocuments} openEdit={handleOpenEditpopup} uploadFile={uploadFile} key={reload}/>
        </ContactDetailsTemplate>

}


