import { IContactSectionAppProps,IContactDataApp,IEmailDataApp,IPhoneDataApp,ILinkDataApp } from './ContactSection.def';
import './ContactSection.scss';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import {  IonItem,IonIcon, IonText,IonToggle,IonSelect ,IonSelectOption} from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useFetchLookupsService } from 'services/general/lookups';
import { ifElse, switchCase } from 'scripts/helpers/general';
import { hasPhoneExtension } from 'services/contacts/createContact';
import { PropsWithChildren } from 'react';
            
export const ContactSection = (props:IContactSectionAppProps): JSX.Element => {
    const { items,handleAddNew,setContact } = props;
    const isEmailList = !!items.emails?.length;
    const isPhonesList = !!items.phones?.length;
    const isLinksList = !!items.links?.length;
    const [, _notifyChange] = useValidateConsumer(props.validate, validate);

      const fieldCheck =(item: IEmailDataApp | IPhoneDataApp | ILinkDataApp, isEmail:boolean =false ,isPhone:boolean=false, isLink:boolean = false):string  => {
        //const fieldEmptyMsg='The field should contain unique valid email, min 5 max 60 characters (user@har.com)'
          const incorrectEmailMsg='This field should contain min 8 max 55 characters, qw@qw.co';
          const incorrectPhoneMsg='This field should contain min 3 max 15 digits';
          const incorrectLinkMsg='This field should contain URL with min 2 max 200 characters';

        const value = item?.value?.replaceAll("  ","")
        if (!value || value === '') {
          return 'The field is required.';
          //return isPhone?incorrectPhoneMsg:incorrectEmailMsg
        }

        if(isPhone&&(value.length < 3 ||value.length > 25)) { return incorrectPhoneMsg; }
        
        const regexEmail=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(isEmail&&!regexEmail.test(value)){
          return incorrectEmailMsg
        }
        const phoneNumberLength = value.replace(/[^0-9]/g,"").length

        if (isPhone && (value.length>25|| phoneNumberLength>15 ) ) {
          return incorrectPhoneMsg
        }

        const regexLink=/^\b(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]$/
        if (isLink&&!regexLink.test(value)) {
         return incorrectLinkMsg
       }

      return '';
    };

  function validate() {
    var isValidate1,isValidate2,isValidate3=false
    const form:IContactDataApp={emails:[],phones:[],links:[]};

    const validatePhone = (item:IPhoneDataApp): Pick<IPhoneDataApp, 'error' | 'errorField'> =>
    {
      // Validating Phone
      const phone_type = item.phone_type || 'N';
      const isFilled = !!item.value.trim() || phone_type !== 'N' || item.phone_extension !== undefined;
      const phoneError = isFilled? fieldCheck(item,false,true): '';
      if(phoneError) { return { error:phoneError, errorField:'phone' }; }

      // Validating Extension
      if(item.phone_extension && hasPhoneExtension(phone_type) && item.phone_extension > 999999) {
        return { error:'This field should contain max 6 digits', errorField:'ext' };
      }

      return { error:'' };
    }

       items.emails.map((item:IEmailDataApp)=>{
        const isError= !!item.value.trim()? fieldCheck(item,true): '';
        form.emails.push({
              value: item.value,
              error: isError,
              preferred:item.preferred,
              importEmail:item.importEmail
            });
            
        });
        items.phones.map((item:IPhoneDataApp)=>{
          const { error, errorField } = validatePhone(item);
          form.phones.push({
              value: item.value,
              //error:(ind===0)?(items.phones[0]?.value.length??0)>0||item.phone_type!=="N"? fieldCheck(item,false,true):"":fieldCheck(item,false,true),
              error,
              errorField,
              preferred:item.preferred,
              phone_type: item.phone_type,
              phone_extension: item.phone_extension
            });
        });
       items.links.map((item:ILinkDataApp)=>{
       form.links.push({
            value: item.value,
            error: !!item.value.trim()? fieldCheck(item, false, false, true): '',
            link_type: item.link_type
          });
      });
      isValidate1= Object.entries(form.emails).every(([, value]) => value.error==='');
      isValidate2= Object.entries(form.phones).every(([, value]) => value.error==='');
      isValidate3= Object.entries(form.links).every(([, value]) => value.error==='');
       setContact(form);
       
    return isValidate1&&isValidate2&&isValidate3
  }


 

    return (<>
        <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Contact</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">
            {isEmailList && items?.emails.map((item,key)=>
            <FormView preferred isImportEmail key={key} index={key} itemsLength={items.emails.length} selectedItem={item} name="emails" placeHolder="Email"  {...props} />)}
            {items.emails.length<10&&<div id="email" className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("emails")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Email</div>
            </div>} 
         </div>
           <div className="form_sec secpadding_left secpadding_right">
            {isPhonesList && items?.phones.map((item,key)=>
            <FormView preferred key={key} index={key} itemsLength={items.phones.length} selectedItem={item} name="phones" placeHolder="Phone" {...props} />)}
            {items.phones.length<10&&<div className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("phones")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Phone</div>
            </div>} 
         </div>
             <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Links</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">          
            {isLinksList && items?.links.map((item,key)=>
             <FormView key={key} index={key} itemsLength={items.links.length}  selectedItem={item} name="links" placeHolder="https://www…"  {...props} />)}
            {items.links.length<10&&<div className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("links")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Link</div>
            </div>} 
         </div>
     </>)
                               
}

const FormView = (props: IContactSectionAppProps.FormViewProps): JSX.Element => {
  const [lookupData] = useFetchLookupsService(['phone_type']);
  const showPhoneExt = hasPhoneExtension(props.selectedItem?.phone_type);

const {handleRemoveContact,index,handleChangeField,name,placeHolder,preferred,/*isImportEmail,*/itemsLength}=props;

    // HACK: errorField based on the name
    const mainErrorField = name.slice(0, -1);

    return ( <div className="border-bottom">
                <div className="row no-gutters">
                    <div className="col-auto align-self-center">
                        <div className="delete_item d-flex">
                            <img onClick={()=>handleRemoveContact(name,index)}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
                           {name==="links"&& <><div className="delete_item_value">Link. {index+1} </div>
                            <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" /></>}
                       </div>
                    </div>
                    <div className="col">
                    {name==="phones"&& <IonItem>
                      <IonSelect value={props.selectedItem?.phone_type} name='phone_type' placeholder="Phone Type" onIonChange={(e) => handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''),props.selectedItem?.phone_extension)} defaultValue="None">
                      {lookupData.response?.phone_type.items.map((x, index) => (
                        <IonSelectOption key={index} value={x.short_value}>{x.long_value}</IonSelectOption>
                                ))}
                        
                      </IonSelect>
                    </IonItem>}
                  
                   {name==="links"&&
                    <Textbox value={props.selectedItem?.link_type} placeholder='e.g. Facebook profile' name={name}  maxlength={40} error={props.selectedItem?.error} onIonChange={(e)=> handleChangeField(index,e.target.name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,props.selectedItem?.phone_extension,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}/>}
                   
                   <MainFieldContainer field={name}>
                    <Textbox
                      type={"text"}
                      value={props.selectedItem?.value}
                      name={name}
                      placeholder={placeHolder}
                      maxlength={switchCase(name, 'links', 200, 'emails', 55, 25)}
                      className={name === 'phones'? 'form-control': ''}
                      containerClassName={name==="phones" ? ifElse(showPhoneExt, "col-9 pr-0", "col-12 pr-0"): ""}
                      id={placeHolder}
                      aria-describedby={placeHolder}
                      error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === mainErrorField: props.selectedItem?.error}
                      onIonChange={(e) => handleChangeField(index,e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''),props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,props.selectedItem?.phone_extension,props.selectedItem?.link_type)} >
                    </Textbox>
                    {name==="phones" && showPhoneExt && <div className="col-3 pl-0">
                      <Textbox
                        type="number"
                        className="form-control"
                        aria-describedby="Ext"
                        placeholder="Ext"
                        value={props.selectedItem?.phone_extension}
                        min={1}
                        max={999999}
                        maxlength={6}
                        error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === 'ext': undefined}
                        onIonChange={(e) => handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,props.selectedItem?.importEmail,props.selectedItem?.phone_type,e.target.value ?? '')}
                        ionItemClassName='ion-no-padding'
                      />
                    </div>}
                    {props.selectedItem?.errorField && props.selectedItem?.error && <div className="col">
                      <div className='color_sold ml-3'>{props.selectedItem.error}</div>
                    </div>}
                  </MainFieldContainer>

                  <IonIcon></IonIcon>
                  </div>
              </div>
              <div className="row no-gutters">
                   {preferred&& <div className="col">
                      <div className="addable_item pl-1 pb-3 pt-1" >                              
                          <div className="pl-5 ml-2 align-self-center pr-3">Make it preferred</div>
                        <IonToggle disabled={itemsLength<2?true:false} checked={itemsLength<2?true:props.selectedItem?.preferred} onIonChange={()=>handleChangeField(index,name,props.selectedItem?.value??"",!props.selectedItem?.preferred,props.selectedItem?.importEmail)}></IonToggle>
                      </div>
                   </div>}
                    {/*isImportEmail&&<div className="col">
                    <div className="addable_item pb-3 pt-1" >                              
                        <div className="pl-4 align-self-center">Import emails</div>
                      <IonToggle  checked={props.selectedItem?.importEmail} onIonChange={()=>handleChangeField(index,name,props.selectedItem?.value??"",props.selectedItem?.preferred,!props.selectedItem?.importEmail)} ></IonToggle>
                    </div>
</div>*/}
              </div>
        </div>
        
    )
}

function MainFieldContainer(props:PropsWithChildren<Record<'field', string>>)
{
  const { field } = props;
  if(field === 'phones') { return <div className='row small-gutters'>{props.children}</div>; }
  return <>{props.children}</>;
}