import { IEmailList } from 'services/emails/searchEmails';
import { IEmailListProps, IEmailListViewTypeProps } from './EmailList.def';
import './EmailList.scss';
import { EmailCard, EmailCardInDraft, EmailCardInForwarded, EmailCardInSent } from '../EmailCard';
import { ShapelessButton } from 'views/forms/buttons/Button';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';

export const EmailListApp = (props: IEmailListProps): JSX.Element => {
    const { loading, type, editMode, page, connectionStatus, hideHeader, selectionCount, remove, mark, setCustomFooter } = props;
    const [selection, setSelection] = useState<string[]>([])
    const [_showButtons, setShowButtons] = useState(false)
    let history = useHistory()

    const selectEmail = (id: string) => {
        if (selection.includes(id)) {
            let arr = selection.filter((email) => email !== id)
            setSelection(arr)
        } else {
            if (selection.length === 0) {
                setShowButtons(true)
            }
            setSelection([...selection, id])
        }

    }

    useEffect(() => {
        selectionCount?.(selection.length)
        if (selection.length > 0) {
            setCustomFooter?.(<ActionButtons />)

        } else {
            setCustomFooter?.(undefined)
        }


    }, [selection])

    useEffect(() => {
        if (editMode === false) {
            setSelection([])

        } else {
            setShowButtons(true)
        }

    }, [editMode])

    useEffect(() => {
        setSelection([])
        setCustomFooter?.(<ActionButtons />)
    }, [type])


    const ActionButtons = () => {
        return (
            <div className="row p-1 opacity-86pr">
                {(type === 'inbox' || type === 'lead') &&
                    <>
                        <ShapelessButton size='small' disabled={false} ><span className="font_size--large align_self_center" onClick={() => {
                            mark?.(selection ? selection : [], 1)
                            setSelection([])
                        }}>Mark as read</span></ShapelessButton>

                        <ShapelessButton size='small' disabled={false} ><span className="font_size--large align_self_center" onClick={() => {
                            mark?.(selection ? selection : [], 0)
                            setSelection([])
                        }}>Mark as unread</span></ShapelessButton>
                    </>
                }
                <ShapelessButton size='small' disabled={false} clickConfirmation="Are you sure you want to delete?" onClick={() => {
                    setSelection([])
                    remove?.(selection ? selection : [])
                }}><span className="font_size--large">Delete</span></ShapelessButton>
            </div>
        );
    }

    return (
        <>

            {connectionStatus === false &&
                <div className="row">
                    <div className="col-12"><div className="border_radius--default d-inline-block mb-2 bg_color_pending_light p-3 font_size--medium">
                        You have been disconnected from your email account. Please connect <a className='cursor--hand' onClick={() => history.push('/settings/account')}>now</a> in order to start sending emails.
                    </div>
                    </div>
                </div>
            }
            <div className={`${!hideHeader ? `mb-5 mb-md-0` : ``}`}>
                {loading && page && page < 2 ?
                    <Loading />
                    :
                    <div className="pt-4 pb-0 emails_list">
                        <>
                            {(type === 'inbox' || type === 'sent' || type === 'forwarded' || type === 'lead') &&
                                <ListViewWithLabel {...props} selected={selection ? selection : []} selectEmail={selectEmail} editMode={editMode} />
                            }

                            {type === 'draft' &&
                                <ListView {...props} selected={selection} selectEmail={selectEmail} editMode={editMode} />
                            }
                        </>
                    </div>
                }
            </div>
        </>
    );
}

const Loading = () => {
    const LoadingField = () => {
        return (
            <div className="card--email cursor--hand ">
                <div className="card--email__checkbox">
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                </div>
                <div className="card--email__agent"><div className="loading__photo mr-3 w-40px h-40px"></div></div>
                <div className="card--emaill__content overflow-hidden w-100">
                    <div className="card--email__content_heading d-flex">
                        {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                        <div className="mr-2 loading__labels mr-2 w-52px"></div>
                        <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                    </div>
                    <div className="card--email__text text-truncate">
                        <div className="loading__text_small w-100"></div>
                    </div>
                    <div className="loading__text_smaller w-85px"></div>
                </div>
            </div>

        )
    }

    const rows: number[] = [];
    for (let i = 0; i < 10; i++) {
        rows[i] = i
    }
    return (
        <div className="pb-0 emails_list">
            <div className="loading__rounded ml-3 mb-2 mt-4 w-88px h-26px"></div>
            <div className="border-bottom">
                {
                    rows.map((index: number) =>
                        <LoadingField key={index} />
                    )
                }
            </div>
        </div>
    );

}

const ListViewWithLabel = (props: IEmailListProps | IEmailListViewTypeProps) => {
    const { id, list, loading, type, editMode, page, totalPages, readList, loadMore, setPreview, redirect } = props as IEmailListProps;
    const { selected, selectEmail } = props as IEmailListViewTypeProps;
    const history = useHistory()

    function labels(list: IEmailList[]) {
        const groups = list.reduce(
            (groups, item: IEmailList) => {
                const key = item?.date_tag
                if (!groups[key]) { groups[key] = { label: key, list: [] }; }
                groups[key]!.list.push(item);
                return groups;

            },
            {} as Record<string, { label: string, list: IEmailList[] }>
        );
        return Object.values(groups)
    }
    function goto(id: string) {
        if (redirect) {
            redirect(id)
        } else {
            history.push(`/emails/` + type + '/' + id)
        }
    }

    return (
        <>
            {!loading && list?.length === 0 &&
                <Empty />
            }
            {list &&
                <>
                    {labels(list)?.map((data: any, ind: number) =>
                        <div key={ind} >
                            <div className={`title__label ml-3 pb-3 ${ind !== 0 && `mt-4`}`}> {data.label}</div>
                            {data.list?.map((email: IEmailList, index: number) =>
                                <div key={index + email.id} className="border-bottom" >
                                    {(type === 'inbox' || type === 'lead') &&
                                        <EmailCard
                                            email={email}
                                            type={type}
                                            id={id}
                                            label={data.label}
                                            selected={selected}
                                            readList={readList}
                                            redirect={goto}
                                            toggleCheckbox={selectEmail}
                                            setPreview={setPreview}
                                            hideCheckbox={!editMode} />
                                    }

                                    {type === 'sent' &&
                                        <EmailCardInSent
                                            email={email}
                                            type={type}
                                            id={id}
                                            redirect={goto}
                                            label={data.label}
                                            selected={selected}
                                            toggleCheckbox={selectEmail}
                                            setPreview={setPreview}
                                            hideCheckbox={!editMode} />
                                    }

                                    {type === 'forwarded' &&
                                        <EmailCardInForwarded
                                            email={email}
                                            type={type}
                                            id={id}
                                            redirect={goto}
                                            label={data.label}
                                            selected={selected}
                                            toggleCheckbox={selectEmail}
                                            hideCheckbox={!editMode} />
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </>
            }
            {!loading && totalPages && page && Number(totalPages) > page &&
                <IonInfiniteScroll threshold='10%'
                    onIonInfinite={(ev) => {
                        setTimeout(() => {
                            ev.target.complete()
                            loadMore?.(page + 1);
                        }, 900);
                    }}><IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            }


        </>
    )

}

const ListView = (props: IEmailListProps | IEmailListViewTypeProps) => {
    const { id, list, loading, page, totalPages, updateList, loadMore, openMessage, update } = props as IEmailListProps;
    const { selected, selectEmail, editMode } = props as IEmailListViewTypeProps;

    function setUp(data: { item: any, isNew: boolean }) {
        let emailItem: IEmailList = {
            id: data?.item.id,
            subject: data?.item?.subject,
            first_name: "",
            middle_name: "",
            first_last_name: "",
            last_name: "",
            short_message: data?.item?.short_message,
            created_dt: data?.item?.created_dt,
            from_address: "",
            date_tag: data?.item?.date_tag,
            contact_id: "",
            display_name: "",
            photo_url: "",
            is_lead: "0",
            read: "0",
            message_uid: data?.item?.message_uid,
            recipients: [],
            recipients_cc: [],
            recipients_bcc: [],
        }
        update?.(data, emailItem)

    }

    useEffect(() => {
        if (updateList) {
            setUp(updateList)
        }
    }, [updateList])


    return (
        <div>
            <div className='pb-2'>
                {!loading && list?.length === 0 &&
                    <Empty />
                }
                {list && list?.map((email: IEmailList, index: number) =>
                    <div key={index} className='border-bottom'>
                        <EmailCardInDraft
                            email={email}
                            id={id}
                            redirect={() => openMessage?.(email.id)}
                            label={email.date_tag}
                            selected={selected}
                            toggleCheckbox={selectEmail}
                            hideCheckbox={!editMode} />
                    </div>


                )}
            </div>
            {!loading && totalPages && page && Number(totalPages) > page &&
                <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                        setTimeout(() => {
                            ev.target.complete()
                            loadMore?.(page + 1);
                        }, 900);
                    }}><IonInfiniteScrollContent loadingText="Please wait..." loadingSpinner="bubbles"></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            }
        </div>
    )

}

const Empty = () => {
    return (
        <div className="d-flex h-calc510">
            <div className="w-100 align-self-center ">
                <div className="text-center pt-md-5 opacity-64pr">
                    <div className="font_size--large pb-4 pt-2 font-weight-bold">You don’t have any emails</div>
                </div>
            </div>
        </div>
    )
}
