import { IContactsTypeaheadProps } from './ContactsTypeahead.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ContactsTypeaheadApp } from './ContactsTypeahead.app';
import { ContactsTypeaheadDesktop } from './ContactsTypeahead.desktop';

export const ContactsTypeahead = (props: IContactsTypeaheadProps): JSX.Element =>
{
    const ContactsTypeaheadPlatform = useResponsiveChoose(ContactsTypeaheadDesktop, ContactsTypeaheadApp);
    return (
        <ContactsTypeaheadPlatform {...props} />
    );
}
