import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { Button } from "views/forms/buttons/Button";
import { Textbox } from "views/forms/textboxes/Textbox";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper";
import { IAddTaskProps } from '../AddTaskPopUp.def';
import { useEffect, useState } from 'react';
import { IFormDataProps, defaultFormData, transformEditData } from '../AddTaskPopUp.def'
import { InputChangeEventDetail, IonInputCustomEvent, } from '@ionic/core';
import { useFetchLookupsService } from 'services/general/lookups';
import { FormLabel } from 'react-bootstrap';
import { DropDown } from 'views/forms/dropdowns/DropDown';
import { CreateCalendarTaskApi, useCreateCalendarTaskService } from 'services/calendar/createCalendarTask';
import { useUpdateCalendarTaskService } from 'services/calendar/updateCalendarTask';
import { cloneDeep } from 'lodash-es';
import { ContactsTextbox } from 'views/forms/textboxes/ContactsTextbox';
import {  useParams } from 'react-router';
import { fetchGetContactService } from 'services/contacts/contactDetails/getContact';
import { SearchContactsApi } from 'services/contacts/searchContacts';
import { RepeatCustomRecurrence } from './RepeatCustomRecurrence';
import { ListingsApi } from 'services/typeaheads/listings';
import { PropertyTypeahead } from 'views/forms/typeaheads/PropertyTypeahead';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useEventConfirmation } from 'scripts/hooks/forms';
import { IDateTimeTextboxProps } from 'views/forms/textboxes/DateTimeTextbox/DateTimeTextbox.def';
import { Avatar } from 'views/contacts/Avatar';
import {ChooseRecurringOptionPopup} from './ChooseRecurringOptionPopup/ChooseRecurringOptionPopup';
import { Tag } from 'scripts/ui/tag';
import { AlertDialog } from 'views/dialogs/AlertDialog';
import moment from 'moment';
import { addYears, compareAsc, format, parseISO, subYears } from 'date-fns';
import { MuiDateTimePicker } from 'views/forms/material/MuiDateTimePicker';
import { ContactTitle } from 'views/general/ContactTitle';
import { DropDownModal } from 'views/forms/dropdowns/DropDownModalMulti';
import { isEmpty } from 'scripts/helpers/texts';

export const TaskForm = (props: IAddTaskProps) => {
    const { id } = useParams<{ id: string }>();
    const { Div } = Tag;
    const { show, toggle, category, refreshData, taskDetail,openContactQuickOverviewPopup } = props
    const [createData, createActions] = useCreateCalendarTaskService();
    const [updateData, updateActions] = useUpdateCalendarTaskService();
    const [showRepeatCustomRecurrence,setShowRepeatCustomRecurrence]=useState(false);
    const [selectedRecurringOption,setRecurringOption]=useState("0");
    const [alertMessage,setAlertMessage]=useState("");
    const [showAddress, setShowAddress] = useState<string>('0')
    const [showRecurringPopup,setShowRecurringPopup]=useState(false);
    const [disableSave, toggleDisableSave] = useState(false)
    const addConfirm = useEventConfirmation();
    const isEditTask = !!taskDetail;

    //const history = useHistory();
    const maxCharacterLimit = 3000;
    const isEvent = category === "e";
    const maxDate = addYears(new Date(), 10)
    const minDate = subYears(new Date(), 2)
    const handleInitialData = () => {
        return isEditTask ? transformEditData(taskDetail) : cloneDeep(defaultFormData);
    }
    useEffect(()=>{if(id && !isEditTask ){handleGetContactByID(parseInt(id))}},[]);
    useEffect(() => { setFormData(handleInitialData); setCharacterCount(handleInitialData().description.length); 
        setShowAddress(isEmpty(taskDetail?.location)?"0":"1") }, [taskDetail]);
    
   const  handleGetContactByID=async(id:number)=>{
       const response=await fetchGetContactService({contact_id:id});
       if(!!!formData.contacts.list.length||formData.contacts.list.find((item)=>item.id!==id)) {
            setFormData({...formData,contacts:{list:[...formData.contacts.list,response],error:""}})
        }
    }
    const handleDescriptionChange = (e: any) => {
        const descriptionValue = e.target.value;

        // Update the description in formData
        setFormData({ ...formData, description: descriptionValue });

        // Update the character count
        setCharacterCount(descriptionValue.length);
    };
  
    useServiceDidSucceeded(createData, () => { displayMessage(isEvent?'Event created successfully.':'Task created successfully.', { onClose:onSuccess }); });
    useServiceDidSucceeded(updateData, () => { displayMessage(isEvent ? 'Event updated successfully.' : 'Task updated successfully.', { onClose: onSuccess }); });

    const [formData,setFormData]=useState<IFormDataProps>(handleInitialData);
    const [characterCount, setCharacterCount] = useState(formData.description.length);
    console.log("formData.property_full_street_address.value",formData.property_full_street_address.value)
    const lookups = useFetchLookupsService(["task_type", "reminder_minutes_prior", "task_priority","task_repeat"]);
    const handleCreateTask=formData.contacts.list.length==0?(isRecurring:boolean=false)=>createTask(isRecurring,false): addConfirm(`Do you want to notify ${formData.contacts.list.length??"0"} contacts?`,(isRecurring:boolean=false)=>{createTask(isRecurring,true);setFormData({ ...formData, notify_contacts:true});},(isRecurring:boolean=false)=>{createTask(isRecurring,false);setFormData({ ...formData, notify_contacts:false});})
    const createTask=async(isRecurring:boolean=false,isNotify:boolean=false)=>{
        toggleDisableSave(true);
        
        const property = formData.property_full_street_address.value;
        var formDataValue: CreateCalendarTaskApi.Request = {
            category: category,
            title: formData.title.value,
            property_harid: property.harid,
            property_listingid: property.listingid,
            property_for: property.for,
            location: property.full_address,
            start_dt: formData.start_dt.value ? parseISO(formData.start_dt.value) : undefined,
            start_time: formData.start_dt.value ? format(parseISO(formData.start_dt.value), "hh:mm a") : '',
            task_type: formData.task_type.value,
            contact_ids: formData.contacts.list.map((item) => item.id.toString()),
            priority: formData.priority,
            repeat: formData.repeat,
            notes: formData.description,
            completed: formData.completed,
            notify_contacts: isNotify,
            reminder_minutes_prior: formData.reminder_minutes_prior.toString(),
        }
        if (formData.repeat === "Custom") {
            formDataValue = {
                ...formDataValue,
                repeat_every_value: formData.repeat_every_value !== '' ? formData.repeat_every_value : "1",
                repeat_every_text: formData.repeat_every_text !== '' ? formData.repeat_every_text : "Daily",
                repeat_on_day: formData.repeat_on_day,
                repeat_end_on: formData.repeat_end_on,
                repeat_end_date: formData.repeat_end_date !== '' ? parseISO(formData.repeat_end_date) : undefined,
                repeat_month_option: formData.repeat_month_option
            }
        }
     
        if (formData.property_full_street_address.isManually) {
            formDataValue = {
                ...formDataValue,
                property_full_street_address: property.street_address,
                property_address2: property.address_2,
                property_city: property.city,
                property_country: property.country,
                property_state: property.state,
                property_zip: property.zip,
            }
        }
       

        if (validate()) {
            if (category === "e") {
                formDataValue = {
                    ...formDataValue,
                    end_dt: formData.end_dt.value ? parseISO(formData.end_dt.value) : undefined,
                    end_time: formData.start_dt.value ? format(parseISO(formData.end_dt.value), "hh:mm a") : '',
                }
            }
            if(isEditTask) {
                if(taskDetail?.is_recurrent== '1'&&selectedRecurringOption=='0'&& !isRecurring){ toggleDisableSave(false); return await setShowRecurringPopup(true); }else{
                    await updateActions.fetch({...formDataValue,task_id:taskDetail.id.toString(),option_id:taskDetail?.is_recurrent=='0'?'0':selectedRecurringOption});
                }
            } else {
                await createActions.fetch(formDataValue);
                    }
                        onSuccess();
                    }
                
        toggleDisableSave(false)
    }

    const validate = () => {
        //return false;
        var specialCharacterRegex = /^[a-zA-Z0-9\s!@#$%^&*()-_=+{}\[\]:;"'<>,.?/\\|]*$/;
        var isValidate = true
        if (formData.title.value.length < 1) {
            formData.title.error = "This field should contain min 1 character. max - infinity. Only Latin letters, numbers, symbols, and space can be added.";
            isValidate = false;
        }
        else if (!specialCharacterRegex.test(formData.title.value)) {
            formData.title.error = "This field should contain (Latin letters, numbers, symbols and space can be added)."
            isValidate = false;
        }
        if (formData.start_dt.value.length < 10 || !moment(formData.start_dt.value).isValid()) {
            formData.start_dt.error = "This field is required.";
            isValidate = false;
        }

        if (compareAsc(minDate, parseISO(formData.start_dt.value)) > 0) {
            formData.start_dt.error = "This field is invalid.";
            isValidate = false;
        }

        if (compareAsc(maxDate, parseISO(formData.start_dt.value)) < 0) {
            formData.start_dt.error = "This field is invalid.";
            isValidate = false;
        }

        if (formData.task_type.value.length < 2) {
            formData.task_type.error = "This field is required.";
            isValidate = false;
        }
        if (category === "e") {
            if (formData.end_dt.value.length < 10 || !moment(formData.end_dt.value).isValid()) {
                formData.end_dt.error = "This field is required.";
                isValidate = false;
            }
    
            if (compareAsc(minDate, parseISO(formData.end_dt.value)) > 0) {
                formData.end_dt.error = "This field is invalid.";
                isValidate = false;
            }
    
            if (compareAsc(maxDate, parseISO(formData.end_dt.value)) < 0) {
                formData.end_dt.error = "This field is invalid.";
                isValidate = false;
            }
            /*if (!moment(formData.end_dt.value).isValid()) {
                formData.end_dt.error = "This field is required.";
                isValidate = false;
            }

            if (compareAsc(parseISO(formData.start_dt.value), parseISO(formData.end_dt.value)) > 0) {
                formData.end_dt.error = "Ending time should not be earlier then starting time.";
                isValidate = false;
            }*/
        }
        {/**  if(formData.contacts.list.length===0){
                    formData.contacts.error="This field is required."   
                    isValidate=false; 
                }*/}
        if (showAddress === '1') {
            if (formData.property_full_street_address.isManually) {
                if (Object.values(formData.property_full_street_address.value).every(x => !x || !x.toString().trim()|| x.toString()=='0')) {
                    formData.property_full_street_address.error = "This field is required."
                    isValidate = false;
                }

            } else {
                if (formData.property_full_street_address.value.full_address?.length === 0) {
                    formData.property_full_street_address.error = "This field is required."
                    isValidate = false;
                }
            }
        }

        setFormData({ ...formData })
        return isValidate;
    }

    const onChangeHandler = (e: IonInputCustomEvent<InputChangeEventDetail> | React.ChangeEvent<any>): void => {
        setFormData({ ...formData, [e.target.name]: { value: e.target.value, error: "" } });
    }
    //DateTime Functions
    const onDateTimeChangeHandler = (e: IDateTimeTextboxProps.ValueChangeArgs): void => {
        setFormData({ ...formData, [e.name!]: { value: e.value, error: "" } });
    }

    const typeFilter = lookups[0].response?.task_type.items || [];
    //const reminder_minutes_prior=lookups[0].response?.reminder_minutes_prior.items||[];
    const reminderMinutesOptions = lookups[0].response?.reminder_minutes_prior.items;

    const priorityFilter = lookups[0].response?.task_priority.items || []
    const taskRepeatFilter = lookups[0].response?.task_repeat.items || []

    const removeContact = (value: SearchContactsApi.IContactSearchItem) => {
       if(parseInt(id)!=value.id){
            formData.contacts.list.splice(formData.contacts.list.indexOf(value), 1);
            const newValues = [...formData.contacts.list];
            setFormData({ ...formData, contacts: { list: newValues, error: false } });
        }
    }
    const title = category === "t" ? isEditTask ? "Edit Task " : "New Task " : isEditTask ? "Edit Event" : "New Event"
    const handleNoAssociatedAddress = () => {
        setShowAddress('0');
        setFormData({ ...formData, property_full_street_address: { value: ListingsApi.getInitialListingList(), isManually: false } });
    }
    const onSuccess = () => {
        setRecurringOption("");
        refreshData(true);
        setFormData(defaultFormData)
       // isEditTask && history.goBack();
       // onClose()
         toggle(false);       
    }
    const onClose = () => {
        props.onClose?.()
        setFormData(handleInitialData())
        setShowRepeatCustomRecurrence(false)
        toggle(false);
    
    }
    const addContacts = (item: any) => {
        formData.contacts.list.push(item[item.length - 1]);
       const array = formData.contacts.list.filter((v, i, a) => (a.findIndex(v2 => (v2.id === v.id&&v2.email === v.email)) === i));

        item.length > 0 &&
            setFormData({ ...formData, contacts: { list: array, error: false } });
    }
    return (
        <DefaultPopUp title={title}
            className="custom--modal custom--modal__fullheight custom--modal__addtask"
            isOpen={show} closeButton toggleShow={onClose}
            onDidDismiss={onClose}
        >
            <DefaultPopUp.Body>
                <div className="ion-padding bg-white">
                    <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-0">
                        <Textbox name="title" onIonChange={onChangeHandler} error={formData.title.error} value={formData.title.value} class="mb-4" label="Title" placeholder={`Give your ${category === "e" ? "event" : "task"} a title`} id="Title"></Textbox>
                        <div className="mb-4">
                            <FormLabel>Type</FormLabel>
                            <DropDown id="task_type" name='task_type' error={formData.task_type.error}
                                value={formData.task_type.value} className="custom-select custom-select_large" onChange={onChangeHandler}>
                                <option value="">Select a {category == "e" ? "event" : "task"} Type</option>
                                {typeFilter.map(item => <option key={item.short_value} value={item.short_value}>{item.long_value}</option>)}
                            </DropDown>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <MuiDateTimePicker
                                    label={category === "e" ? "Start Date" : "Date"}
                                    className='mb-4'
                                    onChange={(date: any) => onDateTimeChangeHandler({ value: format(date, "yyyy-MM-dd'T'HH:mm:ss"), name: 'start_dt' })}
                                    value={taskDetail?.start_dt}
                                    error={formData?.start_dt.error ? formData?.start_dt.error.toString() : ''}

                                />
                            </div>
                        </div>
                        {category === "e" &&
                            <div className='row'>
                                <div className="col-12">
                                    <MuiDateTimePicker
                                        label={category === "e" ? "End Date" : "Date"}
                                        className='mb-4'
                                        onChange={(date: any) => onDateTimeChangeHandler({ value: format(date, "yyyy-MM-dd'T'HH:mm:ss"), name: 'end_dt' })}
                                        value={taskDetail?.end_dt}
                                        error={formData?.end_dt.error ? formData?.end_dt.error.toString() : ''}
                                        disabled={formData.start_dt.value ? false : true}
                                        minDate={formData.start_dt.value ? parseISO(formData.start_dt.value) : undefined}
                                    />
                                </div>
                            </div>
                        }

                        <div className={`row ${showAddress === '0' && `mb-4`}`}>
                            <div className="col-auto font_weight--bold font_size--medium align-self-center">Is it associated with a property?</div>
                            <div className="col-auto">
                                <div role="radiogroup" defaultValue={showAddress}>
                                    <div role="radio" className="custom-control custom-radio custom-radio custom-control-inline mt-3">
                                        <input type="radio" id="radio_checkbox" name="radio_checkbox" className="custom-control-input" checked={showAddress === '1'} onChange={() => setShowAddress('1')} />
                                        <label className="custom-control-label" htmlFor="radio_checkbox">Yes</label>
                                    </div>
                                    <div role="radio" className="custom-control custom-radio custom-radio custom-control-inline">
                                        <input type="radio" id="radio_checkbox2" name="radio_checkbox" className="custom-control-input" checked={showAddress === '0'} onChange={handleNoAssociatedAddress} //defaultChecked={true} 
                                        />
                                        <label className="custom-control-label" htmlFor="radio_checkbox2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAddress === '1' && <div className="mb-4">
                            <div className="">
                                <PropertyTypeahead className={`${formData.property_full_street_address.error ? `is-invalid` : ``}`}
                                    initialValues={!Object.values(formData.property_full_street_address.value).every(x => !x || !x.toString().trim()|| x.toString()=='0') ? [formData.property_full_street_address.value] : []}
                                    change={(item) => setFormData({ ...formData, property_full_street_address: { value: item[0] || ListingsApi.getInitialListingList(), error: false, isManually: false } })}
                                    remove={() => setFormData({ ...formData, property_full_street_address: { value: ListingsApi.getInitialListingList(), error: false, isManually: false } })}
                                    update={(item) => { console.log("data",item);setFormData({ ...formData, property_full_street_address: { value: item[0] || ListingsApi.getInitialListingList(), error: false, isManually: item[0] ? true : false } })}}
                                    showingCountry={true}
                                    minLength={1}
                                />
                                {!!formData.property_full_street_address.error &&
                                    <div className='mt-2 py-1 px-2 error_custom' >{formData.property_full_street_address.error}</div>
                                }
                            </div>
                        </div>}
                        <div className="mb-4">
                            <label className="mb-2">Contacts</label>
                            <div className='typeaheader_absolute'>
                                <ContactsTextbox initialContacts={formData.contacts.list} placeholder="Contacts" onContactsChange={addContacts} maxContacts={20}  minLength={1}/>
                            </div>
                           {/**{!!formData.contacts.error&&<div className='mt-2 py-1 px-2'>{formData.contacts.error}</div>}*/}
                            {formData.contacts.list.length>0&&
                                <div className="mt-3" >
                                <Swiper
                                    slidesPerView={5}
                                    spaceBetween={12}
                                    slidesPerGroup={1} 
                                    loop={false}
                                    loopFillGroupWithBlank={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    slideToClickedSlide={true} 
                                    className="owners_desktop"
                                >
                                   {formData.contacts.list.map((item,ind)=> <SwiperSlide key={ind}>
                                        <div onClick={() => (item.deleted=='0' &&item.id!='0')  && openContactQuickOverviewPopup?.(item.id)} className="agent_signature agent_signature__inline">
                                            <Div className="agent_signature__photo css_vars_test" cssVars={{ agentAvatarPhoto:`url(${item.photo_url})` }}>
                                                 <Avatar id={item?.id} firstName={item?.first_name} lastName={item?.last_name} photo={item?.photo_url} ></Avatar>
                                                 {item.id!=parseInt(id)&& <div onClick={()=>removeContact(item)} className="agent_signature__photo_cross"><img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" /></div>}
                                            </Div>
                                          
                                          <div className="agent_signature__text"> <ContactTitle contact={item} />{`${item.deleted=="1"?`(deleted)`:""}`}</div> 
                                        </div>
                                    </SwiperSlide>)}
                                </Swiper> 
                                </div>}
                                <div className='mb-4 pb-1'>
                           
                        </div>
                       
                        </div>
                        <div className='mb-4 pb-1 d-none'>
                            <div role="checkbox" className="custom-control custom-checkbox">
                                <input type="checkbox" id="Stages_1" name="completed" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="Stages_1"><span className="color_auxiliary">Notify “3” Contacts</span></label>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="mb-2">Priority</label>
                            <ToggleButtonGroup className="d-flex btn-group btn-group-toggle btn-group--priority" type="radio" name="options" value={formData.priority}>
                                {priorityFilter.map((item, ind) => <ToggleButton key={ind}
                                    onClick={() => setFormData({ ...formData, priority: item.short_value })}
                                    className={`btn--medium btn btn--${item.long_value.toLowerCase()}`}
                                    id={item.long_value.toLowerCase()}
                                    value={item.short_value} >

                                    {item.long_value}
                                </ToggleButton>)}
                            </ToggleButtonGroup>
                        </div>
                        <div className="mb-4">
                            <div className="row">
                                <div className="col-6">
                                    <label className="mb-2">Reminder</label>
                                    <DropDownModal placeHolder="Reminder" label='Reminder'  customClassName='custom-select custom-select_large'   values={formData.reminder_minutes_prior??[]} handleSave={(e)=> setFormData({...formData,reminder_minutes_prior:e})} optionsValues={reminderMinutesOptions}></DropDownModal>
                                </div>
                                <div className="col-6">
                                    <label className="mb-2">Repeat</label>
                                    <select value={formData.repeat} onClick={() => formData.repeat === 'Custom' &&
                                        setShowRepeatCustomRecurrence(true)} onChange={(e) => {
                                            if (e.target.value === 'Custom') {
                                                if(formData.start_dt.value===""){           
                                                    setAlertMessage('Please choose task date.', ); 
                                           }else{
                                                setShowRepeatCustomRecurrence(true);
                                                setFormData({ ...formData, repeat: "Custom" })
                                                }
                                            }
                                            else {
                                                setFormData({ ...formData, repeat: e.target.value });
                                            }
                                        }}
                                        className="custom-select custom-select_large">
                                        <option value="">Do not repeat</option>
                                        {taskRepeatFilter.map((item,ind)=><option key={ind} value={item.short_value}>{item.long_value}</option>)}
                                    </select>

                                </div>


                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="mb-2">Description</label>
                            <textarea onChange={handleDescriptionChange} name="description" maxLength={3000} value={formData.description} className="form-control" placeholder="Type some description, if you want…"></textarea>
                            <p className=' font_weight--bold'> Character Count: {characterCount} / {maxCharacterLimit} </p>
                        </div>
                    </div>
                </div>
            <RepeatCustomRecurrence formData={formData} setFormData={setFormData} show={showRepeatCustomRecurrence} toggle={setShowRepeatCustomRecurrence} />
            <ChooseRecurringOptionPopup handleTaskUpdate={()=>createTask(true,formData.notify_contacts)} selectedRecurringOption={selectedRecurringOption} setRecurringOption={setRecurringOption} showRecurringPopup={showRecurringPopup} setShowRecurringPopup={setShowRecurringPopup} />
            <AlertDialog setSuccessMsg={setAlertMessage} message={alertMessage} />
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="pl-2 pr-2 pt-2">
                    <div className="row no-gutters">
                        <div className="col-4">
                            <div className="ml-4 pt-2 w-200px" >
                        <div role="checkbox" className="custom-control custom-checkbox pt-2">
                            <input type="checkbox" checked={formData.completed} onChange={() => setFormData({ ...formData, completed: !formData.completed })} id="Stages_1" name="completed" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="Stages_1"><span className="color_auxiliary font_weight--bold">Mark as Complete</span></label>
                        </div>
                            </div>
                        </div>
                      
                        <div className="col-4 text-center">
                            <Button disabled={disableSave} onClick={()=>validate()&&handleCreateTask(false)} kind="primary" className="btn--medium" loading={updateData.loading || createData.loading}>Submit</Button>
                        </div>
                        <div className="col-4 text-right">
                            <div >
                                <Button onClick={() => toggle(false)} kind="shapeless" className="btn--medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp>
    );
}