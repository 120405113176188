import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import './ContactEmailScreen.scss';
import { IonSearchbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { AlertDialog } from 'views/dialogs/AlertDialog';
import { EmailList } from 'views/emails/EmailList';
import { EmailDetail } from 'views/emails/EmailDetail';
import { EmailDetailsApi } from 'services/emails/emailDetails';
import { NewEmail } from 'views/emails/popups/NewEmail';

import { IEmailLandingScreenProps } from 'views/screens/emails/EmailLandingScreen/EmailLandingScreen.def';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';

export const ContactEmailScreenApp = (props: IEmailLandingScreenProps): JSX.Element => {
    const { list, id, type, loading, mails, page, member, readList, contactId, loadMore, search, remove, mark, update, reload, redirect, setCounter } = props
    const [isManage,] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [, setSelectionLength] = useState(0)
    const [newEmailShow, setNewEmailShow] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>()
    const [messageId, setMessageId] = useState("")
    const [cid, setCid] = useState('')
    const [openPreview, setPreview] = useState(false)
    const [emailItem, setEmailItem] = useState<{ item: any, isNew: boolean } | undefined>()
    const [action, setAction] = useState<'reply' | 'forward' | 'draft' | "">("")
    const [timer, setTimer] = useState<number | string>()

    const startWriting = (mid?: string, action?: 'reply' | 'forward' | "draft" | "") => {
        if (mid) {
            setMessageId(mid)
        } else {
            setMessageId("")
        }
        setAction(action ? action : '')
        setNewEmailShow(true)
        if (contactId) {
            setCid(contactId)
        }
    }

    const doSearch = (key: string) => {
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(setTimeout(() => {
            search?.(key)
        }, 512))
    }

    const forwardDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "forward")
    }


    const replyDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "reply")
    }

    useEffect(() => {
        if (member && typeof member?.provider_status !== 'undefined') {
            setConnectionStatus(member.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [member])



    return (

        <ContactDetailsTemplate selectedMenu='Emails' onSwipeRefresh={props.reload}>


            <NewEmail
                show={newEmailShow}
                onClose={() => {
                    setNewEmailShow(false);
                    setMessageId("")
                    setAction("")
                    setCid("")
                }}
                changePreview={(item?: any, isNew?: boolean) => {
                    if (item) {
                        setEmailItem({ 'item': item, 'isNew': isNew ? isNew : false })
                    }
                }}
                provider_email={member?.provider_email}
                messageId={messageId}
                connectionStatus={connectionStatus}
                action={action}
                contactId={cid}
                reload={reload}
                type={type}
                title={type === 'draft' && messageId !== '0' ? 'Draft' : 'New Email'}

            />

            <div>
                <IonSearchbar className="custom ion-no-padding ion-padding-start ion-padding-end mt-0 mb-0 search_email" placeholder="Search emails" onIonInput={(e: any) => doSearch(e.target.value.trim())}></IonSearchbar>

                <div className="action_bar">
                    <div>{mails?.total} emails</div>
                    <div className="action_bar__link ml-auto">
                        {member && connectionStatus &&
                            <a className='cursor--hand font_size--small' onClick={() => startWriting("", "")}>+New Email</a>
                        }
                    </div>
                </div>


                {member && typeof connectionStatus !== "undefined" &&
                    <div>
                        <EmailList
                            list={list}
                            type={type}
                            id={id}
                            loading={loading}
                            editMode={isManage}
                            page={page}
                            totalPages={mails?.total_pages}
                            updateList={emailItem}
                            readList={readList}
                            connectionStatus={connectionStatus}
                            loadMore={loadMore}
                            redirect={redirect}
                            selectionCount={(id) => setSelectionLength(id)}
                            remove={remove}
                            mark={mark}
                            setPreview={setPreview}
                            openMessage={(Id: string) => {
                                startWriting(Id, "draft")
                            }}
                            update={update}
                        />
                        <EmailDetail
                            type={type}
                            id={id}
                            mails={mails}
                            preview={openPreview}
                            setPreview={setPreview}
                            createDraft={(mail: EmailDetailsApi.Response, isReply: boolean) => {
                                if (isReply) {
                                    replyDraft(mail)
                                } else {
                                    forwardDraft(mail)
                                }

                            }}
                            connectionStatus={connectionStatus}
                            openMessage={(Id: string) => {
                                startWriting(Id, "reply")
                            }}
                            setCounter={setCounter}
                        />
                        <AlertDialog message={alertMessage} setSuccessMsg={setAlertMessage} />
                    </div>
                }

                {member && typeof connectionStatus === 'undefined' &&
                    <NoConnectionBox noBorder={true} />

                }

                <AlertDialog message={alertMessage} setSuccessMsg={setAlertMessage} />
            </div>
        </ContactDetailsTemplate >
    );
}

