import { Avatar } from 'views/contacts/Avatar';
import { IEmailCardProps, IMultipleRecipientsPopoverProps } from './EmailCard.def';
import './EmailCard.scss';
import { SimpleCheckbox } from 'views/forms/checkboxes/SimpleCheckbox';
import { getDayNumber } from 'scripts/helpers/dates';
import { getMonthName, strToDate } from 'scripts/helpers/number';
import { parseISO, format, getMonth } from 'date-fns'


// const getContactOverviewUrl = (contactId: string) => `/contacts/${contactId}/overview`;


export const EmailCard = (props: IEmailCardProps): JSX.Element => {
    const { id, email, label, hideCheckbox, selected, readList, toggleCheckbox, setPreview, redirect } = props

    return (
        <div className={`card--email cursor--hand${id === email.id || selected?.includes(email.id) ? ` selected` : ``}`} >
            {!hideCheckbox &&
                <div className="card--email__checkbox" onClick={() => toggleCheckbox?.(email.id)}>
                    <SimpleCheckbox checked={selected?.includes(email.id) ? true : false} />
                </div>
            }
            <div className=' text-truncate w-100 d-flex' onClick={() => (id && id === email.id) ? setPreview?.(true) : redirect?.(email.id)}>
                <Avatar
                    id={email.contact_id}
                    firstName={email.first_name}
                    lastName={email.last_name}
                    photo={email.photo_url}
                    responsive={false}
                />


                <div className="card--emaill__content  text-truncate" >
                    <div className="card--email__content_heading ">
                        <div className='d-flex'>
                            {email.read === "0" && !readList?.includes(email.id) &&
                                <div className="status_circle mr-2 mt-2"></div>
                            }
                            {email.is_lead === "1" &&
                                <div className="mr-2 label_email label_email--lead">Lead</div>
                            }
                            {email?.folder === 'Forwarded' &&
                                <div className="mr-2 label_email label_email--forwarded">Forwarded</div>
                            }
                            {email.first_last_name || email.from_address}
                            {email.recipients.length > 1 && <MultipleRecipientsPopover recipients={email.recipients} />}
                        </div>
                    </div>
                    <div className={`email__content_heading  text-truncate ${email.subject ? `font-weight_500` : `font_weight--normal`}`}>{email.subject ? email.subject : 'No subject'}</div>
                    <div className="card--email__text text-truncate">{email.short_message?.replace(/<[^>]+>/g, '')}</div>
                    <div className="card--email__date text-uppercase" > {label?.toLowerCase() === 'older' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : label + ' - ' + format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>

                </div>
            </div>
        </div>
    );
}


export const EmailCardInSent = (props: IEmailCardProps): JSX.Element => {
    const { id, email, type, label, hideCheckbox, selected, toggleCheckbox, setPreview, redirect } = props

    return (
        <div className={`card--email cursor--hand${id === email.id || selected?.includes(email.id) ? ` selected` : ``}`} >
            {!hideCheckbox &&
                <div className="card--email__checkbox" onClick={() => toggleCheckbox?.(email.id)}>
                    <SimpleCheckbox checked={selected?.includes(email.id) ? true : false} id="" />
                </div>
            }
            <div className='text-truncate w-100 d-flex' onClick={() => (id && id === email.id) ? setPreview?.(true) : redirect?.(email.id)}>

                {email.recipients[0] &&
                    <Avatar
                        id={email.recipients[0].id}
                        firstName={email.recipients[0].first_name}
                        lastName={email.recipients[0].last_name}
                        photo={email.recipients[0].photo_url}
                        responsive={false}
                        link={`/emails/` + type + '/' + email.id}
                    />
                }


                <div className="card--emaill__content text-truncate">
                    <div className="card--email__content_heading">
                        <div className='d-flex'>
                            {email.recipients[0] && (email.recipients[0].first_last_name || email.recipients[0].email)}
                            {email.recipients.length > 1 && <MultipleRecipientsPopover recipients={email.recipients} />}
                        </div>
                    </div>
                    <div className={`email__content_heading text-truncate  ${email.subject ? `font-weight_500` : `font_weight--normal`}`}>{email.subject ? email.subject : 'No Subject'}</div>
                    <div className="card--email__text text-truncate">{email.short_message?.replace(/<[^>]+>/g, '')}</div>
                    <div className="card--email__date text-uppercase"> {label?.toLowerCase() === 'older' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : label + ' - ' + format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>
                </div>
            </div>
        </div>
    );
}


export const EmailCardInDraft = (props: IEmailCardProps): JSX.Element => {
    const { id, email, label, hideCheckbox, selected, toggleCheckbox, redirect } = props
    return (
        <div key={email.id} className={`card--email cursor--hand${id === email.id || selected?.includes(email.message_uid) ? ` selected` : ``}`}  >
            {!hideCheckbox &&
                <div className="card--email__checkbox" onClick={() => toggleCheckbox?.(email.message_uid)}>
                    <SimpleCheckbox checked={selected?.includes(email.message_uid) ? true : false} id="" />
                </div>
            }
            <div className="card--emaill__content overflow-hidden w-100" onClick={() => redirect?.(email.id)} >
                <div className="email__content_heading">{email.subject ? email.subject : 'No subject'}</div>
                <div className="card--email__text text-truncate">{email.short_message?.replace(/<[^>]+>/g, '')}</div>
                <div className="card--email__date text-uppercase"> {label?.toLowerCase() === 'older' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : label + ' - ' + format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>
            </div>
        </div >
    );
}



export const EmailCardInForwarded = (props: IEmailCardProps): JSX.Element => {
    const { id, email, label, hideCheckbox, selected, toggleCheckbox, setPreview, redirect } = props
    return (
        <div key={email.id + '-' + id} className={`card--email cursor--hand${id === email.id || selected?.includes(email.id) ? ` selected` : ``}`}  >
            {!hideCheckbox &&
                <div onChange={() => toggleCheckbox?.(email.id)}>
                    <SimpleCheckbox checked={selected?.includes(email.id) ? true : false} id="" />
                </div>
            }
            <div className=' w-100 d-flex' onClick={() => (id && id === email.id) ? setPreview?.(true) : redirect?.(email.id)}>

                <div className="card--emaill__content overflow-hidden ml-3 ml-md-0" >

                    <div className="mb-2 label_email label_email--forwarded">Forwarded</div>
                    <div className="font_weight--semi_bold color_auxiliary text-truncate">{email.subject}</div>
                    <div className="color_auxiliary text-truncate">
                        {email.short_message?.replace(/<[^>]+>/g, '')}
                    </div>
                    <div className={`card--email__date text-uppercase`}>{label?.toLowerCase() === 'older' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : label + ' - ' + format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</div>
                </div>
            </div>
        </div>
    );
}


const MultipleRecipientsPopover = (props: IMultipleRecipientsPopoverProps) => {
    const { recipients } = props;
    return <div className="pl-2 d-inline-block font-weight-normal">{`+${recipients.length - 1}`}</div>;

    // return (
    //     <Dropdown className='dropdown dropdown--custom dropdown--custom__inverse'>
    //         <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" variant="none" href="#">
    //             <div className="pl-2 d-inline-block">{`+${recipients.length - 1}`}</div>
    //         </Dropdown.Toggle>
    //         <Dropdown.Menu className="dropdown-menu inline-dropdown agent_dropdown animate slideIn pt-3 pb-3">
    //             {recipients.map((item, index) =>
    //                 <Dropdown.Item key={index} className="dropdown-item animate__animated animate__fadeInUp animate__faster pb-2" as={IonRouterLink} routerLink={getContactOverviewUrl(item.id)}>
    //                     <div className="agent_signature agent_signature__mini2">
    //                         <div className="agent_signature__photo"></div>
    //                         <div className="agent_signature__text">{item.first_last_name || item.email}</div>
    //                     </div>
    //                 </Dropdown.Item>
    //             )}
    //         </Dropdown.Menu>
    //     </Dropdown>
    // );
}