import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { formatStringToSystemTime, formatSystemDateShort } from "scripts/helpers/dates";
import { booleanToStr } from "scripts/helpers/texts";
import { IApiScope } from "types/apis";
import { DeepPropertiesType } from "types/utilities"

export namespace CreateCalendarTaskApi {
    export const url = "/tasks";
    export const method = "POST";
    export const defaultError = "Failed to create task.";
    export const operations = apiOperations(CreateCalendarTaskApi);
    export const scope = new class CreateCalendarTaskApiScope implements IApiScope<Request, Response> {
        request(input: Request): DeepPropertiesType<Request, string> {
            const endTime = input.category === "e" ? `${formatSystemDateShort(input.end_dt)} ${formatStringToSystemTime(input.end_time)}` : `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}`
            const startTime = input.category === "e" ? `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}` : `${formatSystemDateShort(input.start_dt)} ${formatStringToSystemTime(input.start_time)}`

            return {
                ...input,
                start_dt: startTime,
                end_dt: endTime,
                completed: booleanToStr(input.completed),
                notify_contacts: booleanToStr(input.notify_contacts),
                repeat_end_date: formatSystemDateShort(input.repeat_end_date),

            }
        }

        response(input: DeepPropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        "category": string,
        "title": string,
        "start_dt"?: Date,
        "start_time": string,
        "end_dt"?: Date,
        "end_time"?: string,
        "task_type": string,
        "location"?: string,
        "property_photo"?: string,
        "notes"?: string,
        "reminder_minutes_prior"?: string,
        "contact_ids": Array<string>,
        "repeat": string,
        "repeat_every_value"?: string,
        "repeat_every_text"?: string,
        "repeat_on_day"?: string,
        "repeat_end_on"?: string,
        "repeat_end_date"?: Date,
        "repeat_month_option"?: string,
        "priority": string,
        "completed": boolean,
        "notify_contacts": boolean,
        "task_id"?: string,
        "property_harid"?: string,
        "property_listingid"?: string,
        "property_for"?: string,
        "property_full_street_address"?: string,
        "property_address2"?: string,
        "property_city"?: string,
        "property_state"?: string,
        "property_country"?: string,
        "property_zip"?: string,

    }

    export interface Response {
        status: string, message: string
    }
}

export const fetchCreateCalendarTaskService = forwardOperation(CreateCalendarTaskApi.operations, 'fetch');
export const useFetchCreateCalendarTaskService = forwardOperation(CreateCalendarTaskApi.operations, 'useFetch');
export const useCreateCalendarTaskService = forwardOperation(CreateCalendarTaskApi.operations, 'useService');