import { useEffect, useState } from "react";
import { IImportCsvMappedProgress, IImportCsvMappedSteps } from "../ImportCsvMappedScreen.def";

export const ProgressBar = (props: IImportCsvMappedProgress) => {
    const [currentStage, setCurrentStage] = useState(1)
    const [stage, setStage] = useState<IImportCsvMappedSteps[]>([{
        id: 1,
        title: "Import Setup",
        isActive: true,
        isCompleted: false,
        isCurrent: true,
        nextComponentId: 2
    }, {
        id: 2,
        title: "Fields Mapping",
        isActive: false,
        isCompleted: false,
        isCurrent: false,
        nextComponentId: 3,
        prevComponentId: 1,
        nextSkippedId: 4,


    }, {
        id: 3,
        title: "Dropdown Mapping",
        isActive: false,
        isCompleted: false,
        isCurrent: false,
        nextSkippedId: 5,
        prevComponentId: 2

    }, {
        id: 4,
        title: "Validation Process",
        isActive: false,
        isCompleted: false,
        isCurrent: false,
        nextComponentId: 5,
        prevComponentId: 3
    }, {
        id: 5,
        title: "Done",
        isActive: false,
        isCompleted: false,
        isCurrent: false,
        prevComponentId: 4

    }]);

    const moveNext = (stepId: number) => {
        setStage(stage.map((step: IImportCsvMappedSteps) => {
            if (step.id === stepId) {
                return {
                    ...step,
                    isActive: true
                }
            } else if (step.id < stepId) {
                return {
                    ...step,
                    isCompleted: true,
                    isActive: true
                }
            } else {
                return {
                    ...step,
                }
            }
        }));
    }

    const moveBack = (stepId: number) => {
        setStage(stage.map((step: IImportCsvMappedSteps) => {
            if (step.id === stepId) {
                return {
                    ...step,
                    isActive: true,
                    isCompleted: false
                }
            } else if (step.id > stepId) {
                return {
                    ...step,
                    isCompleted: false,
                    isActive: false
                }
            } else {
                return {
                    ...step,
                }
            }
        }));
    }

    useEffect(() => {
        if (props.active) {
            if (props.active > currentStage) {
                moveNext(props.active)
                setCurrentStage(props.active)
            } else if (props.active < currentStage) {
                moveBack(props.active)
                setCurrentStage(props.active)
            }
        }
    }, [props.active]);
    return (
        <>

            <div className="onb_stps_ctr border_radius border_radius--default pt-3 pb-4 mb-md-5 mb-4">
                <div className="onb_stps">
                    {stage?.map((step, index) => {
                        return (<div key={index} className={`onb_stp ${step.isCompleted && 'onb_stp_done'} ${step.isActive && 'active'}`}>
                            {step.isCompleted ?
                                <div className="onb_stp_no"><span><img src="https://content.harstatic.com/media/icons/checkmark_filled.svg" /></span></div>
                                :
                                <div className="onb_stp_no"><span>{step.id}</span></div>
                            }
                            <div className="onb_stp_txt">{step.title}</div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}
