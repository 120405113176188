import { limitMessage, requiredMessage } from "./messages";

export function validateRequired(value?:string): string
{
    // Validate if the field is required
    value = value?.trim();
    if (!value || value === '') { return requiredMessage(); }

    // All validations passed
    return '';
}

export function validateFieldLimit(value:string | undefined, max:number, min?:number): string
{
    if(!value) { return ''; }
    if (value.length <= max && (!min || value.length >= min)) { return ''; }
    return limitMessage(max, min);
}

export function validateEmail(value?:string): boolean
{
    if(!value?.trim()) { return false; }
    const regexEmail=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(value);
}