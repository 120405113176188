//import {  SearchContactsApi } from 'services/contacts/searchContacts';
//import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
//import { Typeahead } from '../Typeahead';
import {  IAddressesTypeaheadProps } from './AddressesTypeahead.def';
import './AddressesTypeahead.scss';
import {  isString } from 'lodash-es';
import { joinWithSpace } from 'scripts/helpers/texts';
import { parseComponents ,IFeature} from 'types/vendors/mapbox';
import { useState } from 'react';
import { useFocusTimer } from '../Typeahead';

export const AddressesTypeaheadDesktop = (props: IAddressesTypeaheadProps): JSX.Element => {

    return (
        <>
            <Form {...props}  />
        </>
    );
}



const Form = (props: IAddressesTypeaheadProps): JSX.Element => {

    const { change } = props;
    const [searchData,setSearchData]=useState<Array<IFeature>>([])
    const [hasFocus, handleFocus, handleBlur] = useFocusTimer();

    const onChange = (v?: IFeature) => {
        console.log(v)
        const components = v? parseComponents(v): undefined;
        change?.({address1:`${v?.place_name.replace(components?.zipCode?.toString()??"","").replace(components?.city??"","").replace(" , ","").slice(0,100)}`,zipcode:components?.zipCode?.toString()??"",city:components?.city??"",state:components?.state??"",country:components?.country??""});
    }

    const handleChange=(value:string)=>{
        change?.({...props.initialValue,address1:`${value}`});
        search(value)
    }

    const search = (async (value: string) => {
        
        const mapboxSearchApiEndpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?bbox=-106.64840698242188%2C25.822144306879686%2C-93.49365234375%2C36.500529337632265&access_token=`+process.env.REACT_APP_AS_MAPBOX_TOKEN;
        try {
          let response = await fetch(mapboxSearchApiEndpoint);
          let data = await response.json();
          setSearchData(data.features);
          return;
        } catch (error) {
          
          return false;
        }
      })

    //const mapResult = (item: {place_name:string}): IDropDownOption => ({ id: ``, text: item.place_name});
    const className=joinWithSpace(props.className,isString(props.error)  && props.error.trim() !== ''&&"is-invalid")
    return (
        <div>
            <input
                className={joinWithSpace('form-control', className)}
                type="text"
                placeholder='Address 1'
                onChange={e => handleChange(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur} 
                value={props.initialValue.address1}
                maxLength={100}
                />
                {hasFocus&&searchData.map((item,ind)=>
                    <div className="typeahead" key={ind}>
                    <div className="typeahead__item p-3 mb-0">
                        <div className='row'  onClick={() => onChange(item)}>
                            <div className="col">
                                <div className="agent_signature agent_signature__large"  >
                                   <div className="agent_signature__text">
                                        <div className=" max-w-60pr d-block">{item.place_name}</div>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
           {/*<Typeahead label='Address 1' 
                placeholder={"Address 1"}
                search={search}
                className={className}
                mapResult={mapResult}
                onChange={onChange}
                //onChangeCapture={(e)=>props.onTextChange?.(e.currentTarget.value) }                
                noAvatar
                initialValue={{...initialFeatureData,place_name:props.initialValue?.address1??""}}
                value={props.initialValue?.address1??""}
    />*/}
               {
                isString(props.error)  && props.error.trim() !== ''
                    ?
                    <div  className='mt-2 py-1 px-2 bac bg_color_sold_light color_sold border_radius--default font_size--small_extra'>{props.error}</div>
                    :
                    null
            }
        </div>
    );
}

/** TODO: Use feature and context to do a safer parse of the components  */
/*const parseAddressComponents=(value:{place_name:string}): IAddressTypeaheadItem => {
    const address=value?.place_name?.replace(/\s{2,}/g, ' ') ?? ''
   const address1=address?.split(",")?.[0] ?? '';
    const zipcode=parseInt(address?.split(",")?.map(s => s.trim().match(/(\d{5}([ -]\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[ -]\d{1}[A-Z]{1}\d{1}$)/))?.filter(e => e)?.[0]?.[0]??"0");
    const city=address?.split(",")?.[2]??"";

    return { address1, city, zipcode }
}*/