import { IUseFetch, useFetch as useFetchHook } from "scripts/hooks/useFetch";
import { IServiceHookResponse, useService as useServiceHook } from "scripts/hooks/useService";
import { IFetchFunction, IServiceParametersRequest, serviceFetch } from "scripts/http/serviceFetch";
import { ApiContentTypes, IApi } from "types/apis";

type MethodKeys<T> = keyof { [K in keyof T as T[K] extends ((...args: any[]) => any) ? K : never]: K }


export interface IApiOperations<TRequest, TResponse, TResponseError = undefined> {
    readonly fetch: IFetchFunction<TRequest, TResponse>;
    readonly useFetch: IUseFetch<TRequest, TResponse, TResponseError>;
    readonly useService: <TParams extends Partial<TRequest> = {}>(parameters?: TParams) => IServiceHookResponse<TRequest, TResponse, TResponseError, TParams>
}

export const apiOperations = <TServerRequest extends IServiceParametersRequest<TUrl, TContentType>, TRequest, TServerResponse, TResponse, TResponseError, TUrl extends string, TContentType extends ApiContentTypes | undefined>
    (api: IApi<TServerRequest, TRequest, TServerResponse, TResponse, any, TResponseError, TUrl, TContentType>) =>
({
    fetch(parameters?: TRequest) {
        return serviceFetch(api, parameters ?? {} as TRequest);
    },

    useFetch(parameters?: TRequest) {
        //const input = parameters? api._request(parameters): undefined;
        return useFetchHook(api, parameters!);
    },

    useService(parameters?: Partial<TRequest>) {
        return useServiceHook(api, parameters);
    }

} as IApiOperations<TRequest, TResponse, TResponseError>);


export function forwardOperation<T, K extends (keyof T & MethodKeys<T>)>(api: T, name: K): T[K] {
    return ((...args: any[]) => (api[name] as any)(...args)) as any;
}
