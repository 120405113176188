import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { IContactMyTransactionsScreenProps } from './ContactTransactionsScreen.def';
import './ContactTransactionsScreen.scss';
import { useLocation, useParams } from 'react-router';
import { ExternalEmbedContent } from 'views/general/ExternalEmbedContent';
import { eExternalEmbedContentSource } from 'views/general/ExternalEmbedContent/ExternalEmbedContent.def';

export const ContactTransactionsScreen = (_props: IContactMyTransactionsScreenProps): JSX.Element =>
{
    const { id, path = '' } = useParams<{ id:string, path:string }>();
    const location = useLocation();

    return (
        <ContactDetailsTemplate forceFullHeight selectedMenu='Services'>

            <ExternalEmbedContent
                url={`/mopx_mls/contacts/${id}`}
                initialPath={path}
                queryString={location.search}
                source={eExternalEmbedContentSource.Website}
                iframeTitle='Transactions'
                enabledInnerRoutes
            />

        </ContactDetailsTemplate>
    );
}
