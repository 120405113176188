import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace AddLinkApi
{
    export const url = "/contacts/:contact_id/links";
    export const method = "POST";
    export const defaultError = "Failed to save link.";
    export const operations = apiOperations(AddLinkApi);
    export const scope = new class AddLinkApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                id: parseInt(data.id)
            }
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        name:string,
        url:string,
    }

    export interface Response
    {
        id: number
    }
}

export const fetchAddLinkService = forwardOperation(AddLinkApi.operations, 'fetch');
export const useFetchAddLinkService = forwardOperation(AddLinkApi.operations, 'useFetch');
export const useAddLinkService = forwardOperation(AddLinkApi.operations, 'useService');