import { IContactManageTemplateProps } from './ContactManageTemplate.def';
import './ContactManageTemplate.scss';
import { DefaultTemplate } from '../DefaultTemplate';


export const ContactManageTemplateDesktop = (props:IContactManageTemplateProps): JSX.Element => 
{
  const Layout = props.fluidTemplate? FluidLayout: SimpleLayout;
  return (
    <DefaultTemplate title="Contacts" fluid={props.fluidTemplate}>
      <Layout {...props} />
    </DefaultTemplate>
  );
};

function SimpleLayout(props:IContactManageTemplateProps): JSX.Element
{
  return (
    <div className="container container__large  d-none d-md-block">
      {props.header}
      {props.children}
    </div>
  )
}

function FluidLayout(props:IContactManageTemplateProps): JSX.Element
{
  return (
    <div className="scrollable_layout__content" onClick={props.onBodyClick}>
      <div className="scrollable_layout__titlebar">
        {props.header}

        
      </div>
      <div className="scrollable_layout__cols">
        {props.children}
      </div>
    </div>
  );
}
    


