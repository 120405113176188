import { IonItem, IonModal,IonHeader,IonButtons,IonToolbar,IonButton ,IonContent,IonTitle } from '@ionic/react';
import { IDropDownModalProps} from './DropDownModal.def';
import './DropDownModal.def';
import {  useState,useEffect } from 'react';
import { OverlayTrigger, Popover  } from 'react-bootstrap';

export function DropDownModal(props: IDropDownModalProps): JSX.Element
{
    const [showModal,setShowModal]=useState(false)
    const {label,children,value,handleSave,name,className,secondary,isSingleSelection,OverlayButton}=props
    const handleClose=()=>{
     setShowModal(false); 
    }
    useEffect(()=>{
      isSingleSelection&&setShowModal(false); 
    },[value])
    const labelText=secondary&& value!="None" ?<div className={className}>{value}</div>:label
     const valueText=secondary && value!="None"?"":<div className={className}>{value}</div>
     return (<>
      <IonItem className="tab__nextpage" onClick={()=>setShowModal(true)} role="button" >
          <div onClick={(e)=>{OverlayButton&&e.stopPropagation();}} className="tab__nextpage_label">{labelText} {OverlayButton&&<OverlayTrigger   trigger={['hover','focus']} placement="right" overlay={PopoverSlider(OverlayButton)}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>}</div>
             <div className={`tab__nextpage_value `}>{valueText}
        </div>     
        <img  className="tab__nextpage_arrow" src="https://content.harstatic.com/media/icons/arrow-right.svg" />
      </IonItem> 
        <div>  
          <IonModal className="graybg" trigger="open-modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)} backdropDismiss={false}>
            <IonHeader>
              <IonToolbar>
                <IonButtons className="w-15pr" slot="start">
                  <IonButton color={"blue"} onClick={handleClose}>Cancel</IonButton>
                </IonButtons>
                <IonTitle>{label}</IonTitle>
                 <IonButtons className="w-15pr" slot="end">
                  <IonButton color={"blue"} onClick={() => {handleSave?.(name);handleClose()}}>Ok</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ion-padding">
                {children}
              </div>
            </IonContent>
          </IonModal>
        </div>
        </>
      );
}
const PopoverSlider =(data:{title:string,content:JSX.Element | JSX.Element[]})=> (<Popover id="popover-basic">
  <Popover.Title as="h3">{data.title}</Popover.Title>
  <Popover.Content>
  {data.content}
 </Popover.Content>
</Popover>
)
