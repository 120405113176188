import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSearchContactsService } from 'services/contacts/searchContacts';
import { ContactCard } from '../ContactCard';
import { IStageAddContactsProps } from '../StageColumn.def';
import { IAddPopoverProps } from './AddPopover.def';
import './AddPopover.scss';

export const AddPopoverApp = (props: IAddPopoverProps): JSX.Element => {
    const { visible, pipeline, setVisiblity, addContact } = props
    const [timer, setTimer] = useState<number | string>()
    const [contacts, setContact] = useState<any[]>([]);
    const [limit, setLimit] = useState(0)
    const [contactSearch, ContactSearchAction] = useSearchContactsService()
    const searchField = useRef<HTMLInputElement | null>(null)
    let history = useHistory<IStageAddContactsProps>();


    const doSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (e.target.value.length === 0) {
            init()
            return
        }
        if (timer) {
            clearTimeout(timer)
        }
        setLimit(8)
        setTimer(setTimeout(() => {
            ContactSearchAction.fetch({ name: e.target.value, sort_by: 'first_name', sort_dir: e.target.value.length > 0 ? 'asc' : 'desc', page: 1 })
        }, 500))
    }


    const overrideAddContact = (contact: any, param: any) => {
        addContact?.(contact, param)
        // if (null !== searchField.current && searchField.current.value.trim() === '') {
        //  init()
        // } else {
        let updatedContact: any = contacts.map((c: any) => {
            if (c.id === contact.id) {
                c.pipeline_status = param.pipeline
                c.pipeline_status_text = param.pipeline_text
            }
            return c
        })
        setContact(updatedContact)
        //}        
    }

    const reset = () => {
        setContact([])
        if (null !== searchField.current) {
            searchField.current.value = ""
        }
        if (setVisiblity) {
            setVisiblity()
        }
    }
    const init = () => {
        setLimit(4)
        ContactSearchAction.fetch({ name: (null !== searchField.current) ? searchField.current.value : '', stages: [0], sort_by: 'created_dt', sort_dir: 'desc', page: 1 })
    }


    const loadContacts = () => {
        const newContacts: any = [];
        if (contactSearch.response) {
            contactSearch.response?.list.map((contact: any, index: number) => {
                if (index < limit)
                    newContacts.push(contact);
            })

            setContact(newContacts);
        }
    }


    useEffect(() => {
        if (visible) {
            init()
            if (null !== searchField.current) {
                searchField.current.value = ""
            }
        }
    }, [visible])

    useEffect(() => {
        loadContacts()
    }, [contactSearch])
    return (
        <IonModal id="example-modal" isOpen={visible} trigger="type-modal"
            initialBreakpoint={0.55}
            breakpoints={[0, 0.55, 0.5, 0.85]}
            handleBehavior="cycle"
            onDidDismiss={() => setVisiblity?.()    }
            backdropDismiss={false}
        >
            <IonContent>
                <IonToolbar>
                    <IonTitle>Add Contacts to Pipeline</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="dark" onClick={() => {
                            if (setVisiblity) {
                                setVisiblity()
                            }
                        }}><img src="https://content.harstatic.com/media/icons/close_black.svg" /></IonButton>
                    </IonButtons>
                </IonToolbar>
                <div className="ion-padding bg-white">

                    <div className="add_pipeline_item__content">
                        <div className="pt-2 pb-4">
                            <label htmlFor="SearchKeyWord" className="sr-only"></label>
                            <input ref={searchField} type="text" className="form-control" id="SearchKeyWord" aria-describedby="SearchKeyWord" placeholder="Search contacts" onChange={(e) => doSearch(e)} />
                        </div>
                        <div className="results">
                            {!contactSearch.loading && searchField.current &&
                                <>
                                    {contacts.length === 0 ?
                                        <div>
                                            <div className='pb-2 color_auxiliary'>No Contacts Found</div>
                                            <div className='border-top pt-3'>
                                                <div className="agent_signature__text__title text-truncate">{searchField.current.value}</div>
                                                <a href='#' className='font-weight-bold' onClick={(e: any) => {
                                                    e.preventDefault()
                                                    setVisiblity?.()
                                                    if (searchField.current) {
                                                        let array = searchField.current.value.split(" ")
                                                        history.push(`/contacts/create`, {
                                                            first_name: array[0] ? array[0] : searchField.current.value,
                                                            last_name: array[1] ? array[1] : "",
                                                            pipeline_status: pipeline ? pipeline : 0
                                                        })

                                                    } else {
                                                        history.push(`/contacts/create`)
                                                    }

                                                }}>

                                                    Add New Contact
                                                </a>
                                            </div>
                                        </div>
                                        :
                                        <div className='pb-3 color_auxiliary'>
                                            {searchField.current.value.length === 0 ?
                                                <div> Recent Contacts</div>
                                                :
                                                <div> Contacts Found</div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                            {!contactSearch.loading && contacts.map((contact: any, index: number) =>
                                <div key={index} className={`card card_hover_edit text-left mb-3 border-0 card__mini`}>
                                    <ContactCard
                                        key={contact.id}
                                        contact={contact}
                                        inList={Number(contact.pipeline_status) === pipeline ? true : false}
                                        miniCard={true}
                                        showDeal={false}
                                        pipeline={pipeline}
                                        addContact={overrideAddContact}
                                        clear={reset}
                                    />
                                </div>
                            )}

                        </div>
                    </div>

                </div>
            </IonContent>
        </IonModal>
    );
}
