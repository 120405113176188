import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace EditContactsTagsApi
{
    export const url = "/contacts/edit_tags";
    export const method = "POST";
    export const defaultError = "Failed to update tags.";
    export const operations = apiOperations(EditContactsTagsApi);
    export const scope = new class EditContactsTagsApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        contact_ids:number[];
        tags:ITag[];
        
    }

    export interface Response
    {
        status:string,message:string
    }

    export interface ITag
    {
        id:number;
        value:string;
    }
}

export const fetchEditContactsTagsService = forwardOperation(EditContactsTagsApi.operations, 'fetch');
export const useFetchEditContactsTagsService = forwardOperation(EditContactsTagsApi.operations, 'useFetch');
export const useEditContactsTagsService = forwardOperation(EditContactsTagsApi.operations, 'useService');