import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace GetDashboardApi
{
    export const url = "/user/dashboard";
    export const method = "GET";
    export const defaultError = "Failed to get data";
    export const operations = apiOperations(GetDashboardApi);
    export const scope = new class GetDashboardApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        "leads": {
            "total": string,
            "total_today": string
          },
          "deals": {
            "total": string,
            "amount": string
          }
    }
}

export const fetchGetDashboardService = forwardOperation(GetDashboardApi.operations, 'fetch');
export const useFetchGetDashboardService = forwardOperation(GetDashboardApi.operations, 'useFetch');
export const useGetDashboardService = forwardOperation(GetDashboardApi.operations, 'useService');