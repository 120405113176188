import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"
import { GetDealsPipelineApi } from "./getDealsPipeline";

export namespace SearchDealApi {
    export const url = "/deals";
    export const method = "GET";
    export const defaultError = "Failed to load deals.";
    export const operations = apiOperations(SearchDealApi);
    export const scope = new class SearchDealApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            const { page, contact_id, page_size, deal_stages, property_types, expired, deal_types, expired_in_90_days, renew } = input;

            return {
                page: page,
                page_size: page_size,
                contact_id: contact_id,
                deal_stages: deal_stages,
                property_types: property_types,
                expired: expired,
                expired_in_90_days: expired_in_90_days,
                deal_types: deal_types,
                renew: renew
            }
        }

        response(input: Record<'data', DeepPropertiesType<GetDealsPipelineApi.IDealsApiResponse, string>>): GetDealsPipelineApi.IDealsApiResponse {
            const { data } = input;
            const { total, total_pages, total_amount, total_amount_formatted, page, page_size, list, ...other } = data;
            return {
                total: parseInt(total),
                total_pages: parseInt(total_pages),
                page: parseInt(page),
                page_size: parseInt(page_size),
                total_amount: parseInt(total_amount),
                total_amount_formatted: total_amount_formatted,
                list: list.map(this._mapDealListItem, this),
                ...other
            }
        }

        _mapDealListItem(input: DeepPropertiesType<GetDealsPipelineApi.IDealList, string>): GetDealsPipelineApi.IDealList {
            const { id, title, deal_type, deal_type_text, property_photo, property_price, created_dt, modified_dt, property_full_street_address, property_address2, property_city,
                property_state, property_zip, property_type, property_type_text, property_listtype, deal_amount, deal_amount_formatted, estimated_deadline, notes: notes, contacts, ...other } = input;
            return {
                id: Number(id),
                title: title,
                deal_type: deal_type,
                deal_type_text: deal_type_text,
                property_photo: property_photo,
                property_price: property_price,
                property_listtype: property_listtype,
                property_full_street_address: property_full_street_address,
                property_address2: property_address2,
                property_city: property_city,
                property_state: property_state,
                property_zip: Number(property_zip),
                property_type: property_type,
                property_type_text: property_type_text,
                deal_amount: Number(deal_amount),
                deal_amount_formatted: deal_amount_formatted,
                estimated_deadline: estimated_deadline,
                created_dt: created_dt,
                modified_dt: modified_dt,
                notes: notes,
                contacts: contacts.map(this._mapDealContacts, this),
                ...other
            };
        }
        _mapDealContacts(input: DeepPropertiesType<GetDealsPipelineApi.IDealContact, string>): GetDealsPipelineApi.IDealContact {
            const { id, first_name, last_name, city, middle_name, score, photo_url, ...other } = input;
            //let MiddleName = (middle_name) ? ' ' + middle_name : ''
            return {
                id: Number(id),
                first_name: first_name,
                last_name: last_name,
                city: last_name,
                middle_name: middle_name,
                score: score,
                photo_url: photo_url,
                ...other
            }
        }
    }();

    export interface Request extends GetDealsPipelineApi.Request {
        contact_id?: string

    }

    export interface Response {

    }
}

export const fetchSearchDealService = forwardOperation(SearchDealApi.operations, 'fetch');
export const useFetchSearchDealService = forwardOperation(SearchDealApi.operations, 'useFetch');
export const useSearchDealService = forwardOperation(SearchDealApi.operations, 'useService');