import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace QuickUpdateApi {
    export const url = "/contacts/update_field";
    export const method = "POST";
    export const defaultError = "Failed to update please try later.";
    export const operations = apiOperations(QuickUpdateApi);
    export const scope = new class QuickUpdateApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string | string[]> {
            return {
                ...input
            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        contact_ids: string[],
        field_name: string,
        field_value: string
    }

    export interface Response {

    }
}

export const fetchQuickUpdateService = forwardOperation(QuickUpdateApi.operations, 'fetch');
export const useFetchQuickUpdateService = forwardOperation(QuickUpdateApi.operations, 'useFetch');
export const useQuickUpdateService = forwardOperation(QuickUpdateApi.operations, 'useService');