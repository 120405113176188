import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace DeleteContactApi {
    export const url = "/contacts/:contactId";
    export const method = "DELETE";
    export const defaultError = "Failed to delete contact.";
    export const operations = apiOperations(DeleteContactApi);
    export const scope = new class DeleteContactApiScope implements IApiScope<Request, Response>
    {
        request(data: Request) {
            return {
                contactId: data.contactId,
                delete_provider: data?.delete_provider
            };
        }
        response(data: Response) { return data; }
    }();

    export interface Request {
        contactId: string
        delete_provider?: string
    }

    export interface Response {
        status: string
        message: string
    }
}

export const fetchDeleteContactService = forwardOperation(DeleteContactApi.operations, 'fetch');
export const useFetchDeleteContactService = forwardOperation(DeleteContactApi.operations, 'useFetch');
export const useDeleteContactService = forwardOperation(DeleteContactApi.operations, 'useService');