import { Tag } from 'scripts/ui/tag';
import { IPhotoUploadSectionViewProps } from './PhotoUploadSection.def';
import './PhotoUploadSection.scss';

export const PhotoUploadSectionApp = (props:IPhotoUploadSectionViewProps): JSX.Element =>
{
    const { imageUrl:url, onOpen } = props;
    return (
        <div className="photophoto mb-4">
          <div className="agent_signature agent_signature__large__extra agent_signature__nagative mb-0 animate__animated  animate__fast">
         <Tag.Div className={`position-relative ${url?``:`photophoto__image`} nophoto agent_signature__photo bg-size-inherit `} onClick={onOpen} cssVars={{  avatarAgentPicture: `url( ${url ? url : `https://content.harstatic.com/media/icons/icon-listing-provided-by-agent.svg`})` }}>
                <div className="photophoto__image__icon"></div>
            </Tag.Div></div>
        </div>
    );
}
