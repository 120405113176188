import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeleteMultiDraftsApi {
    export const url = "/emails/drafts/bulk_delete";
    export const method = "DELETE";
    export const defaultError = "Drafts has not been deleted";
    export const operations = apiOperations(DeleteMultiDraftsApi);
    export const scope = new class DeleteMultiDraftsApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string | string[]> {
            return {
                ...input

            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        draft_ids?: string[];
    }

    export interface Response {
        status: string;
        message: string;
    }
}

export const fetchDeleteMultiDraftsService = forwardOperation(DeleteMultiDraftsApi.operations, 'fetch');
export const useFetchDeleteMultiDraftsService = forwardOperation(DeleteMultiDraftsApi.operations, 'useFetch');
export const useDeleteMultiDraftsService = forwardOperation(DeleteMultiDraftsApi.operations, 'useService');