
import { PrimaryButton } from "views/forms/buttons/Button";
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { IPipelineContactRemoveProps } from "../ContactPipelineScreen.def";


export const PipelineRemovePopUp = (props: IPipelineContactRemoveProps) => {
    const { name, show, toggleShow, removeContact } = props
   return (
        <>
            <DefaultPopUp isOpen={show} title="Remove?" className="modal--size--small" toggleShow={() => toggleShow ? toggleShow() : ''}>
                <DefaultPopUp.Body>
                    <div className="p-5 bg_color_snow_white">
                        <div className="font_size--medium font_weight--semi_bold m-auto text-center algin-self-center color_auxiliary mb-4">
                            Are you sure you want to <br />
                            remove {name ? name : ''} from pipeline?
                        </div>
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="row">
                        <div className="col-auto algin-self-center text-center m-auto">
                            <PrimaryButton onClick={() => {
                                removeContact?.()
                            
                            }} >Remove</PrimaryButton>
                        </div>
                    </div>

                </DefaultPopUp.ActionBar>
            </DefaultPopUp >
        </>

    );

}


