import { version } from "scripts/application/settings";
import { Storage } from "@ionic/storage";

const enabled = true;

interface IBugherdConfig
{
    feedback?:{
        hide:boolean
    };

    reporter:{
        email?:string;
        required:boolean;
    };

    metadata:{
        username?:string;
        version:number;
    };
}

interface IBugherdUser
{
    email:string;
    username:string;
}

const STORAGE_KEY = 'bugherd.user';
let storage:Storage;

const BugHerdConfig:IBugherdConfig = {
    feedback:{hide:false},
    reporter: {required:true},
    metadata:{ version }
};

(window as any).BugHerdConfig = BugHerdConfig;

export function login(username:string, email:string)
{
    if(!enabled) { return; }
    setUser(username, email);
    const data:IBugherdUser = { username, email };
    storage.set(STORAGE_KEY, data);
}

export function logout()
{
    if(!enabled) { return; }
    BugHerdConfig.reporter.email = undefined;
    delete BugHerdConfig.metadata['username'];
    storage.remove(STORAGE_KEY);
}

async function initialize()
{
    if(!enabled) { return; }
    storage = await new Storage().create();
    await loadFromStorage();
    loadScript();
    console.log('Bugherd initialized.');
}

async function loadFromStorage()
{
    const user = await storage.get(STORAGE_KEY) as IBugherdUser | undefined;
    if(user) { setUser(user.username, user.email); }
}

function setUser(username:string, email:string)
{
    BugHerdConfig.reporter.email = email;
    BugHerdConfig.metadata['username'] = username;
}

function loadScript()
{
    const bugherd = window.document.createElement('script');
    const script = window.document.getElementsByTagName('script')[0]!;
    bugherd.type = 'text/javascript';
    bugherd.src = 'https://www.bugherd.com/sidebarv2.js?apikey=fhx0xb5vmiqcp3fpe8wh7q';
    script.parentNode!.insertBefore(bugherd, script);
}

initialize();