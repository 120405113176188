import { IContactsConfirmDeleteProps } from './ContactsConfirmDelete.def';
import { useResponsiveChoose } from 'scripts/hooks/general';
import { ContactsConfirmDeleteDesktop } from './ContactsConfirmDelete.desktop';
import { ContactsConfirmDeleteApp } from './ContactsConfirmDelete.app';
import { useDeleteContactService } from 'services/contacts/deleteContact';
import { useDeleteContactsService } from 'services/contacts/deleteContacts';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useFetchGetBasicInfoService } from 'services/user/getBasicInfo';



export const ContactsConfirmDelete = (props: IContactsConfirmDeleteProps): JSX.Element => {

    const { onDelete } = props
    const [basicInfo] = useFetchGetBasicInfoService();
    const [removeContactService, removeContactAction] = useDeleteContactService();
    const [removeContactsService, removeContactActions] = useDeleteContactsService();
    const removeContact = (delete_provider?: string) => {
        if (props.contacts?.length === 1) {
            let param2 = { contactId: props.contacts?.[0].id, delete_provider }
            removeContactAction.fetch(param2)
        } else {
            let arr: any = [];
            props.contacts?.map((contact: any) =>
                arr.push(contact.id)
            )

            let param2: any = { contact_ids: arr, delete_provider }
            removeContactActions.fetch(param2)
        }
    }

    const callBack = (response: any) => {
        if (response?.status === 'ok') {
            onDelete?.()
            displayMessage('Contact has been successfully deleted.')
        }
    }

    useServiceDidSucceeded(removeContactsService.response, (response) => {
        callBack(response)
    })

    useServiceDidSucceeded(removeContactService.response, (response) => {
        callBack(response)
    })

    const ContactsConfirmDeletePlatform = useResponsiveChoose(ContactsConfirmDeleteDesktop, ContactsConfirmDeleteApp);

    return <ContactsConfirmDeletePlatform 
    removeContact={removeContact}
    mailerBoxId={basicInfo.response?.provider_mailbox_id}
    {...props} />
}
