import { isBoolean } from "lodash-es";
import { useCallback, useEffect, useRef } from "react";
import { usePrevious } from "./general";
import { useTimeout } from "./timers";

export type IValidationFunction = (abortController?:AbortSignal) => boolean | Promise<boolean>;

export class ValidationSignal
{
    private _valid?:boolean;
    private listeners:Set<IValidationFunction>;
    private currentAbortController?:AbortController;

    public get valid() { return this._valid; }
    public get validating() { return this.currentAbortController !== undefined; }

    public constructor()
    {
        this.listeners = new Set();
    }

    public subscribe(listener:IValidationFunction)
    {
        this.listeners.add(listener);
    }

    public unsubscribe(listener:IValidationFunction)
    {
        this.listeners.delete(listener);
    }

    public unload()
    {
        this.listeners.clear();
        this.currentAbortController?.abort();
        this.currentAbortController = undefined;
    }

    protected async dispatch(): Promise<boolean>
    {
        this.currentAbortController?.abort();
        this.currentAbortController = new AbortController();
        this._valid = undefined;
        const listeners = Array.from(this.listeners.values());
        const promises = listeners.map(callback => callback(this.currentAbortController?.signal));
        const results = await Promise.all(promises);
        this.currentAbortController = undefined;
        this._valid = results.every(x => x);
        return this._valid;
    }
}

export interface IValidationState
{
    readonly isValid?:boolean;
    readonly loading:boolean;
}

export interface IValidationControllerState extends IValidationState
{
    readonly signal:ValidationSignal;
}

export function useValidateController(): [IValidationControllerState, () => Promise<boolean>]
{
    //const [isValid, setValid] = useState<boolean>();
    //const [loading, setLoading] = useState(false);
    const signalRef = useRef<ValidationSignal>();
    const signal = signalRef.current = signalRef.current ?? new ValidationSignal();
    // const isValid = signal.valid;
    // const loading = signal.validating;

    const validate = useCallback(function(this:ValidationSignal) { return signalRef.current!.dispatch(); }, []);

    useEffect(() => () => signal.unload());

    const result = {
        get isValid() { return signal.valid; },
        get loading() { return signal.validating; },
        signal
    }

    return [result, validate];
}

export function useValidateConsumer(input:ValidationSignal | boolean | undefined, validate:IValidationFunction): [IValidationState, () => void]
{
    //const [currentValidate, setCurrentValidate] = useState(() => validate);
    const previousValidate = usePrevious(validate);
    //const validateRef = useDynamicRef(validate);
    /*const validateRef = useRef<IValidationFunction>();
    validateRef.current = validate;
    const [symbol] = useState(() => Symbol());*/
    //console.log(symbol);

    const signalRef = useRef<ValidationSignal>();
    const signal = signalRef.current;


    useEffect(() => {
        if(!signalRef.current) { return; }

        //console.log('replacing validate');
        if(previousValidate) { signalRef.current.unsubscribe(previousValidate) }
        signalRef.current.subscribe(validate);
        return () => signalRef.current?.unload();
    }, [validate, previousValidate]);

    const { valid:isValid, validating:loading } = signal ?? { valid:undefined, validating:false };
    const dispatch = useCallback(function(this:ValidationSignal) { signalRef.current!.dispatch(); }, [signal]);
    const { schedule } = useTimeout(dispatch, 500);
    const notifyChange = () => {
        if(input === true) { schedule(); }
    };

    useEffect(() => {
        signalRef.current = input && !isBoolean(input)? input: new ValidationSignal();
        notifyChange();
    }, [input]);

    return [{ isValid, loading }, notifyChange];
}