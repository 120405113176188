import { IImportProviderResultScreenProps } from './ImportProviderResultScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ImportProviderResultScreenApp } from './ImportProviderResultScreen.app';
import { ImportProviderResultScreenDesktop } from './ImportProviderResultScreen.desktop';
import { useParams } from 'react-router';
import { useFetchImportResultService } from 'services/contacts/provider/importResult';

export const ImportProviderResultScreen = (_props: IImportProviderResultScreenProps): JSX.Element => {
    const ImportProviderResultScreenPlatform = useResponsiveChoose(ImportProviderResultScreenDesktop, ImportProviderResultScreenApp);
    const param = useParams<{ importId: string }>();
    const [importResult] = useFetchImportResultService({ import_id: param.importId })
    return (
        <ImportProviderResultScreenPlatform
            result={importResult}
        />
    );
}
