import { IContactsEditTagsPopUpProps, IResponseData, IResponseStatusInfo } from './ContactsEditTagsPopUp.def';
import './ContactsEditTagsPopUp.scss';
import { ConfirmPopUp } from 'views/popups/ConfirmPopUp';
import React, { useEffect, useState } from 'react';
import {  useEditContactsTagsService } from 'services/contacts/editContactsTags';
import { SimpleDialog } from 'views/dialogs/SimpleDialog';
import { useFetchGetTagsService } from 'services/contacts/tags/getTags';
import { useResponsiveRender } from 'scripts/hooks/general';
import { ContactsEditTagsPopUpApp } from './ContactsEditTagsPopUp.app';
import { fetchSearchTagsService } from 'services/typeaheads/searchTags';
import { SelectedContacts } from 'views/contacts/component/SelectedContacts';
import { displayMessage } from 'scripts/hooks/feedbacks';


const ContactsEditTagsPopUpXL = (props: IContactsEditTagsPopUpProps): JSX.Element => {
  const { selectedContacts, reloadTags, setNewTagPop, manageTagPopUp, selectToggle, reload } = props;
  const [typeAheadText, setTypeAheadText] = useState<string>("")
  const [showPopup, setShowPopUp] = useState(false)
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [saveData, setSaveData] = useState<IResponseStatusInfo>({ status: '', message: "" })
  const [getTags, getTagsAction] = useFetchGetTagsService()
  const [availableTags, setSearchTagsData] = useState<Array<IResponseData>>()
  const [tags, setTags] = useState<Array<any>>()
  const [updateContactTagService,updateContactTagAction] = useEditContactsTagsService()


  const doSelectTags = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedTags([...selectedTags, id])
    } else {
      let list = selectedTags?.filter((tag) => id !== tag)
      setSelectedTags(list ? list : [])
    }

  }


  const fetchData = async (): Promise<void> => {
    setSelectedTags([])
    getTagsAction.fetch()

    return;
  };

  const getData = async () => {
    try {
      const response = await fetchSearchTagsService({ input: typeAheadText });
      setSearchTagsData(response)
    } catch (error) { setSearchTagsData([]) }
  }

  const handleUpdate = async () => {
    let requestTag: any = []
    selectedTags.map((tag) => requestTag.push({ id: tag, value: 1 }))
    tags?.filter((tag) => tag.status !== 1).filter((tag) => !selectedTags?.includes(tag.id)).map((tag) => requestTag.push({ id: tag.id, value: 0 }))
    let contactIds: any = [];
    selectedContacts.map(({ id }) => contactIds.push(id));
    try {
      const data: any = updateContactTagAction.fetch({ contact_ids: contactIds, tags: requestTag });
      if (data.status === 'ok') {
        displayMessage("Tags have been assigned.")
      }
      setSaveData(data)
      reload?.()
      setShowPopUp(false)
    } catch (error: any) {
      setSaveData({ status: 'error', message: "Tags are required" })
    }

    setTimeout(() => setSaveData({ status: '', message: "" }), 5000)
  }


  const tagStatus = (id: number): number => {
    let arr: any[] = []
    selectedContacts.map((contact: any) => {
      const filtered = contact.tags.filter((tag: any) => { return tag.id === id })
      if (filtered.length > 0) {
        arr.push({ [contact.id]: id })
      }
    })
    if (arr.length === 0) {
      return 0;
    } else if (selectedContacts.length === arr.length) {
      return 2
    } else {
      return 1
    }
  }


  /*  const isCheckable = (id: number): boolean => {
      if (selectedTags.find((item) => item === id)) {
        return true
      } else {
        return false
      }
    }*/



  const openNewTag = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setNewTagPop?.(true)
  }

  function setUpTags() {
    let arr: any = []
    let sel: any = []
    availableTags?.map((tags) => {
      arr.push({ id: tags.id, name: tags.name, status: tagStatus(tags.id) })
      if (tagStatus(tags.id) === 2) {
        sel.push(tags.id)
      }
    })

    arr.sort(function (a: any, b: any) {
      return b.status - a.status
    })
    setSelectedTags(sel)
    setTags(arr)
  }

  useEffect(() => {
    setSelectedTags([])
    if (selectedContacts.length === 0) {
      setShowPopUp(false)
    } else {
      fetchData()
    }

  }, [selectedContacts])

  useEffect(() => {
    if (typeAheadText !== "") {
      getData()
    } else {
      fetchData()
    }
  }, [typeAheadText])



  useEffect(() => {
    setSearchTagsData(getTags.response)

  }, [getTags])


  useEffect(() => {
    if (reloadTags) {
      fetchData()
    }
  }, [reloadTags])

  useEffect(() => {
    setUpTags()
  }, [availableTags])





  { saveData.status === 'ok' && <SimpleDialog message={saveData.message} /> }
  {/* saveData.status === 'error' && <ErrorDialog title={'Internal Error'}>{saveData.message}</ErrorDialog> */ }

  return (
    <>
      <button role="button" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" disabled={selectedContacts.length > 0 ? false : true} aria-label="Change Tag" onClick={() => setShowPopUp(true)}>Tags</button>
      <ConfirmPopUp isOpen={showPopup} toggleShow={() => setShowPopUp(false)} title="Edit Tags" className="custom--modal" closeButton>
        <ConfirmPopUp.Body>
          <div className="ion-padding bg-white">
            <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
              <SelectedContacts contacts={selectedContacts} selectToggle={selectToggle} />

              <div className="row mb-4">
                <div className="col-auto align-self-center"><div className="font_size--large font_weight--bold" tabIndex={0}>Tags</div></div>
                <div className="col ml-auto align-self-center text-right">
                  <a className="font_weight--semi_bold mr-3" href="#" onClick={(e) => { e.preventDefault(); manageTagPopUp?.() }}>Manage Tags</a>
                  <a className="font_weight--semi_bold" href="#" onClick={(e) => openNewTag(e)}>New Tag</a>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6 col-12">
                  <input className="form-control input-search_form" placeholder="Search Tags" type="text" onChange={(e: any) => setTypeAheadText(e.target.value)} value={typeAheadText} required={true} />
                </div>
              </div>
              <div className='row'>
                  {tags?.map((tag, ind: number) => {
                    return <div className='col-6'><div key={ind} role="checkbox" className={`custom-control custom-checkbox mb-2${tag.status === 1 ? `` : ``}`}>
                      <input type="checkbox" id={`checkbox_button_tag_` + ind} name="checkbox_button_medium" className="custom-control-input" onChange={(e) => doSelectTags(tag.id, e.target.checked)} />
                      <label className="custom-control-label" htmlFor={`checkbox_button_tag_` + ind}>{tag.name}</label>
                    </div></div>
                  })}
              </div>
            </div>
          </div>
        </ConfirmPopUp.Body>
        <ConfirmPopUp.ConfirmButton onClick={handleUpdate} loading={updateContactTagService.loading} disabled={updateContactTagService.loading} />
      </ConfirmPopUp>

    </>
  );
}

export const ContactsEditTagsPopUpDesktop = (props: IContactsEditTagsPopUpProps): JSX.Element => {
  return useResponsiveRender(<ContactsEditTagsPopUpXL {...props} />, <ContactsEditTagsPopUpApp {...props} />)
}
