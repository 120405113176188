import { ILoginFooterProps } from './LoginFooter.def';
import './LoginFooter.scss';

export const LoginFooter = (_props: ILoginFooterProps): JSX.Element =>
{
    const currentYear = new Date().getFullYear();
    return (
        <div className="crm_footer mt-auto border-top pt-3 pb-3 pb-md-0 text-center align-self-center">
            <div className="font_size--small d-none d-md-block pb-0">
                COPYRIGHT© {currentYear} HOUSTON REALTORS® INFORMATION SERVICE, INC. All Rights Reserved.
            </div>

            <div className="container container--v2 pt-md-2 pb-md-3 font_size--small">
                <a href="https://cms.har.com/accessibility/" target="_blank" className="d-inline-block mr-md-3 mr-1" rel='noreferrer'>Accessibility</a>
                <a href="https://cms.har.com/privacy-policy-2/" target="_blank" className="d-inline-block mr-md-3 mr-1" rel='noreferrer'>Privacy Policy</a>
                <a href="https://cms.har.com/termsofuse/" target="_blank" className="d-inline-block mr-md-3 mr-1" rel='noreferrer'>Terms of Use</a>
                <a href="https://cms.har.com/contact-us/" target="_blank" className="d-inline-block mr-md-3" rel='noreferrer'>Contact Us</a>
            </div>
        </div>
    );
}
