
import { useResponsiveChoose } from 'scripts/hooks/general';
import { ISelectWithSearchProps } from './SelectWithSearch.def';
import './SelectWithSearch.scss';
import { SelectWithSearchDesktop } from './SelectWithSearch.desktop';
import { SelectWithSearchApp } from './SelectWithSearch.app';


export const SelectWithSearch = (props: ISelectWithSearchProps): JSX.Element => {
    const SelectedWithSearchPlatform = useResponsiveChoose(SelectWithSearchDesktop, SelectWithSearchApp)

    return <SelectedWithSearchPlatform {...props} />

}
