import { ITaskStatsProps } from './TaskStats.def';
import './TaskStats.scss';
import {Loading} from './states/loading';

export const TaskStats = (props: ITaskStatsProps): JSX.Element =>
 {     
    const { today,overdue,completed,open}=props?.data||{overdue:0,open:0};
    const {filter,handleSetFilter,initialFilter}=props;
    if(!today){return <Loading column={props.column}/>}
  
    const isAllCalendar=filter?.date?.toString() ===""&&filter.completed===undefined&&filter.status_filter==="open"?true:false;
    const toggleOpenTask=()=>{
       if(!isAllCalendar){ handleSetFilter({date:"",completed:undefined,status_filter:isSelected("open")?"":"open"})}
       else{
       // delete filter['completed'];
        handleSetFilter({...filter,...initialFilter})}
    }
    const isSelected=(value:string)=>{
       return  filter?.status_filter===value;
    }
    const toggleOverdueFilter=()=>{
         delete filter['date'];
        handleSetFilter({...filter,date:"",completed:false,status_filter:isSelected("overdue")?"":"overdue"/*,completed:undefined*/})
    }
    const toggleCompletefilter=()=>{
        delete filter['date'];
        handleSetFilter({...filter,completed:props.filter.completed?undefined:true,status_filter:""})}
    return (
            <div className="pt-5 row">
                <div className={`col-${props.column??6}`}>
                    <div className={`cards cards__taskstatus ${isSelected("overdue")?"cards__taskstatus__selected":"cards__taskstatus"}`} onClick={toggleOverdueFilter}>
                        <div className="cards__taskstatus__date">{overdue}</div>
                        <div className="cards__taskstatus__status"><img src="https://content.harstatic.com/media/icons/crm/icon2.svg" /></div>				
                        <div className="cards__taskstatus__text">Overdue</div>
                    </div>
                </div>
                <div className={`col-${props.column??6}`}>
                    <div className={`cards cards__taskstatus ${props.filter.completed?"cards__taskstatus__selected":"cards__taskstatus"}`} onClick={toggleCompletefilter}>
                        <div className="cards__taskstatus__date">{completed}</div>
                        <div className="cards__taskstatus__status"><img src="https://content.harstatic.com/media/icons/crm/completed.svg" /></div>				
                        <div className="cards__taskstatus__text">Complete</div>
                    </div>
                </div>
                <div className={`col-${props.column??6}`}>
                    <div className={`cards cards__taskstatus ${isAllCalendar?"cards__taskstatus__selected":"cards__taskstatus"}`} onClick={toggleOpenTask}>
                        <div className="cards__taskstatus__date">{parseInt(open.toString())}</div>
                        <div className="cards__taskstatus__status"><img src="https://content.harstatic.com/media/icons/crm/icon4.svg" /></div>				
                        <div className="cards__taskstatus__text">Open</div>
                    </div>
                </div>
            </div>
    );
}
