import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { createBrowserHistory } from "history";
import { useValidateController } from 'scripts/hooks/validations';
import { AddressesSection } from './components/AddressesSection';
import { IAddressItem } from 'views/contacts/AddressesSection/AddressesSection.def';
import { BasicInformationSection } from 'views/contacts/BasicInformationSection';
import { IBasicInfoData } from 'views/contacts/BasicInformationSection/BasicInformationSection.def';
import { ContactInsightSection } from 'views/contacts/ContactInsightSection';
import { IContactInsightData } from 'views/contacts/ContactInsightSection/ContactInsightSection.def';
import { OrdinaryButton, ProminentButton } from 'views/forms/buttons/Button';
import { IEditContactScreenProps } from './EditContactScreen.def';
import './EditContactScreen.scss';
import { useParams } from 'react-router';
import { useClassOnSticky } from 'scripts/hooks/general';
//import { IStageAddContactsProps } from 'views/contacts/pipeline/StageColumn/StageColumn.def';
import { useIonAlert } from '@ionic/react';
import { useServiceDidFailed, useServiceDidSucceeded } from 'scripts/hooks/useService';
import { useGetContactService } from 'services/contacts/contactDetails/getContact';
import {UpdateContactApi, initialAddress, transformEditData, useUpdateContactService } from 'services/contacts/contactDetails/updateContact'
import { ContactSection } from './components/ContactSection';
//import { IContactData } from './components/ContactSection/ContactSection.def';
import {IContactDataApp,IEmailDataApp,IPhoneDataApp} from './components/ContactSection/ContactSection.def'
//import { useDeleteEmailService } from 'services/contacts/contactDetails/deleteEmail';
import { RelationshipSection } from 'views/contacts/RelationshipSection';
//import { displayMessage } from 'scripts/hooks/feedbacks';
//import {IRelationItemApp} from 'views/contacts/RelationshipSection/RelationshipSection.def'
import { CreateContactApi, useCreateContactService } from 'services/contacts/createContact';
import { IStageAddContactsProps } from 'views/contacts/pipeline/StageColumn/StageColumn.def';
import { redirectBackIfApplied } from './EditContactScreen.common';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { isNumber } from 'lodash-es';
import { IRelationItem } from 'views/contacts/RelationshipSection/RelationshipSection.def';
import { ifElse } from 'scripts/helpers/general';
import { allEmpty } from 'scripts/helpers/texts';
import { duplicatedContactMessage } from 'scripts/validations/messages';
import { ContactsConfirmDelete } from 'views/contacts/popups/ContactsConfirmDelete';
//import { useDeleteRelationshipService } from 'services/contacts/contactDetails/deleteRelationship';

const browserHistory = createBrowserHistory({ forceRefresh: true });
const emptyRelationForm = {
    name: { value: "", error: "" },
    relationType: { value: "", error: "" }
  }
export const EditContactScreenDesktop = (_props: IEditContactScreenProps): JSX.Element => {
    const { id } = useParams<{ id: string }>();

    const [showPopUp, setShowPopUp] = useState(false)

    const history = useHistory();
    const [validation, validate] = useValidateController();
    const [updateData, updateActions] = useUpdateContactService();
    const [createData, createActions] = useCreateContactService();
    //const [, ActionDeleteEmail] = useDeleteEmailService();
    const [contactDetails,getContactDetails] = useGetContactService();
    useEffect(()=>{!!id&& getContactDetails.fetch({ contact_id:parseInt(id), renew: '1' })},[])
    useEffect(()=>{
      if(!!id){ 
        const {basicInfoDt,addressesDt,relationshipsDt,contactDataDt,contactInsightDt}=transformEditData(contactDetails.response);

        // HACK [Temporary]: Injecting email if not present
        /*if(contactDataDt.emails.length === 1 && !contactDataDt.emails[0]!.id && !!contactDetails.response?.primary_email?.trim()) {
          contactDataDt.emails[0]!.value = contactDetails.response.primary_email;
        }*/

          setBasicInfo(basicInfoDt);
          setAddresses(addressesDt);
          setRelationships(relationshipsDt);
          setContactInsight(contactInsightDt);
          setContactData(contactDataDt);
    }else{
      setAddresses([initialAddress()])
    }
    },[contactDetails.response]);
 
    const [basicInfo, setBasicInfo] = useState<IBasicInfoData>({});
    const [addresses, setAddresses] = useState<IAddressItem[]>();
    const [relationships, setRelationships] = useState<IRelationItem[]>([{ type:'' }]);
    const [contactInsight, setContactInsight] = useState<IContactInsightData>();
    const [contactData, setContactData] = useState<IContactDataApp>({
        _placeholder:true, // hack to know when data came from API
        phones:[{value: "",error:"",preferred:true,phone_type:"",id:undefined}],
        emails:[{id:undefined,value:"",error:"",preferred:true,importEmail:true}],
        links:[{id:undefined,value:"",error:"",link_type:""}]} as IContactDataApp);

    const [isError, toggleError] = useState({ error:false });
   // const [, setLinks] = useState<ILinkItem[]>();
    const stickyRef = useRef<HTMLDivElement>(null);
    const formEmptyRef = useRef(true);
    //const [presentCancelConfirmation, dismissCancelConfirmation] = useIonAlert();
    const [presentError] = useIonAlert();
    const [showConfirmation, setShowConfirmation] = useState(true);
    let location = useLocation<IStageAddContactsProps>();
    

    useServiceDidSucceeded(id?updateData:createData, () => redirectAfterSuccess());

    useServiceDidFailed(id?updateData:createData, (error) => {
      if(error.data?.is_duplicated) {
        error.preventDefault();
        presentError({
          header: 'Duplicated Contact',
          message: duplicatedContactMessage(),
          buttons:['OK'],
          cssClass: 'p-3 line-h-1_2'
        });
      }
    });

    /*const showCancelConfirmation = () => {
        presentCancelConfirmation({
            header: 'Cancel Update Contact?',
            message: 'Are you sure you want to cancel Update contact, the data will be lost?',
            buttons: [
                { text: `Continue to ${id?"Update":"Create"} Contact`, handler: dismissCancelConfirmation },
                { text: `Cancel ${id?"Update":"Create"} Contact`, cssClass: 'btn btn--medium btn--primary', handler() { cancel(false); } }
            ]
        });
    }*/

    const showRequiredFieldsError = () =>
    {
      presentError({
          header: 'Required Fields',
        message: `Please provide at least one of the following:<br />first name, last name or company, along with either a primary phone number or primary email address.`,
        buttons:['OK'],
        cssClass: 'p-5'
      });
    }

    useEffect(() => {
        const confirmationFunction = (e:any) => {
            if(showConfirmation){
            const confirmationMessage = 'Are you sure you want to leave this page? Your changes may not be saved.';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
            }
            return undefined;
          };
          window.addEventListener('beforeunload', confirmationFunction);
        return () => {
          window.removeEventListener('beforeunload', confirmationFunction);
        };
      }, [showConfirmation]); 
    
      useEffect(() => {
        if (location.state) {
            if (location.state.first_name) {
                if (location.state.last_name) {
                    setBasicInfo({
                        ...basicInfo,
                        firstName: location.state.first_name,
                        lastName: location.state.last_name
                    })
                } else {
                    setBasicInfo({
                        ...basicInfo,
                        firstName: location.state.first_name
                    })
                }
            }
            if (location.state.primary_email) {
                setContactData({
                    'emails': [{
                        value: location.state.primary_email,
                        preferred: true,
                        error :'',
                        importEmail:false
                    }],
                    'phones': [],
                    'links':[]
                  
                })
            }
            if (location.state.pipeline_status) {
                setContactInsight({ pipelineType: location.state.pipeline_status.toString() })
            }
        }
    }, [location]);


    // Watch for querystring parameters
    useEffect(() => {

      // Return if it's not a new contact
      if (!!id) { return; }

      // Getting the following parameters from the querystring (first_name, last_name, primary_email, primary_phone)
      const params = new URLSearchParams(location.search);
      const firstName = params.get('first_name')?.trim();
      const lastName = params.get('last_name')?.trim();
      const primaryEmail = params.get('primary_email')?.trim();
      const primaryPhone = params.get('primary_phone')?.trim();

      // Setting each information
      if(firstName || lastName)
      {
        setBasicInfo((prev) => ({ ...prev, firstName, lastName }));
      }

      if(primaryEmail || primaryPhone)
      {
        setContactData((prev) => {
          const result = { ...prev };
          const { emails, phones } = result;
          if(!emails.length) { emails.push({ value:'', preferred: true, error: '', importEmail: false }) }
          if(!phones.length) { phones.push({ value:'', preferred: true, error: '', phone_type: '', id: undefined }) }
          if(primaryEmail) { emails[0]!.value = primaryEmail; }
          if(primaryPhone) { phones[0]!.value = primaryPhone; }
          return result;
        });
      }

      // Removing first_name, last_name, primary_email and primary_phone from query string
      ['first_name', 'last_name', 'primary_email', 'primary_phone'].forEach(x => params.delete(x));
      history.replace({ ...history.location, search:params.toString() });

    }, [location.search]);
    
   
    useClassOnSticky(stickyRef, { sticky: 'sticked', target: document.body });
    const removeEmptyField=(items:Array<IPhoneDataApp|IEmailDataApp>)=>{
      const item:Array<IPhoneDataApp|IEmailDataApp>=[];
      items.map((x)=>x.value!==''&&item.push(x));
      return item;
    }
  
    const saveContact = async () => {
      
     //  if (validation.loading) { return }
      const validation = await validate();
      if(!validation) { return; }
      const emails = removeEmptyField(contactData?.emails) as IEmailDataApp[];
      const phones= removeEmptyField(contactData?.phones) as IPhoneDataApp[];
     // const links= contactData.links.filter((item)=>item.value!=='');
      const relations=relationships.filter((item)=>!!item.contact);

      // Checking required fields
      const { firstName, lastName, company } = basicInfo;
      const preferredEmail = emails.find(x => x.preferred)?.value?.trim();
      const preferredPhone = phones.find(x => x.preferred)?.value;
      const hasName = [firstName, lastName, company].some(x => !!x?.trim());
      const hasContact = [preferredEmail, preferredPhone].some(x => !!x?.trim());
      const hasRequiredFields = hasName && hasContact;
      if (!hasRequiredFields)
      {
        showRequiredFieldsError();
        return toggleError({ error:true });
      }
      const formData:CreateContactApi.Request | UpdateContactApi.Request = {
          contact_id:id,
          photo_url: basicInfo.image,
          first_name: basicInfo.firstName!,
          last_name: basicInfo.lastName!,
          middle_name: basicInfo.middleName,
          title: basicInfo.title,
          name_suffix: basicInfo.suffix,
          gender: basicInfo.gender!,
          birthday: basicInfo.dateOfBirth,
          known_since: basicInfo.knownSince,
          income: basicInfo.income,
          job_title: basicInfo.jobTitle,
          company: basicInfo.company,
          anniversary_date: basicInfo.weddingAniversary,
          home_anniversary_date: basicInfo.homeAniversary,
          reminder_days_after:basicInfo.reminder_days_after?.toString(),
          score: contactInsight?.scoreType,
          pipeline_status: contactInsight?.pipelineType ?? '10',
          sources: contactInsight?.sourceType,
          tags: contactInsight?.tags,
          lead_type: contactInsight?.leadType,
          emails: emails.map(x =>({...x,email:x.value})),
          phones: phones?.map(x => ({ ...x,phone:x.value, phone_extension:x.phone_extension })),
          relationships: relations.map(x => ({id:x.id, type: x.type, related_contact_id: x.contact!.contact_id })),
          addresses: addresses?.filter(x=> !allEmpty(x.address1,x.address2??'',x.city,x.zipcode,x.state??"",x.country??"")),
          links: contactData.links.map(x => ({ ...x,name: x.value==''?'':x.link_type, url: x.value==''?x.link_type:x.value })),
          contact_type: 'None', // don't know
      }
       if(!!id){ 
        await updateActions.fetch(formData as UpdateContactApi.Request);
      }else{
        await createActions.fetch(formData as CreateContactApi.Request);
      }
        await setShowConfirmation(false)
     
        return true;
    };

    const setBasicInfoFromUser = (data: IBasicInfoData) => setDataFromUser(data, setBasicInfo);
    const setContactInsightFromUser = (data: IContactInsightData) => setDataFromUser(data, setContactInsight);
   // const setAddressesFromUser = (data: IAddressItem[]) => setDataFromUser(data, setAddresses);
    //const setRelationshipsFromUser = (data: IRelationItem[]) => setDataFromUser(data, setRelationships);
   //const setContactsFromUser = (data: IContactDataApp) => setDataFromUser(data, setContactData);
    //const setLinksFromUser = (data: ILinkItem[]) => setDataFromUser(data, setLinks);

    function setDataFromUser<T>(data: T, setData: (data: T) => void) {
        toggleError({ error:false });
        formEmptyRef.current = false;
        setData(data);
    };

    const redirectAfterSuccess =() => {
      displayMessage(id ? "The contact has been successfully updated" : "The contact has been successfully added");
      if(redirectBackIfApplied(id? { }: { contact_id:createData.response?.id })) { return; }
      browserHistory.goBack();
    };

    const onCancel = () => { cancel(true); }
    const handleAddContact = (name: string) => setContactData((prev: any) => ({ ...prev, [name]: [...prev[name], { value: "", error: "", preferred: false, importEmail: false,phone_type:"",link_type:"" }] }))

    const handleRemoveContact = (name: string, index: number) => {
      
      /*if (name === "emails"){  
        if(contactData.emails[index]?.id !== undefined)
            {
            try{ ActionDeleteEmail.fetch({
                    contact_id: id!,
                    id: contactData.emails[index]?.id?.toString()??"",
                });
                contactData?.emails.splice(index, 1)
                setContactData((prev: any) => ({ ...prev, emails: contactData?.emails }));
            }catch(e){}
        
    }else{
        contactData?.emails.splice(index, 1)
      setContactData((prev: any) => ({ ...prev, emails: contactData?.emails }));}}*/

      if (name === "emails")
        contactData?.emails.splice(index, 1)
        setContactData((prev: any) => ({ ...prev, emails: contactData?.emails }));
      if (name === "phones")
        contactData?.phones.splice(index, 1)
      setContactData((prev: any) => ({ ...prev, phones: contactData?.phones }));
      if (name === "links")
        contactData?.links?.splice(index, 1)
      setContactData((prev: any) => ({ ...prev, links: contactData?.links }));
     
    };

    const handleChangeContactField = (index: number, name: string, value: string, preferred?: boolean, importEmail?: boolean,phone_type?:string,phone_extension?:number|string,link_type?:string) => {
        
        if (name === "emails") {
          if (preferred !== contactData?.emails[index]?.preferred) {
            setContactData((prev: any) => ({ ...prev, emails: contactData?.emails.map((item) => ({ ...item, preferred: false ,id:contactData?.emails[index]?.id})) }))
          }
          contactData.emails[index] = { value: value, error: "", preferred: preferred || false, importEmail: importEmail || false,id:contactData?.emails[index]?.id }
          setContactData((prev: any) => ({ ...prev, emails: contactData?.emails }));
        }
        if (name === "phones") {
          if (preferred !== contactData?.phones[index]?.preferred) {
            setContactData((prev: any) => ({ ...prev, phones: contactData?.phones.map((item) => ({ ...item, preferred: false,id:contactData?.phones[index]?.id })) }))
          }
          if(phone_extension !== undefined && !isNumber(phone_extension)) { phone_extension = ifElse(!!phone_extension.trim(), parseInt(phone_extension.trim().slice(0,6))) ?? undefined; }
          contactData.phones[index] = { value: value, error: "", errorField:undefined, preferred: preferred || false,phone_type:phone_type??"",phone_extension,id:contactData?.phones[index]?.id}
          setContactData((prev: any) => ({ ...prev, phones: contactData?.phones }));
        }
        if (name === "links") {
          contactData.links[index] = { value: value, error: "", link_type:link_type??"",id:contactData?.links[index]?.id}
          setContactData((prev: any) => ({ ...prev, links: contactData?.links }));
        }
      }
      const handleAddRelation = () => setRelationships((prev: any) => ([...prev, { ...emptyRelationForm }]))
      const handleRemoveRelation = (index: number) => {
        relationships?.splice(index, 1);
        setRelationships?.([...relationships]);
      };
      
      function handleChangeRelationField<K extends keyof IRelationItem>(index: number, name:K, value:IRelationItem[K])
      {
        const item = relationships[index];
        if(item) { item[name] = value; }
        
        setRelationships(current => {
          const item = current[index];
          if(!item) { return current; }
          item[name] = value;
          return [ ...current ];
        });
      }

    const cancel = async (_confirm: boolean) => {
      //  if (confirm && !formEmptyRef.current) { return showCancelConfirmation(); }
        if(redirectBackIfApplied()) { return; }
        if (window.history.state) { history.goBack(); }
        else { history.goBack(); }

    };

    return (<div>
            <div className="position-sticky page-sticky-header contact_sticky_title ml-auto mr-auto" style={{ top: -1}} ref={stickyRef}>
                <div className="container">
                    <div className="blured_bg">
                        <div className="row pt-4 pb-4">
                            <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-3">
                                <h2 tabIndex={(0)} className="h2--simple h2--simple__black mb-0">{id?"Edit":"Add"} Contact</h2>
                            </div>
                            <div className="col-sm-6 col-12 align-self-center text-left text-sm-right">
                                <OrdinaryButton className="mb-0" onClick={onCancel}>Cancel</OrdinaryButton>
                                <ProminentButton disabled={updateData.loading || validation.loading} className="btn btn--prominent__v2 ml-4 mb-0" onClick={saveContact} loading={validation.loading||contactDetails.loading}>{id?"Update":"Create"} Contact</ProminentButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {contactDetails.response?.id&&
            <ContactsConfirmDelete
                show={showPopUp ? true : false}
                toggleShow={() => setShowPopUp(false)}
                contacts={[contactDetails.response]}
                onDelete={()=>{//displayMessage("Contact has been deleted succesfully");window.location.href = "/contacts";
                history.push("/contacts")
                }}
            />}
            <div className="container pb-5">    
                <div className="row mt-1" autoFocus={true}>
                    <div className="col-lg-6 col-12">
                        <BasicInformationSection isEdit={true} loading={updateData.loading} errorRef={isError} id={id} validate={validation.signal} onItemsChange={setBasicInfoFromUser} data={basicInfo} />
                        <AddressesSection onItemsChange={setAddresses} validate={validation.signal} initialItems={addresses} />

                        {/**<AddressesSection id={id} isEdit={true} onItemsChange={setAddressesFromUser} initialItems={addresses}/>*/}
                    </div>
                    <div className="col-lg-6 col-12">
                        <ContactInsightSection isEdit={true} validate={validation.signal} onChange={setContactInsightFromUser} initialValues={contactInsight} />
                        <ContactSection setContact={setContactData} validate={validation.signal} handleAddNew={handleAddContact} handleRemoveContact={handleRemoveContact} handleChangeField={handleChangeContactField} items={contactData} errorRef={isError} />
                        <RelationshipSection.Form contactId={id? parseInt(id): undefined} setRelationship={setRelationships} validate={validation.signal} handleAddNew={handleAddRelation} handleRemoveRelation={handleRemoveRelation} handleChangeField={handleChangeRelationField} items={relationships} />
                     
                      {/**  <ContactSection  isError={isError} onChange={setContactsFromUser} initialEmails={contactData?.emails} initialPhones={contactData?.phones} />*/}
                        {/**   <LinksSection validate={validation.signal} isEdit={true} onLinkChange={setLinksFromUser} initialLinks={links} />
                        <RelationshipSection isEdit={true} onItemsChange={setRelationshipsFromUser} initialItems={relationships} />*/}  </div>
                </div>
                {id && <div className="row pt-4 pb-4">
                        <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-3"> <OrdinaryButton className="btn btn--simple btn--red btn--small" aria-label="Search" onClick={() => setShowPopUp(true)}>Delete this contact</OrdinaryButton></div>
                             <div className="col-sm-6 col-12 align-self-center text-left text-sm-right">
                             <OrdinaryButton className="mb-0" onClick={onCancel}>Cancel</OrdinaryButton>
                             <ProminentButton disabled={updateData.loading || validation.loading} className="btn btn--prominent__v2 ml-4 mb-0" onClick={saveContact} loading={validation.loading||contactDetails.loading}>{id?"Update":"Create"} Contact</ProminentButton>
                    </div>
                    </div>}
                {/*createData.error && <ErrorDialog title="Contact not created" autoClose={true}>{createData.error.message ?? 'Internal Error'}</ErrorDialog>*/}
            </div>
       </div>
    );
}
