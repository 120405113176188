import { useRef, useState } from "react";
import { DefaultTemplate } from "views/templates/DefaultTemplate";
import { ICalendarLandingScreenProps } from "./CalendarLandingScreen.def";
import { ICalendarTimelineScreenState} from '../CalendarTimelineScreen/CalendarTimelineScreen.def';

import { CalendarTimelineScreen } from "../CalendarTimelineScreen";
import { CalendarOverviewScreen } from "../CalendarOverviewScreen";
import { CalendarTemplate } from "views/templates/CalendarTemplate";
import { GetCalendarTasksApi } from "services/calendar/getCalendarTasks";
import { useHistory } from "react-router";
import moment from 'moment';


export const CalendarLandingScreenDesktop = (props: ICalendarLandingScreenProps): JSX.Element => {
    const { taskId } = props
    const history = useHistory<ICalendarTimelineScreenState>()
    const { state = {} } = history.location;
    const getStateDate=()=>{
        try{
            return !!state?.filter ? JSON.parse(state.filter).start_dt:moment()
        }catch(error){}
        return moment()
    }

    const contentRef = useRef(null)
    const [timelineFilter, setTimelineFilter] = useState<GetCalendarTasksApi.Request>({ categories: "T,E" });
    const [section, setSection] = useState("timeline");
    const [refreshData, handleRefreshData] = useState(false)
    const [month, setMonth] = useState(state?.filter ?getStateDate():moment())


    const onClickOverviewDay = (date: Date) => {
        setSection("timeline");
        setTimelineFilter({ ...timelineFilter, date: date })
    }

    

    return (
        <DefaultTemplate title="Calendar" contentRef={contentRef}>
            <CalendarTemplate setMonth={setMonth} month={month} onFilterChange={setTimelineFilter} onSectionChange={setSection} refreshData={handleRefreshData} section={section}>
                {section === "timeline" ? <><CalendarTimelineScreen setMonth={setMonth} taskId={taskId} contentRef={contentRef} month={month} filter={timelineFilter} refreshData={refreshData} /></> :
                    section === "overview" ? <CalendarOverviewScreen  month={month} filter={timelineFilter} onFilterChange={setTimelineFilter} refreshData={refreshData} onClickDay={onClickOverviewDay} /> : <></>}
            </CalendarTemplate>
        </DefaultTemplate>)
}