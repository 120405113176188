export enum eOnboardingStage
{
    Welcome = 'WEL',
    BasicInfo = 'BIN',
    AccountConnection = 'CYC',
    ContactImport = 'CIM',
    Complete = 'COM'
}


export const eOnboardingStageIndex:Record<eOnboardingStage | keyof typeof eOnboardingStage, number> = {
    Welcome:1,
    [eOnboardingStage.Welcome]: 1,
    BasicInfo:2,
    [eOnboardingStage.BasicInfo]: 2,
    AccountConnection:3,
    [eOnboardingStage.AccountConnection]: 3,
    ContactImport:4,
    [eOnboardingStage.ContactImport]: 4,
    Complete:5,
    [eOnboardingStage.Complete]: 5
};