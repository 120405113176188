import { Avatar } from 'views/contacts/Avatar';
import { IContactCardProps } from './ContactCard.def';
import './ContactCard.scss';
import { useHistory } from 'react-router';
import { IonIcon } from '@ionic/react';
import { stagesOptions } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { ContactTitle } from 'views/general/ContactTitle';


export const ContactCard = (props: IContactCardProps): JSX.Element => {
    let history = useHistory();
    const { contact, miniCard, inList, pipeline, redirectToContact, showDeal, referrer, addContact, removeContact } = props
    const addToList = () => {
        if (pipeline && !inList) {
            addContact?.(contact, { id: contact.id, pipeline: pipeline,pipeline_text : getStageOption(pipeline.toString())?.long_value})
            // clear?.()
        }
    }


    function getStageOption(status: string) {
        let stage = stagesOptions.filter((s) => Number(status) === s.short_value)
        return stage[0]
    }

    const loadDetail = () => {

        if (removeContact) {
            removeContact(contact.id, pipeline ? pipeline : 0, contact.first_name + ' ' + contact.last_name)
        }


    }
    const redirect = () => {
        history.push(`/contacts/` + contact.id + `/overview`, referrer ? { referrer: referrer } : '')
    }
    const stage=contact.pipeline_status_text=="Active Listing / Under Contract"?"Active Listing":contact.pipeline_status_text
    return (
        <div  className={`agent_signature max-w-160 ${miniCard ? "agent_signature__medium" : "agent_signature__large"}  ${inList ? `opacity-64pr` : ``}`}>
            <div>
                <Avatar
                id={contact.id}
                firstName={contact.first_name}
                photo={contact.photo_url}
                lastName={contact.last_name}
                middleName={contact.middleName}
                link={`/contacts/` + contact.id + `/overview`}
                />
            </div>
            <div className="agent_signature__text text-truncate" >
                <div className="row no-gutters">
                    <div className="col  text-truncate">
                        <div className="agent_signature__text__title text-truncate cursor--hand" onClick={() => redirectToContact ? redirect() : addToList()}>
                            <ContactTitle contact={contact} />
                        </div>
                        {miniCard &&
                            <div className={`color_available mt-1 ${contact.pipeline_status ? `d-block` : `d-none`}`}><img className="mr-1" src="https://content.harstatic.com/media/icons/check_mark_green.svg" />
                                {stage}

                            </div>
                        }

                    </div>

                    {!miniCard &&
                        <div className='position-absolute right-10px top-5 edit__eye'>
                            <div className="edit_dotes animate animate__fadeIn" onClick={() => loadDetail()}><IonIcon className='viewContactDetailPopover font_size--large mt-1px' name="eye-outline"></IonIcon>

                            </div>
                        </div>
                    }
                </div>
                {showDeal && contact.deal_stages?.map((deal: any, index: number) =>
                    <div key={index} className="message--success cursor_pointer message--small p-2 mt-2 d-inline-block">{deal.name + ' (' + deal.total_deals + ')'}</div>
                )}
            </div>
        </div>
    );
}
