import { LinksSectionDesktop } from "./LinksSection.desktop";
import { ILinksSectionProps } from './LinksSection.def';
import './LinksSection.scss';
import { useComponent } from 'scripts/context';
import { LinksSectionApp } from "./LinksSection.app";


export const LinksSection = (props: ILinksSectionProps): JSX.Element =>
{
  const LinksSectionSectionPlatform = useComponent(LinksSectionDesktop, LinksSectionApp);
  return (
      <LinksSectionSectionPlatform {...props} />
  );
}