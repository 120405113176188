import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { formatHarTime,formatHarDate } from "scripts/helpers/dates";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace GetNotificationsApi
{
    export const url = "/notifications";
    export const method = "GET";
    export const defaultError = "Failed to load data";
    export const operations = apiOperations(GetNotificationsApi);
    export const scope = new class GetNotificationsApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
				...data,
                page: parseInt(data.page),
                page_size:parseInt(data.page_size),
                total:parseInt(data.total),
                total_pages:parseInt(data.total_pages),
               list: data.list.map((x) => ({...x,formatted_date:(x.date_tag=='older'?formatHarDate(x.created_dt):"")+" "+formatHarTime(x.created_dt)}))
			}
        }
    }();
    
    export interface Request
    {
        types?:string ;
        input?:string;
       // page_size: string;

    }

    export interface Response
    {
        total: number,
        page: number,
        page_size: number,
        total_pages: number,
        next_page_url: string,
        prev_page_url: string,
        list:Array<IListData>
    }
    export interface IListData{
        created_dt: string,
        date_tag:string,
        type_object_id:string,
        id:string,
        read_dt:string,
        status:string,
        title:string,
        first_name:string,
        last_name:string,
        deleted:string
        sub_type:string,
        sub_type_text:string,
        type:string,
        userid:string,
        photo:string,
        formatted_date:string,
        payload:IPayload}
   
        export interface IPayload{
        alert: string
        id: string
        message: string
        sound: string
        type: string
        ids?:Array<string>
    }
    
}


export const fetchGetNotificationsService = forwardOperation(GetNotificationsApi.operations, 'fetch');
export const useFetchGetNotificationsService = forwardOperation(GetNotificationsApi.operations, 'useFetch');
export const useGetNotificationsService = forwardOperation(GetNotificationsApi.operations, 'useService');