import { IDefaultTemplateMoreOptionsProps, mobileMenuItems, MobileMenuItemsKey,IDefaultTemplateWrapper, environment, showVersion, IDefaultTemplateFinalProps } from './DefaultTemplate.def';
import './DefaultTemplate.scss';
import { IonContent,  IonHeader, IonImg, IonLabel, IonFooter, IonPage, IonRouterLink, IonTabBar, IonTabButton,IonRefresher,IonRefresherContent,RefresherEventDetail } from '@ionic/react';
import { useEffect, useState } from 'react';
import { isProduction, mopHost, version } from 'scripts/application/settings';
import { Auth } from 'scripts/security/AuthService';
//import { useHistory } from 'react-router';
import { useFetchGetBasicInfoService } from 'services/user/getBasicInfo';
import { Badge } from '@awesome-cordova-plugins/badge';
import { useFetchNotificationSummaryService } from 'services/notifications/notificationSummary';
import { Instabug } from "instabug-cordova";
//import { eOnboardingStageIndex } from 'scripts/enums';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { Tag } from 'scripts/ui/tag';


export const DefaultTemplateApp = (props: IDefaultTemplateFinalProps): JSX.Element =>
{
  const { isDragging,CustomMobileHeader, title,bottomFixElement,total_unread, showIcon,noContent,onSwipeRefresh, ionContentProps } = props;
  const [more, setMore] = useState(false);
  const selectedMenu = more? undefined: props.selectedMenu;
  const menuKeys = Object.keys(mobileMenuItems) as MobileMenuItemsKey[];
  const menu = menuKeys.map(key => ({ key, item:mobileMenuItems[key] }));
  const [basicInfo] = useFetchGetBasicInfoService();
  const [unread,setUnread] = useState<number>()
  //const menuMoreKeys = Object.keys(mobileMenuMoreItems) as MobileMenuMoreItemsKey[];
  //const menuMore = menuMoreKeys.map(key => ({ key, item:mobileMenuMoreItems[key] }));
  const [data]=useFetchNotificationSummaryService()
  useEffect(()=>{
    Badge.set(data.response?.total_unread??0);
  },[data]);

  useServiceDidSucceeded(basicInfo,(response) => {
    setUnread(Number(response.emails?.total_unread))
    
  })

  //if(basicInfo.error || basicInfo.loading) { return <></>; }

  const { response } = basicInfo;
  const children = more? <AppMoreOptions user={response} />: props.children;
  //const enableMenu = response? eOnboardingStageIndex[response.onboarding_stage] > eOnboardingStageIndex.AccountConnection: false;
  const enableMenu = true;

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
        onSwipeRefresh?.()
      event.detail.complete();
    }, 1000);
  }
  return (
    <IonPage className={props.className}>
      <Wrapper className="crm_layout" noContent={noContent}>  
        <>
      {!more && !isDragging && <IonHeader className={noContent ? `crm_layout__header ion-padding ion-padding-top` : `inner_page_header ion-padding-start ion-padding-end`}>
      {/* {showIcon && <img alt="HAR" className=' w-40px h-40px' src="https://content.harstatic.com/resources/images/common/logo-blue.svg" />} */}
      {/*showIcon && <img src="https://content.harstatic.com/media/icons/crm/crm_appicon.png" className='rounded w-40px h-40px mr-2' />*/}
      {showIcon && <img src="https://content.harstatic.com/media/crm/crm_app_logo_3.svg" className='rounded w-40px h-40px mr-2' />}
         <h1 className="header_title text-center">{title}</h1>
         {CustomMobileHeader && <div className="ml-auto align-self-center"><CustomMobileHeader /></div>}
      </IonHeader>}
      {noContent ?
        <div className="crm_layout__body">
            {children? children: <><img src="https://content.harstatic.com/media/temprary/app_homescreen.png" /></>}
        </div>
        :
        <IonContent {...ionContentProps}  fullscreen={true} className=" ion-padding-bottom" ref={props.contentRef}>
            {onSwipeRefresh && 
               <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                 <IonRefresherContent></IonRefresherContent>
               </IonRefresher>
            }
            {children? children: <><img src="https://content.harstatic.com/media/temprary/app_homescreen.png" /></>}
        </IonContent> 
     }
        {bottomFixElement && 
            <div className="custom_footer custom_footer_app_content_management border-bottom w-100 zindex-100">
                {bottomFixElement}
            </div> 
        }
      
       <Footer className="crm_layout__footer" noContent={noContent}>  
          <IonTabBar className="cra_footer_tabs">
            {menu.map(({ key, item }) => (
              <IonTabButton className="withcallout" disabled={!enableMenu} tab={key} selected={key === selectedMenu} href={item.url} onClick={() => setMore(false)} key={key}>
                <IonImg className="filterIt" src={item.icon} />
                <IonLabel className="menu_label">{item.text}
                    {item.text === 'Emails' && unread ?
                    <span className='icon_callout'>{total_unread ? total_unread : unread}</span> : <></>
                    }
                </IonLabel>
            </IonTabButton>
            ))}
            <IonTabButton className="ion-padding-end" tab="more" selected={more} onClick={() => setMore(true)} key="More">
                <IonImg   className="filterIt" src="https://content.harstatic.com/media/icons/crm/menu/icon-more.svg" />
                <IonLabel   className="menu_label">More</IonLabel>                                                    
            </IonTabButton>
          </IonTabBar>
        </Footer>

      </>
    </Wrapper>
    </IonPage>
  );
}

const Wrapper = (props: IDefaultTemplateWrapper) => {
    const {children,className,noContent} = props
    return (
        <>
            {noContent ?
            <div className={className}>{children}</div>
            :
            children
            }
        </>
    );
}


const Footer = (props: IDefaultTemplateWrapper) => {
    const {children,className,noContent} = props
    return (
        <>
        {noContent ?
            <Wrapper className={className} noContent={noContent}>{children}</Wrapper>
        :
            <IonFooter slot="bottom">{children}</IonFooter>
        }
        </>
    );    
}









const AppMoreOptions = (props:IDefaultTemplateMoreOptionsProps) =>
{
    //const history=useHistory()
   // const [showPreferencesModal, setPreferencesModal] = useState(false);
    //const [showRecommendedModal, setRecommendedModal] = useState(false);
   // const [basicInfo] = useFetchGetBasicInfoService();

    if(!props.user) { return <></>; }
      /*const navigate=()=>{
    history.push('/calendar')
  }*/
    //const versionText = isProduction? '': ` v${version}`;
    const versionText = ` v${version}`;

    const signOutHandler = (e:React.MouseEvent) =>
    {
        e.preventDefault();
        Auth.signOut();
    };

   // const enableMenu=!!basicInfo.response?.accountSynced && !!basicInfo.response.basicSynced 
    
    return(   <div>
        <div>
            <IonHeader className="inner_page_header ion-padding-start ion-padding-end">
                <h1 className="header_title">More</h1>        
            </IonHeader>
            <div className="ion-padding-start ion-padding-end">
                <div className="row no-gutters pt-2 pb-2">
                    <div className="col-auto pr-3">
                        <Tag.Div className="bg--image rounded-circle agent_signature_photo w-56px h-56px" cssVars={{agentSignaturePhoto:`url(${props.user.photo})`}}></Tag.Div>
                    </div>            
                    <div className="col align-self-center">
                        <div className=" font_size--large_extra font_weight--bold">{`${props.user.first_name} ${props.user.last_name}`}</div>
                        <div className=" font_size--large color_auxiliary">{props.user.agent_data?.office_name}</div>
                    </div>
                </div>
            </div>
            <div className="ion-padding">
            <div className="">
                    
                  {/**  <h2 tabIndex={0} className="mb-2">Add</h2>
                    <div className="moremenu">
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_task.svg" /></div>
                            <div className="moremenu__item__text">Task</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_deals.svg" /></div>
                            <div className="moremenu__item__text">Deal</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_contact.svg" /></div>
                            <div className="moremenu__item__text">Contact</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_services.svg" /></div>
                            <div className="moremenu__item__text">Service</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        
                    </div>*/} 
                    

                    <h2 tabIndex={0} className="mb-2">Preferences</h2>
                    <div className="moremenu">
                        <IonRouterLink routerLink='/calendar'>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_calendar.svg" /></div>
                            <div className="moremenu__item__text">Calendar</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        </IonRouterLink>
                      {/** 
                        <div className="moremenu__item" onClick={() => setPreferencesModal(true)}>
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_notification.svg" /></div>
                            <div className="moremenu__item__text">Notification Preferences</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                    
                      
                        <IonModal className="gray_bg" trigger="open-modal" isOpen={showPreferencesModal} onDidDismiss={() => setPreferencesModal(false)} backdropDismiss={false}>
                            <IonHeader>
                                <IonToolbar>
                                <IonButtons slot="start">
                                    <IonButton className="d-flex" strong={false} onClick={() => setPreferencesModal(false)}>
                                        <img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> More
                                    </IonButton>
                                </IonButtons>
                                <IonTitle>Notification Preferences</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <IonList className="pt-4">
                                    <IonItem>
                                        <IonLabel className="font_weight--normal">Email</IonLabel>
                                        <IonToggle slot="end"></IonToggle>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel className="font_weight--normal">Text</IonLabel>
                                        <IonToggle slot="end" checked={true}></IonToggle>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel className="font_weight--normal">Push Notification</IonLabel>
                                        <IonToggle slot="end" checked={true}></IonToggle>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel className="font_weight--normal">Send Recommended Action Items</IonLabel>
                                        <IonToggle slot="end" checked={true}></IonToggle>
                                    </IonItem>
                                </IonList>
                            </IonContent>
                        </IonModal>*/}
                        {/* 
                        <div className="moremenu__item" onClick={() => setRecommendedModal(true)}>
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_star.svg" /></div>
                            <div className="moremenu__item__text">Recommended Action Items</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        **/}
                        
                      {/** 
                        <IonModal className="gray_bg" trigger="open-modal" isOpen={showRecommendedModal} onDidDismiss={() => setRecommendedModal(false)} backdropDismiss={false}>
                            <IonHeader>
                                <IonToolbar>
                                <IonButtons slot="start">
                                    <IonButton className="d-flex" strong={false} onClick={() => setRecommendedModal(false)}>
                                        <img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /> More
                                    </IonButton>
                                </IonButtons>
                                <IonTitle>Recommended Action</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <div className="font_size--medium pt-4 pb-3 ion-padding-start ion-padding-end"></div>
                                <IonList className="mb-4">
                                    <IonItem>
                                        <IonLabel className="font_weight--normal">Would you like to receive a daily email with Recommended Action Items?</IonLabel>
                                        <IonToggle slot="end"></IonToggle>
                                    </IonItem>
                                </IonList>
                                <div className="color_auxiliary ion-padding">
                                    <div className="font_weight--bold font_size--large mb-3">Actionable Intelligence</div>
                                    <ul className="list list--doted font_size--large mb-4">
                                        <li>Who to stay in touch</li>
                                        <li>Today’s appointments</li>
                                        <li>Do not miss the anniversaries & birthdays</li>
                                        <li>Contact updates and more...</li>
                                    </ul>
                                    <div className="text-center">
                                        <img className="w-100" src="https://content.harstatic.com/media/bgs/crm/crm_recom.png" />
                                    </div>
                                </div>
                            </IonContent>
                        </IonModal>*/}
                        {/* 
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_privacy.svg" /></div>
                            <div className="moremenu__item__text">Log/History</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        */}
                   
                        <IonRouterLink routerLink='/settings/account'>
                            <div className="moremenu__item">
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_services.svg" /></div>
                                <div className="moremenu__item__text">Settings</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>
                        {!isProduction && <IonRouterLink routerLink='/services'>
                            <div className="moremenu__item">
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/icon_services.svg" /></div>
                                <div className="moremenu__item__text">Services</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>}
                        {/* {!isProduction && <IonRouterLink routerLink="/services/marketinsight">
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/icon_reports.svg"/></div>
                            <div className="moremenu__item__text">MarketInsight</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        </IonRouterLink>}
                        {!isProduction && <IonRouterLink routerLink="/services/emailmarketing">
                            <div className="moremenu__item">
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/common/email_circle_black.svg"/></div>
                                <div className="moremenu__item__text">EmailMarketing</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>}
                        {!isProduction && <IonRouterLink routerLink="/services/mylistings">
                            <div className="moremenu__item">
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/mls/listing_icon_formenu.svg"/></div>
                                <div className="moremenu__item__text">My Listings</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>} */}
                       {/**  <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_contact_2.svg" /></div>
                            <div className="moremenu__item__text">Switch Team</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>*/}
                        
                        
                        <div className="moremenu__item d-none">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/social/email_circle_settings.svg" /></div>
                            <div className="moremenu__item__text">Services</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        
                        
                    </div>
                    
                    
                    <h2 tabIndex={0} className="mb-2">HAR CRM{showVersion && (versionText + environment())}</h2>
                    <div className="moremenu">
                        {!isProduction && <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_app.svg" /></div>
                            <div className="moremenu__item__text">About the App</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>}
                        <div className="moremenu__item" onClick={()=>Instabug.show()}>
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_call.svg" /></div>
                            <div className="moremenu__item__text">Report a Bug</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <IonRouterLink routerLink="/support">
                            <div className="moremenu__item">
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/icon_supprts.svg" /></div>
                                <div className="moremenu__item__text">Support</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>
                        <IonRouterLink routerLink="/support/training">
                            <div className="moremenu__item" >
                                <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/email_circle_black_1.svg" /></div>
                                <div className="moremenu__item__text">Video Tutorials</div>
                                <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                            </div>
                        </IonRouterLink>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_help.svg" /></div>
                            <div className="moremenu__item__text"><a href='https://cms.har.com/help-center/'>Help & Feedback</a></div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_privacy.svg" /></div>
                            <div className="moremenu__item__text"><a href='https://cms.har.com/privacy-policy/'>Privacy Policy</a></div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_accessibility.svg" /></div>
                            <div className="moremenu__item__text"><a href='https://cms.har.com/accessibility/'>Accessibility</a></div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_term.svg" /></div>
                            <div className="moremenu__item__text"><a href='https://cms.har.com/termsofuse/'>Terms of Use</a></div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                        <div className="moremenu__item">
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/icon-landing-page-filter.svg" /></div>
                            <div className="moremenu__item__text"><a target="_blank" href={`${mopHost}/memberonlyarea#member`}>Members Only Area</a></div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                    </div>
                    
            
                    <div className="moremenu">
                        <div className="moremenu__item" onClick={signOutHandler}>
                            <div className="moremenu__item__img"><img src="https://content.harstatic.com/media/icons/crm/menu/menu_icon_key.svg" /></div>
                            <div className="moremenu__item__text">Sign out</div>
                            <div className="moremenu__item__arrow"><img src="https://content.harstatic.com/media/icons/arrow-right.svg" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}