import { usePostCSVValidateService } from "services/contacts/csvmapping/postCSVValidate"
import { IImportCsvMappedScreenProps } from "../ImportCsvMappedScreen.def"
import { IonSegment, IonSpinner, IonText } from "@ionic/react"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { ProminentButton } from "views/forms/buttons/Button"
import { useResponsive } from "scripts/hooks/general"
import { useHistory } from "react-router"
import { FileStack } from "views/forms/files/FileStack"
import { displayError } from "scripts/hooks/feedbacks"
import { isProduction } from "scripts/application/settings"

export const ValidationProcess = forwardRef((props: IImportCsvMappedScreenProps, ref) => {
    const { import_id, selectedMapping, onNext } = props
    const [validationResult, doValidate] = usePostCSVValidateService()
    const [error, setError] = useState('')
    const history = useHistory()
    const isResponsive = useResponsive()

    useImperativeHandle(ref, () => ({
        submit() {
            onSubmit()
        }
    }));

    const intiateValidating = () => {
        if (import_id) {
            doValidate.fetch({
                import_id: import_id,
                selected_value_mappings: selectedMapping,
                queue: !isProduction ? `1` : `0`
            })
        }
    }

    const LoadingState = () => {
        return (
            <div className='pt-3 pb-3' >
                <div className='mx-auto d-flex w-290px min-h-260px'>
                    <IonSpinner className='col-auto' name="bubbles"></IonSpinner>
                    <IonText className='col pt-1'>Please wait while we are validating your file.</IonText>
                </div>
            </div>
        )
    }

    const onSubmit = () => {
        if (validationResult.response && (Number(validationResult.response.total_warnings) === 0 && Number(validationResult.response.total_success) === 0)) {
            setError('Import is not applicable as there is no Contacts to bring into the system.')
            return
        }
        onNext?.(5)
    }

    useEffect(() => {
        intiateValidating()
    }, [])
    return (
        <div>
            {!validationResult.loading && validationResult.response && (Number(validationResult.response.total_warnings) > 0 || Number(validationResult.response.total_errors) > 0) &&
                <div className="alert alert--expired">
                    <div className="alert--expired__content ml-1 p-3  font_weight--light">Red highlight fields must be corrected to import entire contact. Yellow highlighted fields (optional) to correct. Make changes in file before uploading.

                    </div>
                </div>
            }
            {!validationResult.loading && error && <div color="danger" className='error_cell p-3 mb-2'>
                {error}.<br />
                <a className="cursor--hand" onClick={() => history.push('/contacts')}> {isResponsive ? `Tap` : `Click`} here to go back to Contacts</a>.
            </div>
            }

            <div className="table--wrapper table--mapping">

                {validationResult.loading && <LoadingState />}
                {!validationResult.loading && validationResult.response && (Number(validationResult.response.total_warnings) === 0 && Number(validationResult.response.total_success) === 0) && !isResponsive &&
                    <table className="table ion-no-padding contact_table_searchbar make-sticky" >
                        <tr>
                            <td className='align-self-center'>
                                Import is not applicable as there is no Contact to bring into the system.<br />
                                <a className="cursor--hand" onClick={() => history.push('/contacts')}> {isResponsive ? `Tap` : `Click`} here to go back to Contacts</a>.
                            </td>
                        </tr>
                    </table>
                }
                {!validationResult.loading && validationResult.response &&
                    <div className="row">
                        <div className="col pt-3 ml-3">Validation Result</div>
                        <div className="col-auto mr-3">{(Number(validationResult.response.total_warnings) > 0 || Number(validationResult.response.total_errors) > 0) &&
                            <FileStack text="Re-Upload" buttonStyles={{ 'size': 'small', 'kind': 'ordinary' }}
                                onUploadDone={(file) => {
                                    history.push(`/contacts/import/csv-mapping`, { file_key: file.file.key })
                                    onNext?.(1)
                                }}
                                accept={["text/csv"]}
                                onInvalidFileType={(isValid: boolean) => isValid ? displayError(" Invalid File Type", "Please select the CSV format file in order to import contacts") : ''}
                            />
                        }
                            {!isResponsive && (Number(validationResult.response.total_warnings) > 0 || Number(validationResult.response.total_success) > 0) &&
                                <ProminentButton className='' size="small" onClick={onSubmit} >Continue</ProminentButton>
                            }</div>
                    </div>

                }



                <IonSegment scrollable>


                    <table className="table ion-no-padding">

                        {!validationResult.loading && validationResult.response && (Number(validationResult.response.total_warnings) > 0 || (Number(validationResult.response.total_errors) > 0)) &&
                            <tr className="table__th ion-nowrap">
                                {validationResult.response?.selected_columns && validationResult.response?.selected_columns?.length > 0 &&
                                    <td>Problem</td>
                                }
                                {validationResult.response.selected_columns?.map((column, cIndex: number) => {
                                    return <td key={cIndex} >{column.long_value}</td>
                                })}

                            </tr>

                        }

                        {!validationResult.loading && validationResult.response && Number(validationResult.response.total_errors) > 0 &&
                            <>
                                {validationResult.response?.rows_errors.map((row: any, rIndex: number) => {
                                    return (
                                        <tr key={rIndex} className=" ion-nowrap">
                                            <>
                                                <td className='error_cell'>{row['error_message']}</td>
                                                {validationResult.response.selected_columns?.map((column, cIndex: number) => {
                                                    return <td key={cIndex} className={row['error_column_no'] ? Number(row['error_column_no']) - 1 === cIndex ? `error_cell` : `` : ``}>{row[column.csv_field]}</td>
                                                })
                                                }


                                            </>

                                        </tr>
                                    )
                                })
                                }
                            </>


                        }
                        {!validationResult.loading && validationResult.response && Number(validationResult.response.total_warnings) > 0 &&
                            <>
                                {
                                    validationResult.response?.rows_warnings.map((row: any, rIndex: number) => {
                                        return (
                                            <tr key={rIndex} className=" ion-nowrap">
                                                <>
                                                    <td className='warning_cell'>{row['error_message']}</td>
                                                    {validationResult.response.selected_columns?.map((column, cIndex: number) => {
                                                        return <td key={cIndex} className=''>{row[column.csv_field]}</td>
                                                    })
                                                    }

                                                </>
                                            </tr>
                                        )
                                    })
                                }
                            </>
                        }
                    </table>



                </IonSegment>
                <IonSegment>
                    {!validationResult.loading && validationResult.response && (Number(validationResult.response.total_warnings) === 0 && (Number(validationResult.response.total_errors) === 0)) &&
                        <table className="ion-no-padding w-100">
                            <tr className=" ion-nowrap">
                                <td className='success_cell'>
                                    <div className="alert alert-success p-2 font_size--large text-start">
                                        Validation complete no issues found. Please continue to proceed further.
                                    </div></td>
                            </tr>
                        </table>
                    }

                </IonSegment>



                {!validationResult.loading && validationResult.response && (Number(validationResult.response.total_warnings) > 0 || Number(validationResult.response.total_success) > 0) &&
                    <>
                        {!isResponsive &&
                            <table className="table ion-no-padding border-top" >

                                <tr>
                                    <td className='text-right ml-auto'>
                                        <button role="button" className="btn btn--prominent" onClick={onSubmit} >Continue</button>
                                    </td>
                                </tr>
                            </table>
                        }
                    </>

                }

            </div>
        </div >
    )
})