import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IImportProviderContactScreenProps, IImportProviderPaginationProps } from './ImportProviderContactScreen.def';
import './ImportProviderContactScreen.scss';
import { PrimaryButton } from 'views/forms/buttons/Button';
import { IonCheckbox, IonSegment } from '@ionic/react';
import { useState } from 'react';
import { GetContactsApi } from 'services/contacts/provider/getContacts';
import { ConfirmPopUp } from 'views/popups/ConfirmPopUp';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { ImportResult } from './components/ImportResult';
import { ContactImportStatus } from 'views/contacts/component/ContactImportStatus';
import { joinJSX, joinJSXWithSeparator } from 'scripts/helpers/jsx';
import { joinWithSpace } from 'scripts/helpers/texts';
import { OverlayTrigger, Popover  } from 'react-bootstrap';

export const ImportProviderContactScreenDesktop = (props: IImportProviderContactScreenProps): JSX.Element => {
    const { response, loading, importService, doImport } = props
    const [selected, getSelected] = useState<string[]>()
    const [showImportingResult, setShowImportingResult] = useState(false);
    const [showImporting, setImporting] = useState(false);
    const [isPrevent, setIsPrevent] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(1);
    const PAGE_SIZE = 100;
    const hasPagination = response && response.list.length > PAGE_SIZE;



    const doAllSelect = () => {
        let ids: string[] = []
        response?.list.map((contact) => ids.push(contact.id))
        getSelected(ids)
        setSelectAll(true)
    }

    const doUnSelectAll = () => {
        getSelected([]);
        setSelectAll(false)
    }

    useServiceDidSucceeded(importService, () => {
        setSelectAll(false)
        setIsPrevent(true)
        setImporting(false)

    })

    const popover_importcontacts = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Select Contact to Import</Popover.Title>
            <Popover.Content>
                Select the contacts below to import into your HAR CRM. The imported contacts will be added to the Contact Management tab under Contacts.
            </Popover.Content>
        </Popover>
    );
    return (
        <>
            <ImportResult visible={showImportingResult} importService={importService} onClose={() => setShowImportingResult(false)} />

            <ConfirmPopUp isOpen={showImporting} title="Import" onIonModalDidDismiss={() => setImporting(false)}>
                <ConfirmPopUp.Body>
                    <div className="p-5 text-center ml-auto mr-auto max-w-450">
                        <img src="https://content.harstatic.com/media/icons/crm/connecting_agents.svg" />
                        <div className="mb-1 pt-4 font_size--large_extra font_weight--bold">You will import <span className="font_weight--bold">{selected?.length}</span> contact from <span className="font_weight--bold">{response?.total}</span> to the Contact Management page
                        </div>
                        <div className='d-flex justify-content-center  ImpContacts-checkbox cursor--hand mt-3' onClick={() => setIsPrevent(!isPrevent)} >
                            <IonCheckbox id="prevent-checkbox" slot="start" className='mt-2' checked={isPrevent}></IonCheckbox>
                            <div className='ml-2 pt-1 text-center font-15px color_000'>Prevent updates on existing contacts</div>
                        </div>
                    </div>
                </ConfirmPopUp.Body>
                <ConfirmPopUp.ConfirmButton disabled={importService.loading} onClick={() => doImport?.(isPrevent ? "1" : "0", selected ? selected : [])} />
            </ConfirmPopUp >

            <DefaultTemplate title={`Contact Import`}>
                <div className="container">
                    <div className="row pt-4 pb-4">
                        <div className="col-sm-6 col-12 align-self-center pb-sm-0 pb-2">
                            <h2 tabIndex={(0)} className="h2--simple h2--simple__black mb-0">Select Contacts to Import <OverlayTrigger trigger="hover" placement="right" overlay={popover_importcontacts}><img className='cursor--hand' src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></h2>
                        </div>

                        <div className="col-sm-6 col-12 align-self-center text-left text-sm-right">
                        </div>
                    </div>
                    <ContactImportStatus className='mb-4 pb-3' />
                    {hasPagination && <Pagination total={parseInt(response.total)} pageSize={PAGE_SIZE} current={page} onChange={(page) => setPage(page)} />}
                    <div className="cards p-0 overflow-inherit mb-0">
                        <div className="position-sticky border_radius--default pl-3 pr-3 pt-1 pb-1 bg_color_snow_white top-0">
                            <div className="row align-items-center">
                                <div className="col font_size--medium font_weight--semi_bold">Contacts {!loading && '(' + response?.total + ')'}</div>
                                <div className="col-auto">
                                    <PrimaryButton disabled={!(selected && selected?.length > 0)} onClick={() => setImporting(true)}  >Import Contacts</PrimaryButton>
                                </div>
                            </div>
                        </div>
                        <IonSegment>
                            <table className="table ion-no-padding border_radius--default">
                                <thead className="table__th ion-nowrap">
                                    <th className=' max-w-45'>
                                        <div className="agent_signature agent_signature__large">
                                            <div role="checkbox" className={`custom-control custom-checkbox custom-control-inline ${selected && selected.length > 0 ? `custom-checkbox__ContactsSelection` : ``} mb-0`}>
                                                <input type="checkbox" id="checkbox_button_medium_all" name="checkbox_button_medium" className="custom-control-input"
                                                    onChange={() => !selectAll ? doAllSelect() : doUnSelectAll()}
                                                    checked={response && selected?.length === response?.list.length}
                                                />
                                                < label className="custom-control-label mr-0 mb-0" htmlFor="checkbox_button_medium_all" ></label>
                                            </div>
                                        </div>
                                    </th>
                                    <th className=' max-w-200'><span className="font_weight--bold">Name</span></th>
                                    <th className='max-w-200'><span className="font_weight--bold">Email</span></th>
                                    <th><span className="font_weight--bold">Phone</span></th>
                                    <th><span className="font_weight--bold">Status</span></th>
                                </thead>
                                <tbody>
                                    {loading && <LoadingState />}
                                    {!loading && response?.list?.slice(PAGE_SIZE * (page -1), PAGE_SIZE * page).map((contact: GetContactsApi.IContactImport, index: number) =>
                                        <tr key={index} className=" ion-nowrap">
                                            <td className=' max-w-45'>
                                                {contact.imported_status === null &&
                                                    <div className="agent_signature agent_signature__large">
                                                        <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                                                            <input type="checkbox" id={`checkbox_button_medium_${contact.id}`}
                                                                onChange={() => !selected?.includes(contact.id) ? getSelected([...selected ? selected : [], contact.id]) : getSelected(selected.filter((c) => c !== contact.id))}
                                                                name="checkbox_button_medium" className="custom-control-input"
                                                                checked={selected?.includes(contact.id)}
                                                            />
                                                            <label className="custom-control-label mr-0 mb-0" htmlFor={`checkbox_button_medium_${contact.id}`}></label>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                            <td className="font_weight--bold max-w-200">{contact.first_name + ' ' + contact.last_name}</td>
                                            <td className='max-w-200'>{contact.primary_email}</td>
                                            <td>{contact.primary_phone}</td>
                                            <td>
                                                <div className="label label--alert">Not imported</div>
                                            </td>

                                        </tr>
                                    )}
                                    {!loading && response && response.list.length === 0 &&
                                        <EmptyState />
                                    }
                                </tbody>
                            </table>
                        </IonSegment>



                    </div>
                    {hasPagination && <Pagination
                        className='mb-5'
                        total={parseInt(response.total)}
                        pageSize={PAGE_SIZE}
                        current={page}
                        onChange={(page) => setPage(page)}
                    />}


                </div>

            </DefaultTemplate >
        </>
    );
}

const Pagination = (props:IImportProviderPaginationProps) => {
    const { total, pageSize, current, className, onChange } = props;
    const pages = Math.ceil(total / pageSize);
    const pageNumbers = generatePages(pages, current);

    const PageItem = (props: { page: number }) =>
    (
        <div className={joinWithSpace('cursor--hand page-item', props.page === current && 'active')}>
            <a
                key={props.page}
                className='page-link'
                onClick={() => onChange(props.page)}>{props.page}
            </a>
        </div>
    );

        

    return (
        <div className={joinWithSpace('pagination justify-content-end mb-5 mt-5', className)}>
            {joinJSXWithSeparator(
                <div className="page-item"><div className='page-link'>...</div></div>,
                ...pageNumbers.map(chunk =>
                    joinJSX(...chunk.map(p => <PageItem key={p} page={p} />))
                )
            )}
        </div>
    );
}

const LoadingState = () => {

    const Row = () => (
        <tr className="ion-nowrap h-50px">
            <td className=' max-w-45' >
                <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
            </td>
            <td className="font_weight--boldmax-w-200"><div className="loading_text w-95pr"></div></td>
            <td className='max-w-200'><div className="loading_text w-95pr"></div></td>
            <td><div className=" loading_text w-60pr"></div></td>
            <td>
                <div className=" loading_text w-60pr"></div>
            </td>

        </tr>
    )

    const rows = Array.from({ length:10 }, (_, i) => <Row key={i} />);
    return <>{rows}</>
}


const EmptyState = () => {
    return (
        <tr className=" ion-nowrap py-2">
            <td className="font_weight--bold">There are no contacts to import</td>
        </tr>
    );
}

function generatePages(pages: number, current: number)
{
    if(pages < 10) { return [ Array.from({ length: pages }, (_, i) => i + 1) ]; }


    // Create an array of page numbers
    const pageNumbers = new Array<number[]>();

    // First and last pages
    const firstPagesLength = current <= 5 ? 6 : 3;
    const lastPagesLength = current >= pages - 4 ? 6 : 3;
    const middlePagesLength = current > 5 && current < pages -4? 3: 0;

    const firstPages = Array.from({ length: firstPagesLength }, (_, i) => i + 1);
    const lastPages = Array.from({ length: lastPagesLength }, (_, i) => pages - i).reverse();
    const middlePages = Array.from({ length: middlePagesLength }, (_, i) => current - 1 + i);
    
    pageNumbers.push(firstPages);
    if(middlePages.length) { pageNumbers.push(middlePages); }
    pageNumbers.push(lastPages);
    return pageNumbers;
}