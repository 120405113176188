import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace EmailExistenceApi
{
    export const url = "/contacts/email_existence";
    export const method = "GET";
    export const defaultError = "Failed to verify email.";
    export const operations = apiOperations(EmailExistenceApi);
    export const scope = new class EmailExistenceApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:{ is_duplicate:string })
        {
            const isDuplicated = !!parseInt(data.is_duplicate);
            return { isDuplicated };
        }
    }();
    
    export interface Request
    {
        email:string;
    }

    export interface Response
    {
        isDuplicated:boolean;
    }
}

export const fetchEmailExistenceService = forwardOperation(EmailExistenceApi.operations, 'fetch');
export const useFetchEmailExistenceService = forwardOperation(EmailExistenceApi.operations, 'useFetch');
export const useEmailExistenceService = forwardOperation(EmailExistenceApi.operations, 'useService');