import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace DeleteEmailApi
{
    export const url = "/contacts/:contact_id/emails/:id";
    export const method = "DELETE";
    export const defaultError = "Failed to delete email.";
    export const operations = apiOperations(DeleteEmailApi);
    export const scope = new class DeleteEmailApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        id:string
    }

    export interface Response
    {
        
    }
}

export const fetchDeleteEmailService = forwardOperation(DeleteEmailApi.operations, 'fetch');
export const useFetchDeleteEmailService = forwardOperation(DeleteEmailApi.operations, 'useFetch');
export const useDeleteEmailService = forwardOperation(DeleteEmailApi.operations, 'useService');