import {  IonIcon } from "@ionic/react";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useResponsive } from "scripts/hooks/general";
import { useServiceDidSucceeded } from "scripts/hooks/useService";
import { useGetMatrixImportStatusService } from "services/contacts/matrix/getMatrixImportStatus";
import { useFetchGetBasicInfoService } from "services/user/getBasicInfo";
import { close as closeIcon } from 'ionicons/icons'


export const useMatrix = () => {
    const [basicInfo] = useFetchGetBasicInfoService();
    const [matrixStatus, getMatrixStatus] = useGetMatrixImportStatusService();
    const isResponsive = useResponsive()
    const [matrixImportPending, setPending] = useState<boolean | undefined>();
    const [matrixContactImportStatusId, setStatusID] = useState<string | undefined>(/*basicInfo.response?.matrix?.status_id*/undefined);
    const [matrixContactStatus, setStatus] = useState<string | undefined>(/*basicInfo.response?.matrix?.status*/);
    const [intervalCount, setIntervalCount] = useState(0);
    const [progress, setProgress] = useState(0);
    const [contactUploaded, setContactUploaded] = useState(0);
    const [totalContacts, setTotalContact] = useState(0);
    const [error, setError] = useState("");
    const [visibility, setVisibility] = useState(true)
    const IntiateMatrix = (status_id: string) => {
        setError('')
        setProgress(0)
        setContactUploaded(0)
        setVisibility(true)
        getMatrixStatus.fetch({ status_id: status_id })
    }


    const close = () => {
        setPending(false)
        setProgress(0)
        setVisibility(false)
    }

    useServiceDidSucceeded(basicInfo, (response: any) => {
        if (response.matrix?.status?.toLocaleLowerCase() === 'queued' || response.matrix?.status?.toLocaleLowerCase() === 'in progress') {
            if (response.matrix?.status_id) {
                IntiateMatrix?.(response.matrix?.status_id)
            }
            setContactUploaded(response.matrix?.current_count)

        }
    })

    useServiceDidSucceeded(matrixStatus, (response) => {
        if (response.status?.toLocaleLowerCase() === 'queued' || response.status?.toLocaleLowerCase() === 'in progress') {
            setPending(true)
            setIntervalCount(intervalCount + 1)
        }

        if (response.status?.toLocaleLowerCase() === 'error') {
            setProgress(0)
            if (response.progress) {
                setError(response.progress)
            }

        }



        if (response?.current_count) {
            setContactUploaded(Number(response.current_count))
        }
        if (response?.contacts_count && totalContacts === 0) {
            setTotalContact(Number(response.contacts_count))
        }
        setStatusID(response.status_id)
        setStatus(response.status)
        if (response.contacts_count && response.contacts_count !== "0" && response.current_count !== "0") {
            let status = (Math.round((Number(response.current_count) / Number(response.contacts_count)) * 100))
            setProgress(status);
        }

    })


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (matrixImportPending && intervalCount < 10) {
                setIntervalCount(intervalCount + 1);

            }
            if (matrixContactImportStatusId) {
                getMatrixStatus.fetch({ status_id: matrixContactImportStatusId })
            }
        }, 2500);

        return () => clearTimeout(timeout);
    }, [intervalCount]);

    const MatrixImportMessage = () => {


        return <>

            {matrixImportPending && visibility &&
                <div className='mt-2'>
                    <div className={`bg_color_cloudy_sky_light p-3 d-inline-flex align-items-center border_radius--default position-relative ${isResponsive && `w-100`}`} >
                        <div className='position-absolute top-0  right-0px'>
                            <a className="modal-closebtn cursor--hand" onClick={() => {
                                close()
                            }}>
                                <IonIcon className="w-20px h-20px " slot="icon-only" icon={closeIcon} color="dark"></IonIcon>
                            </a>
                        </div>
                        {!isResponsive &&
                            <div className="pr-md-2"><img src="https://content.harstatic.com/media/icons/clock_auxilary.svg" /></div>
                        }
                        {!error &&
                            <div className={`border_radius--default py-3 mt-2 px-4  color_auxiliary ${isResponsive ? `w-100` : `min-w-400px`} `}>
                                <div className="pb-2 font_size--medium">Importing Contacts from Matrix. {contactUploaded && contactUploaded > 0 ? <>({contactUploaded + `/` + totalContacts + ` contacts have been uploaded.`})</> : ''}</div>
                                {progress &&
                                    <ProgressBar now={progress ?? 0} label={`${progress}%`} variant={progress === 100 ? 'success' : 'primary'} />
                                }
                            </div>
                        }
                        {error &&
                            <div className={`border_radius--default py-3 mt-2 px-4  color_auxiliary ${isResponsive ? `w-100` : `min-w-400px`} `}>
                                <div className="pb-2 font_size--medium color_cc0000"> {error}</div>
                            </div>
                        }


                    </div>
                </div>
            }

        </>
    }

    return { matrixImportPending, matrixContactImportStatusId, matrixContactStatus, MatrixImportMessage, IntiateMatrix } as const;
}