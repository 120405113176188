import { IonSelect, IonSelectOption, SelectChangeEventDetail } from '@ionic/react';
import { IonSelectCustomEvent } from "@ionic/core"
import { isString } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { joinWithSpace } from 'scripts/helpers/texts';
import { useClickOutside } from 'scripts/hooks/general';
import { JSXAttributes } from 'types/react';
import { DropDownOptions } from '../DropDownOptions';
import { IDropDownOption, IDropDownOptionsSource } from '../DropDownOptions/DropDownOptions.def';
import { IMultiDropDownProps, ISingleDropDownProps } from './DropDown.def';
import './DropDown.scss';

export function DropDown(props: ISingleDropDownProps): JSX.Element {
    const { kind, className: fieldClassName, containerClassName, containerId, error, label, id, children, ...otherProps } = props;
    const size = kind ?? 'medium';
    const hasErrorMessage = isString(error) && error.trim();
    const errorClassName = hasErrorMessage || error ? 'is-invalid' : undefined;
    const className = joinWithSpace(`custom-select custom-select_${size}`, fieldClassName, errorClassName);
    return (
        <div id={containerId} className={containerClassName}>
            {label && <label htmlFor={id}>{label}</label>}
            <select {...otherProps} id={id} className={className}>
                {children}
            </select>
            {hasErrorMessage && <div className='invalid-feedback'>{error}</div>}
        </div>
    );
}
export namespace DropDown {
    export function Multi<T extends string | number>(props: IMultiDropDownProps<T>): JSX.Element {
        const { values, source,hideAvatar, hideId, onChange, kind, className: fieldClassName, containerClassName, containerId, error, label,placeHolder, id, selectAll, children } = props;
        const size = kind ?? 'medium';
        const hasErrorMessage = isString(error) && error.trim();
        const errorClassName = hasErrorMessage || error ? 'is-invalid' : undefined;
        const className = joinWithSpace(`tags-input-container custom-select custom-select_${size}`, fieldClassName, errorClassName);
        const ionSelectRef = useRef<HTMLIonSelectElement>(null);
        const [ref, isOpen, setOpen] = useClickOutside<HTMLDivElement>(false);
        const [currentValues, setCurrentValues] = useState(values ?? []);
        const options = loadOptions(source, children);
        const isLoading = 'then' in options;
        const unselectedOptions = isLoading ? [] : options.filter(x => !currentValues.includes(x.id));
        const optionsValues = isLoading ? [] : options.filter(x => currentValues.includes(x.id));
        const selectAllText = selectAll && !isLoading ? (unselectedOptions.length ? 'Select All' : 'Unselect All') : undefined;
        const showHeader = !!label || !!selectAllText;

        useEffect(() => { setCurrentValues(values ?? []); }, [values]);
        const onSelect = (value: IDropDownOption<T>) => {
            if (!currentValues.includes(value.id)) {
                const newValues = [...currentValues, value.id];
                setCurrentValues(newValues);
                onChange?.(newValues);
            }
            setOpen(false);
        }
        const onRemove = (value: T) => {
            if (!currentValues.length) { return; }
            const newValues = currentValues.filter(x => x !== value);
            if (newValues.length === currentValues.length) { return; }
            setCurrentValues(newValues);
            onChange?.(newValues);
        };
        const onClick = () => {
            //ionSelectRef.current?.open();
            setOpen(!isOpen);
        };
        const onIonSelectChange = (e: IonSelectCustomEvent<SelectChangeEventDetail<string[]>>) => {
            setCurrentValues(e.detail.value as T[]);
        };
        const onSelectAllClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            if (!unselectedOptions.length) { setCurrentValues([]); }
            else {
                const unselectedValues = unselectedOptions.map(x => x.id);
                setCurrentValues([...currentValues, ...unselectedValues]);
            }
        };
        return (
            <div id={containerId} className={containerClassName}>
                {showHeader &&
                    <div className='row'>
                        {label && <div className="col-sm"><label className='form-label'>{label}</label></div>}
                        {selectAllText && <div className="col-sm text-right"><a href="#" onClick={onSelectAllClick}>{selectAllText}</a></div>}
                    </div>
                }
                <div ref={ref}>
              

                    <IonSelect ref={ionSelectRef} multiple={true} onIonChange={onIonSelectChange} className={`d-none`}>
                        {unselectedOptions.map(({ id, text }) => <IonSelectOption value={id} key={id}>{text}</IonSelectOption>)}
                    </IonSelect>
                    <div id={id} className={className} onClick={onClick}>
                        {isLoading ? 'Loading...' :optionsValues.length>0? optionsValues.map(({ id, text }) => (
                            <div className="tag-item" key={id} onClick={e => e.stopPropagation()}>
                                <span className="text">{text}</span>
                                <span className="close" onClick={() => onRemove?.(id)}>&times;</span>
                            </div>)
                        ):
                            <>{placeHolder}</>}
                    </div>
                    
                </div>
                {hasErrorMessage && <div className='invalid-feedback'>{error}</div>}
                    {isOpen&&<DropDownOptions noAvatar={hideAvatar} source={options} onSelect={onSelect} hideId={hideId} selectedValue={values} />}
            </div>
        );
    }
    function loadOptions<T extends string | number>(source?: IDropDownOptionsSource<IDropDownOption<T>>, children?: JSX.Element | JSX.Element[]):
        IDropDownOptionsSource<IDropDownOption<T>> {
        if (source) { return source; }
        if (!children) { return []; }
        const childList = Array.isArray(children) ? children : [children];
        return childList
            .map(x => x.props as JSXAttributes.Element<'option'>)
            .map(x => ({ id: x.value as T, text: x.children as string }));
    }
}