
import { EmailList } from 'views/emails/EmailList';
import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { IEmailLandingScreenProps } from './EmailLandingScreen.def';
//import { IEmailList } from 'services/emails/searchEmails';
import './EmailLandingScreen.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { EmailDetail } from 'views/emails/EmailDetail';
import { NewEmail } from 'views/emails/popups/NewEmail';
import { EmailDetailsApi } from 'services/emails/emailDetails';
import { ChangeAliasPopUp } from 'views/emails/popups/ChangeAliasPopUp';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';


export const EmailLandingScreenDesktop = (props: IEmailLandingScreenProps): JSX.Element => {
    const { list, id, loading, type, mails, page, keyword, member, total_draft, total_unread_lead, total_unread, readList, search, sort, reload, remove, mark, loadMore, update, setCounter } = props;
    const [newEmailShow, setNewEmailShow] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>()
    const [showAliasPop, setShowAliasPop] = useState(false)
    const [messageId, setMessageId] = useState("")
    const [reloadPreview, setReloadPreview] = useState(false)
    const [emailItem, setEmailItem] = useState<{ item: any, isNew: boolean } | undefined>()
    const [action, setAction] = useState<'reply' | 'forward' | "draft" | "lead" | "">("")



    //service
    const history = useHistory()
    useEffect(() => {
        if (member && typeof member?.provider_status !== 'undefined') {
            setConnectionStatus(member.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [member])


    const startWriting = (mid?: string, action?: 'reply' | 'forward' | 'draft' | "") => {
        if (mid) {
            setMessageId(mid)
        } else {
            setMessageId("")
        }
        setAction(action ? action : '')
        setNewEmailShow(true)
    }

    const forwardDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "forward")
    }


    const replyDraft = (mail: EmailDetailsApi.Response) => {
        startWriting(mail.id, "reply")

    }

    return (
        <>
            {type === 'forwarded' && member?.settings &&
                <ChangeAliasPopUp show={showAliasPop} setting={member.settings} onClose={() => setShowAliasPop(false)} onChange={() => window.location.reload()} />
            }
            <NewEmail
                show={newEmailShow}
                messageId={messageId}
                type={type}
                action={action}
                connectionStatus={connectionStatus}
                changePreview={(item?: any, isNew?: boolean) => {
                    if (!isNew) {
                        setReloadPreview(true)
                    }
                    if (item) {
                        setEmailItem({ 'item': item, 'isNew': isNew ? isNew : false })
                    }
                }}
                provider_email={member?.provider_email}
                onClose={() => {
                    setNewEmailShow(false);
                    setMessageId("")
                    setAction("")
                }}
                reload={reload}

            />
            <DefaultTemplate title="Emails">
                <div className="container container__large">
                    <div className="page_title_container">
                        <div className="row">
                            <div className="col align-self-center">
                                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Emails</h2>
                            </div>
                            <div className="col-auto align-self-center">
                                {member && connectionStatus &&
                                    <a className="btn btn btn--prominent__v2 btn--small ml-4 d-inline-flex" onClick={() => startWriting("", "")}>
                                        <img className="mr-1 w-22px" src="https://content.harstatic.com/media/icons/icon_plus_white.svg" /> Create
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    {type === 'forwarded' && connectionStatus &&
                        <div className="row">
                            <div className="col-2 mb-4 mb-md-0"></div>
                            <div className="col-10"><div className="border_radius--default d-inline-block mb-2 pt-1 pb-1 pl-2 pr-2 font_size--small_extra"><span className=" font_weight--bold">To import new lead as contact</span>,
                                forward email to your CRM email: {member?.settings?.routine_email} <a className="font_weight--bold  cursor--hand" onClick={() => setShowAliasPop(true)}>change email</a></div></div>
                        </div>
                    }


                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-3 mb-4 mb-md-0">
                            <ul className="nav nav-tabs flex-column aside aside__email d-md-block d-none mb-4">
                                <li className="nav-item">
                                    <a className={`nav-link cursor--hand ${type === 'inbox' ? 'active' : ''} `} data-toggle="tab" onClick={() => !loading && history.push(`/emails/inbox`)}>Inbox {Number(total_unread) > 0 ? <span className="nav-link__number">{total_unread}</span> : ''} </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link cursor--hand ${type === 'lead' ? 'active' : ''} `} data-toggle="tab" onClick={() => !loading && history.push(`/emails/lead`)}>har.com Leads {Number(total_unread_lead) > 0 && <span className="nav-link__number">{total_unread_lead}</span>} </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link cursor--hand ${type === 'draft' ? 'active' : ''} `} data-toggle="tab" onClick={() => !loading && history.push(`/emails/draft`)}>Draft {Number(total_draft) > 0 && <span className="nav-link__number">{total_draft}</span>} </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link cursor--hand ${type === 'sent' ? 'active' : ''} `} data-toggle="tab" tabIndex={-1} aria-disabled="true" onClick={() => !loading && history.push(`/emails/sent`)} >Sent</a>
                                </li>
                                {/*
                                <li className="nav-item">
                                    <a className={`nav-link cursor--hand ${type === 'forwarded' ? 'active' : ''} `} data-toggle="tab" tabIndex={-1} aria-disabled="true" onClick={() => !loading && history.push(`/emails/forwarded`)}>
                                        <div className="pb-1">Forwarded</div>
                                        <div className=" line-h-1_2 font_weight--normal font_size--small_extra font_weight--normal">{member?.settings?.routine_email} </div>
                                    </a>
                                </li>*/}
                            </ul>
                            {member &&
                                <div className='info_box info_box_multiline'>
                                    <div className='info_box__content'>
                                        Forward the email conversation to our designated Routing Email <a className='color_0738CD'>({member?.settings?.routine_email})</a>, and it will capture the entire conversation. This ensures that emails sent by your contacts are automatically accessible within your CRM.
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-lg-10 col-md-9 col-9">
                            {member && typeof connectionStatus !== 'undefined' &&
                                <div className="email_container h-calc184">
                                    <EmailList
                                        loading={loading}
                                        list={list}
                                        type={type}
                                        id={id}
                                        total={mails?.total}
                                        totalPages={mails?.total_pages}
                                        page={page}
                                        connectionStatus={connectionStatus}
                                        keyword={keyword}
                                        updateList={emailItem}
                                        readList={readList}
                                        loadMore={loadMore}
                                        search={search}
                                        reload={reload}
                                        sort={sort}
                                        remove={remove}
                                        mark={mark}
                                        update={update}
                                    />

                                    <EmailDetail
                                        type={type}
                                        id={id}
                                        mails={mails}
                                        reload={reloadPreview}
                                        connectionStatus={connectionStatus}
                                        setReload={setReloadPreview}
                                        createDraft={(mail: EmailDetailsApi.Response, isReply: boolean) => {
                                            if (isReply) {
                                                replyDraft(mail)
                                            } else {
                                                forwardDraft(mail)
                                            }
                                        }}
                                        openMessage={(Id: string) => {
                                            startWriting(Id, "draft")
                                        }}
                                        remove={remove}
                                        setCounter={setCounter}

                                    />

                                </div>
                            }
                            {member && typeof connectionStatus === 'undefined' &&
                                <NoConnectionBox className='pt-4 pl-4' />
                            }
                        </div>
                    </div>
                </div>
            </DefaultTemplate >
        </>

    );
}



