
import { IonHeader,IonToolbar,IonButtons,IonButton,IonTitle } from "@ionic/react";
import { DefaultPopUp } from "views/popups/DefaultPopUp";
import { DefaultPopUpApp } from "views/popups/DefaultPopUp/DefaultPopUp.app";
import { IChooseTaskModalProps } from "../../AddTaskPopUp.def";
import { ModuleInfo } from "views/general/ModuleInfo";

export const ChooseTaskModalApp = (props:IChooseTaskModalProps) =>{
    const {show,toggle,setCategory,toggleTask}=props;
return(
     <div>
        <DefaultPopUpApp onDidDismiss={()=>toggle(false)} className="custom--modal initmodal" isOpen={show} hideHeader toggleShow={()=>toggle(false)}
        customHeader={<IonHeader>
                <IonToolbar>
                <IonButtons  className="w-15pr" slot="start">
                    <div className=" w-80px"></div>
                </IonButtons>
                <IonButtons slot="end">
                    <IonButton className=" color_har_blue" color="blue" onClick={()=>toggle(false)}>Cancel</IonButton>
                </IonButtons>
                <IonTitle>Choose an option</IonTitle>
                </IonToolbar>
            </IonHeader>}>            
            <DefaultPopUp.Body>
                <div className="ion-padding bg-white h-100 d-flex">
                    <div className="align-self-center pl-3 pr-3 w-100">
                        <div className="row">
                            <div className="col-6">
                                <a onClick={()=>{setCategory("t");toggleTask(true);toggle(false)}} className="cards p-3 text-center d-block d-flex cursor--hand height-170px">
                                    <div className="align-self-center w-100">
                                        <div className="mb-3"><img className="w-34px" src="https://content.harstatic.com/media/icons/icon_task.svg"></img></div>
                                        <div className="align-center font_size--large font_weight--bold">New Task</div>
                                            <ModuleInfo type="tasks" />

                                    </div>
                                </a>
                            </div>
                            <div className="col-6">
                                <a onClick={()=>{setCategory("e");toggleTask(true);toggle(false)}} className="cards p-3 text-center d-block d-flex cursor--hand height-170px">
                                    <div className="align-self-center w-100 ">
                                        <div className="mb-3"><img className="w-34px" src="https://content.harstatic.com/media/icons/icon_task.svg"></img></div>
                                        <div className="align-center font_size--large font_weight--bold">New Event</div>
                                            <ModuleInfo type="events" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.Body>
        </DefaultPopUpApp>
    </div>
);
}
