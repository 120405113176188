export interface ISimpleDialogProps extends ISimpleDialogComponentProps {
    blur?: boolean,
    autoClose?:number | boolean,    
}

export interface ISimpleDialogComponentProps {
    message: string,
    onClose?: (() => void),
    icon?: eSimpleDialogIcon | string,
}

export enum eSimpleDialogIcon {
    Success = 1
}