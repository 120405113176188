import { SelectWithSearch } from "views/forms/select/SelectWithSearch";
import "../ImportCsvMappedScreen.scss";
import { useState } from "react";



export const CustomTableGrid = () => {
    const [state, setState] = useState('');

  
    return (
        <div>
            <SelectWithSearch title="Select with Search"
                options={[{ label: 'option1', value:'1' }, { label: 'option2', value: '2'}, { label: 'micheal', value: '3' }, { label: 'john', value: '4' }, { label: 'matrix', value: '5' }]}
                onSelect={(val:any) => setState(val.value) }
                selectedValue={state}
            />
        </div>
    );
}




