import { isString } from "lodash-es";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { IErrorOptions, IFeedbackInstance, IFeedbackOptions, IMessageOptions } from "types/feedbacks";
import { ErrorDialog } from "views/dialogs/ErrorDialog";
import { SimpleDialog } from "views/dialogs/SimpleDialog";

export interface IFeedback {
    key: string;
    element: JSX.Element;
    options: IFeedbackOptions;
}

const source = new Map<string, IFeedback>();
const entries = new Subject<IFeedback[]>();
const added = new Subject<IFeedback>();
const removed = new Subject<IFeedback>();


export function useFeedbackList() {
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);

    useEffect(() => {
        const subscription = entries.subscribe(feedbacks => setFeedbacks(feedbacks))
        return () => subscription.unsubscribe();
    }, []);

    return feedbacks;
}

export function useFeedbackIncluded() {
    const [feedback, setFeedback] = useState<{ current?: IFeedback }>({});
    const { current } = feedback;
    feedback.current = undefined;

    useEffect(() => {
        const subscription = added.subscribe(feedback => setFeedback({ current: feedback }))
        return () => subscription.unsubscribe();
    }, []);

    return current;
}

export function useFeedbackRemoved() {
    const [feedback, setFeedback] = useState<{ current?: IFeedback }>({});
    const { current } = feedback;
    feedback.current = undefined;

    useEffect(() => {
        const subscription = removed.subscribe(feedback => setFeedback({ current: feedback }))
        return () => subscription.unsubscribe();
    }, []);

    return current;
}

export function displayError(title: string, content?: string): IFeedbackInstance;
export function displayError(title: string, options?: IErrorOptions): IFeedbackInstance;
export function displayError(title: string, args?: string | IErrorOptions): IFeedbackInstance {
    const options = isString(args) ? { content: args } : args;
    const key = title + ':' + (options?.content ?? '');
    const component = () => (
        <ErrorDialog.Component title={title} onClose={() => feedback.close()}>
            {options?.content}
        </ErrorDialog.Component>
    );

    const feedback = displayFeedback(key, component, options);
    return feedback;
}

export function displayMessage(message: string, options?: IMessageOptions): IFeedbackInstance {
    const key = message;
    const component = () => <SimpleDialog.Component message={message} icon={options?.icon} onClose={() => feedback.close()} />;
    const feedback = displayFeedback(key, component, options);
    return feedback;
}


function displayFeedback(key: string, component: () => JSX.Element, options?: IFeedbackOptions): IFeedbackInstance {
    // Handling auto close
    let timeout: number | undefined;
    const autoClose = options?.autoClose ?? true;
    const closeTimeout = autoClose === true ? 5000 : autoClose;
    if (closeTimeout) { timeout = window.setTimeout(() => { close() }, closeTimeout); }

    setTimeout(() => add(key, component, options), 1);

    const close = () => {
        remove(key);
        if (timeout !== undefined) { clearTimeout(timeout); }
        timeout = undefined;
    }

    return { close };
}

function add(key: string, elementBuilder: () => JSX.Element, options: IFeedbackOptions = {}) {
    if (source.has(key)) { return source.get(key)!; }

    const element = elementBuilder();
    const feedback = { key, element, options };
    source.set(key, feedback);
    entries.next([...source.values()]);
    added.next(feedback);
    return feedback;
}

function remove(key: string) {
    if (!source.has(key)) { return; }

    const feedback = source.get(key)!;
    source.delete(key);
    entries.next([...source.values()]);
    removed.next(feedback);
}