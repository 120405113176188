
import { useState, useEffect } from 'react';
import { useResponsiveChoose } from 'scripts/hooks/general';
import { useDownloadContactsService } from 'services/contacts/downloadContacts';
import { useGetStagesService, GetStagesApi } from 'services/contacts/pipeline/getStages';
import { useRemoveContactService } from 'services/contacts/pipeline/removeContact';
import { useSortStagesService } from 'services/contacts/pipeline/sortStages';
import { useUpdateStageService } from 'services/contacts/pipeline/updateStage';
import { useSearchContactsService } from 'services/contacts/searchContacts';
import { ContactPipelineScreenApp } from './ContactPipelineScreen.app';
import { IContactPipelineColumnProps, IContactPipelineContactsProps, IContactPipelineScreenProps } from './ContactPipelineScreen.def';
import { ContactPipelineScreenDesktop } from './ContactPipelineScreen.desktop';
import './ContactPipelineScreen.scss';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { IContactsFilters, ILocationFilter } from 'views/contacts/popups/ContactsFilterPopUp/ContactsFilterPopUp.def';
import { useLocation } from 'react-router';

export const ContactPipelineScreen = (_props: IContactPipelineScreenProps): JSX.Element => {
    const [getStages, getStagesAction] = useGetStagesService()
    const [contactService, contactServiceNewAction] = useSearchContactsService()
    const [filters, setFilter] = useState<IContactsFilters>()
    const [, setPipelineFilter] = useState<GetStagesApi.Request>()
    const [filterCount, setFilterCount] = useState(0)
    const [dopaging, setDoingPaging] = useState(0)
    const [_sortStage, sortStageAction] = useSortStagesService()
    const [_updateStage, updateStageAction] = useUpdateStageService()
    const [_removeContact, removeContactAction] = useRemoveContactService()
    const [downloadContactsService, downloadContactsServiceAction] = useDownloadContactsService();
    const [_downloading, setDownloading] = useState(false)
    const [downloadFile, setDownloadFile] = useState('')
    const [contacts, setContacts] = useState<Array<IContactPipelineContactsProps>>([])
    const [trash, setTrash] = useState<Array<IContactPipelineContactsProps>>([])
    const [columns, updateColumns] = useState<Array<IContactPipelineColumnProps>>([{
        pipeline: 10,
        title: 'New',
        className: "new",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 20,
        title: 'Qualified',
        className: "qualified",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 30,
        title: 'Cold',
        className: "cold",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 40,
        title: 'Warm',
        className: "worm",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 50,
        title: 'Hot',
        className: "hot",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 60,
        title: <>Active listings /<br /> Under contract</>,
        className: "active",
        total: 0,
        totalPages: 0,
        page: -1
    }, {
        pipeline: 100,
        title: 'Closed',
        className: "closed",
        total: 0,
        totalPages: 0,
        page: -1
    }]);
    const location = useLocation<ILocationFilter>()



    const init = (param?: GetStagesApi.Request) => {
        let updatedFilter: GetStagesApi.Request = {}
        if (!param) {
            updatedFilter = { stages: [10, 20, 30, 40, 50, 60, 100] }
        } else {
            updatedFilter = { ...param }
        }
        getStagesAction.fetch(updatedFilter)

    }

    const checkDuplicate = (contact: any, pipeline: number): boolean => {
        let pipelineContact = contacts.filter((contact) => contact.pipeline === pipeline)[0]
        if (pipelineContact?.list) {
            let searchContact = pipelineContact.list.filter((cont: any) => {
                return contact.id === cont.id
            });
            return (searchContact.length > 0) ? false : true
        } else {
            return true
        }
    }

    const fillColumns = () => {
        getStages.response?.stages.map((st: any) => {
            let pipeline = Number(Object.keys(st)[0])
            let stage = st[pipeline]
            loadContacts(pipeline, stage)
        })
    }


    const reload = (param?: GetStagesApi.Request) => {
        let initColumn: any = []
        columns.map((column) => {
            initColumn.push({
                ...column,
                "total": "0",
                "page": -1,
            })
        })
        updateColumns(initColumn)
        init(param)




    }

    const loadContacts = (pipeline: number, stage: any) => {

        if (stage) {
            let column = columns.filter((col) => col.pipeline === pipeline)[0]
            if (column && stage && Number(column?.page) < stage.page) {
                let newContacts: any = []
                stage?.list.map((contact: any) => {
                    if (column?.pipeline && checkDuplicate(contact, column.pipeline)) {
                        newContacts.push(contact)
                    }
                })
                if (stage.page > 1 && newContacts.length > 1) {
                    let moreContacts = contacts.map((contact: any) => {
                        if (contact.pipeline === column?.pipeline) {
                            newContacts.map((newc: any) => {
                                contact?.list?.push(newc)
                            })
                        }
                        return contact;
                    })
                    setContacts(moreContacts)
                } else {
                    contacts.push({ pipeline: column.pipeline, list: newContacts.length > 0 ? newContacts : [] })
                    setContacts(contacts)

                }
                if (Number(column?.page) < 1) {
                    column.total = Number(stage.total)
                    column.totalPages = Number(stage.total_pages)
                    // column.loading = false
                }
                column.page = Number(stage.page)
                let newColumns = columns.map((c: any) => {
                    if (c.pipeline === column?.pipeline) {
                        return column
                    }
                    return c;
                })
                updateColumns(newColumns)
            }

        }
    }



    const loadMore = (param: any) => {
        contactServiceNewAction.fetch(param)
        setDoingPaging(param.stages[0])
    }

    const updateStageTotal = (pipeline: number, operation: number) => {
        let stages = columns.map((stage: IContactPipelineColumnProps) => {
            if (stage.pipeline === pipeline) {
                if (operation === 1) {
                    stage.total = stage.total || stage.total === 0 ? Number(stage.total) + 1 : Number(stage.total)
                } else {
                    stage.total = stage.total || stage.total === 0 ? Number(stage.total) - 1 : Number(stage.total)
                }
            }
            return stage
        })
        updateColumns(stages)
    }

    const addContact = (contact: any, param: any) => {
        let addedStage = contacts.filter((c: any) => c.pipeline === param.pipeline)[0]
        if (addedStage && (addedStage?.list || addedStage?.list?.length === 0)) {
            let newList = addedStage.list.map((_c: any, index: number) => {
                if (index === 0) {
                    return contact
                } else if (addedStage && addedStage.list) {
                    return addedStage.list[index - 1]
                }
            });
            if (addedStage.list.length === 0) {
                newList.push(contact)
            } else {
                newList.push(addedStage.list[addedStage.list.length - 1])
            }
            let StagesContacts = contacts.filter((contact) => contact.pipeline !== param.pipeline)
            updateStageTotal(param.pipeline, 1)
            StagesContacts.push({ pipeline: param.pipeline, list: newList })
            //check if contact exist in other stage
            if (contact.pipeline_status) {
                let olderStage = contacts.filter((c: any) => c.pipeline === Number(contact.pipeline_status))[0]
                if (olderStage) {
                    let updatedStage = StagesContacts.filter((contact) => olderStage && contact.pipeline !== olderStage.pipeline)
                    let olderStageContactList = olderStage.list?.filter((c: any) => contact.id !== Number(c.id))
                    updatedStage.push({ pipeline: Number(contact.pipeline_status), list: olderStageContactList })
                    updateStageTotal(Number(contact.pipeline_status), 0)
                    setContacts(updatedStage)
                }
            } else {
                setContacts(StagesContacts)
            }
            updateStageAction.fetch({ stages: [{ contact_id: Number(param.id), pipeline_status: Number(param.pipeline) }] })
            displayMessage('Contact has been added to the list. ')
        }


    }

    const removeContact = (id: number, pipeline: number) => {
        removeContactAction.fetch({ id: id })
        let stage = contacts.filter((stage) => stage.pipeline === pipeline)[0]
        let list = stage?.list?.filter((contact: any) => contact.id !== id)
        let stages = contacts.filter((contact) => contact.pipeline !== pipeline)
        stages.push({ pipeline: pipeline, list: list })
        setContacts(stages)
        updateStageTotal(pipeline, 0)
        displayMessage('Contact has been removed from pipeline.')

    }

    const updateAndSort = (param: any) => {
        //for mobile move to delete container
        if (param.destination.droppableId === "0") {
            let contact = contacts.filter((column) => column.pipeline === Number(param.source.droppableId))[0]?.list?.[param.source.index]
            if (contact) {
                setTrash(contact)
                let otherStagesContacts = contacts.filter((contact) => contact.pipeline !== Number(param.source.droppableId))
                otherStagesContacts.push({ pipeline: Number(param.source.droppableId), list: contacts.filter((column) => column.pipeline === Number(param.source.droppableId))[0]?.list?.filter((list) => list.id !== contact.id) })
                updateStageTotal(Number(param.source.droppableId), 0)
                setContacts(otherStagesContacts)
                removeContactAction.fetch({ id: contact.id })
                displayMessage('Contact has been removed from pipeline.')
            }
        } else if (param.destination.droppableId === param.source.droppableId) {
            let contact = contacts.filter((contact) => contact.pipeline === Number(param.destination.droppableId))[0]
            if (contact?.list && contact.list[param.source.index] && contact.list[param.destination.index]) {
                let source = contact.list[param.source.index]
                let destination = contact.list[param.destination.index]
                let newList = contact.list.filter((c) => c.id !== source.id).map((c: any, index: number) => {
                    if (param.destination.index === index) {
                        return source
                    }
                    return c
                })
                if (param.source.index > param.destination.index) {
                    newList.splice(param.destination.index + 1, 0, destination)
                } else {
                    if (param.destination.index === contact.list.length - 1) {
                        newList.splice(param.destination.index, 0, source)
                    } else {
                        newList.splice(param.destination.index + 1, 0, contact.list[param.destination.index + 1])
                    }

                }
                let otherStages = contacts.filter((contact) => contact.pipeline !== Number(param.destination.droppableId))
                otherStages.push({ pipeline: contact.pipeline, list: newList })
                setContacts(otherStages)
                sortStageAction.fetch({
                    pipeline_status: contact.pipeline ? contact.pipeline : 0,
                    positions: [{ source_contact_id: source.id, target_contact_id: destination.id }]
                })
            }

        } else if (param.destination.droppableId !== param.source.droppableId) {
            let source = contacts.filter((contact) => contact.pipeline === Number(param.source.droppableId))[0]
            let target = contacts.filter((contact) => contact.pipeline === Number(param.destination.droppableId))[0]
            if (source && target && source.list && target.list) {
                let sourceContact = source.list[param.source.index]
                let newSourceList = source.list.filter((sc: any) => sc.id !== sourceContact.id)
                //console.log(newSourceList)
                //console.log(sourceContact)
                //console.log(targetContact)

                let newTargetList = target.list.map((cn: any, index: number) => {
                    if (param.destination.index > index) {
                        return cn
                    } else if (param.destination.index === index) {
                        return sourceContact
                    } else if (target?.list) {
                        return target.list[index - 1]
                    }
                })
                if (param.destination.index === target.list.length) {
                    newTargetList.push(sourceContact)
                } else {
                    newTargetList.push(target.list[target.list.length - 1])
                }
                let otherStagesContacts = contacts.filter((contact) => contact.pipeline !== Number(param.destination.droppableId)).filter((contact) => contact.pipeline !== Number(param.source.droppableId))
                otherStagesContacts.push({ pipeline: Number(param.source.droppableId), list: newSourceList })
                otherStagesContacts.push({ pipeline: Number(param.destination.droppableId), list: newTargetList })
                setContacts(otherStagesContacts)
                //stages count update
                updateStageAction.fetch({ stages: [{ contact_id: Number(param.draggableId), pipeline_status: Number(param.destination.droppableId), target_contact_id: newTargetList[param.destination.index === 0 ? param.destination.index : param.destination.index - 1].id }] })
                let stages = columns.map((stage: IContactPipelineColumnProps) => {
                    if (stage.pipeline === Number(param.source.droppableId)) {
                        stage.total = stage.total || stage.total === 0 ? Number(stage.total) - 1 : Number(stage.total)
                        return stage
                    } else if (stage.pipeline === Number(param.destination.droppableId)) {
                        stage.total = stage.total || stage.total === 0 ? Number(stage.total) + 1 : Number(stage.total)
                        return stage
                    } else {
                        return stage
                    }
                })
                updateColumns(stages)
            }
        }
    }

    //Downloading Stuff
    const downloadContacts = (param: any) => {
        let arr: any = [];
        param.map((contact: any) =>
            arr.push(contact.id)
        )
        let param2: any = { contact_ids: arr, format: "csv" }
        downloadContactsServiceAction.fetch(param2)
        setDownloading(true)

    }

    const downloadFileField = () => {
        var link = document.createElement("a");
        link.href = downloadFile;
        document.body.appendChild(link);
        link.click();
    }

    const applyFilter = (param?: IContactsFilters) => {
        let pipelineFilter: GetStagesApi.Request = {}
        let filter = param ? param : filters
        if (filter) {
            setContacts([])
            if (filter.cities && filter.cities.length > 0) {
                pipelineFilter.cities = filter.cities
            }
            if (filter.scores && filter.scores.length > 0) {
                pipelineFilter.scores = filter.scores
            }


            if (filter.stages && filter.stages.length > 0) {
                if (filter.stages?.length === 1 && filter.stages?.includes(0)) {
                    pipelineFilter.stages = [10, 20, 30, 40, 50, 60, 100]
                } else {
                    pipelineFilter.stages = filter.stages
                }
            } else {
                pipelineFilter.stages = [10, 20, 30, 40, 50, 60, 100]
            }

            if (filter.tags && filter.tags.length > 0) {
                let newTags: string[] = [];
                filter.tags.map((tag: any) =>
                    newTags.push(tag.id.toString())
                )
                pipelineFilter.tag_ids = newTags
            }
            if (filter.deal_stages && filter.deal_stages.length > 0) {
                pipelineFilter.deal_stages = filter.deal_stages
            }
            if (filter.owners && filter.owners.length > 0) {
                let owners: any[] = [];
                filter.owners.map((owner: any) =>
                    owners.push(owner.agent_key)
                )
                pipelineFilter.object_owners = owners
            }
            if (filter.zip_codes && filter.zip_codes.length > 0) {
                let codes: string[] = []
                filter.zip_codes.map((code) => codes.push(code.toString()))
                pipelineFilter.zip_codes = codes
            }
            if (filter.companies && filter.companies.length > 0) {
                pipelineFilter.companies = filter.companies
            }
            if (filter.sources && filter.sources.length > 0) {
                pipelineFilter.sources = filter.sources
            }
            if (filter.last_contact_date) {
                pipelineFilter.last_contact_date = filter.last_contact_date
            }
            setPipelineFilter(pipelineFilter)
            reload(pipelineFilter)

        }
    }
    const filterhandler = <K extends keyof IContactsFilters>(key: K, value: IContactsFilters[K]) => {
        setFilter({ ...filters, [key]: value })

    }

    const calcFilterCount = () => {
        var i = 0
        if (filters) {
            if (filters.cities && filters.cities.length > 0) {
                i = i + filters.cities.length
            }
            if (filters.scores && filters.scores.length > 0) {
                i = i + filters.scores.length
            }
            if (filters.stages && filters.stages.length > 0) {
                i = i + filters.stages.length
            }
            if (filters.tags && filters.tags.length > 0) {

                i = i + filters.tags.length
            }
            if (filters.deal_stages && filters.deal_stages.length > 0) {
                i = i + filters.deal_stages.length
            }
            if (filters.owners && filters.owners.length > 0) {
                i = i + filters.owners.length
            }
            if (filters.zip_codes && filters.zip_codes.length > 0) {
                i = i + filters.zip_codes.length
            }
            if (filters.companies && filters.companies.length > 0) {
                i = i + filters.companies.length
            }
            if (filters.sources && filters.sources.length > 0) {
                i = i + filters.sources.length
            }
            if (filters.last_contact_date) {
                i = i + 1
            }

            if (filters.birthday_start || filters.birthday_end) {
                i = i + 1
            }


            if (filters.home_anniversary_start || filters.home_anniversary_end) {
                i = i + 1
            }

            if (filters.wedding_anniversary_start || filters.wedding_anniversary_end) {
                i = i + 1
            }

            if (filters.known_since_start || filters.known_since_end) {
                i = i + 1
            }

            if (filters.lead_type) {
                i = i + 1
            }

        }
        setFilterCount(i)

    }






    useEffect(() => {
        if (location?.state && location?.state.filters) {
            setFilter(location.state.filters)
            applyFilter(location.state.filters)
        } else {
            init()
        }
    }, []);

    useEffect(() => {
        if (downloadFile) {
            downloadFileField()
        }

    }, [downloadFile])

    useEffect(() => {
        if (downloadContactsService.response?.file) {
            setDownloadFile(downloadContactsService.response?.file)
            setDownloading(false)
        }
    }, [downloadContactsService])



    useEffect(() => {
        fillColumns()
    }, [getStages]);

    useEffect(() => {
        let response = contactService.response

        let object = { page: response?.page, list: response?.list }
        loadContacts(dopaging, object)
    }, [contactService]);

    useEffect(() => {
        calcFilterCount()
    }, [filters])

    const ContactPipelineScreenPlatform = useResponsiveChoose(ContactPipelineScreenDesktop, ContactPipelineScreenApp);
    return (
        <ContactPipelineScreenPlatform
            columns={columns}
            contacts={contacts}
            trash={trash}
            filters={filters}
            loading={getStages.loading}
            filterCount={filterCount}
            clearFilter={() => {
                setFilter({})
                applyFilter({})
            }}
            filterhandler={filterhandler}
            applyFilter={applyFilter}
            setTrash={setTrash}
            loadMore={loadMore}
            updateAndSort={updateAndSort}
            addContact={addContact}
            downloadContact={downloadContacts}
            removeContact={removeContact}
        />
    );

}
