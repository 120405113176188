import { IEasyWayCSVResultProps } from './EasyWayCSVResult.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { EasyWayCSVResultApp } from './EasyWayCSVResult.app';
import { EasyWayCSVResultDesktop } from './EasyWayCSVResult.desktop';

export const EasyWayCSVResult = (props: IEasyWayCSVResultProps): JSX.Element =>
{
    const EasyWayCSVResultPlatform = useResponsiveChoose(EasyWayCSVResultDesktop, EasyWayCSVResultApp);
    return (
        <EasyWayCSVResultPlatform {...props} />
    );
}
