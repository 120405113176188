import { useResponsive } from 'scripts/hooks/general';
import { IModuleInfoProps } from './ModuleInfo.def';
import './ModuleInfo.scss';

export const ModuleInfo = (props: IModuleInfoProps): JSX.Element => {
    const { type } = props
    const isResponsive = useResponsive()
    return (
        <>
            {type === "pipeline" &&
                <div className={`bg_color_cement_light cursor--hand d-md-inline-flex px-3 py-2 d-block align-items-center border_radius--default`} >
                    The Contact Pipeline allows you to visually track and manage the journey of contacts through different stages within your sales cycle, from New through Closed.
                </div>
            }

            {type === "contacts" &&
                <div className={`bg_color_cement_light cursor--hand px-3 py-2 d-md-inline-flex  d-block align-items-center border_radius--default `} >
                    Click on the color circle or photo to select any contact. Once selected you can manage tags and export.
                </div>
            }

            {type === "deals" &&
                <div className={`bg_color_cement_light cursor--hand  px-3 py-2 d-md-inline-flex d-block align-items-center border_radius--default `} >
                    {isResponsive ?
                        'Tap & drag a Deal to a another stage.'
                        :
                        'Drag and drop a Deal from one stage to another.'
                    }
                </div>
            }
            {type === 'tasks' &&
                <div className={`align-center color_auxiliary min-h-71px pt-3 ${!isResponsive && ` font_size--small`}`}>An action item to be completed as part of managing clients and sales activities.</div>
            }

            {type === 'events' &&
                <div className={`align-center color_auxiliary min-h-71px pt-3 ${!isResponsive && `font_size--small`}`}>An occurrence related to a customer, lead, or sales opportunity.</div>
            }
        </>
    );
}
