import { history } from 'scripts/router/history';
import { captureException } from "scripts/logging/error-report";
import { isArray, isString } from 'lodash-es';

export type IUrlParams = Record<string, string | number | null | undefined> | string | null;

export const queryString = (params:object, encode:boolean = false) =>
{
    const encodeParam = encode? encodeURIComponent: (param:string) => param;
    return Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([ key, value ]) => `${key}=${encodeParam(value as string ?? '')}`)
        .join('&');
}

export function mailto(input:mailto.IParams)
{
    return mailto.toUrl(input);
}

export namespace mailto
{
    export interface IParams
    {
        to:string | string[];
        cc?:string | string[];
        bcc?:string | string[];
        subject?:string;
        body?:string;
    }

    export function toUrl(input:IParams)
    {
        const to = normalizeRecipient(input.to);
        if(!to) { throw new Error('"to" is required.'); }
        const cc = normalizeRecipient(input.cc);
        const bcc = normalizeRecipient(input.bcc);
        const body = input.body?.replaceAll("\n", '%0A')
        const { subject } = input;
        return buildUrl(`mailto:${to}`, { cc, bcc, subject, body });
    }

    function normalizeRecipient(input?:string | string[]): string | undefined
    {
        if(!input) { return undefined; }
        if(Array.isArray(input)) { input = input.filter(x => !!x).join(','); }
        return input.trim() || undefined;
    }

    function buildUrl(baseUrl:string, params:Record<string, string | undefined>): string
    {
        const url = new URL(baseUrl);
        for(let [name, value] of Object.entries(params))
        {
            if(value) { url.searchParams.append(name, value); }
        }

        return url.toString();
    }
}


interface IRedirectOptions
{
    allowAbsolute?: boolean; // defaults false
}

export function redirectToUrl(targetUrl:string, extraParams:Record<string, unknown> = {}, options?:IRedirectOptions): boolean
{
    if(!targetUrl.trim()) { return false; }

    // Validating options
    const isAbsolute = targetUrl.includes(':/');
    if(!options?.allowAbsolute && isAbsolute) { return false; }
    
    const params = Object.entries(extraParams);
    if(!params.length) { history.push(targetUrl); }

    try
    {
        const baseUrl = isAbsolute? undefined: window.location.origin;
        const url = new URL(targetUrl, baseUrl);
        params.forEach(([key, value]) => {
            if(value === undefined || value === null || value === '') { return; }
            url.searchParams.set(key, value.toString())
        });

        history.push(isAbsolute? url.toString(): url.pathname + url.search + url.hash);
        return true;
    }
    catch(e)
    {
        captureException(new Error(`Invalid redirect URL: ${targetUrl}`, { cause:e }));
        return false;
    }
}

export function redirectToUrlParam(name:string, extraParams:Record<string, string | number | undefined | null> = {}): boolean
{
    const query = new URLSearchParams(history.location.search);
    const redirectUrl = query.get(name)?.trim();
    return redirectToUrl(redirectUrl ?? '', extraParams);
}

export function addQueryStringToUrl(url:string, params?:IUrlParams | IUrlParams[]): string
{
    // Convert params to string, if it's an object
    if(params && !isArray(params)) { params = [params]; }

    // If querystring is empty, return the original URL
    if(!params || params.length === 0) { return url; }
    
    // Building query
    const query:string[] = [];
    for(let param of params)
    {
        if(!param) { continue; }
        if(!isString(param)) { param = queryString(param, true); }

        // If querystring starts with ? or &, remove it
        if(param.startsWith('?') || param.startsWith('&')) { param = param.slice(1); }
        query.push(param);
    }

    // Defining query string separator ? or &
    const separator = url.includes('?') ? '&' : '?';
    return url + separator + query.join('&');
}