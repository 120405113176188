import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace RemoveAddressApi
{
    export const url = "/contacts/:contact_id/addresses/:address_id";
    export const method = "DELETE";
    export const defaultError = "Failed to delete address.";
    export const operations = apiOperations(RemoveAddressApi);
    export const scope = new class RemoveAddressApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:PropertiesType<Response, string>): Response
        {
            return {
				...input
			}
        }
    }();
    
    export interface Request
    {
        contact_id:string,
        address_id:string,
        
    }

    export interface Response
    {
        status:string;
        message:string;
        code:string
    }
}

export const fetchRemoveAddressService = forwardOperation(RemoveAddressApi.operations, 'fetch');
export const useFetchRemoveAddressService = forwardOperation(RemoveAddressApi.operations, 'useFetch');
export const useRemoveAddressService = forwardOperation(RemoveAddressApi.operations, 'useService');