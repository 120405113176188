
import { IonAlert } from '@ionic/react';
import { useEffect } from 'react';
import { IAlertDialogProps } from './AlertDialog.def';
import './AlertDialog.scss';

export const AlertDialog = (props: IAlertDialogProps): JSX.Element => {
  useEffect(()=>{
   props.message.length>0&& handleSuccessMsg()
  },[props.message])
    const handleSuccessMsg=()=>{
        props.setSuccessMsg(props.message)
        setTimeout(()=>{
            props.refresh?.();
            props.setSuccessMsg("");
        },2000);
    }
    return (  <IonAlert
            isOpen={props.message!==''}
            onDidDismiss={() => props.setSuccessMsg("")}
            subHeader={props.message}
        />

    );
}
