import { IDealsFilterPopoverProps } from './DealsFilterPopover.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { DealsFilterPopoverApp } from './DealsFilterPopover.app';
import { DealsFilterPopoverDesktop } from './DealsFilterPopover.desktop';

export const DealsFilterPopover = (props: IDealsFilterPopoverProps): JSX.Element => {
    const DealsFilterPopoverPlatform = useResponsiveChoose(DealsFilterPopoverDesktop, DealsFilterPopoverApp);
    return (
        <DealsFilterPopoverPlatform {...props} />
    );
}
