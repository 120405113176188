import { ITaskDetailsPopUpProps } from './TaskDetailsPopUp.def';
import './TaskDetailsPopUp.scss';
import { getDayLong, formatHarTime, formatHarDate } from 'scripts/helpers/dates';
import { useState, useEffect } from 'react';
//import type { OverlayEventDetail } from '@ionic/core';
import { GetCalendarTaskDetailsApi, useGetCalendarTaskDetailsService } from 'services/calendar/getCalendarTaskDetails';
import { fetchDeleteCalendarTaskService } from 'services/calendar/deleteCalendarTask';
import { TaskFormApp } from '../AddTaskPopUp/components/TaskFormApp';
import { IonFooter, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, useIonActionSheet } from '@ionic/react';
import { DefaultPopUpApp } from 'views/popups/DefaultPopUp/DefaultPopUp.app';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { useHistory } from 'react-router';
import { Avatar } from 'views/contacts/Avatar';
import { isEmpty } from 'lodash-es';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';

export const TaskDetailsPopUpApp = (props: ITaskDetailsPopUpProps): JSX.Element => {
  const history = useHistory();
  const [present] = useIonActionSheet();
  const { taskId, returnUrl, handleTaskCompleted } = props;
  const [showEditTask, toggleEditTask] = useState(false);
  const [details, getDetails] = useGetCalendarTaskDetailsService()
  const [viewAllContact, setViewAllContact] = useState(5)
  const [detailsData, setDetail] = useState<GetCalendarTaskDetailsApi.Response | undefined>()
  const getContactSubtitle=(email:string,phone:string|undefined,fname:string,lname:string):string=>{
    if(!isEmpty(email)){return email;}
    else if(isEmpty(fname)&&isEmpty(lname)&& !isEmpty(phone)){
        return '';
    }else if(!isEmpty(phone)){
        return phone??'';
    }
    return ''; 
}
  useEffect(() => {
    if (taskId) {
        handleFetchDetailsData(taskId);
    }
}, [taskId])



  const handleFetchDetailsData = async (id?:string) => {
    await getDetails.fetch({ id: id ? id : props.taskId  })
    return;
  }

  const handleTaskRemove = async () => {
    present({
      buttons: [
        {
          text: 'Delete this task',
          handler: () => deleteTask("0"),
        },
        {
          text: 'Delete this and following tasks',
          handler: () => deleteTask("1"),
        },
        {
          text: 'Delete all tasks',
          handler: () => deleteTask("2")
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
      //onDidDismiss: ({ detail }) => setResult(detail),
    })
  }
  const deleteTask = async (option_id: string) => {
    const response = await fetchDeleteCalendarTaskService({ id: taskId, option_id: option_id })
    if (response.status === 'ok') { props.toggle?.(false); props.refreshData?.(); displayMessage("The " + detailsData?.category_text.toLowerCase() + " successfully deleted") }
  }
  const closeModal = () => {
    setDetail(undefined)
    props.toggle?.(false);
    if (returnUrl) {
      return history.replace(returnUrl)
    }
  }
  const redirect = (id?: number) => {
    id && history.push(`/contacts/${id}/overview`);
    // closeModal();
  }
  const toggleTaskStatus = async () => {
    await handleTaskCompleted(detailsData!) && handleFetchDetailsData();
  }

  useServiceDidSucceeded((details), () => {
    setDetail(details.response)
  })
  const Body = (): JSX.Element => <div className="ion-padding">
    {details.loading && <div>
      <div className=' loading__text h-20px mb-3 w-100px'></div>
      <div className=' loading__text w-120px mb-4'></div>
      <div className='card_boxed'>
        <div className="row">
          <div className="col-auto">
            <div className=" loading__text_small w-80px mb-2"></div>
            <div className=" loading__text_small w-100px mb-3"></div>
            <div className=" loading__text w-110px mb-3"></div>
            <div className=" loading__text_small w-80px mb-2"></div>
          </div>
          <div className="col-auto align-self-center"><img src="https://content.harstatic.com/media/icons/arrow_faded.svg" /></div>
          <div className="col-auto">
            <div className=" loading__text_small w-80px mb-2"></div>
            <div className=" loading__text_small w-100px mb-3"></div>
            <div className=" loading__text w-110px mb-3"></div>
            <div className=" loading__text_small w-80px mb-2"></div>
          </div>
        </div>
      </div>
      <div className='card_boxed'>
        <div className=" loading__text w-110px mb-3"></div>
        <div className="d-flex align-items-center">
          <div className=" loading__rounded w-40px h-40px bg_color_cloudy_sky_light mr-3"></div>
          <div><div className=" loading__text_small w-80px mb-2"></div></div>
        </div>
      </div>

      <div className='d-flex align-items-center pt-3 mb-4'>
        <div className='w-20px mr-3'><div className="loading__text_small w-10px h-10px bg_color_cloudy_sky_light mr-4"></div></div>
        <div><div className=" loading__text w-150px"></div></div>
      </div>

      <div className='d-flex align-items-center pt-3 mb-4'>
        <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
        <div><div className=" loading__text w-136px"></div></div>
      </div>

      <div className='d-flex align-items-center pt-3 mb-4'>
        <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
        <div><div className=" loading__text w-136px"></div></div>
      </div>

      <div className='d-flex align-items-center pt-3 mb-4 border-bottom border-color--cloudy-sky-light pb-4'>
        <div className='w-20px mr-3'><div className="loading__rounded w-20px h-20px bg_color_cloudy_sky_light mr-4"></div></div>
        <div><div className=" loading__text w-136px"></div></div>
      </div>
      <div className=" loading__text h-30px w-110px mb-4"></div>
    </div>}



    {!details.loading && details.response && <>
      <div className={`label label--${detailsData?.type_text.toLowerCase().replaceAll(" ", "")}`}>{detailsData?.type_text}</div>
      <h2 className="h2--simple h2--simple__black mb-4">{detailsData?.title}</h2>
      <div className="card_boxed">
        <div className="row">
          <div className="col-auto">
            <div className="auxiliary__fadded font_size--medium font_weight--semi_bold text-uppercase pb-2">Starts</div>
            <div className="auxiliary__fadded font_size--small">{getDayLong(detailsData?.start_dt)?.toUpperCase()}</div>
            <div className="font_size--large_extra font_weight--bold">{formatHarDate(detailsData?.start_dt)}</div>
            <div className="font_size--medium">{formatHarTime(detailsData?.start_dt)}</div>
          </div>
          {detailsData?.category === "e" && <>
            <div className="col-auto align-self-center"><img src="https://content.harstatic.com/media/icons/arrow_faded.svg" /></div>
            <div className="col-auto">
              <div className="auxiliary__fadded font_size--medium font_weight--semi_bold text-uppercase pb-2">Ends</div>
              <div className="auxiliary__fadded font_size--small">{getDayLong(detailsData?.end_dt)?.toUpperCase()}</div>
              <div className="font_size--large_extra font_weight--bold">{formatHarDate(detailsData?.end_dt)}</div>
              <div className="font_size--medium">{formatHarTime(detailsData?.end_dt)}</div>
            </div></>}
        </div>
      </div>
      {detailsData && detailsData.contacts.length > 0 &&
        <div className="card_boxed">
          <div className="d-flex">
            <h3>Contacts</h3>
            {detailsData.contacts.length > 5 && <div className="ml-auto color_har_blue cursor--hand" onClick={() => setViewAllContact((initial) => (initial === 5 ? 100 : 5))}>{viewAllContact === 5 ? 'View All' : 'Hide'}</div>}
          </div>
          <div className=" overflow-auto">
            {detailsData?.contacts.map((item, ind) =>
              ind < viewAllContact ? <div key={ind} onClick={() => {(item.deleted=="0"&& item.id!=0)&&redirect(item.id)}} className="agent_signature agent_signature__medium2 mb-3">
                <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                <div className="agent_signature__text">{item.first_name} {item.last_name} {item.deleted=="1"?`(deleted)`:""} <br/><div className='auxiliary__fadded font_size--small'>{getContactSubtitle(item.email,item.phone,item.first_name,item.last_name)}</div></div>
              </div> : null)}</div>
        </div>}

      <div className="pt-4">
        <div className="pl-1 mb-5"><div className={`label_text label_text__${detailsData?.priority_text.toLowerCase()} mb-0`}>{detailsData?.priority_text} Priority</div></div>
        {!isEmpty(detailsData?.location) && <div className="row no-gutters pb-5">
          <div className="col-auto min-w-35px"><img className='w-20px' src="https://content.harstatic.com/media/icons/map_filled_black.svg" /></div>
          <div className="col align-self-center font_size--large">{detailsData?.location}</div>
        </div>}
        {!isEmpty(detailsData?.reminder_minutes_prior_text) && <div className="row no-gutters pb-5">
          <div className="col-auto min-w-35px"><img className='w-20px' src="https://content.harstatic.com/media/icons/notification_bell_black.svg" /></div>
          <div className="col align-self-center font_size--large">{detailsData?.reminder_minutes_prior_text}</div>
        </div>}
        {!isEmpty(detailsData?.repeat_text) && <div className="row no-gutters pb-5">
          <div className="col-auto min-w-35px"><img className='w-20px opacity-40pr' src="https://content.harstatic.com/media/icons/refresh_light_gray.svg" /></div>
          <div className="col align-self-center font_size--large opacity-40pr" >{detailsData?.repeat === "" ? "Do Not Repeat" : detailsData?.repeat_text}</div>
        </div>}
        {!isEmpty(detailsData?.notes) && <div className="row no-gutters pb-5">
          <div className="col-auto min-w-35px"><img className='w-24px' src="https://content.harstatic.com/media/icons/bars_black.svg" /></div>
          <div className="col align-self-center font_size--large_extra">  {detailsData?.notes}</div>
        </div>}
      </div>
      <div className="border-top pt-4 pb-5 mb-5">
        <div className="font_size--large_extra mb-5 font_weight--bold color_sold cursor--hand" onClick={() => { handleTaskRemove() }} >Delete this {detailsData?.category_text}</div>

      </div>
    </>}

  </div>
  return (
    <DefaultPopUpApp title='' isOpen={props.show} onDidDismiss={closeModal} hideHeader
      customHeader={<IonHeader>
        <IonToolbar>
          <IonButtons  className="w-20pr" slot="start">
            <IonButton color={"blue"} onClick={closeModal}><img className='height-18px' src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />Tasks</IonButton>
          </IonButtons>
          <IonTitle>{detailsData?.category_text} Details</IonTitle>
          <IonButtons  className="w-20pr text-right" slot="end">
            <IonButton className='ml-auto' color={"blue"} onClick={() => toggleEditTask(true)}>Edit</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>}>
      < DefaultPopUp.Body>
     
        {detailsData?.id && <TaskFormApp refreshData={() => { closeModal(); props.refreshData?.(); }} show={showEditTask} toggle={toggleEditTask} category={detailsData?.category || "e"} taskDetail={detailsData} />}
        <Body />
      </DefaultPopUp.Body>
      <DefaultPopUp.ActionBar>
        <IonFooter className=" ion-padding-start ion-padding-end pt-3 pb-3 border-top">
          <div className="row">
            <div className="col align-self-center ">
              <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                <input type="checkbox" id="checkbox_button_medium_1" onChange={toggleTaskStatus} name="checkbox_button_medium " checked={detailsData?.completed} className="custom-control-input " />
                <label className="custom-control-label" htmlFor="checkbox_button_medium_1">Mark as Complete</label>
              </div>
            </div>
          </div>
        </IonFooter>
      </DefaultPopUp.ActionBar>

      <DefaultPopUpApp title='Delete Recurring tasks' isOpen onDidDismiss={() => { }} customHeader={<IonHeader>
        <IonToolbar>
          <IonButtons  className="w-15pr" slot="start">
            <IonButton color={"blue"} onClick={closeModal}><img className='height-18px' src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" />back</IonButton>
          </IonButtons>
          <IonTitle>Delete Recurring {detailsData?.category_text}</IonTitle>
          <IonButtons className="w-15pr" slot="end">
            <IonButton color={"blue"} onClick={() => handleTaskRemove()}>Delete</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>}>
        < DefaultPopUp.Body></DefaultPopUp.Body>
      </DefaultPopUpApp>
    </DefaultPopUpApp>
  );
}