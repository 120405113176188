import { IonRouterLink } from "@ionic/react";
import { useState, useRef } from "react";
import { GetCalendarTasksApi } from "services/calendar/getCalendarTasks";
import { AddTaskPopUpApp } from "views/calendar/popups/AddTaskPopUp/AddTaskPopUp.app";
import { CalendarTemplateApp } from "views/templates/CalendarTemplate/CalendarTemplate.app";
import { DefaultTemplate } from "views/templates/DefaultTemplate";
import { CalendarTimelineScreenApp } from "../CalendarTimelineScreen/CalendarTimelineScreen.app";
import { ICalendarLandingScreenProps } from "./CalendarLandingScreen.def";
import moment from 'moment';


export const CalendarLandingScreenApp = (props: ICalendarLandingScreenProps): JSX.Element => {
    const { taskId } = props
    const [timelineFilter, setTimelineFilter] = useState<GetCalendarTasksApi.Request>({ categories: "T,E" });
    const [section, setSection] = useState("timeline");
    const contentRef = useRef(null)
    const [reload, doReload] = useState(1);

    const [refreshData, handleRefreshData] = useState(false)
    const [month, setMonth] = useState(moment().toDate());

    return <DefaultTemplate title="Calendar" fluid={true} contentRef={contentRef} CustomMobileHeader={() => <CustomMobileHeader />}>
        <CalendarTemplateApp setMonth={setMonth} month={month} onFilterChange={setTimelineFilter} onSectionChange={setSection} refreshData={handleRefreshData} section={section} onSwipeRefresh={() => doReload(reload + 1)}>
            <CalendarTimelineScreenApp taskId={taskId} month={month} contentRef={contentRef} filter={timelineFilter} refreshData={refreshData} key={reload} />
        </CalendarTemplateApp>
    </DefaultTemplate>
}
interface ICustomMobileHeaderProps { }
const CustomMobileHeader = (_props: ICustomMobileHeaderProps): JSX.Element => {
    const [showAddTask, toggleShowAddTask] = useState(false)
    return (
        <div className="ml-auto align-self-center">
            <IonRouterLink onClick={() => toggleShowAddTask(true)}>
                <a onClick={() => toggleShowAddTask(true)}><img className=" w-22px" src="https://content.harstatic.com/media/icons/plus_auxilliary.svg"></img></a>
            </IonRouterLink>
            <AddTaskPopUpApp show={showAddTask} toggle={toggleShowAddTask} />
        </div>
        
    );
}