import {  IContactInsightSectionProps } from './ContactInsightSection.def';
import './ContactInsightSection.scss';
import { useEffect, useState } from 'react';
import { OverlayTrigger  } from 'react-bootstrap';
import { popover_score,popover_tags_text,popover_tags } from './components/InfoOverlayTrigger';
import { ScoreOptions } from '../ScoreOptions/ScoreOptions';
import {  IonText, IonItem } from '@ionic/react';
import {DropDownModal} from '../../forms/dropdowns/DropDownModal/DropDownModal.app'
import { fetchGetTagsService,GetTagsApi } from 'services/contacts/tags/getTags';
import { useFetchLookupsService ,getLongvalue} from 'services/general/lookups';
import { ITag } from 'views/forms/textboxes/TagsTextbox/TagsTextbox.def';
//import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useUpdateContactInsightService } from 'services/contacts/contactDetails/updateContactInsight';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useParams } from 'react-router';
import {Loader} from './components/Loader';
import { useCreateTagService } from 'services/contacts/tags/createTag';
import { switchCase } from 'scripts/helpers/general';

export const ContactInsightSectionApp = (props: IContactInsightSectionProps): JSX.Element => {
    const { initialValues, onChange } = props;
    const { id } = useParams<{ id: string }>();
    const [newTagInput,setNewTagInput]=useState("")
    const [selectedTags, setSelectedTags] = useState(initialValues?.tags ?? []);
    const [selectedSource, setSelectedSource] = useState<string>(initialValues?.sourceType??"");
    const [selectedPipeline, setSelectedPipeline] = useState<string>(initialValues?.pipelineType??"");
    const [selectedLeadType, setSelectedLeadType] = useState<string>(initialValues?.leadType??"");
    const [tagsList,setTagsList]=useState<GetTagsApi.Response>([])
    const [lookupData] = useFetchLookupsService(['contact_source', 'pipeline_stage', 'contact_lead_type']);
    const [,toggleEditMode]=useState(false);
    const [updateData, _ActionUpdateContactInsight] = useUpdateContactInsightService();
    const [saveNewTagData, saveNewTagActions] = useCreateTagService();

    useServiceDidSucceeded(updateData, () => { displayMessage('The contact updated successfully.', { onClose: onSuccess }); onSuccess() })
    useServiceDidSucceeded(saveNewTagData, () => {fetchTagsData();   
    saveNewTagData?.response?.data.id&&  triggerChange("tags", [...selectedTags,{id:parseInt(saveNewTagData?.response?.data.id),name:saveNewTagData?.response?.data.name}]);
})

    const handleResetSelectedValue=()=>{
       const initialTags= initialValues?.tags||[]
        setSelectedSource(initialValues?.sourceType??"");
        setSelectedPipeline(initialValues?.pipelineType??"");
        setSelectedLeadType(initialValues?.leadType??"");
       setSelectedTags(initialTags)
    }
    useEffect(handleResetSelectedValue,[initialValues])
    const handleSave=async(key:string)=>{
      if(newTagInput.length>1)  {
       await saveNewTagActions.fetch({name:newTagInput})
         setNewTagInput("")
        }else{
            const value = switchCase(key, "tags", selectedTags, "pipelineType", selectedPipeline, "leadType", selectedLeadType, "sourceType", selectedSource);
            if(value) { triggerChange(key, value); }
        }
    }

    const triggerChange = (key: string, value: string | ITag[]) => {
        onChange?.({ ...initialValues, [key]:value })
    }
    
   
    const transformStagePipeLineLookup = (): Array<{ id: string, textClass: string, label: string }> => {
        if (!lookupData.response?.pipeline_stage.items) { return [] }
        const pipelineData = lookupData.response?.pipeline_stage.items.map(({ long_value, short_value }) => ({
            id: short_value, textClass: 'stage stage__' + long_value.replace("Active Listing / ", "").replace(" ", "").toLowerCase(), label: long_value
        }))
        return [...pipelineData]
    }
    const transformSourceLookup = (): Array<{ value: string, label: string }> => {
        if (!lookupData.response?.contact_source.items) { return [] }
        const sourceData = lookupData.response?.contact_source.items.map(({ long_value, short_value }) => ({
            value: short_value, label: long_value
        }))
        return [...sourceData]
    }
    const transformLeadTypeLookup = (): Array<{ value: string, label: string }> => {
        if (!lookupData.response?.contact_lead_type.items) { return [] }
        const leadTypeData = lookupData.response?.contact_lead_type.items.map(({ long_value, short_value }) => ({
            value: short_value, label: long_value
        }))
        return [...leadTypeData]
    }
    const pipelineValue = lookupData.response?.pipeline_stage && getLongvalue(lookupData.response?.pipeline_stage, initialValues?.pipelineType, 'None')

    useEffect(() => {
         if(!!!tagsList.length){ fetchTagsData();}
    }, [initialValues, updateData]);

    const fetchTagsData = async (): Promise<void> => {
        const response = await fetchGetTagsService();
        setTagsList(response);
    };
    const toggleSelectTags=(item:GetTagsApi.ITag)=>{
        if(selectedTags.find((sItem)=>sItem.id==item.id)){
            setSelectedTags(selectedTags.filter((selectedItem)=>selectedItem.id!==item.id))
        }else{
            setSelectedTags([...selectedTags,item]);
        }
    }
    const selectedTagsString=():string=>{ 
        if(initialValues?.tags?.length===1){return initialValues?.tags[0]?.name?.toString()||"" }else if(initialValues?.tags&&initialValues.tags.length>1){
            const length=initialValues?.tags?.length-1
            return initialValues?.tags[0]?.name +" +"+length
        }
        return "";
    }

        if(!!id &&!props.isEdit){ return <ViewForm {...props} toggleEdit={toggleEditMode}/>;}

    if(lookupData.loading || lookupData.error){
        return <></>
    }

  {/**  const updateContact=()=>{
        ActionUpdateContactInsight.fetch({
            contact_id: id,
            score: initialValues?.scoreType,
            pipeline_status:initialValues?.pipelineType,
            sources: initialValues?.sourceType,
            tags: initialValues?.tags
        });
    }*/}

    
   const onSuccess=()=>{
        toggleEditMode(false); 
        props.onSuccess?.()
    }
 

    if(props.loading || lookupData.loading || lookupData.error){return <Loader/>}

   

   return (<div>
      <div className="row pb-3">
            <div className="col align-self-center">
                <IonText className="sec_title font-weight_semi-bold ml-3">Contact Insight</IonText></div>
               {/**  {!!id&&<div className="col-auto text-right mr-3">
                <ShapelessButton onClick={()=>toggleEditMode(false)}>Cancel</ShapelessButton>
                <PrimaryButton onClick={updateContact}>Save</PrimaryButton></div>}*/}
            </div>
        <div className="card p-4 mb-5">
        <IonItem className="tab__nextpage" role="button" >
          <div className="tab__nextpage_label">Score <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
     </IonItem> 
            <ScoreOptions label="Score" initialSelected={initialValues?.scoreType} onScoreChange={(value)=>triggerChange("scoreType",value||"")} />
            <div className='mb-5'/>
            <DropDownModal
                //secondary
                className={`stage ${stageClassName(initialValues?.pipelineType)}`}
                name="pipelineType" 
                label='Stage on Pipeline'
                value={pipelineValue??""} 
                handleSave={handleSave}                
                resetSelectedValue={handleResetSelectedValue}
                >
                {transformStagePipeLineLookup().map(({id,textClass,label},index)=>
                    <div key={index} className="choosable__stage" >
                    <input type="radio" className="custom-control-input" name="stageoption" onChange={()=>setSelectedPipeline(id)} id={id} checked={selectedPipeline==id} value={id} />
                    <label className="custom-control-label" htmlFor={id}>
                        <div className={textClass}>{label}</div>
                    <div className="check"></div>
                    </label>
		        </div>)}
            </DropDownModal>
            <DropDownModal name="sourceType" label='Sources' value={initialValues?.sourceType || ""} handleSave={handleSave} resetSelectedValue={handleResetSelectedValue}>
                    {transformSourceLookup().map(({label,value},index)=>
                     <div key={index} className="choosable__stage"  >
                        <input type="radio" className="custom-control-input" onChange={()=>setSelectedSource(value)}  checked={selectedSource==value} value={value}  name="sourceoption" id={value} />
                        <label className="custom-control-label" htmlFor={value}>
                            <div className="stage">{label}</div>
		            	<div className="check"></div>
		            </label>
		        </div>)}
            </DropDownModal>
            <DropDownModal name="leadType" label='Lead Type' value={initialValues?.leadType || ""} handleSave={handleSave} resetSelectedValue={handleResetSelectedValue}>
                    {transformLeadTypeLookup().map(({label,value},index)=>
                     <div key={index} className="choosable__stage"  >
                        <input type="radio" className="custom-control-input" onChange={()=>setSelectedLeadType(value)}  checked={selectedLeadType===value} value={value}  name="leadoption" id={value} />
                        <label className="custom-control-label" htmlFor={value}>
                            <div className="stage">{label}</div>
		            	<div className="check"></div>
		            </label>
		        </div>)}
            </DropDownModal>
            <DropDownModal name="tags" label='Tags' value={selectedTagsString()} handleSave={handleSave} resetSelectedValue={handleResetSelectedValue} OverlayButton={popover_tags_text}>
                <div className="tags-input">  <input className='form-control mb-4' type="text" id="input-tag" placeholder="Add a tag" value={newTagInput} maxLength={15} onChange={(e)=>setNewTagInput(e.target.value)}/>
                 {tagsList.map((item,ind)=> <div key={ind} className="border-bottom pb-3 mb-3">
                    <div className="row no-gutters" >
                        <div className="col-auto align-self-center"><img className="mr-3" src="https://content.harstatic.com/media/icons/a_all/icon_tags_blue.svg" /></div>
                        <div className="col font_size--large">{item.name}</div>
                        <div className="col-auto">
                            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mr-0">
                                <input type="checkbox"
                                 onChange={()=>toggleSelectTags(item)} 
                                 id={item.name} name="seelct_tag_1"
                                  className="custom-control-input" 
                                  checked={!!selectedTags.find((sItem)=>sItem.id==item.id)} 
                                  />
                                <label className="custom-control-label mr-0" htmlFor={item.name}></label>
                            </div>
                        </div>
                    </div>
                  </div>)}</div> 
            </DropDownModal>
        </div>
       </div>
    );
}

const ViewForm=(props: IContactInsightSectionProps)=>{
    const data= props.initialValues||{}
    const [lookupData] = useFetchLookupsService(['contact_source', 'pipeline_stage']);
    const pipelineValue=lookupData.response?.pipeline_stage&&getLongvalue(lookupData.response?.pipeline_stage, data?.pipelineType, 'None')
    const stagesClass = `stage ${stageClassName(data?.pipelineType)}`
   
     return (<div className='card mt-4 mb-4 p-4 ml-3 mr-3'>
           <div className="row pb-3">
         <div className="col align-self-center">
           <h3 tabIndex={0}>Contact Insight</h3>
         </div>
         
      {/**  <div className="col-auto text-right"><button className="btn btn--white" onClick={()=>props.toggleEdit?.(true)} type="button">Edit</button></div>*/}
       </div>
       <div className="">
       <div className="row small-gutters mb-4">
                    <div className="col-3">
                        <div className={`score_label score_label__medium score_label${data.scoreType==='A'?'--a':"__disabled"}`}>
                            <span className="score_label__alpha">A</span>
                            <span className="score_label__text">Score</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className={`score_label score_label__medium score_label${data.scoreType==='B'?'--b':"__disabled"}`}>
                            <span className="score_label__alpha">B</span>
                            <span className="score_label__text">Score</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className={`score_label score_label__medium score_label${data.scoreType==='C'?'--c':"__disabled"}`}>
                            <span className="score_label__alpha">C</span>
                            <span className="score_label__text">Score</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className={`score_label score_label__medium score_label${data.scoreType==='D'?'--d':"__disabled"}`}>
                            <span className="score_label__alpha">D</span>
                            <span className="score_label__text">Score</span>
                        </div>
                    </div>
                    <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_score}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger>
                </div>
         <div className="">
             <div className="border-bottom pb-3 mb-3 font_size--medium">
                 <div  className="value_headings" >STAGES ON PIPELINE</div>
                 <div  className={stagesClass}>{pipelineValue}</div>
             </div>
         </div>
         <div className="">
             <div className="border-bottom pb-3 mb-3 font_size--medium">
                 <div className="value_headings">SOURCES</div>
                 <div className="title__label__common">{data.sourceType || "None"}</div>
             </div>
         </div>
         <div className="">
             <div className="border-bottom pb-3 mb-3 font_size--medium">
                 <div className="value_headings mt-3">TAGS <OverlayTrigger trigger={['hover','focus']} placement="right" overlay={popover_tags}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></div>
                 {data?.tags?.length! > 0? data?.tags?.map((item,index)=><div key={index} className="title__label__common mt-3">{item.name}</div>): <div className="title__label__common">None</div>}
             </div>
         </div>
       </div>
   </div>)
   }
  export const stageClassName = (value?: string) => {
    if (value === '10') { return 'stage__new' }
    if (value === '20') { return 'stage__qualified' }
    if (value === '30') { return 'stage__cold' }
    if (value === '40') { return 'stage__warm' }
    if (value === '50') { return 'stage__hot' }
    if (value === '60') { return 'stage__undercontract' }
    if (value === '100') { return 'stage__pastclient' }

    return 'title__label__common';
}