import { IContactSectionAppProps,IContactDataApp,IEmailDataApp,IPhoneDataApp,ILinkDataApp } from './ContactSection.def';
import './ContactSection.scss';
import { Textbox } from 'views/forms/textboxes/Textbox/Textbox.app';
import {  IonItem, IonText,IonToggle,IonSelect ,IonSelectOption} from '@ionic/react';
import { useValidateConsumer } from 'scripts/hooks/validations';
import { useFetchLookupsService } from 'services/general/lookups';
import { ifElse, switchCase } from 'scripts/helpers/general';
import { hasPhoneExtension } from 'services/contacts/createContact';
import { PropsWithChildren, useRef } from 'react';
import { isNumber } from 'lodash-es';

const PREFERRED_FORCE_FIRST = true;
            
export const ContactSection = (props:IContactSectionAppProps): JSX.Element => {
    const { items,handleAddNew,setContact } = props;
    const isEmailList = !!items.emails?.length;
    const isPhonesList = !!items.phones?.length;
    const isLinksList = !!items.links?.length;
    const [, _notifyChange] = useValidateConsumer(props.validate, validate);
    const [lookupData] = useFetchLookupsService(['phone_type']);

      const fieldCheck =(item:{ value:string, preferred?:boolean }, isEmail:boolean =false ,isPhone:boolean=false,isLink:boolean):string  => {
          const fieldEmptyMsg='This field is required'
          const incorrectEmailMsg='This field should contain min 8 max 55 characters, qw@qw.co'   
          const incorrectPhoneMsg='This field should contain min 3 max 15 digits.';
          const incorrectLinkMsg='This field should contain URL with min 2 max 200 characters';

        //const preferred = 'preferred' in item? item.preferred: undefined;
        const value = item.value?.replaceAll("  ","");
        //if(!value) { return ''; }
        if(!value) { return fieldEmptyMsg; }

        /*if (value=='') {
          return fieldEmptyMsg;
        }else*/ if(isPhone&&(value.length < 3 ||value.length > 25))
          {return incorrectPhoneMsg}

       /* if (!value || value === '') {
          return isPhone?incorrectPhoneMsg:fieldEmptyMsg;
        }*/
        
          const regexEmail=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(isEmail&&!regexEmail.test(value)){
            return incorrectEmailMsg
        }
        const phoneNumberLength = value.replace(/[^0-9]/g,"").length

        if (isPhone && (value.length>25|| phoneNumberLength>15 ) ) {
          return incorrectPhoneMsg
        }
       const regexLink=/^\b(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]$/
       if (isLink&&!regexLink.test(value)) {
        return incorrectLinkMsg
      }
      return '';
    };

  const validatePhone = (item:{ value:string, phone_type?:string, phone_extension?:string | number }): Pick<IPhoneDataApp, 'error' | 'errorField'> =>
  {
    // Validating Phone
    const phone_extension = item.phone_extension !== undefined && isNumber(item.phone_extension)? item.phone_extension.toString(): item.phone_extension;
    const phone_type = item.phone_type || 'N';
    const isFilled = !!item.value.trim() || phone_type !== 'N' || phone_extension !== undefined;
    const phoneError = isFilled? fieldCheck(item,false,true,false): '';
    if(phoneError) { return { error:phoneError, errorField:'phone' }; }

    // Validating Extension
    if(!!phone_extension && hasPhoneExtension(phone_type) && phone_extension.length > 6) {
      return { error:'This field should contain max 6 digits', errorField:'ext' };
    }

    return { error:'' };
  }

  function validate() {
    var isValidate1,isValidate2,isValidate3=false
    const form:IContactDataApp={emails:[],phones:[],links:[]}; 
       items.emails.forEach((item:IEmailDataApp)=>{
        const isError= !!item.value.trim()? fieldCheck(item,true,false,false): '';
        form.emails.push({
              value: item.value,
              error: isError,
              preferred:item.preferred,
              importEmail:item.importEmail
            });
            
        });
        items.phones.forEach((item:IPhoneDataApp)=>{
          const { error, errorField } = validatePhone(item);
        form.phones.push({
              value: item.value,
              //error:(ind===0)?(items.phones[0]?.value.length??0)>1||item.phone_type!=="N"? fieldCheck(item,false,true,false):"":fieldCheck(item,false,true,false),
              error,
              errorField,
              preferred:item.preferred,
              phone_type: item.phone_type ?? 'N'
            });
        });
       items.links.forEach((item:ILinkDataApp)=>{
       form.links.push({
            value: item.value,
            error: !!item.value.trim()? fieldCheck(item,false,false,true): '',
            link_type: item.link_type
          });
      });
      isValidate1= Object.entries(form.emails).every(([, value]) => value.error==='');
      isValidate2= Object.entries(form.phones).every(([, value]) => value.error==='');
      isValidate3= Object.entries(form.links).every(([, value]) => value.error==='');

       setContact(form);
       
    return isValidate1&&isValidate2&&isValidate3
  }

  const handleChangeField:IContactSectionAppProps['handleChangeField'] = (...[ index, name, value, preferred, importEmail, phone_type, phone_extension, link_type ]) =>
  {
    let save = false;
    let id:number | undefined;
    let changed = true;
    let item:IEmailDataApp | IPhoneDataApp | ILinkDataApp | undefined;
    if(name === 'emails' && items.emails[index]) {
      
      item = items.emails[index]!;
      id = item.id;

      changed = item.value !== value || item.preferred !== preferred;
      //const isPreferredUnset = !preferred && item.preferred;
      item.error = !!item.value.trim()? fieldCheck({ value, preferred }, true, false,false): '';
      const hasError = item.error && item.error !== ''
      if(!hasError) { save = true; }

      /*if(!item.value.trim() && item.preferred && lastRequiredFields?.email) {
        item.value = lastRequiredFields.email.value;
        item.id = lastRequiredFields.email.id;
      }*/
    }
   else if(name === 'phones' && items.phones[index]) {
      
      item = items.phones[index]!;
      id = item.id;
      const { error, errorField } = validatePhone(({ value, phone_type, phone_extension }));
      item.error = error;
      item.errorField = errorField;
      //item.error = fieldCheck({ value, preferred }, false, true,false);
      if(!item.error || item.error === '') { save = true; }
    }
    else if(name === 'links' && items.links[index]) {
      item = items.links[index]!;
      id = item.id;
      item.error = !!item.value.trim()? fieldCheck({ value }, false, false, true): '';
      if(!item.error || item.error === '') { save = true; }
    }
    //setContact({ ...items });
    if(item) {
      setContact(prev => {
        prev[name as keyof typeof prev][index] = item!;
        return { ...prev };
      });
    }


    if(changed) {
      const isLocalEmpty = !value.trim() && !id;
      props.handleChangeField(index, name, value, preferred, importEmail, phone_type, phone_extension, link_type);
      if(save && props.handleSave && (!isLocalEmpty||name === 'links')) { props.handleSave(index, name); }
    }
  };

    return (<>
        <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Contact</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">
            {isEmailList && items?.emails.map((item,key)=>
            <FormView preferred isImportEmail key={key} index={key} itemsLength={items.emails.length} selectedItem={item} name="emails" placeHolder="Email"  {...props} handleChangeField={handleChangeField} />)}
            {items.emails.length<10&&<div id="email" className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("emails")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Email</div>
            </div>} 
         </div>
           <div className="form_sec secpadding_left secpadding_right">
            {isPhonesList && items?.phones.map((item,key)=>
            <FormView lookupData={lookupData.response?.phone_type.items} preferred key={key} index={key} itemsLength={items.phones.length} selectedItem={item} name="phones" placeHolder="Phone" {...props} handleChangeField={handleChangeField} />)}
            {items.phones.length<10&&<div className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("phones")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Phone</div>
            </div>} 
         </div>
             <div className="mb-3 secpadding_left">
             <IonText className="sec_title font-weight_semi-bold">Links</IonText>
            </div>
            <div className="form_sec secpadding_left secpadding_right">          
            {isLinksList && items?.links.map((item,key)=>
             <FormView key={item.id ?? key} index={key} itemsLength={items.links.length}  selectedItem={item} name="links" placeHolder="http://www…" {...props} handleChangeField={handleChangeField}  />)}
            {items.links.length<10&&<div className="addable_item pb-3 pt-3" onClick={()=>handleAddNew("links")}>                              
                <div className="align-self-center"><img src="https://content.harstatic.com/media/icons/crm/plus_blue.svg" /></div>
                <div className="pl-4 align-self-center">Add Link</div>
            </div>} 
         </div>
     </>)
                               
}

const FormView = (props: IContactSectionAppProps.FormViewProps): JSX.Element => {

const {handleRemoveContact,index,handleChangeField,name,placeHolder,preferred,/*isImportEmail,*/itemsLength,lookupData}=props;
const selectedItem = useRef(props.selectedItem);
selectedItem.current = props.selectedItem
const showPhoneExt = hasPhoneExtension(props.selectedItem?.phone_type);

// const handleSave:typeof props['handleSave'] = (index, name) =>
// {
//   if(shouldSave()) { props.handleSave?.(index, name); }
// }



const isPreferredDisabled = () => {
  if(PREFERRED_FORCE_FIRST) { return false; }
  if(itemsLength<2) { return true; }

  const item = selectedItem.current;
  if(!item?.value?.trim() || !!item.error.trim()) { return true; }
  if(!!item?.preferred && name === 'emails') { return true; }
  return false;
};

const isPreferredChecked = () => {
  return itemsLength < 2 && !PREFERRED_FORCE_FIRST? true: selectedItem.current?.preferred;
};

  // HACK: errorField based on the name
  const mainErrorField = name.slice(0, -1);

    return ( <div className="border-bottom">
                {preferred && index === 0 && PREFERRED_FORCE_FIRST && name === 'emails' && <div className="row no-gutters">
                  <div className="pl-4 p-2 font_size--medium color_available">Preferred Email</div>
                </div>}
                <div className="row no-gutters">
                    <div className="col-auto align-self-center">
                        <div className="delete_item d-flex">
                        {/*name==='emails'&& props.selectedItem?.preferred ?<></>: <img onClick={()=>handleRemoveContact(name,index)}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />*/}
                        <img onClick={()=>handleRemoveContact(name,index)}  className="align-self-center" src="https://content.harstatic.com/media/icons/crm/subtraction_minus.svg" />
                           {name==="links"&& <><div className="delete_item_value">Link. {index+1} </div>
                            <img className="align-self-center" src="https://content.harstatic.com/media/icons/arrow-right.svg" /></>}
                       </div>
                    </div>
                    <div className="col">
                  {name==="phones"&& <IonItem>                                                
                    <IonSelect id='phone_type' value={props.selectedItem?.phone_type} name='phone_type' placeholder="Phone Type" onIonChange={(e) => handleChangeField(index,name,selectedItem.current?.value??"",selectedItem.current?.preferred,selectedItem.current?.importEmail,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''), selectedItem.current?.phone_extension)} defaultValue="None">
                     {lookupData?.map((x, index) => (
                       <IonSelectOption  key={index} value={x.short_value}>{x.long_value}</IonSelectOption>
                               ))}
                      </IonSelect>
                    </IonItem>}
                  
                   {name==="links"&&
                    <Textbox value={props.selectedItem?.link_type} placeholder='e.g. Facebook profile' className="form-control mt-3" name={name}  maxlength={40} error={props.selectedItem?.error} onIonChange={(e)=> handleChangeField(index,e.target.name,selectedItem.current?.value??"",selectedItem.current?.preferred,selectedItem.current?.importEmail,selectedItem.current?.phone_type,selectedItem.current?.phone_extension,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''))}/>}
                   
                  <MainFieldContainer field={name}>
                    <Textbox
                      type={"text"}
                      value={props.selectedItem?.value}
                      name={name}
                      placeholder={placeHolder}
                      maxlength={switchCase(name, 'links', 200, 'emails', 55, 25)}
                      className={name === 'phones'? 'form-control': ''}
                      containerClassName={name==="phones" ? ifElse(showPhoneExt, "col-9 pr-0", "col-12 pr-0"): ""}
                      id={placeHolder}
                      aria-describedby={placeHolder}
                      error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === mainErrorField: props.selectedItem?.error}
                      onIonChange={(e) => handleChangeField(index,e.target.name,String(e.detail.value?.replace(/\s{2,}/g, ' ') ?? ''),selectedItem.current?.preferred,selectedItem.current?.importEmail,selectedItem.current?.phone_type,selectedItem.current?.phone_extension,selectedItem.current?.link_type)}>
                    </Textbox>
                    {name==="phones" && showPhoneExt && <div className="col-3 pl-0">
                      <Textbox
                        type="number"
                        className="form-control"
                        aria-describedby="Ext"
                        placeholder="Ext"
                        value={selectedItem.current?.phone_extension}
                        min={1}
                        max={999999}
                        maxlength={6}
                        error={props.selectedItem?.error && props.selectedItem?.errorField? props.selectedItem?.errorField === 'ext': undefined}
                        onIonChange={(e) => handleChangeField(index,name,selectedItem.current?.value??"",selectedItem.current?.preferred,selectedItem.current?.importEmail,selectedItem.current?.phone_type,e.detail.value ?? '')}
                        ionItemClassName='ion-no-padding'
                      />
                    </div>}
                    {props.selectedItem?.errorField && props.selectedItem?.error && <div className="col">
                      <div className='color_sold ml-3'>{props.selectedItem.error}</div>
                    </div>}
                  </MainFieldContainer>

                  {/* <IonIcon></IonIcon> */}
                  </div>
              </div>
              <div className="row no-gutters">
                   {preferred && (!PREFERRED_FORCE_FIRST || (index === 0 && name !== 'emails')) && <div className="col">
                      <div className="addable_item pl-1 pb-3 pt-1" >                              
                          <div className="pl-5 ml-2 align-self-center pr-3">Make it preferred</div>
                        <IonToggle name={name} disabled={isPreferredDisabled()} checked={isPreferredChecked()} onIonChange={(e)=>{handleChangeField(index,name,selectedItem.current?.value??"",e.detail.checked,selectedItem.current?.importEmail,selectedItem.current?.phone_type,selectedItem.current?.phone_extension,selectedItem.current?.link_type)}}></IonToggle>
                      </div>
                   </div>}
                    {/*isImportEmail&&<div className="col">
                    <div className="addable_item pb-3 pt-1" >                              
                        <div className="pl-4 align-self-center">Import emails</div>
                      <IonToggle  checked={props.selectedItem?.importEmail} onIonChange={()=>handleChangeField(index,name,selectedItem.current?.value??"",selectedItem.current?.preferred,!selectedItem.current?.importEmail)} ></IonToggle>
                    </div>
</div>*/}
              </div>
        </div>
        
    )
}

function MainFieldContainer(props:PropsWithChildren<Record<'field', string>>)
{
  const { field } = props;
  if(field === 'phones') { return <div className='row small-gutters'>{props.children}</div>; }
  return <>{props.children}</>;
}