import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace DeleteTagApi
{
    export const url = "/tags/:id";
    export const method = "DELETE";
    export const defaultError = "Failed to remove tag.";
    export const operations = apiOperations(DeleteTagApi);
    export const scope = new class DeleteTagApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();
    
    export interface Request
    {
        id:number;
    }

    export interface Response
    {
        
    }
}

export const fetchDeleteTagService = forwardOperation(DeleteTagApi.operations, 'fetch');
export const useFetchDeleteTagService = forwardOperation(DeleteTagApi.operations, 'useFetch');
export const useDeleteTagService = forwardOperation(DeleteTagApi.operations, 'useService');