import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace SaveResetSystemApi
{
    export const url = "/user/reset";
    export const method = "POST";
    export const defaultError = "Failed to reset system.";
    export const operations = apiOperations(SaveResetSystemApi);
    export const scope = new class SaveResetSystemApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(data:Response) { return data; }
    }();

    export interface Request
    {
    }

    export interface Response
    {
        
    }
}

export const fetchSaveResetSystemService = forwardOperation(SaveResetSystemApi.operations, 'fetch');
export const useFetchSaveResetSystemService = forwardOperation(SaveResetSystemApi.operations, 'useFetch');
export const useSaveResetSystemService = forwardOperation(SaveResetSystemApi.operations, 'useService');