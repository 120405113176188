import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace GetContactApi
{
    export const url = "/contacts/:id/pipeline";
    export const method = "GET";
    export const defaultError = "Failed to load contact list.";
    export const operations = apiOperations(GetContactApi);
    export const scope = new class GetContactDetailApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string | number>
        {
            return {
				id:input.id
			}
        }

        response(input:Record<'data', DeepPropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        id:number
    }

    export interface Response
    {
        
    }
}

export const fetchGetContactDetailService = forwardOperation(GetContactApi.operations, 'fetch');
export const useFetchGetContactDetailService = forwardOperation(GetContactApi.operations, 'useFetch');
export const useGetContactDetailService = forwardOperation(GetContactApi.operations, 'useService');