import {GlobalSearchApi} from "services/typeaheads/globalsearch"
import { Avatar } from "views/contacts/Avatar"
import { GlobalSearchTypeahead } from "views/forms/typeaheads/GlobalSearchTypeahead"

export const GlobalSearchPopup=()=>
{
    return(<><GlobalSearchTypeahead/></>)
        }
  export const GlobalSearchCard=(props:GlobalSearchApi.IContactData)=>{
            switch(props.last_name){
                case 'Contact':{
                    return (
                        <div className="typeahead__item pb-3 mb-3">	
                        <a href="#" className="agent_signature agent_signature__large">
                       <Avatar id={props.id} firstName=""/>
                         <div className="agent_signature__text">
                                <div className="agent_signature__text__title"><div className="label_email label_email--lead">Lead</div> {props.last_name}</div>
                                <div className="agent_signature__text__address">{props.last_name}</div>
                                <div className="agent_signature__text__tags">
                                  <div className="agent_signature__text__tags_tag"><img src="https://content.harstatic.com/media/icons/tag_small.svg" /> Matrix Contact</div>
                                  <div className="agent_signature__text__tags_tag"><img src="https://content.harstatic.com/media/icons/tag_small.svg" /> High interest</div>
                                </div>
                            </div>
                        </a>
                    </div>)}
                case 'Email':{
                        return (
                            <div className="card--email cursor--hand p-0 pb-3 mb-3">
                            <div className="card--email__agent custom_bg_1"></div>
                            <div className="card--emaill__content overflow-hidden">
                                <div className="card--email__content_heading">
                                    <div className="status_circle mr-2"></div>
                                   {props.last_name}                               
                                </div>
                                <div className="card--email__text text-truncate">
                                    {props.last_name}
                                </div>
                                <div className="card--email__date">
                                {props.last_name}  {props.last_name}
                                </div>
                            </div>                                 
                        </div>)}
          
             case 'Calendar':{
                            return (
                             <div className="card--email cursor--hand p-0  pb-3 mb-3">
                             <div className="card--email__agent custom_bg_2"></div>
                             <div className="card--emaill__content overflow-hidden">
                                 <div className="card--email__content_heading">                                            
                                    {props.last_name}                                   
                                 </div>
                                 <div className="card--email__text text-truncate">
                                     <div className="agent_signature agent_signature__mini mb-0">
                                         <div className="agent_signature__photo"></div>
                                         <div className="agent_signature__text">{props.last_name}</div>
                                     </div>
                                 </div>
                                 <div className="card--email__date">
                                 {props.last_name} {props.last_name}
                                 </div>
                             </div>                                  
                         </div>)}
            }
        return <></>
        }