
import { IonButton, IonButtons, IonContent ,IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { useFetchLookupsService } from 'services/general/lookups';
import { GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { FilterPopUp } from 'views/calendar/popups/FilterPopUp';
import {  GetCalendarTaskStatsApi } from "services/calendar/getCalendarTaskStats";
import { TaskStats } from "views/calendar/TaskStats";

interface IFilterModalProps{
    showFiltersModal:boolean,
    setShowFiltersModal:(showFiltersModal:boolean)=>void
    handleSetFilter:(filter:{})=>void
    filter:GetCalendarTasksApi.Request
    initialFilter:GetCalendarTasksApi.Request
    taskStats:GetCalendarTaskStatsApi.Response
}

export function FilterModalApp(props:IFilterModalProps) {
    const lookups=useFetchLookupsService(["task_type","task_priority"]);
    const prioritiesFilterData=lookups[0].response?.task_priority.items||[]
    const typesFilterData=lookups[0].response?.task_type.items||[];

    return (<IonModal className="gray_bg" trigger="open-modal" isOpen={props.showFiltersModal} onDidDismiss={() => props.setShowFiltersModal(false)} backdropDismiss={false}>
                    <IonHeader>
                        <IonToolbar>
                        <IonButtons className="w-15pr" slot="start">
                            <IonButton color={"blue"} strong={true} onClick={()=>{props.handleSetFilter(props.initialFilter);props.setShowFiltersModal(false)}}>Reset</IonButton>
                        </IonButtons>
                        <IonTitle>Filters</IonTitle>
                        <IonButtons className="w-15pr" slot="end">
                            <IonButton color={"blue"} strong={true} onClick={() => props.setShowFiltersModal(false)}>
                            Ok
                            </IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                        <TaskStats 
                            data={props.taskStats} 
                            handleSetFilter={props.handleSetFilter} 
                            filter={props.filter}
                            initialFilter={props.initialFilter}
                            />     
                        </div>
                        <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                            <div className=" form_title__large">Categories</div>                                                          
                            <FilterPopUp    
                            selectedValue={props.filter.categories||""} 
                            handleFilter={props.handleSetFilter} 
                            filterName="categories"
                            items={[{short_value:"T",long_value:"Task"},{short_value:"E",long_value:"Event"}]}
                            label="Categories"
                            />
                        </div>
                        <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                            <div className=" form_title__large">Priorities</div>                                                          
                           <FilterPopUp    
                            selectedValue={props.filter.priorities||""} 
                            handleFilter={props.handleSetFilter} 
                            filterName="priorities"
                            items={prioritiesFilterData}
                            label="Priorities"
                            />
                        </div>
                        <div className="form_sec__small secpadding_left secpadding_right secpadding_top secpadding_bottom">
                            <div className=" form_title__large">Types</div>                                                          
                          <FilterPopUp    
                            selectedValue={props.filter.types||""} 
                            handleFilter={props.handleSetFilter} 
                            filterName="types"
                            items={typesFilterData}
                            label="Types"
                            />
                        </div>
                    </IonContent>
                </IonModal>);
}

