import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { DropDownSecondary } from 'views/forms/dropdowns/DropDownSecondary';
import { ITaskCardProps } from './TaskCard.def';
import './TaskCard.scss';
import { getMaxStr } from 'scripts/helpers/texts';
import { Avatar } from 'views/contacts/Avatar';
import { isPlatform } from '@ionic/react';
export const TaskCard = (props: ITaskCardProps): JSX.Element => {
    const history = useHistory();
    const { data, returnUrl, openTask, handleTaskCompleted,openContactQuickOverviewPopup } = props;
    const [isCompleted, setCompleted] = useState<boolean>(data.completed);
    const redirect = (id?: number) => {
        id && history.push(`/contacts/${id}/overview`, { referrer: returnUrl })
    }

    const title = data.title &&  data.title.length > 25 ? `${data.title.slice(0, 25)}...` : data.title ?? '';
    if (props.compact) {
        return (<div className="col-12 col-md-12">
            <div className={isCompleted ? 'cards cards--taskcomp selected' : 'cards cards--taskcomp'}>
                <div className="cards--taskcomp__left">
                    <div className="cards--taskcomp__chkbx">
                        <div className="cards--taskcomp__footer__checkbox">
                            <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                                <a data-toggle="tooltip" title="Mark as complete">
                                    <input type="checkbox" id={data.id.toString()} name="card_checkbox" className="custom-control-input" checked={isCompleted} onChange={() => { setCompleted(!isCompleted); handleTaskCompleted(data) }} />
                                    <label className="custom-control-label mb-0" htmlFor={data.id.toString()}></label></a>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => openTask?.(data?.id.toString())} className="cards--taskcomp__title">
                        <a onClick={() => openTask?.(data?.id.toString())} data-toggle="tooltip" title={data.title}>
                            <h4 tabIndex={0}>{isCompleted ? <del>{title}</del>: title}</h4>
                        </a>
                        <div className="cards--taskcomp__title__date">{data.dateTime}</div>
                    </div>
                </div>
                <div className="cards--taskcomp__right">
                    <div className="cards--taskcomp__right__user">
                        {data.contacts.length > 0 &&
                            <div className="d-flex">
                                <div className="agent_signature agent_signature__mini">
                                    <Avatar id={data.contacts[0]?.id.toString() ?? ""} firstName={data.contacts[0]?.first_name} lastName={data.contacts[0]?.last_name} photo={data.contacts[0]?.photo_url} />
                                    <div className="agent_signature__text">{data.contacts[0]?.first_name}.</div>
                                </div>
                                {data.contacts.length > 1 && <div className="agent_signature agent_signature__mini ml-3">
                                    <Avatar id={data.contacts[0]?.id.toString() ?? ""} firstName={data.contacts[0]?.first_name} lastName={data.contacts[0]?.last_name} photo={data.contacts[0]?.photo_url} />
                                    <div className="agent_signature__text">{data.contacts[1]?.first_name}.</div>
                                </div>}
                                {data.contacts.length > 2 &&
                                    <div className='zindex-9999 mb-3'> <DropDownSecondary label={`+${data.contacts.length - 2}`}>
                                        {data.contacts.map((item, index) =>
                                            <Dropdown.Item key={index} className="dropdown-item animate__animated animate__fadeInUp animate__faster pb-2 cursor--hand" onClick={() => item?.deleted=='0'&&redirect(item.id)}>
                                                <div className="agent_signature agent_signature__mini2">
                                                    <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                                                    <div className="agent_signature__text">{item.first_name} {item.last_name}</div>
                                                </div>
                                            </Dropdown.Item>)}
                                    </DropDownSecondary></div>}
                            </div>}


                        {/*<Dropdown className='dropdown dropdown--custom dropdown--custom__inverse'>
                                            <Dropdown.Toggle className="dropdown-toggle border-0 no-outline p-0 m-0" role="link" variant="none" href="#">                                    
                                                <div className="font_weight--bold pl-2 d-inline-block">+8</div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu   className="dropdown-menu inline-dropdown agent_dropdown animate slideIn pt-3 pb-3">
                                                <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster pb-2" href="#">
                                                    <div className="agent_signature agent_signature__mini2">
                                                        <div className="agent_signature__photo"></div>
                                                        <div className="agent_signature__text">Angela Vidal</div>
                                                    </div>
                                                </Dropdown.Item>  
                                            </Dropdown.Menu>
                                        </Dropdown>
                                */}

                        <div className="cards--taskcomp__right__add">{getMaxStr(data.location || data.property_full_street_address, 35)}</div>
                    </div>
                    <div onClick={() => openTask?.(data?.id.toString())} className="cards--taskcomp__right__status" >
                        <div className={"label  label--" + data.type_text.toLowerCase().replaceAll(" ", "")}>{data.type_text}</div>
                        <div className={"font_weight--semi_bold " + cssClassPriority(data.priority)}>{`${data.priority_text} Priority`}</div>
                    </div>
                </div>

            </div></div>)
    }

    return (<div className="col-12 col-md-6" >
        <div className={isCompleted ? 'cards cards--tasks selected' : 'cards cards--tasks'}>
            <div className="cards--tasks__content">
                <div onClick={() => openTask?.(data?.id.toString())} className="cards--tasks__content_header cursor--hand">
                    <div className="cards--tasks__content_header_title">
                        <a onClick={() => openTask?.(data?.id.toString())} data-toggle="tooltip" title={data.title}>
                            <h4 tabIndex={0}>{isCompleted ? <del>{title}</del>: title}</h4>
                        </a>
                    </div>

                    <div className="cards--recommended__content_header_labels">
                        {data.type && <div className={"label label--" + data.type_text.toLowerCase().replaceAll(" ", "")}>{data.type_text}</div>}
                        {data.priority && <div className={"font_weight--semi_bold " + cssClassPriority(data.priority)}>{`${data.priority_text} Priority`}</div>}
                    </div>
                </div>

                <div onClick={() => openTask?.(data?.id.toString())} className="cards--tasks__content_header_date">{data.dateTime}</div>
                <div onClick={() => openTask?.(data?.id.toString())} className="cards--tasks__content_text">
                    {data.location || data.property_full_street_address}
                </div>
                <div className="d-flex">
                    {data.contacts.length > 0 &&
                        <div onClick={() => (data.contacts[0]?.deleted=='0'&& data.contacts[0]?.id!==0) && isPlatform("desktop")? openContactQuickOverviewPopup?.(data.contacts[0]?.id.toString()??""):redirect(data.contacts[0]?.id) } className="agent_signature agent_signature__mini cursor--hand">
                            {/**   <ContactPhoto contact={data.contacts[0]} />*/}
                            <Avatar id={data.contacts[0]?.id.toString() ?? ""} firstName={data.contacts[0]?.first_name} lastName={data.contacts[0]?.last_name} photo={data.contacts[0]?.photo_url} />
                            <div className="agent_signature__text">{data.contacts[0]?.first_name} {data.contacts[0]?.last_name} {data.contacts[0]?.deleted=="1"?`(deleted)`:""}</div>

                        </div>}
                    {data.contacts.length > 1 &&
                        <DropDownSecondary label={`+${data.contacts.length - 1}`}>
                            {data.contacts.map((item, index) =>
                                <Dropdown.Item key={index} className="dropdown-item animate__animated animate__fadeInUp animate__faster pb-2 cursor--hand" onClick={() => (item.deleted=='0'&& item?.id!==0)&&isPlatform("desktop")? openContactQuickOverviewPopup?.(item.id.toString()??""):redirect(item.id)}>
                                    <div className="agent_signature agent_signature__mini2 for_dropdown">
                                        {/** <ContactPhoto contact={item} index={index} /> */}
                                        <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                                        <div className="agent_signature__text text-truncate">{item.first_name} {item.last_name} {item.deleted=="1"?`(deleted)`:""}</div>
                                    </div>
                                </Dropdown.Item>)}
                        </DropDownSecondary>}
                </div>
            </div>
            <div className="cards--tasks__footer">
                <div className="cards--tasks__footer__checkbox">
                    <div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                        <a data-toggle="tooltip" title="Mark as complete">
                            <input type="checkbox" id={data.id.toString()} name="card_checkbox" className="custom-control-input " checked={isCompleted} onChange={() => { setCompleted(!isCompleted); handleTaskCompleted(data) }} />
                            <label onClick={() => { setCompleted(!isCompleted); handleTaskCompleted(data) }} className="custom-control-label" htmlFor={data.id.toString()}><b>{data.category_text}</b> complete</label></a>
                    </div>
                </div>
                <div className="cards--recommended__footer__viewmorelink cursor--hand custom_font color_har_blue">
                    <div onClick={() => openTask?.(data?.id.toString())} className="mr-3">View details →</div>
                </div>
            </div>
           
        </div>
    </div>
    );
}
const cssClassPriority = (value: string) => {
    return value.toLowerCase() === "h" ? "color_sold high" :
        value.toLowerCase() === "l" ? "color_auxiliary_light" :
            "color_pending"
}