type SwitchArguments<T, N extends number = 8, A extends any[][] = [[T, unknown], [T, unknown, unknown]]> =
    A['length'] extends N ? A[number] : SwitchArguments<T, N, [[...A[0], T, unknown], [...A[0], T, unknown, unknown], ...A]>;



type IfElseResponse<T extends Array<unknown>, TCondition extends boolean = true> =
    T extends [infer R, ...infer A extends [unknown, ...Array<unknown>]]?
        (TCondition extends true? IfElseResponse<A, false>: R | IfElseResponse<A, true>):
    T extends [infer R]?
        (TCondition extends true? R: R | undefined):
    never;


/*type SwitchResponse<T extends Array<unknown>, TEven extends boolean | null = null> =
    TEven extends null?
        (T extends [unknown, unknown, ...infer A extends [unknown, ...Array<unknown>]]? IfElseResponse<A, false>: never):
        never;*/



export function switchCase<E, A extends SwitchArguments<E>>(expression:E, ...input:A): IfElseResponse<A>;
export function switchCase<E>(expression:E, ...input:Array<unknown>): unknown
{
    if(input.length < 2) { return undefined; }
    while(input.length)
    {
        if(input.length === 1) { return input.pop(); }
        const caseValue = input.shift();
        const result = input.shift();
        if(expression == caseValue) { return result; }
    }

    return undefined;
}

export function ifElse<T extends SwitchArguments<boolean>>(...input:T): IfElseResponse<T>
export function ifElse(...input:unknown[]): unknown
{
    if(input.length < 2) { return undefined; }
    while(input.length)
    {
        if(input.length === 1) { return input.pop(); }
        const condition = input.shift();
        const result = input.shift();
        if(!!condition) { return result; }
    }

    return undefined;
}