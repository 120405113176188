import { format, parseISO } from 'date-fns';
import { formatHarTime, formatSystemDate, formatSystemDateTime } from 'scripts/helpers/dates';
import { GetCalendarTaskDetailsApi } from 'services/calendar/getCalendarTaskDetails';
import { ListingsApi } from 'services/typeaheads/listings';

export interface IAddTaskPopUpProps {
    toggle: (show: boolean) => void,
    show: boolean,
    refreshData?: (go: boolean) => void

}
export interface IChooseTaskModalProps {
    toggle: (show: boolean) => void,
    show: boolean,
    setCategory: (category: string) => void,
    toggleTask: (show: boolean) => void

}
export interface IAddTaskProps {
    toggle: (show: boolean) => void,
    show: boolean,
    category: string,
    refreshData: (go: boolean) => void,
    taskDetail?: GetCalendarTaskDetailsApi.Response
    onClose?: () => void
    openContactQuickOverviewPopup?:(contactId:string)=>void,
}


export interface IFormDataProps {
    title: IFieldValueProps,
    start_dt: IFieldValueProps,
    start_time: IFieldValueProps,
    task_type: IFieldValueProps,
    property_full_street_address: { value: ListingsApi.IListingsList, error?: boolean | string, isManually: boolean },
    contacts: { list: Array<any>, error?: boolean | string },
    priority: string,
    reminder_minutes_prior: Array<string>,
    repeat: string,
    description: string,
    completed: boolean,
    notify_contacts: boolean,
    end_dt: IFieldValueProps,
    end_time: IFieldValueProps,
    repeat_every_value: string,
    repeat_every_text: string,
    repeat_on_day: string,
    repeat_end_on: string,
    repeat_end_date: string,
    repeat_month_option: string,
}

export interface IAssignContactsProps {
    toggle: (show: boolean) => void,
    show: boolean,
}
export interface IFieldValueProps { value: string, error?: boolean | string }

export const defaultFormData = {
    title: { value: "", error: false },
    start_dt: { value: "", error: false },
    start_time: { value: "", error: false },
    end_dt: { value: "", error: false },
    end_time: { value: "", error: false },
    task_type: { value: "", error: false },
    property_full_street_address: { value: ListingsApi.getInitialListingList(), error: false, isManually: false },
    contacts: { list: [], error: false },
    notify: false,
    priority: "M",
    reminder_minutes_prior: [],
    repeat: "",
    description: "",
    completed: false,
        notify_contacts:false,
    repeat_every_value: "",
    repeat_every_text: "",
    repeat_on_day: "",
    repeat_end_on: "",
    repeat_end_date: "",
    repeat_month_option: ""
}
export const transformEditData = (editTaskData: GetCalendarTaskDetailsApi.Response, isApp: boolean = false) => {

    const startDate = isApp ? formatSystemDateTime(editTaskData.start_dt) : format(editTaskData.start_dt, "yyyy-MM-dd'T'HH:mm:ss")
    const endDate = isApp ? formatSystemDateTime(editTaskData.end_dt) : format(editTaskData.end_dt, "yyyy-MM-dd'T'HH:mm:ss");
    const repeat_end_date = !!editTaskData.repeat_end_date ? isApp ? formatSystemDateTime(parseISO(editTaskData.repeat_end_date)) : formatSystemDate(parseISO(editTaskData.repeat_end_date)) : ''


    return {
        title: { value: editTaskData.title, error: false },
        start_dt: { value: startDate, error: false },
        start_time: { value: formatHarTime(editTaskData.start_dt), error: false },
        task_type: { value: editTaskData.type, error: false },
        property_full_street_address: { value: { ...ListingsApi.getInitialListingList(), full_address: editTaskData.location, harid: editTaskData.property_harid, listingid: editTaskData.property_listingid, for: editTaskData.property_for, street_address: editTaskData.property_full_street_address, city: editTaskData.property_city, state: editTaskData.property_state, zip: editTaskData.property_zip, country: editTaskData.property_country, price: editTaskData.property_price, photo: editTaskData.property_photo, address_2: editTaskData.property_address2 }, error: false, isManually: false },
        contacts: { list: editTaskData.contacts, error: false },
        notify: false,
        priority: editTaskData.priority == '' ? "M" : editTaskData.priority,
        reminder_minutes_prior: editTaskData.reminder_minutes_prior.toString().split(","),
        repeat: editTaskData.repeat,
        description: editTaskData.notes,
        completed: editTaskData.completed,
        notify_contacts:false,
        end_dt: { value: endDate, error: false },
        end_time: { value: formatHarTime(editTaskData.end_dt), error: false },
        repeat_every_value: editTaskData.repeat_every_value,
        repeat_every_text: editTaskData.repeat_every_text,
        repeat_on_day: editTaskData.repeat_on_day,
        repeat_end_on: editTaskData.repeat_end_on,
        repeat_end_date: repeat_end_date,
        repeat_month_option: editTaskData.repeat_month_option
    }

}

export interface IChooseRecurringOptionsProps {
    selectedRecurringOption: string,
    showRecurringPopup: boolean,
    setShowRecurringPopup: (value: boolean) => void,
    handleTaskUpdate:()=>void,
    setRecurringOption: (value: string) => void,

}