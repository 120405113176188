import { EmailDetailsApi, useEmailDetailsService } from 'services/emails/emailDetails';
import { IEmailDetailProps } from './EmailDetail.def';
import './EmailDetail.scss';
import { useEffect, useState } from 'react';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { Avatar } from 'views/contacts/Avatar';
import { parseISO, format, getMonth } from 'date-fns'
import { useMarkEmailReadService } from 'services/emails/markEmailRead';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IonFooter, IonIcon, IonImg, IonText } from '@ionic/react';
import { getMonthName, strToDate } from 'scripts/helpers/number';
import { getDayNumber } from 'scripts/helpers/dates';
import { useHistory } from 'react-router';
import { MultipleRecipientsPopover } from './EmailDetail.common';
import { fetchDownloadAttachmentService } from 'services/emails/downloadAttachment';
import { SendEmailsApi } from 'services/emails/sendEmails';
import { checkInlineAttachments, replaceInlineImages } from '../hooks/emailHook';
import { documentAttachOutline } from 'ionicons/icons';
import { ContactTitle } from 'views/general/ContactTitle';

export const EmailDetailApp = (props: IEmailDetailProps): JSX.Element => {
    const { id, preview, type, connectionStatus, setPreview, createDraft, setCounter } = props
    const [emailService, emailServiceAction] = useEmailDetailsService();
    const [loadingAttachments, setLoadingAttachment] = useState<string[]>()
    const [email, setEmail] = useState<EmailDetailsApi.Response>()
    const [showMail, setShowMail] = useState(false)
    const [, markedReadServiceAction] = useMarkEmailReadService();
    const isContactCreated = !!email?.contact_id;
    let history = useHistory()

    function getEmailDetail(id: string) {
        if (type) {
            emailServiceAction.fetch({ id: id, type: type })
        }
    }

    function markedEmail(id: string, isLead: string) {
        if (type === 'inbox' || type === 'lead') {
            markedReadServiceAction.fetch({ email_ids: [id] })
            setCounter?.(id, type, isLead === '1' ? true : undefined)
        }
    }


    function downloadAttachment(attachment_id: string, file: string) {
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute("download", "")
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoadingAttachment(loadingAttachments?.filter((file: any) => file.id !== attachment_id) && [])
    }
    const requestDownloadAttachment = async (message_id: string, attachment_id: string, content_type: string) => {
        //setLoadingAttachment([...loadingAttachments ? loadingAttachments : [],file_size])
        const promise = fetchDownloadAttachmentService({ message_uid: message_id, attachment_id: attachment_id, content_type: content_type, version: "1" })
        setLoadingAttachment([...loadingAttachments ? loadingAttachments : [], attachment_id])
        const result = await promise
        if (result.data?.url) {
            downloadAttachment(attachment_id, result.data?.url)
        }


    }





    useServiceDidSucceeded(emailService, (response) => {
        const checkInlineAttachment = checkInlineAttachments(response)
        let message = response.message.replace(/<a/g, '<a target="_blank"')
        setEmail({ ...response, message: checkInlineAttachment.length > 0 ? replaceInlineImages(message, checkInlineAttachment) : message })
        if (response.read === "0") {
            markedEmail(response.id, response.is_lead)
        }






    })

    useEffect(() => {
        if (id) {
            setShowMail(true)
            getEmailDetail(id)
        }
    }, [id])



    return (
        <DefaultPopUp title='' isOpen={showMail || preview} hideHeader >
            <DefaultPopUp.Body>
                <div className="app_container" >
                    <div className="app_header position-sticky top-0" >
                        <div className="app_header--left" onClick={() => { setPreview?.(false); setShowMail(false) }}>
                            <div className="appLink">
                                <div className="appLink__img"><img src="https://content.harstatic.com/media/icons/arrow_back_harblue.svg" /></div>
                                {type &&
                                    <div className="appLink__text text-capitalize">{type}</div>
                                }
                            </div>
                        </div>
                        {type !== 'draft' &&
                            <div className="app_header--right">
                                {connectionStatus &&
                                    <>
                                        <div className="appLink">
                                            <div className="appLink__img" onClick={() => email ? createDraft?.(email, true) : ''}><img title='reply' className='w-32px h-32px' src="https://content.harstatic.com/media/icons/crm/icon-reply_.svg" /></div>
                                        </div>
                                        <div className="appLink">
                                            <div className="appLink__img" onClick={() => email ? createDraft?.(email, false) : ''}><img title='forward' className='w-32px h-32px' src="https://content.harstatic.com/media/icons/crm/icon-forward_.svg" /></div>
                                        </div>
                                    </>
                                }
                                {(type === 'inbox' || type === 'lead') && !isContactCreated &&
                                    <div className="appLink">
                                        <a className="appLink__text appLink__text_bold cursor-hand pt-1" title='Add Contact' onClick={() => {
                                            history.push(`/contacts/create`, {
                                                first_name: email?.first_name,
                                                last_name: email?.last_name,
                                                primary_email: email?.from_address
                                            })
                                        }}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#0738cd" className="bi bi-person-plus" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z" />
                                                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                            </svg></a>
                                    </div>
                                }
                            </div>
                        }

                    </div>
                    {emailService.loading ?
                        <Loading />
                        :

                        <div className="app_body">
                            <div className="border-bottom ion-padding">
                                {type === 'inbox' && isContactCreated &&
                                    <div className='pb-2 px-4' >
                                        <div className="row">
                                            <div className="col-auto ml-auto px-0">
                                                <IonImg src="https://content.harstatic.com/media/icons/greencheck_boldborder.svg" className="md hydrated w-16px" />
                                            </div>
                                            <div className="col mr-auto px-2">
                                                <a onClick={() => history.push('/contacts/' + email?.contact_id + '/overview', { referrer: '/emails/' + type })} className="font_weight--bold font_size--small color_available cursor--hand">Existing Contact</a></div>
                                        </div>
                                    </div>
                                }
                                <div className="row pb-2">
                                    <div className="col font_weight font_weight--semi_bold font_size--large"> {email?.subject}</div>
                                </div>

                                <div className="row">
                                    {(type === 'inbox' || type === 'lead') &&
                                        <div className="col">
                                            <div className="agent_signature agent_signature__large">
                                                {email?.contact_id &&
                                                    <Avatar
                                                        id={email?.contact_id}
                                                        firstName={email?.first_name}
                                                        lastName={email?.last_name}
                                                        photo={email.photo_url}
                                                        referrer={'/emails/inbox'}
                                                        link={isContactCreated ? '/contacts/' + email?.contact_id + '/overview' : undefined}

                                                    />
                                                }<div className="agent_signature__text" onClick={() => isContactCreated && history.push('/contacts/' + email?.contact_id + '/overview', { referrer: '/emails/inbox' })}>
                                                    {email?.first_last_name && <div className="agent_signature__text__title">{email?.first_last_name}</div>}
                                                    <div className="agent_signature__text__address"><span className="font-weight_semi-bold">{email?.from_address}</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    {type === 'sent' && email?.recipients[0] &&
                                        <div className="col">
                                            <a className="agent_signature agent_signature__large">
                                                <Avatar
                                                    id={email?.recipients[0].id}
                                                    firstName={email?.recipients[0].first_name}
                                                    lastName={email?.recipients[0].last_name}
                                                    photo={email?.recipients[0].photo_url}
                                                    referrer={'/emails/sent'}
                                                    link={'/contacts/' + email?.recipients[0].id + '/overview'}

                                                />
                                                <div className="agent_signature__text" onClick={() => email?.recipients[0]?.id ? history.push('/contacts/' + email.recipients[0].id + '/overview', { referrer: '/emails/sent' }) : ''}>
                                                    <div className="agent_signature__text__title"><ContactTitle contact={email?.recipients[0]} /></div>
                                                    <div className="agent_signature__text__address">{email.recipients[0].email}</div>
                                                </div>
                                                {email.recipients.length > 1 && <MultipleRecipientsPopover recipients={email.recipients} />}
                                            </a>
                                        </div>
                                    }
                                    {email?.created_dt &&
                                        <div className="col-auto text-right align-self-center font_weight--semi_bold color_auxiliary"><span className='opacity-64pr'>{email?.date_tag?.toUpperCase() === 'OLDER' ? getMonthName(getMonth(strToDate(email.created_dt))) + ' ' + getDayNumber(strToDate(email.created_dt)) : email?.date_tag?.toUpperCase()} <br />
                                            {format(parseISO(email.created_dt), 'hh:mm aaa').toUpperCase()}</span></div>
                                    }

                                </div>
                                {email?.folder === 'Forwarded' &&
                                    <div className='row pl-4 pt-2'>
                                        <div className='col pl-0'>
                                            <div className="label_email label_email--forwarded">Forwarded Message</div>
                                        </div>
                                    </div>
                                }

                                {email?.is_lead === '1' &&
                                    <div className='row pl-4 pt-2'>
                                        <div className='col pl-0'>
                                            <div className="label_email label_email--lead">Lead</div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="details detials_preview">
                                <div className="email_detail_inner">


                                    <div className="email_body ion-padding-top">
                                        {connectionStatus && email?.attachments && email?.attachments?.length > 0 &&
                                            <div className='pb-3'>
                                                {
                                                    email.attachments.map((file: SendEmailsApi.IEmailAttachment, key: number) =>

                                                        !loadingAttachments?.some(x => x === file.id) ?
                                                            <div key={key} className='row pl-3 pb-2 cursor--hand' onClick={() => file.id ? requestDownloadAttachment(email.message_uid, file.id, file.content_type ? file.content_type : '') : ""}>
                                                                <IonIcon icon={documentAttachOutline} size='small' ></IonIcon>
                                                                <div className='ml-2'>{file.file_name}</div>
                                                            </div>
                                                            :
                                                            <div key={key} className='row pl-3 pb-2'>
                                                                <IonText >Please wait your attachments is downloading.</IonText>
                                                            </div>
                                                    )

                                                }
                                            </div>
                                        }
                                        <h1 className="mb-3 d-none" tabIndex={0}>{email?.subject} </h1>

                                        {email?.message &&
                                            <iframe id="crmEmailDetailBody" className="email_body__content w-100 h-700px border-0" srcDoc={email?.message}></iframe>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <IonFooter className=" ion-padding-start ion-padding-end pt-3 pb-3 border-top">
                    <div className="row">
                        <div className="col align-self-center ">
                            {/*<div role="checkbox" className="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="checkbox_button_medium_1" onChange={()=>handleTaskCompleted(taskData!)}  name="checkbox_button_medium " checked={detailsData?.completed||taskData?.completed} className="custom-control-input " />
                    <label className="custom-control-label"  htmlFor="checkbox_button_medium_1">Mark as Complete</label>
               </div>*/}
                        </div>
                    </div>
                </IonFooter>
            </DefaultPopUp.ActionBar>
        </DefaultPopUp >
    );


}


const Loading = () => {
    return (
        <div className="details detials_preview">
            <div className="row mb-3">
                <div className="col">
                    <div className="card--email cursor--hand ">
                        <div className="card--email__agent"><div className="loading__photo mr-3 h-40px w-40px"></div></div>
                        <div className="card--emaill__content overflow-hidden w-100">
                            <div className="card--email__content_heading d-flex">
                                <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                            </div>
                            <div className="card--email__text text-truncate">
                                <div className="loading__text_small w-100"></div>
                            </div>
                            <div className="loading__text_smaller w-85px"></div>
                        </div>
                    </div>
                </div>
                <div className="col-auto d-flex align-self-lg-center">
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                    <div className="loading__square border_radius--default mr-4 w-20px h-20px"></div>
                </div>
            </div>
            <div className="email_detail_inner">
                <div className="email_body pt-3">
                    <div className="loading__text_small mb-3 w-120px"></div>
                    <div className="loading__text mb-4 w-200px"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-3 w-100"></div>
                    <div className="loading__text_small mb-4 w-70pr"></div>

                    <div className="border-top mt-3 pt-4">
                        <div className="loading__text_small mb-3 w-70px"></div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                        <div className="col-4">
                            <div className="loading__square border_radius--default mb-4 w-100 pb50pr"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
