import { Auth } from "./AuthService";
import { fetchGetBasicInfoService } from "services/user/getBasicInfo";
import { login as loginFeedback, logout as logoutFeedback } from "scripts/logging/user-feedback";
import { login as loginErrorReport, logout as logoutErrorReport } from "scripts/logging/error-report";
import { isString } from "lodash-es";
import { history } from "scripts/router/history";
import { isEmpty } from "scripts/helpers/texts";

export function toDefaultScreen()
{
    history.replace('/onboarding');
}

export function handleLogin(token?:string, nextUrl?:string | null)
{
    return Auth.loginProvider.handleLoginCallback(token).then(
        () => setTimeout(() => handleSuccessLogin(nextUrl), 250),
        (error) =>
        {
            const errorMessage = error && isString(error)? error: undefined;
            handleFailedLogin(errorMessage, nextUrl)
        }
    )

    // let url = window.location.origin +  props.location.pathname +  props.location.search;
    // Auth.Instance.authorizationCallback(url);
    // return Auth.Instance.events$.subscribe((action) => {
    //     console.log('action', action);
    //     if(action.action === AuthActions.SignInSuccess) {
    //         setTimeout(() => handleSuccessLogin(props), 1000);
    //     }

    //     if (action.action === AuthActions.SignInFailed) {
    //         const message = encodeURIComponent(action.error ?? 'Internal Error');
    //         setTimeout(() => props.history.replace(`/login?action=signin&error=${message}`), 2500);
    //     }
    // });
}

export function handleLogout(nextUrl?:string | null)
{
    return Auth.loginProvider.handleLogoutCallback().then(
        () => setTimeout(() => handleSuccessLogout(nextUrl), 250),
        (error) =>
        {
            const errorMessage = error && isString(error)? error: 'Internal Error';
            setTimeout(() => handleFailedLogout(errorMessage, nextUrl), 250)
        }
    );

    // Auth.Instance.endSessionCallback();
  
    // return Auth.Instance.events$.subscribe(async(action) => {
    //     if(action.action === AuthActions.SignOutSuccess) {
    //         setTimeout(() => handleSuccessLogout(props), 500);
    //     }
        
    //     if (action.action === AuthActions.SignOutFailed) {
    //         setTimeout(() => handleFailedLogout(action.error, props), 500)
    //     }
    // });
}


function handleSuccessLogin(nextUrl?:string | null)
{
    // Log to Sentry
    fetchGetBasicInfoService().then(user => {
        loginFeedback(user.user_name, user.agent_data?.email)
        loginErrorReport(user.user_name, user.agent_data?.email)
    });
    if(nextUrl) { history.push(nextUrl); }
    else { toDefaultScreen(); }
}

function handleSuccessLogout(nextUrl?:string | null)
{
    logoutFeedback();
    logoutErrorReport();
    historyReplace(isEmpty(nextUrl??'')?'/':'/login', '?action=signout', { nextUrl });
}

function handleFailedLogout(error:string | undefined, nextUrl?:string | null)
{
    error = error || 'Internal Error';
    historyReplace('/login', '?action=signout', { error, nextUrl });
}

export function handleFailedLogin(errorMessage:string | null | undefined, nextUrl?:string | null)
{
    const error = errorMessage || 'Internal Error';
    setTimeout(() => historyReplace('/login', '?action=signin', { error, nextUrl }), 1000);
}

function historyReplace(pathname:string, baseQuery:string, params?:{ error?:string, nextUrl?:string | null })
{
    const query = new URLSearchParams(baseQuery);
    if(params?.error) { query.append('error', params?.error) }
    if(params?.nextUrl) { query.append('nexturl', params?.nextUrl); }
    const queryString = query.size? '?' + query.toString(): '';
    history.replace(pathname + queryString);
}