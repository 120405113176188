import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace ZipCodesApi
{
    export const url = "/typeaheads/zipcodes";
    export const method = "GET";
    export const defaultError = "Failed to load zip codes.";
    export const operations = apiOperations(ZipCodesApi);
    export const scope = new class ZipCodesApiScope implements IApiScope<Request, Response>
    {
        request(data:Request) { return data; }
        response(input:Record<'data', ServerResponse>) { return input.data.map(x=>parseInt(x)); }
    }();
    
    export interface Request
    {
        input:string;
        limit?:number;
    }

    export type ServerResponse = Array<string>

    export type Response = Array<number>

    // export interface Response
    // {
        
    // }
}

export const fetchZipCodesService = forwardOperation(ZipCodesApi.operations, 'fetch');
export const useFetchZipCodesService = forwardOperation(ZipCodesApi.operations, 'useFetch');
export const useZipCodesService = forwardOperation(ZipCodesApi.operations, 'useService');