import { cmsHost, isProduction, mopHost, websiteHost } from "./settings";
import { IUrlParams, addQueryStringToUrl } from "scripts/helpers/urls";

export function buildWebsiteUrl(path:string, params?:IUrlParams, requiresAuthentication:boolean = false): string
{
    return buildUrl(websiteHost, path, params, requiresAuthentication);
}

export function buildMopUrl(path:string, params?:IUrlParams, requiresAuthentication:boolean = false): string
{
    return buildUrl(mopHost, path, params, requiresAuthentication);
}

export function buildCMSUrl(path:string, params?:IUrlParams, requiresAuthentication:boolean = false): string
{
    const finalParams = params && !isProduction? [params, 'stage=test&test=1']: params;
    return buildUrl(cmsHost, path, finalParams, requiresAuthentication);
}

export function toSignedHarUrl(url:string): string
{
    //return websiteHost +  '/login/applogin?nexturl=' + encodeURIComponent(url);
    return 'https://www.har.com/login/applogin?nexturl=' + encodeURIComponent(url);
}

function buildUrl(hostname:string, path:string, params?:IUrlParams | IUrlParams[], requiresAuthentication:boolean = false): string
{
    if(requiresAuthentication) { return toSignedHarUrl(buildUrl(hostname, path, params, false)); }

    const url = `${hostname}${path}`;
    if(!params) { return url; }
    return addQueryStringToUrl(url, params);
}