import { INotificationProps } from './Notification.def';
import './Notification.scss';
import { OnBoardingTemplate } from 'views/templates/OnBoardingTemplate';
import { PrimaryButton } from 'views/forms/buttons/Button';
//import { useOnboardingStage } from 'scripts/hooks/onboarding';
//import { eOnboardingStage } from 'scripts/enums';

export const NotificationDesktop = (_props:INotificationProps): JSX.Element =>
{
    //const onboarding = useOnboardingStage(eOnboardingStage.Complete);
    
    return (
        <OnBoardingTemplate onboarding>
        <div> <div className="col-md col-12">
                        <div className="cards p-5">
                            <div className="mt-4 mb-4">
                                <h2 className="mb-5">Notification Preferences</h2>
                                
                                <div className="font_size--large font_weight--semi_bold pb-2">Default Notifications</div>
                                <div className="color_auxiliary mb-3">Receive notifications about new leads, messages, calendar and task reminders.</div>

                                <div className="border-bottom pb-4 mb-4">

                                    <div role="checkbox" className="custom-control custom-checkbox mb-3">
                                        <input type="checkbox" id="DefaultNotifications_1" name="DefaultNotifications" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="DefaultNotifications_1">Email</label>
                                    </div>

                                    <div role="checkbox" className="custom-control custom-checkbox mb-3">
                                        <input type="checkbox" id="DefaultNotifications_2" name="DefaultNotifications" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="DefaultNotifications_2">Text</label>
                                    </div>

                                    <div role="checkbox" className="custom-control custom-checkbox mb-3">
                                        <input type="checkbox" id="DefaultNotifications_3" name="DefaultNotifications" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="DefaultNotifications_3">Push Notification</label>
                                    </div>
                                </div>

                                <div className="border-bottom pb-4 mb-4">
                                    <h2 className="mb-5">Recommended Action Items</h2>
                                    <div role="checkbox" className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" id="DefaultNotifications_3" name="DefaultNotifications" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="DefaultNotifications_3">Receive daily emails with Recommended Action Items.</label>
                                    </div>

                                    <div className="row">
                                        <div className="col-md col-12">
                                            <div className=" border_radius--pudgy bg_color_cement_light p-4 font_size--medium color_auxiliary ml-4">
                                                <div className="font_weight--semi_bold mb-3">Actionable Intelligence:</div>
                                                <ul className=" list--black mb-0">
                                                    <li>Who to stay in touch</li>
                                                    <li>Today’s appointments</li>
                                                    <li>Do not miss the anniversaries & birthdays</li>
                                                    <li>Contact updates and more...</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-auto col-12">
                                            <img className='max-w-240' src="https://content.harstatic.com/media/bgs/crm/notification_email@2x.jpg" />
                                        </div>
                                    </div>
                                </div>

                                <PrimaryButton className="btn--large">&nbsp;&nbsp;Save&nbsp;&nbsp;</PrimaryButton>
                                
                            </div>
                        </div>
                    </div></div>
                    </OnBoardingTemplate>
    );
}
