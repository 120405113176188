import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";

export namespace ListDocumentsApi {
    export const url = "/contacts/:contact_id/documents";
    export const method = "GET";
    export const defaultError = "Failed to load documents.";
    export const operations = apiOperations(ListDocumentsApi);
    export const scope = new class ListDocumentsApiScope implements IApiScope<Request, Response>
    {
        request(data: Request) { return data; }
        response(input: Record<'data', Response>) { return input.data; }
    }();

    export interface Request {
        page: string;
        renew: string;
        contact_id: string;
    }

    export interface Response {
        total: string,
        page: string,
        page_size: string,
        total_pages: string,
        next_page_url: string,
        prev_page_url: string,
        list: Array<IDocumentData>
    }
    export interface IDocumentData {
        id: string,
        name: string,
        path: string,
        extension: string,
        icon: string,
        created_dt: string,
        modified_dt: string,
    }
}

export const fetchListDocumentsService = forwardOperation(ListDocumentsApi.operations, 'fetch');
export const useFetchListDocumentsService = forwardOperation(ListDocumentsApi.operations, 'useFetch');
export const useListDocumentsService = forwardOperation(ListDocumentsApi.operations, 'useService');