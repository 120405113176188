import { useParams } from "react-router";
import { ContactPlayground } from "./ContactPlayground";
import { AboutPlayground } from "./AboutPlayground";

export function PlaygroundScreen()
{
    const { page = 'index' } = useParams<{ page:string }>();

    switch (page)
    {
        case 'index': break;
        case 'contact': return <ContactPlayground />;
        case 'about': return <AboutPlayground />;
        default: return <h1>404 Not Found</h1>;
    }

    // Index Page
    return (
        <div>
            <h1>Playground</h1>
        </div>
    );
}