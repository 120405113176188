
import { useResponsiveChoose } from 'scripts/hooks/general';
import { IEmailTagsProps } from './EmailTags.def';
import './EmailTags.scss';
import { EmailTagsDesktop } from './EmailTags.desktop';
import { EmailTagsApp } from './EmailTags.app';

export const EmailTags = (props: IEmailTagsProps): JSX.Element => {
    const EmailTagsPlatfrom = useResponsiveChoose(EmailTagsDesktop, EmailTagsApp)
    return <EmailTagsPlatfrom {...props} />

}
