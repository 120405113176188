import { IAddPopoverProps } from './AddPopover.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { AddPopoverApp } from './AddPopover.app';
import { AddPopoverDesktop } from './AddPopover.desktop';

export const AddPopover = (props: IAddPopoverProps): JSX.Element => {
    const AddPopoverPlatform = useResponsiveChoose(AddPopoverDesktop, AddPopoverApp);
    return (
        <AddPopoverPlatform {...props} />
    );
}
