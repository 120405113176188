import { IFilterPopUpProps } from './FilterPopUp.def';
import './FilterPopUp.scss';
import { DropDownSecondary } from 'views/forms/dropdowns/DropDownSecondary';

export const FilterPopUpDesktop = (props:IFilterPopUpProps): JSX.Element =>
{ 
    var {selectedValue,handleFilter,items,filterName,label}=props

    const handleSelectFilterData=(short_value:string)=>{
      const isAvailable= selectedValue.split(",").includes(short_value);
       if(!isAvailable){
            selectedValue=`${selectedValue},${short_value}`;
        }else{
            selectedValue=selectedValue.replace(`,${short_value}`,"").replace(short_value,"");
        }
      const isCharAtStart=selectedValue.substring(0, 1)===',';

        if(isCharAtStart){selectedValue=selectedValue?.substring(1);}
       handleFilter({[filterName]:selectedValue});
    } 

    const isSelected=(value:string)=>{
       return value.split(",").every((string)=> selectedValue.split(",").includes(string));
     }
    const getLabel=()=>{
        const filteredLookupData= items.filter((item)=>selectedValue.split(",").includes(item.short_value))
        const getString= filteredLookupData.map((item)=>" "+item.long_value)
        var length=""
        if(getString?.length>1){length=" +"+(getString.length-1).toString()}
        
    return isSelected(allFilterShortValues)||selectedValue===''?`Any ${label}`:`${getString[0]||""}${length} ${label}`;
    }
    
    const allFilterShortValues=items.map((item)=>item.short_value).toString();
    const handleSelectAllFilter=()=>handleFilter({[filterName]:selectedValue===allFilterShortValues?"none":allFilterShortValues})
   
    return (
       <DropDownSecondary
            customButton={<button className="filterpill mr-3" aria-label="Filter by Any Status">{getLabel()}</button>} >
                <div className="ml-4 mr-4 min-w-190px">
                <div className="choosable__withcheck" >
                        <input type="checkbox" className="custom-control-input" 
                                onChange={handleSelectAllFilter} 
                                checked={isSelected(allFilterShortValues)} defaultChecked={true} 
                                name={filterName} id={filterName+"Any"} 
                                />
                        <label className="custom-control-label border-0 mb-0" htmlFor={filterName+'Any'}>
                            <div className={`font_size--medium font_weight--bold All`}>Any {label}</div>
                            <div className="check animage animate animate__fadeIn"></div>
                        </label>
                    </div>
                {items.map((item,ind)=> 
                    <div key={ind} className="choosable__withcheck" >
                        <input type="checkbox" className="custom-control-input" 
                                onChange={()=>handleSelectFilterData(item.short_value)} 
                                name={filterName}
                                checked={isSelected(item.short_value)}   
                                id={item.short_value} 
                                />
                        <label className="custom-control-label border-0 mb-0" htmlFor={item.short_value}>
                            <div className={`font_size--medium font_weight--bold ${item.short_value}`}>{item.long_value}</div>
                            <div className="check animage animate animate__fadeIn"></div>
                        </label>
                    </div>)}
                </div>
        </DropDownSecondary>
    );
}
