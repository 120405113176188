import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { DeepPropertiesType, PropertiesType } from "types/utilities"

export namespace RemoveContactApi
{
    export const url = "/contacts/:id/pipeline";
    export const method = "DELETE";
    export const defaultError = "Failed to remove contact.";
    export const operations = apiOperations(RemoveContactApi);
    export const scope = new class RemoveContactApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): DeepPropertiesType<Request, string|number>
        {
            return {
				id:input.id
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        id:number
    }

    export interface Response
    {
        
    }
}

export const fetchRemoveContactService = forwardOperation(RemoveContactApi.operations, 'fetch');
export const useFetchRemoveContactService = forwardOperation(RemoveContactApi.operations, 'useFetch');
export const useRemoveContactService = forwardOperation(RemoveContactApi.operations, 'useService');