import { IonInput } from '@ionic/react';
import { IEmailTagsProps } from './EmailTags.def';
import './EmailTags.scss';
import { useRef } from 'react';

export const EmailTagsDesktop = (props: IEmailTagsProps): JSX.Element => {
    const { placeholder, title, list, save } = props
    const ionInputEl = useRef<HTMLIonInputElement>(null);

    const doBreak = (str: string) => {
        let token = str.split(/\s+/, 1)
        if (token[0]) {
            doSave(token[0])
            if (ionInputEl.current !== null) {
                ionInputEl.current.value = ""
            }

        }
    }

    const doSave = (token: string) => {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (token && regex.test(token.trim())) {
            if (list)
                save?.([...list, token.trim()])
        }
    }


    return (

        <div className='row no-gutters'>
            {title &&
                <label>{title}</label>
            }
            <List {...props} />
            {list && list.length < 9 &&
                <div className='col' >
                    <div>
                        <IonInput
                            type="email"
                            ref={ionInputEl}
                            placeholder={placeholder}
                            onKeyUp={(e: any) => {
                                if (e.keyCode === 32 || e.keyCode === 188) {
                                    doBreak(e.target.value + " ")
                                }
                            }}
                            onBlur={(e: any) => doBreak(e.target.value + ' ')}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

const List = (props: IEmailTagsProps) => {
    const { list, save } = props

    const remove = (email: string) => {
        let exceptThisEmailList = list?.filter((emailList) => emailList !== email)
        save?.(exceptThisEmailList)
    }
    return (

        <div className='col-auto d-flex'>
            {list && list.map((email, index: number) =>
                <div className="emailpill" key={index}>
                    <div className="agent_signature agent_signature__mini2">

                        <div className="agent_signature__text">{email}</div>
                    </div>
                    <a className="emailpill--clossbtn" onClick={() => remove(email)}><img src="https://content.harstatic.com/media/icons/close_black.svg" /></a>
                </div>
            )}
        </div>

    )
}
