import { isProduction } from "scripts/application/settings";
import { loadExpiration, signUrl } from "scripts/http/serviceFetch";
import { EmailDetailsApi } from "services/emails/emailDetails";

interface IInlineAttachmentList {
    list:{key:string,value:string}
}

const baseUrl = ():string => {
return isProduction ? 'har-crm-api.har.com' : 'har-crm-api-test.har.com'
}

const createSecureUrl = (message_id: string, content_id: string) => {
    const expiration = loadExpiration()
    let auth = signUrl('/emails/' + message_id + '/attachments/' + content_id + '/download?version=0', expiration)
    return 'https://'+baseUrl()+'/emails/' + message_id + '/attachments/' + content_id + '/download?version=0&X-Token=Fhy7Jh898ynQnnPQjkk&X-Test-Mode=1&X-Expires=' + expiration + '&X-Auth=' + auth;
}

export const checkInlineAttachments = (mail:EmailDetailsApi.Response) : IInlineAttachmentList[] => {
    const spliter = /(cid:)+\w*/g
    let dict:IInlineAttachmentList[] = []
    mail.message.match(spliter)?.map((images: any) => {
        var content = images.split("cid:")
        if (content[1] && mail.message_uid) {
            dict.push({ list :{ key: "cid:" + content[1], value: createSecureUrl(mail.message_uid, content[1]) }})
        }
    })
    return dict
}

export const replaceInlineImages = (messsage:string,dictornary:IInlineAttachmentList[]):string => {
    var updatedMessage = messsage
        dictornary.map((d: any) => {
            updatedMessage = updatedMessage.replace(d.list.key, d.list.value)
        })
    return updatedMessage
}

