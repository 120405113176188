import { ISliderProps } from './Slider.def';
import { IonSlides, IonSlide, IonImg } from '@ionic/react';
import { useResponsiveRender } from "scripts/hooks/general";
import { PropsWithChildren } from "react";
import './Slider.scss';
import { InstructionsSection } from 'views/onboarding/InstructionsSection';

export const Container = (props: PropsWithChildren<{}>) =>
(
    <>{props.children}</>
);

export const Slider = (_props: ISliderProps): JSX.Element => {
    return useResponsiveRender(SliderDesktop, SliderResponsive);
}

const slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoplay: {
        delay: 2000,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
};

export const SliderDesktop = () => {
    return (
        <div className="ml-auto mr-auto max-w-620">
            <IonSlides pager={true} options={slideOpts}>
                <IonSlide>
                    <div className="row">
                        <div className="col-md-6 text-center col-12 mb-md-0 mb-4 pb-4">
                            <div className='pt-4 pb-4 text-center mb-4 color_custom bg-color-rgba_2 min-h-280px border_radius--pudgy'>
                                <IonImg className=' max-w-280' src={"https://content.harstatic.com/media/artwork/illustration.svg"} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12 text-left align-self-center mb-4'>
                            <h2 className="mb-4 h2--simple">
                                Elevate Your Sales:<br />
                                Visualize Your Pipeline Effortlessly
                            </h2>
                            <div className="font_size--large">
                                Create tasks for yourself, oversee your appointments, and manage your emails and activities faster. Do more for your leads.
                            </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <div className="row">
                        <div className="col-md-6 text-center col-12 mb-md-0 mb-4">
                            <div className='pt-4 pb-4 text-center mb-4 bg-color-rgba_2 min-h-280px border_radius--pudgy'>
                                <IonImg className=' max-w-280' src={"https://content.harstatic.com/media/artwork/illustration_2.svg"} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12 text-left align-self-center mb-4'>
                            <h2 className="mb-4 h2--simple">
                                Give your customers<br />
                                powerful services
                            </h2>
                            <div className="font_size--large">
                                With an incredible range of available services, provide your customers all the tools they need.
                            </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <div className="row">
                        <div className="col-md-6 text-center col-12 mb-md-0 mb-4">
                            <div className='pt-4 pb-4 text-center mb-4 bg-color-rgba_2 min-h-280px border_radius--pudgy'>
                                <IonImg className=' max-w-280' src={"https://content.harstatic.com/media/artwork/illustration_3.svg"} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12 text-left align-self-center mb-4'>
                            <h2 className="mb-4 h2--simple">
                                Boost your deals,<br />
                                potentialize your sales
                            </h2>
                            <div className="font_size--large">
                                Simplify and optimize lead management for impactful results. Experience a dynamic visual pipeline for seamless follow-ups and lead nurturing.        </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <InstructionsSection showHarContacts />
                </IonSlide>
            </IonSlides>
        </div>
    );
}

export const SliderResponsive = () => {

    return (
        <div className="ml-auto mr-auto max-w-620">
            <IonSlides pager={true} options={slideOpts}>
                <IonSlide>
                    <div className="row no-gutters flex-column">
                        <div className="col mb-md-0 mb-4">
                            <div className='text-center'>
                                <IonImg className='ml-auto mr-auto max-w-280' src={"https://content.harstatic.com/media/icons/artwork/illustration_app.svg"} />
                            </div>
                        </div>
                        <div className='col text-center align-self-center mb-4 pb-4'>
                            <div className="ml-auto mr-auto max-w-332">
                                <h2 className="h2--simple text-center ">
                                    Elevate Your Sales:<br />
                                    Visualize Your Pipeline Effortlessly
                                </h2>
                                <div className="font_size--large">
                                    Create tasks for yourself, oversee your appointments, and manage your emails and activities faster. Do more for your leads.
                                </div>
                            </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <div className="row flex-column">
                        <div className="col text-center mb-md-0 mb-4">
                            <div className='text-center'>
                                <IonImg className='ml-auto mr-auto max-w-280' src={"https://content.harstatic.com/media/icons/artwork/illustration_2_app.svg"} />
                            </div>
                        </div>
                        <div className='col text-center align-self-center'>
                            <div className="ml-auto mr-auto max-w-332">
                                <h2 className="h2--simple text-center ">
                                    Give your customers<br />
                                    powerful services
                                </h2>
                                <div className="font_size--large">
                                    With an incredible range of available services, provide your customers all the tools they need.
                                </div>
                            </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <div className="row flex-column">
                        <div className="col text-center mb-md-0 mb-4">
                            <div className='text-center'>
                                <IonImg className='ml-auto mr-auto max-w-280' src={"https://content.harstatic.com/media/icons/artwork/illustration_3_app.svg"} />
                            </div>
                        </div>
                        <div className='col text-center align-self-center'>
                            <div className="ml-auto mr-auto max-w-332">
                                <h2 className="h2--simple text-center ">
                                    Boost your deals,<br />
                                    potentialize your sales
                                </h2>
                                <div className="font_size--large">
                                    Manage your leads in an easy and effective way, following up your leads through a visual pipeline.
                                </div>
                            </div>
                        </div>
                    </div>
                </IonSlide>
                <IonSlide>
                    <InstructionsSection showHarContacts />
                </IonSlide>
            </IonSlides>
        </div>
    );
}



