import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace GetMatrixImportStatusApi
{
    export const url = "/contacts/matrix/import/status/:status_id";
    export const method = "GET";
    export const defaultError = "Failed to get status.";
    export const operations = apiOperations(GetMatrixImportStatusApi);
    export const scope = new class GetMatrixImportStatusApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'matrix', PropertiesType<Response, string>>): Response
        {
            const { matrix } = input;
			return {
				...matrix
			}
        }
    }();
    
    export interface Request
    {
        status_id:string
        
    }

    export interface Response
    {   status?:string
        progress?:string,
        contacts_count?:string,
        current_count:string,
        status_id?:string,
        created_dt?:string
        modefied_dt?:string
    }
}

export const fetchGetMatrixImportStatusService = forwardOperation(GetMatrixImportStatusApi.operations, 'fetch');
export const useFetchGetMatrixImportStatusService = forwardOperation(GetMatrixImportStatusApi.operations, 'useFetch');
export const useGetMatrixImportStatusService = forwardOperation(GetMatrixImportStatusApi.operations, 'useService');