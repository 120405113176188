import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType, DeepPropertiesType } from "types/utilities"

export namespace ListingsApi {
    export const url = "/typeaheads/listings";
    export const method = "GET";
    export const defaultError = "Failed to load listings.";
    export const operations = apiOperations(ListingsApi);
    export const scope = new class ListingsApiScope implements IApiScope<Request, Response> {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input
            }
        }

        response(input: Record<'data', DeepPropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data
            }
        }
    }();

    export interface Request {
        input: string
    }

    export interface Response {
        "total": string,
        "list": Array<IListingsList>

    }
    export interface IListingsList {
        "state": string,
        "zip": string,
        "list_type": string,
        "list_price": string,
        "mlsnum": string,
        "city": string,
        "street_address": string,
        "address_2"?: string
        "harid": string,
        "full_address": string,
        "photo": string,
        "for":string,
        "listingid":string,
        "country"?:string
        
    }

    export const getInitialListingList = () => ({
        "state": "",
        "zip": "",
        "list_type": "",
        "list_price": "",
        "mlsnum": "",
        "city": "",
        "country": "",
        "street_address": "",
        "harid": "",
        "full_address": "",
        "photo": "",
        "for":"",
        "listingid":"",
        "address_2":"",

    })
}



export const fetchListingsService = forwardOperation(ListingsApi.operations, 'fetch');
export const useFetchListingsService = forwardOperation(ListingsApi.operations, 'useFetch');
export const useListingsService = forwardOperation(ListingsApi.operations, 'useService');