import { isString } from 'lodash-es';
import { useEffect, useState } from 'react';
import { IDropDownFooter, IDropDownOption, IDropDownOptionsProps } from './DropDownOptions.def';
import './DropDownOptions.scss';
import { Avatar } from 'views/contacts/Avatar';
import { joinWithSpace } from 'scripts/helpers/texts';

export const DropDownOptions = <TID extends string | number>(props: IDropDownOptionsProps<TID>): JSX.Element => {
    const { source, loading, hideId,noAvatar, itemTemplate, emptyTemplate, loadingTemplate, onSelect,className,selectedValue,footer} = props;
    const [results, setResults] = useState<IDropDownOption<TID>[]>();
    useEffect(() => {
        if (source && 'then' in source) { source.then(setResults); }
        else { setResults(source); }
    }, [source]);

    /** Normalizing footer */
    const footerProps = !!footer && 'props' in footer? { template:footer, showResults:true } as IDropDownFooter: footer;

    /** Loading State */
    const isLoading = loading || results === undefined;
    if (isLoading) { return <Loading template={loadingTemplate} />; }

    /** Empty Results */
    if (!results.length) {
        const hasEmptyMessage = isString(emptyTemplate);
        const emptyMessage = hasEmptyMessage ? emptyTemplate : 'No Result found';
        const emptyJSX = hasEmptyMessage ? undefined : emptyTemplate;

        return (
            <div className={joinWithSpace('typeahead', className)}>
                <div className="typeahead__item p-3 mb-0">
                    {emptyJSX ?? <div className="tagitem_list"><b> {emptyMessage}</b></div>}
                </div>
                {footerProps?.showEmpty && footerProps.template}
            </div>
        );
    }

    return (
        <div className="typeahead">
            {results.map((item, ind) => {
                console.log("item",item)
                const CustomItem = itemTemplate?.(item);
                if (CustomItem) { return <CustomItem key={ind} onSelect={() => onSelect?.(item)}/>; }
                return (
                    <div className="typeahead" key={ind}>
                        <div className="typeahead__item p-3 mb-0">
                            <div className='row'>
                                <div className="col">
                                    <div className="agent_signature agent_signature__large" onClick={() => onSelect?.(item)} >
                                        {!noAvatar && <Avatar id={item.title??'0'} photo={item.photo_url} firstName={item.first_name} lastName={item.last_name}></Avatar>}
                                        <div className="agent_signature__text">
                                        <div className={`max-w-60pr d-${hideId ? `none` : `block`}`}>{item.id}</div>
                                            <div className=" max-w-80pr ">{item.text} </div>
                                           
                                        </div>
                                         {!!selectedValue&&(selectedValue?.includes(item.id)&&<div><img src="https://content.harstatic.com/media/icons/checkmark-rounded-green.svg" /></div>)}
                                    </div>
                                </div>
                                {item.rightContent &&
                                    <div className='col-auto text-right'>
                                        {item.rightContent}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                );
            })}
            {footerProps?.showResults && footerProps.template}
        </div>
    )
}

function Loading(props: { template?: JSX.Element, styles?: React.CSSProperties }): JSX.Element {
    return (
        <div className="typeahead">
            <div className="typeahead__item p-3 mb-0">
                {
                    props.template ??
                    <div className="agent_signature agent_signature__large agent_signature__loading">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
