import { useOnboardingStage } from 'scripts/hooks/onboarding';
import { IGetStartedScreenProps } from './GetStartedScreen.def';
import './GetStartedScreen.scss';
import { eOnboardingStage } from 'scripts/enums';
import { Content } from './GetStartedScreen.common';

export const GetStartedScreenApp = (_props:IGetStartedScreenProps): JSX.Element =>
{
    const onboarding = useOnboardingStage(eOnboardingStage.AccountConnection);
    return <Content onboarding={onboarding} />
}
