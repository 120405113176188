import { IonInput } from '@ionic/react';
import { IEmailTagsProps } from './EmailTags.def';
import './EmailTags.scss';
import { useRef } from 'react';

export const EmailTagsApp = (props: IEmailTagsProps): JSX.Element => {
    const { placeholder, title, list, save } = props
    const ionInputEl = useRef<HTMLIonInputElement>(null);
    const doBreak = (str: string) => {
        let token = str.split(/\s+/, 1)
        if (token[0]) {
            doSave(token[0])
            if (ionInputEl.current !== null) {
                ionInputEl.current.value = ""
            }
        }
    }

    const doSave = (token: string) => {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (token && regex.test(token.trim())) {
            save?.([...list ? list : [], token.trim()])
        }
    }


    return (
        <div>
            <List {...props} />
            {list && list.length < 9 &&
                <div className=''>
                    {title &&
                        <label>{title}</label>
                    }
                    <IonInput
                        type="email"
                        ref={ionInputEl}
                        placeholder={placeholder}
                        onKeyUp={(e: any) => {
                            if (e.keyCode === 32 || e.keyCode === 188) {
                                doBreak(e.target.value + " ")
                            }
                        }}
                        onBlur={(e: any) => doBreak(e.target.value + ' ')}
                    />
                </div>
            }

        </div >
    );
}

const List = (props: IEmailTagsProps) => {
    const { list, save } = props

    const remove = (email: string) => {
        let exceptThisEmailList = list?.filter((emailList) => emailList !== email)
        save?.(exceptThisEmailList,true)
    }
    return (
        <div className="tab__nextpage_value mr-auto ml-2 d-flex overflow-scroll w-360px">
            {list && list.map((email, index: number) =>
                <div key={index} className='position-relative pr-5px pt-7px mt-5px'>
                    <div className=' position-absolute right-6px top--4px' onClick={() => remove(email)}>
                        <img src="https://content.harstatic.com/media/icons/cross_auxialiry_with_white.svg" />
                    </div>
                    <div className="addresspill display-block_ruby w-auto mb-0" aria-label="Filter by price">{email}</div>
                </div>
            )}
        </div>
    )
}
