import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IAssignScoreProps } from './AssignScore.def';
import './AssignScore.scss';
import {  PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { useState } from 'react';
import { SelectedContacts } from '../SelectedContacts';
import { ScoreOptions } from 'views/contacts/ScoreOptions';
import { useQuickUpdateService } from 'services/contacts/quickUpdate';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';


export const AssignScoreDesktop = (props: IAssignScoreProps): JSX.Element => {
    const { contacts, selectToggle, onAssignment } = props
    const [open, doOpen] = useState(false)
    const [score, setScore] = useState<string>()
    const [scoreAssignService, scoreAssignAction] = useQuickUpdateService()
    const Launcher = () => {
        return (
            <button role="button" id="contact-assign-score" className="btn btn--simple btn--simple__auxiliary btn--small mx-2" aria-label="Assign Score" onClick={() => doOpen(true)}>Score</button >
        )
    }
    const doAssign = () => {
        let ids: any = [];
        contacts?.map((c: any) => {
            ids.push(c.id)
        })
        if (score) {
            scoreAssignAction.fetch({
                contact_ids: ids,
                field_name: "score",
                field_value: score
            })
        }
    }

    useServiceDidSucceeded(scoreAssignService, () => {
        doOpen(false)
        onAssignment?.()

    })
    return (
        <>
            <Launcher />
            <DefaultPopUp title="Score" class='score_popup' isOpen={open} onWillDismiss={() => doOpen(false)} onIonModalWillDismiss={() => setScore(undefined)}>
                <DefaultPopUp.Body>
                    <div className=" px-5 py-2">
                        <SelectedContacts contacts={contacts} selectToggle={selectToggle} />
                    </div>
                    <div className="px-5 py-2">
                        <div className="font_size--medium font_weight--semi_bold color_auxiliary flex-grow-1 mb-2">Score</div>
                        <ScoreOptions initialSelected={score} onScoreChange={setScore} />
                    </div>
                </DefaultPopUp.Body>
                <DefaultPopUp.ActionBar>
                    <div className="text-center p-2">
                        <div className='row no-gutters'>
                            <div className='col-auto min-w-120px'></div>
                            <div className='col text-center'><PrimaryButton loading={scoreAssignService.loading} disabled={scoreAssignService.loading} onClick={() => doAssign()} className="btn--primary btn w-100px">Apply</PrimaryButton></div>
                            <div className='col-auto min-w-120px text-right'><ShapelessButton onClick={() => doOpen(false)}>Cancel</ShapelessButton></div>
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp>
        </>
    );
}
