import { fetchCompaniesService } from 'services/typeaheads/companies';
import { Typeahead } from '../Typeahead';
import { ICompanyTypeaheadProps } from './CompanyTypeahead.def';
import './CompanyTypeahead.scss';

export const CompanyTypeahead = (props: ICompanyTypeaheadProps): JSX.Element => {

    const { initialValues,hideId, onChange } = props;
    const search = (value: string) => {
        return fetchCompaniesService({ input: value })
    }

    const mapResult = (item: string) => {
        return item;
    }

    const getCompaniesName = (value: any) => {
        onChange?.(value);
    }

    return (
        <div>
            <Typeahead.Multi placeholder='Company' search={search} mapResult={mapResult} initialValue={initialValues} hideId={hideId} onChange={(e) => getCompaniesName(e)} />
        </div>
    );
}
