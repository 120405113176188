import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace MarkEmailReadApi {
    export const url = "/emails/mark";
    export const method = "POST";
    export const defaultError = "Emails have not been marked read/unread";
    export const operations = apiOperations(MarkEmailReadApi);
    export const scope = new class MarkEmailReadApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<Request, string> {
            return {
                ...input,
                // email_ids: input.email_ids.join(','),
                // read:input.read
            }
        }

        response(input: PropertiesType<Response, string>): Response {
            return {
                ...input
            }
        }
    }();

    export interface Request {
        //email_ids: string[],
        // read: string,
    }

    export interface Response {

    }
}

export const fetchMarkEmailReadService = forwardOperation(MarkEmailReadApi.operations, 'fetch');
export const useFetchMarkEmailReadService = forwardOperation(MarkEmailReadApi.operations, 'useFetch');
export const useMarkEmailReadService = forwardOperation(MarkEmailReadApi.operations, 'useService');