import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace NotificationSummaryApi
{
    export const url = "/notifications/summary";
    export const method = "GET";
    export const defaultError = "Failed to load data";
    export const operations = apiOperations(NotificationSummaryApi);
    export const scope = new class NotificationSummaryApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data,
                total:parseInt(data.total),
                total_unread:parseInt(data.total_unread),
                total_read:parseInt(data.total_read)
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        total:number ,
        total_unread:number,
        total_read:number
    }
}

export const fetchNotificationSummaryService = forwardOperation(NotificationSummaryApi.operations, 'fetch');
export const useFetchNotificationSummaryService = forwardOperation(NotificationSummaryApi.operations, 'useFetch');
export const useNotificationSummaryService = forwardOperation(NotificationSummaryApi.operations, 'useService');