import { IMultipleRecipientsPopoverProps } from "./EmailDetail.def";
import { Dropdown } from "react-bootstrap";
import { IonRouterLink } from "@ionic/react";
import { DropDownSecondary } from "views/forms/dropdowns/DropDownSecondary";
import { Avatar } from "views/contacts/Avatar";


export const getContactOverviewUrl = (contactId: string) => `/contacts/${contactId}/overview`;


export const MultipleRecipientsPopover = (props: IMultipleRecipientsPopoverProps) => {
    const { recipients: full } = props;
    const recipients = full.slice(1);

    return (

        <div className="align-self-center flex-grow-1">
            <DropDownSecondary customButton={<span className="pl-3 pr-3">+{recipients.length}</span>}>
                {recipients.map((item, index) =>
                    <Dropdown.Item key={index} className={`dropdown-item animate__animated animate__fadeInUp animate__faster pb-2 ${item.id && `cursor--hand`}`} as={IonRouterLink} routerLink={item.id ? getContactOverviewUrl(item.id) : undefined}>
                        <div className="agent_signature agent_signature__mini2  pb-2">
                            <Avatar id={item.id.toString()} firstName={item.first_name} lastName={item.last_name} photo={item.photo_url} />
                            <div className="agent_signature__text text-truncate b-2">
                                <div className="agent_signature__text__title text-truncate">{item.first_name || item.last_name ? item.first_name + " " + item.last_name : item.email}</div>
                                <div className="agent_signature__text__address text-truncate">{item.email}</div>
                            </div>
                        </div>
                    </Dropdown.Item>
                )}
            </DropDownSecondary>
        </div>
    );
}

