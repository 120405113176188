import { useEventConfirmation } from 'scripts/hooks/forms';
import { IButtonProps, IKindButtonProps } from './Button.def';
import './Button.scss';
import { IonButton } from '@ionic/react';

/*type OnClickHandler = React.MouseEventHandler<HTMLIonButtonElement>;
type OnClickEvent = React.MouseEvent<HTMLIonButtonElement, MouseEvent>;
type IPresentAlert = UseIonAlertResult[0];*/


export const PrimaryButton = (props: IKindButtonProps) =>
(
    <Button kind='primary' {...props} />
);

export const ProminentButton = (props: IKindButtonProps) =>
(
    <Button kind='prominent' {...props} />
);

export const OrdinaryButton = (props: IKindButtonProps) =>
(
    <Button kind='ordinary' {...props} />
);

export const ShapelessButton = (props: IKindButtonProps) =>
(
    <Button kind='shapeless' {...props} />
);

export const ShapelessRedButton = (props: IKindButtonProps) =>
(
    <Button kind='shapeless_red' {...props} />
);

export const ShapelessGreenButton = (props: IKindButtonProps) =>
(
    <Button kind='shapeless_green' {...props} />
);


export const Button = (props: IButtonProps): JSX.Element =>
{
    const addConfirm = useEventConfirmation();
    const {
        kind, size, loading, clickConfirmation,
        disabled, children, className, onClick:onUserClick,
        ...ionicProps
    } = props;

    //const onClick = clickConfirmation? createConfirmationClick(clickConfirmation, presentAlert, onUserClick): onUserClick;
    const onClick = addConfirm(clickConfirmation, onUserClick);

    return (
        <IonButton
            {...ionicProps}
            role="button"
            className={`btn btn--${kind}
                btn--${size ?? 'medium'} ${className}`}
            disabled={disabled || loading}
            fill='clear'
            onClick={onClick}
        >
            {loading && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;</>}
            {children}
        </IonButton>
    );
}

/*const createConfirmationClick = (message:string, presentAlert:IPresentAlert, onClick?:OnClickHandler | undefined) =>
{
    if(!onClick) { return undefined; }
    return (e:OnClickEvent) =>
    {
        presentAlert({
            header: message,
            buttons: [
                { text: 'Yes', role: 'confirm', handler: () => onClick(e) },
                { text: 'No', role: 'cancel' }
            ],
        })
    };
};*/