import { ICalendarTemplateProps } from './CalendarTemplate.def';
import './CalendarTemplate.scss';
import {RefresherEventDetail ,IonRefresher,IonRefresherContent} from '@ionic/react';
import moment from 'moment';

export const CalendarTemplateApp = (props:ICalendarTemplateProps): JSX.Element =>
{   
    
    const addMonth=async ()=>{
        props.setMonth(moment(props.month).add(1, 'month').toDate())
      }
      const subMonth=async()=>{
        props.setMonth(moment(props.month).subtract(1, 'month').toDate())
      }
      function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
           props.onSwipeRefresh?.()
          event.detail.complete();
        }, 1000);
      }
    return (
       <>  {props.onSwipeRefresh && 
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
     }<div>
           <div className="col-lg col-12 order-lg-1 order-0 text-center pb-3 pb-lg-0">
                            <div className="ml-auto mr-auto d-inline-block pt-4 pb-3">
                                <div className="row ml-auto mr-auto small-gutters align-items-center">
                                <div className="col-auto align-self-center pr-1 cursor--hand pt-2">
                                    <div onClick={subMonth} className="d-inline-flex color_carbon "><img className="d-inline-flex" src="https://content.harstatic.com/media/icons/slider_arrow_darkblue_left.svg" /> <span>Previous</span></div>
                                </div>
                                <div className="col-auto align-self-center calendar_title font_size--large_extra_extra text-center">
                                    <span className="font_weight--bold">{moment(props.month).format("MMMM")},</span> {moment(props.month).format("YYYY")}
                                </div>
                                <div className="col-auto align-self-center cursor--hand">
                                <div onClick={addMonth} className="d-inline-flex color_carbon"><span className="align-self-center">Next</span> <img src="https://content.harstatic.com/media/icons/slider_arrow_darkblue_right.svg" /> </div>
                                </div>
                                </div>
                            </div>
                        </div>
           {props.children}
           </div>
             </>);
}
