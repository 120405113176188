import { RouteComponentProps } from "react-router";
import { buildRecordValueError } from "scripts/helpers/forms";
import { Auth } from "scripts/security/AuthService";
import { RecordValueError } from "types/form";

export interface ILoginScreenProps extends RouteComponentProps
{

}

export interface ILoginScreenFormProps
{
    headers:Record<string, string | number>;
    nextUrl?:string | null;
}


export const initialLoginForm = { username:'', password:'', rememberMe:!Auth.loginProvider.showRememberMe };
export const initialLoginFormState = () => buildRecordValueError(initialLoginForm);

export type ILoginForm = typeof initialLoginForm;

export type ILoginFormState = RecordValueError<ILoginForm>;