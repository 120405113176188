import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"
import { buildFullPhone } from "../createContact";

export namespace AddPhoneApi {
    export const url = "/contacts/:contact_id/phones";
    export const method = "POST";
    export const defaultError = "Failed to save phone.";
    export const operations = apiOperations(AddPhoneApi);
    export const scope = new class AddPhoneApiScope implements IApiScope<Request, Response>
    {
        request(input: Request): PropertiesType<ServerRequest, string> {
            const phone = this._buildPhone(input);
            return {
                ...input,
                phone
            }
        }

        response(input: Record<'data', PropertiesType<Response, string>>): Response {
            const { data } = input;
            return {
                ...data,
                id: parseInt(data.id),
                phone_type: data.phone_type,
                preferred: data.preferred === '1'
            }
        }

        _buildPhone(request:Request) {
            const { phone, phone_extension, phone_type } = request;
            delete request.phone_extension;
            return buildFullPhone(phone, phone_extension, phone_type);
        }
    }();

    export interface BaseRequest {
        contact_id: string,
        phone: string,
        preferred: string,
        phone_type: string,
    }

    export type ServerRequest = BaseRequest;

    export interface Request extends BaseRequest
    {
        phone_extension?:number
    }

    export interface Response {
        id: number,
        phone_type: string,
        preferred: boolean,
    }
}

export const fetchAddPhoneService = forwardOperation(AddPhoneApi.operations, 'fetch');
export const useFetchAddPhoneService = forwardOperation(AddPhoneApi.operations, 'useFetch');
export const useAddPhoneService = forwardOperation(AddPhoneApi.operations, 'useService');