import { IDropDownModalProps} from './DropDownModalMulti.def';
import './DropDownModalMulti.def';
import {  useState,useEffect } from 'react';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { PrimaryButton,ShapelessButton } from 'views/forms/buttons/Button';
import { getLongvalue } from 'services/general/lookups';
import { joinWithSpace } from 'scripts/helpers/texts';

export function DropDownModal(props: IDropDownModalProps): JSX.Element
{
    const [showModal,setShowModal]=useState(false)
    const {label,customClassName,optionsValues,values,handleSave,placeHolder}=props
    const [items,setItems]=useState<Array<string>>(values)
    const handleClose=()=>{
     setShowModal(false); 
    }
    useEffect(()=>{setItems(values)},[values])
    
    const isSelected=(value:string):boolean=>{return items.includes(value)}

    const onSelect=(value:string)=>{
      isSelected(value)?  setItems(items.filter((item)=>item!=value)): setItems([...items,value])
    }
    const onRemove=(value:string)=>{
      handleSave?.(items.filter((item)=>item!=value));
    }
    const className = joinWithSpace(`tags-input-container custom-select custom-select_large`,customClassName);
    const filteredValues=values.filter((item)=>item!='')
     return (<>
            <div>
              <div className={className} onClick={()=>setShowModal(true)}>
                  {values&&filteredValues.length >0? filteredValues.map((item) => (
                      <div className="tag-item" key={item} onClick={e => e.stopPropagation()}>
                          <span className="text">{getLongvalue({items:optionsValues?[...optionsValues]:[],caption:""},item)}</span>
                          <span className="close" onClick={() => onRemove(item)}>&times;</span>
                      </div>)):
                            <>{placeHolder}</>}
              </div>
              
          </div>
        <div>  
        <DefaultPopUp title={label} isOpen={showModal} onWillDismiss={handleClose} >
                    <DefaultPopUp.Body> <div className="ion-padding bg-white">
            <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">
                    <div className='row'>
                    {optionsValues?.map((item,ind)=> 
                        <div className='col-6'>
                          <div key={ind} role="checkbox" className={`custom-control custom-checkbox mb-2`}>
                              <input type="checkbox" id={`checkbox_button_tag_` + ind} name="checkbox_button_medium" checked={isSelected(item.short_value)} className="custom-control-input" onChange={() => onSelect(item.short_value)} />
                              <label className="custom-control-label" htmlFor={`checkbox_button_tag_` + ind}>{item.long_value}</label>
                          </div>
                        </div>
                    )}
                    </div> </div> </div>
                    </DefaultPopUp.Body>
                    <DefaultPopUp.ActionBar>
                        <div className="p-2">
                            <div className='row no-gutters'>
                                <div className='col-auto min-w-120px'></div>
                                <div className='col text-center'><PrimaryButton   onClick={() => {handleSave?.(items); handleClose(); }} className="btn--primary btn w-100px">Save</PrimaryButton></div>
                                <div className='col-auto min-w-120px text-right'>
                                    <ShapelessButton onClick={handleClose} >Cancel</ShapelessButton>
                                </div>
                            </div>
                        </div>
                    </DefaultPopUp.ActionBar>   
                </DefaultPopUp>
        </div>
        </>
      );
}