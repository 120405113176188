import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace UpdateStageApi
{
    export const url = "/contacts/update_pipeline";
    export const method = "POST";
    export const defaultError = "Failed to update stage.";
    export const operations = apiOperations(UpdateStageApi);
    export const scope = new class UpdateStageApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				stages:input.stages
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				...data
			}
        }
    }();
    
    export interface Request
    {
        stages:any
    }

    export interface Response
    {
        
    }
}

export const fetchUpdateStageService = forwardOperation(UpdateStageApi.operations, 'fetch');
export const useFetchUpdateStageService = forwardOperation(UpdateStageApi.operations, 'useFetch');
export const useUpdateStageService = forwardOperation(UpdateStageApi.operations, 'useService');