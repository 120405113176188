import { DefaultPopUp, } from "views/popups/DefaultPopUp";
import { IonRadioGroup,IonItem,IonRadio, IonToolbar,IonButtons,IonHeader,IonButton ,IonTitle} from '@ionic/react';
import { IFormDataProps} from '../AddTaskPopUp.def'
import { Button } from "views/forms/buttons/Button";
import { times } from "lodash-es";
import { AlertDialog } from 'views/dialogs/AlertDialog';
import { options,useCustomRepeatOptionsService } from "services/calendar/customRepeatOptions";
import  { useState,useEffect } from "react";
import { useResponsiveChoose } from "scripts/hooks/general";
import { DefaultPopUpApp } from "views/popups/DefaultPopUp/DefaultPopUp.app";
//import { IDateTimeTextboxProps } from 'views/forms/textboxes/DateTimeTextbox/DateTimeTextbox.def';
import {  parseISO,format } from "date-fns";
import { MuiDatePicker } from "views/forms/material/MuiDatePicker";
import { useFetchLookupsService } from "services/general/lookups";
import { NativeDateTimePicker } from "views/general/NativeDateTimePicker";
import { dateFormat } from "scripts/helpers/number";
import { formatSystemDate } from "scripts/helpers/dates";
//import { displayMessage } from "scripts/hooks/feedbacks";


interface ICustomRecurrenceProps{
    show:boolean;
    toggle:(value:boolean)=>void
    setFormData:(item:IFormDataProps)=>void
    formData:IFormDataProps
}
interface ICustomRecurrenceChildProps{
    show:boolean;
    toggle:(value:boolean)=>void
    handleRepeatEnd:(e:string)=>void;
    handleSelectDay:(day:string)=>void;
    handleSetRepeatValue:(value:string)=>void;
    checkEnd:()=>string;
    handleSave:()=>void;
    monthOptions?:options[];
    checkIfDayExist:(day:string)=>boolean;
    setFormData:(item:IFormDataProps)=>void
    formData:IFormDataProps;
    alertMessage:string;
    customRepeatForm:ICustomRepeatFormProps,
    setCustomRepeatForm:(form:ICustomRepeatFormProps)=>void
}
interface ICustomRepeatFormProps{
    repeat_on_day: string,
    repeat_every_value:string,
    repeat_every_text:string,
    repeat_month_option:string,
    repeat_end_on: string
}



export function RepeatCustomRecurrence(props:ICustomRecurrenceProps) {
    const {setFormData,formData}=props;
    const [alertMessage,setAlertMessage]=useState("");

    const [customRepeatForm,setCustomRepeatForm]=useState({
        repeat_on_day: formData.repeat_on_day,
        repeat_every_value:formData.repeat_every_value,
        repeat_every_text:formData.repeat_every_text,
        repeat_month_option:formData.repeat_month_option,
        repeat_end_on: formData.repeat_end_on
    });
    const [options,actionOptions]=useCustomRepeatOptionsService({date:parseISO(formData.start_dt.value)})
  
  
    useEffect(()=>{
        formData.start_dt.value&&  actionOptions.fetch({date:parseISO(formData.start_dt.value)})
    },[formData.start_dt.value,customRepeatForm.repeat_every_text]);

    useEffect(()=>{  
        if(customRepeatForm.repeat_every_text==="MONTHLY"&&formData.start_dt.value===""){
            setFormData({...formData,repeat:""});
            setAlertMessage('Please choose task date.'); 
            
        }
    }, [customRepeatForm.repeat_every_text])
    
    const monthOptions=options.response?.month
 
    const handleSetRepeatValue=(value:string)=>{
        setCustomRepeatForm({...customRepeatForm,repeat_every_value:value})
    }
     const handleSelectDay=(day:string)=>{
     checkIfDayExist(day)? setCustomRepeatForm({...customRepeatForm,repeat_on_day:customRepeatForm.repeat_on_day.replace(","+day,"")}):setCustomRepeatForm({...customRepeatForm,repeat_on_day:customRepeatForm.repeat_on_day+","+day})
    }
    const checkIfDayExist=(day:string)=>{
      return  customRepeatForm.repeat_on_day.split(",").includes(day);
    }
    const handleSave=()=>{
        setFormData({...formData,...customRepeatForm});
        setAlertMessage("Saved.");
        
    }
    const handleRepeatEnd=(e:string)=>{
       
       if(e==="Never"){
        setCustomRepeatForm({...customRepeatForm,repeat_end_on:""})
        } else if (e === "After") {
            setCustomRepeatForm({ ...customRepeatForm, repeat_end_on: "1" })
        } else if (e === "On") {
            setCustomRepeatForm({ ...customRepeatForm, repeat_end_on:formatSystemDate(new Date())  })
}
    }
    const checkEnd=()=>{
        if(customRepeatForm.repeat_end_on===''){return 'Never'}
        else if(customRepeatForm.repeat_end_on.length>5){return 'On'}
        else if(customRepeatForm.repeat_end_on.length<5){return 'After'}
        return 'Never'
    }
    useEffect(()=>{
        if(props.show && (!monthOptions||monthOptions?.length==0)){ 
            props.toggle(false);            

            setAlertMessage("Please Select Task Date"); 
         setFormData({...formData,repeat:""});
    }
    },[props.show])
const CustomRecurrence=useResponsiveChoose(RepeatCustomRecurrenceDesktop,RepeatCustomRecurrenceApp)
  return <><CustomRecurrence setCustomRepeatForm={setCustomRepeatForm} customRepeatForm={customRepeatForm} handleRepeatEnd={handleRepeatEnd} checkIfDayExist={checkIfDayExist} checkEnd={checkEnd} handleSave={handleSave} handleSelectDay={handleSelectDay} handleSetRepeatValue={handleSetRepeatValue} monthOptions={monthOptions} alertMessage={alertMessage} {...props}/>
        <AlertDialog setSuccessMsg={setAlertMessage} message={alertMessage} refresh={()=>props.toggle(false)}/>
    </>
}


function RepeatCustomRecurrenceDesktop(props:ICustomRecurrenceChildProps){
    const lookups=useFetchLookupsService(["task_repeat"]);
    const taskRepeat=lookups[0].response?.task_repeat.items||[]

    const {formData,setFormData,show,toggle,checkEnd,handleRepeatEnd,handleSave,handleSelectDay,handleSetRepeatValue,monthOptions,checkIfDayExist}=props;
    const {customRepeatForm,setCustomRepeatForm}=props;

    return (  <DefaultPopUp title={"Custom Recurrence"} 
                    className="custom--modal custom--modal__fullheight" 
                    isOpen={show} closeButton toggleShow={()=>toggle(false)}>            
            <DefaultPopUp.Body>
                <div className="ion-padding bg-white">
                    <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-4">

                     <div className="row mb-4">
                         <div className="col-4 align-self-center font_weight--semi_bold font_size--large">Repeat</div>
                             <div className="col-8">
                                        <select value={formData.repeat} onChange={(e)=>{
                                            if(e.target.value==='Custom'){
                                                setCustomRepeatForm(formData);
                                                setFormData({...formData,repeat:"Custom"})}
                                            else{
                                                setFormData({...formData,repeat:e.target.value});}}}
                                                className="custom-select custom-select_large">
                                            <option value="">Do not repeat</option>
                                           {taskRepeat.map((item,ind)=> <option key={ind} value={item.short_value}>{item.long_value}</option>)}
                                        </select>
                                    </div>
                             </div>   
                             {formData.repeat==='Custom'&&   <>             
                        <div className="row mb-4">
                            <div className="col-4 align-self-center font_weight--semi_bold font_size--large">Repeat Every</div>
                            <div className="col-3">
                                   <select className="custom-select custom-select_large" placeholder="1" value={customRepeatForm.repeat_every_value}  onChange={(e)=>handleSetRepeatValue(e.target.value??"")}>
                                    {times(999, (i) => (<option key={i+1} value={i+1}>{i+1}</option>))}
                                </select>
                        </div>
                            <div className="col-5">
                                <select value={customRepeatForm.repeat_every_text} className="custom-select custom-select_large" onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_every_text:e.target.value??""})}>
                                    <option value="DAILY">day</option>
                                    <option value="WEEKLY">week</option>
                                    <option value="MONTHLY">month</option>
                                    <option value="YEARLY">Year</option>
                                </select>
                            </div>
                        </div>



                           {customRepeatForm.repeat_every_text==="MONTHLY"&&  
                           <div className="row">
                            <div className="col-4 align-self-center font_weight--semi_bold font_size--large max-w-140"></div>
                            <div className="col-8 mb-4"><select value={customRepeatForm.repeat_month_option}  className="custom-select custom-select_large" onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_month_option:e.target.value??""})}>
                         {monthOptions?.map((item,ind)=><option key={ind} value={item.short_value}>{item.long_value}</option>)}
                                </select></div></div>}         
                            {customRepeatForm.repeat_every_text==="WEEKLY"&&  <><div className="font_weight--semi_bold font_size--large pb-4">Repeat on</div>

                                    <div className="d-flex mb-5">	            	
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("SU")} checked={checkIfDayExist("SU")}
                                                    type="checkbox" className="custom-control-input" id="checkbox__day__1" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__1">
                                                <span className="day_selector">S</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("MO")} checked={checkIfDayExist("MO")} type="checkbox" className="custom-control-input" id="checkbox__day__2" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__2">
                                                <span className="day_selector">M</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("TU")} checked={checkIfDayExist("TU")} type="checkbox" className="custom-control-input" id="checkbox__day__3" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__3">
                                                <span className="day_selector">T</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("WE")} checked={checkIfDayExist("WE")} type="checkbox" className="custom-control-input" id="checkbox__day__4" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__4">
                                                <span className="day_selector">W</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("TH")} checked={checkIfDayExist("TH")} type="checkbox" className="custom-control-input" id="checkbox__day__5" /> 
                                            <label className="custom-control-label" htmlFor="checkbox__day__5">
                                                <span className="day_selector">T</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("FR")} checked={checkIfDayExist("FR")} type="checkbox" className="custom-control-input" id="checkbox__day__6" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__6">
                                                <span className="day_selector">F</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("SA")} checked={checkIfDayExist("SA")} type="checkbox" className="custom-control-input" id="checkbox__day__7" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__7">
                                            <span className="day_selector">S</span>
                                            </label>
                                        </div>
                                        
                                    </div></>}

                                    <div className="font_weight--semi_bold font_size--large pb-2">Ends</div>
                                    <div className="row mb-4" >
                                        <div className="col-sm-5 col-6">
                                             <IonRadioGroup className="radio_group" value={checkEnd()} onIonChange={(e)=>handleRepeatEnd(e.target.value)}>
                                              <IonItem className="ion-no-border">
                                                    <IonRadio id="Never" className="mr-3" value="Never" color={"tertiary"} ></IonRadio>
                                                    <label className="pt-2" htmlFor="Never" slot="">Never</label>
                                                </IonItem>

                                                
                                            <IonItem className="border-0">
                                                <IonRadio id="On" className="mr-3" value="On"  color={"tertiary"}></IonRadio>
                                                <label htmlFor="On" className="pt-2" slot="">On</label>
                                            </IonItem>

                                            <IonItem className="border-0 mt-3">
                                                <IonRadio id="After" className="mr-3 " value="After"  color={"tertiary"}></IonRadio>
                                                <label htmlFor="After" className="pt-2" slot="">After</label>
                                            </IonItem>
                                                
                                        </IonRadioGroup>
                                        </div> 
                             

                        <div className="col-sm-7 mt-40px">

                            {checkEnd() == "On"&&<MuiDatePicker
                                onChange={(date: any) => setCustomRepeatForm({ ...customRepeatForm, repeat_end_on: format(date, 'yyyy-MM-dd') })}
                                className="mb-4 mt-3"
                                value={customRepeatForm?.repeat_end_on?parseISO(customRepeatForm.repeat_end_on):undefined}
                                disabled={checkEnd() == "On" ? false : true}
                            />}
                                           
                               {checkEnd()=="After"&& <select disabled={checkEnd()=="After"?false:true}  className="custom-select custom-select_large p-1" placeholder="1" value={customRepeatForm.repeat_end_on}  onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_end_on:e.target.value})}>
                                {times(1000, (i) => (<option key={i} value={i}>{i} occurrences</option>))}
                            </select>}
                        </div> 
                    </div></>}
                </div>
              </div>
                        </DefaultPopUp.Body>
                    <DefaultPopUp.ActionBar>
                <div className="ml-4 mr-4">
                    <div className="row pt-2 mb-2">
                        <div className="col-auto ml-auto">
                            <Button onClick={handleSave} kind="primary" className="btn--medium">Done</Button>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>
    </DefaultPopUp>);
}

function RepeatCustomRecurrenceApp(props:ICustomRecurrenceChildProps){
    const {show,toggle,checkEnd,handleRepeatEnd,handleSave,handleSelectDay,handleSetRepeatValue,monthOptions,checkIfDayExist}=props;
    const {setCustomRepeatForm,customRepeatForm}=props;
   
    return (  <DefaultPopUpApp onDidDismiss={()=>toggle(false)}
                    className="custom--modal custom--modal__fullheight" 
                    isOpen={show} toggleShow={()=>toggle(false)} 
                    hideHeader
                    customHeader={<IonHeader>
                        <IonToolbar>
                            <IonButtons className="w-15pr" slot="start">
                                <IonButton color={"blue"} onClick={()=>toggle(false)}>Cancel</IonButton>
                            </IonButtons>
                            <IonTitle>Custom Recurrence</IonTitle>
                            <IonButtons className="w-15pr" slot="end">
                                <IonButton color={"blue"} strong={true} onClick={handleSave} >Save</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>}>            
            <DefaultPopUp.Body>
                <div className="ion-padding bg-white">
                    <div className="pb-5 pt-3">
                        <div className="pb-4">
                            <div className="row pb-3">
                                    <div className="col-12 align-self-center font_weight--semi_bold font_size--large pb-3">Repeat Every</div>
                                    <div className="col-5">
                                        <select className="custom-select custom-select_large" placeholder="1" value={customRepeatForm.repeat_every_value} onChange={(e)=>handleSetRepeatValue(e.target.value??"")}>
                                            {times(999, (i) => (<option key={i+1} value={i+1}>{i+1}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-7">
                                        <select value={customRepeatForm.repeat_every_text} className="custom-select custom-select_large" onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_every_text:e.target.value??""})}>
                                        
                                            <option value="DAILY">day</option>
                                            <option value="WEEKLY">week</option>
                                            <option value="MONTHLY">month</option>
                                            <option value="YEARLY">year</option>
                                        </select>
                                    </div>
                                </div>
                            {customRepeatForm.repeat_every_text==="MONTHLY"&&
                            <div className="row">
                            <div className="col-12 align-self-center font_weight--semi_bold font_size--large"></div>
                                <div className="col-12 mb-4"><select value={customRepeatForm.repeat_month_option}  className="custom-select custom-select_large" onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_month_option:e.target.value??""})}>
                            {monthOptions?.map((item,ind)=><option key={ind} value={item.short_value}>{item.long_value}</option>)}
                                    </select></div></div>}
                        </div>    

                                {customRepeatForm.repeat_every_text==="WEEKLY"&&  <><div className="font_weight--semi_bold font_size--large pb-4">Repeat on</div>

                                    <div className="d-flex mb-5">	            	
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("SU")} checked={checkIfDayExist("SU")}
                                                    type="checkbox" className="custom-control-input" id="checkbox__day__1" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__1">
                                                <span className="day_selector">S</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("MO")} checked={checkIfDayExist("MO")} type="checkbox" className="custom-control-input" id="checkbox__day__2" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__2">
                                                <span className="day_selector">M</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("TU")} checked={checkIfDayExist("TU")} type="checkbox" className="custom-control-input" id="checkbox__day__3" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__3">
                                                <span className="day_selector">T</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("WE")} checked={checkIfDayExist("WE")} type="checkbox" className="custom-control-input" id="checkbox__day__4" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__4">
                                                <span className="day_selector">W</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("TH")} checked={checkIfDayExist("TH")} type="checkbox" className="custom-control-input" id="checkbox__day__5" /> 
                                            <label className="custom-control-label" htmlFor="checkbox__day__5">
                                                <span className="day_selector">T</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("FR")} checked={checkIfDayExist("FR")} type="checkbox" className="custom-control-input" id="checkbox__day__6" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__6">
                                                <span className="day_selector">F</span>
                                            </label>
                                        </div>
                                        
                                        <div className="choosable_checkbox choosable_checkbox__day">
                                            <input onChange={()=>handleSelectDay("SA")} checked={checkIfDayExist("SA")} type="checkbox" className="custom-control-input" id="checkbox__day__7" />
                                            <label className="custom-control-label" htmlFor="checkbox__day__7">
                                            <span className="day_selector">S</span>
                                            </label>
                                        </div>
                                        
                                    </div></>}

                                    <div className="font_weight--semi_bold font_size--large pb-2">Ends</div>
                                    <IonRadioGroup className="radio_group" value={checkEnd()} onIonChange={(e)=>handleRepeatEnd(e.target.value)}>
                                        <div className="row">
                                            <div className="col-4 d-flex">                                                        
                                                <IonItem className="ion-no-border pb-0 pl-0 ml-0">
                                                    <IonRadio id="Never" className="mr-3" value="Never" color={"tertiary"} ></IonRadio>
                                                    <label className="pt-2" htmlFor="Never" slot="">Never</label>
                                                </IonItem></div>
                                          
                                                <div className="col-4"> 
                                                <IonItem className="border-0">
                                                    <IonRadio id="On" className="mr-3" value="On"  color={"tertiary"}></IonRadio>
                                                    <label htmlFor="On" className="pt-2" slot="">On</label>
                                                </IonItem></div>

                                                <div className="col-4"> 
                                                <IonItem className="border-0">
                                                    <IonRadio id="After" className="mr-3" value="After"  color={"tertiary"}></IonRadio>
                                                    <label htmlFor="After" className="pt-2" slot="">After</label>
                                                </IonItem>
                                            </div> </div>
                                           
                                        
                                         {checkEnd()=="On"&& <div className="mt-3 w-300px">
                                         <NativeDateTimePicker
                                                name="End ON"
                                                label=""
                                                value={customRepeatForm.repeat_end_on}
                                                onChange={(e: any) => {
                                                    setCustomRepeatForm({ ...customRepeatForm, repeat_end_on:dateFormat(e)}) 
                                                }}
                                            />
                                       {/**   <DateTimeTextbox 
                                            onValueChange={(date: IDateTimeTextboxProps.ValueChangeArgs) => setCustomRepeatForm({ ...customRepeatForm, repeat_end_on: date.isoValue??""})//format(date, 'YYYY-MM-DD') ?? "" })
                                        }
                                            label={""} placeholder="MM/DD/YYYY" 
                                            value={ customRepeatForm.repeat_end_on+"T00:00:00+05:00"}
                                            id="Date" name="end_dt" 
                                            type="date"
                                          //  minValue={!!formData.start_dt.value?moment(formData.start_dt.value).format("YYYY-MM-DDTHH:mm:ss"):moment().format("YYYY-MM-DDTHH:mm:ss")}
                            
                                            />*/}
                                            </div>}
                                                    
                                          {checkEnd()=="After"&&<div className="mt-3">
                                                  <select className="custom-select custom-select_large w-300px" placeholder="1" value={customRepeatForm.repeat_end_on} onChange={(e)=>setCustomRepeatForm({...customRepeatForm,repeat_end_on:e.target.value})}>
                                                    {times(1000, (i) => (<option key={i} value={i}>{i} occurrences</option>))}
                                                </select>
                                            </div>}
                                        
                                        </IonRadioGroup>
                                </div>
                            </div> 
                        </DefaultPopUp.Body>
                 
    </DefaultPopUpApp>);
}