import { StateDispatch } from "types/react";
import { IRelationItem, IRelationItemErrors } from "./RelationshipSection.def";
import { GetContactApi } from "services/contacts/contactDetails/getContact";
import { SearchContactsApi } from "services/contacts/searchContacts";

export function validateAll(_items:IRelationItem[], _setErrors:StateDispatch<IRelationItemErrors[]>)
{
  return true;
  // if(items.length===0) { return true; }

  // const errors = items.map((item): IRelationItemErrors | undefined => {
  //   if(item.contact) { return undefined; }
  //   return { contact:'Contact is required.' };
  // });

  // const isValid = errors.every(error => !error);
  // if(isValid) { return true; }

  // setErrors(prev => prev.map((item, index) => {
  //   Object.keys(item).forEach(key => item[key as keyof typeof item] = errors[index]?.[key as keyof typeof item] ?? '');
  //   return item;
  // }));

  // return isValid;
}

export function extractPhone(input:SearchContactsApi.IContactSearchItem): GetContactApi.IRelationshipContactPhone
{
  return {
    id: parseInt(input.primary_phone_id),
    number: parseInt(input.primary_phone),
    extension: input.primary_phone_extension? parseInt(input.primary_phone_extension): undefined,
    country_code: input.primary_phone_country_code? parseInt(input.primary_phone_country_code): undefined
  };
}