import { IDefaultPopUpProps } from './DefaultPopUp.def';
import { useComponent } from "scripts/context";
import { DefaultPopUpApp } from './DefaultPopUp.app';
import { DefaultPopUpDesktop } from './DefaultPopUp.desktop';
import { PropsWithChildren } from 'react';

export function DefaultPopUp(props: IDefaultPopUpProps): JSX.Element {
    const DefaultPopUpPlatform = useComponent(DefaultPopUpDesktop, DefaultPopUpApp);
    return (
        <DefaultPopUpPlatform {...props} />
    );
}

export namespace DefaultPopUp {
    export function Body(props: PropsWithChildren<{}>) { return <>{props.children}</>; }
    export function ActionBar(props: PropsWithChildren<{}>) { return <>{props.children}</>; }
}
