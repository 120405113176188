import { IOnBoardingTemplateProps } from './OnBoardingTemplate.def';
import { OnBoardingTemplateDesktop } from './OnBoardingTemplate.desktop';
import { OnBoardingTemplateApp } from './OnBoardingTemplate.app';
import { useResponsiveChoose } from 'scripts/hooks/general';

export const OnBoardingTemplate = (props:IOnBoardingTemplateProps): JSX.Element =>
{
   const OnboardingTemplatePlatform = useResponsiveChoose(OnBoardingTemplateDesktop, OnBoardingTemplateApp);
    return (   <OnboardingTemplatePlatform {...props} />
    );
}