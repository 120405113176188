import { useState } from 'react';
import { IAddTaskPopUpProps } from './AddTaskPopUp.def';
import './AddTaskPopUp.scss';
import { TaskForm } from './components/TaskForm';
import { ChooseTaskModal } from './components/ChooseTaskModal/ChooseTaskModal';

export const AddTaskPopUpDesktop = (props:IAddTaskPopUpProps): JSX.Element =>
{       const [showTask,toggleTask]=useState(false);
        const [category,setCategory]=useState("t");

    return (
         <div>
        <ChooseTaskModal {...props} setCategory={setCategory} toggleTask={toggleTask}/>
       {showTask&&<TaskForm refreshData={()=>props.refreshData?.(true)} show={showTask} toggle={toggleTask} category={category} />}
    </div>
    );
}
