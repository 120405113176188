import { clone, isEqual } from "lodash-es";
import { useEffect, useRef } from "react";
import { IApi } from "types/apis";
import { IFetchHookData, IHookActions, useService } from "./useService";


export type IUseFetch<TRequest, TResponse, TResponseError = undefined> =
    {} extends TRequest?
        (parameters?:TRequest) => IFetchHookResponse<TRequest, TResponse, TResponseError>:
        (parameters:TRequest) => IFetchHookResponse<TRequest, TResponse, TResponseError>;


export type IFetchHookResponse<TRequest, TResponse, TResponseError = undefined> =
[
    IFetchHookData<TResponse, TResponseError>,
    IHookActions<TRequest, TRequest>
];


export const useFetch = <TRequest, TResponse, TResponseError>(api:IApi<any,TRequest,any,TResponse,any,TResponseError>, parameters:TRequest) =>
{
    const [data, actions] = useService(api, parameters ?? {});
    const currentParams = useRef(parameters ?? {});

    if(!isEqual(parameters ?? {}, currentParams.current)) { currentParams.current = parameters ?? {}; }

    const request = () =>
    {
        const newParameters = parameters? clone(parameters): {} as TRequest;
        actions.fetch(newParameters);
        return () => actions.abort();
    };

    useEffect(request, [currentParams.current]);

    if(!data.loading && !data.error && !data.response) { (data.loading as any) = true; }
    return [data, actions] as IFetchHookResponse<TRequest, TResponse, TResponseError>;
}