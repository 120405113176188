import { lazy } from "scripts/utilities/decorators";
import { format } from 'date-fns';

class TestConsole
{
    @lazy()
    private get node(): HTMLDivElement
    {
        const div = document.createElement('div');
        div.className = 'bg_color_pending_light p-3 vh-100 vw-100 position-fixed';
        document.body.appendChild(div);
        return div;
    }

    public log(message:unknown)
    {
        this.addMessage(message, 'log');
    }

    public warning(message:unknown)
    {
        this.addMessage(message, 'warning');
    }

    public error(message:unknown)
    {
        this.addMessage(message, 'error');
    }

    private addMessage(message:unknown, className:string)
    {
        const div = document.createElement('div');
        div.className = `output-${className}`;
        div.innerHTML = `<span class="color_auxiliary_light">${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}&gt;</span> ${message?.toString() ?? ''}`;
        this.node.appendChild(div);
    }
}

export const output = new TestConsole();