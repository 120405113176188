import { IExternalLoginScreenProps } from './ExternalLoginScreen.def';
import { useComponent } from "scripts/context";
import { ExternalLoginCallbackScreenApp, ExternalLoginScreenApp, ExternalLogoutScreenApp } from './ExternalLoginScreen.app';
import { ExternalLoginCallbackScreenDesktop, ExternalLoginScreenDesktop, ExternalLogoutScreenDesktop } from './ExternalLoginScreen.desktop';
import { RouteComponentProps, useHistory } from 'react-router';
import { Auth } from 'scripts/security/AuthService';
import { useEffect, useState } from 'react';
import { handleLogin, handleLogout } from 'scripts/security/LoginService';
import { getNextUrl } from './ExternalLoginScreen.common';

export const ExternalLoginScreen = (props: IExternalLoginScreenProps): JSX.Element =>
{
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const subscription = Auth.isAuthenticated.then(logged => {
            const nextUrl = getNextUrl();
            if(logged) { return history.push(nextUrl ?? '/onboarding'); }
            setLoaded(true);
        });

        return () => subscription.abort();
    }, []);

    const ExternalLoginScreenPlatform = useComponent(ExternalLoginScreenDesktop, ExternalLoginScreenApp);
    if(!loaded) { return <></>; }

    return (
        <ExternalLoginScreenPlatform {...props} />
    );
}

export const ExternalLoginCallbackScreen = (props: RouteComponentProps) =>
{
    useEffect(() => {
        const nextUrl = getNextUrl();
        const sub = handleLogin(undefined, nextUrl);
      return () => sub.abort();
    }, []);

    const ExternalLoginScreenPlatform = useComponent(ExternalLoginCallbackScreenDesktop, ExternalLoginCallbackScreenApp);
    return (
        <ExternalLoginScreenPlatform {...props} />
    );
};

export const ExternalLogoutScreen = (props:RouteComponentProps) =>
{
    useEffect(() => {
        const nextUrl = getNextUrl();
        Auth.signOut(nextUrl);
    }, []);

    const LogoutPlatform = useComponent(ExternalLogoutScreenDesktop, ExternalLogoutScreenApp);
    return (
        <LogoutPlatform {...props} />
    );
}

export const ExternalLogoutCallbackScreen = (props:RouteComponentProps) =>
{
    useEffect(() => {
        const nextUrl = getNextUrl();
        const sub = handleLogout(nextUrl);
        return () => sub.abort();
    }, []);
    
    const LogoutPlatform = useComponent(ExternalLogoutScreenDesktop, ExternalLogoutScreenApp);
    return (
        <LogoutPlatform {...props} />
    );
};