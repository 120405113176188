import { DefaultTemplate } from 'views/templates/DefaultTemplate';
import { INotificationScreenProps } from './NotificationScreen.def';
import './NotificationScreen.scss';
import { useFetchGetNotificationsService,GetNotificationsApi} from 'services/notifications/getNotifications';
import { Empty, NotificationsCard } from './components/NotificationsPopup/NotificationsPopup';
import { FilterPopUp } from 'views/calendar/popups/FilterPopUp';
import { useEffect, useState } from 'react';
import {  ShapelessButton } from 'views/forms/buttons/Button';
import { IonCol, IonRow } from '@ionic/react';
import { fetchMarkNotificationService } from 'services/notifications/markNotification';
import { displayMessage } from 'scripts/hooks/feedbacks';

const filterTypes= [{long_value:"Deal",short_value:"Deal"},{long_value:"Calendar",short_value:"Calendar"},{long_value:"Contact",short_value:"Contact"}]
    var timeout: any;

export const NotificationScreenDesktop = (_props:INotificationScreenProps): JSX.Element =>
{
    const [{response,loading},action]=useFetchGetNotificationsService();
    const [data,setData]=useState<Array<GetNotificationsApi.IListData>>([]);
    const [filter,setFilter]=useState({input:"",types:""})
    const handleFilterData = async (type:{  types?: string}) => {
        action.fetch({types:type.types,input:filter.input,page_size:(response?.page_size??20)+20})
        setFilter({...filter,types:type.types??""})
        
    }
    useEffect(()=>{!loading&&setData(response?.list??[])},[response])
    const loadMore = async () => {
        await action.fetch({...filter,page_size:(response?.page_size??20)+20})
    }

    const onChangeSearchText = async (text: string) => {
        clearTimeout(timeout);
        if (text.length > 0) {
            timeout = setTimeout(() => action.fetch({ ...filter, input: text }), 500);
            setFilter({ ...filter, input: text });
        } else if (text.length === 0) {
            action.fetch({ ...filter, input: "" });
        }
    };
    const handleMarkAllRead=async()=>{
        await fetchMarkNotificationService({read:"1"});
        action.fetch(filter);
        displayMessage("Successfully marked all as read");
     }

 return ( <DefaultTemplate title="Notifications" >
        <div className="container pb-5">
            <div className='max-800'>
                        <div className='d-md-block d-none'>
                            <div className='row no-gutters pt-4 align-items-center mb-4'>
                                <div className="col">
                                    <h2 tabIndex={0} className="mb-0">Notifications</h2>
                                </div>
                                <div className="col-auto">
                                    <button role="button" className="btn btn--black" onClick={handleMarkAllRead}><img className="mr-2" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" />Mark all as read</button>
                                </div>
                            </div>
                        </div>

                        <div className='d-md-none d-block'>
                            <div className='row no-gutters pt-4 align-items-center mb-4'>
                                <div className="col">
                                    <h3 tabIndex={0} className="mb-0">Notifications</h3>
                                </div>
                                <div className="col-auto">
                                    <div className="font_weight--bold cursor--hand" onClick={handleMarkAllRead}><img className="mr-2" src="https://content.harstatic.com/media/icons/icon-edit_2.svg" />Mark all as read</div>
                                </div>
                            </div>
                        </div>
                    <div>
                        <div className="card mb-5 mb-md-0">
                        <div className="border-bottom p-3">
                            <IonRow>
                                <IonCol className="col-md-9">
                                <input onChange={(e) => onChangeSearchText(e.target.value ?? "")} type="text"  className=" max-w-400 form-control input-search_form searchwithborder--field input-search_form__shapeless" placeholder="Search notifications…" />
                                </IonCol>
                            <IonCol className="col-md-3">
                                <div className=' d-flex justify-content-end'>
                            <FilterPopUp
                                selectedValue={filter.types}
                                handleFilter={handleFilterData} 
                                filterName="types"
                                items={filterTypes}
                                label="Types"/> 
                                </div>
                            </IonCol>
                            </IonRow>
                        </div>
                        {(!!response?.total&&response?.total>0)&&<div className="border-bottom pl-4 pr-4 mt-2">{response?.total} notifications</div>}
                        
                        <div className="pt-4 pl-4 pr-4">
                        

                        {data.find((item)=>item.date_tag==='today')&&<div className="title__label mb-3 color_snow_white">New</div>}
                         </div>       
                         {data.map((item,index)=>item.date_tag=='today'&&<div className="card--email cursor--hand pr-0 pl-0 bg-color-f3f4f7"> 
                          <div className="pl-4 pr-4"><NotificationsCard key={index} {...item}/></div> </div>)}
                         
                        <div className="pt-4 pl-4 pr-4 pb-4">
                        {data.find((item)=>item.date_tag==='older'||item.date_tag==='yesterday')&&<div className="title__label mt-3 mb-3 color_snow_white">Previous</div>}
                                {data.map((item,index)=>item.date_tag!=='today'&&<NotificationsCard key={index} {...item}/> )}
                                {loading&&<Loading/>}
                                {response?.list.length == 0 && <Empty title='notifications'/>}
                        </div> 
                        {response?.page!==response?.total_pages&&<div className="notification_card_footer p-1 text-right">
                                <ShapelessButton loading={loading} color={"blue"} onClick={loadMore} size='small' >  View more results</ShapelessButton></div>}
                                </div> </div>
            </div>
        </div> 
            </DefaultTemplate>
    )
}

function Loading(_props: { }): JSX.Element {
    return (
                <>    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    <div className="agent_signature  agent_signature__mini agent_signature__loading border-bottom p-3">
                        <div className="agent_signature__photo loading"></div>
                        <div className="agent_signature__text">
                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                        </div>
                    </div>
                    </>
    )
}