import { IContactDealScreenProps } from './ContactDealScreen.def';
import { useResponsiveChoose } from "scripts/hooks/general";
import { ContactDealScreenApp } from './ContactDealScreen.app';
import { ContactDealScreenDesktop } from './ContactDealScreen.desktop';

export const ContactDealScreen = (props: IContactDealScreenProps): JSX.Element =>
{
    const ContactDealScreenPlatform = useResponsiveChoose(ContactDealScreenDesktop, ContactDealScreenApp);
    return (
        <ContactDealScreenPlatform {...props} />
    );
}
