import { Textbox } from 'views/forms/textboxes/Textbox';
import { DefaultPopUp } from 'views/popups/DefaultPopUp';
import { IDealsAddProps } from './DealsAdd.def';
import './DealsAdd.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContactsTypeahead } from 'views/forms/typeaheads/ContactsTypeahead';
import { useEffect, useState } from 'react';
import { IonAlert } from '@ionic/react';
import { PrimaryButton, ShapelessButton } from 'views/forms/buttons/Button';
import { PropertyTypeahead } from 'views/forms/typeaheads/PropertyTypeahead';
import { parseISO, format } from 'date-fns'
import { ListingsApi } from 'services/typeaheads/listings';
import { useRemoveDealService } from 'services/deals/removeDeal';
import { currencyFormat } from 'scripts/helpers/number';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MuiDatePicker } from 'views/forms/material/MuiDatePicker';
import { TextArea } from 'views/forms/textarea/TextArea';
import { ContactDetailPopover } from 'views/contacts/popovers/ContactDetailPopover';
import { useHistory } from 'react-router';
import { OverlayTrigger, Popover  } from 'react-bootstrap';

export const DealsAddDesktop = (props: IDealsAddProps): JSX.Element => {
    const { visible, loading, deals, lookup, dealsId, listing, contact, type, getError, setListing, setContact, setDeal, updateDeal, toggleShow, saveDeal, reload } = props
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmClose, setConfirmClose] = useState(false)
    const [deal_id, setDeal_id] = useState("")
    const [, setDealAmount] = useState("")
    const [showContactDetailPopup,setShowContactDetailPopup]=useState(false)
    const stages = lookup?.response?.deal_stage.items.filter((s) => s.short_value !== 'F').filter((s) => s.short_value !== 'N');
    const dealTypes = lookup?.response?.deal_type.items;
    const dealPriorties = lookup?.response?.deal_priority.items;
    let history = useHistory()

    //const date = new Date()
    function updateContact() {
        setContact?.([])
        updateDeal?.('contact_id', "")
    }

    function removeListing() {
        setListing?.([])
        if (deals)
            setDeal?.({
                ...deals,
                ["property_harid"]: "",
                ["property_listingid"]: "",
                ["property_for"]: "",
                ["property_full_street_address"]: "",
                ["deal_amount"]: "",
            })
    }

    function formattedField(value: string) {
        let number = value.replace(/[^0-9]/g, '')
        updateDeal?.("deal_amount", number)
    }

    function updateListing(listing: ListingsApi.IListingsList[]) {
        if (listing && listing[0]) {
            setListing?.(listing)
        }
    }



    const close = () => {
        let dealsObj: any = { ...deals }
        delete dealsObj.deal_stage
        delete dealsObj.deal_stage_text
        if (props.contactId) {
            delete dealsObj.contact_id
        }
        let isEmpty = Object.values(dealsObj).every(x => x === null || x === '');
        if (!isEmpty) {
            setConfirmClose(true)
        } else {
            toggleShow?.()
        }
    }

    const onDeleteHandler = () => {
        setConfirmDelete(false)
        setDeal_id("")
        reload?.(type)
        toggleShow?.()
    }
    useEffect(() => {
        if (listing && listing[0] && deals) {
            setDeal?.({
                ...deals,
                ["property_harid"]: listing[0]?.harid,
                ["property_listingid"]: listing[0]?.listingid,
                ["property_city"]: listing[0]?.city,
                ["property_state"]: listing[0]?.state,
                ["property_zip"]: listing[0]?.zip,
                ["property_country"]: listing[0]?.country,
                ["property_address2"]: listing[0]?.address_2 ? listing[0]?.address_2 : '',
                ["property_for"]: listing[0]?.for,
                ["property_full_street_address"]: listing[0]?.street_address,
                ["deal_amount"]: listing[0]?.list_price
            })
        }
    }, [listing])

    useEffect(() => {
        if (contact && contact[0]) {
            updateDeal?.('contact_id', contact[0].id.toString())
        }
    }, [contact])


    const popover_priority = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Priority</Popover.Title>
            <Popover.Content>
            Assign a Priority (None, Low, Medium, High) level to the Deal.
            </Popover.Content>
        </Popover>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ContactDetailPopover
                show={showContactDetailPopup}
                viewProfile={() => {
                    setShowContactDetailPopup(showContactDetailPopup ? false : true);
                    setTimeout(() => {
                        history.push('/contacts/' + contact?.[0]?.id + '/overview', { referrer: '/emails/inbox' })
                    }, 400)
                }}
                id={!!contact?.[0]?.id ? contact[0]?.id : 0}
                toggleShow={() => setShowContactDetailPopup(false)}
            // reload={reload}
            />
            <ConfirmClose show={confirmClose}
                affirmative={() => {
                    setConfirmClose(false);
                    toggleShow?.()
                    setDealAmount("")
                    props.clean?.()
                }}
                negative={() => {
                    setConfirmClose(false);
                }}
            />
            <ConfirmDelete deals_id={deal_id} show={confirmDelete} type={type} onClose={() => {
                setConfirmDelete(false)
                setDeal_id("")
            }} onRemove={onDeleteHandler} />
            <DefaultPopUp className='custom--modal__fullheight custom--modal_deals' title={dealsId ? `Edit Deal` : `New Deal`} isOpen={visible} toggleShow={() => toggleShow?.()} onCancel={() => dealsId ? toggleShow?.() : close()}>
                <DefaultPopUp.Body>
                    {loading && <Loading />}
                    {!loading &&
                        <div className="ion-padding bg-white">
                            <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-0">
                                <div className='mb-4'>
                                    <Textbox label="Deal Title" error={getError?.("title")} value={deals?.title} maxlength={99} placeholder="Give your Deal a Title" id="Title" onIonChange={(e: any) => {
                                        updateDeal?.("title", e.target.value)
                                    }}></Textbox>
                                </div>
                                <div className='mb-4' onClick={()=>contact?.[0]?.deleted=='0'&&setShowContactDetailPopup(true)}>
                                    <ContactsTypeahead
                                        className={`${getError?.("contact_id") ? `is-invalid` : ``}`}
                                        initialValues={contact ? contact : []}
                                        change={setContact}
                                        remove={updateContact}
                                        nonRemoveable={props.preSelected ? true : false}
                                        minLength={0}
                                        deleted={contact && contact[0] && contact[0]?.deleted === "1" ? true : false}
                                    />

                                </div>
                                <div className="mb-4">
                                    <label className="mb-2">Current Deal Stage</label>
                                    <Dropdown className={`dropdown dropdown--custom dropdown--custom_large mb-0 ${getError?.("deal_stage") ? `is-invalid` : ``}`}>
                                        <Dropdown.Toggle className="dropdown-toggle font_weight--normal">

                                            {deals?.deal_stage_text ? deals.deal_stage_text : 'Select the Current Deal Stage'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`w-100`}>
                                            {stages?.map((stage, index) =>
                                                <Dropdown.Item key={index} onClick={() => {
                                                    if (deals) {
                                                        setDeal?.({
                                                            ...deals,
                                                            estimated_deadline: stage.short_value === 'C' ? format(new Date(), 'yyyy-MM-dd') : deals.estimated_deadline,
                                                            deal_stage: stage.short_value,
                                                            deal_stage_text: stage.long_value
                                                        })
                                                    }

                                                }} className={`d-flex ${index + 1 !== stages.length ? `border-bottom` : ``} font_weight--bold cursor--hand`}>
                                                    <span>{stage.long_value}</span>
                                                    <span className="ml-auto"><img src={`https://content.harstatic.com/media/icons/crm/dealstage_${index + 1}.svg`}></img></span>
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {getError?.("deal_stage") &&
                                        <div className='invalid-feedback d-flex'>{getError?.("deal_stage")}</div>
                                    }
                                </div>

                                <div className="mb-4">
                                    <label className="mb-2">Deal Type</label>
                                    <Dropdown className="dropdown dropdown--custom dropdown--custom_large mb-0">
                                        <Dropdown.Toggle className="dropdown-toggle font_weight--normal">
                                            {deals?.deal_type_text ? deals.deal_type_text : ' Select the Deal Type'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="w-100">
                                            {dealTypes?.map((type, index) =>
                                                <Dropdown.Item value={type.short_value} onClick={() => {
                                                    if (deals) {
                                                        setDeal?.({
                                                            ...deals,
                                                            deal_type: type.short_value,
                                                            deal_type_text: type.long_value
                                                        })
                                                    }
                                                }} key={index} href="#" className={`d-flex ${index + 1 !== dealTypes.length ? `border-bottom ` : ``} font_weight--bold`}>
                                                    <span>{type.long_value}</span>
                                                    <span className="ml-auto"></span>
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {getError?.("deal_type") &&
                                        <div className='invalid-feedback d-flex'>{getError?.("deal_type")}</div>
                                    }
                                </div>

                                <div className='mb-4'>
                                    <PropertyTypeahead className={`${getError?.("property_full_street_address") ? `is-invalid` : ``}`}
                                        initialValues={listing ? listing : []}
                                        showingCountry={true}
                                        change={setListing}
                                        minLength={1}
                                        remove={removeListing}
                                        update={updateListing}
                                    />
                                    {getError?.("property_full_street_address") &&
                                        <div className='invalid-feedback  d-flex'>{getError?.("property_full_street_address")}</div>
                                    }
                                </div>

                                {/*this block will shows up only when property selected */}

                                <Textbox class="mb-4 form-control" type='text' value={deals?.deal_amount ? currencyFormat(deals.deal_amount) : ''}
                                    label="Deal Amount" maxlength={12} placeholder="$" id="Amount" onIonInput={(e: any) => formattedField(e.target.value)}></Textbox>

                                <div className="pb-4 mb-4">
                                    <MuiDatePicker
                                        label='Estimated Closing Date'
                                        disabled={deals?.deal_stage === 'C'}
                                        value={deals?.estimated_deadline ? parseISO(deals?.estimated_deadline) : undefined}
                                        onChange={(date: any) => updateDeal?.('estimated_deadline', format(date, 'yyyy-MM-dd'))}
                                    />
                                </div>


                                <div className="pb-4 mb-4">                                    
                                    <label className="mb-2">Priority <OverlayTrigger trigger="hover" placement="right" overlay={popover_priority}><img src="https://content.harstatic.com/media/icons/info-bronze.svg"></img></OverlayTrigger></label>

                                    <Dropdown className={`dropdown dropdown--custom dropdown--custom_large mb-0 ${getError?.("deal_stage") ? `is-invalid` : ``}`}>
                                        <Dropdown.Toggle className="dropdown-toggle font_weight--normal">
                                            {deals?.deal_priority ? dealPriorties?.filter((d) => d.short_value === deals?.deal_priority)[0]?.long_value : 'None'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={`w-100`}>
                                            {dealPriorties?.map((priorty, index: number) =>
                                                <Dropdown.Item key={index} className={`d-flex ${index <= dealPriorties.length - 1 ? `border-bottom` : ``} font_weight--bold`} onClick={() =>
                                                    updateDeal?.('deal_priority', priorty.short_value)
                                                }>
                                                    <span>{priorty.long_value}</span>
                                                    <span className="ml-auto"></span>
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>


                                <div className="">
                                    <TextArea label='Your Notes'
                                        labelAttr={{ className: 'mb-2' }}
                                        showCounter={true}
                                        maxLength={3000}
                                        rows={5}
                                        className="form-control" placeholder="Type some notes, if you want…"
                                        onChange={(e: any) => updateDeal?.("notes", e.target.value)} value={deals?.notes}></TextArea>
                                </div>
                            </div >
                        </div >
                    }
                </DefaultPopUp.Body >
                <DefaultPopUp.ActionBar>
                    <div className="ml-4 mr-4">
                        <div className="row pt-2 mb-2">
                            <div className="col-auto min-w-120px"></div>
                            <div className="col text-center">
                                {dealsId ?
                                    <ShapelessButton className="btn--medium" onClick={toggleShow}>Cancel</ShapelessButton> : <></>}
                                <PrimaryButton loading={loading} className="btn--medium min-w-100px" onClick={() => {
                                    if (dealsId) {
                                        saveDeal?.(dealsId)
                                    } else {
                                        saveDeal?.()
                                    }
                                }}>{dealsId ? `Update` : `Save`}</PrimaryButton>
                            </div>
                            <div className="col-auto algin-self-center min-w-120px">
                                {dealsId ?

                                    <ShapelessButton className="btn--medium" onClick={() => {
                                        setDeal_id(dealsId.toString())
                                        setConfirmDelete(true)
                                    }}> <span className='color_sold'>Delete Deal</span></ShapelessButton>
                                    :
                                    <ShapelessButton className="btn--medium" onClick={close}>Cancel</ShapelessButton>
                                }
                            </div>
                            
                        </div>
                    </div>
                </DefaultPopUp.ActionBar>
            </DefaultPopUp >
        </LocalizationProvider>
    );
}

export const Loading = () => {
    return (
        <div className="ion-padding bg-white">
            <div className="pl-md-5 pr-md-5 pt-md-4 pb-md-0">

                <label htmlFor="Address2">Deal Title</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2">Current Deal Stage</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2">Deal Type</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2">Property</label>
                <div className="loading__textbox mb-4"></div>

                {/* menualy add property block starts here */}
                <div className="p-4 mb-5 border_radius--default d-none bg-color-rgba_1">
                    <div className="font_size--large font_weight--semi_bold mb-4">Add Property manualy</div>

                    <label htmlFor="Address2">Address 1</label>
                    <div className="loading__textbox mb-4"></div>

                    <label htmlFor="Address2">Address 2</label>
                    <div className="loading__textbox mb-4"></div>

                    <label htmlFor="Address2">City</label>
                    <div className="loading__textbox mb-4"></div>

                    <label htmlFor="Address2">Zip Code</label>
                    <div className="loading__textbox mb-4"></div>

                    <label htmlFor="Address2">Country</label>
                    <div className="loading__textbox mb-4"></div>
                </div>
                {/* menualy add property block starts ended */}

                {/*this block will shows up only when property selected */}

                <label htmlFor="Address2">Deal Amount</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2">Estimated Closing Date</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2">Priority</label>
                <div className="loading__textbox mb-4"></div>

                <label htmlFor="Address2" className='d-none'>Collaborators</label>
                <div className="loading__textbox mb-4 d-none"></div>

                <label htmlFor="Address2">Your Notes</label>
                <div className="loading__textbox mb-4 h-40px"></div>

            </div>
        </div>
    );
}

export const ConfirmDelete = (props: IDealsAddProps.IDealsRemove) => {
    const { show, deals_id, onClose, onRemove } = props
    const [remove, removeAction] = useRemoveDealService()


    useServiceDidSucceeded(remove, () => {
        onRemove?.()
        displayMessage("The deal successfully Deleted")
    })

    function removeDeal() {
        removeAction.fetch({ deal_id: deals_id })
    }

    return (
        <DefaultPopUp isOpen={show} title="Remove" className='modal--size--small' toggleShow={() => { onClose?.() }}>
            <DefaultPopUp.Body>
                <div className="p-5 bg_color_snow_white">
                    <div className="font_size--medium font_weight--semi_bold m-auto text-center algin-self-center color_auxiliary mb-4">
                        Are you sure ?
                    </div>
                </div>
            </DefaultPopUp.Body>
            <DefaultPopUp.ActionBar>
                <div className="ml-4 mr-4">
                    <div className="row pt-2 mb-2 m-auto">
                        <div className="col">
                            <div className="text-center">
                                <PrimaryButton onClick={() => {
                                    removeDeal()
                                }} >Yes</PrimaryButton>
                                <ShapelessButton onClick={() => {
                                    onClose?.()
                                }} >No</ShapelessButton>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultPopUp.ActionBar>


        </DefaultPopUp>
    );

}

export const ConfirmClose = (props: IDealsAddProps.IDealsConfirmation) => {
    const { show, affirmative, negative } = props
    return (
        <IonAlert
            isOpen={show}
            header="All filled information will be lost, Are you sure you want to cancel?"
            buttons={[
                {
                    text: 'No',
                    cssClass: 'alert-button-cancel',
                    handler: () => negative?.()
                },
                {
                    text: 'Yes',
                    cssClass: 'alert-button-confirm',
                    handler: () => affirmative?.()
                },
            ]}
        ></IonAlert >

    )
}
