/**
 * Represents Mapbox Address Components Object
 */
export interface IMapboxAddressComponents
{
    /**
     * Address Street Number
     */
    streetNumber?: number;

    /**
     * Address Street Name
     */
    streetName?: string;

    /**
     * Address Neighborhood
     */
    neighborhood?: string;

    /**
     * Address Zip Code
     */
    zipCode?: number;

    /**
     * Address City
     */
    city?: string;

    /**
     * Address Unit
     */
    unit?: string;

    /**
     * Address State
     */
    state?: string;

    /**
     * Address Country
     */
    country?: string;
}


export interface IFeature
{
    /**
     * A feature ID in the format {type}.{id} where {type} is the lowest hierarchy feature in the place_type field. The {id} suffix of the feature ID is unstable and may change within versions.
     */
    id:string;

    /**
     * "Feature", a GeoJSON type from the GeoJSON specification.
     */
    type:string;

    /**
     * An array of feature types describing the feature. Options are country, region, postcode, district, place, locality, neighborhood, address, and poi. Most features have only one type, but if the feature has multiple types, all applicable types will be listed in the array. (For example, Vatican City is a country, region, and place.)
     */
    place_type:IPlaceType[];

    /**
     * Indicates how well the returned feature matches the user's query on a scale from 0 to 1. 0 means the result does not match the query text at all, while 1 means the result fully matches the query text. You can use the relevance property to remove results that don’t fully match the query.
     */
    relevance:number;

    /**
     * A string of the house number for the returned address feature. Note that unlike the address property for poi features, this property is outside the properties object.
     */
    address?:string;

    /**
     * An object describing the feature. The properties object may change with data improvements. Your implementation should check for the presence of these values in a response before it attempts to use them.
     */
    properties:IFeatureProperties;

    /**
     * A string representing the feature in the requested language, if specified.
     */
    text:string;

    /**
     * A string representing the feature in the requested language, if specified, and its full result hierarchy.
     */
    place_name:string;

    /**
     * A string analogous to the text field that more closely matches the query than results in the specified language. For example, querying Köln, Germany with language set to English (en) might return a feature with the text Cologne and the matching_text Köln.
     * Category matches will not appear as matching_text. For example, a query for coffee, Köln with language set to English (en) would return a poi Café Reichard, but this feature will not include a matching_text field.
     */
    matching_text?:string;

    /**
     * A string analogous to the place_name field that more closely matches the query than results in the specified language. For example, querying Köln, Germany with language set to English (en) might return a feature with the place_name Cologne, Germany and a matching_place_name of Köln, North Rhine-Westphalia, Germany.
     * Category matches will not appear in the matching_place_name field. For example, a query for coffee, Köln with language set to English (en) would return a matching_place_name of Café Reichard, Unter Fettenhennen 11, Köln, North Rhine-Westphalia 50667, Germany instead of a matching_place_name of coffee, Unter Fettenhennen 11, Köln, North Rhine-Westphalia 50667, Germany.
     */
    matching_place_name?:string;

    /**
     * A string of the IETF language tag of the query’s primary language.
     */
    language?:string;

    /**
     * A bounding box array in the form [minX,minY,maxX,maxY].
     */
    bbox:[number, number, number, number];

    /**
     * The coordinates of the feature’s center in the form [longitude,latitude]. This may be the literal centroid of the feature’s geometry, or the center of human activity within the feature (for example, the downtown area of a city).
     */
    center:[number, number];

    /**
     * An object describing the spatial geometry of the returned feature.
     */
    geometry:IFeatureGeometry;

    /**
     * An array representing the hierarchy of encompassing parent features. Each parent feature may include any of the above properties.
     */
    context:IParentFeature[];

    /**
     * An object with the routable points for the feature.
     */
    routable_points?:
    {
        /**
         * An array of points in the form of [{ coordinates: [lon, lat] }], or null if no points were found.
         */
        points?:Array<{ coordinates:[number,number] }> | null;
    }
}

/**
 * Lists Place Types
 */
export type IPlaceType = "country"|"region"|"postcode"|"district"|"place"|"locality"|"neighborhood"|"address"|"poi";


/**
 * Describes Parent Feature within Feature's context
 */
type IParentFeature = Partial<IFeature> & Pick<IFeature, 'id' | 'text'>;


/**
 * Describes Feature Geometry Object
 */
interface IFeatureGeometry
{
    /**
     * "Point", a GeoJSON type from the GeoJSON specification.
     */
    type:string;

    /**
     * An array in the format [longitude,latitude] at the center of the specified bbox.
     */
    coordinates:[number,number];

    /**
     * If present, indicates that an address is interpolated along a road network. The geocoder can usually return exact address points, but if an address is not present the geocoder can use interpolated data as a fallback. In edge cases, interpolation may not be possible if surrounding address data is not present, in which case the next fallback will be the center point of the street feature itself.
     */
    interpolated?:boolean;

    /**
     * If present, indicates an out-of-parity match. This occurs when an interpolated address is not in the expected range for the indicated side of the street.
     */
    omitted?:boolean;
}

interface IFeatureProperties
{
    /**
     * A point accuracy metric for the returned address feature. Can be one of rooftop, parcel, point, interpolated, intersection, street. Note that this list is subject to change.
     */
    accuracy?:'rooftop'|'parcel'|'point'|'interpolated'|'intersection'|'street';

    /**
     * A string of the full street address for the returned poi feature. Note that unlike the address property for address features, this property is inside the properties object.
     */
    address?:string;

    /**
     * A string of comma-separated categories for the returned poi feature.
     */
    category?:string;

    /**
     * The name of a suggested Maki icon to visualize a poi feature based on its category.
     */
    maki?:string;

    /**
     * The Wikidata identifier for the returned feature.
     */
    wikidata?:string;

    /**
     * The ISO 3166-1 country and ISO 3166-2 region code for the returned feature.
     */
    short_code?:string;
}

interface IAddressLiteral {
    state?:string;
    city?:string;
    country?:string;
    zipCode?:number
}
export function parseComponents(feature:IFeature): IMapboxAddressComponents
{
    /// Looking for main type
    const params:IMapboxAddressComponents = {};
    const typeList:IPlaceType[] = ['poi','address','neighborhood','postcode','place','region','country'];
    const type = feature.place_type.find(x => typeList.includes(x));

    /// Adding type
    if(type && type == 'poi')
    {
        let streetName = feature.properties.address;
        const streetNumber = streetName? parseInt(streetName): undefined;
        if(streetNumber) { streetName = streetName!.slice(streetNumber.toString().length); }

        if(streetNumber) { params.streetNumber = streetNumber; }
        if(streetName) { params.streetName = streetName; }
    }
    else if(type == 'address' && feature.address)
    {
        params.streetNumber = parseInt(feature.address);
        params.streetName = feature.text;
    }
    else if(type == 'address') { params.streetName = feature.text; }
    else if(type) { assignAddressParamFromType(params, type, feature.text); }
    
    /// Adding context
    if(feature.context) feature.context.forEach(item => {
        const type = <IPlaceType>item.id.split('.')[0];
        if(!typeList.includes(type)) { return; }
        assignAddressParamFromType(params, type, item.text);
    });

    /// Returning Components
    return params;
}

/**
  * Assigns Address Parameters from Feature Type if not set yet
  * @param params Address Literal Parameters
  * @param type Feature Type
  * @param value Value to be assigned to Address Parameter
  */
function assignAddressParamFromType(params:IAddressLiteral, type:IPlaceType, value:string): void
{
    switch(type)
    {
        case 'region': return assignAddressParam(params, 'state', value);
        case 'country': return assignAddressParam(params, 'country', value);
        case 'place': return assignAddressParam(params, 'city', value);
        case 'postcode': return assignAddressParam(params, 'zipCode', parseInt(value));
    }
}

/**
  * Assigns Address Literal Parameter based on Key Name
  * @param params Address Literal Object
  * @param name Address Literal Parameter Key Name
  * @param value Value to be assigned to Address Key
  */
function assignAddressParam<K extends keyof IAddressLiteral, T extends IAddressLiteral[K]>(params:IAddressLiteral, name:K, value:T): void
{
    if(!params[name] && value) { params[name] = value; }
}