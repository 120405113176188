import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, } from 'react-beautiful-dnd';
import { useFetchLookupsService } from 'services/general/lookups';

import { DealsColumn } from 'views/deals/DealsColumn';
import { DealsFilterPopover } from 'views/deals/popover/DealsFilterPopover';
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { ContactManageTemplate } from 'views/templates/ContactManageTemplate';
import { IDealsLandingScreenProps } from './DealsLandingScreen.def';
import './DealsLandingScreen.scss';
import { useHistory } from 'react-router';
import { ModuleInfo } from 'views/general/ModuleInfo';

export const DealsLandingScreenDesktop = (props: IDealsLandingScreenProps): JSX.Element => {
    const { did, type, columns, filterLoading, locationFilter, setLocationFilter, filter, reload, redirect, onDragComplete, loadMore } = props
    const [dealPop, setDealPop] = useState(false)
    const [dealStage, setDealStage] = useState("")
    const [dealId, setDealId] = useState(0)
    const [filterButton,] = useState(!type ? 1 : type === 'expired' ? 2 : type === 'upcoming' ? 3 : 0)
    const [lookupData] = useFetchLookupsService(['deal_type', 'property_type']);
    const history = useHistory()


    function formModeOpen(id: number, deal?: string) {
        setDealId(id)
        if (deal) {
            setDealStage(deal)
        } else {
            setDealStage("")
        }
        setDealPop(dealPop ? false : true)

    }

    const saveFilter = (param: IDealsLandingScreenProps.FilterType) => {
        setLocationFilter?.(param)
    }


    function dragEnd(param: any) {
        onDragComplete?.(param)
    }



    const Header = (): JSX.Element => {
        const redirect = (path: string) => {
            if (!filterLoading) {
                history.push(path)
                return true;
            } else {
                return false
            }
        }
        return (
            <div className="position-sticky blured_bg top-0">
                <div className="container container__large">
                    <div className="page_title_container pb-0">
                        <div className="row">
                            <div className="col align-self-center">
                                <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">Deals</h2>

                            </div>

                            <div className="col-auto align-self-center">
                                <DealsFilterPopover onSave={saveFilter} lookupData={lookupData} filters={locationFilter} onClear={() => (setLocationFilter?.({}))} />
                                <div className="btn-group--custom  btn-group btn-group-toggle" defaultValue={filterButton}>
                                    <label id="tbg-radio-1" className={`btn ${type === '' && 'active'} btn-primary`} onClick={() => redirect(`/deals`)}>All deals</label>
                                    <label id="tbg-radio-2" className={`btn ${type === 'expired' && 'active'} btn-primary`} onClick={() => redirect(`/deals-expired`)}>Expired</label>
                                    <label id="tbg-radio-3" className={`btn ${type === 'upcoming' && 'active'} btn-primary`} onClick={() => redirect(`/deals-upcoming`)}>Next 90 days</label>
                                </div>
                                <a className="btn btn btn--prominent__v2 btn--small ml-4 d-inline-flex cursor--hand" onClick={(e: any) => {
                                    e.preventDefault()
                                    formModeOpen(0)
                                }}>
                                    <img className="mr-1 w-22px" src="https://content.harstatic.com/media/icons/icon_plus_white.svg" /> Create
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mt-3">
                    <ModuleInfo type="deals" />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (did) {
            formModeOpen(Number(did))
        }
    }, [did])

    useEffect(() => {
        if (type) {
            if (type === 'expired') {
                filter?.({ ...locationFilter, expired: "1" })
            } else {
                filter?.({ ...locationFilter, expired_in_90_days: "1" })
            }
        } else {
            filter?.({ ...locationFilter })
        }
    }, [locationFilter])


    return (
        <>
            <DealsAdd visible={dealPop} stage={dealStage} toggleShow={() => {
                    setDealId(0);
                setDealPop(false)
                redirect?.()
            }} reload={() => {
                setDealId(0);
                reload?.(type)
            }} dealsId={dealId} type={type} />
            <ContactManageTemplate header={Header()} fluidTemplate={true}>
                <div id="Expired_Cntr" className="pipeline_list fancy_scroll mt-3">

                    <DragDropContext onDragEnd={dragEnd} >
                        {columns?.map((column) =>
                            <Droppable key={column.type} droppableId={`${column.type}`} type="deals">
                                {(provided, _snapshot) => (
                                    <div className='pipeline_col__wrapper deals_col__wrapper'>
                                        <div
                                            className='pipeline_col deals_column'
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <DealsColumn
                                                key={column.type}
                                                column={column}
                                                openAddPopUp={() => formModeOpen(0, column.type)}
                                                openEditPopUp={(deal_id: number) => {
                                                    if (did !== deal_id.toString()) {
                                                        setDealPop(false);
                                                        redirect?.(deal_id.toString())
                                                    } else {
                                                        formModeOpen(deal_id)
                                                    }
                                                }}
                                                filterLoading={filterLoading}
                                                loadMore={loadMore}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </DragDropContext>

                </div>
            </ContactManageTemplate>
        </>
    );
}
