import {  IContactsTextboxProps, ISingleContactsTextboxProps } from './ContactsTextbox.def';
import './ContactsTextbox.scss';
import { Typeahead } from 'views/forms/typeaheads/Typeahead';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import {fetchSearchContactsService,SearchContactsApi } from 'services/contacts/searchContacts';
import { useEffect, useRef, useState } from 'react';
import { ISimpleContactFormPopUpProps } from 'views/contacts/popups/SimpleContactFormPopUp/SimpleContactFormPopUp.def';
import { usePopUp } from 'scripts/hooks/popups';
import { SimpleContactFormPopUp } from 'views/contacts/popups/SimpleContactFormPopUp';

export function ContactsTextbox(props: IContactsTextboxProps): JSX.Element
{
    const maxContacts = props.maxContacts ?? 3;

    const search = (async(value: string) =>  {
        const response=await fetchSearchContactsService({ name: value });
        return response.list
    })

    const mapResult = (item: SearchContactsApi.IContactSearchItem): IDropDownOption => ({ id: `${item.first_name} ${item.last_name}`, text: item.company,photo_url:item.photo_url,title:item.id.toString(),first_name:item.first_name ,last_name:item.last_name});

    return (
        <>
            <Typeahead.Multi
                placeholder='Type here'
                initialValue={props.initialContacts}
                maxValues={maxContacts}
                search={search}
                minLength={1}
                mapResult={mapResult}
                onChange={props?.onContactsChange}
                hideSelectedItem
                fullWidth
            />
        </>
    );
}

export namespace ContactsTextbox
{
    export function Single(props: ISingleContactsTextboxProps)
    {
        const { initialContact, searchKey, exclude, showCreateContact, onContactChange, onContactCreate, ...typeaheadProps } = props;
        const lastSearchValue = useRef<string>();
        const [popupProps, setPopupProps] = useState<ISimpleContactFormPopUpProps>();

        useEffect(() => {
            if(popupProps) { present(); }
        }, [popupProps]);

        const [present] = usePopUp<ISimpleContactFormPopUpProps>(SimpleContactFormPopUp, {
            ...popupProps,
            onSave(input) {
                onContactCreate?.(input)
                setPopupProps(undefined);
            },
        });

        const onContactCreateRequest = () =>
        {
            const input = lastSearchValue.current ?? '';
            const props:ISimpleContactFormPopUpProps = {};
            if(input.includes('@')) { props.email = input; }
            else {
                const [firstName, ...lastName] = input.split(' ').filter(x => x.trim());
                props.firstName = firstName;
                props.lastName = lastName.join(' ');
            }
        
            setPopupProps(props);
        }

        const search = (async(value: string) =>  {
            lastSearchValue.current = value;
            const key = searchKey ?? 'name';
            const response = await fetchSearchContactsService({ [key]: value });
            if(!exclude) { return response.list; }
            const excludeList = Array.isArray(exclude)? exclude: [exclude];
            return response.list.filter(x => !excludeList.includes(x.id));
        });

        const mapResult = (item: SearchContactsApi.IContactSearchItem): IDropDownOption => ({ id:item.id, text: item.company,photo_url:item.photo_url,first_name:item.first_name ,last_name:item.last_name, email:item.primary_email });

        const footer = !showCreateContact? undefined:
            <div className='border-top p-3 border-color--cloudy-sky-light'>
                <div className=" font_weight--bold cursor--hand color_har_blue font_size--medium" onClick={() => onContactCreateRequest()}>Create Contact</div>
            </div>

        return (
            <Typeahead
                {...typeaheadProps}
                placeholder='Type here'
                initialValue={initialContact}
                search={search}
                minLength={1}
                mapResult={mapResult}
                onChange={onContactChange}
                footer={{ template:footer, showEmpty:true, showResults:true, showNoSearch:true }}
            />
        )
    }
}