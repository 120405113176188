
export const joinWithSeparator = (separator:string, ...values:Array<join.Value>): string =>
{
    return values.filter(x => !!x && !!x?.toString()?.trim()).join(separator);
}

export const joinWithSpace = (...values:Array<join.Value>): string =>
{
    return joinWithSeparator(' ', ...values);
}

export function join(...values:Array<join.Value>): string
{
    return joinWithSeparator('', ...values);
}

export namespace join
{
    export type Value = string | number | false | undefined;
}

export const getMaxStr=(str:string,maxLength:number)=>{
    if(str){
       return str.length > maxLength ? str.substring(0,35)+"...":str;
    }
    return "";
}

export function strToBoolean(value:string):boolean;
export function strToBoolean(value?:string,defaultValue?:boolean):boolean;
export function strToBoolean(value?:string):boolean|undefined;
export function strToBoolean(value?:string,defaultValue?:boolean){
    if(value===undefined){ return defaultValue;}
    return value==='1'?true:false
};

export function booleanToStr(value:boolean):string;
export function booleanToStr(value?:boolean,defaultValue?:string):string;
export function booleanToStr(value?:boolean):string|undefined;
export function booleanToStr(value?:boolean,defaultValue?:string){
    if(value===undefined){ return defaultValue;}
    return value===true?'1':'0'
};

export function convertToInternationalCurrencySystem (labelValue:string|undefined) {
    
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue));

}

const formatPhone = (value: string) => {
    const newValue = value.replace(/[""() -]/g, "");
    const size = newValue.length + 4;
    const firstIndex = '(';
    const fourthIndex = ')';
    const fifthIndex = ' ';
    const lastIndex = '-';
    var patternArray = new Array(size);
    patternArray[0] = firstIndex;
    patternArray[4] = fourthIndex;
    patternArray[5] = fifthIndex;
    if (newValue.length > 6) {
        patternArray[9] = lastIndex;
    }
    for (let i = 0; i < newValue.length; i++) {
        if (patternArray[i] === undefined) {
            patternArray[i] = newValue[i]
        } else if (patternArray[i + 1] === undefined) {
            patternArray[i + 1] = newValue[i]
        } else if (patternArray[i + 2] === undefined) {
            patternArray[i + 2] = newValue[i]
        } else if (patternArray[i + 3] === undefined) {
            patternArray[i + 3] = newValue[i]
        } else if (patternArray[i + 4] === undefined) {
            patternArray[i + 4] = newValue[i]
        }

    }
    return patternArray;
}

export function transformPhoneNumber(country_code:string|undefined,phone:string,_phone_extension:string|number|undefined){
    //const extension=!isEmpty(phone_extension?.toString())? ' ext.'+phone_extension:'';
    const joinedPhoneNumber=joinWithSeparator("",country_code,phone)
 
    const phoneWithCountryCode=(country_code?.length??0)>1? (country_code+phone):phone
    const phoneNumber=phoneWithCountryCode.length>10 ||phoneWithCountryCode.length<10?joinedPhoneNumber:formatPhone(phone).toString().split(',').join('');
    //const countryCode= !isEmpty(country_code)?"+"+country_code+" ":'';
    //const transformNumber= joinWithSeparator("",countryCode,phoneNumber,extension);
    return phoneNumber;//transformNumber;
}

export function isEmpty (value:string|undefined){
if(typeof value!='undefined' && value&&value!=''){
    return false;
 }
   return true 
 
}

export function allEmpty(...fields:(string | number)[])
{
    return fields.every(x => !x || !x.toString().trim());
}

export function allEmptyOrNone(...fields:(string | number)[])
{
    return fields.every(x => !x || !x.toString().trim()||x=='None');
}
