import { useFetchGetRecommendedItemsService,GetRecommendedItemsApi } from 'services/recommendedActionItems/getRecommendedItems';
import { useGetCalendarTasksService } from 'services/calendar/getCalendarTasks';
import { useFetchGetBasicInfoService } from 'services/user/getBasicInfo';
//import { AddTaskPopUp } from 'views/calendar/popups/AddTaskPopUp';
import { RecommendedItemsCard } from '../RecommendedItemsCard'
import { TaskTimeline } from 'views/calendar/TaskTimeline';
import { DealsAdd } from 'views/deals/popup/DealsAdd';
import { IHomeScreenProps, IHomeScreenState,INoteCarousel, INoteList  } from './HomeScreen.def';
import { Dropdown, Tab, Tabs, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './HomeScreen.scss';
import { Loading } from 'views/calendar/TaskTimeline/components/loading';
import { Empty } from '../RecommendedItemsCard/components/empty';
import { EmailList } from 'views/emails/EmailList';
import { useFetchGetDashboardService } from 'services/user/getDashboard';
import { convertToInternationalCurrencySystem } from 'scripts/helpers/texts';
import { NewEmail } from 'views/emails/popups/NewEmail';
import { useFetchGetNotesService } from 'services/contacts/contactDetails/getNotes';
import { AddNotesPopUp } from 'views/contacts/popups/AddNotesPopUp';
import { useUpdateNotesService } from 'services/contacts/contactDetails/updateNotes';
import { useAddNotesService } from 'services/contacts/contactDetails/addNotes';
import { useDeleteNotesService } from 'services/contacts/contactDetails/deleteNotes';
import { usePopUp } from 'scripts/hooks/popups';
import { useServiceDidSucceeded } from 'scripts/hooks/useService';
import { LoaderScreen } from 'views/screens/LoaderScreen';
import { NoConnectionBox } from 'views/emails/components/NoConnectionBox';
import { ShapelessButton, ProminentButton } from 'views/forms/buttons/Button';
import { isLocal } from 'scripts/application/settings';
import { IonIcon } from '@ionic/react';
import { peopleCircleOutline } from 'ionicons/icons';
import { TaskForm } from 'views/calendar/popups/AddTaskPopUp/components/TaskForm';

export const HomeScreenDesktop = (props: IHomeScreenProps): JSX.Element => {
    const { mailService, memberService, taskId } = props
    let history = useHistory<IHomeScreenState>()
    const { state = {} } = history.location;
    const [{ response, loading }] = useFetchGetBasicInfoService();
    const [tasks, actionTasks] = useGetCalendarTasksService();
    const [dashboard] = useFetchGetDashboardService();
    const [recommendedItems,getRecommendedItems] = useFetchGetRecommendedItemsService();
    const [showAddTask, toggleShowAddTask] = useState(false);
    const [showMore, setShowMore] = useState(false)
    const [aboutText, setAboutText] = useState(state.aboutText ?? true);
    const [category,setCategory]=useState("t");

    const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>();
    const [dealPop, setDealPop] = useState(false);
    const [cid, setCid] = useState("")
    const [compact, updateCompact] = useState(state.compact ?? 0);
    const [writeEmail, setWriteEmail] = useState(false);
     const [recommendedItemsList,setRecommendedItemsList] = useState<Array<GetRecommendedItemsApi.IResponseList>>([])

    const toggleCompact = (compact: number) => {
        updateCompact(compact)
        history.replace(history.location.pathname, { ...state, compact });
    };
    const closeAboutText = () => {
        setAboutText(false)
        history.replace(history.location.pathname, { ...state, aboutText: false });
    };
    const loadRecommendedItems=async(showMore:boolean)=>{
       await getRecommendedItems.fetch({page_size:showMore?(recommendedItems.response?.page_size??20)+20 : recommendedItems.response?.page_size??20});
    }
    useEffect(()=>{
        !recommendedItems.loading&&  setRecommendedItemsList(recommendedItems.response?.list??[]);
    },[recommendedItems.response?.list])
    /*useEffect(() => {
        if(state.compact) { toggleCompact(state.compact); }
    }, [state.compact]);

    useEffect(() => {
        history.replace(history.location, { compact });
    }, [compact]);*/
    useEffect(() => {
        if (memberService?.response && typeof memberService.response?.provider_status !== 'undefined') {
            setConnectionStatus(memberService?.response.provider_status?.toLowerCase() === 'ok' ? true : false)
        }
    }, [memberService?.response])

    useEffect(() => {
        if (props.contactId)
            setCid(props.contactId)
    }, [props.contactId]);

    const loadTask = () => {
        actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })
    }
    useEffect(() => loadTask(), [])

    const redirectNewTab = (link: string) => {
        window.open(link);
    }
    if (loading) { return <LoaderScreen />; }

    return (
        <div className="container">
            <div className="page_title_container">
                <div className="row">
                    <div className="col align-self-center">
                        {!props.contactId && <h2 tabIndex={0} className="h2--simple h2--simple__black mb-0">{response && `Welcome, ${response?.first_name}`}</h2>}
                        {/*props.contactId &&<div className="col align-self-center"><span>Two months ago </span>Last time you contacted {contacts.response?.data.first_name}  </div>*/}
                    </div>
                    {/* {props.contactId &&<div className="w-100">  <Notes /></div>} */}
                    <div className="col-auto align-self-center">

                        {/* <button role="button" type="button" className="btn btn--prominent__v2 btn--large mb-0">
                                <img className="mr-1" src="https://content.harstatic.com/media/icons/add_white_2.svg" /> Add
                            </button> */}
                        {!props.contactId && <Dropdown>
                            <Dropdown.Toggle className="btn--large w-100 mb-2 align-items-center btn--prominent__v3" variant="-prominent__v2">
                                <img className="mr-1 w-17px" src="https://content.harstatic.com/media/icons/add_white_2.svg" /> Add
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu__icon" align={'right'}>
                                <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => {setCategory('t');toggleShowAddTask(true)}}>
                                    <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></span>
                                    <span className="dropdown-item__text">Task</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => {setCategory('e');toggleShowAddTask(true)}}>
                                    <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></span>
                                    <span className="dropdown-item__text">Event</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#" onClick={() => setDealPop(true)}>
                                    <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-deal.svg" /></span>
                                    <span className="dropdown-item__text">Deal</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" as={Link} to="/contacts/create">
                                    <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-contact.svg" /></span>
                                    <span className="dropdown-item__text">Contact</span>
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#">
                                        <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-service.svg" /></span>
                                        <span className="dropdown-item__text">Service</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item animate__animated animate__fadeInUp animate__faster" href="#">
                                        <span className="dropdown-item__icon"><img src="https://content.harstatic.com/media/icons/crm/icon-conversation-log.svg" /></span>
                                        <span className="dropdown-item__text">Conversation Log</span>
                                    </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    <div className='col-12'>
                        {props.contactId && <div>  <Notes /></div>}
                    </div> 
                </div>
            </div>
            <div className="row">
                {/* Start of First columns */}

                <div className="col-12 col-md-8">
                    {(!props.contactId && aboutText) && <div>
                        <div className=' border_radius--default bg_color_pending_light p-4 mb-4 position-relative'>
                            <div><h3 tabIndex={0}>   About the App</h3></div>
                            <div onClick={closeAboutText} className=' position-absolute top-10px right-10px zindex-1 cursor--hand'><img src="https://content.harstatic.com/media/icons/close_black.svg"></img></div>

                            <div className=' font_size--medium'>
                                Manage your contacts, tasks, and deals all in HAR’s CRM platform. This program helps ensure that prospecting, referral generation, and marketing campaigns happen when planned. Features, such as the Contact Pipeline and the Deals Dashboard

                                {showMore && <span>, allow agents to easily view the current state of their business to plan their next steps.<br />
                                    <a className='font_weight--bold' onClick={() => redirectNewTab('https://cms.har.com/har-crm')}>Learn more here</a><br /><br />
                                    Stay connected on the go with the <a onClick={() => redirectNewTab("https://www.har.com/")}>HAR</a>  CRM mobile app. To download the app, <a className='font_weight--bold' onClick={() => redirectNewTab("https://www.har.com/mobile/#CRMApp")}>click here</a>
                                    <br />
                                </span>}
                                <br />
                                <a onClick={() => setShowMore(showMore ? false : true)} className='font_weight--bold cursor--hand'>view {showMore ? "less" : "more"}</a>
                            </div>
                        </div>

                    </div>}
                    {!props.contactId && memberService?.response?.emails?.total_unread_lead && memberService?.response?.emails?.total_unread_lead !== "0" &&
                    <div className={`bg_color_cement_light p-3 d-flex align-items-center border_radius--default mb-4 py-4 cursor--hand`} onClick={() => history.push('/emails/lead')}>
                        <div className="pr-2"><IonIcon icon={peopleCircleOutline} className='lead--Icon'></IonIcon></div>
                        <div className="font_size--medium font_weight--bold color_auxiliary">You have  {memberService?.response?.emails?.total_unread_lead} unread email lead{Number(memberService?.response?.emails?.total_unread_lead) > 1 ? `s`:``}.</div>
                    </div>
                    }

                    {isLocal&&!props.contactId && (dashboard.loading ? <LoadingState /> : <div className="row mb-5">
                        <div className="col-12 col-md-6 pb-4 pb-md-0">
                            <div className="card--kpi h-100" onClick={() => history.push(`/emails/forwarded`)}>
                                <div className="card--kpi__left">
                                    <div className="card--kpi__left_number">{dashboard.response?.leads.total}</div>
                                    <div className="card--kpi__left_title">Leads</div>
                                </div>
                                <div className="card--kpi__right">
                                    Stay up to date on your opportunities and leads (Active + in Contract):
                                    <br />
                                    Your have {dashboard.response?.leads.total_today} new leads today
                                    <br />
                                    <strong> {dashboard.response?.leads.total_today}</strong> the number of new Leads, as up today

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div onClick={() => history.push(`/deals`)} className="card--kpi card--kpi__light h-100 cursor--hand">
                                <div className="card--kpi__left">
                                    <div className="card--kpi__left_number">{dashboard.response?.deals.total}</div>
                                    <div className="card--kpi__left_title">Deals</div>
                                </div>
                                <div className="card--kpi__right">
                                    <div className="pb-2">
                                        View opportunities / leads
                                        in your pipeline.
                                    </div>
                                    Total transactions amount:<br />
                                    <strong>${convertToInternationalCurrencySystem(dashboard.response?.deals.amount)}</strong>
                                </div>
                            </div>
                        </div>

                    </div>)}
                    <div className="card p-4 mb-5">
                        <div className=" position-absolute align-self-end col-lg-auto col-6 text-lg-left text-right order-lg-0 order-1">
                            <ToggleButtonGroup className="btn-group--custom mr-3" type="radio" name="options" value={compact}>
                                <ToggleButton id="tbg-radio-1" onClick={() => toggleCompact(0)} value={0}>Full</ToggleButton>
                                <ToggleButton id="tbg-radio-2" onClick={() => toggleCompact(1)} value={1}>Compact</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <Tabs defaultActiveKey="UpcomingTasks" transition={false} id="ContactTabs" className="nav nav-tabs nav-tabs--simple ">
                        <Tab eventKey="UpcomingTasks" title="Upcoming Tasks" className="mt-5 animate animate__slideInUp">
                                <>
                            <TaskTimeline tasks={tasks.response || undefined} compact={compact === 1}
                                hideDateCol
                                refreshTasksList={loadTask}
                                taskId={taskId}
                                returnUrl={props.contactId && props.isContactOverview ? `/contacts/${props.contactId}/overview` : '/home'}
                                redirect={
                                    (id: string) => {
                                        history.push(props.contactId ? props.isContactOverview ? `/contacts/${props.contactId}/overview/task/${id}` : `/contacts/${props.contactId}/calendar/task/${id}` : '/home/task/' + id, { compact })
                                    }
                                }
                                />
                                <div className="w-100">{!tasks.loading&& tasks.response?.page !== tasks.response?.total &&
                                <div className=' text-right mt-3'>
                                    <a className=' custom_font text-right' href={props.contactId?`/contacts/${props.contactId}/calendar`:"/calendar"} >{tasks.response?.list.length==0?"Go to Calendar":"View all tasks"} →</a>
                                </div>}</div>
                                </></Tab>
                             <Tab eventKey="RecommendedActions" title="Recommended Actions" className="mt-4 animate animate__fadeInUp">
                                {!recommendedItems.loading &&recommendedItemsList && recommendedItemsList?.length === 0 && <Empty />}
                                {recommendedItemsList.length > 0 &&
                                        <div className="pt-4 pb-4">
                                            <div className="pb-0">
                                                <div className="row">
                                                    {recommendedItemsList.map((item, key) =>
                                                        <RecommendedItemsCard data={item} compact={compact === 1} loadRecommendedItems={()=>loadRecommendedItems(false)} showAddTask={toggleShowAddTask} setCategory={setCategory} showAddDeal={setDealPop} showWriteEmail={setWriteEmail} key={key} />
                                                    )}

                                                </div>
                                            </div>  
                                          {!recommendedItems.loading&&(recommendedItemsList.length!==recommendedItems.response?.total)&&<div className=' text-right'>
                                            <ShapelessButton color={"blue"} onClick={()=>loadRecommendedItems(true)} > View more results</ShapelessButton></div>}
                                          {recommendedItems.loading && <Loading />}
                                        </div>}
                            </Tab>
                        </Tabs>
                      
                    </div>
                    {/* End of First columns */}
                </div>
                {/* Start of right columns */}
                <div className="col-12 col-md-4 mb-5">
                    <div className="card">
                        <div className="pl-3 pr-3 pt-4 pb-3">
                            <div className="row">
                                <div className="col">
                                    <h3 tabIndex={0} className="mb-0">Emails</h3>
                                </div>
                                {memberService?.response && connectionStatus &&
                                    <div className="col-auto align-self-center">
                                        <a className="mr-2 cursor_pointer" onClick={() => {
                                            if (props.contactId) {
                                                setCid(props.contactId);
                                            }
                                            setWriteEmail(true)
                                        }}>+ Email</a>
                                    </div>
                                }
                            </div>
                        </div>
                        {memberService?.loading || mailService?.loading &&
                            <EmailList
                                type={'inbox'}
                                loading={true}
                                hideHeader={true}
                                page={1}
                            />
                        }
                        {!(memberService?.loading || mailService?.loading) && memberService?.response && typeof connectionStatus !== 'undefined' &&
                            <>
                                <EmailList
                                    type={'inbox'}
                                    list={mailService?.response?.list}
                                    loading={mailService?.loading}
                                    total={mailService?.response?.total}
                                    hideHeader={true}
                                    connectionStatus={connectionStatus}
                                    redirect={(id: string) => {
                                        if (props.contactId) {
                                            history.push('/contacts/' + props.contactId + '/emails/' + id)
                                        } else {
                                            history.push('/emails/inbox/' + id)
                                        }
                                    }
                                    } />
                                {!!mailService?.response&&<div className="email_card_footer mt-0 border-top-0">
                                    <a href={props.contactId ? `contacts/${props.contactId}/emails` : "/emails/inbox"} >{mailService?.response?.list.length==0?"Go to Emails":"View all  emails"}</a>

                                </div>}
                            </>

                        }
                        {!(memberService?.loading || mailService?.loading) && memberService?.response && typeof connectionStatus === 'undefined' &&
                            <NoConnectionBox noBorder={true} />
                        }





                    </div>
                </div>
                {/* End of right columns */}
            </div>
            <NewEmail
                show={writeEmail}
                provider_email={memberService?.response?.provider_email}
                onClose={() => {
                    setWriteEmail(false);
                    setCid("")
                }}
                contactId={cid}
                connectionStatus={connectionStatus}
            />
           {/**  <AddTaskPopUp show={showAddTask} refreshData={() => actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })} toggle={toggleShowAddTask} />*/}
            {<TaskForm refreshData={() => actionTasks.fetch({ status_filter: "future", contact_id: props.contactId, completed: false, page_size: 8 })} show={showAddTask} toggle={toggleShowAddTask} category={category} />}
            <DealsAdd visible={dealPop} toggleShow={() => setDealPop(false)} dealsId={0} />
        </div>
    );
}

const LoadingState = (): JSX.Element => {

    return (
        <div className="row mb-5">
            <div className="col-12 col-md-6 pb-4 pb-md-0">
                <div className="cards cards--tasks">
                    <div className="cards--tasks__content">
                        <div className="card--email__content_heading d-flex">
                            {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                            <div className="mr-2 loading__labels mr-2 w-50px"></div>
                            <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                        </div>
                        <div className="card--email__text text-truncate">
                            <div className="loading__text_small w-100"></div>
                        </div>
                        <div className="loading__text_smaller w-85px"></div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 pb-4 pb-md-0">
                <div className="cards cards--tasks">
                    <div className="cards--tasks__content">
                        <div className="card--email__content_heading d-flex">
                            {/*<div className="align-self-center"><div className="loading__photo mr-3"></div></div>*/}
                            <div className="mr-2 loading__labels mr-2 w-50px"></div>
                            <div className="align-self-center"><div className="loading__text_small w-136px"></div></div>
                        </div>
                        <div className="card--email__text text-truncate">
                            <div className="loading__text_small w-100"></div>
                        </div>
                        <div className="loading__text_smaller w-80px"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}







export const Notes = (_props: INoteCarousel) => {
    let history = useHistory();

    const { id } = useParams<{ id: string }>();
    //const [isNote, setNote] = useState(false);
    const [save, ActionSaveNote] = useAddNotesService();
    const [, ActionUpdateNote] = useUpdateNotesService();
    const [, ActionDeleteNote] = useDeleteNotesService();
    const [notes, ActionFetchNotes] = useFetchGetNotesService({ contact_id: id });
    const [notesList, setList] = useState<INoteCarousel.INote>();
    const [selectedNote, setSelectedNote] = useState<INoteList>();
    // const [isOpen, setIsOpen] = useState(true);
    //let isOpen = true;
    const [present, dismiss] = usePopUp(AddNotesPopUp, {
        selectedNote,
        onRemove: (index) => deleteNote(index),
        onSave: (note) => saveNote(note),
        onWillDismiss() { setSelectedNote(undefined); },
        isLoading: save.loading || false
    })
    useEffect(() => {
        setList({
            page: notes.response?.page,
            page_size: notes.response?.page_size,
            total: notes.response?.total,
            total_pages: notes.response?.total_pages,
            list: notes.response?.list.map(note => ({
                id: note.id,
                notes: note.notes,
                created_dt: note.created_dt,
                modified_dt: note.modified_dt
            }))
        });

    }, [notes.response]);

    useServiceDidSucceeded(save, () => {
        ActionFetchNotes.fetch({ contact_id: id });
        dismiss();
    })


    const saveNote = async (value: string) => {
        if (value === undefined) {
            // setNote(false);
            return
        }
        if (selectedNote && selectedNote?.notes !== value) {

            await ActionUpdateNote.fetch({
                contact_id: id,
                notes_id: selectedNote!.id,
                notes: value,
            });
            const index = notesList?.list?.findIndex(note => note === selectedNote);
            notesList!.list![index!]!.notes = value;
            // onChange?.(selectedNote);

        } else if (!selectedNote) {

            await ActionSaveNote.fetch({
                contact_id: id,
                notes: value,
            });
        }

        // setNote(false);

        setSelectedNote(undefined);
        dismiss();
    }

    const editNote = (index: number) => {
        // setNote(true);
        present();
        setSelectedNote(notesList?.list?.[index]!);
    }

    const deleteNote = (value: INoteList) => {

        ActionDeleteNote.fetch({
            contact_id: id,
            notes_id: value.id
        });

        const newList = notesList?.list!.filter(list => list !== value);
        setList({
            total_pages: notesList?.total_pages,
            page: notesList?.page,
            page_size: notesList?.page_size,
            total: notesList?.total,
            list: newList
        });
        setSelectedNote(undefined);
        dismiss();
    }

    const datePattern = (value: string) => {
        const newDate = value.split(' ')[0]!;
        const newTime = value.split(' ')[1]!;
        let date = new Date(Date.parse(newDate)).toDateString();
        const PatternedDate = `${date}   ${newTime}`;
        return PatternedDate;
    }
    const truncatedText=(text:string)=>{
        //   const lines=text.split(/\r\n|\r|\n/).length;
           var transformText='';
           text=text.trim()
           for(var a=1;a<=300;a++){
              const lines= text.substring(0,a).split(/\r\n|\r|\n/).length
               if(lines>4){transformText=text.substring(0,a);break;}else if(a==300){transformText=text.substring(0,300)}
           }
          return  text.length <300?text:<>{transformText}...<a onClick={()=>present()} className="color_0738CD cursor--hand">show more</a></>;
       }
    return (
        <>
            <div className="color_har_blue w-120px mb-2 ml-auto mr-0 text-right">
                <ProminentButton onClick={present} className='btn--prominent__v4'><img className="align-self-center mr-2 w-20px" src="https://content.harstatic.com/media/icons/plus_blue_light.svg" alt='' />write notes</ProminentButton>
            </div>
            <div className="mb-3">
                {/* Start of First columns */}
                <div className="row">
                    {notesList?.list! ? notesList?.list!.slice(0, 2).map((note, index) => (
                          <div className="col-4" key={note.id}>
                            <div className="note mr-4"  >
                                <div className="note__date">
                                    {datePattern(note.created_dt)}
                                </div>
                                <div className="note__text h-130px">
                                    {truncatedText(note.notes)}
                                </div>
                                <div className="note__edit" >
                                    {/* <ShapelessRedButton className='note_delete' size='small' onClick={() => deleteNote(note)} clickConfirmation='Are you sure to delete this note?'>Delete</ShapelessRedButton> */}
                                    <a data-toggle="modal" data-target="#EditNotesModal" href='javascript:void(0)' onClick={() => editNote(index)}>Edit</a>
                                </div>
                            </div>
                        </div>
                    )) : null}
                    {notesList?.list! && notesList?.list!.length > 0 &&
                        <div className="col-4 ">
                            <div className="color_har_blue note mr-4 cursor_pointer h-330px" onClick={notesList?.list!.length > 2?()=>history.push(`/contacts/${id}/notes`):present} >
                           
                            <div className="note h-130px align-self-center justify-content-center"><ShapelessButton color={"color_har_blue"} className='mt-40px'>{notesList?.list!.length > 2?"Show All":"Write note"}</ShapelessButton></div>
                               
                                <div className="note__edit h-50px" >
                                </div>
                            </div>
                          
                        </div>} 
                </div>
            </div>
        </>
    )
}
