import { IBasicInfoScreenFormProps } from './BasicInfoScreen.def';
import { Avatar } from 'views/contacts/Avatar';
import { ChangeAliasPopUp } from 'views/emails/popups/ChangeAliasPopUp';
import { GetBasicInfoApi } from 'services/user/getBasicInfo';
import { displayMessage } from 'scripts/hooks/feedbacks';
import { useEffect, useState } from 'react';
import { eOnboardingStage } from 'scripts/enums';
import { useOnboardingStage } from 'scripts/hooks/onboarding';

export const useBasicInfoOnboarding = () =>
{
    const onboarding = useOnboardingStage(eOnboardingStage.AccountConnection);
    
    useEffect(() => {
        if(onboarding.stage === eOnboardingStage.BasicInfo) { onboarding.next(false); }
    }, [onboarding.stage]);

    return onboarding;
}

export const Form = (props:IBasicInfoScreenFormProps): JSX.Element =>
{
    //const [action] = useFetchGetBasicInfoService();
    const { onboarding,getBasicInfo } = props;
    const [showPopUp, setShowPopUp] = useState(false);
    const [settings, setSettings] = useState<GetBasicInfoApi.IRoutineEmailSettings>()
    // const { id } = useParams<{ id: string }>();
  
   
  
  
    //const [saveData, saveActions] = useSaveBasicInfoService();
   // const [recommended, setRecommended] = useState(false);
    //const history = useHistory();
  
    if (onboarding.loading || onboarding.error) {
      return (
        <>
          Loading...
          {/*basicInfo.error && <ErrorDialog title="Internal Error">{basicInfo.error.message}</ErrorDialog>*/}
          {/*saveData.error && <ErrorDialog title="Internal Error">{saveData.error.message}</ErrorDialog>*/}
        </>
      );
    }
  
    const response = onboarding.user;
    if (response.settings) {
      //    return <Redirect to="/" />;
    }
    /*if (saveData.response) {
      setTimeout(() => history.push('/'), 2000);
      return <SimpleDialog message="Your settings have been successfully saved." />;
    }*/
  
    const onRoutingEmailUpdate = (settings:GetBasicInfoApi.IRoutineEmailSettings) =>
    {
      setSettings(settings);
      displayMessage('Routing email updated successfully');
    }
  
  
    const photo =
      response.photo ?? 'https://content.harstatic.com/resources/images/listing_details/place_holder_agent.png';
  
    //const showButton = response.onboarding_stage !== eOnboardingStage.Complete;
    const showButton = true; // always show button
  
    return (
      <>
      <div className="ap_change cursor--hand agent_signature agent_signature__large__extra agent_signature__nagative mb-0 pt-4 ">
        {/* <div onClick={()=>window.location.href="https://www-test.har.com/moa_myaccount_personalinfo/my_info?pid=1"}> */}
        <div className='d-flex align-items-center'>
          <Avatar className={"position-relative  w-96px h-96px"} id={response.user_id.toString()} photo={photo} />
          <div>
            <div className="font_size--large_extra_extra">{response.first_name} {response.last_name}</div>
            <div className="font_size--large">{response.agent_data.email}</div>
            <div className="font_size--large">{response.agent_data.phone}</div>
          </div>
        </div>
      </div>
  
       {!getBasicInfo&&<div className="pt-5">
          {/* <label>Name</label>
          <input
            type="text"
            className="form-control mb-4"
            id="Name"
            readOnly
            aria-describedby="Name"
            placeholder="Name"
            value={`${response.first_name} ${response.last_name}`}
          />
          <label>Email</label>
          <input
            type="text"
            className="form-control mb-4"
            id="Email"
            readOnly
            aria-describedby="Email"
            placeholder="Email"
            value={response.agent_data?.email}
          />
  
          <label>Phone</label>
          <input
            type="text"
            className="form-control mb-4"
            id="Phone"
            readOnly
            aria-describedby="Phone"
            placeholder="Phone"
            value={response.agent_data?.phone}
          /> */}
  
          <h2 className="font_size--large_extra_extra mb-1">Email</h2>
          <p className='mb-0'>In order to send emails to your crm, forward it to your routing email</p>
          <p className='mb-1'>Emails sent by your contacts will be automatically available in your CRM</p>
          <p className="font_size--large">
            {/* <strong className='d-block d-md-inline'>Your Routing Email:</strong> */}
            <span className="bg_color_cement_light p-2 v-align-middle">{settings?.routine_email ?? response.settings?.routine_email}</span>
            <button className='btn btn--small btn--simple ml-2' onClick={() => setShowPopUp(true)}>change</button>
          </p>
    
          {showButton && <div className="mt-4 pt-4">
            <button
              type="button"
              className="btn btn--primary d-block"
              disabled={onboarding.next.loading}
              onClick={() => onboarding.next()}
            >
              Manage your Email Account &gt;
            </button>
          </div>}
  
          {/* <button 
          className="btn btn--secondary btn--medium ml-4"
          onClick={()=>window.location.href="https://www-test.har.com/moa_myaccount_personalinfo/my_info?pid=1"}
          
          >Edit Contact Information
          </button>   */}
        </div>}
        <ChangeAliasPopUp
          show={showPopUp}
          setting={settings ?? onboarding.user.settings!}
          onClose={() => setShowPopUp(false)}
          onChange={onRoutingEmailUpdate}
        />
      </>
    );
  };