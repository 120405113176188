import { apiOperations, forwardOperation } from "scripts/helpers/apis";
import { IApiScope } from "types/apis";
import { PropertiesType } from "types/utilities"

export namespace IntiateImportApi
{
    export const url = "/contacts/matrix/import";
    export const method = "POST";
    export const defaultError = "Failed to initiate import.";
    export const operations = apiOperations(IntiateImportApi);
    export const scope = new class IntiateImportApiScope implements IApiScope<Request, Response>
    {
        request(input:Request): PropertiesType<Request, string>
        {
            return {
				...input
			}
        }

        response(input:Record<'data', PropertiesType<Response, string>>): Response
        {
            const { data } = input;
			return {
				success: data.success ? true : false,
                status_id: data.status_id
			}
        }
    }();
    
    export interface Request
    {
        
    }

    export interface Response
    {
        success?:boolean  
        status_id?:string
    }
}

export const fetchIntiateImportService = forwardOperation(IntiateImportApi.operations, 'fetch');
export const useFetchIntiateImportService = forwardOperation(IntiateImportApi.operations, 'useFetch');
export const useIntiateImportService = forwardOperation(IntiateImportApi.operations, 'useService');