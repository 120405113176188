import { ITag, ITagsTextboxProps } from './TagsTextbox.def';
import './TagsTextbox.scss';
import { fetchSearchTagsService } from 'services/typeaheads/searchTags';
import { Typeahead } from 'views/forms/typeaheads/Typeahead';
import { IDropDownOption } from 'views/forms/dropdowns/DropDownOptions/DropDownOptions.def';
import { startCase } from 'lodash-es';

export const TagsTextbox = (props: ITagsTextboxProps): JSX.Element => {

    const maxTags = props.maxTags ?? 1000;

    const search = (value: string) => fetchSearchTagsService({ input: value });

    const mapResult = (item: ITag): IDropDownOption => ({
        id: item.id?.toString() ?? '',
        text: item.id ? item.name : item.name + ` (Add New Tag)`
    });


    const mapResultsList = (input: string, items: ITag[]): ITag[] => {
        // Remove items that are already in the initial tags
        console.log({ items: [...items] });
        const initialNames = props.initialTags?.map(x => x.name.toLocaleLowerCase()) ?? [];
        input = input.toLocaleLowerCase();
        items = items.filter(x => !!x.id || !initialNames.includes(x.name.toLocaleLowerCase()));

        // Add the input as a new tag if it doesn't exist
        // 1. Not Matching input with some values in the list
        // 2. Input is not in the initial tags
        console.log({ input, items: [...items], initialNames: [...initialNames] });
        const isExactMatch = items.every(item => item.name.toLocaleLowerCase() !== input) && !initialNames.includes(input);
        if (isExactMatch) {
            items.push({ name: startCase(input) });
        }
        return items;
    }

    return (
        <>
            <Typeahead.Multi
                placeholder='Tags'
                initialValue={props.initialTags}
                maxValues={maxTags}
                search={search}
                mapResult={mapResult}
                mapResultsList={props.noAdd ? undefined : mapResultsList}
                onChange={props?.onTagsChange}
                fullWidth
                maxLength={15}
                hideId={true} />
            {props.allowNone &&
                <div className="d-flex">
                    <input type="checkbox" id="noneTag" checked={props.initialTags?.find((t) => t.id === 0) ? true : false} name="noneTag" readOnly onChange={(e: any) => {
                        if (e.target.checked) {
                            props.onTagsChange?.([...props.initialTags ?? [], { id: 0, name: 'None' }]);
                        } else {
                            props.onTagsChange?.(props.initialTags?.filter(x => x.id !== 0) ?? []);
                        }
                    }} />
                    <label htmlFor="noneTag" className='ml-2 color_0738CD font_size--small pt-2'>search for contacts without tags</label>
                </div>}



        </>
    );
}

/*export const TagsTextbox = (props: ITagsTextboxProps): JSX.Element => {
    const [values, setValues] = useState(props.initialTags ?? []);
    const { initialTags, onTagsChange, className, ...ionicProps } = props;
    const [searchResult, searchActions] = useSearchTagsService();
    const [showTypeahead, setShowTypeahead] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isBackspaceLast, setBackspaceLast] = useState(false);
    const [getInputValue, setInputValue] = useState<string | number>('');
    const maxTags = props.maxTags ?? 3;
    const ref = useRef<any>(null)
    
    useEffect(() => {
        setValues(initialTags && maxTags ? initialTags.splice(0, maxTags) : []);
    }, [initialTags, maxTags]);

    function getApiCallResponse(value: any) {
        searchActions.fetch({ input: `${value}` });
        setShowTypeahead(true);
    }
    function addItemToFIeld(value: any) {
        if (values.length < maxTags) {
            setValues([...values, { name: value.name.toString(), id: value.id + 1 }]);
            setShowTypeahead(false);
            if (ref.current !== null) {
                ref.current.value = '';
                ref.current.focus();
            }
        } else {
            setDisabled(true);
        }
    }
    function filterTagsItem() {
        const filteredArray = values.filter(x=>x.id!==values.length);
        const newValues = [...filteredArray]
        setValues(newValues);
        props.onTagsChange?.(newValues);
    }
    const addInputValues = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
        let value = e.currentTarget.value;
        if(value){
            setInputValue(value)
        }
        if (value === '' && e.keyCode === 8 ) {
            if(isBackspaceLast){
                filterTagsItem();
                setBackspaceLast(false);
            }else{
                setBackspaceLast(true)
            }
        }
        if (!value) { return; }
        if (isString(value) && !value.trim()) { return; }
        const lastId = values.slice(-1).pop()?.id ?? 0;
        if (maxTags && values.length < maxTags && e.key === 'Enter') {
            const newValues = [...values, { name: value.toString(), id: lastId + 1 }];
            setValues(newValues);
            setShowTypeahead(false);
            e.currentTarget.value = '';
            props.onTagsChange?.(newValues);
        }
        else if (value.toString().length > 2 && e.keyCode !== 8 && e.key !== 'Enter' && value !== getInputValue) {
            getApiCallResponse(value);
        } else if (value.toString().length > 2 && e.keyCode === 8 && e.key !== 'Enter') {    
            if (isString(value)) {
                getApiCallResponse(value.slice(0, -1));
            }
        }
        else if (value.toString().length <= 3 && e.keyCode === 8) {
            setShowTypeahead(false)
        } else if (values.length === maxTags) {
            setDisabled(true)
        }
    }
    const deleteInput = (index: number) => {
        values.splice(index, 1);
        const newValues = [...values];
        setValues(newValues);
        setShowTypeahead(false);
        ref.current.value = ''
        if (values.length < maxTags) {
            setDisabled(false);
            ref.current.value = '';
        }
        props.onTagsChange?.(newValues);
    }

    return (
        <>
            <IonLabel position="stacked">{'Tags'}</IonLabel>
            <div className="tags-input-container" id='container'>
                {values.map((item, index) => {
                    return (
                        <div className="tag-item" id={`${item.id}`} key={index}>
                            <span className="text">{item.name}</span>
                            <span className="close" onClick={() => { deleteInput(index) }}>&times;</span>
                        </div>
                    )
                })}
                <IonInput {...ionicProps} ref={ref} disabled={disabled} placeholder={values.length ? '' : 'Tags'} className={joinWithSpace('form-control tags-input', className)} type="text" onKeyUp={addInputValues}></IonInput>
            </div>
            {showTypeahead ?
                <>
                    {searchResult.loading ?
                        <>
                            <div className="typeahead">
                                <div className="typeahead__item p-3 mb-0">
                                    <a href="#" className="agent_signature agent_signature__large agent_signature__loading">
                                        <div className="agent_signature__photo loading"></div>
                                        <div className="agent_signature__text">
                                            <div className="agent_signature__loading__textline max-w-60pr"></div>
                                            <div className="agent_signature__loading__textline max-w-80pr"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {searchResult.response?.length != 0
                                ?
                                <div className="typeahead" >
                                    {searchResult.response?.map((item) => {
                                        return (
                                            <>
                                                <div className="typeahead" key={item.id}>
                                                    <div className="typeahead__item p-3 mb-0">
                                                        <div className="agent_signature agent_signature__large" onClick={() => { addItemToFIeld(item) }} >
                                                            <div className="agent_signature__photo "></div>
                                                            <div className="agent_signature__text">
                                                                <div className="max-w-60pr">{item.id} </div>
                                                                <div className="max-w-80pr">{item.name} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                :
                                <div className="typeahead w-30pr">
                                    <div className="typeahead__item p-3 mb-0">
                                        <a href="#" className="tagitem_list"><b> No Result found</b> </a>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
                :
                null
            }
        </>
    );
}
*/