import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { GetCalendarTasksApi } from 'services/calendar/getCalendarTasks';
import { CalendarOverviewScreen } from 'views/screens/calendar/CalendarOverviewScreen';
import { CalendarTimelineScreen } from 'views/screens/calendar/CalendarTimelineScreen';
import { CalendarTemplate } from 'views/templates/CalendarTemplate';
import { ContactDetailsTemplate } from 'views/templates/ContactDetailsTemplate';
import { IContactCalendarScreenProps } from './ContactCalendarScreen.def';
import './ContactCalendarScreen.scss';
import moment from 'moment';


export const ContactCalendarScreen = (_props: IContactCalendarScreenProps): JSX.Element =>
{  const contentRef=useRef(null)
   const [timelineFilter, setTimelineFilter]=useState<GetCalendarTasksApi.Request>({categories:"T,E"});
   const [section,setSection]=useState("timeline");
   const [refreshData,handleRefreshData]=useState(false)
   const [month,setMonth]=useState(moment().toDate())
   const [reload, doReload] = useState(1);

  
   const onClickOverviewDay=(date:Date)=>{
    setSection("timeline");
    setTimelineFilter({...timelineFilter,date:date,start_dt:undefined,end_dt:undefined})
   }
    const { id } = useParams<{ id: string }>();
    return (
        <ContactDetailsTemplate selectedMenu='Calendar' contentRef={contentRef} onSwipeRefresh={() => doReload(reload + 1)}>
            <CalendarTemplate  setMonth={setMonth} month={month}  onFilterChange={setTimelineFilter} onSectionChange={setSection} refreshData={handleRefreshData} section={section} key={reload} >
             {section==="timeline"?<CalendarTimelineScreen contentRef={contentRef} month={month} contactId={id} filter={timelineFilter} refreshData={refreshData}/>:
              section==="overview"?<CalendarOverviewScreen month={month} contactId={id} filter={timelineFilter} onFilterChange={setTimelineFilter} refreshData={refreshData} onClickDay={onClickOverviewDay}/>:<></>}
            </CalendarTemplate>  
        </ContactDetailsTemplate>
    );
}
