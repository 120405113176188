import { useHistory } from "react-router"
import { platform } from "scripts/context"
import { displayMessage } from "scripts/hooks/feedbacks"
import { useServiceDidSucceeded } from "scripts/hooks/useService"
import { useImportContactService } from "services/contacts/provider/importContact"
import {GetNotificationsApi  } from "services/notifications/getNotifications"
import { fetchMarkNotificationService } from "services/notifications/markNotification"
import { Avatar } from "views/contacts/Avatar"
import { ShapelessButton } from "views/forms/buttons/Button"

export const NotificationsPopup=()=>
{
  

   return(
                      

     {/**  <Dropdown.Menu  className="dropdown-menu animate slideIn p-4">
            <h3 tabIndex={0}>Notifications</h3>
             <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className="nav nav-tabs nav-tabs--simple mt-4"
                >
             <Tab eventKey="All" title="All" className="pt-3">
            <div className="title__label mb-3">new</div>
             {response?.list.slice(0,5).map((item,index)=>  <NotificationsCard  key={index}  {...item}/>)}
                 {/**    <div className="typeahead__item mb-3">			
                        <a href="#" className="agent_signature agent_signature__large">
                            <div className="agent_signature__photo"></div>
                            <div className="agent_signature__text">
                                <div className="agent_signature__text__title"><span className="status_circle status_circle__pending"></span>David R. Putnam</div>
                                <div className="agent_signature__text__address">93 Southwest Fwy, Houston, TX 77027</div>
                                <div className="agent_signature__text__tags">
                                    <div className="agent_signature__text__tags_tag"><img src="https://content.harstatic.com/media/icons/tag_small.svg" /> Matrix Contact</div>
                                    <div className="agent_signature__text__tags_tag"><img src="https://content.harstatic.com/media/icons/tag_small.svg" /> High interest</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    
               

                    <div className="typeahead__item">			
                        <a href="#" className="agent_signature agent_signature__large">
                            <div className="agent_signature__photo"></div>
                            <div className="agent_signature__text">
                                <div className="agent_signature__text__title">David R. Putnam</div>
                                <div className="agent_signature__text__address">1295 Lyndon Street Shillington, PA 19067</div>
                            </div>
                        </a>
                    </div>

                    <div className="typeahead__item">			
                        <a href="#" className="agent_signature agent_signature__large mb-0">
                            <div className="agent_signature__photo" ></div>
                            <div className="agent_signature__text">
                                <div className="agent_signature__text__title"><div className="label_email label_email--lead">Lead</div> Johnathan Lewis</div>
                                <div className="agent_signature__text__address">93 Southwest Fwy, Houston, TX 77027</div>
                            </div>
                        </a>
                    </div>


                    <div className="card--email cursor--hand p-0 pb-3 mb-3">
                        <div className="card--email__agent"></div>
                        <div className="card--emaill__content overflow-hidden">
                            <div className="card--email__content_heading">
                                <div className="status_circle mr-2"></div>
                                Nikki Storm                                        
                            </div>
                            <div className="card--email__text text-truncate">
                                Hi Shadrick, yesterday you called me and asked me to email you and send you
                            </div>
                            <div className="card--email__date">
                                Today – 09:26 AM
                            </div>
                        </div>                                 
                    </div>
                    
                    <div className="card--email cursor--hand p-0 pb-3 mb-3">
                        <div className="card--email__agent"></div>
                        <div className="card--emaill__content overflow-hidden">
                            <div className="card--email__content_heading">
                                <div className="status_circle mr-2"></div>
                                Nikki Storm                                        
                            </div>
                            <div className="card--email__text text-truncate">
                                Hi Shadrick, yesterday you called me and asked me to email you and send you
                            </div>
                            <div className="card--email__date">
                                Today – 09:26 AM
                            </div>
                        </div>                                   
                    </div>


                    <div className="card--email cursor--hand p-0 mb-0">
                        <div className="card--email__agent"></div>
                        <div className="card--emaill__content overflow-hidden">
                            <div className="card--email__content_heading">                                            
                                Meeting to discuss proposal about Sycamore                                   
                            </div>
                            <div className="card--email__text text-truncate">
                                <div className="agent_signature agent_signature__mini mb-0">
                                    <div className="agent_signature__photo"></div>
                                    <div className="agent_signature__text">John Frusciante M.</div>
                                </div>
                            </div>
                            <div className="card--email__date">
                                Tomorrow – 10:00AM to 10:30AM
                            </div>
                        </div>                                  
                    </div>

                </Tab>
                
                <Tab eventKey="Unread" title="Unread" className="pt-3">
                    Unread tab
                </Tab>
            
            </Tabs>
       <div className="notification_card_footer">
         <a href="/notifications">View all notifications</a>
          </div>
        </Dropdown.Menu>*/}
       
       
   )
}

 export const NotificationsCard=(props:GetNotificationsApi.IListData)=>{
    const history = useHistory();
    const [importContactService, importContactServiceAction]=useImportContactService();

    const handleMarkRead=async(notificationId:string,link:string|undefined,type:string,deleted:string)=>{
        if(type== "Calendar"){type="Task/Event";}
        if(deleted=="1"|| !!!notificationId){
            displayMessage(type+" was deleted.");
            return ;
        }else{
            await fetchMarkNotificationService({ids:[notificationId]});
            !!link&& history.push(link)
        }  
    }
    const handleImportContact=(ids:Array<string>)=>{
        importContactServiceAction.fetch({prevent_update:"1",ids})
       
    }
    useServiceDidSucceeded(importContactService,()=>history.push(`/contacts/import/result/${importContactService.response?.import_id}`))

   const title=!platform.isWeb && props.title.length>45?props.title.substring(0,45)+"...":props.title
    const contactLink=props.sub_type=="provider_import"? `/contacts/import`:props.payload?.id?`/contacts/${props.payload?.id}/overview`:undefined;
    switch(props.type){
        case 'Contact':{
            return (
                <div onClick={()=>props.id&&handleMarkRead(props.id,contactLink,props.type,props.deleted)} className="typeahead__item cursor--hand">			
                <div className="agent_signature agent_signature__mini mb-0 flex-wrap-no align-items-flexstart">
                 <Avatar id={props.id} firstName={props.first_name} lastName={props.last_name} photo={props.photo} className="mb-2 w-32px h-32px"/>  
                <div className="card--emaill__content overflow-hidden">
              {props.status=='0'&&<div className="status_circle mr-2"></div>}
              {title}
                        <div className="card--email__date">
                        {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date}
                        </div>
                </div>
                </div>
            </div>
)}
        case 'Import':{
            return (
                <div onClick={()=>handleMarkRead(props.id, `/contacts/import`,props.sub_type,props.type)} className="typeahead__item cursor--hand">			
                <div className="agent_signature agent_signature__mini mb-0 flex-wrap-no align-items-flexstart">
                <Avatar id={props.id} firstName="" photo={props.photo} className="mb-2 w-32px h-32px"/>
                <div className="card--emaill__content overflow-hidden">
                    {props.status=='0'&&<div className="status_circle mr-2"></div>}
                    {title}  {(props.payload?.ids&&props.payload.ids.length>0&&props.payload.ids.length<6)&&<ShapelessButton onClick={(e)=>{e.preventDefault();e.stopPropagation();props.payload.ids&&handleImportContact(props.payload.ids);}} loading={importContactService.loading}>Add Contact</ShapelessButton>}
                                <div className="card--email__date">
                                {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date} 
                                </div>
                </div>
                </div>
            </div>
        )}
        case 'Email':{
                return (
                <div onClick={()=>props.payload?.id&&handleMarkRead(props.id,`/emails/inbox/${props.payload?.id}`,props.type,props.deleted)}  className="card--email cursor--hand p-0 pb-3 mb-3">
                 <div className="agent_signature agent_signature__mini mb-0 flex-wrap-no align-items-flexstart">
                 <Avatar id={props.id} firstName="" photo={props.photo} className="mb-2 w-32px h-32px"/>
                    <div className="card--emaill__content overflow-hidden">
                    {props.status=='0'&&<div className="status_circle mr-2"></div>}
                    {title}
                        <div className="card--email__date">
                        {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date}
                        </div>
                    </div> 
                    </div>                               
                </div>)}
  
     case 'Calendar':{
             return (
               <div onClick={()=>props.payload?.id&&handleMarkRead(props.id,`/calendar/task/${props.payload?.id}`,props.type,props.deleted)}  className="card--email cursor--hand p-0  pb-3 mb-3">
                <div className="agent_signature agent_signature__mini mb-0 flex-wrap-no align-items-flexstart">   
                   <Avatar id="" photo={props.sub_type=="reminder"?"https://content.harstatic.com/media/icons/notification_with_circle.svg":"https://content.harstatic.com/media/icons/calander_gray_cicle.svg"} firstName="" className="mb-0 w-32px h-32px"/>
                <div className="card--emaill__content overflow-hidden">
                 {props.status=='0'&&<div className="status_circle mr-2"></div>}
                 {props.sub_type== "reminder" ?<span className=" font_weight--bold">Reminder:</span>:""} {title}
                <div className="card--email__date">
                {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date}
                 </div>
             </div>  
         </div>                          
   </div>)}
       
      case 'Deal':{
                    return (
                     <div onClick={()=>props.type_object_id&&handleMarkRead(props.id,`/deals/${props.type_object_id}`,props.type,props.deleted)}  className="card--email cursor--hand p-0  pb-3 mb-3">
                     <div className="agent_signature agent_signature__mini mb-0  flex-wrap-no align-items-flexstart">
                    <Avatar id="" photo="https://content.harstatic.com/media/icons/notification_bell_black.svg" firstName="" className="mb-2 w-32px h-32px"/>
                     <div className="card--emaill__content overflow-hidden">
                     {props.status=='0'&&<div className="status_circle mr-2"></div>}
                  {title}
                         <div className="card--email__date">
                         {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date}
                         </div>
                     </div>  
                         </div>                          
                 </div>)}
        default:{ 
                    return (
                    <div onClick={()=>props.type_object_id&&handleMarkRead(props.id,`/deals/${props.type_object_id}`,props.type,props.deleted)}  className="card--email cursor--hand p-0 pb-3 mb-3">
                    <div className="agent_signature agent_signature__mini mb-0 flex-wrap-no align-items-flexstart">
                    <Avatar id="" photo="https://content.harstatic.com/media/icons/notification_bell_black.svg" firstName="" className="mb-2 w-32px h-32px"/>
                    <div className="card--emaill__content overflow-hidden">
                    {props.status=='0'&&<div className="status_circle mr-2"></div>}
                    {title}
                        <div className="card--email__date">
                        {props.date_tag!="older"&&props.date_tag.toUpperCase()}{props.formatted_date}
                        </div>
                    </div>  
                        </div>                          
                </div>)}
    }
}
export const Empty = (props:{title:string}) =>
(
        <div className="card_empty">
                <div className="card_empty__content max-w-320px">
                    <div className="mb-3"><img className=" w-85px" src="https://content.harstatic.com/media/icons/crm/icom-tasks.svg" /></div>
                    <div className="pt-3 pb-2">
                        You currently don’t have {props.title}.
                    </div>
                </div>
            </div>
);
