import { useComponent } from 'scripts/context';
import { ContactsTableApp } from './ContactsTable.app';
import { IContactsTableProps } from './ContactsTable.def';
import { ContactsTableDesktop } from './ContactsTable.desktop';
import "./ContactsTable.scss";


export const ContactsTable = (props: IContactsTableProps): JSX.Element => {
    const ContactsTablePlatform = useComponent(ContactsTableDesktop(props), ContactsTableApp(props))
    return ContactsTablePlatform
}

