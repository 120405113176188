import { ILoginFeedbackProps } from './LoginFeedback.def';
import './LoginFeedback.scss';
import { useState } from "react";
import { useLocation } from "react-router";
import { SimpleDialog } from "views/dialogs/SimpleDialog";
import { eSimpleDialogIcon } from "views/dialogs/SimpleDialog/SimpleDialog.def";

export const LoginFeedback = (_props: ILoginFeedbackProps): JSX.Element =>
{
    const [isOpen, setIsOpen] = useState(true);
    const location = useLocation();
    if (!isOpen) {
        return <></>;
    }
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const error = params.get('error');
    let message: string | null;
    let title: string;
    if ((action === 'signout' || action === 'signin') && error) {
        message = error;
        title = action === 'signout' ? 'Signout Error' : 'Signin error'
    } else if (action === 'signout') {
        message = null;
        title = 'Successfully Signed Out'
    } else {
        return <></>;
    }
    return (<>
        {message == null ? 
        <SimpleDialog message={title} onClose={() => setIsOpen(false)} blur={false} icon={eSimpleDialogIcon.Success} autoClose = {true} />
         : 
         <div className='login--error--message ml-auto mr-auto mt-4  max-w-405'>
            <div className="font_weight--bold">{message}</div> If the problem persists, contact our support team.
            </div>
            }
    </>)
}
